import { Component, Input, OnInit } from "@angular/core";
import { ApprovalSystemService } from "src/app/services/approval-system.service";
import { DataService } from "src/app/services/data.service";
import { TranslateService } from "src/app/services/translation.service";
import { ApprovalMatrixService } from "../../check-list-pop-up/approval-matrix/approval-matrix.service";

@Component({
  selector: "app-new-approval-status",
  templateUrl: "./new-approval-status.component.html",
  styleUrls: ["./new-approval-status.component.scss"]
})
export class NewApprovalStatusComponent implements OnInit {

  @Input() item;

  constructor(
    public approvalMatrixService: ApprovalMatrixService,
    public approvalService: ApprovalSystemService,
    public translateService: TranslateService,
    public ds: DataService
  ) {}

  ngOnInit() {
    this.approvalService.getUserHistory(this.item);
  }

  showComment(item){
    item.showComment = !item.showComment;
  }

}
