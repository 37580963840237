<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' headerToBeAppended="ValueBridgeFundLevel"></nav-menu-header>

<div class="card page">
    <div class="page-inner">
        <div class="row">
            <div class="col-6" style="padding: 0.56vw;">
                <span class="main-heading">{{ translateService.getLabel("value_bridge_fund_level") }} </span>
            </div>
            <div class="col-6" style="text-align: right;">
                <button mat-raised-button class="primary-bg" (click)="showValuationBridgeDataModal(updateValuationBridge)">
                    {{ translateService.getLabel("add_value_bridge") }}
                </button>
            </div>
        </div>
        
        <br>


        <div *ngFor="let bridge of savedValueBridges; let rowIndex=index">
            <mat-expansion-panel style="margin: 20px 5px 20px 5px; background-color:#FFFFFF" [expanded]="rowIndex == 0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>
                            {{ bridge.name | titlecase }}  
                            <ng-template [ngIf] = "bridge.currency">
                                ({{bridge.currency}} Mn)
                            </ng-template>
                        </b>
                        <i class="fa fa-trash action-icon primary-color-medium delete-icon" style="position: absolute; right: 5%; 
                                        margin: 5px 10px; font-size: 18px; cursor: pointer;"
                            (click)="deleteSelectedValueBridgeData(rowIndex)"></i>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row" style="margin-bottom: 10px;">
                    <div class="col-3">
                        {{ translateService.getLabel("no_of_valuation_dates") }}
                        &nbsp;
                        2
                        &nbsp;
                        <mat-slide-toggle [checked]="bridge.system == 3"
                            (change)="bridge.system == 3 ? bridge.system = 2: bridge.system = 3"></mat-slide-toggle>
                        &nbsp;
                        3
                    </div>
                
                    <div class="col-2">
                        <mat-form-field class="valuation-dropdown" (click)="vDatePicker.open()" style="width: 80%;">
                            <input matInput [matDatepicker]="vDatePicker" readonly [ngModel]="datesInValueBridges[rowIndex].startDate" 
                            (ngModelChange) = "getNewSelectedDate($event, rowIndex, 'startDate')">
                            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #vDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                
                    <div class="col-2" *ngIf="bridge.system == 3">
                        <mat-form-field class="valuation-dropdown" (click)="vDatePicker2.open()" style="width: 80%;">
                            <input matInput [matDatepicker]="vDatePicker2" readonly [ngModel]="datesInValueBridges[rowIndex].intermediateDate"
                            (ngModelChange) = "getNewSelectedDate($event, rowIndex, 'intermediateDate')">
                            <mat-datepicker-toggle matSuffix [for]="vDatePicker2"></mat-datepicker-toggle>
                            <mat-datepicker #vDatePicker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                
                    <div class="col-2">
                        <mat-form-field class="valuation-dropdown" (click)="vDatePicker3.open()" style="width: 80%;">
                            <input matInput [matDatepicker]="vDatePicker3" readonly [ngModel]="datesInValueBridges[rowIndex].endDate"
                            (ngModelChange) = "getNewSelectedDate($event, rowIndex, 'endDate')">
                            <mat-datepicker-toggle matSuffix [for]="vDatePicker3"></mat-datepicker-toggle>
                            <mat-datepicker #vDatePicker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                
                    <div class="col-2" style="text-align: center;">
                        <button mat-raised-button class="primary-bg" (click)="updateValueBridgeData(rowIndex, bridge)">
                            {{ translateService.getLabel("update") }}
                        </button>
                    </div>

                    <div class="col-1">
                        <button class="btn-download-icon" (click)="downloadFundLevelValueBridgeExcel(bridge)">
                            <img src="assets/imgs/download.svg" alt="download">
                        </button>
                    </div>
                </div>
                <app-multi-bridge-system [valueBridgeData]="bridge" (totalAggregation)="getTotalAggregations($event)" *ngIf="bridge.data && bridge.system == 3"></app-multi-bridge-system>
        
                <app-one-bridge-system [valueBridgeData]="bridge" (totalAggregation)="getTotalAggregations($event)" *ngIf="bridge.data && bridge.system == 2"></app-one-bridge-system>
        
            </mat-expansion-panel>
        </div>
        
    </div>
</div>

<ng-template #updateValuationBridge let-applicationModal>
    <div class="modal-header">
        <div class="modal-title">
            <h4>{{ translateService.getLabel("add_value_bridge") }}</h4>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="model-content">
            <div class="row">
                <div class="col-3">
                    <span>{{ translateService.getLabel("value_bridge_name") }}</span>
                </div>
                <div class="col-3">
                    <mat-form-field class="valuation-dropdown">
                        <input matInput name="valueBridgeName" [(ngModel)]="valueBridgeName">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" style="margin-top: 20px">
                <div class="col-3">
                    {{ translateService.getLabel("no_of_valuation_dates") }} 
                </div>
                <div class="col-3">
                    2
                    &nbsp;
                    <mat-slide-toggle [checked]="isNewMultiValueBridge"
                        (change)="isNewMultiValueBridge = !isNewMultiValueBridge"></mat-slide-toggle>
                    &nbsp;
                    3
                </div>
            </div>

            <div class="row" style="margin-top: 20px">
                <div class="col-3 p-modal-left-col">
                    <mat-form-field class="valuation-dropdown" (click)="vDatePicker.open()" style="width: 80%;">
                        <input matInput [matDatepicker]="vDatePicker" readonly [(ngModel)]="valBridgeFundLevel.startDate">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-3 p-modal-left-col" *ngIf="isNewMultiValueBridge">
                    <mat-form-field class="valuation-dropdown" (click)="vDatePicker2.open()" style="width: 80%;">
                        <input matInput [matDatepicker]="vDatePicker2" readonly [(ngModel)]="valBridgeFundLevel.intermediateDate">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker2"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker2></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-3 p-modal-left-col">
                    <mat-form-field class="valuation-dropdown" (click)="vDatePicker3.open()" style="width: 80%;">
                        <input matInput [matDatepicker]="vDatePicker3" readonly [(ngModel)]="valBridgeFundLevel.endDate">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker3"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker3></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button class="primary-bg" type="button" (click)="applicationModal.dismiss('Cross click')">
          {{ translateService.getLabel("cancel") }}
        </button>
        <button mat-raised-button class="primary-bg" (click)="addNewValueBridge(); applicationModal.dismiss('Cross click') ">
          {{ translateService.getLabel("add") }}
        </button>
    </div>
</ng-template>

<a style="display: none;" #downloadLink></a>
