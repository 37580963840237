<div class="menu-container">
    <div class="main-menu-div" *ngFor="let menu of navigationService.menuList; let i = index">
        <div [class.selectedMenu]="menu.showmenuarrow" class="main-menu" (click)="toggleMenuArrow(i)"
            *ngIf="menu.show != 'HIDDEN'">
            <div class="row">
                <div class="col-2 d-flex align-items-center">
                    <i class="{{menu.icon}} v-center-start"></i>
                </div>
                <div class="col-7 d-flex align-items-start pl-0" [matTooltip]="menu.toolTip"
                    (click)="menu.hasOwnProperty('subMenuItems') ? '' : openSidenavLinks(menu)">
                    <p class="main-menu-title v-center-start" [class.hide-menu]="menu.show == 'DISABLED'">{{translateService.getLabel(menu.menuLabel)}}
                    </p>
                </div>
                <div class="col-3 d-flex align-items-center" *ngIf="menu.hasOwnProperty('subMenuItems')">
                    <mat-icon class="arrow">{{menu.showmenuarrow ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="menu.showmenuarrow == true && menu.show != 'HIDDEN'" class="submenu-vertical-line ml-4">
            <div *ngFor="let submenu of menu.subMenuItems ; let i = index">
                <div class="hover-menu" (click)="submenu.showsubmenuarrow = !submenu.showsubmenuarrow" *ngIf="submenu.show != 'HIDDEN'">
                    <div class="row show-submenu-arrow">
                        <div class="col-9 d-flex align-items-center" [matTooltip]="submenu.toolTip"
                            (click)="submenu.hasOwnProperty('subMenuItems') ? '' : openSidenavLinks(submenu)">
                            <p class="submenulabel v-center-start" [class.hide-menu]="submenu.show == 'DISABLED'">
                                {{translateService.getLabel(submenu.menuLabel)}}</p>
                        </div>
                        <div class="col-3 d-flex align-items-center" style="padding-left: 12px;" *ngIf="submenu.hasOwnProperty('subMenuItems')">
                            <mat-icon class="arrow">{{submenu.showsubmenuarrow ? 'arrow_drop_up' : 'arrow_drop_down'}}
                            </mat-icon>
                        </div>
                    </div>
                </div>

                <div *ngIf="submenu.showsubmenuarrow == true && submenu.show != 'HIDDEN'">
                    <div *ngFor="let item of submenu.subMenuItems">
                        <div class="row hover-menu menu-items mr-5" *ngIf="item.show != 'HIDDEN'">
                            <div class="col-2" style="visibility: collapse">
                            </div>
                            <div class="col-10 d-flex align-items-center" style="border-radius: 7px;"
                                (click)="openSidenavLinks(item)" [matTooltip]="item.toolTip">
                                <span class="submenulabel"
                                    [class.hide-menu]="item.show == 'DISABLED'">{{translateService.getLabel(item.menuLabel)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="go-back-menu-div" *ngIf="navigationService.previousPage" (click)="navigationService.openPreviousPage()">
    <i class="fas fa-arrow-left go-back-icon" aria-hidden="true"></i>&nbsp;<span class="go-back-label">{{translateService.getLabel('back_to')}} {{translateService.getLabel(navigationService.previousPage.labelKey)}}</span>
</div>