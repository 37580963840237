
<div class="modal-header">
    <div class="modal-title" style="width: 100%;">
         <h3>Adding a Valuation Date</h3>
    </div>
    <button type="button" class="close"  (click)="valuationDateSelectionPopup.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body valuationPopupBody" style="overflow-y: auto">
    <div class="align-content">
        <div class="row" style="text-align: left">
            <div class="col-3">{{ translateService.getLabel("select_date") }}</div>
            <div class="col">
                <mat-form-field class="custom-input-select" (click)="vDatePicker.open()" style="width: 80%;">
                    <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" 
                    [(ngModel)]="newCompany.valuationDate" 
                    (ngModelChange)="updateMaxMarketDate()">
                    <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row rowAlign">
            <div class="col-3">{{ translateService.getLabel("version_name") }}</div>
            <div class="col">
                <mat-form-field class="custom-input-select"  style="width: 80%;">
                    <input type="text" matInput [(ngModel)] ="newCompany.versionName">
                </mat-form-field>
            </div>
        </div>

        <div class="row rowAlign">
            <div class="col-6">
                {{ translateService.getLabel("market_data_question_label") }}
            </div>
            <div class="col" style="max-width:75px; min-width: 75px; text-align:left">  
                <button mat-raised-button class="primary-bg " (click)="this.newCompany.updateMarketData = true" [disabled]="valuationType == 'replicate'"
                    [ngClass]="{'primary-btn': this.newCompany.updateMarketData == true, 'secondary-btn': this.newCompany.updateMarketData == false}">
                    {{ translateService.getLabel("yes") }}
                </button>
            </div>
            <div class="col" style="text-align:left; max-width:75px; min-width: 75px;">  
                <button mat-raised-button class="secondary-btn" (click)="this.newCompany.updateMarketData = false" [disabled]="valuationType == 'replicate'"
                    [ngClass]="{'primary-btn': this.newCompany.updateMarketData == false, 'secondary-btn': this.newCompany.updateMarketData == true}">
                    {{ translateService.getLabel("no") }}
                </button>
            </div>
            <div class="col-3" style="text-align:left;" *ngIf = "this.newCompany.updateMarketData">  
                <mat-form-field class="custom-input-select" (click)="vDatePicker.open()" style="width: 10vw;">
                    <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" 
                    [(ngModel)]="newCompany.marketDataDate" [max]="maxMarketDateAllowed">
                    <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="row rowAlign">
            <div class="col-6">
                {{ translateService.getLabel("investee_financials_question_label") }}
            </div>
            <div class="col" style="max-width:75px; min-width: 75px; text-align:left">  
                <button mat-raised-button class="primary-bg " (click)="this.newCompany.refreshInvesteefinancials = true" [disabled]="valuationType == 'replicate'"
                    [ngClass]="{'primary-btn': this.newCompany.refreshInvesteefinancials == true, 'secondary-btn': this.newCompany.refreshInvesteefinancials == false}">
                    {{ translateService.getLabel("yes") }}
                </button>
            </div>
            <div class="col" style="text-align:left; max-width:75px; min-width: 75px;">  
                <button mat-raised-button class="secondary-btn" [disabled]="valuationType == 'replicate'"
                    (click)="newCompany.refreshInvesteefinancials = false"
                    [ngClass]="{'primary-btn': this.newCompany.refreshInvesteefinancials == false, 'secondary-btn': this.newCompany.refreshInvesteefinancials == true}">
                    {{ translateService.getLabel("no") }}
                </button>
            </div>
            <div class="col-3" style="text-align:left;" *ngIf="this.newCompany.refreshInvesteefinancials == true">  
                <div class="row">
                    <!-- <div class="col-10" style="padding-right: 0px;">
                        <span>Data available <span style="font-size:x-small">-source: Graviton</span></span>
                        <br>
                        <span style="font-size:x-small">{{ translateService.getLabel("as of")}} (30/09/2022)</span>
                    </div>
                    <div class="col" style="padding: 0;">
                        <span class="icon-background"><i class="fa fa-check" style="margin: 5px;" aria-hidden="true"></i></span>
                    </div> -->
                    <div class="col" style="font-size:small">                      
                        <!-- {{ translateService.getLabel("refer_to_the_financials_section") }} -->
                        <mat-form-field class="custom-input-select" (click)="vDatePicker.open()" style="width: 10vw;">
                            <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" 
                            [(ngModel)]="newCompany.financialRefreshDate" [max]="maxMarketDateAllowed">
                            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row rowAlign">
            <div class="col-6">
                {{translateService.getLabel("Or, are you willing to replicate a past valuation exercise ? ") }}
            </div>
            <div class="col" style="max-width:75px; min-width: 75px; text-align:left">  
                <button mat-raised-button class="primary-bg " (click)="valuationType = 'replicate'; this.newCompany.updateMarketData = false; this.newCompany.refreshInvesteefinancials = false;" 
                [ngClass]="{'primary-btn': valuationType == 'replicate', 'secondary-btn': valuationType == 'revalue'}"
                    [matTooltip]="translateService.getLabel('revalue_btn_tool-tip')">
                    {{ translateService.getLabel("yes") }} 
                </button>
            </div>
            <div class="col" style="text-align:left; max-width:75px; min-width: 75px;">  
                <button mat-raised-button class="secondary-btn" [matTooltip]="translateService.getLabel('replicate_btn_tool-tip')"
                    (click)="valuationType = 'revalue'; this.newCompany.updateMarketData = true; this.newCompany.refreshInvesteefinancials = true"
                    [ngClass]="{'primary-btn': valuationType == 'revalue', 'secondary-btn': valuationType == 'replicate'}">
                    {{ translateService.getLabel("no") }}
                </button>
            </div>
        </div>

        <div *ngIf="valuationType == 'replicate'" class="row rowAlign">
            <mat-radio-group class="radio-group" [(ngModel)]="replicatedValDateId">
                <mat-radio-button class="radio-button" *ngFor="let dateObj of valuationDateList; let i = index" [value]="dateObj.id">
                    {{dateObj.valuationDate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>


        <div class="row rowAlign">
            <div class="col-6">
                {{ translateService.getLabel("MBU_exists_question_label") }}
            </div>
            <div class="col" style="max-width:75px; min-width: 75px; text-align:left">  
                <button mat-raised-button class="primary-bg " [disabled]="valuationType == 'replicate'" (click)="multipleBusinessUnitExists = true; newCompany.businessUnitsNumber = this.businessUnitExists" [ngClass]="{'primary-btn': multipleBusinessUnitExists == true, 'secondary-btn': multipleBusinessUnitExists == false}">{{ translateService.getLabel("yes") }}</button>
            </div>
            <div class="col" style="text-align:left; max-width:75px; min-width: 75px;">  
                <button mat-raised-button class="secondary-btn" [disabled]="valuationType == 'replicate'" (click)="multipleBusinessUnitExists = false; newCompany.businessUnitsNumber = 0" [ngClass]="{'primary-btn': multipleBusinessUnitExists == false, 'secondary-btn': multipleBusinessUnitExists == true}">{{ translateService.getLabel("no") }}</button>
            </div>

            <div class="col-3" style="text-align:left;" *ngIf="multipleBusinessUnitExists">
                <mat-form-field class="custom-input-select" style="width: 10vw;">
                    <input type="text" [disabled]="valuationType == 'replicate'" matInput  [(ngModel)]="newCompany.businessUnitsNumber">
                </mat-form-field>
            </div>
        </div>
        
    </div>
</div>
<div class="modal-footer">
    <div style="text-align: right;">
        <button mat-raised-button class="primary-bg p-button" style="margin: 10px;" type="submit" (click)="updateValuationDetails();valuationDateSelectionPopup.dismiss('Cross click')">
            {{ translateService.getLabel("submit") }}
        </button>
    </div>
</div>
 