import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';

const state = {
  fetched: 'INIT'
};

@Pipe({
  name: 'dynamicDecimalPipe', pure: false
})
export class DynamicDecimalPipe implements PipeTransform {
  private static configValue: string;
  dynamicDecimalsObj = {
    '1': '1.1-1', '2': '1.2-2', '3': '1.3-3'
  }
  constructor(private decimalPipe: DecimalPipe, private ums: UserManagementService, private ds: DataService) {
    const user = this.ums.getSelectedUserDetails();
    if (state.fetched === 'INIT') {
      state.fetched = 'IN_PROGRESS';
      this.ds.getNumberOfDecimals(user.organization.id).subscribe((res) => {
        DynamicDecimalPipe.configValue = res?.body['response']?.configValue;
        state.fetched = 'DONE';
      });
    }
  }

  transform(value: number): any {
    if (isNaN(value))
    return "NA";
    
    const newValue = this.decimalPipe.transform(Math.abs(value), this.dynamicDecimalsObj[DynamicDecimalPipe.configValue]);
    return (value < 0) ? `(${newValue})` : newValue;
  }

}