import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { colors } from 'src/app/utils/colors';
import { ValueBridgeFundLevelService } from 'src/app/value-bridge-fund-level/value-bridge-fund-level.service';
import { UtilService } from 'src/app/utils/util.service';
import { TranslateService } from 'src/app/services/translation.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-multi-bridge-system',
  templateUrl: './multi-bridge-system.component.html',
  styleUrls: ['./multi-bridge-system.component.scss']
})
export class MultiBridgeSystemComponent implements OnInit, OnChanges {
  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridToolbar = ['ColumnChooser'];
  gridFilterSettings = { type: 'Excel' };
  customAttributes = {class: 'hilightCols'};

  @Output() totalAggregation = new EventEmitter();

  constructor(public utilService : UtilService, 
    public datePipe : DatePipe,
    private vbService : ValueBridgeFundLevelService,
    public translateService: TranslateService) { }

  @Input() valueBridgeData;
  
  fundLevelVBTabularData = [];

  valuationBridge;
  companyBridge;

  waterFallLabelsForVB = false;
  waterFallLabelsForCB = false;
  
  aggregatedWaterFallNumbers = {
    totalStartStakeValue : 0,
    totalFirstMetricImpact : 0,
    totalFirstMultipleImpact : 0,
    totalFirstNetDebtImpact : 0,
    totalFirstFxImpact : 0,
    totalFirstStakeImpact : 0,
    totalFirstOthersImpact : 0,
    totalFirstRealisedProceedsImpact : 0,
    totalFirstOthersDebtImpact: 0,
    totalFirstAdjustmentsToEquityValueImpact : 0,
    totalFirstWaterfallImpact : 0,

    totalIntermediateStakeValue : 0,

    totalSecondMetricImpact : 0,
    totalSecondMultipleImpact : 0,
    totalSecondNetDebtImpact : 0,
    totalSecondFxImpact : 0,
    totalSecondStakeImpact : 0,
    totalSecondOthersImpact : 0,
    totalSecondRealisedProceedsImpact : 0,
    totalSecondOthersDebtImpact: 0,
    totalSecondAdjustmentsToEquityValueImpact : 0,
    totalSecondWaterfallImpact : 0,

    totalEndStakeValue : 0,
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.valueBridgeData && this.valueBridgeData.data){
      this.fundLevelVBTabularData = this.vbService.getFormattedValueBridgeDataForMultipleBridgeSystem(this.valueBridgeData.data); //Tabular Format Data for

      let waterFallNumbersForVB =  this.getWaterFallNumbersForFundLevelVB(this.fundLevelVBTabularData)
      this.valuationBridge = this.vbService.drawFundLevelValuationBridgeV2(waterFallNumbersForVB, this.waterFallLabelsForVB); //For Initializing the VB chart

      let waterFallNumbersForCB =  this.getWaterFallNumbersForValueCreationBridge(this.fundLevelVBTabularData);
      this.companyBridge = this.vbService.drawValueCreationBridgeV2(waterFallNumbersForCB, this.waterFallLabelsForCB); //For Initializing the CB chart

      console.log("Total Tabular Aggregates - Multi", this.aggregatedWaterFallNumbers);
      console.log("WaterFall numbers VB - Multi", waterFallNumbersForVB);
      console.log("WaterFall numbers CB - Multi", waterFallNumbersForCB);
    }
  }

  refreshWaterfallGraph() {
    if(this.fundLevelVBTabularData){
      let waterFallNumbersForVB =  this.getWaterFallNumbersForFundLevelVB(this.fundLevelVBTabularData)

      const waterFallChartData = this.vbService.drawFundLevelValuationBridgeV2(waterFallNumbersForVB, this.waterFallLabelsForVB); //For Re-Initializing the VB chart

      this.valuationBridge = cloneDeep(waterFallChartData);
    }

  }

  getWaterFallNumbersForValueCreationBridge(fundLevelVBData){
    let startStakeValue = 0;
    let endStakeValue = 0;
    let intermediateStakeValue = 0;
    
    fundLevelVBData.forEach(row => {
      startStakeValue += row.startStakeValue;
      endStakeValue += (row.endStakeValue);
      intermediateStakeValue += (row.intermediateStakeValue);
    })

    const waterFallNumbers = [];

    // Start Date and Intermediate Date
    waterFallNumbers.push({name: this.datePipe.transform(this.valueBridgeData.dates.startDate, 'mediumDate'), y: startStakeValue && !isNaN(startStakeValue) ? +startStakeValue : 0, color: colors.primaryColor });

    fundLevelVBData.forEach(row => {
      const changeInNAV = row.intermediateStakeValue - row.startStakeValue;
      waterFallNumbers.push({name: row.companyName + " (1)", y: changeInNAV && !isNaN(changeInNAV) ? +changeInNAV : 0});
    })

    // waterFallNumbers.push({name: this.datePipe.transform(this.valuationDates[1], 'mediumDate'), y: intermediateStakeValue && !isNaN(intermediateStakeValue) ? +intermediateStakeValue : 0, color: colors.primaryColor });
    waterFallNumbers.push({name: this.datePipe.transform(this.valueBridgeData.dates.intermediateDate, 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
    // Start Date and Intermediate Date


    // Intermediate Date and endDate

    fundLevelVBData.forEach(row => {
      const changeInNAV = row.endStakeValue - row.intermediateStakeValue;
      waterFallNumbers.push({name: row.companyName + " (2)", y: changeInNAV && !isNaN(changeInNAV) ? +changeInNAV : 0});
    })

    waterFallNumbers.push({name: this.datePipe.transform(this.valueBridgeData.dates.endDate, 'mediumDate'), isSum: true, color: colors.primaryColor });

    return waterFallNumbers;
  }


  getWaterFallNumbersForFundLevelVB(fundLevelVBData){
    const waterFallNumbers = [];

    this.aggregatedWaterFallNumbers = this.vbService.getAggregatedNumbersForMultiBridgeSystem(fundLevelVBData);

    const keys = Object.keys(this.aggregatedWaterFallNumbers);

    keys.forEach( key => {
        if(key == "totalStartStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), y: this.aggregatedWaterFallNumbers[key] ? +this.aggregatedWaterFallNumbers[key] : 0, color: colors.primaryColor });
        }
        else if(key == "totalEndStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), isSum: true, color: colors.primaryColor });
        }
        else if(key == "totalIntermediateStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
        }
        else{
          waterFallNumbers.push({name: this.getValueBridgeLabelByKey(key), y: this.aggregatedWaterFallNumbers[key] ? +this.aggregatedWaterFallNumbers[key] : 0});
        }
    })

    return waterFallNumbers
  }

  getValueBridgeLabelByKey(key){
    let label = "";

    switch (key) {
      case "totalStartStakeValue":
        label = this.valueBridgeData.dates.startDate;
        break;
    
      case "totalFirstMetricImpact":
        label = "Metric (1)";
        break;

      case "totalFirstOthersDebtImpact":
        label = "Adjustment to Stake Equity Value (1)";
        break;  
          
      case "totalFirstMultipleImpact":
        label = "Multiple (1)";
        break;

      case "totalFirstNetDebtImpact":
        label = "Net Debt and Other Balance Sheet Adjustments (1)";
        break;
    
      case "totalFirstFxImpact":
        label = "FX (1)";
        break;
          
      case "totalFirstStakeImpact":
        label = "Stake (1)";
        break;

      case "totalFirstRealisedProceedsImpact":
        label = "Realised Proceeds (1)";
        break;

      case "totalFirstOthersImpact":
        label = "Others (1)"
        break;

      case "totalFirstAdjustmentsToEquityValueImpact":
        label = "Adjustment to Equity Value (1)"
        break;
        
      case "totalFirstWaterfallImpact":
        label = "Waterfall (1)"
        break;

      case "totalFirstNewInvestmentsImpact":
        label = "New Investments (1)"
        break;

      case "totalFirstExitInvestmentsImpact":
        label = "Exit Investments (1)"
        break;   

      case "totalIntermediateStakeValue":
        label = this.valueBridgeData.dates.intermediateDate;
        break;
    
      case "totalSecondMetricImpact":
        label = "Metric (2)";
        break;
    
      case "totalSecondOthersDebtImpact":
        label = "Adjustment to Stake Equity Value (2)";
        break; 

      case "totalSecondMultipleImpact":
        label = "Multiple (2)";
        break;

      case "totalSecondNetDebtImpact":
        label = "Net Debt and Other Balance Sheet Adjustments (2)";
        break;
    
      case "totalSecondFxImpact":
        label = "FX (2)";
        break;
          
      case "totalSecondStakeImpact":
        label = "Stake (2)";
        break;

      case "totalSecondRealisedProceedsImpact":
        label = "Realised Proceeds (2)";
        break;

      case "totalSecondOthersImpact":
        label = "Others (2)"
        break;  

      case "totalSecondAdjustmentsToEquityValueImpact":
        label = "Adjustment to Equity Value (2)"
        break;
        
      case "totalSecondWaterfallImpact":
        label = "Waterfall (2)"
        break;

      case "totalSecondNewInvestmentsImpact":
        label = "New Investments (2)"
        break;

      case "totalSecondExitInvestmentsImpact":
        label = "Exit Investments (2)"
        break; 

      case "totalEndStakeValue":
        label = this.valueBridgeData.dates.endDate;
        break;    
    }

    return label;
  }

}
