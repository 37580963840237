import { Component, OnInit } from '@angular/core';

import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/utils/util.service';
import { QXP_PortFolioService } from '../../qxp-portfolio.service';
import { QXP_FundListService } from '../qxp-fund-list.service';

@Component({
  selector: 'app-qxp-custom-summary-component',
  templateUrl: './qxp-custom-summary-component.component.html',
  styleUrls: ['./qxp-custom-summary-component.component.scss']
})
export class QXP_CustomSummaryComponentComponent implements OnInit {
  geography
  sector

  filteredCompanies = [];
  userSelection = { fund: "", geography: [], sector: [], customSelection: {}};

  mapData = {
    value: 'currentVal',
    name:'Current Valuation',
    data:[]
  }

  geographicalFormData = [];
  mapChart;

  constructor(public portfolioService: QXP_PortFolioService, 
    public fundService: QXP_FundListService, private router: Router,
    private utilService: UtilService,
    private ds: DataService) 
  { }

  ngOnInit() {
    if(! this.fundService.allFunds || this.fundService.allFunds.length == 0) {      
      this.router.navigateByUrl("/qxp-fundList");
      return;
    }

    this.initializeGeographySector()

    this.portfolioService.portfolioUpdated.subscribe(() => {
      this.initializeGeographySector();
      this.filteredCompanies = this.portfolioService.portfolioData;
      this.userSelection.customSelection = {};
      this.portfolioService.portfolioData.forEach(comp => {
        this.userSelection.customSelection[comp.id] = true;
      })
      this.utilService.closeAllPopups();    
    })
  }

  initializeGeographySector() {
    this.geography = this.portfolioService.companies
      .filter((companies) => companies.geography)
      .map((companies) => { return companies.geography.name })

    this.sector = this.portfolioService.companies
      .filter((companies) => companies.sector)
      .map((companies) => { return companies.sector })

    this.sector = this.sector.filter((companies, index, value) => { return value.indexOf(companies) === index })
    this.geography = this.geography.filter((companies, index, value) => { return value.indexOf(companies) === index })
  }

  addGeography(selection) {
    this.userSelection.geography.push(selection)
  }

  addSector(selection) {
    this.userSelection.sector.push(selection)
  }

  removeGeography(index) {
    this.userSelection.geography.splice(index, 1);
  }

  removeSector(index) {
    this.userSelection.sector.splice(index, 1);
  }

  refreshGraphs() {
    let filterredForms = this.portfolioService.companies.filter(comp => comp.groupFormId == null && status!=="Initiated");
    
    filterredForms = filterredForms.filter( f => !this.userSelection.geography || this.userSelection.geography.length === 0 || this.userSelection.geography.indexOf(f.geography.name) >= 0);
    filterredForms = filterredForms.filter( f => !this.userSelection.sector || this.userSelection.sector.length === 0 || this.userSelection.sector.indexOf(f.sector) >= 0);

    this.portfolioService.prepareMapInformation(filterredForms);
    this.portfolioService.prepareColumnChartInfo(filterredForms);

    this.filteredCompanies = this.portfolioService.portfolioData.filter(comp => {
      const formExists = filterredForms.find(f => comp.id == f.id);
      return formExists;
    })
  }

  refreshWithCustomSelection() {
    let filterredForms = this.portfolioService.companies.filter(comp => comp.groupFormId == null && status!=="Initiated");
    
    filterredForms = filterredForms.filter( f => !this.userSelection.geography || this.userSelection.geography.length === 0 || this.userSelection.geography.indexOf(f.geography.name) >= 0);
    filterredForms = filterredForms.filter( f => !this.userSelection.sector || this.userSelection.sector.length === 0 || this.userSelection.sector.indexOf(f.sector) >= 0);

    filterredForms = filterredForms.filter( f => this.userSelection.customSelection[f.id] );

    this.portfolioService.prepareMapInformation(filterredForms);
    this.portfolioService.prepareColumnChartInfo(filterredForms);
  }

  async fundSelectionChange(selection) {
    this.utilService.showLoadingPopup();

    this.portfolioService.companies = [];
    if(selection === "all") {
      this.portfolioService.getAllFormsForAllFunds(this.fundService.allFunds.filter(f => f.fund.type !== 'REPORT').map( f => f.fund.id));  
    } else {
      this.portfolioService.getAllForms(selection);
    }
  }
}
