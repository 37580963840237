import { Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { colors } from '../colors'

@Component({
  selector: 'message-popup',
  templateUrl: 'message-popup.component.html',
})
export class MessagePopupComponent {

  theme = colors;
  
  constructor(public dialogRef: MatDialogRef<MessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig) {
    
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogConfig {
  okButtonMsg: string;
  noButtonMsg: string;
  dialogMsg: string;
  error: boolean;
}