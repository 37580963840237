import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrillDownService {

  constructor() { }

  // To get underlying metrics in local currency or reporting currency only.
  getUnderlyingMetricsForDrillDown(item, comp?, currencyKey?){

    switch(item){
      case "totalValue" :
        return [
          { "key": comp[currencyKey]['realisedProceeds'], "type": "value"},
          { "key": "+", "type": "operator" },
          { "key": comp[currencyKey]['stakeValue'], "type": "value"}
        ]

      case "moic" :
        return [
          { "key": "(", "type": "operator" },
          { "key": comp[currencyKey]['stakeValue'], "type": "value"},
          { "key": "+", "type": "operator" },
          { "key": comp[currencyKey]['realisedProceeds'], "type": "value"},
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": comp[currencyKey]['investmentAmount'], "type": "value"}
        ]

      case "from_last_val_date" :
        return [
          { "key": "(", "type": "operator" },
          { "key": "(", "type": "operator" },
          { "key": comp[currencyKey]['stakeValue'], "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": comp[currencyKey]['prevStakeValue'], "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": comp[currencyKey]['prevStakeValue'], "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": "100", "type": "operator" },
        ]

      default :
        return [ { "key": "NA", "type": "operator" } ];
    }
  }

  // To get underlying metrics for Total aggregations.
  getUnderlyingMetricsForAggregations(key, totalAggregations?){
    switch(key){
      case "totalValue":
        return [
          { "key": totalAggregations['totalRealisedProceeds'], "type": "value"},
          { "key": "+", "type": "operator" },
          { "key": totalAggregations['totalStakeValue'], "type": "value"}
        ]

      case "moic":
        let totalStakeValueWhereInvestmentExists = totalAggregations['totalStakeValueWhereInvestmentExists'] + totalAggregations['totalRealisedProceeds'];
        return [
          { "key": totalStakeValueWhereInvestmentExists, "type": "value" },
          { "key": "/", "type": "operator" },
          { "key": totalAggregations['totalInvestment'], "type": "value" }
        ]

      case "from_last_val_date":
        return [
          { "key": "(", "type": "operator" },
          { "key": "(", "type": "operator" },
          { "key": totalAggregations['totalStakeValue'], "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": totalAggregations['totalPrevStakeValue'], "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": totalAggregations['totalPrevStakeValue'], "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": "100", "type": "operator" },
        ]

      case "percentageChangeInMOICForCurrentOrRealised":
        return [
          { "key": "(", "type": "operator" },
          { "key": totalAggregations['totalMOIC'], "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": totalAggregations['totalPreviousMOIC'], "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": "100", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": totalAggregations['totalPreviousMOIC'], "type": "operator" },
        ]

      case "totalPercentageChangeInMOIC":
        return [
          { "key": totalAggregations.current['totalChangeMOIC'], "type": "operator" },
          { "key": "+", "type": "operator" },
          { "key": totalAggregations.exit['totalChangeMOIC'], "type": "operator" }
        ]

      default :
        return [ { "key": "NA", "type": "operator" } ];
    }
  }

  // To get IRR in local currency or reporting currency only.
  getIRRforCurrentOrRealisedPortfolio(comp, currencyKey, irrKey){

    if(comp[currencyKey][irrKey] && comp[currencyKey][irrKey].length > 0){
      let xIRR = [{"key": "XIRR(", "type": "value"}]

      comp[currencyKey][irrKey].forEach((item, index) => {
        xIRR.push({"key": item.date, "type": "value"});

        if(index != comp[currencyKey][irrKey].length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": " : ", "type": "operator"});
      xIRR.push({ "key": "", "type": "newLine"});

      comp[currencyKey][irrKey].forEach((item, index) => {
        xIRR.push({"key": item.amount, "type": "value"});

        if(index != comp[currencyKey][irrKey].length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": ")", "type": "operator"});
      
      return xIRR;
    }
    else{
      return [ { "key": "NA", "type": "operator" } ];
    }
  }

  // To get IRR for Total aggregations.
  getIRRForAggregationInPortfolioSummary(irrReqBody){
    if(irrReqBody && irrReqBody.length > 0){
      let xIRR = [{"key": "XIRR(", "type": "value"}];

      irrReqBody.forEach((item, index) => {
        xIRR.push({"key": item.date, "type": "value"});

        if(index != irrReqBody.length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": " : ", "type": "operator"});
      xIRR.push({ "key": "", "type": "newLine"});

      irrReqBody.forEach((item, index) => {
        xIRR.push({"key": item.amount, "type": "value"});

        if(index != irrReqBody.length - 1){
          xIRR.push({"key": ", ", "type": "operator"});
        }
      })

      xIRR.push({"key": ")", "type": "operator"});
      
      return xIRR;
    }
    else{
      return [ { "key": "NA", "type": "operator" } ];
    }
  }

  getSourceForDrillDown(item, subcategory?, subcategory1?){
    switch(item){

      case "form":
        let formArray = [];

        if(subcategory == 'currency'){
          formArray.push(this.getSubCategory("portfolio_summary", "operator"));
          formArray.push(this.getSubCategory(" / ", "operator"));
          formArray.push(this.getSubCategory("User Selected Currency", "operator"));
        }
        else{ 
          formArray.push({ "key": item, "type": "value", "link": "form" });
  
          if(subcategory){
            formArray.push(this.getSubCategory(" / ", "operator"));
            formArray.push(this.getSubCategory(subcategory, "value", "form"));
          }
  
          if(subcategory1){
            formArray.push(this.getSubCategory(" / ", "operator"));
            formArray.push(this.getSubCategory(subcategory1, "value", "form"));
          }
        }

        return formArray;

      case "transactions":
        return [
          { "key": item, "type": "value", "link": item }
        ]

      case "data":
        let dataArray = [];

        dataArray.push({ "key": "data", "type": "value", "link": "data_tab" });

        if(subcategory){
          dataArray.push(this.getSubCategory("/", "operator"));
          dataArray.push(this.getSubCategory(subcategory, "value", "data_tab"));
        }

        return dataArray;

      case "valuation":

        let valuationArray = [];

        valuationArray.push({ "key": item, "type": "value", "link": "valuation" });

        if(subcategory){
          valuationArray.push(this.getSubCategory(" / ", "operator"));
          valuationArray.push(this.getSubCategory(subcategory, "value", "valuation"));
        }

        if(subcategory1){
          valuationArray.push(this.getSubCategory(" / ", "operator"));
          valuationArray.push(this.getSubCategory(subcategory1, "value", "valuation"));
        }

        return valuationArray;

      case "valuation_summary":
        return [{"key": item, "type": "value", "link": "valuation_summary"}]

      case "investment_summary":
        return [{"key": item, "type": "value", "link": "investment_summary"}]

      default :
        return [ { "key": "NA", "type": "operator" } ]
      
    }
  }

  getSubCategory(key, type, link?){
    
    let obj = {
      "key": key,
      "type": type
    }

    if(link){
      Object.assign(obj, {"link" : link});
    }

    return obj;

  }

  getFormulaForDrillDownItems(item, comp?, currencyKey?){

    switch(item){
      case "equityValue" : 
        return [
          { "key": "enterprise_value", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "net_debt", "type": "value", "link": "data_tab" }
        ]

      case "stakeValue" :         
        return [
          { "key": "(", "type": "operator" },
          { "key": "adjusted_equity_value", "type": "value", "link": "valuation_summary" },
          { "key": "*", "type": "operator" },
          { "key": "stake", "type": "value", "link": "valuation_summary" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "100", "type": "operator" },
        ]

      case "total_stake" :
        return [
          { "key": "[Total NAV for Current & Realised Portfolio]", "type": "operator" }
        ]

      case "currentPortfolio_stakeValue":
        return [
          { "key": "[Total NAV for Current Portfolio]", "type": "operator" }
        ]

      case "realisedPortfolio_stakeValue":
        return [
          { "key": "[Total NAV for Realised Portfolio]", "type": "operator" }
        ]

      case "stakeAdjustedEquityValue" :       
        return [
          { "key": "stake_equity_value", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "debt_others", "type": "value", "link": "data_tab" }
        ]

      case "moic" :                
        return [
          { "key": "(", "type": "operator" },
          { "key": "nav", "type": "value", "link": "valuation_summary" },
          { "key": "+", "type": "operator" },
          { "key": "realised_proceeds", "type": "value", "link": "transactions" },
          { "key": ")", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "investment_amount", "type": "value", "link": "transactions" },
        ]

      case "dpi" :               
        return [
          { "key": "realised_proceeds", "type": "value", "link": "transactions" },
          { "key": "/", "type": "operator" },
          { "key": "invested_amount", "type": "value", "link": "transactions" },
        ]

      case "total_value" :
        return [
          { "key": "realised_proceeds", "type": "value", "link": "transactions"},
          { "key": "+", "type": "operator" },
          { "key": "nav", "type": "value", "link": "valuation_summary"}
        ]

      case "total_totalValue":
        return [
          { "key": "[Total Total Value for Current & Realised Portfolio]", "type": "operator"}
        ]

      case "currentPortfolio_totalValue":
        return [
          { "key": "[Total Total Value for Current Portfolio]", "type": "operator"}
        ]

      case "realisedPortfolio_totalValue":
        return [
          { "key": "[Total Total Value for Realised Portfolio]", "type": "operator"}
        ]

      case "gross_irr":
        return [
          { "key": "XIRR(Dates : Amounts)", "type": "operator" }
        ]

      case "total_gross_irr":
        return [
          { "key": "[XIRR for Current & Realised Portfolio]", "type": "operator" }
        ]

      case "currentPortfolio_gross_irr":
        return [
          { "key": "[XIRR for Current Portfolio]", "type": "operator" }
        ]

      case "realisedPortfolio_gross_irr":
        return [
          { "key": "[XIRR for Realised Portfolio]", "type": "operator" }
        ]

      case "from_last_val_date" :
        return [
          { "key": "([", "type": "operator" },
          { "key": "Total Value of", "type": "operator" },
          { "key": "(", "type": "operator" },
          { "key": comp["latestValuationDate"] ? comp["latestValuationDate"] : "", "type": "operator" },
          { "key": ")]", "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": "", "type": "newLine"},
          { "key": "[", "type": "operator" },
          { "key": "Total Value of", "type": "operator" },
          { "key": "(", "type": "operator" },
          { "key": comp["prevValuationDate"] ? comp["prevValuationDate"] : "", "type": "operator" },
          { "key": ")]", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "", "type": "newLine"},
          { "key": "[", "type": "operator" },
          { "key": "Total Value of", "type": "operator" },
          { "key": "(", "type": "operator" },
          { "key": comp["prevValuationDate"] ? comp["prevValuationDate"] : "", "type": "operator" },
          { "key": ")])", "type": "operator" },
          { "key": "*", "type": "operator" },
          { "key": "100", "type": "operator" }

          
          // { "key": "Stake Value", "type": "operator" },
          // { "key": "-", "type": "operator" },
          // { "key": "Previous Stake Value", "type": "operator" },
          // { "key": ")", "type": "operator" },
          // { "key": "/", "type": "operator" },
          // { "key": "Previous Stake Value", "type": "operator" },
          // { "key": ")", "type": "operator" },
          // { "key": "*", "type": "operator" },
          // { "key": "100", "type": "operator" }
        ]

      case "total_from_last_val_date":
        return [
          { "key": "(([Total Value of latest valuation date for Current & Realised Portfolio]", "type": "operator" },
            { "key": "-", "type": "operator" },
            { "key": "[Total Value of latest valuation date - 1 for Current & Realised Portfolio])", "type": "operator" },
            { "key": "/", "type": "operator" },
            { "key": "[Total Value of latest valuation date - 1 for Current & Realised Portfolio])", "type": "operator" },
            { "key": "x", "type": "operator" },
            { "key": "100", "type": "operator" },
        ]

      case "currentPortfolio_from_last_val_date":
          return [
            { "key": "(([Total Value of latest valuation date for Current Portfolio]", "type": "operator" },
            { "key": "-", "type": "operator" },
            { "key": "[Total Value of latest valuation date - 1 for Current Portfolio])", "type": "operator" },
            { "key": "/", "type": "operator" },
            { "key": "[Total Value of latest valuation date - 1 for Current Portfolio])", "type": "operator" },
            { "key": "x", "type": "operator" },
            { "key": "100", "type": "operator" }
          ]

      case "realisedPortfolio_from_last_val_date":
        return [
          { "key": "(([Total Value of latest valuation date for Realised Portfolio]", "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": "[Total Value of latest valuation date - 1 for Realised Portfolio])", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Value of latest valuation date - 1 for Realised Portfolio])", "type": "operator" },
          { "key": "x", "type": "operator" },
          { "key": "100", "type": "operator" }
        ]

      case "totalMoic":
        return [
          { "key": "[Total Value for Current & Realised Portfolio]", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Investment Amount for Current & Realised Portfolio]", "type": "operator" }
        ]

      case "currentPortfolio_moic":
        return [
          { "key": "[Total Value for Current Portfolio]", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Investment Amount for Current Portfolio]", "type": "operator" }
        ]

      case "realisedPortfolio_moic":
        return [
          { "key": "[Total Value for Realised Portfolio]", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Investment Amount for Realised Portfolio]", "type": "operator" }
        ]

      case "totalInvestmentAmount":
        return [
          { "key": "[Total Invested Amount for Current & Realised Portfolio]", "type": "operator" }
        ]

      case "currentPortfolio_investedAmount":
        return [
          { "key": "[Total Invested Amount for Current Portfolio]", "type": "operator" }
        ]

      case "realisedPortfolio_investedAmount":
        return [
          { "key": "[Total Invested Amount for Realised Portfolio]", "type": "operator" }
        ]

      case "totalRealisedProceeds":
        return [
          { "key": "[Total Realised Proceeds for Current & Realised Portfolio]", "type": "operator" }
        ]

      case "totalRealisedProceedsForCurrentInvestments":
        return [
          { "key": "[Total Realised Proceeds for Current Portfolio]", "type": "operator" }
        ]

      case "totalRealisedProceedsForExitedInvestments":
        return [
          { "key": "[Total Realised Proceeds for Realised Portfolio]", "type": "operator" }
        ]

      case "totalPercentageChangeInMOIC":
        return [
          {"key": "[Total % Change in MOIC for Current & Realised Portfolio]", "type": "operator"}
        ]

      case "totalChangeInMOICForCurrentPortfolio":
        return [
          { "key": "(", "type": "operator" },
          { "key": "[Total MOIC for Current Portfolio]", "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": "[Total Previous MOIC for Current Portfolio]", "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "* 100", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Previous MOIC for Current Portfolio]", "type": "operator" }
        ]

      case "totalChangeInMOICForRealisedPortfolio":
        return [
          { "key": "(", "type": "operator" },
          { "key": "[Total MOIC for Realised Portfolio]", "type": "operator" },
          { "key": "-", "type": "operator" },
          { "key": "[Total Previous MOIC for Realised Portfolio]", "type": "operator" },
          { "key": ")", "type": "operator" },
          { "key": "* 100", "type": "operator" },
          { "key": "/", "type": "operator" },
          { "key": "[Total Previous MOIC for Realised Portfolio]", "type": "operator" }
        ]

      default :
        return [ { "key": "NA", "type": "operator" } ]
    }

  }
}
