<div class="adjustments-container">
    <div class="row row-no-margin">
        <div class="col">
            <span class="modal-body-header">{{ headerLabel }} : {{ adjustmentOnTotalValue.totalValueBeforeAdjustment |
                number: '1.2-2' }}</span>
        </div>
    </div>
    <div class="row input-block row-no-margin">
        <div class="col-3">
            <mat-form-field class="custom-textbox" style="text-align: left !important;">
                <mat-label>{{translateService.getLabel("search")}}</mat-label>
                <ng-template [ngIf]="isBalanceSheetAdjustments" [ngIfElse]="elseBlock">
                    <input matInput type="text" [(ngModel)]="newAdjustmentModel.name" [matAutocomplete]="auto"
                        (ngModelChange)="inputChange($event)">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions" [matTooltip]="option.helpText"
                            [matTooltipPosition]="'right'" [value]="option.label"
                            style="white-space: normal; line-height: inherit; height: auto; margin: 5px 0;">
                            <div style="font-size: medium">{{option.label}}</div>
                        </mat-option>
                    </mat-autocomplete>
                </ng-template>
                <ng-template #elseBlock>
                    <input matInput type="text" [(ngModel)]="newAdjustmentModel.name"
                        [ngModelOptions]="{standalone: true}">
                </ng-template>
            </mat-form-field>
        </div>

        <div class="col-2">
            <mat-form-field class="custom-input-select">
                <mat-label>{{translateService.getLabel("select_type")}}</mat-label>
                <mat-select name="type" [(ngModel)]="newAdjustmentModel.type">
                    <mat-option value="Add"> {{translateService.getLabel("add")}}</mat-option>
                    <mat-option value="Less"> {{translateService.getLabel("less")}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="custom-textarea">
                <textarea matInput rows="1" style="height: 35px;" placeholder="{{ translateService.getLabel('add_comment') }}" name="comment"
                    [(ngModel)]="newAdjustmentModel.comment"></textarea>
            </mat-form-field>
        </div>
        <div class="col-2">
            <mat-form-field name="value" class="custom-textbox align-right">
                <input matInput type="number" [(ngModel)]="newAdjustmentModel.value" name="value"
                    placeholder="Enter Value">
            </mat-form-field>
        </div>
        <div class="col-1 btn-adj">
            <button mat-raised-button class="btn-custom-primary" type="submit" (click)="addAdjustment()">{{translateService.getLabel("add")}}</button>
        </div>
    </div>

    <div class="table-custom-border">
        <div class="row row-adjustment table-custom-header">
            <label class="col-3 no-padding">{{translateService.getLabel("name")}}</label>
            <label class="col-2">{{translateService.getLabel("type")}}</label>
            <label class="col-4">{{translateService.getLabel("comment")}}</label>
            <label class="col-2">{{translateService.getLabel("value")}}</label>
        </div>
        <ng-template ngFor [ngForOf]="adjustmentOnTotalValue.adjustments" let-adj let-rowIndex="index">
            <div class="row adjustments-table-row row-no-margin">
                <div class="col-3">
                    <ng-template [ngIf]="isBalanceSheetAdjustments" [ngIfElse]="elseBlock">
                        <div class="name-column-data">
                            <mat-label class="mat-adjname">{{adj.name}}</mat-label>
                            <ng-template ngFor [ngForOf] = "balanceSheetAdjOptionsClone" let-option>
                                <em *ngIf = "option.label === adj.name" class="fa fa-info-circle info-icon" [matTooltip]="option.helpText" [matTooltipPosition]="'right'"></em>
                            </ng-template>
                        </div>
                    </ng-template>
                    <ng-template #elseBlock>
                        <mat-form-field class="custom-textbox" style="text-align: left !important;">
                            <mat-label>{{translateService.getLabel("name")}}</mat-label>
                            <input matInput type="text" [(ngModel)]="adj.name">                        
                        </mat-form-field>
                    </ng-template>
                </div>
                <div class="col-2">
                    <!-- {{ adj.type }} -->
                    <mat-form-field class="custom-input-select">
                        <mat-label>{{translateService.getLabel("select_type")}}</mat-label>
                        <mat-select name="type" [(ngModel)]="adj.type" (ngModelChange)="editAdjustments()">
                            <mat-option value="Add">{{translateService.getLabel("add")}}</mat-option>
                            <mat-option value="Less">{{translateService.getLabel("less")}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="custom-textarea">
                        <textarea matInput rows="1" style="height: 35px;" placeholder="Add Comment" name="comment"
                            [(ngModel)]="adj.comment"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-2">
                    <mat-form-field class="custom-textbox align-right">
                        <input matInput type="number" [(ngModel)]="adj.value" (ngModelChange)="editAdjustments()">
                    </mat-form-field>
                </div>
                <div class="col-1 align-trash">
                    <span class="icon-enabled" (click)="deleteConfirmationModal(rowIndex)">
                        <em class="fa fa-trash"> </em>
                    </span>
                </div>
            </div>
        </ng-template>

        <div class="row table-last-custom-row row-no-margin">
            <div class="col-9">{{translateService.getLabel("after_adjustments")}}</div>
            <div class="col-3">{{utilService.getDisplayFormattedNumber(adjustmentOnTotalValue.totalValueAfterAdjustment, 2, "", "-", false)}} </div>
        </div>
    </div>

    <!-- <div class="col-6">
        <div class="row" style = "margin-top:10px;width:100%;margin-left:5px;" *ngIf="adjustmentOnTotalValue && adjustmentOnTotalValue.adjustments && adjustmentOnTotalValue.adjustments.length>0">
            <div class="col-12">
              <div class="m-auto" style = "height:310px;" [chart]="waterFallChartData"></div>
            </div>
        </div>
    </div> -->
</div>
<div class="adjustments-footer">
    <button mat-raised-button class="btn-custom-primary save-btn" type="submit"
        (click)="emitAdjustedValue()">{{translateService.getLabel("save")}}</button>
</div>