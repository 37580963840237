<div class="drp-wrapper">
  <h4 class="drp-table-header">
    Financials, General Details, Profile Details
    <div class="drp-table-subheader">
      Error Count: <mat-chip color="warn" selected="{{dataSource.data.length > 0 }}">{{dataSource.data.length}}</mat-chip>
    </div>
  </h4>
  <div class="drp-table-wrapper mat-elevation-z1">
    <table mat-table [dataSource]="dataSource" class="drp-table">
      <!-- Column Groups -->
      <ng-container matColumnDef="empty">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2" class="no-border">&nbsp;</th>
      </ng-container>
      <ng-container matColumnDef="financials">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Financials</th>
      </ng-container>
      <ng-container matColumnDef="generalDetails">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">General Details</th>
      </ng-container>
      <ng-container matColumnDef="profileDetails">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Profile Details</th>
      </ng-container>

      <!-- Columns -->
      <ng-container matColumnDef="organizationName">
        <th mat-header-cell *matHeaderCellDef class="left-align">Organization</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.organizationName}}</td>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef class="left-align">Company</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.companyName}} <br/><br/> {{"'" + row?.companyId + "'"}}</td>
      </ng-container>
      <!-- Financials -->
      <ng-container matColumnDef="gxFinancialsCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxFinancialsCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFinancialsBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFinancialsBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFinancialsFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFinancialsFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="financialsDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.financialsDelta !== 0 }">
          {{row?.financialsDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxFinancialsLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxFinancialsLastUpdated ? (row?.gxFinancialsLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- General Details -->
      <ng-container matColumnDef="gxGeneralDetailsCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxGeneralDetailsCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoGeneralDetailsBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoGeneralDetailsBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoGeneralDetailsFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoGeneralDetailsFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="generalDetailsDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.generalDetailsDelta !== 0 }">
          {{row?.generalDetailsDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxGeneralDetailsLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxGeneralDetailsLastUpdated ? (row?.gxGeneralDetailsLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- Profile Details -->
      <ng-container matColumnDef="gxProfileDetailsCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxProfileDetailsCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoProfileDetailsBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoProfileDetailsBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoProfileDetailsFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoProfileDetailsFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="profileDetailsDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.profileDetailsDelta !== 0 }">
          {{row?.profileDetailsDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxProfileDetailsLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxProfileDetailsLastUpdated ? (row?.gxProfileDetailsLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>

      <!-- Loading, Empty & Error rows -->
      <ng-container matColumnDef="loading">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <div class="progress-loader">
            <div class="indeterminate"></div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="nodata">
        <td *matFooterCellDef [colSpan]="displayColumns.length">No delta found.</td>
      </ng-container>
      <ng-container matColumnDef="error">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <mat-icon>error</mat-icon> <span>{{error}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumnGroups"></tr>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns" (click)="onRowClick($event,row)"></tr>
      <tr mat-footer-row [hidden]="dataSource?.data?.length > 0 || loading || error" *matFooterRowDef="['nodata']" class="empty-row"></tr>
      <tr mat-footer-row [hidden]="!error" *matFooterRowDef="['error']" class="error-row"></tr>
      <tr mat-footer-row [hidden]="!loading" *matFooterRowDef="['loading']" class="loader-row"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>
</div>
