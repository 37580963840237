import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class GxAttributesService {
  showCreateMap = false;

  constructor(private dataService: DataService, private userMgmtService: UserManagementService) {}

  getGxAttributes() {
    const user = this.userMgmtService.getSelectedUserDetails();
    const orgId = user.organization.id;
    return this.dataService.getGxAttributes(orgId);
  }
}
