<div *ngIf="!isDataloaded && !noDashboardFound" class="loader">
  <h1>{{translateService.getLabel("Loading")}}...</h1>
</div>

<div *ngIf="isDataloaded" style="height: 95vh;">
  <powerbi-report [embedConfig]="reportConfig"></powerbi-report>
</div>

<div *ngIf="noDashboardFound" class="loader">
  {{translateService.getLabel("no_dashboard_found")}}. {{translateService.getLabel("please_contact_your_org_admin")}}.
</div>
