<div class="navigation-header" *ngIf="router.url!='/auth'">
  <!-- <span class="home-icon">
    <i class="fa fa-home"></i>
  </span>
  <span class="home" (click)="navigate()">
    Home
  </span>
  <span class="navigate-icon">
    <i class="fas fa-chevron-right"></i>
  </span> -->
  <span class="header-label" [ngClass]="{'cursor': headerLabel === translateService.getLabel('funds') }" (click)="navigateToFundList()">
    {{headerLabel}}
  </span>
  <!--Added for having additional headers-->
  <span class="navigate-icon" *ngIf="(headerToBeAppended =='Portfolio') || (headerToBeAppended =='InvestmentSummary') || (headerToBeAppended =='MappingManagement')|| (headerToBeAppended =='InvestmentSummary_ConsolSummary') || (headerToBeAppended =='ValueBridgeFundLevel') || (headerToBeAppended =='ConsolidatedSummary') || (headerToBeAppended == 'StatusFundLevel') ||
    (headerToBeAppended == 'CurrencyDashboard') || (headerToBeAppended == 'creditRatingUpload') || (headerToBeAppended == 'Exit Simulation')|| (headerToBeAppended == 'Company Master') || (headerToBeAppended == 'Configuration')">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label cursor" *ngIf="(headerToBeAppended =='Portfolio') || (headerToBeAppended =='InvestmentSummary')|| (headerToBeAppended =='MappingManagement') || (headerToBeAppended =='InvestmentSummary_ConsolSummary') || (headerToBeAppended =='ValueBridgeFundLevel') || (headerToBeAppended =='ConsolidatedSummary') || (headerToBeAppended == 'StatusFundLevel') || (headerToBeAppended == 'CurrencyDashboard')
    || (headerToBeAppended == 'creditRatingUpload') || (headerToBeAppended == 'Exit Simulation') || (headerToBeAppended == 'Company Master')|| (headerToBeAppended == 'Configuration')" (click)="navigateToPortfolio()">
    {{ translateService.getLabel("portfolio_summary") }}  <span *ngIf="fundId">- {{fundName || fundService.getFundName(this.fundId)}}</span>
  </span>
  <span class="header-label cursor" *ngIf="headerToBeAppended =='Monitoring'">
    <i class="fas fa-chevron-right"></i> {{ translateService.getLabel("monitoring") }}  <span *ngIf="fundId">- {{fundName || fundService.getFundName(this.fundId)}}</span>
  </span>
  <span class="navigate-icon" *ngIf="(headerToBeAppended =='ConsolidatedSummary') || (headerToBeAppended == 'StatusFundLevel') || (isDebtInvestmentSummary == 'true') || (headerToBeAppended == 'CurrencyDashboard')">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label cursor" *ngIf="(headerToBeAppended =='ConsolidatedSummary') || (isDebtInvestmentSummary == 'true')" (click)="navigateToConsolidatedSummary()">
    {{ translateService.getLabel("consolidated_summary") }}
  </span>
  <span class="navigate-icon" *ngIf="(headerToBeAppended =='InvestmentSummary') || (headerToBeAppended == 'InvestmentSummary_ConsolSummary') || (headerToBeAppended =='MappingManagement')">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span *ngIf="(headerToBeAppended =='InvestmentSummary') || (headerToBeAppended == 'InvestmentSummary_ConsolSummary')">
    <span class="header-label cursor" (click)="navClickEvent('InvestmentSummary')">
      {{ translateService.getLabel("investment_summary") }}
    </span>
    <span class="navigate-icon" *ngIf="headerToBeAppended =='InvestmentSummary_ConsolSummary'">
      <i class="fas fa-chevron-right"></i>
    </span>
    <span class="header-label" *ngIf="headerToBeAppended =='InvestmentSummary_ConsolSummary'">
    {{ translateService.getLabel("consol_summary") }}
    </span>
  </span>
  <span class="navigate-icon" *ngIf="headerToBeAppended =='Configuration'">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='MappingManagement'">    
    {{ translateService.getLabel("Mapping Management") }}
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='Configuration'">
    {{ translateService.getLabel("configuration") }}
  </span>
  <span class="navigate-icon" *ngIf="headerToBeAppended =='creditRatingUpload'">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='creditRatingUpload'">
    {{ translateService.getLabel("credit_rating_upload") }}
  </span>
  <span class="navigate-icon" *ngIf="headerToBeAppended =='ValueBridgeFundLevel'">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='ValueBridgeFundLevel'">
    {{ translateService.getLabel("value_bridge_fund_level") }}
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='StatusFundLevel'" (click)="onSpanClick($event)">
    {{ translateService.getLabel("fund_status") }}
  </span>
  <span class="header-label" *ngIf="(headerToBeAppended == 'CurrencyDashboard')" (click)="onSpanClick($event)">
    {{ translateService.getLabel("currency_dashboard") }}
  </span>
  <span class="header-label" *ngIf="(headerToBeAppended == 'Exit Simulation')">
    <span class="navigate-icon"><i class="fas fa-chevron-right"></i></span> Exit Simulation
  </span>
  <!--Added for having additional headers-->

  <span class="navigate-icon" *ngIf="headerToBeAppended =='Custom Portfolio Summary'">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='Custom Portfolio Summary'">
    {{ translateService.getLabel("custom_portfolio_summary") }}
  </span>

  <span class="navigate-icon" *ngIf="headerToBeAppended =='Company Master'">
    <i class="fas fa-chevron-right"></i>
  </span>
  <span class="header-label" *ngIf="headerToBeAppended =='Company Master'">
    {{ translateService.getLabel("Company Master") }}
  </span>

  <span class="right-nav-content" *ngIf="isLoggedIn">
    <!-- <span class="right-nav-icons" [matTooltip]="'Platform Version : 2.1.4'">
      <i class="fas fa-info"></i>
    </span> -->
    <!-- <span class="right-nav-icons">
      <i class="fas fa-sync-alt"></i>
    </span>
    <span class="right-nav-icons">
      <i class="fas fa-ellipsis-v"></i>
    </span> -->
  </span>
  <span class = "row" style="float: right;" *ngIf="currency">
    <span class="currency-text col">
      <mat-icon class="info-class">info_outline</mat-icon> {{ translateService.getLabel("all_amounts_mentioned_in") }}
    <span class="dynamic-currency"> {{ currency }} Mn </span> {{ translateService.getLabel("unless_otherwise_specified") }}.
    </span>
  </span>
</div>
