import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

import max from 'lodash/max';
import min from 'lodash/min';
import cloneDeep from 'lodash/cloneDeep';
import sum from 'lodash/sum';
import { UtilService } from 'src/app/utils/util.service';
import { Chart } from 'angular-highcharts';
import { DatePipe } from '@angular/common';
import { CCMServiceV3 } from '../../portfolio/valuation-algorithms/ccm-new-v3.service';
import { IAService } from '../../portfolio/valuation-algorithms/income-approach.service';
import { CTMService } from '../../portfolio/valuation-algorithms/ctm.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

@Injectable({
  providedIn: 'root'
})
export class QXI_ValuationAlgorithmService {
  
  constructor(private ds: DataService,      private iaService: IAService,
        private ccmService: CCMServiceV3,   private ctmService: CTMService,
        private utilService: UtilService,   private datePipe: DatePipe,
        private currencyExchangeService : CurrencyExchangeService) 
  {}

  async initWaterFallBetweenValuationDates(waterFallGraph: Chart, valuationDate1, valuationDate2, oldForm, newForm) {
    const waterFallNumbers = [];

    const valuationSummaries = await this.ds.getInvestmentData([oldForm.id]).toPromise();
    const vd1Summary = JSON.parse(valuationSummaries.body["response"][oldForm.id]);

    const oldFormAPIData =  await this.ds.getFormData(oldForm.id, oldForm.formVersion).toPromise();

    const oldFormData = oldFormAPIData.body['response'].formData;

    let  oldCurrencyExchangeRate = 1;
    
    const targetCurr = oldFormData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY;
    const srcCurr = oldFormData.GENERAL.GD_General_Details.GD_CR_CURRENCY;
    const valDate = oldFormData.valuationDate;
    
    if(this.currencyExchangeService.exchangeRates[targetCurr + '' + srcCurr + '' + valDate]){
      oldCurrencyExchangeRate = this.currencyExchangeService.exchangeRates[targetCurr + '' + srcCurr + '' + valDate];
    }
    else{
      try {
        const oldExchangeRateAPI = await this.ds.getValuationDateCurrency(targetCurr, srcCurr, valDate).toPromise();
        oldCurrencyExchangeRate = oldExchangeRateAPI.body["response"].value;
        this.currencyExchangeService.exchangeRates[targetCurr + '' + srcCurr + '' + valDate] = oldCurrencyExchangeRate
      } 
      catch(e) {}
    }

    this.ccmService.currencyExchangeRate = oldCurrencyExchangeRate;
    this.ctmService.currencyExchangeRate = oldCurrencyExchangeRate;

    this.ccmService.formData = oldFormData;
    this.ctmService.formData = oldFormData;

    const valuationDateCCM1 = JSON.parse(valuationDate1.CCM);
    const valuationDateCCM2 = JSON.parse(valuationDate2.CCM); 

    const valuationDateCTM1 = JSON.parse(valuationDate1.CTM);
    const valuationDateCTM2 = JSON.parse(valuationDate2.CTM);

    const valuationDateIA1 = JSON.parse(valuationDate1.IA);
    const valuationDateIA2 = JSON.parse(valuationDate2.IA);

    const oldBalanceSheet = { cash: valuationDateIA1.response.analysis.cash, debt: valuationDateIA1.response.analysis.debt}
    this.ctmService.setBalanceSheet(oldBalanceSheet);          
    this.ccmService.setBalanceSheet(oldBalanceSheet);

    const newBalanceSheet = { cash: valuationDateIA2.response.analysis.cash, debt: valuationDateIA2.response.analysis.debt}
    valuationDateCCM2.balSheet = newBalanceSheet;          
    valuationDateCTM2.balSheet = newBalanceSheet;

    //Initialise CTM
    this.ctmService.initSavedValuation(valuationDateCTM1);

    //Initialise CCM
    this.ccmService.initSavedValuation(valuationDateCCM1);

    const newIA = valuationDateIA2.response.analysis.analysisData; 
    const oldIA = valuationDateIA1.response.analysis.analysisData; 

    let newRevenueGrowthForScenarios = newIA.map((row, index)=> { return { year: row.year, yoYGrowth: row.yoYGrowth } } );
    let newEBITDAMarginForScenarios = newIA.map((row, index)=> { return { year: row.year, margin: row.margin } } );
    
    if(oldIA.length < newIA.length) {
      newRevenueGrowthForScenarios = newRevenueGrowthForScenarios.slice(0, oldIA.length);
      newEBITDAMarginForScenarios = newEBITDAMarginForScenarios.slice(0, oldIA.length);

    } else if(oldIA.length > newIA.length) {
      for(let index = newRevenueGrowthForScenarios.length; index < oldIA.length; index ++) {
        newRevenueGrowthForScenarios.push({year: oldIA[index].year, yoYGrowth: 1});
        newEBITDAMarginForScenarios.push({ year: oldIA[index].year, margin: 0 });
      }
    }

    //First column in waterfall graph
    const oldFormEquityValue = oldForm.investment.equityValue.finalAdjustedEquityVal;
    waterFallNumbers.push({name: this.datePipe.transform(oldFormData.valuationDate, 'mediumDate'), y: oldFormEquityValue });

    const reqBody = {
      //Only revenue growth is replaced from New to Old analysis.
      revenueScenarios: newRevenueGrowthForScenarios,

      ebitdaScenarios: oldIA.map((row, index)=> { return { year: row.year, margin: row.margin } } ),
      capExScenarios: oldIA.map((row, index)=> { return { year: row.year, capEx: row.lessCapitalExpenditureRevenue } } ),

      "longTermGrowthRate": valuationDateIA2.input.dcfAssumptions.longTermGrowthRate,
      "stableEbitdaMargin":  valuationDateIA1.input.dcfAssumptions.stableCapitalExpenditureOfSales,
      "stableCapitalExpenditure":  valuationDateIA1.input.dcfAssumptions.stableEBITDAMargin ,
      "stableWorkingCapital":  valuationDateIA1.input.dcfAssumptions.stableWorkingCapitalOfSales,
      "discountRate": valuationDateIA1.input.generalInputs.discountRate,

      scenarioName: "BASE_SCENARIO"
    }

    const baseScenario = await this.ds.getIAScenario(reqBody, oldForm.id, oldForm.formVersion).toPromise();

    valuationDateIA1.input = baseScenario.body["response"].scenariosResponse.input;
    valuationDateIA1.response = baseScenario.body["response"].scenariosResponse.response;

    let iaRangeInput = this.iaService.getIARangeAPIInput(
      valuationDateIA1.input.generalInputs.discountRate, // OLD 
      valuationDateIA2.dcfAssumptions.longTermGrowthRate, // NEW
      0.25);

    const iaRangeAnalysis = [];

    for(let rowIndexInRange=0; rowIndexInRange < iaRangeInput.rowHeader.length; rowIndexInRange ++) {
      for(let columnIndexInRange=0; columnIndexInRange < iaRangeInput.columnHeader.length; columnIndexInRange++) { 

        const reqBody = {
          //Only revenue growth is replaced from New to Old analysis.
          revenueScenarios: newRevenueGrowthForScenarios,

          ebitdaScenarios: oldIA.map((row, index)=> { return { year: row.year, margin: row.margin } } ),
          capExScenarios: oldIA.map((row, index)=> { return { year: row.year, capEx: row.lessCapitalExpenditureRevenue } } ),

          "longTermGrowthRate": iaRangeInput.rowHeader[rowIndexInRange],
          "stableEbitdaMargin":  valuationDateIA1.input.dcfAssumptions.stableCapitalExpenditureOfSales,
          "stableCapitalExpenditure":  valuationDateIA1.input.dcfAssumptions.stableEBITDAMargin ,
          "stableWorkingCapital":  valuationDateIA1.input.dcfAssumptions.stableWorkingCapitalOfSales,
          "discountRate": iaRangeInput.columnHeader[columnIndexInRange],

          scenarioName: "BASE_SCENARIO" + "_" + rowIndexInRange + "_" + columnIndexInRange
        }

        const rangeScenario = await this.ds.getIAScenario(reqBody, oldForm.id, oldForm.formVersion).toPromise();
        iaRangeAnalysis.push(rangeScenario.body["response"].scenariosResponse.response.analysis.currencyTotalValue)
      }
    }

    this.iaService.range = {algo: "IA", values: [ min(iaRangeAnalysis), max(iaRangeAnalysis) ] }

    // console.log("------------------ IA Range", iaRangeAnalysis)

    //Find range analysis for CTM
    this.ctmService.updateIAFromBaseScanario(valuationDateIA1.response.analysis.analysisData)
    this.ctmService.updateAnalysisAndSave();

    const projectedRevenue = valuationDateIA1.response.analysis.analysisData
                                  .filter((row, index) => index < 2)
                                  .map(row => row.revenue);
    //Find range analysis for CCM
    this.ccmService.analyzeWithNewSubjectCompFin("revenue", projectedRevenue);
    this.ccmService.findRange();

    // console.log("------- Revenue Growth - before change", vd1Summary.equityValue.finalAdjustedEquityVal);

    //Find final adjusted Equity Value
    this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    const revenueGrowthChange = vd1Summary.equityValue.finalAdjustedEquityVal;

    waterFallNumbers.push({name: 'Revenue Growth', y: (vd1Summary.equityValue.finalAdjustedEquityVal - oldFormEquityValue) });

    // console.log("------- Revenue Growth - After change", vd1Summary.equityValue.finalAdjustedEquityVal, "column value", (vd1Summary.equityValue.finalAdjustedEquityVal - oldFormEquityValue));
    
    // =========================== EBITDA Margin Change - START
    
    const emReqBody = {
      //revenue growth is replaced from New to Old analysis.
      revenueScenarios: newRevenueGrowthForScenarios,

      //EBITDA margin is replaced from New to Old analysis.
      ebitdaScenarios: newEBITDAMarginForScenarios,

      capExScenarios: oldIA.map((row, index)=> { return { year: row.year, capEx: row.lessCapitalExpenditureRevenue } } ),

      "longTermGrowthRate": valuationDateIA2.input.dcfAssumptions.longTermGrowthRate,
      "stableEbitdaMargin":  valuationDateIA2.input.dcfAssumptions.stableCapitalExpenditureOfSales,
      "stableCapitalExpenditure":  valuationDateIA1.input.dcfAssumptions.stableEBITDAMargin ,
      "stableWorkingCapital":  valuationDateIA1.input.dcfAssumptions.stableWorkingCapitalOfSales,
      "discountRate": valuationDateIA1.input.generalInputs.discountRate,

      scenarioName: "EBITDA_MARGIN_SCENARIO"
    }

    const emScenario = await this.ds.getIAScenario(emReqBody, oldForm.id, oldForm.formVersion).toPromise();

    valuationDateIA1.input = emScenario.body["response"].scenariosResponse.input;
    valuationDateIA1.response = emScenario.body["response"].scenariosResponse.response;

    let iaEM_RangeInput = this.iaService.getIARangeAPIInput(
      valuationDateIA1.input.generalInputs.discountRate, // OLD 
      valuationDateIA2.dcfAssumptions.longTermGrowthRate, // NEW
      0.25);

    const iaEM_RangeAnalysis = [];
    const iaBS_RangeAnalysis = [];

    for(let rowIndexInRange=0; rowIndexInRange < iaEM_RangeInput.rowHeader.length; rowIndexInRange ++) {
      for(let columnIndexInRange=0; columnIndexInRange < iaEM_RangeInput.columnHeader.length; columnIndexInRange++) { 

        const reqBody = {
          //Only revenue growth is replaced from New to Old analysis.
          revenueScenarios: newRevenueGrowthForScenarios,

          //EBITDA margin is replaced from New to Old analysis.
          ebitdaScenarios: newEBITDAMarginForScenarios,

          capExScenarios: oldIA.map((row, index)=> { return { year: row.year, capEx: row.lessCapitalExpenditureRevenue } } ),

          "longTermGrowthRate": iaEM_RangeInput.rowHeader[rowIndexInRange], //NEW
          "stableEbitdaMargin": valuationDateIA2.input.dcfAssumptions.stableCapitalExpenditureOfSales, // NEW
          "stableCapitalExpenditure": valuationDateIA1.input.dcfAssumptions.stableEBITDAMargin ,
          "stableWorkingCapital": valuationDateIA1.input.dcfAssumptions.stableWorkingCapitalOfSales,
          "discountRate": iaEM_RangeInput.columnHeader[columnIndexInRange],

          scenarioName: "EBITDA_MARGIN_SCENARIO" + "_" + rowIndexInRange + "_" + columnIndexInRange
        }

        const rangeScenario = await this.ds.getIAScenario(reqBody, oldForm.id, oldForm.formVersion).toPromise();
        iaEM_RangeAnalysis.push(rangeScenario.body["response"].scenariosResponse.response.analysis.currencyTotalValue)

        //Balance Sheet Adjustments
        let scenarioAnalysis = rangeScenario.body["response"].scenariosResponse.response.analysis;
        let bsScenarioAnalysis = scenarioAnalysis.totalEnterpriseValue 
          + valuationDateIA2.response.analysis.cash 
          - valuationDateIA2.response.analysis.debt;

        iaBS_RangeAnalysis.push(bsScenarioAnalysis * (scenarioAnalysis.totalValue / scenarioAnalysis.currencyTotalValue));
      }
    }

    // console.log("------------------ IA Range - EBITDA_MARGIN_SCENARIO", iaEM_RangeAnalysis)

    this.iaService.range = {algo: "IA", values: [ min(iaEM_RangeAnalysis), max(iaEM_RangeAnalysis) ] }

    //Find range analysis for CTM
    this.ctmService.updateIAFromBaseScanario(valuationDateIA1.response.analysis.analysisData)
    this.ctmService.updateAnalysisAndSave();

    const iaEM_ProjectedRevenue = valuationDateIA1.response.analysis.analysisData
                                  .filter((row, index) => index < 2)
                                  .map(row => row.revenue);

    const iaEM_ProjectedEM = valuationDateIA1.response.analysis.analysisData
                                  .filter((row, index) => index < 2)
                                  .map(row => row.margin);

    //Find range analysis for CCM
    this.ccmService.analyzeWithNewSubjectCompFin(iaEM_ProjectedRevenue, iaEM_ProjectedEM);
    this.ccmService.findRange();

    // console.log("------- EBITDA_MARGIN_SCENARIO - before change", vd1Summary.equityValue.finalAdjustedEquityVal);

    //Find final adjusted Equity Value
    this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    const ebitdaMarginChangeEquityValue = vd1Summary.equityValue.finalAdjustedEquityVal;

    waterFallNumbers.push({name: 'EBITDA Margin Expansion', y: (vd1Summary.equityValue.finalAdjustedEquityVal - revenueGrowthChange)});

    // console.log("------- EBITDA_MARGIN_SCENARIO - After change", vd1Summary.equityValue.finalAdjustedEquityVal, "Column Value", (vd1Summary.equityValue.finalAdjustedEquityVal - revenueGrowthChange)); 


    // ==================== Balance Sheet Change - START
    this.ccmService.balSheet = valuationDateCCM2.balSheet;
    this.ctmService.balSheet = valuationDateCTM2.balSheet;

    this.iaService.initSavedValuation(valuationDateIA2, null);

    // console.log("---------------- Old Balance Sheet Input", cloneDeep(this.iaService.result.input));

    // this.iaService.result.input.analysisUpdates.balanceSheetAdjustments = valuationDateIA2.input.analysisUpdates.balanceSheetAdjustments;
    

    // console.log("---------------- NEW Balance Sheet Input", cloneDeep(this.iaService.result.input));

    this.ccmService.balanceSheetAdjustment = valuationDateCCM2.balanceSheetAdjustment;
    this.ctmService.balanceSheetAdjustment = valuationDateCTM2.balanceSheetAdjustment;

    this.ccmService.analyze();
    this.ccmService.findRange();

    this.ctmService.updateAnalysisAndSave();

    const iaBSChangeInput = this.iaService.getCustomAnalysisInput();

    const iaBSChangeAnalysis = await this.ds.getIACustomAnalysis(iaBSChangeInput, newForm.id, newForm.formVersion).toPromise();

    const iaBSChangeRange = iaBSChangeAnalysis.body["response"].response.rangeAlgoResponse;
    

    // this.iaService.range = {algo: "IA", values: [ min(iaBS_RangeAnalysis), max(iaBS_RangeAnalysis) ] }
    this.iaService.range = {algo: "IA", values: [iaBSChangeRange.low, iaBSChangeRange.high]};

    // console.log("---------------- # After API Call Balance Sheet Input Change", this.iaService.range);

    // console.log("------- BS - before change", vd1Summary.equityValue.finalAdjustedEquityVal);

    //Find final adjusted Equity Value
    this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    const bsChangeEquityValue = vd1Summary.equityValue.finalAdjustedEquityVal;

    waterFallNumbers.push({name: 'Balance Sheet Impact', y: (vd1Summary.equityValue.finalAdjustedEquityVal - ebitdaMarginChangeEquityValue)});

    // console.log("------- BS - After change", vd1Summary.equityValue.finalAdjustedEquityVal, "Column Value", (vd1Summary.equityValue.finalAdjustedEquityVal - ebitdaMarginChangeEquityValue)); 


    // ==================== Discount Rate - START
    // this.iaService.result.input.generalInputs.discountRate = valuationDateIA2.input.generalInputs.discountRate;

    // const iaDRChangeInput = this.iaService.getCustomAnalysisInput();
    // const iaDRChangeAnalysis = await this.ds.getIACustomAnalysis(iaDRChangeInput, oldForm.id, oldForm.formVersion).toPromise();

    // const iaDRChangeRange = iaDRChangeAnalysis.body["response"].response.rangeAlgoResponse;
    // this.iaService.range = {algo: "IA", values: [iaDRChangeRange.low, iaDRChangeRange.high]};

    // console.log("------- Discount Rate - before change", vd1Summary.equityValue.finalAdjustedEquityVal);

    //Find final adjusted Equity Value
    // this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    // const drChangeEquityValue = vd1Summary.equityValue.finalAdjustedEquityVal;

    // waterFallNumbers.push({name: 'Change In Discount Rate & TVM', y: (vd1Summary.equityValue.finalAdjustedEquityVal - bsChangeEquityValue)});

    // console.log("------- Discount Rate - After change", vd1Summary.equityValue.finalAdjustedEquityVal, "Column Value", (vd1Summary.equityValue.finalAdjustedEquityVal - bsChangeEquityValue)); 


    // Multiple Expansion
    this.ccmService.similarCompanies = valuationDateCCM2.similarCompanies;
    this.ccmService.prepareMultiples();
    this.ccmService.analyze();
    this.ccmService.findRange();

    // console.log("------- Multiple Expansion - before change", vd1Summary.equityValue.finalAdjustedEquityVal);

    //Find final adjusted Equity Value
    this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    const multipleChangeEquityValue = vd1Summary.equityValue.finalAdjustedEquityVal;

    waterFallNumbers.push({name: 'Multiple Expansion', y: (vd1Summary.equityValue.finalAdjustedEquityVal - bsChangeEquityValue)});

    // console.log("------- Multiple Expansion - After change", vd1Summary.equityValue.finalAdjustedEquityVal, "Column Value", (vd1Summary.equityValue.finalAdjustedEquityVal - bsChangeEquityValue)); 

    // =============== SUB TOTAL
    waterFallNumbers.push({name: 'Gross Equity Value', isIntermediateSum: true});

    // this.iaService.analyseWithNewCurrencyRate();
    // this.ccmService.analyseWithNewCurrencyRate();
    // this.ctmService.analyseWithNewCurrencyRate();

    // const change = this.findRangeAndFinalEquity(vd1Summary.algoSplitUp, oldFormData, vd1Summary.equityValue);

    // waterFallNumbers.push({name: "FX Impact", change });

    //Last column in waterfall graph
    const newFormEquityValue = newForm.investment.equityValue.finalAdjustedEquityVal;
    const totalDifference = newFormEquityValue - oldFormEquityValue;
    const othersChange = totalDifference - waterFallNumbers.reduce((total, wn) => wn.y + total);

    waterFallNumbers.push({name: 'Others', y: othersChange});

    // console.log("------------ CHANGE Series", oldFormEquityValue, newFormEquityValue)
    // console.log("------- CHANGE totalDifference", totalDifference, "Others", othersChange);

    waterFallNumbers.push({name: this.datePipe.transform(newForm.valuationDate, 'mediumDate'), isSum: true });

    waterFallGraph.addSeries({
          upColor: "#5FAFF2",
          color: "#5FAFF2",
          data: waterFallNumbers,
          pointPadding: 0
      } as any, true, false);

    waterFallGraph.ref$.subscribe(ref => {
      // console.log("------------ CHANGE Series", ref.series)
    })
  }

  
  findRangeAndFinalEquity(algoSplitUp, oldFormData, equityValue) {
    this.ccmService.findRange();

    const ctmRange = this.ctmService.range.af.analysis
      .concat(this.ctmService.range.mam.analysis)
      .concat(this.ctmService.range.region.analysis)
      .concat(this.ctmService.range.concludedVariation.analysis)
      .sort((a, b) => a > b ? 1 : a == b ? 0 : -1)
      .filter(v => v > 0);

    equityValue.weightedEquityValue = this.calculateAlgoConcludedMedian(algoSplitUp, this.iaService.range.values, this.ccmService.concludedRange, ctmRange);

    this.addSurplusAssets(oldFormData, equityValue);
  }

  calculateAlgoConcludedMedian(algoSplitUp, ia, ccm, ctm) {
    let cMin = []
    let cMax = []

    //Income Approach
    this.getValuationConcludedNumbers(ia, algoSplitUp, "Income Approach", cMin, cMax);

    //CCM
    this.getValuationConcludedNumbers(ccm, algoSplitUp, "Comparable Company Approach", cMin, cMax);

    //CTM 
    this.getValuationConcludedNumbers(ia, algoSplitUp, "Comparable Transaction Approach", cMin, cMax);

    const concludedValues = cMin.concat(cMax);
    const algoMedian = this.utilService.findMedian(concludedValues);

    // console.log("Summary Conclusion", algoMedian, cMin, cMax, sum(cMin), sum(cMax));

    const totalEquityValue = (sum(cMin) + sum(cMax)) / 2;

    return totalEquityValue;
  }

  getValuationConcludedNumbers(valuation, algoSplitUp, algoName, min, max) {
    const percentAlgo = algoSplitUp.find( a => a.name === algoName);

    const minV = !valuation[0] || isNaN(valuation[0]) ? 0 : valuation[0];
    const maxV = !valuation[1] || isNaN(valuation[1]) ? 0 : valuation[1];

    if(percentAlgo) {
      min.push(+(minV * (+percentAlgo.y / 100)).toFixed(2))
      max.push(+(maxV * (+percentAlgo.y / 100)).toFixed(2))
    }
  }
  
  addSurplusAssets(formData, equityValue) {
    if(formData.OTHERS.OT_Surplus_Assets.OT_SA_YES_NO == "Yes"
        && formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT
        && formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT.OT_SA_NAME) {

          formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT = [formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT]
    }

    this.calculateSurplusAssets(formData, equityValue);
  }
  
  calculateSurplusAssets(formData, equityValue){
    equityValue.dlomAdjustedEquityVal = equityValue.weightedEquityValue * (1-(equityValue.dlomAdjustment/100));

    equityValue.surplusAssetsTotalEquityVal = equityValue.dlomAdjustedEquityVal;

    const surPlusExists = formData.OTHERS.OT_Surplus_Assets 
      && formData.OTHERS.OT_Surplus_Assets.OT_SA_YES_NO == "Yes"
      && formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT
      && formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT.length > 0;

    let sumOfAssets = 0;

    if (surPlusExists) {
      const surplusAssets = formData.OTHERS.OT_Surplus_Assets.OT_SA_PARENT;

      surplusAssets.forEach( async asset => {
        let conversionMultiple = 1;
        if(asset.OT_SA_SOURCE[1] != formData.GENERAL.GD_General_Details.GD_CR_CURRENCY){
          try {
            const currConverter = await this.ds.getValuationDateCurrency(asset.OT_SA_SOURCE[1], formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).toPromise();
            conversionMultiple = currConverter.body['response'].value;
          } 
          catch (error) {
            console.log("Error while fetching exchange rate for surplus assets", error)
          }
        }
        sumOfAssets += asset.OT_SA_VALUE ? +asset.OT_SA_VALUE * conversionMultiple : 0;
        asset.result = sumOfAssets;
      })

      equityValue.weightedEquityValue += sumOfAssets;
      equityValue.surplusAssetsTotalEquityVal = sumOfAssets + equityValue.dlomAdjustedEquityVal;

      this.calcSumOfParts(equityValue, surplusAssets);

      this.calcStakeValue(equityValue);
    
    } else {
      this.calcSumOfParts(equityValue, null);

      this.calcStakeValue(equityValue);
    }
  }
  calcStakeValue(equityValue){
    equityValue.stakeValue = equityValue.finalAdjustedEquityVal * (equityValue.stake/100);
  }

  calcSumOfParts(equityValue, sumOfParts){
    let surplusAssetsTotalEquityVal = equityValue.surplusAssetsTotalEquityVal;

    if(sumOfParts) {

      if(equityValue.surplusAssetsTotalEquityVal !==0){
        sumOfParts.forEach(part =>{
          if (part.type == "Add") {
            surplusAssetsTotalEquityVal = surplusAssetsTotalEquityVal + part.number
          }
          else {
            surplusAssetsTotalEquityVal = surplusAssetsTotalEquityVal - part.number
          }
  
          part.result = surplusAssetsTotalEquityVal;
        })
      }
    }

    equityValue.finalAdjustedEquityVal = surplusAssetsTotalEquityVal;

    this.calcStakeValue(equityValue);
  }
}