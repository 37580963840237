<button mat-raised-button class="primary-bg floating-save" 
    *ngIf = "!ums.currentUserCompanyPermissionReadOnly"
    (click)="saveData(true)" > 
    {{ translateService.getLabel("save") }}
</button>

<div style="background-color: white;" >
    <div class="row">
        <div class="col-5">
            <table class="table" style="margin-bottom: 0">
                <tr>
                    <td>
                        <label style="cursor: pointer;">
                            <input style="display:none" accept="image/*" type="file" id="file" (change)="getImageURL($event)">
                            <img [src]="investmentDateValuation.logo" 
                                style="cursor: pointer; max-width: 100px; max-height: 100px;"
                                (error)="reloadLogoImage(investmentDateValuation)"
                                *ngIf="investmentDateValuation && investmentDateValuation.logo; else defaultLogo2">

                            <ng-template #defaultLogo2>
                                <i class="far fa-building" style="margin-left: 6px; font-size: xx-large;"></i>
                            </ng-template>
                        </label>

                        <span style="margin-left: 4%;font-size: 20px;">
                            <!-- {{ fundDetails.organization.name }} -->
                            {{ companyName }}
                        </span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-6">
            <table class="table">
                <tr>
                    <td style="width: 10%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-2" style="width: fit-content; padding: 0;">
                                    <img width="20px" style="margin: auto;" height="20px"
                                        [src]="imageLoadService.getImage('value (1).svg')" alt="value">
                                </div>
                                <div class="col" style="width: fit-content; padding: 0;">
                                    <div style="font-size: 10px; text-align: center">
                                        {{ translateService.getLabel("nav") }}
                                    </div>
                                    <div style="font-size: 15px; font-weight: bold; white-space: nowrap; text-align: center">
                                        <ng-template [ngIf] = "loadingTrackRecord" [ngIfElse] = "nav">
                                            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20"></mat-spinner>
                                        </ng-template>
                                        <ng-template #nav>
                                            <ng-template [ngIf] = "latestValDateTrackRecord.stakeAdjustedEquityValue > 0"
                                                [ngIfElse] = "noValue">
                                                {{ latestValDateTrackRecord.currency }} 
                                                <app-data-drill-down-menu [value]="latestValDateTrackRecord.stakeAdjustedEquityValue" [decimalPoint]="1" [suffix]="'Mn'" [valueInPlaceOfZero]="'-'"
                                                [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [onlyPositiveAllowed]="true"
                                                [label]="translateService.getLabel('nav')" [formula]="getFormulaForTrackRecordItems('stakeAdjustedEquityValue')"
                                                [underlyingMetrics]="getUnderlyingMetrics('stakeAdjustedEquityValue', latestValDateTrackRecord)" [source]="getSourceForDrillDown('valuation_summary', 'fair_value_of_investment')" [valDateId]="latestValDateTrackRecord.formId"
                                                [currency] = "latestValDateTrackRecord.currency" [typeOfSource]="'Calculated'"></app-data-drill-down-menu>
                                            </ng-template>
                                            <ng-template #noValue>
                                                -
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- <td style="width: 22%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" style="margin: auto;" height="20px"
                                        [src]="imageLoadService.getImage('refresh.svg')" alt="refresh">
                                </div>
                                <div class="col" style="width: fit-content; padding: 0;">
                                    <div style="font-size: 10px;display: inline-block; white-space: nowrap;">
                                        Update from last date
                                    </div>
                                    <ng-template
                                        [ngIf]="latestValData && latestValData[0] && latestValData[0].changeInValPercentage">
                                        <div style="font-size: 15px; font-weight: bold; ">
                                            {{ latestValData[0].changeInValPercentage * 100 | number:'1.1-1' }}%
                                            <img width="10px" height="10px"
                                                *ngIf="latestValData[0].changeInValPercentage > 0"
                                                [src]="imageLoadService.getImage('up-arrow.svg')" alt="up-arrow">
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="!latestValData || !latestValData[0] || !latestValData[0].changeInValPercentage">
                                        <div> - </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </td> -->
                    <td style="width: 15%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" height="20px" [src]="imageLoadService.getImage('surface1.svg')"
                                        alt="value">
                                </div>
                                <div class="col" style="padding: 0">
                                    <div style="font-size: 10px;display: inline-block">
                                        <div> {{translateService.getLabel("IRR")}}</div>
                                        <div>({{latestValuationDate.valuationDate | date: "mediumDate"}})</div>
                                    </div>
                                    <div style="font-size: 15px; font-weight: bold;">
                                        <ng-template [ngIf]="loadingTrackRecord || latestValDateTrackRecord.grossIRR == 'loading'" [ngIfElse] = "grossIRR">
                                            <mat-spinner style="display: inline-block; margin: auto;" [diameter] = "20"></mat-spinner>
                                        </ng-template>
                                        
                                        <ng-template #grossIRR>
                                            <ng-template [ngIf]="!latestValDateTrackRecord.grossIRR || latestValDateTrackRecord.grossIRR >= 0">
                                                <app-data-drill-down-menu [value]="latestValDateTrackRecord.grossIRR" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0'"
                                                [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [onlyPositiveAllowed]="false"
                                                [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')"
                                                [underlyingMetrics]="getUnderlyingMetrics('IRR', latestValDateTrackRecord)" [source]="getSourceForDrillDown('valuation')"
                                                [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency" [typeOfSource]="'Calculated'"></app-data-drill-down-menu>
                                            </ng-template>
                                            
                                            <ng-template [ngIf]="latestValDateTrackRecord.grossIRR < 0">
                                                <span style="color: red">
                                                    <app-data-drill-down-menu [value]="latestValDateTrackRecord.grossIRR" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0'"
                                                    [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                                    [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [displayNegativeNo]="true" [typeOfSource]="'Calculated'"
                                                    [underlyingMetrics]="getUnderlyingMetrics('IRR', latestValDateTrackRecord)" [source]="getSourceForDrillDown('valuation')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                                </span>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style="width: 15%; padding: 5px;">
                        <div class="box" style="height: 70px;">

                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" height="20px" [src]="imageLoadService.getImage('money.svg')"
                                        alt="value">
                                </div>
                                <div class="col" style="padding: 0">
                                    <div style="font-size: 10px;display: inline-block">
                                        <div>{{translateService.getLabel("moic")}}</div>
                                        <div>({{latestValuationDate.valuationDate | date: "mediumDate"}})</div>
                                    </div>
                                    <div style="font-size: 15px; font-weight: bold;">
                                        <ng-template [ngIf] = "loadingTrackRecord" [ngIfElse] = "moic">
                                            <mat-spinner style="display: inline-block; margin: auto;" [diameter] = "20"></mat-spinner>
                                        </ng-template>
                                        <ng-template #moic>
                                            <ng-template [ngIf] = "latestValDateTrackRecord.moic > 0"
                                                [ngIfElse] = "noValue">
                                                <!-- {{ utilService.getDisplayFormattedNumber(latestValDateTrackRecord.moic, 2, "x", "-", true) }} -->
                                                <app-data-drill-down-menu [value]="latestValDateTrackRecord.moic" [decimalPoint]="2" [suffix]="'x'" [valueInPlaceOfZero]="'-'"
                                                [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [onlyPositiveAllowed]="true"
                                                [label]="translateService.getLabel('moic')" [formula]="getFormulaForTrackRecordItems('moic')" [typeOfSource]="'Calculated'"
                                                [underlyingMetrics]="getUnderlyingMetrics('moic', latestValDateTrackRecord)" [source]="getSourceForDrillDown('transactions')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                            </ng-template>
                                            <ng-template #noValue>
                                                -
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="val-date-upper-limit">
        <span style="cursor: pointer;"
            (click)="openValdateLimitPopUp(valDateUpperLimitPopup)">{{valDateUpperLimit}}</span>
        
        <span style="cursor: pointer;margin-left: 10px;">
            <mat-form-field class="exited-investment">
                <mat-select [(ngModel)]="selectedExitStatus"
                    [disabled] = "ums.currentUserCompanyPermissionReadOnly" 
                    class="{{selectedExitStatus == 'CURRENT PORTFOLIO' ? 'colorGreen' : 'colorRed'}}" (ngModelChange)="setExitStatus()">

                    <mat-option value="CURRENT PORTFOLIO">
                        {{ translateService.getLabel("current") }}
                    </mat-option>
                    <mat-option value="EXIT PORTFOLIO">
                        {{ translateService.getLabel("exited") }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span> &nbsp;
        <span class="exited-date-picker" *ngIf="selectedExitStatus == 'EXIT PORTFOLIO'" style="margin-top: 10px;vertical-align: middle;">
            <mat-form-field class="dateInputBox">
                <input matInput [matDatepicker]="picker" style="color:red;font-size:14px;vertical-align: super;" [(ngModel)]="selectedExitDate" (ngModelChange)="setExitStatus()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </span>
    </div>

    <div class="exit-date-error" *ngIf="selectedExitStatus == 'EXIT PORTFOLIO' && !selectedExitDate">
        <span class="exit-date-error-initial-space"></span>
        &nbsp;
        <span class="choose-exit-date-error-msg">
            {{translateService.getLabel("choose_exit_date")}}
        </span>
    </div>

    <div class="row">
        <div class="col-5">
            <div class="row" style="font-size: small">
                <div class="col spacing sidePanelStyle">
                    <div style="border-right: solid 1px #d8dad8;margin-top: 2%;">
                        <table class="table borderless tableSpacing1" *ngIf = "showHideStatusOfGeneralAttributes">
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.location.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("location") }}</td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="countryName">
                                        <!-- {{countryName}} -->
                                        <app-data-drill-down-menu [value]="translateService.getLabel('country_' + countryAlias)" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                            textField="true" [label]="translateService.getLabel('location')"
                                            [formula]="getFormulaForTrackRecordItems('NA')" [underlyingMetrics]="getUnderlyingMetrics('')" [typeOfSource]="'User Entry'"
                                            [source]="getSourceForDrillDown('form', 'country')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>                                  
                                </td>
                            </tr>
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.sector.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("sector") }}</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="companyName.toLowerCase().indexOf('orolia') < 0">
                                        <!-- {{ companySector }} -->
                                        <app-data-drill-down-menu [value]="companySector" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                            textField="true" [label]="translateService.getLabel('sector')"
                                            [formula]="getFormulaForTrackRecordItems('NA')" [underlyingMetrics]="getUnderlyingMetrics('')" [typeOfSource]="'User Entry'"
                                            [source]="getSourceForDrillDown('form', 'industry')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="companyName.toLowerCase().indexOf('orolia') >= 0">
                                        {{ translateService.getLabel("defence") }} & {{ translateService.getLabel("aerospace") }}
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr *ngIf="fundDetails && fundDetails.organization">
                                <td style="font-weight: bold;">Total Number of Employees</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="fundDetails.organization.estimated_num_employees">
                                        {{ fundDetails.organization.estimated_num_employees |
                                        number:"1.0-0"}}
                                    </ng-template>
                                </td>
                            </tr> -->
                            <!-- <tr>
                                <td style="font-weight: bold;">Founded on</td>
                                <td class="rightAlignment"></td>
                            </tr> -->
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.website.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("website") }}</td>
                                <td class="rightAlignment">

                                    <ng-template [ngIf]="latestValDateTrackRecord" [ngIfElse]="elseSide">
                                        <app-data-drill-down-menu [value]="formData.GENERAL.GD_General_Details.GD_WEBSITE"
                                            textField="true" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                            [label]="translateService.getLabel('website')" [formula]="getFormulaForTrackRecordItems('NA')"
                                            [underlyingMetrics]="getUnderlyingMetrics('')" [source]="getSourceForDrillDown('form', 'website')" [typeOfSource]="'User Entry'"
                                            [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #elseSide>
                                        {{ formData.GENERAL.GD_General_Details.GD_WEBSITE }}
                                    </ng-template>
                                </td>
                            </tr>

                            <!-- <ng-template [ngIf]="fundDetails">
                                <tr *ngFor="let people of fundDetails.people;let i=index">
                                    <ng-template [ngIf]="i < 4">
                                        <td style="font-weight: bold;float: left;" *ngIf="i==0">Key People</td>
                                        <td style="font-weight: bold;float: left;" *ngIf="i>0"
                                            [style.visibility]="'hidden'">
                                            Key People
                                        </td>
                                        <td class="rightAlignment">
                                            <ng-template [ngIf]="people.name && people.name.length > 0">
                                                {{ people.name }}

                                                <ng-template [ngIf]="people.title && people.title.length > 0">
                                                    ({{people.title}})
                                                </ng-template>
                                            </ng-template>
                                        </td>
                                    </ng-template>
                                </tr>
                            </ng-template> -->

                            <!-- <tr>
                                <td style="font-weight: bold;">Total Funding</td>
                                <td class="rightAlignment">
                                    USD {{ fundDetails.organization.total_funding }} Billion
                                </td>
                            </tr> -->

                            <tr *ngIf = "showHideStatusOfGeneralAttributes.investmentDate.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("investment_date") }}</td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="latestValDateTrackRecord" [ngIfElse]="elseSide">
                                        <app-data-drill-down-menu [value]="datePipe.transform(investmentDateValuation.valuationDate, 'MMM, yyyy')" 
                                            textField="true" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                            [label]="translateService.getLabel('investment_date')" [formula]="getFormulaForTrackRecordItems('NA')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                            [source]="getSourceForDrillDown('form')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #elseSide>
                                        {{investmentDateValuation.valuationDate | date: "MMM, yyyy"}}
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.intialInvestment.show">
                                <td style="font-weight: bold;">
                                    {{ translateService.getLabel("initial_investment") }}
                                    <!-- <span>
                                          <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentAmount)"></i>
                                      </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <!-- <ng-template
                                        [ngIf]="transactionsForCompany && transactionsForCompany.initialInvestmentAmount">
                                        {{ latestValData[0].currency }} {{transactionsForCompany.initialInvestmentAmount
                                        | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template
                                        [ngIf]=" !transactionsForCompany || !transactionsForCompany.initialInvestmentAmount">
                                        <span> - </span>
                                    </ng-template> -->
                                    <!-- ToDo -->
                                    <ng-template [ngIf] = "trackRecord && trackRecord[0] && trackRecord[0].initialInvestment"
                                        [ngIfElse] = "noValue">
                                        {{ trackRecord[0].currency }} 
                                        <app-data-drill-down-menu [value]="trackRecord[0].initialInvestment" [decimalPoint]="1" [suffix]="'Mn'"
                                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(trackRecord[0].valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                            [onlyPositiveAllowed]="true" [label]="translateService.getLabel('initial_investment')" [formula]="getFormulaForTrackRecordItems('initial_investment')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                            [source]="getSourceForDrillDown('transactions')" [valDateId]="trackRecord[0].formId"></app-data-drill-down-menu>
                                    </ng-template>

                                    <ng-template #noValue>
                                        NA
                                    </ng-template>

                                </td>
                            </tr>
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.investmentToDate.show">
                                <td style="font-weight: bold;">
                                    {{ translateService.getLabel("investment_to_date") }} 
                                </td>
                                <td class="rightAlignment">
                                    <!-- <ng-template
                                        [ngIf]="transactionsForCompany && transactionsForCompany.remainingInvestmentAmount">
                                        {{ latestValData[0].currency }}
                                        {{transactionsForCompany.remainingInvestmentAmount | number: '1.1-1'}} Mn
                                    </ng-template>

                                    <ng-template [ngIf]=" !transactionsForCompany || !transactionsForCompany.remainingInvestmentAmount">
                                        <span> - </span>
                                    </ng-template> -->

                                    <ng-template [ngIf] = "latestValDateTrackRecord && latestValDateTrackRecord.investmentAmount"
                                        [ngIfElse] = "noValue">
                                        {{ latestValDateTrackRecord.currency }} 
                                        <app-data-drill-down-menu [value]="latestValDateTrackRecord.investmentAmount" [decimalPoint]="1" [suffix]="'Mn'"
                                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                            [onlyPositiveAllowed]="true" [label]="translateService.getLabel('investment_to_date')" [formula]="getFormulaForTrackRecordItems('investment_to_date')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                            [source]="getSourceForDrillDown('transactions')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>

                                    <ng-template #noValue>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;"> 
                                    Investment Status
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentStatus)"></i>
                                    </span>
                                </td>
                                <td> 
                                    {{exitStatus | titlecase}}
                                </td>
                            </tr> -->
                            <!-- <tr>
                                <td style="font-weight: bold;">
                                    Partner
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(partner)"></i>
                                    </span>
                                </td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="savedData.partners && savedData.partners.length > 0">
                                        <span *ngFor="let partner of savedData.partners; let index = index">
                                            <ng-template [ngIf]="partner.name">
                                                {{ partner.name | titlecase}} <span
                                                    *ngIf="index < savedData.partners.length-1">,</span>
                                            </ng-template>
                                        </span>
                                    </ng-template>

                                </td>
                            </tr> -->
                            

                            <!-- Custom Attribute  -->
                            <ng-template [ngIf]="displayCustomAttributes">
                                <tr *ngFor="let attribute of displayCustomAttributes; let index = index">
                                    <ng-template [ngIf]="attribute.show">
                                        <td style="font-weight: bold;">
                                            {{ translateCustomAttributes(attribute)}}
                                        </td>
                                        <ng-template [ngIf]="attribute.attributeKey == 'security_type'" [ngIfElse]="elseSide">
                                            <td class="rightAlignment">
                                                <app-data-drill-down-menu [value]="attribute.attributeValue" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                                textField="true" [label]="translateCustomAttributes(attribute)"
                                                [formula]="getFormulaForTrackRecordItems('NA')" [underlyingMetrics]="getUnderlyingMetrics('')" [typeOfSource]="'User Entry'"
                                                [source]="getSourceForDrillDown('User Entry')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                            </td>
                                        </ng-template>
                                        <ng-template #elseSide>
                                            <ng-template [ngIf]="getStringValue(attribute.attributeValue)" [ngIfElse]="notAString">
                                                <td class="rightAlignment" style="word-break: break-word;">
                                                    <app-data-drill-down-menu [value]="attribute.attributeValue"
                                                        [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" textField="true"
                                                        [label]="translateCustomAttributes(attribute)" [formula]="getFormulaForTrackRecordItems('NA')"
                                                        [underlyingMetrics]="getUnderlyingMetrics('')" [source]="getSourceForDrillDown('User Entry')"
                                                        [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency" [typeOfSource]="'User Entry'">
                                                    </app-data-drill-down-menu>
                                                </td>
                                            </ng-template>
                                            <ng-template #notAString>
                                                <td class="rightAlignment">
                                                    <!-- {{attribute.attributeValue}} -->
                                                    <app-data-drill-down-menu [value]="attribute.attributeValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'-'"
                                                        [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                                        [label]="translateCustomAttributes(attribute)" [formula]="getFormulaForTrackRecordItems('')"
                                                        [underlyingMetrics]="getUnderlyingMetrics('')" [source]="getSourceForDrillDown('User Entry')"
                                                        [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency" [typeOfSource]="'User Entry'">
                                                    </app-data-drill-down-menu>
                                                </td>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <!-- Custom Attribute Code End  -->

                        </table>
                    </div>                    
                </div>

                <div class="col spacing sidePanelStyle">
                    <div style="margin-top: 2%;">
                        <table class="table borderless tableSpacing2" *ngIf = "showHideStatusOfGeneralAttributes">
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.impliedownership.show">
                                <td style="font-weight: bold;">
                                    {{ translateService.getLabel("fully_diluted_ownership") }}
                                    <!-- <span>
                                        <i class="fas fa-external-link-alt"
                                            (click)="openPopUpModel(fullyDilutedOwnership)"></i>
                                    </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="summaryNumbers.fullyDilutedOwnership && summaryNumbers.fullyDilutedOwnership > 0" [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.fullyDilutedOwnership" [decimalPoint]="1" [suffix]="'%'"
                                            [valueInPlaceOfZero]="'-'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                            [onlyPositiveAllowed]="true" [label]="translateService.getLabel('fully_diluted_ownership')" [formula]="getFormulaForTrackRecordItems('fully_diluted_ownership')" [underlyingMetrics]="getUnderlyingMetrics('fully_diluted_ownership', latestValuationDate)"
                                            [source]="getSourceForDrillDown('track_record', 'stake')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency" [typeOfSource]="'Calculated'"></app-data-drill-down-menu>
                                    </ng-template>
                                    <!-- <ng-template [ngIf]="fundDetails.organization.name && fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                      25%
                                      </ng-template> -->
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">
                                    Security Type
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(securityType)"></i>
                                    </span>
                                </td>
                                <td class="rightAlignment">
                                    {{ savedData.securityType | titlecase }}
                                </td>
                            </tr> -->
                            <!-- <ng-template [ngIf]="fundDetails.organization.name && fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                  Series B Preferred
                              </ng-template> -->
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.irr.show">
                                <td style="font-weight: bold;">{{translateService.getLabel("IRR")}}</td>
                                <td class="rightAlignment">
                                    <!-- <ng-template [ngIf]="this.transactionsForCompany.IRR">
                                        {{ this.transactionsForCompany.IRR * 100 | number: '1.1-1'}}%
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.IRR">
                                        <span> - </span>
                                    </ng-template> -->

                                    <ng-template [ngIf]="!latestValDateTrackRecord.grossIRR || latestValDateTrackRecord.grossIRR >= 0">
                                        <app-data-drill-down-menu [value]="latestValDateTrackRecord.grossIRR" [decimalPoint]="1" [suffix]="'%'"
                                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                            [onlyPositiveAllowed]="true" [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [underlyingMetrics]="getUnderlyingMetrics('IRR', latestValDateTrackRecord)"
                                            [source]="getSourceForDrillDown('track_record', 'IRR')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>

                                    <ng-template [ngIf]="latestValDateTrackRecord.grossIRR < 0">
                                        <span style="color: red;">
                                            <app-data-drill-down-menu [value]="latestValDateTrackRecord.grossIRR" [decimalPoint]="1" [suffix]="'%'"
                                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                            [displayNegativeNo]="true" [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [underlyingMetrics]="getUnderlyingMetrics('IRR', latestValDateTrackRecord)"
                                            [source]="getSourceForDrillDown('track_record', 'IRR')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                        </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.moic.show">
                                <td style="font-weight: bold;">{{translateService.getLabel("moic")}}</td>
                                <td class="rightAlignment">
                                    <!-- <ng-template [ngIf]="this.transactionsForCompany.moic">
                                        {{ this.transactionsForCompany.moic | number: '1.2-2'}}x
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.moic">
                                        <span> - </span>
                                    </ng-template> -->

                                    <ng-template [ngIf] = "latestValDateTrackRecord && latestValDateTrackRecord.moic && latestValDateTrackRecord.moic > 0"
                                        [ngIfElse] = "noValue">
                                        <app-data-drill-down-menu [value]="latestValDateTrackRecord.moic" [decimalPoint]="2" [suffix]="'x'"
                                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                            [onlyPositiveAllowed]="true" [label]="translateService.getLabel('moic')" [formula]="getFormulaForTrackRecordItems('moic')" [underlyingMetrics]="getUnderlyingMetrics('moic', latestValDateTrackRecord)"
                                            [source]="getSourceForDrillDown('track_record', 'moic')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #noValue>
                                        NA
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf = "showHideStatusOfGeneralAttributes.realisedproceeds.show">
                                <td style="font-weight: bold;">
                                    {{ translateService.getLabel("realised_proceeds") }}
                                    <!-- <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(realisedProceeds)"></i>
                                    </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <!-- <ng-template
                                        [ngIf]="transactionsForCompany.totalRealisedProceeds && transactionsForCompany.totalRealisedProceeds">
                                        {{ latestValData[0].currency }} {{ transactionsForCompany.totalRealisedProceeds
                                        | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="!transactionsForCompany.totalRealisedProceeds || !transactionsForCompany.totalRealisedProceeds">
                                        <span> - </span>
                                    </ng-template> -->

                                    <ng-template [ngIf] = "latestValDateTrackRecord && latestValDateTrackRecord.realisedProceeds && latestValDateTrackRecord.realisedProceeds > 0"
                                        [ngIfElse] = "noValue">
                                        {{ latestValDateTrackRecord.currency }} 
                                        <app-data-drill-down-menu [value]="latestValDateTrackRecord.realisedProceeds" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [onlyPositiveAllowed]="true" [label]="translateService.getLabel('realised_proceeds')" [formula]="getFormulaForTrackRecordItems('realised_proceeds')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('transactions')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>

                                    <ng-template #noValue> 
                                        NA
                                    </ng-template>

                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">Industry Median (EBITDA Multiple)</td>
                                <td class="rightAlignment">{{summaryNumbers.industryBevEbitda | number: "1.2-2"}} x</td>
                            </tr> -->
                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedebitdamultiple.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_ebitda_multiple") }} ({{getYearTranslation(latestValuationYear)}})
                                </td>
                                <td class="rightAlignment">
                                    <app-data-drill-down-menu [value]="summaryNumbers.subjectCompBevEbitda" [decimalPoint]="1" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [onlyPositiveAllowed]="true" [label]="translateService.getLabel('implied_ebitda_multiple') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('subjectCompBevEbitda')" [underlyingMetrics]="getUnderlyingMetrics('subjectCompBevEbitda')"
                                        [source]="getSourceForDrillDown('track_record', 'ebitda_multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">Industry Median (Revenue Multiple)</td>
                                <td class="rightAlignment">{{summaryNumbers.industryBevRevennue | number: "1.2-2"}} x</td>
                            </tr> -->
                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedrevenuemultiple.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_revenue_multiple") }}
                                    ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompBevRevenue" [decimalPoint]="1" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_revenue_multiple') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('subjectCompBevRevenue')" [underlyingMetrics]="getUnderlyingMetrics('subjectCompBevRevenue')"
                                        [source]="getSourceForDrillDown('track_record', 'revenue_multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedebitmultiple.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_ebit_multiple") }} ({{getYearTranslation(latestValuationYear)}})
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompBevEbit" [decimalPoint]="1" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_ebit_multiple') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('subjectCompBevEbit')" [underlyingMetrics]="getUnderlyingMetrics('subjectCompBevEbit')"
                                        [source]="getSourceForDrillDown('track_record', 'ebit_multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedgrossprofitmultiple.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_gross_profit_multiple") }} ({{getYearTranslation(latestValuationYear)}})
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompEVGrossProfit" [decimalPoint]="1" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_gross_profit_multiple') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('subjectCompEVGrossProfit')" [underlyingMetrics]="getUnderlyingMetrics('subjectCompEVGrossProfit')"
                                        [source]="getSourceForDrillDown('track_record', 'gross_profit_multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedpricesales.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("sales_multiple") }}
                                    ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompPsales" [decimalPoint]="2" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_price') + ' / ' + translateService.getLabel('sales_multiple') + ' (' + getYearTranslation(latestValuationYear) + ')'" [formula]="getFormulaForTrackRecordItems('subjectCompPsales')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedpriceearnings.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("earnings_multiple") }}
                                    ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompPe" [decimalPoint]="2" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_price') + ' / ' + translateService.getLabel('earnings_multiple') + ' (' + getYearTranslation(latestValuationYear) + ')'" [formula]="getFormulaForTrackRecordItems('subjectCompPe')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.impliedpricebookvalue.show">
                                <td style="font-weight: bold;"> {{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("book_value_multiple") }}
                                    ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers"
                                        [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.subjectCompPbv" [decimalPoint]="2" [suffix]="'x'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('implied_price') + ' / ' + translateService.getLabel('book_value_multiple') + ' (' + getYearTranslation(latestValuationYear) + ')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.revenue.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("revenue") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>

                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.revennue" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('revenue') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="showHideStatusOfGeneralAttributes.ebitda.show">
                                <td style="font-weight: bold;">{{translateService.getLabel("ebitda")}} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>

                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.ebitda" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('ebitda') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="showHideStatusOfGeneralAttributes.ebitdamargin.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("ebitda_margin") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.ebitdaMargin" [decimalPoint]="1" [suffix]="'%'"
                                        [valueInPlaceOfZero]="'NMF'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('ebitda_margin') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('ebitdaMargin')" [underlyingMetrics]="getUnderlyingMetrics('ebitdaMargin', latestValDateTrackRecord)"
                                        [onlyPositiveAllowed]="true" [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.ebit.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("ebit") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.ebit" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('ebit') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.grossprofit.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("gross_profit") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.grossProfit" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('gross_profit') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.ebitmargin.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("ebit_margin") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <app-data-drill-down-menu [value]="summaryNumbers.ebitMargin" [decimalPoint]="1" [suffix]="'%'"
                                        [valueInPlaceOfZero]="'NMF'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                        [label]="translateService.getLabel('ebit_margin') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('ebitMargin')" [underlyingMetrics]="getUnderlyingMetrics('ebitMargin', latestValDateTrackRecord)"
                                        [onlyPositiveAllowed]="true" [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.priceearnings.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("price_of_earnings") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.pe" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('price_of_earnings') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.pricebookvalue.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("price_of_book_value") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.pbv" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('price_of_book_value') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="showHideStatusOfGeneralAttributes.pricesales.show">
                                <td style="font-weight: bold;">{{ translateService.getLabel("price_of_sales") }} ({{getYearTranslation(latestValuationYear)}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="formData">
                                            {{ formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        <app-data-drill-down-menu [value]="summaryNumbers.psales" [decimalPoint]="1" [suffix]="'Mn'"
                                        [valueInPlaceOfZero]="'0'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'User Entry'"
                                        [label]="translateService.getLabel('price_of_sales') + ' (' + getYearTranslation(latestValuationYear) +')'" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                        [source]="getSourceForDrillDown('data')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
            <span style="margin: 10px;">
                <button mat-button class="primary-bg editButtonMarginLeft" (click) = "createGeneralAttributesData()">
                    {{ translateService.getLabel("manage_attributes") }}
                </button>
                <app-custom-attribute-popup 
                [currency] = "latestValDateTrackRecord.currency"
                [companyId]="companyId" 
                [generalAttributes] = "generalAttributeData"
                [showHideStatusOfGeneralAttributes] = "showHideStatusOfGeneralAttributesClone"
                [latestValuationDate]="latestValuationDate"
                [popupState] = "popupState"
                (updated)="getCustomAttributesData()"
                (updateGeneralAttrsStatus)="updateGeneralAttrsStatus($event)">
                
                </app-custom-attribute-popup>
            </span>
        </div>
        <div class="col-7">
            <div style="font-size: medium; margin: 5px">
                <!-- {{fundDetails.organization.short_description}} -->
                {{companyDescription}}
            </div>

            <br>
        
            <ng-template [ngIf]="orgKeyWords && orgKeyWords.length > 0">
                <mat-chip-list>
                    <mat-chip class="primary-bg"
                        *ngFor="let keyword of orgKeyWords; let keyIndex=index" [removable]="true"
                        (removed)="remove(keyword, keyIndex)">
                        {{ keyword | titlecase}}
                        <mat-icon matChipRemove style="color: #fff;">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
            <br>

            <div class="row">          
                <div class="col-11" 
                    #barChartRef
                    id = "barChartRef"
                    [chart]="valuationBarChart"
                    style="height: 250px;">
                </div>
                <div class="col-1 chart-alignment" *ngIf="valuationBarChart">
                    <span>
                        <i class="fas fa-cog" style="cursor: pointer;" *ngIf = "!ums.currentUserCompanyPermissionReadOnly"
                        (click)="openSettingsOption()"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <br>

    <!-- NAV bridge -->
    <div class="row">
        <div class="col-4" *ngIf="valueBridgeLabel != 'ADVANCED_NAV_BRIDGE'" style="padding-left: 0;">
            <span class="header">
                {{getLabelsForValueBridgeType(valueBridgeLabel)}} {{ translateService.getLabel("bridge") }} ({{valueBridgeCurrency}})
                <i class="fas fa-info-circle" style="font-size: small;"
                    matTooltip="{{translateService.getLabel('nav_bridge_balance_sheet_custom_adjustments')}}"></i>
            </span>
        </div>

        <div class="col" *ngIf="valueBridgeLabel == 'ADVANCED_NAV_BRIDGE'" style="padding-left: 0;">
            <span class="header">
                {{getLabelsForValueBridgeType(valueBridgeLabel)}} {{ translateService.getLabel("adv_nav_bridge") }} ({{valueBridgeCurrency}})
                <i class="fas fa-info-circle" style="font-size: small;"
                    matTooltip="{{translateService.getLabel('nav_bridge_balance_sheet_custom_adjustments')}}"></i>
            </span>
        </div>
        


        <!-- <div class="col-2" style="text-align: center;">
            <button mat-raised-button class="primary-bg p-button"
                (click)="showValuationBridgeDataModal(updateValuationBridge)"
                [disabled]="!preparedValueBridge">
                Update
            </button>
        </div> -->

        <!-- <div class="col-1">
            <mat-checkbox *ngIf="multipleValueBridgeData && multipleValueBridgeData[0]"
                [(ngModel)]="multipleValueBridgeData[0].waterFallGraphShowLabels" 
                (change)="refreshWaterfallGraph(0)">
                Show Labels
            </mat-checkbox>
        </div> -->

        <!-- <div class="col-1" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <span class="right-align"><i class="fas fa-cog" style="cursor: pointer;" (click)="openSettingsOption()"></i></span>
        </div>

        <div class="col-1" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <button mat-raised-button class="primary-bg" (click) = "downloadValueBridgeExcel()" [class.disabled]="isVBExcelDownloading">Download</button>
        </div>
        <div class="col-1" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <button mat-raised-button class="primary-bg" (click) = "addValueBridge()">Add</button>
        </div> -->
        <div *ngIf="valueBridgeLabel == 'ADVANCED_NAV_BRIDGE'" class="col-2" style="text-align: end;font-size:14px; font-weight:bold;padding: 14px 0px 0 0;"><i class="fa fa-filter"  style="font-size:larger; padding-right: 5px;" aria-hidden="true"><span>Group Impact</span></i></div>
        <div class="col-3 selectValues" (focusout)='closeDropdown($event)' style="text-align: right; margin-top: 10px;" *ngIf = "valueBridgeLabel == 'ADVANCED_NAV_BRIDGE'&& multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <input class="drop-toggle" placeholder="Select Adjustments" [(ngModel)]="searchInput" 
                (ngModelChange)="searchFilter($event)" (click)="showDropdown = true" />
            <div class="drop-show" *ngIf="showDropdown" [ngClass]="{'no-show': algorithmFiltersForNAVBridge.length == 0}">
                <div style="max-height: 41vh !important; overflow-y: auto !important;">

                    <!-- valuation summary -->
                    <div class="select-box" *ngFor="let algo of algorithmFiltersForNAVBridge">
                        <mat-checkbox *ngIf="algo.filtered" class="box-margin" [checked]="algo.selected"
                            [indeterminate]="allChildTabsSelected(algo)" (change)="onChangeParent($event, algo)">
                            {{  translateService.getLabel(algo.name)}}
                        </mat-checkbox>
                        <ng-template [ngIf]="algo.children && algo.children.length > 0 && algo.filtered">
                            <i class="fas fa-chevron-up" style="padding-left: 20px; cursor: pointer;" *ngIf="algo.expanded"
                                (click)="algo.expanded = !algo.expanded"></i>
                            <i class="fas fa-chevron-down" style="padding-left: 20px; cursor: pointer" *ngIf="!algo.expanded"
                                (click)="algo.expanded = !algo.expanded"></i>

                            <!-- Algorithmn Summary -->
                            <div class="select-box" style="height: 2px; margin: 0; padding: 0;" *ngIf="algo.expanded"></div>
                            <ng-template [ngIf]="algo.expanded">
                                <div class="select-box" *ngFor="let section of algo.children">
                                    <mat-checkbox class="box-margin" *ngIf="section.filtered" style="padding-left: 30px;"
                                        [(ngModel)]="section.selected" [indeterminate]="allChildTabsSelected(section)"
                                        (change)="onChangeChildrentab($event, section)">
                                        {{ translateService.getLabel(utilService.getTranslationAlgoName(section.name)) }} 
                                        <!-- {{ }} -->
                                    </mat-checkbox>
                                    <ng-template [ngIf]="section.children && section.children.length > 0 && section.filtered">
                                        <i class="fas fa-chevron-up" style="padding-left: 20px; cursor: pointer;" *ngIf="section.expanded"
                                            (click)="section.expanded = !section.expanded"></i>
                                        <i class="fas fa-chevron-down" style="padding-left: 20px; cursor: pointer;" *ngIf="!section.expanded"
                                            (click)="section.expanded = !section.expanded"></i>
                                    </ng-template>

                                    <!-- impacts -->
                                    <div class="select-box" style="height: 2px; margin: 0; padding: 0;" *ngIf="section.expanded"></div>
                                    <ng-template [ngIf]="section.expanded">
                                        <div class="select-box" *ngFor="let adj of section.children">
                                            <mat-checkbox class="box-margin adjustment-box-icon" *ngIf="adj.filtered" style="padding-left: 60px;"
                                            [(ngModel)]="adj.selected" [indeterminate]="allChildTabsSelected(adj)"
                                            (change)="onChangeChildrenSubtab($event, adj, section)">
                                                {{ adj.name }}
                                            </mat-checkbox>

                                            <ng-template [ngIf]="adj.children && adj.children.length > 0 && adj.filtered">
                                                <i class="fas fa-chevron-up" style="padding-left: 20px; cursor: pointer;" *ngIf="adj.expanded"
                                                    (click)="adj.expanded = !adj.expanded"></i>
                                                <i class="fas fa-chevron-down" style="padding-left: 20px; cursor: pointer;" *ngIf="!adj.expanded"
                                                    (click)="adj.expanded = !adj.expanded"></i>
                                            </ng-template>

                                            <!-- impact items -->
                                            <div class="select-box" style="height: 2px; margin: 0; padding: 0;" *ngIf="adj.expanded"></div>
                                                <ng-template [ngIf]="adj.expanded">
                                                    <div class="select-box" *ngFor="let subChild of adj.children">

                                                        <mat-checkbox class="box-margin" *ngIf="subChild.filtered" style="padding-left: 90px;"
                                                            [(ngModel)]="subChild.selected" [indeterminate]="allChildTabsSelected(subChild)"
                                                            (change)="onChangeChildrenSubtab($event, subChild)">
                                                            {{ translateService.getLabel(subChild.name) }}
                                                        </mat-checkbox>
                                                    </div>
                                                </ng-template>
                                            </div>
                                    </ng-template>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
                <div style="text-align: right;">
                    <button type="button" style="line-height: 6px; margin: 5px;" class="btn-custom-primary"
                        (click)="prepareUpdatedNAVBridgeOnApplyingFilters(0); showDropdown = false;">
                        {{translateService.getLabel("save")}}
                    </button>
                </div>
            </div>
        </div>



        <div [ngClass]="{'col-1' : valueBridgeLabel == 'ADVANCED_NAV_BRIDGE', 'col' : valueBridgeLabel != 'ADVANCED_NAV_BRIDGE'}" 
        [style]="valueBridgeLabel == 'ADVANCED_NAV_BRIDGE'? 'max-width:20px; text-align: right; margin-top: 10px;':'text-align: right; margin-top: 10px;' "
         *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <span ngbDropdown class="d-inline-block" placement="bottom-right">
                <span id="toggleMenu" ngbDropdownToggle>
                  <i class="fas fa-ellipsis-v ellipsis-icon" style="font-size: large;"></i>
                </span>
                <div ngbDropdownMenu aria-labelledby="toggleMenu">
                    
                    <button ngbDropdownItem class="btn" >
                        <mat-checkbox class="checkbox-alignment" [(ngModel)]="multipleValueBridgeData[0].waterFallGraphShowLabels" (change)="refreshWaterfallGraph(0)">
                            {{ translateService.getLabel("show_labels") }}
                        </mat-checkbox>
                    </button>

                    <button ngbDropdownItem class="btn" (click)="changeCurrencyForValueBridge()">
                        {{ translateService.getLabel("show_in") }} {{valueBridgeCurrencyMenuLabel}} {{ translateService.getLabel("currency") }} 
                    </button>
                
                    <button ngbDropdownItem class="btn" *ngIf = "!ums.currentUserCompanyPermissionReadOnly" 
                    (click)="openSettingsOption()">
                        {{ translateService.getLabel("settings") }} 
                    </button>
                
                    <button ngbDropdownItem class="btn" (click)="downloadValueBridgeExcel()" [class.disabled]="isVBExcelDownloading">
                        {{ translateService.getLabel("download") }} 
                    </button>
                
                    <button ngbDropdownItem class="btn" *ngIf = "!ums.currentUserCompanyPermissionReadOnly" 
                    (click)="addValueBridge()">
                        {{ translateService.getLabel("add") }} 
                    </button>
                
                </div>
            </span>
        </div>
    </div>

    <br>

    <!-- MASTER Value Bridge -->
    <div class="row" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
        <div class="col">
            <div class="m-auto" *ngIf="preparedValueBridge" [chart]="multipleValueBridgeData[0].waterFallChartData"></div>
            
            <div *ngIf="!preparedValueBridge" id="loader-container">
                <div id="loader"></div>
                <div>
                    {{ valuationAlgorithms.processingWaterFallMsg}}
                </div>
            </div>
        </div>
        <ng-template [ngIf] = "savedData && savedData.comments && valueBridgeLabel != 'ADVANCED_NAV_BRIDGE'">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <mat-form-field style="width: 100%" class="text-area"> 
                            <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                            [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[0].title"  style="margin: 0px;"> 
                            <span matSuffix>                                              
                                <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                            </span>
                            
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <textarea matInput rows="9" [(ngModel)]="savedData.comments[0].comment" name="impact" 
                            [disabled] = "ums.currentUserCompanyPermissionReadOnly">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                            [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[1].title"  style="margin: 0px;"> 
                            <span matSuffix>                                              
                                <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                            </span>
                            
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <textarea matInput rows="9" [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[1].comment" name="impact">
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col">
                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                            [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[2].title"  style="margin: 0px;"> 
                            <span matSuffix>                                              
                                <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                            </span>
                            
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <textarea matInput rows="9" [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[2].comment" name="impact" >
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text"
                            [disabled] = "ums.currentUserCompanyPermissionReadOnly" 
                            [(ngModel)]="savedData.comments[3].title"  style="margin: 0px;"> 
                            <span matSuffix>                                              
                                <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                            </span>
                            
                        </mat-form-field>

                        <mat-form-field style="width: 100%;" class="text-area"> 
                            <textarea matInput rows="9" [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                            [(ngModel)]="savedData.comments[3].comment" name="impact" >
                            </textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <br>


    <!-- User Saved NAV Bridges in Loop except the Master VB -->
    <ng-template [ngIf] = "multipleValueBridgeData && multipleValueBridgeData.length > 1">
        <ng-template ngFor [ngForOf] = "multipleValueBridgeData" let-valueBridge let-rowIndex = "index">
            <mat-expansion-panel style="margin: 20px; background-color:#FFFFFF" *ngIf = "rowIndex > 0">
                <mat-expansion-panel-header>
                 <mat-panel-title>
                    <b>{{ valueBridge.name | titlecase }}</b>
                    <i class="fa fa-trash action-icon primary-color-medium delete-icon" style="position: absolute; right: 5%; 
                    margin: 5px auto; font-size: 18px; cursor: pointer;" (click)="deleteMultiplValueBridgeData(rowIndex)"
                    *ngIf = "!ums.currentUserCompanyPermissionReadOnly">
                    </i>
                 </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-2"></div>
                    <div class="col-1" *ngIf = "!ums.currentUserCompanyPermissionReadOnly">
                        <button mat-raised-button class="primary-bg p-button"
                        (click)="showValuationBridgeDataModal(updateValuationBridge, valueBridge, rowIndex)"
                        [disabled]="!preparedValueBridge">
                            {{ translateService.getLabel("update") }}
                        </button>
                    </div>
                    <div class="col-1">
                        <mat-checkbox [(ngModel)]="valueBridge.waterFallGraphShowLabels" (change)="refreshWaterfallGraph(rowIndex)">{{ translateService.getLabel("show_labels") }}</mat-checkbox>
                    </div>
                    <div class="col"></div>

                </div>
                <div class="row" style="background-color: #FFFFFF; margin-top: 15px;">
                    <div class="col">
                        <div class="m-auto" *ngIf="preparedValueBridge" [chart]="valueBridge.waterFallChartData"></div>
                        <div *ngIf="!preparedValueBridge" id="loader-container">
                            <div id="loader"></div>
                            <div>
                                {{ valuationAlgorithms.processingWaterFallMsg}}
                            </div>
                        </div>
                    </div>
                    <div class="col" *ngIf = "valueBridgeLabel != 'ADVANCED_NAV_BRIDGE'">
                        <div class="row">
                            <div class="col">
                                <mat-form-field style="width: 100%" class="text-area"> 
                                    <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                                    [(ngModel)]="valueBridge.comments[0].title" 
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 0, 'title', valueBridge.valueBridgeId)" 
                                    style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[0].comment" 
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 0, 'comment', valueBridge.valueBridgeId)" 
                                    name="impact" >
                                    </textarea> 
                                </mat-form-field>
                            </div>
        
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                                    [(ngModel)]="valueBridge.comments[1].title" [disabled] = "ums.currentUserCompanyPermissionReadOnly" 
                                    (ngModelChange) = "assignValueBridgeComments($event, 1, 'title', valueBridge.valueBridgeId)" style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[1].comment" 
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 1, 'comment', valueBridge.valueBridgeId)" name="impact">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                                    [(ngModel)]="valueBridge.comments[2].title" [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 2, 'title', valueBridge.valueBridgeId)" 
                                    style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[2].comment" 
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 2, 'comment', valueBridge.valueBridgeId)" name="impact" >
                                    </textarea>
                                </mat-form-field>
                            </div>
        
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="{{ translateService.getLabel('placeholder') }}" type="text" 
                                    [(ngModel)]="valueBridge.comments[3].title" [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (ngModelChange) = "assignValueBridgeComments($event, 3, 'title', valueBridge.valueBridgeId)" 
                                    style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[3].comment"
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly" 
                                    (ngModelChange) = "assignValueBridgeComments($event, 3, 'comment', valueBridge.valueBridgeId)"name="impact" >
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>  
            </mat-expansion-panel>
        </ng-template>
    </ng-template>

    <br/>
    <!-- Track Record -->
    <div class="row" id="is_TrackRecord">
        <div class="col" style="padding-left: 0;">
            <span class="header">{{ translateService.getLabel("track_record") }}</span>
        </div>
        <div class="col-1" *ngIf="!ums.currentUserCompanyPermissionReadOnly && trackRecord && trackRecord.length > 0" 
            style="margin-top:10px">
            <button mat-raised-button class="primary-bg floating-dates" (click)="showAllDatesInTrackRecord()"> 
                {{ translateService.getLabel("show_all") }} 
            </button>
        </div>
        <div class="col" *ngIf="trackRecord && trackRecord.length > 0" style="max-width: 60px;" matTooltip='{{translateService.getLabel("download_track_record_table")}}'>
            <button mat-icon-button class="primary-color" (click)="downloadTrackRecordExcel()">
                <mat-icon>download</mat-icon>
            </button>
        </div>
        <div class="col" style="max-width: 60px;" *ngIf = "!ums.currentUserCompanyPermissionReadOnly">
            <!-- <span><i class="fas fa-cog" style="cursor: pointer;" (click)="openSettingsOption()"></i></span> -->
            <button mat-icon-button class="primary-color"
            (click)="openSettingsOption()">
                <mat-icon>settings</mat-icon>
            </button>
        </div>

        <div class="col" *ngIf="!ums.currentUserCompanyPermissionReadOnly && trackRecord && trackRecord.length > 0" 
            style="max-width: 60px" [matTooltip]="translateService.getLabel('show_hide_track_record_rows')">
            
            <app-track-record-preferences [trackRecord]="trackRecord" 
                [metricOneKey]="getTrackRecordMetric()?.firstMetric" 
                [metricOneLabel]="getValueBridgeMetricLabel(getTrackRecordMetric()?.firstMetric)"
                [metricTwoKey]="getTrackRecordMetric()?.secondMetric" 
                [metricTwoLabel]="getValueBridgeMetricLabel(getTrackRecordMetric()?.secondMetric)" 
                [metricThreeKey]="getTrackRecordMetric()?.thirdMetric" 
                [metricThreeLabel]="getValueBridgeMetricLabel(getTrackRecordMetric()?.thirdMetric)"
                
                [primaryPeriodLabel]="getSelectedPeriodLabel(getTrackRecordMetric()?.firstPeriod)"
                [secondaryPeriodLabel]="getSelectedPeriodLabel(getTrackRecordMetric()?.secondaryPeriod)" 
                [tertiaryPeriodLabel]="getSelectedPeriodLabel(getTrackRecordMetric()?.tertiaryPeriod)"

                [settings] = "settings">
            </app-track-record-preferences>
        </div>
    </div>

    <div class="widget" style="margin: 15px; padding: 15px;"
        *ngIf="trackRecord && trackRecord.length > 0">

        <table style="width: 98%;" class="dr-table">
            <thead>
                <tr class="tr-valtabhead dr-table-header">
                    <th style="padding: 10px; padding-left: 25px;">{{ translateService.getLabel("in") }}  {{ latestValDateTrackRecord.currency }} Mn</th> &nbsp;
                    <th style="padding: 10px; text-align: right;" *ngFor="let valuation of trackRecord">
                        {{valuation.valuationDate | date: 'MMM d, y'}}
                    </th>
                </tr>
            </thead>

            <!-- first Metric -->
            <tr class="tr-valtab" *ngIf="trService.preferences[getTrackRecordMetric()?.firstMetric + '_primary']">
                <td style="padding-left: 25px;">
                    {{getValueBridgeMetricLabel(getTrackRecordMetric()?.firstMetric)}} 
                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.firstPeriod)}})
                </td> &nbsp;

                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                <app-data-drill-down-menu
                    [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod, 'adjustedMetric')"
                    [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'" [onlyPositiveAllowed]="false"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('data')" [typeOfSource]="'User Entry'"
                    [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod)" [formula]="getFormulaForTrackRecordItems('')"
                    [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId" [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            <!-- first Metric code ends  -->

            <!-- Second Metric  -->
            <tr class="tr-valtab" *ngIf="trService.preferences[getTrackRecordMetric()?.secondMetric + '_secondary']">
                <td style="padding-left: 25px;">
                    {{getValueBridgeMetricLabel(getTrackRecordMetric()?.secondMetric)}}

                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.secondaryPeriod)}})
                </td> &nbsp;
            
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <!-- {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[1], settings.period, 'adjustedMetric')}} -->
                   
                    <span *ngIf="this.settings.secondaryMetric == getTrackRecordMetric()?.secondMetric">
                        <app-data-drill-down-menu
                            [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'adjustedMetric')"
                            [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'" [onlyPositiveAllowed]="false"
                            [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('data')" [typeOfSource]="'User Entry'"
                            [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod)" [formula]="getFormulaForTrackRecordItems('')"
                            [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                    </span>
                    <span *ngIf="this.settings.secondaryMetric != getTrackRecordMetric()?.secondMetric">
                        <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'adjustedMetric')" 
                            [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'" [onlyPositiveAllowed]="false"
                            [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('data')" [typeOfSource]="'User Entry'"
                            [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod)" [formula]="getFormulaForTrackRecordItems('')"
                            [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                    </span>
                </td>
            </tr>
            <!-- Second Metric code ends -->

            <!-- Third  Metric code -->
            <tr class="tr-valtab"  *ngIf="getTrackRecordMetric()?.thirdMetric && trService.preferences[getTrackRecordMetric()?.thirdMetric + '_tertiary']">
                <td style="padding-left: 25px;">
                    {{getValueBridgeMetricLabel(getTrackRecordMetric()?.thirdMetric)}} 

                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.tertiaryPeriod)}})                    
                </td> &nbsp;

                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod, 'adjustedMetric')" 
                        [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'" [onlyPositiveAllowed]="false"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('data')" [typeOfSource]="'User Entry'"
                        [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod)" [formula]="getFormulaForTrackRecordItems('')"
                        [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            <!-- Third Metric code ends -->

            <!-- first Metric Multiples -->
            <tr class="tr-valtab" *ngIf="trService.preferences[getTrackRecordMetric()?.firstMetric + 'Multiple' + '_primary']">
                <td style="padding-left: 25px;">
                    {{getMultipleLabel(getTrackRecordMetric()?.firstMetric)}}
                    
                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.firstPeriod)}})
                </td> &nbsp;
                
                
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod, 'impliedMultiple')" 
                        [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NMF'" [onlyPositiveAllowed]="true"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('implied_multiple')" [typeOfSource]="'Calculated'"
                        [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod, true)" [formula]="getFormulaForMultiple(getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod)"
                        [underlyingMetrics]="getUnderlyingMetricsForMultiple(valuation, getTrackRecordMetric()?.firstMetric, getTrackRecordMetric()?.firstPeriod, 'adjustedMetric')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            <!--first Metric Multiples ends -->

            <!-- Second Metric Multiples -->
            <tr class="tr-valtab" *ngIf="trService.preferences[getTrackRecordMetric()?.secondMetric + 'Multiple' + '_secondary']">
                <!-- <td style="padding-left: 25px;">{{getSelectedTRMultipleLabel(savedData.trackRecordPreference.selectedMetrics[1])}} Multiple</td> &nbsp; -->
                <td style="padding-left: 25px;">
                    {{getMultipleLabel(getTrackRecordMetric()?.secondMetric)}}

                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.secondaryPeriod)}})
                </td> &nbsp; 

                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <!-- {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[1], settings.period, 'impliedMultiple')}} -->

                    <span *ngIf="this.settings.secondaryMetric == getTrackRecordMetric()?.secondMetric">
                        <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'impliedMultiple')" 
                            [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NMF'" [onlyPositiveAllowed]="true"
                            [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('implied_multiple')" [typeOfSource]="'Calculated'"
                            [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, true)" [formula]="getFormulaForMultiple(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod)"
                            [underlyingMetrics]="getUnderlyingMetricsForMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'adjustedMetric')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                    </span>
                    <span *ngIf="this.settings.secondaryMetric != getTrackRecordMetric()?.secondMetric">
                        <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'impliedMultiple')" 
                            [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NMF'" [onlyPositiveAllowed]="true"
                            [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('implied_multiple')" [typeOfSource]="'Calculated'"
                            [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, true)" [formula]="getFormulaForMultiple(getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod)"
                            [underlyingMetrics]="getUnderlyingMetricsForMultiple(valuation, getTrackRecordMetric()?.secondMetric, getTrackRecordMetric()?.secondaryPeriod, 'adjustedMetric')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                    </span>
                </td>
            </tr>
            <!--Second Metric Multiples code ends  -->

             <!-- Third Metric Multiples -->
             <tr class="tr-valtab" *ngIf="getTrackRecordMetric()?.thirdMetric && trService.preferences[getTrackRecordMetric()?.thirdMetric + 'Multiple' + '_tertiary']">
                <td style="padding-left: 25px;">
                    {{getMultipleLabel(getTrackRecordMetric()?.thirdMetric)}}

                    ({{getSelectedPeriodLabel(getTrackRecordMetric()?.tertiaryPeriod)}})
                </td> &nbsp; 
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                   <app-data-drill-down-menu [value]="getSelectedTRMultiple(valuation, getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod, 'impliedMultiple')" 
                        [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NMF'" [onlyPositiveAllowed]="true"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [source]="getSourceForDrillDown('implied_multiple')" [typeOfSource]="'Calculated'"
                        [label]="getLabelForMeticsInDrillDown(getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod, true)" [formula]="getFormulaForMultiple(getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod)"
                        [underlyingMetrics]="getUnderlyingMetricsForMultiple(valuation, getTrackRecordMetric()?.thirdMetric, getTrackRecordMetric()?.tertiaryPeriod, 'adjustedMetric')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            <!-- Third Metric Multiples code ends -->

            <tr class="tr-valtab" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;" 
            *ngIf="trService.preferences['enterpriseValue']">

                <td style="padding-left: 25px;">{{ translateService.getLabel("enterprise_value") }}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.enterpriseValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                        [label]="translateService.getLabel('enterprise_value')" [formula]="getFormulaForEnterpriseValue(valuation)" [source]="getSourceForDrillDown('valuation_summary', 'enterprise_value', 'weighted_implied_fair_enterprise_value')"
                        [underlyingMetrics]="getUnderlyingMetricsForEnterpriseValue(valuation)" [valDateId]="valuation.formId" [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['netDebt']">
                <td style="padding-left: 25px;">{{ translateService.getLabel("netDebt_and_other_balanceSheet_adjustments") }}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.netDebt" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                        [label]="translateService.getLabel('netDebt_and_other_balanceSheet_adjustments')" [formula]="getFormulaForTrackRecordItems('')" [source]="getSourceForDrillDown('data', 'rounding_off_valuation_summary','',valuation,'netDebt')"
                        [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency">
                    </app-data-drill-down-menu>
                </td>
            </tr>

            <!-- <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">(Net Debt) / Net cash</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <ng-template [ngIf]="valuation.cash > 0">
                        ({{ (valuation.debt / valuation.cash ) | number: '1.1-1'}})
                    </ng-template>

                    <ng-template [ngIf]="valuation.cash <= 0">NA</ng-template>
                </td>
            </tr> -->

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['equityValue']">
                <td style="padding-left: 25px;">{{ translateService.getLabel("equity_value") }} (100%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.equityValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                        [label]="translateService.getLabel('equity_value') + ' (100%)'" [formula]="getFormulaForTrackRecordItems('equityValue')"
                        [underlyingMetrics]="getUnderlyingMetrics('equityValue', valuation)" [source]="getSourceForDrillDown('valuation_summary', 'equity_value')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['adjustmentsToEquityValue']">
                <td style="padding-left: 25px;">{{ translateService.getLabel("adjustments_to_equity_value") }}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.adjustmentsToEquityValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                        [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''"
                        [label]="translateService.getLabel('adjustments_to_equity_value')"
                        [formula]="getFormulaForTrackRecordItems('')"
                        [underlyingMetrics]="getUnderlyingMetrics('')" [typeOfSource]="'Calculated'"
                        [source]="getSourceForDrillDown('data', 'rounding_off_valuation_summary', '', valuation, 'adjustmentsToEquityValue')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>
            
            <tr class="tr-valtabhead" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['adjustedEquityValue']">
                <th style="padding-left: 25px;">{{ translateService.getLabel("adjusted_equity_value") }} (100%)</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.adjustedConcludedEquityValueInValuationSummary" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('adjusted_equity_value') + ' (100%)'" [formula]="getFormulaForTrackRecordItems('adjustedEquityValue')" [source]="getSourceForDrillDown('valuation_summary', 'adjusted_equity_value')"
                    [underlyingMetrics]="getUnderlyingMetrics('adjustedEquityValue', valuation)" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </th>
            </tr>

            <tr class="tr-valtabhead" style="border-left: 1px solid; border-right: 1px solid;"
                *ngIf="trService.preferences['adjustedEquityValueWaterfall']">
                <th style="padding-left: 25px;">{{ translateService.getLabel("adjusted_equity_value_waterfallInputs") }}</th>
                &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.adjustedConcludedEquityValueInWaterfall" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('adjusted_equity_value_waterfallInputs')" [formula]="getFormulaForTrackRecordItems('')" [source]="getSourceForDrillDown('valuation_summary')"
                    [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </th>
            </tr>
            
            
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['stake']">
                <td style="padding-left: 25px;">{{ translateService.getLabel("stake") }} (%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.stake" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('stake') + ' (%)'" [formula]="getFormulaForTrackRecordItems('')" [source]="getSourceForStakeInDrillDown(valuation.selectedTypeOfStakeValue)"
                    [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>            
            
            <tr class="tr-valtabhead" style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;"
            *ngIf="trService.preferences['stakeValue']">

                <th style="padding-left: 25px;">{{ translateService.getLabel("stake_equity_value") }}</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.stakeValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('stake_equity_value')" [formula]="getFormulaForTrackRecordItems('stakeValue')"
                    [underlyingMetrics]="getUnderlyingMetrics('stakeValue', valuation)" [source]="getSourceForDrillDown('valuation_summary', 'concluded_adjusted_fair_value_of_equity')"
                    [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </th>
            </tr>
            

            <!-- Empty Row -->
            <tr class="tr-valtabhead">
                <th style="padding-left: 25px;"></th> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                   
                </td>
            </tr>

            <tr class="tr-valtab" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;"
            *ngIf="trService.preferences['debtAndOthers']">

                <!-- <td style="padding-left: 25px;">{{ translateService.getLabel("debt_others") }}</td> &nbsp; -->
                <td style="padding-left: 25px;">{{ translateService.getLabel("adjustment_to_stake_equity_value") }}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.stakeEquityAdjustments" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="0"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('adjustment_to_stake_equity_value')" [formula]="getFormulaForTrackRecordItems('')" [source]="getSourceForDrillDown('data','rounding_off_valuation_summary', '', valuation,'stakeEquityAdjustments')"
                    [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </td>
            </tr>

            <tr class="tr-valtabhead" style="border-left: 1px solid; border-right: 1px solid;"
            *ngIf="trService.preferences['value']">

                <th style="padding-left: 25px;">{{ translateService.getLabel("nav") }}</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.stakeAdjustedEquityValue" [decimalPoint]="1" [suffix]="''" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                    [label]="translateService.getLabel('nav')" [formula]="getFormulaForTrackRecordItems('stakeAdjustedEquityValue')"
                    [underlyingMetrics]="getUnderlyingMetrics('stakeAdjustedEquityValue', valuation)" [source]="getSourceForDrillDown('valuation_summary', 'fair_value_of_investment')" [valDateId]="valuation.formId" [currency] = "valuation.currency"></app-data-drill-down-menu>
                </th>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;"
            *ngIf="trService.preferences['investedAmount']">

                <td style="padding-left: 25px;">{{ translateService.getLabel("invested_amount") }} </td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <ng-template [ngIf]="valuation.investmentAmountTrans.length > 0" [ngIfElse]="notApplicable">
                        <app-data-drill-down-menu [value]="valuation.investmentAmount" [decimalPoint]="1" [suffix]="''"
                            [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)"
                            [onlyPositiveAllowed]="''" [typeOfSource]="'User Entry'" [label]="translateService.getLabel('invested_amount')"
                            [formula]="getFormulaForTrackRecordItems('invested_amount')" [source]="getSourceForDrillDown('transactions')"
                            [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"
                            [currency]="valuation.currency"></app-data-drill-down-menu>
                    </ng-template>
                    <ng-template #notApplicable>
                        NA
                    </ng-template>
                </td>
            </tr>
            
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['realisedProceeds']">
                <td style="padding-left: 25px;">{{ translateService.getLabel("realised_proceeds") }} </td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <ng-template [ngIf]="valuation.realisedProceedsTrans.length > 0" [ngIfElse]="notApplicableProceeds">
                        <app-data-drill-down-menu [value]="valuation.realisedProceeds" [decimalPoint]="1" [suffix]="''"
                            [valueInPlaceOfZero]="0.0" [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)"
                            [onlyPositiveAllowed]="''" [typeOfSource]="'User Entry'"
                            [label]="translateService.getLabel('realised_proceeds')"
                            [formula]="getFormulaForTrackRecordItems('realised_proceeds')" [source]="getSourceForDrillDown('transactions')"
                            [underlyingMetrics]="getUnderlyingMetrics('')" [valDateId]="valuation.formId"
                            [currency]="valuation.currency"></app-data-drill-down-menu>
                    </ng-template>
                    <ng-template #notApplicableProceeds>
                        NA
                    </ng-template>
                </td>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" *ngIf="trService.preferences['investmentMultiple']">
                <th style="padding-left: 25px;"> {{translateService.getLabel("moic")}} </th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <app-data-drill-down-menu [value]="valuation.moic" [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NA'"
                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''"
                    [label]="translateService.getLabel('moic')" [formula]="getFormulaForTrackRecordItems('moic')" [typeOfSource]="'Calculated'"
                    [underlyingMetrics]="getUnderlyingMetrics('moic', valuation)" [source]="getSourceForDrillDown('transactions')" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                </th>
            </tr>

            <tr class="tr-valtabhead" *ngIf="!isEZ && trService.preferences['dpi']" style="border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">{{ translateService.getLabel("dpi") }}</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <ng-template [ngIf]="valuation.investmentAmountTrans.length > 0" [ngIfElse]="notApplicableDPI">
                        <app-data-drill-down-menu [value]="valuation.dpi" [decimalPoint]="1" [suffix]="'x'" [valueInPlaceOfZero]="'NA'"
                            [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''"
                            [label]="translateService.getLabel('dpi')" [formula]="getFormulaForTrackRecordItems('dpi')"
                            [typeOfSource]="'Calculated'" [underlyingMetrics]="getUnderlyingMetrics('dpi', valuation)"
                            [source]="getSourceForDrillDown('valuation_summary')" [valDateId]="valuation.formId"
                            [currency]="valuation.currency"></app-data-drill-down-menu>
                    </ng-template>
                    <ng-template #notApplicableDPI>
                        NA
                    </ng-template>
                </th>
            </tr>

            <tr class="tr-valtab" *ngIf="trService.preferences['irr_YTD']" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">{{translateService.getLabel("ytd_irr")}} (%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <span> 
                        <ng-template [ngIf]="valuation.grossIRR_YTD" [ngIfElse]="notApplicableYTDIRR">
                            <ng-template [ngIf]="!valuation.grossIRR_YTD">
                                <app-data-drill-down-menu [value]="valuation.grossIRR_YTD" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0 %'"
                                [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                [label]="translateService.getLabel('ytd_irr')" [formula]="getFormulaForTrackRecordItems('IRR_YTD')" [source]="getSourceForDrillDown('valuation','transactions')"
                                [underlyingMetrics]="getUnderlyingMetrics('ytd_irr', valuation)" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                            </ng-template>
    
                            <ng-template [ngIf]="valuation.grossIRR_YTD > 0">
                                <app-data-drill-down-menu [value]="valuation.grossIRR_YTD" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0 %'"
                                [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                [label]="translateService.getLabel('ytd_irr')" [formula]="getFormulaForTrackRecordItems('IRR_YTD')" [source]="getSourceForDrillDown('valuation','transactions')"
                                [underlyingMetrics]="getUnderlyingMetrics('ytd_irr', valuation)" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                            </ng-template>
                    
                            <ng-template [ngIf]="valuation.grossIRR_YTD < 0">
                                <span style="color:red">
                                    <app-data-drill-down-menu [value]="valuation.grossIRR_YTD" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0'"
                                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('ytd_irr')" [formula]="getFormulaForTrackRecordItems('IRR_YTD')" [source]="getSourceForDrillDown('valuation','transactions')"
                                    [underlyingMetrics]="getUnderlyingMetrics('ytd_irr', valuation)" [displayNegativeNo]="true" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                                </span>
                            </ng-template>
                        </ng-template>
                        <ng-template #notApplicableYTDIRR>
                            NA
                        </ng-template>
                    </span>
                </td>
            </tr>

            <tr class=" tr-valtabhead tr-bottom-row" *ngIf="trService.preferences['irr']" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">{{translateService.getLabel("IRR")}} (%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    <span> 
                        <ng-template [ngIf]="valuation.investmentAmountTrans.length > 0" [ngIfElse]="notApplicableIRR">
                            <ng-template [ngIf]="!valuation.grossIRR">
                                <app-data-drill-down-menu [value]="valuation.grossIRR" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0 %'"
                                [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [source]="getSourceForDrillDown('valuation','transactions')"
                                [underlyingMetrics]="getUnderlyingMetrics('IRR', valuation)" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                            </ng-template>
    
                            <ng-template [ngIf]="valuation.grossIRR > 0">
                                <app-data-drill-down-menu [value]="valuation.grossIRR" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0 %'"
                                [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [source]="getSourceForDrillDown('valuation','transactions')"
                                [underlyingMetrics]="getUnderlyingMetrics('IRR', valuation)" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                            </ng-template>
                    
                            <ng-template [ngIf]="valuation.grossIRR < 0">
                                <span style="color:red">
                                    <app-data-drill-down-menu [value]="valuation.grossIRR" [decimalPoint]="1" [suffix]="'%'" [valueInPlaceOfZero]="'0.0'"
                                    [valuationDate]="datePipe.transform(valuation.valuationDate, mediumDate)" [onlyPositiveAllowed]="''" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('IRR')" [formula]="getFormulaForTrackRecordItems('IRR')" [source]="getSourceForDrillDown('valuation','transactions')"
                                    [underlyingMetrics]="getUnderlyingMetrics('IRR', valuation)" [displayNegativeNo]="true" [valDateId]="valuation.formId"  [currency] = "valuation.currency"></app-data-drill-down-menu>
                                </span>
                            </ng-template>
                        </ng-template>
                        <ng-template #notApplicableIRR>
                            NA
                        </ng-template>
                    </span>
                </td>
            </tr>

            <!-- Empty Row -->
            <tr class="tr-valtabhead">
                <th style="padding-left: 25px;"></th> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                   
                </td>
            </tr>

            <ng-template [ngIf]="getCustomAttributesStatus()" >
                <tr class="tr-valtabhead tr-top-row">
                    <td style="padding-left: 25px;">{{ translateService.getLabel("custom_attributes") }}</td> &nbsp;
                    <td style="text-align: right;" *ngFor="let valuation of trackRecord">
            
                    </td>
                </tr>
            
            
                <ng-template ngFor [ngForOf]="uniqueAttributesList" let-item let-index="index">
                    <tr class="tr-valtab tr-middle-row" *ngIf="trService.preferences[item.attributeKey]">
            
                        <td style="padding-left: 25px;">{{item.attributeName}}</td> &nbsp;
            
                        <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                            <span> 
                                <ng-template
                                    [ngIf]="attributesData[valuation.formId] && attributesData[valuation.formId][item.attributeKey]"
                                    [ngIfElse]="elseBlock">
             
                                    <ng-template [ngIf]="attributesData[valuation.formId][item.attributeKey].value"
                                        [ngIfElse]="elseSide">
                                        <!-- {{attributesData[valuation.formId][item.attributeKey].value}} -->
                                        <ng-template [ngIf]="item.attributeKey != 'no_of_employees' && item.attributeKey != 'key_people' && item.attributeKey != 'partners'" [ngIfElse]="elseSide">
                                             <app-data-drill-down-menu [value]="attributesData[valuation.formId][item.attributeKey].value" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)"
                                             textField="true" [label]="item.attributeName"
                                             [formula]="getFormulaForTrackRecordItems('NA')" [underlyingMetrics]="getUnderlyingMetrics('')" [typeOfSource]="'Calculated'"
                                             [source]="getSourceForDrillDown('User Entry')" [valDateId]="latestValDateTrackRecord.formId" [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                         </ng-template>
                                         <ng-template #elseSide>
                                             <app-data-drill-down-menu [value]="attributesData[valuation.formId][item.attributeKey].value" [decimalPoint]="1" [suffix]="''"
                                             [valueInPlaceOfZero]="'-'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                             [onlyPositiveAllowed]="true" [label]="item.attributeName" [formula]="getFormulaForTrackRecordItems('')" [underlyingMetrics]="getUnderlyingMetrics('')"
                                             [source]="getSourceForDrillDown('User Entry')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                         </ng-template>
                                    </ng-template>
                                    <ng-template #elseSide> - </ng-template>
                                </ng-template>
                                <ng-template #elseBlock> - </ng-template>
                            </span>
                        </td>
                    </tr>
                </ng-template>
            
                <!-- Empty Row -->
                <tr class="tr-valtabhead tr-bottom-row">
                    <td style="padding-left: 25px;"></td> &nbsp;
                    <td style="text-align: right;" *ngFor="let valuation of trackRecord">
            
                    </td>
                </tr>
            </ng-template>
            <!-- Empty Row -->
            <!-- <tr class="tr-valtabhead">
                <th style="padding-left: 25px;"></th> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                   
                </td>
            </tr>

            <tr class="tr-valtabhead" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Value LPs</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    {{valuation.stakeValueLP | number: '1.1-1'}}
                </th>
            </tr>
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Proceeds LPs</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    {{valuation.realisedProceedsLP | number: '1.1-1'}}
                </td>
            </tr>
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Invested Amount LPs</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                    {{valuation.investmentAmountLP | number: '1.1-1'}}
                </td>
            </tr>
            <tr class="tr-valtabhead" style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Investment Multiple LPs</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of trackRecord">
                    {{valuation.moicLP | number: '1.1-1'}}x
                </th>
            </tr> -->

            <!-- <tr>
                    <th style="padding-left: 25px;"><i>investment Amount {{fundListService.getFundName(fundId)}}</i></th> &nbsp;
                       <td style="text-align: right;" *ngFor="let valuation of trackRecord">
                          {{valuation.valuationDate | date: 'MMM d, y'}}
                      </td>
                 </tr> -->
        </table>
    </div>

    <br>
    <br>

    <div class="row">
        <span class="header">{{ translateService.getLabel("industry_and_company_performance") }} </span>
    </div>

    <br>

    <div class="row">
        <div class="col">
            <div class="widget" style="height: 410px;">
                <div class="row" style="margin: 5px 10px;">

                    <div class="col-3 topic margin-auto" style="padding: 0">{{ translateService.getLabel("multiple_evolution") }} 
                        <span  [matTooltip]="translateService.getLabel('download_multiple_evolution_chart')"> &nbsp;
                            <button style="margin-top: -10px;" mat-icon-button class="primary-color"
                            [disabled] = "isMultipleEvolutionChartExcelDownloading"
                            (click)="downloadMultipleEvolutionChart()">
                                <mat-icon>download</mat-icon>
                            </button>
                        </span>

                        <span *ngIf = "isMultipleEvolutionChartExcelDownloading">
                            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20"></mat-spinner>
                        </span>
                    </div>
                    <div class="col-2 margin-auto" style="padding: 0">
                        <ng-template
                            [ngIf]="selectedForm && selectedForm.businessUnits && selectedForm.businessUnits.length > 1">
                            <mat-form-field class="business-units">
                                <mat-select [(ngModel)]="buMarketMultiple" name="buMarketMultiple" width
                                    (ngModelChange)="fetchMarketEvolutionChartOnBUChange()">
                                    <mat-option [value]="bu.businessUnitName"
                                        *ngFor="let bu of selectedForm.businessUnits; let index=index">
                                        {{bu.companyNameInForm}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </div>

                    <div class="col-2 margin-auto align-right" style="padding: 0;">
                        {{ invSummaryService.getMarketMultipleLabelForEvolutionChartByKey(marketMultiple) }} ({{getSelectedPeriodLabel(settings.period)}})
                    </div>
                    
                    <div class="col-2 align-center margin-auto" style="padding: 0;">
                        <mat-checkbox [(ngModel)]="multipleChartShowLabels" (change)="createMarketEvolutionChart(marketMultiple)">{{ translateService.getLabel("show_labels") }}</mat-checkbox>
                    </div>
                    
                    <div class="col" style="padding: 0;" *ngIf="!areaChartLoader">
                        <div class="row">
                            <div *ngIf="marketDataForAdditionalDate.loading" style="text-align: center;">
                                <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20"></mat-spinner>
                            </div>
    
                            <div *ngIf="!ums.currentUserCompanyPermissionReadOnly && !marketDataForAdditionalDate.loading">
                                <mat-form-field class="evolution-chart-date" (click)="evolutionChartDate.open()">
                                    <input matInput [matDatepicker]="evolutionChartDate" readonly class="date-field" 
                                        [(ngModel)]="evolutionChartAdditionalDate"
                                        [min]="minForChartAdditionalDate"
                                        [max]="maxForChartAdditionalDate">
                                    <mat-datepicker-toggle matSuffix [for]="evolutionChartDate"></mat-datepicker-toggle>
                                    <mat-datepicker #evolutionChartDate class="datepicker"></mat-datepicker>
                                </mat-form-field>
                                
                                <!-- Disabling Show Button wehn Latest Val Date is beyond the current date 
                                    since market data cannot be fetched (Tristan's Requirement) -->
                                <button mat-raised-button style="float: right;" class="primary-bg" 
                                *ngIf = "!ums.currentUserCompanyPermissionReadOnly"
                                (click)="addDateToEvolutionChart()"
                                [ngClass]="{'decreaseOpacity': isLatestValDateGreaterThanCurrentDate}"
                                [disabled] = "isLatestValDateGreaterThanCurrentDate">
                                    {{ translateService.getLabel("show") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="m-auto" [chart]="areaChart" *ngIf="!areaChartLoader"></div>
                    <div *ngIf="areaChartLoader" id="loader-container">
                        <div id="loader"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="widget" style="height: 410px; padding: 10px;">
                <div class="row" style="font-size: 16px !important">
                    <div class="col spacing" style="background-color: #CBD4FF; height: 30px; font-weight: bold;">         
                        {{translateService.getLabel("kpi_implied_multiple_variations")}}
                    </div>
                </div>

                <div class="row" style="font-size: 13px !important">
                    <div class="col align-left margin-auto">
                        {{translateService.getLabel("vs_last_valuation")}}
                    </div>
                    <div class="col align-right">
                        <button class="btn-alignment" type="button">
                            <ng-template [ngIf] = "marketChartDataVariations.vsLastestValuation >= 0" [ngIfElse] = "negativevsLastestValuation">
                                <span style="color: green;">
                                    <!-- {{ utilService.getDisplayFormattedNumber(marketChartDataVariations.vsLastestValuation * 100, 1, '%', 'NA' ) }} -->
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsLastestValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('vs_last_valuation')" [formula]="getFormulaForTrackRecordItems('vsLastestValuation')" [underlyingMetrics]="getUnderlyingMetrics('vsLastestValuation')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId" [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>    
                                </span>
                            </ng-template>
                            <ng-template #negativevsLastestValuation>
                                <span style="color: red;" >
                                    <!-- {{ utilService.getNegativeNumberDisplayFormat(marketChartDataVariations.vsLastestValuation * 100, 1, '%', 'NA' ) }} -->
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsLastestValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [displayNegativeNo]="true" [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('vs_last_valuation')" [formula]="getFormulaForTrackRecordItems('vsLastestValuation')" [underlyingMetrics]="getUnderlyingMetrics('vsLastestValuation')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                </span>
                            </ng-template>
                        </button>
                    </div>
                </div>

                <hr style="border-top: 1px dotted grey; margin: 0px 12px 0px 12px;">

                <div class="row" style="font-size: 13px !important">
                    <div class="col align-left margin-auto">
                        VS Q4 Of N-1                  
                    </div>
                    <div class="col align-right">
                        <button class="btn-alignment" type="button">
                            <ng-template [ngIf] = "marketChartDataVariations.vsQ4QuarterPrevToLastestValuation >= 0" 
                                [ngIfElse] = "negativevsQ4QuarterPrevToLastestValuation">
                                <span style="color: green;">
                                    <!-- {{ utilService.getDisplayFormattedNumber(marketChartDataVariations.vsQ4QuarterPrevToLastestValuation * 100, 1, '%', 'NA' ) }} -->  
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsQ4QuarterPrevToLastestValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('VS Q4 Of N-1')" [formula]="getFormulaForTrackRecordItems('vsQ4QuarterPrevToLastestValuation')" [underlyingMetrics]="getUnderlyingMetrics('vsQ4QuarterPrevToLastestValuation')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu> 
                                </span>
                            </ng-template>
                            <ng-template #negativevsQ4QuarterPrevToLastestValuation>
                                <span style="color: red;" >
                                    <!-- {{ utilService.getNegativeNumberDisplayFormat(marketChartDataVariations.vsQ4QuarterPrevToLastestValuation * 100, 1, '%', 'NA' ) }} -->
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsQ4QuarterPrevToLastestValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [displayNegativeNo]="true" [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('VS Q4 Of N-1')" [formula]="getFormulaForTrackRecordItems('vsQ4QuarterPrevToLastestValuation')" [underlyingMetrics]="getUnderlyingMetrics('vsQ4QuarterPrevToLastestValuation')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                </span>
                            </ng-template>
                        </button>
                    </div>
                </div>

                <hr style="border-top: 1px dotted grey; margin: 0px 12px 0px 12px;">

                <div class="row" style="font-size: 13px !important">
                    <div class="col align-left margin-auto">
                        {{translateService.getLabel("vs_inception")}}
                    </div>
                    <div class="col align-right">
                        <button class="btn-alignment" type="button">
                            <ng-template [ngIf] = "marketChartDataVariations.vsInvestmentValuation >= 0" [ngIfElse] = "negativevsInvestmentValuation">
                                <span style="color: green;">
                                    <!-- {{ utilService.getDisplayFormattedNumber(marketChartDataVariations.vsInvestmentValuation * 100, 1, '%', 'NA' ) }} -->
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsInvestmentValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('vs_inception')" [formula]="getFormulaForTrackRecordItems('vs_inception')" [underlyingMetrics]="getUnderlyingMetrics('vs_inception')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu> 
                                </span>
                            </ng-template>
                            <ng-template #negativevsInvestmentValuation>
                                <span style="color: red;" >
                                    <!-- {{ utilService.getNegativeNumberDisplayFormat(marketChartDataVariations.vsInvestmentValuation * 100, 1, '%', 'NA' ) }} -->
                                    <app-data-drill-down-menu [value]="marketChartDataVariations.vsInvestmentValuation * 100" [decimalPoint]="1" [suffix]="'%'"
                                    [displayNegativeNo]="true" [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(latestValuationDate.valuationDate, mediumDate)" [typeOfSource]="'Calculated'"
                                    [label]="translateService.getLabel('vs_inception')" [formula]="getFormulaForTrackRecordItems('vs_inception')" [underlyingMetrics]="getUnderlyingMetrics('vs_inception')"
                                    [source]="getSourceForDrillDown('Implied Valuation Multiple')" [valDateId]="latestValDateTrackRecord.formId"  [currency] = "latestValDateTrackRecord.currency"></app-data-drill-down-menu>
                                </span>
                            </ng-template>
                        </button>
                    </div>
                </div>

                <hr class="primary-bg-theme" style="height:2px; border-width:0; margin: 0px 12px 0px 12px;">

                <br>

                <div class="row" style="margin: 6px;">
                    <div class="col-1 min-padding">
                        <span><i class="fas fa-info-circle primary-color" style="cursor: pointer; font-size: medium;"></i></span>
                    </div>
                    <div class="col min-padding" style="justify-content: flex-start; font-size: 13px !important">
                        <span>
                            <b class="blue-color"> 1) </b>
                            {{translateService.getLabel("info_comparable_list_subject_company")}}
                        </span>
                        <br>
                        <br>
                        <span>
                            <b class="blue-color"> 2) </b>
                            {{translateService.getLabel("info_implied_multiple_todays_market_data")}}
                        </span>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="col-6">
            <div class="widget" style="height: 410px;">
                <div style="margin-bottom: 50px;">
                    <span class="topic">{{ translateService.getLabel("significant_event_market") }}</span>
                </div>
                <mat-form-field class="text-area">
                    <textarea matInput rows="20" [(ngModel)]="savedData.significantEventMarket"
                        style="font-size: small">
                    </textarea>
                </mat-form-field>
            </div>
        </div> -->
    </div>
    <br>

    <app-qualitative-analysis
    [valDateUpperLimit] = "valDateUpperLimit"
    [selectedValuationDates] = "selectedValuationDates">
    </app-qualitative-analysis>

</div>

<a style="display: none;" #downloadLink></a>

<ng-template #partner let-partnerName>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="partnerName.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table borderless">
            <tr>
                <th></th>
                <th>
                    {{ translateService.getLabel("name") }}
                </th>
                <th style="text-align: right;">
                    {{ translateService.getLabel("delete") }}
                </th>
            </tr>
            <ng-template [ngIf]="masterPartnersList && masterPartnersList?.length > 0">
                <ng-template ngFor [ngForOf]="masterPartnersList" let-partnerList let-rowIndex="index">
                    <tr>
                        <td>
                            <mat-checkbox [(ngModel)]="partnerList.isSelected"
                                (ngModelChange)="addPartnerToCompany($event, partnerList)"></mat-checkbox>
                        </td>
                        <td style="width: 150px;">
                            {{ partnerList.name }}
                        </td>
                        <td style="text-align: right">
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </ng-template>
            <tr>
                <td>
                    <mat-checkbox [(ngModel)]="partnerList.isSelected"></mat-checkbox>
                </td>
                <td style="width: 150px;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="partnerList.name" name="partner" type="text"
                            [disabled]="!partnerList.isSelected">
                    </mat-form-field>
                </td>
                <td>

                </td>
            </tr>
        </table>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                (click)="addPartnerList(); 
                partnerName.dismiss('Cross click')">
                {{ translateService.getLabel("save") }}
            </button> &nbsp;
        </div>
    </div>
</ng-template>
<!-- End -->


<ng-template #valDateUpperLimitPopup let-valDateUpperLimitPopupModal>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close"
            (click)="valDateUpperLimitPopupModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: unset; overflow: hidden;">
        <mat-form-field>
            <mat-label>{{ translateService.getLabel("valuation_date") }}</mat-label>
            <mat-select [(ngModel)]="valDateUpperLimitUserInput">
                <mat-option *ngFor="let valDate of valDatesForUpperLimitSelection;" [value]="valDate"
                    style="margin-top:10px;">

                    {{valDate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <Br /><Br />

        {{ translateService.getLabel("click_on") }} <b>{{ translateService.getLabel("apply") }}</b> {{ translateService.getLabel("refresh_investment_summary") }}.

        <div style="margin: 10px;">
            <button mat-raised-button class="primary-bg" type="submit"
                (click)="saveValDateUpperLimit(valDateUpperLimitPopupModal)">

                {{ translateService.getLabel("apply") }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #fullyDilutedOwnership let-fullyDiluted>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="fullyDiluted.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> {{ translateService.getLabel("fully_diluted_ownership") }} </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput style="text-align: right;" [(ngModel)]="fullyDilutedOwnershipStake"
                            name="partner" type="number">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                [disabled]="!fullyDilutedOwnershipStake"
                (click)="addFullyDilutedOwnership(); fullyDiluted.dismiss('Cross click')">{{translateService.getLabel("add")}}</button>
        </div>
    </div>
</ng-template>


<ng-template #securityType let-security>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="security.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> {{ translateService.getLabel("security_type") }} </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="securityTypeAv" name="partner" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                (click)="addSecurityType(); security.dismiss('Cross click')">{{ translateService.getLabel("add") }}</button>
        </div>
    </div>
</ng-template>


<ng-template #plannedPerformance let-plannedPerformance>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="plannedPerformance.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-planned-vs-actual [excelData]="actualPerformanceDataModel" (dataUpdated)="savePerformanceData()">
        </app-planned-vs-actual>
    </div>
</ng-template>

<!-- User Added Value Bridge Popup to Update -->
<ng-template #updateValuationBridge let-applicationModal>
    <div class="modal-header">
        <div class="modal-title">
            <h4>{{ translateService.getLabel("update_valuation_bridge_data") }} </h4>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close"
            (click)="applicationModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="model-content">
            <div class="row p-modal-row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-label>{{ translateService.getLabel("name") }} </mat-label>
                        <input matInput name="valueBridgeName" [(ngModel)] = "valueBridgeName">
                    </mat-form-field>
                </div>

                <div class="col rightAlign">
                    {{ translateService.getLabel("no_of_valuation_dates") }} &nbsp;
                    2
                    &nbsp;
                    <mat-slide-toggle [checked] = "isMultiValueBridge" (change) = "isMultiValueBridge = !isMultiValueBridge"></mat-slide-toggle>
                    &nbsp;
                    3
                </div>

                <div class="col-1"></div>
            </div>
            <div class="row p-modal-row">
                <div class="col p-modal-left-col">
                    <mat-form-field>
                        <mat-label>{{ translateService.getLabel("start_date") }}</mat-label>
                        <mat-select [(ngModel)]="startDateValuationBridge" >
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="startDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col p-modal-left-col" *ngIf="this.filteredForms.length >= 3 && isMultiValueBridge">
                    <mat-form-field>
                        <mat-label>{{ translateService.getLabel("intermediate_date") }}</mat-label>
                        <mat-select [(ngModel)]="intermediateDateValuationBridge">
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="intermediateDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col p-modal-left-col">
                    <mat-form-field>
                        <mat-label>{{ translateService.getLabel("end_date") }}</mat-label>
                        <mat-select [(ngModel)]="endDateValuationBridge">
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="endDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col-2">
                    <button mat-raised-button class="primary-bg"
                        [disabled]="!startDateValuationBridge || !endDateValuationBridge"
                        (click)="fetchValuationDataForWaterfallChart(selectedValueBridgeIndex)">
                        {{ translateService.getLabel("fetch") }}
                    </button>
                </div>
            </div>

            <ng-template [ngIf]="!showValueBridgePopUpLoader">
                <div>
                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col" style="width: 100%;">
                            <b>{{ translateService.getLabel("label") }}</b>
                        </div>
                        <div class="col-4" style="width: 100%; text-align: right">
                            <b>{{ translateService.getLabel("value") }}</b>
                        </div>
                        <!-- <div class="col-3" style="width: 100%; text-align: center">
                        <b>Intermediate Sum</b>
                    </div> -->
                    </div>

                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col start-date-container">
                            <ng-template [ngIf]="valueBridgeModel[0].name !== 'Start Date'">
                                {{ valueBridgeModel[0].name | date: 'MMM d, y'}}
                            </ng-template>

                            <ng-template [ngIf]="valueBridgeModel[0].name == 'Start Date'">
                                {{ valueBridgeModel[0].name}}
                            </ng-template>
                        </div>
                        <div class="col-4 rightAlign">
                            <!-- <mat-form-field style="width: 100%; text-align: right">
                            <input matInput [(ngModel)]="valueBridgeModel[0].formattedValue" (input)="acceptOnlyDigitsVB($event, valueBridgeModel[0])" required>
                        </mat-form-field> -->
                            {{ valueBridgeModel[0].y | number:"1.2-2" }}
                        </div>
                        <!-- <div class="col-3 p-modal-left-col">
                    </div> -->
                    </div>

                    <ng-template ngFor [ngForOf] = "valueBridgeModel" let-entry let-rIndex = "index">
                        <ng-template [ngIf] = "rIndex > 0 && rIndex < (valueBridgeModel.length - 1) && !entry.hide">
                            <div class="row p-modal-row">
                                <ng-template [ngIf]="entry">
                                    <div class="col leftAlign">
                                        {{ getTranslatedLabelsByActualLabel(entry.name) }}
                                    </div>
                                    <div class="col-4 rightAlign">
                                        {{ entry.y | number: "1.2-2" }}
                                    </div>
                                   
                                </ng-template>
                            </div>
                        </ng-template>
                    </ng-template>

                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col end-date-container">
                            <ng-template [ngIf]="valueBridgeModel[(valueBridgeModel.length - 1)].name !== 'End Date'">
                                {{ valueBridgeModel[(valueBridgeModel.length - 1)].name | date: 'MMM d, y'}}
                            </ng-template>

                            <ng-template [ngIf]="valueBridgeModel[(valueBridgeModel.length - 1)].name == 'End Date'">
                                {{ valueBridgeModel[(valueBridgeModel.length - 1)].name}}
                            </ng-template>
                        </div>
                        <div class="col-4 p-modal-left-col" style="width: 100%; text-align: right;">
                            {{ valueBridgeModel[(valueBridgeModel.length - 1)].y | number:"1.2-2"}}
                        </div>
                        <!-- <div class="col-3 p-modal-left-col">
                        </div> -->
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template [ngIf]="!showValueBridgePopUpLoader">
            <div class="row p-modal-row">
                <div class="col p-modal-right-col">
                </div>
                <div class="col">
                    <button mat-raised-button class="secondary-bg p-button" style="float: right;"
                        (click)="reInstateTheValues(); applicationModal.dismiss('Cross click')">
                        {{ translateService.getLabel("cancel") }}
                    </button>
                    <button mat-raised-button class="primary-bg p-button" style="float: right;"
                        (click)="saveValuationBridgeData(selectedValueBridgeIndex)">
                        {{ translateService.getLabel("save") }}
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="showValueBridgePopUpLoader">
            <div class="row" style="margin: 25%; width: 100%; text-align: center;">
                <span style="font-size: xx-large;">
                    {{ translateService.getLabel("preparing_value_bridge") }}...
                </span>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #editCompanyContent let-editCompanyContent>
    <div class="modal-header">
        <div class="modal-title">
            <h4>{{ translateService.getLabel("edit_company") }}</h4>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close"
            (click)="editCompanyContent.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="model-content">

            <div class="row marginBottom">
                <div class="col-3"><b>{{ translateService.getLabel("no_of_employees") }}</b></div>
                <div class="col-9">
                    <mat-form-field>
                        <input matInput type="number"
                            [(ngModel)]="editCompanyDetails.organization.estimated_num_employees">
                    </mat-form-field>
                </div>
            </div>

            <div class="row marginBottom">
                <div class="col-3"><b>{{ translateService.getLabel("key_people") }}</b></div>
                <div class="col-5">
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="editCompanyDetails.organization.newKeyPeople">
                    </mat-form-field>
                </div>
                <div class="col-4"><button mat-raised-button class="primary-bg p-button" (click)="addKeyPeople()"
                        [disabled]="!editCompanyDetails.organization.newKeyPeople">{{translateService.getLabel("add")}}</button></div>
            </div>

            <ng-template ngFor [ngForOf]="editCompanyDetails.people" let-existingKeyPeople let-index="index">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-7">
                        {{existingKeyPeople.name}}<ng-template [ngIf]="existingKeyPeople.title">
                            ({{existingKeyPeople.title}})</ng-template>
                    </div>
                    <div class="col-2" style="cursor:pointer;"><i class="far fa-trash-alt"
                            (click)="deleteKeyPeople(index)"></i></div>
                </div>
            </ng-template>

        </div>

        <div class="row p-modal-row">
            <div class="col-4 p-modal-left-col">
            </div>
            <div class="col">
                <button mat-raised-button class="secondary-bg p-button"
                    (click)="editCompanyContent.dismiss('Cross click')">
                    {{ translateService.getLabel("cancel") }}
                </button>
                <button mat-raised-button class="primary-bg p-button" (click)="saveCompanyDetails(editCompanyContent)">
                    {{ translateService.getLabel("save") }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #businessUnitPopup let-businessUnitModal>
    <div class="modal-header">
        <div class="modal-title" style="width: 100%;">
            <h6>{{ translateService.getLabel("business_units") }} ({{ translateService.getLabel("enterprise_value") }})</h6>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="businessUnitModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template [ngIf] = "selectedBusinessUnits && selectedBusinessUnits.length > 0" [ngIfElse] = "loadingBusinessUnitsPopUp">
            <!-- Hiding the BUs which are not submitted on View Popup -->
            <app-business-unit-popup-ui [selectedBusinessUnits] = "selectedBusinessUnits" [mode] = "'VIEW_TYPE'" 
            (emitAction) = "emitAction($event)"></app-business-unit-popup-ui>
        </ng-template>

        <ng-template #loadingBusinessUnitsPopUp>
            <div class="loading-container">
              <p class="loading-text">{{ translateService.getLabel("loading") }}...</p>
            </div>
        </ng-template>
    </div>
</ng-template>

<a style="display: none;" #downloadLink></a>