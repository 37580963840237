import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import moment from 'moment';

import { CreateVlService } from './create-vl.service';
import { DataService } from 'src/app/services/data.service';
import { DEFAULT_VERSION_LIST_DETAILS } from '../version-list/version-data';
import { duplicateVlNameAsync } from 'src/app/utils/validators/duplicate-validator';
import { FinancialsVersion, VERSION_LIST_MODE } from '../version-interfaces';
import { FinancialVersionType } from '../version-interfaces';
import { MappingMgmtConfigService } from '../../mapping-mgmt-config.service';
import { ToastService } from 'src/app/utils/toast.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { VersionListService } from '../version-list.service';
import { DateUtilService } from 'src/app/date-util.service';

@Component({
  selector: 'app-create-vl',
  templateUrl: './create-vl.component.html',
  styleUrls: ['./create-vl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateVlComponent implements OnInit, OnDestroy {
  private commonSub = new Subscription();

  mode: VERSION_LIST_MODE;

  ranks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  histVersions = [];
  projectedVersions = [];

  editVersionId: string;
  fundId: string;
  isReadOnly: boolean = false;

  uploadDate: string;
  listName: string;
  versionDetailsUploadDate = '';

  isDuplicateHistVersion = false;
  isDuplicateHistRank = false;
  isDuplicateProjectedVersion = false;
  isDuplicateProjectedRank = false;
  areAllHistRowsBlank = false;
  areAllProjRowsBlank = false;

  isDirty: boolean;

  listNameFc = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'blur',
  });

  constructor(
    public cvlService: CreateVlService,
    private vlService: VersionListService,
    private utilService: UtilService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configMappingService: MappingMgmtConfigService,
    private dataService: DataService,
    private userMgmtService: UserManagementService,
    private toastService: ToastService,
    private dateUtilSvc: DateUtilService,
    private cdr: ChangeDetectorRef
  ) {
    this.isReadOnly = false;
    const routeSnapShot = this.activatedRoute.snapshot;
    this.editVersionId = routeSnapShot.paramMap.get('versionId');
    this.fundId = routeSnapShot.paramMap.get('fundId');
    const url = activatedRoute.snapshot.url.toString();
    if (url.includes('edit-version')) {
      this.mode = VERSION_LIST_MODE.EDIT;
      this.isReadOnly = false;
    } else if (url === 'create-version') {
      this.mode = VERSION_LIST_MODE.CREATE;
      this.isReadOnly = false;
    } else {
      this.mode = VERSION_LIST_MODE.READ;
      this.isReadOnly = true;
    }
  }

  ngOnInit(): void {
    if (!this.isReadOnly) {
      this.getEnabledVersions();
    }
    this.commonSub.add(
      this.configMappingService.uploadDate$.subscribe((valDate) => {
        this.uploadDate = this.dateUtilSvc.getDateYYYYMMDD(valDate);
      })
    );

    if (this.mode !== VERSION_LIST_MODE.CREATE) {
      if (this.editVersionId === 'default' && this.isReadOnly) {
        const defaultVersionListDetails = DEFAULT_VERSION_LIST_DETAILS;
        this.listName = 'Default List';
        this.cvlService.setHistoricalItems(defaultVersionListDetails.backwardLookingMappings);
        this.cvlService.setProjectedItems(defaultVersionListDetails.forwardLookingMappings);
      } else {
        this.utilService.showLoadingPopup();
        this.cvlService.getVersionListDetails(this.editVersionId).subscribe(
          (list) => {
            if (this.isReadOnly) {
              this.listName = list.name;
              this.versionDetailsUploadDate = list.uploadDate;

            } else {
              this.listNameFc.setValue(list.name);
              const ud = new Date(list.uploadDate);
              this.configMappingService.resetUploadDate(ud);
            }
            this.cvlService.setHistoricalItems(list.backwardLookingMappings);
            this.cvlService.setProjectedItems(list.forwardLookingMappings);
            this.utilService.closeAllPopups();
            this.cdr.detectChanges();
          },
          (err) => {
            console.log('Failed to get version list details...', err);
            this.utilService.closeAllPopups();
            this.toastService.openSnackBar('Failed to get version list details.');
            this.location.back();
          }
        );
      }
    } else {
      this.cvlService.setHistoricalItems([]);
      this.cvlService.setProjectedItems([]);
      // add default blank rows for create
      this.onAddHistVersion();
      this.onAddProjectedVersion();
    }
  }

  getEnabledVersions() {
    this.utilService.showLoadingPopup();
    this.cvlService.getEnabledVersionsFromGx().subscribe((response) => {
      this.projectedVersions = response
        .filter((ver) => ver.type === FinancialVersionType.FORWARD)
        .map((ver) => {
          return {
            ...ver,
            displayValue: ver.name,
            value: ver.id,
          };
        });

      this.histVersions = response
        .filter((ver) => ver.type === FinancialVersionType.BACKWARD)
        .map((ver) => {
          return {
            ...ver,
            displayValue: ver.name,
            value: ver.id,
          };
        });
      this.utilService.closeAllPopups();
      this.listNameFc.setAsyncValidators(duplicateVlNameAsync(this.dataService, this.cdr, this.fundId, this.editVersionId ));
      this.listNameFc.updateValueAndValidity();
      this.cdr.detectChanges();
    });
  }

  onHistoricalRankChange(e) {
    this.isDirty = true;
    const rankAndVersionToBeUpdated = {
      uuid: e.row.uuid,
      id: e.row.id,
      rank: e.event.value,
      versionId: e.row.versionId ? e.row.versionId : '',
    };
    this.cvlService.editHistoricalItem(rankAndVersionToBeUpdated);
    this.isDuplicateHistRank = this.cvlService.isDuplicateHistRank();
    this.areAllHistRowsBlank = this.cvlService.areAllHistRowsInvalid();
  }

  onHistoricalVersionChange(e) {
    this.isDirty = true;
    if (e.event.value) {
      const versionToBeUpdated = {
        uuid: e.row.uuid,
        id: e.row.id,
        versionId: e.event.value,
        versionName: this.histVersions.filter((ver) => ver.id === e.event.value)[0].name,
      };

      this.cvlService.editHistoricalItem(versionToBeUpdated);
      this.isDuplicateHistVersion = this.cvlService.isDuplicateHistVersion();
      this.areAllHistRowsBlank = this.cvlService.areAllHistRowsInvalid();
    }
  }

  onDeleteHistoricalItem(e) {
    if(e.id){
      this.cvlService.deleteExistingHistoricalItem(e);
    } else{
      this.cvlService.deleteHistoricalItem(e);
    }
    this.isDuplicateHistVersion = this.cvlService.isDuplicateHistVersion();
    this.isDuplicateHistRank = this.cvlService.isDuplicateHistRank();
    this.areAllHistRowsBlank = this.cvlService.areAllHistRowsInvalid();
  }

  onProjectedRankChange(e) {
    this.isDirty = true;
    const rankAndVersionToBeUpdated = {
      uuid: e.row.uuid,
      id: e.row.id,
      rank: e.event.value,
      versionId: e.row.versionId,
    };
    this.cvlService.editProjectedItem(rankAndVersionToBeUpdated);
    this.isDuplicateProjectedRank = this.cvlService.isDuplicateProjectedRank();
    this.areAllProjRowsBlank = this.cvlService.areAllProjRowsInvalid();
  }

  onProjectedVersionChange(e) {
    this.isDirty = true;
    if (e.event.value) {
      const versionToBeUpdated = {
        uuid: e.row.uuid,
        id: e.row.id,
        versionId: e.event.value,
        versionName: this.projectedVersions.filter((ver) => ver.id === e.event?.value)[0].name,
      };
      this.cvlService.editProjectedItem(versionToBeUpdated);
      this.isDuplicateProjectedVersion = this.cvlService.isDuplicateProjectedVersion();
      this.areAllProjRowsBlank = this.cvlService.areAllProjRowsInvalid();
    }
  }

  onAddHistVersion() {
    this.isDirty = true;
    const newHistVersion: FinancialsVersion = {
      id: null,
      uuid: this.utilService.getUUID(),
      versionName: '',
      rank: null,
      versionId: '',
      deleted: false,
    };
    this.cvlService.addNewHistoricalItem(newHistVersion);
    this.areAllHistRowsBlank = this.cvlService.areAllHistRowsInvalid();
  }

  onAddProjectedVersion() {
    this.isDirty = true;
    const newItem: FinancialsVersion = {
      id: null,
      uuid: this.utilService.getUUID(),
      versionName: '',
      rank: null,
      versionId: '',
      deleted: false,
    };
    this.cvlService.addProjectedItem(newItem);
    this.areAllProjRowsBlank = this.cvlService.areAllProjRowsInvalid();
  }

  onDeleteProjectedItem(e) {
    if(e.id) {
      this.cvlService.deleteExistingProjectedItem(e);
    } else {
      this.cvlService.deleteProjectedItem(e);
    }
    this.isDuplicateProjectedVersion = this.cvlService.isDuplicateProjectedVersion();
    this.isDuplicateProjectedRank = this.cvlService.isDuplicateProjectedRank();
    this.areAllProjRowsBlank = this.cvlService.areAllProjRowsInvalid();
  }

  submitList(){
    if(this.mode === VERSION_LIST_MODE.CREATE){
      this.saveList();
    }else if(this.mode === VERSION_LIST_MODE.EDIT){
      this.updateList();
    }
  }

  saveList() {
    this.utilService.showLoadingPopup();
    this.cvlService.saveVersionList(this.listNameFc.value?.trim(), this.uploadDate, this.fundId).subscribe(
      (response) => {
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Version List created successfully');
        this.router.navigate(['../versions'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge'
        });
      },
      (err) => {
        console.log('Save version list failed', err);
        this.toastService.openSnackBar('Failed to save version list.');
        this.utilService.closeAllPopups();
      }
    );
  }

  updateList() {
    this.utilService.showLoadingPopup();
    this.cvlService.updateVersionList(this.listNameFc.value?.trim(), this.uploadDate, this.fundId, this.editVersionId)
    .subscribe(
      (response) => {
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Version List updated successfully');
        this.cvlService.resetDeletedItems();
        this.router.navigate(['../../versions'], {
          relativeTo: this.activatedRoute,
          queryParamsHandling: 'merge'
        });
      },
      (err) => {
        console.log('Update version list failed', err);
        this.toastService.openSnackBar('Failed to update version list.');
        this.utilService.closeAllPopups();
      }
    );
    this.isDirty = false;    
  }

  goBack() {
    if (this.listNameFc.dirty || this.isDirty) {
      const dialogRef = this.utilService.showConfirmMessage('Are you sure you want to discard the changes?', 'Yes', 'No');

      dialogRef.afterClosed().subscribe((userResponse: string) => {
        if (userResponse === 'Yes') {
          this.location.back();
        }
      });
    } else {
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
    this.configMappingService.resetUploadDate();
  }
}
