import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { InvestmentSummaryService } from '../qubit-x/portfolio/investment-page-summary-v2/investment-summary.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from './data.service';
import { UserManagementService } from './user-management.service';


@Injectable({
  providedIn: 'root'
})
export class ApprovalSystemService {

  allConditions = [];
  allEnabledConditions = [];

  constructor(private ds: DataService,
      public invSummaryService : InvestmentSummaryService,
      private route : ActivatedRoute,
      private um: UserManagementService,  
      private ps: PortFolioSummaryServiceV2,    
    )
  {}

  init(company, valuationDateGridDataSource?) {

    const userDetails = this.um.getSelectedUserDetails();

    let fundId = this.route.snapshot.queryParamMap.get('parentId');
    if(!fundId && !!this.ps.selectedFundId){
      fundId = this.ps.selectedFundId;
    }
    const userId = this.um.getSelectedUserDetails().id;

    const reqBody = {
        "orgId": userDetails.organization.id,
        "fundId": fundId,
        "companyId": valuationDateGridDataSource ? company : company.companyId,
        "userId": userId,
        "module": "EQUITY"
    }

    this.ds.getCompanyConditions(reqBody).subscribe( res => {
        const response = res.body["response"];
        this.allConditions = cloneDeep(response);

        this.allEnabledConditions = this.allConditions.filter(e => e.isConditionEnabled)

        this.allEnabledConditions.splice(0, 0, {
          "conditionName" : "None",
          "id": "none"
        })

        if(valuationDateGridDataSource && this.allEnabledConditions.length == 2){    //showing the only condition by default
          valuationDateGridDataSource.forEach(formVersionForGrid => {    
            formVersionForGrid.value.forEach(eachVersion => {
                eachVersion.selectedCondition = {
                  "conditionName": this.allEnabledConditions[1].conditionName,
                  "id": this.allEnabledConditions[1].id
                }
            })
          })
        }

        if(!valuationDateGridDataSource && this.allEnabledConditions.length == 2){    //showing the only condition by default for checklist panel
          company.selectedCondition = {                                               //not passing 'valuationDateGridDataSource' parameter for checklist panel
            "conditionName": this.allEnabledConditions[1].conditionName,
            "id": this.allEnabledConditions[1].id
          }
        }

        
        this.allConditions.forEach(c => {
          let i = "1";
          c.approverDetails = [];
          while(!!c.conditionUsers[i]){
            
            let allApprovers = [];
            
            c.conditionUsers[i].forEach(detail => {
              if(!!detail && !!detail.approverName){
                allApprovers.push({
                  "name" : detail.approverName,
                  "type" : detail.type
                });
              }
            })

            c.approverDetails.push({
              "approverDetailsPerLevel" : allApprovers
            })

            i = (parseInt(i)+1).toString();

          }

        })
    })


  }

  getUserHistory(item){

    let valDateId;

    if(!!item.id){
      valDateId = item.id;
    }else{
      valDateId = item.latestValuationDateId;
    }

    this.ds.getDocumentStatus(valDateId).subscribe(data => {
      const history = data.body['response'];

      item['approvalUserData'] = history.sort((a, b) =>
        new Date(b.approveAt).getTime() - new Date(a.approveAt).getTime());

      if(item.approvalUserData && item.approvalUserData.length == 1 && item.approvalSystem && item.approvalSystem.approvalObjectStatus == "APPROVED"){
        item.approvalUserData[item.approvalUserData.length - 1].approverStatus = "APPROVED";
      }
    })

  }
  
  getApprovalStatus(formVersion) {

    let obj = {
        approvalStatus : null,
        actionName : null,
        approvalRequestId: null,
        editable: false,
        approvalUserData: [],
    }

    let approvalData = [];

    approvalData = formVersion.approvalSystem;

    if(approvalData)
    {
        // obj.approvalRequestId = approvalData[0].id;

        if(approvalData[0].status == "approved")
        {
            // obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.APPROVED;
            // obj.actionName = this.APPROVAL_ACTION.NONE;
            approvalData[0].approvals = approvalData[0].approvals.sort(function (a, b) {
              return new Date(a.user?.timestamp) === new Date(b.user?.timestamp)? 0: new Date(a.user?.timestamp) < new Date(b.user?.timestamp)? 1: -1;
            });
            obj.approvalUserData.push(approvalData[0].approvals)
        }
        else if(approvalData[0].status == "rejected")
        {
            // obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.REJECTED;
            // obj.actionName = this.APPROVAL_ACTION.NONE;
            obj.approvalUserData.push(approvalData[0].approvals)
        }
        else
        {
            if(approvalData[0].status == "pending")
            {
              // ToDo: 

                // let isInitator = this.isInitiator(approvalConditionData, initiatorsGroupMembers);
                // if(isInitator)
                // {
                //     obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.PENDING;
                //     obj.actionName =  this.APPROVAL_ACTION.RECALL_APPROVAL;
                // }
                // else if(this.isCurrentApprover(approvalData))
                // {
                //     obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.PENDING;
                //     obj.actionName =  this.APPROVAL_ACTION.REJECT_APPROVE;
                // }
                // else
                // {
                //     // no action
                //     obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.PENDING;
                //     obj.actionName =  this.APPROVAL_ACTION.NONE;
                // }
        
            }
        }
    }
    else if(this.allEnabledConditions && this.allEnabledConditions.length > 0) 
    {
      // obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.REQUEST_NOT_STARTED;
      // obj.actionName = this.APPROVAL_ACTION.INITIATE_APPROVAL;
      obj.editable = !formVersion.approvalStatus;
    }
    else
    {
        // Disable initiate button
        // obj.actionName = this.APPROVAL_ACTION.NO_CONDITION;
        // obj.approvalStatus = this.APPROVAL_OBJECT_STATUS.REQUEST_NOT_STARTED;
        obj.editable = !formVersion.approvalStatus;
    }

    return obj;
  }
}