import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from 'src/app/utils/util.service';
import { HIST_BALANCE_SHEET_DEFAULT_MAP, HIST_CASH_FLOW_STATEMENT_DEFAULT_MAP, HIST_INCOME_STATEMENT_DEFAULT_MAP } from './historical-qx-attributes';
import { AttributesService } from './attributes.service';
import { GxAttributesService } from './gx-attributes.service';
import { PROJ_ATTRIBUTES } from './projected-qx-attributes';
import { FormControl, Validators } from '@angular/forms';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/utils/toast.service';
import { DateUtilService } from 'src/app/date-util.service';
import { Subscription } from 'rxjs';
import { ATTRIBUTES_LIST_MODE, AttrMap, AttributeListDetails } from './attribute-interfaces';
import { Location } from '@angular/common';
import { duplicateAttributeListNameAsync } from 'src/app/utils/validators/duplicate-validator';
import { DataService } from 'src/app/services/data.service';
import { ReplicateAttributeListService } from './replicate-attribute-list.service';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesComponent implements OnInit, OnDestroy {
  histIncomeStatementMap = HIST_INCOME_STATEMENT_DEFAULT_MAP;
  histBalanceSheetMap = HIST_BALANCE_SHEET_DEFAULT_MAP;
  histCashflowStatementMap = HIST_CASH_FLOW_STATEMENT_DEFAULT_MAP;
  histAttributesMap = [...HIST_INCOME_STATEMENT_DEFAULT_MAP, ...HIST_BALANCE_SHEET_DEFAULT_MAP, ...HIST_CASH_FLOW_STATEMENT_DEFAULT_MAP]

  histUsedIncomeStatementAttrs = {};
  histUsedBalanceSheetAttrs = {};
  histUsedCashflowStatementAttrs = {};
  histUsedAttrs = {};
  usedProjectedAttrs = {};

  projectedAttributes = PROJ_ATTRIBUTES;
  apiAttrs = [];

  mapNameFc = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'blur',
  });
  attributeListMapId: string;
  fundId: string;
  uploadDate: string;

  mode: ATTRIBUTES_LIST_MODE;
  isReadOnly: boolean;

  attributeListName: string;
  attributeListUploadDate: string;

  isDirty: boolean;

  gxGlobalKeyAndLabels: Record<string, string> = {};

  private commonSub = new Subscription();

  constructor(
    private utilService: UtilService,
    private gxSvc: GxAttributesService,
    public attrsService: AttributesService,
    public replicateAttrsListService: ReplicateAttributeListService,
    private location: Location,
    private router: Router,
    private configMappingService: MappingMgmtConfigService,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private dateUtilSvc: DateUtilService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const routeSnapShot = this.activatedRoute.snapshot;
    this.attributeListMapId = routeSnapShot.paramMap.get('mapId');
    this.fundId = routeSnapShot.paramMap.get('fundId');
    const url = routeSnapShot.url.toString();
    console.log(url, 'Url...');
    if (url.includes('create-attributes-map')) {
      this.mode = ATTRIBUTES_LIST_MODE.CREATE;
      this.isReadOnly = false;
    } else if (url.includes('replicate-attributes-map')) {
      this.mode = ATTRIBUTES_LIST_MODE.REPLICATE;
      this.isReadOnly = false;
    } else {
      this.mode = ATTRIBUTES_LIST_MODE.READ;
      this.isReadOnly = true;
    }
    this.commonSub.add(
      this.configMappingService.uploadDate$.subscribe((valDate) => {
        this.uploadDate = this.dateUtilSvc.getDateYYYYMMDD(valDate);
      })
    );
    if (this.attributeListMapId === 'default-attribute-map' && this.isReadOnly) {
      this.getDefaultAttributeListDetails();
    } else if (this.mode === ATTRIBUTES_LIST_MODE.REPLICATE) {
      this.getGxAttributesForReplicate(this.attributeListMapId);
    } else {
      this.getGxAttributes(this.isReadOnly);
    }
    this.mapNameFc.setAsyncValidators(duplicateAttributeListNameAsync(this.dataService, this.cdr, this.fundId));
  }

  getGxAttributes(isReadOnly: boolean) {
    if (!isReadOnly) {
      this.utilService.showLoadingPopup();
      this.gxSvc.getGxAttributes().subscribe(
        (res) => {
          console.log(res, 'Gx Attributes...');
          this.apiAttrs = [...res.body['response'][0].custom, ...res.body['response'][0].orgLevel];
          this.setLatestLabelsFromGx(res.body['response'][0]?.orgLevel);

          // set data sources for components
          this.attrsService.setIncomeStatmentMap([...this.histIncomeStatementMap]);
          this.attrsService.setHistBalanceSheet([...this.histBalanceSheetMap]);
          this.attrsService.setHistCashflow([...this.histCashflowStatementMap]);
          this.attrsService.setProjectedAttributes([...this.projectedAttributes]);
          // below lines are so that we can access these while saving,
          // the edit operation is mutable due to scroll jumping around during change detection/re-renders cycles if immutable
          this.attrsService.updateProjectedAttributesMap([...this.projectedAttributes]);
          this.attrsService.updateBalanceSheetMap([...this.histBalanceSheetMap]);
          this.attrsService.updateHistCashflowMap([...this.histCashflowStatementMap]);
          this.attrsService.updateProjectedAttributesMap([...this.projectedAttributes]);
          this.histAttributesMap.forEach((attr) => {
            this.histUsedAttrs[attr.gxKey] = true;
          });
          this.histIncomeStatementMap.forEach((attr) => {
            this.histUsedIncomeStatementAttrs[attr.gxKey] = true;
          });
          this.histBalanceSheetMap.forEach((attr) => {
            this.histUsedBalanceSheetAttrs[attr.gxKey] = true;
          });
          this.histCashflowStatementMap.forEach((attr) => {
            this.histUsedCashflowStatementAttrs[attr.gxKey] = true;
          });
          this.projectedAttributes.forEach((attr) => {
            this.usedProjectedAttrs[attr.gxKey] = true;
          });

          this.histUsedAttrs = {...this.histUsedAttrs}
          console.log(this.histUsedAttrs, 'histUsedAttrs');

          this.histUsedIncomeStatementAttrs = { ...this.histUsedIncomeStatementAttrs };
          console.log(this.histUsedIncomeStatementAttrs, 'histUsedIncomeStatementAttrs');

          this.histUsedBalanceSheetAttrs = { ...this.histUsedBalanceSheetAttrs };
          console.log(this.histUsedBalanceSheetAttrs, 'this.histUsedBalanceSheetAttrs');

          this.histUsedCashflowStatementAttrs = { ...this.histUsedCashflowStatementAttrs };
          console.log(this.histUsedCashflowStatementAttrs, 'this.histUsedCashflowStatementAttrs');

          this.usedProjectedAttrs = { ...this.usedProjectedAttrs };
          console.log(this.usedProjectedAttrs, 'this.usedProjectedAttrs');
          this.utilService.closeAllPopups();
          this.cdr.detectChanges();
        },
        (err) => {
          console.log('Err while getting Gx/Monitoring attributes', err);
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar('Failed to get graviton attributes.');
        }
      );
    } else {
      this.getAttributeListDetails(this.attributeListMapId);
    }
  }

  getGxAttributesForReplicate(attributeListId) {
    this.utilService.showLoadingPopup();
    this.replicateAttrsListService.getDataForReplicateMap(attributeListId).subscribe(
      (response) => {
        console.log(response, ' Replicate map data...');
        this.apiAttrs = [...response[0][0].custom, ...response[0][0].orgLevel];

        this.setAttributeListDetailsData(response[1], true);
        this.utilService.closeAllPopups();
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Failed to load Gx attributes or attribute list details...', err);
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Failed to replicate map. Please try again.');
      }
    );
  }

  setAttributeListDetailsData(response: AttributeListDetails, isReplicateMode: boolean) {
    this.attributeListName = response.name;
    this.attributeListUploadDate = response.uploadDate;
    // set the date into upload date picker
    this.configMappingService.resetUploadDate(new Date(this.attributeListUploadDate));
    let histIncomeStatementMap: AttrMap[] = [];
    let histBalanceSheetMap: AttrMap[] = [];
    let histCashflowStatementMap: AttrMap[] = [];
    response.backwardLookingMappings.forEach((attr) => {
      if (attr.financialStatementType === 'INCOME_STATEMENT') {
        histIncomeStatementMap.push(attr);
        // this.histUsedIncomeStatementAttrs[attr.gxKey] = true;
        this.histUsedAttrs[attr.gxKey] = true;
      }
      if (attr.financialStatementType === 'BALANCE_SHEET') {
        histBalanceSheetMap.push(attr);
        // this.histUsedBalanceSheetAttrs[attr.gxKey] = true;
        this.histUsedAttrs[attr.gxKey] = true;
      }
      if (attr.financialStatementType === 'CASH_FLOW_STATEMENT') {
        histCashflowStatementMap.push(attr);
        // this.histUsedCashflowStatementAttrs[attr.gxKey] = true;
        this.histUsedAttrs[attr.gxKey] = true;
      }
    });
    response.forwardLookingMappings.forEach((attr) => {
      this.usedProjectedAttrs[attr.gxKey] = true;
    });
    this.histIncomeStatementMap = [...histIncomeStatementMap];
    this.histIncomeStatementMap.sort((a, b) => (a.qxKeyOrder < b.qxKeyOrder ? -1 : 1));
    this.histBalanceSheetMap = [...histBalanceSheetMap];
    this.histBalanceSheetMap.sort((a, b) => { 
      if(a.qxKeyOrder < b.qxKeyOrder) {
        return -1;
      }
      if(a.qxKeyOrder > b.qxKeyOrder) {
        return 1;
      }
      if(a.qxKeyOrder === b.qxKeyOrder) {
        if(a.qxKey === 'Other_Current_Liabilities' || a.qxKey === 'Other_Non_Current_Liabilities') {
          return a.qxKeyOrder - b.qxKeyOrder;
         }
      }
      // return (a.qxKeyOrder < b.qxKeyOrder ? -1 : 1);
    });
    this.histCashflowStatementMap = [...histCashflowStatementMap];
    this.histCashflowStatementMap.sort((a, b) => (a.qxKeyOrder < b.qxKeyOrder ? -1 : 1));
    this.projectedAttributes = [...response.forwardLookingMappings];
    this.projectedAttributes.sort((a, b) => (a.qxKeyOrder < b.qxKeyOrder ? -1 : 1));
    this.attrsService.setIncomeStatmentMap(this.histIncomeStatementMap);
    this.attrsService.setHistBalanceSheet(this.histBalanceSheetMap);
    this.attrsService.setHistCashflow(this.histCashflowStatementMap);
    this.attrsService.setProjectedAttributes(this.projectedAttributes);
    // uncomment and revisit immuatability in below code for edit
    // this.attrsService.updateBalanceSheetMap(this.histBalanceSheetMap);
    // this.attrsService.updateHistCashflowMap(this.histCashflowStatementMap);
    // this.attrsService.updateProjectedAttributesMap(this.projectedAttributes);
    // this.histUsedIncomeStatementAttrs = {...this.histUsedIncomeStatementAttrs};
    // this.histUsedBalanceSheetAttrs = {...this.histUsedBalanceSheetAttrs};
    // this.histUsedCashflowStatementAttrs = {...this.histUsedCashflowStatementAttrs};
    // this.usedProjectedAttrs = {...this.usedProjectedAttrs};
  }

  getAttributeListDetails(attributeListId: string) {
    this.utilService.showLoadingPopup();
    this.attrsService.getAttributeListById(attributeListId).subscribe(
      (response) => {
        console.log(response, 'Details');
        this.utilService.closeAllPopups();
        this.setAttributeListDetailsData(response, false);
        this.cdr.detectChanges();
      },
      (err) => {
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Failed to get attribute list details.');
        console.log('Error while getting the attribute list details...', err);
      }
    );
  }

  getDefaultAttributeListDetails() {
    this.utilService.showLoadingPopup();
    this.gxSvc.getGxAttributes().subscribe(
      (res) => {
        this.utilService.closeAllPopups();
        this.setLatestLabelsFromGx(res.body['response'][0]?.orgLevel);
        // set data sources for components
        this.attrsService.setIncomeStatmentMap([...this.histIncomeStatementMap]);
        this.attrsService.setHistBalanceSheet([...this.histBalanceSheetMap]);
        this.attrsService.setHistCashflow([...this.histCashflowStatementMap]);
        this.attrsService.setProjectedAttributes([...this.projectedAttributes]);
        this.attributeListName = 'Default Map';
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Error while getting gx attributes...', err);
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Failed to get graviton attributes.');
      }
    );
  }

  setLatestLabelsFromGx(globalAttributes) {
    globalAttributes.forEach((gxGlobalAttribute) => {
      this.gxGlobalKeyAndLabels[gxGlobalAttribute?.uniqueKey] = gxGlobalAttribute?.name;
    });
    // set latest lables from monitoring for default values
    this.histIncomeStatementMap = this.histIncomeStatementMap.map((qxGxAttrMap: AttrMap) => {
      if (this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey]) {
        return {
          ...qxGxAttrMap,
          gxLabel: this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey],
        };
      } else {
        return { ...qxGxAttrMap };
      }
    });

    this.histBalanceSheetMap = this.histBalanceSheetMap.map((qxGxAttrMap: AttrMap) => {
      if (this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey]) {
        return {
          ...qxGxAttrMap,
          gxLabel: this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey],
        };
      } else {
        return { ...qxGxAttrMap };
      }
    });

    this.histCashflowStatementMap = this.histCashflowStatementMap.map((qxGxAttrMap: AttrMap) => {
      if (this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey]) {
        return {
          ...qxGxAttrMap,
          gxLabel: this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey],
        };
      } else {
        return { ...qxGxAttrMap };
      }
    });

    this.projectedAttributes = this.projectedAttributes.map((qxGxAttrMap: AttrMap) => {
      if (this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey]) {
        return {
          ...qxGxAttrMap,
          gxLabel: this.gxGlobalKeyAndLabels[qxGxAttrMap.gxKey],
        };
      } else {
        return { ...qxGxAttrMap };
      }
    });
  }

  onIncomeStatementUpdate(e) {
    console.log(e, 'Update attr map');
    // const payLoad = {
    //   ...e.row,
    //   gxKey: e.value.value.uniqueKey,
    //   gxLabel: e.value.value.name
    // }
    // console.log(payLoad, 'Payload...')
    this.attrsService.updateIncomeStatementAttrs(e);
    this.isDirty = true;
    // this.cdr.detectChanges();
  }

  onHistBalanceSheetUpdate(e) {
    this.attrsService.updateBalanceSheetMap(e);
    this.isDirty = true;
  }

  onHistCashflowUpdate(e) {
    this.attrsService.updateHistCashflowMap(e);
    this.isDirty = true;
  }

  onProjectedAttrsUpdate(e) {
    this.attrsService.updateProjectedAttributesMap(e);
    this.isDirty = true;
  }

  onReplicateMapClick() {
    console.log(this.attributeListMapId);
  }

  setDataForReplicateMap() {}

  onSaveClick() {
    this.attrsService.createAttributeMap(this.mapNameFc.value.trim(), this.uploadDate, this.fundId).subscribe(
      (response) => {
        console.log(response, 'Response create attribute map...');
        this.toastService.openSnackBar('Successfully created attribute map.');
        if (this.mode === ATTRIBUTES_LIST_MODE.REPLICATE) {
          this.router.navigate(['../../attributes'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
          });
        } else {
          this.router.navigate(['../attributes'], {
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge'
          });
        }
      },
      (err) => {
        console.log('Failed to create attribute map', err);
        this.toastService.openSnackBar('Failed to create attribute map.');
      }
    );
  }

  goBack() {
    if (this.mapNameFc.dirty || this.isDirty) {
      const dialogRef = this.utilService.showConfirmMessage('Are you sure you want to discard the changes?', 'Yes', 'No');

      dialogRef.afterClosed().subscribe((userResponse: string) => {
        if (userResponse === 'Yes') {
          this.location.back();
        }
      });
    } else {
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
    this.configMappingService.resetUploadDate();
    this.usedProjectedAttrs = {};
    this.histUsedBalanceSheetAttrs = {};
    this.histUsedCashflowStatementAttrs = {};
    this.histUsedIncomeStatementAttrs = {};
    this.isDirty = false;
    this.gxGlobalKeyAndLabels = {};
  }
}
