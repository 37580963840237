<div class="row">
  <div class="col-6 mat-box">
    <div class="first-head">
      <img
        style="width: 20px"
        src="../../../assets/imgs/circle-nodes-solid 1.svg"
      />
    </div>
    <div class="column-display">
      <span class="header-text" style="letter-spacing: initial;">{{ drillDown.item }}</span>
      <ng-template [ngIf]="drillDown.valDate != 'NA'">
        <span class="asofdate"> As of {{ drillDown.valDate }}</span>
      </ng-template>
    </div>
  </div>
  <div class="col-6">
    <div class="row zero-margin-padding">
      <div class="col-11 column-display zero-margin-padding">
        <ng-template [ngIf]="drillDown.currency">
          <span class="curr" style="color: #1D1563; text-align: right; padding-right: 0px;">
            All amounts in {{ drillDown.currency}} Mn</span>
        </ng-template>

        <span style="padding-right: 20px; margin-top: 1%; text-align: right;">
          <button mat-raised-button style="cursor: text; background: black;" class="primary-bg floating-dates">
            {{ drillDown.typeOfSource }}
          </button>
        </span>
      </div>

      <div class="col-1 close-icon zero-margin-padding">
        <span class="close-drawer" (click)="closeDrawer($event)">x</span>
      </div>
    </div>
  </div>
</div>

<hr class="line">

<div class="row vec">
  <div class="">
    <img src="../../../assets/imgs/Vector.svg" />
    <span class="" style="font-weight: bold; color: #1D1563; padding-left: 10px">Value</span>
  </div>
  <div class="" style="margin-left: 25px; color: #9d9d9d; font-weight: 600">
    {{ drillDown.metric }}
    <ng-template [ngIf]="drillDown.suffix">
      {{drillDown.suffix}}
    </ng-template>
  </div>
</div>

<div class="row metr">
  <div class="label-column-styling">
    <img src="../../../assets/imgs/global.svg" />
    <span class="label-alignment">Sources</span>
  </div>
  <!-- <div class="drill-down-content" style="text-align: left; font-weight: bold;">Formula</div> -->
  <div
    class="drill-down-content content-label-alignment">
    <ng-template [ngIf]="drillDown.source">
      <ng-template ngFor [ngForOf]="drillDown.source" let-field let-i="index">
        <u
          *ngIf="field.type == 'value'"
          (click)="navigateToSource(field.link)"
          >{{ translateService.getLabel(field.key) }}
        </u>

        <ng-template [ngIf]="field.type == 'operator'">
          {{ translateService.getLabel(field.key) }}
        </ng-template>

        <ng-template [ngIf]="field.type == 'newLine'">
          <br />
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
</div>

<div class="row metr">
  <div class="label-column-styling">
    <img src="../../../assets/imgs/Formula.svg" />
    <span class="label-alignment">Formula</span>
  </div>
  <!-- <div class="drill-down-content" style="text-align: left; font-weight: bold;">Formula</div> -->
  <div
    class="drill-down-content content-label-alignment">
    <ng-template [ngIf]="drillDown.formula">
      <ng-template ngFor [ngForOf]="drillDown.formula" let-field let-i="index">
        <u
          *ngIf="field.type == 'value'"
          (click)="navigateToSource(field.link)"
          [style.color]="field.color"
          >{{ translateService.getLabel(field.key) }}
        </u>

        <ng-template [ngIf]="field.type == 'operator'">
          {{ field.key }}
        </ng-template>

        <ng-template [ngIf]="field.type == 'newLine'">
          <br />
        </ng-template>
      </ng-template>
    </ng-template>
  </div>
</div>

<div class="row metr">
  <div class="label-column-styling">
    <img src="../../../assets/imgs/metrics.svg" />
    <span class="label-alignment">Underlying Metrics</span>
  </div>
  <!-- <div class="drill-down-content" style="text-align: left; font-weight: bold;">Formula</div> -->
  <div class="drill-down-content content-label-alignment">
    <ng-template [ngIf]="drillDown.underlyingMetrics">
      <ng-template ngFor [ngForOf]="drillDown.underlyingMetrics" let-field let-i="index">

        <ng-template [ngIf]="field.type == 'value' || field.type == 'operator'">
          <span [style.color]="field.color">
            {{ field.key }}
          </span>
        </ng-template>

        <ng-template [ngIf]="field.type == 'newLine'">
          <br />
        </ng-template>

      </ng-template>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col note-head">
    <img src="../../../assets/imgs/i-icon.svg" />
    <div style="padding-left: 5px">
      Note: Links are Clickable and will direct you to their respective source
    </div>
  </div>
</div>
