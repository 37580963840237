import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FundListService } from '../../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { InvestmentSummaryService } from '../../qubit-x/portfolio/investment-page-summary-v2/investment-summary.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateService } from '../../services/translation.service';
import { UtilService } from '../../utils/util.service';

@Component({
  selector: 'app-drill-down',
  templateUrl: './drill-down.component.html',
  styleUrls: ['./drill-down.component.scss']
})
export class DrillDownComponent implements OnInit {
  @Output() closeEvent = new EventEmitter();

  @Input() drillDown;

  fundId;
  fundName;
  formulaList = [];
  formulaListCount;

  constructor(public translateService: TranslateService, public utilService: UtilService,
    private activatedRoute: ActivatedRoute, private fundService: FundListService,
    private navigationService:NavigationService, public invSummaryService : InvestmentSummaryService,) { }

  ngOnInit(): void {
    this.formulaList = [{formulaName: "Income Approach" , color : "#EF4949CC"}, {formulaName: "Comparable Company Method" , color : "#F9BBBB"} , {formulaName: "Comparable Transaction Method" , color : "#675611" } , {formulaName: "Custom "  ,color : "#CCA922"} , {formulaName: "Calibration Approach" , color : "#6FC469"} , {formulaName: " Listed Company Valuation " , color : "#8D1010"} , {formulaName: "Concluded Approach" , color :"#313B4A"} ] 
    this.activatedRoute.paramMap.subscribe((params) => {
      this.fundId = params.get("fundId");

      if(!this.fundId) {
        this.fundId = this.activatedRoute.snapshot.queryParamMap.get("parentId");
        if(!this.fundId) {
          this.fundId = localStorage.getItem("FUND_ID");
        }
        this.fundName= this.fundService.getFundName(this.fundId);
      }
    });

  }

  closeDrawer(event){
    this.utilService.drawerOpened = false;
    event.stopPropagation();
    this.closeEvent.emit();
  }

  navigateToValSummary(route){
    if(this.drillDown.valDateId){
      let urlParameters = "id=" + this.drillDown.valDateId + "&fv=" + 4 + "&fundName="+this.fundName;
  
      let url = environment.portalUrl + environment.pvValuation + "/#/" + route + "?" + urlParameters + "&parentId=" + this.fundId;
  
      window.open(url, "_self");
    }
  }

  navigateToSource(link) {
    switch(link) {
      case "valuation_summary": 
        this.navigateToValSummary('valuation-summary');
        break;
      
      case "transactions":
        let transObj = {
          "link": link
        }
        this.navigationService.openSidenavLinks(transObj);
        break;
      
      case "data_tab":
        this.navigateToValSummary('data');
        break;

      case "track_record":
        this.openSettingsOption();
        break;
    
      case "form":
        this.navigateToValSummary('form');
        break;

      case "valuation":
        this.navigateToValSummary('valuation');
        break;

      case "investment_summary":
        this.utilService.navigateToInvestmentSummary(this.drillDown.valDateId, this.fundId);
        break;
    }
  }

  openSettingsOption(){
    this.invSummaryService.getTrackRecordTable();
  }
}
