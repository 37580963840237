<nav-menu-header headerLabel="73 Strings"></nav-menu-header>
<!-- <div class="navigation-content">
  <h5 class="navigation-content-header">Total Applications Summary</h5>
</div>
<div class="prospects-summary-content">
  <div class="row prospects-summary-content-row">
    <div class="col card summary" *ngFor="let summary of summaryContent">
      <div class="summary-header">
        {{summary.header}}
      </div>
      <div class="summary-value">
        <span class="float-left document">
          <i class="fas fa-file-alt"></i>
        </span>
        {{summary.value}}
      </div>
      <div class="summary-footer" *ngIf="summary?.footer">
        {{summary.footer?.label}} : {{summary.footer?.value}}
      </div>
    </div>
  </div>
</div> -->

<!-- (change) -->
<div class="row">  
  <div class="col" style="text-align: right; margin-bottom: 10px; margin-right: 10px;">
    <mat-slide-toggle [(ngModel)]="showUsers" (change)="prepareUsersList()"></mat-slide-toggle> <span class="label-style" style="margin-left: 5px;">All Users</span>
  </div>
</div>

<ng-template [ngIf]="showUsers">
  <div class="prospects-report">
    <div class="mat-elevation-z8">
      <div class="header-container">
        <h5 class="navigation-content-header"><i class="fas fa-users"></i> List of Portal Users</h5>
        <div class="spinner-border loading" *ngIf="dataLoading"></div>
      </div>
      
      <div *ngIf="ums.is73sAdmin()" style="margin: 10px 16px;">        
        <mat-radio-group name="orgTypeSelection" [(ngModel)]="orgTypeSelection" (ngModelChange)="orgTypeSelectionChange($event)">
          <mat-radio-button [value]="'vc'" style="padding-right: 10px">Venture Capital</mat-radio-button>
          <mat-radio-button [value]="'corp-corporate'" style="padding-right: 10px">Corporate</mat-radio-button>
          <mat-radio-button [value]="'corp-impairement'" style="padding-right: 10px">Impairment</mat-radio-button>
          <mat-radio-button [value]="'corp-portfolio'">Portfolio</mat-radio-button>
        </mat-radio-group>
      </div>
  
      <div class="util-lis-grid">
        <ejs-grid #usersListGrid
          [dataSource]="dataSource" 
          [allowResizing]="true" 
          [allowTextWrap]="true"
          [allowSorting]="true" 
          [allowReordering]="true"  
          [allowPaging]="true" 
          [showColumnChooser]="true"
          [pageSettings]="gridPageSettings" 
          [allowFiltering]="true"
          [filterSettings]="gridFilterSettings"          
          >
          <e-columns>
            <e-column field='userName' headerText='VC Name' width='100px'></e-column>
            <e-column field='userId' headerText='Email' width='100px'></e-column>
            <e-column field='active' headerText='Status' width='100px'>
              <ng-template #template let-data>{{data.active? 'Active': ''}}</ng-template>
            </e-column>
            <e-column field='lastActiveOn' headerText='Last Active On' width='100px'></e-column>
            <e-column headerText='View Applications' width='100px'>
              <ng-template #template let-data let-index>
                <a class="view" (click)="view(index)">
                  <span>
                    <i class="fas fa-eye"></i>
                  </span>
                  View
                </a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
  
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!showUsers">
  <div class="card prospects-report users-report" style="margin-left: 10px; margin-right: 10px;">
    <div class="mat-elevation-z8 users-mat-elevation">
      <div class="header-container">
        <h4>
          <span class="icon">
            <i class="far fa-building"></i>
          </span>
          Organizations
        </h4>
        
        <!-- <div class="spinner-border loading" *ngIf="orgLoading"></div> -->
        <!-- <span class="float-right" *ngIf="is73sAdmin">
          <span class="icon" (click)="openStartApplication(startApplicationContent)">
            <button class="btn add-btn" color="primary" type="button"><i class="fas fa-plus-circle"></i> Add</button>
          </span> -->
          <!-- <span class="icon btn-icon">
            <i class="fas fa-sync"></i>
          </span> -->
        <!-- </span> -->
      </div>
  
      <div class="util-lis-grid">
        <ejs-grid #orgListGrid
          [dataSource]="orgDataSource" 
          [allowResizing]="true" 
          [allowTextWrap]="true"
          [allowSorting]="true" 
          [allowReordering]="true"  
          [allowPaging]="true" 
          [showColumnChooser]="true"
          [pageSettings]="orgGridPageSettings" 
          [allowFiltering]="true"
          [filterSettings]="gridFilterSettings"          
          >
          <e-columns>
            <e-column field='name' headerText='Name' width='100px'></e-column>
            <e-column field='createdOn' headerText='Created On' width='100px'></e-column>
            <e-column field='createdBy' headerText='Created By' width='100px'></e-column>
            <e-column headerText='View' width='100px'>
              <ng-template #template let-data>
                <a (click)="clickViewOnOrg(data.id, data.name)">
                  <span>
                    <i class="fas fa-binoculars" style="cursor: pointer;"></i>
                  </span>
                </a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
  
    </div>
  </div>
  
  <ng-template [ngIf]="userDataSource && userDataSource.length > 0">
    <div class="prospects-report">
      <div class="mat-elevation-z8">
        <div class="row">
          <div class="col" style="margin-left: 10px; margin-bottom: 5px;">
            <span class="icon">
              <i class="fas fa-user-tie"></i> Users
            </span>
          </div>
        </div>
        <div class="util-lis-grid">
          <ejs-grid #usersListGrid
            [dataSource]="userDataSource" 
            [allowResizing]="true" 
            [allowTextWrap]="true"
            [allowSorting]="true" 
            [allowReordering]="true"  
            [allowPaging]="true" 
            [showColumnChooser]="true"
            [pageSettings]="gridPageSettings" 
            [allowFiltering]="true"
            [filterSettings]="gridFilterSettings"          
            >
            <e-columns>
              <e-column field='userName' headerText='VC Name' width='100px'></e-column>
              <e-column field='userId' headerText='Email' width='100px'></e-column>
              <e-column field='active' headerText='Status' width='100px'>
                <ng-template #template let-data>{{data.active? 'Active': ''}}</ng-template>
              </e-column>
              <e-column field='lastActiveOn' headerText='Last Active On' width='100px'></e-column>
              <e-column headerText='View Applications' width='100px'>
                <ng-template #template let-data let-index>
                  <a class="view" (click)="view(index)">
                    <span>
                      <i class="fas fa-eye"></i>
                    </span>
                    View
                  </a>
                </ng-template>
              </e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </ng-template>

</ng-template>
