<nav-menu-header headerLabel="Prospects Summary"></nav-menu-header>

<div class="prospects-operation-content">
  <!-- <div class="row prospects-operation-content-row">
    <div class="col operation card" *ngFor="let s of summary">
      <div class="operation-header">
        {{s.header}}
      </div>
      <div class="operation-value">
        <span class="float-left document">
          <i class="fas fa-file-alt"></i>
        </span>
        {{s.value}}
      </div>
      <div class="operation-footer" *ngIf="operation?.footer">
        {{s?.footer?.label}} : {{s?.footer?.value}}
      </div>
    </div>
  </div> -->
  
  <div class="prospects-report">
    <div class="mat-elevation-z8">
      <div class="header-container">
        <div class="row" style="margin-bottom: 5px">
          <div class="col-4"><H5><i class="fas fa-file-alt"></i> Application Management</H5></div>
          <div class="col" style="text-align: right;">
            <mat-form-field style="padding: 0 10px; width: 350px">
              <input matInput type="text" [(ngModel)]="searchNameString" (input)="searchCompanyName($event)" placeholder="Type 3 characters to start filtering">
            </mat-form-field>
          </div>
        </div>

        <div class="spinner-border loading" *ngIf="loading"></div>
        <span class="float-right">
          <button *ngIf="!isCorpUser && (formTypeSelection === 'IV' || formTypeSelection === 'T2')"
            class="btn send-btn" 
            [disabled]="!(radioCheckedCompany === true)"
            (click)="deleteSavedForm()">
            <span class="send-icon">
              <i class="far fa-trash-alt"></i>
            </span>
          </button>

          <button *ngIf="!isCorpUser && myOrgUsers.length > 0"
            class="btn send-btn" 
            [disabled]="!(radioCheckedCompany === true) || !(selectedForm && selectedForm.assessorUserId === userId)"
            (click)="openPopupModal(assignUserContent)">
            <span class="send-icon">
              <i class="fas fa-user-tag"></i>
            </span>
            Assign
          </button>
          
          <button *ngIf="!isCorpUser && !isProduction"
            class="btn send-btn" 
            [disabled]="!(radioCheckedCompany === true && formTypeSelection === 'ENTR')"
            (click)="openPopupModal(sendToEnterpreneurContent)">
            <span class="send-icon">
              <i class="fas fa-paper-plane"></i>
            </span>
            Send to Enterpreneur
          </button>

          <button *ngIf="!isCorpUser && !isProduction" class="btn send-btn" [disabled]="!(radioCheckedCompany === true && formTypeSelection === 'ENTR')">
            <span class="send-icon">
              <i class="fas fa-paper-plane"></i>
            </span>
            Send Completed to VC
          </button>

          <button *ngIf="isCorpUser" class="icon edit-form-btn" [class.disable-form-btn]="radioCheckedCompany !== true" 
            [disabled]="radioCheckedCompany !== true" (click)="openSAFCorpForm()">

            <i class="fas fa-edit"></i> Edit
          </button>

          <button *ngIf="formTypeSelection == 'ENTR' || isCorpUser" class="icon edit-form-btn" (click)="openStartApplication(startApplicationContent)">
            <i class="fas fa-plus-circle"></i> Add
          </button>
          <!-- <span class="icon">
            <i class="fas fa-sync"></i>
          </span> -->
        </span>
      </div>
          
      <div style="margin: 10px 16px;" *ngIf="!isCorpUser">        
        <mat-radio-group name="formTypeSelection" [(ngModel)]="formTypeSelection" (ngModelChange)="formTypeSelectionChange($event)">
          <mat-radio-button [value]="'T2'" style="padding-right: 10px">T + 2</mat-radio-button>
          <mat-radio-button [value]="'T7'" style="padding-right: 10px" *ngIf="!isProduction">T + 7</mat-radio-button>
          <mat-radio-button [value]="'ENTR'" style="padding-right: 10px" *ngIf="!isProduction">Enterpreneur</mat-radio-button>
          <mat-radio-button [value]="'IV'">Saved Forms</mat-radio-button>
        </mat-radio-group>
      </div>

      <table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px;"> Select </th>
          <td mat-cell *matCellDef="let element;index as i" style="width: 80px;">
            <mat-radio-button (change)="checkedCompany(i)"></mat-radio-button>
          </td>
        </ng-container>

        <ng-container matColumnDef="projectName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <ng-template  [ngIf]="formTypeSelection === 'IV'"> Tag </ng-template>
              <ng-template  [ngIf]="formTypeSelection !== 'IV'"> Project Name </ng-template>
          </th>
          <td mat-cell *matCellDef="let element;index as i">
            <mat-form-field *ngIf="editable[formTypeSelection][i] && formTypeSelection === 'T2'; else showTag">
              <input matInput [(ngModel)]="element.projectName" maxlength="15">
            </mat-form-field>
            <ng-template #showTag>
              
              <ng-template  [ngIf]="formTypeSelection === 'IV'"> {{element.tag}} </ng-template>
              <ng-template  [ngIf]="formTypeSelection !== 'IV'"> {{element.projectName}} </ng-template>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
          <td mat-cell *matCellDef="let element">
            {{element.name}} <i class="fas fa-user-tag" *ngIf="element.assessorUserId !== userId"></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="vScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> 
            Vouch Score 
            <i class="fas fa-info-circle" style="font-size: small;" matTooltip="Assign a score to this company between 1 to 10"></i>
          </th>
          <td mat-cell *matCellDef="let element;index as i">
            <mat-form-field *ngIf="editable[formTypeSelection][i]">
              <input matInput type="number" [ngModel]="element.vScore" (input)="vScoreChange($event, element)">
            </mat-form-field>

            <ng-template [ngIf]="!editable[formTypeSelection][i]">              
              <span [matTooltip]="element.vScoreTrace">{{element.vScore}}</span>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="assignedPerson">
          <th mat-header-cell *matHeaderCellDef> Assigned Person </th>
          <td mat-cell *matCellDef="let element;index as i">
            {{element.assignees}}
          </td>
        </ng-container>

        <ng-container matColumnDef="initiationDate">
          <th mat-header-cell *matHeaderCellDef> Initiation Date </th>
          <td mat-cell *matCellDef="let element;index as i">
            {{element.initiationDate}}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> 
            <ng-template [ngIf]="formTypeSelection !== 'IV'">Status</ng-template> 
          </th>
          <td mat-cell *matCellDef="let element;index as i">
            <ng-template [ngIf]="formTypeSelection !== 'IV'">
              <ng-template [ngIf]="!editable[formTypeSelection][i] || !user73s">
                {{element.status}}
              </ng-template>
              <mat-form-field *ngIf="editable[formTypeSelection][i] && user73s">
                <mat-select disableOptionCentering [(ngModel)]="element.status">
                  <mat-option *ngFor="let status of statusValues" [value]="status">
                    {{status}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </td>
        </ng-container>
        
        <ng-container matColumnDef="lastUpdatedOn">
          <th mat-header-cell *matHeaderCellDef> Last Updated On </th>
          <td mat-cell *matCellDef="let element;index as i">
            {{element.lastUpdatedOn}}
          </td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef [style.width]="'50px'"> View </th>
          <td mat-cell *matCellDef="let element;index as i" [style.width]="'50px'">
            <span class="view-icon" *ngIf="element.status !== 'Form Initiated'">
              <i class="fas fa-binoculars" (click)="clickView(element)"></i>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef [style.width]="formTypeSelection === 'T2' || formTypeSelection === 'IV'? '50px' : '10px'"> </th>
          <td mat-cell *matCellDef="let element;index as i" [style.width]="formTypeSelection === 'T2' || formTypeSelection === 'IV'? '0px' : '50px'">
            <span class="view-icon" *ngIf="formTypeSelection === 'T2' || formTypeSelection === 'IV'">
              <i class="far fa-edit" (click)="editable[formTypeSelection][i] = !editable[formTypeSelection][i]" *ngIf="!editable[formTypeSelection][i]"></i>
              <i class="far fa-save" (click)="editable[formTypeSelection][i] = !editable[formTypeSelection][i]; saveFormStatus(element)" *ngIf="editable[formTypeSelection][i]"></i>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      <div *ngIf="!dataSource || !dataSource.data || !(dataSource.data.length > 0)">
        No forms are available.
      </div>
    </div>

    
    <div style="margin: 20px 10px; font-size: medium;">
      <span><a (click)="openSearchHome()" style="text-decoration: underline">Search</a> by company name or website instantly.</span>
    </div>

  </div>
</div>

<ng-template #startApplicationContent let-startApplicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Start Application</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="startApplicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    
    <div class="model-content">
      <div class="row modal-row">
        <div class="col-4 modal-left-col">
          Company Name
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput [(ngModel)]="companyName">
          </mat-form-field>
        </div>
      </div>
      <div class="row modal-row" *ngIf="!isCorpUser">
        <div class="col-4 modal-left-col">
          Email Id
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput [(ngModel)]="emailId">
          </mat-form-field>
        </div>
      </div>
      <div class="row modal-row" *ngIf="!isCorpUser">
        <div class="col-4 modal-left-col">
          Enterpreneur Name
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput [(ngModel)]="founderUserName">
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="!isCorpUser">
        Add Question (upto 3)
        <span class="icon" (click)="addQuestion()">
          <i class="fas fa-plus-circle"></i>
        </span>
        <div class="row question-row" *ngFor="let question of additionalQuestions;let qi=index">
          <div class="col-1">
            Q{{qi+1}}.
          </div>
          <div class="col">
            <div class="question-box">
              <div class="label-container">
                <label>Select the type Of question</label>
              </div>
              <mat-form-field>
                <mat-select disableOptionCentering [ngModel]="question.elementType" (ngModelChange)="questionInputChange(qi, $event)">
                  <mat-option *ngFor="let questionType of questionTypes" [value]="questionType.type">
                    {{questionType.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              
              <div class="label-container">
                <label>Enter your Question</label>
              </div>
              <mat-form-field>
                <input matInput [(ngModel)]="question.label">
              </mat-form-field>
              
              <div class="label-container">
                <label>Tell us More about your expectation from this question</label>
              </div>
              <mat-form-field>
                <textarea matInput rows="6" [(ngModel)]="question.toolTip"></textarea>
              </mat-form-field>
              <div *ngIf="question.elementType === 'radio' || question.elementType === 'checkbox'">
                <div class="label-container">
                  <label>
                    Options
                    <span class="icon" (click)="addOption(qi)">
                      <i class="fas fa-plus-circle"></i>
                    </span>
                  </label>
                </div>
                <div class="row option-row" *ngFor="let option of question.elementOptions;let oi=index">
                  <div class="col">
                    <mat-form-field>
                      <input matInput [(ngModel)]="option.optionValue">
                    </mat-form-field>
                  </div>
                  <div class="col-1">
                    <span class="icon" *ngIf="oi!=0" (click)="deleteOption(qi,oi)">
                      <i class="fas fa-minus-circle"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1">
            <span class="icon bottom-icon" (click)="deleteQuestion(qi)">
              <i class="fas fa-minus-circle"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
      </div>
      <div class="col">
        <button class="btn cancel-btn" (click)="startApplicationModal.dismiss('Cross click')">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
          Cancel
        </button>
        <button class="btn save-btn" (click)="startApplication()">
          <span class="icon">
            <i class="fas fa-check"></i>
          </span>
          Start
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendToEnterpreneurContent let-sendToEnterpreneurModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Send Application</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close"
      (click)="sendToEnterpreneurModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Send To 
      </div>
      <div class="col">
        Enterpreneur
        <!-- <br>
        <br> -->
        <!-- <mat-form-field class="pilled-input-form-field">
          <mat-chip-list #chipList>
            <mat-chip 
              *ngFor="let enterPreneurEmailId of enterPreneurEmailIds" 
              [selectable]="selectable" 
              [removable]="removable"
              (removed)="remove(enterPreneurEmailId)">
              {{enterPreneurEmailId}}
              <mat-icon matChipRemove *ngIf="removable" class="close-mat-icon">close</mat-icon>
            </mat-chip>
            <input 
              [matChipInputFor]="chipList" 
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur" 
              (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
        </mat-form-field> -->
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Company
      </div>
      <div class="col">
        {{modalCompanyName}}
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Message
      </div>
      <div class="col">
        <mat-form-field>
          <textarea matInput rows="6"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col">
      </div>
      <div class="col">
        <button class="btn cancel-btn" (click)="sendToEnterpreneurModal.dismiss('Cross click')">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
          Cancel
        </button>
        <button class="btn save-btn" (click)="sendToEnterpreneur()">
          <span class="icon">
            <i class="fas fa-check"></i>
          </span>
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assignUserContent let-assignUserModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Assign application to other user(s)</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close"
      (click)="assignUserModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content">
      <div class="modal-row" *ngFor="let user of myOrgUsers" style="text-align: left">
          <mat-checkbox [checked]="isUserAssigned(user.id)" (change)="orgUserSelectionChange($event, user)"><b>{{user.userName}}</b> - <i>{{user.userId}}</i></mat-checkbox>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col">
      </div>
      <div class="col">
        <button class="btn cancel-btn" (click)="sendToEnterpreneurModal.dismiss('Cross click')">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
          Cancel
        </button>
        <button class="btn save-btn" (click)="assignUsersToForm()">
          <span class="icon">
            <i class="fas fa-check"></i>
          </span>
          Save
        </button>
      </div>
    </div>
  </div>
</ng-template>