<div class="mat-elevation-z1 wrapper">
    <table mat-table [dataSource]="tabeDataSource">
      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef> Org Name </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.orgName}}
  
        </td>
      </ng-container>
      <ng-container matColumnDef="gxGlobalAttributes">
        <th mat-header-cell *matHeaderCellDef> Platform Global Attrs </th>
        <td mat-cell *matCellDef="let map;index as i" >
          {{map?.gxGlobalAttributes}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsGlobalAttributes">
        <th mat-header-cell *matHeaderCellDef> Reporting Global Attrs </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsGlobalAttributes}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="globalAttrsDiff">
        <th mat-header-cell *matHeaderCellDef>  Global Attrs Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="globalAttrsDiff !== 0">
          {{map?.globalAttrsDiff}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="gxCustomAttributes">
        <th mat-header-cell *matHeaderCellDef> Platform Custom Attrs </th>
        <td mat-cell *matCellDef="let map;index as i" >
          {{map?.gxCustomAttributes}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsCustomAttributes">
        <th mat-header-cell *matHeaderCellDef> Reporting Custom Attrs </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsCustomAttributes}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="customAttrsDiff">
        <th mat-header-cell *matHeaderCellDef>  Custom Attrs Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.customAttrsDiff !== 0">
          {{map?.customAttrsDiff}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="gxAttributeFormula">
        <th mat-header-cell *matHeaderCellDef> Platform Attr Forumula </th>
        <td mat-cell *matCellDef="let map;index as i" >
          {{map?.gxAttributeFormula}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsAttributeFormula">
        <th mat-header-cell *matHeaderCellDef> Reporting Attr Formula </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsAttributeFormula}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="attributeFormulaDiff">
        <th mat-header-cell *matHeaderCellDef>  Formula Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.attributeFormulaDiff !== 0">
          {{map?.attributeFormulaDiff}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="gxAttributeFormulaDetails">
        <th mat-header-cell *matHeaderCellDef> Platform Formulae Details </th>
        <td mat-cell *matCellDef="let map;index as i" >
          {{map?.gxAttributeFormulaDetails}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsAttributeFormulaDetails">
        <th mat-header-cell *matHeaderCellDef> Reporting Formulae Details </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsAttributeFormulaDetails}}
  
        </td>
      </ng-container>


      <ng-container matColumnDef="attributeFormulaDetailsDiff">
        <th mat-header-cell *matHeaderCellDef>  Formula Details Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.attributeFormulaDetailsDiff !== 0">
          {{map?.attributeFormulaDetailsDiff}}
  
        </td>
      </ng-container>

      <ng-container matColumnDef="gxAttributeFormulaCompanies">
        <th mat-header-cell *matHeaderCellDef> Platform Attr Formula Companies </th>
        <td mat-cell *matCellDef="let map;index as i" >
          {{map?.gxAttributeFormulaCompanies}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsAttributeFormulaCompanies">
        <th mat-header-cell *matHeaderCellDef> Reporting Attr Formula Companies </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsAttributeFormulaCompanies}}
  
        </td>
      </ng-container>


      <ng-container matColumnDef="attributeFormulaCompaniesDiff">
        <th mat-header-cell *matHeaderCellDef>  Formula Companies Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.attributeFormulaCompaniesDiff !== 0">
          {{map?.attributeFormulaCompaniesDiff}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="nodata">
        <td mat-footer-row *matFooterCellDef class="no-companies" [colSpan]="displayedOrgColumns.length">
          No delta found.
        </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedOrgColumns" (click)="onRowClick($event,row)"></tr>
      <tr mat-footer-row [hidden]="tabeDataSource?.data?.length >0" *matFooterRowDef="['nodata']">
  
     
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>