export const colors = {
    lineColors: ["#1D1563", "#CBD4FF", "#FFD32B"],
    primaryColor: "#1D1563",
    primaryColorMedium: "#9EACF3",
    primaryColorLight: "#CBD4FF",
    secondaryColor: "#FFD32B",
    secondaryColorMedium: "#EDED4A",
    secondaryColorLight: "#EEFFC6",

    chartColorsArray: ["#1D1563", "#9EACF3", "#CBD4FF", "#EEFFC6", "#EDED4A", "#FFD32B"],
    chartPreviousValuationColor: "#766DCC",
    chartCurrentValuationColor: "#1D1563",

    chartColorsArrayV2: ["#1D1563", "#5FAFF2", "#A3CDFA", "#FFD32B", "#EDED4A", "#EEFFC6", "#1D1563", "#5FAFF2", "#A3CDFA", "#FFD32B", "#EDED4A", "#EEFFC6"]

}