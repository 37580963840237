<div class="wrapper">
    <header class="main-head">Filters</header>
    <aside class="side">
        <ul>
            <li [class.active-link]="seletecedFilter === 'GEOGRAPHY'">

                <button mat-flat-button class="geo-btn" (click)="onSelectedFilterChange('GEOGRAPHY')" disableRipple>
                    Geography
                </button>
                <mat-icon class="filter-icon" *ngIf="selectedCountries?.size > 0">filter_alt</mat-icon>
                <mat-icon class="filter-icon"
                    *ngIf="!selectedCountries || selectedCountries.size < 1">filter_alt_off</mat-icon>
            </li>
            <li [class.active-link]="seletecedFilter === 'SECTOR'">
                <button mat-flat-button class="sector-btn" (click)="onSelectedFilterChange('SECTOR')" disableRipple>
                    Sector
                </button>
                <mat-icon class="filter-icon" *ngIf="selectedSectors?.size > 0">filter_alt</mat-icon>
                <mat-icon class="filter-icon"
                    *ngIf="!selectedSectors || selectedSectors.size < 1">filter_alt_off</mat-icon>
            </li>
        </ul>
    </aside>
    <div class="content">
        <ng-container *ngIf="seletecedFilter === 'GEOGRAPHY'">
            <div class="filter-sub-heading">
                <div>
                    Geography
                </div>
                <div class="selected-sort">
                    <mat-slide-toggle labelPosition="before" name="geographySelectedOnlyToggle" [disableRipple]="true"
                        [checked]="selectedCountriesOnly" (change)="showOnlySelectedCountries($event)">Selected
                        only</mat-slide-toggle>
                </div>
            </div>
            <div class="geography-name-search">
                <mat-form-field>
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput name="geographyName" placeholder="Search for geography"
                        [formControl]="geographyNameFc" />
                </mat-form-field>
            </div>
            <p class="country-list">
                <ng-container *ngFor="let country of filteredCountries">
                    <mat-checkbox *ngIf="!selectedCountriesOnly" color="primary"
                        [checked]="selectedCountries.has(country.countryCode)"
                        (change)="onCountryToggle($event, country)">
                        {{ country?.name ? country.name : country.code }}
                    </mat-checkbox>

                    <mat-checkbox *ngIf="selectedCountriesOnly && selectedCountries.has(country.countryCode)"
                        color="primary" [checked]="selectedCountries.has(country.countryCode)"
                        (change)="onCountryToggle($event, country)">
                        {{ country?.name ? country.name : country.code }}
                    </mat-checkbox>
                </ng-container>
            </p>
        </ng-container>

        <ng-container *ngIf="seletecedFilter === 'SECTOR'">
            <div class="filter-sub-heading">
                <div>
                    Sector
                </div>
                <div class="selected-sort">
                    <mat-slide-toggle labelPosition="before" name="sectorSelectedOnlyToggle" [disableRipple]="true"
                        [checked]="selectedOnlySectorsOnly" (change)="showOnlySelectedSectors($event)">Selected
                        only</mat-slide-toggle>
                </div>
            </div>
            <div class="sector-name-search">
                <mat-form-field>
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput name="sectorName" placeholder="Search for sector"
                        [formControl]="sectorNameFc" />
                </mat-form-field>
            </div>
            <p class="sector-list">
                <ng-container *ngFor="let sector of filteredSectors">
                    <mat-checkbox *ngIf="!selectedOnlySectorsOnly" color="primary"
                        [checked]="selectedSectors.has(sector)" (change)="onSectorToggle($event, sector)">
                        {{ sector }}
                    </mat-checkbox>

                    <mat-checkbox *ngIf="selectedOnlySectorsOnly && selectedSectors.has(sector)" color="primary"
                        [checked]="selectedSectors.has(sector)" (change)="onSectorToggle($event, sector)">
                        {{ sector }}
                    </mat-checkbox>
                </ng-container>
            </p>
        </ng-container>

    </div>
    <footer class="main-footer">

        <button mat-flat-button class="btn-apply" (click)="onApply()">
            Apply
        </button>
        <div class="f-separator">

        </div>
        <button mat-flat-button class="btn-close" (click)="onClose()">
            Close
        </button>
    </footer>
</div>