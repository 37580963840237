import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { colors } from 'src/app/utils/colors';
import { ValueBridgeFundLevelService } from 'src/app/value-bridge-fund-level/value-bridge-fund-level.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { UtilService } from 'src/app/utils/util.service';
import { TranslateService } from 'src/app/services/translation.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-one-bridge-system',
  templateUrl: './one-bridge-system.component.html',
  styleUrls: ['./one-bridge-system.component.scss']
})
export class OneBridgeSystemComponent {
  @Input() valueBridgeDates;
  @ViewChild('fundLevelVBGrid', { static: false }) public fundLevelVBGrid: GridComponent;
  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridToolbar = ['ColumnChooser'];
  gridFilterSettings = { type: 'Excel' };
  customAttributes = {class: 'hilightCols'};

  constructor(public utilService : UtilService, 
    public datePipe : DatePipe,
    private vbService : ValueBridgeFundLevelService, public translateService : TranslateService) { }

  @Input() fundLevelVBData = [];

  @Input() valueBridgeData;
  fundLevelVBTabularData = [];

  valuationBridge;
  companyBridge;

  waterFallLabelsForVB = false;
  waterFallLabelsForCB = false;

  @Output() totalAggregation = new EventEmitter();
  
  aggregatedWaterFallNumbers = {
    totalStartStakeValue : 0,

    totalFirstMetricImpact : 0,
    totalFirstMultipleImpact : 0,
    totalFirstNetDebtImpact : 0,
    totalFirstFxImpact : 0,
    totalFirstStakeImpact : 0,
    totalFirstOthersImpact : 0,
    totalFirstRealisedProceedsImpact : 0,
    totalFirstOthersDebtImpact: 0,
    totalFirstAdjustmentsToEquityValueImpact : 0,
    totalFirstWaterfallImpact : 0,
    
    totalEndStakeValue : 0,
  }

  ngOnChanges(){
    if(this.valueBridgeData && this.valueBridgeData.data){
      this.fundLevelVBTabularData = this.vbService.getFormattedValueBridgeDataForOneBridgeSystem(this.valueBridgeData.data); //Tabular Format Data for

      let waterFallNumbersForVB =  this.getWaterFallNumbersForFundLevelVB(this.fundLevelVBTabularData)
      this.valuationBridge = this.vbService.drawFundLevelValuationBridgeV2(waterFallNumbersForVB, this.waterFallLabelsForVB); //For Initializing the VB chart

      let waterFallNumbersForCB =  this.getWaterFallNumbersForValueCreationBridge(this.fundLevelVBTabularData);
      this.companyBridge = this.vbService.drawValueCreationBridgeV2(waterFallNumbersForCB, this.waterFallLabelsForCB); //For Initializing the CB chart

      console.log("Total Tabular Aggregates - One", this.aggregatedWaterFallNumbers);
      console.log("WaterFall numbers VB - One", waterFallNumbersForVB);
      console.log("WaterFall numbers CB - One", waterFallNumbersForCB);  
    }
  }

  refreshWaterfallGraph() {
    if(this.fundLevelVBTabularData){
      let waterFallNumbersForVB =  this.getWaterFallNumbersForFundLevelVB(this.fundLevelVBTabularData)

      const waterFallChartData = this.vbService.drawFundLevelValuationBridgeV2(waterFallNumbersForVB, this.waterFallLabelsForVB); //For Re-Initializing the VB chart

      this.valuationBridge = cloneDeep(waterFallChartData);
    }

  }

  getWaterFallNumbersForValueCreationBridge(fundLevelVBData){
    let startStakeValue = 0;
    let endStakeValue = 0;
    
    fundLevelVBData.forEach(row => {
      startStakeValue += row.startStakeValue;
      endStakeValue += (row.endStakeValue);
    })

    const waterFallNumbers = [];

    // Start Date and Intermediate Date
    waterFallNumbers.push({name: this.datePipe.transform(this.valueBridgeData.dates.startDate, 'mediumDate'), y: startStakeValue && !isNaN(startStakeValue) ? +startStakeValue : 0, color: colors.primaryColor });

    fundLevelVBData.forEach(row => {
      const changeInNAV = row.endStakeValue - row.startStakeValue;
      waterFallNumbers.push({name: row.companyName + " (1)", y: changeInNAV && !isNaN(changeInNAV) ? +changeInNAV : 0});
    })

    waterFallNumbers.push({name: this.datePipe.transform(this.valueBridgeData.dates.endDate, 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
    
    return waterFallNumbers;
  }


  getWaterFallNumbersForFundLevelVB(fundLevelVBData){
    const waterFallNumbers = [];

    this.aggregatedWaterFallNumbers = this.vbService.getAggregatedNumbersForOneBridgeSystem(fundLevelVBData);

    const keys = Object.keys(this.aggregatedWaterFallNumbers);

    keys.forEach( key => {
        if(key == "totalStartStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), y: this.aggregatedWaterFallNumbers[key] ? +this.aggregatedWaterFallNumbers[key] : 0, color: colors.primaryColor });
        }
        else if(key == "totalEndStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), isSum: true, color: colors.primaryColor });
        }
        else{
          waterFallNumbers.push({name: this.getValueBridgeLabelByKey(key), y: this.aggregatedWaterFallNumbers[key] ? +this.aggregatedWaterFallNumbers[key] : 0});
        }
    })

    return waterFallNumbers
  }

  getValueBridgeLabelByKey(key){
    let label = "";

    switch (key) {
      case "totalStartStakeValue":
        label = this.valueBridgeData.dates.startDate;
        break;
    
      case "totalFirstMetricImpact":
        label = "Metric (1)";
        break;

      case "totalFirstOthersDebtImpact":
        label = "Adjustment to Stake Equity Value (1)";
        break;  
          
      case "totalFirstMultipleImpact":
        label = "Multiple (1)";
        break;

      case "totalFirstNetDebtImpact":
        label = "Net Debt and Other Balance Sheet Adjustments (1)";
        break;
    
      case "totalFirstFxImpact":
        label = "FX (1)";
        break;
          
      case "totalFirstStakeImpact":
        label = "Stake (1)";
        break;

      case "totalFirstRealisedProceedsImpact":
        label = "Realised Proceeds (1)";
        break;

      case "totalFirstOthersImpact":
        label = "Others (1)"
        break;

      case "totalFirstAdjustmentsToEquityValueImpact":
        label = "Adjustment to Equity Value (1)"
        break;
        
      case "totalFirstWaterfallImpact":
        label = "Waterfall (1)"
        break;

      case "totalFirstNewInvestmentsImpact":
        label = "New Investments (1)"
        break;

      case "totalFirstExitInvestmentsImpact":
        label = "Exit Investments (1)"
        break;  

      case "totalEndStakeValue":
        label = this.valueBridgeData.dates.endDate;
        break;    
    }

    return label;
  }

}
