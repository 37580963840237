<div class="tranching-container">
  <div class="row" style="padding-bottom: 10px;">
    <div class="col-2 d-flex">
      <span class="main-heading mr-3 text-nowrap">{{translateService.getLabel("cx_as_of")}} </span>
      <mat-form-field class="custom-input-select">
        <mat-select  [formControl]="valuationDate" (ngModelChange)="getAllFormsAndTablesData($event)">
          <mat-option *ngFor="let valDate of valuationDates" [value]="valDate">
            {{valDate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="col-10 align-right">
      <button class="btn-custom-primary" (click)="saveInputsAndEv()"> {{translateService.getLabel("save")}} </button>
    </div> -->
  </div>

  <ng-container *ngIf="trancheValuationStatus.status !== 'IN_PROGRESS'">
    <!-- Input table -->
    <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
      <div class="col-6">
        <span class="main-heading">{{translateService.getLabel("credit_inputs")}} </span>
      </div>
      <div class="col-12">
        <form [formGroup]="inputForm">
          <div class="row">
            <div class="col-3 input-label">Type</div>
            <div class="col-2">
              <mat-form-field class="custom-input-select">
                <mat-select formControlName="type">
                  <mat-option value="CORPORATE">Corporate</mat-option>
                  <mat-option value="MID_MARKET">Mid Market</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 input-label">Geography</div>
            <div class="col-2">
              <mat-form-field class="custom-input-select">
                <mat-select formControlName="geography">
                  <mat-option value="UNITED_STATES">United States</mat-option>
                  <mat-option value="EUROPE">Europe</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-3 input-label">Round Leverage Multiple to</div>
            <div class="col-2">
              <ejs-numerictextbox formControlName="roundLeverageMultiple" class="round-leverage-input-field yellow-input"
                [showSpinButton]="false">
              </ejs-numerictextbox>
            </div>
          </div>
        </form>
        <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
          <div class="col-6">
            <button class="btn-custom-primary" (click)="saveInputs()">{{translateService.getLabel("save")}}</button>
          </div>
        </div>
      </div>
    </div>
  
    <!-- EV table -->
    <div>
      <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
        <div class="col-6">
          <span class="main-heading">{{translateService.getLabel("ev")}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-custom-border table_wrapper">
            <table class="table">
              <tr class="table-custom-header">
                <th style="width: 45%"></th>
                <th class="align-right" style="width: 10%;"></th>
                <th class="align-right" style="width: 10%;"></th>
                <th class="align-right" style="width: 15%;"></th>
                <th class="align-right" style="width: 10%;"></th>
              </tr>
              <tbody>
                <tr class="table-custom-body">
                  <td>{{translateService.getLabel("ebitda")}}</td>
                  <td></td>
                  <td></td>
                  <td class="align-right">
                    <ng-container>
                      <ejs-numerictextbox [ngClass]="evData.ebitda.overwritten ? 'yellow-input' : 'blue-input'"
                        [(ngModel)]="evData.ebitda.value"
                        (change)="evData.ebitda.overwritten = true; evData.enterpriseValue = evData.ebitda.value * evData.ebitdaMultiple.value"
                        class="input-field" format="#,###.0" [showSpinButton]="false"></ejs-numerictextbox>
                      <!-- <input [(ngModel)]="evResp.ebitda.value" class="input-field" format="#,###.0"/> -->
                    </ng-container>
                  </td>
                  <td></td>
                </tr>
                <tr class="table-custom-body">
                  <td>{{translateService.getLabel("ev_ebitda_multiple")}}</td>
                  <td></td>
                  <td></td>
                  <td class="align-right">
                    <ng-container>
                      <ejs-numerictextbox [ngClass]="evData.ebitdaMultiple.overwritten ? 'yellow-input' : 'blue-input'"
                        [(ngModel)]="evData.ebitdaMultiple.value"
                        (change)="evData.ebitdaMultiple.overwritten = true; evData.enterpriseValue = evData.ebitda.value * evData.ebitdaMultiple.value"
                        class="input-field" format="#,###.0'x'" [showSpinButton]="false"></ejs-numerictextbox>
                    </ng-container>
                  </td>
                  <td></td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("enterprise_value")}}</td>
                  <td></td>
                  <td></td>
                  <td class="align-right">{{evData?.enterpriseValue | dynamicDecimalPipe}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
        <div class="col-6">
          <button class="btn-custom-secondry" (click)="resetEV()" style="margin-right: 10px;">{{translateService.getLabel("reset")}}</button>
          <button class="btn-custom-primary" (click)="saveEV()"> {{translateService.getLabel("save")}} </button>
        </div>
      </div>
    </div>
  
    <!-- 3rd table -->
    <div>
      <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
        <div class="col-6">
          <span class="main-heading">{{translateService.getLabel("credit_company_capitalization")}}</span>
        </div>
      </div>
    </div>
  
    <!-- company capitalization table -->
    <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
      <div class="col-12">
        <div class="table-custom-border table_wrapper">
          <table class="table">
            <tr class="table-custom-header">
              <th>{{translateService.getLabel("credit_company_capitalization")}}</th>
              <th class="align-right" style="width: 17%;">{{translateService.getLabel("amount")}}</th>
              <th class="align-right" style="width: 17%;">{{translateService.getLabel("credit_ltv")}} %</th>
              <th class="align-right" style="width: 17%;">{{translateService.getLabel("net_leverage")}}</th>
              <th class="align-right"></th>
            </tr>
            <!-- <tr class="table-custom-body"
              *ngIf="!companyCapitalizationResp || !companyCapitalizationResp.tranches || companyCapitalizationResp.tranches.length === 0"> -->
              <!-- No records to display message -->
              <!-- <td colspan="8" class="normal-cell">{{translateService.getLabel("no_records_to_display")}}</td>
            </tr> -->
            <tbody *ngIf="companyCapitalizationResp">
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("cash")}}</td>
                <td class="align-right">{{companyCapitalizationResp.cash | dynamicDecimalPipe}}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <!-- <tr class="table-custom-body" *ngFor="let tranch of companyCapitalizationResp.tranches;let t=index">
                <td class="normal-cell">{{tranch.name}}</td>
                <td class="align-right">{{tranch.amount}}</td>
                <td class="align-right">{{tranch.ltv | dynamicDecimalPipe}}</td>
                <td class="align-right">{{tranch.netLeverage | dynamicDecimalPipe}}</td>
                <td class="align-center">
                  <em class="fas fa-edit action-icon primary-color"
                    (click)="openPopupEditClaim(updateCompanyCapitalization, tranch)"> </em>
                </td>
              </tr> -->
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("credit_revolver")}}</td>
                <td class="align-right">{{companyCapitalizationResp.revolver.amount | dynamicDecimalPipe}}</td>
                <td class="align-right">{{companyCapitalizationResp.revolver.ltv | dynamicDecimalPipe}}%</td>
                <td class="align-right">{{companyCapitalizationResp.revolver.netLeverage | dynamicDecimalPipe}}x</td>
                <td class="align-center">
                  <em class="fas fa-edit action-icon primary-color"
                    (click)="openPopupEditClaim(updateCompanyCapitalization, TRANCHE.REVOLVER)">
                  </em>
                </td>
              </tr>
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("credit_unitranche")}}</td>
                <td class="align-right">{{companyCapitalizationResp.uniTranche.amount | dynamicDecimalPipe}}</td>
                <td class="align-right">{{companyCapitalizationResp.uniTranche.ltv | dynamicDecimalPipe}}%</td>
                <td class="align-right">{{companyCapitalizationResp.uniTranche.netLeverage | dynamicDecimalPipe}}x</td>
                <td class="align-center">
                  <em class="fas fa-edit action-icon primary-color"
                    (click)="openPopupEditClaim(updateCompanyCapitalization, TRANCHE.UNI_TRANCHE)">
                  </em>
                </td>
              </tr>
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("total_net_debt")}}</td>
                <td class="align-right">{{companyCapitalizationResp.totalNetDebt.amount | dynamicDecimalPipe}}</td>
                <td class="align-right">{{companyCapitalizationResp.totalNetDebt.ltv | dynamicDecimalPipe}}%</td>
                <td class="align-right">{{companyCapitalizationResp.totalNetDebt.netLeverage | dynamicDecimalPipe}}x</td>
                <td></td>
              </tr>
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("enterprise_value")}}</td>
                <td class="align-right">{{companyCapitalizationResp.enterpriseValue.amount | dynamicDecimalPipe}}</td>
                <td class="align-right">{{companyCapitalizationResp.enterpriseValue.ltv | dynamicDecimalPipe}}%</td>
                <td class="align-right">{{companyCapitalizationResp.enterpriseValue.netLeverage | dynamicDecimalPipe}}x</td>
                <td></td>
              </tr>
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("credit_common_equity")}}</td>
                <td class="align-right">{{companyCapitalizationResp.commonEquity.amount | dynamicDecimalPipe}}</td>
                <td class="align-right">{{companyCapitalizationResp.commonEquity.ltv | dynamicDecimalPipe}}%</td>
                <td class="align-right">{{companyCapitalizationResp.commonEquity.netLeverage | dynamicDecimalPipe}}x</td>
                <td></td>
              </tr>
              <tr class="table-custom-body">
                <td class="normal-cell">{{translateService.getLabel("credit_distressed_equity")}}</td>
                <td class="align-right">{{companyCapitalizationResp.distressedEquity | dynamicDecimalPipe}}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="col">
        <button class="btn-custom-primary" (click)="openPopupCompanyCapitalization(addCompanyCapitalization)">
          {{translateService.getLabel("add")}} </button>
      </div> -->
    </div>
  
    <!-- 4th table -->
    <div>
      <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
        <div class="col-6">
          <span class="main-heading">{{translateService.getLabel("credit_market_capitalization")}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-custom-border table_wrapper">
            <table class="table">
              <tr class="table-custom-header">
                <th>{{translateService.getLabel("credit_market_capitalization")}}</th>
                <th class="align-right" style="width: 20%;">{{translateService.getLabel("amount")}}</th>
                <th class="align-right" style="width: 20%;">{{translateService.getLabel("credit_ltv")}} %</th>
                <th class="align-right" style="width: 20%;">{{translateService.getLabel("net_leverage")}}</th>
              </tr>
              <!-- <tr class="table-custom-body"
                *ngIf="!marketCapitalizationResp || !marketCapitalizationResp.tranches || marketCapitalizationResp.tranches.length === 0">
                <td colspan="8" class="normal-cell"> {{translateService.getLabel("no_records_to_display")}}</td>
              </tr> -->
              <tbody *ngIf="marketCapitalizationResp">
                <!-- <tr class="table-custom-body" *ngFor="let item of marketCapitalizationResp.tranches; let i = index">
                  <td class="normal-cell">{{item.name}}</td>
                  <td class="align-right">{{item.amount}}</td>
                  <td class="align-right">{{item.ltv}}</td>
                  <td class="align-right">{{item.netLeverage}}</td>
                </tr> -->
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_revolver")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.revolver.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.revolver.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.revolver.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_unitranche")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.uniTranche.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.uniTranche.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.uniTranche.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body bold-row">
                  <td class="normal-cell">{{translateService.getLabel("credit_net_senior_debt")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.netSeniorDebt.amount  | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.netSeniorDebt.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.netSeniorDebt.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_preferred_equity")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.preferredEquity.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.preferredEquity.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.preferredEquity.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_common_equity")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.commonEquity.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.commonEquity.ltv  | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.commonEquity.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_distressed_equity")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.distressedEquity.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.distressedEquity.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.distressedEquity.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
                <tr class="table-custom-body bold-row">
                  <td class="normal-cell">{{translateService.getLabel("enterprise_value")}}</td>
                  <td class="align-right">{{marketCapitalizationResp.enterpriseValue.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketCapitalizationResp.enterpriseValue.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketCapitalizationResp.enterpriseValue.netLeverage | dynamicDecimalPipe}}x</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  
    <!-- 5th table -->
    <div>
      <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
        <div class="col-6">
          <span class="main-heading">{{translateService.getLabel("credit_market_re_tranching")}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="table-custom-border table_wrapper">
            <table class="table">
              <tr class="table-custom-header">
                <th>{{translateService.getLabel("credit_market_re_tranching")}}</th>
                <th class="align-right" style="width: 17%;">{{translateService.getLabel("allocation")}}</th>
                <th class="align-right" style="width: 17%;">{{translateService.getLabel("credit_ltv")}} %</th>
                <th class="align-right" style="width: 17%;">{{translateService.getLabel("weight")}}</th>
                <th class="align-right" style="width: 17%;">{{translateService.getLabel("credit_market_spread")}}</th>
              </tr>
              <!-- <tr class="table-custom-body"
                *ngIf="!marketTranchingResp || !marketTranchingResp.tranches || marketTranchingResp.tranches.length === 0">
                <td colspan="8" class="normal-cell"> {{translateService.getLabel("no_records_to_display")}}</td>
              </tr> -->
              <tbody *ngIf="marketTranchingResp">
                <!-- <tr class="table-custom-body" *ngFor="let item of marketTranchingResp.tranches; let i = index">
                  <td class="normal-cell">{{item.name}}</td>
                  <td class="align-right">{{item.allocation}}</td>
                  <td class="align-right">{{item.ltv}}</td>
                  <td class="align-right">{{item.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{item.marketSpread | dynamicDecimalPipe}}%</td>
                </tr> -->
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_revolver")}}</td>
                  <td class="align-right">{{marketTranchingResp.revolver.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.revolver.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.revolver.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.revolver.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_unitranche")}}</td>
                  <td class="align-right">{{marketTranchingResp.uniTranche.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.uniTranche.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.uniTranche.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.uniTranche.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body bold-row">
                  <td class="normal-cell">{{translateService.getLabel("credit_net_senior_debt")}}</td>
                  <td class="align-right">{{marketTranchingResp.netSeniorDebt.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.netSeniorDebt.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.netSeniorDebt.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.netSeniorDebt.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_preferred_equity")}}</td>
                  <td class="align-right">{{marketTranchingResp.preferredEquity.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.preferredEquity.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.preferredEquity.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.preferredEquity.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_common_equity")}}</td>
                  <td class="align-right">{{marketTranchingResp.commonEquity.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.commonEquity.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.commonEquity.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.commonEquity.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body">
                  <td class="normal-cell">{{translateService.getLabel("credit_distressed_equity")}}</td>
                  <td class="align-right">{{marketTranchingResp.distressedEquity.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.distressedEquity.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.distressedEquity.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.distressedEquity.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
                <tr class="table-custom-body bold-row">
                  <td class="normal-cell">{{translateService.getLabel("credit_market_tranching")}}</td>
                  <td class="align-right">{{marketTranchingResp.market.allocation | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{marketTranchingResp.market.ltv | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.market.weight | dynamicDecimalPipe}}%</td>
                  <td class="align-right">{{marketTranchingResp.market.marketSpread | dynamicDecimalPipe}}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row Isa-row">
      <div class="col-6">
        <span class="ISAtextInputLabel mr-5">Investment Specific Adjustment</span>
        <i class="far fa-edit action-icon"  (click)="openInvestmentSpecificAdjustment()"></i>
      </div>
      <div class="col-6 text-right">
        <span class="ISAValue">{{ isaValue | dynamicDecimalPipe }}%</span>
      </div>
    </div>
  
    <div class="row">
      <div class="col-12 row align-items-center conclude-tranching">
        <div class="conclude-tranching-desc input-label">{{translateService.getLabel("credit_tranching_analysis_info")}}?
        </div>
        <div class="col-2">
          <mat-form-field class="custom-input-select">
            <mat-label>{{translateService.getLabel("select")}}</mat-label>
            <mat-select name="type" [formControl]="conclude" (selectionChange)="onTranchngAnalysisConclude()">
              <mat-option [value]="true"> {{translateService.getLabel("yes")}} </mat-option>
              <mat-option [value]="false"> {{translateService.getLabel("no")}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-6">
        <button (click)="evaluate()" class="btn-custom-primary">{{translateService.getLabel("evaluate")}}</button>
        <p style="margin-top: 15px;"><em>{{
          trancheValuationStatus.status === 'COMPLETED' ?
          'Last Processed at ' + getPrettyDate(trancheValuationStatus.processedAt) + 
            ', with dicount rate of ' + (trancheValuationStatus.discountRate | dynamicDecimalPipe) + '%' :
          ''
          }}</em></p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="trancheValuationStatus.status === 'IN_PROGRESS'">
    <div class="mt-2 mtb-2">
      <p style="text-align: center;">We are evaluating tranche valuation</p>
      <p style="text-align: center;"><em>Please check back in a few minutes</em></p>
    </div>
  </ng-container>

<!-- update company capitalization popup -->
<ng-template #updateCompanyCapitalization let-updateCompanyCapitalization>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">{{popupHeader}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="updateCompanyCapitalization.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <form [formGroup]="updateTrancheForm"> -->
    <div class="modal-body claims-modal-body">
      <div class="model-content">
        <div class="row claims-container">
          <!-- <div>
            <mat-label>{{translateService.getLabel("credit_tranche")}}</mat-label>
            <mat-form-field class="security-field">
              <input matInput class="label-padding" formControlName="securityName" [matAutocomplete]="autoSecurities"  required>
              <mat-autocomplete  #autoSecurities="matAutocomplete">
                <ng-container *ngFor="let option of allTrancheSecurities ;let index = index">
                  <mat-option [value]="option.securityName" style="line-height: unset;">
                    {{option.securityName}} <br>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
          <div class="col-6">
            <!-- <div >Amount</div> -->
            <div>
              <mat-label>{{translateService.getLabel("amount")}}</mat-label><br />
                <mat-form-field class="number-textbox custom-textbox tranche-amount align-right">
                  <input matInput [(ngModel)]="trancheInformation.value" type="number">
                </mat-form-field>
            </div>
          </div>

          <div class="col-6">
            <div>
              <mat-label>Securities</mat-label><br />
              <mat-select class="security-dropdown" [(ngModel)]="trancheInformation.securities" multiple>
                <mat-option *ngFor="let security of securityIdToSecurityName | keyvalue" [value]="security.key">
                  {{security.value}}
                </mat-option>
              </mat-select>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn-custom-secondry" (click)="updateCompanyCapitalization.dismiss('Cross click')">
        {{translateService.getLabel("cancel")}}
      </button>
      <button type="button" (click)="updateTranche()" class="btn-custom-primary" >
        {{translateService.getLabel("save")}}
      </button>
    </div>
  <!-- </form> -->
</ng-template>

<ng-template #investmentSpecificAdjustmentPopup let-investmentSpecificAdjustment>
  <div class="modal-header">
    <div class="modal-title"><span class="font-weight-500">Investment Specific Adjustment</span></div>
    <button #closeBtn type="button" class="close" aria-label="Close"
      (click)="investmentSpecificAdjustment.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="section">
      <div class="row">
        <div class="col-6">
          {{translateService.getLabel("credit_isa_category")}}
        </div>
        <div class="col-6 label_format column-2">
          <select class="tableRow" [(ngModel)]="isaCategory">
            <option value="CALL_PROTECTION">{{translateService.getLabel("credit_call_protection")}}</option>
            <option value="RECENT_CLOSE">{{translateService.getLabel("credit_recent_close")}}</option>
            <option value="PERFORMANCE">{{translateService.getLabel("performance")}}</option>
            <option value="THIRD_PARTY_RANGE">{{translateService.getLabel("credit_third_party_range")}}</option>
            <option value="OTHER">{{translateService.getLabel("credit_other")}}</option>
            <option value="PRELIM_PLACEHOLDER">{{translateService.getLabel("credit_prelim_placeholder")}}</option>
            <option value="NA">{{translateService.getLabel("credit_na")}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="section"> 
      <div class="row">
        <div class="col-6">
          {{translateService.getLabel("credit_isa_value")}}
        </div>
        <div class="col-6 label_format column-2">
          <ejs-numerictextbox [(ngModel)]="isaValue" class="inputbox-field yellow-inputbox" max="100" format="#,###.0'%'" [showSpinButton]="false">
          </ejs-numerictextbox>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="row">
        <div class="col-6">
          {{translateService.getLabel("add_comment")}}
        </div>
        <div class="col-6 label_format column-2">
          <div class="comments-container">
            <textarea [(ngModel)]="isaComment" style="height: 100px" cols="70" [placeholder]="translateService.getLabel('add_comment')"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row modal-footer-buttons">
      <div class="col-6">
        <button class="btn-custom-primary"
          (click)="saveInvestmentSpecificAdjustment(); investmentSpecificAdjustment.dismiss('Cross click');">
          {{translateService.getLabel("save")}}
        </button>
      </div>
      <div class="col-6">
        <button class="btn-custom-secondry" (click)="investmentSpecificAdjustment.dismiss('Cross click');">
          {{translateService.getLabel("close")}}
        </button>
      </div>
    </div>
  </div>
</ng-template>