import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { PortFolioService } from '../portfolio.service';
import {map, startWith} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import { TranslateService } from 'src/app/services/translation.service';

export interface User {
  name: string;
}

@Component({
  selector: 'app-investment-v2',
  templateUrl: './investment-v2.component.html',
  styleUrls: ['./investment-v2.component.scss']
})
export class InvestmentV2Component implements OnInit {

  constructor(private dataService: DataService, private router: Router,
    private route: ActivatedRoute,
    public fundService : FundListService, public portfolioService : PortFolioSummaryServiceV2, 
    public investmentService : InvestmentSummaryService,
    public utilService : UtilService, private modalService : NgbModal,
    public translateService: TranslateService) { }

  selectedDate;
  allValuationDates;
  loadingCaptable : boolean = false;
  loadingInvestorName: boolean = false;
  myControl = new FormControl();
  filteredOptions: Observable<User[]>;
  options: User[] = [];
  bsAssignmentName;
  selectedBSAssignment = "";
  selectedFund;
  capTableData;
  capTableExists = true;
  investorWiseStakeValueAdjustments = {}
  investors = [];
  investorName = "";
  stakeValueAdjustments = {
    totalValueBeforeAdjustment: 0,
    totalValueAfterAdjustment: 0,
    adjustments: [],
    headerLabel: ""
  }

  investmentTableData = [];

  selectedListOfInvestors = {};

  company;

  selectedStakeFundName = "";
  customStake = {
    manualEntry: 100,
    waterFallModel: {},
    capTableModel: {},
    selectedOption: "manualEntry"
  }

  ngOnInit(): void {    
    const companyId = this.route.snapshot.queryParamMap.get('companyId');
    const parentId = this.route.snapshot.queryParamMap.get('parentId');

    if(!parentId) {
      this.router.navigateByUrl('/users');
      return;

    } else if(!companyId) {
      this.router.navigateByUrl('/portfolio/' + parentId);

    } else if(!this.investmentService.allValuationDates){
      this.router.navigateByUrl('/company-valuation/' + companyId + "?parentId=" + parentId);

    } else {
      this.init();
    }
  }

  async init() {
    this.investmentTableData = [];
    this.loadingCaptable = true;
    
    this.allValuationDates = this.investmentService.allValuationDates;

    this.allValuationDates = this.allValuationDates.filter( form => {
      return form.status != "Initiated";
    });

    this.selectedDate = this.allValuationDates[0];
    this.selectedFund = this.fundService.getFundById(this.portfolioService.selectedFundId);

    this.company = this.allValuationDates.find(c => !c.groupFormId);

    console.log("company", this.company);
    console.log("selectedDate", this.selectedDate);

    try {
      const investmentAPIData = await this.dataService.getWidgetDataFromDB("INVESTMENTS", this.company.id).toPromise();
      this.selectedListOfInvestors = investmentAPIData.body["response"][0].widgetData
    } catch(e) {
      this.initCompanyWithSelectedFund();
    }

    await this.getBSLPInvestorData();

    try {
      const apiData = await this.dataService.getWidgetDataFromDB("STAKE_VALUE_ADJUSTMENTS", this.selectedDate.id).toPromise();
      this.investorWiseStakeValueAdjustments = apiData.body["response"][0].widgetData ? apiData.body["response"][0].widgetData : {};
    } catch (error) {
      console.error("Failed to fetch the STAKE_VALUE_ADJUSTMENTS widget", error);
    }

    let tableData;
    try {
      const captableAPIData = await this.dataService.getConcludedWaterfallVersion(this.selectedDate.id).toPromise();
      tableData = captableAPIData.body['response'].table as any
      this.capTableData = tableData;
      this.options = tableData.map( (investorNames, index) => {
        if(index != 0 && index != tableData.length - 1 && investorNames[0]){
          return { name: investorNames[0] };
        }
      }).filter( v => v);

      this.capTableExists = true;

    } catch (error) {
      console.log("Failed to fetch CapTable Data from DB", error);
      this.capTableExists = false;
    }  

    if(this.selectedListOfInvestors[this.selectedDate.id] && this.selectedListOfInvestors[this.selectedDate.id].length > 0){
      
      this.selectedListOfInvestors[this.selectedDate.id].forEach( data => {
      
        const nameAlreadyExists = this.investors.find(option => {
          return data.investorName == option.name;
        })

        if(!nameAlreadyExists){
          this.investors.push({name: data.investorName});
        }
      })

    }   

    this.loadingCaptable = false;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice()));
  }

  initCompanyWithSelectedFund(){
    this.allValuationDates.forEach( valDate => {
      if(!this.selectedListOfInvestors[valDate.id]){
        this.selectedListOfInvestors[valDate.id] = []
      }
  
      this.selectedListOfInvestors[valDate.id].push({
        investorName : this.fundService.getFundById(this.portfolioService.selectedFundId).name,
        stake: valDate.investment ? valDate.investment.equityValue.stake : 0,
        noOfShares: 0
      })
    })
  }

  openCustomStakeSelection(content, data){
    this.selectedStakeFundName = data.investorName;
    this.customStake.capTableModel = data.capTableModel;
    this.customStake.waterFallModel = data.waterFallModel;

    this.customStake.selectedOption = data.selectedStakeOption; 
    this.customStake.manualEntry = this.utilService.getValidNumber(data.stakeManualEntry);

    this.modalService.open(content, { centered: true , size: 'lg', backdrop: 'static' });
  }

  updateCustomStakeSelection(typeOfStake){
    this.customStake.selectedOption = typeOfStake;
  }

  saveCustomStake(){
    if(this.selectedListOfInvestors[this.selectedDate.id] && this.selectedListOfInvestors[this.selectedDate.id].length > 0){     
      this.selectedListOfInvestors[this.selectedDate.id].find( data => {
      
        if(data.investorName == this.selectedStakeFundName){
          data.selectedStakeOption = this.customStake.selectedOption;

          if(this.customStake.selectedOption == "capTable") {
              data.stake = this.customStake.capTableModel["stake"];

          } else if(this.customStake.selectedOption == "manualEntry") {
            data.stakeManualEntry = this.customStake.manualEntry;
            data.stake = this.customStake.manualEntry;

          } else if(this.customStake.selectedOption == "impliedStakeWaterfall") {
            data.stake = this.customStake.waterFallModel["stake"];
          }
        }
      })
    }
    
    this.utilService.showLoadingPopup();

    this.saveInvestmentWidgetData(true);
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }

  async getBSLPInvestorData(){
    await this.dataService.getBSLPInvestorData(this.company.id, this.selectedDate.id, this.portfolioService.selectedFundId, this.selectedDate.valuationDate).then(res => {
      this.investmentTableData = res.body['response'];
      console.log("investmentTableData", this.investmentTableData);
    }).catch(e => {
      this.utilService.showMessage(this.translateService.getLabel("err_fetch_BSLP_data"), this.translateService.getLabel("ok"), true);
    });
  }

  async valuationDateChange(date){
    this.loadingCaptable = true;
    this.capTableExists = true;
    this.selectedDate = cloneDeep(date);
    this.bsAssignmentName = "";
    this.selectedBSAssignment = "";
    this.options = [];
    this.capTableData = null;
    this.investorWiseStakeValueAdjustments = {};
    this.investors = [];

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice()));

    await this.getBSLPInvestorData();

    try {
      const apiData = await this.dataService.getWidgetDataFromDB("STAKE_VALUE_ADJUSTMENTS", this.selectedDate.id).toPromise();
      this.investorWiseStakeValueAdjustments = apiData.body["response"][0].widgetData ? apiData.body["response"][0].widgetData : {};
    } catch (error) {
      console.error("Failed to fetch the STAKE_VALUE_ADJUSTMENTS widget", error);
    }  

    let tableData;
    try {
      const captableAPIData = await this.dataService.getConcludedWaterfallVersion(this.selectedDate.id).toPromise();
      tableData = captableAPIData.body['response'].table as any
      this.capTableData = tableData;
      this.options = tableData.map( (investorNames, index) => {
        if(index != 0 && index != tableData.length - 1 && investorNames[0]){
          return { name: investorNames[0] };
        }
      }).filter( v => v);

      this.capTableExists = true;

    } catch (error) {
      console.log("Failed to fetch CapTable Data from DB", error);
      this.capTableExists = false;
    }  

    if(this.selectedListOfInvestors[this.selectedDate.id] && this.selectedListOfInvestors[this.selectedDate.id].length > 0){
      this.selectedListOfInvestors[this.selectedDate.id].forEach( data => {

        const nameAlreadyExists = this.investors.find(option => {
          return data.investorName == option.name;
        })

        if(!nameAlreadyExists){
          this.investors.push({name: data.investorName});
        }
      })
    }   
    this.loadingCaptable = false;
  }

  addOption(option){
    this.loadingInvestorName = true;

    const nameAlreadyExists = this.investors.find(data => {
      return data.name == option.name
    })

    if(!nameAlreadyExists){
      this.investors.push(option);
      //adding investors
      let noOfShares = this.getTotalNoOfSharesbyCaptableData(this.capTableData, option.name);
      let stake = this.calculateStake(this.capTableData, option.name);

      if(!this.selectedListOfInvestors[this.selectedDate.id]) {
        this.initSelectedListOfInvestors();
      }

      this.selectedListOfInvestors[this.selectedDate.id].push({
        investorName : option.name,
        stake: stake,
        noOfShares: noOfShares,
        // bsAssignment: ""
      });

      this.investors = this.selectedListOfInvestors[this.selectedDate.id].map(inv => { return { name: inv.investorName } } );

      this.saveInvestmentWidgetData(true);
    }else{
      this.loadingInvestorName = false;
    }
  }

  initSelectedListOfInvestors() {
    this.selectedListOfInvestors[this.selectedDate.id] = [{
      investorName : this.investmentTableData[0].investorName,
      stake: this.investmentTableData[0].stake,
      noOfShares: this.investmentTableData[0].noOfShares,
      bsAssignment: this.investmentTableData[0].bsAssignment
    }]
  }

  removeOption(index){
    this.loadingInvestorName = true;
    const removedInvestor = this.investors.splice(index, 1)

    if(this.selectedListOfInvestors[this.selectedDate.id] && this.selectedListOfInvestors[this.selectedDate.id].length > 0){
      const investorToBeDeleted = this.selectedListOfInvestors[this.selectedDate.id].findIndex( data => data.investorName == removedInvestor[0].name)
      this.selectedListOfInvestors[this.selectedDate.id].splice(investorToBeDeleted, 1);
      this.saveInvestmentWidgetData(true);
    }

  }

  enableSelector(row, selectedBSAssignment){
    if(row.isTotal) return;
    
    const investorName = row.investorName;

    if(this.bsAssignmentName != investorName) {
      this.bsAssignmentName = investorName
      this.selectedBSAssignment = selectedBSAssignment

    } else {
      // If dropdown is already shown for the same investor, hide the dropdown.
      this.bsAssignmentName = null;
      this.selectedBSAssignment = null;
    }
  }

  addSelectedBSAssignment(selectedBSAssignment){
    this.selectedBSAssignment = selectedBSAssignment;
    this.selectedListOfInvestors[this.selectedDate.id].forEach(data => {
      if(data.investorName == this.bsAssignmentName){
        data.bsAssignment = selectedBSAssignment;
      }
    });
    this.saveInvestmentWidgetData();
    // this.investmentTableData.forEach(data => {
    //   if(data.investorName == this.bsAssignmentName){
    //     data.bsAssignment = selectedBSAssignment;
    //     // console.log("data bsAssignment", data.bsAssignment);
    //   }
    // })
  }

  openAdjustmentsPopUp(content, rowData){
    this.investorName = rowData.investorName;
    this.stakeValueAdjustments.totalValueBeforeAdjustment = rowData.stakeValue;
    this.stakeValueAdjustments.adjustments = this.investorWiseStakeValueAdjustments[rowData.investorName] 
    && this.investorWiseStakeValueAdjustments[rowData.investorName].adjustments ? this.investorWiseStakeValueAdjustments[rowData.investorName].adjustments : [];
    this.stakeValueAdjustments.headerLabel = this.translateService.getLabel("stake_value") + " (" + this.getInvestorNameTranslations(rowData.investorName) + ") "; 

    this.modalService.open(content, { centered: true, windowClass: "stake-value-adjustments"});
  }

  emittedAdjustedStakeValue(adjustment){
    this.utilService.showLoadingPopup();
    const investorName = this.investorName;
    if(!this.investorWiseStakeValueAdjustments[investorName]){
      this.investorWiseStakeValueAdjustments[investorName] = {
        adjustments: []
      }
    }

    this.investorWiseStakeValueAdjustments[investorName].adjustments = adjustment.adjustments;

    this.dataService.saveWidgetDataToDB("STAKE_VALUE_ADJUSTMENTS", this.investorWiseStakeValueAdjustments, this.selectedDate.id).subscribe(async res => {
      console.log("Successfully saved adjustments", res);
      this.utilService.showMessage(this.translateService.getLabel("suc_adjustment_success"), this.translateService.getLabel("ok"))

      const investorInSavedWidget = this.selectedListOfInvestors[this.selectedDate.id];
   
      let noOfShares = 0;
      let customParameters = {};

      investorInSavedWidget.forEach(inv => {
        if(inv.investorName == investorName) {
          noOfShares = inv.noOfShares
          customParameters["bsAssignment"] = inv["bsAssignment"] ? inv["bsAssignment"] : "";
        }
      });

      this.utilService.showLoadingPopup();
    
      await this.getBSLPInvestorData();
    }, error => {
      console.log("Failed to save adjustments", error);
      this.utilService.showMessage(this.translateService.getLabel("err_save_adjustment"), this.translateService.getLabel("ok"), true)
    })

    this.stakeValueAdjustments = {
      totalValueBeforeAdjustment: 0,
      totalValueAfterAdjustment: 0,
      adjustments: [],
      headerLabel: ""
    }

    this.investorName = "";
  }

   saveInvestmentWidgetData(showMessage?) {
     this.utilService.showLoadingPopup();

     this.dataService.saveWidgetDataToDB("INVESTMENTS", this.selectedListOfInvestors, this.company.id).subscribe(async success => {
      // console.log('Succesfuly Saved The investor names',success);
      await this.getBSLPInvestorData();
      if(showMessage) {
        this.utilService.showMessage(this.translateService.getLabel("succ_investment_info"), this.translateService.getLabel("ok"));
      } else {
        this.utilService.closeAllPopups();
      }
      this.loadingInvestorName = false;
    }, error => {
      this.loadingInvestorName = false;
      console.log('Failed to Save The investor names', error);
      this.utilService.showMessage(this.translateService.getLabel("err_investment_info"), this.translateService.getLabel("ok"), true);
    })
  }

  getTotalNoOfSharesbyCaptableData(capTableData, investorName){
    let totalNoOfShares = 0;

    const columnHeaders = capTableData[0];
    const indexOfTotalSharesColumn = columnHeaders.findIndex( name => name == "Total Shares");
    if(indexOfTotalSharesColumn >= 0){
      const investorExists = capTableData.find(investors => investors[0] == investorName);
      totalNoOfShares = investorExists[indexOfTotalSharesColumn] ? investorExists[indexOfTotalSharesColumn] : 0
    }

    return totalNoOfShares;
  }

  calculateStake(capTableData, investorName){
    const investorDetails = capTableData.find( data => data[0] === investorName);
    let stake = 0.0;
    if(investorDetails){
      // Assuming absolute value of stake is coming from excel
      stake = investorDetails[investorDetails.length - 1]; //* 100;
    }
    return stake;
  }

  closeInvestments(){
    this.router.navigateByUrl('/company-valuation/' + this.company.id + "?parentId=" + this.portfolioService.selectedFundId);
  }
  getInvestorNameTranslations(investorName){
    if(investorName == "Others")
    return this.translateService.getLabel('others')
    else
    return investorName;
  }
  getSelectedStakeFundName(selectedStakeFundName){
    if(selectedStakeFundName == "Others")
    return this.translateService.getLabel('others')
    else
    return selectedStakeFundName;
  }
}
