<nav-menu-header headerLabel="{{translateService.getLabel('funds')}}" headerToBeAppended="ConsolidatedSummary" [currency]="debtPortfolioService.securityCurrency"></nav-menu-header>

<div class="top-header">
  <span style="font-size: 20px">{{debtCompanyDetails?.companyName}}</span>
</div>

<div class="page-header">
    <nav class="page-header-navigation">
      <ul>
        <li *ngFor="let item of valuationNavigationLinks; index as i" [class.active]="item.isActive">
          <a (click)="switchTab(item.key, i);" [class.disabled]="item.isActive">{{item.title}}</a>
        </li>
      </ul>
    </nav>
</div>
<ng-template [ngIf]="activeTab=='consolidatedSummary'">
  <div class="card">
    <div style="background-color: white;">
      <div class="row" style="margin: 0px 33px 0px 33px">
        <div class="col-1">
          <label style="cursor: pointer;">
            <ng-template [ngIf]="debtCompanyDetails?.logoURL" [ngIfElse]="defaultLogo">
              <input style="display: none" accept="image/*" type="file" id="file" (input)="getImageURL($event)">
              <img [src]="debtCompanyDetails?.logoURL" width="100" height="100" (error)="companyDetails.logoURL = ''">
            </ng-template>
            <ng-template #defaultLogo>
              <input style="display:none" accept="image/*" type="file" id="file" (input)="getImageURL($event)">
              <i class="far fa-building" style="margin-left: 10px; font-size: 100px; margin-top: 10px;"></i>
            </ng-template>
          </label>
        </div>
        <div class="col-11">
          <div style="font-size: small; margin: 5px; margin-left: 15px;"> {{ debtCompanyDetails?.description }} </div>
        </div>
      </div>

      <div class="row" style="margin: 10px 33px 20px 47px">
        <div class="col-1"></div>
        <div class="col-2"> <b>{{translateService.getLabel("sector")}}:</b> {{ debtCompanyDetails?.sector }} </div>
        <div class="col-2"> <b>{{translateService.getLabel("website")}}:</b> {{ debtCompanyDetails?.website }} </div>
      </div>

      <div *ngIf="concludeOnWaterfall" class="issuer-consol-summary-grid" style="margin: 0px 30px 30px 48px">
        <app-restructured-securities [restructuredSecurities] = "restructuredSecurities" (navigateTab)="navigateWaterFall($event)"></app-restructured-securities>
      </div>
      <div *ngIf="concludeOnWaterfall" style="margin: 0px 30px 30px 48px" class="issuer-consol-summary-grid">
        <mat-accordion class="old-securities">
          <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                  <mat-panel-title >{{translateService.getLabel("old_securities")}}</mat-panel-title>
              </mat-expansion-panel-header>
        <!-- Table Skeleton -->
        <div class="skeleton-table" *ngIf="!issuerConsolSummary?.valuationDates?.length">
          <ngx-skeleton-loader count="1" [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>
        </div>
        
        <ng-template [ngIf]="issuerConsolSummary?.valuationDates?.length > 0">
          <ejs-grid #debtConsolidatedSummary [dataSource]="issuerConsolSummary?.valuationDates" [allowResizing]="true"
            [allowTextWrap]="true" [allowGrouping]="false" [allowExcelExport]="true" [allowSorting]="true"
            [allowReordering]="true" [allowPaging]="false" [pageSettings]="gridPageSettings" [showColumnChooser]="true"
            [toolbar]="gridToolbar" [allowFiltering]="true" [filterSettings]="gridFilterSettings"
            (rowSelected)="companyRowSelected($event)" (actionComplete)="saveTableState($event, CONSOLIDATED_SUMMARY_TABLE)">
            <e-columns>

              <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='75px' textAlign="left">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.securityName">
                    {{comp.securityName }}<span *ngIf="comp.currency != comp.financialCurrency"> (in {{comp.financialCurrency}} Mn)</span>
                  </ng-template>
                </ng-template>
              </e-column>

              <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px' textAlign="left">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.valutationDate">
                    {{comp.valutationDate | date:'mediumDate' }}
                  </ng-template>
                </ng-template>
              </e-column>

              <e-column field='fairValue' [headerText]='translateService.getLabel("dirty_price")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.fairValue">{{comp.convertedFairValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='percentageOfPar' [headerText]='translateService.getLabel("dirty_price_of_par")' width='85px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.percentageOfPar">{{comp.percentageOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='cleanPriceFairValue' [headerText]='translateService.getLabel("clean_price")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.cleanPriceFairValue">{{comp.convertedCleanPriceFairValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='cleanPricePercentOfPar' [headerText]='translateService.getLabel("clean_price_of_par")' width='85px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.cleanPricePercentOfPar">{{comp.cleanPricePercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='warrantValue' [headerText]='translateService.getLabel("warrant_value")' width='70px' textAlign="right">
                <ng-template #template let-comp>
                <ng-template [ngIf]="comp.warrantValue">{{comp.convertedWarrantValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='warrantPercentOfPar' [headerText]='translateService.getLabel("warrant_of_par")' width='75px' textAlign="right">
                <ng-template #template let-comp>
                <ng-template [ngIf]="comp.warrantPercentOfPar">{{comp.warrantPercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='totalValue' [headerText]='translateService.getLabel("total_value")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.totalValue">{{comp.convertedTotalValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='totalPercentOfPar' [headerText]='translateService.getLabel("total_of_par")' width='70px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.totalPercentOfPar">{{comp.totalPercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column field="securityName" type="sum">
                    <ng-template #footerTemplate>
                      {{translateService.getLabel("total")}}
                    </ng-template>
                  </e-column>
                  <e-column field="fairValue" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate [ngIf]="issuerConsolSummaryTotal?.convertedTotalFairValue">
                      {{issuerConsolSummaryTotal?.convertedTotalFairValue | dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="percentageOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.percentageOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.percentageOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                  <e-column field="cleanPriceFairValue" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalCleanPriceFairValue" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.convertedTotalCleanPriceFairValue| dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="cleanPricePercentOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.totalCleanPricePercentOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.totalCleanPricePercentOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                  <e-column field="warrantValue" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate>
                      <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalWarrantValue">{{ issuerConsolSummaryTotal.convertedTotalWarrantValue  | dynamicDecimalPipe}}</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field="warrantPercentOfPar" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate>
                      <ng-template [ngIf]="issuerConsolSummaryTotal?.totalWarrantPercentOfPar">
                        {{ issuerConsolSummaryTotal?.totalWarrantPercentOfPar| dynamicDecimalPipe}}%
                      </ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field="totalValue" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalValue" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.convertedTotalValue | dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="totalPercentOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.totalPercentOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.totalPercentOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
      </div>
      <div *ngIf="!concludeOnWaterfall" style="margin: 0px 30px 30px 48px" class="issuer-consol-summary-grid">
        <!-- Table Skeleton -->
        <div class="skeleton-table" *ngIf="!issuerConsolSummary?.valuationDates?.length">
          <ngx-skeleton-loader count="1" [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

          <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
          'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>
        </div>
        <div *ngIf="showEquityTable" style="background-color:#1D1563; color: white; padding: 10px; text-align: left;">
          {{translateService.getLabel("credit_securities")}}  
        </div>
        <ng-template [ngIf]="issuerConsolSummary?.valuationDates?.length > 0">
          <ejs-grid #debtConsolidatedSummary [dataSource]="issuerConsolSummary?.valuationDates" [allowResizing]="true"
            [allowTextWrap]="true" [allowGrouping]="false" [allowExcelExport]="true" [allowSorting]="true"
             [allowReordering]="true" [allowPaging]="false" [pageSettings]="gridPageSettings" [showColumnChooser]="true"
             [toolbar]="gridToolbar" [allowFiltering]="true" [filterSettings]="gridFilterSettings"
            (rowSelected)="companyRowSelected($event)" (actionComplete)="saveTableState($event, CONSOLIDATED_SUMMARY_TABLE)">
            <e-columns>

              <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='75px' textAlign="left">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.securityName">
                    {{comp.securityName }}
                  </ng-template>
                </ng-template>
              </e-column>

              <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px' textAlign="left">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.valutationDate">
                    {{comp.valutationDate | date:'mediumDate' }}
                  </ng-template>
                </ng-template>
              </e-column>

              <e-column field='fairValue' [headerText]='translateService.getLabel("dirty_price")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.fairValue">{{comp.convertedFairValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='percentageOfPar' [headerText]='translateService.getLabel("dirty_price_of_par")' width='85px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.percentageOfPar">{{comp.percentageOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='cleanPriceFairValue' [headerText]='translateService.getLabel("clean_price")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.cleanPriceFairValue">{{comp.convertedCleanPriceFairValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='cleanPricePercentOfPar' [headerText]='translateService.getLabel("clean_price_of_par")' width='85px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.cleanPricePercentOfPar">{{comp.cleanPricePercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='warrantValue' [headerText]='translateService.getLabel("warrant_value")' width='70px' textAlign="right">
                <ng-template #template let-comp>
                <ng-template [ngIf]="comp.warrantValue">{{comp.convertedWarrantValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='warrantPercentOfPar' [headerText]='translateService.getLabel("warrant_of_par")' width='75px' textAlign="right">
                <ng-template #template let-comp>
                <ng-template [ngIf]="comp.warrantPercentOfPar">{{comp.warrantPercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='totalValue' [headerText]='translateService.getLabel("total_value")' width='60px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.totalValue">{{comp.convertedTotalValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='totalPercentOfPar' [headerText]='translateService.getLabel("total_of_par")' width='70px' textAlign="right">
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.totalPercentOfPar">{{comp.totalPercentOfPar | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
            </e-columns>
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                  <e-column field="securityName" type="sum">
                    <ng-template #footerTemplate>
                      {{translateService.getLabel("total")}}
                    </ng-template>
                  </e-column>
                  <e-column field="fairValue" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate [ngIf]="issuerConsolSummaryTotal?.convertedTotalFairValue">
                      {{issuerConsolSummaryTotal?.convertedTotalFairValue | dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="percentageOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.percentageOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.percentageOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                  <e-column field="cleanPriceFairValue" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalCleanPriceFairValue" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.convertedTotalCleanPriceFairValue| dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="cleanPricePercentOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.totalCleanPricePercentOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.totalCleanPricePercentOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                  <e-column field="warrantValue" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate>
                      <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalWarrantValue">{{ issuerConsolSummaryTotal.convertedTotalWarrantValue  | dynamicDecimalPipe}}</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field="warrantPercentOfPar" type="sum" textAlign='Right'>
                    <ng-template #footerTemplate>
                      <ng-template [ngIf]="issuerConsolSummaryTotal?.totalWarrantPercentOfPar">
                        {{ issuerConsolSummaryTotal?.totalWarrantPercentOfPar| dynamicDecimalPipe}}%
                      </ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field="totalValue" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.convertedTotalValue" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.convertedTotalValue | dynamicDecimalPipe}}
                    </ng-template>
                  </e-column>
                  <e-column field="totalPercentOfPar" type="sum" textAlign='Right'>
                    <ng-template [ngIf]="issuerConsolSummaryTotal?.totalPercentOfPar" #footerTemplate>
                      {{ issuerConsolSummaryTotal?.totalPercentOfPar| dynamicDecimalPipe}}%
                    </ng-template>
                  </e-column>
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </ng-template>
      </div>
      <div *ngIf="showEquityTable" style="margin: 0px 30px 30px 48px" class="issuer-consol-summary-grid">
        <div style="background-color:#1D1563; color: white; padding: 10px; text-align: left;">
          {{translateService.getLabel("credit_equity_securities")}}  
        </div>
        <ejs-grid #debtConsolidatedSummary [dataSource]="equityValueSummary" [allowResizing]="true" [allowTextWrap]="true"
          [allowGrouping]="false" [allowExcelExport]="true" [allowSorting]="true" [allowReordering]="true"
          [allowPaging]="false" [pageSettings]="gridPageSettings" [showColumnChooser]="true" [toolbar]="gridToolbar"
          [allowFiltering]="true" [filterSettings]="gridFilterSettings" (rowSelected)="goToEquity()"
          (actionComplete)="saveTableState($event, CONSOLIDATED_SUMMARY_TABLE)">
          <e-columns>
            <e-column field='securityName' [headerText]='translateService.getLabel("company_name")' width='75px'
              textAlign="left">
              <ng-template #template let-data>
                {{ data?.securityName || '-' }}
              </ng-template>
            </e-column>
      
            <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px'
              textAlign="left">
              <ng-template #template let-data>
                {{ data?.valuationDate | date:'mediumDate' || '-' }}
              </ng-template>
            </e-column>
      
            <e-column field='enterpriseValue' [headerText]='translateService.getLabel("enterprise_value")' width='60px'
              textAlign="right">
              <ng-template #template let-data>
                {{ data?.enterpriseValue | dynamicDecimalPipe }}
              </ng-template>
            </e-column>
            <e-column field='equityValue' [headerText]='translateService.getLabel("equity_value")' width='65px'
              textAlign="right">
              <ng-template #template let-data>
                {{ data?.equityValue | dynamicDecimalPipe }}
              </ng-template>
            </e-column>
            <e-column field='stake' [headerText]='translateService.getLabel("stake")' width='60px'
              textAlign="right">
              <ng-template #template let-data>
                {{ data?.stake | dynamicDecimalPipe }}%
              </ng-template>
            </e-column>
            <e-column field='noOfShares' [headerText]='translateService.getLabel("noOfShares")'
              width='65px' textAlign="right">
              <ng-template #template let-data>
                {{ data?.noOfShares || '-' }}
              </ng-template>
            </e-column>
            <e-column field='fairValuePerShare' [headerText]='translateService.getLabel("credit_fair_value_per_share")' width='75px'
              textAlign="right">
              <ng-template #template let-data>
                <span *ngIf="data?.fairValuePerShare">{{ data?.fairValuePerShare | dynamicDecimalPipe }}</span>
                <span *ngIf="!data?.fairValuePerShare">{{ '-' }}</span>
              </ng-template>
            </e-column>
            <e-column field='totalValue' [headerText]='translateService.getLabel("total_value")' width='60px'
              textAlign="right">
              <ng-template #template let-data>
                {{ data?.totalValue | dynamicDecimalPipe}}
              </ng-template>
            </e-column>
            <!-- <e-column field='totalPercentOfPar' [headerText]='translateService.getLabel("total_of_par")' width='70px'
                    textAlign="right">
                    <ng-template #template let-data>
                      {{ data?.total_of_par || '-' }}
                    </ng-template>
                  </e-column> -->
          </e-columns>
          <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column field="securityName" type="sum">
                  <ng-template #footerTemplate>
                    {{translateService.getLabel("total")}}
                  </ng-template>
                </e-column>
                <e-column field="valuationDate" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="enterpriseValue" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="equityValue" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="fairValue" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="noOfShares" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="fairValuePerShare" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                  </ng-template>
                </e-column>
                <e-column field="totalValue" type="sum" textAlign='Right'>
                  <ng-template [ #footerTemplate>
                    {{ equityTotalvalue | dynamicDecimalPipe}}
                  </ng-template>
                </e-column>
                <!-- <e-column field="totalPercentOfPar" type="sum" textAlign='Right'>
                        <ng-template #footerTemplate>
                          --
                        </ng-template>
                      </e-column> -->
              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
      </div>
      
      <div *ngIf="showEquityTable" class="total-value" style="margin: 0px 30px 30px 48px">
        <span style="font-size: 16px; font-weight: bold;">Total Value</span>
        <ng-container *ngIf="creditTotalvalue && equityTotalvalue; else singleValue">
          <span style="font-size: 16px; font-weight: bold;">
            {{ (creditTotalvalue + equityTotalvalue) | dynamicDecimalPipe }}
          </span>
        </ng-container>
        <ng-template #singleValue>
          <span style="font-size: 16px; font-weight: bold;">
            {{ (creditTotalvalue ? creditTotalvalue : equityTotalvalue) | dynamicDecimalPipe }}
          </span>
        </ng-template>
      </div>

      <!-- Loan Currency Table -->
      <div *ngIf="!concludeOnWaterfall && isLoanCurrency" style="margin: 0px 30px 30px 48px"
        class="issuer-consol-summary-grid">
        <mat-accordion class="old-securities">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Loan Currency</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Table Skeleton -->
            <div class="skeleton-table" *ngIf="!issuerConsolSummary?.valuationDates?.length">
              <ngx-skeleton-loader count="1"
                [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }">
              </ngx-skeleton-loader>

              <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
              'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

              <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
              'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>

              <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '8.5vw', 'margin-right': '0.2vw', 'white-space': 'nowrap',
              'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.5vw' }"></ngx-skeleton-loader>
            </div>

            <ng-template [ngIf]="issuerConsolSummary?.valuationDates?.length > 0">
              <ejs-grid #debtConsolidatedSummary [dataSource]="issuerConsolSummary?.valuationDates"
                [allowResizing]="true" [allowTextWrap]="true" [allowGrouping]="false" [allowExcelExport]="true"
                [allowSorting]="true" [allowReordering]="true" [allowPaging]="false" [pageSettings]="gridPageSettings"
                [showColumnChooser]="true" [toolbar]="gridToolbar" [allowFiltering]="true"
                [filterSettings]="gridFilterSettings" (rowSelected)="companyRowSelected($event)"
                (actionComplete)="saveTableState($event, CONSOLIDATED_SUMMARY_TABLE)">
                <e-columns>

                  <e-column field='securityName' headerText='Security Name' width='70px' textAlign="left">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.securityName">
                        {{comp.securityName }}
                      </ng-template>
                    </ng-template>
                  </e-column>

                  <e-column field='currency' headerText='Currency' width='60px' textAlign="left">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.currency">
                        {{ comp.currency }}
                      </ng-template>
                    </ng-template>
                  </e-column>

                  <e-column field='valuationDate' headerText='Valuation Date' width='70px' textAlign="left">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.valutationDate">
                        {{comp.valutationDate | date:'mediumDate' }}
                      </ng-template>
                    </ng-template>
                  </e-column>

                  <e-column field='fairValue' headerText='Dirty Price' width='60px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.fairValue">{{comp.fairValue | dynamicDecimalPipe}}</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='percentageOfPar' headerText='Dirty Price % of Par' width='85px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.percentageOfPar">{{comp.percentageOfPar | dynamicDecimalPipe}}%
                      </ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='cleanPriceFairValue' headerText='Clean Price' width='60px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.cleanPriceFairValue">{{comp.cleanPriceFairValue | dynamicDecimalPipe}}
                      </ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='cleanPricePercentOfPar' headerText='Clean Price % of Par' width='85px'
                    textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.cleanPricePercentOfPar">
                        {{comp.cleanPricePercentOfPar | dynamicDecimalPipe}}%</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='warrantValue' headerText='Warrant Value' width='70px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.warrantValue">{{comp.warrantValue | dynamicDecimalPipe}}</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='warrantPercentOfPar' headerText='Warrant % of Par' width='75px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.warrantPercentOfPar">{{comp.warrantPercentOfPar | dynamicDecimalPipe}}%
                      </ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='totalValue' headerText='Total Value' width='60px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.totalValue">{{comp.totalValue | dynamicDecimalPipe}}</ng-template>
                    </ng-template>
                  </e-column>
                  <e-column field='totalPercentOfPar' headerText='Total % of Par' width='70px' textAlign="right">
                    <ng-template #template let-comp>
                      <ng-template [ngIf]="comp.totalPercentOfPar">{{comp.totalPercentOfPar | dynamicDecimalPipe}}%
                      </ng-template>
                    </ng-template>
                  </e-column>
                </e-columns>
              </ejs-grid>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <app-debt-company-summary-details [keyMetricsAtEntry]="keyMetricsAtEntry" [recentEventsComments]="recentEventsComments" [transactionOverviewComments]="transactionOverviewComments" [transactionOverview]="transactionOverview" [fairValueTrend]="fairValueTrend" [discountRateTrend]="discountRateTrend" [currency]="currency"></app-debt-company-summary-details>
  </div>
</ng-template>

<ng-template [ngIf]="activeTab=='capitalStructure'">
  <div class="waterfall-container">
  <app-debt-capital-structure></app-debt-capital-structure>
  </div>
 </ng-template>
<ng-template [ngIf]="activeTab=='coverageAnalysis'">
  <app-coverage-analysis (waterFallExistChange)="waterFallExistChange($event)"></app-coverage-analysis>
 </ng-template>

 <ng-template [ngIf]="activeTab=='waterfallAnalysis'">
  <div class="waterfall-container">
    <app-waterfall-analysis></app-waterfall-analysis>
  </div>
 </ng-template>

 <ng-template [ngIf]="activeTab == 'tranchingAnalysis'">
  <div class="tranching-container">
    <app-tranching-analysis></app-tranching-analysis>
  </div>
 </ng-template>
