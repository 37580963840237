import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Chart } from 'angular-highcharts';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { QXP_PortFolioService } from '../qxp-portfolio.service';

@Component({
  selector: 'app-qxp-company-valuation',
  templateUrl: './qxp-company-valuation.component.html',
  styleUrls: ['./qxp-company-valuation.component.scss']
})
export class QXP_CompanyValuationComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute,
    public portfolioService: QXP_PortFolioService,
    private ums: UserManagementService, public currencyService: CurrencyExchangeService,
    private router: Router, private datePipe: DatePipe,
    private dataService: DataService,
    private utilService: UtilService,
    private modalService:NgbModal) 
  {}

  newCompany = {name: "", valuationDate: null, groupFormId: null, security: "EQUITY"};

  selectedCompanyDates = [];
  waterFallChart;
  primaryCompanyId;

  investmentDateInfo;

  showUserValDates = false;
  showValDates = false;
  formObject = {
    valuationDate:'',
    fairVal:0,
    stake: 0,
    stakeVal: 0
  }


  ngOnInit() {    
    this.activatedRoute.paramMap.subscribe( async params => {
      this.primaryCompanyId = params.get("company");
      this.portfolioService.companyId = this.primaryCompanyId;

      this.selectedCompanyDates = this.portfolioService.getSelectedCompanyDates( this.primaryCompanyId );

      if(!this.selectedCompanyDates || this.selectedCompanyDates.length == 0) {
        this.router.navigateByUrl("/fundList");
      }
      
      this.newCompany.name = this.selectedCompanyDates[0].companyName;
      this.newCompany.groupFormId = this.primaryCompanyId;

      //investmentDateInfo - This is mainly used in currency dashboard
      this.initCurrencyExchangeRate();
    })
  }

  initCurrencyExchangeRate() {
    this.investmentDateInfo = this.portfolioService.portfolioData.find(comp => comp.id == this.primaryCompanyId);
    this.dataService.getFormData(this.primaryCompanyId, this.ums.getApplicationVersion()).subscribe(data => {
      const formData = data.body["response"].formData;

      this.investmentDateInfo.formData = formData;
      // const sourceCurrencies = [formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY];

      const reqBody = [];

      // Hist Currency
      // this.currencyService.initExchangeRates(formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY,
      //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForFinHist")
      
        const histKey = formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY 
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[histKey]){
          this.investmentDateInfo["exchangeRateForFinHist"] = this.currencyService.exchangeRates[histKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForFinHist"] = exchangeRate
            this.currencyService.exchangeRates[histKey] = exchangeRate;
          })
        }

      //Proj Currency
      // this.currencyService.initExchangeRates(formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY,
      //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForProjHist")

        const projKey = formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[projKey]){
          this.investmentDateInfo["exchangeRateForProjHist"] = this.currencyService.exchangeRates[projKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForProjHist"] = exchangeRate
            this.currencyService.exchangeRates[histKey] = exchangeRate;
          })
        }

      if(formData.OTHERS.OT_Secondaries && formData.OTHERS.OT_Secondaries.OT_SEC_Buyer_Name.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForSecondaries")

          const secondariesKey = formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[secondariesKey]){
          this.investmentDateInfo["exchangeRateForSecondaries"] = this.currencyService.exchangeRates[secondariesKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForSecondaries"] = exchangeRate
            this.currencyService.exchangeRates[secondariesKey] = exchangeRate;
          })
        }
      }

      if(formData.OTHERS.OT_Bid_Details && formData.OTHERS.OT_Bid_Details.OT_BD_Bidder_Name.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForBidDetails")

        const bidDetailsKey = formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[bidDetailsKey]){
          this.investmentDateInfo["exchangeRateForBidDetails"] = this.currencyService.exchangeRates[bidDetailsKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForBidDetails"] = exchangeRate
            this.currencyService.exchangeRates[bidDetailsKey] = exchangeRate;
          })
        }
      }

      if(formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND && formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_NAME.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForPori")

        const poriKey = formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[poriKey]){
          this.investmentDateInfo["exchangeRateForPori"] = this.currencyService.exchangeRates[poriKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForPori"] = exchangeRate
            this.currencyService.exchangeRates[poriKey] = exchangeRate;
          })
        }
      }
    })
  }

  // getExchangeRate(sourceCurrency, targetCurrency, valDate, key){
  //   let exchangeRate = this.currencyService.initExchangeRates(sourceCurrency, targetCurrency, valDate)

  //   exchangeRate.then( value => {
  //     this.investmentDateInfo[key] = value
  //   }).catch( error => {
  //     console.log("Error while returning a value", error)
  //   })
  // }

  initChart (waterFallNumbers) {
    this.waterFallChart = new Chart({
      chart: { type: 'waterfall' },
      title: { text: '' },
      xAxis: { type: 'category' },
      yAxis: { title: { text: '' } },
      legend: { enabled: false },
      tooltip: { enabled: false },
      credits:{ enabled: false},
      plotOptions: {
        waterfall: {
          negativeColor: '#EEFFC6'
        }
      },

      series: []
    });
  }

  addValuationDate() {
    let loggedInUserDetails = this.ums.getSelectedUserDetails();

    if(!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;

    this.dataService.createNewCompany(this.newCompany, loggedInUserDetails, this.ums.getApplicationVersion(), this.selectedCompanyDates[0].fundCompany).subscribe(res=>{
      // console.log("Company Created", res.body);

      const newCompanyCreated = res.body["response"];

      this.portfolioService.prepareCompanyData([ newCompanyCreated ]);
      
      this.selectedCompanyDates = this.portfolioService.getSelectedCompanyDates( this.primaryCompanyId );

      
      this.dataService.copyFormAnswers(this.primaryCompanyId, newCompanyCreated.id).subscribe((saveResult)=>{
        // console.log("copy answers is successful", saveResult);

      }, (error)=>{
        console.log("error in copying form answers", error);
      }); 

      this.utilService.showMessage(this.newCompany.name + " Valuation is initiated", "Ok");
    }, err=>{
      console.log("Error while creating company", err)
      this.utilService.showMessage("Sorry, failed to create " + this.newCompany.name + " valuation.", "Ok");
    })
    
  }

  openSAF(comp) {
    const version = this.ums.getApplicationVersion() + "";
    
    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version); 
    localStorage.setItem('qubit-val-date', comp.valuationDate); 
    localStorage.setItem('qubit-investment-date-id', this.primaryCompanyId); 

    window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&v=" + version + "&id=" + comp.id, "_self");
  }

  getValuationDifferences(valuationDate1, valuationDateCCM2) {
    const waterFallNumbers = [];
    if(valuationDate1.valuation.CONCLUDED_IA && valuationDateCCM2.valuation.CONCLUDED_IA) {
      const iaValuation1 = valuationDate1.valuation.CONCLUDED_IA.data;
      const iaValuation2 = valuationDateCCM2.valuation.CONCLUDED_IA.data;

      waterFallNumbers.push({ name: this.datePipe.transform(valuationDate1.valuationDate, 'mediumDate'), y: valuationDate1.valuation.concluded });

      waterFallNumbers.push({ name: this.datePipe.transform(valuationDateCCM2.valuationDate, 'mediumDate'), y: valuationDateCCM2.valuation.concluded, isSum: true, color: "#1D1563" });

      const iaChanges = {
        generalInputs: this.prepareIAChangeList(iaValuation1.input.generalInputs , iaValuation2.input.generalInputs),
        internationalCostofCapital: this.prepareIAChangeList(iaValuation1.input.internationalCostofCapital , iaValuation2.input.internationalCostofCapital),
        weightedAvarageCostofCapital: this.prepareIAChangeList(iaValuation1.input.weightedAvarageCostofCapital , iaValuation2.input.weightedAvarageCostofCapital),
        dcfAssumptions: this.prepareIAChangeList(iaValuation1.input.dcfAssumptions , iaValuation2.input.dcfAssumptions),
      }

      // console.log("Valaution changes between ", this.datePipe.transform(valuationDate1.valuationDate, 'mediumDate'),
      //   this.datePipe.transform(valuationDateCCM2.valuationDate, 'mediumDate'), 
      //   iaChanges)
    }

    return waterFallNumbers;
  }

  prepareIAChangeList(oldInputSection, newInputSection) {
    const changes = [];
    const keys = Object.keys(newInputSection);
    keys.forEach(key=>{
      if(oldInputSection[key] !== newInputSection[key]) {
        changes.push({key: key, oldValue: oldInputSection[key], newValue: newInputSection[key]})
      }
    })

    return changes;
  }

  getTotalEquityValue(ccmEquity, ctmEquity, IAEquity){
    return ((ccmEquity + ctmEquity + IAEquity)/3);
  }

  addToPortfolioTable() {
    this.showUserValDates = false;
    this.showValDates = true;
    const selectedComp: any = this.portfolioService.getSelectedCompanyDates(this.primaryCompanyId).filter(comp => comp.groupFormId == null);
    // console.log("selectedComp", selectedComp);
    const obj = {
      id: null,
      groupFormId: selectedComp[0].id,
      companyName: selectedComp[0].companyName,
      security: selectedComp[0].security,
      website: selectedComp[0].website,
      valuationDate: this.formObject.valuationDate,
      geography: selectedComp[0].geography,
      sector: selectedComp[0].sector,
      userEntered: true,
      investment: {
        equityValue: {finalAdjustedEquityVal: this.formObject.fairVal, stake: this.formObject.stake, stakeValue: this.formObject.stakeVal}
      }
    }
    
    this.portfolioService.userSavedInvestedForms.push(obj)

    this.selectedCompanyDates.push(obj)

    this.dataService.saveWidgetDataToDB("user_invested_forms", this.portfolioService.userSavedInvestedForms, this.portfolioService.userId).subscribe(res => {
      // console.log("Succesfully saved the User Saved Invested forms", res);
      if(this.portfolioService.userSavedInvestedForms.length > 0){
        this.portfolioService.getUserAddedForms(this.portfolioService.userSavedInvestedForms);
      }
      this.portfolioService.preparePortfolioData();
      this.portfolioService.prepareMapData();
      this.portfolioService.prepareColumnChart();
    }, error =>{
      console.log("Error while saving User Saved Invested forms", error);
    })


      // this.portfolioService.preparePortfolioData();
      // this.portfolioService.prepareMapData();
      // this.portfolioService.prepareColumnChart();
  }

  openCurrencyDashboardPopup(content) {
    this.modalService.open(content, { centered: true })
  }
}