import { Component, OnInit } from "@angular/core";
import { DataService } from "../services/data.service";
import { UserManagementService } from "../services/user-management.service";
import { UtilService } from "../utils/util.service";

import * as moment from 'moment';
import { PortFolioService } from "../qubit-x/portfolio/portfolio.service";

@Component({
  selector: 'app-shared-access',
  templateUrl: './shared-access.component.html',
  styleUrls: ['./shared-access.component.scss']
})
export class SharedAccessComponent implements OnInit {
  loading: any = true;
  sharedCompanyDates = [];

  constructor(private ds: DataService,
    private ums: UserManagementService,
    private utilService: UtilService,
    private portfolioService: PortFolioService)
  {}

  ngOnInit() {
    const user = this.ums.getSelectedUserDetails();
    this.ds.getSharedFormsToExternalUser(user.id).subscribe(res => {
      // console.log("Shared Forms", res.body);

      this.loading = false;

      this.sharedCompanyDates = res.body["response"];

      this.sharedCompanyDates.forEach(form => {
        const details = JSON.parse(form.details);
          
        form.companyName = details["companyName"];
        form.companyNameInForm = details["companyNameInForm"];
        form.status = details["status"];
        form.website = details["website"];
        form.geography = details["geography"];
        form.versionName = details["versionName"];
        form.businessUnitsNumber = details["businessUnitsNumber"];

        form.multipleRealisedProceeds = details['multipleRealisedProceeds']? details['multipleRealisedProceeds'] : [];
        form.totalRealisedProceeds = details['totalRealisedProceeds']? details['totalRealisedProceeds'] : 0;

        form.multipleInvestmentAmount = details['multipleInvestmentAmount']? details['multipleInvestmentAmount'] : [];
        
        if(details['multipleInvestmentAmount']) {
          details['totalInvestmentAmount'] = 0;

          details['multipleInvestmentAmount'].forEach(inv => {
            details['totalInvestmentAmount'] += inv.value;
          })
          
          form.totalInvestmentAmount = details['totalInvestmentAmount'];
        }

        form.moic = details['moic']? details['moic'] : 0;
        form.irr = details['irr']? details['irr'] : 0;


        if(details["sector"]) {
          const sectorParts = details["sector"].split(",")
          form.sector = sectorParts[0];
        }

        form.valuationDate = (moment(form.valuationDate)).format("YYYY-MM-DD");
      });
    }, error => {      
      this.loading = "ERROR";
    })
  }

  openValuation(comp) {
    this.portfolioService.openValuation(comp);
  }
}