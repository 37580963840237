import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AttributesService } from '../attributes.service';
import { AttrMap } from '../attribute-interfaces';

export interface QxGxAttributes {
  qxKey: string;
  qxLabel: string;
  gxKey: string;
  gxLabel: string;
}

@Component({
  selector: 'app-attr-mapping-table',
  templateUrl: './attr-mapping-table.component.html',
  styleUrls: ['./attr-mapping-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttrMappingTableComponent implements OnInit {
  selectedAttributes;
  _attributes: AttrMap[];
  _gxAttributes: any[];

  _usedAttrs = {};

  @ViewChild('auto') autoComplete: TemplateRef<any>;

  @ViewChild('attrForm') attrForm: TemplateRef<any>;

  @Input() tableId: string;

  @Input() isReadOnly?: boolean;

  @Input()
  set attributes(val: AttrMap[]) {
    if (val?.length > 0) {
      this._attributes = val;
    }
  }

  @Input()
  set usedAttrs(attrs) {
    console.log(attrs, 'Used attributes...');
    if (attrs) {
      this._usedAttrs = attrs;
    }
  }

  @Input()
  set seletedAttributes(val) {
    this.selectedAttributes = [...val];
  }

  @Input()
  set gxAttributes(val) {
    this._gxAttributes = [...val];
  }

  @Output() onGxAttributeSelect = new EventEmitter();

  filteredOptions: any[] = [];
  height: string;

  // attributesFormGroup: FormGroup;

  attrOptionDisplay(option): string | undefined {
    return option ? option.name : undefined;
  }

  constructor(public attrsService: AttributesService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  onSelectionChange(e, i) {
    console.log(e, 'On Selection change...', this.tableId);
    if (this.tableId === 'incomeStatement') {
      this.attrsService.updateIncomeStatementAttrs(e?.value);
    }
  }

  onSubmit() {
    console.log(this.autoComplete);
    console.log(this._attributes, '<<');
  }

  onSelect(e, row: AttrMap, index: number) {
    console.log(e, 'Select event', index);

    if (e) {
      if (this._usedAttrs[row.gxKey]) {
        this._usedAttrs[row.gxKey] = false;
      }
        this._usedAttrs[e?.option?.value.uniqueKey] = true;
      console.log('Used attributes...', this._usedAttrs);
      this._attributes[index] = {
        ...row,
        gxKey: e?.option?.value.uniqueKey,
        gxLabel: e?.option?.value.name,
        gxAttributeId: e?.option?.value.id,
        isGxGlobalAttribute: e?.option?.value.global === 'true' ? true : false,
      };
      // if(row.qxKey === this._attributes[index].qxKey) {
      //   this._attributes[index] = { ...this._attributes[index], gxKey: e?.option?.value.uniqueKey, gxLabel: e?.option?.value.name };
      // }

      this.onGxAttributeSelect.emit(this._attributes);
    }
    console.log(row, 'upd', this._usedAttrs);
    console.log(this._attributes[index], '????');
  }

  onKeyUp(e) {
    console.log(e.target.value, 'Keyup');
    this.filteredOptions = this._gxAttributes.filter((option) => {
      return option.name?.toLowerCase().startsWith(e.target.value?.trim()?.toLowerCase());
    });
    console.log(this.filteredOptions, 'Keyup filter');
    if (this.filteredOptions.length < 4) {
      this.height = this.filteredOptions.length * 28 + 'px';
    } else {
      this.height = '200px';
    }
  }
}
