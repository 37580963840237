import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';

import * as moment from 'moment';
import { cloneDeep } from 'lodash';

import { GridComponent, ExcelExportService } from '@syncfusion/ej2-angular-grids';
import { DebtPortfolioService } from '../debt-portfolio.service';
import { FundListService } from 'src/app/qubit-x/portfolio/fund-list-ui/fund-list.service';
import { MessageService } from 'src/app/services/message.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { ToastService } from 'src/app/utils/toast.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from 'src/app/services/translation.service';
import { MatSelectChange } from '@angular/material';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-debt-portfolio',
  templateUrl: './debt-portfolio.component.html',
  styleUrls: ['./debt-portfolio.component.scss'],
  providers: [ ExcelExportService ]
})
export class DebtPortfolioComponent implements OnInit, OnDestroy{

  filterCompaniesFormGroup : FormGroup;

  defaultSecurityData = {name: "", valuationDate: null, security: "DEBT", investmentAmount: 0, logo: null, businessUnitsNumber: 0, company_id: null, issuerCompanyId:null, onboardedSecurityName: ""};
  newSecurity = cloneDeep(this.defaultSecurityData);
  searchString = ""
  searchedCompanyNames = [];
  existingCompanyNames = [];
  combinedCompanyNames = [];
  showDividerAt;
  nullDefaultValue = 0;
  userId;
  fundId;
  fundName;

  showMenu = false;
  selectedFormForAssignment;

  showSummary;
  // showExitSimulation;
  listOfCompaniesToBeDeleted=[];
  transactions = false;
  @ViewChild('investmentSummaryGridCurrent', { static: false }) public investmentSummaryGridCurrent: GridComponent;
  @ViewChild('investmentSummaryGridExited', { static: false }) public investmentSummaryGridExited: GridComponent;
  @ViewChild('localCurrencyInvestmentSummaryGridCurrent', { static: false }) public localCurrencyInvestmentSummaryGridCurrent: GridComponent;
  @ViewChild('localCurrencyInvestmentSummaryGridExited', { static: false }) public localCurrencyInvestmentSummaryGridExited: GridComponent;
  gridPageSettings = { pageSizes: true, pageSize: 10 };
  gridToolbar = ['ColumnChooser'];
  gridFilterSettings = { type: 'Excel' };
  customAttributes = {class: 'hilightCols'};

  companyNames : any[] = [];
  companyAndSecurityNames : any[] = [];
  exportSheetName: any
  columnNames = [];
  unSelectedColumnsDB = [];

  showDebtUI = false;
  portfolioSummaryReady = false;
  payloadForIssuerLoaded = false;
  payloadForCurrencyChange = [];

  securityDetailsReportingCurrencyCurrent = [];
  securityDetailsReportingCurrencyExited = [];
  securityDetailsLocalCurrencyCurrent = [];
  isSecurityDetailsLocalCurrencyCurrentLoaded = false;
  securityDetailsLocalCurrencyExited = [];
  securityDetailsTotalReportingCurrency = [];

  totalPortfolioValue: any;
  totalPortfolioValueUnit;

  totalPortfolioValueTable: any;

  grossIRR: any;
  percentOfPar: any;
  percentOfParChange: any;
  headerBoxgrossIRR: any;
  headerBoxpercentOfPar: any;
  onDeleteCompanies: boolean = false;
  reportingCurrencySelectedColumnsCurrent = [];
  reportingCurrencySelectedColumnsExited = [];
  localCurrencySelectedColumnsCurrent = [];
  localCurrencySelectedColumnsExited = [];
  orgId;
  portfolio: any = {
    current : {
      expandPortfolio: false,
      name: this.translateService.getLabel('current')
    },
    exited: {
      expandPortfolio: false,
      name: this.translateService.getLabel('exited')
    }
  }
  disableClientSpecificFields: boolean = false;

  public payload = [];
  excelAmountMetric: string;
  companyDupObject = [];
  investmentStatuses = {
    CURRENT: 'current',
    EXITED: 'exited',
    RESTRUCTURED: 'restructured'
  };

  // Constants
  readonly latestValuationDateText: string = this.translateService.getLabel('latest_ValuationDate_Label');

  selectedValDate: string = this.latestValuationDateText;
  allValDates: string[] = [this.selectedValDate];
  triggerCompanyTableChange: boolean = false;
  payloadForIssuerDup = []
  selectedSecurityIdsForDeletion: any = [];
  securityLinkage: boolean = false;
  securityDeletable: any[] = [];
  securityDeletableIds : any[] = [];
  securityNonDeletable: any[] = [];
  allSecurityDeletable: any[] = [];
  combinedDeletableSecurities: any[] = [];
  combinedNonDeletableSecurities: any[] = [];
  combinedAllDeletableSecurities:any[] = [];
  notSubmittedSecurityName: any[] = [];
  combinedNotSubmittedSecurityName: any[] = [];

  constructor(private modalService: NgbModal, private ums: UserManagementService,
    private dataService: DataService, private utilService: UtilService, public debtPortfolioService: DebtPortfolioService,
    private router:Router, private activatedRoute: ActivatedRoute, private ms: MessageService,
    public fundService: FundListService, public imageLoadService: ImageLoadService,
    public toastService: ToastService, public portfolioServiceV2: PortFolioSummaryServiceV2, 
    public currencyExchange: CurrencyExchangeService, private formBuilder: FormBuilder,
    public translateService: TranslateService)
  {
    this.filterCompaniesFormGroup = this.formBuilder.group({
      companyName: [],
    });
  }

  ngOnInit() {
    const userDetails = this.ums.getSelectedUserDetails();
    this.orgId = userDetails?.organization.id;
    this.setUpCompaniesSearch();
    try {
      this.activatedRoute.paramMap.pipe(mergeMap((params:any) => {
        this.portfolioSummaryReady = false;
        this.fundId = params.get("fundId");
        this.debtPortfolioService.selectedFundId = this.fundId;
        //Client Specific
        if(this.fundId === 'b85335fd-5df8-4f05-add1-34ed2234c251' || this.fundId === '1af13588-3898-46ad-a518-33920b68cd87') {
          this.disableClientSpecificFields = true;
        }
        return this.dataService.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.fundId)
      }),catchError(err => {
        return of(null);
      }))
      .subscribe(async (currencyResponse: any) => {
        if(currencyResponse) {
          let widgetData = currencyResponse["body"]["response"][0].widgetData;
          this.portfolioServiceV2.selectedCurrency = widgetData.selectedCurrency;
        }

        // Fetching distinct dates
        try {
          const { body: { response: { valuationDates } } } = await this.dataService
            .getAllDistinctValuationDates(this.fundId,true)
            .toPromise() as HttpResponse<{ response: { valuationDates: string[] } }>;
          this.allValDates = [this.latestValuationDateText, ...valuationDates];
        } catch {
          this.allValDates = [this.latestValuationDateText];
        }

        // Company Level Data
        this.fetchCompanyLevelData(this.latestValuationDateText);

        //Get ALl Logos
        this.debtPortfolioService.getPortfolioCompanysLogo(userDetails, this.fundId);

        // Security Level Data
        this.fetchSecurityLevelData(this.latestValuationDateText);
      })
    } catch (e) {
      console.log("Error in getting debt data", e)
    }

    this.ms.subscribe("ADD_NEW_DEBT_INVESTMENT", (newSecurity) => {
      this.addNewInvestmentToGrid(newSecurity);
    });

    this.ms.subscribe("REPORTING_CURRENCY_CHANGE_EQUITY", (change) => {
      if(change == true) {
        this.initExchangeRate();
      }
    });
    this.getIssuerCompanies()
  }

  onAsOnDateChange(event: MatSelectChange) {
    this.selectedValDate = event.value;

    // Bar graph skeleton
    this.debtPortfolioService.debtColumnChart = false;

    // Three summary boxes skeleton
    this.headerBoxgrossIRR = undefined;
    this.totalPortfolioValue = undefined;
    this.headerBoxpercentOfPar = undefined;

    // Geography box skeleton
    this.debtPortfolioService.debtMapChart = false;

    // Company level table skeleton
    this.debtPortfolioService.issuerPorfolioSummary = null;
    this.triggerCompanyTableChange = false;

    // Reporting Currency table skeleton
    this.securityDetailsReportingCurrencyCurrent = [];
    this.isSecurityDetailsLocalCurrencyCurrentLoaded = false;
    this.securityDetailsReportingCurrencyExited = [];

    // Local Currency table skeleton
    this.securityDetailsLocalCurrencyCurrent = [];
    this.securityDetailsLocalCurrencyExited = [];

    this.triggerCompanyTableChange = false;
    this.fetchCompanyLevelData(this.selectedValDate);
    this.fetchSecurityLevelData(this.selectedValDate);
  }

  private fetchCompanyLevelData(valuationDate: string) {
    const userDetails = this.ums.getSelectedUserDetails();
    let observable: Observable<HttpResponse<Object>>;
    if (valuationDate === this.latestValuationDateText)
      observable = this.debtPortfolioService.getSecuritiesByIssuerCompany(
        this.fundId, userDetails
      );
    else observable = this.debtPortfolioService.getSecuritiesByIssuerCompanyWithVD(
      this.fundId, valuationDate
    );
    observable.subscribe((res:any) => {
      if (res?.body["response"] && res.body['response'].companies?.length) {
        this.debtPortfolioService.payloadForIssuer = res.body['response'];
        this.payloadForIssuerDup = [...res.body.response?.companies]
        this.payloadForIssuerLoaded = true;
        this.triggerCompanyTableChange = true;
      }
    })
  }

  private fetchSecurityLevelData(valuationDate: string) {
    const userDetails = this.ums.getSelectedUserDetails();
    let observable: Observable<HttpResponse<Object>>;
    let index = this.allValDates.findIndex(x => x == this.selectedValDate);
    if (valuationDate === this.latestValuationDateText) {
      observable = this.debtPortfolioService.getAllSecuritiesForPortfolio(this.fundId, userDetails);
    } else {
      observable = this.debtPortfolioService.getAllSecuritiesForPortfolioWithVD(
        this.fundId, userDetails, this.selectedValDate
      );
    }
    observable.subscribe((payloadData: any) => {
      if (payloadData?.body.response) {
        this.debtPortfolioService.isDebtLoaded = true;
        this.payloadForCurrencyChange = cloneDeep(payloadData.body.response);

        if(this.selectedValDate == this.latestValuationDateText) {
          this.payloadForCurrencyChange['previousValuationDate'] = this.allValDates[index + 2];
        } else if(index == this.allValDates?.length - 1) {
          this.payloadForCurrencyChange['valuationDate'] = this.selectedValDate;
        } else {
          this.payloadForCurrencyChange['valuationDate'] = this.selectedValDate;
          this.payloadForCurrencyChange['previousValuationDate'] = this.allValDates[index + 1];
        }
        this.updateReportingCurrencyTable(this.payloadForCurrencyChange);
        this.updateLocalCurrencyTable(this.payloadForCurrencyChange);
      }
    })
  }

  initSummaryGridData(securityDetails, securityDetailsGridData) {
    let logoURL = '';
    securityDetails?.forEach( investment => {
      //Get logo for company
      if (this.debtPortfolioService.allCompanyLogos) {
        logoURL = this.debtPortfolioService.allCompanyLogos[investment.companyId];
      }

      if(investment.valuationDates.length != 0) {
        investment.valuationDates[0].issuerName = investment.valuationDates[0].issuerName || investment.valuationDates[0].qxCompanyName;
        investment.valuationDates[0].investmentDate = investment.valuationDates[0].investmentDate || investment.valuationDates[0].qXvaluationDate;
        investment.valuationDates[0].valuationDate = investment.valuationDates[0].valuationDate || investment.valuationDates[0].qXvaluationDate;
        investment.valuationDates[0].valuationDateId = investment.valuationDates[0].valuationDateId || investment.companyId;
        investment.valuationDates[0].companyId = investment.companyId;
        investment.valuationDates[0].logoURL = logoURL;
        investment.valuationDates[0].issuerCompanyId = investment.issuerCompanyId;
        const currentDate = new Date();
        const exitDate = new Date(investment.valuationDates[0].exitDate);
        //investment.valuationDates[0]['investmentStatus'] = currentDate > exitDate ? 'Exited' : 'Current';
        securityDetailsGridData.push(investment.valuationDates[0]);
      }
    })
  }

  getIssuerCompanies(){
    this.dataService.getIssuerCompanies(this.fundId).subscribe((response) => {
      this.existingCompanyNames = response.body['response']
    })
  }
  updateSummaryData(payloadData?) {
    this.totalPortfolioValue = undefined;
    this.totalPortfolioValueTable = undefined;
    this.totalPortfolioValueUnit = undefined;
    this.grossIRR = undefined;
    this.percentOfPar = undefined;
    this.portfolio.current.grossIRR = undefined;
    this.portfolio.current.percentOfPar = undefined;
    this.portfolio.current.value = undefined;
    this.portfolio.exited.grossIRR = undefined
    this.portfolio.exited.percentOfPar = undefined;
    this.portfolio.exited.value = undefined;

    this.debtPortfolioService.getPortfolioTotals(this.portfolioServiceV2.selectedCurrency, payloadData).then((res: any) => {
      if (res.body['response']) {
        let totalValue = res.body['response'].currentTotals.value;
        if (totalValue > 100) {
          this.totalPortfolioValue = totalValue / 1000;
          this.totalPortfolioValueUnit = "Bn";
        } else {
          this.totalPortfolioValue = totalValue;
          this.totalPortfolioValueUnit = "Mn";
        }
        this.totalPortfolioValueTable = res.body['response'].totals.value;
        
        this.grossIRR = res.body['response'].totals.grossIrr;
        this.percentOfPar = res.body['response'].totals.percentPar;
        this.percentOfParChange = res.body['response'].currentTotals.percentParChange;

        this.portfolio.current.grossIRR = res.body['response'].currentTotals.grossIrr;
        this.portfolio.current.percentOfPar = res.body['response'].currentTotals.percentPar;
        this.portfolio.current.value = res.body['response'].currentTotals.value;

        this.portfolio.exited.grossIRR = res.body['response'].exitedTotals.grossIrr;
        this.portfolio.exited.percentOfPar = res.body['response'].exitedTotals.percentPar;
        this.portfolio.exited.value = res.body['response'].exitedTotals.value;

        this.headerBoxgrossIRR = this.portfolio.current.grossIRR;
        this.headerBoxpercentOfPar = this.portfolio.current.percentOfPar;
      }
    });

  }


  ngOnDestroy() {
    this.debtPortfolioService.debtMapChart = null;
    this.debtPortfolioService.debtColumnChart = null;
    this.debtPortfolioService.currencyChanged.unsubscribe();
  }

  changeReportingCurrency() {
    this.ms.publish("REPORTING_CURRENCY_CHANGE_DEBT", true);
    this.initExchangeRate();
  }

  initExchangeRate() {
    this.payloadForCurrencyChange && this.updateReportingCurrencyTable(
      this.payloadForCurrencyChange
    );
    this.debtPortfolioService.currencyChanged.next(true);
  }

  updateReportingCurrencyTable(payloadData) {
    this.updateSummaryData(payloadData);
    this.debtPortfolioService.getSectorData(this.portfolioServiceV2.selectedCurrency, payloadData);
    this.debtPortfolioService.getGeographyData(this.portfolioServiceV2.selectedCurrency, payloadData);
    //To get user selected columns
    try {
      this.getUserSelectedColumns('SECURITY_LEVEL_REPORTING_COLUMNS_CURRENT');
      this.getUserSelectedColumns('SECURITY_LEVEL_REPORTING_COLUMNS_EXITED');
    } catch (e) {
      console.log("No data in widget DB", e)
    }

    this.debtPortfolioService.getDebtPortfolioSummaryReporting(this.portfolioServiceV2.selectedCurrency, payloadData).then((response:any) => {
      if(response.body["response"]) {
        this.securityDetailsReportingCurrencyCurrent = [];
        this.securityDetailsReportingCurrencyExited = [];
        this.initSummaryGridData(response.body["response"].current.companies, this.securityDetailsReportingCurrencyCurrent);
        this.initSummaryGridData(response.body["response"].exited.companies, this.securityDetailsReportingCurrencyExited);
        this.portfolio.current.expandPortfolio = this.securityDetailsReportingCurrencyCurrent.length > 0 ? true : false;

        if(this.securityDetailsReportingCurrencyCurrent.length > 0 || this.securityDetailsReportingCurrencyExited.length > 0 ) {
          this.showDebtUI = true;
          this.portfolioSummaryReady = true;
          // this.debtPortfolioService.initColumnChart();
          this.companyDupObject = [...this.securityDetailsReportingCurrencyCurrent, ...this.securityDetailsReportingCurrencyExited];
          this.securityDetailsTotalReportingCurrency = this.companyDupObject.map((o) => { return {...o }});
          // this.companyDupObject = this.securityDetailsTotalReportingCurrency.map((o) => { return {...o }});

          if (this.investmentSummaryGridCurrent) {
            this.reportingCurrencySelectedColumnsCurrent?.length && this.investmentSummaryGridCurrent.hideColumns(this.reportingCurrencySelectedColumnsCurrent);
            this.investmentSummaryGridCurrent.refresh()
          }

          if (this.investmentSummaryGridExited) {
            this.reportingCurrencySelectedColumnsExited?.length && this.investmentSummaryGridExited.hideColumns(this.reportingCurrencySelectedColumnsExited);
            this.investmentSummaryGridExited.refresh()
          }
        }
      }
    }).catch(err => {
      console.log("Error in getting debt portfolio summary local", err);
    })
  }

  updateLocalCurrencyTable(payloadData) {
    //To get user selected columns
    try {
      this.getUserSelectedColumns('SECURITY_LEVEL_LOCAL_COLUMNS_CURRENT');
      this.getUserSelectedColumns('SECURITY_LEVEL_LOCAL_COLUMNS_EXITED');
    } catch (e) {
      console.log("No data in widget DB", e)
    }

    this.debtPortfolioService.getPortfolioSummaryLocal(payloadData).then((response:any) => {
      if(response.body["response"]) {
        this.initSummaryGridData(response.body["response"].current.companies, this.securityDetailsLocalCurrencyCurrent);
        this.initSummaryGridData(response.body["response"].exited.companies, this.securityDetailsLocalCurrencyExited);

        this.isSecurityDetailsLocalCurrencyCurrentLoaded = true;

        if(this.securityDetailsLocalCurrencyCurrent.length > 0 || this.securityDetailsLocalCurrencyExited.length > 0) {
          this.showDebtUI = true;
          this.portfolioSummaryReady = true;
          setTimeout(() => {
            this.debtPortfolioService.initColumnChart();

            if (this.localCurrencyInvestmentSummaryGridCurrent) {
              this.localCurrencySelectedColumnsCurrent?.length && this.localCurrencyInvestmentSummaryGridCurrent.hideColumns(this.localCurrencySelectedColumnsCurrent);
              this.localCurrencyInvestmentSummaryGridCurrent.refresh();
            }
            if (this.localCurrencyInvestmentSummaryGridExited) {
              this.localCurrencySelectedColumnsExited?.length && this.localCurrencyInvestmentSummaryGridExited.hideColumns(this.localCurrencySelectedColumnsExited);
              this.localCurrencyInvestmentSummaryGridExited?.refresh();
            }
          })
        }
      }
    }).catch(err => {
      console.log("Error in getting debt portfolio summary local", err);
    })
  }

  getUserSelectedColumns(key) {
    this.dataService.getWidgetDataFromDB(key, this.orgId)
    .subscribe((res: any) => {
      switch(key) {
        case 'SECURITY_LEVEL_REPORTING_COLUMNS_CURRENT' : 
        this.reportingCurrencySelectedColumnsCurrent = res?.body?.response[0]?.widgetData;
        break;
        case 'SECURITY_LEVEL_REPORTING_COLUMNS_EXITED' : 
        this.reportingCurrencySelectedColumnsExited = res?.body?.response[0]?.widgetData;
        break;
        case 'SECURITY_LEVEL_LOCAL_COLUMNS_CURRENT' : 
        this.localCurrencySelectedColumnsCurrent = res?.body?.response[0]?.widgetData;
        break;
        case 'SECURITY_LEVEL_LOCAL_COLUMNS_EXITED' : 
        this.localCurrencySelectedColumnsExited = res?.body?.response[0]?.widgetData;
        break;
      }
    })
  }


  userPopup(content, form) {
    const userDetails = this.ums.getSelectedUserDetails();
    this.debtPortfolioService.resetUserAssignment();

    const dbForm = this.debtPortfolioService.companies.find(c => c.id === form.id);

    if(dbForm.assessorDetails && this.debtPortfolioService.myOrgUsers && this.debtPortfolioService.myOrgUsers.length > 0) {
      const assignees = dbForm.assessorDetails.assignees;
      if(assignees) {
        this.debtPortfolioService.myOrgUsers.forEach(user => {
          const assignedUser = assignees.find(u => u.id === user.id);

          user.assigned = !!assignedUser;
          user.editable = assignedUser ? assignedUser.editable : 'R';

          if(dbForm.assessorUserId === user.id) {
            user.assigned = true;
            user.editable = 'W';
          }else if(this.ums.isMasterUser()) {
            //Form or application will be shared to Org master user by default.
            user.assigned = true;
            user.editable = 'W';
          }
        })
      }
    } else if(!this.debtPortfolioService.myOrgUsers || this.debtPortfolioService.myOrgUsers.length == 0) {
      return;
    }

    this.selectedFormForAssignment = dbForm;

    this.modalService.open(content, { centered: true, size: 'lg'})
  }

  ngAfterViewInit(){

  }

  openPopupModal(content){
    this.newSecurity = cloneDeep(this.defaultSecurityData);
    this.getIssuerCompanies()
    this.newSecurity.businessUnitsNumber = 0;
    this.listOfCompaniesToBeDeleted = [];
    this.selectedSecurityIdsForDeletion = [];
    this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
  }

  async startApplication() {
    const loggedInUserDetails = this.ums.getSelectedUserDetails();

    if(this.newSecurity.name!=='' && this.newSecurity.valuationDate && this.newSecurity.onboardedSecurityName !==''){

      this.newSecurity.valuationDate =(moment(this.newSecurity.valuationDate)).format("YYYY-MM-DD");

      this.modalService.dismissAll();
      const loggedInUser = this.ums.getUserDetails();

      this.dataService.createNewCompanyWithVersion(this.newSecurity, loggedInUserDetails, this.ums.getApplicationVersion(), "FIRST", true, loggedInUser, this.fundId).subscribe(res=>{
        this.selectedFormForAssignment = res.body["response"];

        this.newSecurity.company_id = this.selectedFormForAssignment.id

        let payload = {
          fundId: this.debtPortfolioService.selectedFundId,
          fundName: this.fundService.getFundName(this.debtPortfolioService.selectedFundId),
          companyId: this.selectedFormForAssignment.id,
          companyName: this.newSecurity.name,
          investmentDate: this.newSecurity.valuationDate,
          onboardedSecurityName: this.newSecurity.onboardedSecurityName
        }

        this.dataService.createDebtModelMetaData(payload).then(resp => {
          console.log("Saved new company metaata to debt model");
        }).catch(err => {
          console.log("Error: unable to saved new company metaata to debt model", err);
        })

        this.debtPortfolioService.assignUsersToForm(this.selectedFormForAssignment, this.newSecurity.name);

        //this.debtPortfolioService.getDebtInvestMentDetails();
        this.addNewInvestmentToGrid(this.newSecurity);

        this.utilService.showMessage(this.newSecurity.name + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }, err=>{
        console.log("Error while creating company", err)
        this.utilService.showMessage(this.translateService.getLabel("err_failed_create") + " " + this.newSecurity.name + " " + this.translateService.getLabel("valuation") + ".", this.translateService.getLabel("ok"));
      })
    }
    else{
      this.utilService.showMessage(this.translateService.getLabel("req_all_details"), this.translateService.getLabel("ok"));
    }
  }

  addNewInvestmentToGrid(newSecurity) {
    if(newSecurity.name) {
      //Add new company to table
      let newSecurityGridData = {
        grossIRR: 0,
        investmentDate: newSecurity.valuationDate,
        valuationDate: newSecurity.valuationDate,
        issuerName: newSecurity.name,
        logoURL: "",
        percentOfPar: 0,
        performanceClass: "",
        security: "DEBT",
        securityName: newSecurity.onboardedSecurityName,
        seniority: "",
        valuationDateId: newSecurity.company_id,
        value: 0,
        companyId: newSecurity.company_id
      }

      this.securityDetailsReportingCurrencyCurrent.push(newSecurityGridData);
      this.securityDetailsLocalCurrencyCurrent.push(newSecurityGridData);
      this.securityDetailsTotalReportingCurrency.push(newSecurityGridData);

      this.showDebtUI = true;
      this.portfolioSummaryReady = true;

      this.investmentSummaryGridCurrent.refresh();
      this.investmentSummaryGridExited.refresh();
      this.localCurrencyInvestmentSummaryGridCurrent.refresh();
      this.localCurrencyInvestmentSummaryGridExited.refresh();
    }
  }


  companyNameChange() {
      if(this.newSecurity.name && this.newSecurity.name.length >= 3) {

        this.dataService.getNewMatchingCompanyNames(this.newSecurity.name).subscribe(result => {
          let filteredExistingCompanies = this.existingCompanyNames.filter(x => {
            return x.issuerCompanyName.toLowerCase().includes(this.newSecurity.name.toLowerCase());
          });
            //Incase sorting is needed in future

            // .sort((a, b) => {
            //   if (a.issuerCompanyName.toLowerCase().indexOf(this.newSecurity.name.toLowerCase()) > b.issuerCompanyName.toLowerCase().indexOf(this.newSecurity.name.toLowerCase())) {
            //     return 1;
            //   } else if (a.issuerCompanyName.toLowerCase().indexOf(this.newSecurity.name.toLowerCase()) < b.issuerCompanyName.toLowerCase().indexOf(this.newSecurity.name.toLowerCase())) {
            //     return -1;
            //   } else {
            //     if (a.issuerCompanyName > b.issuerCompanyName)
            //       return 1;
            //     else
            //       return -1;
            //   }
            // });

          if(result.body["response"]) {

            this.searchedCompanyNames = result.body["response"];
            this.showDividerAt = this.searchedCompanyNames.length -1;
            this.combinedCompanyNames = this.searchedCompanyNames.concat(filteredExistingCompanies)

          } else {
            this.combinedCompanyNames = [...filteredExistingCompanies]
          }
        }, error=>{

          console.log("error", error);
          this.combinedCompanyNames = [...this.existingCompanyNames]
        })

      } else {
        this.combinedCompanyNames = [...this.existingCompanyNames]
      }
  }

  selectedCompanyName(option) {
    // console.log("company selected", option)
    if(option.company_id){
      this.newSecurity.company_id = option.company_id
      this.newSecurity.issuerCompanyId = null
    }else{
      this.newSecurity.issuerCompanyId = option.issuerCompanyId
    }
  }

  //handled Column/Filtering event of reporting currency table
  filteringReportingCurrency(event, tableType) {
    // let filteredRows = [];
    // if (event.requestType == 'filtering' && event.currentFilteringColumn == 'investmentStatus') {
    //   if (event.action == 'filter') {
    //     event.rows.forEach(row => {
    //       filteredRows.push(row.data);
    //     })
    //   } else if (event.action == 'clear-filter') {
    //     filteredRows = cloneDeep(this.securityDetailsReportingCurrencyCurrent);
    //   }

    //   this.updateSummaryData(filteredRows);
    // }

    if(event.requestType == 'columnstate' && !event.columns?.name) {
      let selectedColumns;
      if(tableType == 'CURRENT') {
        selectedColumns = [...this.reportingCurrencySelectedColumnsCurrent];
      } else {
        selectedColumns = [...this.reportingCurrencySelectedColumnsExited];
      }
      event.columns.map((column:any) => {
        if(!column.visible) {
          selectedColumns.push(column.headerText);
        } else {
          let index = selectedColumns.indexOf(column.headerText);
          if(index > -1)
          selectedColumns.splice(index, 1)
        }
      })
      this.dataService.saveWidgetDataToDB('SECURITY_LEVEL_REPORTING_COLUMNS_' + tableType, selectedColumns, this.orgId)
        .subscribe();
    }
  }

  localCurrencyAction(event, tableType) {
    if(event.requestType == 'columnstate' && !event.columns?.name) {
      let selectedColumns;
      if(tableType == 'CURRENT') {
        selectedColumns = [...this.localCurrencySelectedColumnsCurrent];
      } else {
        selectedColumns = [...this.localCurrencySelectedColumnsExited];
      }
      event.columns.map((column:any) => {
        if(!column.visible) {
          selectedColumns.push(column.headerText);
        } else {
          let index = selectedColumns.indexOf(column.headerText);
          if(index > -1)
          selectedColumns.splice(index, 1)
        }
      })
      this.dataService.saveWidgetDataToDB('SECURITY_LEVEL_LOCAL_COLUMNS_' + tableType, selectedColumns, this.orgId)
        .subscribe();
    }
  }

  getDisplayValue(num, toBeAppended?, convertInPercantage?) {
    if(!num) {
      num = 0
    }

    if(convertInPercantage) {
      num = num * 100
    }
    num = Number(num).toFixed(1)
    num = toBeAppended ? num + toBeAppended : num
    return num
  }

  updateMapAndSave() {
    // this.portfolioService.debtMapChart = this.portfolioService.updateWorldMap();
    this.debtPortfolioService.saveFundPortfolioWidget();
  }

  // Moved to new comp
  searchCompanies() {
    if (this.searchString != "") {
      this.debtPortfolioService.searchedForms = this.debtPortfolioService.portfolioData.filter((item) => {
        if (item.companyName.toUpperCase().indexOf(this.searchString.toUpperCase()) >= 0 || item.id === this.searchString)
          return true
      })
    }
    else
      this.debtPortfolioService.searchedForms = this.debtPortfolioService.portfolioData;
  }




  //Start: Added code to delete companies
  deleteCompanySelectionChange(event, company){
    if(event.checked){
      this.listOfCompaniesToBeDeleted.push(company);
      this.selectedSecurityIdsForDeletion.push(company.companyId);
    }
    else if(!event.checked && this.checkIfCompanyExistsInList(company)){
      this.listOfCompaniesToBeDeleted.splice(this.listOfCompaniesToBeDeleted.findIndex(c => c.companyId === company.companyId), 1);
    }
  }

  checkIfCompanyExistsInList(company){
    if(this.listOfCompaniesToBeDeleted.findIndex(c => c.companyId === company.companyId) >= 0){
      return true;
    }
    else {
      return false;
    }
  }

  validationBeforeDeleteConfirm(content, securityPopup) {
    this.securityDeletable = []
    this.securityNonDeletable = [],
    this.allSecurityDeletable = [],
    this.securityDeletableIds = [],
    this.notSubmittedSecurityName = []
    //To check linked securities
    this.dataService.getGlobalConfig(this.orgId).pipe(
      mergeMap((res:any) => {
        let allSettings = res?.body?.response?.orgSettings;
        this.securityLinkage = allSettings?.find( e => e?.orgSettingsKey == 'SECURITY_LINKAGE')?.isActive || false;
        if (this.securityLinkage){
          return this.dataService.deleteAllCompanies(this.selectedSecurityIdsForDeletion);
        } else {
          return of(null);
        }
      }),
    ).subscribe((res: any) => {      
      if(this.securityLinkage){
        let securityData = res?.body?.response?.data; 
        if(securityData?.notSubmittedSecurities){
          securityData?.notSubmittedSecurities.forEach(e => {
            this.securityDeletableIds.push(e.securityId);
            this.notSubmittedSecurityName.push(e.companyName + ' - ' + e.securityName)
          })
          this.combinedNotSubmittedSecurityName = this.notSubmittedSecurityName;
          this.modalService.open(securityPopup, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
        }
        if(securityData?.deletionImpactType == 'SECURITY_LINKAGE_AVAILABLE'){
          this.modalService.dismissAll();
          if(securityData?.securitiesDeletable){    
            securityData?.securitiesDeletable.forEach(e => {
              this.securityDeletableIds.push(e.securityId);
              this.securityDeletable.push(e.companyName + ' - ' + e.securityName);
            });
            this.combinedDeletableSecurities = this.securityDeletable;
          }
          if (securityData?.securitiesNonDeletable) {
            securityData?.securitiesNonDeletable.forEach(e => {
              this.securityNonDeletable.push(e.companyName + ' - ' + e.securityName);
            })
            this.combinedNonDeletableSecurities = this.securityNonDeletable;
          }
          this.modalService.open(securityPopup, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
        }
        else {
          if(securityData?.securitiesDeletable){
            this.modalService.dismissAll();
            securityData?.securitiesDeletable.forEach(e => {
              this.securityDeletableIds.push(e.securityId);
              this.allSecurityDeletable.push(e.companyName + ' - ' + e.securityName);
            })
            this.combinedAllDeletableSecurities = this.allSecurityDeletable;
            this.modalService.open(securityPopup, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
          }
        }
      }
      else {
        this.companyNames = [];
        if(!this.listOfCompaniesToBeDeleted || this.listOfCompaniesToBeDeleted.length == 0) return;
        this.modalService.dismissAll()
        this.listOfCompaniesToBeDeleted.forEach(data => {
          this.companyNames.push(data.issuerName + ' - ' + data.securityName);
        })
        const concatenatedNames = this.companyNames;
        this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal', size: 'lg'});
        this.companyAndSecurityNames = concatenatedNames;
        this.securityDeletableIds = this.listOfCompaniesToBeDeleted.map(c => c.companyId);
      }
    })
  }


  deleteCompanies(content){
    // console.log("this.securityDeletableIds ", this.securityDeletableIds);

    // if(!this.listOfCompaniesToBeDeleted || this.listOfCompaniesToBeDeleted.length == 0) return;
    // const idsToBeDeleted = this.listOfCompaniesToBeDeleted.map(c => c.companyId);

    if(!this.securityDeletableIds || this.securityDeletableIds.length == 0) return;
      this.dataService.deleteDebtCompanies(this.securityDeletableIds).subscribe(response => {

        this.securityDeletableIds.forEach(cId => {
          //Delete from Company level table
          const indexCompanyLevel = this.debtPortfolioService.issuerPorfolioSummary.findIndex(c => c.companyId === cId);
          if(indexCompanyLevel >= 0) {
            this.debtPortfolioService.issuerPorfolioSummary.splice(indexCompanyLevel, 1)
          }
          //Delete from Reporting Currency - Current table
          const indexReportingCurrrencyCurrent = this.securityDetailsReportingCurrencyCurrent.findIndex(c => c.companyId === cId);
          if(indexReportingCurrrencyCurrent >= 0) {
            this.securityDetailsReportingCurrencyCurrent.splice(indexReportingCurrrencyCurrent, 1)
          }
          //Delete from Reporting Currency - Exited table
          const indexReportingCurrrencyExited = this.securityDetailsReportingCurrencyExited.findIndex(c => c.companyId === cId);
          if(indexReportingCurrrencyExited >= 0) {
            this.securityDetailsReportingCurrencyExited.splice(indexReportingCurrrencyExited, 1)
          }
          //Delete from Local Currency - Current table
          const indexLocalCurrrencyCurrent = this.securityDetailsLocalCurrencyCurrent.findIndex(c => c.companyId === cId);
          if(indexLocalCurrrencyCurrent >= 0) {
            this.securityDetailsLocalCurrencyCurrent.splice(indexLocalCurrrencyCurrent, 1)
          }
          //Delete from Local Currency - Exited table
          const indexLocalCurrrencyExited = this.securityDetailsLocalCurrencyExited.findIndex(c => c.companyId === cId);
          if(indexLocalCurrrencyExited >= 0) {
            this.securityDetailsLocalCurrencyExited.splice(indexLocalCurrrencyExited, 1)
          }
          //Delete from Total Reporting Companies
          const indexTotalCompanies = this.securityDetailsTotalReportingCurrency.findIndex(c => c.companyId === cId);
          if(indexTotalCompanies >= 0) {
            this.securityDetailsTotalReportingCurrency.splice(indexTotalCompanies, 1)
          }         
      })

        this.updateSummaryData(this.payloadForCurrencyChange);

        this.investmentSummaryGridCurrent.refresh();
        this.investmentSummaryGridExited.refresh();
        this.localCurrencyInvestmentSummaryGridCurrent.refresh();
        this.localCurrencyInvestmentSummaryGridExited.refresh();
        //this.debtPortfolioService.getDebtInvestMentDetails();
        this.onDeleteCompanies = true;

        this.modalService.dismissAll();
        this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal' });

      }, error => {
        console.log("Error While Deleting Companies", error);
        this.modalService.dismissAll();
        this.utilService.showMessage(this.translateService.getLabel("err_failed_delete") + " - " + this.companyNames + ". " + this.translateService.getLabel("info_try_after_sometime") + ".", this.translateService.getLabel("ok"), true);
      });

  }
  //END: delete company




  companyRowSelected(event) {
    console.log("Company Object:", event.data);
    if(event.data.investmentStatus?.toLowerCase() === this.investmentStatuses.RESTRUCTURED) {
      const issuerCompanyId = event.data.issuerCompanyId;
      this.router.navigateByUrl(`/debt-company-summary?issuerCompanyId=${issuerCompanyId}&companyId=${event.data.companyId}&fundId=${this.debtPortfolioService.selectedFundId}`);
    } else {
      this.router.navigateByUrl(`/debt-company-valuation?valuationId=${event.data.valuationDateId}&companyId=${event.data.companyId}&fundId=${this.debtPortfolioService.selectedFundId}`);
    }
  }

  okButtonHandler(args:any):void{
    if(args.requestType=="columnstate"){
      this.columnNames = [];
      this.investmentSummaryGridCurrent.getHiddenColumns().forEach(column=>{
        this.columnNames.push(column.headerText);
      })
      if(JSON.stringify(this.unSelectedColumnsDB) !== JSON.stringify(this.columnNames)) {
        this.dataService.saveWidgetDataToDB("PORTFOLIO_TABLE_COLUMNS", this.columnNames, this.fundId).subscribe( success => {
          // console.log("Data saved", success);
        }, error => {
          console.log("Failed to save", error);
        })
        this.unSelectedColumnsDB = this.columnNames;
      }
    }
  }

  /**
   * 
   * @param fileData : Data for the downloaded file
   * @param type : type of file download (excel/pdf/word)
   */
  public downloadFile(fileData: any, type: string): void {
    try {
      const url = (window as any).URL.createObjectURL(fileData);
      var link = document.createElement('a');
      link.href = url;
      link.target = "_blank";
      link.download = "Summary." + type;
      link.click();
    } catch (e) {
      console.log("Failed to download file", e)
    }
  }

  /**
   * Toast Message after file is downloaded
   */
  public toastMessage(message: string): void{
    this.toastService.openSnackBar(message);
  }

  setUpCompaniesSearch() {
    this.filterCompaniesFormGroup.valueChanges.pipe(
      debounceTime(250)
    ).subscribe((values) => {
      this.listOfCompaniesToBeDeleted = [];
      this.securityDetailsTotalReportingCurrency = this.companyDupObject.map((o) => { return {...o }});
      if(!values.companyName) {
        this.securityDetailsTotalReportingCurrency = this.companyDupObject.map((o) => { return {...o }});
      } else {
      this.securityDetailsTotalReportingCurrency = this.searchByNameOrSecurity(values.companyName, this.securityDetailsTotalReportingCurrency);
    }
    });
  }

  searchByNameOrSecurity(searchStr, ds) {
    const lowerCaseSearchStr = searchStr?.toLowerCase();
    const companies = ds.filter((company) => {
        return company.issuerName?.toLowerCase().indexOf(lowerCaseSearchStr) > -1 || company.securityName?.toLowerCase().indexOf(lowerCaseSearchStr) > -1;
    });
    return companies;
  }
}
