<!-- <form class="formDesign" #oldInvestmentForm="ngForm" (ngSubmit)="addToPortfolioTable()">
    <div class="form-group">
        <div class="row">
            <div class="col-2">
                <mat-label>Company Name</mat-label>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput type="text" name="companyName" [(ngModel)]="formObject.companyName" required>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="row">
            <div class="col-2">
                <mat-label>Security</mat-label>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-select name="security" [(ngModel)]="formObject.security">
                        <mat-option value="" disabled>Select</mat-option>
                        <mat-option value="Equity">Equity</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="form-group">
        <div class="row">
            <div class="col-2">
                <mat-label>Website</mat-label>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <input matInput type="text" name="website" [(ngModel)]="formObject.website" required>
                </mat-form-field>
            </div>
        </div>
    </div>
    <table class="table">
        <tr class="primary-bg">
            <th class="textAlign">Valuation Date</th>
            <th class="textAlign">Geography</th>
            <th class="textAlign">Sector</th>
            <th class="textAlign">Fair Value</th>
            <th class="textAlign">Stake</th>
            <th class="textAlign">Stake Value</th>
        </tr>
        <tr *ngFor="let item of portfolioService.userSavedInvestedForms">
            <td>{{ item.valuationDate | date:"mediumDate" }}</td>
            <td>{{ item.geography.name }}</td>
            <td>{{ item.sector }}</td>
            <td class="text-Align-Right">{{ item.investment.equityValue.finalAdjustedEquityVal | number: "1.1-2" }}</td>
            <td class="text-Align-Right">{{ item.investment.equityValue.stake | number: "1.1-2" }}</td>
            <td class="text-Align-Right">{{ item.investment.equityValue.stakeValue | number: "1.1-2" }}</td>
        </tr>
        <tr>
            <td>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" name="valuationDate"
                        [(ngModel)]="formObject.valuationDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <input matInput type="text" name="geography" [(ngModel)]="formObject.geography" required>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field>
                    <input matInput type="text" name="sector" [(ngModel)]="formObject.sector" required>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field style="text-align: right;">
                    <input matInput type="number" name="fairValue" [(ngModel)]="formObject.fairVal" required>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field style="text-align: right;">
                    <input matInput type="number" name="stake" [(ngModel)]="formObject.stake" required>
                </mat-form-field>
            </td>
            <td>
                <mat-form-field style="text-align: right;">
                    <input matInput type="number" name="stakeValue" [(ngModel)]="formObject.stakeVal" required>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div style="text-align: center;">
        <button mat-raised-button class="primary-bg" type="submit" [disabled]="!oldInvestmentForm.valid">Save</button>
    </div>
</form> -->

<div class="row">
  
  <div class="col tabset" style="margin: 10px;">
    <ngb-tabset>
      <ngb-tab title="{{ translateService.getLabel('inputs') }}" id="old-val-data-inputs">
        <ng-template ngbTabContent>
          <!-- Old valuation data excel-->
          <app-add-old-valuation (save)="userAction('SAVE')"></app-add-old-valuation>
        </ng-template>
      </ngb-tab>
      
      <ngb-tab title="{{ translateService.getLabel('files') }}" id="old-val-data-files">
        <!-- files upload, view, delete, download-->
        <ng-template ngbTabContent>
          <app-files></app-files>       
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>

  <div class="col-1">  
    <button mat-raised-button class="primary-light-bg p-button" (click)="userAction('CLOSE')">{{ translateService.getLabel("close") }}</button>
  </div>
</div>