import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-restructured-securities',
  templateUrl: './restructured-securities.component.html',
  styleUrls: ['./restructured-securities.component.scss']
})
export class RestructuredSecuritiesComponent implements OnInit {

  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridFilterSettings = { type: "Excel" };
  gridToolbar = ["ColumnChooser"];

 @ViewChild('restructuredSecuritiesTable', { static: false }) public restructuredSecuritiesTable: GridComponent;

  @Input() restructuredSecurities;
  @Output() navigateTab = new EventEmitter<string>();

  constructor(public translateService: TranslateService) { }

  ngOnInit(): void {
  }
  navigatewaterfallAnalysis(){
    this.navigateTab.emit('waterfallAnalysis');
  }

}
