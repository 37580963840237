import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PortFolioService } from '../portfolio.service';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-old-investments',
  templateUrl: './old-investments.component.html',
  styleUrls: ['./old-investments.component.scss']
})
export class OldInvestmentsComponent implements OnInit {
  @Output() action = new EventEmitter();

  constructor(public portfolioService: PortFolioService, private ds : DataService, private ums: UserManagementService,
    public translateService: TranslateService) { }

  ngOnInit() {
  }

  userAction(event) {
    this.action.next(event);
  }
}
