import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '../services/translation.service';
import { ImageLoadService } from '../services/image-load.service';
import { DataService } from '../services/data.service';

import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import { Router } from '@angular/router';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-credit-bi-dashboard',
  templateUrl: './credit-bi-dashboard.component.html',
  styleUrls: ['./credit-bi-dashboard.component.scss']
})
export class CreditBiDashboardComponent implements OnInit {
  @ViewChild(PowerBIReportEmbedComponent, { static: false }) reportObj!: PowerBIReportEmbedComponent;

  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: { panes: {
      filters: {
        expanded: false,
        visible: false,
      },
    }},
  };


  isDataloaded = false;
  noDashboardFound = false;


  constructor(
    public ds: DataService, 
    public translateService: TranslateService, 
    public imageLoadService: ImageLoadService,
    private router: Router,
    private ums: UserManagementService
    ) { }

  ngOnInit(): void {
    const url = this.router.url;
    let dashboardType = "CREDIT_DASHBOARD";
    // let dashboardType = "POWER_BI_TAB";
    // if(url.includes("overview")) {
    //   dashboardType = POWER_BI_DASHBOARD_TYPE.OVERVIEW;
    // } else if(url.includes("power-bi-report")) {
    //   dashboardType = POWER_BI_DASHBOARD_TYPE.POWER_BI_TAB;
    // }

    if(dashboardType) {
      const orgId = this.ums.getSelectedUserDetails().organization.id;
      this.ds.powerBIReport(dashboardType, orgId).subscribe((res: any)=> {
        const embdInfo = JSON.parse(res['body'].response);
        if(embdInfo) {
          this.reportConfig.embedUrl = embdInfo['embedReports'][0].embedUrl;
          this.reportConfig.id = embdInfo['embedReports'][0].reportId;
          this.reportConfig.accessToken = embdInfo['embedToken'].token;
          this.isDataloaded = true;
        } else {
          this.noDashboardFound = true;
        }
      });
    }
  }

}
