<nav-menu-header headerLabel="User Management"></nav-menu-header>

<div class="prospects-operation-content">
  <div class="row prospects-operation-content-row" *ngIf="is73sAdmin">
    <div class="card col operation" *ngFor="let stats of userStatistics">
      <div class="operation-header">
        {{stats.header}}
      </div>
      <div class="operation-value">
        <span class="float-left document">
          <i [class]="stats.icon"></i>
        </span>
        <div class="spinner-border loading" *ngIf="!stats.value"></div>
        <span *ngIf="stats.value">{{stats.value}}</span>
      </div>
      <div class="operation-footer" *ngIf="stats?.footer">
        {{stats?.footer?.label}} : {{stats?.footer?.value}}
      </div>
    </div>
  </div>

  <div class="card prospects-report users-report">
    <div class="mat-elevation-z8 users-mat-elevation">
      <div class="header-container">
        <h4>
          <span class="icon">
            <i class="far fa-building"></i>
          </span>
          Organizations
        </h4>
        
        <div class="spinner-border loading" *ngIf="orgLoading"></div>
        <span class="float-right" *ngIf="is73sAdmin">
          <span class="icon" (click)="openStartApplication(startApplicationContent)">
            <button class="btn add-btn" color="primary" type="button"><i class="fas fa-plus-circle"></i> Add</button>
          </span>
          <!-- <span class="icon btn-icon">
            <i class="fas fa-sync"></i>
          </span> -->
        </span>
      </div>
      <div *ngIf="is73sAdmin">        
        <mat-radio-group name="orgTypeSelection" [(ngModel)]="orgTypeSelection" (ngModelChange)="orgTypeSelectionChange($event)">
          <mat-radio-button [value]="'vc'">Venture Capital</mat-radio-button>
          <mat-radio-button [value]="'corp'">Corporate</mat-radio-button>
          <mat-radio-button [value]="'saf'">Entrepreneur</mat-radio-button>
        </mat-radio-group>
      </div>
      <table mat-table [dataSource]="orgDataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef > Name </th>
          <td mat-cell *matCellDef="let element;index as i">
            <ng-template [ngIf]="!orgEditable[i]">
              {{element.name}}
            </ng-template>
            <mat-form-field *ngIf="orgEditable[i]">
              <input matInput [(ngModel)]="element.name">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef> Created By </th>
          <td mat-cell *matCellDef="let element;index as i">
            <ng-template [ngIf]="!orgEditable[i]">
              {{element.createdBy}}
            </ng-template>
            <mat-form-field *ngIf="orgEditable[i]">
              <input matInput [(ngModel)]="element.createdBy">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef> Created On </th>
          <td mat-cell *matCellDef="let element;index as i">
            <ng-template [ngIf]="!orgEditable[i]">
              {{element.createdOn}}
            </ng-template>
            <mat-form-field *ngIf="orgEditable[i]">
              <input matInput [(ngModel)]="element.createdOn">
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef> View </th>
          <td mat-cell *matCellDef="let element;index as i">
            <span class="view-icon">
              <i class="fas fa-binoculars" (click)="clickViewOnOrg(element.id, element.name)"></i>
            </span>
          </td>
        </ng-container>
        
        <!-- <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element;index as i">
            <span (click)="orgEditable[i] = true" class="edit-icon" *ngIf="!orgEditable[i]">
              <i class="fas fa-pen"></i>
            </span>
            <span (click)="orgEditable[i] = false" class="save-icon" *ngIf="orgEditable[i]">
              <i class="far fa-save"></i>
            </span>
          </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef> Delete </th>
          <td mat-cell *matCellDef="let element;index as i">
            <span class="delete-icon">
              <i class="far fa-trash-alt"></i>
            </span>
          </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedOrgColumns"></tr>
      </table>

      <mat-paginator #orgPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

  <div class="card">
    
    <ngb-tabset class="users-tabset">
      
     <ngb-tab title="Users">
        <ng-template ngbTabContent>
          <div class="prospects-report users-report">
            <div class="mat-elevation-z8 users-mat-elevation">
              <div class="header-container">
                <span class="icon">
                  <i class="fas fa-user-tie"></i>
                </span>
                <span *ngIf="selectedOrg">{{selectedOrg + ' - '}}</span>User Management
                <div class="spinner-border loading" *ngIf="selectedOrg && userDataSource.data.length <= 0"></div>
                <span class="float-right" *ngIf="selectedOrg">
                  <span class="icon btn-icon" (click)="openCreateNewUser(createNewUserContent)">
                    <i class="fas fa-plus-circle"></i>
                  </span>
                  <!-- <span class="icon btn-icon">
                    <i class="fas fa-sync"></i>
                  </span> -->
                </span>
              </div>
              <table mat-table [dataSource]="userDataSource" matSort>
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef > User Id </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.userId}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef > User Name </th>
                  <td mat-cell *matCellDef="let element;index as i">
                    <ng-template [ngIf]="!userEditable[i]">
                      {{element.name}}
                    </ng-template>
                    <mat-form-field *ngIf="userEditable[i]">
                      <input matInput [(ngModel)]="element.name">
                    </mat-form-field>
                  </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element;index as i">
                      {{element.status}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef> Created By </th>
                    <td mat-cell *matCellDef="let element;index as i">
                      {{element.createdBy}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="createdOn">
                    <th mat-header-cell *matHeaderCellDef> Created On </th>
                    <td mat-cell *matCellDef="let element;index as i">
                      {{element.createdOn}}
                    </td>
                  </ng-container>
                
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef> Edit </th>
                  <td mat-cell *matCellDef="let element;index as i">
                    <span  (click)="openEditUser(element, createNewUserContent)" class="edit-icon" *ngIf="!userEditable[i]">
                      <i class="fas fa-pen"></i>
                    </span>
                    <span (click)="userEditable[i] = false" class="save-icon" *ngIf="userEditable[i]">
                      <i class="far fa-save"></i>
                    </span>
                  </td>
                </ng-container>
      
                <ng-container matColumnDef="delete">
                  <th mat-header-cell *matHeaderCellDef> Delete </th>
                  <td mat-cell *matCellDef="let element;index as i">
                    <span class="delete-icon">
                      <i class="far fa-trash-alt"></i>
                    </span>
                  </td>
                </ng-container>
      
                <tr mat-header-row *matHeaderRowDef="userDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: userDisplayedColumns"></tr>
              </table>
      
              <mat-paginator #userPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>

<ng-template #createNewUserContent let-createNewUserModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Create/Update User</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="createNewUserModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Email
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="userInfo.userId" placeholder="abc@comp.com">
        </mat-form-field>
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Name
      </div>    
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="userInfo.name" placeholder="Elon Musk">
        </mat-form-field>
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Status
      </div>
      <div class="col">
        <mat-radio-group name="radioOpt3" [(ngModel)]="userInfo.active">
          <mat-radio-button [value]="true">Active</mat-radio-button>
          <mat-radio-button [value]="false">Inactive</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row modal-row">
      <span class="spinner-border loading" *ngIf="savingUser"></span>
      <span style="float:right">
        <button class="btn cancel-btn secondary-bg" (click)="createNewUserModal.dismiss('Cross click')">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
          Cancel
        </button>
        <button class="btn save-btn primary-bg"  (click)="createUser()" [disabled]="!userInfo.name || !userInfo.userId || savingUser">
          <span class="icon">
            <i class="fas fa-check"></i>
          </span>
          Submit
        </button>
      </span>
    </div>
  </div>
</ng-template>

<ng-template #startApplicationContent let-startApplicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Add Organization</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="startApplicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Company Name
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="orgCompanyName">
        </mat-form-field>
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Email Id
      </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="orgEmailId" placeholder="abc@comp.com">
        </mat-form-field>
      </div>
    </div>
    <div class="row modal-row">
      <div class="col-4 modal-left-col">
        Valuation Type
      </div>
      <div class="col">
        <mat-radio-group name="valuationType" [(ngModel)]="valuationType">
          <mat-radio-button color="primary" [value]="'biz'">Corporate</mat-radio-button>
          <mat-radio-button color="primary" [value]="'imp'">Impairement</mat-radio-button>
          <mat-radio-button color="primary" [value]="'por'">Portfolio</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col"><b>API Usage Limit</b></div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col"> Search </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="orgApiLimits.search" placeholder="150">
        </mat-form-field>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col"> Background Check </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="orgApiLimits.bc" placeholder="50">
        </mat-form-field>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col"> Personality Analysis </div>
      <div class="col">
        <mat-form-field>
          <input matInput [(ngModel)]="orgApiLimits.pa" placeholder="20">
        </mat-form-field>
      </div>
    </div>

    <div class="row modal-row">
      <div class="col-4 modal-left-col">
      </div>
      <div class="col">
        <button class="btn cancel-btn secondary-bg" (click)="startApplicationModal.dismiss('Cross click')">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
          Cancel
        </button>
        <button class="btn save-btn primary-bg" (click)="createOrg()" [disabled]="!orgEmailId || !orgCompanyName">
          <span class="icon">
            <i class="fas fa-check"></i>
          </span>
          Submit
        </button>
      </div>
    </div>
  </div>
</ng-template>