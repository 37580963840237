<div mat-dialog-title class="dialog-header">
  <label class="header"> Annualized Risk Free Rate </label>
  <em class="fa fa-times" aria-hidden="true" (click)="dismiss()"></em>
</div>

<div mat-dialog-content>
  <div class="risk-free-rate-form">
    <div class="row">
      <div class="col-3">
        <ejs-radiobutton label="Auto" value="false" name="type1" [checked]="!annualizedRiskFreeRate.manualOverride"
          (change)="toggleManualOverrideRiskFreeRate(false)">
        </ejs-radiobutton>
      </div>
      <div class="col-4">
        <ejs-radiobutton label="Manual Override" value="true" name="type1"
          [checked]="annualizedRiskFreeRate.manualOverride" (change)="toggleManualOverrideRiskFreeRate(true)">
        </ejs-radiobutton>
      </div>
    </div>

    <div class="row" style="padding-top: 10px;" *ngIf="!annualizedRiskFreeRate.manualOverride">
      <div class="col input-label">
        Country
      </div>
      <div class="col">
        <ejs-dropdownlist class="input-field" placeholder="Select" [dataSource]='countries'
          [(value)]="annualizedRiskFreeRate.selectedCountry" [fields]="fieldMapRiskFreeRate">
        </ejs-dropdownlist>
      </div>
    </div>

    <div class="row" *ngIf="!annualizedRiskFreeRate.manualOverride">
      <div class="col input-label">
        Date
      </div>
      <div class="col">
        <ejs-datepicker class="input-field" placeholder="yyyy-MM-dd" format='yyyy-MM-dd'
          [(value)]="annualizedRiskFreeRate.selectedDate"></ejs-datepicker>
      </div>
    </div>

    <div class="row" *ngIf="!annualizedRiskFreeRate.manualOverride">
      <div class="col-6"></div>
      <div class="col-3"></div>
      <div class="col-3 align-right">
        <button mat-button class="btn-custom-primary" (click)="calculateRiskFreeRate()">Fetch</button>
      </div>
    </div>

    <div class="row" *ngIf="!annualizedRiskFreeRate.manualOverride && showSeriesDropdown">
      <div class="col input-label">
        Series
      </div>
      <div class="col">
        <ejs-dropdownlist class="input-field" placeholder="Select" [dataSource]='series'
          (change)="getRiskFreeRateFromSeries()" [(value)]="annualizedRiskFreeRate.selectedSeries"
          [fields]="fieldMapRiskFreeRate">
        </ejs-dropdownlist>
      </div>
    </div>
    <div class="row summary-cards" *ngIf="!annualizedRiskFreeRate.manualOverride">
      <div class="col-12">
        <!-- <div class="row summary-cards">
          <div class="col-8"> -->
        <div class="row risk-card">
          <div class="col summary-card-title" style="text-align: left;">Annualized Risk Free Rate
          </div>
          <div class="col summary-card-title">
            {{ annualizedRiskFreeRate.riskFreeRate | number: '1.1-1'}}%</div>
        </div>
      </div>
      <!-- </div>
      </div> -->
    </div>

    <div class="row" *ngIf="annualizedRiskFreeRate.manualOverride">
      <div class="col input-label">
        Annualized Risk Free Rate
      </div>
      <div class="col align-right">
        <ejs-numerictextbox class="input-field yellow-input" min="0" format="#,###.0'  %'"
          [(value)]="annualizedRiskFreeRate.manualRiskFreeRate" [showSpinButton]="false">
        </ejs-numerictextbox>
      </div>
    </div>
    <div class="row" *ngIf="annualizedRiskFreeRate.manualOverride">
      <div class="col input-label">
        Comment
      </div>
      <div class="col">
        <div class="comments-container">
          <textarea id="comment" rows="6" placeholder="Add Comment"
            [(ngModel)]="annualizedRiskFreeRate.comment"></textarea>
        </div>
      </div>
    </div>

  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="btn-custom-secondry">Cancel</button>
  <button mat-button [mat-dialog-close]="annualizedRiskFreeRate" cdkFocusInitial class="btn-custom-primary"
    (click)="saveRiskFreeRate()">Save</button>
</div>

<div class="center-spinner overlay" *ngIf="loading == 'LOADING'">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>
