import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "src/app/services/translation.service";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGauge from "highcharts/modules/solid-gauge";
import { DataService } from "src/app/services/data.service";
import { ActivatedRoute } from "@angular/router";
import { UtilService } from "src/app/utils/util.service";
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: "app-debt-company-summary-details",
  templateUrl: "./debt-company-summary-details.component.html",
  styleUrls: ["./debt-company-summary-details.component.scss"],
})
export class DebtCompanySummaryDetailsComponent implements OnChanges {
  @Input()keyMetricsAtEntry: any;
  @Input()transactionOverview:any;
  @Input()fairValueTrend:any;
  @Input()discountRateTrend:any;
  @Input()currency:any;
  data:any={};
  transactionData: any;
  disRateTrendData: any;
  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridFilterSettings = { type: "Excel" };
  gridToolbar = ["ColumnChooser"];

  transactionOverviewText: string = '';
  recentEventsText: string = '';
  issuerCompanyId:any
  @Input()recentEventsComments:any;
  @Input()transactionOverviewComments:any
  constructor(public translateService: TranslateService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService) {}

    ngOnChanges(): void {
      this.intiliazeContent();
      this.data = this.keyMetricsAtEntry;
      this.transactionData = this.transactionOverview;
    }

  intiliazeContent(){
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get('issuerCompanyId')
    this.recentEventsText = this.recentEventsComments;
    this.transactionOverviewText = this.transactionOverviewComments
  }
  saveText(){
    const payload = {
      transactionOverviewComment: this.transactionOverviewText,
      recentEvents: this.recentEventsText
    };
    this.utilService.showLoadingPopup();
    this.dataService.getConsolidatedCommentary(this.issuerCompanyId, payload)
    .subscribe(response => {
      if (response) {
        this.utilService.closeAllPopups();
      }
    });
  }
}
