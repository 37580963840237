import { AttrMap } from './attribute-interfaces';

const HIST_INCOME_STATEMENT_DEFAULT_MAP: AttrMap[] = [
  {
    qxKey: 'totalNetRevenue',
    qxLabel: 'Total Net Revenue',
    gxKey: 'Revenue',
    gxLabel: 'Revenue',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1000,
  },
  {
    qxKey: 'costOfGoodsSold',
    qxLabel: 'Cost of Goods Sold',
    gxKey: 'Cost_of_Goods_Sold',
    gxLabel: 'Cost of Goods Sold',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1001,
  },
  {
    qxKey: 'grossProfit',
    qxLabel: 'Gross Profit',
    gxKey: 'Gross_Profit',
    gxLabel: 'Gross Profit',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1002
  },
  {
    qxKey: 'grossProfitMargin',
    qxLabel: 'Gross Profit Margin',
    gxKey: 'Gross_Profit_Margin_Percentage',
    gxLabel: 'Gross Profit Margin %',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1003
  },
  // {
  //   qxKey: 'expenses',
  //   qxLabel: 'Expenses',
  //   gxKey: '',
  //   gxLabel: '',
  //   isGxGlobalAttribute: true,
  //   financialStatementType: 'INCOME_STATEMENT',
  //   financialComponentType: null,
  //   gxAttributeId: '',
  // },
  {
    qxKey: 'sellingGeneralAdminExp',
    qxLabel: 'Selling, General and Administrative Expenses',
    gxKey: 'Selling_General_And_Admin',
    gxLabel: 'Selling, General & Admin',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1004
  },
  {
    qxKey: 'advertisingAndPromotion',
    qxLabel: 'Marketing Expenses',
    gxKey: 'Marketing_Expenses',
    gxLabel: 'Marketing Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1005
  },
  {
    qxKey: 'salariesBenefitsAndWages',
    qxLabel: 'Salaries, Benefits & Wages',
    gxKey: 'Employee_Benefit',
    gxLabel: 'Employee Benefit',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1006
  },
  {
    qxKey: 'researchDevelopmentCost',
    qxLabel: 'Research & Development',
    gxKey: 'Research_And_Development',
    gxLabel: 'Research & Development',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1007
  },
  {
    qxKey: 'otherExpenses',
    qxLabel: 'Other Expenses',
    gxKey: 'Other_Expenses',
    gxLabel: 'Other Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1008
  },
  {
    qxKey: 'totalOperatingExpenses',
    qxLabel: 'Total Operating Expenses',
    gxKey: 'Total_Operating_Expenses',
    gxLabel: 'Total Operating Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1009
  },
  {
    qxKey: 'eBITDA',
    qxLabel: 'EBITDA',
    gxKey: 'EBITDA',
    gxLabel: 'EBITDA',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1010,
  },
  {
    qxKey: 'depreciationAmortization',
    qxLabel: 'Depreciation & Amortization',
    gxKey: 'Depreciation_And_Amortization',
    gxLabel: 'Depreciation & Amortization',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1011
  },
  {
    qxKey: 'earningsBefore',
    qxLabel: 'Earnings Before Interest & Taxes',
    gxKey: 'Earnings_Before_Interest_And_Taxes',
    gxLabel: 'Earnings Before Interest & Taxes',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1012
  },
  {
    qxKey: 'interestExpense',
    qxLabel: 'Interest Expense',
    gxKey: 'Interest_Expense',
    gxLabel: 'Interest Expense',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1013,
  },
  {
    qxKey: 'earningsBeforeTaxes',
    qxLabel: 'Earnings Before Taxes',
    gxKey: 'Earnings_Before_Taxes',
    gxLabel: 'Earnings Before Taxes',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1014,
  },
  {
    qxKey: 'taxes',
    qxLabel: 'Taxes',
    gxKey: 'Taxes',
    gxLabel: 'Taxes',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1015,
  },
  {
    qxKey: 'netIncome',
    qxLabel: 'Earnings (Net Income)',
    gxKey: 'Net_Income',
    gxLabel: 'Net Income',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 1016
  },
];
const HIST_BALANCE_SHEET_DEFAULT_MAP: AttrMap[] = [
  {
    qxKey: 'cash',
    qxLabel: 'Cash',
    gxKey: 'Cash',
    gxLabel: 'Cash',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2000,
  },
  {
    qxKey: 'accountsReceivable',
    qxLabel: 'Accounts Receivable',
    gxKey: 'Accounts_Receivable',
    gxLabel: 'Accounts Receivable',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2001,
  },
  {
    qxKey: 'inventory',
    qxLabel: 'Inventory',
    gxKey: 'Inventory',
    gxLabel: 'Inventory',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2002,
  },
  {
    qxKey: 'Other_Current_Assets',
    qxLabel: 'Other Current Assets',
    gxKey: 'Other_Current_Assets',
    gxLabel: 'Other Current Assets',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2003,
  },
  {
    qxKey: 'totalCurrentAssets',
    qxLabel: 'Total Current Assets',
    gxKey: 'Total_Current_Assets',
    gxLabel: 'Total Current Assets',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2003,
  },
  {
    qxKey: 'totalFixedAssets',
    qxLabel: 'Total Fixed Assets',
    gxKey: 'Total_Fixed_Assets',
    gxLabel: 'Total Fixed Assets',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2004,
  },
  {
    qxKey: 'totalAssets',
    qxLabel: 'Total Assets',
    gxKey: 'Total_Assets',
    gxLabel: 'Total Assets',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'ASSETS',
    gxAttributeId: '',
    qxKeyOrder: 2005,
  },
  // {
  //   qxKey: 'NO KEY',
  //   qxLabel: 'Liabilities - NO KEY',
  //   gxKey: '',
  //   gxLabel: '',
  //   financialStatementType: 'BALANCE_SHEET',
  //   financialComponentType: null,
  //   gxAttributeId: '',
  // },
  {
    qxKey: 'accountsPayable',
    qxLabel: 'Accounts Payable',
    gxKey: 'Accounts_Payable',
    gxLabel: 'Accounts Payable',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2006
  },
  {
    qxKey: 'shortTermDebt',
    qxLabel: 'Short Term Debt',
    gxKey: 'Short_Term_Debt',
    gxLabel: 'Short Term Debt',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2007
  },
  {
    qxKey: 'Other_Current_Liabilities',
    qxLabel: 'Other Current Liabilities',
    gxKey: 'Other_Current_Liabilities',
    gxLabel: 'Other Current Liabilities',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2008
  },
  {
    qxKey: 'Other_Non_Current_Liabilities',
    qxLabel: 'Other Non-Current Liabilities',
    gxKey: 'Other_Non_Current_Liabilities',
    gxLabel: 'Other Non-Current Liabilities',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2008
  },
  {
    qxKey: 'totalCurrentLiabilities',
    qxLabel: 'Total Current Liabilities',
    gxKey: 'Total_Current_Liabilities',
    gxLabel: 'Total Current Liabilities',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2008
  },
  {
    qxKey: 'longTermDebt',
    qxLabel: 'Long-Term debt',
    gxKey: 'Long_Term_Debt',
    gxLabel: 'Long-Term Debt',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2009,
  },
  {
    qxKey: 'totalNonCurrentLiabilities',
    qxLabel: 'Total Non Current Liabilities',
    gxKey: 'Total_Non_Current_Liabilities',
    gxLabel: 'Total Non Current Liabilities',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2010,
  },
  {
    qxKey: 'totalLiabilities',
    qxLabel: 'Total Liabilities',
    gxKey: 'Total_Liabilities',
    gxLabel: 'Total Liabilities',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'LIABILITIES',
    gxAttributeId: '',
    qxKeyOrder: 2011,
  },
  // {
  //   qxKey: 'NO KEY',
  //   qxLabel: 'Equity - NO KEY',
  //   gxKey: '',
  //   gxLabel: '',
  //   financialStatementType: 'BALANCE_SHEET',
  //   financialComponentType: null,
  //   gxAttributeId: '',
  // },
  {
    qxKey: 'totalShareholdersEquity',
    qxLabel: "Total Shareholder's Equity",
    gxKey: 'Total_Shareholders_Equity',
    gxLabel: `Total Shareholder's Equity`,
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'EQUITY',
    gxAttributeId: '',
    qxKeyOrder: 2012,
  },
  {
    qxKey: 'totalLiabilitiesAndEquity',
    qxLabel: 'Total Liabilities & Equity',
    gxKey: 'Total_Liabilities_And_Equity',
    gxLabel: 'Total Liabilities And Equity',
    isGxGlobalAttribute: true,
    financialStatementType: 'BALANCE_SHEET',
    financialComponentType: 'EQUITY',
    gxAttributeId: '',
    qxKeyOrder: 2013,
  },
];

const HIST_CASH_FLOW_STATEMENT_DEFAULT_MAP: AttrMap[] = [
  {
    qxKey: 'cashFromOperations',
    qxLabel: 'Cash Flow from Operations',
    gxKey: 'Cash_from_Operations',
    gxLabel: 'Cash from Operations',
    isGxGlobalAttribute: true,
    financialStatementType: 'CASH_FLOW_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 3000
  },
  {
    qxKey: 'capitalExpenditure',
    qxLabel: 'Capital Expenditure (incl Disposal)',
    gxKey: 'Capital_Expenditure',
    gxLabel: 'Capital Expenditure',
    isGxGlobalAttribute: true,
    financialStatementType: 'CASH_FLOW_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 3001
  },
  {
    qxKey: 'cashFromInvesting',
    qxLabel: 'Cash Flow from Investing (excl Capex)',
    gxKey: 'Cash_from_Investing',
    gxLabel: 'Cash from Investing',
    isGxGlobalAttribute: true,
    financialStatementType: 'CASH_FLOW_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 3002
  },
  {
    qxKey: 'cashFromFinancing',
    qxLabel: 'Cash Flow from Financing',
    gxKey: 'Cash_from_Financing',
    gxLabel: 'Cash from Financing',
    isGxGlobalAttribute: true,
    financialStatementType: 'CASH_FLOW_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 3003
  },
  {
    qxKey: 'closingCashBalance',
    qxLabel: 'Closing Cash Balance',
    gxKey: 'Closing_Cash_Balance',
    gxLabel: 'Closing Cash Balance',
    isGxGlobalAttribute: true,
    financialStatementType: 'CASH_FLOW_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 3004
  },
];

export { HIST_INCOME_STATEMENT_DEFAULT_MAP, HIST_BALANCE_SHEET_DEFAULT_MAP, HIST_CASH_FLOW_STATEMENT_DEFAULT_MAP };
