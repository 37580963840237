<ng-template [ngIf]="!item.approvalSystem && item.status == 'Submitted' && invSummaryService.checkWhetherBusinessUnitIsSubmitted(item)">
  <button [ngClass]="{'button-disable': (!item.selectedCondition.id || item.selectedCondition.conditionName == 'None'),'button-enable': (!!item.selectedCondition.id && item.selectedCondition.conditionName != 'None')}" 
  (click)="item.actionName = 'Initiate'; item.comment = ''; openPopupModal(captureComment, item);" 
  class="btn-status">
    {{ translateService.getLabel("initiate") }}
  </button>
</ng-template>

<!-- <ng-template [ngIf]="!ums.currentUserCompanyPermissionReadOnly && !!item.approvalSystem && !item.approvalSystem.userRole && item.approvalSystem.approvalObjectStatus == 'REQUEST NOT INITIATED'">
  <div matTooltip="{{ translateService.getLabel('info_approval_not_available') }}" matTooltipPosition='above'>
    <button style="border: none; color: grey; background-color: #F4F5FD; border-radius: 6px; cursor: pointer; width: 5vw;" 
    (click)="item.actionName = 'Initiate'; item.approvalSystem.approvalObjectStatus = 'INITIATED'; item.approvalSystem.userRole = 'INITIATOR'; updateOnAction(item)"
    class="btn-status" >
      {{ translateService.getLabel("initiate") }}
    </button>
  </div>
</ng-template> -->

<ng-template [ngIf]=" !!item.approvalSystem && (item.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS' || item.approvalSystem.approvalObjectStatus == 'INITIATED') && item.approvalSystem.status == 'INITIATED' && item.approvalSystem.userRole == 'INITIATOR'">
  <button style="border: none; color: #1D1563; background-color: #F4F5FD; border-radius: 6px; cursor: pointer; width: 50%;"
  (click)="item.actionName = 'Recall'; item.comment = 'Recalled'; updateOnAction(item)"
  class="btn-status">
    {{ translateService.getLabel("recall") }}
  </button>
</ng-template>

<ng-template [ngIf]=" !!item.approvalSystem && !item.approvalSystem.userRole">
</ng-template>

<ng-template [ngIf]=" !!item.approvalSystem && (item.approvalSystem.status != 'APPROVED' && item.approvalSystem.status != 'REJECTED')  && item.approvalSystem.userRole == 'APPROVER' && (item.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS' || item.approvalSystem.approvalObjectStatus == 'INITIATED')">
  <button style="border: none; color: #45C94E; background-color: #45C94E1A; border-radius: 6px 0 0 6px; cursor: pointer; width: 50%;"
  (click)="item.actionName = 'Approved'; item.comment = ''; openPopupModal(captureComment, item)"
  class="btn-status">
       {{ translateService.getLabel("approve") }}
  </button>
  <button style="border: none; color: #EE0F0F; background-color: #ff4c4c1a; border-radius: 0 6px 6px 0; cursor: pointer; width: 50%;"
  (click)="item.actionName = 'Rejected'; item.comment = ''; openPopupModal(captureComment, item)"
  class="btn-status" >
       {{ translateService.getLabel("reject") }}
  </button>

</ng-template>

<ng-template [ngIf]=" !!item.approvalSystem && (item.approvalSystem.approvalObjectStatus == 'APPROVED' || item.approvalSystem.approvalObjectStatus == 'REJECTED')">
    <div class="locked"><i class="fa fa-lock icon-lock" matTooltip="{{translateService.getLabel('this_version_is_not_editable')}}" matTooltipPosition='above'></i> </div>
</ng-template>

<!-- <ng-template [ngIf]="!item.actionName">
  {{ translateService.getLabel("loading_approval_information") }}
</ng-template> -->

<ng-template #captureComment let-applicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <span *ngIf="item.actionName == 'Initiate'" style="font-size: 22px;">{{translateService.getLabel("initiate_approval")}}</span>
      <span *ngIf="item.actionName == 'Approved'" style="font-size: 22px;">{{translateService.getLabel("approve")}}</span>
      <span *ngIf="item.actionName == 'Rejected'" style="font-size: 22px;">{{translateService.getLabel("reject")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="modal-content">
      <mat-form-field>
        <textarea matInput placeholder="Enter Comment"  [(ngModel)]="item.comment"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="item.actionName == 'Initiate'" type="button" class="btn btn-save" (click)="updateOnAction(item); applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("initiate")}}
    </button>
    <button *ngIf="item.actionName == 'Rejected'" type="button" class="btn btn-save" (click)="updateOnAction(item); applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("reject")}}
    </button>
    <button *ngIf="item.actionName == 'Approved'" type="button" class="btn btn-save" (click)="updateOnAction(item); applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("approve")}}
    </button>
  </div>
</ng-template>