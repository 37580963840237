import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from '../../../../services/user-management.service';

@Component({
    selector: 'audit-trail-global',
    templateUrl: './audit-trail-global.component.html',
    styleUrls: ['./audit-trail-global.component.scss']
})
export class AuditTrailGlobalComponent implements OnInit {

    orgId: any;
    selectedConfig: any;
    configSaving: boolean = false;
    qualitativeAnalysisValue:boolean = false;

    constructor(private modalService: NgbModal,
        private route: ActivatedRoute,
        public router: Router,
        private ds: DataService,
        private um: UserManagementService) { }

    ngOnInit(): void {
        const userDetails = this.um.getSelectedUserDetails();
        this.orgId = userDetails?.organization.id

        this.ds.getGlobalAuditConfig(this.orgId)
            .subscribe((res: any) => {
                let auditData = res?.body?.response?.configValue;
                this.selectedConfig = JSON.parse(auditData);

                //Manualy adding the qualitative key first time
                if(!this.selectedConfig?.qualitativeAnalysis){
                    this.selectedConfig.qualitativeAnalysis =
                       {
                        "qualitativeAnalysisValue": true
                    }
                }
            })
    }

    openPopupModal(content) {
        this.modalService.open(content, { centered: true, windowClass: 'center-popup-modal', size: 'lg' });
    }

    saveAuditTrailConfig() {
        this.configSaving = true;
        const stringifyData = JSON.stringify(this.selectedConfig);
        const payLoadObj = {
            "configKey": 'AUDIT_TRAIL',
            "configValue": stringifyData,
            "orgId": this.orgId
        };
        this.ds.saveGlobalAuditConfig(payLoadObj)
            .subscribe((res: any) => {
                this.configSaving = false;
            })
        this.modalService.dismissAll();
    }
}
