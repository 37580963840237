<div class="issuer-consol-summary-grid margin-top" *ngIf="data">
    <div class="e-card-header justify-content-left mb-3">
        <span class="main-page-header">Key Metrics</span>
    </div>
    <ejs-grid
    [dataSource]="data"
    [allowResizing]="true"
    [allowTextWrap]="true"
    [allowGrouping]="false"
    [allowExcelExport]="true"
    [allowSorting]="true"
    [allowReordering]="true"
    [allowPaging]="false"
    [pageSettings]="gridPageSettings"
    [showColumnChooser]="true"
    [allowFiltering]="true"
    [filterSettings]="gridFilterSettings"
    >
        <e-columns>
            <e-column field="metricName" [headerText]='translateService.getLabel("Metric Name")' width="75px" textAlign="left">
            </e-column>
            <e-column field="value" [headerText]='translateService.getLabel("Value")' width="75px" textAlign="left">
            </e-column>
        </e-columns>
    </ejs-grid>
</div>

<div class="transaction-overview-grid margin-top padding-top" *ngIf="transactionData">
    <div class="e-card-header justify-content-left mb-3">
        <span class="main-page-header">Transaction Overview</span>
    </div>
    <ejs-grid
    [dataSource]="transactionData"
    [allowResizing]="true"
    [allowSorting]="true"
    [allowPaging]="false"
    [allowTextWrap]="true"
    [pageSettings]="gridPageSettings"
    [showColumnChooser]="true"
    [allowFiltering]="true"
    [filterSettings]="gridFilterSettings"
    >
        <e-columns>
          <e-column field='Security Name' width='70px' textAlign="left">
            <ng-template #template let-comp>
              {{comp['Security Name'] }}
            </ng-template>
          </e-column>
          <e-column field='Investment Date' width='75px' textAlign="left">
            <ng-template #template let-comp>
              {{comp['Investment Date'] | date:'mediumDate'}}
            </ng-template>
          </e-column>
          <e-column field='Investment Amount' width='75px' textAlign="left">
            <ng-template #template let-comp>
              {{comp['Investment Amount'] }}
            </ng-template>
          </e-column>
          <e-column field='Expected Exit Date' width='75px' textAlign="left">
            <ng-template #template let-comp>
              {{comp['Expected Exit Date'] | date:'mediumDate' }}
            </ng-template>
          </e-column>
          <e-column field='Performance Classification' width='80' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Performance Classification'] }}
            </ng-template>
          </e-column>
          <e-column field='Attached Warrants' width='75px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Attached Warrants'] }}
            </ng-template>
          </e-column>
          <e-column field='OID' width='50px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['OID'] }}
            </ng-template>
          </e-column>
          <e-column field='Pricing - Cash' width='75px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Pricing - Cash'] }}
            </ng-template>
          </e-column>
          <e-column field='Pricing - PIK' width='75px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Pricing - PIK'] }}
            </ng-template>
          </e-column>
          <e-column field='Seniority and Type' width='75px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Seniority and Type'] }}
            </ng-template>
          </e-column>
          <e-column field='Remaining Time to Maturity' width='100px' textAlign="right">
            <ng-template #template let-comp>
              {{comp['Remaining Time to Maturity'] }}
            </ng-template>
          </e-column>
        </e-columns>
    </ejs-grid>
</div>


<div class="margin-top padding-top" *ngIf="fairValueTrend">
    <div class="e-card-header justify-content-left mb-3">
        <span class="main-page-header">Fair Value Trend</span>
    </div>
    <app-column-line [data]="fairValueTrend" [currency]="currency"></app-column-line>
</div>

<div class="margin-top-discount-chart" *ngIf="discountRateTrend">
    <div class="e-card-header justify-content-left mb-3">
        <span class="main-page-header">Discount Rate Trend</span>
    </div>
    <app-spline-chart [data]="discountRateTrend" [currency]="currency"></app-spline-chart>
</div>

<div class="row margin-top-discount-chart" *ngIf="data">
  <div class="col-6 " style="padding-top: 10px;">
    <div class="align-items-center">
      <label class="main-page-headertext " for="transaction-overview">Transaction Overview</label>
    </div>
    <textarea id="transaction-overview" class="text-box" [(ngModel)]="transactionOverviewText"></textarea>
  </div>
  <div class="col-6" style="padding-top: 10px;">
    <div class="align-items-left ">
      <label class="main-page-headertext" for="recent-events">Recent Events / Situation
        Update</label>
    </div>
    <textarea id="recent-events" class="text-box" [(ngModel)]="recentEventsText"></textarea>
  </div>
  <div class="col-12">
    <button (click)="saveText()" class="btn-custom-primary float-right">Save</button>
  </div>
</div>