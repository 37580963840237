import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { HttpResponse } from '@angular/common/http';
import { CompaniesInFundMap, Company, CompanyToVersionListAndAttributeList } from '../ca-interfaces';
import { VersionList } from '../../versions/version-interfaces';
import { AttributeList } from '../../attribute-list-container/attr-list-interfaces';

@Injectable({
  providedIn: 'root',
})
export class CaService {
  user;

  constructor(private dataService: DataService, private userMgmtService: UserManagementService) {
  }

  getVersionListsForUploadDate(uploadDate: string, fundId: string): Observable<VersionList[]> {
    return this.dataService.getVersionLists(uploadDate, fundId).pipe(map((resp) => resp.body?.response));
  }

  getAttrsListsForUploadDate(uploadDate: string, fundId: string): Observable<AttributeList[]> {
    return this.dataService.getAttributesLists(uploadDate, fundId).pipe(map((resp) => resp.body?.response));
  }

  assignVlToCompanies(selectedCompanyIds: string[], attrListId: string, uploadDate: string, versionId: string, fundId: string) {
    const selectedUserDetails = this.userMgmtService.getSelectedUserDetails();
    const requestBody: CompanyToVersionListAndAttributeList = {
      attributeListId: attrListId,
      fundId: fundId,
      userId: selectedUserDetails.id,
      orgId: selectedUserDetails.organization.id,
      uploadDate: uploadDate,
      versionListId: versionId,
      companyIds: selectedCompanyIds,
    };
    return this.dataService.associateVlToCompanies(requestBody);
  }

  validateSelectedCompanies(reqBody: Pick<CompanyToVersionListAndAttributeList, 'companyIds' | 'uploadDate' | 'fundId'>) {
    return this.dataService.validateCompanyAssociations(reqBody);
  }

  assignVlAndAttrsToDebtCompanies(selectedCompanyIds: string[], attrListId: string, uploadDate: string, versionId: string, fundId: string) {
    const selectedUserDetails = this.userMgmtService.getSelectedUserDetails();
    const requestBody: CompanyToVersionListAndAttributeList = {
      attributeListId: attrListId,
      fundId: fundId,
      userId: selectedUserDetails.id,
      orgId: selectedUserDetails.organization.id,
      uploadDate: uploadDate,
      versionListId: versionId,
      companyIds: selectedCompanyIds,
    };
    return this.dataService.associateVlAndAttrsToDebtCompanies(requestBody);
  }

  validateSelectedCompaniesForDebt(reqBody: Pick<CompanyToVersionListAndAttributeList, 'companyIds' | 'uploadDate' | 'fundId'>) {
    return this.dataService.validateDebtCompanyAssociations(reqBody);
  }
}
