import {
  FinancialsVersion,
  VersionList,
  VersionListDetails,
} from '../version-interfaces';

const DEAULT_VERSION_LIST: VersionList[] = [
  {
    id: 'default',
    name: 'Default List',
    createdBy: 'System',
    uploadDate: new Date().toISOString(),
  },
];

const DEFAULT_VERSION_LIST_DETAILS: Pick<
  VersionListDetails,
  'backwardLookingMappings' | 'forwardLookingMappings' | 'name'
> = {
  backwardLookingMappings: [
    {
      uuid: '',
      id: '',
      versionName: 'Actual',
      versionId: 'DEFAULT_BACKWARD_VERSION_ID',
      rank: 1,
      deleted: false,
    },
  ],
  forwardLookingMappings: [
    {
      uuid: '',
      id: '',
      versionName: 'Budget',
      versionId: 'DEFAULT_FORWARD_VERSION_ID',
      rank: 1,
      deleted: false,
    },
  ],
  name: 'Default List',
};
export { DEAULT_VERSION_LIST, DEFAULT_VERSION_LIST_DETAILS };
