<!-- Audit Trail Global Configuration -->
<div class="leverage-header-margin" *ngIf="selectedConfig">
  <span class="main-header">Inputs Tracked in Audit Trail</span><br>

  <!-- General Details -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > General Details</span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">ESG Score
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.esgScore" 
      (change)="selectedConfig.input.generalDetails.esgScore = !selectedConfig.input.generalDetails.esgScore;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Origination Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.originationDate" 
      (change)="selectedConfig.input.generalDetails.originationDate = !selectedConfig.input.generalDetails.originationDate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Exit Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.exitDate" 
      (change)="selectedConfig.input.generalDetails.exitDate = !selectedConfig.input.generalDetails.exitDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Day Count Convention (Interest Accrual)
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.dayCountConvention" 
      (change)="selectedConfig.input.generalDetails.dayCountConvention = !selectedConfig.input.generalDetails.dayCountConvention;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Day Count Convention (Discounting)
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.dayCountConventionDiscount" 
      (change)="selectedConfig.input.generalDetails.dayCountConventionDiscount = !selectedConfig.input.generalDetails.dayCountConventionDiscount;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Currency
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.currency" 
      (change)="selectedConfig.input.generalDetails.currency = !selectedConfig.input.generalDetails.currency;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Committed Capital at Origination
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.committedCapital" 
      (change)="selectedConfig.input.generalDetails.committedCapital = !selectedConfig.input.generalDetails.committedCapital;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Principal Outstanding at Origination
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.principalOutstanding" 
      (change)="selectedConfig.input.generalDetails.principalOutstanding = !selectedConfig.input.generalDetails.principalOutstanding;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Original Issue Discount
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.originalIssueDiscount" 
      (change)="selectedConfig.input.generalDetails.originalIssueDiscount = !selectedConfig.input.generalDetails.originalIssueDiscount;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Warrant Fair Value at Origination
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.warrantFairValue" 
      (change)="selectedConfig.input.generalDetails.warrantFairValue = !selectedConfig.input.generalDetails.warrantFairValue;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Middle Market Adjustment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.middleMarketAdjustment" 
      (change)="selectedConfig.input.generalDetails.middleMarketAdjustment = !selectedConfig.input.generalDetails.middleMarketAdjustment;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Tags
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.bxTags" 
      (change)="selectedConfig.input.generalDetails.bxTags = !selectedConfig.input.generalDetails.bxTags;"></mat-slide-toggle>
    </span>
  </div>

  <!-- additional input -->
  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Additional Inputs
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.additionalInputs" 
      (change)="selectedConfig.input.generalDetails.additionalInputs = !selectedConfig.input.generalDetails.additionalInputs;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">EUR LLI Adjustment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.eurLliAdjustment" (change)="selectedConfig.input.generalDetails.eurLliAdjustment = !selectedConfig.input.generalDetails.eurLliAdjustment;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Arr Deals
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.arr" 
      (change)="selectedConfig.input.generalDetails.arr = !selectedConfig.input.generalDetails.arr;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Link to Referrer Security
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.securityLinkageOpted" (change)="selectedConfig.input.generalDetails.securityLinkageOpted = !selectedConfig.input.generalDetails.securityLinkageOpted;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Referrer Security
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.generalDetails.securityLinkageParentSecurityName" 
      (change)="selectedConfig.input.generalDetails.securityLinkageParentSecurityName = !selectedConfig.input.generalDetails.securityLinkageParentSecurityName;"></mat-slide-toggle>
    </span>
  </div>


  <!-- Interest Details -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Interest Details</span>
  </div>

  <!-- <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Add Regime
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.warrantFairValue" 
      (change)="selectedConfig.input.interestDetails.warrantFairValue = !selectedConfig.input.interestDetails.warrantFairValue;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Delete Regime
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.warrantFairValue" 
      (change)="selectedConfig.input.interestDetails.warrantFairValue = !selectedConfig.input.interestDetails.warrantFairValue;"></mat-slide-toggle>
    </span>
  </div> -->

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Regime Start Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.regimeStartDate" 
      (change)="selectedConfig.input.interestDetails.regimeStartDate = !selectedConfig.input.interestDetails.regimeStartDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Regime End Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.regimeEndDate" 
      (change)="selectedConfig.input.interestDetails.regimeEndDate = !selectedConfig.input.interestDetails.regimeEndDate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">First Payment Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.firstInterestPaymentDate" 
      (change)="selectedConfig.input.interestDetails.firstInterestPaymentDate = !selectedConfig.input.interestDetails.firstInterestPaymentDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Payment Frequency
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.interestPaymentFrequency" 
      (change)="selectedConfig.input.interestDetails.interestPaymentFrequency = !selectedConfig.input.interestDetails.interestPaymentFrequency;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Interest Paid or Accrued
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.interestPaidOrAccrued" 
      (change)="selectedConfig.input.interestDetails.interestPaidOrAccrued = !selectedConfig.input.interestDetails.interestPaidOrAccrued;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Day of Interest Payment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.interestPaymentDay" 
      (change)="selectedConfig.input.interestDetails.interestPaymentDay = !selectedConfig.input.interestDetails.interestPaymentDay;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Interest Type
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.interestType" 
      (change)="selectedConfig.input.interestDetails.interestType = !selectedConfig.input.interestDetails.interestType;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Fixed Interest Rate
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.fixedBaseRate" 
      (change)="selectedConfig.input.interestDetails.fixedBaseRate = !selectedConfig.input.interestDetails.fixedBaseRate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Floating Base Rate
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.floatingBaseRateCurve" 
      (change)="selectedConfig.input.interestDetails.floatingBaseRateCurve = !selectedConfig.input.interestDetails.floatingBaseRateCurve;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Base Rate Cap
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.baseRateCap" 
      (change)="selectedConfig.input.interestDetails.baseRateCap = !selectedConfig.input.interestDetails.baseRateCap;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Base Rate Floor
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.baseRateFloor" 
      (change)="selectedConfig.input.interestDetails.baseRateFloor = !selectedConfig.input.interestDetails.baseRateFloor;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Spread Type
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.spreadType" 
      (change)="selectedConfig.input.interestDetails.spreadType = !selectedConfig.input.interestDetails.spreadType;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Spread Amount
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.baseRateSpread" 
      (change)="selectedConfig.input.interestDetails.baseRateSpread = !selectedConfig.input.interestDetails.baseRateSpread;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Financial Metric Used in Pricing Grid
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.financialMetric" 
      (change)="selectedConfig.input.interestDetails.financialMetric = !selectedConfig.input.interestDetails.financialMetric;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Credit Spread Adjustment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.creditSpreadAdjustment" 
      (change)="selectedConfig.input.interestDetails.creditSpreadAdjustment = !selectedConfig.input.interestDetails.creditSpreadAdjustment;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <!-- <span class="background-blue-track box-width">Company’s Metric Projection
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.pricingGridTable" 
      (change)="selectedConfig.input.interestDetails.pricingGridTable = !selectedConfig.input.interestDetails.pricingGridTable;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span> -->
    <span class="background-blue-track box-width">Pricing Grid
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestDetails.pricingGridTable" 
      (change)="selectedConfig.input.interestDetails.pricingGridTable = !selectedConfig.input.interestDetails.pricingGridTable;"></mat-slide-toggle>
    </span>
  </div>


  <!-- Principal Outstanding -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Principal Outstanding</span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.principalOutstanding.principalOutstandingDate" 
      (change)="selectedConfig.input.principalOutstanding.principalOutstandingDate = !selectedConfig.input.principalOutstanding.principalOutstandingDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">New Balance
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.principalOutstanding.principalOutstandingNewBalance" 
      (change)="selectedConfig.input.principalOutstanding.principalOutstandingNewBalance = !selectedConfig.input.principalOutstanding.principalOutstandingNewBalance;"></mat-slide-toggle>
    </span>
  </div>


  <!-- Principal Drawdown -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Principal Drawdown</span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Change in committed capital
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.principalDrawdown.principalDrawdownChangeInCommittedCapital" 
      (change)="selectedConfig.input.principalDrawdown.principalDrawdownChangeInCommittedCapital = !selectedConfig.input.principalDrawdown.principalDrawdownChangeInCommittedCapital;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Change in principal outstanding
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.principalDrawdown.principalDrawdownChangeInPrincipal" 
      (change)="selectedConfig.input.principalDrawdown.principalDrawdownChangeInPrincipal = !selectedConfig.input.principalDrawdown.principalDrawdownChangeInPrincipal;"></mat-slide-toggle>
    </span>
  </div>

  <!-- Repayment Details -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Repayment Details</span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Change in repayment dates
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.repaymentDetails.repaymentDate" 
      (change)="selectedConfig.input.repaymentDetails.repaymentDate = !selectedConfig.input.repaymentDetails.repaymentDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Change in repayment amounts
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.repaymentDetails.repaymentBalance" 
      (change)="selectedConfig.input.repaymentDetails.repaymentBalance = !selectedConfig.input.repaymentDetails.repaymentBalance;"></mat-slide-toggle>
    </span>
  </div>

  <!-- Call Premium -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Call Premium</span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Change in call dates
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.callPremium.date" 
      (change)="selectedConfig.input.callPremium.date = !selectedConfig.input.callPremium.date;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Change in associated call premium
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.callPremium.percentage" 
      (change)="selectedConfig.input.callPremium.percentage = !selectedConfig.input.callPremium.percentage;"></mat-slide-toggle>
    </span>
  </div>

  <!-- Deal Fees -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Deal Fees</span>
  </div>

  <!-- <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Add Regime
      <mat-slide-toggle style="float: right;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Delete Regime
      <mat-slide-toggle style="float: right;"></mat-slide-toggle>
    </span>
  </div> -->

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Regime Start Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.regimeStartDate" 
      (change)="selectedConfig.input.dealFees.regimeStartDate = !selectedConfig.input.dealFees.regimeStartDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Regime End Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.regimeEndDate" 
      (change)="selectedConfig.input.dealFees.regimeEndDate = !selectedConfig.input.dealFees.regimeEndDate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">First Payment Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.firstPaymentDate" 
      (change)="selectedConfig.input.dealFees.firstPaymentDate = !selectedConfig.input.dealFees.firstPaymentDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Day of Payment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.dayOfPayment" 
      (change)="selectedConfig.input.dealFees.dayOfPayment = !selectedConfig.input.dealFees.dayOfPayment;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Payment Frequency
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.paymentFrequency" 
      (change)="selectedConfig.input.dealFees.paymentFrequency = !selectedConfig.input.dealFees.paymentFrequency;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Fee Base
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.feeBase" 
      (change)="selectedConfig.input.dealFees.feeBase = !selectedConfig.input.dealFees.feeBase;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Fee Percentage
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.dealFees.feePercentage" 
      (change)="selectedConfig.input.dealFees.feePercentage = !selectedConfig.input.dealFees.feePercentage;"></mat-slide-toggle>
    </span>
  </div>


  <!-- Interest on Undrawn Capital -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Interest on Undrawn Capital</span>
  </div>

  <!-- <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Add Regime
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.feePercentage" 
      (change)="selectedConfig.input.interestUndrawnCapital.feePercentage = !selectedConfig.input.interestUndrawnCapital.feePercentage;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Delete Regime
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.feePercentage" 
      (change)="selectedConfig.input.interestUndrawnCapital.feePercentage = !selectedConfig.input.interestUndrawnCapital.feePercentage;"></mat-slide-toggle>
    </span>
  </div> -->

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Regime Start Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.regimeStartDate" 
      (change)="selectedConfig.input.interestUndrawnCapital.regimeStartDate = !selectedConfig.input.interestUndrawnCapital.regimeStartDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Regime End Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.regimeEndDate" 
      (change)="selectedConfig.input.interestUndrawnCapital.regimeEndDate = !selectedConfig.input.interestUndrawnCapital.regimeEndDate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">First Payment Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.firstPaymentDate" 
      (change)="selectedConfig.input.interestUndrawnCapital.firstPaymentDate = !selectedConfig.input.interestUndrawnCapital.firstPaymentDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <!-- <span class="background-blue-track box-width">Interest Paid or Accrued
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.regimeEndDate" 
      (change)="selectedConfig.input.interestUndrawnCapital.regimeEndDate = !selectedConfig.input.interestUndrawnCapital.regimeEndDate;"></mat-slide-toggle>
    </span> -->
  </div>

  <div class="audit-header-margin flex">
    <!-- <span class="background-blue-track box-width">Interest Payment mode
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.regimeEndDate" 
      (change)="selectedConfig.input.interestUndrawnCapital.regimeEndDate = !selectedConfig.input.interestUndrawnCapital.regimeEndDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span> -->
    <span class="background-blue-track box-width">Payment Frequency
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.interestPaymentFrequency" 
      (change)="selectedConfig.input.interestUndrawnCapital.interestPaymentFrequency = !selectedConfig.input.interestUndrawnCapital.interestPaymentFrequency;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Interest on Undrawn Capital Payment Percentage
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.interestUndrawnCapital.interestUndrawnPercentage" 
      (change)="selectedConfig.input.interestUndrawnCapital.interestUndrawnPercentage = !selectedConfig.input.interestUndrawnCapital.interestUndrawnPercentage;"></mat-slide-toggle>
    </span>
  </div>

  <!-- Skims -->
  <div class="audit-header-margin margin-credit-quality background-blue">
    <span class="audit-features-label">Inputs > Skims</span>
  </div>

  <!-- <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Add Regime
      <mat-slide-toggle style="float: right;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Delete Regime
      <mat-slide-toggle style="float: right;"></mat-slide-toggle>
    </span>
  </div> -->

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Regime Start Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.regimeStartDate" 
      (change)="selectedConfig.input.skims.regimeStartDate = !selectedConfig.input.skims.regimeStartDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Regime End Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.regimeEndDate" 
      (change)="selectedConfig.input.skims.regimeEndDate = !selectedConfig.input.skims.regimeEndDate;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">First Payment Date
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.firstPaymentDate" 
      (change)="selectedConfig.input.skims.firstPaymentDate = !selectedConfig.input.skims.firstPaymentDate;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Day of Payment
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.dayOfPayment" 
      (change)="selectedConfig.input.skims.dayOfPayment = !selectedConfig.input.skims.dayOfPayment;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Payment Frequency
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.skimPaymentFrequency" 
      (change)="selectedConfig.input.skims.skimPaymentFrequency = !selectedConfig.input.skims.skimPaymentFrequency;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Skim Base
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.skimBase" 
      (change)="selectedConfig.input.skims.skimBase = !selectedConfig.input.skims.skimBase;"></mat-slide-toggle>
    </span>
  </div>

  <div class="audit-header-margin flex">
    <span class="background-blue-track box-width">Skim Percentage
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.skimPercentage" 
      (change)="selectedConfig.input.skims.skimPercentage = !selectedConfig.input.skims.skimPercentage;"></mat-slide-toggle>
    </span>
    <span style="width: 4%;"></span>
    <span class="background-blue-track box-width">Outstanding balance as of Valuation Date 
      <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.skims.outstandingBalance" 
      (change)="selectedConfig.input.skims.outstandingBalance = !selectedConfig.input.skims.outstandingBalance;"></mat-slide-toggle>
    </span>
  </div>

    <!-- Issuer Financials -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Inputs > Issuer Financials</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Debt Senior to Issue
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.issuerFinancial.debtSeniorIssue" 
        (change)="selectedConfig.input.issuerFinancial.debtSeniorIssue = !selectedConfig.input.issuerFinancial.debtSeniorIssue;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Enterprise Value
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.issuerFinancial.enterpriseValue" 
        (change)="selectedConfig.input.issuerFinancial.enterpriseValue = !selectedConfig.input.issuerFinancial.enterpriseValue;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Net Latest Leverage
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.issuerFinancial.netLatestLeveragepricingGrid" 
        (change)="selectedConfig.input.issuerFinancial.netLatestLeveragepricingGrid = !selectedConfig.input.issuerFinancial.netLatestLeveragepricingGrid;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Shadow Rating Year
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.issuerFinancial.shadowRatingYear" 
        (change)="selectedConfig.input.issuerFinancial.shadowRatingYear = !selectedConfig.input.issuerFinancial.shadowRatingYear;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">LTM EBITDA
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.input.issuerFinancial.ltmEbitda" 
        (change)="selectedConfig.input.issuerFinancial.ltmEbitda = !selectedConfig.input.issuerFinancial.ltmEbitda;"></mat-slide-toggle>
      </span>
    </div>

    <!-- Discount Rate > Build-up > Discount Rate Computation -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Discount Rate > Build-up > Discount Rate Computation</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Custom Adjustments
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationBuildup.adjustment" 
        (change)="selectedConfig.discountRate.discountRateComputationBuildup.adjustment = !selectedConfig.discountRate.discountRateComputationBuildup.adjustment;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Risk-free Rate
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationBuildup.riskFreeRateMedian" 
        (change)="selectedConfig.discountRate.discountRateComputationBuildup.riskFreeRateMedian = !selectedConfig.discountRate.discountRateComputationBuildup.riskFreeRateMedian;"></mat-slide-toggle>
      </span>
    </div>
    
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Selected Metric
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationBuildup.selectedMetric" 
        (change)="selectedConfig.discountRate.discountRateComputationBuildup.selectedMetric = !selectedConfig.discountRate.discountRateComputationBuildup.selectedMetric;"></mat-slide-toggle>
      </span>
    </div>
    
    <!-- Discount Rate > Calibration > Discount Rate Computation -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Discount Rate > Calibration > Discount Rate Computation</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Risk-free Rate as of Amendment Date 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateOriginationDate" 
        (change)="selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateOriginationDate = !selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateOriginationDate;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Risk-free Rate as of Valuation Date
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateValuationDate" 
        (change)="selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateValuationDate = !selectedConfig.discountRate.discountRateComputationCalibration.riskFreeRateValuationDate;"></mat-slide-toggle>
      </span>
    </div>
    
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Adjustments
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateComputationCalibration.adjustment" 
        (change)="selectedConfig.discountRate.discountRateComputationCalibration.adjustment = !selectedConfig.discountRate.discountRateComputationCalibration.adjustment;"></mat-slide-toggle>
      </span>
    </div>  

    <!-- Discount Rate > Concluded Value > Concluded Discount Rate  -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Discount Rate > Concluded Value > Concluded Discount Rate</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Weight
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateConcluded.weight" 
        (change)="selectedConfig.discountRate.discountRateConcluded.weight = !selectedConfig.discountRate.discountRateConcluded.weight;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Value
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateConcluded.value" 
        (change)="selectedConfig.discountRate.discountRateConcluded.value = !selectedConfig.discountRate.discountRateConcluded.value;"></mat-slide-toggle>
      </span>
    </div>

    <!-- Discount Rate > Concluded Value > Range Analysis  -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Discount Rate > Concluded Value > Range Analysis</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Senstivity
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.discountRate.discountRateRangeAnalysis.sensitivity" 
        (change)="selectedConfig.discountRate.discountRateRangeAnalysis.sensitivity = !selectedConfig.discountRate.discountRateRangeAnalysis.sensitivity;"></mat-slide-toggle>
      </span>
    </div>

    <!-- Warrant Valuation > Black Scholes Merton Model   -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Warrant Valuation > Black Scholes Merton Model</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Underlying Price
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.blackSholesMertonWarrant.underlyingPrice" 
        (change)="selectedConfig.warrantValuation.blackSholesMertonWarrant.underlyingPrice = !selectedConfig.warrantValuation.blackSholesMertonWarrant.underlyingPrice;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Volatility Annualised 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.blackSholesMertonWarrant.annualizedVolatility" 
        (change)="selectedConfig.warrantValuation.blackSholesMertonWarrant.annualizedVolatility = !selectedConfig.warrantValuation.blackSholesMertonWarrant.annualizedVolatility;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Strike Price
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.blackSholesMertonWarrant.strikePrice" 
        (change)="selectedConfig.warrantValuation.blackSholesMertonWarrant.strikePrice = !selectedConfig.warrantValuation.blackSholesMertonWarrant.strikePrice;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Time to Expiration 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.blackSholesMertonWarrant.timeToExpiration" 
        (change)="selectedConfig.warrantValuation.blackSholesMertonWarrant.timeToExpiration = !selectedConfig.warrantValuation.blackSholesMertonWarrant.timeToExpiration;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Risk-free Rate Annualised
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.blackSholesMertonWarrant.riskFreeRate" 
        (change)="selectedConfig.warrantValuation.blackSholesMertonWarrant.riskFreeRate = !selectedConfig.warrantValuation.blackSholesMertonWarrant.riskFreeRate;"></mat-slide-toggle>
      </span>
    </div>

    <!-- Warrant Valuation > Multiple Based Warrant Valuation  -->
    <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Warrant Valuation > Multiple Based Warrant Valuation</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">EBITDA Multiple
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.ebitdaMultiple" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.ebitdaMultiple = !selectedConfig.warrantValuation.multipleBasedWarrant.ebitdaMultiple;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">EBITDA 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.ebitda" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.ebitda = !selectedConfig.warrantValuation.multipleBasedWarrant.ebitda;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Net Debt
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.netDebt" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.netDebt = !selectedConfig.warrantValuation.multipleBasedWarrant.netDebt;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Equity Ownership 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.equityOwnership" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.equityOwnership = !selectedConfig.warrantValuation.multipleBasedWarrant.equityOwnership;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Number of OBSAs
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.numberOfBPAs" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.numberOfBPAs = !selectedConfig.warrantValuation.multipleBasedWarrant.numberOfBPAs;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Warrant per OBSA 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.warrantsPerBPA" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.warrantsPerBPA = !selectedConfig.warrantValuation.multipleBasedWarrant.warrantsPerBPA;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Strike Price
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.strikePrice" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.strikePrice = !selectedConfig.warrantValuation.multipleBasedWarrant.strikePrice;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Time to Expiry 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.timeToExpiry" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.timeToExpiry = !selectedConfig.warrantValuation.multipleBasedWarrant.timeToExpiry;"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Discount Rate
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.discountRate" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.discountRate = !selectedConfig.warrantValuation.multipleBasedWarrant.discountRate;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Round to Nearest
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.multipleBasedWarrant.roundToNearest" 
        (change)="selectedConfig.warrantValuation.multipleBasedWarrant.roundToNearest = !selectedConfig.warrantValuation.multipleBasedWarrant.roundToNearest;"></mat-slide-toggle>
      </span>
    </div>

     <!-- Warrant Valuation > Concluded Warrant -->
     <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Warrant Valuation > Concluded Warrant</span>
    </div>
  
    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Approach Weights
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.concludedWarrant.approach" 
        (change)="selectedConfig.warrantValuation.concludedWarrant.approach = !selectedConfig.warrantValuation.concludedWarrant.approach;"></mat-slide-toggle>
      </span>
      <span style="width: 4%;"></span>
      <span class="background-blue-track box-width">Sensitivity 
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.warrantValuation.concludedWarrant.concludedSensitivity" 
        (change)="selectedConfig.warrantValuation.concludedWarrant.concludedSensitivity = !selectedConfig.warrantValuation.concludedWarrant.concludedSensitivity;"></mat-slide-toggle>
      </span>
    </div>

     <!-- Qualitative Analysis -->
     <div class="audit-header-margin margin-credit-quality background-blue">
      <span class="audit-features-label">Qualitative Analysis
      </span>
    </div>

    <div class="audit-header-margin flex">
      <span class="background-blue-track box-width">Comments
        <mat-slide-toggle style="float: right;" [checked]="selectedConfig.qualitativeAnalysis.qualitativeAnalysisValue" 
        (change)="selectedConfig.qualitativeAnalysis.qualitativeAnalysisValue = !selectedConfig.qualitativeAnalysis.qualitativeAnalysisValue"></mat-slide-toggle>
      </span>
    </div>

    <div class="audit-header-margin">
      <button mat-raised-button type="button" class="btn-custom-primary" (click)="openPopupModal(auditTrail)"
        [disabled]="configSaving">Save</button>
    </div>

</div>

<!-- Confirmation Dialog -->
<ng-template #auditTrail let-auditTrail>
    <div class="modal-header">
      <div class="modal-title">
        <span style="font-size: 22px;">Confirm</span>
      </div>
      <button #closeBtn type="button" class="close" aria-label="Close" (click)="auditTrail.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content deleteConfirmPopUpContent">
        <span style="font-size: 15px;">Please confirm if you wish to change Audit Trail Settings?</span>
      </div>
    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
      <button type="button" class="btn-custom-secondry" (click)="auditTrail.dismiss('Cross click')">
        No
      </button>
      <button type="button" class="btn-custom-primary" (click)="saveAuditTrailConfig()">
        Yes
      </button>
    </div>
</ng-template>
