import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  translations: any;
  selectedLang: string = window.localStorage.getItem("selectedLang") ? window.localStorage.getItem("selectedLang") : 'EN';

  constructor(private dataService: DataService) {}

  changeLanguage(selectedLang) {
    this.selectedLang = selectedLang;
    window.localStorage.setItem("selectedLang", this.selectedLang);
    window.location.reload();
  }

  getLabel(key: string) {
    return this.translations[key] ? this.translations[key] : key 
  }

  changeKeyAndGetLabel(label: string):string {
    let key = label.split(' ').join('_').toLowerCase();
    return this.getLabel(key);
  }

  getTranslations() {
    return this.dataService.getTranslatedData(this.selectedLang).pipe(
        tap((response) => {
          this.translations = response;
        })).toPromise();
  }
}