
<div class="main">
  <div class="get-form">
    <mat-form-field class="get-form-id-input">
      <input type="password" matInput [(ngModel)]="oldPassword" autocomplete="off">
      <mat-placeholder>
        Old Password
      </mat-placeholder>
    </mat-form-field>

    
    <mat-form-field class="get-form-id-input">
      <input type="password" matInput [(ngModel)]="newPassword1" autocomplete="off">
      <mat-placeholder>
        New Password
      </mat-placeholder>
    </mat-form-field>

        
    <mat-form-field class="get-form-id-input">
      <input type="password" matInput [(ngModel)]="newPassword2" autocomplete="off">
      <mat-placeholder>
        Confirm New Password
      </mat-placeholder>
    </mat-form-field>

    <button 
    mat-raised-button color="primary" 
    (click)="updatePassword()" [disabled]="newPassword1.length < 6 || newPassword2.length < 6 || oldPassword.length < 6">
    Save
    </button>

    <div style="width: 100%">NOTE: Password shall be atleast 6 characters long.</div>
  </div>
</div>