import { Component, OnDestroy, OnInit } from '@angular/core';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { Subscription, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from 'src/app/utils/util.service';
import { VersionListService } from './version-list.service';
import { switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/utils/toast.service';
import { DateUtilService } from 'src/app/date-util.service';
@Component({
  selector: 'app-versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss'],
})
export class VersionsComponent implements OnInit, OnDestroy {
  editVersion = false;
  fundId: string;

  private commonSub = new Subscription();

  constructor(
    private configMappingService: MappingMgmtConfigService,
    public vlService: VersionListService,
    private route: ActivatedRoute,
    private router: Router,
    private utilService: UtilService,
    private dateUtilSvc: DateUtilService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    const routeSnapShot = this.route.snapshot;
    this.fundId = routeSnapShot.paramMap.get('fundId');
    this.getVersionLists();
  }

  getVersionLists() {
    this.utilService.showLoadingPopup();
    this.commonSub.add(
      this.configMappingService._uploadDate
        .pipe(
          switchMap((newValDate) => {
            const date = this.dateUtilSvc.getDateYYYYMMDD(newValDate);
            return this.vlService.getVersionListsOrDefault(date, this.fundId);
          })
        )
        .subscribe(
          (response) => {
            console.log(response, 'Version lists response');
            if (response?.length > 0) {
              this.vlService.loadVersionListsFromApiResponse(response);
            } 
            // else if (response) {
            //   // there's a pipe on getVersionLists to suppress the error observable
            //   this.vlService.loadVersionListsFromApiResponse(DEAULT_VERSION_LIST);
            // }
            this.utilService.closeAllPopups();
          },
          (err) => {
            console.log('API failure VERSION LISTS', err);
            this.utilService.closeAllPopups();
          }
        )
    );
  }

  viewList(row) {
    console.log('View list...', row);
    // this.router.navigateByUrl(`/mapping-configuration/version-details/${'098765'}`);
    this.router.navigate(['../version-details', row.id], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  deleteVersionList(event) {
    const dialogRef = this.utilService.showConfirmMessage('Are you sure you want to delete this version?', 'Yes', 'No');
    dialogRef.afterClosed().subscribe((result) => {
      if(result === "Yes") {
        this.utilService.showLoadingPopup();
        this.vlService.deleteVersionList(event.versionList.id).subscribe(res=>{
          if(res.body["success"]) {
            this.utilService.closeAllPopups();
            this.toastService.openSnackBar('Version list deleted successfully');
            this.getVersionLists();
          }else{
            this.utilService.closeAllPopups();
            this.toastService.openSnackBar('Something went wrong! Please try again.');
          }
        },
        (err)=> {
            this.utilService.closeAllPopups();
            this.toastService.openSnackBar('Failed to delete version list. Please try again.');
          });
      }
    });
  }

  downloadVersionList(event){   
    return this.vlService.downloadVersionList(event.versionList?.id).subscribe((response: Blob) => {     
      const url = (window as any).URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
      var link = document.createElement('a');
      link.href = url;
      link.download = `${event.versionList?.name}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      (window as any).URL.revokeObjectURL(url);
    }, (err)=>{
      this.toastService.openSnackBar('Failed to dowload the file, Please try again.');
      console.log(err);
    });
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
    this.configMappingService.resetUploadDate();
  }
}
