import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { interestConstants } from '../debt-capital-structure/debt-capital-structure.constants';

@Component({
  selector: 'app-debt-capital-structure',
  templateUrl: './debt-capital-structure.component.html',
  styleUrls: ['./debt-capital-structure.component.scss']
})
export class DebtCapitalStructureComponent implements OnInit {
  popupHeader: any;
  buttonLabel: any;
  showDebtSecurityname: boolean = false;
  showcCashSecurityname: boolean = false;
  showEquitySecurityname: boolean = false;
  securityDebtExists: boolean = false;
  securityCashExists: boolean = false;
  securityEquityExists: boolean = false;
  constructor(public translateService: TranslateService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private utilService: UtilService) { }

  public interestFormConstant = interestConstants;
  selectedValuationDate: string;
  valuationDates: string[] = [];
  companyId: string;
  issuerCompanyId;
  addelements = ['Add debt', 'Add total', 'Add total debt']
  financialDates: string[] = [];
  cashData: any[] = [];
  equityData: any[] = [];
  totalDebtData: any[] = [];
  totalCashData: any[] = [];
  totalEquityData: any[] = [];
  totalEbitdaData: any[] = [];
  totalLeverageData: any[] = [];
  debtData: any[] = [];
  companySecurities: any
  noDataAvailable = false
  isEditClicked: boolean = false;
  isTransactionEditClicked: boolean = false;
  claimType;
  transactionOverviewData: any[] = [];
  selectedSecurityName: string = '';
  financialDateValues: { [key: string]: number } = {};
  populateSecurityFromAPI: boolean = true;
  isSaveVisible: boolean = false;
  selectedSecurity: any = [];
  isSecuritySelectedFromTransactions: boolean = false;
  transactionTempValuePricingCash:any;
  transactionTempValuePricingPik:any;
  transactionTempFixedInterestRate:any;
  transactionTempInterestType:any;
  transactionTempfloatingBaseRateCurve:any;

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get("issuerCompanyId");
    this.companyId = params.get("companyId");
    this.getValuationdates();
    // this.selectedValuationDate = params.get("valuationDate");
    // if (!this.selectedValuationDate && this.valuationDates.length > 0) {
    //   this.selectedValuationDate = this.valuationDates[0];
    // }
    this.getCapitalStructure();
  }

  checkIfSecurityDebtExists() {
    this.securityDebtExists = this.debtData.some(
      (company) => company.companyName?.toLowerCase() === this.selectedSecurityName.toLowerCase()
    );
  }
  checkIfSecurityCashExists(){
    this.securityCashExists = this.cashData.some(
      (company) => company.companyName?.toLowerCase() === this.selectedSecurityName.toLowerCase()
    );

  }
  checkIfSecurityEquityExists(){
    this.securityEquityExists = this.equityData.some(
      (company) => company.companyName?.toLowerCase() === this.selectedSecurityName.toLowerCase()
    );
  }


  getCapitalStructure() {
    this.utilService.showLoadingPopup();
    this.dataService.getCapitalStructureSummmary(this.issuerCompanyId).subscribe((response: any) => {
      this.financialDates = response?.body?.response?.financialDates
      this.debtData = response?.body?.response?.debtData;
      this.cashData = response?.body?.response?.cashData;
      this.equityData = response?.body?.response?.capitalizationData;
      this.companySecurities = response?.body?.response?.companies;
      this.debtData.forEach(company => {
        this.financialDates.forEach(date => {
          if (!company[date]) {
            company[date] = 0;
          }
        });
      });
      this.cashData.forEach(company => {
        this.financialDates.forEach(date => {
          if (!company[date]) {
            company[date] = 0;
          }
        });
      });
      this.equityData.forEach(company => {
        this.financialDates.forEach(date => {
          if (!company[date]) {
            company[date] = 0;
          }
        });
      });
      const filteredData = response?.body?.response?.summaryData
        .filter(item => item.totalDebt !== undefined)
        .reduce((result, item) => {
          const dataObj = result.length > 0 ? result[0] : { 'Financial Date': item.financialDate };
          this.financialDates.forEach(date => {
            dataObj[date] = (dataObj[date] || 0) + (date === item.financialDate ? item.totalDebt : 0);
          });
          return [dataObj];
        }, []);
      this.totalDebtData = filteredData;

      const filteredData2 = response?.body?.response?.summaryData
        .filter(item => item.totalDebt !== undefined)
        .reduce((result, item) => {
          const dataObj = result.length > 0 ? result[0] : { 'Financial Date': item.financialDate };
          this.financialDates.forEach(date => {
            dataObj[date] = (dataObj[date] || 0) + (date === item.financialDate ? item.totalNetDebt : 0);
          });
          return [dataObj];
        }, []);
      this.totalCashData = filteredData2;

      const filteredData3 = response?.body?.response?.summaryData
        .filter(item => item.totalDebt !== undefined)
        .reduce((result, item) => {
          const dataObj = result.length > 0 ? result[0] : { 'Financial Date': item.financialDate };
          this.financialDates.forEach(date => {
            dataObj[date] = (dataObj[date] || 0) + (date === item.financialDate ? item.totalCapitalization : 0);
          });
          return [dataObj];
        }, []);
      this.totalEquityData = filteredData3;

      const filteredData4 = response?.body?.response?.summaryData
        .filter(item => item.totalDebt !== undefined)
        .reduce((result, item) => {
          const dataObj = result.length > 0 ? result[0] : { 'Financial Date': item.financialDate };
          this.financialDates.forEach(date => {
            dataObj[date] = (dataObj[date] || 0) + (date === item.financialDate ? item.ebitda : 0);
          });
          return [dataObj];
        }, []);
      this.totalEbitdaData = filteredData4;

      const filteredData5 = response?.body?.response?.summaryData
        .filter(item => item.totalDebt !== undefined)
        .reduce((result, item) => {
          const dataObj = result.length > 0 ? result[0] : { 'Financial Date': item.financialDate };
          this.financialDates.forEach(date => {
            dataObj[date] = (dataObj[date] || 0) + (date === item.financialDate ? item.leverage : 0);
          });
          return [dataObj];
        }, []);
      this.totalLeverageData = filteredData5;
      this.getTransactionOverview();
      (error: any) => {
        this.noDataAvailable = true;
      }
    })
  }

  onValuationDateChange(event) {
    this.selectedValuationDate = event.value;
    this.getCapitalStructure()
  }

  resetTransctionOverview() {
    this.isEditClicked = false;
    this.getCapitalStructure()
  }

  getValuationdates() {
    this.dataService.getWaterfallValuationDates(this.issuerCompanyId)
      .then(valuationDatesResp => {
        const valudationDates = valuationDatesResp['body']['response']['valuationDates'];
        valudationDates.sort();
        valudationDates.reverse();
        this.valuationDates = valudationDates;
        this.selectedValuationDate = this.valuationDates[0];
      })
  }

  openPopupAddClaim(content: any, type: String) {
    this.selectedSecurityName = '';
    this.financialDateValues = {};
    this.claimType = type;
    this.popupHeader = this.translateService.getLabel("add");
    this.buttonLabel = this.translateService.getLabel("add");
    this.showDebtSecurityname = type === 'debit';
    this.showcCashSecurityname = type === 'cash';
    this.showEquitySecurityname= type === 'equity';
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  addCashDebt() {
    this.isEditClicked = true;
  }

  updateCapitalStructureData() {
    const selectedSecurityName = this.selectedSecurityName;
    const selectedCompany = this.companySecurities.find(company => company.companyName === selectedSecurityName);
    const companyId = selectedCompany ? selectedCompany.companyId : '';
    const payload = {
      "companyId": companyId,
      "companyName": selectedSecurityName
    };
    for (let header of this.financialDates) {
      const debitValue = this.financialDateValues[header] || 0;
      payload[header] = debitValue;
    }
    let dataArray;
    if (this.claimType === 'debit') {
      dataArray = this.debtData;
    } else if (this.claimType === 'cash') {
      dataArray = this.cashData;
    } else if (this.claimType === 'equity') {
      dataArray = this.equityData;
    }
    const existingItemIndex = dataArray.findIndex(item => item.companyName === selectedSecurityName);
    if (existingItemIndex !== -1) {
      const existingItem = dataArray[existingItemIndex];
      for (let header of this.financialDates) {
        existingItem[header] = payload[header];
      }
    } else {
      dataArray.push({ ...payload });
    }
    this.selectedSecurityName = '';
    this.financialDateValues = {};
    this.modalService.dismissAll();
    this.isSaveVisible = true
  }
  isManualSecurityTransactionExists = false;
  showTranscationOverviewTable = true;
  getTransactionOverview() {
    this.utilService.showLoadingPopup();
    this.dataService.getTransctionOverviewSummmary(this.issuerCompanyId, this.selectedValuationDate).subscribe((res: any) => {
      this.transactionOverviewData = res?.body?.response?.transactionsView;
      if (this.transactionOverviewData.length === 0) {
        this.showTranscationOverviewTable = false;
      } else {
        this.transactionOverviewData['isSecuritySelectedFromTransactions'] = false;
        const isManualSecurityTransactionExists = this.transactionOverviewData.some(
          transaction => transaction.securityType === 'MANUAL_SECURITY'
        );
        this.isManualSecurityTransactionExists = isManualSecurityTransactionExists;
      }
      this.utilService.closeAllPopups();
    }, (error: any) => {
      this.utilService.closeAllPopups();
    });
  }

  debitDataChange(value, i, j?) {
    if (j != undefined) {
      this.debtData[i][j] = value;
    } else {
      this.debtData[i]['companyName'] = value;
    }
  }

  cashDataChange(value, i, j?) {
    if (j != undefined) {
    this.cashData[i][j] = value;
  } else {
      this.cashData[i]['companyName'] = value;
    }
  }

  equityDataChange(value, i, j?) {
    if (j != undefined) {
    this.equityData[i][j] = value;
  } else {
      this.equityData[i]['companyName'] = value;
    }
  }

  save() {
    this.utilService.showLoadingPopup();
    const payload = [];
       const getCompanyIdForSecurity = (securityName) => {
          const selectedCompany = this.companySecurities.find(company => company.companyName === securityName);
           return selectedCompany ? selectedCompany.companyId : null;
      };
    for (let data of this.debtData) {
      for (let header of this.financialDates) {
        interface FinancialData {
          debt?: number;
        }
        const debtValue = data[header];
        payload.push({
          "companyId":getCompanyIdForSecurity(data.companyName),
          "companyName": data.companyName,
          "financialDate": header,
          "debt": debtValue , 
          "isExposure":data.isExposure
          
        });
      }
    }
    for (let data of this.cashData) {
      for (let header of this.financialDates) {
        interface FinancialData {
          cash?: number;
        }
        const cashValue = data[header];
        payload.push({
          "companyId": getCompanyIdForSecurity(data.companyName),
          "companyName": data.companyName,
          "financialDate": header,
          "cash": cashValue, 
          "isExposure":data.isExposure
        });
      }
    }
    for (let data of this.equityData) {
      for (let header of this.financialDates) {
        interface FinancialData {
          equity?: number;
        }
        const equityValue = data[header];
        payload.push({
          "companyId": getCompanyIdForSecurity(data.companyName),
          "companyName":data.companyName,
          "financialDate": header,
          "equity": equityValue, 
          "isExposure":data.isExposure

        });
      }
    }
     
    // In Case No data in the table then there is no save ApI Call required
    if (payload.length === 0) {
      this.getCapitalStructure()
      this.isEditClicked = false;
      this.isSaveVisible = false;
      this.isTransactionEditClicked = false;
      setTimeout(() => {
        this.utilService.closeAllPopups();
      }, 1000);
      return;
    }

    const finalPayload = {
      financialDateData: payload
    };
    this.dataService.putCapitalStructureSummmary(this.issuerCompanyId, finalPayload).subscribe((res: any) => {
      this.getCapitalStructure()
      this.isEditClicked = false;
      this.isSaveVisible = false;
      this.isTransactionEditClicked = false;
    }, (error) => {
      if (error.status === 400 && error.error?.message) {
        this.utilService.closeAllPopups();
        this.utilService.showMessage(error.error.message, 'Ok');
      }
    });
}
  cancel() {
    this.isEditClicked = false
  }

  editCustomSecurity(content, selectedSecurity, interestPaidOrAccrued) {
    this.selectedSecurity = selectedSecurity;
    this.selectedSecurity.interestPaidOrAccrued = interestPaidOrAccrued;
    this.selectedSecurity.spreadType = 'FIXED';
    this.transactionTempValuePricingCash = this.selectedSecurity.pricingCash;
    this.transactionTempValuePricingPik = this.selectedSecurity.pricingPik;
    this.transactionTempFixedInterestRate = this.selectedSecurity.fixedInterestRate;
    this.transactionTempfloatingBaseRateCurve = this.selectedSecurity.floatingBaseRateCurve;
    this.transactionTempInterestType = this.selectedSecurity.interestRateType;

    if((interestPaidOrAccrued === "ACCRUED" && this.selectedSecurity.pricingPik === "NA") || (interestPaidOrAccrued === "PAID" && this.selectedSecurity.pricingCash === "NA")){
      this.selectedSecurity.interestRateType = "FIXED";
      this.selectedSecurity.floatingBaseRateCurve="NA";
      this.selectedSecurity.fixedInterestRate = "NA";
    }

    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  handlePercentage(value){
    return value.includes('%') ? value : value +'%';
  }

  updateTransactionOverviewModalData() {
    if(this.selectedSecurity.interestRateType !== 'FIXED'){
      if(this.selectedSecurity.interestPaidOrAccrued === 'PAID'){
        this.selectedSecurity.pricingCash = this.selectedSecurity.floatingBaseRateCurve + " + " + this.handlePercentage(this.selectedSecurity.fixedInterestRate);
        this.selectedSecurity.pricingPik = "NA";
      }
      else{
        this.selectedSecurity.pricingCash = "NA";
        this.selectedSecurity.pricingPik = this.selectedSecurity.floatingBaseRateCurve + " + " + this.handlePercentage(this.selectedSecurity.fixedInterestRate);
      }
    }
    else{
      if(this.selectedSecurity.interestPaidOrAccrued === 'PAID'){
        this.selectedSecurity.pricingCash = this.handlePercentage(this.selectedSecurity.pricingCash);
        this.selectedSecurity.pricingPik = "NA";
      }
      else{
        this.selectedSecurity.pricingCash = "NA";
        this.selectedSecurity.pricingPik = this.handlePercentage(this.selectedSecurity.pricingPik);
      }
    }
    this.modalService.dismissAll();
    this.selectedSecurity.isSecuritySelectedFromTransactions = true;
  }
  resetTransactionOverviewOnClose(){
    this.selectedSecurity.interestRateType = this.transactionTempInterestType;
    this.selectedSecurity.floatingBaseRateCurve = this.transactionTempfloatingBaseRateCurve;
    if(this.selectedSecurity.interestRateType == 'FIXED'){
      if(this.selectedSecurity.interestPaidOrAccrued === 'PAID')
        this.selectedSecurity.pricingCash = this.transactionTempValuePricingCash;
      else
        this.selectedSecurity.pricingPik =  this.transactionTempValuePricingPik;
    }else{
        this.selectedSecurity.fixedInterestRate = this.transactionTempFixedInterestRate;
    }
    this.modalService.dismissAll();
  }

  updateTransactionOverview(selectedSecurity) {
    this.utilService.showLoadingPopup();
    this.selectedSecurity = selectedSecurity;
    this.selectedSecurity.investmentDate = this.selectedSecurity.investmentDate ? (moment(this.selectedSecurity.investmentDate)).format("YYYY-MM-DD") : '';
    this.selectedSecurity.exitDate = this.selectedSecurity.exitDate ? (moment(this.selectedSecurity.exitDate)).format("YYYY-MM-DD") : '';
    delete this.selectedSecurity.isSecuritySelectedFromTransactions;
    let payload = [];
    if(this.selectedSecurity.interestRateType == 'FIXED')
    this.selectedSecurity.fixedInterestRate = this.selectedSecurity.interestPaidOrAccrued === 'PAID' ? this.selectedSecurity.pricingCash?.replace("%", "") : this.selectedSecurity.pricingPik?.replace("%", "");
    payload.push(this.selectedSecurity);
    this.dataService.saveTransctionOverviewSummmary(this.issuerCompanyId, payload)
      .pipe(
        catchError((err) => {
            this.utilService.closeAllPopups();
            return of(err); 
          }))
      .subscribe((res:any) => {
        this.getTransactionOverview();
      })
  }

  deleteRow(rowIndex: number, claimType: string) {
    let metricName;
    let rowData;
    if (claimType === 'debt') {
        metricName = 'debt';
        rowData = this.debtData;
    } else if (claimType === 'cash') {
        metricName = 'cash';
        rowData = this.cashData;
    } else if (claimType === 'equity') {
        metricName = 'equity';
        rowData = this.equityData;
    }
    if (!rowData[rowIndex]?.recordId) {
      rowData.splice(rowIndex, 1); 
      return;
  }
    const getCompanyIdForSecurity = (securityName) => {
        const selectedCompany = this.companySecurities.find(company => company.companyName === securityName);
        return selectedCompany ? selectedCompany.companyId : null;
    };
    const payload = {
        "metricsType": metricName,
        "companyName": rowData[rowIndex]?.companyName,
        "companyId": getCompanyIdForSecurity(rowData[rowIndex]?.companyName),
    };
    this.dataService.deleteRecord(this.issuerCompanyId, payload).subscribe(
        () => {
            this.getCapitalStructure();
        }
    );
  }
  editTransactionOverviewData() {
    this.isTransactionEditClicked = true
  }
  cancelTransactionOverviewData() {
    this.isTransactionEditClicked = false;
  }

  resetTransactionTempUpdates(){
    this.getTransactionOverview();
  }
}
