<nav-menu-header headerLabel="Bugs"></nav-menu-header>

<div class="card" style="padding: 10px">
  <div class="row" style="font-weight: bold">
    <div class="col-1">
      Type
    </div>
    <div class="col-2">
      Page
    </div>
    <div class="col">
      Details
    </div>
    <div class="col-1">
      Status
    </div>
    <div class="col-1">
      Created On
    </div>
    <div class="col-1">
      Updated On
    </div>
  </div>
  <div class="row data-row" *ngFor="let bug of bugs">
    <div class="col-1">
      {{bug.type}}
    </div>
    <div class="col-2">
      {{bug.page}}
    </div>
    <div class="col">
      {{bug.detals}}
    </div>
    <div class="col-1">
      {{bug.status}}
    </div>
    <div class="col-1">
      {{bug.createdDate | date}}
    </div>
    <div class="col-1">
      {{bug.lastModifiedDate | date}}
    </div>
  </div>
</div>
