import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MatDatepicker, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageLoadService } from '../services/image-load.service';
import { default as _rollupMoment, Moment } from 'moment';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class StatusPageComponent implements OnInit {
  showPageOne = true;
  productStatus = [
    {
      data: 'Qubit X - Equity',
      comment: 'No Issue Detected!'
    },
    {
      data: 'Qubit X - Debt',
      comment: 'No Issue Detected!'
    },
    {
      data: 'Qubit X - Monitoring',
      comment: 'No Issue Detected!'
    }
  ]

  data = [];

  yearTransaction = [];

  constructor(private router: Router,
    public loaderService: ImageLoadService,
    private activatedRoute : ActivatedRoute) { }

  ngOnInit(){
    this.getPreviousMonths(3, this.data);
  }

  getPreviousMonths(length, monthsArray){
    let monthName = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");

    let d = new Date();
    d.setDate(1);
    d.setMonth(d.getMonth() - 1);

    for (let i=0; i<length; i++) {
    
      monthsArray.push(
        {
          date: monthName[d.getMonth()] + ' ' + d.getFullYear(),
          tag: 'No incident has been recorded'
        }
      )
      d.setMonth(d.getMonth() - 1);
    }
  }

  clkIncidentHistory(){
    this.getPreviousMonths(13, this.yearTransaction);
    
    this.showPageOne = false;
  }

  clkReturnStatus(){
    this.showPageOne = true;
  }
  fromdate = new FormControl(moment());
  todate = new FormControl(moment(new Date("1/1/2021")));

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.fromdate.value;
    ctrlValue.year(normalizedYear.year());
    this.fromdate.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.fromdate.value;
    ctrlValue.month(normalizedMonth.month());
    this.fromdate.setValue(ctrlValue);
    datepicker.close();
  }

  chosenYearToHandler(normalizedYear: Moment) {
    const ctrlValue = this.todate.value;
    ctrlValue.year(normalizedYear.year());
    this.todate.setValue(ctrlValue);
  }

  chosenMonthToHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.todate.value;
    ctrlValue.month(normalizedMonth.month());
    this.todate.setValue(ctrlValue);
    datepicker.close();
  }
}
