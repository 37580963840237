<div class="profile">
  <nav-menu-header headerLabel="My Profile"></nav-menu-header>
</div>

<div class="main">
  <div class="profile-container m-auto">
    <div class="row profile-row">
      <div class="col">
        Name
      </div>
      <div class="col">
        {{userDetail?.userName}}
      </div>
    </div>
    <div class="row profile-row">
      <div class="col">
        Company Name
      </div>
      <div class="col">
        {{userDetail?.organization.orgName}}
      </div>
    </div>
    <div class="row profile-row">
      <div class="col">
        Email Id
      </div>
      <div class="col">
        {{userDetail?.userId}}
      </div>
    </div>
    <div class="profile-row">
      <button class="btn reset-btn" (click)="openResetForm()">Reset Password</button>
    </div>
    <div class="row profile-row" style="border-top: 1px solid; margin-top: 10px">
      <div class="col-2">
        Usage Statistics
      </div>
      <div class="col" *ngIf="statistics">
        <div class="row api-stats-row">
          <div class="col">Search</div>
          <div class="col-2">{{statistics.SEARCH.usage}} / {{statistics.SEARCH.maxAllowedUsage}}</div>
          <div class="col">
            <button class="btn reset-btn" (click)="increaseLimit('SEARCH')" [disabled]="statistics.SEARCH.request">Increase Limit</button>
          </div>
        </div>
        <!-- <div class="row api-stats-row">
          <div class="col">Personality Analysis</div>
          <div class="col-2">{{statistics.PERSONALITY_ANALYSIS.usage}} / {{statistics.PERSONALITY_ANALYSIS.maxAllowedUsage}}</div>
          <div class="col">
            <button class="btn reset-btn" (click)="increaseLimit('PERSONALITY_ANALYSIS')" [disabled]="statistics.PERSONALITY_ANALYSIS.request">
              Increase Limit
            </button>
          </div>
        </div> -->
        <div class="row api-stats-row">
          <div class="col">Background Check</div>
          <div class="col-2">{{statistics.BACKGROUND_CHECK.usage}} / {{statistics.BACKGROUND_CHECK.maxAllowedUsage}}</div>
          <div class="col">
            <button class="btn reset-btn" (click)="increaseLimit('BACKGROUND_CHECK')" [disabled]="statistics.BACKGROUND_CHECK.request">
              Increase Limit
            </button>
          </div>
        </div>
      </div>

      
      
      <div class="col" *ngIf="!statistics">
        No statistical data available.
      </div>
    </div>
    <!-- <div class="analysisAppraoch" *ngFor="let data of defaultValues">
      <h5>{{ data.analysisApproach }}</h5>
      <table class="table" style="margin-left: 2%;">
        <tr *ngFor="let colData of data.defaultValue.dataValues">
          <td class="tdAlign" style="padding: 2px;">{{colData.label}}</td>
          <td class="tdAlign" style="padding: 2px;">{{colData.value}}</td>
          <td class="tdAlign" style="padding: 2px;" *ngIf="colData.year">{{colData.year}}</td>
        </tr>
      </table>
    </div> -->
    <div *ngIf="defaultValuesIncomeAppraoch">
      <h5>Income Approach</h5>
      <table class="table">
        <tr>
          <th>General Inputs</th>
        </tr>

        <tr>
          <td class="tdAlign" class="tdAlign">What Type of DCF Model do you want to use?</td>
          <td class="tdAlign"> {{ defaultValuesIncomeAppraoch.generalInputs.dcfModelType }} </td>
        </tr>

        <tr>
          <td class="tdAlign" class="tdAlign">Please select the terminal Year Computation Model</td>
          <td class="tdAlign"> {{ defaultValuesIncomeAppraoch.generalInputs.computationModel }}</td>
        </tr>

        <tr>
          <td class="tdAlign" class="tdAlign">Which Exit Multiple you want to use ?</td>
          <td class="tdAlign"> {{ defaultValuesIncomeAppraoch.generalInputs.exitMultipleUse }}</td>
        </tr>

        <tr>
          <td class="tdAlign" class="tdAlign">Do we have Valuation Date Balance Sheet?</td>
          <td class="tdAlign"> {{ defaultValuesIncomeAppraoch.generalInputs.vdBalanceSheet }}</td>
        </tr>

        <tr>
          <td class="tdAlign" class="tdAlign">Capital Advantage Period</td>
          <td class="tdAlign"> {{ defaultValuesIncomeAppraoch.generalInputs.capitalAP + " " + "years" }} </td>
        </tr>
      </table>
    </div>

    <div *ngIf="defaultValuesComparableAppraoch">
      <h5>Comparable Company Approach</h5>
      <table class="table">
        <tr>
          <th>Multiples</th>
          <th>Weight</th>
          <th>FY</th>
        </tr>
        <tr *ngFor="let data of defaultValuesComparableAppraoch.weights">
          <td class="tdAlign"> {{ data.multiple.label }} </td>
          <td class="tdAlign"> {{ data.weight }}% </td>
          <td class="tdAlign"> {{ data.fy }}  </td>
        </tr>
      </table>
    </div>

    <div *ngIf="defaultValuesComparableTransactionAppraoch">
      <h5>Comparable Transaction Approach</h5>
      <table class="table">
        <tr>
          <td class="tdAlign">Value Conclusion Mechanism</td>
          <td class="tdAlign">
            <span *ngIf="defaultValuesComparableTransactionAppraoch.baseOfAnalysis==='multipleBased'"> Multiple Based </span>
            <span *ngIf="defaultValuesComparableTransactionAppraoch.baseOfAnalysis!='multipleBased'"> Enterprise Value Based </span> 
          </td>
        </tr>

        <ng-template [ngIf]="defaultValuesComparableTransactionAppraoch.baseOfAnalysis==='multipleBased'">
          <tr><th> Multiple Adjustment Factor </th></tr>
          <tr>
            <td class="tdAlign">Aquisition</td>
            <td class="tdAlign"> {{ defaultValuesComparableTransactionAppraoch.mNafactor }} </td>
          </tr>
          <tr>
            <td class="tdAlign">Funding</td>
            <td class="tdAlign"> {{ defaultValuesComparableTransactionAppraoch.mNafactor - 100 }} </td>
          </tr>
          <tr>
            <td class="tdAlign">{{defaultValuesComparableTransactionAppraoch.companyMetrics.key}}</td>
            <td> {{defaultValuesComparableTransactionAppraoch.companyMetrics.value}} </td>
          </tr>
        </ng-template>

        <tr><th>Concluded Weights</th></tr>
        <tr>
          <td  class="tdAlign">EV REVENUE</td>
          <td  class="tdAlign"> {{ defaultValuesComparableTransactionAppraoch.concludedWeights.evRevenue }} </td>
        </tr>
        <tr>
          <td  class="tdAlign">EV BITDA</td>
          <td  class="tdAlign"> {{ defaultValuesComparableTransactionAppraoch.concludedWeights.evEbitda }} </td>
        </tr>
      </table>
    </div>
  </div>
</div>