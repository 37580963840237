export const MENU_LIST = [
  {
    "menuLabel": "Home",
    "icon": "fas fa-clipboard-list",
    "link": "/ems-home",
    "toolKey": "ems-home",
    "allPage": true,
    "accessForAll": true
  },
  {
    "menuLabel": "Graviton X",
    "icon": "far fa-list-alt",
    "link": "/monitoring",
    "toolKey": "gx_monitoring",
    "portfolioSummary": true,
    "companyValuation": true
  },
  {
    "menuLabel": "equity_nav",
    "menuKey": "equity-menu",
    "showmenuarrow": false,
    "icon": "fas fa-dollar-sign",
    "subMenuItems": [
      {
        "menuLabel": "Analysis",
        "showsubmenuarrow": false,
        "menuKey": "analysis",
        "subMenuItems": [
          {
            "menuLabel": "simulation_analysis",
            "icon": "fas fa-user-cog",
            "link": "/exit-simulation-v2",
            "toolKey": "exit_simulation",
            "portfolioSummary": true,
            "companyValuation": true
          },
          {
            "menuLabel": "status",
            "icon": "fas fa-user-cog",
            "link": "status-fund-level",
            "toolKey": "status",
            "portfolioSummary": true,
            "companyValuation": true
          },
          {
            "menuLabel": "value_bridge_fund_level",
            "icon": "fas fa-clipboard-list",
            "link": "value-bridge-fund-level",
            "toolKey": "value_bridge_fund_level",
            "portfolioSummary": true,
            "companyValuation": true
          }
        ]
      },
      {
        "menuLabel": "currency_dashboard",
        "icon": "fas fa-clipboard-list",
        "link": "portfolio-currency-dashboard",
        "toolKey": "currency_dashboard",
        "portfolioSummary": true,
        "companyValuation": true
      },
      {
        "menuLabel": "global_comps",
        "icon": "fas fa-user-cog",
        "link": "/globalcomps",
        "toolKey": "global_comps",
        "fundList": true,
        "portfolioSummary": true,
        "companyValuation": true,
      },
      {
        "menuLabel": "investments",
        "icon": "fas fa-clipboard-list",
        "link": "/investments",
        "toolKey": "investment",
        "companyValuation": true
      },
      {
        "menuLabel": "mandate_level",
        "icon": "fas fa-user-cog",
        "link": "/mandates",
        "toolKey": "mandate",
        "portfolioSummary": true,
        "companyValuation": true,
      },
      {
        "menuLabel": "Reporting",
        "showsubmenuarrow": false,
        "menuKey": "reporting",
        "subMenuItems": [
          {
            "menuLabel": "download_report",
            "icon": "fas fa-clipboard-list",
            "link": "dashboard-equity",
            "toolKey": "dashboard",
            "portfolioSummary": true,
            "companyValuation": true,
            "fundList": true
          },
          {
            "menuLabel": "power_bi_report",
            "icon": "fas fa-clipboard-list",
            // "link": "embedded-reports-ui",
            "link": "/power-bi",
            "toolKey": "power_bi_report",
            "portfolioSummary": true,
            "companyValuation": true,
          }
        ]
      },
      {
        "menuLabel": "schedular",
        "icon": "far fa-calendar-alt",
        "link": "scheduler",
        "toolKey": "schedular",
        "portfolioSummary": true,
        "companyValuation": true
      },
      {
        "menuLabel": "transactions",
        "icon": "fas fa-clipboard-list",
        "link": "transactions",
        "toolKey": "transactions",
        "portfolioSummary": true,
        "companyValuation": true
      },
      {
        "menuLabel": "Mapping Management",
        "icon": "fas fa-gears",
        "link": "/mapping-configuration",
        "toolKey": "equity_mapping_management",
        "fundList": false,
        "portfolioSummary": true,
        "companyValuation": true
      },
    ]
  },
  {
    "menuLabel": "credit",
    "menuKey": "credit",
    "showmenuarrow": false,
    "icon": "far fa-credit-card",
    "subMenuItems": [
      {
        "menuLabel": "credit_rating_upload",
        "icon": "fas fa-user-cog",
        "link": "/credit-rating-upload",
        "toolKey": "credit_rating_upload",
        "portfolioSummary": true,
        "companyValuation": true,
      },
      {
        "menuLabel": "currency_dashboard",
        "icon": "fas fa-clipboard-list",
        "link": "portfolio-currency-dashboard",
        "toolKey": "credit_currency_dashboard",
        "portfolioSummary": true,
        "companyValuation": true
      },
      {
        "menuLabel": "Reporting",
        "showsubmenuarrow": false,
        "menuKey": "report-menu",
        "subMenuItems": [
          {
            "menuLabel": "download_report",
            "icon": "fas fa-clipboard-list",
            "link": "dashboard-debt",
            "toolKey": "credit_download_report",
            "portfolioSummary": true,
            "companyValuation": true,
            "fundList": true
          },
          {
            "menuLabel": "power_bi_report",
            "icon": "fas fa-clipboard-list",
            // "link": "embedded-reports-ui",
            "link": "/power-bi/#/?reportType=credit",
            "toolKey": "credit_power_bi_report",
            "portfolioSummary": true,
            "companyValuation": true,
            "fundList": true
          },
        ]
      },
      {
        "menuLabel": "schedular",
        "icon": "far fa-calendar-alt",
        "link": "scheduler",
        "toolKey": "credit_schedular",
        "portfolioSummary": true,
        "companyValuation": true
      },
      {
        "menuLabel": "simulation_analysis",
        "icon": "fas fa-user-cog",
        "link": "/simulation",
        "toolKey": "simulation_analysis",
        "portfolioSummary": true,
        "companyValuation": true,
      },
      {
        "menuLabel": "stakeholder_allocation",
        "icon": "fas fa-user-cog",
        "link": "/fund-allocation",
        "toolKey": "stakeholder_allocation",
        "portfolioSummary": true,
        "companyValuation": true,
        "fundList": true
      },
      {
        "menuLabel": "Mapping Management",
        "icon": "fas fa-gears",
        "link": "/mapping-configuration",
        "toolKey": "credit_mapping_management",
        "fundList": false,
        "portfolioSummary": true,
        "companyValuation": true
      },
    ]
  },
  {
    "menuLabel": "Org Admin",
    "menuKey": "orgadmin",
    "showmenuarrow": false,
    "icon": "far fa-building",
    "masterItem": true,
    "allPage": true,

    "subMenuItems": [
      {
        "menuLabel": "approval_system",
        "icon": "fas fa-clipboard-list",
        "link": "approval-matrix-new-2.0",
        "toolKey": "approval_matrix",
        "portfolioSummary": true,
        "companyValuation": true,
        "masterItem": true
      },
      {
        "menuLabel": 'configuration_credit',
        "icon": "fas fa-user-cog",
        "link": "/leverage-ratio",
        "toolKey": "org_admin_credit_configuration",
        "fundList": true,
        "portfolioSummary": true,
        "companyValuation": true,
        "masterItem": true
      },
      {
        "menuLabel": 'configuration_equity',
        "icon": "fas fa-user-cog",
        "link": "/configuration-v2",
        "toolKey": "configuration",
        "portfolioSummary": true,
        "companyValuation": true,
        "masterItem": true
      },
      {
        "menuLabel": "data_management",
        "icon": "fas fa-user-cog",
        "link": "/data-management",
        "toolKey": "data_management",
        "portfolioSummary": true,
        "companyValuation": true,
        "masterItem": true
      },
      {
        "menuLabel": "user_management",
        "icon": "fas fa-user-cog",
        "link": "/admin-uam",
        "toolKey": "org_admin_user_management",
        "masterItem": true,
        "allPage": true
      },
    ]
  },
  {
    "menuLabel": "73 Strings Admin",
    "menuKey": "73stringadmin",
    "showmenuarrow": false,
    "icon": "far fa-address-book",
    "superMaster": true,
    "allPage": true,
    "subMenuItems": [
      {
        "menuLabel": "administrator",
        "icon": "fas fa-user-cog",
        "link": "/admin",
        "toolKey": "73s_administrator",
        "superMaster": true,
        "allPage": true
      },
      {
        "menuLabel": "Company Master",
        "icon": "fas fa-clipboard-list",
        "link": "company-master-list",
        "toolKey": "73s_company_master",
        "portfolioSummary": true,
        "companyValuation": true,
        "superMaster": true
      },
      {
        "menuLabel": "replicate_funds",
        "icon": "fas fa-user-cog",
        "link": "/replicate-funds",
        "toolKey": "73s_replicate_funds",
        "superMaster": true,
        "allPage": true
      },
      {
        "menuLabel": "Data Recon",
        "icon": "fas fa-user-cog",
        "link": "/data-recon",
        "toolKey": "73s_data_recon",
        "superMaster": true,
        "allPage": true
      },
      {
        "menuLabel": "Data Recon",
        "icon": "fas fa-user-cog",
        "link": "/data-recon",
        "toolKey": "73s_data_recon",
        "superMaster": true,
        "allPage": true
      }
    ]
  },
  {
    "menuLabel": "Users",
    "icon": "fas fa-clipboard-list",
    "link": "/users",
    "toolKey": "portfolio",
    "is73sUser": true,
    "allPage": true
  },
  {
    "menuLabel": "Credit PowerBi Dashboard",
    "icon": "fas fa-clipboard-list",
    "link": "/credit-bi-dashboard",
    "toolKey": "portfolio",
    "is73sUser": true,
    "allPage": true
  },
  {
    "menuLabel": "Graviton X V2",
    "icon": "far fa-list-alt",
    "link": "/v2-monitoring",
    "toolKey": "monitoring",
    "portfolioSummary": true,
    "companyValuation": true
  },
]