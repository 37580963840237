<div class="top-header">
  <span style="font-size: 20px">
    <ng-template [ngIf]="fundName">
      {{fundName}} - 
    </ng-template>
    Portfolio Summary
  </span>

  <mat-form-field class="currencySelect">
    <mat-select [(ngModel)]="portfolioService.selectedCurrency" (ngModelChange)="portfolioService.initExchangeRate()">
      <mat-option *ngFor="let currency of portfolioService.currencyList" value="{{ currency.id }}">{{ currency.country }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <span ngbDropdown class="d-inline-block" placement="bottom-right" style="float: right;">
    <span id="toggleMenu" ngbDropdownToggle>
      <i class="fas fa-ellipsis-v ellipsis-icon"></i>
    </span>
    <div ngbDropdownMenu aria-labelledby="toggleMenu">
      <!-- <button ngbDropdownItem class="btn" (click)="openPopupModal(applicationContent)"> -->
      <!-- <button ngbDropdownItem class="btn" [routerLink]="'/legal-entities/' + fundId">
        Add Company
      </button> -->
      <button ngbDropdownItem class="btn" [routerLink]="'/qxp-portfolio-summary'" *ngIf="portfolioService.companies && portfolioService.companies.length> 0">
        Show Fund Summary
      </button>

      <!-- <button ngbDropdownItem class="btn" [routerLink]="'/qxp-exit-simulation/'" *ngIf="portfolioService.companies && portfolioService.companies.length> 0">
        Exit Simulation
      </button>
      <button ngbDropdownItem class="btn" [routerLink]="'/qxp-portfolio-currency-dashboard/'" *ngIf="portfolioService.companies && portfolioService.companies.length> 0">
        Currency Dashboard
      </button> -->
    </div>
  </span>
</div>

<div class="card" style="padding: 10px;" *ngIf="portfolioService.companies && portfolioService.companies.length> 0">
  <div>
    <div class="row" style=" margin: auto 30px;">
      <div class="col-5">
        <div class="row" style="padding: 0; margin: 5px 0;">
          <mat-form-field *ngIf="portfolioService.mapData">
            <mat-select [(ngModel)]="portfolioService.mapData.value" required (ngModelChange)="updateMap()">
              <mat-option value="currentVal">Current Valuation</mat-option>
              <mat-option value="prevQuarterVal">Previous Quarter Valuation</mat-option>
              <mat-option value="noOfComp">Total No of Companies</mat-option>
              <mat-option value="changeInVal">Change in Value</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [chart]="portfolioService.mapChart" id="map-chart" style="width: 500px;"></div>
      </div>

      <!-- <div class="col-2" style="margin: auto;">
        <div class="row bubble">
          <p style="margin: auto;">
            {{ portfolioService.totalPortfolioData.totalIRR * 100| number: '1.1-1'}}% IRR
          </p>
        </div>
        <div class="row" style="width: 100px; margin: 5px auto; background-color: rgb(248, 211, 178);">
          <p style="margin: auto; text-align: center;">
            Gross IRR
          </p>
        </div>
        <div class="row bubble">
          <p style="margin: auto;">
            {{ portfolioService.totalPortfolioData.totalMOIC | number: '1.1-1'}}x MOIC
          </p>
        </div>
        <div class="row" style="width: 100px; margin: 5px auto; background-color: rgb(248, 211, 178);">
          <p style="margin: auto; text-align: center;">
            MOIC
          </p> 
        </div>
      </div> -->

      <div class="col">
        <div id="column-chart-container" style="height: 400px; width: 450px;">
        </div>
      </div>
    </div>

    <div style="text-align: right; margin: 5px 10px;">
      <mat-form-field style="width: 400px">
        <input matInput type="text" placeholder="Search By company name" [(ngModel)]="searchString" (ngModelChange)="searchCompanies()">
      </mat-form-field>
    </div>
  </div>
</div>
    
<app-legal-entities [corpId]="fundId" [entityValuationData]="portfolioService.valuationDataForCrossHolding"></app-legal-entities>

<ng-template #applicationContent let-applicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Add Company</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="model-content">
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          Company Name
        </div>
        <div class="col">
          <mat-form-field style="width: 80%;">
            <input matInput [(ngModel)]="newCompany.name" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          Security
        </div>
        <div class="col">
          <mat-form-field style="width: 80%;">
            <mat-select disableOptionCentering [(ngModel)]="newCompany.security" required>
              <mat-option value="EQUITY">Equity</mat-option>
              <mat-option value="DEBT">Debt</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          Investment Date
        </div>
        <div class="col">
          <mat-form-field (click)="vDatePicker.open()" style="width: 80%;">
            <input matInput [matDatepicker]="vDatePicker" readonly class="date-field"
              [(ngModel)]="newCompany.valuationDate" required>
            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          Logo
        </div>
        <div class="custom-file col">
          <label class="custom-file-label" style="width: 70%; margin: 0 auto; text-align: left;">
            <span class="choose-file" style="text-align: left;">
              Upload logo
            </span>
          </label>
          <input type="file" accept=".png, .jpg" class="custom-file-input" (change)="upload($event)" />
        </div>
      </div> -->
    </div>

    <div class="row p-modal-row">
      <div class="col-4 p-modal-left-col">
      </div>
      <div class="col">
        <button mat-raised-button class="secondary-bg p-button" (click)="applicationModal.dismiss('Cross click')">
          Cancel
        </button>
        <button mat-raised-button class="primary-bg p-button" (click)="startApplication()">
          Start
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="showSummary">
  <app-portfolio-summary></app-portfolio-summary>
</div>

<ng-template #userPopUpContent let-userPopUp>
  <div class="modal-header">
    <div class="modal-title">
    </div>
    <button type="button" class="close" aria-label="Close" (click)="userPopUp.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body form-user-assignment">
    <div style="margin-left: 10px;" *ngFor="let user of myOrgUsers">
      <div class="row">
        <div class="col-7">
          <mat-checkbox class="example-margin" [(ngModel)]="user.assigned">
              <span style="font-weight: bold;">{{ user.userName }}</span> - <i>{{user.userId}}</i>
          </mat-checkbox>
        </div>

        <div class="col-4">      
          <mat-form-field>
            <mat-select disableOptionCentering [(ngModel)]="user.editable">
              <mat-option [value]="true"> Write </mat-option>
              <mat-option [value]="false"> View Only </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div style="text-align: center;">
      <button mat-raised-button class="primary-bg" (click)="assignUsersToForm()">
        SAVE
      </button>
    </div>
  </div>
</ng-template>
