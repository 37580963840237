<button mat-raised-button class="primary-bg floating-save" (click)="saveData(true)"> Save </button>
<div style="background-color: white;" *ngIf="latestValData && latestValData[0]">
    <div class="row">
        <div class="col-5">
            <table class="table" style="margin-bottom: 0"
                *ngIf="portfolioService.fundDetails && portfolioService.fundDetails.organization">
                <tr>
                    <td>
                        <label style="cursor: pointer;">
                            <input style="display:none" accept="image/*" type="file" id="file" (change)="getImageURL($event)">
                            <img [src]="latestValData[0].logo" width="100" height="100"
                                (error)="latestValData[0].logo = null" style="cursor: pointer;"
                                *ngIf="latestValData && latestValData[0] && latestValData[0].logo; else defaultLogo2">

                            <ng-template #defaultLogo2>
                                <i class="far fa-building" style="margin-left: 6px; font-size: xxx-large;"></i>
                            </ng-template>
                        </label>

                        <span style="margin-left: 4%;font-size: 20px;">
                            <!-- {{ portfolioService.fundDetails.organization.name }} -->
                            {{formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME}}
                        </span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-6">
            <table class="table">
                <tr>
                    <td style="width: 10%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-2" style="width: fit-content; padding: 0;">
                                    <img width="20px" style="margin: auto;" height="20px"
                                        [src]="imageLoadService.getImage('value (1).svg')" alt="value">
                                </div>
                                <div class="col" style="width: fit-content; padding: 0;">
                                    <div style="font-size: 10px; text-align: center">
                                        NAV {{fundListService.getFundName(portfolioService.selectedFundId)}}
                                    </div>
                                    <div
                                        style="font-size: 15px; font-weight: bold; white-space: nowrap; text-align: center">
                                        <ng-template
                                            [ngIf]="latestValData  && latestValData[0] && latestValData[0].stakeVal">
                                            {{ latestValData[0].currency }} {{ latestValData[0].stakeVal | number:
                                            '1.1-1'}} Mn
                                        </ng-template>
                                        <ng-template
                                            [ngIf]="  !latestValData || !latestValData[0] || !latestValData[0].stakeVal">
                                            <div> - </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- <td style="width: 22%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" style="margin: auto;" height="20px"
                                        [src]="imageLoadService.getImage('refresh.svg')" alt="refresh">
                                </div>
                                <div class="col" style="width: fit-content; padding: 0;">
                                    <div style="font-size: 10px;display: inline-block; white-space: nowrap;">
                                        Update from last date
                                    </div>
                                    <ng-template
                                        [ngIf]="latestValData && latestValData[0] && latestValData[0].changeInValPercentage">
                                        <div style="font-size: 15px; font-weight: bold; ">
                                            {{ latestValData[0].changeInValPercentage * 100 | number:'1.1-1' }}%
                                            <img width="10px" height="10px"
                                                *ngIf="latestValData[0].changeInValPercentage > 0"
                                                [src]="imageLoadService.getImage('up-arrow.svg')" alt="up-arrow">
                                        </div>
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="!latestValData || !latestValData[0] || !latestValData[0].changeInValPercentage">
                                        <div> - </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </td> -->
                    <td style="width: 15%; padding: 5px;">
                        <div class="box" style="height: 70px;">
                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" height="20px" [src]="imageLoadService.getImage('surface1.svg')"
                                        alt="value">
                                </div>
                                <div class="col" style="padding: 0">
                                    <div style="font-size: 10px;display: inline-block">
                                        <div>Current IRR</div>
                                        <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>
                                    </div>
                                    <ng-template [ngIf]="this.transactionsForCompany.IRR">
                                        <div style="font-size: 15px; font-weight: bold;">
                                            <!-- {{ portfolioService.formDetailsObj.irr * 100 | number: '1.1-1'}}% -->
                                            {{this.transactionsForCompany.IRR * 100 | number: '1.1-1'}}%
                                        </div>
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.IRR">
                                        <div style="font-size: 15px; font-weight: bold;">
                                            -
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style="width: 15%; padding: 5px;">
                        <div class="box" style="height: 70px;">

                            <div class="row">
                                <div class="col-3" style="width: fit-content; padding: 0;">
                                    <img width="20px" height="20px" [src]="imageLoadService.getImage('money.svg')"
                                        alt="value">
                                </div>
                                <div class="col" style="padding: 0">
                                    <div style="font-size: 10px;display: inline-block">
                                        <div>MOIC</div>
                                        <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>
                                    </div>

                                    <ng-template [ngIf]="this.transactionsForCompany.moic">
                                        <div style="font-size: 15px; font-weight: bold;">
                                            {{ this.transactionsForCompany.moic | number: '1.2-2'}}x
                                        </div>
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.moic">
                                        <div style="font-size: 15px; font-weight: bold;">
                                            -
                                        </div>
                                    </ng-template>
                                </div>
                            </div>

                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="val-date-upper-limit">
        <span style="cursor: pointer;"
            (click)="openValdateLimitPopUp(valDateUpperLimitPopup)">{{selectedCompanyDates[0].valuationDate}}</span>
    </div>

    <div class="row">
        <div class="col-3">
            <button mat-button class="primary-bg editButtonMarginLeft"
                (click)="openPopupModal(editCompanyContent)">Edit</button>
        </div>
    </div>

    <div class="row">
        <div class="col-5">
            <div class="row" style="font-size: small">
                <div class="col spacing primary-color" style="background-color: #f5f4f4;">
                    <div style="border-right: solid 1px #d8dad8;margin-top: 2%;">
                        <table class="table borderless tableSpacing1">
                            <tr *ngIf="portfolioService.fundDetails && portfolioService.fundDetails.organization">
                                <td style="font-weight: bold;">Location</td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="countryName">
                                        {{countryName}}
                                    </ng-template>                                  
                                </td>
                            </tr>
                            <tr *ngIf="portfolioService.fundDetails && portfolioService.fundDetails.organization">
                                <td style="font-weight: bold;">Sector</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="!portfolioService.fundDetails.organization.name || portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0">
                                        {{ companySector }}
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') >= 0">
                                        Defence & Aerospace
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="portfolioService.fundDetails && portfolioService.fundDetails.organization">
                                <td style="font-weight: bold;">Total Number of Employees</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="portfolioService.fundDetails.organization.estimated_num_employees">
                                        {{ this.portfolioService.fundDetails.organization.estimated_num_employees |
                                        number:"1.0-0"}}
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">Founded on</td>
                                <td class="rightAlignment"></td>
                            </tr> -->
                            <tr *ngIf="portfolioService.fundDetails && portfolioService.fundDetails.organization">
                                <td style="font-weight: bold;">Website</td>
                                <td class="rightAlignment">
                                    {{ formData.GENERAL.GD_General_Details.GD_WEBSITE }}
                                </td>
                            </tr>

                            <ng-template [ngIf]="this.portfolioService.fundDetails">
                                <tr *ngFor="let people of this.portfolioService.fundDetails.people;let i=index">
                                    <ng-template [ngIf]="i < 4">
                                        <td style="font-weight: bold;float: left;" *ngIf="i==0">Key People</td>
                                        <td style="font-weight: bold;float: left;" *ngIf="i>0"
                                            [style.visibility]="'hidden'">
                                            Key People
                                        </td>
                                        <td class="rightAlignment">
                                            <ng-template [ngIf]="people.name && people.name.length > 0">
                                                {{ people.name }}

                                                <ng-template [ngIf]="people.title && people.title.length > 0">
                                                    ({{people.title}})
                                                </ng-template>
                                            </ng-template>
                                        </td>
                                    </ng-template>
                                </tr>
                            </ng-template>

                            <!-- <tr>
                                <td style="font-weight: bold;">Total Funding</td>
                                <td class="rightAlignment">
                                    USD {{ this.portfolioService.fundDetails.organization.total_funding }} Billion
                                </td>
                            </tr> -->

                            <tr>
                                <td style="font-weight: bold;">Investment Date</td>
                                <td class="rightAlignment">{{latestValData[0].investmentDate | date: "MMM, yyyy"}}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Initial Investment
                                    <!-- <span>
                                          <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentAmount)"></i>
                                      </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="transactionsForCompany && transactionsForCompany.initialInvestmentAmount">
                                        {{ latestValData[0].currency }} {{transactionsForCompany.initialInvestmentAmount
                                        | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template
                                        [ngIf]=" !transactionsForCompany || !transactionsForCompany.initialInvestmentAmount">
                                        <span> - </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Investment to Date 
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="transactionsForCompany && transactionsForCompany.remainingInvestmentAmount">
                                        {{ latestValData[0].currency }}
                                        {{transactionsForCompany.remainingInvestmentAmount | number: '1.1-1'}} Mn
                                    </ng-template>

                                    <ng-template [ngIf]=" !transactionsForCompany || !transactionsForCompany.remainingInvestmentAmount">
                                        <span> - </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;"> 
                                    Investment Status
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentStatus)"></i>
                                    </span>
                                </td>
                                <td> 
                                    {{exitStatus | titlecase}}
                                </td>
                            </tr> -->
                            <tr>
                                <td style="font-weight: bold;">
                                    Partner
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(partner)"></i>
                                    </span>
                                </td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="savedData.partners && savedData.partners.length > 0">
                                        <span *ngFor="let partner of savedData.partners; let index = index">
                                            <ng-template [ngIf]="partner.name">
                                                {{ partner.name | titlecase}} <span
                                                    *ngIf="index < savedData.partners.length-1">,</span>
                                            </ng-template>
                                        </span>
                                    </ng-template>

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="col spacing primary-color" style="background-color:#f5f4f4;">
                    <div style="margin-top: 2%;">
                        <table class="table borderless tableSpacing2">
                            <tr>
                                <td style="font-weight: bold;">
                                    Fully Diluted Ownership
                                    <!-- <span>
                                        <i class="fas fa-external-link-alt"
                                            (click)="openPopUpModel(fullyDilutedOwnership)"></i>
                                    </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="summaryNumbers.fullyDilutedOwnership && summaryNumbers.fullyDilutedOwnership > 0" [ngIfElse]="notExists">
                                        {{summaryNumbers.fullyDilutedOwnership | number:'1.2-2'}}%
                                    </ng-template>
                                    <!-- <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                      25%
                                      </ng-template> -->
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Security Type
                                    <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(securityType)"></i>
                                    </span>
                                </td>
                                <td class="rightAlignment">
                                    {{ savedData.securityType | titlecase }}
                                    <!-- <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                          Series B Preferred
                                      </ng-template> -->
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">IRR</td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="this.transactionsForCompany.IRR">
                                        {{ this.transactionsForCompany.IRR * 100 | number: '1.1-1'}}%
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.IRR">
                                        <span> - </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">MOIC</td>
                                <td class="rightAlignment">
                                    <ng-template [ngIf]="this.transactionsForCompany.moic">
                                        {{ this.transactionsForCompany.moic | number: '1.2-2'}}x
                                    </ng-template>
                                    <ng-template [ngIf]="!this.transactionsForCompany.moic">
                                        <span> - </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Realised Proceeds
                                    <!-- <span>
                                        <i class="fas fa-external-link-alt" (click)="openPopUpModel(realisedProceeds)"></i>
                                    </span> -->
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="transactionsForCompany.totalRealisedProceeds && transactionsForCompany.totalRealisedProceeds">
                                        {{ latestValData[0].currency }} {{ transactionsForCompany.totalRealisedProceeds
                                        | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template
                                        [ngIf]="!transactionsForCompany.totalRealisedProceeds || !transactionsForCompany.totalRealisedProceeds">
                                        <span> - </span>
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">Industry Median (EBITDA Multiple)</td>
                                <td class="rightAlignment">{{summaryNumbers.industryBevEbitda | number: "1.2-2"}} x</td>
                            </tr> -->
                            <tr *ngIf="summaryNumbers.subjectCompBevEbitda && summaryNumbers.subjectCompBevEbitda > 0">
                                <td style="font-weight: bold;"> Implied EBITDA Multiple ({{latestValuationYear}})
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompBevEbitda && summaryNumbers.subjectCompBevEbitda > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompBevEbitda | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="font-weight: bold;">Industry Median (Revenue Multiple)</td>
                                <td class="rightAlignment">{{summaryNumbers.industryBevRevennue | number: "1.2-2"}} x</td>
                            </tr> -->
                            <tr *ngIf="summaryNumbers.subjectCompBevRevenue && summaryNumbers.subjectCompBevRevenue > 0">
                                <td style="font-weight: bold;"> Implied Revenue Multiple
                                    ({{latestValuationYear}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompBevRevenue && summaryNumbers.subjectCompBevRevenue > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompBevRevenue | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.subjectCompBevEbit && summaryNumbers.subjectCompBevEbit > 0">
                                <td style="font-weight: bold;"> Implied EBIT Multiple ({{latestValuationYear}})
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompBevEbit && summaryNumbers.subjectCompBevEbit > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompBevEbit | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.subjectCompEVGrossProfit && summaryNumbers.subjectCompEVGrossProfit > 0">
                                <td style="font-weight: bold;"> Implied Gross Profit Multiple ({{latestValuationYear}})
                                </td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompEVGrossProfit && summaryNumbers.subjectCompEVGrossProfit > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompEVGrossProfit | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="ccmImpliedMultiplesExits['psales']">
                                <td style="font-weight: bold;"> Implied Price / Sales Multiple
                                    ({{latestValuationYear}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompPsales && summaryNumbers.subjectCompPsales > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompPsales | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="ccmImpliedMultiplesExits['pe']">
                                <td style="font-weight: bold;"> Implied Price / Earnings Multiple
                                    ({{latestValuationYear}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompPe && summaryNumbers.subjectCompPe > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompPe| number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="ccmImpliedMultiplesExits['pbv']">
                                <td style="font-weight: bold;"> Implied Price / Book Value Multiple
                                    ({{latestValuationYear}})</td>
                                <td class="rightAlignment">
                                    <ng-template
                                        [ngIf]="summaryNumbers.subjectCompPbv && summaryNumbers.subjectCompPbv > 0"
                                        [ngIfElse]="notExists">
                                        {{summaryNumbers.subjectCompPbv | number: "1.2-2"}}x
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.revennue > 0">
                                <td style="font-weight: bold;">Revenue ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>

                                    <ng-template [ngIf]="summaryNumbers.revennue > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.revennue | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="summaryNumbers.ebitda > 0">
                                <td style="font-weight: bold;">EBITDA ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>

                                    <ng-template [ngIf]="summaryNumbers.ebitda > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.ebitda | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>
                            <tr *ngIf="summaryNumbers.ebitdaMargin > 0">
                                <td style="font-weight: bold;">EBITDA Margin ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.ebitdaMargin > 0" [ngIfElse]="notExists">
                                        {{utilService.getDisplayFormattedNumber(summaryNumbers.ebitdaMargin , 1, "%", "NMF", 'true')}}
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.ebit > 0">
                                <td style="font-weight: bold;">EBIT ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.ebit > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.ebit | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.grossProfit > 0">
                                <td style="font-weight: bold;">Gross Profit ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.grossProfit > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.grossProfit | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="summaryNumbers.ebitMargin > 0">
                                <td style="font-weight: bold;">EBIT Margin ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.ebitMargin > 0" [ngIfElse]="notExists">
                                        {{utilService.getDisplayFormattedNumber(summaryNumbers.ebitMargin, 1, "%", "NMF", 'true')}}
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="ccmImpliedMultiplesExits['pe']">
                                <td style="font-weight: bold;">Price of Earnings ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.pe > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.pe | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <tr *ngIf="ccmImpliedMultiplesExits['pbv']">
                                <td style="font-weight: bold;">Price of Book Value ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.pbv > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.pbv | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                            <!-- its same as Revenue -->
                            <tr *ngIf="ccmImpliedMultiplesExits['psales']">
                                <td style="font-weight: bold;">Price of Sales ({{latestValuationYear}})</td>
                                <td class="rightAlignment"><span style="color: green;"></span>
                                    <ng-template [ngIf]="summaryNumbers.psales > 0" [ngIfElse]="notExists">
                                        <ng-template [ngIf]="latestValData[0].formData">
                                            {{ latestValData[0].formData.GENERAL.GD_General_Details.GD_CR_CURRENCY }}
                                        </ng-template>
                                        {{summaryNumbers.psales | number: "1.1-1"}} Mn
                                    </ng-template>
                                    <ng-template #notExists>
                                        -
                                    </ng-template>
                                </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-7">
            <div style="font-size: medium; margin: 5px">
                <!-- {{portfolioService.fundDetails.organization.short_description}} -->
                {{companyDescription}}
            </div>

            <!-- <mat-form-field style="margin: 20px auto;" class="text-area">
                <textarea matInput rows="7" cols="80px" style="font-size: small;" name="description" [(ngModel)]="this.portfolioService.fundDetails.organization.short_description">
                </textarea>
            </mat-form-field> -->
            <br>
            <ng-template
                [ngIf]="portfolioService.fundDetails && portfolioService.fundDetails.organization && portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('iberchem') >= 0">

                <mat-chip-list>
                    <mat-chip class="primary-bg">Specialty chemicals</mat-chip>
                    <mat-chip class="primary-bg">Fragrances</mat-chip>
                    <mat-chip class="primary-bg">Natural & artifical flavours</mat-chip>
                    <mat-chip class="primary-bg">Personal Products</mat-chip>
                    <mat-chip class="primary-bg">Fabric Chemicals</mat-chip>
                </mat-chip-list>
            </ng-template>

            <ng-template
                [ngIf]="portfolioService.fundDetails && portfolioService.fundDetails.organization && portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') >= 0">

                <mat-chip-list>
                    <mat-chip class="primary-bg">GPS GNSS</mat-chip>
                    <mat-chip class="primary-bg">Position, Navigation & Timing</mat-chip>
                    <mat-chip class="primary-bg">Precision Instruments</mat-chip>
                    <mat-chip class="primary-bg">Aerial Safety Systems</mat-chip>
                    <mat-chip class="primary-bg">Communications Equipment</mat-chip>
                </mat-chip-list>
            </ng-template>
            <ng-template [ngIf]="!portfolioService.fundDetails || !portfolioService.fundDetails.organization || !portfolioService.fundDetails.organization.name 
                  || (portfolioService.fundDetails.organization.name.toLowerCase().indexOf('iberchem') < 0
                  && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0)">
                <mat-chip-list>
                    <mat-chip class="primary-bg"
                        *ngFor="let keyword of portfolioService.orgKeyWords; let keyIndex=index" [removable]="true"
                        (removed)="remove(keyword, keyIndex)">
                        {{ keyword | titlecase}}
                        <mat-icon matChipRemove style="color: #fff;">cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </ng-template>
            <br>

            <div [chart]="valuationBarChart" style="height: 250px;"></div>
        </div>
    </div>
    <br>
    <!-- <ng-template [ngIf] = "!selectedCompanyDates[selectedCompanyDates.length - 1].businessUnits"> -->
        <div class="row">
            <div class="col-2 header" style="padding-left: 0;">
                NAV Bridge
                <i class="fas fa-info-circle" style="font-size: small;"
                    matTooltip="NAV Bridge is constructed based on the movement of enterprise value between two measurement dates. Enterprise value for the firm has been derived from the total equity value of the company by adjusting for the balance sheet and other custom adjustments."></i>
            </div>
    
            <!-- <div class="col-2" style="text-align: center;">
                <button mat-raised-button class="primary-bg p-button"
                    (click)="showValuationBridgeDataModal(updateValuationBridge)"
                    [disabled]="!preparedValueBridge">
                    Update
                </button>
            </div> -->
            <div class="col-2">
                <mat-checkbox *ngIf="multipleValueBridgeData && multipleValueBridgeData[0]"
                    [(ngModel)]="multipleValueBridgeData[0].waterFallGraphShowLabels" 
                    (change)="refreshWaterfallGraph(0)">
                    Show Labels
                </mat-checkbox>
            </div>
    
            <div class="col-1" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
                <button mat-raised-button class="primary-bg" (click) = "downloadValueBridgeExcel()" [class.disabled]="isDownloading">Download</button>
            </div>
            <div class="col-1" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
                <button mat-raised-button class="primary-bg" (click) = "addValueBridge()">Add</button>
            </div>
            <!-- <div class="col"></div> -->
        </div>
        <br>

        <!-- <div class="row" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <div class="col-2" style="margin-left: 2%;">
                <b>{{ multipleValueBridgeData[0].name | titlecase }}</b>
            </div>
            <div class="col-2"></div>
            
            <div class="col-1">
                <button mat-raised-button class="primary-bg p-button"
                    (click)="showValuationBridgeDataModal(updateValuationBridge, multipleValueBridgeData[0], 0)"
                    [disabled]="!preparedValueBridge">
                    Update
                </button>
            </div>
            <div class="col-1">
                <mat-checkbox [(ngModel)]="multipleValueBridgeData[0].waterFallGraphShowLabels" (change)="refreshWaterfallGraph(0)">Show Labels</mat-checkbox>
            </div>
        </div> -->

        <div class="row" *ngIf = "multipleValueBridgeData && multipleValueBridgeData.length > 0">
            <div class="col-6">
                <div class="m-auto" *ngIf="preparedValueBridge" [chart]="multipleValueBridgeData[0].waterFallChartData"></div>
                
                <div *ngIf="!preparedValueBridge" id="loader-container">
                    <div id="loader"></div>
                    <div>
                        {{ valuationAlgorithms.processingWaterFallMsg}}
                    </div>
                </div>
            </div>
            <ng-template [ngIf] = "multipleValueBridgeData[0] && multipleValueBridgeData[0].comments">
                <div class="col">
                    <div class="row">
                        <div class="col">
                            <mat-form-field style="width: 100%" class="text-area"> 
                                <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="multipleValueBridgeData[0].comments[0].title"  style="margin: 0px;"> 
                                <span matSuffix>                                              
                                    <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                </span>
                                
                            </mat-form-field>

                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <textarea matInput rows="9" [(ngModel)]="multipleValueBridgeData[0].comments[0].comment" name="impact" >
                                </textarea>
                            </mat-form-field>
                        </div>

                        <div class="col">
                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="multipleValueBridgeData[0].comments[1].title"  style="margin: 0px;"> 
                                <span matSuffix>                                              
                                    <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                </span>
                                
                            </mat-form-field>

                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <textarea matInput rows="9" [(ngModel)]="multipleValueBridgeData[0].comments[1].comment" name="impact">
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col">
                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="multipleValueBridgeData[0].comments[2].title"  style="margin: 0px;"> 
                                <span matSuffix>                                              
                                    <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                </span>
                                
                            </mat-form-field>

                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <textarea matInput rows="9" [(ngModel)]="multipleValueBridgeData[0].comments[2].comment" name="impact" >
                                </textarea>
                            </mat-form-field>
                        </div>

                        <div class="col">
                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="multipleValueBridgeData[0].comments[3].title"  style="margin: 0px;"> 
                                <span matSuffix>                                              
                                    <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                </span>
                                
                            </mat-form-field>

                            <mat-form-field style="width: 100%;" class="text-area"> 
                                <textarea matInput rows="9" [(ngModel)]="multipleValueBridgeData[0].comments[3].comment" name="impact" >
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <br>
    <!-- </ng-template> -->

    <ng-template [ngIf] = "multipleValueBridgeData && multipleValueBridgeData.length > 1">
        <ng-template ngFor [ngForOf] = "multipleValueBridgeData" let-valueBridge let-rowIndex = "index">
            <mat-expansion-panel style="margin: 20px; background-color:#FFFFFF" *ngIf = "rowIndex > 0">
                <mat-expansion-panel-header>
                 <mat-panel-title>
                    <b>{{ valueBridge.name | titlecase }}</b>
                    <i class="fa fa-trash action-icon primary-color-medium delete-icon" style="position: absolute; right: 5%; 
                    margin: 5px auto; font-size: 18px; cursor: pointer;" (click)="deleteMultiplValueBridgeData(rowIndex)"></i>
                 </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-2"></div>
                    <div class="col-1">
                        <button mat-raised-button class="primary-bg p-button"
                        (click)="showValuationBridgeDataModal(updateValuationBridge, valueBridge, rowIndex)"
                        [disabled]="!preparedValueBridge">
                            Update
                        </button>
                    </div>
                    <div class="col-1">
                        <mat-checkbox [(ngModel)]="valueBridge.waterFallGraphShowLabels" (change)="refreshWaterfallGraph(rowIndex)">Show Labels</mat-checkbox>
                    </div>
                    <div class="col"></div>

                </div>
                <div class="row" style="background-color: #FFFFFF; margin-top: 15px;">
                    <div class="col-6">
                        <div class="m-auto" *ngIf="preparedValueBridge" [chart]="valueBridge.waterFallChartData"></div>
                        <div *ngIf="!preparedValueBridge" id="loader-container">
                            <div id="loader"></div>
                            <div>
                                {{ valuationAlgorithms.processingWaterFallMsg}}
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <mat-form-field style="width: 100%" class="text-area"> 
                                    <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="valueBridge.comments[0].title"  style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[0].comment" name="impact" >
                                    </textarea> 
                                </mat-form-field>
                            </div>
        
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="valueBridge.comments[1].title"  style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[1].comment" name="impact">
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="valueBridge.comments[2].title"  style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[2].comment" name="impact" >
                                    </textarea>
                                </mat-form-field>
                            </div>
        
                            <div class="col">
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <input matInput class="label" placeholder="placeholder" type="text" [(ngModel)]="valueBridge.comments[3].title"  style="margin: 0px;"> 
                                    <span matSuffix>                                              
                                        <i class="fa fa-edit" style="align-items:right;margin-top:-11px;"> </i>
                                    </span>
                                    
                                </mat-form-field>
        
                                <mat-form-field style="width: 100%;" class="text-area"> 
                                    <textarea matInput rows="9" [(ngModel)]="valueBridge.comments[3].comment" name="impact" >
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>  
            </mat-expansion-panel>
        </ng-template>
    </ng-template>

    <div class="row">
        <div class="col-6">
            <span class="header">Track Record</span>
        </div>
        <div class="col-4" *ngIf="dashboardRepresentation && dashboardRepresentation.length > 0">
            Period
            <mat-form-field>
                <mat-select [(ngModel)]="savedData.trackRecordPreference.period">
                    <mat-option value="LTM">LTM</mat-option>
                    <mat-option value="NTM">NTM</mat-option>
                    <mat-option value="NTM+1">NTM + 1</mat-option>
                    <mat-option value="fy-1">FY - 1</mat-option>
                    <mat-option value="fy+1">FY + 1</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-2" *ngIf="dashboardRepresentation && dashboardRepresentation.length > 0">
            <button mat-raised-button class="primary-bg floating-dates" (click)="showAllDatesInTrackRecord()"> Show All </button>
        </div>
    </div>

    <div class="widget" style="margin: 15px; padding: 15px;"
        *ngIf="dashboardRepresentation && dashboardRepresentation.length > 0">

        <table style="width: 98%;" class="dr-table">
            <thead>
                <tr class="tr-valtabhead dr-table-header">
                    <th style="padding: 10px; padding-left: 25px;">In {{ latestValData[0].currency }} Mn</th> &nbsp;
                    <th style="padding: 10px; text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                        {{valuation.valuationDate | date: 'MMM d, y'}}
                    </th>
                </tr>
            </thead>
            <tr class="tr-valtab">
                <td style="padding-left: 25px;">
                
                    <mat-form-field>
                        <mat-select [(ngModel)]="savedData.trackRecordPreference.selectedMetrics[0]">
                            <mat-option value="revenue">Revenue</mat-option>
                            <mat-option value="ebitda">EBITDA</mat-option>
                            <mat-option value="ebit">EBIT</mat-option>
                            <mat-option value="ebitda_capex">EBITDA-Capex</mat-option>
                            <mat-option value="grossProfit">Gross Profit</mat-option>
                            <mat-option value="priceEarnings">Net Income</mat-option>
                            <mat-option value="priceBookValue">Shareholder's Equity</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td> &nbsp;

                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <!-- {{utilService.getDisplayFormattedNumber(valuation[trackRecordPreference.selectedMetrics[0]], 1, '', 'NMF' )}} -->
                    {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[0], 'adjustedMetric')}}
                </td>
            </tr>
            <tr class="tr-valtab">
                <td style="padding-left: 25px;">
                    <mat-form-field>
                        <mat-select [(ngModel)]="savedData.trackRecordPreference.selectedMetrics[1]">

                            <mat-option value="revenue">Revenue</mat-option>
                            <mat-option value="ebitda">EBITDA</mat-option>
                            <mat-option value="ebit">EBIT</mat-option>
                            <mat-option value="ebitda_capex">EBITDA-Capex</mat-option>
                            <mat-option value="grossProfit">Gross Profit</mat-option>
                            <mat-option value="priceEarnings">Net Income</mat-option>
                            <mat-option value="priceBookValue">Shareholder's Equity</mat-option>
                        </mat-select>
                    </mat-form-field>
                </td> &nbsp;

                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <!-- {{utilService.getDisplayFormattedNumber(valuation[trackRecordPreference.selectedMetrics[1]], 1, '', 'NMF' )}} -->
                    {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[1], 'adjustedMetric')}}
                </td>
            </tr>

            <tr class="tr-valtab">
                <td style="padding-left: 25px;">{{getSelectedTRMultipleLabel(savedData.trackRecordPreference.selectedMetrics[0])}} Multiple</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <!-- {{utilService.getDisplayFormattedNumber(valuation.bevRevenue, 1, 'x', 'NMF', true )}} -->
                    {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[0], 'impliedMultiple')}}
                </td>
            </tr>

            <tr class="tr-valtab">
                <td style="padding-left: 25px;">{{getSelectedTRMultipleLabel(savedData.trackRecordPreference.selectedMetrics[1])}} Multiple</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <!-- {{utilService.getDisplayFormattedNumber(valuation.bevEbitda, 1, 'x', 'NMF', true )}} -->
                    {{getSelectedTRMultiple(valuation, savedData.trackRecordPreference.selectedMetrics[1], 'impliedMultiple')}}
                </td>
            </tr>

            <tr class="tr-valtab" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Enterprise Value</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.enterpriseValue, 1, '', 'NA' )}}
                </td>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Net Debt</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.netDebt, 1, '', 'NA' )}}
                </td>
            </tr>

            <!-- <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">(Net Debt) / Net cash</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <ng-template [ngIf]="valuation.cash > 0">
                        ({{ (valuation.debt / valuation.cash ) | number: '1.1-1'}})
                    </ng-template>

                    <ng-template [ngIf]="valuation.cash <= 0">NA</ng-template>
                </td>
            </tr> -->

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Equity Value (100%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.equityValue, 1, '', 'NA' )}}
                </td>
            </tr>
            
            
            <tr class="tr-valtabhead" style="border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Adjusted Equity Value (100%)</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.adjustedConcludedEquityValue, 1, '', 'NA' )}}
                </th>
            </tr>
            
            
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Stake (%)</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.stake, 1, '%', 'NA' )}}
                </td>
            </tr>            
            
            <tr class="tr-valtabhead" style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Stake Equity Value</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.stakeValue, 1, '', 'NA' )}}
                </th>
            </tr>
            

            <!-- Empty Row -->
            <tr class="tr-valtabhead">
                <th style="padding-left: 25px;"></th> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                   
                </td>
            </tr>

            <tr class="tr-valtab" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Debt & Others</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.stakeEquityAdjustments, 1, '', 0 )}}
                </td>
            </tr>

            <tr class="tr-valtabhead" style="border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Value {{fundListService.getFundName(portfolioService.selectedFundId)}}</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.stakeAdjustedEquityValue, 1, '', 'NA' )}}
                </th>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Invested Amount {{fundListService.getFundName(portfolioService.selectedFundId)}}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.investmentAmount, 1, '', 'NA' )}}
                </td>
            </tr>
            
            
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Proceeds {{fundListService.getFundName(portfolioService.selectedFundId)}}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.realisedProceeds, 1, '', '0.0', false)}}
                </td>
            </tr>

            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;" >
                <th style="padding-left: 25px;">Investment Multiple {{fundListService.getFundName(portfolioService.selectedFundId)}} (MOIC)</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.moic, 1, 'x', 'NA' )}}
                </th>
            </tr>

            <tr class="tr-valtabhead" *ngIf="!isEZ" style="border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">DPI</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{utilService.getDisplayFormattedNumber(valuation.dpi, 1, 'x', 'NA' )}}
                </th>
            </tr>

            <tr class="tr-valtab track-record-bottom-row">
                <td style="padding-left: 25px;">IRR {{fundListService.getFundName(portfolioService.selectedFundId)}}</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    <ng-template [ngIf]="valuation.grossIRR == 0" [ngIfElse]="IRRElseBlock">
                        {{utilService.getDisplayFormattedNumber(valuation.grossIRR, 1, '%', '0.0', false)}}%
                    </ng-template>
                    <ng-template #IRRElseBlock>
                        {{utilService.getDisplayFormattedNumber(valuation.grossIRR, 1, '%', '0.0', false)}}
                    </ng-template>
                </td>
            </tr>

            <!-- Empty Row -->
            <!-- <tr class="tr-valtabhead">
                <th style="padding-left: 25px;"></th> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                   
                </td>
            </tr>

            <tr class="tr-valtabhead" style="border-top: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Value LPs</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{valuation.stakeValueLP | number: '1.1-1'}}
                </th>
            </tr>
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Proceeds LPs</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{valuation.realisedProceedsLP | number: '1.1-1'}}
                </td>
            </tr>
            <tr class="tr-valtab" style="border-left: 1px solid; border-right: 1px solid;">
                <td style="padding-left: 25px;">Invested Amount LPs</td> &nbsp;
                <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{valuation.investmentAmountLP | number: '1.1-1'}}
                </td>
            </tr>
            <tr class="tr-valtabhead" style="border-bottom: 1px solid; border-left: 1px solid; border-right: 1px solid;">
                <th style="padding-left: 25px;">Investment Multiple LPs</th> &nbsp;
                <th style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                    {{valuation.moicLP | number: '1.1-1'}}x
                </th>
            </tr> -->

            <!-- <tr>
                    <th style="padding-left: 25px;"><i>investment Amount {{fundListService.getFundName(portfolioService.selectedFundId)}}</i></th> &nbsp;
                       <td style="text-align: right;" *ngFor="let valuation of dashboardRepresentation">
                          {{valuation.valuationDate | date: 'MMM d, y'}}
                      </td>
                 </tr> -->
        </table>
    </div>

    <br>
    <br>

    <div class="row">
        <span class="header">Industry and Company Performance </span>
    </div>
    <br>
    <div class="row">
        <div class="col-8" style="height: 410px;">
            <div class="widget">
                <div class="row" style="margin: 0 0 10px 0;">

                    <div class="col topic">Multiple Evolution</div>

                    <div class="col" style="display: inline-block;">
                        <ng-template
                            [ngIf]="portfolioService.selectedForm && portfolioService.selectedForm[0] && portfolioService.selectedForm[0].businessUnits && portfolioService.selectedForm[0].businessUnits.length > 1">
                            <mat-form-field>
                                <mat-select [(ngModel)]="buMarketMultiple" name="buMarketMultiple"
                                    (ngModelChange)="fetchMarketEvolutionChartOnBUChange()">
                                    <mat-option [value]="bu.businessUnitName"
                                        *ngFor="let bu of portfolioService.selectedForm[0].businessUnits; let index=index">
                                        {{bu.companyNameInForm}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-template>
                    </div>

                    <div class="col" style="display: inline-block;">
                        <mat-form-field>
                            <mat-select [(ngModel)]="marketMultiple" name="marketMultiple"
                                (ngModelChange)="createMarketEvolutionChart(marketMultiple)">
                                <mat-option value="bevRevenue">
                                    EV/ Revenue 
                                </mat-option>
                                <mat-option value="bevEbitda">
                                    EV/ EBITDA
                                </mat-option>
                                <mat-option value="bevEbitda_Capex">
                                    EV/ (EBITDA - CapEx)
                                </mat-option>
                                <mat-option value="evGrossProfit">
                                    EV/ Gross Profit 
                                </mat-option>
                                <mat-option value="psales">
                                    Price/ Sales
                                </mat-option>
                                <mat-option value="pe">
                                    Price/ Earnings
                                </mat-option>
                                <mat-option value="pbv">
                                    Price/ Book Value
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    
                    <div class="col">
                        <mat-checkbox [(ngModel)]="multipleChartShowLabels" (change)="createMarketEvolutionChart(marketMultiple)">Show Labels</mat-checkbox>
                    </div>
                </div>
                <div>
                    <div class="m-auto" [chart]="areaChart" *ngIf="!areaChartLoader"></div>
                    <div *ngIf="areaChartLoader" id="loader-container">
                        <div id="loader"></div>
                    </div>
                </div>
                <!-- <div>
                <div class="m-auto" [chart]="areaChart" *ngIf="!areaChartLoader" ></div>
                <div *ngIf="areaChartLoader" id="loader-container">
                  <div id="loader"></div>
                </div>
            </div> -->
            </div>
        </div>

        <div class="col-4">
            <div class="widget" style="height: 410px;">
                <div style="margin-bottom: 50px;">
                    <span class="topic">Significant Event - Market</span>
                </div>
                <mat-form-field class="text-area">
                    <textarea matInput rows="20" [(ngModel)]="savedData.significantEventMarket"
                        style="font-size: small">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <br>
    <!-- <div class="row">
        <div class="col-8">
            <div class="widget" style="overflow: hidden; height: 100%;">
                <div style="margin-bottom: 20px;">
                    <div class="row">
                        <div class="col">
                            <span class="topic">Planned vs Actual Performance</span>
                        </div>

                        <div class="col-2">
                            <ng-template [ngIf]="actualPerformanceDataModel.graphType"><b>Graph Type</b></ng-template>
                        </div>

                        <div class="col-2">
                            <ng-template [ngIf]="actualPerformanceDataModel.graphType">
                                <mat-select [(ngModel)]="actualPerformanceDataModel.graphType"
                                    (ngModelChange)="createActualPerformanceGraph()">
                                    <mat-option value="quarter"> Quarter </mat-option>
                                    <mat-option value="year"> Year </mat-option>
                                </mat-select>
                            </ng-template>
                        </div>

                        <div class="col-2" style="text-align: right">
                            <button mat-button class="primary-bg" type="submit"
                                (click)="openPopUpModel(plannedPerformance)">Update</button>
                        </div>
                    </div>
                </div>
                
                <div class="row" style="margin: 0; height: 300px;">
                  <div class="col-6" style="padding-left: 0; height: 100%;"> -->

                    <!-- Converted to grid -->
                    <!-- <table class="table borderless" style="height: 100%; width: 100%; text-align: right;">
                        <tr>
                            <th style="width:100px"></th>
                            <th colspan="3" class="planned-vs-actual-table-header">
                                {{actualPerformanceDataModel.header1[0]}}</th>
                            <th colspan="3" class="planned-vs-actual-table-header">
                                {{actualPerformanceDataModel.header1[1]}}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th class="subItemBorders">Actual</th>
                            <th class="subItemBorders">Budget</th>
                            <th class="subItemBorders">Delta (%)</th>
                            <th class="subItemBorders">Actual</th>
                            <th class="subItemBorders">Budget</th>
                            <th class="subItemBorders">Delta (%)</th>
                        </tr>
                        
                        <tr *ngFor="let row of actualPerformanceDataModel.rows">
                            <td style="text-align: left">{{row.label}}</td>
                            <td *ngFor="let v of row.values">
                              {{this.utilService.getFormattedNumber(v)}}
                            </td>
                        </tr>
                    </table> -->

                    <!-- <div class="actual-vs-planned-grid">
                      <ejs-grid [dataSource]='actualPerformanceDataModel.rows' #actualVsPlannedGrid 
                      [allowResizing]='true'
                      [allowTextWrap]='true'
                      [gridLines]='Both'>
                        <e-columns>
                          <e-column field='label' headerText='' width='60' ></e-column>
                          <e-column [headerText]="actualPerformanceDataModel.header1[0]" [columns]='actualPerformanceTableColumSet1' textAlign='Center'></e-column>
                          <e-column [headerText]="actualPerformanceDataModel.header1[1]" [columns]='actualPerformanceTableColumSet2' textAlign='Center'></e-column>
                        </e-columns>
                      </ejs-grid>
                    </div>

                  </div>
                
                  <div class="col-6">
                    <ng-template [ngIf]="actualPerformanceGraph">
                      <div [chart]="actualPerformanceGraph" style="height: 300px"></div>
                    </ng-template>
                  </div>
                </div>
            </div>
        </div>

        <div class="col-4">
            <div class="widget" style="height: 410px; overflow: hidden; height: 100%;">
                <div style="margin-bottom: 50px;">
                    <span class="topic">Significant Event - Company</span>
                </div>
                <mat-form-field class="text-area" style="font-size: small">
                    <textarea matInput rows="20" [(ngModel)]="savedData.significantEventCompany"> </textarea>
                </mat-form-field>
            </div>
        </div>
    </div> -->

    <app-qualitative-analysis></app-qualitative-analysis>

</div>

<div class="loading-container" *ngIf="!portfolioService.fundDetails">
    <p class="loading-text">Fetching Summary</p>
</div>

<ng-template #realisedProceeds let-realised>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="realised.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template [ngIf]="portfolioService.formDetailsObj.multipleRealisedProceeds && 
        portfolioService.formDetailsObj.multipleRealisedProceeds.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Date
                    </th>
                    <th style="text-align: right;">
                        Realised Proceeds
                    </th>
                    <th style="text-align: right;">
                        Currency
                    </th>
                    <th style="text-align: right;">
                        Exchange Value
                    </th>
                    <th style="text-align: right;">
                        Exchange Rate
                    </th>
                    <th style="text-align: right;">
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="portfolioService.formDetailsObj.multipleRealisedProceeds" let-returns
                    let-rowIndex="index">
                    <tr>
                        <td>
                            {{ returns.date | date: "MMM, dd yyyy" }}
                        </td>
                        <td style="text-align: right;">
                            {{returns.valueBeforeConversion ? returns.valueBeforeConversion : returns.value | number:
                            '1.1-1'}} {{ returns.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            {{ returns.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="returns.exchangeValue">
                                {{ returns.exchangeValue | number: '1.1-1' }} {{ returns.sourceCurrency }}
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="returns.exchangeRate">
                                {{ returns.exchangeRate | number: '1.1-1'}}
                            </ng-template>
                            <ng-template [ngIf]="!returns.exchangeRate">
                                1.00
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <i class="far fa-trash-alt" (click)="delete('realisedProceeds', rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Date </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field (click)="vDatePicker.open()">
                        <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="date">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label>Realised Proceeds ({{ latestValData[0].currency }} Mn)</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="value" name="realised" type="number"
                            (ngModelChange)="getCurrencyExchange($event, userSelectedCurrency, latestValData[0].currency, this.latestValData[0].valuationDate)">
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Currency </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <app-currency-dropdown [sourceCurrency]="latestValData[0].currency"
                        [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency"
                        [currencyConversionDate]="this.latestValData[0].valuationDate"
                        (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Rate</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.exchangeRate | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Value</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.convertedValue | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                (click)="addRealisedProceeds(); realised.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>

<ng-template #investmentAmount let-investment>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="investment.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template [ngIf]="portfolioService.formDetailsObj.multipleInvestmentAmount && 
        portfolioService.formDetailsObj.multipleInvestmentAmount.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Date
                    </th>
                    <th style="text-align: right;">
                        Investment Amount
                        <!-- ({{ latestValData[0].currency }} Mn) -->
                    </th>
                    <th style="text-align: right;">
                        Currency
                    </th>
                    <th style="text-align: right;">
                        Exchange Value
                    </th>
                    <th style="text-align: right;">
                        Exchange Rate
                    </th>
                    <th style="text-align: right;">
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="portfolioService.formDetailsObj.multipleInvestmentAmount" let-amount
                    let-rowIndex="index">
                    <tr>
                        <td>
                            {{ amount.date | date: "MMM, dd yyyy"}}
                        </td>
                        <td style="text-align: right;">
                            {{amount.valueBeforeConversion | number: '1.1-1'}} {{ amount.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            {{ amount.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="amount.exchangeValue">
                                {{ amount.exchangeValue | number: '1.1-1' }} {{ amount.sourceCurrency }}
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="amount.exchangeRate">
                                {{ amount.exchangeRate | number: '1.1-1'}}
                            </ng-template>
                            <ng-template [ngIf]="!amount.exchangeRate">
                                1.00
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <i class="far fa-trash-alt" (click)="delete('investmentAmount', rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Date </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field (click)="vDatePicker.open()">
                        <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="date">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Investment Amount ({{ latestValData[0].currency }} Mn)</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="value" name="realised" type="number"
                            (ngModelChange)="getCurrencyExchange($event, userSelectedCurrency, latestValData[0].currency, this.latestValData[0].valuationDate)">
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Currency </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <app-currency-dropdown [sourceCurrency]="latestValData[0].currency"
                        [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency"
                        [currencyConversionDate]="this.latestValData[0].valuationDate"
                        (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Rate</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.exchangeRate | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Value</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.convertedValue | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                (click)="addInvestmentAmount(); investment.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #currencyConversion let-converter>
    <div class="currency-modal">
      <div class="modal">
        <div class="modal-header">
          <div class="modal-title">
      
          </div>
          <button type="button" class="close" aria-label="Close" (click)="converter.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-currency-dropdown [sourceCurrency]="latestValData[0].currency" [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency" [currencyConversionDate]="latestValuationDate" (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
        </div>
      </div>
    </div>
</ng-template> -->

<!-- Partner -->
<ng-template #partner let-partnerName>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="partnerName.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <table class="table borderless">
            <tr>
                <th></th>
                <th>
                    Name
                </th>
                <th style="text-align: right;">
                    Delete
                </th>
            </tr>
            <ng-template [ngIf]="masterPartnersList && masterPartnersList?.length > 0">
                <ng-template ngFor [ngForOf]="masterPartnersList" let-partnerList let-rowIndex="index">
                    <tr>
                        <td>
                            <mat-checkbox [(ngModel)]="partnerList.isSelected"
                                (ngModelChange)="addPartnerToCompany($event, partnerList)"></mat-checkbox>
                        </td>
                        <td style="width: 150px;">
                            {{ partnerList.name }}
                        </td>
                        <td style="text-align: right">
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </ng-template>
            <tr>
                <td>
                    <mat-checkbox [(ngModel)]="portfolioService.partnerList.isSelected"></mat-checkbox>
                </td>
                <td style="width: 150px;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="portfolioService.partnerList.name" name="partner" type="text"
                            [disabled]="!portfolioService.partnerList.isSelected">
                    </mat-form-field>
                </td>
                <td>

                </td>
            </tr>
        </table>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                [disabled]="!portfolioService.partnerList.name && !portfolioService.partnerList.isSelected"
                (click)="addPartnerList(); partnerName.dismiss('Cross click')">Add</button> &nbsp;
        </div>
    </div>
</ng-template>
<!-- End -->

<ng-template #investmentStatus let-investment>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="investment.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" >
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label style="font-weight:bold;">Investment Status</mat-label>
                </div>
                <div class="col-8">
                    <mat-form-field style="width:20vw">
                        <mat-select [(ngModel)]="exitStatus">
                            <mat-option value="CURRENT PORTFOLIO">
                                Current Portfolio
                            </mat-option>
                            <mat-option value="COMPLETE EXIT">
                                Complete Exit
                            </mat-option>
                            <mat-option value="PARTIAL EXIT">
                                Partial Exit
                            </mat-option>    
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div style="text-align: right; margin: 5px;">
            <button mat-raised-button class="primary-bg" type="submit" (click)="setExitStatus(); investment.dismiss('Cross click')">
                Save
            </button>
        </div>
    </div>
</ng-template>


<ng-template #valDateUpperLimitPopup let-valDateUpperLimitPopupModal>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close"
            (click)="valDateUpperLimitPopupModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: unset; overflow: hidden;">
        <mat-form-field>
            <mat-label>Valuation Date</mat-label>
            <mat-select [(ngModel)]="valDateUpperLimit">
                <mat-option *ngFor="let valDate of valDatesForUpperLimitSelection;" [value]="valDate"
                    style="margin-top:10px;">

                    {{valDate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <Br /><Br />

        Click on <b>save</b> to refresh the investment summary as on selected valuation date.

        <div style="margin: 10px;">
            <button mat-raised-button class="primary-bg" type="submit"
                (click)="saveValDateUpperLimit(valDateUpperLimitPopupModal)">

                Save
            </button>
        </div>
    </div>
</ng-template>

<ng-template #fullyDilutedOwnership let-fullyDiluted>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="fullyDiluted.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Fully Diluted Ownership </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput style="text-align: right;" [(ngModel)]="portfolioService.fullyDilutedOwnership"
                            name="partner" type="number">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                [disabled]="!portfolioService.fullyDilutedOwnership"
                (click)="addFullyDilutedOwnership(); fullyDiluted.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>


<ng-template #securityType let-security>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="security.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Security Type </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="portfolioService.securityType" name="partner" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit"
                (click)="addSecurityType(); security.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>


<ng-template #plannedPerformance let-plannedPerformance>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="plannedPerformance.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <app-planned-vs-actual [excelData]="actualPerformanceDataModel" (dataUpdated)="savePerformanceData()">
        </app-planned-vs-actual>
    </div>
</ng-template>

<!-- Update Value Bridge -->
<ng-template #updateValuationBridge let-applicationModal>
    <div class="modal-header">
        <div class="modal-title">
            <h4>Update Valuation Bridge Data</h4>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close"
            (click)="applicationModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="model-content">
            <div class="row p-modal-row">
                <div class="col-3">
                    <mat-form-field>
                        <mat-label>Name </mat-label>
                        <input matInput name="valueBridgeName" [(ngModel)] = "valueBridgeName">
                    </mat-form-field>
                </div>

                <div class="col rightAlign">
                    No of Valuation Dates &nbsp;
                    2
                    &nbsp;
                    <mat-slide-toggle [checked] = "isMultiValueBridge" (change) = "isMultiValueBridge = !isMultiValueBridge"></mat-slide-toggle>
                    &nbsp;
                    3
                </div>

                <div class="col-1"></div>
            </div>
            <div class="row p-modal-row">
                <div class="col p-modal-left-col">
                    <mat-form-field>
                        <mat-label>Start Date</mat-label>
                        <mat-select [(ngModel)]="startDateValuationBridge" >
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="startDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col p-modal-left-col" *ngIf="this.filteredForms.length >= 3 && isMultiValueBridge">
                    <mat-form-field>
                        <mat-label>Intermediate Date</mat-label>
                        <mat-select [(ngModel)]="intermediateDateValuationBridge">
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="intermediateDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col p-modal-left-col">
                    <mat-form-field>
                        <mat-label>End Date</mat-label>
                        <mat-select [(ngModel)]="endDateValuationBridge">
                            <mat-option *ngFor="let valuation of filteredForms" [value]="valuation.valuationDate"
                                (click)="endDateChange(valuation)">
                                {{valuation.valuationDate | date: 'MMM d, y'}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col-2">
                    <button mat-raised-button class="primary-bg"
                        [disabled]="!startDateValuationBridge || !endDateValuationBridge"
                        (click)="fetchValuationDataForWaterfallChart(selectedValueBridgeIndex)">
                        Fetch
                    </button>
                </div>
            </div>

            <ng-template [ngIf]="!showValueBridgePopUpLoader">
                <div>
                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col" style="width: 100%;">
                            <b>Label</b>
                        </div>
                        <div class="col-4" style="width: 100%; text-align: right">
                            <b>Value</b>
                        </div>
                        <!-- <div class="col-3" style="width: 100%; text-align: center">
                        <b>Intermediate Sum</b>
                    </div> -->
                    </div>

                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col start-date-container">
                            <ng-template [ngIf]="valueBridgeModel[0].name !== 'Start Date'">
                                {{ valueBridgeModel[0].name | date: 'MMM d, y'}}
                            </ng-template>

                            <ng-template [ngIf]="valueBridgeModel[0].name == 'Start Date'">
                                {{ valueBridgeModel[0].name}}
                            </ng-template>
                        </div>
                        <div class="col-4 rightAlign">
                            <!-- <mat-form-field style="width: 100%; text-align: right">
                            <input matInput [(ngModel)]="valueBridgeModel[0].formattedValue" (input)="acceptOnlyDigitsVB($event, valueBridgeModel[0])" required>
                        </mat-form-field> -->
                            {{ valueBridgeModel[0].y | number:"1.2-2" }}
                        </div>
                        <!-- <div class="col-3 p-modal-left-col">
                    </div> -->
                    </div>

                    <div *ngFor="let entry of valueBridgeModel | slice:1:(valueBridgeModel.length - 1); index as i"
                        class="row p-modal-row">
                        <ng-template [ngIf]="entry">
                            <div class="col leftAlign">
                                <ng-template [ngIf]="i==6 && valueBridgeModel.length > 6" [ngIfElse]="inputField">
                                    {{ valueBridgeModel[7].name | date: 'MMM d, y'}}
                                </ng-template>
                                <ng-template #inputField>
                                    <!-- <mat-form-field style="width: 90%;">
                                        <input matInput [(ngModel)]="entry.name" required>
                                    </mat-form-field> -->
                                    {{ entry.name }}
                                </ng-template>
                            </div>
                            <div class="col-4 rightAlign">
                                <!-- <mat-form-field style="width: 100%; text-align: right" *ngIf="!entry.isIntermediateSum">
                                    <input matInput [(ngModel)]="entry.formattedValue" (input)="acceptOnlyDigitsVB($event, entry)" required>
                                </mat-form-field> -->
                                {{ entry.y | number: "1.2-2" }}
                            </div>
                            <!-- <div class="col-3" style="text-align: center">
                                <mat-radio-button [checked]="entry.isIntermediateSum" [value]="entry.name" name="addToIntermediateSumRadio" (change)="addToIntermediateSum(i)"></mat-radio-button>
                            </div> -->
                        </ng-template>
                        <!-- <div class="col-3" style="text-align: center">
                        <mat-radio-button [checked]="entry.isIntermediateSum" [value]="entry.name" name="addToIntermediateSumRadio" (change)="addToIntermediateSum(i)"></mat-radio-button>
                    </div> -->
                    </div>

                    <div class="row p-modal-row">
                        <div class="col p-modal-left-col end-date-container">
                            <ng-template [ngIf]="valueBridgeModel[(valueBridgeModel.length - 1)].name !== 'End Date'">
                                {{ valueBridgeModel[(valueBridgeModel.length - 1)].name | date: 'MMM d, y'}}
                            </ng-template>

                            <ng-template [ngIf]="valueBridgeModel[(valueBridgeModel.length - 1)].name == 'End Date'">
                                {{ valueBridgeModel[(valueBridgeModel.length - 1)].name}}
                            </ng-template>
                        </div>
                        <div class="col-4 p-modal-left-col" style="width: 100%; text-align: right;">
                            {{ valueBridgeModel[(valueBridgeModel.length - 1)].y | number:"1.2-2"}}
                        </div>
                        <!-- <div class="col-3 p-modal-left-col">
                        </div> -->
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template [ngIf]="!showValueBridgePopUpLoader">
            <div class="row p-modal-row">
                <div class="col p-modal-right-col">
                </div>
                <div class="col">
                    <button mat-raised-button class="secondary-bg p-button" style="float: right;"
                        (click)="reInstateTheValues(); applicationModal.dismiss('Cross click')">
                        Cancel
                    </button>
                    <button mat-raised-button class="primary-bg p-button" style="float: right;"
                        (click)="saveValuationBridgeData(selectedValueBridgeIndex)">
                        Save
                    </button>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="showValueBridgePopUpLoader">
            <div class="row" style="margin: 25%; width: 100%; text-align: center;">
                <span style="font-size: xx-large;">
                    Preparing Value Bridge...
                </span>
            </div>
        </ng-template>
    </div>
</ng-template>

<ng-template #editCompanyContent let-editCompanyContent>
    <div class="modal-header">
        <div class="modal-title">
            <h4>Edit Company</h4>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close"
            (click)="editCompanyContent.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="model-content">

            <div class="row marginBottom">
                <div class="col-3"><b>Total Number Of Employees</b></div>
                <div class="col-9">
                    <mat-form-field>
                        <input matInput type="number" style="text-align:right;"
                            [(ngModel)]="editCompanyDetails.organization.estimated_num_employees">
                    </mat-form-field>
                </div>
            </div>

            <div class="row marginBottom">
                <div class="col-3"><b>Key People</b></div>
                <div class="col-5">
                    <mat-form-field>
                        <input matInput type="text" [(ngModel)]="editCompanyDetails.organization.newKeyPeople">
                    </mat-form-field>
                </div>
                <div class="col-4"><button mat-raised-button class="primary-bg p-button" (click)="addKeyPeople()"
                        [disabled]="!editCompanyDetails.organization.newKeyPeople">Add</button></div>
            </div>

            <ng-template ngFor [ngForOf]="editCompanyDetails.people" let-existingKeyPeople let-index="index">
                <div class="row">
                    <div class="col-3"></div>
                    <div class="col-7">
                        {{existingKeyPeople.name}}<ng-template [ngIf]="existingKeyPeople.title">
                            ({{existingKeyPeople.title}})</ng-template>
                    </div>
                    <div class="col-2" style="cursor:pointer;"><i class="far fa-trash-alt"
                            (click)="deleteKeyPeople(index)"></i></div>
                </div>
            </ng-template>

        </div>

        <div class="row p-modal-row">
            <div class="col-4 p-modal-left-col">
            </div>
            <div class="col">
                <button mat-raised-button class="secondary-bg p-button"
                    (click)="editCompanyContent.dismiss('Cross click')">
                    Cancel
                </button>
                <button mat-raised-button class="primary-bg p-button" (click)="saveCompanyDetails(editCompanyContent)">
                    Save
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #businessUnitPopup let-businessUnitModal>
    <div class="modal-header">
        <div class="modal-title" style="width: 100%;">
            <h6>Business Units (Enterprise Value)</h6>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="businessUnitModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template ngFor [ngForOf]="selectedBusinessUnits" let-bu let-buI="index">
            <div class="row" style="margin: 10px;" [class.consol-form-row-in-popup]="buI == 0">

                <div class="col">
                    {{bu.companyNameInForm || bu.businessUnitName }}
                    <div style="font-size: 12px;">{{bu.versionName}} | {{bu.valuationDate | date: "mediumDate"}}</div>
                </div>


                <div class="col-4">

                    <ng-template [ngIf]="bu.investment">
                        {{bu.investment.currency}}

                        <ng-template [ngIf]="bu.investment.enterpriseValue">
                            {{utilService.getDisplayFormattedNumber(bu.investment.enterpriseValue , 1, "Mn", "0")}}
                        </ng-template>
                    </ng-template>
                </div>

                <div class="col-3">
                    <!-- <i class="far fa-edit primary-color-medium action-icon" (click)="portfolioService.openSAF(bu)"></i> &nbsp;&nbsp;
            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "10"></mat-spinner> &nbsp;&nbsp; -->

                    <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(bu)"
                        *ngIf="bu.status !== 'Initiated'"></i>

                </div>
            </div>
        </ng-template>
    </div>
</ng-template>

<a style="display: none;" #downloadLink></a>