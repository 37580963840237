import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from './role.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from './group.model';
import { User } from './user.model';
import { DataService } from '../services/data.service';
import { UserManagementService } from '../services/user-management.service';

import * as moment from 'moment/moment';
import { MessagePopupComponent } from '../utils/popup/message-popup.component';
import { Router } from '@angular/router';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  is73sAdmin = false;

  orgLoading:boolean = true;

  servicesToggleChecked: boolean[];
  roleSlideToggleChecked: boolean[];
  groupSlideToggleChecked: boolean[];
  userSlideToggleChecked: boolean[];

  savingRole = false;
  savingGroup = false;
  savingUser = false;
  
  orgEmailId;
  orgCompanyName;
  orgType = "corp"
  orgTypeSelection = "corp";

  valuationType = "biz";

  orgApiLimits = { search: 150, bc: 50, pa: 20}

  selectedOrg;
  selectedOrgId;

  selectedRoleRadio
  selectedGroupRadio
  selectedUserRadio
  
  editable: boolean[];
  roleEditable: boolean[];
  orgEditable: boolean[];
  groupEditable: boolean[];
  userEditable: boolean[];

  groups = [];

  roles = []

  services = [];
  
  userStatistics = [
        {
          "header": "Total Number of Roles", "value": "", "icon":"fas fa-user-tie"
        },
        {
          "header": "Total Number of Groups", "value": "", "icon":"fas fa-users",
        },
        {
          "header": "Total Number of Users", "value": "", "icon":"fas fa-users",
          "footer": { "label": "New This Month", "value": "" }
        },
        {
          "header": "Total Number of Active Users", "value": "", "icon":"fas fa-users",
        }
      ]

  displayedOrgColumns: string[] = [
    "name",
    "createdOn",
    "createdBy",
    "view"
  ];

  roleDisplayedColumns: string[] = [
    "name",
    "description",
    "status",
    "createdBy",
    "createdOn",
    "edit"
  ];

  groupDisplayedColumns: string[] = [
    "name",
    "description",
    "status",
    "createdBy",
    "createdOn",
    "edit"
  ];

  userDisplayedColumns: string[] = [
    "id",
    "name",
    "status",
    "createdBy",
    "createdOn",
    "edit"
  ];
  
  orgList = [];
  orgDataSource = new MatTableDataSource<Org>(ORG_DATA);

  roleDataSource = new MatTableDataSource<Role>(ROLE_ELEMENT_DATA);
  
  groupDataSource = new MatTableDataSource<Group>(GROUP_ELEMENT_DATA);
  
  userDataSource = new MatTableDataSource<User>(USER_ELEMENT_DATA);

  @ViewChild('userPaginator', { static: false }) userPaginator: MatPaginator;
  @ViewChild('orgPaginator', { static: false }) orgPaginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private modalService: NgbModal, private dialog: MatDialog, private us:UserManagementService, private router: Router) { }

  ngOnInit() {
    const details = this.us.getUserDetails();
    if(!this.us.isMasterUser()) {
      this.router.navigateByUrl('/fundList');
    } else {
      if(this.us.isPortalUser()) {
        let orgArray : Org [] = [];
        const org = details.organization;

        const obj : Org = {
          id: org.id,
          name: org.orgName,
          orgType: org.orgType,
          createdOn: org.createdDate ? moment(org.createdDate).format("ll") : moment(new Date()).format("ll"),
          createdBy: org.createdBy ? org.createdBy : "73S-Admin"
        }

        orgArray.push(obj);

        this.loadOrgDatails(orgArray);
        
      } else {
        this.is73sAdmin = this.us.is73sAdmin();

        this.getOrgList();
        // this.getOrgStats();
      }
    }
  }

  getOrgList() {
    let orgArray : Org [] = [];
    const details = this.us.getUserDetails();
    const org = details.organization;
  
    orgArray.push({
      id: org.id,
      name: org.orgName,
      orgType: org.orgType,
      createdOn: org.createdDate ? moment(org.createdDate).format("ll") : moment(new Date()).format("ll"),
      createdBy: org.createdBy ? org.createdBy : "73S-Admin"
    });

    this.us.getOrgList(this.is73sAdmin ? null : details.id).subscribe((orgListData)=>{
      if(!orgListData || !orgListData.body["response"]) return;

      orgListData.body["response"].forEach(org => {
        const obj : Org = {
          id: org.id,
          name: org.orgName,
          orgType: org.orgType,
          createdOn: org.createdDate ? moment(org.createdDate).format("ll") : moment(new Date()).format("ll"),
          createdBy: org.createdBy ? org.createdBy : "73S-Admin"
        }

        orgArray.push(obj);
      });

      this.orgList = orgArray;

      this.orgTypeSelectionChange(this.orgTypeSelection);
    }, error=>{
      this.orgLoading = false;
    });
  }

  loadOrgDatails(orgArray) {
    orgArray = orgArray.sort((o1, o2)=>{
      return new Date(o1.createdOn) < new Date(o2.createdOn) ? 1: -1;
    })

    // console.log("newArray onload", orgArray);

    this.orgDataSource = new MatTableDataSource<Org>(orgArray);
    this.orgEditable = new Array(this.orgDataSource.data.length);

    this.prepareDataSource(this.orgDataSource, this.orgEditable, this.orgPaginator);

    this.orgLoading = false;
  }

  getOrgStats() {
    this.us.getStats().subscribe((data)=>{
      // console.log("Org stats", data);

      if(!data || !data.body["response"] ) return;
      this.userStatistics[0]["value"] = data.body["response"].roles;
      this.userStatistics[1]["value"] = data.body["response"].groups;
      this.userStatistics[2]["value"] = data.body["response"].users;
      this.userStatistics[2]["footer"] = { "label": "New This Month", "value": data.body["response"].usersCurMonth };
      
      this.userStatistics[3]["value"] = data.body["response"].usersActive;
    }, error=>{
      console.log("Org Statistics fetch failed", error);
    })
  }
  
  openStartApplication(content) {
    this.modalService.open(content, { centered: true , windowClass: 'start-application-modal'});
  }

  createOrg() {
    if(!this.orgCompanyName || !this.orgEmailId 
      || !this.orgApiLimits.search || !this.orgApiLimits.pa 
      || !this.orgApiLimits.bc) {

      return;
    }

    this.modalService.dismissAll();
    const org = {
      email: this.orgEmailId.toLowerCase(),
      orgName: this.orgCompanyName,
      type: this.orgType,
      userName: "Admin",

      businessValuation: this.valuationType === "biz",
      impairmentValuation: this.valuationType === "imp",
      portfolioValuation: this.valuationType === "por",
      
      createdBy: this.us.getUserDetails().userId,
      searchApiLimit: this.orgApiLimits.search,
      bcApiLimit: this.orgApiLimits.bc,
      paApiLimit: this.orgApiLimits.pa,
    }

    this.orgLoading = true;

    this.us.createOrg(org).subscribe((success)=>{
      // console.log("org created", success);
    
      const newOrg = {
          id: success.body["response"].id,
          name: this.orgCompanyName,
          orgType: this.orgType,
          createdOn: moment(new Date()).format("ll"),
          createdBy: "73S-Admin"
      }

      let newArray = this.orgDataSource.data;
      newArray.push(newOrg);

      newArray = newArray.sort((o1, o2)=>{
        return new Date(o1.createdOn) < new Date(o2.createdOn) ? 1: -1;
      })

      this.orgList = newArray;

      this.orgDataSource = new MatTableDataSource<Org>(newArray);

      this.orgEditable = new Array(this.orgDataSource.data.length);

      this.prepareDataSource(this.orgDataSource, this.orgEditable, this.orgPaginator);

      this.orgCompanyName = "";
      this.orgEmailId = ""; 
      
      this.orgLoading = false;
      this.showMessage("Organization is successfully created.");
    }, (error)=>{
      console.log("Org creation failed.", error);
      this.orgLoading = false;
      this.showMessage("Organization creation failed. Please contact us.");
    })
  }

  prepareDataSource(dataSource, editable, paginator) {

    editable.forEach(() => {
      editable.push(false);
    })

    // this.sort.active = 'createdOn';
    // this.sort.direction = 'desc';

    dataSource.paginator = paginator;
    // dataSource.sort = this.sort;
  }

  clickViewOnOrg(orgId, orgName) {
    this.selectedOrg = orgName;
    this.selectedOrgId = orgId;

    this.resetAll();

    // this.us.getRoles(orgId).subscribe((roleSuccess)=>{
    //   console.log("roleSuccess", roleSuccess);
    //   const roles = roleSuccess.body["response"];
    //   this.roles = roles;
    //   this.loadRoles(roles);

    // }, error=>{
    //   console.log("Error in fetching roles", error);
    // })

    // this.us.getServices().subscribe((sData)=>{
    //   console.log("getServices", sData);
    //   this.services = sData.body["response"];
    // }, error=>{
    //   console.log("Error in fetching services", error);
    // })

    // this.us.getGroups(orgId).subscribe((groupSuccess)=>{
    //   console.log("groupSuccess", groupSuccess);
    //   const groups = groupSuccess.body["response"];
    //   this.groups = groups;

    //   this.loadGroups(groups);

    // }, error=>{
    //   console.log("Error in fetching groups", error);
    // })
    
    this.us.getUsers(orgId).subscribe((userSuccess)=>{
      // console.log("userSuccess", userSuccess);
      const users = userSuccess.body["response"];

      this.loadUsers(users, this.selectedOrg);

    }, error=>{
      console.log("Error in fetching users", error);
    })
  }

  resetAll() {
    const rolesData: Role[] = [];
    this.roleDataSource = new MatTableDataSource<Role>(rolesData);

    const groupData: Group[] = [];
    this.groupDataSource = new MatTableDataSource<Group>(groupData);

    const userData: User[] = [];
    this.userDataSource = new MatTableDataSource<User>(userData);
  }



  loadUsers(users, org) {
    const userData: User[] = [];

    users.forEach(user => {
      let r = {
        createdBy : user.createdBy? user.createdBy : '73S-Admin',
        id : user.id,
        userId: user.userId,
        name : user.userName,
        status: user.active? "Active" : "In-Active",
        createdOn : moment(user.createdDate).format("ll"),
        description: user.details ? user.details.description : "",
        groupIds: user.groupIds,
        organization: org
      }

      userData.push(r);
    });

    // console.log("users", userData);

    this.userDataSource = new MatTableDataSource<User>(userData);

    this.userEditable = new Array(this.userDataSource.data.length);
    this.prepareDataSource(this.userDataSource, this.userEditable, this.userPaginator);
  }

  orgTypeSelectionChange(event) {
    const filteredArray = this.orgList.filter(row=>{
      return row.orgType === event || row.orgType === 'ai73s'
    })

    this.loadOrgDatails(filteredArray);
  }

  roleRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt1') {
        
    }
  }

  groupRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt2') {
        
    }
  }

  userRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt3') {
        
    }
  }

  servicesToggle(event: MatSlideToggleChange, service) {
    // console.log('toggle', event.checked, service.id);
    service.checked = event.checked;
  }

  // matRoleSlideToggleChange(event: MatSlideToggleChange) {
  //   console.log('toggle', event.checked);
  // }

  // matGroupSlideToggleChange(event: MatSlideToggleChange) {
  //   console.log('toggle', event.checked);
  // }

  // matUserSlideToggleChange(event: MatSlideToggleChange) {
  //   console.log('toggle', event.checked);
  // }

  // roleInfo = {};
  // errorMsg = "";

  // createRole() {
  //   this.savingRole = true;
  //   if(this.roleInfo["id"]) {
  //     this.updateRole();
  //     return;
  //   }

  //   const role = this.getInputRole();
  //   console.log("Role create", role);

  //   this.us.create('role', role).subscribe((status)=>{
  //     console.log("Role success create", status);
  //     role["id"] = status.body["response"].id;
  //     this.refreshRoleDatasource(role);

  //     this.modalService.dismissAll();
  //   }, error =>{
  //     console.log("Failed to create Role", error);
  //     this.modalService.dismissAll();
  //   })
  // }

  // updateRole() {
  //   const role = this.getInputRole();
  //   console.log("Role update", role);

  //   this.us.update('role', role).subscribe((status)=>{
  //     console.log("Role success update", status);

  //     this.refreshRoleDatasource(role);
  //     this.modalService.dismissAll();
  //   }, error =>{
  //     console.log("Failed to update Role", error);
  //     this.modalService.dismissAll();
  //   })
  // }

  // refreshRoleDatasource(role) {    
  //     const rolesData = this.roleDataSource.data;
  //     let index = rolesData.findIndex(r => r.id === role.id);
  //     if(index >= 0) {
  //       rolesData.splice(index, 1);
  //       this.roles.splice(index, 1);
  //     }

  //     let r = {
  //       createdBy : role.createdBy? role.createdBy : '73S-Admin',
  //       id : role.id,
  //       name : role.roleName,
  //       status: role.active? "Active" : "In-Active",
  //       createdOn : moment(role.createdDate).format("ll"),
  //       description: role.details ? role.details.description : "",
  //       serviceIds: role.serviceIds
  //     }

  //     rolesData.push(r);
  //     this.roles.push(role);

  //     this.roleDataSource = new MatTableDataSource<Role>(rolesData);

  //     this.roleEditable = new Array(this.roleDataSource.data.length);
  //     this.prepareDataSource(this.roleDataSource, this.roleEditable);
  // }

  // openEditRole(role, content) {
  //   this.savingRole = false;

  //   this.services.forEach((service)=>{
  //     service.checked = role.serviceIds.includes(service.id) ? true : false;
  //   })

  //   this.roleInfo["id"] = role.id;
  //   this.roleInfo["name"] = role.name;
  //   this.roleInfo["description"] = role.description;
  //   this.roleInfo["active"] = role.status === "Active" ? true: false;
  //   this.roleInfo["createdDate"] = role.createdOn;
  //   this.roleInfo["createdBy"] = role.createdBy;
    
  //   console.log("edit role info", this.roleInfo, this.services);

  //   this.modalService.open(content, { centered: true , windowClass: 'create-new-role-modal'});
  // }

  // openCreateNewRole(content) {
  //   this.savingRole = false;
    
  //   this.roleInfo = {active : true};

  //   this.services.forEach((service)=>{
  //     service.checked = false;
  //   })
  //   this.modalService.open(content, { centered: true , windowClass: 'create-new-role-modal'});
  // }

  // getInputRole() {    
  //   let selectedServices = this.services.filter(service => service.checked === true);
  //   if(selectedServices) {
  //     selectedServices = selectedServices.map(service => service.id);
  //   } 

  //   const role = {
  //     createdDate: this.roleInfo["createdDate"]? moment(this.roleInfo["createdDate"]).format() : (new Date()),
  //     createdBy: this.roleInfo["createdBy"]? this.roleInfo["createdBy"] : '73S-Admin',
  //     roleName: this.roleInfo["name"],
  //     details: {description : this.roleInfo["description"]},
  //     active: this.roleInfo["active"],
  //     orgId: this.selectedOrgId,
  //     serviceIds: selectedServices
  //   }

  //   if(this.roleInfo["id"])   role["id"] = this.roleInfo["id"];

  //   return role;
  // }

  userInfo = {};

  createUser() {
    this.savingUser = true;
    
    if(this.userInfo["id"]) {
      this.updateUser();
      return;
    }

    const user = this.getInputUser();
    // console.log("User create", user);

    this.us.create('/user', user).subscribe((status)=>{
      // console.log("User success create", status);
      user["id"] = status.body["response"].id;
      this.refreshUserDatasource(user);

      this.modalService.dismissAll();
      this.showMessage("Successfully created the User.");
    }, error =>{
      console.log("Failed to create User", error);
      this.modalService.dismissAll();
      this.showMessage("Failed to create the User.");
    })
  }

  updateUser() {
    const user = this.getInputUser();
    // console.log("User update", user);

    this.us.update('/user', user).subscribe((status)=>{
      // console.log("User success update", status);

      this.refreshUserDatasource(user);
      this.modalService.dismissAll();
      this.showMessage("Successfully updated the User.");
    }, error =>{
      console.log("Failed to update User", error);
      this.modalService.dismissAll();
      this.showMessage("Failed to update User. Please contact us.");
    })
  }

  refreshUserDatasource(user) {    
      const usersData = this.userDataSource.data;
      let index = usersData.findIndex(r => r.id === user.id);
      if(index >= 0) {
        usersData.splice(index, 1);
      }

      let r = {
        createdBy : user.createdBy? user.createdBy : '73S-Admin',
        id : user.id,
        name : user.userName,
        userId: user.userId,
        status: user.active? "Active" : "In-Active",
        createdOn : moment(user.createdDate).format("ll"),
        description: user.details ? user.details.description : "",
        groupIds: user.groupIds,
        organization: this.selectedOrg
      }

      usersData.push(r);

      this.userDataSource = new MatTableDataSource<User>(usersData);

      this.userEditable = new Array(this.userDataSource.data.length);
      this.prepareDataSource(this.userDataSource, this.userEditable, this.userPaginator);
  }

  openEditUser(user, content) {
    this.savingUser = false;
    
    this.groups.forEach((group)=>{
      group.checked = user.groupIds.includes(group.id) ? true : false;
    })

    this.userInfo["id"] = user.id;
    this.userInfo["userId"] = (user.userId).toLowerCase().trim();
    this.userInfo["name"] = user.name;
    this.userInfo["description"] = user.description;
    this.userInfo["active"] = user.status === "Active" ? true: false;
    this.userInfo["createdDate"] = user.createdOn;
    this.userInfo["createdBy"] = user.createdBy;
    
    // console.log("edit user info", this.userInfo);

    this.modalService.open(content, { centered: true , windowClass: 'create-new-user-modal'});
  }

  openCreateNewUser(content) {
    this.savingUser = false;

    this.userInfo = {active: true};

    this.groups.forEach((group)=>{
      group.checked = false;
    })
    this.modalService.open(content, { centered: true , windowClass: 'create-new-user-modal'});
  }

  getInputUser() {    
    let selectedGroups = this.groups.filter(group => group.checked === true);
    if(selectedGroups) {
      selectedGroups = selectedGroups.map(group => group.id);
    } 

    const user = {
      createdDate: this.userInfo["createdDate"]? moment(this.userInfo["createdDate"]).format() : (new Date()),
      createdBy: this.userInfo["createdBy"]? this.userInfo["createdBy"] : this.us.getUserDetails().userId,
      userName: this.userInfo["name"],
      userId : (this.userInfo["userId"]).toLowerCase().trim(),
      details: {description : this.userInfo["description"]},
      active: this.userInfo["active"],
      orgId: this.selectedOrgId,
      groupIds: selectedGroups
    }

    if(this.userInfo["id"])   user["id"] = this.userInfo["id"];

    return user;
  }

  showMessage(msg) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessagePopupComponent, {        
      data : {
        okButtonMsg: "Ok",
        dialogMsg: msg
      }
    });
  }
}

export interface Org {
  id: string;
  name: string;
  orgType: string;
  createdOn: string;
  createdBy: string;
}

const ORG_DATA : Org[] = [];

const ROLE_ELEMENT_DATA: Role[] = [];

const GROUP_ELEMENT_DATA: Group[] = []

const USER_ELEMENT_DATA: User[] = []