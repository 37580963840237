<!-- <div class="white">
  <div class=" box-shadow mx-4">
    <ejs-accordion>
      <e-accordionitems>
        <e-accordionitem>
          <ng-template #header>
            <div class="font-20">Master Data Management</div>
          </ng-template>
          <ng-template #content>
            <div class="d-flex justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <input type="checkbox" (change)="selectAll($event,'data')" class="font mr-1" />
                <span>Select All</span>
              </div>
              <div class="icon-pd">
                <i class="fas fa-file-download  font-20 color-primary"></i>
                <i class="fas fa-download font-20 color-primary"></i>
                <i class="fas fa-trash font-20 color-primary"></i>
                <button class="btn-s" (click)="add($event)">Add</button>
              </div>
            </div>

            <div class="data-management-grid">
              <ejs-grid #grid [dataSource]='listMasterData' [allowPaging]="true" [pageSettings]="gridPageSettings"
                [columns]='columns' [editSettings]='editSettings' [toolbar]='toolbar'
                (actionBegin)="actionBegin($event)">
              </ejs-grid>
            </div>
            <div class="d-flex justify-content-end m-3">

            </div>
          </ng-template>
        </e-accordionitem>
      </e-accordionitems>
    </ejs-accordion>
  </div> -->

  <div class="white">
    <div class="box-shadow mx-4">
      <ejs-accordion>
        <e-accordionitems>
          <e-accordionitem>
            <ng-template #header>
              <div class="font-20">{{ translateService.getLabel("master_data_management") }}</div>
            </ng-template>
            <ng-template #content>
              <div class="d-flex justify-content-between mb-3">
                <div class="d-flex align-items-center">
                  <input type="checkbox" (change)="selectAll($event,'data')" class="font mr-1" />
                  <span>{{ translateService.getLabel("select_all") }}</span>
                </div>
                <div class="icon-pd">
                  <i class="fas fa-file-download  font-20 color-primary"></i>
                  <i class="fas fa-download font-20 color-primary"></i>
                  <i class="fas fa-trash font-20 color-primary"></i>
                  <button class="btn-s" (click)="addRow()" [disabled]="isEditing">{{ translateService.getLabel("add") }}</button>
                  <!-- <button class="btn-s" (click)="save()">Update</button> -->
                </div>
              </div>
              <!-- <p *ngIf="showErrorMessage"> Record not saved</p> -->
              <div class="table-custom-border">
                <table class="table" style="table-layout: fixed;">
                  <thead>
                    <tr class="table-custom-header">
                      <th style="width: 100px;">S/N</th>
                      <th style="width: 100px;">{{ translateService.getLabel("select") }}</th>
                      <th style="width: 185px;">{{ translateService.getLabel("client") }} {{ translateService.getLabel("company_name") }}</th>
                      <th style="width: 185px;">{{ translateService.getLabel("client") }} {{ translateService.getLabel("company_id") }}</th>
                      <th style="width: 150px;">{{ translateService.getLabel("client") }} {{ translateService.getLabel("security_id") }}</th>
                      <th style="width: 200px;">{{ translateService.getLabel("qubit") }} X {{ translateService.getLabel("company_name") }}</th>
                      <th style="width: 170px;">{{ translateService.getLabel("qubit") }} X {{ translateService.getLabel("company_id") }}</th>
                      <th style="width: 188px;">{{ translateService.getLabel("qubit") }} X {{ translateService.getLabel("security_name") }}</th>
                      <th style="width: 160px;">{{ translateService.getLabel("qubit") }} X {{ translateService.getLabel("security_id") }}</th>
                      <th style="width: 100px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let company of listMasterData; index as i;">
                      <tr class="table-custom-body">
                        <ng-container *ngIf="enableEditIndex != i">
                          <td>{{i + 1}}</td>
                          <td style="text-align: center;">
                            <mat-checkbox [disabled]="true" [(ngModel)]="company.verified"></mat-checkbox>
                          </td>
                          <td>{{company.clientCompanyName}}</td>
                          <td>{{company.clientCompanyId}}</td>
                          <td>{{company.clientSecurityId}}</td>
                          <td>{{company.qubitxCompanyName}}</td>
                          <td>{{company.qubitxCompanyId}}</td>
                          <td>{{company.qubitxSecurityName}}</td>
                          <td>{{company.qubitxSecurityId}}</td>
                          <td style="text-align: center;white-space: nowrap;" *ngIf="enableEditIndex != i">
                            <i role="button" class="fas fa-pen pointer" (click)="switchEditMode(i)"></i>
                            <i role="button" style="padding-left: 10px;" (click)="delete(company,i)"
                              class="fa fa-trash pointer"></i>
                            <!-- <button type="button" (click)="switchEditMode(i)">Edit</button> -->
                          </td>
                        </ng-container>
                        <ng-container *ngIf="enableEditIndex == i">
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="number" [(ngModel)]="company.id">
                            </mat-form-field>
                          </td>
                          <td style="text-align: center;">
                            <mat-checkbox [(ngModel)]="company.verified"></mat-checkbox>
                          </td>
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.clientCompanyName">
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.clientCompanyId" />
                            </mat-form-field>
                          </td>
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.clientSecurityId" />
                            </mat-form-field>
                          </td>
                          <td>
                            <!-- <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.qubitxCompanyName" />
                            </mat-form-field> -->

                            <ejs-dropdownlist class="sbp-input-field" [placeholder]="translateService.getLabel('select')" [dataSource]='companies'
                              [(ngModel)]="company.qubitxCompanyName" [fields]="fieldMap"
                              (change)="onCompanyChange($event, i)"></ejs-dropdownlist>
                          </td>
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.qubitxCompanyId" />
                            </mat-form-field>
                          </td>
                          <td>
                            <ejs-dropdownlist class="sbp-input-field" [placeholder]="translateService.getLabel('select')" [dataSource]='securities'
                              [(ngModel)]="company.qubitxSecurityName" [fields]="fieldMap1"
                              (change)="onSecurityChange($event,i)"></ejs-dropdownlist>
                          </td>
                          <td>
                            <mat-form-field class="custom-textbox">
                              <input matInput class="text" type="text" [(ngModel)]="company.qubitxSecurityId" />
                            </mat-form-field>
                          </td>


                          <td style="text-align: center; white-space: nowrap;">
                            <i role="button" class="fas fa-check pointer" (click)="update(company)"></i>
                            <i role="button" style="padding-left: 10px;" (click)="delete(company,i)"
                              class="fa fa-trash pointer"></i>
                            <!-- <button (click)="save()">Update</button></td> -->
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </e-accordionitem>
        </e-accordionitems>
      </ejs-accordion>
    </div>

    <div class="body box-shadow mx-4">
      <div class="title-page">{{ translateService.getLabel("file_data_management") }}</div>
      <div class="d-flex justify-content-between m-3">
        <div class="d-flex align-items-center">
          <span class="mr-3">{{ translateService.getLabel("file") }}</span>
          <input type="file" (change)="onFileSelected($event)" value="upload" id="fileUpload" hidden />
          <button class="btn-s" (click)="clkFileBtn()">{{ translateService.getLabel("upload") }}</button>
          <span class="ml-3">{{currentUploadFile?.name}}</span>
          <span class="ml-3">{{ translateService.getLabel("date") }}</span>
          <div class="ml-3 mtop-10">
            <ejs-datepicker [value]='dateValue'></ejs-datepicker>
          </div>
          <span class="ml-3"></span>
          <div class="m1-3 mtop-10" style="width: 18vw;">
            <ejs-dropdownlist id="ejs-dropdownlist_updateType" [dataSource]="updateTypeList" [(value)]="updateType">
            </ejs-dropdownlist>
          </div>
          <span class="ml-3"></span>
          <div class="m1-3 mtop-10" style="width: 12vw;">
            <ejs-dropdownlist id="ejs-dropdownlist_savedFiles" placeholder='List of Staged Files' [dataSource]="stagingFiles" [(value)]="selectedStagingFile">
            </ejs-dropdownlist>
          </div>
        </div>

        <div>
          <i class="fas fa-file-download font-20 color-primary"></i>
          <button class="btn-color ml-3" (click)="processFileUpload()">{{ translateService.getLabel("process") }}</button>
        </div>
      </div>
      <div class="table table-condensed" id="container" class="data-management-grid px-0 ml-3 mr-3">
        <ejs-grid #summaryGrid [dataSource]="fileUploadList" [allowPaging]="true" [pageSettings]="gridPageSettings"
          [allowSelection]="true" (rowSelected)="fileSelected($event)">
          <e-columns>
            <e-column headerText='S/N' width='50px'>
              <ng-template #template let-comp>
                {{+comp.index + 1}}
              </ng-template>
            </e-column>
            <e-column field='fileName' headerText='{{ translateService.getLabel("file_name") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.fileName}}
              </ng-template>
            </e-column>
            <e-column field='fileUploadStatus' headerText='{{ translateService.getLabel("file_upload_status") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.fileUploadStatus}}
              </ng-template>
            </e-column>
            <e-column field='fileDateUploaded' headerText='{{ translateService.getLabel("date_uploaded") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.fileDateUploaded}}
              </ng-template>
            </e-column>
            <e-column field='view' headerText='{{ translateService.getLabel("view") }}' width='100px'>
              <ng-template #template let-comp>
                <i class="far fa-eye font-20 color-primary"></i>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="d-flex justify-content-end m-3">

      </div>
    </div>
    <div class="body box-shadow mx-4 py-3">
      <div class="d-flex justify-content-between m-3">
        <div class="d-flex align-items-center">
          <input [(ngModel)]="isSelectAll"  type="checkbox" (change)="selectAll($event,'arrayOfObj')" class="font mr-1" />
          <span>{{ translateService.getLabel("select_all") }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span *ngIf="selectedFile">{{ translateService.getLabel("contents_for") }} {{selectedFile?.fileName}}</span>
        </div>

        <div>
          <button class="btn-color" (click)="processFileProgress()">{{ translateService.getLabel("process") }}</button>
        </div>
      </div>
      <div class="table table-condensed mb-3" id="container" class="data-management-grid px-0 ml-3 mr-3">
        <ejs-grid #summaryGrid [allowPaging]="true" [allowSorting]='true' [pageSettings]="gridPageSettings"
          [dataSource]="dataManagementFileRows">
          <e-columns>
            <e-column headerText='S/N' width='50px'>
              <ng-template #template let-comp>
                {{+comp.index + 1}}
              </ng-template>
            </e-column>
            <e-column headerText='{{translateService.getLabel("select")}}' editType='checkboxedit' width='50px'>
              <ng-template #template let-data>
                <div>
                  <ejs-checkbox #checkbox [checked]='data.verified' (change)="onCheckChange($event, data)">
                  </ejs-checkbox>
                </div>
              </ng-template>
            </e-column>
            <e-column field='clientCompanyName' headerText='{{ translateService.getLabel("client") }} {{ translateService.getLabel("company_name") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.clientCompanyName}}
              </ng-template>
            </e-column>
            <e-column field='clientCompanyId' headerText='{{ translateService.getLabel("client") }} {{ translateService.getLabel("company_id") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.clientCompanyId}}
              </ng-template>
            </e-column>
            <e-column field='clientSecurityName' headerText='{{ translateService.getLabel("client") }} {{ translateService.getLabel("security_name") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.clientSecurityName}}
              </ng-template>
            </e-column>
            <e-column field='clientSecurityId' headerText='{{ translateService.getLabel("client") }} {{ translateService.getLabel("security_id") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.clientSecurityId}}
              </ng-template>
            </e-column>
            <e-column field='qubitxFundName' headerText='{{ translateService.getLabel("qubit") }} X_{{ translateService.getLabel("fund_name") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.qubitxFundName}}
              </ng-template>
            </e-column>
            <e-column field='status' headerText='{{ translateService.getLabel("status") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.status}}
              </ng-template>
            </e-column>
            <e-column field='error' headerText='{{ translateService.getLabel("error_details") }}' width='100px'>
              <ng-template #template let-comp>
                {{comp.error}}
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
