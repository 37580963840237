import { Component, OnInit, Input, OnChanges, ElementRef, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PortFolioService } from '../portfolio.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { clone, cloneDeep, isArray } from 'lodash';

import * as moment from 'moment';

import * as Highcharts from 'highcharts';

import { UserManagementService } from 'src/app/services/user-management.service';
import { CCMServiceV3 } from '../valuation-algorithms/ccm-new-v3.service';
import { ValuationAlgorithmService } from '../valuation-algorithms/valuation-algorithms.service';
import { UtilService } from 'src/app/utils/util.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { colors } from 'src/app/utils/colors';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ThemeService } from 'src/app/utils/theme.service';

@Component({
  selector: 'app-investment-page-summary',
  templateUrl: './investment-page-summary.component.html',
  styleUrls: ['./investment-page-summary.component.scss']
})
export class InvestmentPageSummaryComponent implements OnInit, OnChanges {
  @Input() formData;
  @Input() buildUpSaved = false;

  @Output() consolFormSelected = new EventEmitter();

  @ViewChild("businessUnitPopup", {static: true}) businessUnitPopup: TemplateRef<any>;
  selectedBusinessUnits = [];
  
  companyDescription = "";
  companySector = "";
  countryName = "";
  
  bubbleChartData: any
  waterFallChartData: any
  areaChart
  impactCapitalExpDescription
  impactRevenueDescription
  impactMarginExpDescription
  plannedData
  selectedCompanyDates;
  logoLink
  fundCompanyDetails:any
  isEZ = false;

  valueBridgeModelClone;

  masterPartnersList = [];
  
  exitStatus="CURRENT PORTFOLIO";

  compPerformanceNumbers = { rows: [], headers: [], aggregations: {}};

  transactionsForCompany = {
    multipleInvestmentAmount : [],
    multipleRealisedProceeds : [],
    initialInvestmentAmount: 0,
    remainingInvestmentAmount: 0,
    totalInvestmentAmount: 0,
    totalRealisedProceeds: 0,
    moic : 0,
    IRR : 0

  };

  latestValData;
  latestValuationDate;
  latestValuationYear;

  valuationAlgoData

  investmentDateCCM;
  valuationDateCCM;

  areaChartData = {
    thirdQuartile: [],
    firstQuartile: [],
    median: [],
    average: [],
    subjectComp: [],
    categories: [],
    revenue: [],
    ebitda: [],
    ebitdaMargin: []
  }

  marketEvolutionChartData = {}

  multipleChartShowLabels = false;

  areaChartLoader = true;
  marketDataResponse;

  marketMultiple = "bevRevenue";
  buMarketMultiple;

  revenue = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  grossProfit = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }
  
  gpMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitda = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitdaMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebit = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  netDebt = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  cashBalance = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  realisedProceeds = {
    date:'',
    value: 0
  }

  ccmImpliedMultiplesExits = {
    psales: false,
    pe: false,
    pbv: false
  }

  summaryNumbers = {} as any;

  waterFallNumbers = [];

  waterFallGraphShowLabels = false;

  multipleRealisedProceeds = [];

  multipleInvestmentAmount = [];

  latestFormData;

  userSelectedCurrency;

  currencyExchange = {
    exchangeRate: 1,
    convertedValue: 0
  }


  investmentAmount = {
    date:'',
    value: 0
  }

  selectedIndex;
  date: null;
  value : 0;

  orgId;

  savedData = {
    comments: [{title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}],
    significantEventMarket: "",
    significantEventCompany: "",
    performance: { revenue: {}, grossProfit: {}, gpMargin: {}, ebitda: {}, ebitdaMargin: {}, ebit: {}, ebitMargin: {} },
    partners: [],
    fullyDilutedOwnership : 0,
    securityType: '',
    marketMultiple:"bevRevenue",
    valuationBridgeDates: [],

    trackRecordPreference: {
      period: "LTM",
      selectedMetrics: ["revenue", "ebitda"]
    }
  }

  valuationBarChart;
  showAllValdatesInChart = false;

  startDateValuationBridge = "";
  endDateValuationBridge = "";
  intermediateDateValuationBridge = ""

  startDateValuationBridgeClone = "";
  endDateValuationBridgeClone = "";
  intermediateDateValuationBridgeClone = ""

  startDateForm = {};
  endDateForm = {};
  intermediateDateForm = {};

  filteredForms = [];

  editCompanyDetails;

  sumOfInvestmentAmount = 0;

  showValueBridgePopUpLoader = false;

  actualPerformanceGraph;

  valDatesForUpperLimitSelection = [];
  valDateUpperLimit;

  dashboardRepresentation;
  grossProfitExistsInTrackRecord; 

  showAllValDatesinTrackRecord = false;

  actualPerformanceTableColumSet1 = [
    {
        field: 'values.0',
        headerText: 'Actual',
        width: 30,
        textAlign: 'Right',
        valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
        
    },
    {
        field: 'values.1',
        headerText: 'Budget',
        width: 35,
        textAlign: 'Right',
        valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
        
    },
    {
        field: 'values.2',
        headerText: 'Delta (%)',
        width: 40,
        textAlign: 'Right',
        valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
    },
  ];

  actualPerformanceTableColumSet2 =  [
      {
          field: 'values.3',
          headerText: 'Actual',
          width: 30,
          textAlign: 'Right',
          valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
      },
      {
          field: 'values.4',
          headerText: 'Budget',
          width: 35,
          textAlign: 'Right',
          valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
      },
      {
          field: 'values.5',
          headerText: 'Delta (%)',
          width: 40,
          textAlign: 'Right',
          valueAccessor: (field: string, data: any, column: object) => this.utilService.getFormattedNumber(data.values[field.split('.')[1]])
      },
  ];

  multipleValueBridgeData = [];

  valueBridgeName = "";

  selectedValueBridgeIndex = -1;

  isMultiValueBridge = false;


  multipleEvolutionFormWiseData = {}

  valueBridgeData;
  isDownloading;
  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

  @ViewChild('actualVsPlannedGrid', { static: false }) public actualVsPlannedGrid: GridComponent;

  constructor(public ccmService: CCMServiceV3,    public portfolioService: PortFolioService, 
              public utilService: UtilService,    private datePipe: DatePipe,
              private modalService:NgbModal,      private ds: DataService,
              public valuationAlgorithms: ValuationAlgorithmService,
              private currencyExchangeService : CurrencyExchangeService,
              public imageLoadService: ImageLoadService, private ums: UserManagementService, 
              private dataService: DataService,   public fundListService: FundListService,
              private themeService: ThemeService  
              ) 
  { }

  async ngOnInit() { 
    this.isEZ = this.ums.isEZUser();   
    this.portfolioService.fundDetails = null;
    
    try {
      const userOrgDetailsAPI = await this.dataService.getWidgetDataFromDB("USER_ORG_DETAILS", this.portfolioService.companyId).toPromise();        
      if(userOrgDetailsAPI && userOrgDetailsAPI.body["response"][0]["widgetData"]) {          
        this.portfolioService.fundDetails  = userOrgDetailsAPI.body["response"][0]["widgetData"];
      }
    }
    catch(error) {
        console.log("Failed to load Saved Company Details Data.", error);
    }
    
    this.orgId = this.ums.getSelectedUserDetails().organization.id;

    this.ds.getWidgetDataFromDB("PARTNER_MASTER_LIST", this.orgId).subscribe(res => {
      this.masterPartnersList = res["body"]["response"][0].widgetData;
    })

    try {
      const invSummaryRes = await this.ds.getWidgetDataFromDB("INVESTMENT_SUMMARY", this.portfolioService.companyId).toPromise();
      
      this.savedData = invSummaryRes.body["response"][0].widgetData;

      //Selected market multiple - Multiple evolution chart
      this.marketMultiple = this.savedData.marketMultiple;

      if(!this.savedData.trackRecordPreference) {
        this.savedData.trackRecordPreference = {
          period: "LTM",
          selectedMetrics: ["revenue", "ebitda"]
        }
      }

      // this.savedData.valuationBridgeDates = []

      // Actual Vs Performance Data
      if(this.savedData["actualPerformance"]) {
        this.actualPerformanceDataModel = this.savedData["actualPerformance"];
        this.createActualPerformanceGraph();
      }
      
      this.portfolioService.fullyDilutedOwnership = this.savedData.fullyDilutedOwnership;
      this.portfolioService.securityType = this.savedData.securityType;
      const partnerNames = this.masterPartnersList.map( m => m.name.toLowerCase());

      this.savedData.partners = this.savedData.partners.filter( s => {
        return partnerNames.indexOf(s.name.toLowerCase()) >= 0
      })

      this.masterPartnersList.forEach(m => {
        m.isSelected = false;
        this.savedData.partners.forEach( s => {
          if(s.name.toLowerCase() === m.name.toLowerCase()){
            m.isSelected = s.isSelected;
          }
        })
      })
    } catch(e) {
      this.masterPartnersList.forEach( m => {
        if(m.isSelected){
          m.isSelected = !m.isSelected;
        }
      })
      console.log("Failed to fetch Investment Summary details", e)
    }
    
    this.ds.getformDetails(this.portfolioService.companyId).subscribe(res=>{
      const formDetails = res.body['response'].details;
      this.portfolioService.formDetailsObj = JSON.parse(formDetails);
      // this.calculateInvestmentAmount();
    }, error =>{
      console.log("Failed to fetch the selected form id details", error)
    })

    this.getSelectedCompany(this.portfolioService.companyId);
    this.latestValData = this.portfolioService.portfolioData.filter(comp => comp.id && comp.id == this.portfolioService.companyId);
    
    this.exitStatus = this.portfolioService.selectedForm[0].exitStatus;
    
    this.initTrasactionsForCompany();
    // console.log("Investment date VAl DATA", this.latestValData)

    if(!this.portfolioService.fundDetails) {
      this.portfolioService.getSelectedCompanyOrgDetails(this.utilService.getDomainName(this.portfolioService.selectedForm[0].website), this.companyDescription, this.latestValData[0]);
    }

    //All Valuation Dates created for this company
    this.selectedCompanyDates = this.portfolioService.getSelectedCompanyDates( this.portfolioService.companyId );

    let valuatedForms = this.selectedCompanyDates.filter( comp => !comp.userEntered && comp.status !== "Initiated")
    //sorted in ascending order of valuation dates;
    this.filteredForms = valuatedForms.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    })


    if(this.selectedCompanyDates.length > 1){
      this.selectedCompanyDates = this.selectedCompanyDates.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      });
    }

    //Allow user to select upper limit only till investment date
    const allValuationDates = this.portfolioService.getCompanyAllValuationDates(this.portfolioService.companyId);

    const investmentDateIndex = allValuationDates.findIndex(comp => !comp.groupFormId);
    this.valDatesForUpperLimitSelection = allValuationDates.filter((comp, index) => index <= investmentDateIndex)
                      .map(comp => comp.valuationDate);

    this.valDateUpperLimit = this.selectedCompanyDates[0].valuationDate;
    // ----------------

    //Track Record Table
    this.initDashboardPresentation();
                      
    const latestForm = this.selectedCompanyDates.find(c => !c.userEntered);
    this.latestValuationDate = new Date(latestForm.valuationDate);
    this.latestValuationYear = this.latestValuationDate.getFullYear();

    // this.assignSubjectCompanyMultiples();
    
    this.prepareCharts();
    this.initBarChart(this.selectedCompanyDates);

    this.portfolioService.portfolioUpdated.subscribe(() => {
      //All Valuation Dates created for this company
      this.selectedCompanyDates = this.portfolioService.getSelectedCompanyDates( this.portfolioService.companyId );

      if(this.selectedCompanyDates.length > 1){
        this.selectedCompanyDates = this.selectedCompanyDates.sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
        });
      }

      //Allow user to select upper limit only till investment date
      const allValuationDates = this.portfolioService.getCompanyAllValuationDates(this.portfolioService.companyId);

      const investmentDateIndex = allValuationDates.findIndex(comp => !comp.groupFormId);
      this.valDatesForUpperLimitSelection = allValuationDates.filter((comp, index) => index <= investmentDateIndex)
                        .map(comp => comp.valuationDate);

      this.valDateUpperLimit = this.selectedCompanyDates[0].valuationDate;
      // ---------------------------------------

      this.initDashboardPresentation();

      this.prepareCharts();
      this.initBarChart(this.selectedCompanyDates);
    })
    
    this.actualPerformanceDataModel.header1[0] = "Second Quarter (" + this.datePipe.transform(this.latestValData[0].latestValuationDate, "mediumDate") + ")";
    this.actualPerformanceDataModel.header1[1] = "Year to date (" + this.datePipe.transform(this.latestValData[0].latestValuationDate, "mediumDate") + ")";
  }

  setExitStatus(){
    let inputObj = {
      companyId: this.portfolioService.companyId,
      exitStatus: this.exitStatus
    }
    
    this.dataService.setExitStatus(inputObj).subscribe(res=> {
      
      this.portfolioService.selectedForm[0].exitStatus = this.exitStatus;
      console.log("Exit status is saved");
    }, error => {
      console.log("Failed to save exit status", error);
      this.utilService.showMessage("Failed to save exit status. Please try again later.", "Ok", true);
    })
  }

  ngOnChanges() {    
    if(this.formData) {
      try {
        this.companyDescription = this.utilService.cleanString(this.formData.GENERAL.GD_General_Details.GD_BD_BIZ_DESCRIPTION);
      
        if(this.formData.GENERAL.GD_General_Details.GD_Industry) {
          this.companySector = this.formData.GENERAL.GD_General_Details.GD_Industry.split(",")[0]
        }

        if(this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME) {
          this.countryName = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.name;
        }

        this.getKeywords();
      } catch (error) {
        console.log("Error in formData", error);
      }
    }

    if(this.buildUpSaved){
      try {
        this.prepareValueBridgeChart();
      } catch (error) {
        console.log("Error in Loading VB", error);
      }
    }
  }

  initTrasactionsForCompany(){

    //sorting the transactions in ASC
    // this.transactionsForCompany.multipleRealisedProceeds = this.portfolioService.transactionData.filter( comp => {
    //   return this.latestValData[0].id == comp.companyId && comp.Type.toLowerCase() == "realised proceeds";
    // }).sort((f1, f2) => {
    //   const f1Date = new Date(f1.Date);
    //   const f2Date = new Date(f2.Date);
    //   return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    // });

    // //sorting the transactions in ASC
    // this.transactionsForCompany.multipleInvestmentAmount = this.portfolioService.transactionData.filter( comp => {
    //   return this.latestValData[0].id == comp.companyId && comp.Type.toLowerCase() == "investment";
    // }).sort((f1, f2) => {
    //   const f1Date = new Date(f1.Date);
    //   const f2Date = new Date(f2.Date);
    //   return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    // });

    const selectedFund = this.fundListService.getFundById(this.portfolioService.selectedFundId);

    const validTransactions = this.portfolioService.getValidTransactionsForSelectedFund(this.latestValData[0].id, selectedFund.name);

    this.transactionsForCompany.multipleRealisedProceeds = validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0 
    ? cloneDeep(validTransactions["multipleRealisedProceeds"]) : [] 

    //sorting the transactions in ASC
    // this.transactionsForCompany.multipleRealisedProceeds = this.portfolioService.transactionData.filter( comp => {
    //   return this.latestValData[0].id == comp.companyId && comp.Type.toLowerCase() == "realised proceeds"
    //             && comp.Buyer && comp.Buyer.toLowerCase() === selectedFund.name.toLowerCase();

    // }).sort((f1, f2) => {
    //   const f1Date = new Date(f1.Date);
    //   const f2Date = new Date(f2.Date);
    //   return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    // });

    this.transactionsForCompany.multipleInvestmentAmount = validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0 
    ? cloneDeep(validTransactions["multipleInvestmentAmount"]) : [] 

    //sorting the transactions in ASC
    // this.transactionsForCompany.multipleInvestmentAmount = this.portfolioService.transactionData.filter( comp => {
    //   return this.latestValData[0].id == comp.companyId && comp.Type.toLowerCase() == "investment"
    //             && comp.Buyer && comp.Buyer.toLowerCase() === selectedFund.name.toLowerCase();
    // }).sort((f1, f2) => {
    //   const f1Date = new Date(f1.Date);
    //   const f2Date = new Date(f2.Date);
    //   return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    // });

    //taking out the first investment
    this.transactionsForCompany.initialInvestmentAmount = this.transactionsForCompany.multipleInvestmentAmount[0] ? 
        this.transactionsForCompany.multipleInvestmentAmount[0].convertedValue : 0;

    //taking sum of all investments except the initial    
    let remainingInvestmentAmount = 0;
    this.transactionsForCompany.multipleInvestmentAmount.forEach( (comp, index) => {

      remainingInvestmentAmount += this.utilService.getValidNumber(comp.convertedValue); //already converted to valuation currency so not converting to portfolio currency as it is done on the UI;
      
    })
    this.transactionsForCompany.remainingInvestmentAmount = remainingInvestmentAmount;

    //all investments sum
    // this.transactionsForCompany.totalInvestmentAmount = this.transactionsForCompany.initialInvestmentAmount + this.transactionsForCompany.remainingInvestmentAmount;
    this.transactionsForCompany.totalInvestmentAmount = this.transactionsForCompany.remainingInvestmentAmount;

    //all realised proceeds
    let realisedProceeds = 0;
    this.transactionsForCompany.multipleRealisedProceeds.forEach(comp => {
      realisedProceeds += this.utilService.getValidNumber(comp.convertedValue); //already converted to valuation currency so not converting to portfolio currency as it is done on the UI;
    })
    this.transactionsForCompany.totalRealisedProceeds = realisedProceeds;

    const selectedCompanyDates = this.portfolioService.getSelectedCompanyDates(this.portfolioService.companyId);
    const latestValuationForm = selectedCompanyDates.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    }).find(f => f.status !== "Initiated" && !f.userEntered);
    
    let portfolioComp = this.portfolioService.portfolioData.find( comp => comp.id === this.portfolioService.companyId);

    const response = this.portfolioService.prepareMultiplesForIRRAndMoic(portfolioComp, latestValuationForm, false);
    this.transactionsForCompany.moic = response.moicBeforeConversion; // in Valuation Currency

    let IRR = 0;

    // console.log("-----------------", response);

    this.dataService.getIRR(response.reqBodyIRRForLocalCurrency).subscribe(apiResponse => {
      IRR = apiResponse.body["response"].data.complete_processed_data;
      response["IRR"] = IRR;
      this.transactionsForCompany.IRR = IRR;

      // console.log("T", this.transactionsForCompany)
    }, error => {
      console.log("Failed to calculated IRR for -" + portfolioComp.companyName);
      response["IRR"] = IRR;
      this.transactionsForCompany.IRR = IRR;
      console.log("T", this.transactionsForCompany)
    })
  }

  initDashboardPresentation() {
    let allFormIds = this.selectedCompanyDates.filter(f => f.id.indexOf("_S") < 0 && f.status !== 'Initiated').map(f => f.id);

    if(!allFormIds) return;
    
    if(allFormIds.length > 6 && !this.showAllValDatesinTrackRecord) {
      const latestFiveDates = allFormIds.slice(0, 5);
      allFormIds = latestFiveDates.concat([allFormIds[ allFormIds.length - 1]]);
    }

    const reqBody = {
      "fundId": this.portfolioService.selectedFundId,
      "formId": this.portfolioService.companyId,
      "valDateIds": allFormIds
    }

    this.dataService.getTrackRecord(reqBody).subscribe(res => {
      this.grossProfitExistsInTrackRecord = false;

      this.dashboardRepresentation = res.body["response"].sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? -1: 1;
      })

      this.dashboardRepresentation.forEach(row => {
        row["netDebt"] = row.equityValue - row.enterpriseValue;
        row["stakeEquityAdjustments"] = row.stakeAdjustedEquityValue - row.stakeValue;
        row["realisedProceeds"] = this.getRealisedProceeds(row.valuationDate, row.formId, true);
        row["investmentAmount"] = this.getInvestmentFromTransactions(row.valuationDate, row.formId, true);
        row["moic"] = this.getMoicForValutionDate(row["realisedProceeds"], row["investmentAmount"], row["stakeAdjustedEquityValue"]);
        row["stakeValueLP"] = this.getStakeValueLPFromInvestments(row)
        row["realisedProceedsLP"] = this.getRealisedProceeds(row.valuationDate, row.formId, false);
        row["investmentAmountLP"] = this.getInvestmentFromTransactions(row.valuationDate, row.formId, false);
        row["dpi"] = this.getDPI(row["realisedProceeds"], row["investmentAmount"]);
        row["moicLP"] = this.getMoicForValutionDate(row["realisedProceedsLP"], row["investmentAmountLP"], row["stakeValueLP"]);

        this.grossProfitExistsInTrackRecord = this.grossProfitExistsInTrackRecord || row.evGrossProfit;
        row.grossIRR = row.grossIRR;
      });
      this.assignSubjectCompanyMultiples();
    }, error => {
      console.log("ERROR: Failed to fetch all form data for Dashboard Presentation", error);
    })

    // this.dataService.getDashboardPresentation(allFormIds).subscribe(res => {
    //   // this.grossProfitExistsInTrackRecord = false;
    //   // console.log("all form data for Dashboard Presentation", res.body["response"]);

    //   // this.dashboardRepresentation = res.body["response"].sort((f1, f2) => {
    //   //   const f1Date = new Date(f1.valuationDate);
    //   //   const f2Date = new Date(f2.valuationDate);
    //   //   return f1Date === f2Date? 0: f1Date < f2Date? -1: 1;
    //   // })

    //   // this.dashboardRepresentation.forEach(row => {
    //   //   row["netDebt"] = row.equityValue - row.enterpriseValue;
    //   //   row["stakeEquityAdjustments"] = row.stakeAdjustedEquityValue - row.stakeValue;
    //   //   row["realisedProceeds"] = this.getRealisedProceeds(row.valuationDate, row.formId, true);
    //   //   row["investmentAmount"] = this.getInvestmentFromTransactions(row.valuationDate, row.formId, true);
    //   //   row["moic"] = this.getMoicForValutionDate(row["realisedProceeds"], row["investmentAmount"], row["stakeAdjustedEquityValue"]);
    //   //   row["stakeValueLP"] = this.getStakeValueLPFromInvestments(row)
    //   //   row["realisedProceedsLP"] = this.getRealisedProceeds(row.valuationDate, row.formId, false);
    //   //   row["investmentAmountLP"] = this.getInvestmentFromTransactions(row.valuationDate, row.formId, false);
    //   //   row["dpi"] = this.getDPI(row["realisedProceeds"], row["investmentAmount"]);
    //   //   row["moicLP"] = this.getMoicForValutionDate(row["realisedProceedsLP"], row["investmentAmountLP"], row["stakeValueLP"]);

    //   //   this.grossProfitExistsInTrackRecord = this.grossProfitExistsInTrackRecord || row.evGrossProfit;
    //   // });

    //   // this.assignSubjectCompanyMultiples();
      
    // }, error => {
    //   console.log("ERROR: Failed to fetch all form data for Dashboard Presentation", error);
    // })


  }

  showAllDatesInTrackRecord(){
    this.showAllValDatesinTrackRecord = !this.showAllValDatesinTrackRecord;
    this.initDashboardPresentation();
  }

  getDPI(realisedProceeds, investmentAmount){
    return investmentAmount > 0 ? realisedProceeds/investmentAmount : 0;
  }

  trackRecordPeriodChange() {

  }

  getSelectedTRMultiple(valuationDate, selectedMultiple, type) {
    const year = this.savedData.trackRecordPreference.period;
    switch(selectedMultiple) {
      case "revenue" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "bevRevenue", year, type);
      case "ebitda" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "bevEbitda", year, type);
      case "ebit" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "bevEbit", year, type);
      case "ebitda_capex" : 
      let ebitdaCapexMultiple = this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "evEbitda_Capex", year, type);
      if(ebitdaCapexMultiple == "NMF"){
        ebitdaCapexMultiple = this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "bevEbitda_Capex", year, type);
      }
      return ebitdaCapexMultiple;      
      case "priceEarnings" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "pe", year, type);
      case "priceBookValue" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "pbv", year, type);
      case "grossProfit" : return this.getSelectedTRMultipleValue(valuationDate.allAdjustedMetricsYearWise, "evGrossProfit", year, type);
    }
  }
  
  getSelectedTRMultipleValue(valuationDate, multipleKey, year, type) {
    try {
      let yearKey = "fy";
      
      if(year.indexOf("fy") >= 0) {
        yearKey = year;

      } else {
        const yearFromList = Object.keys(valuationDate[multipleKey]).find(y => valuationDate[multipleKey][y].year === year);
        
        yearKey = yearFromList;
      }

      if(type == "impliedMultiple") {
        if(multipleKey == "bevRevenue" && this.isEquityMultipleSelected()) {
          multipleKey = "psales";
        }
        return this.utilService.getDisplayFormattedNumber(valuationDate[multipleKey][yearKey][type], 1, 'x', 'NMF', true );
      }
      else {
        return this.utilService.getDisplayFormattedNumber(valuationDate[multipleKey][yearKey][type], 1, '', 'NMF', true );
      }
    } catch(e) {
      return "NMF";
    }
  }

  isEquityMultipleSelected() {
    const allEquityMultiples = ["priceEarnings", "priceBookValue"];
    
    let isEquityMultiple = false;
    
    allEquityMultiples.forEach(m => {
      isEquityMultiple = isEquityMultiple 
        || this.savedData.trackRecordPreference.selectedMetrics[0] == m
        || this.savedData.trackRecordPreference.selectedMetrics[1] == m
    })
    return isEquityMultiple;
  }

  getSelectedTRMultipleLabel(selectedMetric) {
    switch(selectedMetric) {
      case "revenue" : return "Revenue";
      case "ebitda" : return "EBITDA";
      case "ebit" : return "EBIT";
      case "ebitda_capex" : return "EBITDA-Capex";
      case "priceEarnings" : return "Net Income";
      case "priceBookValue" : return "Shareholder's Equity";
      case "grossProfit" : return "Gross Profit";
    }
  }

  getStakeValueLPFromInvestments(row){
    const selectedFund = this.fundListService.getFundById(this.portfolioService.selectedFundId).name;
    let totalStakeValueLP = 0;
    if(this.portfolioService.selectedListOfInvestors[row.formId] && this.portfolioService.selectedListOfInvestors[row.formId].length > 0){
      this.portfolioService.selectedListOfInvestors[row.formId].forEach( data => {
        if(data.investorName.toLowerCase() != selectedFund){
          totalStakeValueLP += (+data.stake/100) * (+row.equityValue)
        }
      })
    }

    return totalStakeValueLP;
  }

  getMoicForValutionDate(realisedProceeds, investmentAmount, stakeValue){
    let moic = 0;

    if(investmentAmount > 0){
      moic = (+realisedProceeds + +stakeValue)/ +investmentAmount;
    }

    return moic;
  }

  getRealisedProceeds(valuationDate, formId, isSelectedFund) {
    const valDate = new Date(valuationDate);

    let totalRealisedProceeds = 0;
    const selectedFund = this.fundListService.getFundById(this.portfolioService.selectedFundId).name;

    const validTransactions = this.portfolioService.getValidTransactionsForSelectedFund(this.latestValData[0].id, selectedFund); //to fetch transactions based on buyer and seller

    const multipleRealisedProceeds = validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0 
    ? cloneDeep(validTransactions["multipleRealisedProceeds"]) : [] 

    multipleRealisedProceeds.forEach( rp => {
      
      const valDate = new Date(valuationDate);
      
      const transDate = new Date(rp.Date);
      if(transDate > valDate){
        return;
      }

      if(this.portfolioService.selectedListOfInvestors[formId] && !isSelectedFund){
        const transactionFound = this.portfolioService.selectedListOfInvestors[formId].find( data => data.investorName.toLowerCase() !=  selectedFund.toLowerCase() &&  rp.Seller.toLowerCase() == data.investorName.toLowerCase())
        
        if(transactionFound){
          totalRealisedProceeds += +rp.convertedValue;
        }
      }
      else if(isSelectedFund) {//  if(this.portfolioService.selectedListOfInvestors[formId] && isSelectedFund){
        // const transactionFound = this.portfolioService.selectedListOfInvestors[formId].find( data => data.investorName.toLowerCase() ==  selectedFund.toLowerCase() &&  rp.Seller.toLowerCase() === selectedFund.toLowerCase())
        
        if(rp.Seller.toLowerCase() === selectedFund.toLowerCase()){
          totalRealisedProceeds += +rp.convertedValue;
        }
      }
    })

    return this.utilService.getValidNumber(totalRealisedProceeds);
  }

  getInvestmentFromTransactions(valuationDate, formId, isSelectedFund) {
    const selectedFund = this.fundListService.getFundById(this.portfolioService.selectedFundId).name;
    let totalInvestmentAmount = 0;

    const validTransactions = this.portfolioService.getValidTransactionsForSelectedFund(this.latestValData[0].id, selectedFund);

    const multipleInvestmentAmount =validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0 
    ? cloneDeep(validTransactions["multipleInvestmentAmount"]) : [] 
    
    multipleInvestmentAmount.forEach( inv => {
      const valDate = new Date(valuationDate);
      
      const transDate = new Date(inv.Date);
      if(transDate > valDate){
        return;
      }

      if(this.portfolioService.selectedListOfInvestors[formId] && !isSelectedFund){
        const transactionFound = this.portfolioService.selectedListOfInvestors[formId].find( data => data.investorName.toLowerCase() !=  selectedFund.toLowerCase() &&  inv.Buyer.toLowerCase() == data.investorName.toLowerCase())
        
        if(transactionFound){
          totalInvestmentAmount += +inv.convertedValue;
        }
      }
      else if(isSelectedFund) {// if(this.portfolioService.selectedListOfInvestors[formId] && isSelectedFund){
        // const transactionFound = this.portfolioService.selectedListOfInvestors[formId].find( data => data.investorName.toLowerCase() ==  selectedFund.toLowerCase() &&  inv.Buyer.toLowerCase() === selectedFund.toLowerCase())
        
        if(inv.Buyer.toLowerCase() === selectedFund.toLowerCase()){
          totalInvestmentAmount += +inv.convertedValue;
        }
      }
    })

    return totalInvestmentAmount;
  }

  remove(keyword, index){      

    this.portfolioService.orgKeyWords.splice(index, 1);
  }

  openValuation(comp) {
    if(comp.businessUnits) {
      this.modalService.dismissAll();
      this.consolFormSelected.emit(comp);
    } else {
      this.portfolioService.openValuation(comp);
    }
  }

  initBarChart(valuationDateForms) {
    const __this = this;

    // Plot all valuation dates
    // If status is submitted
    // If it has valuation numbers
    // If it has business units, then atleast one the BU shall be submitted
    valuationDateForms = valuationDateForms.filter(c => c.status !== 'Initiated' && c.investment && (!c.businessUnits || c.investment.enterpriseValue > 0));

    if(valuationDateForms.length > 6 && !this.showAllValdatesInChart){
      const latestFiveDates = valuationDateForms.slice(0, 5);
      valuationDateForms = latestFiveDates.concat([valuationDateForms[ valuationDateForms.length - 1]]);
    }
    
    const realisedProceeds = valuationDateForms.map((data) => { 
      const valDate = new Date(data.valuationDate);
      let totalRealisedProceeds = 0;

      __this.transactionsForCompany.multipleRealisedProceeds.forEach( rp => {
        const transDate = new Date(rp.Date);
        if(transDate <= valDate){
          totalRealisedProceeds += rp.convertedValue;
        }
      })

      return { 
        y: __this.utilService.getValidNumber(totalRealisedProceeds)
      }
    })

    this.valuationBarChart = new Chart({
      chart: { 
        type: 'bar'
      },
      title: { text: '' },
      xAxis: { 
        visible: true, 
        categories: valuationDateForms.map((data) => { return this.datePipe.transform(data.valuationDate, "MMMM yyyy") }) 
      },
      yAxis: { title: { text: '' }, visible: false },
      legend: { enabled: true },      
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              {
                text: __this.showAllValdatesInChart ? 'Hide All Valuation Dates' : 'Show All Valuation Dates',
                onclick: function () {
                  __this.showAllValdatesInChart = !__this.showAllValdatesInChart;
                  __this.initBarChart(__this.selectedCompanyDates);
                }
              } as any
            ]
          }
        }
      },
      credits: { enabled: false },
      tooltip: {
        enabled: true,
        formatter(event) {
          return this.point.series.name;
        }
      },
      plotOptions: {
        bar: {
          dataLabels: { 
            enabled: true, 
            formatter: function() { 
              if(this.y > 0) {
                const currency = __this.latestValData[0].currency ? __this.latestValData[0].currency : ""
                return  currency + " " + __this.utilService.getDisplayFormattedNumber(this.y, 1, "", "", false) + " Mn";
              }
            } 
          },
        },
        series: {
          cursor: 'pointer',
          stacking: 'normal',
          events: {
            click: (e) => {
              const comp = valuationDateForms[e.point["index"]];
              if(comp.businessUnits) {
                __this.selectedBusinessUnits = [comp].concat(comp.businessUnits);
                __this.modalService.open(__this.businessUnitPopup, { size: 'md', centered: true });
              } else {
                __this.portfolioService.openValuation(comp);
              }
            }
          }
        }
      },
      series: [
        { name: "NAV", 
          color: colors.primaryColor,
          data: valuationDateForms.map((data) => { 
            let stakeValue = data.investment.equityValue.finalStakeValue 
            if(!stakeValue) {
              stakeValue = data.investment.equityValue.stakeValue
            }
            return { 
              y: __this.utilService.getValidNumber(stakeValue), 
              color: ( data.id && data.id.indexOf("_S") < 0 ) 
                  ? colors.primaryColor  // SAF Forms
                  : colors.primaryColorLight // User added old valuation date
            }
          })
        },
        { name: "Realised Proceeds", 
          data: realisedProceeds,
          color: colors.secondaryColor
        },
      ] as any
    });
  }

  openModalPopUp(content, index){
    this.selectedIndex = index;
    this.modalService.open(content, {backdrop: 'static', size: 'lg', centered: true });
  }

  initBubbleChart(valuationData) {
    try {
      const chartValues = [];
      const similarCompanies = JSON.parse(valuationData[this.portfolioService.companyId].CCM).similarCompanies;

      similarCompanies.forEach(comp => {
        const marketCap = comp.market_cap ? +comp.market_cap : 0;

        if(+comp.bevRevenue[0] > 0 && +comp.bevRevenue[0] < 500 && +comp.bevEbitda[0] > 0 && +comp.bevEbitda[0] < 500) {
          chartValues.push([
            +comp.bevRevenue[0], +comp.bevEbitda[0], marketCap
          ])
        } else {
          // console.log("No Bubble ", comp.company_name, comp.bevRevenue[0], comp.bevEbitda[0]);
        }
      });

      const __this = this;

      this.bubbleChartData = new Chart({
        chart: {
          type: 'bubble', zoomType: 'xy', height: 300
        },
        title: { text: '' },
        credits: { enabled: false },
        exporting: { enabled: false },
        tooltip : {
          formatter: function() {
            const compName = similarCompanies[this.point["index"]].company_name;

            return '<b>' + compName + '</b>' +
              '<br><b>EBITDA Multiple (x)</b> : ' + this.y.toLocaleString() + 
              '<br><b>Revenue Multiple (x)</b> : ' + this.x.toLocaleString() +
              '<br><b>Market Cap ('+ __this.latestValData[0].currency +' Mn)</b> : ' + this.point["z"].toLocaleString();
          }
        },
        xAxis: {
          title:{ text: 'Revenue Multiple' },
          gridLineWidth: 1,
          gridLineColor: '#BEBEBE',

          labels: { formatter: function () { return  this.value + ' x' } }
        },
        yAxis: {
          title:{ text: 'EBITDA Multiple' },
          gridLineWidth: 1,
          gridLineColor: '#BEBEBE',

          labels: { formatter: function () { return  this.value + ' x' } }
        },
        series: [
          {
            data: chartValues,
            name: "Market Cap ("+ __this.latestValData[0].currency +" Mn)"
          }
        ] as any
      })
    } catch (e) {
      console.log("Error while creating bubble graph")
    }
  }

  getCCMComparableCompanies() {
    
    const compPerformanceNumbers = {};
    const headers = [];
    const aggregations = { average: [], median: [], q1: [], q3: []}

    let allDates: string [] = Object.keys(this.valuationAlgoData);
    allDates = allDates.reverse();

    allDates.forEach( (valDateId, valDateIndex) => {
      const selectedForm = this.portfolioService.companies.find(f => f.id === valDateId);
      
      headers.push(selectedForm.valuationDate);

      if(this.valuationAlgoData[valDateId].CCM){
        const ccmAlgoSimilarCompanies = JSON.parse(this.valuationAlgoData[valDateId].CCM).similarCompanies.filter(c => !c.disabled);

        ccmAlgoSimilarCompanies.forEach( comp => {
          if(!compPerformanceNumbers[comp.ticker]) {
            compPerformanceNumbers[comp.ticker] = [comp.company_name];
          }

          for(let i = (compPerformanceNumbers[comp.ticker].length - 1); i<valDateIndex; i++) {
            compPerformanceNumbers[comp.ticker].push(0);
          }

          compPerformanceNumbers[comp.ticker].push(comp['bevRevenue'][0]);
        })

        const aggrValues = JSON.parse(this.valuationAlgoData[valDateId].CCM).multipleType.bevRevenue.aggregations;
        aggregations.average.push(aggrValues.average[0])
        aggregations.median.push(aggrValues.median[0])
        aggregations.q1.push(aggrValues.q1[0])
        aggregations.q3.push(aggrValues.q3[0])
      }
    })

    this.compPerformanceNumbers.rows = Object.values(compPerformanceNumbers).sort( (r1: any, r2: any) =>{
      const r1ValidNumbers = r1.filter( v => v > 0 );
      const r2ValidNumbers = r2.filter( v => v > 0 );

      if(r1ValidNumbers.length === allDates.length) return -1;
      if(r2ValidNumbers.length === allDates.length) return 1;
      return 0;
    });

    this.compPerformanceNumbers.aggregations = aggregations;
    this.compPerformanceNumbers.headers = headers;

    this.summaryNumbers.bevRevenue = aggregations.median[aggregations.median.length - 1]
  }

  getSelectedCompany(companyId) {
    this.portfolioService.selectedForm = this.portfolioService.companies.filter(comp => comp.id == companyId);
    // console.log("selected form", this.portfolioService.selectedForm)
  }

  initwaterFallChart(waterFallGraphShowLabels?) {
    const waterFallChartData = new Chart({
      chart: {
        type: 'waterfall',
        // backgroundColor: "#e6e6e6",
        // events: { 
        //   load: function() {
        //     this.renderer.text('Under Construction', 300, 100)
        //       .css({
        //           color: '#5FAFF2',
        //           fontSize: '20px'
        //       })
        //       .add()
        //   }
        // }        
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend:{ enabled: false},

      xAxis: {
        type: 'category',
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      yAxis: {
        title: { text: '' },
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      plotOptions: {
        series: { 
          stacking: 'normal'
        },
        waterfall: {
          negativeColor: colors.secondaryColor,
      
          dataLabels: {
              enabled: waterFallGraphShowLabels && waterFallGraphShowLabels == true ? true : false,
              formatter: function () {
                  return Highcharts.numberFormat(this.y, 0, ',') + 'Mn';
              },
              verticalAlign: 'top',
              x: 3,
              y: -30,
              color: "black",
              
              style: { textOutline: 'none' }      
          }
        }
      },
      tooltip: {
        formatter: function () {
            return '<b>' + this.point.name + '</b> : ' + this.y.toLocaleString();
        }
      },
      series: [{
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: [],
        pointPadding: 0
      } as any]
    })

    // console.log("waterfall", this.waterFallNumbers, this.waterFallChartData)

    return waterFallChartData;
  }

  refreshWaterfallGraph(index) {
    
    this.preparedValueBridge = false;

    if(this.multipleValueBridgeData[index].waterFallChartData){
      this.multipleValueBridgeData[index].waterFallChartData.destroy();
    }

    this.valueBridgeModel = cloneDeep(this.multipleValueBridgeData[index].valueBridgeModel);
    const waterFallChartData = this.drawValuationBridge(this.multipleValueBridgeData[index].waterFallGraphShowLabels)

    if(this.multipleValueBridgeData[index]){
      this.multipleValueBridgeData[index].waterFallChartData = cloneDeep(waterFallChartData);
      // this.multipleValueBridgeData[index].waterFallNumbers = cloneDeep(this.waterFallNumbers);
      // this.multipleValueBridgeData[index].valueBridgeModel = cloneDeep(this.valueBridgeModel)
    }

    // setTimeout(() => {
    //   // add data to chart.
    //   waterFallChartData.addSeries({
    //     upColor: "#5FAFF2",
    //     color: "#5FAFF2",
    //     data: cloneDeep(this.multipleValueBridgeData[index].waterFallNumbers),
    //     pointPadding: 0
    //   } as any, true, false);

    //   setTimeout(() => {
    //     this.multipleValueBridgeData[index].waterFallChartData = cloneDeep(waterFallChartData)
    //     this.preparedValueBridge = true; 
    //   });
    // });
  }

  toggleChartDataLabels(chartObject) {
    chartObject.ref.options.plotOptions.series.dataLabels.enabled = true;   
    chartObject.ref.options.plotOptions.series.dataLabels.enabled = true;   
    // chartObject.yAxis[0].isDirty = true;
    // chartObject.redraw();

    this.areaChart = cloneDeep(this.areaChart); 
  }

  getMockNumbers() {
    const waterFallNumbers = [];
    const currentCompanyDate = this.portfolioService.companies.filter(comp=> comp.id === this.latestValData[0].id);
    const val1 = this.latestValData[0].valuation;
    const val2 = this.latestValData[0].fairVal;

    waterFallNumbers.push({name: moment(this.latestValData[0].investmentDate).format("MMM DD, YYYY"), y: this.latestValData[0].valuation})
    // waterFallNumbers.push({name: 'Balance Sheet', y: (val2 - val1)* 0.408})
    // waterFallNumbers.push({name: 'Multiples', y: (val2 - val1)* 1.266 })
    // waterFallNumbers.push({name: 'Control Premium', y: (val2 - val1)* 1.853 })
    // waterFallNumbers.push({name: 'Multiplication Acquisition Factor', y: (val2 - val1)* 1.847 * -1})
    // waterFallNumbers.push({name: 'General Inputs', y: (val2 - val1)* 0.339})
    // waterFallNumbers.push({name: 'DCF Assumptions', y: (val2 - val1)* 1.069 * -1 })
    // waterFallNumbers.push({name: 'Others', y: (val2 - val1)* 0.05})
    // waterFallNumbers.push({name: 'Multiplication Acquisition Factor', y: (val2 - val1)* 0.45})
    // waterFallNumbers.push({name: 'Weights', y: (val2 - val1)* 0.20 * -1 })
    // waterFallNumbers.push({name: 'Merger & Acquisition', y: (val2 - val1)* 0.30 })
    // waterFallNumbers.push({name: 'General Inputs', y: (val2 - val1)* 0.30 })
    // waterFallNumbers.push({name: 'DCF Assumptions', y: (val2 - val1)* 0.14 })
    waterFallNumbers.push({name: 'Revenue Growth', y: 323 })
    waterFallNumbers.push({name: 'EBITDA Margin', y:  647 })
    waterFallNumbers.push({name: 'Net Debt Reduction', y: 152 })
    waterFallNumbers.push({name: 'Multiple Expansion', y: 1045 })
    waterFallNumbers.push({name: 'Change In Discount Rate & TVM', y: 334 })
    waterFallNumbers.push({name: 'Gross Equity Value', isIntermediateSum: true })
    waterFallNumbers.push({name: 'FX Impact', y: -436})
    waterFallNumbers.push({name: 'Other', y: 698 })
    waterFallNumbers.push({name: moment(this.latestValData[0].latestValuationDate).format("MMM DD, YYYY"), y: this.latestValData[0].fairVal - 567, isSum: true })
    return waterFallNumbers;
  }

  createMarketMultipleChart(userSelected) {
    // console.log("Plotting area chart", userSelected);
    let subjectCompanyName = this.selectedCompanyDates[this.selectedCompanyDates.length - 1].companyNameInForm;

    if(this.selectedCompanyDates[this.selectedCompanyDates.length - 1].businessUnits) {
      subjectCompanyName = this.selectedCompanyDates[this.selectedCompanyDates.length - 1].businessUnits.find(bu => bu.businessUnitName === this.buMarketMultiple).companyNameInForm;
    } 

    const xAxis = cloneDeep(this.areaChartData[userSelected].categories);
    const median = cloneDeep(this.areaChartData[userSelected].median);
    const average = cloneDeep(this.areaChartData[userSelected].average);
    const subjectComp = cloneDeep(this.areaChartData[userSelected].subjectComp);
    
    const thirdQuartile = cloneDeep(this.areaChartData[userSelected].thirdQuartile).reverse();
    const firstQuartile = cloneDeep(this.areaChartData[userSelected].firstQuartile).reverse();

    let grapHPlacement = "on";

    if(xAxis.length <= 1) {
      grapHPlacement = "between";
    }

    // Below code makes sure that legend icon color is picked from chart options
    // This will ensure "1st qurtile" legend has same color as "3rd quartile" legend
    (function (H) {
      H.wrap(H.Legend.prototype, 'colorizeItem', function (proceed, item, visible) {
        item.legendColor = item.options.legendColor;
          proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      });
    }(Highcharts));

    this.areaChart = new Chart({
      chart: { type: 'area', height: 340 }, //  backgroundColor: "#e6e6e6",
      title: { text: '' },
      legend:{ layout: 'horizontal' },
      xAxis: { categories: xAxis.reverse(), gridLineWidth: 1, gridLineColor: '#BEBEBE' },
      yAxis: { title:{ text: ''} , labels: { format: '{value}x' }, gridLineWidth: 1, gridLineColor: '#BEBEBE'},
      tooltip: { 
        enabled: true,           

        formatter: function() { 
          if(this.y > 0) {
            const yValue = (+this.y);

            if(this.series.name === "(Upper Bound) - 3rd Quartile") {
              const pointIndex = this.point['index'];
              return  this.series.name + ": " + ((yValue + firstQuartile[pointIndex] ).toFixed(1)).toLocaleString() +"x";

            } else {
              return  this.series.name + ": " + (yValue.toFixed(1)).toLocaleString() +"x";
            }
          }
        } 
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          stacking: 'normal', pointStart: 0,
          marker: {
            enabled: false, symbol: 'circle', radius: 2,
            states: { hover: { enabled: true } }
          }
        },
        series: {
          // events: {
          //     legendItemClick: function() {
          //       return false;
          //     }
          // },

          pointPlacement: grapHPlacement,

          dataLabels: { 
            enabled: this.multipleChartShowLabels, 
            style: { textOutline: 'none' }, 
            color: '#000000',           
        

            formatter: function() { 
              if(this.y > 0) {
                const yValue = (+this.y);

                if(this.series.name === "(Upper Bound) - 3rd Quartile") {
                  const pointIndex = this.point['index'];
                  return  ((yValue + firstQuartile[pointIndex] ).toFixed(1)).toLocaleString() +"x";

                } else {
                  return  (yValue.toFixed(1)).toLocaleString() +"x";
                }
              }
            } 
          }
        }
      },
      exporting: { enabled: false },
      series: [{
        name: "(Upper Bound) - 3rd Quartile",
        data: thirdQuartile.map((num, i) => num - firstQuartile[i]),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q3[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q3[1]],
        type: 'area',
        color: '#EEFFC6',
      }, {
        name: '(Lower Bound) - 1st Quartile',
        data: firstQuartile,
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q1[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q1[1]],
        type: "area",
        legendColor: '#EEFFC6',
        color: "transparent"
      } as any, {
        name: ' Median',
        type: "spline",
        data: median.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.median[1],this.valuationDateCCM.multipleType[userSelected].aggregations.median[1]],
        color: "#5FAFF2"

      }, {
        name: ' Average',
        type: "spline",
        data: average.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.average[1],this.valuationDateCCM.multipleType[userSelected].aggregations.average[1]],
        color: "#FFD32B"

      }, {
        name: subjectCompanyName,
        type: "spline",
        data: subjectComp.reverse(),
        // data: [this.investmentDateCCM.subjectCompany[userSelected][1], this.valuationDateCCM.subjectCompany[userSelected][1]],
        color: this.themeService.primaryColor
      }]
    });
  }

  openPopUpModel(content) {    
    this.modalService.open(content, { centered: true, size: 'lg' })
  }

  async getCurrencyExchange(value, userSelCurr, srcCurr, valuationDate){
    if(!userSelCurr){
      userSelCurr = srcCurr
    }
    let conversionMultiple = 1;
    if(this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate]){
      conversionMultiple = this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate];
    }
    else{
      try {
        const currConverter = await this.ds.getValuationDateCurrency(userSelCurr, srcCurr, valuationDate).toPromise();
        conversionMultiple = currConverter.body['response'].value;
        this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate] = conversionMultiple
      } 
      catch (error) {
        console.log("Error while fetching exchange rates", error)
      }
    }
    this.currencyExchange.exchangeRate = conversionMultiple;
    this.currencyExchange.convertedValue = value * conversionMultiple;
  }

  currencyMultiple(converter){
    this.value = this.value? this.value : 0;
    this.userSelectedCurrency = converter.userSelectedCurrency
    this.currencyExchange.exchangeRate = converter.conversionMultiple;
    this.currencyExchange.convertedValue = this.value * converter.conversionMultiple;
  }

  addRealisedProceeds(){
    if( this.date && this.value){
      const realisedProceedsObj = {
        date: '',
        value: 0,
        sourceCurrency: this.latestValData[0].currency,
        userSelectedCurrency: this.userSelectedCurrency ? this.userSelectedCurrency : this.latestValData[0].currency,
        exchangeRate: this.currencyExchange.exchangeRate,
        valueBeforeConversion: this.value,
        exchangeValue: this.currencyExchange.convertedValue,
      }
      realisedProceedsObj.date = this.date
      realisedProceedsObj.value = this.currencyExchange.convertedValue
  
      if(!this.portfolioService.formDetailsObj.multipleRealisedProceeds){
        this.portfolioService.formDetailsObj.multipleRealisedProceeds = [];
      }
      this.portfolioService.formDetailsObj.multipleRealisedProceeds.push(realisedProceedsObj);

      this.date = null;
      this.value = 0;
      this.userSelectedCurrency = '';
      this.currencyExchange = {
        exchangeRate: 1,
        convertedValue: 0
      }
      
      this.portfolioService.formDetailsObj.totalRealisedProceeds = 0; 

      let length = this.portfolioService.formDetailsObj.multipleRealisedProceeds.length;
      if( length > 0) {

        let sum = 0;
        this.portfolioService.formDetailsObj.multipleRealisedProceeds.forEach( rp => {
          sum = sum + rp.value;
        })
        this.portfolioService.formDetailsObj.totalRealisedProceeds = sum;
      }

      this.portfolioService.selectedForm[0].totalRealisedProceeds = this.portfolioService.formDetailsObj.totalRealisedProceeds;
      this.portfolioService.selectedForm[0].multipleRealisedProceeds = cloneDeep(this.portfolioService.formDetailsObj.multipleRealisedProceeds)

      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
      
    }

    this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    // this.portfolioService.calculateMultiples();
  }

  addInvestmentAmount(){
    if(this.date && this.value){
      const investmentAmountObj = {
        date: '',
        value: 0,
        sourceCurrency: this.latestValData[0].currency,
        userSelectedCurrency: this.userSelectedCurrency ? this.userSelectedCurrency : this.latestValData[0].currency,
        exchangeRate: this.currencyExchange.exchangeRate,
        valueBeforeConversion: this.value,
        exchangeValue: this.currencyExchange.convertedValue,
      }
      investmentAmountObj.date = this.date;
      investmentAmountObj.value = this.currencyExchange.convertedValue;
  
      if(!this.portfolioService.formDetailsObj.multipleInvestmentAmount){
        this.portfolioService.formDetailsObj.multipleInvestmentAmount = [];
      }
      this.portfolioService.formDetailsObj.multipleInvestmentAmount.push(investmentAmountObj);


      this.date = null;
      this.value = 0;
      this.userSelectedCurrency = '';
      this.currencyExchange = {
        exchangeRate: 1,
        convertedValue: 0
      }

      let sum = 0;
      if(this.portfolioService.formDetailsObj.multipleInvestmentAmount && this.portfolioService.formDetailsObj.multipleInvestmentAmount.length > 0) {
        this.portfolioService.formDetailsObj.multipleInvestmentAmount.forEach( iv => {
          sum = sum + iv.value;
        })
      }
      this.portfolioService.formDetailsObj.totalInvestmentAmount = sum;

      this.portfolioService.selectedForm[0].totalInvestmentAmount = this.portfolioService.formDetailsObj.totalInvestmentAmount;
      this.portfolioService.selectedForm[0].multipleInvestmentAmount = cloneDeep(this.portfolioService.formDetailsObj.multipleInvestmentAmount)

      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    }
    
    this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    // this.portfolioService.calculateMultiples();
  }

  delete(type, index){
    if(type == "realisedProceeds"){
      const deleteObj = this.portfolioService.formDetailsObj.multipleRealisedProceeds.splice(index, 1);

      let sum = 0;
      if(this.portfolioService.formDetailsObj.multipleRealisedProceeds && this.portfolioService.formDetailsObj.multipleRealisedProceeds.length > 0) {
        this.portfolioService.formDetailsObj.multipleRealisedProceeds.forEach( rp => {
          sum = sum + rp.value;
        })
      }
      this.portfolioService.formDetailsObj.totalRealisedProceeds = sum;
      
      // this.portfolioService.formDetailsObj.totalRealisedProceeds -= deleteObj[0].value > 0 ? deleteObj[0].value : -(deleteObj[0].value);
      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    }
    else{
      const deleteObj  = this.portfolioService.formDetailsObj['multipleInvestmentAmount'].splice(index, 1);
      
      let sum = 0;
      if(this.portfolioService.formDetailsObj.multipleInvestmentAmount && this.portfolioService.formDetailsObj.multipleInvestmentAmount.length > 0) {
        this.portfolioService.formDetailsObj.multipleInvestmentAmount.forEach( iv => {
          sum = sum + iv.value;
        })
      }
      this.portfolioService.formDetailsObj.totalInvestmentAmount = sum;

      // this.portfolioService.formDetailsObj.totalInvestmentAmount -= deleteObj[0].value > 0 ? deleteObj[0].value : -(deleteObj[0].value);
      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    }
  }

  saveData(showMessage?){
    try {
      this.initCalculationMultiples();
    }  catch(e) {
      console.log("Error while calculating IRR", e)
    }

    this.savedData["details"] = this.portfolioService.selectedForm[0]["details"];
    
    //Save market multiple - Market Multiple evolution chart type
    this.savedData.marketMultiple = this.marketMultiple;

    this.saveDataToWidget(showMessage);

    this.ds.saveWidgetDataToDB("PARTNER_MASTER_LIST", this.masterPartnersList, this.orgId).subscribe(res => {
      // console.log("Master Partner list saved successfully");
    }, error => {
      console.log("Error while saving Master Partner list", error);
    })
    

    this.ds.saveWidgetDataToDB("ORG_KEYWORDS", this.portfolioService.orgKeyWords, this.portfolioService.companyId).subscribe(res => {
      // console.log("Keywords data saved successfully", this.portfolioService.orgKeyWords);
    },error =>{
      console.log("error while saving data", error);
    })
  }

  saveDataToWidget(showMessage?) {
    this.modalService.dismissAll();

    this.ds.saveWidgetDataToDB("INVESTMENT_SUMMARY", this.savedData, this.portfolioService.companyId).subscribe(res =>{
      if(showMessage) {
        this.utilService.showMessage("Summary information is saved successfully.", "Ok");
      }
    }, error =>{
      if(showMessage) {
        this.utilService.showMessage("Failed to save summary", "Ok");
      }
    })
  }
  
  initCalculationMultiples(){    
    let selectedCompanyDates = this.portfolioService.getSelectedCompanyDates(this.portfolioService.companyId);

    selectedCompanyDates = selectedCompanyDates.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    }).filter(f => f.status !== "Initiated" && !f.userEntered);
    
    this.portfolioService.selectedForm = this.portfolioService.companies.filter(comp=> comp.id === this.portfolioService.companyId);    
    let portfolioComp = this.portfolioService.portfolioData.find( comp => comp.id === this.portfolioService.companyId);

    this.calculateMultiples(this.portfolioService.selectedForm[0], portfolioComp, selectedCompanyDates[0]);
    this.portfolioService.searchedForms = this.portfolioService.portfolioData;
  }
  
  async calculateMultiples(selectedForm, portfolioComp, latestCompanyDate, init?){
    if(selectedForm.details){
      if(typeof selectedForm.details == "string"){
        selectedForm.details = JSON.parse(selectedForm.details)
      }
    }
    let conversionMultiple = portfolioComp.exchangeRate? portfolioComp.exchangeRate : 1;
        
    let stakeVal = 0;

    //Stake value is converted to selected currency
    if(latestCompanyDate.investment && latestCompanyDate.investment.equityValue){
      if(latestCompanyDate.investment.equityValue.finalStakeValue) {
        stakeVal = latestCompanyDate.investment.equityValue.finalStakeValue * conversionMultiple;
      } else {
        stakeVal = latestCompanyDate.investment.equityValue.stakeValue * conversionMultiple
      }
    }
    let stakeValArray = [];

    stakeValArray.push({
      date: new Date(latestCompanyDate.valuationDate), 
      value: stakeVal
    })
    
    selectedForm.totalRealisedProceeds = selectedForm.details && selectedForm.details.totalRealisedProceeds 
          ? selectedForm.details.totalRealisedProceeds : 0;

    selectedForm.totalRealisedProceeds = selectedForm.totalRealisedProceeds ? selectedForm.totalRealisedProceeds : 0

    //for moic caluculation
    selectedForm.moic = (selectedForm.totalRealisedProceeds + stakeVal)/selectedForm.totalInvestmentAmount;

    // console.log("#######MOiC Company Name", selectedForm.companyName)
    // console.log("###totalRealisedProceeds", selectedForm.totalRealisedProceeds);
    // console.log("stakeVal", stakeVal);
    // console.log("totalInvestmentAmount", selectedForm.totalInvestmentAmount);

    // console.log("MOIC val", selectedForm.moic);

    // console.log("-----------------------------------")
        
    selectedForm.multipleInvestmentAmount = selectedForm.details && selectedForm.details.multipleInvestmentAmount 
          ? selectedForm.details.multipleInvestmentAmount : [];

    let investmentArray = cloneDeep(selectedForm.multipleInvestmentAmount);
    investmentArray = investmentArray.map(arr => {
      arr.value = -1 * arr.value * conversionMultiple;
      return arr;
    })
    
    let multiplesArray;

    if(selectedForm.multipleRealisedProceeds && selectedForm.multipleRealisedProceeds.length > 0){

      let realisedProceedsArray = cloneDeep(selectedForm.multipleRealisedProceeds)
      
      //converting realisedProceeds amount into actual value using exchange rate

      realisedProceedsArray.forEach( arr => {
        arr.value = arr.value * conversionMultiple
      });

      multiplesArray =  realisedProceedsArray.concat(investmentArray).concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }
    else{
      multiplesArray =  investmentArray.concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }
    const irrApiReqBody = {
      data: multiplesArray.map( arr => {
              return { 
                date: (moment(arr.date)).format("DD/MM/YY"),
                amount: arr.value
              }
            }),
      request_id: selectedForm.id
    }
    let irrVal = 0;

    try {
      const irrValAPI = await this.dataService.getIRR(irrApiReqBody).toPromise();
      irrVal = irrValAPI.body["response"].data.complete_processed_data
    } catch(e) {

    }

    // console.log("IRR", irrVal, irrApiReqBody)
    // console.log("IRR Company Name", selectedForm.companyName, selectedForm)
    // console.log("IRR =======================================")

    selectedForm.irr = irrVal? irrVal : 0;

    selectedForm.details = cloneDeep(selectedForm.details);

    if(!init) {
      this.dataService.saveFormDetails(selectedForm.details).subscribe(data => {
        // console.log("Data is Saved Successfully", data);
      }, error => {
        console.log("Failed to save the data", error);
      })
    }

    portfolioComp.investmentAmount = selectedForm.totalInvestmentAmount;
    portfolioComp.realisedProceeds = selectedForm.totalRealisedProceeds;
    portfolioComp.moic = selectedForm.moic;
    portfolioComp.grossIRR = selectedForm.irr;

    // console.log("------------------- grossIRR portfolioComp", portfolioComp);
    // console.log("------------------- grossIRR selectedForm", selectedForm);
    this.portfolioService.calculateTotal();
  }

  addPlanned(form: NgForm) {
    // console.log("data items", this.savedData.performance)
  }

  async prepareCharts() {
    this.valuationAlgorithms.processingWaterFallMsg = "Preparing Value Bridge"
    //Get only form ids and filter any null ids from the list.
    let selectedCompIds = this.selectedCompanyDates.map(comp => comp.id).filter(id => id);

    let allBusinessUnits = [];
    this.selectedCompanyDates.forEach(comp => {
      if(comp.businessUnits) {
        allBusinessUnits = allBusinessUnits.concat(comp.businessUnits.map( bu => bu.id ));
      }
    });

    selectedCompIds = selectedCompIds.concat(allBusinessUnits);

    this.prepareValueBridgeChart();

    this.fetchMarketEvolutionChartOnBUChange()    
    
    let valuationDateResponse; 
    try {
      valuationDateResponse = await this.ds.getValuationAlgo(selectedCompIds).toPromise();
    } catch (error) {
      
    }

    const valuationData = valuationDateResponse ? valuationDateResponse.body["response"] : null;

    this.valuationAlgoData = valuationData;

    this.prepareCompanyData();
    // this.assignSubjectCompanyMultiples();
    
    // this.initBubbleChart(valuationData);

    this.getCCMComparableCompanies();

    if(this.portfolioService.formDetailsObj.multipleInvestmentAmount){
      this.portfolioService.initCalculationMultiples();
    }

    //--------------------------------------------------
    // Get Market financials
    // -------------------------------------------------
    let dataList = [];
    
    const valuationDoneForms = Object.keys(this.valuationAlgoData);
    const valuationDoneFormIds = valuationDoneForms.filter( formId => this.valuationAlgoData[formId].CCM);
    
    for(let selectedCompany of this.selectedCompanyDates){
      if (selectedCompany.id && selectedCompany.id.indexOf("_S") > 0) {
        let obj = {
          apiFormId: selectedCompany.groupFormId,
          date: selectedCompany.valuationDate,
          formVersion: this.ums.getApplicationVersion(),
          id: selectedCompany.id
        };
        dataList.push(obj);

      } else if((!valuationDoneFormIds || valuationDoneFormIds.indexOf(selectedCompany.id) < 0) && !selectedCompany.businessUnits) {
        //Form with Valuation Done without CCM

        let obj = {
          apiFormId: selectedCompany.groupFormId || selectedCompany.id,
          date: selectedCompany.valuationDate,
          formVersion: this.ums.getApplicationVersion(),
          id: selectedCompany.id
        };
        dataList.push(obj);

      } else if(selectedCompany.businessUnits) {        
        //Business Units with Valuation Done without CCM
        selectedCompany.businessUnits.forEach(bu => {
          if(!valuationDoneFormIds || valuationDoneFormIds.indexOf(bu.id) < 0) {

            let obj = {
              apiFormId: bu.id,
              date: bu.valuationDate,
              formVersion: this.ums.getApplicationVersion(),
              id: bu.id,
              businessUnit: true
            };

            dataList.push(obj);
          }
        });
      }
    }

    if(dataList.length === 0) {
      let obj = {
        apiFormId: this.selectedCompanyDates[0].groupFormId || this.selectedCompanyDates[0].id,
        date: this.selectedCompanyDates[0].valuationDate,
        formVersion: this.ums.getApplicationVersion(),
        id: this.selectedCompanyDates[0].id
      };
      dataList.push(obj);
    }

    try {
      let marketDataResponse;
      if(dataList.length > 0) {
        const marketDataAPI = await this.dataService.getComparableCompsMarketDataNew(dataList).toPromise();
        marketDataResponse = marketDataAPI.body["response"];
        this.marketDataResponse = marketDataResponse;
      }

      //--------------------------------------------------
      // Multiple Evolution Chart
      //--------------------------------------------------

      this.areaChartLoader = false;
      
      // this.prepareAreaChart('bevRevenue', marketDataResponse, this.marketMultiple == "bevRevenue");

      // this.prepareAreaChart('bevEbitda', marketDataResponse, this.marketMultiple == "bevEbitda");

      // this.prepareAreaChart('pe', marketDataResponse, this.marketMultiple == "pe");

      // this.prepareAreaChart('psales', marketDataResponse, this.marketMultiple == "psales");

      // this.prepareAreaChart('pbv', marketDataResponse, this.marketMultiple == "pbv");

      // this.prepareAreaChart('evEbitda_Capex', marketDataResponse, this.marketMultiple == "evEbitda_Capex");

      // this.prepareAreaChart('evEbit', marketDataResponse, this.marketMultiple == "evEbit");

      // this.prepareAreaChart('evGrossProfit', this.marketDataResponse, this.marketMultiple == "evGrossProfit");
    } catch(e) {
      this.areaChartLoader = false;
      console.log("Error while getting market data", e)
    }
  }

  getFormattedDataForChart(marketEvolutionChartData){
    const marketMultiples = Object.keys(marketEvolutionChartData);

    marketMultiples.forEach( m => {
      const formIds = Object.keys(marketEvolutionChartData[m]);
      let agregationData = []
      formIds.forEach(id => {
        agregationData.push(marketEvolutionChartData[m][id]);
      })

      agregationData = agregationData.sort((f1, f2)=>{
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0 : f1Date < f2Date? -1: 1;
      })

      this.marketEvolutionChartData[m] = {
        xAxis: agregationData.map( ag => (moment(ag.valuationDate)).format("MMM DD, YYYY")),
        median : agregationData.map( ag => ag.median),
        average : agregationData.map( ag => ag.average),
        subjectComp : agregationData.map( ag => ag.subjectCompanyMultiple),

        thirdQuartile: agregationData.map( ag => ag.q3),
        firstQuartile: agregationData.map( ag => ag.q1),
      }
    })
  }

  //On BU Change
  fetchMarketEvolutionChartOnBUChange(){
    this.areaChartLoader = true
    const reqBodyForMarketEvolutionChart = this.getReqBodyForMarketEvolutionChart(this.buMarketMultiple);

    if(this.multipleEvolutionFormWiseData[reqBodyForMarketEvolutionChart.forms[0].id]){
      this.getFormattedDataForChart(this.multipleEvolutionFormWiseData[reqBodyForMarketEvolutionChart.forms[0].id]);
      this.createMarketEvolutionChart(this.marketMultiple);
      this.areaChartLoader = false;
    }
    else{
      this.ds.getMultipleEvolutionChartData(reqBodyForMarketEvolutionChart).subscribe(res => {
        this.multipleEvolutionFormWiseData[reqBodyForMarketEvolutionChart.forms[0].id] = res.body["response"].marketEvolutionChartData;
        this.getFormattedDataForChart(this.multipleEvolutionFormWiseData[reqBodyForMarketEvolutionChart.forms[0].id]);
        this.createMarketEvolutionChart(this.marketMultiple);
        this.areaChartLoader = false;
      }, error => {
        this.areaChartLoader = false;
        console.log("ERROR", error)
      });
    }
  }

  // On Multiple Change
  createMarketEvolutionChart(userSelected) {
    // console.log("Plotting area chart", userSelected);
    let subjectCompanyName = this.selectedCompanyDates[this.selectedCompanyDates.length - 1].companyNameInForm;

    if(this.selectedCompanyDates[this.selectedCompanyDates.length - 1].businessUnits) {
      subjectCompanyName = this.selectedCompanyDates[this.selectedCompanyDates.length - 1].businessUnits.find(bu => bu.businessUnitName === this.buMarketMultiple).companyNameInForm;
    } 

    const xAxis = cloneDeep(this.marketEvolutionChartData[userSelected].xAxis);
    const median = cloneDeep(this.marketEvolutionChartData[userSelected].median);
    const average = cloneDeep(this.marketEvolutionChartData[userSelected].average);
    const subjectComp = cloneDeep(this.marketEvolutionChartData[userSelected].subjectComp);
    
    const thirdQuartile = cloneDeep(this.marketEvolutionChartData[userSelected].thirdQuartile);
    const firstQuartile = cloneDeep(this.marketEvolutionChartData[userSelected].firstQuartile);

    let grapHPlacement = "on";

    if(xAxis.length <= 1) {
      grapHPlacement = "between";
    }

    // Below code makes sure that legend icon color is picked from chart options
    // This will ensure "1st qurtile" legend has same color as "3rd quartile" legend
    (function (H) {
      H.wrap(H.Legend.prototype, 'colorizeItem', function (proceed, item, visible) {
        item.legendColor = item.options.legendColor;
          proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      });
    }(Highcharts));

    this.areaChart = new Chart({
      chart: { type: 'area', height: 340 }, //  backgroundColor: "#e6e6e6",
      title: { text: '' },
      legend:{ layout: 'horizontal' },
      xAxis: { categories: xAxis, gridLineWidth: 1, gridLineColor: '#BEBEBE' },
      yAxis: { title:{ text: ''} , labels: { format: '{value}x' }, gridLineWidth: 1, gridLineColor: '#BEBEBE'},
      tooltip: { 
        enabled: true,           

        formatter: function() { 
          if(this.y > 0) {
            const yValue = (+this.y);

            if(this.series.name === "(Upper Bound) - 3rd Quartile") {
              const pointIndex = this.point['index'];
              return  this.series.name + ": " + ((yValue + firstQuartile[pointIndex] ).toFixed(1)).toLocaleString() +"x";

            } else {
              return  this.series.name + ": " + (yValue.toFixed(1)).toLocaleString() +"x";
            }
          }
        } 
      },
      credits: { enabled: false },
      plotOptions: {
        area: {
          stacking: 'normal', pointStart: 0,
          marker: {
            enabled: false, symbol: 'circle', radius: 2,
            states: { hover: { enabled: true } }
          }
        },
        series: {
          // events: {
          //     legendItemClick: function() {
          //       return false;
          //     }
          // },

          pointPlacement: grapHPlacement,

          dataLabels: { 
            enabled: this.multipleChartShowLabels, 
            style: { textOutline: 'none' }, 
            color: '#000000',           
        

            formatter: function() { 
              if(this.y > 0) {
                const yValue = (+this.y);

                if(this.series.name === "(Upper Bound) - 3rd Quartile") {
                  const pointIndex = this.point['index'];
                  return  ((yValue + firstQuartile[pointIndex] ).toFixed(1)).toLocaleString() +"x";

                } else {
                  return  (yValue.toFixed(1)).toLocaleString() +"x";
                }
              }
            } 
          }
        }
      },
      exporting: { enabled: false },
      series: [{
        name: "(Upper Bound) - 3rd Quartile",
        data: thirdQuartile.map((num, i) => num - firstQuartile[i]),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q3[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q3[1]],
        type: 'area',
        color: '#EEFFC6',
      }, {
        name: '(Lower Bound) - 1st Quartile',
        data: firstQuartile,
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q1[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q1[1]],
        type: "area",
        legendColor: '#EEFFC6',
        color: "transparent"
      } as any, {
        name: ' Median',
        type: "spline",
        data: median,
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.median[1],this.valuationDateCCM.multipleType[userSelected].aggregations.median[1]],
        color: "#5FAFF2"

      }, {
        name: ' Average',
        type: "spline",
        data: average,
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.average[1],this.valuationDateCCM.multipleType[userSelected].aggregations.average[1]],
        color: "#FFD32B"

      }, {
        name: subjectCompanyName,
        type: "spline",
        data: subjectComp,
        // data: [this.investmentDateCCM.subjectCompany[userSelected][1], this.valuationDateCCM.subjectCompany[userSelected][1]],
        color: this.themeService.primaryColor
      }]
    });
  }

  getReqBodyForMarketEvolutionChart(buMarketMultiple){
    let valuationDatesWithoutBU = []
    valuationDatesWithoutBU = this.selectedCompanyDates
    .filter(comp => !comp.businessUnits)
    .filter(comp => comp.status != "Initiated").map( comp => {
      return {
        id: comp.id,
        formType: comp.formType,
        formVersion: comp.formVersion,
        valuationDate: comp.valuationDate,
      }
    })

    if(!buMarketMultiple && this.selectedCompanyDates[0].businessUnits) {
      buMarketMultiple = this.selectedCompanyDates[0].businessUnits[0].businessUnitName;
      this.buMarketMultiple = buMarketMultiple;
    }

    let valuationDatesWithBU = []

    this.selectedCompanyDates.forEach( comp => {
      if(comp.businessUnits) {
        valuationDatesWithBU = valuationDatesWithBU.concat(comp.businessUnits.map( bu => {
          if(bu.businessUnitName.toUpperCase() == buMarketMultiple.toUpperCase()){
            return {
              id: bu.id,
              formType: bu.formType,
              formVersion: bu.formVersion,
              valuationDate: bu.valuationDate,
            }
          }
          else{
            return null;
          }
        }).filter(bu => bu != null))
      }
    })

    const listOfAllValuationDates = valuationDatesWithoutBU.concat(valuationDatesWithBU);

    const reqBody = {
      forms: listOfAllValuationDates,
      marketMultiples: ["bevRevenue", "bevEbitda", "evEbitda_Capex", "evGrossProfit", "psales", "pe", "pbv"],
    }

    return reqBody;
  }

  async prepareValueBridgeChart() {  
    this.multipleValueBridgeData = [];
    if(this.savedData.valuationBridgeDates && this.savedData.valuationBridgeDates.length > 0) {

      // console.log("SAVED VALUE BRIDGE DATA", this.savedData.valuationBridgeDates)

      for (let index = 0; index < this.savedData.valuationBridgeDates.length; index++) {
        const eachVB = this.savedData.valuationBridgeDates[index];

        let response;

        const startDate = eachVB["startDate"];
        const endDate = eachVB["endDate"];
        const centerDate = eachVB["centerDate"] ? eachVB["centerDate"] : "";
        const typeOfValueBridge = eachVB["isMultiValueBridge"] ? 2 : 1;
        let intermediateDateExists: any;
        console.log("Value Bridge ----------", eachVB);

        try {
          const apiData = await this.ds.getSavedValuationBridge(startDate, endDate, this.portfolioService.companyId, centerDate, typeOfValueBridge).toPromise();
          response = apiData.body["response"];

          console.log("Saved Value Bridge ----------", response)

          if(response){

            this.valueBridgeModel = cloneDeep(JSON.parse(response.data));
            this.valueBridgeModelClone = cloneDeep(this.valueBridgeModel);

            this.startDateValuationBridge = this.datePipe.transform(Date.parse(this.valueBridgeModel[0].name), "yyyy-MM-dd");
            this.startDateValuationBridgeClone = this.startDateValuationBridge;
  
            this.endDateValuationBridge = this.datePipe.transform(Date.parse(this.valueBridgeModel[this.valueBridgeModel.length - 1].name), "yyyy-MM-dd");
            this.endDateValuationBridgeClone = this.endDateValuationBridge;
  
             intermediateDateExists = this.valueBridgeModel.find( data => data.isIntermediateSum == true);
            if(intermediateDateExists){
              this.intermediateDateValuationBridge = this.datePipe.transform(Date.parse(intermediateDateExists.name), "yyyy-MM-dd");
              this.intermediateDateValuationBridgeClone = this.intermediateDateValuationBridge;
            }
          }else{
            this.startDateValuationBridge = this.datePipe.transform(Date.parse(startDate), "yyyy-MM-dd");
            this.startDateValuationBridgeClone = this.startDateValuationBridge;
  
            this.endDateValuationBridge = this.datePipe.transform(Date.parse(endDate), "yyyy-MM-dd");
            this.endDateValuationBridgeClone = this.endDateValuationBridge;
  
            if(typeOfValueBridge==2){
              this.intermediateDateValuationBridge = this.datePipe.transform(Date.parse(centerDate), "yyyy-MM-dd");
              this.intermediateDateValuationBridgeClone = this.intermediateDateValuationBridge;
            }
          }
            // Default Value Bridge
            if(index == 0) {
              this.initValueBridgeDatesDefault();

              const reqBody = this.getValueBridgeAPIReqBody();

              const res = await this.ds.getValuationBridgeDataForAllForms(reqBody,this.portfolioService.selectedFundId, this.portfolioService.companyId).toPromise();

              const apiResponse = res.body["response"];

              this.valueBridgeData = apiResponse;
              console.log("valueBridgeData", this.valueBridgeData);

              this.initValueBridgeChartAndDraw(apiResponse);
        
              this.waterFallNumbers = cloneDeep(this.valueBridgeModel);

              intermediateDateExists = this.valueBridgeModel.find( data => data.isIntermediateSum == true);
            } else {

              // Additional User Saved Valuation Bridges
              this.initValueBridgeDates();
            }

            const waterFallChartData = this.drawValuationBridge()

            const valueBridgeObj : any = {
              name: eachVB.name,
              startDateForm : cloneDeep(this.startDateForm),
              startDateValuationBridge : cloneDeep(this.startDateValuationBridge),
              endDateForm: cloneDeep(this.endDateForm),
              endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
              waterFallGraphShowLabels: false,
              intermediateDateForm: intermediateDateExists ? cloneDeep(this.intermediateDateForm) : "",
              intermediateDateValuationBridge: intermediateDateExists ? cloneDeep(this.intermediateDateValuationBridge) : "",
              waterFallNumbers: cloneDeep(this.waterFallNumbers),
              isMultiValueBridge: eachVB.isMultiValueBridge,
              valueBridgeModel: cloneDeep(this.valueBridgeModel),
              waterFallChartData: cloneDeep(waterFallChartData),
              comments: eachVB.comments,
            }
        
            this.multipleValueBridgeData.push(valueBridgeObj)

          
        } catch (error) {
          this.prepareWaterFallChartNew(true);
        }        
      }
      
    } 
    else {
      this.prepareWaterFallChartNew(true);
    }
  }

  getValueBridgeAPIReqBody() {
    const reqBody = [];

    reqBody.push({ 
      formId:this.startDateForm['id'], 
      version:this.startDateForm['formVersion'],
      type: this.startDateForm['formType']
    });

    if(this.intermediateDateForm && this.intermediateDateForm['id']){
      reqBody.push({
        formId:this.intermediateDateForm['id'],
        version:this.intermediateDateForm['formVersion'],
        type: this.intermediateDateForm['formType']
      });
    }

    reqBody.push({
      formId:this.endDateForm['id'],
      version:this.endDateForm['formVersion'],
      type: this.endDateForm['formType']
    });

    return reqBody;
  }

  downloadValueBridgeExcel(){
    if(this.isDownloading) return;

    let fileData = [];

    this.valueBridgeData.forEach((ele, index) => {
      fileData.push(this.getExcelDownloadValueBridgeBody(ele, index));
    });

    this.isDownloading = true;

    this.ds.downloadExcelFile(fileData).subscribe((fileData)=>{
      this.manageFileDownload(fileData, "xlsx");
    }, err => {
      console.log("Failed to download file", err)
      this.utilService.showMessage("Sorry! Not able to download the file. Please contact us.", "Ok")
      this.isDownloading = false;
    });
  }

  getExcelDownloadValueBridgeBody(responseData, index){
    let headers = ["", "", responseData.valueBridgeData.startDate, "", responseData.valueBridgeData.endDate];
    const values = [];
    let responseDataIndex = index+1;

    let keys = Object.keys(this.valueBridgeData[index].newData).filter(item => item !== "key" && item !== "multipleKey");

    values.push(["Enterprise Value", "", responseData.oldData.enterpriseValue, "", responseData.newData.enterpriseValue]);
    values.push(["Net Debt", "", responseData.oldData.netDebt, "", responseData.newData.netDebt]);
    values.push(["Equity Value", "", responseData.oldData.equityValue, "", responseData.newData.equityValue]);

    let multipleKey = responseData.newData.multipleKey;
    for(let i=0; i<keys.length; i++){    
      if(keys[i] !== "enterpriseValue" && keys[i] !== "netDebt" && keys[i] !== "equityValue" && keys[i] !== "stake" && keys[i] !== multipleKey && keys[i] !== "multiple" && keys[i] !== "fX"){
        values.push([this.getValueBridgeMetricLabel(keys[i]), "", responseData.oldData[keys[i]], "", responseData.newData[keys[i]]]);
      } 
    }

    values.push(["", "", "", ""]);

    values.push(["Stake", "", responseData.oldData.stake, "", responseData.newData.stake]);

    values.push(["", "", "", ""]);

    values.push([this.getValueBridgeMetricLabel(multipleKey), "", responseData.oldData[multipleKey], "", responseData.newData[multipleKey]]);
    values.push([this.getValueBridgeMetricLabel(multipleKey) +" Multiple", "", responseData.oldData.multiple, "", responseData.newData.multiple]);

    values.push(["", "", "", ""]);
    
    values.push(["NAV (Old)", "", responseData.valueBridgeData.startingValue, "", "", "Formula"]);

    values.push(["", "", "", ""]);

    let oldmultipleValueImpactkey = responseData.oldData.multipleKey;
    let newmultipleValueImpactkey = responseData.newData.multipleKey;
    values.push([this.getValueBridgeMetricLabel(multipleKey) +" Impact", "", responseData.valueBridgeData.multipleValueImpact, "", "", "("+responseData.newData[newmultipleValueImpactkey] +"-"+ "("+ responseData.oldData[oldmultipleValueImpactkey]+")" +")" +"*"+ responseData.oldData.multiple +"*"+ responseData.newData.stake]);

    values.push([this.getValueBridgeMetricLabel(multipleKey) +" Multiple Impact", "", responseData.valueBridgeData.multipleImpact, "", "", "("+ responseData.newData.multiple +"-"+ "("+ responseData.oldData.multiple +")" +")" +"*"+ responseData.newData[multipleKey] +"*"+ responseData.newData.stake]);

    values.push(["Realised Proceeds Impact", "", responseData.valueBridgeData.realisedProceedsImpact, "", "", "("+ responseData.newData.realisedProceeds +"-"+ responseData.oldData.realisedProceeds +")" +"*"+ "-1"]);

    values.push(["Net Debt Impact", "", responseData.valueBridgeData.netDebtImpact, "", "", "("+ responseData.newData.netDebt +"-"+ "("+ responseData.oldData.netDebt +")" +")" +"*"+ responseData.newData.stake]);

    // values.push(["FX Impact", "", responseData.valueBridgeData.fxImpact, "", "", "("+ 
    // "("+ responseData.newData.stakeValue +"*"+ responseData.oldData.stakeValue +")" +"*"
    // +"(" +"("+ responseData.newData.fX +"/"+ responseData.oldData.fX +")" +"-1" +")" +")"+ ")"+ "*"+
    // responseData.newData.stake]);

    values.push(["Others Impact", "", responseData.valueBridgeData.othersImpact, "", ""]);
    values.push(["", "", "", ""]);

    values.push(["NAV (New)", "", responseData.valueBridgeData.endingValue, "", "", ""]);


    return { "tabName": "Bridge " + responseDataIndex, "data": { "headers": headers, "values": values } }
  }

  manageFileDownload(fileData, type) {

    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.download = "Value Bridge." + type;
      this.downloadLink.nativeElement.click();
    } catch(e) {
      console.log("Failed to download file", e)
      this.utilService.showMessage("Sorry! Not able to download the file. Please contact us.", "Ok")
    }

    this.isDownloading = false;
  }

  assignSubjectCompanyMultiples() {  
    
    // this.prepareCompanyData()
    const latestValDateTrackRecord = this.dashboardRepresentation && this.dashboardRepresentation.length > 0 ? this.dashboardRepresentation[this.dashboardRepresentation.length - 1] : {}
    const latestForm = this.selectedCompanyDates.find(c => !c.userEntered);
    
    try {
      const finArray = this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC;
      //picking the last column in Hist Financials
      let lastYearFin = {} as any;

      if(finArray.length == 1) {
        lastYearFin = finArray[0]
      } else {
        lastYearFin = finArray[finArray.length - 1]
      }

      this.latestValuationYear = lastYearFin.year;

      this.summaryNumbers.fullyDilutedOwnership = latestForm.investment.equityValue.stake;

      this.summaryNumbers.revennue = latestValDateTrackRecord["revenue"] ? latestValDateTrackRecord["revenue"] : 0; //this.getMetricValueFromCCM(lastYearFin.totalNetRevenue, "bevRevenue", this.latestValuationYear);
            
      this.summaryNumbers.ebitda = latestValDateTrackRecord["ebitda"] ? latestValDateTrackRecord["ebitda"] : 0; //this.getMetricValueFromCCM(lastYearFin.eBITDA, "bevEbitda", this.latestValuationYear);

      this.summaryNumbers.ebitdaMargin = this.summaryNumbers.revennue > 0 ? (this.summaryNumbers.ebitda / this.summaryNumbers.revennue) * 100 : 0;

      this.summaryNumbers.ebit = latestValDateTrackRecord["ebit"] ? latestValDateTrackRecord["ebit"] : 0; //this.getMetricValueFromCCM(lastYearFin.earningsBefore, "evEbit", this.latestValuationYear);

      this.summaryNumbers.grossProfit = latestValDateTrackRecord["grossProfit"] ? latestValDateTrackRecord["grossProfit"] : 0; //this.getMetricValueFromCCM(lastYearFin.grossProfit, "evGrossProfit", this.latestValuationYear);

      this.summaryNumbers.ebitMargin = this.summaryNumbers.revennue > 0 ? (this.summaryNumbers.ebit / this.summaryNumbers.revennue) * 100 : 0;

      this.summaryNumbers.psales = latestValDateTrackRecord["priceSales"] ? latestValDateTrackRecord["priceSales"] : 0; //this.getMetricValueFromCCM(lastYearFin.totalNetRevenue, "psales", this.latestValuationYear);
      this.summaryNumbers.pe = latestValDateTrackRecord["priceEarnings"] ? latestValDateTrackRecord["priceEarnings"] : 0; //this.getMetricValueFromCCM(lastYearFin.netIncome, "pe", this.latestValuationYear);
      this.summaryNumbers.pbv = latestValDateTrackRecord["priceBookValue"] ? latestValDateTrackRecord["priceBookValue"] : 0; //this.getMetricValueFromCCM(lastYearFin.totalShareholdersEquity, "pbv", this.latestValuationYear);

      // this.summaryNumbers.industryBevEbitda = this.valuationAlgoData.multipleType['bevEbitda'].aggregations.median[0];
      // this.summaryNumbers.industryBevRevennue = this.valuationAlgoData.multipleType["bevRevenue"].aggregations.median[0];
    
    } catch(e) {
      console.log("Exception while assigning subject company multiples to summary", e)
    }

    if(!latestForm.businessUnitsNumber) {

      this.summaryNumbers.subjectCompBevRevenue = latestForm.investment && latestForm.investment["bevRevenue"] ? latestForm.investment["bevRevenue"] : 0;

      this.summaryNumbers.subjectCompBevEbitda = latestForm.investment && latestForm.investment['bevEbitda'] ? latestForm.investment['bevEbitda'] : 0;

      this.summaryNumbers.subjectCompBevEbit = latestForm.investment && latestForm.investment['bevEbit'] ? latestForm.investment['bevEbit'] : 0;

      this.summaryNumbers.subjectCompEVGrossProfit = latestForm.investment && latestForm.investment['evGrossProfit'] ? latestForm.investment['evGrossProfit'] : 0;

      this.summaryNumbers.subjectCompPsales = latestForm.investment && latestForm.investment['psales'] ? latestForm.investment['psales'] : 0;

      this.summaryNumbers.subjectCompPe = latestForm.investment && latestForm.investment['pe'] ? latestForm.investment['pe'] : 0;

      this.summaryNumbers.subjectCompPbv = latestForm.investment && latestForm.investment['pbv'] ? latestForm.investment['pbv'] : 0;
    } else {
      if(latestForm.investment) {

        this.summaryNumbers.subjectCompBevRevenue = this.summaryNumbers.revennue ? latestForm.investment.enterpriseValue / this.summaryNumbers.revennue : 0;

        this.summaryNumbers.subjectCompBevEbitda = this.summaryNumbers.ebitda ? latestForm.investment.enterpriseValue / this.summaryNumbers.ebitda: 0;

        this.summaryNumbers.subjectCompBevEbit = this.summaryNumbers.ebit ? latestForm.investment.enterpriseValue / this.summaryNumbers.ebit: 0;

        this.summaryNumbers.subjectCompEVGrossProfit = this.summaryNumbers.grossProfit ? latestForm.investment.enterpriseValue / this.summaryNumbers.grossProfit: 0;

        this.summaryNumbers.subjectCompPsales = this.summaryNumbers.psales ? latestForm.investment.enterpriseValue / this.summaryNumbers.psales: 0;

        this.summaryNumbers.subjectCompPe = this.summaryNumbers.pe ? latestForm.investment.enterpriseValue / this.summaryNumbers.pe: 0;

        this.summaryNumbers.subjectCompPbv = this.summaryNumbers.pbv ? latestForm.investment.enterpriseValue / this.summaryNumbers.pbv: 0;
      }
    }
  }

  //Parameter is the value to be assigned at worst case (its from formData)
  getMetricValueFromCCM(valueToBeAssigned, metricKey, year){
    let finalMetricValue = 0;

    const latestForm = this.selectedCompanyDates.find(c => !c.userEntered);
    const valuationDataExists = this.valuationAlgoData[latestForm.id]
    
    // checking whether CCM Exists
    if(valuationDataExists && valuationDataExists["CCM"]){
      const ccmAnalysis = JSON.parse(valuationDataExists["CCM"]);

      if(ccmAnalysis.analysis.concludedAnalysis && ccmAnalysis.analysis.concludedAnalysis.length > 0){

        const multipleExists = ccmAnalysis.analysis.concludedAnalysis.find( row => row.multiple.key === metricKey && row.fy == year);
        if(multipleExists){
          finalMetricValue = multipleExists.metricAdjustments ? multipleExists.metricAdjustments : multipleExists.metric;
        }
        else{
          finalMetricValue = valueToBeAssigned;
        }
      }
      else{
        finalMetricValue = valueToBeAssigned;
      }
    }
    else{
      finalMetricValue = valueToBeAssigned;
    }

    return finalMetricValue;
  }

  prepareCompanyData(){
    const latestForm = this.selectedCompanyDates.find(c => !c.userEntered);
    const valuationDataExists = this.valuationAlgoData[latestForm.id]

    // psales, pe, pbv
    if(valuationDataExists){
      // checking whether CCM Exists
      if(valuationDataExists["CCM"]){
        const ccmValuationExists = JSON.parse(valuationDataExists["CCM"]);

        ccmValuationExists.analysis.concludedAnalysis.forEach( analysis => {
          //checking whether psales, pe, pbv exists
          this.ccmImpliedMultiplesExits[analysis.multiple.key] = true;
        })
      }
    }
  }

  prepareWaterFallChartNew(init?) {
    const reqBody = [];

    this.initValueBridgeDatesDefault();

    if(this.preparedValueBridge) return;

    //value bridge for business units
    // if(this.startDateForm["businessUnitsNumber"] > 0) {

    //   if(this.filteredForms.length >= 2){
    //     this.filteredForms.forEach( form => {
    //       reqBody.push({
    //         formId: form.id,
    //         version: form.formVersion,
    //         type: form.formType
    //       })
    //     })

    //     this.valueBridgeModel[0].name = this.filteredForms[0].valuationDate
    //     this.valueBridgeModel[this.valueBridgeModel.length - 1].name = this.filteredForms[this.filteredForms.length - 1].valuationDate;
    //   }
    //   else if(this.filteredForms.length < 2) {
    //     this.preparedValueBridge = true;
    //     return;
    //   }

    //   this.initValueBridgeDates();

    //   this.getValueBridgeBU(reqBody);
    // } 
    
    // else {
      
      //Value Bridge For Multiple Val Dates/ BU Value Bridge
      if(this.filteredForms.length >= 3){
  
        //selecting inv form, latest form, and prev to latest form for forms greater than 3;
        this.filteredForms.forEach( (form, index) => {
          if(index == 0 || index == this.filteredForms.length - 2 || index == this.filteredForms.length - 1){
            reqBody.push({
              formId: form.id,
              version: form.formVersion,
              type: form.formType
            })
          }
        })
      }
      else if(this.filteredForms.length == 2){
        //if two forms
        this.filteredForms.forEach( (form) => {
          reqBody.push({
            formId: form.id,
            version: form.formVersion,
            type: form.formType
          })
        })
      }
      else{
        this.preparedValueBridge = true;
        return;
      }

      this.initValueBridgeDates();

      this.getValuationBridgeData(init, reqBody);
    // }
  }

  // getValueBridgeBU(input) {
  //   const reqBody = {
  //     "oldConsolId": input[0].formId,
  //     "newConsolId": input[1].formId
  //   }
  //   this.ds.getValuationBridgeBizUnits(reqBody, this.portfolioService.selectedFundId, this.portfolioService.companyId).subscribe( res => {
  //     const apiData = res.body["response"];
  //     this.initValueBridgeForBusinessBunits(apiData);

  //   }, error => {
  //     this.preparedValueBridge = true;
  //     this.valuationAlgorithms.processingWaterFallMsg = ""
  //   });
  // }

  getValuationBridgeData(init, reqBody) {

    this.ds.getValuationBridgeDataForAllForms(reqBody,this.portfolioService.selectedFundId, this.portfolioService.companyId).subscribe( res => {
      const apiResponse = res.body["response"];

      this.initValueBridgeChartAndDraw(apiResponse);
      let waterFallChartData;
      if(init) {
        this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
        waterFallChartData = this.drawValuationBridge();
      }

      this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers, this.valueBridgeModel);

      // index is 0 by default
      this.saveValuationBridgeData(0)

    }, error => {
      this.preparedValueBridge = true;

      this.valueBridgeModel[0].name = this.datePipe.transform(this.startDateForm['valuationDate'], 'mediumDate');
      this.valueBridgeModel[this.valueBridgeModel.length - 1].name = this.datePipe.transform(this.endDateForm['valuationDate'], 'mediumDate');

      this.savedData
      console.log("Error while Calculating Value Bridge", error);
      
      this.valueBridgeModel.forEach( (obj, index) => {
        if(index == 0){
          const date2 = new Date(obj.name);
          const temp = this.selectedCompanyDates.filter( comp => {
            const date1 = new Date(comp.valuationDate);
            return date2.getFullYear() === date1.getFullYear()
            && date2.getDate() === date1.getDate()
            && date2.getMonth() === date1.getMonth();
          })

          obj.y = temp[0]["investment"] ? temp[0]['investment'].equityValue.finalAdjustedEquityVal : 0;
          obj["formattedValue"] = obj.y;
          obj["isIntermediateSum"] = false;
        }
        else if(index == this.valueBridgeModel.length - 1){
          const date2 = new Date(obj.name);
          const temp = this.selectedCompanyDates.filter( comp => {
            const date1 = new Date(comp.valuationDate);
            return date2.getFullYear() === date1.getFullYear()
            && date2.getDate() === date1.getDate()
            && date2.getMonth() === date1.getMonth();
          })
          obj.y = temp[0]['investment'] ? temp[0]['investment'].equityValue.finalAdjustedEquityVal : 0;;
          obj["formattedValue"] = obj.y;
          obj["isIntermediateSum"] = false;
        }
        else{
          obj.y = 0;
          obj["formattedValue"] = 0;
          obj["isIntermediateSum"] = false;
        }
      })
      this.valuationAlgorithms.processingWaterFallMsg = ""
      
      this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
      const waterFallChartData = this.drawValuationBridge();  
      
      this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers, this.valueBridgeModel);
      // index is 0 by default
      this.saveValuationBridgeData(0)
    });
  }

  createMultipleValueBridge(waterFallChartData, waterFallNumbers, valueBridgeModel){
    this.valueBridgeName = "Default Value Bridge"
    const valueBridgeObj : any = {
      name: this.valueBridgeName,
      startDateForm : cloneDeep(this.startDateForm),
      startDateValuationBridge : cloneDeep(this.startDateValuationBridge),
      endDateForm: cloneDeep(this.endDateForm),
      endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
      waterFallGraphShowLabels: false,
      intermediateDateForm: cloneDeep(this.intermediateDateForm),
      intermediateDateValuationBridge: cloneDeep(this.intermediateDateValuationBridge),
      waterFallNumbers: cloneDeep(waterFallNumbers),
      isMultiValueBridge: this.isMultiValueBridge,
      valueBridgeModel: cloneDeep(valueBridgeModel),
      waterFallChartData: cloneDeep(waterFallChartData),
      comments: [{title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}],
    }

    this.multipleValueBridgeData.push(valueBridgeObj)
    
  }

  addValueBridge(){

    const valueBridgeObj : any = {
      name: this.multipleValueBridgeData[0].name,
      startDateForm : cloneDeep(this.startDateForm),
      startDateValuationBridge : cloneDeep(this.startDateValuationBridge),
      endDateForm: cloneDeep(this.endDateForm),
      endDateValuationBridge: cloneDeep(this.endDateValuationBridge),
      waterFallGraphShowLabels: false,
      intermediateDateForm: cloneDeep(this.intermediateDateForm),
      intermediateDateValuationBridge: cloneDeep(this.intermediateDateValuationBridge),
      waterFallNumbers: cloneDeep(this.multipleValueBridgeData[0].waterFallNumbers),
      isMultiValueBridge: cloneDeep(this.multipleValueBridgeData[0].isMultiValueBridge),
      valueBridgeModel: cloneDeep(this.multipleValueBridgeData[0].valueBridgeModel),
      waterFallChartData: {},
      comments: [{title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}],
    }
    const waterFallChartData = this.initwaterFallChart();

    setTimeout(() => {
      
      const finalChartData = cloneDeep(valueBridgeObj.waterFallNumbers).filter((row, index) => row.y !== 0 || index === 0 || row.isSum || row.isIntermediateSum);

      waterFallChartData.addSeries({
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: finalChartData,
        pointPadding: 0
      } as any, true, false);


      valueBridgeObj.waterFallChartData = waterFallChartData

      setTimeout(() => {
        this.multipleValueBridgeData.push(valueBridgeObj) 
      });
    });
    

  }

  prepareWaterFallChart(valuationData, forms){
    if(valuationData && Object.keys(valuationData).length > 1){

      const formVersion = forms[0].formVersion;

      const valuationDoneForms = Object.keys(valuationData);

      const formsInMap = valuationDoneForms.filter( formId => valuationData[formId].CCM && valuationData[formId].CTM && valuationData[formId].IA)
      
      if(formsInMap && formsInMap.length > 1) {
        const newFormId = formsInMap[formsInMap.length - 2];
        const oldFormId = formsInMap[formsInMap.length - 1];

        const oldForm = forms.find(f => f.id == oldFormId);

        const newForm = forms.find(f => f.id == newFormId);
        
        this.initwaterFallChart();
        this.valuationAlgorithms.initWaterFallBetweenValuationDates(this.waterFallChartData, valuationData[oldFormId], valuationData[newFormId], oldForm, newForm, this.portfolioService.companyId, this.savedData);
      }
    }
  }
  
  getTotalEquityValue(ccmEquity, ctmEquity, IAEquity){
    return ((ccmEquity + ctmEquity + IAEquity)/3);
  }

  prepareAreaChart(userSelected, marketDataResponse, plotGraph?){ 
    let areaChartData = {
      thirdQuartile: [],
      firstQuartile: [],
      median: [],
      average: [],
      subjectComp: [],
      categories: [],
      revenue: [],
      ebitda: [],
      ebitdaMargin: []
    }

    //Added
    let dataList = [];
    //Added 
    if(this.valuationAlgoData && Object.keys(this.valuationAlgoData).length >= 1){
      const valuationDoneForms = Object.keys(this.valuationAlgoData);
      const valuationDoneFormIds = valuationDoneForms.filter( formId => this.valuationAlgoData[formId].CCM);

      if(this.selectedCompanyDates && this.selectedCompanyDates.length >= 1){

        let isAllBUForms = false

        const allFormsWithBU = this.selectedCompanyDates.filter( comp => comp.businessUnits);

        isAllBUForms = allFormsWithBU && allFormsWithBU.length === this.selectedCompanyDates.length;

        let selectedForms = [];
        
        if(isAllBUForms) {
          
          if(!this.buMarketMultiple) {
            this.buMarketMultiple = this.selectedCompanyDates[0].businessUnits[0].businessUnitName;
          }

          selectedForms = this.selectedCompanyDates.map(f => f.businessUnits.find(bu => bu.businessUnitName === this.buMarketMultiple))
        } 
        else {
          selectedForms = this.selectedCompanyDates
        }

        for(let i = 0; i < selectedForms.length; i++) {
          const valDate = selectedForms[i];
          if(!valDate.investment || (valDate.businessUnits && !isAllBUForms)){
            continue;
          }

          if(valDate.id) {
            const valDoneFormId = valuationDoneFormIds.find(fId => fId === valDate.id);
            if(valDoneFormId) {
              areaChartData.categories.push((moment(valDate.valuationDate)).format("MMM DD, YYYY"))

              const valuationData = JSON.parse(this.valuationAlgoData[valDoneFormId].CCM);

              areaChartData.thirdQuartile.push(this.utilService.getValidNumber(valuationData.multipleType[userSelected]?.aggregations.q3[1]));
              areaChartData.firstQuartile.push(this.utilService.getValidNumber(valuationData.multipleType[userSelected]?.aggregations.q1[1]));
              areaChartData.median.push(this.utilService.getValidNumber(valuationData.multipleType[userSelected]?.aggregations.median[1]));
              areaChartData.average.push(this.utilService.getValidNumber(valuationData.multipleType[userSelected]?.aggregations.average[1]));

              areaChartData.subjectComp.push(this.utilService.getValidNumber(valDate.investment[userSelected] ? valDate.investment[userSelected] : 0))

              areaChartData.revenue.push(this.utilService.getValidNumber(valuationData.subjectCompany['iaRevenue'][1]))
              
              areaChartData.ebitdaMargin.push(this.utilService.getValidNumber(valuationData.subjectCompany['iaEbitdaMargin'][1]));

            } 
            else {
              // Multiple Evolution for all forms without CCM including user added valuation dates

              let valuationDateObj = "";

              if(!valDate.groupFormId) {
                  // Investment Date without CCM
                  valuationDateObj = valDate.valuationDate;
                  areaChartData.subjectComp.push(valDate.investment[userSelected] ? valDate.investment[userSelected] : 0)
              
              } else if(valDate.id.indexOf("_S") > 0){
                  // User Added old valuation date
                  const savedForm = this.portfolioService.userSavedOldFormsExcel[valDate.groupFormId].find(f => f.id == valDate.id)
                  valuationDateObj = savedForm.valuationDate.value;

                  if(userSelected === "bevRevenue" || userSelected === "bevEbitda") {
                    const multipleIndex = userSelected === "bevRevenue" ? 0 : 1 // bevEbitda

                    areaChartData.subjectComp.push(savedForm.enterprise[multipleIndex].values[1] 
                            ? savedForm.enterprise[multipleIndex].values[1] 
                            : 0)
                  } else {
                    const multipleIndex = userSelected === "psales" 
                          ? 0 
                          : userSelected === "pe"
                          ? 1 
                          : 2 // pbv

                    areaChartData.subjectComp.push(savedForm.equity[multipleIndex].values[1] 
                            ? savedForm.equity[multipleIndex].values[1] 
                            : 0)
                  }
              } else {
                  //Valuation Date without CCM
                  valuationDateObj = valDate.valuationDate;
                  areaChartData.subjectComp.push(valDate.investment[userSelected] ? valDate.investment[userSelected] : 0)
              }

              try {
                const marketData = marketDataResponse[valDate.id];

                let year = {} as any;
                if(marketData.aggregations)
                    year = marketData.aggregations[userSelected].LTM;
                else 
                    year = marketData[userSelected].LTM;

                const marketQ1 = this.utilService.getValidNumber(year.q1);
                const marketQ3 = this.utilService.getValidNumber(year.q3);
                const marketMedian = this.utilService.getValidNumber(year.median);
                const marketAverage = this.utilService.getValidNumber(year.average);
                
                areaChartData.thirdQuartile.push(marketQ3);
                areaChartData.firstQuartile.push(marketQ1);
                areaChartData.median.push(marketMedian);
                areaChartData.average.push(marketAverage);

                const subjectRevenue = valDate.investment["bevRevenue"] ? valDate.investment["bevRevenue"] : 0;
                const subjectEbitda = valDate.investment["bevEbitda"] ? valDate.investment["bevEbitda"] : 0;

                areaChartData.revenue.push(this.utilService.getValidNumber(subjectRevenue))
                areaChartData.ebitda.push(this.utilService.getValidNumber(subjectEbitda))
                areaChartData.ebitdaMargin.push(this.utilService.getValidNumber(subjectEbitda / subjectRevenue));
              
                areaChartData.categories.push((moment(valuationDateObj)).format("MMM DD, YYYY"));
              } catch(e) {
                console.log("Exception occurred while multiple evolution calculation", e)
              }
            }
          }
        }
        // console.log("Data for area chart received", userSelected, areaChartData)
        this.areaChartData[userSelected] = areaChartData;

        if(plotGraph) {
          this.createMarketMultipleChart(userSelected);
        }
      }
    }
  }

  prepareAreaChartOnBU_Change() {    
    
    this.prepareAreaChart('bevRevenue', this.marketDataResponse, this.marketMultiple == "bevRevenue");

    this.prepareAreaChart('bevEbitda', this.marketDataResponse, this.marketMultiple == "bevEbitda");

    this.prepareAreaChart('evEbitda_Capex', this.marketDataResponse, this.marketMultiple == "evEbitda_Capex");

    this.prepareAreaChart('evEbit', this.marketDataResponse, this.marketMultiple == "evEbit");

    this.prepareAreaChart('evGrossProfit', this.marketDataResponse, this.marketMultiple == "evGrossProfit");

    this.prepareAreaChart('pe', this.marketDataResponse, this.marketMultiple == "pe");

    this.prepareAreaChart('psales', this.marketDataResponse, this.marketMultiple == "psales");

    this.prepareAreaChart('pbv', this.marketDataResponse, this.marketMultiple == "pbv");
  }

  fetchValuationDataForWaterfallChart(rowIndex){

    const reqBody = [];
    if(this.filteredForms 
      && this.filteredForms.length >= 3 
      && this.isMultiValueBridge
      && (this.startDateForm["id"] == this.endDateForm["id"] 
      || (this.endDateForm["id"] == this.intermediateDateForm["id"] && this.isMultiValueBridge) 
      || this.intermediateDateForm["id"] ==  this.startDateForm["id"])){

        this.utilService.showMessage("Please select Unique Valuation Dates", "Ok");
        return;
    }

    else if(this.filteredForms 
      && this.filteredForms.length == 2 
      && (this.startDateForm["id"] == this.endDateForm["id"])){

        this.utilService.showMessage("Please select Unique Valuation Dates", "Ok");
        return;
    }

    if(this.filteredForms && this.filteredForms.length >= 3){
      
      const startDate = this.filteredForms.find( comp => comp.id === this.startDateForm["id"]);
      reqBody.push({
        formId: startDate.id,
        version: startDate.formVersion,
        type: startDate.formType
      })

      if(this.intermediateDateValuationBridge && this.isMultiValueBridge){
        const interDate = this.filteredForms.find( comp => comp.id === this.intermediateDateForm["id"]);
        reqBody.push({
          formId: interDate.id,
          version: interDate.formVersion,
          type: interDate.formType
        })
      }

      const endDate = this.filteredForms.find( comp => comp.id === this.endDateForm["id"]);
      reqBody.push({
        formId: endDate.id,
        version: endDate.formVersion,
        type: endDate.formType
      })

      this.multipleValueBridgeData[rowIndex].startDateValuationBridge = this.startDateValuationBridge ? this.startDateValuationBridge : "";
      this.multipleValueBridgeData[rowIndex].endDateValuationBridge = this.endDateValuationBridge ? this.endDateValuationBridge : "";
      this.multipleValueBridgeData[rowIndex].intermediateDateValuationBridge = this.intermediateDateValuationBridge ? this.intermediateDateValuationBridge : "";

      // this.initValueBridgeDates();

      this.multipleValueBridgeData[rowIndex].startDateForm = this.startDateForm ? this.startDateForm : null;
      this.multipleValueBridgeData[rowIndex].endDateForm = this.endDateForm ? this.endDateForm : null;
      this.multipleValueBridgeData[rowIndex].intermediateDateForm = this.intermediateDateForm ? this.intermediateDateForm : null

    }
    else if(this.filteredForms && this.filteredForms.length == 2){
      const startDate = this.filteredForms.find( comp => comp.id === this.startDateForm["id"]);
      reqBody.push({
        formId: startDate.id,
        version: startDate.formVersion,
        type: startDate.formType
      })

      const endDate = this.filteredForms.find( comp => comp.id === this.endDateForm["id"]);
      reqBody.push({
        formId: endDate.id,
        version: endDate.formVersion,
        type: endDate.formType
      })

      this.multipleValueBridgeData[rowIndex].startDateValuationBridge = this.startDateValuationBridge ? this.startDateValuationBridge : "";
      this.multipleValueBridgeData[rowIndex].endDateValuationBridge = "";
      this.multipleValueBridgeData[rowIndex].intermediateDateValuationBridge = this.intermediateDateValuationBridge ? this.intermediateDateValuationBridge : "";

      // this.initValueBridgeDates();

      this.multipleValueBridgeData[rowIndex].startDateForm = this.startDateForm ? this.startDateForm : null;
      this.multipleValueBridgeData[rowIndex].endDateForm = null;
      this.multipleValueBridgeData[rowIndex].intermediateDateForm = this.intermediateDateForm ? this.intermediateDateForm : null
    }

    this.showValueBridgePopUpLoader = true;
    this.ds.getValuationBridgeDataForAllForms(reqBody, this.portfolioService.selectedFundId, this.portfolioService.companyId).subscribe( res => {
      const apiResponse = res.body["response"];

      if(apiResponse) {
        this.initValueBridgeChartAndDraw(apiResponse);

        // const waterFallChartData = this.drawValuationBridge()
        // this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers);

        // this.multipleValueBridgeData[rowIndex].waterFallNumbers = cloneDeep(this.waterFallNumbers)
        // this.multipleValueBridgeData[rowIndex].waterFallChartData = cloneDeep(waterFallChartData)

        this.showValueBridgePopUpLoader = false;
      } 
      else {
        this.prepareDefaultValueBridge();

        this.showValueBridgePopUpLoader = false;
      }

    }, error => {
      console.log("Error while Calculating Value Bridge", error);
      this.prepareDefaultValueBridge();

      this.showValueBridgePopUpLoader = true;
    });
  }

  initValueBridgeForBusinessBunits(apiData) {
    const waterFallNumbers = [ {
      name: this.datePipe.transform(this.startDateForm['valuationDate'], 'mediumDate'),
      y: 0
    }]

    let sumOfEndValues = 0;
    let fxImpactSum = 0;
    let netDebtImpactSum = 0;
    let othersImpactSum = 0;

    apiData.forEach((bu, index) => {
      if(bu.valueBridgeData) {
        const companyName = bu.buName

        waterFallNumbers[0].y += bu.valueBridgeData.startingValue;
        sumOfEndValues += bu.valueBridgeData.endingValue;
        netDebtImpactSum += bu.valueBridgeData.netDebtImpact;
        othersImpactSum += bu.valueBridgeData.othersImpact;
        fxImpactSum += bu.valueBridgeData.fxImpact;

        waterFallNumbers.push({
          name: companyName + "<br/>" + this.getValueBridgeMetricLabel(bu.valueBridgeData.key),
          y: bu.valueBridgeData.multipleValueImpact,          
          formattedValue: this.utilService.getFormattedNumber(bu.valueBridgeData.multipleValueImpact)
        } as any)

        waterFallNumbers.push({
          name: companyName + "<br/>" + "Multiple",
          y: bu.valueBridgeData.multipleImpact,          
          formattedValue: this.utilService.getFormattedNumber(bu.valueBridgeData.multipleImpact)
        } as any)

        // waterFallNumbers.push({
        //   name: companyName + " - " + "Net Debt Impact",
        //   y: bu.valueBridgeData.netDebtImpact,          
        //   formattedValue: this.utilService.getFormattedNumber(bu.valueBridgeData.netDebtImpact)
        // } as any)

        // waterFallNumbers.push({
        //   name: companyName + " - " + "FX Impact",
        //   y: bu.valueBridgeData.fxImpact,          
        //   formattedValue: this.utilService.getFormattedNumber(bu.valueBridgeData.fxImpact)
        // } as any)

        // waterFallNumbers.push({
        //   name: companyName + " - " + "Others Impact",
        //   y: bu.valueBridgeData.othersImpact,          
        //   formattedValue: this.utilService.getFormattedNumber(bu.valueBridgeData.othersImpact)
        // } as any)
      }
    })

    waterFallNumbers.push({
      name: "FX Impact",
      y: fxImpactSum,          
      formattedValue: this.utilService.getFormattedNumber(sumOfEndValues)
    } as any)

    waterFallNumbers.push({
      name: "Net Debt Impact",
      y: netDebtImpactSum,          
      formattedValue: this.utilService.getFormattedNumber(sumOfEndValues)
    } as any)

    waterFallNumbers.push({
      name: "Others Impact",
      y: othersImpactSum,          
      formattedValue: this.utilService.getFormattedNumber(sumOfEndValues)
    } as any)

    waterFallNumbers.push({
      name: this.datePipe.transform(this.endDateForm['valuationDate'], 'mediumDate'),
      y: sumOfEndValues,          
      formattedValue: this.utilService.getFormattedNumber(sumOfEndValues)
    } as any)

    this.waterFallNumbers = waterFallNumbers;
    this.valueBridgeModel = this.waterFallNumbers;
    
    if(sumOfEndValues > 0) {
      // this.drawValuationBridge()

      this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
      const waterFallChartData = this.drawValuationBridge();  
      
      this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers, this.valueBridgeModel);
      // index is 0 by default
      this.saveValuationBridgeData(0)
    }
    this.preparedValueBridge = true;
  }

  reInstateTheValues(){
    //reinstating the values back
    this.startDateValuationBridge = this.startDateValuationBridgeClone;
    this.endDateValuationBridge = this.endDateValuationBridgeClone;
    
    if(this.intermediateDateValuationBridgeClone){
      this.intermediateDateValuationBridge = this.intermediateDateValuationBridgeClone;
    }
    this.initValueBridgeDates();
    this.valueBridgeModel = cloneDeep(this.valueBridgeModelClone);
  }

  initValueBridgeChartAndDraw(apiResponse) {
    if(!apiResponse || !(apiResponse.length > 0)) {
      this.prepareDefaultValueBridge();
      return;
    }

    this.valueBridgeModel = this.getDefaultValueBridge();


    let valueBridgeModelForAllForms = []

    let typeOfValueBridge;
    if(apiResponse.length == 1){
      typeOfValueBridge = 1;
    }
    else if(apiResponse.length > 1){
      typeOfValueBridge = 2;
    }

    apiResponse.forEach( (data, rowIndex) => {
      const res = data.valueBridgeData;
      const tempModel = cloneDeep(this.valueBridgeModel);
      tempModel.forEach((obj: any, index) => {

        if(index == 0){
          obj.y = res.startingValue ? res.startingValue : 0
          if(rowIndex == 0){
            obj.name = this.datePipe.transform(this.startDateForm['valuationDate'], 'mediumDate')
          }else{
            delete tempModel[0];
          }
        }
        else if(index == 1){
          obj.name = this.getValueBridgeMetricLabel(res.key);
          obj.y = res.multipleValueImpact ? res.multipleValueImpact : 0
        }
        else if(index == 2){
          obj.y = res.multipleImpact ? res.multipleImpact : 0
        }
        else if(index == 3){
          obj.y = res.netDebtImpact ? res.netDebtImpact : 0
        }
        else if(index == 4){
          obj.y = res.fxImpact ? res.fxImpact : 0
        }
        else if(index == 5){
          obj.y = res.realisedProceedsImpact ? res.realisedProceedsImpact : 0
        }
        else if(index == 6){
          obj.y = res.othersImpact ? res.othersImpact : 0
        }
        else if(index == 7){
          obj.y = res.endingValue ? res.endingValue : 0
          if(typeOfValueBridge == 1){
            obj.name = this.datePipe.transform(this.endDateForm['valuationDate'], 'mediumDate')
          }
          else{
            if(rowIndex == 0){
              obj.name = this.datePipe.transform(this.intermediateDateForm['valuationDate'], 'mediumDate')
            }else{
              obj.name = this.datePipe.transform(this.endDateForm['valuationDate'], 'mediumDate')
            }
          }
          if(rowIndex < apiResponse.length - 1){
            obj.isIntermediateSum = true;
          }
          else{
            obj.isIntermediateSum = false;
            obj.isSum = true;
          }
        }
  
        obj["formattedValue"] = this.utilService.getFormattedNumber(obj.y);
      })

      if(res.externalGrowth){
        const externalGrowth = {
          formattedValue: this.utilService.getFormattedNumber(res.externalGrowth),
          isIntermediateSum: false,
          name: "External Growth",
          y: res.externalGrowth
        }

        tempModel.splice(1, 0, externalGrowth);
      }

      valueBridgeModelForAllForms = valueBridgeModelForAllForms.concat(cloneDeep(tempModel));
    })
    
    this.valueBridgeModel = valueBridgeModelForAllForms.filter( v => v);
    // this.waterFallNumbers = this.valueBridgeModel;
    this.preparedValueBridge = true;

    // console.log("Value Bridge Model", this.valueBridgeModel);
  }

  getValueBridgeMetricLabel(key) {
    if(key === 'bevEbitda') {
      return "EBITDA";

    } else if(key === 'bevRevenue') {
      return "Revenue"

    } else if(key === 'evGrossProfit') {
      return "Gross Profit"

    } else if(key === 'pbv') {
      return "Book Value of Equity";

    } else if(key === 'psales') {
      return "Revenue";

    } else if(key === 'pe') {
      return "Net Income";

    } else if(key === 'stakeValue') {
      return "Stake Value";

    } else if(key === 'stake') {
      return "Stake";

    } else if(key === 'multiple') {
      return "Multiple";

    } else if(key === 'netDebt') {
      return "Net Debt";

    } else if(key === 'fX') {
      return "FX";

    } else if(key === 'realisedProceeds') {
      return "Realised Proceeds";

    } else if(key === 'enterpriseValue') {
      return "Enterprise Value";

    } else if(key === 'equityValue') {
      return "Equity Value";

    } else {
      return "Metric"
    }
  }

  prepareDefaultValueBridge() {
    let endValue = this.endDateForm['investment'] 
          ? this.utilService.getValidNumber(this.endDateForm['investment'].equityValue.finalAdjustedEquityVal)
          : 0;

    let intermediateSum = 0;

    this.valueBridgeModel.forEach( (obj, index) => {
      if(index == 0) {
        obj.y = this.startDateForm["investment"] ? this.startDateForm['investment'].equityValue.finalAdjustedEquityVal : 0;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;

        intermediateSum += obj.y;
      }

      else if(index == this.valueBridgeModel.length - 1){        
        obj.y = endValue;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;
      }
      else if(index == this.valueBridgeModel.length - 2) {      
        obj.y = endValue - intermediateSum;
        obj["formattedValue"] = obj.y;
        obj["isIntermediateSum"] = false;
      }      
      else {
        obj.y = 0;
        obj["formattedValue"] = 0;
        obj["isIntermediateSum"] = false;

        intermediateSum += obj.y;
      }
    })

    this.waterFallNumbers = cloneDeep(this.valueBridgeModel);
  }

  addPartnerList(){
    this.masterPartnersList.push(this.portfolioService.partnerList);
    if(this.portfolioService.partnerList.isSelected){
      if(!this.savedData.partners){
        this.savedData.partners = []
      }
      this.savedData.partners.push(this.portfolioService.partnerList);
    }
    this.portfolioService.partnerList = {
      name: '',
      isSelected: false
    }
  }

  deletePartnerName(index){
    const removedPartner = this.masterPartnersList.splice(index, 1);
    const deletedPartnerIndex = this.savedData.partners.findIndex( s => s.name.toLowerCase() === removedPartner[0].name.toLowerCase())
    if(deletedPartnerIndex >= 0){
      this.savedData.partners.splice(deletedPartnerIndex, 1);
    }
  }

  addFullyDilutedOwnership(){
    this.savedData.fullyDilutedOwnership = +this.portfolioService.fullyDilutedOwnership;
  }

  addSecurityType(){
    this.savedData.securityType = this.portfolioService.securityType;
    this.portfolioService.securityType = '';
  }

  addPartnerToCompany(event, list){
    if(event){
      if(!this.savedData.partners){
        this.savedData.partners = []
      }
      this.savedData.partners.push(list);
    }else{
      this.savedData.partners = this.masterPartnersList.filter( p => {
        return p.isSelected;
      });
    }
  }

  getDefaultValueBridge() {
    return [
      {
        name: this.datePipe.transform(this.startDateForm['valuationDate'], 'mediumDate'),
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Metric",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Multiple",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Net Debt",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "FX",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Realised Proceeds",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Others",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: this.datePipe.transform(this.endDateForm['valuationDate'], 'mediumDate'),
        y: 0,
        isIntermediateSum: false
      }
    ]
  }

  //Uppdate Value Bridge Data
  preparedValueBridge = false;

  valueBridgeModel = [
      {
        name: "Start Date",
        y: 0,
        isIntermediateSum: false
      },
      {
        // name: "Revenue Growth",
        name: "Metric",
        y: 0,
        isIntermediateSum: false
      },
      {
        // name: "EBITDA Margin Expansion",
        name: "Multiple",
        y: 0,
        isIntermediateSum: false
      },
      {
        // name: "Multiple Expansion",
        name: "Net Debt",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "FX",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Realised Proceeds",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "Others",
        y: 0,
        isIntermediateSum: false
      },
      {
        name: "End Date",
        y: 0,
        isIntermediateSum: false
      }
    ];

  acceptOnlyDigitsVB(event, entry) {
    this.utilService.acceptOnlyNumbers(event, entry, 'y');
    this.calcEndDateValuation();
  }

  showValuationBridgeDataModal(content, valueBridge, rowIndex) {
    if(this.selectedCompanyDates.length === 1) {
      return;
    }

    this.selectedValueBridgeIndex = rowIndex;

    this.startDateValuationBridge = valueBridge.startDateValuationBridge ? valueBridge.startDateValuationBridge : "";
    this.endDateValuationBridge = valueBridge.endDateValuationBridge ? valueBridge.endDateValuationBridge : "";
    this.intermediateDateValuationBridge = valueBridge.intermediateDateValuationBridge ? valueBridge.intermediateDateValuationBridge : "";

    this.valueBridgeName = this.multipleValueBridgeData[this.selectedValueBridgeIndex].name
    this.isMultiValueBridge = this.multipleValueBridgeData[this.selectedValueBridgeIndex].isMultiValueBridge

    this.initValueBridgeDates();

    valueBridge.startDateForm = this.startDateForm ? this.startDateForm : null;
    valueBridge.endDateForm = this.endDateForm ? this.endDateForm : null;
    valueBridge.intermediateDateForm = this.intermediateDateForm ? this.intermediateDateForm : null

    this.valueBridgeModel = cloneDeep(valueBridge.valueBridgeModel);

    this.valueBridgeModel.forEach(row => {
      if(row.y) {
        row["formattedValue"] = this.utilService.getFormattedNumber(row.y);
      }
    })

    this.modalService.open(content, { centered: true , size: 'lg', backdrop: 'static' });
  }

  initValueBridgeDatesDefault() {
    
    if(this.filteredForms.length >= 3){
      this.startDateForm = this.filteredForms[0];
      this.startDateValuationBridge = this.filteredForms[0].valuationDate;
      
      this.intermediateDateForm = this.filteredForms[this.filteredForms.length - 2];
      this.intermediateDateValuationBridge = this.filteredForms[this.filteredForms.length - 2].valuationDate;
      
      this.endDateForm = this.filteredForms[this.filteredForms.length - 1];
      this.endDateValuationBridge = this.filteredForms[this.filteredForms.length - 1].valuationDate;
      
      this.isMultiValueBridge = true;
    }
    else if(this.filteredForms.length == 2){
      this.startDateForm = this.filteredForms[0];
      this.startDateValuationBridge = this.filteredForms[0].valuationDate;

      this.endDateForm = this.filteredForms[this.filteredForms.length - 1];
      this.endDateValuationBridge = this.filteredForms[this.filteredForms.length - 1].valuationDate;

      this.isMultiValueBridge = false;
    }
    else{
      this.preparedValueBridge = true;
      return;
    }
  }

  initValueBridgeDates() {    
    try {
      const date1 = new Date(this.startDateValuationBridge);
      this.startDateForm = this.selectedCompanyDates.find( comp => {
        const valDate = new Date(comp.valuationDate);
        return valDate.getFullYear() === date1.getFullYear()
        && valDate.getDate() === date1.getDate()
        && valDate.getMonth() === date1.getMonth();
      })

      const date2 = new Date(this.endDateValuationBridge);
      this.endDateForm = this.selectedCompanyDates.find( comp => {
        const valDate = new Date(comp.valuationDate);
        return valDate.getFullYear() === date2.getFullYear()
        && valDate.getDate() === date2.getDate()
        && valDate.getMonth() === date2.getMonth();
      })

      if(this.intermediateDateValuationBridge){
        const date3 = new Date(this.intermediateDateValuationBridge);
        this.intermediateDateForm = this.selectedCompanyDates.find( comp => {
        const valDate = new Date(comp.valuationDate);
        return valDate.getFullYear() === date3.getFullYear()
        && valDate.getDate() === date3.getDate()
        && valDate.getMonth() === date3.getMonth();
      })
      }
    } catch(e) {
      this.startDateForm = this.filteredForms[0]
      this.endDateForm = this.filteredForms[this.filteredForms.length - 1]
    }
  }

  addToIntermediateSum(index) {
    this.valueBridgeModel.forEach(entry => {
      entry.isIntermediateSum = false;
    });

    this.valueBridgeModel[index+1].isIntermediateSum = true;
    this.calcEndDateValuation();
  }

  calcEndDateValuation() {
    let total = 0;
    this.valueBridgeModel.forEach((entity, index)=> {
      if(!entity.isIntermediateSum && entity.y && index < (this.valueBridgeModel.length - 1)) {
        total = total + Number(entity.y);
      }
    })

    this.valueBridgeModel[(this.valueBridgeModel.length - 1)].y = total;
  }

  saveValuationBridgeData(selectedValueBridgeIndex) {
    if(!this.valueBridgeModel[0].name || !this.valueBridgeModel[(this.valueBridgeModel.length - 1)].name)
      return;
      
    this.modalService.dismissAll();

    const waterFallChartData = this.drawValuationBridge(this.multipleValueBridgeData[selectedValueBridgeIndex].waterFallGraphShowLabels)
    // this.createMultipleValueBridge(waterFallChartData, this.waterFallNumbers);

    if(this.multipleValueBridgeData[selectedValueBridgeIndex]){
      this.multipleValueBridgeData[selectedValueBridgeIndex].waterFallChartData = cloneDeep(waterFallChartData);
      this.multipleValueBridgeData[selectedValueBridgeIndex].waterFallNumbers = cloneDeep(this.waterFallNumbers);
      this.multipleValueBridgeData[selectedValueBridgeIndex].valueBridgeModel = cloneDeep(this.valueBridgeModel);
      this.multipleValueBridgeData[selectedValueBridgeIndex].name = this.valueBridgeName;
      this.multipleValueBridgeData[selectedValueBridgeIndex].isMultiValueBridge = this.isMultiValueBridge;
    }

    this.valueBridgeModelClone = cloneDeep(this.valueBridgeModel);
    this.startDateValuationBridgeClone = this.startDateValuationBridge;
    this.endDateValuationBridgeClone = this.endDateValuationBridge;

    if(this.intermediateDateValuationBridgeClone){
      this.intermediateDateValuationBridgeClone = this.intermediateDateValuationBridge;
    }
    this.initValueBridgeDates();

    //POST call to save Valuation bridge data
    let valuationBridgeData = {
      startDate: this.datePipe.transform(Date.parse(this.valueBridgeModel[0].name), "dd-MMM-yyyy"),
      endDate: this.datePipe.transform(Date.parse(this.valueBridgeModel[(this.valueBridgeModel.length - 1)].name), "dd-MMM-yyyy"),
      companyId: this.portfolioService.companyId,
      typeOfValueBridge : this.multipleValueBridgeData[selectedValueBridgeIndex].isMultiValueBridge ? 2 : 1,
      centerDate: null,
      data: JSON.stringify(this.valueBridgeModel)
    };

    const typeOfValueBridgeIsTwo = this.valueBridgeModel.find( v => v.isIntermediateSum == true)

    if(typeOfValueBridgeIsTwo){
      valuationBridgeData["centerDate"] = this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy");
    }

    this.dataService.createValuationBridge(valuationBridgeData).subscribe(res => {
      if(!this.savedData.valuationBridgeDates || !isArray(this.savedData.valuationBridgeDates)) {
        this.savedData.valuationBridgeDates = []
      }

      const startDate = new Date(valuationBridgeData.startDate);
      const endDate = new Date(valuationBridgeData.endDate);
      const centerDate = valuationBridgeData.centerDate ? new Date(valuationBridgeData.centerDate) : null;

      this.savedData.valuationBridgeDates = [];
      
      this.multipleValueBridgeData.forEach( data => {
        const typeOfValueBridgeIsTwo = data.valueBridgeModel.find( v => v.isIntermediateSum == true)
        let centerDate = null;
  
        if(typeOfValueBridgeIsTwo){
          centerDate = new Date(this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy"));
        }
  
        const dateCombinationIndex = this.checkForDateCombinationinSavedData(startDate, endDate, centerDate, data.isMultiValueBridge)
  
        if(dateCombinationIndex == -1){
          this.savedData.valuationBridgeDates.push({
            startDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[0].name), "dd-MMM-yyyy"),
            endDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[(data.valueBridgeModel.length - 1)].name), "dd-MMM-yyyy"),
            centerDate: typeOfValueBridgeIsTwo ? this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy") : null,
            name: data.name,
            isMultiValueBridge: data.isMultiValueBridge,
            comments: data.comments
          })
        }
        else if(dateCombinationIndex >= 0){
          this.savedData.valuationBridgeDates[dateCombinationIndex] = {
            startDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[0].name), "dd-MMM-yyyy"),
            endDate: this.datePipe.transform(Date.parse(data.valueBridgeModel[(data.valueBridgeModel.length - 1)].name), "dd-MMM-yyyy"),
            centerDate: typeOfValueBridgeIsTwo ? this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy") : null,
            name: data.name,
            isMultiValueBridge: data.isMultiValueBridge,
            comments: data.comments
          }
        }
      })

      this.saveDataToWidget();

      // this.savedData.valuationBridgeDates["startDate"] = valuationBridgeData.startDate;
      // this.savedData.valuationBridgeDates["endDate"] = valuationBridgeData.endDate;

      // console.log("Valuation Bridge saved successfully");
    }, error => {
      console.log("Error while saving valuation Bridge", error);
    })
  }

  deleteMultiplValueBridgeData(rowIndex){
    if(rowIndex == 0){
      return;
    }

    const deletedValueBridge = this.multipleValueBridgeData.splice(rowIndex, 1);
    
    const typeOfValueBridgeIsTwo = deletedValueBridge[0].valueBridgeModel.find( v => v.isIntermediateSum == true)
    let centerDate : any
    if(typeOfValueBridgeIsTwo){
      centerDate = this.datePipe.transform(Date.parse(typeOfValueBridgeIsTwo.name), "dd-MMM-yyyy");
    }

    let startDate : any= this.datePipe.transform(Date.parse(deletedValueBridge[0].valueBridgeModel[0].name), "dd-MMM-yyyy");
    let endDate : any = this.datePipe.transform(Date.parse(deletedValueBridge[0].valueBridgeModel[(deletedValueBridge[0].valueBridgeModel.length - 1)].name), "dd-MMM-yyyy");

    startDate = new Date(startDate);
    endDate = new Date(endDate);
    centerDate = centerDate ? new Date(centerDate) : null

    const dateCombinationIndex = this.checkForDateCombinationinSavedData(startDate, endDate, centerDate, deletedValueBridge[0].isMultiValueBridge);
    
    this.savedData.valuationBridgeDates.splice(dateCombinationIndex,1); 

    this.saveDataToWidget();
  }

  checkForDateCombinationinSavedData(startDate, endDate, centerDate, isMultiValueBridge){
    let dateCombinationIndex;
      if(isMultiValueBridge){
          dateCombinationIndex = this.savedData.valuationBridgeDates.findIndex( data => {
          const startDate1 = new Date(data.startDate);
          const endDate1 = new Date(data.endDate);
          const centerDate1 = data.centerDate ? new Date(data.centerDate) : null;
          return centerDate1 && centerDate
          && startDate.getFullYear() === startDate1.getFullYear()
          && startDate.getDate() === startDate1.getDate()
          && startDate.getMonth() === startDate1.getMonth()
  
          && endDate.getFullYear() === endDate1.getFullYear()
          && endDate.getDate() === endDate1.getDate()
          && endDate.getMonth() === endDate1.getMonth()
  
          && centerDate.getFullYear() === centerDate1.getFullYear()
          && centerDate.getDate() === centerDate1.getDate()
          && centerDate.getMonth() === centerDate1.getMonth();
        })
      }

      else{
        dateCombinationIndex = this.savedData.valuationBridgeDates.findIndex( data => {
          const startDate1 = new Date(data.startDate);
          const endDate1 = new Date(data.endDate);

          return startDate.getFullYear() === startDate1.getFullYear()
          && startDate.getDate() === startDate1.getDate()
          && startDate.getMonth() === startDate1.getMonth()
  
          && endDate.getFullYear() === endDate1.getFullYear()
          && endDate.getDate() === endDate1.getDate()
          && endDate.getMonth() === endDate1.getMonth()
          && !data.isMultiValueBridge
        })
      }

      return dateCombinationIndex;
  }

  drawValuationBridge(waterFallGraphShowLabels?) {
    this.waterFallNumbers = [];

    let firstIndex = 1;
    this.valueBridgeModel.forEach((entry, index) => {
      if(entry.isIntermediateSum) {
        firstIndex++;
        this.waterFallNumbers.push({name: this.datePipe.transform(entry.name, 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
      } 
      else if(index == 0){
        this.waterFallNumbers.push({name: this.datePipe.transform(entry.name, 'mediumDate'), y: Number(entry.y), color: colors.primaryColor });
      }
      else if(index < (this.valueBridgeModel.length - 1)){
        this.waterFallNumbers.push({name:  this.filteredForms.length >= 3 ? "("+ firstIndex +") " + entry.name : entry.name, y: Number(entry.y)});
      } 
      else {
        this.waterFallNumbers.push({name: this.datePipe.transform(entry.name, 'mediumDate'), isSum: true, color: colors.primaryColor });
      }
    });

    // this.waterFallNumbers[0].name = this.datePipe.transform(this.waterFallNumbers[0].name, 'mediumDate')
    // this.waterFallNumbers[this.waterFallNumbers.length - 1].name = this.datePipe.transform(this.waterFallNumbers[this.waterFallNumbers.length - 1].name, 'mediumDate')

    // Create the chart object.
    const waterFallChartData = this.initwaterFallChart(waterFallGraphShowLabels);

    const finalChartData = cloneDeep(this.waterFallNumbers).filter((row, index) => row.y !== 0 || index === 0 || row.isSum || row.isIntermediateSum);

    console.log("value bridge chart data ------------", finalChartData)
    // add data to chart.
    waterFallChartData.addSeries({
      upColor: "#5FAFF2",
      color: "#5FAFF2",
      data: finalChartData,
      pointPadding: 0
    } as any, true, false);

    this.preparedValueBridge = true;

    return waterFallChartData;
  }
  
  startDateChange(valuation){
    this.startDateValuationBridge = valuation.valuationDate;
    this.startDateForm = valuation
  }

  endDateChange(valuation){
    this.endDateValuationBridge = valuation.valuationDate;
    this.endDateForm = valuation
  }

  intermediateDateChange(valuation){
    this.intermediateDateValuationBridge = valuation.valuationDate;
    this.intermediateDateForm = cloneDeep(valuation);
  }

  openPopupModal(content){
    this.editCompanyDetails = {
      organization: { 
        logo_url: "",
        name: "",
        city: "",
        state: "",
        country: "",
        industry: "",
        estimated_num_employees: undefined,
        website_url: "",
        location: "",
        newKeyPeople: ""
      },
      people: [],
      newKeyPeopleList:[]
    };
    this.prepareEditableCompanyData();

    this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal'});
  }

  async getImageURL(event){
    try {
      const imageUrl = await this.ds.getImageUrl(event.target.files[0], event.target.files[0].name);
      
      const requestBody = {
          url: imageUrl.body["response"],
          companyId: this.portfolioService.companyId
      }
      let res = await this.ds.saveLogoUrl(requestBody);
      const selectedCompany = this.portfolioService.companies.find(comp=> comp.id === this.portfolioService.companyId);    
      const portfolioCompany = this.portfolioService.portfolioData.find( comp => comp.id === this.portfolioService.companyId);

      selectedCompany["logo"] = imageUrl.body["response"];
      portfolioCompany["logo"] = imageUrl.body["response"];
    }
    catch(e) {
      console.log("Error while saving company logo", e);
      this.utilService.showMessage(
        "Failed to save logo for " + this.formData.GENERAL.GD_General_Details.GD_CN_COMPANY_NAME + ". Please try again later.", 
        "Ok", true)      
    }
  }


  saveCompanyDetails(editCompanyContent){
    let editCompanyRequestBody = {
      organization: { 
        logo_url: this.editCompanyDetails.organization.logo_url,
        name: this.portfolioService.fundDetails.organization.name,
        city: this.editCompanyDetails.organization.location,
        state: "",
        country: "",
        industry: this.editCompanyDetails.organization.industry,
        estimated_num_employees: this.editCompanyDetails.organization.estimated_num_employees,
        website_url: this.editCompanyDetails.organization.website_url,
      },
      people: this.editCompanyDetails.people
    };
    this.dataService.saveWidgetDataToDB("USER_ORG_DETAILS", JSON.stringify(editCompanyRequestBody), this.portfolioService.companyId).subscribe(res=>{
      this.portfolioService.fundDetails = editCompanyRequestBody;
      console.log("Company Details saved to db", res.body);
      editCompanyContent.dismiss('Cross click');
    }, error=>{
      console.log("Failed to save Company Details data to db");
    });

  }
  prepareEditableCompanyData() {
    this.editCompanyDetails.organization.city = this.portfolioService.fundDetails.organization.city;
    this.editCompanyDetails.organization.state = this.portfolioService.fundDetails.organization.state;
    this.editCompanyDetails.organization.country = this.portfolioService.fundDetails.organization.country;
    
    if (!this.portfolioService.fundDetails.organization.name || this.portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0) {
      this.editCompanyDetails.organization.industry = this.portfolioService.fundDetails.organization.industry;
    }
    else {
      this.editCompanyDetails.organization.industry = "Defence & Aerospace";
    }
    this.editCompanyDetails.organization.estimated_num_employees = this.portfolioService.fundDetails.organization.estimated_num_employees;
    this.editCompanyDetails.organization.logo_url = this.portfolioService.fundDetails.organization.logo_url;
    this.editCompanyDetails.organization.website_url = this.portfolioService.fundDetails.organization.website_url;
    this.editCompanyDetails.people = this.portfolioService.fundDetails.people;
    if(this.portfolioService.fundDetails.organization.city && this.portfolioService.fundDetails.organization.state && this.portfolioService.fundDetails.organization.country){
    this.editCompanyDetails.organization.location = this.portfolioService.fundDetails.organization.city + "," + this.portfolioService.fundDetails.organization.state + "," + this.portfolioService.fundDetails.organization.country
    }
    else{
      this.editCompanyDetails.organization.location = this.portfolioService.fundDetails.organization.city;
    }
  }

  addKeyPeople() {
    let newKeyPeople = {
      name: this.editCompanyDetails.organization.newKeyPeople
    }; 
    this.editCompanyDetails.people.push(newKeyPeople);
    // console.log(this.editCompanyDetails.people);
  }
  deleteKeyPeople(index) {
    this.editCompanyDetails.people.splice(index, 1);
  }

  getKeywords() {
    this.portfolioService.orgKeyWords = [];

    this.dataService.getWidgetDataFromDB("ORG_KEYWORDS", this.portfolioService.companyId).subscribe((res) => {
      this.portfolioService.orgKeyWords = res.body["response"][0].widgetData;
      // console.log("keywords fetched successfully", this.portfolioService.orgKeyWords);

    }, error => {

      const keyWordsAPI_Input = {
        uuid: "Test",
        isNewApi: true,
        text: this.companyDescription
      }

      this.dataService.getTagsForText(keyWordsAPI_Input).subscribe((res) => {

        const response = res.body["response"]

        if(!!response){
          this.portfolioService.orgKeyWords = response.map(n => n.tag.replaceAll("_", " "));
          // console.log("keywords fetched successfully", this.portfolioService.orgKeyWords);
        }

      }, error => {
        console.log("Error: While fetching keywords based on company description", error.error);
      });
    });
  }


  //Excel Datastructure
  actualPerformanceDataModel = {
    header1: ["", ""],
    header2: ["Actual", "Budget", "Delta(%)", "Actual", "Budget", "Delta(%)"],
    rows: [
        {
          label: "Revenue", 
          values: [] 
        },
        {
          label: "Gross Profit", 
          values:[]
        },
        {
          label: "GP Margin(%)", 
          values: []
        } ,
        {
          label: "EBITDA", 
          values: []
        },
        {
          label: "EBITA Margin(%)", 
          values: []
        }, 
        {
          label: "EBIT",
          values: []
        }
    ]
  };

  createActualPerformanceGraph() {
    let graphType = this.actualPerformanceDataModel["graphType"];
    if(!graphType) {
      graphType = "quarter";
      this.actualPerformanceDataModel["graphType"] = graphType;
    }

    let valueIndex = 0;
    let seriesLabel = "Second Quarter";

    if(graphType == "year") {
      valueIndex = 3;
      seriesLabel = "Year to date";
    }

    const xAxis = ["Revenue", "EBITDA"];

    const series = [
      {
        name: seriesLabel + ' - Actual',
        color: colors.primaryColor,
        data: [this.actualPerformanceDataModel.rows[0].values[valueIndex], this.actualPerformanceDataModel.rows[3].values[valueIndex]]
      },
      {
        name: seriesLabel + ' - Budget',
        color: colors.secondaryColor,
        data: [this.actualPerformanceDataModel.rows[0].values[valueIndex+1], this.actualPerformanceDataModel.rows[3].values[valueIndex+1]]
      }
    ]

    this.actualPerformanceGraph = new Chart({
        // chart: { polar: true, type: 'line'},
        chart: { type: "bar" },
        credits: { enabled: false },
        accessibility: { description: '' },
        title: { text: '' },
        xAxis: {
            categories: xAxis,
            title: { text: ""} 
        },

        yAxis: { title: { text: ""} },

        tooltip: {
            shared: true,
            pointFormat: '<span style="color:{series.color}" : return "{series.name}: <b>{point.y:,.0f}</b><br/>'
        },

        legend: { enabled: true },

        series: series as any,

        responsive: {
            rules: [{
                condition: { maxWidth: 500 }
            }]
        }

    });
  }

  savePerformanceData() {
    // console.log("Save performance data");
    // console.log(this.actualPerformanceDataModel);

    if(!this.actualPerformanceDataModel["graphType"]) {
      this.actualPerformanceDataModel["graphType"] = "quarter"
    }

    this.createActualPerformanceGraph();
    this.savedData["actualPerformance"] = this.actualPerformanceDataModel;
    this.saveDataToWidget();
    this.actualVsPlannedGrid.refresh();
    //Save excelDataModel
  }

  /**
   * Valuation Date Upper Limit Change
   */

  
  openValdateLimitPopUp(content){
    this.modalService.open(content, {backdrop: 'static', size: 'sm', centered: true });
  }

  saveValDateUpperLimit(popUp) {
    popUp.dismiss('Close Popup');

    this.utilService.showLoadingPopup();

    const oldValDateUpperLimit = this.portfolioService.valDateUpperLimit[this.portfolioService.companyId];

    this.portfolioService.valDateUpperLimit[this.portfolioService.companyId] = this.valDateUpperLimit;

    this.ds.saveWidgetDataToDB("VAL_DATES_UPPERLIMIT", this.portfolioService.valDateUpperLimit, this.portfolioService.selectedFundId).subscribe(res => {
      window.location.reload();

    },error =>{
      console.log("error while saving VAL_DATES_UPPERLIMIT", error);

      this.utilService.showMessage("Failed to refresh the page. Please try later.", "Ok", true);
      this.portfolioService.valDateUpperLimit[this.portfolioService.companyId] = oldValDateUpperLimit;
    })
  }
  
}