<div class="card">
  <br>

  <div class="row">
    <div class="col"></div>
    <div class="col-2">
      <!-- <button mat-raised-button class="primary-light-bg p-button" style="position: absolute; right: 20px;" (click)="closeInvestments()">Close</button> -->
    </div>
  </div>

  <div class="row" style="margin: 0">
    <div class="col-4" style="padding: 0 35px;">{{translateService.getLabel("valuation_date")}}</div>

    <div class="col" style="padding: 0 35px;">{{translateService.getLabel("add_investors")}}</div>

    <div class="col-2" style="text-align: center;">
      <button mat-raised-button class="primary-light-bg p-button" (click)="saveInvestments(true)">{{translateService.getLabel("save")}}</button>
      <button mat-raised-button class="primary-light-bg p-button" style="margin-left: 10px;" (click)="closeInvestments()">{{translateService.getLabel("close")}}</button>
    </div>
  </div>
 
  <div class="row" style="margin: 0">
    <div class="col-4">
      <mat-form-field class="valulation_drop">
        <mat-select [value]="selectedDate?.valuationDate">
          <mat-option *ngFor="let valDate of allValuationDates" [value]="valDate.valuationDate" (click) = "valuationDateChange(valDate)">{{valDate.valuationDate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-spinner style="display: inline-block; margin-bottom: -6px" [diameter] = "20" *ngIf = "loadingCaptable"></mat-spinner>
    </div>


    <div class="col">

      <mat-form-field class="example-full-width">
        <mat-label>{{translateService.getLabel("select")}}</mat-label>
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="addOption(option)">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-spinner style="display: inline-block; margin-bottom: -6px" [diameter] = "20" *ngIf = "loadingInvestorName"></mat-spinner>
    </div>
  </div>

  <p *ngIf = "!capTableExists" class="warning">{{ warningMsg }}</p>

  <mat-chip-list #chipList class="chipsvaldate">
    <mat-chip *ngFor="let value of investors; let index=index" class="chip">
      {{value.name}}
      <mat-icon matChipRemove (click)="removeOption(index)" class="close-mat-icon" >close</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <div class="row">
    <table class="table borderless" *ngIf = "!loadingCaptable && portfolioService.investmentTableData && portfolioService.investmentTableData.length > 0">
      <thead>
        <tr>
          <th class="table-heading leftAlign">
            {{translateService.getLabel("inter-party_summary")}}
          </th>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data>
            <th class="table-heading rightAlign">
              <span (click)="enableSelector(data.name, data.bsAssignment)" style="cursor: pointer;">
                {{ data.name  }}
              </span>
              <ng-template [ngIf]="bsAssignmentName == data.name" [ngIfElse] = "hideDropDown">
                <mat-form-field class="custom-textbox">

                  <mat-select [(ngModel)]="selectedBSAssignment" (ngModelChange)="addSelectedBSAssignment(selectedBSAssignment)">                   
                    <mat-option value="aumTotal">AUM {{translateService.getLabel("total")}} </mat-option>
                    <mat-option value="fundCarryco"> {{translateService.getLabel("fund (+carryco)")}} </mat-option>
                    <mat-option value="LPs"> {{translateService.getLabel("lps")}} </mat-option>
                  </mat-select>

                </mat-form-field>
              </ng-template>

              <ng-template #hideDropDown> 
                <span *ngIf = "data.bsAssignment == 'aumTotal' ">
                  &nbsp;(AUM {{translateService.getLabel("total")}})
                </span>
                <span *ngIf = "data.bsAssignment == 'fundCarryco'">
                  &nbsp;({{translateService.getLabel("fund (+carryco)")}})
                </span>
                <span *ngIf = "data.bsAssignment == 'LPs'">
                  &nbsp;({{translateService.getLabel("lps")}})
                </span>
              </ng-template>

            </th>
          </ng-template>
        </tr>
      </thead>

      <tbody>
        <tr >
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("stake")}}
            </td>
            <td class="table-data">
              {{data.stake | number: '1.2-2'}} %
            </td>
          </ng-template>
        </tr>
  
        <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
          
        </ng-template>
  
        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("stake_value")}}
            </td>
            <td class="table-data">
              <ng-template [ngIf] = "data.name == selectedFund.name" [ngIfElse] = "capTableInvestors">
                {{data.adjustedStakeValue | number: '1.2-2'}}
              </ng-template>
              <ng-template #capTableInvestors>
                <i class="fas fa-edit" (click)="openAdjustmentsPopUp(adjustmentsPopup, data)"></i>
                {{data.adjustedStakeValue | number: '1.2-2'}}
              </ng-template>
            </td>
          </ng-template>
        </tr>
  
        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("realised_proceeds")}}
            </td>
            <td class="table-data">
              {{data.realisedProceeds | number: '1.2-2'}}
            </td>
          </ng-template>
        </tr>
  
        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("investment")}}
            </td>
            <td class="table-data">
              {{data.investmentAmount | number: '1.2-2'}}
            </td>
          </ng-template>
        </tr>
  
        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("IRR")}}
            </td>
            <td class="table-data">
              {{data.irr * 100 | number: '1.2-2'}} %
            </td>
          </ng-template>
        </tr>

        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("moic")}}
            </td>
            <td class="table-data">
              {{data.moic | number: '1.2-2'}} x
            </td>
          </ng-template>
        </tr>

        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("no_of_shares (#)")}}
            </td>
            <td class="table-data">
              {{ data.noOfShares | number: "1.2-2" }}
              <!-- <mat-form-field style="width: 25%" class="text-area"> 
                <input matInput class="label" type="text"  style="margin: 0px; text-align: right;"
                  [(ngModel)]="data.noOfShares" (ngModelChange)="saveNoOfShares(data)">             
              </mat-form-field> -->
            </td>
          </ng-template>
        </tr>

        <tr>
          <ng-template ngFor [ngForOf] = "portfolioService.investmentTableData" let-data let-index = "index">
            <td *ngIf = "index == 0" class = "leftAlign">
              {{translateService.getLabel("price_per_share")}}
            </td>
            <td class="table-data">
              <ng-template [ngIf] = "data.noOfShares > 0" [ngIfElse] = "zeroShares">
                {{(data.stakeValue * 1000000 / data.noOfShares) | number: '1.2-2'}}
              </ng-template>
              <ng-template #zeroShares>
                0.00
              </ng-template>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #adjustmentsPopup let-adjustments>

  <!-- <div class="adjustment-popup"> -->
  <div class="modal-header">
    <div class="modal-title">
      <h4>
        {{ stakeValueAdjustments.headerLabel }}
      </h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="adjustments.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-adjustments [totalValueBeforeAdjustment]="stakeValueAdjustments.totalValueBeforeAdjustment"
      headerLabel = '{{translateService.getLabel("stake_value")}}'
      (finalAdjustments)="emittedAdjustedStakeValue($event); adjustments.dismiss('Cross click')"
      [existingAdjustments]="stakeValueAdjustments">
    </app-adjustments>
  </div>
  <!--</div>-->
</ng-template>