import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from '../date-util.service';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translation.service';
import { UserManagementService } from '../services/user-management.service';
import { UtilService } from '../utils/util.service';
import { ValueBridgeFundLevelService } from '../value-bridge-fund-level/value-bridge-fund-level.service';

@Component({
  selector: 'app-value-bridge-fund-level-v2',
  templateUrl: './value-bridge-fund-level-v2.component.html',
  styleUrls: ['./value-bridge-fund-level-v2.component.scss']
})
export class ValueBridgeFundLevelV2Component implements OnInit {

  isMultiValueBridge = true;
  isNewMultiValueBridge = true;
  isUpdateMultiValueBridge = true;

  fundLevelVBData = [];

  valBridgeFundLevel = {
    startDate : new Date(),
    endDate : new Date(),
    intermediateDate : new Date()
  }
  valueBridgeName = this.translateService.getLabel("new_value_bridge") + " 1";

  defaultValueBridge;

  savedValueBridges = [];

  datesInValueBridges = [];

  fundId;
  valDates;

  totalAggregationsData;

  isDownloading = false;

  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;
  

  constructor(public portfolioService: PortFolioSummaryServiceV2, private datePipe : DatePipe,
    public utilService : UtilService, public ds: DataService, private activatedRoute : ActivatedRoute,
    private ums: UserManagementService, private modalService : NgbModal,
    private vbService : ValueBridgeFundLevelService, public translateService: TranslateService,
    public fundService : FundListService, public dateUtil : DateUtilService) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.fundId = params.get("fundId");
      this.portfolioService.selectedFundId = this.fundId;
      try {
          const res = await this.ds.getWidgetDataFromDB("FUND_VB", this.fundId).toPromise();
          this.savedValueBridges = res["body"]["response"][0].widgetData;
          this.initDates();
        }
        catch(e) {
          console.log("Error Retrieving data from Widget"+ JSON.stringify(e));
        }

      try {
        const currencyResponse = await this.ds.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.fundId).toPromise();
        let widgetData = currencyResponse["body"]["response"][0].widgetData;
        this.portfolioService.selectedCurrency = widgetData.selectedCurrency;
        this.portfolioService.mapData.value = widgetData.valuationMap;
      }
      catch(e) {
        console.log("Error Retrieving data from Widget"+ JSON.stringify(e));
      }
    })
  }

  initDates(){
    this.datesInValueBridges = [];
    this.savedValueBridges.forEach(ele => {
    
      const startDate = this.dateUtil.convertDDMMYYYYToDate(ele.dates.startDate);
      const intermediateDate = this.dateUtil.convertDDMMYYYYToDate(ele.dates.intermediateDate);
      const endDate = this.dateUtil.convertDDMMYYYYToDate(ele.dates.endDate);

      this.datesInValueBridges.push({
        startDate, intermediateDate, endDate
      })
    })
  }

  async fetchValuationDataForWaterfallChart(valueBridgeInput){
    
    let valDatesToBeConsideredForFundVB = [];
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(valueBridgeInput.dates.startDate, "yyyy-MM-dd"));
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(valueBridgeInput.dates.intermediateDate, "yyyy-MM-dd"));
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(valueBridgeInput.dates.endDate, "yyyy-MM-dd"));

    if(valueBridgeInput.system == 2){
      valDatesToBeConsideredForFundVB.splice(1, 1);
    }

    const userId = this.ums.getSelectedUserDetails().id;

    let fundObj = this.fundService.getFundById(this.fundId);

    let data;

    if(fundObj.type == "FUND"){
      const reqBody = {
        "valuationDates": valDatesToBeConsideredForFundVB,
        "destinationCurrency": this.portfolioService.selectedCurrency
      }

      const res = await this.ds.getFundLevelVB(this.fundId, userId, reqBody).toPromise();
      
      data = res.body["response"];
    }
    else{
      let reqBody = {
        "valuationDates":  valDatesToBeConsideredForFundVB
      }

      try {
        const res = await this.ds.getValueBridgeForYellowReport(reqBody, this.fundId).toPromise();
        data = res.body["response"];
      }
      catch(e){
        console.log("Error Retrieving data for yellow report"+ JSON.stringify(e));
      }
    }

    //Seggregating the Value Bridge Data and Old/New Data For Excel Download
    const oldAndNewData = data.map(d => {
      return {
        companyId: d.companyId,
        companyName: d.companyName,
        oldAndNewData: d.oldAndNewData
      }
    })

    const bridgeData = data.map(d => {
      return {
        companyId: d.companyId,
        companyName: d.companyName,
        valueBridgeData: d.valueBridgeData
      }
    })

    // Saving the Old/New Data for Excel download with respective vbId
    this.ds.saveWidgetToDBAsync(valueBridgeInput.vbId, oldAndNewData, this.fundId)

    valueBridgeInput.data = bridgeData;
    
  }

  async addNewValueBridge(){
    let startDate = this.dateUtil.getDateYYYYMMDD(this.valBridgeFundLevel.startDate);
    let intermediateDate = this.dateUtil.getDateYYYYMMDD(this.valBridgeFundLevel.intermediateDate);
    let endDate = this.dateUtil.getDateYYYYMMDD(this.valBridgeFundLevel.endDate);

    const dates = {
      startDate: startDate,
      intermediateDate: intermediateDate,
      endDate: endDate
    }
    
    const vbInput = {
      dates : dates,
      system: this.isNewMultiValueBridge ? 3: 2,
      name: this.valueBridgeName,
      currency: this.portfolioService.selectedCurrency,
      vbId: "FUND_VB-" + this.utilService.getUID()
    }

    this.utilService.showLoadingPopup();

    this.seggregateAndSaveValueBridgeDataAndOldNewData(vbInput, null);
  }

  seggregateOldAndNewData(vbData){
    const seggregatedData = vbData.data.map(vb => {
      return {
        companyId: vb.companyId,
        companyName: vb.companyName,
        oldAndNewData: vb.oldAndNewData,
      }
    })

    vbData.data.forEach(vb => {
      delete vb.oldAndNewData;
    })

    return seggregatedData;
  }

  async updateValueBridgeData(index, bridge){

    let valDatesToBeConsideredForFundVB = [];
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(bridge.dates.startDate, "yyyy-MM-dd"));
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(bridge.dates.intermediateDate, "yyyy-MM-dd"));
    valDatesToBeConsideredForFundVB.push(this.datePipe.transform(bridge.dates.endDate, "yyyy-MM-dd"));

    const dates = {
      startDate: valDatesToBeConsideredForFundVB[0],
      intermediateDate: valDatesToBeConsideredForFundVB[1],
      endDate: valDatesToBeConsideredForFundVB[2]
    }
    
    const vbInput = {
      dates : dates,
      system: bridge.system,
      name: bridge.name,
      currency: this.portfolioService.selectedCurrency,
      vbId: bridge.vbId ? bridge.vbId : "FUND_VB-" + this.utilService.getUID()
    }

    this.utilService.showLoadingPopup();

    this.seggregateAndSaveValueBridgeDataAndOldNewData(vbInput, index)
  }

  async seggregateAndSaveValueBridgeDataAndOldNewData(vbInput, index) {
    try {
      await this.fetchValuationDataForWaterfallChart(vbInput);

      if(index == null){
        this.savedValueBridges.push(vbInput);
      }
      else {
        this.savedValueBridges[index] = vbInput;
      }
      
      this.initDates();

      this.utilService.closeAllPopups();

      this.ds.saveWidgetToDBAsync("FUND_VB", this.savedValueBridges, this.fundId);

    } catch(error) {
      this.utilService.showMessage(this.translateService.getLabel("err_failed_fetch_value_bridge_data"), this.translateService.getLabel("ok"), true);
    }
  }

  deleteSelectedValueBridgeData(index){
    this.savedValueBridges.splice(index, 1);

    this.initDates();

    this.ds.saveWidgetToDBAsync("FUND_VB", this.savedValueBridges, this.fundId);
  }

  showValuationBridgeDataModal(content){
    this.modalService.open(content, { centered: true , size: 'lg', backdrop: 'static' });
  }

  getNewSelectedDate(yearDateObject, index, valDateType){
    let selectedDate = this.dateUtil.getDateYYYYMMDD(yearDateObject);

    this.savedValueBridges[index]["dates"][valDateType] = selectedDate;
    
    this.datesInValueBridges[index][valDateType] = yearDateObject;
  }

  getValDatesToBeConsideredForFundLevelVB(){
    let valDates = [];

    const currentDateObj = new Date();

    //Current Quarter
    const currentYear = currentDateObj.getFullYear();

    const quarterName = this.utilService.getQuarterName(this.datePipe.transform(currentDateObj, 'yyyy-MM-dd'));

    const currentQuarterMonth = this.utilService.getIndexOfMonthByQuarterName(quarterName);

    const currentQuarterDate = this.datePipe.transform(new Date(currentYear, currentQuarterMonth, 0), 'yyyy-MM-dd') //Identifying the last Day of the month
    valDates.push(currentQuarterDate);

    //Previous Quarter
    const prevQuarter = this.getPrevQuarterDateByMonthAndYear(currentQuarterMonth, currentYear);
    valDates.push(prevQuarter.prevQuarterDate);

    //Previous To Previous Quarter
    const prevToPrevQuarter = this.getPrevQuarterDateByMonthAndYear(prevQuarter.previousQuarterMonth, prevQuarter.prevYear);

    valDates.push(prevToPrevQuarter.prevQuarterDate);

    valDates = valDates.sort((date1, date2) => {
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2);
      
      const isdate1GreaterThanDate2 = this.utilService.compareDates(date1Obj, date2Obj);
      
      return isdate1GreaterThanDate2 == -1 ? 1 : isdate1GreaterThanDate2 == 0 ? 0 : -1;
    })

    return valDates;
  }

  getPrevQuarterDateByMonthAndYear(currentQuarterMonth, currentYear){
    let prevYear = currentYear;
    let previousQuarterMonth = 0;

    if(currentQuarterMonth == 3){
      prevYear = currentYear - 1;
      previousQuarterMonth = 12
    }
    else if(currentQuarterMonth == 6 || currentQuarterMonth == 9 || currentQuarterMonth == 12 ){
      prevYear = currentYear;
      previousQuarterMonth = currentQuarterMonth - 3;
    }

    const prevQuarterDate = this.datePipe.transform(new Date(prevYear, previousQuarterMonth, 0), 'yyyy-MM-dd')

    return {
      prevQuarterDate: prevQuarterDate,
      previousQuarterMonth: previousQuarterMonth,
      prevYear : prevYear
    }
  }

  downloadFundLevelValueBridgeExcel(valueBridge){
    if(this.isDownloading) return;

    this.utilService.showLoadingPopup();

    this.ds.getWidgetDataFromDB(valueBridge.vbId, this.fundId).subscribe(res => {
      let widgetData = res["body"]["response"][0].widgetData;
      const dataToDownloadExcel = valueBridge.data.map(vb => {
        const dataFound = widgetData.find(w => w.companyId == vb.companyId);
        vb.oldAndNewData = dataFound.oldAndNewData
        return vb;
      })


      let fileData = [];
      if(valueBridge.system == 3){
        const formattedValueBridgeData = this.vbService.getFormattedValueBridgeDataForMultipleBridgeSystem(dataToDownloadExcel);
        this.totalAggregationsData = this.vbService.getAggregatedNumbersForMultiBridgeSystem(formattedValueBridgeData);
        
        fileData.push(this.getMultiValueBridgeForFundLevel(dataToDownloadExcel));
      }
      else{
        const formattedValueBridgeData = this.vbService.getFormattedValueBridgeDataForOneBridgeSystem(dataToDownloadExcel);
        this.totalAggregationsData = this.vbService.getAggregatedNumbersForOneBridgeSystem(formattedValueBridgeData);
        
        fileData.push(this.getOneValueBridgeForFundLevel(dataToDownloadExcel));
      }
      
      this.isDownloading = true;

      this.ds.downloadExcelFileV2(fileData).subscribe((fileData)=>{
        this.manageFileDownload(fileData, "xlsx");
      }, err => {
        console.log("Failed to download Fund Level value bridge file", err)
        this.utilService.closeAllPopups();
        this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"));
        this.isDownloading = false;
      });
    }, error => {
      this.utilService.closeAllPopups();
      this.utilService.showMessage(this.translateService.getLabel("Please refresh the Value Bridge to download the Excel"), 
      this.translateService.getLabel("ok"));
    })

  }

  manageFileDownload(fileData, type) {
    let fundName= this.fundService.getFundName(this.portfolioService.selectedFundId);
    const currentDate = this.datePipe.transform(new Date(), "dd-MMM-yyyy");
    
    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.download = fundName + "_" + currentDate + "_Fund Level Value Bridge." + type;
      this.downloadLink.nativeElement.click();
      this.utilService.closeAllPopups();
    } catch(e) {
      console.log("Failed to download file", e)
      this.utilService.closeAllPopups();
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    }

    this.isDownloading = false;
  }

  getCellObject(type, value, decimal?){
    if(type == "text"){
      const obj = {
        "type": type,
        "value": value
      }

      return obj
    }
    else{
      if(decimal){
        const obj = {
          "type": type,
          "value": value,
          "decimal": decimal
        }
  
        return obj
      }
      else{
        const obj = {
          "type": type,
          "value": value
        }
  
        return obj
      }
 
    }
  }

  getWaterfallImpact(valBridgeData){
    if(valBridgeData.newData.equityValueFromWaterfall == 0 && valBridgeData.oldData.equityValueFromWaterfall != 0){
      return  this.getCellObject("formula",
                "(" + 0
                + "-(" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.equityValueFromWaterfall)
                + "-(" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.equityValueFromValSummary)
                + ")))*" + this.utilService.convertExponentialToNumber(valBridgeData.newData.stake)
                + "*" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.fX), "1");
    }
    else if(valBridgeData.newData.equityValueFromWaterfall != 0 && valBridgeData.oldData.equityValueFromWaterfall == 0){
      return  this.getCellObject("formula",
                "((" + this.utilService.convertExponentialToNumber(valBridgeData.newData.equityValueFromWaterfall)
                + "-(" + this.utilService.convertExponentialToNumber(valBridgeData.newData.equityValueFromValSummary)
                + "))-" + 0
                + ")*" + this.utilService.convertExponentialToNumber(valBridgeData.newData.stake)
                + "*" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.fX), "1");
    }
    else if(valBridgeData.newData.equityValueFromWaterfall == 0 && valBridgeData.oldData.equityValueFromWaterfall == 0){
      return  this.getCellObject("formula",
                "(" + 0 + "-" + 0
                + ")*" + this.utilService.convertExponentialToNumber(valBridgeData.newData.stake)
                + "*" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.fX), "1");
    }
    else{
      return this.getCellObject("formula",
              "((" + this.utilService.convertExponentialToNumber(valBridgeData.newData.equityValueFromWaterfall)
              + "-(" + this.utilService.convertExponentialToNumber(valBridgeData.newData.equityValueFromValSummary)
              + "))-(" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.equityValueFromWaterfall)
              + "-(" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.equityValueFromValSummary)
              + ")))*" + this.utilService.convertExponentialToNumber(valBridgeData.newData.stake)
              + "*" + this.utilService.convertExponentialToNumber(valBridgeData.oldData.fX), "1");
    }

  }

  getMultiValueBridgeForFundLevel(vbData){

    const values = [];

    let headers = ["Company", vbData[0].valueBridgeData[0].startDate, "Metric Impact", "Multiple Impact", "Net Debt and Other Balance Sheet Adjustments Impact", 
    "Adjustment to Equity Value Impact", "FX Impact", "Stake Impact", "Waterfall Impact", "Others Impact", "Realised Proceeds Impact", "Adjustment to Stake Equity Value Impact", 
    "New Investments Impact", "Exit Investments Impact", vbData[0].valueBridgeData[0].endDate,
    "Metric Impact", "Multiple Impact", "Net Debt and Other Balance Sheet Adjustments Impact", "Adjustment to Equity Value Impact", "FX Impact", "Stake Impact", "Waterfall Impact", "Others Impact", 
    "Realised Proceeds Impact", "Adjustment to Stake Equity Value Impact", "New Investments Impact", "Exit Investments Impact", 
    vbData[0].valueBridgeData[1].endDate] 

    vbData.forEach(ele => {
      let i = 0;
      let valueBridgeFirstHalf = ele.valueBridgeData[i];
      let valueBridgeSecondHalf = ele.valueBridgeData[i + 1];

      let fX = ele.oldAndNewData[i].oldData.fX;
      let fX2 = ele.oldAndNewData[i+1].oldData.fX;

      let metricKey = ele.valueBridgeData[0].metricKey;

      let oldAndNewDataFirstHalf = ele.oldAndNewData[i];
      let oldAndNewDataSecondHalf = ele.oldAndNewData[i+1];

      if(Object.keys(oldAndNewDataFirstHalf.oldData).length !== 0 
        && Object.keys(oldAndNewDataFirstHalf.newData).length !== 0 
        && Object.keys(oldAndNewDataSecondHalf.oldData).length !== 0 
        && Object.keys(oldAndNewDataSecondHalf.newData).length !== 0){
        console.log("both one and two", ele.companyName)
        values.push([
          this.getCellObject("text", ele.companyName), 
          this.getCellObject("number", valueBridgeFirstHalf.startStakeValue, "1"),
          
          //Metric Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData[metricKey] )
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData[metricKey]) 
          + "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.multiple) 
          + "*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake )
          + "*" + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //Multiple Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.multiple) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.multiple)
          + "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData[metricKey]) 
          + "*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //NetDebt Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.netDebt) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.netDebt) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),

          //Adjustment to Equity Value Impact
          this.getCellObject("formula",
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.adjustmentsToEquityValue) + 
          "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.adjustmentsToEquityValue) + 
          "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) +
          "*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.fX), "1"),
          
          //Fx Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.newFX) + "-" 
          + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.oldFX) + ")*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stakeValue), "1"),
          
          //Stake Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) 
          + "-(" +this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.stake) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.equityValue)
          + "*" + this.utilService.convertExponentialToNumber(fX), "1"),

          //Waterfall Impact
          this.getWaterfallImpact(oldAndNewDataFirstHalf),
          
          //Others Impact
          this.getCellObject("formula", 
          this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.endStakeValue) 
          + "- (" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.startStakeValue )
          + "+(" +  this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.metricImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.multipleImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.netDebtImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.adjustmentsToEquityValueImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.fxImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.waterfallImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.stakeImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.othersDebtImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.realisedProceedsImpact )
          + ")" + ")", "1"),
          
          //Realised Proceeds Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.realisedProceeds )
          + "-(" +this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.realisedProceeds) 
          + "))*" + -1 + "*" + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //Debt and Others Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.debtAndOthers) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.debtAndOthers) 
          + "))*" + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //New Investments Impact
          this.getCellObject("number", valueBridgeFirstHalf.newInvestments, "1"),
          
          //Exit Investments Impact
          this.getCellObject("number", valueBridgeFirstHalf.exitInvestments, "1"),
          
          //Intermediate Date value
          this.getCellObject("number", valueBridgeSecondHalf.startStakeValue, "1"),
    
          //Metric Impact
          this.getCellObject("formula", 
          "(" + oldAndNewDataSecondHalf.newData[metricKey]
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData[metricKey]) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.multiple) 
          + "*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) 
          + "*" + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Multiple Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.multiple) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.multiple) +"))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData[metricKey]) 
          + "*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) 
          + "*" + this.utilService.convertExponentialToNumber(fX2), "1"),

          //NetDebt Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.netDebt) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.netDebt) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Adjustment to Equity Value Impact
          this.getCellObject("formula",
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.adjustmentsToEquityValue) + 
          "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.adjustmentsToEquityValue) + 
          "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) +
          "*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.fX), "1"),

          //Fx Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.newFX) 
          + "-" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.oldFX) + ")*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stakeValue), "1"),

          //Stake Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) 
          + "- (" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.stake) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.equityValue) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Waterfall Impact
          this.getWaterfallImpact(oldAndNewDataSecondHalf),
          
          //Others Impact
          this.getCellObject("formula", 
          this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.endStakeValue) 
          + "- (" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.startStakeValue) 
          + "+(" +  this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.metricImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.multipleImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.netDebtImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.adjustmentsToEquityValueImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.fxImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.waterfallImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.stakeImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.othersDebtImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.realisedProceedsImpact) + ")" + ")", "1"),
          
          //Realised Proceeds Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.realisedProceeds) 
          + "- (" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.realisedProceeds) + "))*" 
          + -1 + "*" + this.utilService.convertExponentialToNumber(fX2), "1"),
          
          //Debt and Others Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.debtAndOthers) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.debtAndOthers) + "))*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),
          
          //New Investments Impact
          this.getCellObject("number", valueBridgeSecondHalf.newInvestments, "1"),
          //Exit Investments Impact
          this.getCellObject("number", valueBridgeSecondHalf.exitInvestments, "1"),
    
          //End Date value
          this.getCellObject("number", valueBridgeSecondHalf.endStakeValue, "1")
        ])
      }
      else if(Object.keys(oldAndNewDataFirstHalf.oldData).length !== 0 
        && Object.keys(oldAndNewDataFirstHalf.newData).length !== 0 
        && Object.keys(oldAndNewDataSecondHalf.oldData).length === 0 
        && Object.keys(oldAndNewDataSecondHalf.newData).length === 0){
        console.log("only first", ele.companyName);

        values.push([
          this.getCellObject("text", ele.companyName), 
          this.getCellObject("number", valueBridgeFirstHalf.startStakeValue, "1"),
          //Metric Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData[metricKey]) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData[metricKey]) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.multiple) + "*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),

          //Multiple Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.multiple) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.multiple) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData[metricKey]) + "*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) + "*" 
          + fX, "1"),
          
          //NetDebt Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.netDebt) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.netDebt) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),

          //Adjustment to Equity Value Impact
          this.getCellObject("formula",
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.adjustmentsToEquityValue) + 
          "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.adjustmentsToEquityValue) + 
          "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) +
          "*" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.fX), "1"),

          //Fx Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.newFX) + "-" 
          + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.oldFX) + ")*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stakeValue), "1"),
          
          //Stake Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.stake) 
          + "-(" +this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.stake) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.equityValue) + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),

          //Waterfall Impact
          this.getWaterfallImpact(oldAndNewDataFirstHalf),
         
          //Others Impact
          this.getCellObject("formula", 
          this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.endStakeValue) 
          + "- (" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.startStakeValue) 
          + "+(" +  this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.metricImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.multipleImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.netDebtImpact)
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.adjustmentsToEquityValueImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.fxImpact)
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.waterfallImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.stakeImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.othersDebtImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeFirstHalf.realisedProceedsImpact) + ")" + ")", "1"),
          
          //Realised Proceeds Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.realisedProceeds) 
          + "-(" +this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.realisedProceeds) + "))*" + -1 + "*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //Debt and Others Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.newData.debtAndOthers) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataFirstHalf.oldData.debtAndOthers) + "))*" 
          + this.utilService.convertExponentialToNumber(fX), "1"),
          
          //New Investments Impact
          this.getCellObject("number", valueBridgeFirstHalf.newInvestments, "1"),
          
          //Exit Investments Impact
          this.getCellObject("number", valueBridgeFirstHalf.exitInvestments, "1"),
          
          //Intermediate Date value
          this.getCellObject("number", valueBridgeSecondHalf.startStakeValue, "1"),

          this.getCellObject("number", valueBridgeSecondHalf.metricImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.multipleImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.netDebtImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.adjustmentsToEquityValueImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.fxImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.stakeImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.waterfallImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.othersImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.realisedProceedsImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.othersDebtImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.newInvestments, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.exitInvestments, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.endStakeValue, "1")
        ])
      }
      else if(Object.keys(oldAndNewDataFirstHalf.oldData).length === 0 && Object.keys(oldAndNewDataFirstHalf.newData).length === 0 && Object.keys(oldAndNewDataSecondHalf.oldData).length !== 0 && Object.keys(oldAndNewDataSecondHalf.newData).length !== 0){
        console.log("only second", ele.companyName);

        values.push([
          this.getCellObject("text", ele.companyName), 
          this.getCellObject("number", valueBridgeFirstHalf.startStakeValue, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.metricImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.multipleImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.netDebtImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.adjustmentsToEquityValueImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.fxImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.stakeImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.waterfallImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.othersImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.realisedProceedsImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.othersDebtImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.newInvestments, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.exitInvestments, "1"),

          this.getCellObject("number", valueBridgeSecondHalf.startStakeValue, "1"),

          //Metric Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData[metricKey]) + "-(" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData[metricKey]) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.multiple) + "*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Multiple Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.multiple) + "-(" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.multiple) +"))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData[metricKey]) + "*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),
          
          //NetDebt Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.netDebt) 
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.netDebt) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Adjustment to Equity Value Impact
          this.getCellObject("formula",
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.adjustmentsToEquityValue) + 
          "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.adjustmentsToEquityValue) + 
          "))*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) +
          "*" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.fX), "1"),
          
          //Fx Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.newFX) + "-" 
          + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.oldFX) + ")*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stakeValue), "1"),
          
          //Stake Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.stake) + "- (" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.stake) + "))*" 
          + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.equityValue) + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),

          //Waterfall Impact
          this.getWaterfallImpact(oldAndNewDataSecondHalf),
          
          //Others Impact
          this.getCellObject("formula", 
          this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.endStakeValue)
          + "- (" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.startStakeValue) 
          + "+(" +  this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.metricImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.multipleImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.netDebtImpact)
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.adjustmentsToEquityValueImpact ) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.fxImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.waterfallImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.stakeImpact) 
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.othersDebtImpact )
          + ")+(" + this.utilService.convertExponentialToNumber(valueBridgeSecondHalf.realisedProceedsImpact) + ")" + ")", "1"),
          
          //Realised Proceeds Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.realisedProceeds) 
          + "- (" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.realisedProceeds) + "))*" + -1 + "*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),
          
          //Debt and Others Impact
          this.getCellObject("formula", 
          "(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.newData.debtAndOthers)
          + "-(" + this.utilService.convertExponentialToNumber(oldAndNewDataSecondHalf.oldData.debtAndOthers) + "))*" 
          + this.utilService.convertExponentialToNumber(fX2), "1"),
          
          //New Investments Impact
          this.getCellObject("number", valueBridgeSecondHalf.newInvestments, "1"),
          
          //Exit Investments Impact
          this.getCellObject("number", valueBridgeSecondHalf.exitInvestments, "1"),
    
          //End Date value
          this.getCellObject("number", valueBridgeSecondHalf.endStakeValue, "1")
        ])
      }
      else{
        console.log("not available", ele.companyName);
        values.push([
          this.getCellObject("text", ele.companyName), 
          this.getCellObject("number", valueBridgeFirstHalf.startStakeValue, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.metricImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.multipleImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.netDebtImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.adjustmentsToEquityValueImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.fxImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.stakeImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.waterfallImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.othersImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.realisedProceedsImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.othersDebtImpact, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.newInvestments, "1"),
          this.getCellObject("number", valueBridgeFirstHalf.exitInvestments, "1"),
          
          this.getCellObject("number", valueBridgeSecondHalf.startStakeValue, "1"),

          this.getCellObject("number", valueBridgeSecondHalf.metricImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.multipleImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.netDebtImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.adjustmentsToEquityValueImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.fxImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.stakeImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.waterfallImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.othersImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.realisedProceedsImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.othersDebtImpact, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.newInvestments, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.exitInvestments, "1"),
          this.getCellObject("number", valueBridgeSecondHalf.endStakeValue, "1")
        ])        
      }

    })

    //Total Aggregations
    values.push([
      this.getTotalObject("text", "Total", true), 
      this.getTotalObject("number", this.totalAggregationsData.totalStartStakeValue, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstMetricImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalFirstMultipleImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstNetDebtImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstAdjustmentsToEquityValueImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalFirstFxImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalFirstStakeImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstWaterfallImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalFirstOthersImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstRealisedProceedsImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalFirstOthersDebtImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstNewInvestmentsImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalFirstExitInvestmentsImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalIntermediateStakeValue, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondMetricImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondMultipleImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalSecondNetDebtImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondAdjustmentsToEquityValueImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalSecondFxImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondStakeImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalSecondWaterfallImpact, true, "1"),
      this.getTotalObject("number", this.totalAggregationsData.totalSecondOthersImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondRealisedProceedsImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondOthersDebtImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondNewInvestmentsImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalSecondExitInvestmentsImpact, true, "1"), 
      this.getTotalObject("number", this.totalAggregationsData.totalEndStakeValue, true, "1")
    ]);

    return { "tabName": "Fund Level Value Bridge", "data": { "headers": headers, "values": values } }
  }

  getTotalObject(type, value, bold?, decimal?){
    if(type == "text"){
      const obj = {
        "type": type,
        "value": value,
        "bold": bold
      }
    
      return obj
    }
    else{
      const obj = {
        "type": type,
        "value": value,
        "bold": bold,
        "decimal": decimal
      }
    
      return obj
    }
  }

  getOneValueBridgeForFundLevel(vbData){
    const values = [];

    let headers = ["Company", vbData[0].valueBridgeData[0].startDate, 
    "Metric Impact", "Multiple Impact", "Net Debt and Other Balance Sheet Adjustments Impact", "Adjustment to Equity Value Impact", "FX Impact",
    "Stake Impact", "Waterfall Impact", "Others Impact", "Realised Proceeds Impact", "Adjustment to Stake Equity Value Impact",
    "New Investments Impact", "Exit Investments Impact", 
    vbData[0].valueBridgeData[0].endDate] 

    vbData.forEach(ele => {
      let valueBridge = ele.valueBridgeData[0];
      let fX = ele.valueBridgeData[0].oldFX;

      let metricKey = ele.valueBridgeData[0].metricKey;

      if(Object.keys(ele.oldAndNewData[0].oldData).length !== 0 &&  Object.keys(ele.oldAndNewData[0].newData).length !== 0){
        let oldAndNewData = ele.oldAndNewData[0];
        values.push([this.getCellObject("text", ele.companyName), this.getCellObject("number", valueBridge.startStakeValue, "1"),
        
        //Metric Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData[metricKey]) 
        + "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData[metricKey]) + "))*" 
        + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.multiple) 
        + "*" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stake) 
        + "*" + this.utilService.convertExponentialToNumber(fX), "1"),

        //Multiple Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.multiple)
        + "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.multiple) + "))*" 
        + this.utilService.convertExponentialToNumber(oldAndNewData.newData[metricKey]) 
        + "*" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stake)
        + "*" + this.utilService.convertExponentialToNumber(fX), "1"),
        
        //NetDebt Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.netDebt) + 
        "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.netDebt) + "))*" 
        + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stake) + "*" + fX, "1"),

        //Adjustment to Equity Value Impact
        this.getCellObject("formula",
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.adjustmentsToEquityValue) + 
        "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.adjustmentsToEquityValue) + 
        "))*" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stake) +
        "*" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.fX), "1"),
        
        //Fx Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(valueBridge.newFX) + "-" 
        + this.utilService.convertExponentialToNumber(valueBridge.oldFX) + ")*" 
        + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stakeValue), "1"),

        //Stake Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.stake) 
        + "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.stake) + "))*" 
        + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.equityValue)
        + "*" + this.utilService.convertExponentialToNumber(fX), "1"),

        //Waterfall Impact
        this.getWaterfallImpact(oldAndNewData),
        
        //Others Impact
        this.getCellObject("formula", 
        this.utilService.convertExponentialToNumber(valueBridge.endStakeValue) 
        + "-("  + this.utilService.convertExponentialToNumber(valueBridge.startStakeValue )
        + "+("  + this.utilService.convertExponentialToNumber(valueBridge.metricImpact )
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.multipleImpact) 
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.netDebtImpact )
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.adjustmentsToEquityValueImpact )
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.fxImpact )
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.waterfallImpact )
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.stakeImpact) 
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.othersDebtImpact)
        + ")+(" + this.utilService.convertExponentialToNumber(valueBridge.realisedProceedsImpact) + ")" + ")", "1"),

        //Realised Proceeds Impact
        this.getCellObject("formula", 
        "(" +  this.utilService.convertExponentialToNumber(oldAndNewData.newData.realisedProceeds) 
        + "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.realisedProceeds) + "))*" 
        + -1 + "*" + this.utilService.convertExponentialToNumber(fX), "1"),
        
        //Debt and Others Impact
        this.getCellObject("formula", 
        "(" + this.utilService.convertExponentialToNumber(oldAndNewData.newData.debtAndOthers) 
        + "-(" + this.utilService.convertExponentialToNumber(oldAndNewData.oldData.debtAndOthers) + "))*" 
        + this.utilService.convertExponentialToNumber(fX), "1"),
        
        //New Investments Impact
        this.getCellObject("number", valueBridge.newInvestments, "1"),
        
        //Exit Investments Impact
        this.getCellObject("number", valueBridge.exitInvestments, "1"),
        
        //End Date value
        this.getCellObject("number", valueBridge.endStakeValue, "1")]);
      }
      else{
        values.push([
          this.getCellObject("text", ele.companyName), 
          this.getCellObject("number", valueBridge.startStakeValue, "1"),
          this.getCellObject("number", valueBridge.metricImpact, "1"),
          this.getCellObject("number", valueBridge.multipleImpact, "1"),
          this.getCellObject("number", valueBridge.netDebtImpact, "1"),
          this.getCellObject("number", valueBridge.adjustmentsToEquityValueImpact, "1"),
          this.getCellObject("number", valueBridge.fxImpact, "1"),
          this.getCellObject("number", valueBridge.stakeImpact, "1"),
          this.getCellObject("number", valueBridge.waterfallImpact, "1"),
          this.getCellObject("number", valueBridge.othersImpact, "1"),
          this.getCellObject("number", valueBridge.realisedProceedsImpact, "1"),
          this.getCellObject("number", valueBridge.othersDebtImpact, "1"),
          this.getCellObject("number", valueBridge.newInvestments, "1"),
          this.getCellObject("number", valueBridge.exitInvestments, "1"),
          this.getCellObject("number", valueBridge.endStakeValue, "1")
        ])
      }
    })

    //Total Aggregations
    values.push([this.getTotalObject("text", "Total", true), 
    this.getTotalObject("number", this.totalAggregationsData.totalStartStakeValue, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstMetricImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalFirstMultipleImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstNetDebtImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstAdjustmentsToEquityValueImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalFirstFxImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalFirstStakeImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstWaterfallImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalFirstOthersImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstRealisedProceedsImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalFirstOthersDebtImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstNewInvestmentsImpact, true, "1"), 
    this.getTotalObject("number", this.totalAggregationsData.totalFirstExitInvestmentsImpact, true, "1"),
    this.getTotalObject("number", this.totalAggregationsData.totalEndStakeValue, true, "1")]);

    return { "tabName": "Fund Level Value Bridge", "data": { "headers": headers, "values": values } }
  }

  getTotalAggregations($event){
    this.totalAggregationsData = $event;
  }

}
