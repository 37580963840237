import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBiReportComponent implements OnInit {
  powerBIfullPath: any = null;

  constructor(
    public sanitizer: DomSanitizer) { 
  }

  ngOnInit(): void {
    const url = environment.portalUrl + '/power-bi/#/';
    this.powerBIfullPath = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    console.log("powerBIfullPath", this.powerBIfullPath);
  }

}
