<div>
    <input type="text" class="form-control remove-focus input-field" [(ngModel)]="title" placeholder="{{translateService.getLabel('title')}}">
</div>
<div style="margin-top: 5px;">
    <textarea class="form-control remove-focus input-field" [(ngModel)]="comment" placeholder="{{translateService.getLabel('write_a_comment')}}..."
        rows="3"></textarea>
</div>
<div style="text-align: right;">
    <button mat-raised-button (click)="save()" class="primaryBgButton">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;<span class="btn-text">{{translateService.getLabel("comment")}}</span>
    </button>
</div>