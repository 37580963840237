import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { MapChart } from 'angular-highcharts';
import worldMap from '../world-data.data';
import { XIRRServiceService } from '../xirrservice.service';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import Highcharts from 'highcharts';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { CTMService } from '../valuation-algorithms/ctm.service';
import { CCMServiceV3 } from '../valuation-algorithms/ccm-new-v3.service';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

import * as moment from 'moment';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { MessageService } from 'src/app/services/message.service';
import { colors } from '../../../utils/colors'
import { DatePipe } from '@angular/common';
import { TranslateService } from 'src/app/services/translation.service';

@Injectable({
  providedIn: 'root'
})
export class PortFolioSummaryServiceV2 {
    selectedFundId;
    selectedCurrency = "USD";

    loading = true;

    summary: any = {};

    mapData: any = {};

    myOrgUsers;

    companiesAvailable$ = new Subject();

    nATooltip = 'The transaction details are missing. Please refer to the "Trasaction" section from the top left navigation tool to upload the appropriate cash flows.';
    aggregateTooltip = 'The aggregated caculation is excluding the investment for which the fields, Investment Amount, IRR and MOIC are NA';

    constructor(private ums: UserManagementService, private dataService: DataService,
        private fundService: FundListService, public currencyService: CurrencyExchangeService,
        private utilService: UtilService, private ms: MessageService, public translateService : TranslateService)
    {}

    init(fundId) {
        this.myOrgUsers = cloneDeep(this.ums.myOrgUsers);

        this.selectedFundId = fundId;

        this.initFund();

        if(this.summary[fundId]) {

        } else {
            this.selectedFundId = fundId;
            this.fetchPortfolioData(this.selectedFundId);
        }
        
        this.initPortfolioSummaryWidgetdata();

        this.getUserEntityAccess(fundId);
    }

    fetchPortfolioData(fundId, asOfDate?) { //Optional Parameter "asOfDate" - portfolio
        this.loading = true;

        const user = this.ums.getSelectedUserDetails();

        this.summary[fundId] = null;

        const apiReq = {
            "userId": user.id,
            "fundId": fundId,
            "exitStatus": { 
                "currentPortfolio": true,
                "realisedPortfolio": true
            }
        }

        if(asOfDate){
          apiReq["customRequirements"] = {}
          apiReq["customRequirements"]["asOfDate"] = asOfDate
        }

        this.dataService.getPortfolioSummary(apiReq).subscribe(res => {
            this.summary[fundId] = res.body["response"];
            
            this.companiesAvailable$.next();

            this.loading = false;
        }, error => {
            this.fetchPortfolioData(fundId);
        })
    }

    getUserEntityAccess(fundId, companyId?) {
      const user = this.ums.getSelectedUserDetails();

      this.ums.currentUserFundPermissionReadOnly = true;
      this.ums.currentUserCompanyPermissionReadOnly = true;

      this.dataService.getUserEntityAccess(user.id, fundId).subscribe(res => {
        try {
          const apiResponse = res.body["response"];
          let readWrite = "R";

          if(apiResponse.equity && apiResponse.equity[0]) {
            readWrite = apiResponse.equity[0].readWrite;
          }
          
          this.ums.currentUserFundPermissionReadOnly = readWrite != "W";

          this.addCompanyLevelPermission(companyId, user.id);

        } catch(e) {
          console.log("Error while getting entity level permission");
          this.addCompanyLevelPermission(companyId, user.id);
        }
      }, err => {
        this.addCompanyLevelPermission(companyId, user.id);
      })
    }

    addCompanyLevelPermission(companyId, userId) {
      if(companyId) {
        this.dataService.getUserEntityAccess(userId, companyId).subscribe(res => {
          
          const apiResponse = res.body["response"];
          let readWrite = "R";

          if(apiResponse.equity && apiResponse.equity[0]) {
            readWrite = apiResponse.equity[0].readWrite;
          }
          
          this.ums.currentUserCompanyPermissionReadOnly = readWrite != "W";

        }, error => {
          console.log("Failed to get permission of the company");
        })
      }
    }

    getAllCompanies(){
      const currentPortfolioCompanies = this.summary[this.selectedFundId].currentPortfolio.portfolioData;
      const exitedPortfolioCompanies = this.summary[this.selectedFundId].realisedPortfolio.portfolioData;
      const allCompaniesList = currentPortfolioCompanies.concat(exitedPortfolioCompanies).map(c => {
        return c;
      })
      return allCompaniesList;
    }

    async saveFundPortfolioWidget() {
        let portfolioDataReqBody = {
          selectedCurrency: this.selectedCurrency,
          valuationMap: this.mapData.value
        };
    
        try {
            const res = await this.dataService.saveWidgetDataToDB("PORTFOLIO_SUMMARY_DATA", JSON.stringify(portfolioDataReqBody), this.selectedFundId).toPromise();
            
        } catch(e) {
            console.log("Failed to save Selected portfolio data to db", e);
        }
    }

    initFund() {
        if(!this.fundService.allFunds || this.fundService.allFunds.length == 0) {
            const myDetails = this.ums.getSelectedUserDetails();
    
            this.fundService.getFunds(myDetails);
        }
    }

    
  assignUsersToForm(selectedFormForAssignment, newCompanyName?) {

    const userDetails = this.ums.getSelectedUserDetails();
    
    if(newCompanyName) {
      // In case of new form creation, assign to all users with whome the fund is shared.
      const selectedFund = this.fundService.getFundById(selectedFormForAssignment.fundCompany);
      this.resetUserAssignment();


      if(selectedFund.details && this.myOrgUsers && this.myOrgUsers.length > 0) {
        const selectedFundDetails = JSON.parse(selectedFund.details);
        const assignees = selectedFundDetails.assignees;
        if(assignees) {
          this.myOrgUsers.forEach(user => {
            const assignedUser = assignees.find(u => u.id === user.id);
        
            user.assigned = !!assignedUser;
            
            user.editable = assignedUser ? assignedUser.editable || assignedUser.permission : null;

            if(selectedFormForAssignment.assessorUserId === user.id) {
              user.assigned = true;
              user.editable = 'W';
            } else if(this.ums.isMasterUser()) {
              //Form or application will be shared to Org master user by default.
              user.assigned = true;
              user.editable = 'W';
            }
          })
        }
      }
    }

    const usersList = [];
    const assignees = [];
    const editable = [];

    this.myOrgUsers.forEach(u => {
      if(u.editable && u.editable != "FN") {
        usersList.push(u.id);
        editable.push(u.editable);

        const uInfo = {
          id: u.id,
          userName: u.userName,
          userId: u.userId,
          editable: u.editable
        }

        assignees.push(uInfo);
      }
    });
    
    const createdBy = {
      id: userDetails.id,        
      name: userDetails.userName,
      email: userDetails.userId
    }

    const body = {
      "users": usersList,
      "editable": editable,
      "formId": selectedFormForAssignment.id,
      "orgId": userDetails.organization.id,
      "usersInfo": JSON.stringify({createdBy: createdBy, assignees: assignees})
    }

    this.dataService.assignUsersToSAFForm(body).subscribe(result=>{
      // console.log("Assignmet result", result);

      if(!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("suc_user_assign_complete"), this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }
      selectedFormForAssignment.assessorDetails.assignees = assignees
      this.ms.publish("loadingMessage", "");
    }, error=>{
      console.log("Assignmet error", error);

      if(!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("err_failed_assign_user_form"), this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }

      this.ms.publish("loadingMessage", "");
    })
  }

  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.editable = '';
    })
  }

  async getSelectedFundName() {
    let fundName = this.fundService.getFundName(this.selectedFundId);

    if(fundName.length == 0) {
      const fundAPIData = await this.dataService.getFundByFundId(this.selectedFundId).toPromise();
      fundName = fundAPIData.body["response"].name;
    }

    return fundName;
  }

  async initPortfolioSummaryWidgetdata() {
    try {
        const res = await this.dataService.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.selectedFundId).toPromise();
        let widgetData = res["body"]["response"][0].widgetData;
        this.selectedCurrency = widgetData.selectedCurrency;
        this.mapData.value = widgetData.valuationMap;
    }
    catch(e) {
      console.log("Error Retrieving data from Widget", e);
    }
  }

  displayAlgorithmsName(algoName){
    switch (algoName){
      case 'Comparable Company Method':
        return this.translateService.getLabel("trading_comps")
      case 'Comparable Transaction Method':
        return this.translateService.getLabel("transaction_comps")
      case 'Income Approach':
        return this.translateService.getLabel("income_approach_discounted_cash_flow")
      case 'Calibration Approach':
        return this.translateService.getLabel("calibration_approach")
      case 'Secondaries':
        return this.translateService.getLabel("implied_ev_from_secondary")
      case 'Price of Recent Investment':
        return this.translateService.getLabel("implied_ev_from_pori")
      case 'Bid Details':
        return this.translateService.getLabel("implied_ev_from_bid")
      case 'Custom':
        return this.translateService.getLabel("custom_approach")
      case 'DDM Approach':
        return this.translateService.getLabel("income_approach_dividend_discount_model")
      case 'Listed Company Valuation':
        return this.translateService.getLabel("listed_company")
      default: 
          return algoName
    }
  }
}