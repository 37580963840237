import { UtilService } from './../utils/util.service';
import { filter } from 'lodash';
import { UserManagementService } from './../services/user-management.service';
import { DataService } from './../services/data.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import pptxgen from "pptxgenjs";
import {
  EditSettingsModel,
  ToolbarItems,
  GridComponent,
  IEditCell,
} from "@syncfusion/ej2-angular-grids";
import { MouseEventArgs } from "@syncfusion/ej2-base";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { DataManagementService } from "./data-management.service";
import { ActivatedRoute } from "@angular/router";
import { formatDate } from "@angular/common";
import { TranslateService } from 'src/app/services/translation.service';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
// var $:any
@Component({
  selector: "app-data-management",
  templateUrl: "./data-management.component.html",
  styleUrls: ["./data-management.component.scss"],
})
export class DataManagementComponent implements OnInit {
  currentUploadFile: any;
  public stateParams: IEditCell;
  listMasterData: any = [];
  fileUploadList: any = [];
  dataManagementFileRows: any = [];
  public stateElem: HTMLElement;
  public stateObj: DropDownList;
  dateValue = new Date();
  fundId: any;
  updateType: any;
  updateTypeList = [this.translateService.getLabel("updateAll"), this.translateService.getLabel("updateFinancial"), this.translateService.getLabel("updateAllExceptFinancial")];
  stagingFiles: any = [];
  selectedStagingFile: any;
  listOfStagingFileData: any;
  selectedFile: any;

  isEditing: boolean = false;
  enableEditIndex = null;
  newMasterData;
  companies
  securities
  fieldMap: Object = { text: 'issuerCompanyName', value: 'issuerCompanyName' };
  fieldMap1: Object = { text: 'securityName', value: 'securityName' };
  isSelectAll:Boolean = false;
//   showErrorMessage = false

  constructor(
    private dataManagement: DataManagementService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private ums: UserManagementService,
    private utilService: UtilService,
    public translateService: TranslateService
  ) {}

  public editSettings: object;
  public toolbar: string[];
  public dropdown2: object[] = [{ type: "true" }, { type: "false" }];
  public dropdown: object[] = [
    { type: "company Name 1" },
    ,
    { type: "company Name 2" },
    { type: "company Name 3" },
    { type: "company Name 4" },
    { type: "company Name 5" },
    { type: "company Name 6" },
    { type: "company Name 7" },
    { type: "company Name 8" },
  ];
  public dropdown1: object[] = [
    { type2: "Name 1" },
    ,
    { type2: "Name 2" },
    { type2: "Name 3" },
    { type2: "Name 4" },
    { type2: "Name 5" },
    { type2: "Name 6" },
    { type2: "Name 7" },
    { type2: "Name 8" },
  ];
  public columns: object[] = [
    { field: "id", headerText: "S/N", isPrimaryKey: true, width: 50 },
    {
      field: "verified",
      headerText: "select",
      editType: "dropdownedit",
      width: 80,
      displayAsCheckBox: "true",
      edit: {
        params: {
          query: new Query(),
          dataSource: this.dropdown2,
          fields: { value: "type", text: "type" },
        },
      },
    },
    {
      field: "clientCompanyName",
      headerText: "Client Company Name",
      width: 120,
    },
    { field: "clientCompanyId", headerText: "Client Company ID", width: 120 },
    // {field: "clientSecurityName", headerText: "Client Security Name", width: 120},
    { field: "clientSecurityId", headerText: "Client Security ID", width: 120 },
    {
      field: "qubitxCompanyName",
      headerText: "Qubit X_Company Name",
      width: 120,
      editType: "dropdownedit",
      edit: {
        params: {
          query: new Query(),
          dataSource: this.dropdown,
          fields: { value: "type", text: "type" },
        },
      },
    },
    {
      field: "qubitxSecurityName",
      headerText: "Qubit X_Security Name",
      width: 120,
      editType: "dropdownedit",
      edit: {
        params: {
          query: new Query(),
          dataSource: this.dropdown1,
          fields: { value: "type2", text: "type2" },
        },
      },
    },
    {
      field: "qubitxSecurityId",
      headerText: "Qubit X_Security ID",
      width: 120,
    },
    { field: "qubitxFundName", headerText: "Client Security ID", width: 120 },
  ];

  ngOnInit(): void {
    this.editSettings = {
      allowEditing: true,
      allowDeleting: true,
      allowAdding: true,
    };
    this.toolbar = ["Edit", "Delete", "Update", "Cancel", "Add"];
    const userDetails = this.ums.getSelectedUserDetails();
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.fundId = params.get("fundId");
      this.getIssuerCompanies();
    });
    this.getListMasterDataMapping();
    this.getFileUpload();
    this.updateType = this.translateService.getLabel("updateAll");
    this.getStagingFiles(userDetails.organization.id);
  }

  gridPageSettings = { pageSizes: true, pageSize: 10 };
  gridToolbar = ["ColumnChooser"];
  gridFilterSettings = { type: "Excel" };

  onFileSelected(event) {
    this.currentUploadFile = event.target.files[0];
  }

  processFileUpload() {
    let apiObservable: any;
    this.utilService.showLoadingPopup();
    //If file is selected from list of staging files
    if (this.selectedStagingFile) {
      const fileId = this.listOfStagingFileData.find(x => x.fileName == this.selectedStagingFile)
      apiObservable = this.dataManagement
        .processStagingFile(fileId.id, this.fundId, this.updateType, formatDate(this.dateValue, "YYYY-MM-dd", "en"))
    } else {
      apiObservable = this.dataManagement
        .uploadFile(
          this.currentUploadFile,
          this.fundId,
          this.updateType,
          formatDate(this.dateValue, "YYYY-MM-dd", "en")
        )
    }
    apiObservable.subscribe((res) => {
      this.getFileUpload();
    });
  }

  companyRowSelected(dta) {}
  clkFileBtn() {
    document.getElementById("fileUpload").click();
  }
  public add(e: any): void {
    let getId = document.querySelector("button[aria-label='Add']").id;
    document.getElementById(getId).click();
  }

  selectAll(event, objName) {
    switch (objName) {
      case "data":
        this.listMasterData = this.listMasterData.map((x) => {
          return {
            ...x,
            verified: event.target.checked,
          };
        });
        break;
      case "arrayOfObj":
        this.dataManagementFileRows = this.dataManagementFileRows.map((x) => {
          return {
            ...x,
            verified: event.target.checked,
          };
        });
        break;
    }
  }

  getListMasterDataMapping() {
    this.dataManagement
      .getListMasterDataMapping(this.fundId)
      .subscribe((res) => {
        this.isEditing = false;
        console.log("wef", res);
        this.listMasterData = res.body["response"];
        this.multiColumnSortBy(this.listMasterData, 'qubitxCompanyName','qubitxSecurityName');
      });
  }

  getStagingFiles(orgId) {
    this.dataManagement
      .getStagingFiles(orgId)
      .subscribe((res) => {
        this.listOfStagingFileData = res?.body["response"];
        if (this.listOfStagingFileData?.length) {
          res.body["response"].map(x => this.stagingFiles.push(x.fileName));
        }
      });
  }

  saveMasterDataMapping(masterDataMapping: any) {
    this.dataManagement
      .saveMasterDataMappings(masterDataMapping)
      .subscribe((result) => {
      });
  }

  deleteMasterDataMapping(idArray: any) {
    this.dataManagement
      .deleteMasterDataMappings(this.fundId, idArray)
      .subscribe((result) => {
        console.log(result);
        this.getListMasterDataMapping()
      });
  }

  getFileUpload() {
    this.dataManagement.listFileUploads(this.fundId).subscribe(
      (result) => {
        this.utilService.closeAllPopups();
        this.fileUploadList = result.body["response"];
      },
      (error) => {
        this.utilService.closeAllPopups();
        console.log("No files found", error);
      }
    );
  }

  getFilemanagementRow(fileUploadId: any,isSelectAll = false) {
    this.dataManagement.progress(fileUploadId).subscribe((res) => {
      this.utilService.closeAllPopups();
      if (res.body["response"]) {
        this.dataManagementFileRows =
        res.body["response"].dataManagementFileRows;
        this.multiColumnSortBy(this.dataManagementFileRows, 'clientCompanyName','clientSecurityName');
        this.dataManagementFileRows = this.dataManagementFileRows.map(
          (obj) => ({ ...obj, selected: false, verified: isSelectAll })
        );
      } else {
        this.dataManagementFileRows = [];
      }
    });
  }

  actionBegin(event: any) {
    console.log(event);
    if (event == null) return;
    if (event.requestType == "save") {
      this.saveMasterDataMapping(event.data);
    } else if (event.requestType == "delete") {
      var idArray = event.data.map(function (el) {
        return el.id;
      });
      this.deleteMasterDataMapping(idArray);
    }
  }

  fileSelected(event) {
    console.log(event);
    if (event == null && event.data == null) return;
    this.selectedFile = event.data;
    this.getFilemanagementRow(event.data.id,true);
    this.isSelectAll = true;
  }

  processFileProgress() {
    this.utilService.showLoadingPopup();
    this.dataManagement
      .bulkProcess(
        this.dataManagementFileRows.filter((e) => e.verified).map((e) => e.id)
      )
      .subscribe((res) => {
        console.log(res);
        this.getFilemanagementRow(this.selectedFile.id);
      });
  }

  onCheckChange(event, data) {
    console.log(data);
    data.selected = event.checked;
    this.dataManagementFileRows.filter((e) => e.id === data.id).selected =
      event.checked;
  }

  switchEditMode(i) {
    this.getSecurities(this.listMasterData[i].qubitxCompanyId, i)
  }

  update(company) {
    this.isEditing = false;
    this.enableEditIndex = null;
    this.saveMasterDataMapping(company)
  }

  delete(company,index){
    var idArray = [company.id]
      if(!idArray?.[0]){
         this.listMasterData.splice(index,1);
         this.isEditing = false;
        } else {
        this.deleteMasterDataMapping(idArray);
      }
  }

  addRow() {
    this.newMasterData = {
      id: null,
      clientCompanyName: "",
      clientCompanyId: "",
      clientSecurityId: "",
      qubitxCompanyId: "",
      qubitxCompanyName: "",
      qubitxSecurityId: "",
      qubitxSecurityName: "",
      qubitxFundId: this.fundId,
      qubitxFundName: "",
      verified: null,
    };
    // if(this.isEditing){
    //   this.showErrorMessage = true
    //   return
    // }else{
      this.isEditing = true;
      this.enableEditIndex = this.listMasterData.length;
      this.listMasterData.push(this.newMasterData);
    // }
  }

  getIssuerCompanies() {
    this.dataService.getIssuerCompanies(this.fundId).pipe(mergeMap((response) => {
      if (response?.body['response']) {
        return this.dataService.getIssuerCompanyFromGeneralDetail(response?.body['response']);
      }
    }), catchError(err => {
      return of(null);
    })).subscribe((res) => {
      if (res) {
        this.companies = res?.body?.response;
        this.sortBy(this.companies, 'issuerCompanyName');
      }
    })
  }


sortBy(array, key){
   array.sort(function(a, b){
    if(a[key].trim() < b[key].trim()) { return -1; }
    if(a[key].trim() > b[key].trim()) { return 1; }
    return 0;
})
}

multiColumnSortBy(array, key1, key2){
  array.sort(function(a, b){
   if(a[key1].trim() > b[key1].trim()) { return 1; }
   else if(a[key1].trim() < b[key1].trim()) { return -1; }
   else if(a[key2].trim() > b[key2].trim()) { return 1; }
   else if(a[key2].trim() < b[key2].trim()) { return -1; }
   else { return 0;}
})
}

onCompanyChange(event, index){
  this.listMasterData[index].qubitxCompanyId = event.itemData.issuerCompanyId
  this.getSecurities(event.itemData.issuerCompanyId)

}

getSecurities(issuerCompanyId, index?){
  const userDetails = this.ums.getSelectedUserDetails();
  this.utilService.showLoadingPopup();
  this.dataService.getAllSecuritiesInIssuerCompany(userDetails.id, this.fundId, issuerCompanyId,userDetails.organization.id).subscribe((data:any) => {
    this.dataService.getIssuerConsolSummary(data.body.response).subscribe((response:any) => {
      const companies = response.body.response.companies;
      const selectedComapny = companies.filter((item) => {
        return item.issuerCompanyId === issuerCompanyId
      })
      this.securities = selectedComapny[0].valuationDates
      this.sortBy(this.securities, 'securityName')
      this.utilService.closeAllPopups();
      if(!isNaN(index)){
        this.isEditing = true;
        this.enableEditIndex = index;
      }
      console.log(companies, this.securities);
    });
  },
  (error) => {
    console.log(error)
    this.utilService.closeAllPopups();
    if(!isNaN(index)){
      this.isEditing = true;
      this.enableEditIndex = index;
    }
  })
}
onSecurityChange(event, index){
  console.log(event);
  this.listMasterData[index].qubitxSecurityId = event.itemData.companyId
}
}
