<mat-card>

    <div *ngIf="!isReadOnly" class="list-name">
        <div class="mat-h2 list-label">
            List Name
        </div>
        <mat-form-field>
            <input matInput type="text" required [formControl]="listNameFc" placeholder="Enter list name" />
        </mat-form-field>
        <mat-error class="list-name-error" *ngIf="listNameFc?.touched && listNameFc?.hasError('required')">
            <sup>*</sup>Please enter a name</mat-error>
        <mat-error class="list-name-error" *ngIf="listNameFc?.touched && listNameFc?.hasError('duplicateName')">
            <sup>*</sup>Name already in use. Please select a different name.</mat-error>
       
    </div>
    <div *ngIf="isReadOnly" class="list-name-view">
        <div class="mat-h2 list-label">
            {{ listName }}
        </div>
        <div *ngIf="versionDetailsUploadDate" class="upload-date-label">
            <div class="date-label">
                Upload Date
            </div>
             {{versionDetailsUploadDate | date: 'mediumDate' }}
        </div>
        
    </div>
    <mat-divider></mat-divider>
    <div class="hist-proj">
        <div class="table-wrapper">
            <!-- <ng-container *ngIf="(cvlService.historicalItems$ | async) as historicalFins"> -->
            <div class="historical-versions">
                <div class="mat-h3">
                    Historical
                </div>
                <button *ngIf="!isReadOnly" mat-button class="btn-custom-primary"
                    [disabled]="historicalFins?.length === histVersions?.length" (click)="onAddHistVersion()">Add
                </button>
            </div>
            <div class="duplicate-err">
                <mat-error *ngIf="isDuplicateHistVersion"> *Version must be unique. </mat-error>
                <mat-error *ngIf="isDuplicateHistRank"> *Rank must be unique. </mat-error>
            </div>
            <div class="table-br">
                <app-historical-table [versionListItems]="cvlService.historicalItems$ | async" [rankOptions]="ranks"
                    [isReadOnly]="isReadOnly" [historicalVersionOptions]="histVersions"
                    (rankChange)="onHistoricalRankChange($event)" (versionChange)="onHistoricalVersionChange($event)"
                    (deleteHistoricalItem)="onDeleteHistoricalItem($event)"></app-historical-table>
            </div>
            <!-- </ng-container> -->
        </div>
        <div class="table-wrapper">
            <div class="projected-versions">
                <div class="mat-h3">
                    Projected
                </div>
                <button *ngIf="!isReadOnly" mat-button class="btn-custom-primary" (click)="onAddProjectedVersion()">Add
                </button>
            </div>
            <div class="duplicate-err">
                <mat-error *ngIf="isDuplicateProjectedVersion"> *Version must be unique. </mat-error>
                <mat-error *ngIf="isDuplicateProjectedRank"> *Rank must be unique. </mat-error>
            </div>
            <div class="table-br">
                <app-projected-table [projectedVersionListItems]="cvlService.projectedItems$ | async"
                    [projectedVersionOptions]="projectedVersions" [rankOptions]="ranks" [isReadOnly]="isReadOnly"
                    (rankChange)="onProjectedRankChange($event)" (versionChange)="onProjectedVersionChange($event)"
                    (deleteProjectedItem)="onDeleteProjectedItem($event)">
                </app-projected-table>
            </div>
        </div>

    </div>

</mat-card>

<mat-toolbar class="footer">
    <div>
        <button mat-flat-button class="cancel-btn" (click)="goBack()">
            Back
        </button>
        <!-- {{ (cvlService.isHistoricalValid$ | async )}} - {{ cvlService.isProjectedValid$ | async }} -->
        <button *ngIf="!isReadOnly" mat-flat-button class="btn-custom-primary"
            [disabled]="!listNameFc.valid || isDuplicateHistVersion || isDuplicateProjectedVersion || isDuplicateHistRank || isDuplicateProjectedRank || areAllHistRowsBlank || areAllProjRowsBlank"
            (click)="submitList()">
            Save
        </button>
    </div>
</mat-toolbar>