<div class="modal-body-area">
  <div class="comments-container">
    <div class="comments">
      <div class="comment-container"
        *ngFor="let comment of comments; let commentIndex=index, let last=last,let first=first"
        [ngClass]="{'last-child': last,'first-child':first}">
        <div class="date-time-report">
          <span *ngIf="comment.lastModifiedDate">{{comment.lastModifiedDate | date:'longDate'}}</span>
          <span *ngIf="!comment.lastModifiedDate">{{comment.createdDate | date:'longDate'}}</span>
          <!-- <span class="time">{{comment.createdDate | date:'mediumTime'}}</span> -->
          <span style="cursor: pointer; margin-left: 15px;" (click)="deleteComment(comment)"><i class="fa fa-trash"></i></span>
          <span style="cursor: pointer; margin-left: 15px;" (click)="showEdit = comment.id">
            <i class="fa fa-edit"></i>
          </span>
          <span *ngIf="comment.lastModifiedDate" class="time" style="float: right">{{comment.lastModifiedDate | date:'mediumTime'}}</span>
          <span *ngIf="!comment.lastModifiedDate" class="time" style="float: right">{{comment.createdDate | date:'mediumTime'}}</span>
        </div>
          <ng-template [ngIf]="showEdit && comment.id == showEdit">
            <div class="row" style="overflow-y:hidden; padding-bottom:5px;">
              <div class="col">
                <mat-form-field class="custom-textarea">
                  <textarea matInput matAutosizeMinRows=5 matAutosizeMaxRows=20 placeholder="{{translateService.getLabel('comment')}}"
                    [(ngModel)]="comment.comment">
                  </textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col editBox">
                <button type="button" class="btn-custom-primary" (click)="editComment(comment)">{{translateService.getLabel("save")}}</button>
              </div>
            </div>
          </ng-template>
          <ng-template [ngIf]="comment.id != showEdit">
            <div class="comment-style comment">{{comment.comment}}</div>
          </ng-template>
    </div>
  </div>
  </div>
  <mat-form-field class="custom-textarea">
    <textarea matInput matAutosizeMinRows=5 matAutosizeMaxRows=20 placeholder="{{translateService.getLabel('comment')}}"
      [(ngModel)]="commentText">
    </textarea>
  </mat-form-field>
</div>
<div class="modal-footer">
  <button type="button" class="btn-custom-primary"
    (click)="sendMessage1()">{{translateService.getLabel("add")}}</button>
</div>