import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MessagePopupComponent } from './popup/message-popup.component';
import { LoadingPopupComponent } from './loading-popup/loading-popup.component';
import { Router } from '@angular/router';
import { UserManagementService } from '../services/user-management.service';

import floor from 'lodash/floor';
import sortBy from 'lodash/sortBy';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MessageService } from '../services/message.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { MatDrawer } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private dialog: MatDialog, private router: Router,
    private ums: UserManagementService,
    private numberFormatter: DecimalPipe,
    private ms:MessageService,
    private datePipe: DatePipe) { }

  GENERAL_ATTRIBUTES_KEYS = ["location", "sector", "website", "investmentDate", "intialInvestment", "investmentToDate", 
  "irr", "moic", "realisedproceeds", "impliedebitdamultiple", "impliedrevenuemultiple", "revenue", "ebitda", "ebitdamargin",
  "impliedownership", "impliedebitmultiple", "impliedgrossprofitmultiple", "priceearnings", "pricebookvalue", "pricesales", 
  "impliedpriceearnings", "impliedpricebookvalue", "impliedpricesales", "grossprofit", "ebitmargin", "ebit"]  

  public showMessage(message, okButton, error?, closeAnyOpenedDialogs = true): MatDialogRef<any> {
    if(closeAnyOpenedDialogs) {
      this.dialog.closeAll();
    }
    this.ms.publish("loadingMessage", "");

    return this.dialog.open(MessagePopupComponent, {
      data: {
        okButtonMsg: okButton,
        dialogMsg: message,
        error: error
      },
      disableClose: true
    });
  }

  public drawer: MatDrawer;
  drawerOpened = false;

  public open(drawer: MatDrawer) {
    if(this.drawerOpened){
      this.close();
    }
    this.drawer = drawer;
    this.drawer.open();
  }

  public close() {
      this.drawer.close();
      this.drawerOpened = false;
  }
  

  public showConfirmMessage(message, okButton, noButton) {
    this.dialog.closeAll();

    return this.dialog.open(MessagePopupComponent, {
          data : {
            okButtonMsg: okButton,
            noButtonMsg: noButton,
            dialogMsg: message
          },
          disableClose: true
        });
  }

  public showLoadingPopup() {
    this.dialog.open(LoadingPopupComponent, { disableClose: true });
  }

  public closeAllPopups() {
    this.dialog.closeAll();
  }

  areTwoDatesEqual(date1: Date, date2: Date) {
    return date1.getFullYear() == date2.getFullYear()
      && date1.getMonth() == date2.getMonth()
      && date1.getDate() == date2.getDate()
  }

  compareDates(lowDate: Date, highDate: Date){
    if(lowDate > highDate){
      return -1;  //low date is greater than highDate
    }
    else if(lowDate < highDate){
      return 1; //low date is less than highdate
    }
    else{
      return 0; //low date is equal to high date
    }
  }


  getDifferenceInDays(startDate, endDate){
    // Parse the input dates
    const start = this.parseDate(startDate);
    const end = this.parseDate(endDate);

    // Calculate the difference in milliseconds
    const differenceInMs = end.getTime() - start.getTime();

    // Convert milliseconds to days
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

   let days = Math.abs(differenceInDays)

    return days;
  }

  parseDate(dateStr) {
    const [day, month, year] = dateStr.split('/');
    return new Date(`20${year}-${month}-${day}`);
  }


  public navigateHome(valuationType?) {
    const details = this.ums.getSelectedUserDetails();

    if(details.organization.orgType === "vc") {
      this.router.navigateByUrl('/search-home');
    }
    else if(details.organization.orgType === "corp") {
      if(valuationType === "corp-portfolio") {
        this.router.navigateByUrl('/fundList');

      } else if(valuationType === "corp-impairement"){
        this.router.navigateByUrl('/qxi-fundList');

      } else if(valuationType === "corp-corporate"){
        this.router.navigateByUrl('/fundList');
      }
    } else if(this.ums.isExternalUser()) {
      this.router.navigateByUrl('/shared-access');

    } else if(this.ums.is73sAdmin()) {
      this.router.navigateByUrl('/users');
    }
  }

  navigateToValuationSummary(comp, fundName, companyNameInForm, versionName, additionalParams, sourceOfAction) {
    //Ignore if it is user added old valuation date
    if(!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if(comp.businessUnits) return;

    const version = 4; //comp.formVersion; 

    // let versionName=comp.versionName;
    let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName;

    if(comp.consolForm) {
      urlParameters += "&consol=" + companyNameInForm;
    }

    this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, sourceOfAction);
    
    let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

    if(comp.approvalRequestId) {
      url += "&aid="+comp.approvalRequestId;
    }

    if(additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self")
  }

  navigateToDebtValuationSummary(valuationDateId){
    let url = environment.portalUrl + "/debt-model/#/view-cashflow?valuationDateId=" + valuationDateId
    
    window.open(url, "_self")
  }

  navigateToDebtValuationSetUp(valuationDateId) {
    let url = environment.portalUrl + "/debt-model/#/valuation/" + valuationDateId + "/set-up/generalDetails"
    window.open(url, "_self")
  }

  navigateToDebtConsolidatedSummary(issuerCompanyId, companyId, fundId){
    this.router.navigateByUrl('debt-company-summary?' + "issuerCompanyId=" + issuerCompanyId + "&companyId=" + companyId + 
    "&fundId=" +fundId);
    setTimeout(() => {
      window.location.reload();
    })
  }

  navigateToDebtInvestmentSummary(valuationId, companyId, fundId){
    this.router.navigateByUrl('debt-company-valuation?' + "valuationId=" + valuationId + "&companyId=" + companyId + 
    "&fundId=" +fundId);
    setTimeout(() => {
      window.location.reload();
    })
  }

  navigateToInvestmentSummary(companyId, fundId){
    this.router.navigateByUrl('/company-valuation/' + companyId + "?parentId=" + fundId);
    setTimeout(() => {
      window.location.reload();
    })
  }

  navigateToConsolSummary(companyId, fundId, consolId){
    this.router.navigateByUrl('/company-valuation/' + companyId + "?parentId=" + fundId + "&showPage=consol&showPageId=" + consolId);
    setTimeout(() => {
      window.location.reload();
    })
  }

  navigateToSAFForm(formId, companyId, valuationDate, versionName, consolForm, fundId, fundName) {
    const version = this.ums.getApplicationVersion() + "";
    
    localStorage.setItem('formId', formId);
    localStorage.setItem('fv', version); 
    localStorage.setItem('qubit-val-date', valuationDate); 
    localStorage.setItem('qubit-investment-date-id', companyId);
    localStorage.setItem("FUND_ID", fundId);

    let urlParameters = "v=" + version + "&id=" + formId + "&fundName="+fundName + "&versionName="+versionName;

    if(consolForm) {
      urlParameters += "&srcCurrency=" + consolForm.srcCurrency 
        + "&consol=" + consolForm.consol
        + "&marketDate=" + consolForm.marketDate;
    }

    window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&" + urlParameters, "_self");
  }

  cleanString(input) {
    return input;
  }

  getDomainName(website) {
    if(!website || typeof website != 'string') return "";

    website = website.toLowerCase();
    website = website.replace("https://", "");
    website = website.replace("http://", "");
    website = website.replace("www.", "");

    const index = website.indexOf("/");
    if(index > 0) {
      website = website.substr(0, index);
    }
    return website;
  }

  getAggreagtions(arr) {
    return {
      q1: this.getQuartile(arr, 0.25),
      q3: this.getQuartile(arr, 0.75)
    }
  }

  getQuartile(arr, q) {
    const sorted = arr.sort((a, b) => a-b);

    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
  }

  findMedian(array) {
    let median  = 0;
    array = sortBy(array);

    const medianIndex = floor(array.length / 2);
    if (array.length % 2 === 0) {
      median =
        (+array[medianIndex] + +array[medianIndex - 1]) /
        2;
    } else {
      median = array[medianIndex];
    }
    return median;
  }

  acceptOnlyNumbers(event, sourceObject, key) {
    const regex = /[^-0-9.]/gi;
    event.target.value = event.target.value.replace(regex, '');

    const userInput = event.target.value;

    const value = +event.target.value;

    if(isNaN(value)) {
      event.target.value = "0"
    }

    if(userInput === "-") {
      event.target.value = "-";
    }

    sourceObject[key] = +event.target.value;

    event.target.value = this.getFormattedNumber(event.target.value);

    return event.target.value;
  }


  acceptOnlyPercentage(event, sourceObject, key) {
    const regex = /[^-0-9.]/gi;
    event.target.value = event.target.value.replace(regex, '');

    const userInput = event.target.value;

    const value = +event.target.value;

    if (value > 100) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
    } else if(isNaN(value)) {
      event.target.value = null;
    }

    if(userInput === "-") {
      event.target.value = "-";
    }

    sourceObject[key] = +event.target.value;
  }

  public sortComparer = (reference: string, comparer: string) => {
    return new Date(reference).valueOf() - new Date(comparer).valueOf();
  }

  acceptOnlyIntegers(event, sourceObject, key) {
    const regex = /[^0-9]/gi;
    event.target.value = event.target.value.replace(regex, '');

    const userInput = event.target.value;

    const value = +event.target.value;

    if(isNaN(value)) {
      event.target.value = "0"
    }

    sourceObject[key] = +event.target.value;

    event.target.value = this.getFormattedNumber(event.target.value);

    return event.target.value;
  }

  getFormattedNumber(num) {
      // let parts = num.toString().split(".");

      // parts[0] = this.numberFormatter.transform(parts[0], '1.0-2')

      // num = parts.join(".");
      // return num;


      // if(!num || isNaN(num)) return 0;
      // return this.numberFormatter.transform(num, '1.0-2')

      if(!num || isNaN(num) || !isFinite(num)) {
        return "0"
      }

      let parts = num.toString().split(".");

      parts[0] = this.numberFormatter.transform(parts[0], '1.0-2')

      num = parts.join(".");
      return num;
  }

  getValueForExcel(num) {
    if (isNaN(num) || num <= 0) {
      return null;
    } else {
      return Number.parseFloat((+num).toFixed(1));
    }
  }
  
  getValueForExcelWithNegatives(numberValue: any) {

    const value = +(numberValue + "").replace(/,/g, "");

    if(isNaN(value))
      return null;

    if(!numberValue) return 0;

    return Number(value);
  }

  getValidNumber(numberValue) {
    if(!numberValue) return 0;

    const value = +(numberValue + "").replace(/,/g, "");

    if(isNaN(value))
      return 0;

    return Number(value);
  }

  getQuarterName(valuationDate){ //format 'YYYY-MM-DD'
    const dateObj = new Date(valuationDate);
    const month = dateObj.getMonth();

    const quarter = (+(month/3).toFixed(0)) + 1;
    const year = dateObj.getFullYear();

    const quarterName = "Q" + quarter + " " + year;

    return quarterName;
  }

  getIndexOfMonthByQuarterName(quarterName){
    const quarterNumber = +quarterName.split(" ")[0][1];
    let indexOfMonth = 0; //assuming index of month starts from 1
    switch (quarterNumber) {
      case 1:
        indexOfMonth = 3
        break;

      case 2:
        indexOfMonth = 6
        break;

      case 3:
        indexOfMonth = 9
        break;

      case 4:
        indexOfMonth = 12
        break;
    }

    return indexOfMonth
  }

  // Format :
  // 'll' : MMM dd, YYYY
  displayFormattedDate(date, format) {
    try {
      return moment(date).format(format);
    } catch(e) {
      return "-"
    }
  }

  zeroInForOneDecimalFormat(num) {
    const value = this.getValidNumber(num);
    
    return value;
  }

  convertExponentialToNumber(numIn) {
    if(!numIn){
      return 0;
    }
    
    numIn +="";                                            // To cater to numric entries
    let sign="";                                           // To remember the number sign
    numIn.charAt(0)=="-" && (numIn =numIn.substring(1),sign ="-"); // remove - sign & remember it
    let str = numIn.split(/[eE]/g);                        // Split numberic string at e or E
    if (str.length<2) return sign+numIn;                   // Not an Exponent Number? Exit with orginal Num back
    let power = str[1];                                    // Get Exponent (Power) (could be + or -)
    if (power ==0 || power ==-0) return sign+str[0];       // If 0 exponents (i.e. 0|-0|+0) then That's any easy one
   
    let deciSp = 1.1.toLocaleString().substring(1,2);  // Get Deciaml Separator
    str = str[0].split(deciSp);                        // Split the Base Number into LH and RH at the decimal point
    let baseRH = str[1] || "",                         // RH Base part. Make sure we have a RH fraction else ""
        baseLH = str[0];                               // LH base part.
   
    if (power>0) {   // ------- Positive Exponents (Process the RH Base Part)
      if (power> baseRH.length) baseRH +="0".repeat(power-baseRH.length); // Pad with "0" at RH
      baseRH = baseRH.slice(0,power) + deciSp + baseRH.slice(power);      // Insert decSep at the correct place into RH base
      if (baseRH.charAt(baseRH.length-1) ==deciSp) baseRH =baseRH.slice(0,-1); // If decSep at RH end? => remove it
  
    } 
    else {         // ------- Negative Exponents (Process the LH Base Part)
      let num= Math.abs(power) - baseLH.length;                               // Delta necessary 0's
      if (num>0) baseLH = "0".repeat(num) + baseLH;                       // Pad with "0" at LH
      baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power);     // Insert "." at the correct place into LH base
      if (baseLH.charAt(0) == deciSp) baseLH="0" + baseLH;                // If decSep at LH most? => add "0"
    }
    
    return sign + baseLH + baseRH;                                          // Return the long number (with sign)
  }

  getDisplayFormattedNumber(num, decimalPoints, suffix, placeHolderForZeroValue, onlyPositiveAllowed?) {
    if(!num || isNaN(num) || (+num == 0) || !isFinite(num)) return placeHolderForZeroValue;

    if(onlyPositiveAllowed && (+num <= 0  || !isFinite(num) ) ) {
      return placeHolderForZeroValue;
    }

    let formattedValue = "";

    if(num > 0) {
      formattedValue = decimalPoints == null ? num 
      : this.numberFormatter.transform(num, '1.' + decimalPoints + '-' + decimalPoints);

    } else {
      formattedValue = decimalPoints == null ? "(" + num + ")"
      : "(" + this.numberFormatter.transform((num * -1), '1.' + decimalPoints + '-' + decimalPoints) + ")";
    }


    return formattedValue + suffix;
  }


  getNegativeNumberDisplayFormat(num,decimalPoints, suffix, placeHolderForZeroValue){
    
    if(!num || isNaN(num) || (+num == 0) || !isFinite(num)) return placeHolderForZeroValue;

    let formattedValue = "";
    formattedValue = "-" + this.numberFormatter.transform((num * -1), '1.' + decimalPoints + '-' + decimalPoints);
    return formattedValue + suffix;
  }

  getArrayOfUniqueObjects(array: [], uniqueValueKey) {
    const map: any = {};

    array.forEach(item => {
      map[item[uniqueValueKey]] = item;
    })

    return Object.values(map);
  }

  //Util

  getUID() {

    let now = Date.now().toString();

    now = now + Math.floor(Math.random() * 1000);

    if (now.length < 16) {

      for (let index = 0; index < 16 - now.length; index++) {

        now = '0' + now;

      }

    }

    return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 16)].join('-');

  }

  getUUID() {
    let now = Date.now().toString();
    now = now + Math.floor(Math.random() * 1000);

    let uuid = "";

    for(let index = 0; uuid.length < 16; index++) {
      const randomNumber = Math.floor(Math.random() * 10);
      if(now[randomNumber]){
        uuid += now[randomNumber] + randomNumber;
      }
    }

    return uuid;
  }

  currencies = [
    { id: "USD", country: "United States Dollar" },
    { id: "GBP", country: "British Pound Sterling" },
    { id: "EUR", country: "Euro" },
    { id: "CHF", country: "Swiss Franc" },
    { id: "AED", country: "United Arab Emirates Dirham" },
    { id: "AFN", country: "Afghan Afghani" },
    { id: "ALL", country: "Albanian Lek" },
    { id: "AMD", country: "Armenian Dram" },
    { id: "ANG", country: "Netherlands Antillean Guilder" },
    { id: "AOA", country: "Angolan Kwanza" },
    { id: "ARS", country: "Argentine Peso" },
    { id: "AUD", country: "Australian Dollar" },
    { id: "AWG", country: "Aruban Florin" },
    { id: "AZN", country: "Azerbaijani Manat" },
    { id: "BAM", country: "Bosnia-Herzegovina Convertible Mark" },
    { id: "BBD", country: "Barbadian Dollar" },
    { id: "BDT", country: "Bangladeshi Taka" },
    { id: "BGN", country: "Bulgarian Lev" },
    { id: "BHD", country: "Bahraini Dinar" },
    { id: "BIF", country: "Burundian Franc" },
    { id: "BMD", country: "Bermudan Dollar" },
    { id: "BND", country: "Brunei Dollar" },
    { id: "BOB", country: "Bolivian Boliviano" },
    { id: "BRL", country: "Brazilian Real" },
    { id: "BSD", country: "Bahamian Dollar" },
    { id: "BTC", country: "Bitcoin" },
    { id: "BTN", country: "Bhutanese Ngultrum" },
    { id: "BWP", country: "Botswanan Pula" },
    { id: "BYN", country: "Belarusian Ruble" },
    { id: "BYR", country: "Belarusian Ruble" },
    { id: "BZD", country: "Belize Dollar" },
    { id: "CAD", country: "Canadian Dollar" },
    { id: "CDF", country: "Congolese Franc" },
    { id: "CLF", country: "Chilean Unit of Account (UF)" },
    { id: "CLP", country: "Chilean Peso" },
    { id: "CNY", country: "Chinese Yuan" },
    { id: "COP", country: "Colombian Peso" },
    { id: "CRC", country: "Costa Rican Colón" },
    { id: "CUC", country: "Cuban Convertible Peso"},
    { id: "CUP", country: "Cuban Peso" },
    { id: "CVE", country: "Cape Verdean Escudo" },
    { id: "CZK", country: "Czech Republic Koruna" },
    { id: "DJF", country: "Djiboutian Franc" },
    { id: "DKK", country: "Danish Krone" },
    { id: "DOP", country: "Dominican Peso" },
    { id: "DZD", country: "Algerian Dinar" },
    { id: "EEK", country: "Estonian Kroon" },
    { id: "EGP", country: "Egyptian Pound" },
    { id: "ERN", country: "Eritrean Nakfa" },
    { id: "ETB", country: "Ethiopian Birr" },
    { id: "FJD", country: "Fijian Dollar" },
    { id: "FKP", country: "Falkland Islands Pound" },
    { id: "GEL", country: "Georgian Lari" },
    { id: "GGP", country: "Guernsey Pound" },
    { id: "GHS", country: "Ghanaian Cedi" },
    { id: "GIP", country: "Gibraltar Pound" },
    { id: "GMD", country: "Gambian Dalasi" },
    { id: "GNF", country: "Guinean Franc" },
    { id: "GTQ", country: "Guatemalan Quetzal" },
    { id: "GYD", country: "Guyanaese Dollar" },
    { id: "HKD", country: "Hong Kong Dollar" },
    { id: "HNL", country: "Honduran Lempira" },
    { id: "HRK", country: "Croatian Kuna" },
    { id: "HTG", country: "Haitian Gourde" },
    { id: "HUF", country: "Hungarian Forint" },
    { id: "IDR", country: "Indonesian Rupiah" },
    { id: "ILS", country: "Israeli New Sheqel" },
    { id: "IMP", country: "Manx pound" },
    { id: "INR", country: "Indian Rupee" },
    { id: "IQD", country: "Iraqi Dinar" },
    { id: "IRR", country: "Iranian Rial" },
    { id: "ISK", country: "Icelandic Króna" },
    { id: "JEP", country: "Jersey Pound" },
    { id: "JMD", country: "Jamaican Dollar" },
    { id: "JOD", country: "Jordanian Dinar" },
    { id: "JPY", country: "Japanese Yen" },
    { id: "KES", country: "Kenyan Shilling" },
    { id: "KGS", country: "Kyrgystani Som" },
    { id: "KHR", country: "Cambodian Riel" },
    { id: "KMF", country: "Comorian Franc" },
    { id: "KPW", country: "North Korean Won" },
    { id: "KRW", country: "South Korean Won" },
    { id: "KWD", country: "Kuwaiti Dinar" },
    { id: "KYD", country: "Cayman Islands Dollar" },
    { id: "KZT", country: "Kazakhstani Tenge" },
    { id: "LAK", country: "Laotian Kip" },
    { id: "LBP", country: "Lebanese Pound" },
    { id: "LKR", country: "Sri Lankan Rupee" },
    { id: "LRD", country: "Liberian Dollar" },
    { id: "LSL", country: "Lesotho Loti" },
    { id: "LTL", country: "Lithuanian Litas" },
    { id: "LVL", country: "Latvian Lats" },
    { id: "LYD", country: "Libyan Dinar" },
    { id: "MAD", country: "Moroccan Dirham" },
    { id: "MDL", country: "Moldovan Leu" },
    { id: "MGA", country: "Malagasy Ariary" },
    { id: "MKD", country: "Macedonian Denar" },
    { id: "MMK", country: "Myanma Kyat" },
    { id: "MNT", country: "Mongolian Tugrik" },
    { id: "MOP", country: "Macanese Pataca" },
    { id: "MRO", country: "Mauritanian Ouguiya" },
    { id: "MUR", country: "Mauritian Rupee" },
    { id: "MVR", country: "Maldivian Rufiyaa" },
    { id: "MWK", country: "Malawian Kwacha" },
    { id: "MXN", country: "Mexican Peso" },
    { id: "MYR", country: "Malaysian Ringgit" },
    { id: "MZN", country: "Mozambican Metical" },
    { id: "NAD", country: "Namibian Dollar" },
    { id: "NGN", country: "Nigerian Naira" },
    { id: "NIO", country: "Nicaraguan Córdoba" },
    { id: "NOK", country: "Norwegian Krone" },
    { id: "NPR", country: "Nepalese Rupee" },
    { id: "NZD", country: "New Zealand Dollar" },
    { id: "OMR", country: "Omani Rial" },
    { id: "PAB", country: "Panamanian Balboa" },
    { id: "PEN", country: "Peruvian Nuevo Sol" },
    { id: "PGK", country: "Papua New Guinean Kina" },
    { id: "PHP", country: "Philippine Peso" },
    { id: "PKR", country: "Pakistani Rupee" },
    { id: "PLN", country: "Polish Zloty" },
    { id: "PYG", country: "Paraguayan Guarani" },
    { id: "QAR", country: "Qatari Rial" },
    { id: "RON", country: "Romanian Leu" },
    { id: "RSD", country: "Serbian Dinar" },
    { id: "RUB", country: "Russian Ruble" },
    { id: "RWF", country: "Rwandan Franc" },
    { id: "SAR", country: "Saudi Riyal" },
    { id: "SBD", country: "Solomon Islands Dollar" },
    { id: "SCR", country: "Seychellois Rupee" },
    { id: "SDG", country: "Sudanese Pound" },
    { id: "SEK", country: "Swedish Krona" },
    { id: "SGD", country: "Singapore Dollar" },
    { id: "SHP", country: "Saint Helena Pound" },
    { id: "SLL", country: "Sierra Leonean Leone" },
    { id: "SOS", country: "Somali Shilling" },
    { id: "SRD", country: "Surinamese Dollar" },
    { id: "STD", country: "São Tomé and Príncipe Dobra" },
    { id: "SVC", country: "Salvadoran Colón" },
    { id: "SYP", country: "Syrian Pound" },
    { id: "SZL", country: "Swazi Lilangeni" },
    { id: "THB", country: "Thai Baht" },
    { id: "TJS", country: "Tajikistani Somoni" },
    { id: "TMT", country: "Turkmenistani Manat" },
    { id: "TND", country: "Tunisian Dinar" },
    { id: "TOP", country: "Tongan Paʻanga" },
    { id: "TRY", country: "Turkish Lira" },
    { id: "TTD", country: "Trinidad and Tobago Dollar" },
    { id: "TWD", country: "New Taiwan Dollar" },
    { id: "TZS", country: "Tanzanian Shilling" },
    { id: "UAH", country: "Ukrainian Hryvnia" },
    { id: "UGX", country: "Ugandan Shilling" },
    { id: "UYU", country: "Uruguayan Peso" },
    { id: "UZS", country: "Uzbekistan Som" },
    { id: "VEF", country: "Venezuelan Bolílet Fuerte" },
    { id: "VND", country: "Vietnamese Dong" },
    { id: "VUV", country: "Vanuatu Vatu" },
    { id: "WST", country: "Samoan Tala" },
    { id: "XAF", country: "CFA Franc BEAC" },
    { id: "XAG", country: "Silver (troy ounce)" },
    { id: "XAU", country: "Gold (troy ounce)" },
    { id: "XCD", country: "East Caribbean Dollar" },
    { id: "XDR", country: "Special Drawing Rights" },
    { id: "XOF", country: "CFA Franc BCEAO" },
    { id: "XPF", country: "CFP Franc" },
    { id: "YER", country: "Yemeni Rial" },
    { id: "ZAR", country: "South African Rand" },
    { id: "ZMK", country: "Zambian Kwacha (pre-2013)" },
    { id: "ZMW", country: "Zambian Kwacha" },
    { id: "ZWL", country: "Zimbabwean Dollar" }
  ]


  formatFilterAlgorithmnArrayForCTM_CCM(filterArray, algo){
      let algorithmAndImpacts = {
        "name": algo,
        "key": algo,
        "selected": true,
        "expanded": false,
        "filtered": true,
        "type": "ALGORITHM",
        "financialId:": "",
        "children": [
          {
            "key": "WEIGHT_IMPACT",
            "name": "Weight Impact",
            "selected": true,
            "expanded": false,
            "filtered": true,
            "type": "IMPACT"
          },
          {
            "key": "VALUE_IMPACT",
            "name": "Value Impact",
            "selected": true,
            "expanded": false,
            "filtered": true,
            "type": "IMPACT",
            "children": [
              {
                "key": "MULTIPLE_IMPACT",
                "name": "Multiple Impact",
                "selected": false,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT"
              },
              {
                "key": "METRIC_IMPACT",
                "name": "Metric Impact",
                "selected": false,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT"
              }
            ]
          }
        ]
      }
    // ]
    filterArray[0].children.push(algorithmAndImpacts)
    console.log("Filter CTM",filterArray)
    return  filterArray
  }

  formatFilterAlgorithmnArrayforIncomeApproach(filterArray, algo){
    // let algorithmAndImpacts = [
      let algorithmAndImpacts =  {
        "name": algo,
        "key": algo,
        "selected": true,
        "expanded": false,
        "filtered": true,
        "type": "ALGORITHM",
        "financialId:": "",
        "children": [
              {
                "key": "WEIGHT_IMPACT",
                "name": "Weight Impact",
                "selected": true,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT"
              },
              {
                "key": "VALUE_IMPACT",
                "name": "Value Impact",
                "selected": true,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT",
                "children": [
                  {
                    "key": "PROJECTIONS_IMPACT",
                    "name": "Projections Impact",
                    "selected": false,
                    "expanded": false,
                    "filtered": true,
                    "type": "IMPACT"
                  },
                  {
                    "key": "TERMINAL_VALUE_IMPACT",
                    "name": "Terminal Impact",
                    "selected": false,
                    "expanded": false,
                    "filtered": true,
                    "type": "IMPACT"
                  },
                  {
                    "key": "DISCOUNT_RATE_IMPACT",
                    "name": "Discount Rate Impact",
                    "selected": false,
                    "expanded": false,
                    "filtered": true,
                    "type": "IMPACT"
                  }
                ]
              }
            ]
      }
    filterArray[0].children.push(algorithmAndImpacts)

    return  filterArray
  }

  formatFilterAlgorithmnArrayforOtherAlgorithmns(filterArray, algo){
      let algorithmAndImpacts = {
        "name": algo,
        "key": algo,
        "selected": true,
        "expanded": false,
        "filtered": true,
        "type": "ALGORITHM",
        "financialId:": "",
        "children": [
              {
                "key": "WEIGHT_IMPACT",
                "name": "Weight Impact",
                "selected": true,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT"
              },
              {
                "key": "VALUE_IMPACT",
                "name": "Value Impact",
                "selected": true,
                "expanded": false,
                "filtered": true,
                "type": "IMPACT"
              }
            ]
      }
    filterArray[0].children.push(algorithmAndImpacts)

    return  filterArray
  }


  getTranslationAlgoName(label){
    switch(label){
      case "Comparable Company Method" : return "trading_comps";
      case "Comparable Transaction Method" : return "transaction_comps";
      case "Calibration Approach" : return "implied_ev_from_calibration";
      case "Income Approach" : return "income_approach_discounted_cash_flow";
      case "Custom" : return "implied_ev_from_custom";
      case "Listed Company Valuation" : return "listed_company";  
      case "Secondaries": return "implied_ev_from_secondary";
      case "Price of Recent Investment": return "implied_ev_from_pori";
      case "Bid Details": return "implied_ev_from_bid";
      default:
        return label;
    }
  }
}
