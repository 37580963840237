<!-- <mat-tab-group>
    <mat-tab label="Attributes">
        <ng-template matTabContent>
            <app-mapping-info></app-mapping-info>
            <app-attribute-list *ngIf="!showCreateMap" (onEditMapClick)="onEditMapClick($event)"
                (onDeleteMapClick)="onDeleteMapClick($event)"
                (onDownloadMapClick)="onDownloadMapClick($event)"></app-attribute-list>
            <button *ngIf="!showCreateMap" (click)="createMap()">Create Map</button>
            <app-attributes *ngIf="showCreateMap"></app-attributes>
        </ng-template>
    </mat-tab>
    <mat-tab label="Versions">
        <ng-template matTabContent>
            <app-mapping-info></app-mapping-info>
            <app-versions></app-versions>
        </ng-template>
    </mat-tab>
    <mat-tab label="Company Assignment">
        <ng-template matTabContent>
            <app-mapping-info></app-mapping-info>
            <app-company-assignment></app-company-assignment>
        </ng-template>
    </mat-tab>
</mat-tab-group> -->

<!-- <button style="float: right;padding-right:16px" [routerLink]="'./create-map'">Create Map</button> -->
<nav style="padding: 0 16px 0 16px;" mat-tab-nav-bar>
    <a mat-tab-link [routerLink]="'./attributes'" routerLinkActive #attrRla="routerLinkActive"
        [active]="attrRla.isActive ||  attrRla.router.url?.includes('create-attributes-map') ||
    versionRla.router.url?.includes('edit-attributes-map') ||
    versionRla.router.url?.includes('attributes-map-details') || versionRla.router.url?.includes('replicate-attributes-map')" queryParamsHandling="merge"> Attributes </a>

    <a mat-tab-link [routerLink]="'./versions'" routerLinkActive #versionRla="routerLinkActive" [active]="versionRla.isActive ||  versionRla.router.url?.includes('edit-version') ||
    versionRla.router.url?.includes('create-version') ||
    versionRla.router.url?.includes('version-details')" queryParamsHandling="merge"> Versions </a>

    <a mat-tab-link [routerLink]="'./company-assignment'" routerLinkActive #companyRla="routerLinkActive"
        [active]="companyRla.isActive" queryParamsHandling="merge"> Company Assignment - Equity </a>

    <a mat-tab-link [routerLink]="'./company-assignment-qxc'" routerLinkActive #companyQxcRla="routerLinkActive"
        [active]="companyQxcRla.isActive" queryParamsHandling="merge"> Company Assignment - Credit</a>
</nav>
<app-mapping-info></app-mapping-info>
<div style="padding: 0 40px 40px 40px">
    <router-outlet></router-outlet>
</div>