import { Component, OnInit } from '@angular/core';
import { ImageLoadService } from '../../services/image-load.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(public loaderService: ImageLoadService,private router: Router) { }

  ngOnInit(): void {
  }
  login() {
    this.router.navigateByUrl('/');
  }

}
