import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-comparison-filters',
  templateUrl: './comparison-filters.component.html',
  styleUrls: ['./comparison-filters.component.scss']
})
export class ComparisonFiltersComponent implements OnInit {
  @Input() filters;
  @Output() change = new EventEmitter();

  versionComparePreferences = {};

  constructor( private ds : DataService ) {

  }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void {

  }


  isIndeterminate(element) {
    return element.indeterminate;
    
  }


  childrenSelectionChange(element, childrenSelectionSatus) {
    console.log("Status Element", element.label, element);

    if (childrenSelectionSatus == "all") {
      element.selected = true;
      element.indeterminate = false;
      console.log("all");
    }
    else if (childrenSelectionSatus == "partial") {
      element.selected = false;
      element.indeterminate = true;
      console.log("partial");
    }
    else {
      element.selected = false;
      element.indeterminate = false;
      console.log("nothing");
    }
    this.selectionChange();
    // this.setUserPreferences();
  }

  selectionChange() {
    let allSelected = true;
    let partialSelected = false;

    this.filters.forEach(e => {
      allSelected = allSelected && e.selected;
      partialSelected = partialSelected || e.selected || e.indeterminate;
    })

    if (allSelected) {
      this.change.emit("all")
    }
    else if (partialSelected) {
      this.change.emit("partial")
    }
    else {
      this.change.emit("none")
    }
  }

  onChangeParentOnClick(parentFilter) {
    //This event happens before ngModelChange
    
    const selectionStatus = !parentFilter.selected;

    this.changeChildrenSelectionOnParentChange(parentFilter.children, selectionStatus);
  }

  changeChildrenSelectionOnParentChange(children, status) {
    children.forEach(element => {
      element.selected = status;
      element.indeterminate = false;

      if(element.children) {
        this.changeChildrenSelectionOnParentChange(element.children, status);
      }
    });
  }


  // createPreferenceSelection(preferences) {

  //   preferences.forEach(element => {

  //     if (element.children && element.children.length > 0) {

  //       this.createPreferenceSelection(element.children);

  //     } else {

  //       this.versionComparePreferences[element.key] = element.selected;

  //     }

  //   });

  // }

  // setUserPreferences is being called from company-valuation-component
  // setUserPreferences() {
  //   this.createPreferenceSelection(this.filters);
  // }
}

