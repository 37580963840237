
<div class="table table-condensed" id="container" class="investment-summary-grid" *ngIf="showAggregations" style="width: 96vw; max-width: 96vw; min-width: 96vw;">
  <ejs-grid #summaryGrid
    [dataSource]="portfolioData" 
    [allowResizing]="true" 
    [allowTextWrap]="true" 
    [allowGrouping]="true" 
    [allowExcelExport]="true" 
    [allowSorting]="true" 
    [allowReordering]="true" 
    [allowPaging]="true" 
    [pageSettings]="gridPageSettings" 
    [showColumnChooser]="true" 
    [toolbar]="gridToolbar" 
    [allowFiltering]="true" 
    [filterSettings]="gridFilterSettings"
    (rowSelected)="companyRowSelected($event)"
    (actionComplete)='actionComplete($event)'>
    
    <e-columns>

      <e-column field='logo' headerText= '{{translateService.getLabel("logo")}}' [allowSorting]="false" [allowFiltering]="false" width='75px' [visible]="gridAllColumnsShowStatus.logo">
        <ng-template #template let-comp>
          <img [src]="comp.logo" class="companyLogo" (error)="comp.logo = null" *ngIf="comp.logo; else defaultLogo">
          <ng-template #defaultLogo>
            <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column field='companyName' headerText= '{{translateService.getLabel("name")}}' field='companyName' width='200px' [visible]="gridAllColumnsShowStatus.companyName">
        <ng-template #template let-comp>
          <span style="font-weight: 500;">{{comp.companyName}}</span>
        </ng-template>
      </e-column>

      <e-column field='investmentDate' [sortComparer]='utilService.sortComparer' headerText= '{{translateService.getLabel("investment_date")}}' width='130px' [visible]="gridAllColumnsShowStatus.investmentDate">
        <ng-template #template let-comp>
          <!-- {{ comp.investmentDate | date: "mediumDate" }} -->
          <app-data-drill-down-menu [value]="comp.investmentDate | date: 'mediumDate'" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
            [label]="translateService.getLabel('investment_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('form', 'investment_date')" [valDateId]="comp.latestValuationDateId"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>

      <e-column field='latestValuationDate' [sortComparer]='utilService.sortComparer' headerText= '{{translateService.getLabel("valuationDate")}}' width='130px' [visible]="gridAllColumnsShowStatus.latestValuationDate">
        <ng-template #template let-comp>
          <app-data-drill-down-menu [value]="comp.latestValuationDate | date: 'mediumDate'" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
            [label]="translateService.getLabel('investment_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('form', 'investment_date')" [valDateId]="comp.latestValuationDateId"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>

      <e-column field='geography' headerText= '{{translateService.getLabel("geography")}}' width='110px' [visible]="gridAllColumnsShowStatus.geography">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.geography">
            <app-data-drill-down-menu [value]="comp.geography" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [label]="translateService.getLabel('geography')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')" [source]="drillDownService.getSourceForDrillDown('form', 'location')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column field='sector' headerText= '{{translateService.getLabel("sector")}}' field='sector' width='120px' [visible]="gridAllColumnsShowStatus.sector">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.sector">{{comp.sector | titlecase}}</ng-template>
        </ng-template>
      </e-column>

      <e-column field='security' headerText='{{translateService.getLabel("type_of_security")}}' field='security' width='100px' [visible]="gridAllColumnsShowStatus.security">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.security">{{comp.security | titlecase}}</ng-template>
        </ng-template>
      </e-column>

      <e-column field='exitDate' [sortComparer]='utilService.sortComparer' headerText='{{translateService.getLabel("exit_date")}}' width='90px' [visible]="gridAllColumnsShowStatus.exitDate" *ngIf="exitGrid">
        <ng-template #template let-comp>
          <!-- {{utilService.displayFormattedDate(comp.exitDate, 'll')}} -->
          <app-data-drill-down-menu [value]="comp.exitDate | date: 'mediumDate'" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, 'mediumDate')"
            [label]="translateService.getLabel('exit_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('investment_summary')" [valDateId]="comp.id"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>

      <e-column [field]='currencyToBeDisplayed' headerText= '{{translateService.getLabel("currency")}}' textAlign='Right' width='90px' [visible]="gridAllColumnsShowStatus[currencyToBeDisplayed]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp[currencyToBeDisplayed]">
            <app-data-drill-down-menu [value]="comp[currencyToBeDisplayed]" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [label]="translateService.getLabel('currency')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')" [source]="drillDownService.getSourceForDrillDown('form', 'currency')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column [field]="'stake_' + currencyKey" headerText= '{{translateService.getLabel("stake")}}' width='90px' textAlign='Right' [visible]="gridAllColumnsShowStatus['stake_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['stake_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('stake')"
              [formula]="drillDownService.getFormulaForDrillDownItems('stake')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
              [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      <e-column [field]="'fullyDilutedStake_' + currencyKey" headerText= '{{translateService.getLabel("Fully diluted stake")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['fullyDilutedStake_' + currencyKey]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp['fullyDilutedStake_' + currencyKey] >= 0" [ngIfElse]="NA">
            <span *ngIf="comp['fullyDilutedStake_' + currencyKey] >= 0">
              <app-data-drill-down-menu [value]="comp['fullyDilutedStake_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [onlyPositiveAllowed]="false" [label]="translateService.getLabel('Fully diluted stake')" [typeOfSource]="'User Entry'"
                 [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                [source]="drillDownService.getSourceForDrillDown('cap table')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
          <ng-template #NA>
            <span style="color: red;" *ngIf="!comp['fullyDilutedStake_' + currencyKey] || comp['fullyDilutedStake_' + currencyKey] < 0" [matTooltip]="portfolioService.nATooltip">
              {{utilService.getDisplayFormattedNumber(comp['fullyDilutedStake_' + currencyKey], 1, "", "NA")}}
            </span>
          </ng-template>
        </ng-template>
      </e-column>
      
      <e-column [field]="'investmentAmount_' + currencyKey" headerText= '{{translateService.getLabel("investment_amount")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['investmentAmount_' + currencyKey]">
        <ng-template #template let-comp>
          <span *ngIf="comp[currencyKey].investmentAmount > 0" >
            <app-data-drill-down-menu [value]="comp['investmentAmount_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')" [typeOfSource]="'User Entry'"
              [formula]="drillDownService.getFormulaForDrillDownItems('investment_amount')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
          <span style="color: red;" *ngIf="!comp[currencyKey].investmentAmount || comp[currencyKey].investmentAmount <= 0" [matTooltip]="portfolioService.nATooltip">
            {{utilService.getDisplayFormattedNumber(comp['investmentAmount_' + currencyKey], 1, "", "NA")}}
          </span>
        </ng-template>
      </e-column>

      <e-column [field]="'realisedProceeds_' + currencyKey" headerText= '{{translateService.getLabel("realised_proceeds")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['realisedProceeds_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['realisedProceeds_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('realised_proceeds')" [typeOfSource]="'User Entry'"
              [formula]="drillDownService.getFormulaForDrillDownItems('realised_proceeds')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>

      <e-column [field]="'stakeValue_' + currencyKey" headerText='{{translateService.getLabel("nav")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['stakeValue_' + currencyKey]">
        <ng-template #template let-comp>
            <span>
              <app-data-drill-down-menu [value]="comp['stakeValue_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
                [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [onlyPositiveAllowed]="false" [label]="translateService.getLabel('nav')" [typeOfSource]="'Calculated'"
                [formula]="drillDownService.getFormulaForDrillDownItems('nav')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
        </ng-template>
      </e-column>


      <e-column [field]="'totalValue_' + currencyKey" headerText= '{{translateService.getLabel("total_value")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['totalValue_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['totalValue_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('total_value')" [typeOfSource]="'Calculated'"
              [formula]="drillDownService.getFormulaForDrillDownItems('total_value')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('totalValue', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      
      <e-column [field]="'grossIRR_' + currencyKey" headerText= '{{translateService.getLabel("gross_irr")}}' width='90px' textAlign='Right' [visible]="gridAllColumnsShowStatus['grossIRR_' + currencyKey]">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp[currencyKey].grossIRR != 'loading'">
              
              <ng-template [ngIf]="comp[currencyKey].investmentAmount > 0" [ngIfElse] = "IRRNA" >
                <span *ngIf = "comp[currencyKey].grossIRR >= 0; else IRRLessThanZero">
                  <app-data-drill-down-menu [value]="comp[currencyKey].grossIRR" [decimalPoint]="1" [suffix]="'%'"
                    [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('gross_irr')" [typeOfSource]="'Calculated'"
                    [formula]="drillDownService.getFormulaForDrillDownItems('gross_irr')" [underlyingMetrics]="drillDownService.getIRRforCurrentOrRealisedPortfolio(comp, currencyKey, 'IRRInDestCurrReqBody')"
                    [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
                  </app-data-drill-down-menu>
                </span>
                <ng-template #IRRLessThanZero>
                  <span style="color: red;">
                    <app-data-drill-down-menu [value]="comp[currencyKey].grossIRR" [decimalPoint]="1" [suffix]="'%'"
                      [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                      [label]="translateService.getLabel('gross_irr')" [displayNegativeNo]="true" [typeOfSource]="'Calculated'"
                      [formula]="drillDownService.getFormulaForDrillDownItems('gross_irr')" [underlyingMetrics]="drillDownService.getIRRforCurrentOrRealisedPortfolio(comp, currencyKey, 'IRRInDestCurrReqBody')"
                      [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
              </ng-template>
              <ng-template #IRRNA>
                <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
                  NA
                </span>
              </ng-template>
            </ng-template>
            <ng-template [ngIf]="comp[currencyKey].grossIRR == 'loading'">
              <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20">
              </mat-spinner>
            </ng-template>
            
          </ng-template>
      </e-column>

      <e-column [field]="'moic_' + currencyKey" [headerText]='translateService.getLabel("moic")' width='80px' textAlign='Right' [visible]="gridAllColumnsShowStatus['moic_' + currencyKey]">
        <ng-template #template let-comp>
          <span *ngIf="comp[currencyKey].moic > 0">
            <app-data-drill-down-menu [value]="comp['moic_' + currencyKey]" [decimalPoint]="1" [suffix]="'x'"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('moic')"
              [formula]="drillDownService.getFormulaForDrillDownItems('moic')" [typeOfSource]="'Calculated'"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('moic', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
          <span style="color: red;" *ngIf="!comp[currencyKey].moic || comp[currencyKey].moic <= 0">
            <app-data-drill-down-menu [value]="comp['moic_' + currencyKey]" [decimalPoint]="1" [suffix]="'x'"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [displayNegativeNo]="true" [label]="translateService.getLabel('moic')" [typeOfSource]="'Calculated'"
              [formula]="drillDownService.getFormulaForDrillDownItems('moic')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('moic', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      <e-column [field]="'changeInMOIC_' + currencyKey" [headerText]='translateService.getLabel("percentage_change_in_moic")' width='120px' textAlign='Right' [visible]="gridAllColumnsShowStatus['changeInMOIC_' + currencyKey]">
        <ng-template #template let-comp>
          <!-- null or undefined -->
          <span *ngIf="!comp['changeInMOIC_' + currencyKey] && comp['changeInMOIC_' + currencyKey] != 0">
            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"></mat-spinner>
          </span>
          <!-- Positive change in MOIC -->
          <span *ngIf="comp['changeInMOIC_' + currencyKey] >= 0" [RawDataDirective]="comp['changeInMOIC_' + currencyKey]">
            {{comp['changeInMOIC_' + currencyKey] | number:"1.1-1"}}%
          </span>
          
          <!-- Negative change in MOIC -->
          <span *ngIf="comp['changeInMOIC_' + currencyKey] < 0" [RawDataDirective]="comp['changeInMOIC_' + currencyKey]" style="color: red;">
            {{comp['changeInMOIC_' + currencyKey] | number:"1.1-1"}}%
          </span>
        </ng-template>
      </e-column>

      <e-column [field]="'changeInValuationPercentage_' + currencyKey" headerText= '{{translateService.getLabel("from_last_val_date")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['changeInValuationPercentage_' + currencyKey]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="!comp[currencyKey].changeInValuationPercentage || comp[currencyKey].changeInValuationPercentage >= 0">
            <span>
              <app-data-drill-down-menu [value]="comp['changeInValuationPercentage_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [onlyPositiveAllowed]="false" [label]="translateService.getLabel('from_last_val_date')" [typeOfSource]="'Calculated'"
                [formula]="drillDownService.getFormulaForDrillDownItems('from_last_val_date', comp, currencyKey)" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('from_last_val_date', comp, currencyKey)"
                [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
                [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
          <ng-template [ngIf]="comp[currencyKey].changeInValuationPercentage < 0">
            <span style="color: red;">
              <app-data-drill-down-menu [value]="comp['changeInValuationPercentage_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [displayNegativeNo]="true" [label]="translateService.getLabel('from_last_val_date')" [typeOfSource]="'Calculated'"
                [formula]="drillDownService.getFormulaForDrillDownItems('from_last_val_date', comp, currencyKey)" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('from_last_val_date', comp, currencyKey)"
                [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
                [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
        </ng-template>
      </e-column>
    </e-columns>
    
    <e-aggregates>
      <e-aggregate>
        <e-columns>
            <e-column  columnName="logo" type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="total-row">
                  {{translateService.getLabel("total")}}
                </span>

              </ng-template>
            </e-column>

            <e-column  columnName="investmentAmount"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <ng-template [ngIf]="!totalAggregations.totalInvestment || totalAggregations.totalInvestment >= 0">
                  <span class="currency-table-footer">
                    <app-data-drill-down-menu [value]="totalAggregations.totalInvestment" [decimalPoint]="1" [suffix]="''"
                      [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')"
                      [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_investedAmount') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_investedAmount')" 
                      [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                      [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
                <ng-template [ngIf]="totalAggregations.totalInvestment < 0">
                  <span class="currency-table-footer" style="color: red;">
                    <app-data-drill-down-menu [value]="totalAggregations.totalInvestment" [decimalPoint]="1" [suffix]="''"
                      [valueInPlaceOfZero]="'NA'" [valuationDate]="'NA'" [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')"
                      [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_investedAmount') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_investedAmount')" 
                      [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                      [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
              </ng-template>
            </e-column>

            <e-column  columnName="realisedProceeds"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="currency-table-footer">
                  <app-data-drill-down-menu [value]="totalAggregations.totalRealisedProceeds" [decimalPoint]="1" [suffix]="''"
                    [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('realised_proceeds')"
                    [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('totalRealisedProceedsForExitedInvestments') : drillDownService.getFormulaForDrillDownItems('totalRealisedProceedsForCurrentInvestments')" 
                    [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                    [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                  </app-data-drill-down-menu>
                </span>

              </ng-template>
            </e-column>

            <e-column  columnName="stakeValue"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="currency-table-footer">
                  <app-data-drill-down-menu [value]="totalAggregations.totalStakeValue" [decimalPoint]="1" [suffix]="''"
                    [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('nav')"
                    [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_stakeValue') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_stakeValue')"
                    [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                    [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                  </app-data-drill-down-menu>
                </span>

              </ng-template>
            </e-column>

            <e-column  columnName="totalValue"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="currency-table-footer">
                  <app-data-drill-down-menu [value]="totalAggregations.totalTotalValue" [decimalPoint]="1" [suffix]="''"
                    [valueInPlaceOfZero]="'0.0'" [valuationDate]="'NA'"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('total_value')"
                    [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_totalValue') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_totalValue')" 
                    [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('totalValue', totalAggregations)" [typeOfSource]="'Calculated'"
                    [source]="drillDownService.getSourceForDrillDown('NA')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                  </app-data-drill-down-menu>
                </span>

              </ng-template>
            </e-column>

            <e-column  columnName="grossIRR"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="currency-table-footer">
                  <ng-template [ngIf]="!totalAggregations.totalIRR || totalAggregations.totalIRR >= 0">
                    <span>
                      <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                        [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'"
                        [onlyPositiveAllowed]="false" [label]="translateService.getLabel('gross_irr')"
                        [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_gross_irr') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_gross_irr')" 
                        [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                        [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                      </app-data-drill-down-menu>
                    </span>
                  </ng-template>
                  <ng-template [ngIf]="totalAggregations.totalIRR < 0">
                    <span style="color: red;">
                      <app-data-drill-down-menu [value]="totalAggregations.totalIRR" [decimalPoint]="1" [suffix]="'%'"
                        [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'"
                        [onlyPositiveAllowed]="false" [label]="translateService.getLabel('gross_irr')"
                        [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_gross_irr') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_gross_irr')" 
                        [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('NA')" [typeOfSource]="'Calculated'"
                        [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                      </app-data-drill-down-menu>
                    </span>
                  </ng-template>
                  <sup class="superscript" [RawDataDirective]="portfolioService.aggregateTooltip">*</sup>
                
                </span>  
              </ng-template>
            </e-column>

            <e-column  columnName="moic"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <span class="currency-table-footer">
                  <app-data-drill-down-menu [value]="totalAggregations.totalMOIC" [decimalPoint]="1" [suffix]="'x'"
                    [valueInPlaceOfZero]="'0.0 x'" [valuationDate]="'NA'"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('moic')"
                    [formula]="exitGrid ? drillDownService.getFormulaForDrillDownItems('realisedPortfolio_moic') : drillDownService.getFormulaForDrillDownItems('currentPortfolio_moic')"
                    [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('moic', totalAggregations)" [typeOfSource]="'Calculated'"
                    [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                  </app-data-drill-down-menu>
                </span>
                <sup class="superscript" [RawDataDirective]="portfolioService.aggregateTooltip">*</sup>
              </ng-template>
            </e-column>

            <e-column  columnName="changeInValPercentage"  textAlign='Right' type="Custom">
              <ng-template #footerTemplate let-data>
                <ng-template
                  [ngIf]="!totalAggregations.totalChangeInValPercentage || totalAggregations.totalChangeInValPercentage >= 0">
                  <span class="currency-table-footer">
                    <app-data-drill-down-menu [value]="totalAggregations.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'"
                      [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="'NA'" [label]="translateService.getLabel('from_last_val_date')" [typeOfSource]="'Calculated'"
                      [formula]="drillDownService.getFormulaForDrillDownItems('total_from_last_val_date')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', totalAggregations)"
                      [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
                <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage < 0">
                  <span style="color: red;">
                    <app-data-drill-down-menu [value]="totalAggregations.totalChangeInValPercentage" [decimalPoint]="1" [suffix]="'%'" [typeOfSource]="'Calculated'"
                      [valueInPlaceOfZero]="'0.0 %'" [displayNegativeNo]="true" [valuationDate]="'NA'" [label]="translateService.getLabel('from_last_val_date')"
                      [formula]="drillDownService.getFormulaForDrillDownItems('total_from_last_val_date')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForAggregations('from_last_val_date', totalAggregations)"
                      [source]="drillDownService.getSourceForDrillDown('')" [valDateId]="''" [currency]="portfolioService.selectedCurrency">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
              
              </ng-template>
            </e-column>
            
        </e-columns>
      </e-aggregate>
    </e-aggregates>
  </ejs-grid>
</div>



<div class="table table-condensed" id="container" class="investment-summary-grid" *ngIf="!showAggregations">
  <ejs-grid #summaryGrid
    [dataSource]="portfolioData" 
    [allowResizing]="true" 
    [allowTextWrap]="true" 
    [allowGrouping]="true" 
    [allowExcelExport]="true" 
    [allowSorting]="true" 
    [allowReordering]="true" 
    [allowPaging]="true" 
    [pageSettings]="gridPageSettings" 
    [showColumnChooser]="true" 
    [toolbar]="gridToolbar" 
    [allowFiltering]="true" 
    [filterSettings]="gridFilterSettings"
    (rowSelected)="companyRowSelected($event)"
    (actionComplete)='actionComplete($event)'>

    <e-columns>

      <e-column field='logo' headerText='{{translateService.getLabel("logo")}}' [allowSorting]="false" [allowFiltering]="false" width='75px' [visible]="gridAllColumnsShowStatus.logo">
        <ng-template #template let-comp>
          <img [src]="comp.logo" class="companyLogo" (error)="comp.logo = null" *ngIf="comp.logo; else defaultLogo">
          <ng-template #defaultLogo>
            <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column field='companyName' headerText= '{{translateService.getLabel("name")}}' field='companyName' width='200px' [visible]="gridAllColumnsShowStatus.companyName">
        <ng-template #template let-comp>
          <span style="font-weight: 500;">{{comp.companyName}}</span>
        </ng-template>
      </e-column>

      <e-column field='investmentDate' [sortComparer]='utilService.sortComparer' headerText = '{{translateService.getLabel("investment_date")}}' width='130px' [visible]="gridAllColumnsShowStatus.investmentDate">
        <ng-template #template let-comp>
          <!-- {{ comp.investmentDate | date: "mediumDate" }} -->
          <app-data-drill-down-menu [value]="comp.investmentDate | date: 'mediumDate'" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
            [label]="translateService.getLabel('investment_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('form', 'investment_date')" [valDateId]="comp.latestValuationDateId"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>
      <e-column field='latestValuationDate' [sortComparer]='utilService.sortComparer' headerText= '{{translateService.getLabel("valuationDate")}}' width='130px' [visible]="gridAllColumnsShowStatus.latestValuationDate">
        <ng-template #template let-comp>
          <app-data-drill-down-menu [value]="comp.latestValuationDate | date: 'mediumDate'" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
            [label]="translateService.getLabel('investment_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('form', 'investment_date')" [valDateId]="comp.latestValuationDateId"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>

      <e-column field='geography' headerText= '{{translateService.getLabel("geography")}}' width='110px' [visible]="gridAllColumnsShowStatus.geography">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.geography">
            <!-- {{comp.geography | titlecase}} -->
            <app-data-drill-down-menu [value]="comp.geography" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [label]="translateService.getLabel('geography')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
              [underlyingMetrics]="'NA'" [source]="drillDownService.getSourceForDrillDown('form', 'location')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column field='sector' headerText='{{translateService.getLabel("sector")}}' field='sector' width='120px' [visible]="gridAllColumnsShowStatus.sector">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.sector">{{comp.sector | titlecase}}</ng-template>
        </ng-template>
      </e-column>

      <e-column field='security' headerText='{{translateService.getLabel("type_of_security")}}' field='security' width='100px' [visible]="gridAllColumnsShowStatus.security">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp.security">{{comp.security | titlecase}}</ng-template>
        </ng-template>
      </e-column>

      <e-column field='exitDate' [sortComparer]='utilService.sortComparer' headerText='{{translateService.getLabel("exit_date")}}' width='90px' [visible]="gridAllColumnsShowStatus.exitDate" *ngIf="exitGrid">
        <ng-template #template let-comp>
          <!-- {{utilService.displayFormattedDate(comp.exitDate, 'll')}} -->
          <app-data-drill-down-menu [value]="comp.exitDate | date: 'mediumDate'" textField="true"[valuationDate]="comp.latestValuationDate | date: 'mediumDate'"
            [label]="translateService.getLabel('exit_date')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
            [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('investment_summary')" [valDateId]="comp.id"
            [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
          </app-data-drill-down-menu>
        </ng-template>
      </e-column>
      <e-column [field]='currencyToBeDisplayed' headerText='{{translateService.getLabel("currency")}}' textAlign='Right' width='90px' [visible]="gridAllColumnsShowStatus[currencyToBeDisplayed]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp[currencyToBeDisplayed]">
            <!-- {{comp[currencyToBeDisplayed] | uppercase}} -->
            <app-data-drill-down-menu [value]="comp[currencyToBeDisplayed]" textField="true" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [label]="translateService.getLabel('currency')" [formula]="drillDownService.getFormulaForDrillDownItems('NA')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')" [source]="drillDownService.getSourceForDrillDown('form', 'currency')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </ng-template>
        </ng-template>
      </e-column>

      <e-column [field]="'stake_' + currencyKey" headerText='{{translateService.getLabel("stake")}}' width='90px' textAlign='Right' [visible]="gridAllColumnsShowStatus['stake_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['stake_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
              [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('stake')"
              [formula]="drillDownService.getFormulaForDrillDownItems('stake')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
              [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      <e-column [field]="'fullyDilutedStake_' + currencyKey" headerText= '{{translateService.getLabel("Fully diluted stake")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['fullyDilutedStake_' + currencyKey]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="comp['fullyDilutedStake_' + currencyKey] >= 0" [ngIfElse]="NA">
            <span *ngIf="comp['fullyDilutedStake_' + currencyKey] >= 0">
              <app-data-drill-down-menu [value]="comp['fullyDilutedStake_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [onlyPositiveAllowed]="false" [label]="translateService.getLabel('Fully diluted stake')" [typeOfSource]="'User Entry'"
                 [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('NA')"
                [source]="drillDownService.getSourceForDrillDown('cap table')" [valDateId]="comp.latestValuationDateId" [currency]="portfolioService.selectedCurrency">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
          <ng-template #NA>
            <span style="color: red;" *ngIf="!comp['fullyDilutedStake_' + currencyKey] || comp['fullyDilutedStake_' + currencyKey] < 0">
              {{utilService.getDisplayFormattedNumber(comp['fullyDilutedStake_' + currencyKey], 1, "", "NA")}}
            </span>
          </ng-template>
        </ng-template>
      </e-column>
      
      <e-column [field]="'investmentAmount_' + currencyKey" headerText='{{translateService.getLabel("investment_amount")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['investmentAmount_' + currencyKey]">
        <ng-template #template let-comp>
          <span *ngIf="comp[currencyKey].investmentAmount > 0">
            <app-data-drill-down-menu [value]="comp['investmentAmount_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('investment_amount')"
              [formula]="drillDownService.getFormulaForDrillDownItems('investment_amount')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </span>
          <span style="color: red;" *ngIf="!comp[currencyKey].investmentAmount || comp[currencyKey].investmentAmount <= 0" [matTooltip]="portfolioService.nATooltip">
            {{utilService.getDisplayFormattedNumber(comp['investmentAmount_' + currencyKey], 1, "", "NA")}}
          </span>
        </ng-template>
      </e-column>

      <e-column [field]="'realisedProceeds_' + currencyKey" headerText= '{{translateService.getLabel("realised_proceeds")}}' width='110px' textAlign='Right' [visible]="gridAllColumnsShowStatus['realisedProceeds_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['realisedProceeds_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('realised_proceeds')"
              [formula]="drillDownService.getFormulaForDrillDownItems('realised_proceeds')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'User Entry'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>

      <e-column [field]="'stakeValue_' + currencyKey" headerText= '{{translateService.getLabel("nav")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['stakeValue_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['stakeValue_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('nav')"
              [formula]="drillDownService.getFormulaForDrillDownItems('nav')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('')"
              [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>


      <e-column [field]="'totalValue_' + currencyKey" headerText= '{{translateService.getLabel("total_value")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['totalValue_' + currencyKey]">
        <ng-template #template let-comp>
          <span>
            <app-data-drill-down-menu [value]="comp['totalValue_' + currencyKey]" [decimalPoint]="1" [suffix]="''"
              [valueInPlaceOfZero]="'0.0'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('total_value')"
              [formula]="drillDownService.getFormulaForDrillDownItems('total_value')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('totalValue', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      
      <e-column [field]="'grossIRR_' + currencyKey" headerText= '{{translateService.getLabel("gross_irr")}}' width='90px' textAlign='Right' [visible]="gridAllColumnsShowStatus['grossIRR_' + currencyKey]">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp[currencyKey].grossIRR != 'loading'">
              <ng-template [ngIf]="comp[currencyKey].investmentAmount > 0" [ngIfElse] = "IRRNA" >
                <span *ngIf = "comp[currencyKey].grossIRR >= 0; else IRRLessThanZero">
                  <app-data-drill-down-menu [value]="comp['grossIRR_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                    [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                    [onlyPositiveAllowed]="false" [label]="translateService.getLabel('gross_irr')"
                    [formula]="drillDownService.getFormulaForDrillDownItems('gross_irr')" [underlyingMetrics]="drillDownService.getIRRforCurrentOrRealisedPortfolio(comp, currencyKey, 'IRRReqBody')"
                    [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
                    [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                  </app-data-drill-down-menu>
                </span>
                <ng-template #IRRLessThanZero>
                  <span style="color: red;">
                    <app-data-drill-down-menu [value]="comp['grossIRR_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                      [valueInPlaceOfZero]="'0.0%'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                      [label]="translateService.getLabel('gross_irr')" [displayNegativeNo]="true"
                      [formula]="drillDownService.getFormulaForDrillDownItems('gross_irr')" [underlyingMetrics]="drillDownService.getIRRforCurrentOrRealisedPortfolio(comp, currencyKey, 'IRRReqBody')"
                      [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
                      [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
                    </app-data-drill-down-menu>
                  </span>
                </ng-template>
              </ng-template>
              <ng-template #IRRNA>
                <span style="color: red;" [RawDataDirective]="portfolioService.nATooltip">
                  NA
                </span>
              </ng-template>
            </ng-template>
            <ng-template [ngIf]="comp[currencyKey].grossIRR == 'loading'">
              <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20">
              </mat-spinner>
            </ng-template>
          </ng-template>
      </e-column>

      <e-column [field]="'moic_' + currencyKey" [headerText]="translateService.getLabel('moic')" width='80px' textAlign='Right' [visible]="gridAllColumnsShowStatus['moic_' + currencyKey]">
        <ng-template #template let-comp>
          <span *ngIf="comp[currencyKey].moic > 0">
            <app-data-drill-down-menu [value]="comp['moic_' + currencyKey]" [decimalPoint]="1" [suffix]="'x'"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [onlyPositiveAllowed]="false" [label]="translateService.getLabel('moic')"
              [formula]="drillDownService.getFormulaForDrillDownItems('moic')"
              [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('moic', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
          <span style="color: red;" *ngIf="!comp[currencyKey].moic || comp[currencyKey].moic <= 0">
            <app-data-drill-down-menu [value]="comp['moic_' + currencyKey]" [decimalPoint]="1" [suffix]="'x'"
              [valueInPlaceOfZero]="'NA'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
              [displayNegativeNo]="true" [label]="translateService.getLabel('moic')"
              [formula]="drillDownService.getFormulaForDrillDownItems('moic')" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('moic', comp, currencyKey)"
              [source]="drillDownService.getSourceForDrillDown('transactions')" [valDateId]="comp.latestValuationDateId"
              [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
            </app-data-drill-down-menu>
          </span>
        </ng-template>
      </e-column>
      <e-column [field]="'changeInMOIC_' + currencyKey" [headerText]='translateService.getLabel("percentage_change_in_moic")' width='120px' textAlign='Right' [visible]="gridAllColumnsShowStatus['changeInMOIC_' + currencyKey]">
        <ng-template #template let-comp>
          <!-- null or undefined -->
          <span *ngIf="!comp['changeInMOIC_' + currencyKey] && comp['changeInMOIC_' + currencyKey] != 0">
            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"></mat-spinner>
          </span>
          <!-- Positive change in MOIC -->
          <span *ngIf="comp['changeInMOIC_' + currencyKey] >= 0" [RawDataDirective]="comp['changeInMOIC_' + currencyKey] >= 0">
            {{comp['changeInMOIC_' + currencyKey] | number:"1.1-1"}}%
          </span>
          
          <!-- Negative change in MOIC -->
          <span *ngIf="comp['changeInMOIC_' + currencyKey] < 0" [RawDataDirective]="comp['changeInMOIC_' + currencyKey] >= 0" style="color: red;">
            {{comp['changeInMOIC_' + currencyKey] | number:"1.1-1"}}%
          </span>
        </ng-template>
      </e-column>
      <!-- NOTE: prevValuationDate -->
      <e-column [field]="'changeInValuationPercentage_' + currencyKey" headerText='{{translateService.getLabel("from_last_val_date")}}' width='100px' textAlign='Right' [visible]="gridAllColumnsShowStatus['changeInValuationPercentage_' + currencyKey]">
        <ng-template #template let-comp>
          <ng-template [ngIf]="!comp[currencyKey].changeInValuationPercentage || comp[currencyKey].changeInValuationPercentage >= 0">
            <span>
              <app-data-drill-down-menu [value]="comp['changeInValuationPercentage_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [onlyPositiveAllowed]="false" [label]="translateService.getLabel('from_last_val_date')"
                [formula]="drillDownService.getFormulaForDrillDownItems('from_last_val_date', comp, currencyKey)" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('from_last_val_date', comp, currencyKey)"
                [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
                [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
          <ng-template [ngIf]="comp[currencyKey].changeInValuationPercentage < 0">
            <span style="color: red;">
              <app-data-drill-down-menu [value]="comp['changeInValuationPercentage_' + currencyKey]" [decimalPoint]="1" [suffix]="'%'"
                [valueInPlaceOfZero]="'0.0 %'" [valuationDate]="datePipe.transform(comp.latestValuationDate, mediumDate)"
                [displayNegativeNo]="true" [label]="translateService.getLabel('from_last_val_date')"
                [formula]="drillDownService.getFormulaForDrillDownItems('from_last_val_date', comp, currencyKey)" [underlyingMetrics]="drillDownService.getUnderlyingMetricsForDrillDown('from_last_val_date', comp, currencyKey)"
                [source]="drillDownService.getSourceForDrillDown('valuation_summary')" [valDateId]="comp.latestValuationDateId"
                [currency]="portfolioService.selectedCurrency" [typeOfSource]="'Calculated'">
              </app-data-drill-down-menu>
            </span>
          </ng-template>
        </ng-template>
      </e-column>

    </e-columns>
  </ejs-grid>
</div>