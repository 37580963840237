<div>
  <span *ngIf="item.approvalStatus == approvalMatrixService.APPROVAL_STATUS.REJECTED">{{ translateService.getLabel("rejected") }}</span>
  <span *ngIf="item.approvalStatus == approvalMatrixService.APPROVAL_STATUS.APPROVED">{{ translateService.getLabel("approved") }}</span>
  <span *ngIf="item.approvalStatus ==  approvalMatrixService.APPROVAL_STATUS.NOT_STARTED">{{ translateService.getLabel("approval") }} - {{ translateService.getLabel("not_started") }}</span>
  <span *ngIf="item.approvalStatus ==  approvalMatrixService.APPROVAL_STATUS.PENDING">{{ translateService.getLabel("approval") }} - {{ translateService.getLabel("in_progress") }}</span>
  <span *ngIf="item.approvalStatus ==  approvalMatrixService.APPROVAL_STATUS.REJECTED || item.approvalStatus == approvalMatrixService.APPROVAL_STATUS.APPROVED ">
    <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%">
      <i class="far fa-comment-alt"></i>
    </span>
    <mat-menu #belowMenu="matMenu" yPosition="below">
      <div class="comment-box">
        <div class="row comment-Header"> 
          <div class="col">{{ translateService.getLabel("comments")}} ({{item.approvalUserData[0].length}})</div>
        </div>
        <div class="comment-Body" *ngFor="let commentItem of item.approvalUserData[0]">
          <div class="row">
            <div class="col-6 bold-font align-left">{{commentItem?.user?.name}}</div>
            <div class="col-6 align-right" [ngStyle]="{'color': commentItem?.status == approval ? 'red' : 'green' }" style="text-transform: capitalize;">
              {{commentItem?.status}}
            </div>
          </div>
          <div class="row font-size-small">
            <div class="col align-left" >{{commentItem?.timestamp  | date:'medium'}}</div>
          </div>
          <div class="row" style="font-size: 12px;">
            <div class="col">{{commentItem?.comments}}</div>
          </div>
        </div>
      </div>
    </mat-menu>
  </span>
</div>