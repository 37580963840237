import { DatePipe } from '@angular/common';
import { Component, OnInit, Input} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';

@Component({
  selector: 'app-guest-users-share-popup',
  templateUrl: './guest-users-share-popup.component.html',
  styleUrls: ['./guest-users-share-popup.component.scss']
})
export class GuestUsersSharePopupComponent implements OnInit {
  @Input() shareInput;
  @Input() newCompany;
  @Input() selectedFormToShare;
  @Input() valuationSharePopUpModal;
  filterGuestUserData = [];
  allGuestUsers = [];
  shareInputName;
  constructor(
    public ums: UserManagementService,
    public fundService: FundListService,
    public utilService: UtilService, 
    public translateService: TranslateService,
    private ds: DataService,
    private datePipe: DatePipe

  ) { }

  ngOnInit(): void {
    let user = this.ums.getSelectedUserDetails();
    this.getAllGuestUsers(user)
    console.log("shareInput", this.shareInput)
  }

  filteredOptions(event){
    this.filterGuestUserData = []
    this.allGuestUsers.sort( function(a,b) { 
      if (a.userName < b.userName) return -1;
      else if (a.userName > b.userName) return 1;
    })
    this.allGuestUsers.forEach(x=>{
      let userName = x.userName.toLowerCase()
      if(userName.includes(event.target.value.toLowerCase()) && this.userAlreadySelected(x)){
        this.filterGuestUserData.push(x)
      }
    })
  }

  userAlreadySelected(x){
    return this.shareInput.users.findIndex(item => item.id == x.id) == -1;
  }

  getAllGuestUsers(user){
    let orgId = user.organization.id
    this.ds.getAllGuestUsers(orgId).subscribe(res=>{
      this.allGuestUsers = res.body["response"]
    })
  }

  addShareInputs(userValue) {
    let obj = {
      "email": userValue.userId,
      "name": userValue.userName,
      "id": userValue.id,
      "initialName": userValue.userName[0].toUpperCase()
    }
    if (this.shareInput.users.findIndex(item => item.email === userValue.userId) == -1) {
      this.shareInput.users.push(obj);
    }
    let selectedUserIndex = this.filterGuestUserData.findIndex(item => item.id == userValue.id)
    this.filterGuestUserData.splice(selectedUserIndex,1)
    this.shareInputName = "";
  }

  checkUncheckAll(){
    if (this.shareInput.sharePermissions.every(val => val.selected == true))
      this.shareInput.sharePermissions.forEach(val => { val.selected = false });
    else
      this.shareInput.sharePermissions.forEach(val => { val.selected = true });
  }

  removeShareInputs(index) {
    this.shareInput.users.splice(index, 1);
  }

  share(popUp) {

    console.log("this.shareInput", this.shareInput)
    if (this.shareInput.users && this.shareInput.users.length > 0 && this.isValidShareInput(this.shareInput.users)) {
      const selectedTabs = this.shareInput.sharePermissions.filter(tab => tab['selected'] )
      if(selectedTabs.length > 0) {
        this.shareInput['sharedTabNames'] = selectedTabs.map( tab => tab.label).join(", ");
      } else {
        //No tabs are selected. Stay on the Input Popup.
        return;
      }

      popUp.dismiss('Cross click');

      this.shareInput["valuationDate"] = this.datePipe.transform(this.selectedFormToShare.valuationDate, "MMM dd, yyyy");
      this.shareInput["companyName"] = this.newCompany.name;

      this.ds.shareFormToExternalUser(this.selectedFormToShare.id, this.shareInput).subscribe(res => {
        
        let shareInputUserNames = this.shareInput.users.map(obj=>obj.name).toString();
        this.utilService.showMessage(this.translateService.getLabel("valuation_date") + " (" + this.selectedFormToShare.valuationDate + ") " + this.translateService.getLabel("is_shared_to") + " " + shareInputUserNames, this.translateService.getLabel("ok"));
      }, error => {
        
        this.utilService.showMessage(this.translateService.getLabel("err_failed_share_valuation_date") + " (" + this.selectedFormToShare.valuationDate + ")", this.translateService.getLabel("ok"), true);
      })
        
    } else {
      // Stay on the Input Popup.
    }    
  }

  isValidShareInput(users) {
    users.forEach(obj => {
      if (!(obj.name.length > 3 && obj.email.length > 3)) {
        return false;
      }
    });
    return true;
  }

}
