import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-drd-attr-details',
  templateUrl: './drd-attr-details.component.html',
  styleUrls: ['./drd-attr-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrdAttrDetailsComponent {
  displayedOrgColumns = [
    'orgName',
    'gxGlobalAttributes',
    'reportsGlobalAttributes',
    'globalAttrsDiff',
    'gxCustomAttributes',
    'reportsCustomAttributes',
    'customAttrsDiff',
    'gxAttributeFormula',
    'reportsAttributeFormula',
    'attributeFormulaDiff',
    'gxAttributeFormulaDetails',
    'reportsAttributeFormulaDetails',
    'attributeFormulaDetailsDiff',
    'gxAttributeFormulaCompanies',
    'reportsAttributeFormulaCompanies',
    'attributeFormulaCompaniesDiff'
  ];

  _attrsDetails: any[]; // any for now on purpose for POC

  tabeDataSource: MatTableDataSource<any[]>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() set attrsDiffDetails(attrsDiffDetails) {
    this._attrsDetails = [...attrsDiffDetails];
    if (this._attrsDetails) {
      this.tabeDataSource = new MatTableDataSource(this._attrsDetails);
      this.tabeDataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
