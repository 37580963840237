import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class XIRRServiceService {

  constructor() { }

  XIRR (values, dates, guess){
    // Credits: algorithm inspired by Apache OpenOffice
    
    // Calculates the resulting amount
    var irrResult = (values, dates, rate) => {
      var r = rate + 1;
      var result = values[0];
      for (var i = 1; i < values.length; i++) {
        result += values[i] / Math.pow(r, moment(dates[i]).diff(moment(dates[0]), 'days') / 365);
      }
      return result;
    }
  
    // Calculates the first derivation
    var irrResultDeriv = (values, dates, rate) => {
      var r = rate + 1;
      var result = 0;
      for (var i = 1; i < values.length; i++) {
        var frac = moment(dates[i]).diff(moment(dates[0]), 'days') / 365;
        result -= frac * values[i] / Math.pow(r, frac + 1);
      }
      return result;
    }
  
    // Check that values contains at least one positive value and one negative value
    var positive = false;
    var negative = false;
    for (var i = 0; i < values.length; i++) {
      if (values[i] > 0) positive = true;
      if (values[i] < 0) negative = true;
    }
    
    // Return error if values does not contain at least one positive value and one negative value
    if (!positive || !negative) return '#NUM!';
  
    // Initialize guess and resultRate
    var guess = (typeof guess === 'undefined') ? 0.1 : guess;
    var resultRate = guess;
    
    // Set maximum epsilon for end of iteration
    var epsMax = 1e-10;
    
    // Set maximum number of iterations
    var iterMax = 50;
  
    // Implement Newton's method
    var newRate, epsRate, resultValue;
    var iteration = 0;
    var contLoop = true;
    do {
      resultValue = irrResult(values, dates, resultRate);
      newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate);
      epsRate = Math.abs(newRate - resultRate);
      resultRate = newRate;
      contLoop = (epsRate > epsMax) && (Math.abs(resultValue) > epsMax);
    } while(contLoop && (++iteration < iterMax));
  
    if(contLoop) return '#NUM!';
  
    // Return internal rate of return
    return resultRate;
  }
}
