import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from 'src/app/utils/util.service';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DateUtilService } from 'src/app/date-util.service';
import { ToastService } from 'src/app/utils/toast.service';
import { AttributeListService } from './attribute-list.service';
import { AttributeList } from './attr-list-interfaces';

@Component({
  selector: 'app-attribute-list-container',
  templateUrl: './attribute-list-container.component.html',
  styleUrls: ['./attribute-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeListContainerComponent implements OnInit, OnDestroy {
  fundId: string;
  private commonSub = new Subscription();
  attributesMapList: AttributeList[];

  constructor(
    private utilService: UtilService,
    private configMappingService: MappingMgmtConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private dateUtilSvc: DateUtilService,
    public attributeListService: AttributeListService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const routeSnapshot = this.route.snapshot;
    this.fundId = routeSnapshot.paramMap.get('fundId');
    this.getAttributesLists();
  }

  getAttributesLists() {
    this.utilService.showLoadingPopup();
    this.commonSub.add(
      this.configMappingService._uploadDate
        .pipe(
          switchMap((newValDate) => {
            const date = this.dateUtilSvc.getDateYYYYMMDD(newValDate);
            return this.attributeListService.getAttributeListsOrDefault(date, this.fundId);
          })
        )
        .subscribe(
          (response) => {
            // this.attributesMapList = response;
            this.attributeListService.loadAttributeListsFromApiResponse(response);
            this.utilService.closeAllPopups();
            this.cdr.detectChanges();
          },
          (err) => {
            console.log('error', err);
            this.utilService.closeAllPopups();
            this.toastService.openSnackBar('Failed to get attribute lists.Please try again.');
          }
        )
    );
  }

  navigateToAttributeListDetails(row) {
    console.log('On row click', row.id);
    this.router.navigate(['../attributes-map-details', row.id], { relativeTo: this.route, queryParamsHandling: 'merge' });
  }

  onDeleteAttributeListHandler(e) {
    const attributeListId = e?.map?.id;
    const confirmationDialogRef = this.utilService.showConfirmMessage('Are you sure you want to delete this map?', 'Yes', 'No');
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.deleteAttributeList(attributeListId);
      }
    });
  }

  deleteAttributeList(attributeListId: string) {
    this.utilService.showLoadingPopup();
    this.attributeListService.deleteAttributeList(attributeListId).subscribe(
      (response) => {
        console.log('Attribute list deleted success...', response);
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Map deleted successfully.');
        this.getAttributesLists();
      },
      (err) => {
        console.log('Failed to delete attribute list...', err);
        this.utilService.closeAllPopups();
        this.toastService.openSnackBar('Failed to delete map.');
      }
    );
  }

  downloadExcelHandler(e) {
    const attributeList = e?.map;
    this.downloadAttributeListExcel(attributeList.id, attributeList.name);
  }

  downloadAttributeListExcel(attributeListId: string, name: string) {
    this.attributeListService.downloadAttributeListExcel(attributeListId).subscribe(
      (response) => {
        console.log('Attribute list download success...');
        const url = (window as any).URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        var link = document.createElement('a');
        link.href = url;
        link.download = `${name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        (window as any).URL.revokeObjectURL(url);
        this.toastService.openSnackBar('File downloaded.');
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Failed to download attribute list...', err);
        this.toastService.openSnackBar('Download failed. Please try again.');
      }
    );
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
