import { Injectable } from '@angular/core';
import { Chart } from 'highcharts';
import { MapChart } from 'angular-highcharts';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { CTMService } from '../portfolio/valuation-algorithms/ctm.service';
import { XIRRServiceService } from '../portfolio/xirrservice.service';
import { CCMServiceV3 } from '../portfolio/valuation-algorithms/ccm-new-v3.service';
import worldMap from '../portfolio/world-data.data';
import { EntityValuationData } from './legal-entities/legal-entities.model';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

@Injectable({
  providedIn: 'root'
})
export class QXP_PortFolioService {
  companies = [];
  fundDetails:any
  orgKeyWords = [];
  
  formsClone = []

  partnerList = {
    name: '',
    isSelected: false
  }

  securityType: string = '';
  fullyDilutedOwnership;
  selectedCompany = {}
  userId;
  portfolioDataClone;

  valuationDataForCrossHolding: Map<String, EntityValuationData>;

  formDetailsObj: any = {};
  portfolioData = [];
  searchedForms = [];

  topGainers = [];

  topLosers = [];

  selectedForm;

  totalPortfolioData = {
    totalInvestment : 0,
    totalRealisedProceeds : 0,
    totalMOIC : 0,
    totalIRR : 0,
    totalChangeInValPercentage: 0,
    totalStakeVal: 0,
    totalFairVal: 0,
    totalPrevStakeVal: 0
  };

  totalRealisedProceeds = 0;

  totalInvestmentAmount = 0;

  userSavedInvestedForms = [];

  multiples = {
    investmentAmount: 0,
    realisedProceeds: 0,
    moic: 0,
    irr: 0
  }

  mapData = {
    value: 'currentVal',
    name:'Current Valuation',
    data:[]
  }

  geographicalFormData = [];
  filteredForms = []
  geography = [];

  sectors = [];

  columnChartData = []

  columnChart;
  mapChart;

  companyId;
  fundId;

  portfolioSummary;
  portfolioSummaryWidget = "PORTFOLIO_SUMMARY";

  portfolioUpdated = new Subject();

  selectedCurrency = "USD"
  
  constructor(private dataService: DataService, private ums: UserManagementService, private ccmService: CCMServiceV3,
    private ctmService: CTMService, private xirrService : XIRRServiceService, private utilService: UtilService,
    private currExchangeService : CurrencyExchangeService)
  {
    const userId = this.ums.getSelectedUserDetails().id;
    this.dataService.getAllWidgetDataFromDB([this.portfolioSummaryWidget, "user_invested_forms"], userId).subscribe(res => {
      // console.log("Widget Logs", res.body["response"]);
      
      if(res.body["response"].user_invested_forms) {
        this.initUserAddedForms(res.body["response"].user_invested_forms);
      }

      if(res.body["response"][this.portfolioSummaryWidget]) {
        this.portfolioSummary = res.body["response"].PORTFOLIO_SUMMARY;
      }
    }, error => {
      console.log("Error: Failed to load portfolio widget data", error)
    })
  }

  init(fundId?) {
    if(fundId) {
      this.fundId = fundId;
    }

    if(!this.companies || this.companies.length === 0) {
      this.companies = [];
      this.getAllForms(fundId);
    } else {
      const loggedInUserId = this.ums.getSelectedUserDetails().id;
      
      if(this.companies[0].assessorUserId != loggedInUserId || (fundId && fundId !== this.companies[0].fundCompany)) {
        this.companies = [];
        this.getAllForms(fundId);  
              
      } else {
        this.preparePortfolioData();
        this.prepareMapData();
        this.prepareColumnChart();

        this.portfolioUpdated.next();
      }
    }
  }

  async initExchangeRate() {
    const reqBody = this.portfolioData.map((comp) => {
      if(comp.currency){
        return {
          from: comp.currency,
          to: this.selectedCurrency,
          date: comp.valuationDate
        }
      }
    }).filter( obj => obj);

    await this.currExchangeService.initExchangeRates(reqBody)

    this.portfolioData.forEach( comp => {
      if(this.currExchangeService.exchangeRates[comp.currency + this.selectedCurrency + comp.valuationDate]){
        comp['exchangeRate'] = this.currExchangeService.exchangeRates[comp.currency + this.selectedCurrency + comp.valuationDate]
      }
    })
  }

  async initUserAddedForms(userInvestedForms) {    
    try { 
      this.userSavedInvestedForms = userInvestedForms;
      this.userSavedInvestedForms.forEach((form, index)=>{
        form.userEntered = true;
        form.formVersion = this.ums.getApplicationVersion();
      })
      
      if(this.userSavedInvestedForms.length > 0){
        this.getUserAddedForms(this.userSavedInvestedForms);
      }

      this.preparePortfolioData();
      this.prepareMapData();
      this.prepareColumnChart();
    }
    catch(e) { console.log("No user added forms are found.")}
  }

  getAllForms(fundId?) {
    const userId = this.ums.getSelectedUserDetails().id;
    if(userId) {
      this.userId = userId;

      this.getOwnForms(fundId);
    }  
  }

  getOwnForms(fundId?) {
    this.dataService.getAllVCForms(this.userId, fundId).subscribe( results =>{

      if(results.body["response"] && results.body["response"].length > 0) {
        this.prepareCompanyData(results.body["response"]);
        this.getInvestmentData();   
      } 
    }, (error)=>{
      console.log("Error in fetching all forms", error);
    })
  }

  getAllFormsForAllFunds(funds) {
    const userId = this.ums.getSelectedUserDetails().id;
    this.dataService.getAllFormsForAllFunds(userId, funds).subscribe( results =>{

      if(results.body["response"] && results.body["response"].length > 0) {
        this.prepareCompanyData(results.body["response"]);
        this.getInvestmentData();        
      } 
    }, (error)=>{
      console.log("Error in fetching all forms", error);
    })
  }

  getAssignedForms(fundId?) {
    this.dataService.getAssignedForms(this.userId).subscribe(results=>{

      if(results.body["response"]) {
        this.prepareCompanyData(results.body["response"]);
      }
    }, error=>{
      console.log("Error in fetching assigned forms", error);
    })
  }

  getUserAddedForms(forms){
    this.companies = this.companies.concat(forms).sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })
  }

  prepareCompanyData(forms) {
    if(!forms) return;

    forms.forEach(form=>{
      if(form.assignees) { 
        const details = JSON.parse(form.assignees);
        form.assignees = details.assignees.map(user=>user.userName);
      }

      const details = JSON.parse(form.details);


      if(details) {        
        form.companyName = this.utilService.cleanString(details["companyName"]);
        form.status = details["status"];
        form.website = details["website"];
        form.geography = details["geography"];
        form.multipleRealisedProceeds = details['multipleRealisedProceeds']? details['multipleRealisedProceeds'] : [];
        form.totalRealisedProceeds = details['totalRealisedProceeds']? details['totalRealisedProceeds'] : 0;

        form.multipleInvestmentAmount = details['multipleInvestmentAmount']? details['multipleInvestmentAmount'] : [];
        form.totalInvestmentAmount = details['totalInvestmentAmount']? details['totalInvestmentAmount'] : 0;

        form.moic = details['moic']? details['moic'] : 0;
        form.irr = details['irr']? details['irr'] : 0;


        if(details["sector"]) {
          const sectorParts = details["sector"].split(",")
          form.sector = sectorParts[0];
        }        
      }

      form.valuation = {CONCLUDED_IA: null, CONCLUDED_CCM: null, CONCLUDED_CTM: null, concluded: null}
    })

    this.companies = this.companies.concat(forms).filter(f=> f.formVersion && +f.formVersion >= 2).sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })
  }

  getSelectedCompanyDates(companyId) {
    return this.companies.filter(comp=> comp.id === companyId || comp.groupFormId === companyId)
  }

  getInvestmentDateForms() {
    let investmentDateForms = this.companies.filter(comp=> !comp.groupFormId)
    
    return investmentDateForms;
  }

  async getInvestmentData() {
    const allFormIds = this.companies.map( comp => comp.id );
    this.dataService.getInvestmentData(allFormIds).subscribe(res => {
      const investmentAPIData = res.body["response"];

      if(investmentAPIData) {
        this.companies.forEach( comp => {

          if(investmentAPIData[comp.id]) {
            comp.investment = JSON.parse(investmentAPIData[comp.id]);           
          }
        })
      }

      this.preparePortfolioData();
      this.prepareMapData();
      this.prepareColumnChart();

      this.portfolioUpdated.next();
    }, error=> {
      console.log("No valuation data found", error)
    })
  }

  initConcludedValuation(company, valDateTime) {
    let concludedValues = [];

    let concludedValDateTime = new Date(valDateTime); 

    if(company.valuation.CONCLUDED_IA) {
      concludedValues.push({date: company.valuation.CONCLUDED_IA.date, valuation: company.valuation.CONCLUDED_IA.concluded})      
    }

    if(company.valuation.CONCLUDED_CCM) {
      concludedValues.push({date: company.valuation.CONCLUDED_CCM.date, valuation: company.valuation.CONCLUDED_CCM.concluded})
    }

    if(company.valuation.CONCLUDED_CTM) {
      concludedValues.push({date: company.valuation.CONCLUDED_CTM.date, valuation: company.valuation.CONCLUDED_CTM.concluded})
    }

    // Get the latest concluded valuation by sorting dates in DESCENDING order.
    concludedValues = concludedValues.sort((v1, v2)=>{
      if (v1.date < v2.date) {return 1;}
      if (v1.date > v2.date) {return -1;}
      return 0;
    })

    company.valuation.concluded = concludedValues[0].valuation;
  }

  initColumnChart(){
    this.columnChart = new Chart({
      chart: { 
        renderTo: 'column-chart-container',
        type: 'column' 
      },
      title: { text: '' },
      xAxis: {
          categories: this.columnChartData.map( data => data.sectorName),
          crosshair: true
      },
      yAxis:{ title:{ text:'' } },
      credits: { enabled: false },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        { name: 'Previous Valuation', data: this.columnChartData.map( data => data.prevValDate)},
        { name: 'Current Valuation', data:  this.columnChartData.map( data => data.currValDate)}
      ] as any
    })

  }

  prepareColumnChart(){
    this.prepareColumnChartInfo(this.filteredForms);
  }

  prepareColumnChartInfo(formsArray) {
    this.columnChartData = [];

    this.sectors = [];

    formsArray.forEach(form =>{
      if(form.sector){
        if(this.sectors.indexOf(form.sector) == -1){
          this.sectors.push(form.sector);
        }
      }
    });

    this.sectors.forEach(sector => {
      const formData = this.filteredForms.filter(form => form.sector == sector);
      let prevValDate: number = 0;
      let currValDate: number = 0;


      formData.map((form) => {
        let valDateForm = this.getSelectedCompanyDates(form.id);

        valDateForm = valDateForm.sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
        }).filter(f => f.status !== "Initiated");

        if(valDateForm.length == 1 && valDateForm[0].investment && valDateForm[0].investment.equityValue.stakeValue){
          currValDate += +(valDateForm[0].investment.equityValue.stakeValue.toFixed(2));
        }
        else if(valDateForm.length > 1){
          currValDate += valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
          prevValDate += valDateForm[1].investment ? +((valDateForm[1].investment.equityValue.stakeValue).toFixed(2)) : 0;
        }
      })

      this.columnChartData.push({sectorName: sector, prevValDate: prevValDate, currValDate: currValDate});
    });

    this.columnChartData = this.columnChartData.sort((sec1, sec2) => {
      return (sec2.currValDate - sec1.currValDate);
    }).splice(0, 6);

    this.initColumnChart();
  }

  initWorldMapChart(mapData){
    this.mapChart = new MapChart({
      chart: { 
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          type: 'mapbubble',
          name: mapData.name,
          joinBy: ['iso-a3', 'code3'],
          data: mapData.data,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormat: '{point.properties.name}:{point.z}'
          }
      }] as any
    });
  }

  updateWorldMap(){
    this.mapData.data = [];
    this.geographicalFormData.forEach((formData)=>{

      const data = { code3:'', name:'', z: 0 }
      
      if(this.mapData.value == "changeInVal"){
        this.mapData.name = "Change In Valuation";
        let changeInValCountryWise = 0;

        formData.forms.map((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);

          valDateForm = valDateForm.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date > f2Date? -1: 1;
          }).filter(f => f.status !== "Initiated");

          if(valDateForm.length == 1){
            changeInValCountryWise += valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue * valDateForm[0].investment.equityValue.changeInValPercentage).toFixed(2)) : 0;
          }
          else if(valDateForm.length > 1){
            const latestFormStake = valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
            const prevFormStake = valDateForm[1].investment ? +((valDateForm[1].investment.equityValue.stakeValue).toFixed(2)) : 0;
            changeInValCountryWise += latestFormStake - prevFormStake;
          }
        });

        data.z = changeInValCountryWise;
      }
      else if(this.mapData.value == "prevQuarterVal"){
        this.mapData.name = "Previous Quarter Valuation";
        let prevQuarValCountryWise = 0;

        formData.forms.forEach((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);

          valDateForm = valDateForm.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
          }).filter(f => f.status !== "Initiated");


          if(valDateForm.length == 1){
            prevQuarValCountryWise += valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
          }
          else if(valDateForm.length > 1){
            prevQuarValCountryWise += valDateForm[1].investment ? +((valDateForm[1].investment.equityValue.stakeValue).toFixed(2)) : 0;

          }
        });

        data.z = prevQuarValCountryWise;
      }
      else if(this.mapData.value == "noOfComp"){
        this.mapData.name = "Total Number of Companies";
        let totalNoOfComp = formData.forms.length? formData.forms.length: 0 ;
        data.z = totalNoOfComp;
      }
      else{
        this.mapData.name = "Current Valuation";
        let totalValCountryWise = 0;

        formData.forms.forEach((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);

          valDateForm = valDateForm.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
          }).filter(f => f.status !== "Initiated");
        
          totalValCountryWise += valDateForm[0].investment && valDateForm[0].investment.equityValue.stakeValue ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;

        });
        data.z = totalValCountryWise;
      }

      data.code3 = formData.countryCode;
      data.name = formData.countryName;
      this.mapData.data.push(data);
    });

    this.initWorldMapChart(this.mapData);
  }

  prepareMapData(){
    this.filteredForms = this.companies.filter(comp => comp.groupFormId == null && status!=="Initiated");
    this.prepareMapInformation(this.filteredForms);
  }

  prepareMapInformation(formsArray) {
    let codeCountryName = [];

    this.geographicalFormData = [];

    formsArray.map(form => {
      if(form.geography != undefined){
        codeCountryName.push({
          alias: form.geography.cbAlias, 
          countryName: form.geography.name
        });
      }
    })

    let alias = '';
    let countryName = '';
    let filteredData = codeCountryName.filter((data)=>{
      if(alias == ''){
        alias=data.alias;
        countryName = data.countryName;
      }
      else{
        if(alias == data.alias && countryName == data.countryName)
          return false
        else
          alias = data.alias
          countryName = data.countryName
          return true
      }

    })

    let geoCode = codeCountryName.map((code) => code.alias)

    let geo = geoCode.filter( (code, index) => {
      return geoCode.indexOf(code) == index;
    })
  
    geo.map( geoCode =>{
      let formData = [];
      formsArray.filter(form =>{
        if(form.geography != undefined){
          if(form.geography.cbAlias == geoCode){
            formData.push(form);
          }
        }
      });
      const geographicalData = {
        countryName:'',
        countryCode:'', 
        forms:[]
      }

      let countryName;
      filteredData.map( data =>{
        if(data.alias == geoCode){
          countryName = data.countryName;
        }
      })
      geographicalData.countryName = countryName;
      geographicalData.countryCode = geoCode;
      geographicalData.forms = formData;
      this.geographicalFormData.push(geographicalData);
    })
    this.updateWorldMap();
  }

  getValuationDateForms(formId){
    return this.companies.filter(comp=> comp.groupFormId === formId)
  }

  preparePortfolioData(){
    this.portfolioData = []
    const filteredInvestmentForms = this.companies.filter(comp => comp.groupFormId == null);
    filteredInvestmentForms.map((comp) =>{
      const tableObj = {
        id:'',
        logo: '',
        companyName: '',
        investmentDate: '',
        geography:'',
        sector: '',
        security: '',
        type: '',
        version: 0,
        currency: '',
        fairVal: 0,
        stake: 0,
        stakeVal: 0,
        prevStakeValue: 0,
        investmentAmount: 0,
        realisedProceeds: 0,
        moic: 0,
        grossIRR: 0,
        changeInValPercentage: 0,
        changeInStakeValue: 0,
        userEntered: null,
        latestValuationDate: '',
        valuationDate:'',
        valuation: 0,

        carryingValue: 0,
        fairValNO: 0, //Non Operating Fair Value
        totalFairValue: 0,
        concludedDR: 0,
        evRevenue: 0,
        evEbitda: 0
      }

      tableObj.id = comp.id;
      tableObj.logo = comp.website ? "https://logo.allindigital.se/" + this.utilService.getDomainName(comp.website) : '';
      tableObj.companyName = comp.companyName;
      tableObj.investmentDate = comp.valuationDate;
      tableObj.geography = comp.geography ? comp.geography : null;
      tableObj.valuationDate = comp.valuationDate;
      tableObj.sector = comp.sector ? comp.sector: null;
      tableObj.type = comp.formType;
      tableObj.version = comp.formVersion;
      tableObj.security = comp.security;
      tableObj.userEntered = comp.userEntered;
      tableObj.valuation = (comp.investment? comp.investment.equityValue.finalAdjustedEquityVal : 0);
      
      let valuationDateForms = this.getSelectedCompanyDates(comp.id)
      
      if(valuationDateForms.length > 1){
        valuationDateForms = valuationDateForms.sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
        }).filter(f => f.status !== "Initiated");
      }
            
      if(valuationDateForms.length >= 2){
        const latestCompanyForm = valuationDateForms[0];
        tableObj.latestValuationDate = latestCompanyForm.valuationDate;

        tableObj.currency = latestCompanyForm.investment ? latestCompanyForm.investment.currency : "";
        tableObj.fairVal = latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.finalAdjustedEquityVal : 0;
        tableObj.stake = latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.stake : 0;
        tableObj.stakeVal = latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.stakeValue : 0;
        tableObj.fairValNO = latestCompanyForm.investment ? latestCompanyForm.investment.nonOperatingValue : 0;
        tableObj.totalFairValue = tableObj.fairVal + tableObj.fairValNO,

        tableObj.concludedDR = latestCompanyForm.investment ? latestCompanyForm.investment.discountRate : 0,
        tableObj.evRevenue = latestCompanyForm.investment ? latestCompanyForm.investment.bevRevenue : 0,
        tableObj.evEbitda = latestCompanyForm.investment ? latestCompanyForm.investment.evEbitda : 0

        tableObj.prevStakeValue = valuationDateForms[1].investment ? valuationDateForms[1].investment.equityValue.stakeValue : 0
        
        const prevFairValue = valuationDateForms[1].investment ? +valuationDateForms[1].investment.equityValue.finalAdjustedEquityVal : 0;
        const changeInVal = +(latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.finalAdjustedEquityVal : 0) - prevFairValue;
        
        let changeInValPercentage = 0;
        if(prevFairValue > 0){
          changeInValPercentage = changeInVal / prevFairValue;
          tableObj.changeInValPercentage = isNaN(changeInValPercentage)? 0 : changeInValPercentage;
        }
        else{
          tableObj.changeInValPercentage = 0;
        }
      }
      else if(valuationDateForms.length == 1){
        tableObj.currency = valuationDateForms[0].investment ? valuationDateForms[0].investment.currency : "";
        tableObj.latestValuationDate = valuationDateForms[0].valuationDate;

        tableObj.fairVal = valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.finalAdjustedEquityVal : 0;
        tableObj.stake = valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.stake : 0;
        tableObj.stakeVal = valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.stakeValue : 0;
        tableObj.prevStakeValue = comp.investment ? comp.investment.equityValue.stakeValue : 0

        tableObj.fairValNO = valuationDateForms[0].investment ? valuationDateForms[0].investment.nonOperatingValue : 0;
        tableObj.totalFairValue = tableObj.fairVal + tableObj.fairValNO,

        tableObj.concludedDR = valuationDateForms[0].investment ? valuationDateForms[0].investment.discountRate : 0,
        tableObj.evRevenue = valuationDateForms[0].investment ? valuationDateForms[0].investment.bevRevenue : 0,
        tableObj.evEbitda = valuationDateForms[0].investment ? valuationDateForms[0].investment.bevEBITDA : 0
      }
      
      tableObj.investmentAmount = comp.totalInvestmentAmount? comp.totalInvestmentAmount : 0;
      tableObj.realisedProceeds = comp.totalRealisedProceeds? comp.totalRealisedProceeds : 0;
      tableObj.moic = comp.moic? comp.moic : 0;
      tableObj.grossIRR = comp.irr? comp.irr : 0;

      this.portfolioData.push(tableObj);
    })


    this.portfolioData.sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date > f2Date? -1: 1;
    })

    this.portfolioDataClone = cloneDeep(this.portfolioData);

    this.searchedForms = this.portfolioData;

    this.prepareCompaniesClone(filteredInvestmentForms, this.portfolioDataClone);

    this.prepareValuationDataForCrossHolding();

    this.calculateTotal();

    this.initExchangeRate();
  }

  prepareValuationDataForCrossHolding() {
    this.valuationDataForCrossHolding = new Map();

    this.portfolioData.forEach( comp => {
      let valData: EntityValuationData = {
        valuationStatus: comp.fairVal > 0,
        operatingValue: comp.fairVal,
        nonOperatingValue: comp.fairValNO,
        fairValuePreCrossHolding: comp.totalFairValue,
        crossHoldingValue: 0,
        fairValuePostCrossHolding: 0 
      };

      this.valuationDataForCrossHolding.set(comp.companyName, valData);
    })
  }

  calculateMultiples(){

    let valuationForms = this.getSelectedCompanyDates(this.companyId); 

    let stakeVal = 0;
    if(valuationForms[0]['investment']){
      stakeVal = valuationForms[0]['investment'].equityValue.stakeValue;
    }
    let stakeValArray = [];

    stakeValArray.push({
      date: new Date(valuationForms[0].valuationDate), 
      value: stakeVal
    })
    this.formDetailsObj.moic = (this.formDetailsObj.totalRealisedProceeds + stakeVal)/this.formDetailsObj.totalInvestmentAmount;
    
    let investmentArray = cloneDeep(this.formDetailsObj.multipleInvestmentAmount);
    investmentArray = investmentArray.map(arr => {
      if(arr.value > 0){
        arr.value = -arr.value;
      }
      return arr;
    })

    let multiplesArray;

    if(this.formDetailsObj.multipleRealisedProceeds){
      multiplesArray =  this.formDetailsObj.multipleRealisedProceeds.concat(investmentArray).concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }
    else{
      multiplesArray =  this.formDetailsObj.multipleInvestmentAmount.concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }

    let cashFlow = multiplesArray.map( arr => {
      return arr.value;
    });

    let dates = multiplesArray.map( arr => {
      return new Date(arr.date);
    });
    
    const irrVal = this.xirrService.XIRR(cashFlow, dates, 0);
    // console.log("IRR", cashFlow, dates)

    // this.multiples.irr = this.xirrService.XIRR([-1000, -100, 1200],[new Date(2015, 11, 1 ), new Date(2016, 7, 1 ), new Date(2016, 7, 19 )],0 )
    this.formDetailsObj.irr = irrVal? irrVal : 0;

    this.selectedForm[0].details = cloneDeep(this.formDetailsObj);

    this.dataService.saveFormDetails(this.selectedForm[0].details).subscribe(data => {
      // console.log("Data is Saved Successfully", data);
    }, error => {
      console.log("Failed to save the data", error);
    })

    let index = this.portfolioData.findIndex(comp => comp.id == this.companyId);

    this.portfolioData[index].investmentAmount = this.formDetailsObj.totalInvestmentAmount;
    this.portfolioData[index].realisedProceeds = this.formDetailsObj.totalRealisedProceeds;
    this.portfolioData[index].moic = this.formDetailsObj.moic;
    this.portfolioData[index].grossIRR = this.formDetailsObj.irr;
    this.calculateTotal();
  }

  calculateTotal(){
    let totalObj = {
      totalStakeVal:0,
      totalInvestment:0,
      totalRealisedProceeds: 0,
      totalMOIC: 0,
      totalIRR: 0,
      totalFairVal: 0,
      totalChangeInValPercentage: 0
    }


    this.totalPortfolioData.totalFairVal = this.getPortfolioDataTotal("fairVal").total;
    this.totalPortfolioData.totalInvestment = this.getPortfolioDataTotal("investmentAmount").total;
    this.totalPortfolioData.totalRealisedProceeds = this.getPortfolioDataTotal("realisedProceeds").total;
    this.totalPortfolioData.totalStakeVal = this.getPortfolioDataTotal("stakeVal").total;
    this.totalPortfolioData.totalPrevStakeVal = this.getPortfolioDataTotal("prevStakeValue").total;

    this.totalPortfolioData.totalChangeInValPercentage = (this.totalPortfolioData.totalStakeVal - this.totalPortfolioData.totalPrevStakeVal) / this.totalPortfolioData.totalPrevStakeVal
    this.totalPortfolioData.totalMOIC = (this.totalPortfolioData.totalRealisedProceeds + this.totalPortfolioData.totalStakeVal) / this.totalPortfolioData.totalInvestment;
    this.totalPortfolioData.totalIRR = this.getTotalIRR();
  }

  getTotalIRR(){
    let totalIRR = 0;
    let investmentArray = [];
    let realisedProceedsArray = [];
    let stakeArray = [];
    
    this.companies.forEach( comp => {
      if(comp.multipleInvestmentAmount && comp.multipleInvestmentAmount.length > 0 && !isNaN(comp.irr) && isFinite(comp.irr) && comp.irr){
        comp.multipleInvestmentAmount.forEach( inv => {
          investmentArray.push(inv);
        });
        comp.multipleRealisedProceeds.forEach( rp => {
          realisedProceedsArray.push(rp);
        })
        stakeArray.push({date: new Date(comp.valuationDate), value: comp.investment && comp.investment.equityValue.stakeValue? comp.investment.equityValue.stakeValue : 0 })
      }
    })

    let investmentArrayClone = cloneDeep(investmentArray);
    let realisedProceedsClone = cloneDeep(realisedProceedsArray);

    investmentArrayClone = investmentArrayClone.map( inv => {
      inv.date = new Date(inv.date);
      inv.value = - inv.value;
      return inv
    });

    realisedProceedsClone = realisedProceedsClone.map( rp => {
      rp.date = new Date(rp.date);
      rp.value = rp.value;
      return rp
    });

    const totalMultiplesArray = investmentArrayClone.concat(realisedProceedsClone).concat(stakeArray).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {return 1;}
      if (obj1.date < obj2.date) {return -1;}
      return 0;
    });

    const cashFlow = totalMultiplesArray.map( t => t.value);
    const dates = totalMultiplesArray.map( t => t.date);

    const irr = this.xirrService.XIRR(cashFlow, dates, 0);

    totalIRR = isNaN(irr) ? 0 : irr;

    return totalIRR;
  }

  getPortfolioDataTotal(parameter) {
    const returnValue = { total: 0, validRowsLength: 0};

      this.portfolioData.forEach( compData => {

        if(!isNaN(compData[parameter]) && isFinite(compData[parameter])) {
          returnValue.total += +compData[parameter];
          returnValue.validRowsLength ++;
        }
      });

      return returnValue;
  }

  prepareCompaniesClone(filteredForms, portfolioDataClone){
    this.formsClone = cloneDeep(filteredForms);

    let filterInvestmentForms =this.formsClone.filter( comp => comp.investment );

    filterInvestmentForms.sort((comp1, comp2) => {
      return (comp2.investment.equityValue.changeInEquityVal - comp1.investment.equityValue.changeInEquityVal);
    })

    portfolioDataClone.sort((comp1, comp2) => {
      return comp2.stakeVal == comp1.stakeVal? 0 : (comp2.stakeVal > comp1.stakeVal)? 1 : -1;
    })

    this.topGainers = portfolioDataClone.filter( comp => comp.stakeVal >= 0 );
    if(this.topGainers && this.topGainers.length > 3) {
      this.topGainers = this.topGainers.splice(0, 3);
    } 
    
    this.topLosers = portfolioDataClone.filter( comp => comp.stakeVal < 0 );
    if(this.topLosers && this.topLosers.length > 3) {
      this.topLosers = this.topLosers.splice(this.topLosers.length - 3, 3);
    }
    if(this.topLosers.length == 0){
      this.topLosers = portfolioDataClone.splice(portfolioDataClone.length - 3, 3).reverse();
    }
  }

  getSelectedCompanyOrgDetails(domain, description) {
    this.dataService.getSelectedCompanyOrgDetails(domain).subscribe((fundCompanyDetails) => {
      this.fundDetails = fundCompanyDetails.body["response"];
    }, error=>{
      console.log("Error: While fetching fund company details.")
    })

    this.orgKeyWords = [];

    const keyWordsAPI_Input = {
      uuid: "Test",
      isNewApi: true,
      text: description
    }

    this.dataService.getTagsForText(keyWordsAPI_Input).subscribe((res) => {
      this.orgKeyWords = res.body["response"].map( n => n.tag.replaceAll("_", " ") )
    }, error=>{
      console.log("Error: While fetching keywords based on company description", error.error);
    })
  }

  openValuation(comp) {
    const version = this.ums.getApplicationVersion() + "";

    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version);
    localStorage.setItem('qubit-val-date', comp.valuationDate);
    localStorage.setItem('qubit-investment-date-id', this.companyId);

    window.open(environment.portalUrl + environment.corpForms + "/#/valuation-summary?id=" + comp.id + "&fv=" + version, "_self")
  }
  
  sortParameter = "";
  sortOrder = "";

  sortPortfolioCompanies(sortParameter, dataType) {
    if(!this.searchedForms) return;
    
    if(this.sortParameter === sortParameter) {
      if(dataType === "string") {
        const stringEmptyValue = this.sortOrder === "DESC" ? "AAAA" : "ZZZZ"

        this.searchedForms = sortBy(this.searchedForms, (f) => {
          if(sortParameter == 'geography') {
            return f[sortParameter] && f[sortParameter].name ? f[sortParameter].name.toUpperCase(): stringEmptyValue;
          } else {
            return f[sortParameter] ? f[sortParameter].toUpperCase(): stringEmptyValue;
          }
        });

      } else {
        const numberEmptyValue = this.sortOrder === "DESC" ? Number.MAX_VALUE : Number.MIN_VALUE;

        this.searchedForms = sortBy(this.searchedForms, (f) => f[sortParameter] ? +f[sortParameter]: numberEmptyValue).reverse();
      }

      if(this.sortOrder === "DESC") {
        this.searchedForms = this.searchedForms.reverse();
        this.sortOrder = "ASC";
      } else {
        this.sortOrder = "DESC"
      }
      
    } else {
      if(dataType === "string") {
        this.searchedForms = sortBy(this.searchedForms, (f) => {
          if(sortParameter == 'geography') {
            return f[sortParameter] && f[sortParameter].name ? f[sortParameter].name.toUpperCase(): "ZZZZZ";
          } else {
            return f[sortParameter] ? f[sortParameter].toUpperCase(): "ZZZZZ";
          }
        });

        this.sortOrder = "ASC";
      } else {

        this.searchedForms = sortBy(this.searchedForms, (f) => f[sortParameter] ? +f[sortParameter]: Number.MIN_VALUE).reverse();
        this.sortOrder = "DESC";
      }
    }

    this.sortParameter = sortParameter;
  }

  currencyList = [
    { id: "USD", country: "United States Dollar" },
    { id: "GBP", country: "British Pound Sterling" },
    { id: "EUR", country: "Euro" },
    { id: "CHF", country: "Swiss Franc" },
    { id: "AED", country: "United Arab Emirates Dirham" },
    { id: "AFN", country: "Afghan Afghani" },
    { id: "ALL", country: "Albanian Lek" },
    { id: "AMD", country: "Armenian Dram" },
    { id: "ANG", country: "Netherlands Antillean Guilder" },
    { id: "AOA", country: "Angolan Kwanza" },
    { id: "ARS", country: "Argentine Peso" },
    { id: "AUD", country: "Australian Dollar" },
    { id: "AWG", country: "Aruban Florin" },
    { id: "AZN", country: "Azerbaijani Manat" },
    { id: "BAM", country: "Bosnia-Herzegovina Convertible Mark" },
    { id: "BBD", country: "Barbadian Dollar" },
    { id: "BDT", country: "Bangladeshi Taka" },
    { id: "BGN", country: "Bulgarian Lev" },
    { id: "BHD", country: "Bahraini Dinar" },
    { id: "BIF", country: "Burundian Franc" },
    { id: "BMD", country: "Bermudan Dollar" },
    { id: "BND", country: "Brunei Dollar" },
    { id: "BOB", country: "Bolivian Boliviano" },
    { id: "BRL", country: "Brazilian Real" },
    { id: "BSD", country: "Bahamian Dollar" },
    { id: "BTC", country: "Bitcoin" },
    { id: "BTN", country: "Bhutanese Ngultrum" },
    { id: "BWP", country: "Botswanan Pula" },
    { id: "BYN", country: "Belarusian Ruble" },
    { id: "BYR", country: "Belarusian Ruble" },
    { id: "BZD", country: "Belize Dollar" },
    { id: "CAD", country: "Canadian Dollar" },
    { id: "CDF", country: "Congolese Franc" },
    { id: "CLF", country: "Chilean Unit of Account (UF)" },
    { id: "CLP", country: "Chilean Peso" },
    { id: "CNY", country: "Chinese Yuan" },
    { id: "COP", country: "Colombian Peso" },
    { id: "CRC", country: "Costa Rican Colón" },
    { id: "CUC", country: "Cuban Convertible Peso" },
    { id: "CUP", country: "Cuban Peso" },
    { id: "CVE", country: "Cape Verdean Escudo" },
    { id: "CZK", country: "Czech Republic Koruna" },
    { id: "DJF", country: "Djiboutian Franc" },
    { id: "DKK", country: "Danish Krone" },
    { id: "DOP", country: "Dominican Peso" },
    { id: "DZD", country: "Algerian Dinar" },
    { id: "EEK", country: "Estonian Kroon" },
    { id: "EGP", country: "Egyptian Pound" },
    { id: "ERN", country: "Eritrean Nakfa" },
    { id: "ETB", country: "Ethiopian Birr" },
    { id: "FJD", country: "Fijian Dollar" },
    { id: "FKP", country: "Falkland Islands Pound" },
    { id: "GEL", country: "Georgian Lari" },
    { id: "GGP", country: "Guernsey Pound" },
    { id: "GHS", country: "Ghanaian Cedi" },
    { id: "GIP", country: "Gibraltar Pound" },
    { id: "GMD", country: "Gambian Dalasi" },
    { id: "GNF", country: "Guinean Franc" },
    { id: "GTQ", country: "Guatemalan Quetzal" },
    { id: "GYD", country: "Guyanaese Dollar" },
    { id: "HKD", country: "Hong Kong Dollar" },
    { id: "HNL", country: "Honduran Lempira" },
    { id: "HRK", country: "Croatian Kuna" },
    { id: "HTG", country: "Haitian Gourde" },
    { id: "HUF", country: "Hungarian Forint" },
    { id: "IDR", country: "Indonesian Rupiah" },
    { id: "ILS", country: "Israeli New Sheqel" },
    { id: "IMP", country: "Manx pound" },
    { id: "INR", country: "Indian Rupee" },
    { id: "IQD", country: "Iraqi Dinar" },
    { id: "IRR", country: "Iranian Rial" },
    { id: "ISK", country: "Icelandic Króna" },
    { id: "JEP", country: "Jersey Pound" },
    { id: "JMD", country: "Jamaican Dollar" },
    { id: "JOD", country: "Jordanian Dinar" },
    { id: "JPY", country: "Japanese Yen" },
    { id: "KES", country: "Kenyan Shilling" },
    { id: "KGS", country: "Kyrgystani Som" },
    { id: "KHR", country: "Cambodian Riel" },
    { id: "KMF", country: "Comorian Franc" },
    { id: "KPW", country: "North Korean Won" },
    { id: "KRW", country: "South Korean Won" },
    { id: "KWD", country: "Kuwaiti Dinar" },
    { id: "KYD", country: "Cayman Islands Dollar" },
    { id: "KZT", country: "Kazakhstani Tenge" },
    { id: "LAK", country: "Laotian Kip" },
    { id: "LBP", country: "Lebanese Pound" },
    { id: "LKR", country: "Sri Lankan Rupee" },
    { id: "LRD", country: "Liberian Dollar" },
    { id: "LSL", country: "Lesotho Loti" },
    { id: "LTL", country: "Lithuanian Litas" },
    { id: "LVL", country: "Latvian Lats" },
    { id: "LYD", country: "Libyan Dinar" },
    { id: "MAD", country: "Moroccan Dirham" },
    { id: "MDL", country: "Moldovan Leu" },
    { id: "MGA", country: "Malagasy Ariary" },
    { id: "MKD", country: "Macedonian Denar" },
    { id: "MMK", country: "Myanma Kyat" },
    { id: "MNT", country: "Mongolian Tugrik" },
    { id: "MOP", country: "Macanese Pataca" },
    { id: "MRO", country: "Mauritanian Ouguiya" },
    { id: "MUR", country: "Mauritian Rupee" },
    { id: "MVR", country: "Maldivian Rufiyaa" },
    { id: "MWK", country: "Malawian Kwacha" },
    { id: "MXN", country: "Mexican Peso" },
    { id: "MYR", country: "Malaysian Ringgit" },
    { id: "MZN", country: "Mozambican Metical" },
    { id: "NAD", country: "Namibian Dollar" },
    { id: "NGN", country: "Nigerian Naira" },
    { id: "NIO", country: "Nicaraguan Córdoba" },
    { id: "NOK", country: "Norwegian Krone" },
    { id: "NPR", country: "Nepalese Rupee" },
    { id: "NZD", country: "New Zealand Dollar" },
    { id: "OMR", country: "Omani Rial" },
    { id: "PAB", country: "Panamanian Balboa" },
    { id: "PEN", country: "Peruvian Nuevo Sol" },
    { id: "PGK", country: "Papua New Guinean Kina" },
    { id: "PHP", country: "Philippine Peso" },
    { id: "PKR", country: "Pakistani Rupee" },
    { id: "PLN", country: "Polish Zloty" },
    { id: "PYG", country: "Paraguayan Guarani" },
    { id: "QAR", country: "Qatari Rial" },
    { id: "RON", country: "Romanian Leu" },
    { id: "RSD", country: "Serbian Dinar" },
    { id: "RUB", country: "Russian Ruble" },
    { id: "RWF", country: "Rwandan Franc" },
    { id: "SAR", country: "Saudi Riyal" },
    { id: "SBD", country: "Solomon Islands Dollar" },
    { id: "SCR", country: "Seychellois Rupee" },
    { id: "SDG", country: "Sudanese Pound" },
    { id: "SEK", country: "Swedish Krona" },
    { id: "SGD", country: "Singapore Dollar" },
    { id: "SHP", country: "Saint Helena Pound" },
    { id: "SLL", country: "Sierra Leonean Leone" },
    { id: "SOS", country: "Somali Shilling" },
    { id: "SRD", country: "Surinamese Dollar" },
    { id: "STD", country: "São Tomé and Príncipe Dobra" },
    { id: "SVC", country: "Salvadoran Colón" },
    { id: "SYP", country: "Syrian Pound" },
    { id: "SZL", country: "Swazi Lilangeni" },
    { id: "THB", country: "Thai Baht" },
    { id: "TJS", country: "Tajikistani Somoni" },
    { id: "TMT", country: "Turkmenistani Manat" },
    { id: "TND", country: "Tunisian Dinar" },
    { id: "TOP", country: "Tongan Paʻanga" },
    { id: "TRY", country: "Turkish Lira" },
    { id: "TTD", country: "Trinidad and Tobago Dollar" },
    { id: "TWD", country: "New Taiwan Dollar" },
    { id: "TZS", country: "Tanzanian Shilling" },
    { id: "UAH", country: "Ukrainian Hryvnia" },
    { id: "UGX", country: "Ugandan Shilling" },
    { id: "UYU", country: "Uruguayan Peso" },
    { id: "UZS", country: "Uzbekistan Som" },
    { id: "VEF", country: "Venezuelan Bolívar Fuerte" },
    { id: "VND", country: "Vietnamese Dong" },
    { id: "VUV", country: "Vanuatu Vatu" },
    { id: "WST", country: "Samoan Tala" },
    { id: "XAF", country: "CFA Franc BEAC" },
    { id: "XAG", country: "Silver (troy ounce)" },
    { id: "XAU", country: "Gold (troy ounce)" },
    { id: "XCD", country: "East Caribbean Dollar" },
    { id: "XDR", country: "Special Drawing Rights" },
    { id: "XOF", country: "CFA Franc BCEAO" },
    { id: "XPF", country: "CFP Franc" },
    { id: "YER", country: "Yemeni Rial" },
    { id: "ZAR", country: "South African Rand" },
    { id: "ZMK", country: "Zambian Kwacha (pre-2013)" },
    { id: "ZMW", country: "Zambian Kwacha" },
    { id: "ZWL", country: "Zimbabwean Dollar" }
  ]

}
