import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root',
})
export class DataReconFilterService {

  allClients = [];

  private selectedClients = [];
  constructor() {}

  selectClients(clients) {
    this.selectedClients = [...clients];
  }

  getSelectedClient() {
    return this.selectedClients;
  }
}
