<nav-menu-header headerLabel="Funds"></nav-menu-header>

<div class="card" style="text-align: center;">

    <div style="text-align: right;">
        <button mat-raised-button class="secondary-bg" (click)="openPopup(reportPopUp)" style="margin: 10px 10px 0 0;"> 
          Add Report
        </button>
        <button mat-raised-button class="primary-bg" (click)="openPopup(fundPopUp)" style="margin: 10px 10px 0 0;">
          Add Fund
        </button>
    </div>

    <div class="row rowStyling">
        <div class="col-3 box primary-bg fa-3x" [class.secondary-bg]="row.fund.type == 'REPORT'" *ngFor="let row of fundService.allFunds">
            <i class="fa fa-user iconStyle" [class.secondary-bg]="row.fund.type == 'REPORT'" (click)="userPopup(userPopUpContent, row.fund)" aria-hidden="true"></i>
            
            <div class="primary-bg contentStyle fund-card-hover" 
              [class.secondary-bg]="row.fund.type == 'REPORT'" 
              [class.report-card-hover]="row.fund.type == 'REPORT'" 
              (click)="cardClicked(row.fund)">
              
                <span>{{row.fund.name}}</span>
            </div>
        </div>
    </div>
</div>

<ng-template #userPopUpContent let-userPopUp>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="userPopUp.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body fund-user-modal">
        <div style="margin-left: 10px;" *ngFor="let user of myOrgUsers">
          <div class="row">
            <div class="col-7">
              <mat-checkbox class="example-margin" [(ngModel)]="user.assigned">
                  <span style="font-weight: bold;">{{ user.userName }}</span> - <i>{{user.userId}}</i>
              </mat-checkbox>
            </div>

            <div class="col-4">      
              <mat-form-field>
                <mat-select disableOptionCentering [(ngModel)]="user.permission">
                  <mat-option [value]="'W'"> Write </mat-option>
                  <mat-option [value]="'V'"> View Only </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div style="text-align: center;">
            <!-- <button mat-raised-button class="gery-bg" (click)="userPopUp.dismiss('Cross click')"> <span aria-hidden="true" class="close-footer-icon">&times;</span>
                CANCEL</button> -->
            <button mat-raised-button class="primary-bg" (click)="assignUsersToFund()">
              SAVE
            </button>
        </div>
    </div>
</ng-template>


<ng-template #fundPopUp let-fundPopUpModal>
    <div class="modal-header">
        <div class="modal-title">Create a Fund</div>
        <button type="button" class="close" aria-label="Close" (click)="fundPopUpModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

      <div class="row" style="margin: 5px;">
        <div class="col">Fund Name</div>

        <div class="col">          
          <mat-form-field>
            <input matInput [(ngModel)]="fundName"/>
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin: 5px;">
        <div class="col"></div>
        <div class="col">
          <button matRaisedButton class="primary-bg" (click)="addFund()">Create</button>
        </div>
      </div>

    </div>
</ng-template>

<ng-template #reportPopUp let-reportPopUpModal>
    <div class="modal-header">
        <div class="modal-title">Create a Report</div>
        <button type="button" class="close" aria-label="Close" (click)="reportPopUpModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

      <div class="row" style="margin: 5px;">
        <div class="col">Report Name</div>

        <div class="col">          
          <mat-form-field>
            <input matInput [(ngModel)]="fundName"/>
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin: 5px;">
        <div class="col"></div>
        <div class="col">
          <button matRaisedButton class="primary-bg" (click)="addReport()">Create</button>
        </div>
      </div>

    </div>
</ng-template>