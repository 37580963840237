import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { clone, cloneDeep } from 'lodash';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { PortfolioUtilService } from '../../portfolio-util.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-portfolio-table-v2',
  templateUrl: './portfolio-table-v2.component.html',
  styleUrls: ['./portfolio-table-v2.component.scss']
})
export class PortfolioTableV2Component implements OnInit, OnChanges {

  constructor(public portfolioUtil : PortfolioUtilService, 
    public translateService : TranslateService,
    public portfolioService: PortFolioSummaryServiceV2,
    public utilService : UtilService,
    private ds: DataService) { }

  @Input() allPortfolioData;
  @Input() reportingCurrency = "USD"
  @Input() allowEdit = false;
  @Input() showCompaniesInLocalCurrency = false; //Show or Hide Companies in Local Currencies
  @Input() IRR_LOADING_PROGRESS = {
    "TOTAL_IRR": false,
    "CURRENT_PORTFOLIO_IRR": false,
    "REALISED_PORTFOLIO_IRR": false,
    "CONSOLIDATED_IRR": false,
  }

  @Output() emitAction = new EventEmitter();

  portfolio: any = {
    current : {
      name: this.translateService.getLabel("current_portfolio"),
      expandPortfolio: false
    },
    exit: {
      name: this.translateService.getLabel("realised_portfolio"),
      expandPortfolio: false
    }
  }

  @ViewChild('downloadExcelPortfolio', { static: true }) downloadLink: ElementRef;

  typeOfPortfolios = ["current", "exit"];

  currentPortfolioCompanies = [];
  currentPortfolioAggregations;

  exitedPortfolioCompanies = [];
  exitedPortfolioAggregations;

  totalAggregations;

  portfolioDataLoaded = false;

  showHideColumns = {
    "reportingCurrencyCurrentPortfolio": {
      geography: false,
      sector: false,
      security: false,
      exit_date: false,
      changeInMOIC_valuesInPortfolioCurrency: false
    },
    "reportingCurrencyExitedPortfolio": {
      geography: false,
      sector: false,
      security: false,
      changeInMOIC_valuesInPortfolioCurrency: false
    },
    "localCurrencyCurrentPortfolio": {
      geography: false,
      sector: false,
      security: false,
      exit_date: false,
      changeInMOIC_valuesInLocalCurrency: false
    },
    "localCurrencyExitedPortfolio": {
      geography: false,
      sector: false,
      security: false,
      changeInMOIC_valuesInLocalCurrency: false
    },
  }

  ngOnInit(): void {
    if(this.allPortfolioData){
      this.currentPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);
      
      const currentPortfolioAggregations = this.portfolioUtil.getPortfolioSummaryAggregationByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);
      currentPortfolioAggregations.name = this.translateService.getLabel("current_portfolio");
      currentPortfolioAggregations.key = "current";
      currentPortfolioAggregations.expandPortfolio = true;

      this.currentPortfolioAggregations = cloneDeep(currentPortfolioAggregations);

      this.exitedPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);
      const exitedPortfolioAggregations = this.portfolioUtil.getPortfolioSummaryAggregationByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);
      exitedPortfolioAggregations.name = this.translateService.getLabel("realised_portfolio");
      exitedPortfolioAggregations.key = "exit";
      exitedPortfolioAggregations.expandPortfolio = false;

      this.exitedPortfolioAggregations = cloneDeep(exitedPortfolioAggregations);

      this.totalAggregations = this.portfolioUtil.getTotalPortfolioSummaryAggregations(this.allPortfolioData);

      this.portfolioDataLoaded = true;
      this.preparePrecentageChangeInMOIC()
    }
    this.portfolio["current"].expandPortfolio = !this.portfolio["current"].expandPortfolio
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes.IRR_LOADING_PROGRESS && 
      changes.IRR_LOADING_PROGRESS.currentValue && changes.IRR_LOADING_PROGRESS.previousValue){
      const typeOfIRRLoadingProgress = Object.keys(changes.IRR_LOADING_PROGRESS.currentValue);

      const changeExists = typeOfIRRLoadingProgress.find(type => changes.IRR_LOADING_PROGRESS.currentValue[type] 
        != changes.IRR_LOADING_PROGRESS.previousValue[type]);

      if(changeExists){
        this.ngOnInit();
      }  
    }
  }

  getTypeOfAggregationByPortfolio(typeOfPortfolio){
    return typeOfPortfolio == "current" ? this.currentPortfolioAggregations : this.exitedPortfolioAggregations;
  }

  getTypeOfCompaniesByPortfolio(typeOfPortfolio){
    return typeOfPortfolio == "current" ? this.currentPortfolioCompanies : this.exitedPortfolioCompanies;
  }

  emittingActionOnPortfolio(event){
    this.emitAction.emit(event);
  }

  addCompany(){
    const actionDetails = {
      type: this.portfolioUtil.ACTIONS_TYPE.ADD_COMPANY,
      details: null
    }

    this.emittingActionOnPortfolio(actionDetails);
  }

  async preparePrecentageChangeInMOIC(){
    const valuationDates = [];
    this.currentPortfolioCompanies.forEach(comp => {
      this.prepareChangeInMOIC_ReqBody(comp, valuationDates);
    });
    this.exitedPortfolioCompanies.forEach(comp => {
      this.prepareChangeInMOIC_ReqBody(comp, valuationDates);
    });
    let requestBody = {
      "valuationDates": valuationDates,
      "allTransactionsNeeded": false
    }
    this.ds.getPrecentageChangeinMoicForPortfolioCurrency(requestBody, this.portfolioService.selectedFundId, this.portfolioService.selectedCurrency).subscribe(res => {
      let transcationsInCurrencyResponse = res.body["response"];
      
      this.currentPortfolioCompanies.forEach(comp => {
        this.assignChangeInMOIC(comp, transcationsInCurrencyResponse);
      });
      this.exitedPortfolioCompanies.forEach(comp => {
        this.assignChangeInMOIC(comp, transcationsInCurrencyResponse);
      });
      this.currentPortfolioCompanies = cloneDeep(this.currentPortfolioCompanies);
      this.exitedPortfolioCompanies = cloneDeep(this.exitedPortfolioCompanies);
    });
  }
  prepareChangeInMOIC_ReqBody(comp, valuationDates) {
    let currency;
    if(comp.prevValuationDateId == null || !comp.prevValuationDateId){
      comp.valuesInPortfolioCurrency.changeInMOIC = 0
    }
    else {
      currency = comp.valuesInLocalCurrency.currency;
      const valDate = {
        "valuationDateId": comp.prevValuationDateId,
        "latestValuationDate": comp.prevValuationDate,
        "valuesInLocalCurrency": {
          "currency": comp.valuesInLocalCurrency.currency
        }
      }
      valuationDates.push(valDate);
    }
  }
  assignChangeInMOIC(comp, transcations) {
    
    if(comp.prevValuationDateId == null || !comp.prevValuationDateId){
      comp.valuesInPortfolioCurrency.changeInMOIC = 0
      comp.valuesInPortfolioCurrency.previousMOIC = 0;
    }
    else if(transcations[comp.prevValuationDateId]){
      let investmentAmount = 0; 
      let realisedProceeds = 0;
      if(transcations[comp.prevValuationDateId].investmentAmountTrans) {
        investmentAmount = this.utilService.getValidNumber(transcations[comp.prevValuationDateId].investmentAmountTrans.totalAmount); 
      } 
      
      if(transcations[comp.prevValuationDateId].realisedProceedsTrans) {
        realisedProceeds = this.utilService.getValidNumber(transcations[comp.prevValuationDateId].realisedProceedsTrans.totalAmount); 
      }
      if(investmentAmount) {
      
        comp.valuesInPortfolioCurrency.previousMOIC = (comp.valuesInPortfolioCurrency.prevStakeValue
          + realisedProceeds)
          / investmentAmount;
        const moicDiff = (comp.valuesInPortfolioCurrency.moic - comp.valuesInPortfolioCurrency.previousMOIC)
        
        comp.valuesInPortfolioCurrency.changeInMOIC = ( moicDiff
          / comp.valuesInPortfolioCurrency.previousMOIC) 
          * 100;
      } else {
        comp.valuesInPortfolioCurrency.previousMOIC = 0;
        comp.valuesInPortfolioCurrency.changeInMOIC = 0;
      }
    }
    comp.changeInMOIC_valuesInPortfolioCurrency = comp.valuesInPortfolioCurrency.changeInMOIC;
  }


  excelExport(){
    const actionDetails = {
      type: this.portfolioUtil.ACTIONS_TYPE.EXCEL_DOWNLOAD,
      details: {
        portfolioData: cloneDeep(this.allPortfolioData),
        reportingCurrency: this.reportingCurrency,
        downloadElement: this.downloadLink
      }
    }

    this.emittingActionOnPortfolio(actionDetails);
  }

  deleteCompany(){
    const actionDetails = {
      type: this.portfolioUtil.ACTIONS_TYPE.DELETE_COMPANY,
      details: null
    }

    this.emittingActionOnPortfolio(actionDetails);
  }

  // Persist the column hide/show status for each portfolio table 
  // grid key identifies each portfolio table uniquely
  updateShowHideColumns(gridKey, columnsVisibility) {
    columnsVisibility.forEach(c => {
      this.showHideColumns[gridKey][c.columnKey] = c.visible;
    })
  }

}
