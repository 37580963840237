<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}'></nav-menu-header>

<div style="text-align: center; border-radius: 0px; height: fit-content; background-color: white">

    <div style="text-align: right; margin: 0px 20px 0px 0px;">
        <!-- <button mat-raised-button class="secondary-bg" (click)="openPopup(reportPopUp)" style="margin: 10px 10px 0 0;"> 
          Add Report
        </button>
        <button mat-raised-button class="primary-bg" (click)="openPopup(fundPopUp)" style="margin: 10px 10px 0 0;">
          Add Fund
        </button> -->

        <button mat-raised-button class="secondaryBgButton" (click)="redirectToV2Navigation()"  style="margin: 10px 10px 0 0;width: 9vw;">
          <span style="margin-left: 4px;">Navigation V2</span>
        </button>
        <app-qualitative-analysis-v2 [id]="orgId" groupId="FUND_LIST" commentId="QA"></app-qualitative-analysis-v2>
       
        <ng-template [ngIf]="isMasterUser">
          <button mat-raised-button class="secondaryBgButton" (click)="openPopup(reportPopUp)" style="margin: 10px 10px 0 0;"> 
            {{ translateService.getLabel("add_report") }}
          </button>
        </ng-template>

        <button mat-raised-button class="primaryBgButton" (click)="openPopup(fundPopUp)" style="margin: 10px 10px 0 0;">
          {{ translateService.getLabel("add_fund") }}
        </button>
    </div>

        <!-- <div class="row rowStyling">
        <div class="col-3 box primary-bg fa-3x" [class.secondary-bg]="row.fund.type == 'REPORT'" *ngFor="let row of fundService.allFunds">
            <i class="fa fa-user iconStyle" [class.secondary-bg]="row.fund.type == 'REPORT'" (click)="userPopup(userPopUpContent, row.fund)" aria-hidden="true"></i>
            <i class="fas fa-trash-alt deleteIconStyle" (click)="onClickOfFundDelete(row.fund)"></i>
            <div class="primary-bg contentStyle fund-card-hover" 
              [class.secondary-bg]="row.fund.type == 'REPORT'" 
              [class.report-card-hover]="row.fund.type == 'REPORT'" 
              (click)="cardClicked(row.fund)">
              
                <span>{{row.fund.name}}</span>
            </div>
        </div>
    </div> -->

    <div class="row fundRow">
      <div class="col eachCardCol" *ngFor="let row of fundService.allFunds; index as i">
        <div class="fund-card" style="background-color: transparent;" *ngIf="row.permission!='FN'">
          <button class="fundToggleButton" style="font-size: 0.7vw;" *ngIf="row.permission!='FR' && um.is73sAdminAndMasterUser()" (mouseup)="fundDropDownToggle(row)" (focusout)="fundDropDownToggleAll()">
            <i class="fas fa-bars fa-2x barIcon"></i>
          </button>
          <div class="dropdown-content" id="myDropdown" *ngIf="row.showDropDown && row.permission!='FR' && um.is73sAdminAndMasterUser()">
            <!-- <a href="javascript:;" class="dropDownOption" style="margin-top: 5.7vw" (mousedown)="userPopup(userPopUpContent, row.fund); row.showDropDown=false">{{ translateService.getLabel("user_assignment") }}</a> -->
            <a href="javascript:;" class="dropDownOption" style="margin-top: 2.7vw" (mousedown)="deleteFundPopUp(deleteConfirmationPopUp, row.fund); row.showDropDown=false">Delete</a>
          </div>
          <img class="card-img-top fund-card-img" [src]="imageLoadService.getImage((i % 10)+'.jpg')" alt="Card image cap" (click)="cardClicked(row.fund)">
          <div class="card-body primary-bg fund-card-body" [class.secondary-bg]="row.fund.type == 'REPORT'" 
          [class.report-card-hover]="row.fund.type == 'REPORT'">
            <p class="card-text" (click)="cardClicked(row.fund)">{{row.fund.name}}</p>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #userPopUpContent let-userPopUp>
    <div class="modal-header">
        <h4 class="modal-title"> {{ userAssignmentPopHeader }} </h4>
        <button type="button" class="close" aria-label="Close" (click)="userPopUp.dismiss('Cross click'); ">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
    <div class="modal-body fund-user-modal user-assignment-pop-up">
        <div style="margin-left: 10px; max-height: 45vh; overflow-y: scroll;">
          <table class="table">
            <tr>
              <th>{{ translateService.getLabel("username") }}</th>
              <th>{{ translateService.getLabel("email") }}</th>
              <th>{{ translateService.getLabel("access_type") }}</th>
            </tr>
            <tr *ngFor="let user of fundService.myOrgUsers">
              <td class="bold">{{ user.userName }}</td>
              <td class="italic">{{ user.userId }}</td>
              <td>
                <mat-form-field style="border-radius: 4px !important">
                  <mat-select disableOptionCentering [(ngModel)]="user.permission">
                    <mat-option [value]="'FN'" default> {{ translateService.getLabel("no_access") }} </mat-option>
                    <mat-option [value]="'FW'"> {{ translateService.getLabel("edit") }} </mat-option>
                    <mat-option [value]="'FR'"> {{ translateService.getLabel("view") }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>

          </table>
          <!-- <div class="row">
            <div class="col-7">
              <mat-checkbox class="example-margin" [(ngModel)]="user.assigned">
                  <span style="font-weight: bold;">{{ user.userName }}</span> - <i>{{user.userId}}</i>
              </mat-checkbox>
            </div>

            <div class="col-4">      
              <mat-form-field>
                <mat-select disableOptionCentering [(ngModel)]="user.permission">
                  <mat-option [value]="'FW'" default> Write </mat-option>
                  <mat-option [value]="'FR'"> View Only </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
        </div>

      </div>
      <div style="text-align: center;" class="modal-footer">
          <!-- <button mat-raised-button class="gery-bg" (click)="userPopUp.dismiss('Cross click')"> <span aria-hidden="true" class="close-footer-icon">&times;</span>
              CANCEL</button> -->
          <button mat-raised-button class="primaryBgButtonDeletePopUp" (click)="fundService.assignUsersToFund(cardSelectedForAssignment)">
            {{ translateService.getLabel("save") }}
          </button>
      </div>
</ng-template>


<ng-template #fundPopUp let-fundPopUpModal>
    <div class="modal-header">
        <h4 class="modal-title">{{ translateService.getLabel("create_a_fund") }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="fundPopUpModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

      <div class="row popupMargin" style="margin: 0px; width: 100%;">
        <div class="col-4">{{ translateService.getLabel("fund_name") }}</div>

        <div class="col-8">          
          <mat-form-field class="yellowBackground">
            <input class="yellowBackground" matInput [(ngModel)]="fundName" (keyup)="checkFundNameEmptyOrNot()"/>
          </mat-form-field>
          <span *ngIf="fundExist" style="color:#1D1563">Fund Already Exist</span>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <div class="row" style="margin: 5px; float: right; margin-right: 12px">
        <div class="col">
          <button matRaisedButton class="secondaryBgButtonDeletePopUp" (click)="fundPopUpModal.dismiss('Cross click')">{{ translateService.getLabel("cancel") }}</button>
        </div>
        <div class="col">
          <button matRaisedButton [ngClass]="fundExist ? 'disabled-btn' : 'primaryBgButtonDeletePopUp'" [disabled]="fundExist" (click)="addFund()">{{ translateService.getLabel("create") }}</button>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #reportPopUp let-reportPopUpModal>
    <div class="modal-header">
        <h4 class="modal-title">Create a Report</h4>
        <button type="button" class="close" aria-label="Close" (click)="reportPopUpModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
      <div class="row popupMargin" style="margin: 0px; width: 100%;">
        <div class="col-4">Report Name</div>

        <div class="col-8">          
          <mat-form-field class="yellowBackground">
            <input matInput class="yellowBackground" [(ngModel)]="fundName"/>
          </mat-form-field>
        </div>
      </div>
    </div>
    
    <div class="modal-footer">
      <div class="row" style="margin: 5px; float: right; margin-right: 12px">
        <div class="col">
           <button matRaisedButton class="secondaryBgButtonDeletePopUp" (click)="reportPopUpModal.dismiss('Cross click')">Cancel</button>
        </div>
        <div class="col">
          <button matRaisedButton class="primaryBgButtonDeletePopUp" [disabled]="fundName==null" (click)="addReport()">Create</button>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #deleteConfirmationPopUp let-deleteConfirmationPopUpModal class="fitContent">
  <div class="modal-header">
      <h4 class="modal-title">Delete {{ deleteFundType }} </h4>
      <button type="button" class="close" aria-label="Close" (click)="deleteConfirmationPopUpModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="modal-body">
    <div class="row popupMargin" style="margin: 5px;">
      <div class="popUpLabel">Please confirm if you wish to delete - <span class="bold">{{ deleteFundName }}</span></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row" style="margin: 5px; float: right">
      <div class="col">
        <button matRaisedButton class="secondaryBgButtonDeletePopUp" (click)="deleteConfirmationPopUpModal.dismiss('Cross click')">No</button>
      </div>
      <div class="col">
        <button matRaisedButton class="primaryBgButtonDeletePopUp" (click)="onClickOfFundDelete(deleteFund); deleteConfirmationPopUpModal.dismiss('Cross click')">Yes</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteSuccessModal let-deleteSuccessModal>
  <div class="modal-body">
    <div class="model-content deletePopUpContent popupMargin" style="text-align: center;">
      <div class="col">
        <i class="col p-modal-row fas fa-check-circle delete-success-popup"></i>
        <span class="primary-color" style="font-size: 22px; padding-left: 35px;">Deleted Successfully!</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="display: block; text-align: center;">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="primary-bg primaryBgButtonDeletePopUp" (click)="deleteSuccessModal.dismiss('Cross click')">
      OK
    </button>
  </div>
</ng-template>