import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { InvestmentSummaryService } from '../../investment-page-summary-v2/investment-summary.service';

@Component({
  selector: 'app-add-valuation-popup',
  templateUrl: './add-valuation-popup.component.html',
  styleUrls: ['./add-valuation-popup.component.scss']
})
export class AddValuationPopupComponent implements OnInit {
  @Input() valuationDateSelectionPopup;
  @Input() newCompany;
  @Output() saveValuationDateDetails = new EventEmitter();
  valuationType = "revalue";
  marketDate;
  multipleBusinessUnitExists = false
  valuationDateList
  replicatedValDateId

  businessUnitExists;

  maxMarketDateAllowed;

  constructor(
    public utilService: UtilService, 
    private ds: DataService,
    public translateService: TranslateService,
    private invSummaryService: InvestmentSummaryService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.newCompany.valuationDate = new Date(); 

    this.newCompany.marketDataDate = new Date();
    this.newCompany.financialRefreshDate = new Date();

    this.newCompany.refreshInvesteefinancials = true;

    this.maxMarketDateAllowed = this.newCompany.valuationDate;

    this.businessUnitExists = this.newCompany.businessUnitsNumber
    // Check Business Unit Exists and if true; set flag to true
    this.multipleBusinessUnitExists = this.businessUnitExists > 0
    
    //valuationDateList consist of Valuation Date list
    this.valuationDateList = this.invSummaryService.allValuationDates.filter(form => {
      const details = JSON.parse(form.details);
      return details.status == "Submitted";
    })

    if(this.newCompany.versionName == "" || !this.newCompany.versionName){
      this.newCompany.versionName = 'FIRST'
    } 

    this.replicatedValDateId = this.valuationDateList[0].id;
  }

  updateMaxMarketDate(){
    const todayDate = new Date();
    const vDateObject = this.newCompany.valuationDate;

    if(this.utilService.compareDates(vDateObject, todayDate) == -1){
      // if Valuation Date is greater than Current Date, Max Market Date Can be Today's Date
      this.newCompany.marketDataDate = todayDate;
      this.newCompany.financialRefreshDate = todayDate;
      this.maxMarketDateAllowed = todayDate;
      this.newCompany.financialRefreshDate = todayDate;
    }
    else{
      this.newCompany.marketDataDate = this.newCompany.valuationDate
      this.newCompany.financialRefreshDate = this.newCompany.valuationDate;
      this.maxMarketDateAllowed = this.newCompany.valuationDate;
      this.newCompany.financialRefreshDate = this.newCompany.valuationDate;
    }
  }

  updateValuationDetails(){
    try {
      this.newCompany.valuationDate = this.ds.getDateYYYYMMDDFromUnits(this.newCompany.valuationDate.getFullYear(), this.newCompany.valuationDate.getMonth() + 1, this.newCompany.valuationDate.getDate());

      if(this.newCompany.marketDataDate) {
        this.newCompany.marketDataDate = this.ds.getDateYYYYMMDDFromUnits(this.newCompany.marketDataDate.getFullYear(), this.newCompany.marketDataDate.getMonth() + 1, this.newCompany.marketDataDate.getDate());
      }
      if(this.newCompany.financialRefreshDate){
        this.newCompany.financialRefreshDate = this.ds.getDateYYYYMMDDFromUnits(this.newCompany.financialRefreshDate.getFullYear(), this.newCompany.financialRefreshDate.getMonth() + 1, this.newCompany.financialRefreshDate.getDate())
      }
      this.newCompany.replicatedValDateId = this.replicatedValDateId;
      this.newCompany.valuationType = this.valuationType;

      if(this.newCompany.valuationDate && this.newCompany.versionName){
        this.saveValuationDateDetails.emit(this.newCompany);
      }
      else{
        this.utilService.showMessage("Please Enter the Valuation Date and Version Name.", "Ok", true)
      }
    } catch(e) {
      console.log("Invalid inputs while creating valuation date", e);
      this.utilService.showMessage("Please enter valid inputs", "Ok", true);
    }
  }
}
