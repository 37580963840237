import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { DateUtilService } from 'src/app/date-util.service';

@Component({
  selector: 'app-mapping-filter',
  templateUrl: './mapping-filter.component.html',
  styleUrls: ['./mapping-filter.component.scss'],
})
export class MappingFilterComponent implements OnInit, OnDestroy {
  private commonSub = new Subscription();


  today = new Date();

  uploadDateFc = new FormControl();

  showCreateListBtn = false;
  showCreateMapBtn = false;
  showAssignBtn = false;
  showUploadDate = true;

  constructor(private mappingMgmtConfigService: MappingMgmtConfigService, private dateUtilSvc: DateUtilService) {}

  ngOnInit(): void {
    this.mappingMgmtConfigService.resetUploadDate$.subscribe((date) => {
      console.log('Resetting upload date...', date);
      this.uploadDateFc.setValue(date);
    });
  
    this.uploadDateFc.valueChanges.pipe().subscribe((valuationDate: Date) => {
      // console.log(valuationDate, 'Date upload...');
      this.mappingMgmtConfigService.setUploadDate(valuationDate);
    });

    this.commonSub.add(
      this.mappingMgmtConfigService.currentRoute$.subscribe((urlSgement: string) => {
        // if (urlSgement?.includes('versions')) {

        // }
        const endOfUrlSegmentString = urlSgement.split('/')[3]?.trim()?.toLowerCase();
        if (endOfUrlSegmentString === 'attributes' || endOfUrlSegmentString === 'attributes?for=credit') {
          this.showCreateListBtn = false;
          this.showCreateMapBtn = true;
          this.showAssignBtn = false;
          this.showUploadDate = true;
        } else if (urlSgement?.includes('company-assignment')) {
          this.showCreateListBtn = false;
          this.showCreateMapBtn = false;
          this.showAssignBtn = true;
          this.showUploadDate = true;
        } else if (urlSgement?.includes('versions')) {
          this.showCreateListBtn = true;
          this.showAssignBtn = false;
          this.showCreateMapBtn = false;
          this.showUploadDate = true;
        } else if (urlSgement?.includes('version-details')) {
          this.showCreateListBtn = false;
          this.showAssignBtn = false;
          this.showCreateMapBtn = false;
          this.showUploadDate = false;
        } else if (urlSgement?.includes('attributes-map-details')) {
          this.showCreateListBtn = false;
          this.showAssignBtn = false;
          this.showCreateMapBtn = false;
          this.showUploadDate = false;
        } else if (urlSgement?.includes('replicate-attributes-map')) {
          this.showCreateListBtn = false;
          this.showAssignBtn = false;
          this.showCreateMapBtn = false;
          this.showUploadDate = true;
        } else {
          this.showCreateListBtn = false;
          this.showAssignBtn = false;
          this.showCreateMapBtn = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
