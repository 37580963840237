<div class="mapping-info mat-h4">
    <i class="fa fa-lightbulb"></i>
    <ng-container *ngIf="!for">
        Map or associate the attributes and versions in GravitonX to attributes in the equity form submission on QubitX.
    </ng-container>
    <ng-container *ngIf="for === 'credit'">
        Map or associate the attributes and versions in GravitonX to attributes in the credit security form submission
        on QubitX.
    </ng-container>
</div>

<div *ngIf="showCreateVlInfo" class="create-vl-info mat-elevation-z1">
    Select and rank available data versions from Graviton X. For the equity form, the system first tries to fetch data
    from the top-ranked versions. If it's inaccessible, it moves to the next ranked version, and so on,
    ensuring data retrieval even if preferred versions are unavailable.
</div>

<div *ngIf="showCreateAttributeListInfo" class="create-vl-info mat-elevation-z1">
    The default mapping connects Qubit X form attributes with their corresponding counterparts in Graviton X. Modify the
    mapping as required. This configuration dictates that the Equity form will retrieve data from Graviton X according
    to the established mapping.
</div>