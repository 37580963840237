import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/utils/util.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { LoadingPopupComponent } from 'src/app/utils/loading-popup/loading-popup.component';

@Component({
  selector: 'app-reset',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetComponent implements OnInit {
  newPassword1 = "";
  newPassword2 = "";
  oldPassword = "";

  constructor(private router: Router, private um: UserManagementService, private dialog: MatDialog, private utilService: UtilService) { }

  ngOnInit() {

  }

  updatePassword() {
    this.dialog.open(LoadingPopupComponent, { disableClose: true });
    if(this.newPassword1 === this.newPassword2) {
      this.um.resetPassword(this.newPassword1, this.oldPassword).subscribe((status)=>{
        // console.log("Reset Success", status);
        this.dialog.closeAll()
        
        const dialogRef = this.utilService.showMessage("Password reset is successful. Please login with new credentials.", "Ok");

        dialogRef.afterClosed().subscribe(result => { 
          this.um.logout();
          this.router.navigateByUrl('/auth');
        });
      }, error =>{    
        this.dialog.closeAll()    
        this.utilService.showMessage("Password reset is failed. Please try again or contact us.", "Ok");
        console.log("Reset Failed", error);
      })

    } else {
      this.dialog.closeAll()
      this.utilService.showMessage("New Password and Confirm password are not same.", "Ok");
    }

    this.reset();
  }

  reset() {
    this.newPassword1 = "";
    this.newPassword2 = "";
  }
}