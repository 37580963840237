<div class="modal-header">
    <div class="modal-title" style="width: 100%;" >
        <h3>
            {{ translateService.getLabel("share") }} 
        </h3>
    </div>
    <button type="button" class="close"  (click)="valuationSharePopUpModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="overflow-y: auto">
    <div class="tabContainer" style="text-align: left">
        <div class="row" style="margin: 0px 2px;">
            <div class="col" style="padding: 0px;">
                <h6>
                    {{ translateService.getLabel("Share with") }} 
                    <span matTooltip = "Please add a guest user to select tabs to share" matTooltipPosition="right">
                        <em class="fa fa-info-circle"></em>
                    </span>
                </h6>
            </div>
        </div>
        <div class="row serchBarCss" style="margin: 0;">
            <div style="margin: 10px 0px;">
                <mat-chip *ngFor="let user of shareInput.users; let i=index" [selectable]="selectable"
                    [removable]="removable" (removed)="removeShareInputs(i)" style="margin-right: 7px; margin-bottom:2px;"
                    class="chipColor">
                    <span class="initialLetterStyle">{{user.initialName}}</span>
                    {{user.name }}
                    <mat-icon style="font-weight: bolder;" matChipRemove (click)="removeShareInputs(i)">close</mat-icon>
                </mat-chip>
            </div>
    
            <div class="col-12 spacing" style="border-radius: 5px; height: 30px;padding: 0px;">
                <input type="text" style="margin-top: 5px;" placeholder="Start typing to see Guest Users" aria-label="text"
                    matInput [matAutocomplete]="auto" [(ngModel)]="shareInputName" (input)="filteredOptions($event)">
    
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filterGuestUserData" [value]="option.userName"
                        (click)="addShareInputs(option)">
                        <div class="row">
                            <div class="col" style="float: left">{{option.userName}} </div>
                            <div class="col" style="text-align: right">{{option.userId}}</div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
    
        <div class="row" style="padding: 0px;">
            <div class="col">
                <h6 style="margin: 20px 0px;">
                    <mat-checkbox (click)="checkUncheckAll()">
                        {{ translateService.getLabel("access_privileges") }}
                    </mat-checkbox>
                </h6>
            </div>
            <div class="col">
            </div>
        </div>
    
        <div class="row">
            <ng-template ngFor [ngForOf]="shareInput.sharePermissions" let-tab>
                <div class="col-6" *ngIf="!tab.hide" style="text-align: left">
                    <mat-checkbox [checked]="tab.selected" (change)="tab.selected = $event.checked">{{tab.label}}
                    </mat-checkbox>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div style="text-align: right;">
        <button mat-raised-button class="btn-custom-secondry mat-raised-button mat-button-base" style="margin: 10px;"
            type="button" (click)="valuationSharePopUpModal.dismiss('Cross click')">{{
            translateService.getLabel("cancel") }}</button>
        <button mat-raised-button class="btn-custom-primary p-button" [disabled]="this.shareInput.users.length <= 0"  style="margin: 10px;" type="submit"
            (click)="share(valuationSharePopUpModal);">
            {{ translateService.getLabel("share") }}
        </button>
    </div>
</div>
 