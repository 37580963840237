/* tslint:disable:variable-name */
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AttributesRow } from '../../data-recon-pluto.model';

@Component({
  selector: 'app-drp-attribute-details',
  templateUrl: './drp-attribute-details.component.html',
  styleUrls: ['../table-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrpAttributeDetailsComponent {
  displayColumnGroups = [
    'empty',
    'globalAttributes',
    'customAttributes',
    'attributeFormulae',
    'formulaDetails',
    'formulaCompanies'
  ];

  displayColumns = [
    'organizationName',
    'gxGlobalAttributesCount',
    'plutoGlobalAttributesBaseCount',
    'plutoGlobalAttributesFactCount',
    'globalAttributesDelta',
    'gxGlobalAttributesLastUpdated',
    'gxCustomAttributesCount',
    'plutoCustomAttributesBaseCount',
    'plutoCustomAttributesFactCount',
    'customAttributesDelta',
    'gxCustomAttributesLastUpdated',
    'gxAttributeFormulaCount',
    'plutoAttributeFormulaBaseCount',
    'plutoAttributeFormulaFactCount',
    'attributeFormulaDelta',
    'gxAttributeFormulaLastUpdated',
    'gxFormulaDetailsCount',
    'plutoFormulaDetailsBaseCount',
    'plutoFormulaDetailsFactCount',
    'formulaDetailsDelta',
    'gxFormulaDetailsLastUpdated',
    'gxFormulaCompaniesCount',
    'plutoFormulaCompaniesBaseCount',
    'plutoFormulaCompaniesFactCount',
    'formulaCompaniesDelta',
    'gxFormulaCompaniesLastUpdated',
  ];

  _data: AttributesRow[];

  dataSource: MatTableDataSource<AttributesRow>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() loading: boolean;

  @Input() error: string | null;

  @Input() set data(data) {
    this._data = [...data];
    if (this._data) {
      this.dataSource = new MatTableDataSource(this._data);
      this.dataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
