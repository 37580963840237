<nav-menu-header headerLabel="Exit Simulation"></nav-menu-header>
<div class="card exit-simulation">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col label" style="text-align: left; white-space: pre-line;">
                    <mat-label>Exit Mutiple Type</mat-label>
                </div>
                <div class="col field">
                    <mat-form-field>
                        <mat-select name="exitMutipleType" [(ngModel)]="exitSimulation.exitMutipleType">
                            <mat-option value="" disabled>
                                Select
                            </mat-option>
                            <mat-option value="revenue">
                                Revenue
                            </mat-option>
                            <mat-option value="ebitda">
                                EBITDA
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col label">
                    <mat-label>Multiple</mat-label>
                </div>
                <div class="col field">
                    <mat-form-field style="text-align: right;">
                        <input type="number" name="multiple" matInput [(ngModel)]="exitSimulation.multipleValue">
                        <span matSuffix>x</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col label">
                    <mat-label>Exit Date</mat-label>
                </div>
                <div class="col field">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" readonly placeholder="Choose a date" name="exitDate" [(ngModel)]="exitSimulation.exitDate" (ngModelChange)="assignMetricYear($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row">
                <div class="col label">
                    <mat-label>Metric Year</mat-label>
                </div>
                <div class="col field">
                    <mat-form-field>
                        <mat-select name="metricYear" [(ngModel)]="exitSimulation.metricYear">
                            <mat-option value="" disabled>
                                Select
                            </mat-option>
                            <mat-option [value]="year" *ngFor="let year of tableHeaderYears">
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-1">
            <button mat-raised-button class="primary-bg" [disabled]="!exitSimulation.metricYear && !exitSimulation.exitDate" (click)="calculateExitSimulation(exitSimulation.exitMutipleType)">
                Calculate
            </button>
        </div>
    </div>
    <ng-template [ngIf]="simulationTableData && simulationTableData.length > 0">
        <div class="row simulation-table">
            <table class="table borderless">
                <tr>
                    <th></th>
                    <th *ngFor="let year of tableHeaderYears" class="rightAlignment" [class.highlight]="year == exitSimulation.metricYear">
                        {{ year }}
                    </th>
                    <th class="rightAlignment">
                        Exit Value
                    </th>
                    <th class="rightAlignment">
                        MOIC
                    </th>
                    <th class="rightAlignment">
                        IRR
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="simulationTableData" let-data let-index="index">
                    <tr>
                        <td style="width: 150px;">
                            {{ data.companyName }}
                        </td>
                        <ng-template [ngIf]="exitSimulation.exitMutipleType == 'revenue'">
                            <ng-template ngFor [ngForOf]="data.financials" let-multipleType>
                                <ng-template [ngIf]="multipleType.history">
                                    <td class="rightAlignment" [class.highlight]="multipleType.year == exitSimulation.metricYear">
                                        {{ multipleType.revenue | number:'1.2-2'}}
                                    </td>
                                </ng-template>
                            </ng-template>
                            <ng-template ngFor [ngForOf]="data.financials" let-multipleType let-rowIndex = "index">
                                <ng-template [ngIf]="multipleType.LTM && !multipleType.history">
                                    <td class="rightAlignment" [class.highlight]="multipleType.year == exitSimulation.metricYear">
                                        {{ multipleType.revenue | number:'1.2-2'}}
                                    </td>
                                </ng-template>
                                <ng-template [ngIf]="!multipleType.LTM && rowIndex == tableHeaderYears.indexOf('LTM')">
                                    <td class="rightAlignment" [class.highlight]="multipleType.year == exitSimulation.metricYear">
                                        {{ 0 | number:'1.2-2' }}
                                    </td>
                                </ng-template>
                            </ng-template>
                            <ng-template ngFor [ngForOf]="data.financials" let-multipleType>
                                <ng-template [ngIf]="multipleType.projected">
                                    <td class="rightAlignment" [class.highlight]="multipleType.year == exitSimulation.metricYear">
                                        {{ multipleType.revenue | number:'1.2-2'}}
                                    </td>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                        <ng-template [ngIf]="exitSimulation.exitMutipleType == 'ebitda'" >
                            <td *ngFor="let multipleType of data.financials; let index=index"  class="rightAlignment" [class.highlight]="multipleType.year == exitSimulation.metricYear">
                                {{ multipleType.ebitda | number:'1.2-2'}}
                            </td>
                        </ng-template>
                        <td class="rightAlignment">
                            <ng-template [ngIf]="data.exitValue != 0">
                                {{ data.exitValue | number:'1.2-2' }}
                            </ng-template>
                            <ng-template [ngIf]="data.exitValue == 0">
                                -
                            </ng-template>
                        </td>
                        <td class="rightAlignment">
                            <ng-template [ngIf]="data.moic != 0">
                                {{ data.moic | number:'1.2-2' }}x
                            </ng-template>
                            <ng-template [ngIf]="data.moic == 0">
                                -
                            </ng-template>
                        </td>
                        <td class="rightAlignment">
                            <ng-template [ngIf]="data.irr != 0">
                                {{ data.irr * 100 | number:'1.2-2' }}%
                            </ng-template>
                            <ng-template [ngIf]="data.irr == 0">
                                -
                            </ng-template>
                        </td>
                    </tr>
                    <ng-template [ngIf]="index+1 == simulationTableData.length">
                        <tr>
                            <td>

                            </td>
                            <td *ngFor="let year of tableHeaderYears">

                            </td>
                            <td class="rightAlignment">
                                <strong>
                                    Gross
                                </strong>
                            </td>
                            <td class="rightAlignment">
                                <ng-template [ngIf]="grossTotal.totalMoic != 0">
                                    {{ grossTotal.totalMoic | number:'1.2-2' }}x
                                </ng-template>
                                <ng-template [ngIf]="grossTotal.totalIrr == 0">
                                    -
                                </ng-template>
                            </td>
                            <td class="rightAlignment">
                                <ng-template [ngIf]="grossTotal.totalIrr != 0">
                                    {{ grossTotal.totalIrr * 100 | number:'1.2-2' }}%
                                </ng-template>
                                <ng-template [ngIf]="grossTotal.totalIrr == 0">
                                    -
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                </ng-template>
            </table>
        </div>
    </ng-template>
</div>