import { Injectable } from '@angular/core';
import * as Rx from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class MessageService {
	private subjects = {};
  private valuePropositionLookUp: Map<string, any> = new Map<string, any>();

  private messages:  Map<string, any> = new Map<string, any>();

  private onLoad = true;

  constructor() {}

	publish (name: string, parameters?: any) {
		if(!this.subjects[name]) {
			this.subjects[name]=new Rx.BehaviorSubject({});
		}

    // console.log("PUBLISHING:", name);
    this.subjects[name].next(parameters);
  }

	subscribe (name: string, listener: (parameters?: any) => void) {
    // console.log("subscribe name", name);
		if(!this.subjects[name]) {
      // console.log("subscribe name new subjetct", name);
			this.subjects[name]=new Rx.BehaviorSubject({});
		}

		this.subjects[name].subscribe(listener);

    if(this.messages.get(name)) {
      // console.log("subscribe name msg", this.messages.get(name));

      this.subjects[name].next(this.messages.get(name));
    }
  }
  setValueProposition(id: string, vp) {
    if(vp && vp.length > 0) {
      this.valuePropositionLookUp.set(id, vp);
    } else {
      if(this.valuePropositionLookUp.has(id)){
        this.valuePropositionLookUp.delete(id);
      }
    }
  }

  getValueProposition() {
    let allValueProps = [];
    this.valuePropositionLookUp.forEach(value => {
      allValueProps = allValueProps.concat(value);
    });

    if(allValueProps.length == 0) {
      this.onLoad = false
    }
    return allValueProps;
  }

  unsubscribe(name: string) {
    if(this.subjects[name]) {
      this.subjects[name].unsubscribe();
    }

    this.subjects[name] = null;
  }
}
