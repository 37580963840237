import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExcelExportProperties, GridComponent, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { DataService } from '../services/data.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {FormControl} from '@angular/forms';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import { UtilService } from '../utils/util.service';
import { PortFolioService } from '../qubit-x/portfolio/portfolio.service';
import { TranslateService } from '../services/translation.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-status-fund-level',
  templateUrl: './status-fund-level.component.html',
  styleUrls: ['./status-fund-level.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class StatusFundLevelComponent implements OnInit {

  fundId : string; 

  statusHeader = "Unknown";
  selectedQuarter = "";
  selectedMarketDate = "";
  dataSource = [];
  date = new FormControl(moment());
  public toolbarOptions: ToolbarItems[];

  @ViewChild('usersListGrid') public usersListGrid: GridComponent;
  gridFilterSettings = { type: 'Excel' };

  valuationDates = [
    {value: 'Q4 2021', viewValue: 'Q4 2021'},
    {value: 'Q3 2021', viewValue: 'Q3 2021'},
    {value: 'Q2 2021', viewValue: 'Q2 2021'},
    {value: 'Q1 2021', viewValue: 'Q1 2021'},
    {value: 'Q4 2020', viewValue: 'Q4 2020'},
    {value: 'Q3 2020', viewValue: 'Q3 2020'},
    {value: 'Q2 2020', viewValue: 'Q2 2020'},
    {value: 'Q1 2020', viewValue: 'Q1 2020'},
  ];
  constructor(private activatedRoute : ActivatedRoute, private fundService: FundListService, private ds: DataService, private utilService: UtilService, private router: Router, public translateService: TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.fundId = params.get("fundId");
    })
    this.toolbarOptions = ['ExcelExport'];
  }

  getFundDetails() {
    this.utilService.showLoadingPopup();
    this.ds.getFundLevelStatus(this.fundId, this.selectedQuarter).then(res => {
      this.dataSource = res.body['response'];
      this.getStatusHeader();
      this.utilService.closeAllPopups();
    }).catch(e => {
      this.utilService.showMessage(this.translateService.getLabel("err_failed_fetch_data"), this.translateService.getLabel("ok"));
    });
  }

  getMarketDate(event){
    let selectedMonth = event.value._i.month + 1;
    this.selectedMarketDate = event.value._i.year + "-" + "0" + selectedMonth + "-" +event.value._i.date;
    this.getStatusHeader();
  }

  getStatusHeader(){
    for(let i = 0; i < this.dataSource.length; i++){
      if(this.dataSource[i].valDateId && this.dataSource[i].marketDate && this.dataSource[i].marketDate === this.selectedMarketDate){
        this.statusHeader = "No Irregularities";
      }else{
        this.statusHeader = "Irregularities Detected"
        break;
      }
    }
  }

  statusExcelExport(): void {
    let statusData = JSON.parse(JSON.stringify(this.dataSource));
    statusData.map((x:any) => {
      x.companyName = x.companyName
      x.valDateId = x.valDateId ? "Initiated" : "Not Initiated"
      x.marketDate = x.marketDate ? x.marketDate : "N/A"
      x.status = x.valDateId && x.marketDate && x.marketDate == this.selectedMarketDate ? "Yes" : "No"
      return x
    })

    const statusExcelExportProperties: ExcelExportProperties = {
      dataSource: statusData,
    };

    this.usersListGrid.excelExport(statusExcelExportProperties);
}

  navigateToPortfolio(){
    this.router.navigateByUrl('/portfolio/' + this.fundId);
  }
}

