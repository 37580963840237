import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MessagePopupComponent } from 'src/app/utils/popup/message-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from '../utils/util.service';
import { PortFolioService } from '../qubit-x/portfolio/portfolio.service';
import { TranslateService } from '../services/translation.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit{
  @Input() data;
  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;
  
  fileUploading = false;
  files = [];

  deletedFiles = [];

  widgetKey = "FILE_UPLOAD";


  constructor(private ds: DataService, 
    private utilService: UtilService,
    private portfolioService: PortFolioService,
    public translateService: TranslateService) 
  {}

  ngOnInit() {
    this.ds.getUploadedFiles(this.portfolioService.companyId).subscribe(result=>{
      this.files = result.body["response"];
    }, error=>{
      console.log("No files are uploaded yet.", error)
    });

    this.getDeletedFiles();
  }

  getDeletedFiles() {    
    // this.ds.getDeletedFiles(this.portfolioService.companyId).subscribe(result=>{
    //   this.deletedFiles = result.body["response"];

    // }, error=>{
    //   console.log("No files are uploaded yet.", error)
    // })
  }

  download(fileId) {
    this.ds.downloadFile(fileId).subscribe((fileData)=>{
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.click();
    }, (error)=>{
      console.log("File download error", error);
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"));
    })
  }

  delete(file, index) {
    this.ds.deleteFile(file.id).subscribe(result=>{
      this.utilService.showMessage(this.translateService.getLabel("file") + " (" + file.name + ") " + this.translateService.getLabel("is_deleted"), this.translateService.getLabel("ok"));
      this.files.splice(index, 1);
      this.getDeletedFiles();
    }, error=>{
      console.log("Failed to delete file.", error)
      this.utilService.showMessage(this.translateService.getLabel("err_failed_delete_file"), this.translateService.getLabel("ok"));
    })
  }
  
  handleFileUpload(file) {
    this.files = [...this.files, file];
  }
}