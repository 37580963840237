import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss']
})
export class LoadingPopupComponent implements OnInit {
  message = ""

  constructor(private ms: MessageService) { }

  ngOnInit() {
    this.ms.subscribe("loadingMessage", (msg)=>{
      this.message = msg;
    })
  }

}
