import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QXI_PortFolioService } from '../qxi-portfolio.service';

@Component({
  selector: 'app-qxi-protfolportfolio-currency-dashboard',
  templateUrl: './qxi-protfolportfolio-currency-dashboard.component.html',
  styleUrls: ['./qxi-protfolportfolio-currency-dashboard.component.scss']
})
export class QXI_ProtfolportfolioCurrencyDashboardComponent implements OnInit {

  constructor(public portfolioService:QXI_PortFolioService, private router: Router) { }

  ngOnInit() {    
    if(! this.portfolioService.portfolioData || this.portfolioService.portfolioData.length == 0) {      
      this.router.navigateByUrl("/qxi-fundList");
      return;
    }
  }
}
