import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CompanyListService } from '../company-list.service';
import { CaService } from './ca.service';
import moment from 'moment';
import { VersionList } from '../../versions/version-interfaces';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { UserManagementService } from 'src/app/services/user-management.service';
import { Company } from '../ca-interfaces';
import { UtilService } from 'src/app/utils/util.service';
import { FormControl } from '@angular/forms';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/utils/toast.service';
import { of } from 'rxjs';
import { DateUtilService } from 'src/app/date-util.service';
import { AttributeList } from '../../attribute-list-container/attr-list-interfaces';
import { GsFilterContainerComponent } from './gs-filter-container/gs-filter-container.component';
import { GsFilterService } from './gs-filter-container/gs-filter.service';

@Component({
  selector: 'app-assign-map-version-dialog',
  templateUrl: './assign-map-version-dialog.component.html',
  styleUrls: ['./assign-map-version-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignMapVersionDialogComponent implements OnInit, OnDestroy {
  @ViewChild('gsFilter', { static: false }) gsFilter: ElementRef;
  fundId: string;

  selectedVersionListId: string;
  selectedAttrListId: string;
  selectedUploadDate: Date = new Date();

  versionListsForUploadDate: VersionList[];
  attrsMapListForUploadDate: AttributeList[];

  defaultSelectedCompanies = new Set<Company>();
  selectedCompanies: Company[];

  companySearchFc = new FormControl();

  companyIdsWithConflict: Record<string, boolean> = {};
  areSelectionsInConflict: boolean;

  isGsFilterApplied: boolean = false;
  isDebtCompanyAssignment: boolean;

  constructor(
    private dialog: MatDialog,
    private utilService: UtilService,
    private dateUtilSvc: DateUtilService,
    public clService: CompanyListService,
    private caService: CaService,
    private dialogRef: MatDialogRef<AssignMapVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastService: ToastService,
    private gsFilterService: GsFilterService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fundId = this.data?.['fundId'];
    this.isDebtCompanyAssignment = this.data.isDebtCompanyAssignment;
    const date = this.dateUtilSvc.getDateYYYYMMDD(this.selectedUploadDate);
    this.getVlForUploadDate(date, this.fundId);
    this.getAttrListsForUploadDate(date, this.fundId);
    this.companySearchFc.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.clService.setCompanyNameFilterValue(value?.trim());
      this.clService.filterCompanies(value?.trim(), this.gsFilterService.getSelectedCountries(), this.gsFilterService.getSelectedSectors());
    });
  }

  getVlForUploadDate(uploadDate: string, fundId: string) {
    this.caService.getVersionListsForUploadDate(uploadDate, fundId).subscribe((resp) => {
      this.versionListsForUploadDate = [...resp];
      this.cdr.detectChanges();
    });
  }

  getAttrListsForUploadDate(uploadDate: string, fundId: string) {
    this.caService.getAttrsListsForUploadDate(uploadDate, fundId).subscribe((resp) => {
      this.attrsMapListForUploadDate = [...resp];
      this.cdr.detectChanges();
    });
  }

  onCompanySelectionChange(companies: Set<Company>) {
    this.defaultSelectedCompanies = { ...companies };
    if (this.companyIdsWithConflict) {
    }
    this.selectedCompanies = Array.from(companies);
    this.areSelectionsInConflict = false;
  }

  onVersionListChange(e: string) {
    this.selectedVersionListId = e;
    this.companyIdsWithConflict = {};
    this.areSelectionsInConflict = false;
  }

  onVMapListChange(e: string) {
    this.selectedAttrListId = e;
    this.companyIdsWithConflict = {};
    this.areSelectionsInConflict = false;
  }

  onUploadDateChange(e: Date) {
    this.selectedUploadDate = e;
    const date = this.dateUtilSvc.getDateYYYYMMDD(e);
    this.getVlForUploadDate(date, this.fundId);
    this.getAttrListsForUploadDate(date, this.fundId);
    this.companyIdsWithConflict = {};
    this.areSelectionsInConflict = false;
  }

  openGeographySectorFilter(e) {
    const clientRect = this.gsFilter.nativeElement.getBoundingClientRect();
    const dialogRef = this.dialog.open(GsFilterContainerComponent, {
      hasBackdrop: true,
      disableClose: true,
      height: '442px',
      width: '450px',
      position: {
        top: `${clientRect.top + 40}px`,
        right: `${clientRect.right}px`,
        bottom: `${clientRect.bottom}px`,
        left: `${clientRect.left - 400}px`,
      },
    });

    dialogRef.afterClosed().subscribe((result: { isGsFilterApplied: boolean }) => {
      this.isGsFilterApplied = result?.isGsFilterApplied;
      this.cdr.detectChanges();
    });
  }

  onAppply() {
    const companyIds = this.selectedCompanies.map((c) => c.companyId);
    const uploadDate = this.dateUtilSvc.getDateYYYYMMDD(this.selectedUploadDate);
    if(!this.isDebtCompanyAssignment) {
      this.validateAndApply(companyIds, this.selectedVersionListId, this.selectedAttrListId, uploadDate, this.fundId);
    } else {
      this.validateAndApplyForDebtCompanies(companyIds, this.selectedVersionListId, this.selectedAttrListId, uploadDate, this.fundId);
    }
  }

  // WARNING: function has side-effects for companyIdsWithConflict and tap operator
  validateAndApply(companyIds: string[], selectedVersionListId: string, selectedAttrListId: string, uploadDate: string, fundId: string) {
    this.caService
      .validateSelectedCompanies({
        companyIds: companyIds,
        uploadDate: uploadDate,
        fundId: fundId,
      })
      .pipe(
        tap((res) => {
          let obj: Record<string, boolean> = {};
          res.body?.response?.companyIds?.forEach((c: string) => {
            obj[c] = true;
          });
          this.companyIdsWithConflict = { ...obj };
          if (Object.keys(this.companyIdsWithConflict)?.length > 0) {
            this.areSelectionsInConflict = true;
            const str =
              '<strong>Note:</strong> A map for one or more of the selected companies exists for the specified upload date. Uploading for the same date is not permitted.Please review and resolve before saving.';
            this.utilService.showMessage(str, 'OK', '', false);
          } else {
            this.areSelectionsInConflict = false;
          }
          this.cdr.detectChanges();
        }),
        switchMap((r) => {
          if (r.body?.response?.companyIds?.length < 1) {
            return this.caService.assignVlToCompanies(companyIds, selectedAttrListId, uploadDate, selectedVersionListId, fundId);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (response) => {
          if (response) {
            this.dialogRef.close({
              resetCl: true,
            });
            this.toastService.openSnackBar('Assigned Successfully.');
          } else {
            console.log('No apply');
          }
        },
        (err) => {
          console.log(err);
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar('Failed to assigned.');
        }
      );
  }

  // WARNING: function has side-effects for companyIdsWithConflict and tap operator
  validateAndApplyForDebtCompanies(companyIds: string[], selectedVersionListId: string, selectedAttrListId: string, uploadDate: string, fundId: string) {
    this.caService
      .validateSelectedCompaniesForDebt({
        companyIds: companyIds,
        uploadDate: uploadDate,
        fundId: fundId,
      })
      .pipe(
        tap((res) => {
          let obj: Record<string, boolean> = {};
          res.body?.response?.companyIds?.forEach((c: string) => {
            obj[c] = true;
          });
          this.companyIdsWithConflict = { ...obj };
          if (Object.keys(this.companyIdsWithConflict)?.length > 0) {
            this.areSelectionsInConflict = true;
            const str =
              '<strong>Note:</strong> A map for one or more of the selected companies exists for the specified upload date. Uploading for the same date is not permitted.Please review and resolve before saving.';
            this.utilService.showMessage(str, 'OK', '', false);
          } else {
            this.areSelectionsInConflict = false;
          }
          this.cdr.detectChanges();
        }),
        switchMap((r) => {
          if (r.body?.response?.companyIds?.length < 1) {
            return this.caService.assignVlAndAttrsToDebtCompanies(companyIds, selectedAttrListId, uploadDate, selectedVersionListId, fundId);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (response) => {
          if (response) {
            this.dialogRef.close({
              resetCl: true,
            });
            this.toastService.openSnackBar('Assigned Successfully.');
          } else {
            console.log('No apply');
          }
        },
        (err) => {
          console.log(err);
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar('Failed to assigned.');
        }
      );
  }

  ngOnDestroy(): void {
    this.clService.resetCompanies();
    this.gsFilterService.setSelectedCountries(undefined);
    this.gsFilterService.setSelectedSectors(undefined);
  }
}
