import { Component, OnInit } from '@angular/core';
import { ImageLoadService } from '../../services/image-load.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss']
})
export class LoginFailedComponent implements OnInit {

  constructor( public loaderService: ImageLoadService,private router: Router) { }

  ngOnInit(): void {
  }

  login() {
    this.router.navigateByUrl('/');

  }

}
