import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-drd-snowflake-financials-general-details',
  templateUrl: './drd-snowflake-financials-general-details.component.html',
  styleUrls: ['./drd-snowflake-financials-general-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrdSnowflakeFinancialsGeneralDetailsComponent{
  displayedOrgColumns = [
    'orgName',
    'companyName',
    'gxFinancialRows',
    'reportsFinancialRows',
    'financialRowsDiff',
    'gxGeneralDetailsRows',
    'reportsGeneralDetailsRows',
    'generalDetailsRowsDiff',
    'gxProfileDetailsRows',
    'reportsProfileDetailsRows',
    'profileDetailsRowsDiff',
  ];

  _snowflakeFinsAndGd: any[]; // any for now on purpose for POC

  tabeDataSource: MatTableDataSource<any[]>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() set snowflakeFinsAndGd(snowflakeFinsAndGd) {
    this._snowflakeFinsAndGd = [...snowflakeFinsAndGd];
    if (this._snowflakeFinsAndGd) {
      this.tabeDataSource = new MatTableDataSource(this._snowflakeFinsAndGd);
      this.tabeDataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
