import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-integration-tool',
  templateUrl: './integration-tool.component.html',
  styleUrls: ['./integration-tool.component.scss']
})
export class IntegrationToolComponent implements OnInit {

  constructor(private modalService:NgbModal) { }

  ngOnInit(): void {
  }
  
  @Input() title: any;
  @Input() text:any;
  @Input() img:any;
  @Input() addTopMargin:any;
  
  openModal(modalTemplate:TemplateRef<any>) {
    this.modalService.open(modalTemplate,
      {
        centered: true,
        backdrop: 'static'
      }
    );
  }  
}


  