<span class="main-heading">{{translateService.getLabel("value_bridge_table")}}</span>

<div class="table table-condensed single-valueBridge-grid" id="container">
  <ejs-grid #fundLevelVBGrid
  [dataSource]='fundLevelVBTabularData'
  [allowResizing]="true" 
  [allowTextWrap]="true" 
  [allowGrouping]="true" 
  [allowExcelExport]="true"
  [allowSorting]="true" 
  [allowReordering]="true" 
  [allowPaging]="false" 
  [pageSettings]="gridPageSettings" 
  [showColumnChooser]="true" 
  [toolbar]="gridToolbar" 
  [allowFiltering]="true" 
  [filterSettings]="gridFilterSettings"
   >
      <e-columns>
          <e-column field='companyName' [headerText]='translateService.getLabel("company")' width='150px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.companyName}}
              </ng-template>
            </e-column>

            <e-column field='startStakeValue' width='120px'
              [headerText] = "datePipe.transform(valueBridgeData.dates.startDate, 'mediumDate')"  width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.startStakeValue, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Metric Impact -->
            <e-column field='firstMetricImpact' [headerText]='translateService.getLabel("metric_impact")' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstMetricImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Multiple Impact -->
            <e-column field='firstMultipleImpact' [headerText]='translateService.getLabel("multiple_impact")' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstMultipleImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Realised proceeds Impact -->
            <e-column field='firstRealisedProceedsImpact' [headerText]='translateService.getLabel("realised_proceeds_impact")' width='180px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstRealisedProceedsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Net Debt and Other BD Adj Impact -->
            <e-column field='firstNetDebtImpact' [headerText]='translateService.getLabel("net_debt_and_other_BS_adj")' width='260px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstNetDebtImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Adjsutment to Equity value Impact -->
            <e-column field='firstAdjustmentsToEquityValueImpact' headerText='{{ translateService.getLabel("adjustment_equity_value_impact") }}' width='220px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstAdjustmentsToEquityValueImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Adjsutment to Stake Equity value Impact -->
            <e-column field='firstOthersDebtImpact' [headerText]='translateService.getLabel("adjustment_to_stake_equity_value_impact")' width='220px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstOthersDebtImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Stake Impact -->
            <e-column field='firstStakeImpact' [headerText]='translateService.getLabel("stake_impact")' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstStakeImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- FX impact -->
            <e-column field='firstFxImpact' [headerText]='translateService.getLabel("fx_impact")' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstFxImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>
            
            <!-- Waterfall Impact -->
            <e-column field='firstWaterfallImpact' headerText='{{ translateService.getLabel("waterfall_impact") }}' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstWaterfallImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <!-- Other Impact -->
            <e-column field='firstOthersImpact' [headerText]='translateService.getLabel("others_impact")' width='150px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstOthersImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>
            
            <e-column field='firstNewInvestmentsImpact' headerText='New Investments Impact'  width='180px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstNewInvestmentsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field='firstExitInvestmentsImpact' headerText='Exit Investments Impact'  width='180px' textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.firstExitInvestmentsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field='endStakeValue' width='150px'
            [headerText] = "datePipe.transform(valueBridgeData.dates.endDate, 'mediumDate')" textAlign='Right'>
              <ng-template #template let-comp>
                {{ utilService.getDisplayFormattedNumber(comp.endStakeValue, 1, "", "-", false)}}
              </ng-template>
            </e-column>
      </e-columns>

      <e-aggregates>
        <e-aggregate>
          <e-columns>
            <e-column field="companyName" width='150px' type="sum">
              <ng-template #footerTemplate>
                {{translateService.getLabel("total")}}
              </ng-template>
            </e-column>

            <e-column field="startStakeValue" width='120px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalStartStakeValue, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstMetricImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstMetricImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstMultipleImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstMultipleImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstRealisedProceedsImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstRealisedProceedsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstNetDebtImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstNetDebtImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstAdjustmentsToEquityValueImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstAdjustmentsToEquityValueImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstOthersDebtImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstOthersDebtImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstStakeImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstStakeImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstFxImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstFxImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstWaterfallImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstWaterfallImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstOthersImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstOthersImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstNewInvestmentsImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstNewInvestmentsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="firstExitInvestmentsImpact" width='150px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalFirstExitInvestmentsImpact, 1, "", "-", false)}}
              </ng-template>
            </e-column>

            <e-column field="endStakeValue" width='120px' type="sum">
              <ng-template #footerTemplate>                  
                {{ utilService.getDisplayFormattedNumber(aggregatedWaterFallNumbers.totalEndStakeValue, 1, "", "-", false)}}
              </ng-template>
            </e-column>
          </e-columns>
        </e-aggregate>
      </e-aggregates>
    
  </ejs-grid>
</div>


<div class="row">
  <div class="col-6">
    <span class="main-heading">{{ translateService.getLabel("value_bridge_heading") }}</span>
  </div>
  <div class="col-6 right-align">
    <mat-checkbox [(ngModel)]="waterFallLabelsForVB" (change)="refreshWaterfallGraph()" style="margin-right: 10px;">
      {{ translateService.getLabel("show_labels") }}
    </mat-checkbox>
  </div>
</div>
<div [chart]="valuationBridge"></div>

<br>
<span class="main-heading">{{translateService.getLabel("company_bridge")}}</span>
<div [chart]="companyBridge"></div>