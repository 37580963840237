<mat-card class="table-wrapper">
    <div *ngIf="!isReadOnly" class="map-name">
        <div class="mat-h2 map-label">
            Map Name
        </div>
        <mat-form-field>
            <input matInput type="text" placeholder="Enter map name" [formControl]="mapNameFc" required />
        </mat-form-field>
        <mat-error class="list-name-error" *ngIf="mapNameFc?.touched && mapNameFc?.hasError('required')">
            <sup>*</sup>Please enter a name</mat-error>
        <mat-error class="list-name-error" *ngIf="mapNameFc?.touched && mapNameFc?.hasError('duplicateName')">
            <sup>*</sup>Name already in use. Please select a different name.</mat-error>
    </div>

    <div *ngIf="isReadOnly" class="list-name-view">
        <div class="mat-h2 map-label">
            {{ attributeListName }}
        </div>
        <div *ngIf="attributeListUploadDate" class="upload-date-label">
            <div class="date-label">
                Upload Date
            </div>
            {{ attributeListUploadDate | date: 'mediumDate' }}
        </div>
        <div *ngIf="attributeListUploadDate" class="replicate-map">
            <mat-icon class="info-icon"
                matTooltip="Replicate Map allows users to duplicate an existing mapping configuration. It streamlines the process of creating similar maps with minor adjustments for different companies, ensuring efficiency and consistency"
                matTooltipPosition="left">info_outline</mat-icon>
            <button mat-stroked-button class="replicate-map-btn" (click)="onReplicateMapClick()" [routerLink]="['../../replicate-attributes-map', attributeListMapId]">
                Replicate Map
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="historical-versions">
        <div class="mat-h2 historical-financials">
            Historical Financials
        </div>
        <div class="mat-h4">
            Income Statement (Mn)
        </div>
    </div>
    <!-- Projected
    <button mat-button (click)="onAddHistVersion()">Add </button> -->
    <div class="table-br">
        <app-attr-mapping-table *ngIf="!isReadOnly && apiAttrs.length > 0" tableId="incomeStatement"
            [isReadOnly]="isReadOnly" [attributes]="attrsService.incomeStatements$ | async" [gxAttributes]="apiAttrs"
            [usedAttrs]="histUsedAttrs" (onGxAttributeSelect)="onIncomeStatementUpdate($event)">Loading
            income statment...</app-attr-mapping-table>

        <app-attr-mapping-table *ngIf="isReadOnly && attributeListName" tableId="incomeStatement"
            [isReadOnly]="isReadOnly" [attributes]="attrsService.incomeStatements$ | async">Loading income
            statment...</app-attr-mapping-table>
    </div>

    <div class="mat-h4">
        Balance Sheet (Mn)
    </div>
    <div class="table-br">
        <app-attr-mapping-table *ngIf="!isReadOnly && apiAttrs.length > 0" tableId="balanceSheet"
            [usedAttrs]="histUsedAttrs" [attributes]="attrsService.histBalanceSheetAttributes$ | async"
            [isReadOnly]="isReadOnly" [gxAttributes]="apiAttrs"
            (onGxAttributeSelect)="onHistBalanceSheetUpdate($event)">Loading balance
            sheet...</app-attr-mapping-table>

        <app-attr-mapping-table *ngIf="isReadOnly && attributeListName" tableId="balanceSheet"
            [attributes]="attrsService.histBalanceSheetAttributes$ | async" [isReadOnly]="isReadOnly">Loading balance
            sheet...</app-attr-mapping-table>
    </div>

    <div class="mat-h4">
        Cash Flow Statement (Mn)
    </div>
    <div class="table-br">
        <app-attr-mapping-table *ngIf="!isReadOnly && apiAttrs.length > 0" tableId="cashFlowStatement"
            [usedAttrs]="histUsedAttrs" [attributes]="attrsService.histCashflowAttributes$ | async"
            [isReadOnly]="isReadOnly" [gxAttributes]="apiAttrs"
            (onGxAttributeSelect)="onHistCashflowUpdate($event)">Loading cash flow statment...</app-attr-mapping-table>

        <app-attr-mapping-table *ngIf="isReadOnly && attributeListName" tableId="cashFlowStatement"
            [attributes]="attrsService.histCashflowAttributes$ | async" [isReadOnly]="isReadOnly"
            [gxAttributes]="apiAttrs" (onGxAttributeSelect)="onHistCashflowUpdate($event)">Loading cash flow
            statment...</app-attr-mapping-table>
    </div>

    <div class="historical-versions">
        <div class="mat-h2 projected-financials">
            Projected Financials
        </div>
        <div class="mat-h4">
            Financials
        </div>
    </div>
    <div class="table-br">
        <app-attr-mapping-table *ngIf="!isReadOnly && apiAttrs.length > 0" tableId="projectedFinancials"
            [usedAttrs]="usedProjectedAttrs" [attributes]="attrsService.projectedAttributes$ | async"
            [isReadOnly]="isReadOnly" [gxAttributes]="apiAttrs"
            (onGxAttributeSelect)="onProjectedAttrsUpdate($event)">Loading projected
            financials
            attributes...</app-attr-mapping-table>

        <app-attr-mapping-table *ngIf="isReadOnly && attributeListName" tableId="projectedFinancials"
            [attributes]="attrsService.projectedAttributes$ | async" [isReadOnly]="isReadOnly">Loading projected
            financials
            attributes...</app-attr-mapping-table>
    </div>
</mat-card>

<mat-toolbar class="footer">
    <div>
        <button mat-stroked-button class="cancel-btn" (click)="goBack()">
            Back
        </button>

        <button *ngIf="!isReadOnly" mat-raised-button class="btn-custom-primary" (click)="onSaveClick()"
            [disabled]="!mapNameFc.valid">
            Save
        </button>
    </div>
</mat-toolbar>
