import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DataReconPlutoService } from '../data-recon-pluto.service';
import { ToastService } from 'src/app/utils/toast.service';
import {
  AttributesRow,
  Client,
  DataLoadStatus,
  DataReconState,
  FinancialsGDProfileRow, OrganizationUsersRow, UserCompanyAccessRow
} from '../data-recon-pluto.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-data-recon-pluto-container',
  templateUrl: './data-recon-pluto-container.component.html',
  styleUrls: ['./data-recon-pluto-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataReconPlutoContainerComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject();

  clients: Client[] = [];

  dataLoadStatus: DataLoadStatus;

  selectedClient: string | undefined = undefined;

  loading: DataReconState<boolean> = {
    dataLoad: false,
    financialsGD: false,
    attributes: false,
    userCompanies: false,
    orgUsers: false,
  };

  apiErrors: DataReconState<string | null> = {
    dataLoad: null,
    financialsGD: null,
    attributes: null,
    userCompanies: null,
    orgUsers: null,
  };

  financialGDProfileRows: FinancialsGDProfileRow[] = [];

  attributesRows: AttributesRow[] = [];

  userCompanyAccessRows: UserCompanyAccessRow[] = [];

  organizationUsersRows: OrganizationUsersRow[] = [];

  get errorCount() {
    return this.financialGDProfileRows.length + this.attributesRows.length
      + this.userCompanyAccessRows.length + this.organizationUsersRows.length;
  }

  constructor(
    private dataReconService: DataReconPlutoService,
    private snackBarService: ToastService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading.dataLoad = true;
    this.dataReconService.getClients()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clients => {
        this.clients = clients;
        this.loading.dataLoad = false;
        this.refreshData();
      }, error => {
        this.snackBarService.openSnackBar('Error fetching clients');
        this.loading.dataLoad = false;
        this.apiErrors.dataLoad = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  getDataLoadStatus(orgIds: string[]) {
    this.loading.dataLoad = true;
    this.apiErrors.dataLoad = null;
    this.dataReconService.getDataLoadStatus(orgIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dataLoadStatus => {
        this.dataLoadStatus = { ...dataLoadStatus };
        this.loading.dataLoad = false;
        this.cdr.detectChanges();
      }, error => {
        this.snackBarService.openSnackBar('Failed to fetch Data Load status');
        this.loading.dataLoad = false;
        this.apiErrors.dataLoad = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  getFinancialsGDProfileDetails(orgIds: string[]) {
    this.loading.financialsGD = true;
    this.apiErrors.financialsGD = null;
    this.dataReconService.getFinancialsGDProfileDetails(orgIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.financialGDProfileRows = [...data];
        this.loading.financialsGD = false;
        this.cdr.detectChanges();
      }, error => {
        this.snackBarService.openSnackBar('Failed to fetch Financials GD Profile details');
        this.loading.financialsGD = false;
        this.apiErrors.financialsGD = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  getAttributeDetails(orgIds: string[]) {
    this.loading.attributes = true;
    this.apiErrors.attributes = null;
    this.dataReconService.getAttributeDetails(orgIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.attributesRows = [...data];
        this.loading.attributes = false;
        this.cdr.detectChanges();
      }, error => {
        this.snackBarService.openSnackBar('Failed to Attribute details');
        this.loading.attributes = false;
        this.apiErrors.attributes = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  getUserCompanyAccessDetails(orgIds: string[]) {
    this.loading.userCompanies = true;
    this.apiErrors.userCompanies = null;
    this.dataReconService.getUserCompanyAccessDetails(orgIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.userCompanyAccessRows = [...data];
        this.loading.userCompanies = false;
        this.cdr.detectChanges();
      }, error => {
        this.snackBarService.openSnackBar('Failed to User Company Access details');
        this.loading.userCompanies = false;
        this.apiErrors.userCompanies = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  getOrganizationUserDetails(orgIds: string[]) {
    this.loading.orgUsers = true;
    this.apiErrors.orgUsers = null;
    this.dataReconService.getOrganizationUserDetails(orgIds)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.organizationUsersRows = [...data];
        this.loading.orgUsers = false;
        this.cdr.detectChanges();
      }, error => {
        this.snackBarService.openSnackBar('Failed to Organization Users details');
        this.loading.orgUsers = false;
        this.apiErrors.orgUsers = this.getErrorMessage(error);
        this.cdr.detectChanges();
      });
  }

  onClientSelection(id: string) {
    this.selectedClient = id;
    this.refreshData();
  }

  resetData() {
    this.dataLoadStatus = undefined;
    this.financialGDProfileRows = [];
    this.attributesRows = [];
    this.userCompanyAccessRows = [];
    this.organizationUsersRows = [];
  }

  refreshData() {
    if (this.clients.length === 0 && !this.selectedClient) {
      console.log('No clients selected for Data recon');
      return;
    }

    const orgIds = this.selectedClient ? [this.selectedClient] : this.clients.map(client => client.id);

    this.resetData();
    this.getDataLoadStatus(orgIds);
    this.getFinancialsGDProfileDetails(orgIds);
    this.getAttributeDetails(orgIds);
    this.getUserCompanyAccessDetails(orgIds);
    this.getOrganizationUserDetails(orgIds);
  }

  getErrorMessage(error: HttpErrorResponse) {
    const apiService = error?.url?.includes('pluto-service') ? 'Pluto Service' : 'Internal Visualization Service';
    const errorMessage = error?.error?.message || 'Error fetching data';

    return `${apiService} error: ${errorMessage}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
