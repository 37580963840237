import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
@Component({
  selector: 'app-rollover',
  templateUrl: './rollover.component.html',
  styleUrls: ['./rollover.component.scss']
})
export class RolloverComponent implements OnInit {

  constructor(private ds : DataService,private modalService : NgbModal, 
    public utilService : UtilService,public translateService: TranslateService) { }

  @Input() valuationDateSelectionPopup;
  @Input() rolloverDetails;
  // @Input() valuationDateGridDataSource; temporary remove
  @Output() rolloverSubmit = new EventEmitter<any>();
  valuationDate;
  versionName = 'FIRST';
  marketDataDate;
  financialDate;
  minDate;
  refreshFinancials = false;
  refreshSecurityDetails = false;


  ngOnInit(): void {
    this.valuationDate = this.rolloverDetails?.valuationDate;
    this.financialDate = this.rolloverDetails?.financialDate ? this.financialDate : this.valuationDate;
    this.refreshFinancials = this.rolloverDetails?.refreshFinancials || this.refreshFinancials;
    this.refreshSecurityDetails = this.rolloverDetails?.refreshSecurityDetails || this.refreshSecurityDetails;
    this.marketDateValidator();
    //temporary remove
    // const minValDate = moment(this.valuationDateGridDataSource[0].name).add(1, 'days').format("YYYY-MM-DD");
    // this.minDate = minValDate;
  }

  updateValuationDetails(){
    try {
      if(this.valuationDate && this.versionName){
        let payload = {
          "currentValuationDate" : (moment(this.valuationDate)).format("YYYY-MM-DD"),
          "marketDataDate" : (moment(this.marketDataDate)).format("YYYY-MM-DD"),
          "financialDate" : (moment(this.financialDate)).format("YYYY-MM-DD"),
          "versionName" : this.versionName,
          "refreshFinancials": this.refreshFinancials,
          "refreshSecurityDetails": this.refreshSecurityDetails,
          ...this.rolloverDetails
        }
        delete payload.valuationDate;
        this.rolloverSubmit.emit(payload);
      }
      else{
        this.utilService.showMessage("Please Enter the Valuation Date and Version Name.", "Ok", true)
      }
    } catch(e) {
      this.utilService.showMessage("Please enter valid inputs", "Ok", true);
    }
  }


  marketDateValidator() {
    const todayDate = new Date().setHours(0,0,0,0);
    const valDate = new Date(this.valuationDate).setHours(0,0,0,0);
    if(todayDate <= valDate) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      this.marketDataDate = date;
    } else {
      this.marketDataDate = new Date(this.valuationDate);
    }
    this.financialDate = new Date(this.valuationDate);
  }

}
