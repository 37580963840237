/* tslint:disable:variable-name */
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { FinancialsGDProfileRow } from '../../data-recon-pluto.model';

@Component({
  selector: 'app-drp-financials-general-profile-details',
  templateUrl: './drp-financials-general-profile-details.component.html',
  styleUrls: ['../table-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrpFinancialsGeneralProfileDetailsComponent {
  displayColumnGroups = [
    'empty',
    'financials',
    'generalDetails',
    'profileDetails',
  ];

  displayColumns = [
    'organizationName',
    'companyName',
    'gxFinancialsCount',
    'plutoFinancialsBaseCount',
    'plutoFinancialsFactCount',
    'financialsDelta',
    'gxFinancialsLastUpdated',
    'gxGeneralDetailsCount',
    'plutoGeneralDetailsBaseCount',
    'plutoGeneralDetailsFactCount',
    'generalDetailsDelta',
    'gxGeneralDetailsLastUpdated',
    'gxProfileDetailsCount',
    'plutoProfileDetailsBaseCount',
    'plutoProfileDetailsFactCount',
    'profileDetailsDelta',
    'gxProfileDetailsLastUpdated',
  ];

  _data: FinancialsGDProfileRow[];

  dataSource: MatTableDataSource<FinancialsGDProfileRow>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() loading: boolean;

  @Input() error: string | null;

  @Input() set data(data) {
    this._data = [...data];
    if (this._data) {
      this.dataSource = new MatTableDataSource(this._data);
      this.dataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
