import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import moment from "moment";

@Component({
  selector: "app-waterfall-analysis",
  templateUrl: "./waterfall-analysis.component.html",
  styleUrls: ["./waterfall-analysis.component.scss"],
})
export class WaterfallAnalysisComponent implements OnInit {
  yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

  exitInvestmentFormGroup : FormGroup;
  valuationDate: string;

  navigationLinks = [
    { title: 'Going-concern Analysis', isActive: true, key: 'goingConcernAnalysis' },
    { title: 'Liquidation Analysis', isActive: false, key: 'liquidationAnalyis' }
  ];
activeTab = "goingConcernAnalysis";
  constructor(private formBuilder: FormBuilder) {
  //   this.exitInvestmentFormGroup = this.formBuilder.group({
  //     exitFormSelect : [],
  // });
  }

  ngOnInit(): void {}

  switchTab(itemKey, index){
    this.navigationLinks.forEach(link => { link.isActive = false });
    this.navigationLinks[index].isActive = true;
    this.activeTab = itemKey;
  }

  onInvestmentStatusChange(event) {
    console.log(event);
    if(event.value === 'Exited') {
      this.exitInvestmentFormGroup.addControl('exitFormDate', new FormControl(this.yesterday));
    } else {
      this.exitInvestmentFormGroup.removeControl('exitFormDate');
    }
  }

  onExitDateChange(event) {
    console.log(event);
  }

  setValuationDate(event) {
    console.log(event);
    this.valuationDate = event;
  }
}
