import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class QXP_FundListService {
  allFunds = [];

  constructor(private ds: DataService)
  {
    
  }

  createFund(fundBody, user) {
    this.ds.createFund(fundBody, user).subscribe(res =>{
      // console.log("Fund Created", res.body["response"]);
      this.allFunds.push({fund: res.body["response"], permission: "W"});
    }, error =>{
      console.log("error while creating fund", error)
    })
  }

  getFunds(user) {
    this.allFunds = [];

    this.ds.getAllFunds(user).subscribe(res =>{
      // console.log("All Funds", res.body);
      this.allFunds = res.body["response"]
    }, error =>{
      console.log("Error while fetching all funds", error)
    })
  }

  getFundName(id) {
    const fundObj = this.allFunds.find( f => f.fund.id === id);
    return fundObj ? fundObj["fund"].name : "";
  }

  getFundById(fundId) {
    return this.allFunds.find(f => f.fund.id === fundId).fund;
  }
}