import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from '../services/user-management.service';

import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { UtilService } from '../utils/util.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { NavigationService } from '../services/navigation.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  summaryContent = [
    {
      "header": "Total Number of applications",
      "value": "84",
      "footer": {
        "label": "New This Month",
        "value": "3"
      }
    },
    {
      "header": "Application with Enterpreneur",
      "value": "9"
    },
    {
      "header": "Applications with 73 Strings",
      "value": "8"
    },
    {
      "header": "Applications Completed",
      "value": "67"
    }
  ]

  displayedColumns: string[] = [
    "name",
    "email",
    "status",
    "total",
    "completed",
    "pending",
    "lastActiveOn",
    "actions"
  ];

  dataSource = [];
  error;
  dataLoading = true;
  orgTypeSelection = "corp-portfolio"
  valuationUsers = [];
  selectedValuationUsers = [];

  orgSelected = "corp-portfolio";

  orgDataSource = [];

  orgLoading: boolean = true;

  @ViewChild('orgPaginator', { static: false }) orgPaginator: MatPaginator;

  orgEditable: boolean[];

  displayedOrgColumns: string[] = [
    "name",
    "createdOn",
    "createdBy",
    "view"
  ];

  showUsers = false;

  userDataSource = [];

  selectedUserList = [];

  userDisplayedColumns: string[] = [
    "id",
    "name",
    "status",
    "createdBy",
    "createdOn",
    "edit"
  ];

  orgList = [];
  selectedOrg;
  selectedOrgId;

  vcUsers = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @ViewChild('popOver') public popover: NgbPopover;

  orgGridPageSettings = { pageSizes: true, pageSize: 10 };
  @ViewChild('orgListGrid') public orgListGrid: GridComponent;

  gridPageSettings = { pageSizes: true, pageSize: 20 };
  @ViewChild('usersListGrid') public usersListGrid: GridComponent;
  gridFilterSettings = { type: 'Excel' };
  is73SAdmin: boolean;

  constructor(private router: Router, public ums: UserManagementService, private popupMessageService: UtilService,
    private navigationService: NavigationService, private us: UserManagementService, public utilService: UtilService) { }

  ngOnInit() {
    this.is73SAdmin = this.us.is73sAdminAndMasterUser()
    this.getOrgList();
  }

  prepareUsersList() {
    if (this.showUsers) {
      if (!this.dataSource || this.dataSource.length == 0) {
        this.getUsersList();
      }
    }
  }

  getUsersList() {
    const details = this.ums.getUserDetails();
    if (this.ums.isPortalUser()) {
      this.utilService.showLoadingPopup();
      let userType = "";

      if (details.organization.portfolioValuation) {
        userType = "corp-portfolio"
      } else if (details.organization.impairmentValuation) {
        userType = "corp-impairement"
      } else if (details.organization.businessValuation) {
        userType = "corp-corporate"
      }

      this.popupMessageService.navigateHome(userType);

      this.utilService.closeAllPopups();

    } else {
      localStorage.removeItem("73s-selected-vc");
      this.orgTypeSelection = "corp-portfolio";
      this.getPortalUsers();
    }
  }

  getOrgList() {
    this.utilService.showLoadingPopup();

    let orgArray: any[] = [];
    const details = this.us.getUserDetails();
    const org = details.organization;

    org['name'] = org.orgName;
    org['createdOn'] = org.createdDate ? moment(org.createdDate).format("ll") : moment(new Date()).format("ll");
    org['createdBy'] = org.createdBy ? org.createdBy : "73S-Admin";

    orgArray.push(org);

    this.us.getOrgList(this.is73SAdmin ? null : details.id).subscribe((orgListData) => {
      if (!orgListData || !orgListData.body["response"]) return;

      orgListData.body["response"].forEach(org => {
        const obj = org;

        obj['name'] = org.orgName;
        obj['createdOn'] = org.createdDate ? moment(org.createdDate).format("ll") : moment(new Date()).format("ll");
        obj['createdBy'] = org.createdBy ? org.createdBy : "73S-Admin";

        orgArray.push(obj);
      });

      this.orgList = orgArray;

      console.log("orgList", this.orgList);

      this.orgTypeSelected(this.orgTypeSelection);
    }, error => {
      this.orgLoading = false;
    });
  }

  getPortalUsers() {
    this.popupMessageService.showLoadingPopup();
    const userType = this.orgTypeSelection.indexOf("corp") === 0 ? "corp" : this.orgTypeSelection;

    this.ums.getPortalUsers(userType).subscribe(result => {

      if (result.body["response"] && result.body["response"].length > 0) {

        result.body["response"].forEach(user => {
          if (user.lastActiveOn) {
            user.lastActiveOn = moment(user.lastActiveOn).format("ll");
          }
        });

        this.prepareUserList(result.body["response"]);

      } else {
        this.error = "No data found. Please contact us."
      }
      this.dataLoading = false;
      this.popupMessageService.closeAllPopups();

    }, error => {
      console.log("Error: Failed to fetch list of VCs", error);
      this.error = "Failed to fetch list of VCs. Please try again later"
      this.dataLoading = false;
      this.popupMessageService.closeAllPopups();
    })
  }

  prepareUserList(dbArray) {
    if (this.orgTypeSelection === "vc") {
      const adminUser = this.ums.getUserDetails();
      adminUser.lastActiveOn = moment(adminUser.lastActiveOn).format("ll");

      this.vcUsers = dbArray;
      this.vcUsers.push(adminUser);

      this.selectedValuationUsers = this.vcUsers;
    }
    else {
      this.valuationUsers = dbArray;
      this.selectedValuationUsers = dbArray;

      if (this.orgTypeSelection === "corp-portfolio") {
        this.selectedValuationUsers = this.valuationUsers.filter(u => u.organization.portfolioValuation);

      } else if (this.orgTypeSelection === "corp-impairement") {
        this.selectedValuationUsers = this.valuationUsers.filter(u => u.organization.impairmentValuation);

      } else if (this.orgTypeSelection === "corp-corporate") {
        this.selectedValuationUsers = this.valuationUsers.filter(u => u.organization.businessValuation);
      }
    }

    setTimeout(() => {
      this.dataSource = this.selectedValuationUsers;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;       
    });
  }

  view(user) {
    let selectedUser = user;

    if (this.selectedUserList && this.selectedUserList.length > 0) {
      selectedUser = this.selectedUserList.find(u => u.id == user.id);
    }

    this.ums.setSelectedUser(selectedUser);

    if (!this.showUsers) {
      this.orgTypeSelection = "corp-portfolio";
    }

    if (this.orgTypeSelection === "vc") {
      this.router.navigateByUrl('/application-forms/' + user.id);
    } else if (this.orgTypeSelection === "corp-portfolio") {
      // this.router.navigateByUrl('/portfolio/TEST');
      //this.router.navigateByUrl('/fundList');
      this.navigationService.navigateToSpecificApp(selectedUser);
    } else if (this.orgTypeSelection === "corp-impairement") {
      this.router.navigateByUrl('/qxi-fundList');

    } else if (this.orgTypeSelection === "corp-corporate") {
      this.router.navigateByUrl('/qxp-fundList');
    } else {
      // this.router.navigateByUrl('/fundList');
      this.navigationService.navigateToSpecificApp(user);
    }
  }

  orgTypeSelectionChange(selectedType) {
    this.orgTypeSelection = selectedType;

    if (selectedType === "vc" && this.vcUsers.length === 0) {
      this.getPortalUsers();

    } else if (selectedType.indexOf("corp") === 0 && this.valuationUsers.length === 0) {
      this.getPortalUsers();

    } else {
      const array = selectedType.indexOf("corp") === 0 ? this.valuationUsers : this.vcUsers;
      this.prepareUserList(array);
    }
  }

  orgTypeSelected(event) {
    // const filteredArray = this.orgList.filter(row=>{
    //   return row.orgType === event || row.orgType === 'ai73s'
    // })

    this.loadOrgDatails(this.orgList);
  }

  loadOrgDatails(orgArray) {
    orgArray = orgArray.sort((o1, o2) => {
      return new Date(o1.createdOn) < new Date(o2.createdOn) ? 1 : -1;
    })

    // console.log("newArray onload", orgArray);

    this.orgDataSource = orgArray;

    this.utilService.closeAllPopups();
  }

  prepareDataSource(dataSource, editable, paginator) {

    editable.forEach(() => {
      editable.push(false);
    })

    // this.sort.active = 'createdOn';
    // this.sort.direction = 'desc';

    dataSource.paginator = paginator;
    // dataSource.sort = this.sort;
  }

  clickViewOnOrg(orgId, orgName) {
    this.selectedOrg = orgName;
    this.selectedOrgId = orgId;

    // this.resetAll();

    this.us.getUsers(orgId).subscribe((userSuccess) => {
      // console.log("userSuccess", userSuccess);
      const users = userSuccess.body["response"];

      this.loadUsers(users, this.selectedOrg);

    }, error => {
      console.log("Error in fetching users", error);
    })
  }

  loadUsers(users, org) {
    const usersData = [];

    const selectedOrganization = this.orgList.find(org => org.id == this.selectedOrgId);

    users.forEach(user => {
      // let r = {
      //   createdBy : user.createdBy? user.createdBy : '73S-Admin',
      //   id : user.id,
      //   userId: user.userId,
      //   userName : user.userName,
      //   active: user.active? "Active" : "In-Active",
      //   createdOn : moment(user.createdDate).format("ll"),
      //   description: user.details ? user.details.description : "",
      //   groupIds: user.groupIds,
      //   lastActiveOn: user.lastActiveOn,
      //   organization: selectedOrganization
      // }

      let r = user
      r.organization = selectedOrganization
      usersData.push(r);
    });

    this.selectedUserList = cloneDeep(usersData);
    this.userDataSource = usersData;

  }

}

export interface Org {
  id: string;
  name: string;
  orgType: string;
  createdOn: string;
  createdBy: string;
}

const ORG_DATA: Org[] = [];