import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { DataService } from '../services/data.service';
import { MessageService } from '../services/message.service';
import { TranslateService } from '../services/translation.service';
import { UserManagementService } from '../services/user-management.service';
import { NotificationService } from '../utils/notification/notification.service';
import { UtilService } from '../utils/util.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit {
  @Input() isLoggedIn;

  @ViewChild("inputSearchElement") inputSearchElement: ElementRef;

  inputValues = [];
  searchString = "";

  matchingEntities;
  savedEntities = [];
  showSavedEntities = true;

  companyValuationDates = [];
  versionsInValuationDate = [];

  allCompanies = [];

  allDebtCompanies = [];

  selectedCompanyId;

  selectable = true;
  removable = true;

  searchStep = 0;

  dataFetchInProgress = false;
  
  allVersionsInValuationDate;

  companySecurities = [];

  securityValDate = [];

  debtValuationDates = null;

  allVersions = null;

  constructor(public router: Router, private ds: DataService, public utilService: UtilService,
    private us: UserManagementService, private portfolioService: PortFolioSummaryServiceV2,
    private fundListService : FundListService, private datePipe: DatePipe,
    private ms: MessageService, private notificationService: NotificationService,
    public translateService: TranslateService) {

      this.ms.subscribe("selected-user-init", loggedInUser =>{
        const user = this.us.getSelectedUserDetails();
        this.savedEntities = [];
  
        this.notificationService.init();
  
        this.ds.getWidgetDataFromDB("LAST_SEARCHED_COMPANIES", user.id).subscribe(res => {
  
          if(res.body["response"][0]["widgetData"]) {
            this.savedEntities = res.body["response"][0]["widgetData"];
          }
          console.log("savedEntities", this.savedEntities);  
          this.matchingEntities = this.savedEntities;
          this.showSavedEntities = true;
        }, error => {
          this.savedEntities = [];
          this.matchingEntities = [];
        })

        this.ds.getAllCompaniesByUser(user.id).subscribe(res => {
          this.allCompanies = res.body["response"].filter(c => c.companyName && c.companyName.length > 0).map(c => {
            return {
              companyId: c.id,
              companyName: c.companyName,
              exitStatus: c.exitStatus,
              fundId: c.fundCompany,
              logo: c.logo
            }
          })
        })
      })
    }

  ngOnInit(){
    this.dataFetchInProgress = true;
    
    const fundAvailableInterval = setInterval(() => {
      if(this.fundListService.allFunds && this.fundListService.allFunds.length > 0){
        this.getDebtCompanies()

        clearInterval(fundAvailableInterval);
      }
    }, 500)

  }

  getRecentlySearchedOnEnter(evt: any, option){
    if (evt.source.selected) {
      this.clickOnSavedOption(option);
    }
  }

  // addingSearchedOptionOnEnter(evt: any, option){
  //   if (evt.source.selected) {
  //     if(option.type == "label" || this.inputValues.length >= 4){
  //       this.navigate();
  //     }
  //     else{
  //       this.addSelectedItemToChips(option);
  //     }
  //   }

  // }

  getLatestSearchOnEnter(){ 
      this.navigate();
  }

  async getDebtCompanies(){

      let fundIds = [];
      this.fundListService.allFunds.forEach(item => {
        fundIds.push(item.fund.id);
      })

      const user = this.us.getSelectedUserDetails();
      const reqBody = {
        "fundIds": fundIds,
        "orgId": user.organization.id,
        "userId": user.id
      }

      try {
        let debtCompaniesResponse = await this.ds.getCompaniesForDebt(reqBody).toPromise();
        this.allDebtCompanies = debtCompaniesResponse.body["response"];
      } catch(e) {
        console.log("Debt companies do not exist")
      }

      this.dataFetchInProgress = false;
  }

  searchCompanyDetails(searchedString) {
    this.showSavedEntities = false;
    this.matchingEntities = [];

    // this.getDebtCompanies();

    if (!searchedString || !searchedString.length) {

      if(this.inputValues.length == 0) {
        this.showSavedEntities = true;
        this.matchingEntities = this.savedEntities;
        return;
      }

      searchedString = "";
    }

    setTimeout(() => {    
      if(this.searchStep == 0){
        const options = this.fundListService.allFunds.filter(item => {
          return searchedString.length == 0 || (item.fund.name.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase())) 
        });
  
        let matchingFunds = [];

        if(!this.showSavedEntities) {
          matchingFunds = [{
            "type": "label",
            "name": this.translateService.getLabel("funds")
          }]
    
          options.forEach(ele => {
            matchingFunds.push({
              "id": ele.fund.id,
              "name": ele.fund.name,
              "type": "FUND"
            })
          })
  
          // this.searchCompanies(null, searchedString); 
          this.filterCompanies(searchedString, true);
          
          let matchingCompanies = this.matchingEntities.map(c => {
            if(c.type == "label") {
              return c;
            }

            try {
              const fund = this.fundListService.allFunds.find(f => f.fund.id == c["fundId"]).fund;
              if(c.companyType == "Debt"){
                return {
                  "id": c["id"],
                  "name": c["name"],
                  "type": "FUND_AND_COMPANY",
                  "fundId": c["fundId"],
                  "fundName": fund ? fund.name : null,
                  "companyType": "Debt",
                  "issuerCompanyId": c["issuerCompanyId"]
                }
              }
              else{
                return {
                  "id": c["id"],
                  "name": c["name"],
                  "type": "FUND_AND_COMPANY",
                  "fundId": c["fundId"],
                  "fundName": fund ? fund.name : null,
                  "companyType": "Equity"
                }
              }
            } catch(e) {
              return {
                "id": c["id"],
                "name": c["name"],
                "type": "FUND_AND_COMPANY",
                "fundId": c["fundId"],
                "fundName": null
              }
            }
          })

          matchingCompanies = matchingCompanies.filter(c => c.type == "label" || c.fundName);
          
          this.matchingEntities = matchingFunds.concat(matchingCompanies);
        }
      }

      else if(this.searchStep == 1){
        this.filterCompanies(searchedString);
      }

      else if(this.searchStep == 2){
        if(this.inputValues[this.inputValues.length - 1].companyType == "Debt"){
          this.filterSecurities(searchedString);
        }
        else{
          this.filterValuationDates(searchedString)
        }
      }
      else if(this.searchStep == 3){
        if(this.inputValues[this.inputValues.length - 1].type == "SECURITY"){
          this.filterValuationDatesForSecurities(searchedString);
        }
        else{
          this.filterValuationDateVersions(searchedString);
        }
      }
      else if(this.searchStep == 4){
        if(this.inputValues[this.inputValues.length - 1].type == "DEBT_VALUATION_DATE"){
          this.filterValuationDatesForSecurityVersions(searchedString);
        }
      }
    })
  }

  filterValuationDateVersions(searchedString) {
    const versionNames = this.versionsInValuationDate.filter(versions => {
      return searchedString.length == 0 || (versions.version.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
    })
    
    if(!this.showSavedEntities) {
      this.matchingEntities = [{
        "type": "label",
        "name": "Versions"
      }]

      versionNames.forEach(ele => {
        this.matchingEntities.push({
          "id": ele["id"],
          "name": ele["version"],
          "type": "VERSION",
          "companyType": "Equity"
        })
      })
    }
  }

  filterValuationDatesForSecurityVersions(searchedString) {
    const versionNames = this.allVersions[this.debtValuationDates].filter(versions => {
      return searchedString.length == 0 || (versions['versionName'].toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
    })
    if(!this.showSavedEntities) {
      this.matchingEntities = [{
        "type": "label",
        "name": "Versions"
      }]
      versionNames.forEach(ele => {
        this.matchingEntities.push({
          "id": ele["id"],
          "name": ele["versionName"],
          "type": "DEBT_VERSION",
          "companyType": "Debt"
        })
      })
    }
  }

  filterValuationDates(searchedString) {    
    if(!this.companyValuationDates[this.inputValues[1].id]) {
      this.companyValuationDates[this.inputValues[1].id] = [];
    }

    console.log("searching val dates", this.companyValuationDates[this.inputValues[1].id])

    let valuationDates = this.companyValuationDates[this.inputValues[1].id].filter(valDate => {
      let options = this.datePipe.transform(valDate.valuationDate, "mediumDate").toLocaleLowerCase();
      return searchedString.length == 0 || options.includes(searchedString.toLocaleLowerCase());
    })

    if(!valuationDates || valuationDates.length == 0) {
      valuationDates = this.companyValuationDates[this.inputValues[1].id]
    }

    if(!this.showSavedEntities) {
      this.matchingEntities = [{
        "type": "label",
        "name": "Valuation Dates"
      }]

      valuationDates.forEach(ele => {
        this.matchingEntities.push({
          "id": ele["id"],
          "name": this.datePipe.transform(ele["valuationDate"], "mediumDate"),
          "date": ele["valuationDate"],
          "type": "VALUATION_DATE",
          "companyType": "Equity"
        })
      })    
    }
  }

  filterCompanies(searchedString, all?) { 
    this.matchingEntities = [];

    let options = [];
    let debtCompanyOptions = [];
    
    if(!all) {
      options = this.allCompanies.filter(comp => comp.fundId == this.inputValues[0].id);

      debtCompanyOptions = this.allDebtCompanies.filter(comp => comp.fundCompany == this.inputValues[0].id)

    } else {
      options = this.allCompanies.filter(comp => {
        return (comp.companyName.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
      })

      debtCompanyOptions = this.allDebtCompanies.filter(comp => {
        return (comp.companyName.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
      })
    }
    

    if(!all && searchedString.length > 0) {
      options = options.filter(comp => {
        return (comp.fundId == this.inputValues[0].id && comp.companyName.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
      })

      debtCompanyOptions = debtCompanyOptions.filter(comp => {
        return (comp.fundCompany == this.inputValues[0].id && comp.companyName.toLocaleLowerCase().includes(searchedString.toLocaleLowerCase()));
      })
    }

    const comps = [];

    comps.push({
      "id": "label",
      "type": "label",
      "name": this.translateService.getLabel("companies")
    });
    
    options.forEach(ele => {
      comps.push({
        "id": ele["companyId"],
        "name": ele["companyName"],
        "fundId": ele["fundId"],
        "type": "COMPANY",
        "companyType": "Equity"
      })
    })

    debtCompanyOptions.forEach(item => {
      comps.push({
        "id": item["companyId"],
        "name": item["companyName"],
        "fundId": item["fundCompany"],
        "type": "COMPANY",
        "companyType": "Debt",
        "issuerCompanyId": item["id"]
      })
    })
    
    this.matchingEntities = comps;
  }

  filterSecurities(expression){
    
    const securities = this.companySecurities[this.inputValues[1].issuerCompanyId].filter(item => {
      return expression.length == 0 || (item.securityName.toLocaleLowerCase().includes(expression.toLocaleLowerCase()));
    })

    if(!this.showSavedEntities) {
      this.matchingEntities = [{
        "type": "label",
        "name": "Securities"
      }]

      securities.forEach(item => {
        this.matchingEntities.push({
          "id": item["companyId"],
          "name": item["securityName"],
          "valuationId": item["valuationId"],
          "issuerCompanyid": item["issuerCompanyid"],
          "type": "SECURITY",
          "companyType": "Debt",
        })
      });
    }

  }

  filterValuationDatesForSecurities(expression){

    let valDates = this.securityValDate.filter(item => {
      let options = this.datePipe.transform(item.valuationDate, "mediumDate").toLocaleLowerCase();
      return expression.length == 0 || options.includes(expression.toLocaleLowerCase());
    })

    if(!valDates || valDates.length == 0){
      valDates = this.securityValDate
    }

    if(!this.showSavedEntities) {
      this.matchingEntities = [{
        "type": "label",
        "name": "Valuation Dates"
      }]

      valDates.forEach(item => {
        this.matchingEntities.push({
          "id": item.id,
          "name": this.datePipe.transform(item.valuationDate, "mediumDate"),
          "valuationDate": item.valuationDate,
          "investmentDate": item.investmentDate,
          "companyType": "Debt",
          "type": "DEBT_VALUATION_DATE"
        })
      })
    }
  }

  remove(value): void {
    const index = this.inputValues.indexOf(value);

    console.log("input values", this.inputValues)

    if (index >= 0) {
      this.inputValues.splice(index, this.inputValues.length);
      this.searchStep = index;
    }

    this.matchingEntities = [];

    if(this.inputValues.length == 0) { 
      this.matchingEntities = this.savedEntities;
      this.showSavedEntities = true;

    } else if(this.inputValues.length == 1) {
      this.dataFetchInProgress = true;

      setTimeout(() => {
        this.dataFetchInProgress = false;

        setTimeout(() => {
          this.filterCompanies("");
          this.inputSearchElement.nativeElement.focus();
        });
      });
    } else if(this.inputValues.length == 2) {
      this.dataFetchInProgress = true;

      setTimeout(() => {
        this.dataFetchInProgress = false;

        setTimeout(() => {
          if(this.inputValues[this.inputValues.length - 1].companyType == "Debt"){
            this.filterSecurities("");
          }
          else{
            this.filterValuationDates("");
          }
          this.inputSearchElement.nativeElement.focus();
        });
      });

    } else if(this.inputValues.length == 3) {
      this.dataFetchInProgress = true;

      setTimeout(() => {
        this.dataFetchInProgress = false;

        setTimeout(() => {
          if(this.inputValues[this.inputValues.length - 1].type == "SECURITY"){
            this.filterValuationDatesForSecurities("");
          }
          else{
            this.filterValuationDateVersions("");
          }
          this.inputSearchElement.nativeElement.focus();
        });
      });
    } else if(this.inputValues.length == 4) {
      this.dataFetchInProgress = true;
      setTimeout(() => {
        this.dataFetchInProgress = false;
        setTimeout(() => {
          if(this.inputValues[this.inputValues.length - 1].type == "DEBT_VALUATION_DATE"){
            this.filterValuationDatesForSecurityVersions("");
          }
          this.inputSearchElement.nativeElement.focus();
        });
      });
    }
  }

  searchCompanies(fundId, expression){

    if(expression && expression.length){
      const user = this.us.getSelectedUserDetails();

      this.ds.getMatchingCompaniesForSearch(user.id, expression).subscribe(res => {
        
        if(this.showSavedEntities || this.inputValues.length == 1) {
          return;
        }

        const companies = res.body["response"]
        let options = companies;

        if(fundId){
          options = companies.filter(comp => {
            return expression.length == 0 || (comp.fundId == fundId && comp.companyName.toLocaleLowerCase().includes(expression.toLocaleLowerCase()));
          })
        }
        
        let comp = [];

        if(!this.inputValues || this.inputValues.length == 0){

          if(options && options.length > 0) {
            this.matchingEntities.push({
              "id": "label",
              "type": "label",
              "name": "Companies"
            });
          }

          options.forEach(ele => {
            comp.push({
              "id": ele["companyId"],
              "name": ele["companyName"],
              "type": "FUND_AND_COMPANY",
              "fundId": ele["fundId"],
              "fundName": ele["fundName"]
            })
          })
        }
        else{
          this.matchingEntities.push({
            "id": "label",
            "type": "label",
            "name": "Companies"
          });
          
          options.forEach(ele => {
            comp.push({
              "id": ele["companyId"],
              "name": ele["companyName"],
              "type": "COMPANY"
            })
          })

        }

        const ids = comp.map(o => o.id)
        const uniqueCompanies = comp.filter(({id}, index) => !ids.includes(id, index + 1))
        this.matchingEntities = this.matchingEntities.concat(uniqueCompanies);

        const compIds = this.matchingEntities.map(o => o.id)
        this.matchingEntities = this.matchingEntities.filter(({id}, index) => !compIds.includes(id, index + 1))

      })
    }
  
  }

  async addSelectedItemToChips(option){

    const user = this.us.getSelectedUserDetails();
    if (this.searchStep == 0) {
      
      if(option.type != "label"){
        if(option.type == "FUND_AND_COMPANY"){

          this.dataFetchInProgress = true;

          this.inputValues.push({
            "id": option.fundId,
            "name": option.fundName,
            "type": "FUND"
          });

          if(option.companyType == "Debt"){

            const reqBody = {
              "fundId": this.inputValues[0].id,
              "orgId": user.organization.id,
              "userId": user.id,
              "issuerCompanyId": [option.issuerCompanyId]
            }
    
            this.ds.getSecuritiesForDebtCompanies(reqBody).subscribe(res => {
              this.dataFetchInProgress = false;
    
              this.companySecurities = res.body["response"];
    
              this.matchingEntities = [{
                "type": "label",
                "name": "Securities"
              }]
    
              let issuerId = option.issuerCompanyId;

              this.companySecurities[issuerId].forEach(element => {
                this.matchingEntities.push({
                  "id": element.companyId,
                  "name": element["securityName"],
                  "valuationId": element["valuationId"],
                  "issuerCompanyid": option.issuerCompanyId,
                  "type": "SECURITY",
                  "companyType": "Debt",
    
                })
              });
    
              setTimeout(() => {
                this.matchingEntities = cloneDeep(this.matchingEntities);
                this.inputSearchElement.nativeElement.focus();
              });
    
            }, error => {
              this.dataFetchInProgress = false;
            })

            this.inputValues.push({
              "id": option.id,
              "name": option.name,
              "type": "COMPANY",
              "issuerCompanyId": option.issuerCompanyId,
              "companyType": option.companyType
            });
          }
          else{
            this.inputValues.push({
              "id": option.id,
              "name": option.name,
              "type": "COMPANY",
              "companyType": "Equity"
            });

            this.selectedCompanyId = option.id;

            const reqBody = {
              "companyIds": [option.id],
              "maximumRows": 50
            }

            this.ds.getLatestValuationDatesByCompanyId(reqBody).subscribe(res => {
              this.companyValuationDates = res.body["response"]   
  
              this.dataFetchInProgress = false;   
              
              this.matchingEntities = [{
                "type": "label",
                "name": "Valuation Dates"
              }]
      
              this.companyValuationDates[option.id].forEach(ele => {
                this.matchingEntities.push({
                  "id": ele["id"],
                  "name": this.datePipe.transform(ele["valuationDate"], "mediumDate"),
                  "date": ele["valuationDate"],
                  "type": "VALUATION_DATE",
                  "companyType": "Equity"
                })
              })
  
              setTimeout(() => {
                this.matchingEntities = cloneDeep(this.matchingEntities);
                this.inputSearchElement.nativeElement.focus();
              });
  
            }, error => {
              this.dataFetchInProgress = false; 
            })

          }
          this.searchStep = this.searchStep + 2;
        }
        else {
          this.inputValues.push(option);
          this.searchStep++;
          this.dataFetchInProgress = true;

          setTimeout(() => {
            this.dataFetchInProgress = false;

            setTimeout(() => {
              this.filterCompanies("");
              this.inputSearchElement.nativeElement.focus();
            });
          });
        }
      }
      
    }
    else if(this.searchStep == 1){
      
      console.log("fetching val dates. dataFetchInProgress");

      this.dataFetchInProgress = true;

      if(option.companyType == "Debt"){
        const reqBody = {
          "fundId": this.inputValues[0].id,
          "orgId": user.organization.id,
          "userId": user.id,
          "issuerCompanyId": [option.issuerCompanyId]
        }

        this.ds.getSecuritiesForDebtCompanies(reqBody).subscribe(res => {
          this.dataFetchInProgress = false;

          this.companySecurities = res.body["response"];

          this.matchingEntities = [{
            "type": "label",
            "name": "Securities"
          }]

          let issuerId = option.issuerCompanyId

          this.companySecurities[issuerId].forEach(element => {
            this.matchingEntities.push({
              "id": element.companyId,
              "name": element["securityName"],
              "valuationId": element["valuationId"],
              "issuerCompanyid": option.issuerCompanyId,
              "type": "SECURITY",
              "companyType": "Debt",

            })
          });

          console.log("matchingEntities", this.matchingEntities);

          setTimeout(() => {
            this.matchingEntities = cloneDeep(this.matchingEntities);
            this.inputSearchElement.nativeElement.focus();
          });

        }, error => {
          this.dataFetchInProgress = false;
        })

        if(option.type != "label"){
          this.inputValues.push({
            "id": option.id,
            "name": option.name,
            "type": "COMPANY",
            "issuerCompanyId": option.issuerCompanyId,
            "companyType": option.companyType
          });

          this.searchStep++;
        }

      }
      else{
        const reqBody = {
          "companyIds": [option.id],
          "maximumRows": 50
        }

        this.selectedCompanyId = option.id;

        this.ds.getLatestValuationDatesByCompanyId(reqBody).subscribe(res => {
  
          this.dataFetchInProgress = false;
          this.companyValuationDates = res.body["response"]; 
          
          this.matchingEntities = [{
            "type": "label",
            "name": "Valuation Dates"
          }]
  
          this.companyValuationDates[option.id].forEach(ele => {
            this.matchingEntities.push({
              "id": ele["id"],
              "name": this.datePipe.transform(ele["valuationDate"], "mediumDate"),
              "date": ele["valuationDate"],
              "type": "VALUATION_DATE",
              "companyType": "Equity"
            })
          })
  
          setTimeout(() => {
            this.matchingEntities = cloneDeep(this.matchingEntities);
            this.inputSearchElement.nativeElement.focus();
          });
          console.log("fetched val dates. matchingEntities", this.matchingEntities);
  
        }, error => {
          this.dataFetchInProgress = false;
        })

        if(option.type != "label"){
          this.inputValues.push({
            "id": option.id,
            "name": option.name,
            "type": "COMPANY",
            "companyType": "Equity"
          });

          this.searchStep++;
        }
      }

    }
    else if(this.searchStep == 2){

      this.dataFetchInProgress = true;

      if(option.companyType == "Debt"){

        const reqBody = {
          "companyId": [option.id],
          "orgId": user.organization.id,
          "userId": user.id,
          "fundId": this.inputValues[0].id
        }

        this.ds.getValuationDatesBySecurities(reqBody).subscribe(res => {

          this.dataFetchInProgress = false;

          const valuationDates = res.body["response"];

          this.securityValDate = valuationDates[option.id].sort((a, b) => {
            return new Date(b.valuationDate).valueOf() - new Date(a.valuationDate).valueOf()
          });

          this.matchingEntities = [{
            "type": "label",
            "name": "Valuation Dates"
          }]

          this.securityValDate.forEach(item => {
            this.matchingEntities.push({
              "id": item.id,
              "name": this.datePipe.transform(item.valuationDate, "mediumDate"),
              "valuationDate": item.valuationDate,
              "investmentDate": item.investmentDate,
              "companyType": "Debt",
              "type": "DEBT_VALUATION_DATE"
            })
          });

          setTimeout(() => {
            this.matchingEntities = cloneDeep(this.matchingEntities);
            this.inputSearchElement.nativeElement.focus();
          });

        }, error => {
          this.dataFetchInProgress = false;
        })

        if(option.type != "label"){
          this.inputValues.push({
            "id": option.id,
            "name": option.name,
            "type": "SECURITY",
            "valuationId": option.valuationId,
            "companyType": option.companyType
          });
          this.searchStep++;
        }

      }
      else {

        this.ds.getVersionsInValuationDates(this.selectedCompanyId, option.date).subscribe(res => {
  
          // allVersionsInValuationDate may include business unit versions as well
          this.allVersionsInValuationDate = res.body["response"];
  
          this.versionsInValuationDate = this.allVersionsInValuationDate.filter(item => item.isBusinessUnit === false)
         
          this.matchingEntities = [{
            "type": "label",
            "name": "Versions"
          }]
  
          this.versionsInValuationDate.forEach(ele => {
            this.matchingEntities.push({
              "id": ele["id"],
              "name": ele["version"],
              "type": "VERSION",
              "companyType": "Equity"
            })
          })
  
          this.dataFetchInProgress = false;
  
          setTimeout(() => {
            this.inputSearchElement.nativeElement.focus();  
          });
        }, error => {
          this.dataFetchInProgress = false;
        })
  
        if(option.type != "label"){
          this.inputValues.push({
            "id": option.id,
            "name": option.name,
            "date": option.date,
            "type": "VALUATION_DATE",
            "companyType": "Equity"
          });
          this.searchStep++;
        }
      }

  
    }
    else if(this.searchStep == 3 && option.companyType == "Debt"){
      this.dataFetchInProgress = true;
      if(option.companyType == "Debt") {
        this.debtValuationDates = option.valuationDate;
        let deptCompanyId = this.inputValues[2].id;
        this.ds.getDebtFormsVersionsForAllValDates(deptCompanyId, [option.valuationDate]).subscribe(res => {
          this.dataFetchInProgress = false;
          // allVersionsInValuationDate may include business unit versions as well
          this.allVersions = res.body["response"][0];
          this.matchingEntities = [{
            "type": "label",
            "name": "Versions"
          }]
          this.allVersions[this.debtValuationDates].forEach(entry=> {
            this.matchingEntities.push({
              "id": entry["id"],
              "name": entry["versionName"],
              "type": "DEBT_VERSION",
              "companyType": "Debt"
            })
          })
          this.dataFetchInProgress = false;
          setTimeout(() => {
            this.inputSearchElement.nativeElement.focus();
          });
        }, error => {
          this.dataFetchInProgress = false;
        })
        if(option.type != "label"){
          this.inputValues.push({
            "id": option.id,
            "name": option.name,
            "date": option.date,
            "type": "DEBT_VALUATION_DATE",
            "companyType": "Debt"
          });
          this.searchStep++;
      }
    }
    }
    else if((this.searchStep == 3 && option.companyType == "Equity") || (this.searchStep == 4 && option.companyType == "Debt")){

      if(option.type != "label"){
        this.inputValues.push(option);
        this.searchStep++;
      }
      this.navigate();
    
    }
    
    setTimeout(() => {
      this.searchString = "";
      this.inputSearchElement.nativeElement.value = "";
    })
  }

  clickOnSavedOption(option) {
    if(option[option.length - 1].type == "VALUATION_DATE" || option[option.length - 1].type == "VERSION"){
      this.ds.getVersionsInValuationDates(option[1].id, option[2].date).subscribe(res => {
        this.allVersionsInValuationDate = res.body["response"];
        this.versionsInValuationDate = this.allVersionsInValuationDate.filter(item => item.isBusinessUnit === false)

        let selectedVersion = option[option.length - 1];
        let consolSummary = this.allVersionsInValuationDate.some(ele => ele.isBusinessUnit === true && ele.consolFormId == selectedVersion.id);

        this.navigateToSearchedPage(option, null, consolSummary);
      })
    }
    else{
      this.navigateToSearchedPage(option);
    }
  }

  async navigate() {

    // Add selected company into saved list at top position
 
    let alreadySearched = false;
    
    this.savedEntities.forEach(element => {

      if(element[element.length - 1].id === this.inputValues[this.inputValues.length - 1].id){
        alreadySearched = true;
      }
      
    })

    if(this.inputValues[this.inputValues.length - 1].type == "VERSION" || this.inputValues[this.inputValues.length - 1].type ==  "VALUATION_DATE"){

      let consolSummary = this.allVersionsInValuationDate.some(ele => ele.isBusinessUnit === true && ele.consolFormId == this.versionsInValuationDate[0].id);

      try{
        const formAPI = await this.ds.getformDetails(this.inputValues[this.inputValues.length - 1].id).toPromise();
        const form = formAPI.body["response"];

        let details = JSON.parse(form.details)

        if (form && details["status"] != "Initiated") {
          this.saveRecentlySearchedData(alreadySearched, form, consolSummary);
        }
        else{
          const dialogue = this.utilService.showMessage(this.translateService.getLabel("info_valuation"), this.translateService.getLabel("ok"))

          this.inputValues = [];
          dialogue.afterClosed().subscribe(status => {
            this.showSavedEntities = true;
            this.matchingEntities = this.savedEntities;
            this.searchStep = 0;
          })
        }
      }
      catch(error){
        const dialogue = this.utilService.showMessage(this.translateService.getLabel("info_valuation"), this.translateService.getLabel("ok"))

        this.inputValues = [];
        dialogue.afterClosed().subscribe(status => {
          this.showSavedEntities = true;
          this.matchingEntities = this.savedEntities;
          this.searchStep = 0;
        })
      }
    }
    else {
      this.saveRecentlySearchedData(alreadySearched)
    }

  }

  saveRecentlySearchedData(alreadySearched, formVersion?, consolSummary?){
    if(!alreadySearched) {
      this.savedEntities.unshift(this.inputValues);
    }
    
    if(this.savedEntities.length > 5) {
      // Save only latest 5 searched companies
      this.savedEntities.splice(this.savedEntities.length - 1, 1);
    }

    const user = this.us.getSelectedUserDetails();
    this.ds.saveWidgetDataToDB("LAST_SEARCHED_COMPANIES", this.savedEntities, user.id,).subscribe(res => {
      this.navigateToSearchedPage(this.inputValues, formVersion, consolSummary);
    }, err => {
      // location.reload();
    })
  }

  async navigateToSearchedPage(selectedEntityParameters, formVersion?, consolSummary?) {
    this.searchStep = 0;
    const naviagtionObject = selectedEntityParameters[selectedEntityParameters.length - 1];

    switch(naviagtionObject.type){
      case "VERSION" : 
        if(formVersion){
          if(consolSummary){
            this.utilService.navigateToConsolSummary(selectedEntityParameters[1].id, selectedEntityParameters[0].id, naviagtionObject.id);
          }
          else{
            this.utilService.navigateToValuationSummary(formVersion, selectedEntityParameters[0].name, selectedEntityParameters[1].name, naviagtionObject.name, null, "SEARCH");
          }
        }
        else{
          const formAPI = await this.ds.getformDetails(naviagtionObject.id).toPromise();
          const form = formAPI.body["response"];
          if(consolSummary){
            this.utilService.navigateToConsolSummary(selectedEntityParameters[1].id, selectedEntityParameters[0].id, naviagtionObject.id);
          }
          else{
            this.utilService.navigateToValuationSummary(form, selectedEntityParameters[0].name, selectedEntityParameters[1].name, naviagtionObject.name, null, "SEARCH");      
          }
        }
      break;

      case "DEBT_VERSION" :
        this.utilService.navigateToDebtValuationSummary(naviagtionObject.id);
      break;

      case "VALUATION_DATE" :
        const formAPI2 = await this.ds.getformDetails(naviagtionObject.id).toPromise();
        const form2 = formAPI2.body["response"];
        const valDate = this.versionsInValuationDate.find(v => v.frozen)
        if(consolSummary){
          this.utilService.navigateToConsolSummary(selectedEntityParameters[1].id, selectedEntityParameters[0].id, naviagtionObject.id);
        }
        else{
          this.utilService.navigateToValuationSummary(form2, selectedEntityParameters[0].name, selectedEntityParameters[1].name, valDate.version, null, "SEARCH");
        }
      break;

      case "DEBT_VALUATION_DATE" :
        this.utilService.navigateToDebtValuationSummary(naviagtionObject.id);
      break;

      case "SECURITY" :
        this.utilService.navigateToDebtInvestmentSummary(naviagtionObject.valuationId, naviagtionObject.id, selectedEntityParameters[0].id)
      break;

      case "COMPANY" :
        if(naviagtionObject.companyType == "Debt"){
          this.utilService.navigateToDebtConsolidatedSummary(naviagtionObject.issuerCompanyId, naviagtionObject.id, selectedEntityParameters[0].id)
        }
        else{
          this.utilService.navigateToInvestmentSummary(naviagtionObject.id, selectedEntityParameters[0].id);
        }
      break;

      case "FUND" :
        this.navigateToPortfolioSummary(naviagtionObject.id);
      break;
    }

    this.inputValues = [];
    this.matchingEntities = this.savedEntities;
    this.showSavedEntities = true;
  }

  navigateToPortfolioSummary(fundId){
    this.portfolioService.selectedFundId = null;

    this.portfolioService.summary[fundId] = null;

    this.portfolioService.loading = true;

    this.router.navigateByUrl('/portfolio/' + fundId);
    setTimeout(() => {
      location.reload();
    })
  }
}
