<nav-menu-header></nav-menu-header>

<div class="main">
  <div class="banner-container">
    <img [src]="loaderService.getImage('login-image.png')" class="banner-image1" alt="">
    <div class="text-container">
      <div class="get-form">
        <img [src]="loaderService.getImage('73_Strings_logo_blueUE.png')" class="company-logo" alt="">
        <div class="welcome-label">Welcome To</div>
        <div class="product-name">Qubit X</div>
        <div class="eurazeo-group-label">
            Eurazeo Group employee?
        </div>
        <div style="padding: 4vh 0;">
            <img [src]="loaderService.getImage('eurazeo-logo.png')" width="284px" height="54px">
        </div>
        <button mat-raised-button class="login-button"
        (click)="loginClick()" >
        Use: Eurazeo Group Email Address
        </button>

        <div class="forgot-password-text" style="padding-bottom: 1vw; font-size: 14px">
            <a href="javascript:void(0);" [routerLink]="'/auth'">Don't have Eurazeo email?</a>
        </div>
        
        <div class="forgot-password-text">
          <a target="_blank" href="../../assets/static/73stringsPrivacyPolicy.htm">Privacy Policy</a>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a target="_blank" href="../../assets/static/73StringsSupport.htm">Contact Us</a>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a target="_blank" href="../../assets/static/73StringsTermsOfUse.htm">Terms of Use</a>
          <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a target="_blank" href="../../assets/static/73stringsResponsibleDisclosurePolicy.htm">Responsible Disclosure Policy</a>
        </div>
        <div class="forgot-password-text">
          <a href="javascript:void(0);" [routerLink]="'/status'">Application Status</a>
        </div>

        <div class="copyright-text">
          <label>Copyright © * 73 Strings. All rights reserved.</label>
        </div>
      </div>

    </div>    
  </div>
</div>

<ng-template #signUpForm let-signUpFormModal>
  <div class="modal-header">
    <div class="modal-title">
      <label class="forgot-password-label">Enter Registered Email ID</label>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close"
      (click)="signUpFormModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <mat-form-field class="get-form-id-input email-textbox" style="margin-top: 0">
        <input matInput [(ngModel)]="userName">
        <mat-placeholder>
          Email
        </mat-placeholder>
      </mat-form-field>
  
      <button style="margin-top: 15px;"
      mat-raised-button class="send-email-button"
      (click)="forgotPassword()">
      Send Email
      </button>
  </div>
</ng-template>