import { AttrMap } from './attribute-interfaces';

const PROJ_ATTRIBUTES: AttrMap[] = [
  {
    qxKey: 'revenue',
    qxLabel: 'Revenue',
    gxKey: 'Revenue',
    gxLabel: 'Revenue',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4000
  },
  {
    qxKey: 'costOfGoodsSold',
    qxLabel: 'Cost of Goods Sold',
    gxKey: 'Cost_of_Goods_Sold',
    gxLabel: 'Cost of Goods Sold',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4001
  },
  {
    qxKey: 'grossProfit',
    qxLabel: 'Gross Profit',
    gxKey: 'Gross_Profit',
    gxLabel: 'Gross Profit',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4002
  },
  {
    qxKey: 'marketingExpenses',
    qxLabel: 'Marketing Expenses',
    gxKey: 'Marketing_Expenses',
    gxLabel: 'Marketing Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4003
  },
  {
    qxKey: 'employeeBenefit',
    qxLabel: 'Employee Benefit',
    gxKey: 'Employee_Benefit',
    gxLabel: 'Employee Benefit',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4004
  },
  {
    qxKey: 'sellingGeneralAndAdmin',
    qxLabel: 'Selling, General & Admin',
    gxKey: 'Selling_General_And_Admin',
    gxLabel: 'Selling, General & Admin',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4005
  },
  {
    qxKey: 'researchAndDevelopment',
    qxLabel: 'Research & Development',
    gxKey: 'Research_And_Development',
    gxLabel: 'Research & Development',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4006
  },
  {
    qxKey: 'otherExpenses',
    qxLabel: 'Other Expenses',
    gxKey: 'Other_Expenses',
    gxLabel: 'Other Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4007
  },
  {
    qxKey: 'totalExpenses',
    qxLabel: 'Total Operating Expenses',
    gxKey: 'Total_Operating_Expenses',
    gxLabel: 'Total Operating Expenses',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4008,
  },
  {
    qxKey: 'ebitda',
    qxLabel: 'EBITDA',
    gxKey: 'EBITDA',
    gxLabel: 'EBITDA',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4009
  },
  {
    qxKey: 'depreciationAndAmortization',
    qxLabel: 'Depreciation & Amortization',
    gxKey: 'Depreciation_And_Amortization',
    gxLabel: 'Depreciation & Amortization',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4010
  },
  {
    qxKey: 'interestPayment',
    qxLabel: 'Interest Payment',
    gxKey: 'Interest_Expense',
    gxLabel: 'Interest Expense',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4011,
  },
  {
    qxKey: 'totalTaxableIncome',
    qxLabel: 'Total Taxable Income',
    gxKey: 'Earnings_Before_Taxes',
    gxLabel: 'Earnings Before Taxes',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4012
  },
  {
    qxKey: 'taxPayment',
    qxLabel: 'Tax Payment',
    gxKey: 'Taxes',
    gxLabel: 'Taxes',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4013
  },
  {
    qxKey: 'netProfit',
    qxLabel: 'Net Profit',
    gxKey: 'Net_Income',
    gxLabel: 'Net Income',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4014,
  },
  {
    qxKey: 'grossProfitMargin',
    qxLabel: 'Gross Profit Margin',
    gxKey: 'Gross_Profit_Margin_Percentage',
    gxLabel: 'Gross Profit Margin %',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4015,
  },
  {
    qxKey: 'principalRepayment',
    qxLabel: 'Principal Repayment',
    gxKey: 'Principal_Repayment',
    gxLabel: 'Principal Repayment',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4016
  },
  {
    qxKey: 'equityFundraisingPlans',
    qxLabel: 'Equity Fundraising Plans',
    gxKey: 'Equity_Fundraising_Plans',
    gxLabel: 'Equity Fundraising Plans',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4017
  },
  {
    qxKey: 'capitalExpenditure',
    qxLabel: 'Capital Expenditure',
    gxKey: 'Capital_Expenditure',
    gxLabel: 'Capital Expenditure',
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4018
  },
  {
    qxKey: 'totalShareholdersEquity',
    qxLabel: `Total Shareholder's Equity`,
    gxKey: 'Total_Shareholders_Equity',
    gxLabel: `Total Shareholder's Equity`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4019
  },
  {
    qxKey: 'totalDebt',
    qxLabel: `Total Debt`,
    gxKey: 'Long_Term_Debt',
    gxLabel: `Long-Term Debt`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4020
  },
  {
    qxKey: 'accountsPayable',
    qxLabel: `Accounts Payable (Amount)`,
    gxKey: 'Accounts_Payable',
    gxLabel: `Accounts Payable`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4021
  },
  {
    qxKey: 'cash',
    qxLabel: `Cash`,
    gxKey: 'Cash',
    gxLabel: `Cash`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4022
  },
  {
    qxKey: 'cashFromOperations',
    qxLabel: `Cash Flow from Operations`,
    gxKey: 'Cash_from_Operations',
    gxLabel: `Cash from Operations`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4023
  },
  {
    qxKey: 'cashFromInvesting',
    qxLabel: `Cash Flow from Investing (excl Capex)`,
    gxKey: 'Cash_from_Investing',
    gxLabel: `Cash from Investing`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4024,
  },
  {
    qxKey: 'cashFromFinancing',
    qxLabel: `Cash Flow from Financing`,
    gxKey: 'Cash_from_Financing',
    gxLabel: `Cash from Financing`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4025,
  },
  {
    qxKey: 'accountsReceivable',
    qxLabel: `Accounts Receivable (Amount)`,
    gxKey: 'Accounts_Receivable',
    gxLabel: `Accounts Receivable`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4026
  },
  {
    qxKey: 'inventory',
    qxLabel: `Inventory`,
    gxKey: 'Inventory',
    gxLabel: `Inventory (Amount)`,
    isGxGlobalAttribute: true,
    financialStatementType: 'INCOME_STATEMENT',
    financialComponentType: null,
    gxAttributeId: '',
    qxKeyOrder: 4027
  },
];

export { PROJ_ATTRIBUTES };
