import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilService } from 'src/app/utils/util.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { cloneDeep, indexOf } from 'lodash';
import { UserManagementService } from 'src/app/services/user-management.service';
import moment from 'moment';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { TranslateService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-protfolportfolio-currency-dashboard',
  templateUrl: './protfolportfolio-currency-dashboard.component.html',
  styleUrls: ['./protfolportfolio-currency-dashboard.component.scss']
})
export class ProtfolportfolioCurrencyDashboardComponent implements OnInit {
  @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

  constructor(public portfolioService: PortFolioSummaryServiceV2, private router: Router,
    private activatedRoute: ActivatedRoute,
    private currencyExchangeService: CurrencyExchangeService,
    private utilService: UtilService, private navService: NavigationService,
    private popUpService: UtilService, private modalService: NgbModal,
    private ds: DataService, private ums: UserManagementService,
    public translateService: TranslateService) { }

  currencyExchange = [];
  currencyAverage = []
  exchange_rate = [];
  isDownloading = false;
  selectedPeriodValue = 20;
  systemValue = ""

  averageTypeEditModel = { averageType: "CUSTOM", "periodType": "DAYS", "module": "EQUITY", "rateCapturedFor": "FUND", frequency: "DAILY", period: 20 }

  averageTypeSelection = {
    "EQUITY": { averageType: "CUSTOM", "periodType": "DAYS", "module": "EQUITY", "rateCapturedFor": "FUND", frequency: "DAILY", period: 20 },
    "DEBT": { averageType: "CUSTOM", "periodType": "DAYS", "module": "DEBT", "rateCapturedFor": "FUND", frequency: "DAILY", period: 20 },
    "MONITORING": { averageType: "CUSTOM", "periodType": "DAYS", "module": "MONITORING", "rateCapturedFor": "FUND", frequency: "DAILY", period: 20 },
  }

  selectedView = "Platform View";

  platformView = 'platform';

  dashboard = {} as any;
  dashboardClone = {} as any;

  fundId;
  fundName = "";
  fileTobeUploaded;
  currencyDasboardData;

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.fundId = params.get("fundId");

      this.init(this.fundId);

      this.fundName = await this.portfolioService.getSelectedFundName();

      this.portfolioService.initPortfolioSummaryWidgetdata();
    })

    this.ds.currencyDashboardConfig(this.fundId).subscribe((res: any) => {
      if (res.body?.response.value == 'Platform') {
        this.selectedView = 'Platform View';
        this.platformView = 'platform';
      } else if (res.body?.response.value == 'Oanda') {
        this.selectedView = 'Oanda View';
        this.platformView = 'oanda';
      } else {
        this.selectedView = 'Clients View';
        this.platformView = 'client_excel';
      }
    }, (error: any) => {
      if (error.error.errorCode == 'CDCE003 : The configuration data for the specified key could not be found.') {
        this.updateCurrencyAverage(this.selectedView)
      }
    }
    )

    this.ds.currencyRateCaptured().subscribe((res: any) => {
      this.systemValue = res.body?.response.value;
    }, (error: any) => {
      console.log(error.error.errorCode, "erorr");

      if(error.error.errorCode == 'CDCE003 : The configuration data for the specified key could not be found.'){
      this.systemValue = 'System'
      }
    });
  }

  init(fundId) {
    this.portfolioService.selectedFundId = fundId;

    this.utilService.showLoadingPopup();

    this.ds.getCurrencyDashboard(fundId).subscribe(result => {
      this.dashboard = result.body["response"];
      let filteredArray = this.filterUniqueTranscations(this.dashboard[this.activeTab]);
      this.dashboard[this.activeTab] = cloneDeep(filteredArray)
      this.dashboardClone = cloneDeep(this.dashboard);

      console.log("CurrencyDashboard result", this.dashboard.equity);
      this.prepareData();

      this.utilService.closeAllPopups();

      const isInProgress = this.isUpdateInProgress(this.dashboard["equity"]) || this.isUpdateInProgress(this.dashboard["debt"]) || this.isUpdateInProgress(this.dashboard["monitoring"])
      if (isInProgress) {

        const interval = setInterval(() => {

          this.ds.getCurrencyDashboard(fundId).subscribe(result => {
            this.dashboard = result.body["response"];
            let filteredArray = this.filterUniqueTranscations(this.dashboard[this.activeTab]);
            this.dashboard[this.activeTab] = cloneDeep(filteredArray)
            this.dashboardClone = cloneDeep(this.dashboard);

            const isInProgress = this.isUpdateInProgress(this.dashboard["equity"]) || this.isUpdateInProgress(this.dashboard["debt"]) || this.isUpdateInProgress(this.dashboard["monitoring"])

            if (!isInProgress) {
              clearInterval(interval);
            }

            this.prepareData();
          })
        }, 2000);
      }
    }, error => {
      console.log("ERROR: Failed to fetch currency dashboard data", error)
      this.utilService.closeAllPopups();
    })

    this.initAverageType();
  }

  initAverageType() {
    this.ds.getCurrencyDashboardAverageType(this.fundId).subscribe(res => {
      const averageTypeSelection = res.body["response"];

      if (averageTypeSelection.EQUITY)
        this.averageTypeSelection.EQUITY = averageTypeSelection.EQUITY;

      if (averageTypeSelection.DEBT)
        this.averageTypeSelection.DEBT = averageTypeSelection.DEBT;

      if (averageTypeSelection.MONITORING)
        this.averageTypeSelection.MONITORING = averageTypeSelection.MONITORING;
    })
  }

  updateAverageType() {
    const module = this.activeTab.toUpperCase();

    const user = this.ums.getSelectedUserDetails();

    const averageTypeSelection: any = cloneDeep(this.averageTypeEditModel);
    averageTypeSelection.rateCapturedForId = this.fundId;
    averageTypeSelection.createdBy = user.id

    if (averageTypeSelection.periodType != "SPOT") {
      averageTypeSelection.averageType = "CUSTOM"
    } else {
      averageTypeSelection.averageType = "SPOT"
    }

    delete averageTypeSelection.new; // Remove un wanted keys

    this.ds.updateAverageType(averageTypeSelection).subscribe(res => {
      this.averageTypeSelection[module] = res.body["response"];
      location.reload();
    }, error => {
      this.utilService.showMessage(this.translateService.getLabel("err_save_average_info"), this.translateService.getLabel("ok"), true);
    })
  }

  isUpdateInProgress(data) {
    let inProgress = false;

    if (data) {
      data.forEach(cur => {
        inProgress = inProgress || cur.updating
      });
    }

    return inProgress;
  }

  getViewData(dashboard) {
    if (this.platformView == 'platform' || this.platformView == 'oanda') {
      return dashboard?.filter(c => {
        const isNotExcelRow = c.source.indexOf("EXCEL") != 0;
        const notEqualCurrency = c.sourceCurrency != c.targetCurrency;

        return isNotExcelRow && notEqualCurrency;
      });
    } else {
      return dashboard.filter(c => c.source.indexOf("EXCEL") >= 0 && c.sourceCurrency != c.targetCurrency);
    }
  }

  valuationNavigationLinks = [
    { title: this.translateService.getLabel('equity'), isActive: true, key: 'equity' },
    { title: this.translateService.getLabel('debt'), isActive: false, key: 'debt' },
    { title: this.translateService.getLabel('monitoring'), isActive: false, key: 'monitoring' }
  ];
  activeTab = "equity";

  switchTab(itemKey, index) {
    this.valuationNavigationLinks.forEach(link => { link.isActive = false });
    this.valuationNavigationLinks[index].isActive = true;
    this.activeTab = itemKey;
    this.selectedView = "Platform View"
    this.platformView = 'platform';
    this.ds.currencyDashboardConfig(this.fundId).subscribe((res: any) => {
      if (res.body?.response.value == 'Platform') {
        this.selectedView = 'Platform View';
        this.platformView = 'platform';
      } else if (res.body?.response.value == 'Oanda') {
        this.selectedView = 'Oanda View';
        this.platformView = 'oanda';
      } else {
        this.selectedView = 'Clients View';
        this.platformView = 'client_excel';
      }
      this.updateCurrencyAverage(this.selectedView)
    })
  }  

  navigateToPortfolio() {
    this.router.navigateByUrl('/portfolio/' + this.fundId);
  }

  viewModel() {
    this.platformView == "client_excel"
  }


  sortByDates(a, b) {
    return new Date(b.currencyDate).getTime() - new Date(a.currencyDate).getTime();
  }

  prepareData() {
    Object.keys(this.dashboard).forEach(key => {
      this.dashboard[key].sort(this.sortByDates);
    })
  }

  manualOverrideRate(data) {
    if (data.source.indexOf("EXCEL") >= 0) {
      data.source = "MANUAL/EXCEL";
    } else {
      data.source = "MANUAL";
    }
  }
  handlChange(val, index) {
    if (val) {
      let valid = (val != "" && /^[0-9]*\.?[0-9]*$/.test(val));
      if (valid) {
        index.show = false
      } else {
        index.show = true
      }
    } else {
      index.show = false
    }
  }
  updateCurrencyFxRate() {
    const filteredArray = this.filterUniqueTranscations(this.dashboard[this.activeTab]);
    this.dashboard[this.activeTab] = filteredArray
    
    this.ds.updateCurrencyDashboardFxRate(this.dashboard[this.activeTab]).subscribe(res => {
      this.dashboardClone[this.activeTab] = filteredArray;
      this.dashboard[this.activeTab] = filteredArray;

      //Force reload of portfolio summary 
      this.portfolioService.summary[this.fundId] = null;

      this.init(this.fundId);

    }, error => {
      this.utilService.showMessage(this.translateService.getLabel("err_failed_update_currency_dashboard"), this.translateService.getLabel("ok"), true)
    });
  }

  filterUniqueTranscations(data) {
    const uniqueTranscations = [];
    
    return data.filter((arr) => {
      const key = arr.currencyDate + '-' + arr.sourceCurrency+ '-' + arr.targetCurrency;
  
      if (!uniqueTranscations.hasOwnProperty(key)) {
        uniqueTranscations[key] = key;
        return true;
      }
      return false;
    });
  }


  // YYYYMMDD
  getDateFormat(date: string){
    let yyyy = date.split("-")[2];
    let mm = date.split("-")[1];
    let dd = date.split("-")[0];
    return yyyy + "-" + mm + "-" + dd
  }




  editCustomPopupModal(content) {
    this.averageTypeEditModel = cloneDeep(this.averageTypeSelection[this.activeTab.toUpperCase()]);

    this.modalService.open(content, { centered: true, windowClass: 'fx-rate' });
  }

  closeThePage() {
    this.router.navigateByUrl('/portfolio/' + this.portfolioService.selectedFundId);
  }

  clickOnFileInput(fileInput) {
    fileInput.click();
  }
  changeCurrency(event) {
    this.updateCurrencyAverage(event)
  }
  updateCurrencyAverage(platForm:any){
    let userId = JSON.parse(localStorage.getItem("73S-User"))
    let json: any = {
      fundId: this.fundId,
      source: platForm.split(" View")[0],
      module: this.activeTab.toUpperCase(),
      createdBy: userId.id,
      lastUpdatedBy: userId.id
    }
    if (platForm !== 'Clients View') {
      this.ds.updateCurrencyAverage(json).subscribe((res: any) => {
        this.init(this.fundId);
      })
    }
  }



  fileUpload(event) {
    var list: FileList = event.target.files;

    if (!list || list.length === 0) return;

    this.fileTobeUploaded = list[0];

    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length === 0 || target.files.length !== 1) {

      //This allows uploading same file again.
      event.target.value = "";

      this.utilService.showMessage(this.translateService.getLabel("info_select_filled_template"), this.translateService.getLabel("ok"));
      return;
    }

    if (!target.files[0].name.endsWith("xlsx")) {

      //This allows uploading same file again.
      event.target.value = "";

      this.utilService.showMessage(this.translateService.getLabel("info_select_xlsx_format"), this.translateService.getLabel("ok"));
      return;
    }

    this.utilService.showLoadingPopup();

    var list: FileList = event.target.files
    var file: File = list.item(0);

    //This allows uploading same file again.
    event.target.value = "";

    this.ds.parseExcel(file).subscribe(async event => {
      // console.log("Uploading. . . .", event);

      if (event.type === HttpEventType.UploadProgress) {
        // console.log("progres", Math.round(100 * event.loaded / event.total));

      } else if (event instanceof HttpResponse) {
        // console.log('File is completely uploaded!', JSON.parse(event.body.toString())["response"]);
        // this.transactionData = JSON.parse(event.body.toString())["response"];
        let apiResponseData = JSON.parse(event.body.toString())["response"];
        this.currencyDasboardData = [];
        console.log({ apiResponseData });

        this.dashboard[this.activeTab] = cloneDeep(this.dashboardClone[this.activeTab]);

        const user = this.ums.getSelectedUserDetails();

        apiResponseData.forEach((row, index) => {
          if (index > 0) {
            if (apiResponseData[0].length == 3) {
              let source = row[1].slice(0, 3);
              let target = row[1].slice(4, 7);
              const currencyExistsIndex = this.dashboard[this.activeTab].findIndex(item => item.currencyDate == row[0] && item.sourceCurrency == source && item.targetCurrency == target);
              if (currencyExistsIndex < 0) {
                let currencyData = {
                  "currencyDate": row[0],
                  "sourceCurrency": source,
                  "targetCurrency": target,
                  "fxRate": null,
                  "manualOverride": row[2],
                  "concludedRate": row[2],
                  "type": "VALUATION_DATE",
                  "source": "EXCEL",
                  "module": this.activeTab == "debt" ? "DEBT" : this.activeTab == "equity" ? "EQUITY" : "MONITORING",
                  "rateCapturedFor": "FUND",
                  "rateCapturedForId": this.portfolioService.selectedFundId,
                  "createdBy": user.id,
                  "createdDate": moment().format("YYYY-MM-DD")
                }
                this.dashboard[this.activeTab].push(currencyData);
              }
              else {
                this.dashboard[this.activeTab][currencyExistsIndex].manualOverride = row[2];
                this.dashboard[this.activeTab][currencyExistsIndex].concludedRate = row[2];

                if (this.dashboard[this.activeTab][currencyExistsIndex].source.indexOf("EXCEL") < 0) {
                  this.dashboard[this.activeTab][currencyExistsIndex].source = this.dashboard[this.activeTab][currencyExistsIndex].source + "/EXCEL";
                }
              }
            } else {
              let source = row[1];
              let target = row[2];
              const currencyExistsIndex = this.dashboard[this.activeTab].findIndex(item => item.currencyDate == row[0] && item.sourceCurrency == source && item.targetCurrency == target);
              if (currencyExistsIndex < 0) {
                let currencyData = {
                  "currencyDate": row[0],
                  "sourceCurrency": source,
                  "targetCurrency": target,
                  "fxRate": null,
                  "manualOverride": row[5],
                  "concludedRate": row[5],
                  "type": "VALUATION_DATE",
                  "source": "EXCEL",
                  "module": this.activeTab == "debt" ? "DEBT" : this.activeTab == "equity" ? "EQUITY" : "MONITORING",
                  "rateCapturedFor": "FUND",
                  "rateCapturedForId": this.portfolioService.selectedFundId,
                  "createdBy": user.id,
                  "createdDate": moment().format("YYYY-MM-DD")
                }
                this.dashboard[this.activeTab].push(currencyData);
              }
              else {
                this.dashboard[this.activeTab][currencyExistsIndex].manualOverride = row[4];
                this.dashboard[this.activeTab][currencyExistsIndex].concludedRate = row[5];

                if (this.dashboard[this.activeTab][currencyExistsIndex].source.indexOf("EXCEL") < 0) {
                  this.dashboard[this.activeTab][currencyExistsIndex].source = this.dashboard[this.activeTab][currencyExistsIndex].source + "/EXCEL";
                }
              }
            }
          }else{
            let source = row[1];
            let target = row[2];
            const currencyExistsIndex = this.dashboard[this.activeTab].findIndex(item => item.currencyDate == row[0] && item.sourceCurrency == source && item.targetCurrency == target);
            if (currencyExistsIndex < 0) {
              let currencyData = {
                "currencyDate": this.getDateFormat(row[0]),
                "sourceCurrency": source,
                "targetCurrency": target,
                "fxRate": null,
                "manualOverride": row[5],
                "concludedRate": row[5],
                "type": "VALUATION_DATE",
                "source": "EXCEL",
                "module": this.activeTab == "debt" ? "DEBT" : this.activeTab == "equity" ? "EQUITY" : "MONITORING",
                "rateCapturedFor": "FUND",
                "rateCapturedForId": this.portfolioService.selectedFundId,
                "createdBy": user.id,
                "createdDate": moment().format("YYYY-MM-DD")
              }
              this.dashboard[this.activeTab].push(currencyData);
            }
            else {
              this.dashboard[this.activeTab][currencyExistsIndex].manualOverride = row[4];
              this.dashboard[this.activeTab][currencyExistsIndex].concludedRate = row[5];

              if(this.dashboard[this.activeTab][currencyExistsIndex].source.indexOf("EXCEL") < 0) {
                this.dashboard[this.activeTab][currencyExistsIndex].source = this.dashboard[this.activeTab][currencyExistsIndex].source + "/EXCEL";
              }
            }
          }
          
        });
        this.prepareData()
        this.utilService.closeAllPopups();
      }
    }, error => {
      console.log("Financial History File Upload failed.", error)
      this.utilService.closeAllPopups();
      this.utilService.showMessage(this.translateService.getLabel("err_failed_upload_financial_file"), this.translateService.getLabel("ok"))
    })
  }

  getExcelDownLoadDebtRequestBody() {
    // if(this.activeTab = "debt"){
    //   values.push("Debt");
    // }
    // else if(this.activeTab = "equity"){
    //   values.push(this.dashboard.debt);
    // }
    // else if(this.activeTab = "monitoring"){
    //   values.push(this.dashboard.debt);
    // }
    // values.push(["", "", "", "", "", "", "", "", ""]);

    // let headers = ["Date", "Local Currency", "Reporting Currency", "Platform Fx Rate", "Manual Override", "Concluded Fx"];
    // const values = [];

    // this.dashboard.debt.forEach(comp => {
    //   if(comp['sourceCurrency'] != comp['targetCurrency']) {
    //     values.push([comp['currencyDate'], comp['sourceCurrency'], comp['targetCurrency'], comp['fxRate'], comp['manualOverride'], comp['concludedRate']]);
    //   }
    // })
    // values.push(["", "", "", "", "", "", "", "", ""]);
    const rowsForExcel = this.getViewData(this.dashboard[this.activeTab]);
    let data = {} as any;
    if (this.platformView == 'platform' || this.platformView == 'oanda') {
      data = this.getExcelRowsInPlatformFormat(rowsForExcel);
    } else {
      data = this.getExcelRowsInClientFormat(rowsForExcel);
    }
    return [{ "tabName": "Debt", "data": data }]
  }

  getExcelDownLoadEquityRequestBody() {
    const rowsForExcel = this.getViewData(this.dashboard[this.activeTab]);
    let data = {} as any;

    if (this.platformView == 'platform' || this.platformView == 'oanda') {
      data = this.getExcelRowsInPlatformFormat(rowsForExcel);
    } else {
      data = this.getExcelRowsInClientFormat(rowsForExcel);
    }

    return [{ "tabName": "Equity", "data": data }]
  }

  getExcelRowsInClientFormat(rowsForExcel) {
    let headers = ["Date", "FX", "Rate"];
    const values = [];

    rowsForExcel.forEach(comp => {
      values.push([comp['currencyDate'], comp['sourceCurrency'] + "/" + comp['targetCurrency'], comp['concludedRate']]);
    })

    return { "headers": headers, "values": values };
  }

  getExcelRowsInPlatformFormat(rowsForExcel) {
    let headers = ["Date", "Local Currency", "Reporting Currency", "Platform Fx Rate", "Manual Override", "Concluded Fx"];
    const values = [];

    rowsForExcel.forEach(comp => {
      values.push([comp['currencyDate'], comp['sourceCurrency'], comp['targetCurrency'], comp['fxRate'], comp['manualOverride'], comp['concludedRate']]);
    })

    values.push(["", "", "", "", "", "", "", "", ""]);

    return { "headers": headers, "values": values };
  }

  getExcelDownLoadMonitoringRequestBody() {
    // let headers = ["Date", "Local Currency", "Reporting Currency", "Platform Fx Rate", "Manual Override", "Concluded Fx"];
    // const values = [];

    // this.dashboard.monitoring.forEach(comp => {
    //   if(comp['sourceCurrency'] != comp['targetCurrency']) {
    //     values.push([comp['currencyDate'], comp['sourceCurrency'], comp['targetCurrency'], comp['fxRate'], comp['manualOverride'], comp['concludedRate']]);
    //   }
    // })
    // values.push(["", "", "", "", "", "", "", "", ""]);

    const rowsForExcel = this.getViewData(this.dashboard[this.activeTab]);
    let data = {} as any;

    if (this.platformView == 'platform' || this.platformView == 'oanda') {
      data = this.getExcelRowsInPlatformFormat(rowsForExcel);
    } else {
      data = this.getExcelRowsInClientFormat(rowsForExcel);
    }
    return [{ "tabName": "Monitoring", "data": data }]
  }


  manageFileDownload(fileData, type) {
    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.download = "Currency Dashboard_" + this.fundName + "_" + moment().format("yyyy-MMM-DD") + "." + type;
      this.downloadLink.nativeElement.click();
    } catch (e) {
      console.log("Failed to download file", e)
      this.popUpService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    }

    this.isDownloading = false;
  }


  downloadExcel() {
    if (this.isDownloading) return;
    let fileData;
    if (this.activeTab == 'debt') {
      fileData = this.getExcelDownLoadDebtRequestBody();
    }
    else if (this.activeTab == 'equity') {
      fileData = this.getExcelDownLoadEquityRequestBody();
    }
    else if (this.activeTab == 'monitoring') {
      fileData = this.getExcelDownLoadMonitoringRequestBody();
    }
    this.isDownloading = true;

    this.ds.downloadExcelFile(fileData).subscribe((fileData) => {
      this.manageFileDownload(fileData, "xlsx");
    }, err => {
      console.log("Failed to download file", err)
      this.popUpService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))

      this.isDownloading = false;
    });
  }
  downloadCurrencyDashbaordExcelTemplate() {
    window.open(environment.currency_dashboard_template);
  }
}
