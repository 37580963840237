<nav-menu-header headerLabel="Search"></nav-menu-header>

<div class="search-container">
  <div class="vertical-center">
    <div class="brand-name">Proton X</div>
    <div style="margin: 0px 10px; font-size: large;">Artificial Intelligence Powered Company Analytics</div>

    <div style="margin: auto; max-width: 300px;">
      <div class="row search-type-row">
        <div class="col type" (click)="selectedSearchBox = 'Name'" [class.selected-type]="selectedSearchBox == 'Name'">
          Name
        </div>
        <div class="col type" (click)="selectedSearchBox = 'Website'" [class.selected-type]="selectedSearchBox == 'Website'">
          Website
        </div>
      </div>
    </div>

    <div>
      <span class="search-box" style="padding: 5px 0px 0px 0px; font-size: 20px"> <!-- (mouseenter)="openCompanySearch()" (mouseleave)="hideCompanySearch()"> -->

        <ng-template [ngIf]="showSearchBox">

          <mat-form-field style="padding: 0; width: 90%; margin: 20px 0;">
            <input matInput type="text" [placeholder]="'Type minimum 3 letters to search'" [(ngModel)]="instaValCompanyName" [matAutocomplete]="auto" #inputBox (keydown.enter)="searchCompanyNameChange()">

            <span matSuffix class="search-icon" (click)="searchCompanyNameChange()" style="margin-right: -7px !important;">
                <i class="fa fa-search" [style.color]="searchingCompany? 'gray': 'black'"></i>
            </span>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectSearchedCompany($event, newCompanyContent)" (closed)="closeSearchBox()">
              <mat-option *ngFor="let company of searchedCompanies" [value]="company.uuid">
                <span style="font-size: small" [matTooltip]="company.domain">
                  <span class="company-source" [style.background]="company.source === '222'? 'blue': 'green'" *ngIf="company.uuid !== 'SEARCHING'"></span>
                  <span>
                    <img class="sh-comp-logo" [src]="company.logo_url" (error) ="logoSourceError($event, company)" *ngIf="company.logo_url; else defaultLogo">
                    <ng-template #defaultLogo>
                      <i class="fa fa-landmark" style="margin-left: 6px; font-size: 15px;"></i>
                    </ng-template>
                  </span>
                  <b> {{company.name | titlecase}}</b> 
                  <ng-template [ngIf]="company.region"> - <i>{{company.region | titlecase}}</i></ng-template>
                </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          
        </ng-template>

      </span>
    </div>

  <div style="width: 80%; margin-left: 10%">
    <span *ngFor="let c of suggestedCompanies">
      <div class="suggested-logo-div">
        <div style="height: 50px"><img class="suggested-logo" [matTooltip]="c.domain" [src]="c.logo" *ngIf="c.logo" (click)="openInstaValForm(c)"></div>
        <div class="name">{{c.name}}</div>
      </div>   
    </span>

    <div style="margin: 20px 10px; font-size: large;" *ngIf="!countries || countries.length == 0">
      <span>Please add the <a [routerLink]="'/profile'" style="text-decoration: underline">country preference</a> to see the personalized recommendation</span>
    </div>
    
    <div style="margin: 10px 10px; font-size: medium;">
      <span><a (click)="openSavedCompanies()" style="text-decoration: underline">Show saved companies</a></span>
    </div>
  </div>
  <div>
    <mat-form-field>
      <mat-select [(ngModel)]="ds.apiVersion">
        <mat-option value="V1">V1
        </mat-option>
        <mat-option value="V2">V2
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
