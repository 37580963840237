<app-drp-page-header
  [clients]="clients"
  [loading]="loading.dataLoad"
  [dataLoadStatus]="dataLoadStatus"
  [error]="apiErrors.dataLoad"
  [errorCount]="errorCount"
  (clientSelectionEvent)="onClientSelection($event)"
  (refreshEvent)="refreshData()"
></app-drp-page-header>

<hr class="divider" />

<app-drp-financials-general-profile-details
  [loading]="loading.financialsGD"
  [error]="apiErrors.financialsGD"
  [data]="financialGDProfileRows"
></app-drp-financials-general-profile-details>

<app-drp-attribute-details
  [loading]="loading.attributes"
  [error]="apiErrors.attributes"
  [data]="attributesRows"
></app-drp-attribute-details>

<app-drp-user-company-access-details
  [loading]="loading.userCompanies"
  [error]="apiErrors.userCompanies"
  [data]="userCompanyAccessRows"
></app-drp-user-company-access-details>

<app-drp-organization-user-details
  [loading]="loading.orgUsers"
  [error]="apiErrors.orgUsers"
  [data]="organizationUsersRows"
></app-drp-organization-user-details>
