import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { SearchDropDownComponent } from 'src/app/utils/search-drop-down/search-drop-down.component';

@Component({
  selector: 'app-historical-table',
  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoricalTableComponent implements OnInit {

  isRankDirty: { [key: number]: boolean } = {};

  isHistVerDirty: { [key: number]: boolean } = {};

  dataSource = [];
  @Input()
  set versionListItems(vli) {
    this.dataSource = [...vli];
  }

  @Input() rankOptions;

  @Input() historicalVersionOptions;

  @Input() isReadOnly: boolean;

  @Output() rankChange = new EventEmitter();

  @Output() versionChange = new EventEmitter();

  @Output() deleteHistoricalItem = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onRankChange(e, row) {
    this.rankChange.emit({
      event: e,
      row,
    });
  }

  onVersionChange(e, row) {
    this.versionChange.emit({
      event: e,
      row,
    });
  }

  onDelete(row, i) {
    this.deleteHistoricalItem.emit(row);
    this.isRankDirty[i] = undefined;
    this.isHistVerDirty[i] = undefined;
  }

  onRankOpened(e: boolean, i: number) {
    if (!e) {
      this.isRankDirty[i] = true;
    }
  }

  onVersionOpened(e: boolean, i: number) {
    if (!e) {
      this.isHistVerDirty[i] = true;
    }
  }
}
