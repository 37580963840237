<nav-menu-header [headerLabel]="newCompany.name"></nav-menu-header>

<div class="card">
  <div class="top-header">
    <span style="font-size: 20px">{{newCompany.name}}</span>
    
    <span ngbDropdown class="d-inline-block" placement="bottom-right" style="float: right;">
      <span id="toggleMenu" ngbDropdownToggle>
        <i class="fas fa-ellipsis-v ellipsis-icon"></i>
      </span>
      <div ngbDropdownMenu aria-labelledby="toggleMenu">
        <button ngbDropdownItem class="btn" (click)="showValDates = !showValDates">
          Show valuation dates
        </button>
        <button ngbDropdownItem class="btn" (click)="showUserValDates = !showUserValDates">
          Add old valuation dates
        </button>
        <button ngbDropdownItem class="btn" (click)="openCurrencyDashboardPopup(currencyDashboard)">
          Currency Dashboard
        </button>
      </div>
    </span>
  </div>

  <ng-template [ngIf]="showValDates">
    <!-- <div [chart]="waterFallChart"></div> -->

    <div style="margin: 0px">
      <table class="table table-condensed" id="container">
        <tr class="bg primary-bg">
            <!-- <th>Name</th> -->
            <th>Valuation Date</th>
            <th>Valuation Amount</th>
            <th>Security</th>
            <th></th>
        </tr>

        <ng-template ngFor [ngForOf]="selectedCompanyDates" let-comp>
          <tr [class.highlight-row]="comp.id === primaryCompanyId" [class.userAddedForm]="comp.userEntered">
            <!-- <td>{{comp.companyName}}</td> -->
            <td>{{comp.valuationDate | date: "mediumDate"}}</td>
            <td>
              <ng-template [ngIf]="comp.investment">
                {{comp.investment.currency ? comp.investment.currency : 'USD'}} 
                {{comp.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
              </ng-template>
            </td>
            <td>{{comp.security}}</td>
            <td>
              <ng-template [ngIf]="!comp.userEntered">
                <i class="far fa-edit primary-color-medium action-icon" (click)="openSAF(comp)"></i>
                <i class="fas fa-binoculars primary-color action-icon" (click)="portfolioService.openValuation(comp)" *ngIf="comp.status !== 'Initiated'"></i>
              </ng-template>
            </td>
          </tr>
        </ng-template>

        <tr>
          <td>
            Valuation Date
          </td>
          <td>
            <mat-form-field (click)="vDatePicker.open()" style="width: 80%;">
              <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="newCompany.valuationDate">
              <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
            </mat-form-field>
          </td>

          <td>
            <button mat-raised-button class="primary-bg p-button" (click)="addValuationDate()">
              Add
            </button>
          </td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </div>
  </ng-template>

  <br>

  <ng-template [ngIf]="showUserValDates">
    <div class="row" style="margin: 0px">
      <table class="table table-condensed" id="container">
        <tr class="bg primary-bg">
            <th>Valuation Date</th>
            <th>Fair Value</th>
            <th>Stake</th>
            <th>Stake Value</th>
        </tr>
        <tr>
          <td>
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" name="valuationDate"
                    [(ngModel)]="formObject.valuationDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </td>
          <td>
              <mat-form-field style="text-align: right;">
                  <input matInput type="number" name="fairValue" [(ngModel)]="formObject.fairVal" required>
              </mat-form-field>
          </td>
          <td>
              <mat-form-field style="text-align: right;">
                  <input matInput type="number" name="stake" [(ngModel)]="formObject.stake" required>
              </mat-form-field>
          </td>
          <td>
              <mat-form-field style="text-align: right;">
                  <input matInput type="number" name="stakeValue" [(ngModel)]="formObject.stakeVal" required>
              </mat-form-field>
          </td>
        </tr>
      </table>
      <div style="text-align: center; margin: auto;">
          <button mat-raised-button class="primary-bg" (click)="addToPortfolioTable()" type="submit" [disabled]="!formObject.valuationDate || !formObject.stakeVal || !formObject.fairVal || !formObject.stake">
            Save
          </button>
      </div>
    </div>
  </ng-template>

  <br>

  <div *ngIf="investmentDateInfo.formData && primaryCompanyId">
    <!-- <app-investment-page-summary [companyDescription]="investmentDateInfo.formData.GENERAL.GD_General_Details.GD_BD_BIZ_DESCRIPTION"></app-investment-page-summary> -->
  </div>
</div>

<ng-template #currencyDashboard let-currencyDashboard>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>Currency Dashboard</h6>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="currencyDashboard.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span style="font-weight: bold;">Valuation Date:</span> <span> {{investmentDateInfo.valuationDate | date: "mediumDate"}}</span><br><br>
    <div class="table table-condensed" id="container">
      <table class="table">
        <tr class="grey-bg">
          <th>Data</th>
          <th class="center-align">Input Currency</th>
          <th class="center-align">Valuation Currency</th>
          <th>Conversion</th>
        </tr>
        <tr>
          <td>Historical Financials</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr>
          <td>Projected Financials</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS.OT_Bid_Details && investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_Bidder_Name.length > 0">
          <td>Bid Details</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForBidDetails'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS.OT_Secondaries && investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_Buyer_Name.length > 0">
          <td>Secondaries</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForSecondaries'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf = "investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND && investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_NAME.length > 0">
          <td>PORI</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign"> {{ investmentDateInfo['exchangeRateForPori'] | number: "1.2-2" }} </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>