import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { catchError, concatMap, delay, map, switchMap } from 'rxjs/operators';
import { ApiResponse, DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { CompaniesInFundMap, Company, CompanyDetails } from './ca-interfaces';
import moment from 'moment';
import { DateUtilService } from 'src/app/date-util.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyListService {
  private readonly _allCompaniesInFund = new BehaviorSubject<Company[]>([]);

  readonly allCompaniesInFund$ = this._allCompaniesInFund.asObservable();

  private readonly _allComapniesInFundClone = new BehaviorSubject<Company[]>([]);

  readonly allComapniesInFundClone$ = this._allComapniesInFundClone.asObservable();

  // private allComapniesInFundClone: Company[];

  private companyNameFilterValue: string;

  user;

  constructor(
    private MappingMgmtConfigService: MappingMgmtConfigService,
    private dataService: DataService,
    private userMgmt: UserManagementService,
    private activatedRouteSnap: ActivatedRoute,
    private dateUtilSvc: DateUtilService
  ) {
    this.user = this.userMgmt.getSelectedUserDetails();
  }

  set allCompaniesInFund(val: Company[]) {
    this._allCompaniesInFund.next(val);
  }

  get allCompaniesInFund() {
    return this._allCompaniesInFund.getValue();
  }

  set allComapniesInFundClone(companies: Company[]) {
    this._allComapniesInFundClone.next(companies);
  }

  get allComapniesInFundClone() {
    return this._allComapniesInFundClone.getValue();
  }

  setCompanyNameFilterValue(searchStr: string) {
    this.companyNameFilterValue = searchStr;
  }

  setAllCompaniesInFund(val) {
    this._allCompaniesInFund.next(val);
  }

  cloneAllComapniesInFundClone(companies: Company[]) {
    this.allComapniesInFundClone = companies?.map((o) => {
      return { ...o };
    });
  }

  resetCompanies() {
    this._allCompaniesInFund.next([...this.allComapniesInFundClone]);
  }

  applyGeoSectorFilters(countries: Map<string, { name: string; countryCode: string }>, sectors: Map<string, string>): void {
    const searchStr = this.companyNameFilterValue || '';
    this.filterCompanies(searchStr, countries?.size > 0 ? countries : undefined, sectors?.size > 0 ? sectors : undefined);
  }

  filterCompanies(name: string, countries?: Map<string, { name: string; countryCode: string }>, sectors?: Map<string, string>): void {
    let hasCountry = false;
    let hasSectors = false;

    // name has value, countries have value, sectors have value - Y Y Y
    if (name && countries && countries?.size > 0 && sectors && sectors?.size > 0) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        hasCountry = (c.details as CompanyDetails)?.geography ? countries?.has(c.details['geography']['code']) : false;
        hasSectors = (c.details as CompanyDetails)?.sector ? sectors?.has((c.details as CompanyDetails).sector) : false;
        if (c.companyName?.toLowerCase().includes(name?.toLowerCase()) && hasCountry && hasSectors) {
          return true;
        } else {
          return false;
        }
      });
      return;
    }

    // name has value, countries have value, no sectors - Y Y N
    if (name && countries && countries?.size > 0 && (!sectors || sectors?.size < 1)) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        hasCountry = (c.details as CompanyDetails)?.geography ? countries?.has(c.details['geography']['code']) : false;
        if (c.companyName?.toLowerCase().includes(name?.toLowerCase()) && hasCountry) {
          return true;
        } else {
          return false;
        }
      });
      return;
    }

    // name has value,no countries, no sectors - Y N N
    if (name && (!countries || countries?.size < 1) && (!sectors || sectors?.size < 1)) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        if (c.companyName?.toLowerCase().includes(name?.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      return;
    }

    // no name, no country, no sector - N N N
    if (!name && (!countries || countries?.size < 1) && (!sectors || sectors?.size < 1)) {
      this.allCompaniesInFund = this.allComapniesInFundClone.map((o) => {
        return { ...o, details: { ...(o.details as CompanyDetails), geography: { ...(o.details as CompanyDetails)?.geography } } };
      });
    }

    // no name, country has value, sectors have value - N Y Y
    if (!name && countries?.size > 0 && sectors?.size > 0) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        hasCountry = (c.details as CompanyDetails)?.geography ? countries?.has(c.details['geography']['code']) : false;
        hasSectors = (c.details as CompanyDetails)?.sector ? sectors?.has((c.details as CompanyDetails).sector) : false;
        return hasCountry && hasSectors;
      });
      return;
    }

    // no name, country has value, no sectors - N Y N
    if (!name && countries?.size > 0 && (!sectors || sectors?.size < 1)) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        hasCountry = (c.details as CompanyDetails)?.geography ? countries?.has(c.details['geography']['code']) : false;
        return hasCountry;
      });
      return;
    }

    // no name, no country, sectors have value - N N Y
    if (!name && (!countries || countries?.size < 1) && sectors?.size > 0) {
      this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
        hasSectors = (c.details as CompanyDetails)?.sector ? sectors?.has((c.details as CompanyDetails).sector) : false;
        return hasSectors;
      });
      return;
    }

      // name has value, no country, sectors have value - Y N Y
      if (name && (!countries || countries?.size < 1) && sectors?.size > 0) {
        this.allCompaniesInFund = this.allComapniesInFundClone?.filter((c) => {
          hasSectors = (c.details as CompanyDetails)?.sector ? sectors?.has((c.details as CompanyDetails).sector) : false;
          return c.companyName?.toLowerCase().includes(name?.toLowerCase()) && hasSectors;
        });
        return;
      }
  }

  getAllCompaniesInAFund(fundId: string): Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>> {
    return this.dataService.getCompanyDetailsForCompanyMaster({
      userId: this.user?.id,
      fundIds: [fundId],
    }) as Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>>;
  }

  getAssignedCompaniesAndAllComapniesInFund(fundId) {
    const obs1$ = this.MappingMgmtConfigService.uploadDate$.pipe(
      switchMap((val: Date) => {
        console.log(val, 'Upload date... company list service');
        const uploadDate = this.dateUtilSvc.getDateYYYYMMDD(val);
        return this.dataService.getCompanyAssociations(fundId, uploadDate);
      })
    );

    const obs2$ = this.dataService.getAllCompaniesInFundWithEarliestAndLatestValuationDetails(fundId) as Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>>;
    return combineLatest([obs1$, obs2$]);
  }

  deleteCompanyAssignment(id: string) {
    return this.dataService.deleteCompanyAssociation(id);
  }

  deleteDebtCompanyAssignment(id: string) {
    return this.dataService.deleteDebtCompanyAssociation(id);
  }

  getAllDebtCompaniesInAFund(fundId) {
    return this.dataService.getAllDebtCompaniesInFund(fundId).pipe(
      map((resp) => resp.body?.response)
    )
  }

  getAssignedDebtCompaniesAndAllDebtComapniesInFund(fundId) {
    const obs1$ = this.MappingMgmtConfigService.uploadDate$.pipe(
      switchMap((val: Date) => {
        console.log(val, 'Upload date debt... company list service');
        const uploadDate = this.dateUtilSvc.getDateYYYYMMDD(val);
        return this.dataService.getDebtCompanyAssociations(fundId, uploadDate);
      })
    );

    const obs2$ = this.dataService.getAllDebtCompaniesInFund(fundId);
    return combineLatest([obs1$, obs2$]);
  }
}
