import { UtilService } from '../../../../utils/util.service';
import { UserManagementService } from '../../../../services/user-management.service';
import { DataService } from '../../../../services/data.service';
import { Component, OnInit } from "@angular/core";
import { IEditCell } from "@syncfusion/ej2-angular-grids";
import { ActivatedRoute } from "@angular/router";
import { formatDate } from "@angular/common";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-credit-rating-upload',
  templateUrl: './credit-rating-upload.component.html',
  styleUrls: ['./credit-rating-upload.component.scss']
})
export class CreditRatingComponent implements OnInit {

  currentUploadFile: any;
  public stateParams: IEditCell;
  fileUploadList: any = [];
  dateValue = new Date();
  fundId: any;
  selectedFile: any;
  downloadJson: any;
  fileName : any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private ums: UserManagementService,
    private utilService: UtilService,
    private sanitizer: DomSanitizer
  ) { }


  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.fundId = params.get("fundId");
    });
    this.getFileUpload();
  }

  gridPageSettings = { pageSizes: true, pageSize: 10 };
  gridToolbar = ["ColumnChooser"];

  onFileSelected(event) {
    this.currentUploadFile = event.target.files[0];
  }

  processFileUpload() {
    if (this.currentUploadFile && this.dateValue) {
      this.dataService.uploadCreditRatingsFile(
        this.currentUploadFile,
        this.fundId,
        formatDate(this.dateValue, "YYYY-MM-dd", "en")
      )
        .subscribe((res) => {
          console.log(res);
          this.getFileUpload();
        });
    }
  }

  clkFileBtn() {
    document.getElementById("fileUpload").click();
  }

  getFileUpload() {
    this.dataService.listCreditRatingsFileUploads(this.fundId).subscribe(
      (result) => {
        this.fileUploadList = result.body["response"];
      },
      (error) => {
        console.log("No files found", error);
      }
    );
  }

  generateDownloadJSON(data) {
    this.fileName = data.fileName;
    let theJSON = JSON.stringify(data.data, null, "\t");
    let blob = new Blob([theJSON], { type: 'text/json' });
    let url = window.URL.createObjectURL(blob);
    let uri: SafeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
    this.downloadJson = uri;
  }
}
