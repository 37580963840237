<mat-spinner style="margin: 0 auto;" [diameter]="50" *ngIf="isExitInvestmentLoading">
</mat-spinner>

<div style="background-color: white;" *ngIf="debtCompanyDetails">

    <div class="row" style="margin: 0px 33px 0px 33px">
        <div class="col-1">
            <label style="cursor: pointer;">
                <ng-template [ngIf]="debtCompanyDetails.logoURL" [ngIfElse]="defaultLogo">
                    <input style="display: none" accept="image/*" type="file" id="file" (input)="getImageURL($event)">
                    <img [src]="debtCompanyDetails.logoURL" width="100" height="100" (error)="companyDetails.logoURL = ''">
                </ng-template>
                <ng-template #defaultLogo>
                    <input style="display:none" accept="image/*" type="file" id="file" (input)="getImageURL($event)">
                    <i class="far fa-building" style="margin-left: 10px; font-size: 100px; margin-top: 10px;"></i>
                </ng-template>
            </label>
        </div>
        <div class="col-11">
            <div style="font-size: small; margin: 5px; margin-left: 15px;"> {{ debtCompanyDetails.description }} </div>
        </div>
    </div>

    <div class="col-12 row" style="margin: 10px 33px 20px 30px">
        <div class="col-1"></div>
        <div class="col-2"> <b>{{translateService.getLabel("sector")}}:</b> {{ debtCompanyDetails.sector }} </div>
        <div class="col-2">  <b>{{translateService.getLabel("website")}}:</b> {{ debtCompanyDetails.website }} </div>
        <!-- <div class="col-2"> <b> Geography:</b> {{ debtCompanyDetails.geography }} </div> -->

        <form class="col-6 row exit-investment-container" [formGroup]="exitInvestmentFormGroup">
          <div class="col-3">
            <div class="mat-label">{{translateService.getLabel("investment_status")}}</div>
            <mat-form-field class="col-12 custom-input-select">
              <mat-select formControlName="exitFormSelect" [placeholder]='translateService.getLabel("investment_status")'
                (selectionChange)="onInvestmentStatusChange($event)">
                <mat-option value="Current">
                    {{translateService.getLabel("current")}}
                </mat-option>
                <mat-option value="Exited">
                    {{translateService.getLabel("exited")}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="exitInvestmentFormGroup.get('exitFormDate')">
            <div class="mat-label">{{translateService.getLabel("exit_date")}}</div>
            <mat-form-field class="dateInputBox custom-input-select">
              <input matInput [matDatepicker]="picker" formControlName="exitFormDate" [placeholder]='translateService.getLabel("exit_date")'
                [max]="yesterday" (dateChange)="onExitDateChange($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-2" *ngIf="exitInvestmentFormGroup.get('exitFormDate')">
            <div class="mat-label">{{translateService.getLabel("percent_of_par")}}</div>
            <mat-form-field class="col-12 custom-input-select">
              <input matInput class="label form-field number-form-field" formControlName="exitFormPercentOfPar"
                [placeholder]='translateService.getLabel("percent_of_par")' type="number" step="0.5">
              <span matSuffix>%</span>
            </mat-form-field>
          </div>

          <div class="col-3" *ngIf="exitInvestmentFormGroup.get('exitFormDate')">
            <div class="mat-label">{{translateService.getLabel("notional_outstanding")}}</div>
            <mat-form-field class="col-12 custom-input-select number-form-field">
              <input matInput class="label form-field" formControlName="exitFormNotionalOS"
                [placeholder]='translateService.getLabel("notional_outstanding")' type="number" step="10.00">
            </mat-form-field>
          </div>
          <button mat-flat-button class="btn-custom-primary" (click)="onSaveClick()">{{translateService.getLabel("save")}}</button>
        </form>
    </div>
   
    <!-- <div class="row" style="margin: 0px 33px 0px 33px">
        <div class="col-1">
            <div style="margin-left: 4%;font-size: 20px; font-weight: 900"> {{ debtCompanyDetails.companyName }} </div>
        </div>
        <div class="col-11">
            <ng-template [ngIf]="tags">
                <ejs-chiplist id="chip" enableDelete="true">
                    <e-chips>
                        <e-chip 
                            class="primary-bg" 
                            *ngFor="let keyword of tags; let keyIndex=index" 
                            [removable]="true"
                            [text]="keyword"
                            (removed)="remove(keyword, keyIndex)">
                            {{ keyword | titlecase}}
                        </e-chip>
                    </e-chips>
                </ejs-chiplist>
            </ng-template>
        </div>
    </div> -->

    <div class="row" style="margin: 0px 20% 0px 20%; text-align: center">
        <table class="table">
            <tr>
                <td style="border: none;">
                    <div class="box" style="width: fit-content; height: 6%; padding: 5px;">
                        <div class="row">
                            <div class="col">
                                <div class="squareCardHeader">
                                    {{translateService.getLabel("issue_date")}}
                                </div>
                                <div class="squareCardData">
                                    <!-- {{ debtCompanyDetails.issuedDate | date:'mediumDate'}} -->
                                    {{ originationDate | date:'mediumDate' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td style="border: none;">
                    <div class="box" style="width: fit-content; height: 6%;">
                        <div class="row">
                            <div class="col">
                                <div class="squareCardHeader">
                                    {{translateService.getLabel("exit_date")}}
                                </div>
                                <div *ngIf="exitDate" class="squareCardData">
                                    <!-- {{ debtCompanyDetails.maturityDate | date:'mediumDate'}} -->

                                    {{ exitDate | date:'mediumDate' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td style="border: none;">
                    <div *ngIf="seniorityAndType" class="box" style="width: fit-content; height: 6%;">
                        <div class="row">
                            <div class="col">
                                <div class="squareCardHeader">
                                    {{translateService.getLabel("seniority_&_type")}}
                                </div>
                                <div class="squareCardData">
                                    <!-- {{ debtCompanyDetails.seniorityAndType }} -->
                                    {{ seniorityAndType }}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td style="border: none;">
                    <div class="box" style="width: fit-content; height: 6%;" *ngIf="debtCompanyDetails.callable">

                        <div class="row">
                            <div class="col">
                                <div class="squareCardHeader">
                                    {{translateService.getLabel("callable")}}
                                </div>
                                <div class="squareCardData">
                                    {{ debtCompanyDetails.callable | date:'mediumDate'}}
                                </div>
                            </div>
                        </div>

                    </div>
                </td>
            </tr>
        </table>
    </div>

    <div style="margin: 0px 30px 0px 48px">
        <ng-template [ngIf]="selectedCompanyDates">
            <ejs-grid #investmentSummaryGrid
            [dataSource]="debtValuationDetails" 
            [allowResizing]="true" 
            [allowTextWrap]="true" 
            [allowGrouping]="false" 
            [allowExcelExport]="true" 
            [allowSorting]="true" 
            [allowReordering]="true" 
            [allowPaging]="false" 
            [pageSettings]="gridPageSettings" 
            [showColumnChooser]="true" 
            [toolbar]="gridToolbar" 
            [allowFiltering]="false" 
            [filterSettings]="gridFilterSettings"
            (rowSelected)="companyRowSelected($event)"
            [contextMenuItems]="contextMenuItems"
            (contextMenuClick)='contextMenuClick($event)'
            (rowDataBound)= "rows($event)"
            (actionComplete)="columnsSelected($event)">
            <e-columns>
    
              <e-column field='date' [headerText]='translateService.getLabel("dates")' width='75px' textAlign="Left">
                <ng-template #template let-comp>
                <ng-template [ngIf] = "comp.loading">
                    {{translateService.getLabel("loading")}}...
                </ng-template>
                <ng-template [ngIf] = "!comp.loading">
                    {{comp.date | date:'mediumDate'}}
                </ng-template>
                </ng-template>  
              </e-column>

              <e-column field='value' [headerText]='translateService.getLabel("value") + " (Mn)"' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.value">{{comp.value | dynamicDecimalPipe }}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='percentPar' [headerText]='translateService.getLabel("percent_of_par")' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.totalPercentOfPar">{{comp.totalPercentOfPar | dynamicDecimalPipe }}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='discountRate' [headerText]='translateService.getLabel("discount_rate") + " (%)"' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.discountRate">{{comp.discountRate | dynamicDecimalPipe }}%</ng-template>
                </ng-template>
              </e-column>
              <e-column field='revenue' [headerText]='translateService.getLabel("revenue") + " (Mn)"' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.revenue">{{comp.revenue | dynamicDecimalPipe }}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='ebitda' [headerText]='translateService.getLabel("ebitda") + " (Mn)"' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.ebitda">{{comp.ebitda | dynamicDecimalPipe }}</ng-template>
                </ng-template>
              </e-column>
              <e-column field='cash' [headerText]='translateService.getLabel("cash") + " (Mn)"' width='75px' textAlign="Right">
                <ng-template #template let-comp>
                    <ng-template [ngIf]="comp.cash">{{comp.cash | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
    
            </e-columns>
            </ejs-grid>
        </ng-template>
        
    <!-- Discount rate table  -->
    <div class="table-responsive table-top border-left-right mt-5"  *ngIf="allDiscountRate.length>0">
        <table class="table table-condensed border table-hover mb-0">

            <!-- Discount rate valuation date table head  -->
            <tr class="bg primary-bg">
                <th class="border border-white w-20 t-sticky left-0 backBlue">{{translateService.getLabel("discount_rate")}}</th>
                <ng-container *ngFor="let valuationdate of allDiscountRate;let d=index">
                    <th class="border border-white text-right w-300">
                        {{valuationdate?.valuationDate}}</th>
                </ng-container>
                
            </tr>
            <tbody>
                
                <!-- Build Up start  -->
                <tr class="text-left bg-white" *ngIf="allDiscountRateBuildUpKey?.length>0">
                    <h6 class="t-sticky left-0 back-white border-0 pt-3 pb-3 pl-3">
                        <span class="main-page-header">
                            {{translateService.getLabel('build_up')}}
                        </span>
                    </h6>
                </tr>

                <!-- Build up key not custom adjustment  -->
                <ng-container *ngFor="let allkey of  allDiscountRateBuildUpKey; let i=index">
                    <ng-template [ngIf]="allkey!='customAdjustments'">
                        <tr>
                            <td class="t-sticky left-0 back-white white-space"><b>{{getCalibrationValue(allkey)}}</b></td>
                            <ng-container *ngFor="let discountRate of allDiscountRate; let j=index">
                                <td class="text-right m-3">
                                    {{discountRate?.discountRateBuildUp? (discountRate?.discountRateBuildUp[allkey] ?(discountRate?.discountRateBuildUp[allkey]|dynamicDecimalPipe)+'%':'-'):'-'}}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    
                    <!-- Build up custom adjustment  -->
                    <ng-template [ngIf]="allkey=='customAdjustments'">
                        <ng-container *ngFor="let allkey of  allDiscountBuildUpCustomAdjustmentKey">
                            <tr>
                                <td class="t-sticky left-0 back-white"><b>{{allkey?.adjustmentName}}</b></td>
                                <ng-container *ngFor="let discountRate of allDiscountRate; let j=index">
                                    <td class="text-right m-3">
                                        {{getDiscountCustomAdjustment(discountRate?.discountRateBuildUp,allkey?.adjustmentName)}}
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-template>
                 
                </ng-container>

                <!-- Calibration Start  -->
                <tr class="text-left border-top " *ngIf="allDiscountRateCalibration?.length>0">
                    <h6 class="t-sticky left-0 back-white border-0 pt-3 pb-3 pl-3">
                        <span class="main-page-header">
                            {{translateService.getLabel('calibration')}}
                        </span>
                    </h6>
                </tr>


                <ng-container *ngFor="let allCalibrationKey of allDiscountRateCalibration">
                    
                    <!-- Calibration keys not custom adjustment  -->
                    <ng-template [ngIf]="allCalibrationKey !='customAdjustments'">
                        <tr>
                            <td class='t-sticky left-0 back-white'><b>{{getCalibrationValue(allCalibrationKey)}}</b></td>
                            <td class="text-right m-3" *ngFor="let discountRate of allDiscountRate;">
                                {{(discountRate?.discountRateCalibration)?(discountRate?.discountRateCalibration[allCalibrationKey]?(discountRate?.discountRateCalibration[allCalibrationKey]|dynamicDecimalPipe)+'%':'-'):'-'}}
                            </td>
                        </tr>
                    </ng-template>
                    
                    <!-- Calibration key custom adjustment  -->
                    <ng-template [ngIf]="allCalibrationKey =='customAdjustments'">
                        <tr *ngFor="let keyName of allDiscountCalibrationCustomAdjustmentkey;let k =index;">
                            <td class='t-sticky left-0 back-white'><b>{{keyName}}</b></td>
                            <td class="text-right m-3" *ngFor="let customAdjustment of allDiscountRate;">
                                {{getDiscountCustomAdjustment(customAdjustment?.discountRateCalibration,keyName)}}
                            </td>
                        </tr>
                    </ng-template>
                </ng-container>
                
            </tbody>

        </table>
    </div>

    <!-- Financials table  -->
    <div class="table-responsive table-top border-left-right mt-5" *ngIf="allFinancialKey.length>0">
        <table class="table table-condensed table-hover mb-0">

            <tr class="bg primary-bg">
            <th class="border border-white w-20 t-sticky left-0 backBlue">{{translateService.getLabel("credit_financials")}}</th>
                <th class="border border-white text-right w-300" *ngFor="let valuationdate of allFinancial">
                    {{valuationdate?.valuationDate}}</th>
            </tr>
            <tbody>

                <ng-container *ngFor="let allkey of allFinancialKey">
                    <ng-template [ngIf]="allkey!== 'valuationDate' ">
                        <tr>
                            <ng-container *ngFor="let title of allFinancialTitle">
                                <td *ngIf="title.key==allkey" class='t-sticky left-0 back-white'><b>{{title.value}}</b></td>
                            </ng-container>
                            <td class="text-right m-3" *ngFor="let financialData of allFinancial">{{financialData[allkey]?(financialData[allkey]|dmDecimal):'-'}}</td>
                        </tr>
                    </ng-template>
                </ng-container>

                <ng-template [ngIf]="allFinancialKey.length==0">
                    <tr><td [colSpan]="allFinancial.length+1">{{translateService.getLabel("no_records_to_display")}}</td></tr>
                </ng-template>

            </tbody>
        </table>
    </div>

     <!-- Shadow Rating table  -->
     <div class="table-responsive table-top border-left-right mt-5" *ngIf="allShadowRatingMethodLabel.length>0">
        <table class="table table-condensed table-hover mb-0">

            <tr class="bg primary-bg">
            <th class="border border-white w-20 t-sticky left-0 backBlue white-space">Shadow Rating</th>
                <th class="border border-white text-right w-300 shadowRatingthwidth" *ngFor="let valuationdate of allShadowRatingMatric">
                    {{valuationdate?.valuationDate|date:'mediumDate'}}</th> 
            </tr>
            <tbody>

                <ng-container *ngFor="let shadowRatingMethodLabel of allShadowRatingMethodLabel;let r=index" >
                    <tr>
                        <td class='border border-right-0 border-left-0 t-sticky left-0 back-white'>
                            <div class="white-space">
                                <b>{{shadowRatingMethodLabel.label}}</b>
                                <div class="w-100 mt-1 rating-range">Rating Range</div> 
                            </div>
                        </td>
                        <ng-container *ngFor="let methodRating of allShadowRatingMatric;let k=index">
                            <td class="text-right white-space">
                                <b>{{methodRating?.customRatings[r]['score'] | dynamicDecimalPipe}}</b>
                                <div class="w-100 mt-1 rating-range">{{methodRating?.customRatings[r]['ratingRange']}}</div>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>

                <tr>
                    <td class='t-sticky left-0 back-white'>
                        <b>
                            <div>Score</div>
                            <div>Shadow Rating</div>
                        </b>
                    </td>
                    <td class="text-right" *ngFor="let scoreShadowRating of allScores">
                        <div class="w-100 mt-1"><b>{{scoreShadowRating.roundingScore | dynamicDecimalPipe}}</b></div>
                        <div class="w-100 mt-1">{{(scoreShadowRating?.scoreCorrespondenceRating=='NA')?'--':scoreShadowRating?.scoreCorrespondenceRating}}</div>
                    </td> 
                </tr>

                <tr>
                    <td class='t-sticky left-0 back-white'>
                        <b>
                            <div>Concluded Rating</div>
                        </b>
                    </td>
                    <td *ngFor="let methodRating of allShadowRatingMatric" class="text-right">
                        {{(methodRating.concludedRating=='NA')?'--':methodRating.concludedRating}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    
    </div>

    <!-- Graphs -->
    <div class="row" style="margin:16px">
        <div class="col-6" *ngIf="valueTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header justify-content-left">
                    <span class="main-page-header">{{translateService.getLabel("dirty_price_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="valueTrendChart"></div>
                </div>
            </div>        
        </div>
        <div class="col-6" *ngIf="percentOfParTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("dirty_price_percent_of_par_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="percentOfParTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="cleanCashTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("clean_price_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="cleanCashTrendChart"></div>
                </div>
            </div>
        </div>
           <div class="col-6" *ngIf="cleanPricePercentOfParTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("clean_price_percent_of_par_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="cleanPricePercentOfParTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="amountTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("discount_rate_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="amountTrendChart"></div>
                </div>
            </div>
        </div>
 
        <!-- <div class="col-6">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">Company Specific Spread Trend</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="companySpecificSpreadTrend"></div>
                </div>
            </div>
        </div> -->
        <div class="col-6" *ngIf="leverageTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("leverage_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="leverageTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="coverageTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("coverage_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="coverageTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="profitablityTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("profitablity_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="profitablityTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="cashBalanceTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("cash_balance_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="cashBalanceTrendChart"></div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="ebitdaTrendChart?.options?.series[0]?.data?.length">
            <div tabindex="0" class="e-card" id="basic">
                <div class="e-card-header">
                    <span class="main-page-header">{{translateService.getLabel("ebitda_trend")}}</span>
                </div>
                <div class="e-card-content">
                    <div [chart]="ebitdaTrendChart"></div>
                </div>
            </div>
        </div>
    </div>
</div>
