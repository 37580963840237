<div mat-dialog-title class="dialog-header">
  <label class="header"> Black-Scholes Option Pricing Model Input </label>
  <em class="fa fa-times" aria-hidden="true" (click)="dismiss()"></em>
</div>
<div mat-dialog-content>
  <div class="opm-form">
    <div class="row">
      <div class="col-6">Expected Term to Liquidation (Years)</div>
      <div class="col-5">
        <ejs-numerictextbox class="sbp-input-field yellow-input" min="0"
          [(value)]="blackAndSholesData.timeUntilExpiration" format='#,###.0' [showSpinButton]="false">
        </ejs-numerictextbox>
      </div>
    </div>
    <div class="row" style="padding-bottom:25px">
      <div class="col-6">Volatility</div>
      <div class="col-5  align-right">
        {{ blackAndSholesData.volatility || 0 | number: '1.1-1'}}
      </div>
      <div class="col-1 refresh-icon" (click)="editAnnualisedVolitility()">
        <i class="fas fa-sync-alt"></i>
      </div>
    </div>
    <div class="row" style="padding-bottom:25px">
      <div class="col-6">Risk Free Rate</div>
      <div class="col-5 align-right">
        {{ blackAndSholesData.riskFreeInterestRate || 0 | number: '1.1-1'}}
      </div>
      <div class="col-1 refresh-icon" (click)="editRiskFreeRate()">
        <i class="fas fa-sync-alt"></i>
      </div>
    </div>
    <div class="row">
      <div class="col-6">Dividend Yield</div>
      <div class="col-5">
        <ejs-numerictextbox class="sbp-input-field yellow-input" min="0" [(value)]="blackAndSholesData.dividendYield"
          format="#,###.0'%'" [showSpinButton]="false">
        </ejs-numerictextbox>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="btn-custom-secondry">Cancel</button>
  <button mat-button [mat-dialog-close]="blackAndSholesData" cdkFocusInitial class="btn-custom-primary"
    (click)="saveInputs()">Save</button>
</div>
