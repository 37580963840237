
<div style="background-color: white;">

    <ng-template [ngIf]="portfolioService.fundDetails && portfolioService.fundDetails.organization">
      
      <button mat-raised-button class="primary-bg floating-save" (click)="saveData()"> Save </button>

      <div class="row">
          <div class="col-5">
              <table class="table" style="margin-bottom: 0">
                  <tr>
                      <td>
                          <img [src]="portfolioService.fundDetails.organization.logo_url" width="100" height="100">
                          <span style="margin-left: 5%;font-size: 25px;">
                              {{ portfolioService.fundDetails.organization.name }}</span>
                      </td>
                  </tr>
              </table>
          </div>
          <div class="col-7">
              <table class="table" style="max-width: 600px;">
                  <tr>
                      <td style="width: 280px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" style="margin: auto;" height="30px" [src]="imageLoadService.getImage('value (1).svg')" alt="value">
                                  </div>
                                  <div class="col" style="width: fit-content; padding: 0;">
                                      <div style="font-size: 10px; text-align: center">
                                          Current Recoverable
                                      </div>
                                      <div style="font-size: 16px; font-weight: bold; white-space: nowrap; text-align: center">
                                          <ng-template [ngIf]="latestValData  && latestValData[0] && latestValData[0].recoverable">
                                              {{ latestValData[0].currency }} {{ latestValData[0].recoverable | number: '1.1-1'}} Mn
                                          </ng-template>
                                          <ng-template [ngIf]="  !latestValData || !latestValData[0] || !latestValData[0].recoverable">
                                              <div> - </div>
                                          </ng-template>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td style="width: 200px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" style="margin: auto;" height="30px" [src]="imageLoadService.getImage('refresh.svg')" alt="refresh">
                                  </div>
                                  <div class="col" style="width: fit-content; padding: 0;">
                                      <div style="font-size: 10px;display: inline-block; white-space: nowrap;">
                                          Update from last date 
                                      </div> 
                                      <ng-template [ngIf]="latestValData && latestValData[0] && latestValData[0].changeInValPercentage">
                                          <div style="font-size: 16px; font-weight: bold; "> 
                                              {{ latestValData[0].changeInValPercentage * 100 | number:'1.1-1' }} %
                                              <img width="15px" height="15px" *ngIf="latestValData[0].changeInValPercentage > 0" [src]="imageLoadService.getImage('up-arrow.svg')" alt="up-arrow">
                                          </div>
                                          <!-- <span style="font-size: 15px;color: green;" *ngIf="latestValData[0].changeInValPercentage > 0"> 
                                              %Up
                                          </span>
                                          <span style="font-size: 15px;color: red;" *ngIf="latestValData[0].changeInValPercentage < 0"> 
                                              %Down
                                          </span> -->
                                      </ng-template>
                                      <ng-template [ngIf]="!latestValData || !latestValData[0] || !latestValData[0].changeInValPercentage">
                                          <div> - </div>
                                      </ng-template>
                                  </div>
                              </div>
                          </div>
                      </td>
                      <!-- <td style="width: 200px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" height="30px" [src]="imageLoadService.getImage('surface1.svg')" alt="value">
                                  </div>
                                  <div class="col">
                                      <div style="font-size: 10px;display: inline-block">
                                          <div>Current IRR</div>
                                          <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>                                           
                                      </div>
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.irr">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              {{ portfolioService.formDetailsObj.irr * 100 | number: '1.1-1'}}%
                                          </div>
                                      </ng-template>
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.irr">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              -
                                          </div>
                                      </ng-template>
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td style="width: 200px">
                          <div class="box" style="height: 70px;">

                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" height="30px" [src]="imageLoadService.getImage('money.svg')" alt="value">
                                  </div>
                                  <div class="col">
                                      <div style="font-size: 10px;display: inline-block">
                                          <div>MOIC</div>
                                          <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>
                                      </div>
                                      
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.moic">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              {{ portfolioService.formDetailsObj.moic | number: '1.1-1'}}x
                                          </div>
                                      </ng-template >
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.moic">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              -
                                          </div>
                                      </ng-template>
                                  </div>
                              </div>

                          </div>
                      </td> -->
                  </tr>
              </table>
          </div>
      </div>

      <div class="row">
          <div class="col-5">
              <div class="row" style="font-size: small">
                  <div class="col spacing" style="background-color: #f5f4f4;color: #1D1563;">
                      <div style="border-right: solid 1px #d8dad8;margin-top: 2%;">
                          <table class="table borderless tableSpacing1">
                              <tr>
                                  <td style="font-weight: bold;">Location</td>
                                  <td class="rightAlignment">
                                      {{ this.portfolioService.fundDetails.organization.city }}, 
                                      {{ this.portfolioService.fundDetails.organization.state }}, 
                                      {{ this.portfolioService.fundDetails.organization.country }} 
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Sector</td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="!portfolioService.fundDetails.organization.name || portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0">
                                        {{ this.portfolioService.fundDetails.organization.industry | titlecase}}
                                    </ng-template>
                                    <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') >= 0">
                                        Defence & Aerospace
                                    </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Total Number of Employees</td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.fundDetails.organization.estimated_num_employees">
                                      {{ this.portfolioService.fundDetails.organization.estimated_num_employees | number:"1.0-0"}}
                                    </ng-template>
                                  </td>
                              </tr>
                              <!-- <tr>
                                  <td style="font-weight: bold;">Founded on</td>
                                  <td class="rightAlignment"></td>
                              </tr> -->
                              <tr>
                                  <td style="font-weight: bold;">Website</td>
                                  <td class="rightAlignment">
                                      {{ this.portfolioService.fundDetails.organization.website_url }}
                                  </td>
                              </tr>

                              <ng-template [ngIf]="this.portfolioService.fundDetails">
                                  <tr *ngFor="let people of this.portfolioService.fundDetails.people;let i=index">
                                      <ng-template [ngIf]="i < 4">
                                        <td style="font-weight: bold;float: left;" *ngIf="i==0">Key People</td>
                                        <td style="font-weight: bold;float: left;" *ngIf="i>0" [style.visibility]="'hidden'">
                                          Key People
                                        </td>
                                        <td class="rightAlignment">
                                            {{ people.name }} ({{people.title}})
                                        </td>
                                      </ng-template>
                                    </tr>
                              </ng-template>

                              <!-- <tr>
                                  <td style="font-weight: bold;">Total Funding</td>
                                  <td class="rightAlignment">
                                      USD {{ this.portfolioService.fundDetails.organization.total_funding }} Billion
                                  </td>
                              </tr> -->

                              <tr>
                                  <td style="font-weight: bold;">Investment Date</td>
                                  <td class="rightAlignment">{{latestValData[0].investmentDate | date: "mediumDate"}}</td>
                              </tr>
                              <tr>
                                    <td style="font-weight: bold;">
                                        Investment Amount
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentAmount)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.totalInvestmentAmount">
                                        {{ latestValData[0].currency }} {{portfolioService.formDetailsObj.totalInvestmentAmount | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template [ngIf]=" !portfolioService.formDetailsObj || !portfolioService.formDetailsObj.totalInvestmentAmount">
                                        <span> - </span>
                                    </ng-template>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold;">
                                        Partner
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(partner)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        <ng-template [ngIf]="savedData.partners && savedData.partners.length > 0">
                                            <span *ngFor="let partner of savedData.partners; let index = index">
                                                <ng-template [ngIf]="partner.name">
                                                    {{ partner.name | titlecase}} <span *ngIf="index < savedData.partners.length-1">,</span>
                                                </ng-template>
                                            </span>
                                        </ng-template>
                                        
                                    </td>
                              </tr>
                          </table>
                      </div>
                  </div>

                  <div class="col spacing" style="background-color:#f5f4f4 ;color: #1D1563;">
                      <div style="margin-top: 2%;">
                          <table class="table borderless tableSpacing2">
                              <!-- <tr>
                                    <td style="font-weight: bold;">
                                        Fully Diluted Ownership
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(fullyDilutedOwnership)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        <ng-template [ngIf]="savedData.fullyDilutedOwnership">
                                            {{ savedData.fullyDilutedOwnership | number:'1.2-2'}}%
                                        </ng-template>
                                    </td>
                              </tr>
                              <tr>
                                    <td style="font-weight: bold;">
                                        Security Type
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(securityType)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        {{ savedData.securityType | titlecase }}
                                    </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">IRR</td>
                                  <td class="rightAlignment">
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.irr">
                                          {{ portfolioService.formDetailsObj.irr * 100 | number: '1.1-1'}} %
                                      </ng-template>
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.irr">
                                          <span> - </span>
                                      </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">MOIC</td>
                                  <td class="rightAlignment">
                                      <ng-template [ngIf]=" portfolioService.formDetailsObj && portfolioService.formDetailsObj.moic">
                                          {{ portfolioService.formDetailsObj.moic | number: '1.1-1'}} x
                                      </ng-template>            
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.moic">
                                          <span> - </span>
                                      </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">
                                      Realised Proceeds
                                      <span>
                                          <i class="fas fa-external-link-alt" (click)="openPopUpModel(realisedProceeds)"></i>
                                      </span>
                                  </td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.totalRealisedProceeds">
                                        {{ latestValData[0].currency }} {{ portfolioService.formDetailsObj.totalRealisedProceeds | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.totalRealisedProceeds">
                                        <span> - </span>
                                    </ng-template>
                                  </td>
                              </tr> -->
                              <tr>
                                  <td style="font-weight: bold;">Industry Median (EBITDA Multiple)</td>
                                  <td class="rightAlignment">{{summaryNumbers.industryBevEbitda | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Company Implied (EBITDA Multiple) LFY</td>
                                  <td class="rightAlignment">{{summaryNumbers.subjectCompBevEbitda | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Industry Median (Revenue Multiple)</td>
                                  <td class="rightAlignment">{{summaryNumbers.industryBevRevennue | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Company Implied (Revenue Multiple) LFY</td>
                                  <td class="rightAlignment">{{summaryNumbers.subjectCompBevRevenue | number: "1.2-2"}} x</td>
                              </tr>

                              <tr>
                                  <td style="font-weight: bold;">Revenue ({{latestValuationDate.getFullYear() - 1}})</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{ latestValData[0].currency }} {{summaryNumbers.revennue | number: "1.2-2"}} Million</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">EBITDA ({{latestValuationDate.getFullYear() - 1}})</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{ latestValData[0].currency }} {{summaryNumbers.ebitda | number: "1.2-2"}} Million</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">EBITDA Margin ({{latestValuationDate.getFullYear() - 1}})</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{summaryNumbers.ebitdaMargin | number: "1.2-2"}} %</td>
                              </tr>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-7">  
            <div style="font-size: medium; margin: 5px">
              <!-- {{portfolioService.fundDetails.organization.short_description}} -->
              {{companyDescription}}
            </div>

            <br>
            
            <mat-chip-list>
                <mat-chip class="primary-bg" *ngFor="let keyword of portfolioService.orgKeyWords">
                    {{ keyword | titlecase}}
                </mat-chip>
            </mat-chip-list>
            <br>
            
            <div [chart]="valuationBarChart"></div>
          </div>
      </div>
      <br>
      <div class="row">
        <span class="header">
              Value Bridge <sub class="subScript">(Since Investment Date to Till Date)</sub>
        </span>
      </div>
      <br>
      <div class="row">
          <div class="col-6">
              <div class="m-auto" [chart]="waterFallChartData"></div>
          </div>
          <div class="col-3">
              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[0].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactRevenue" [(ngModel)]="savedData.comments[0].comment">
                  </textarea>
              </mat-form-field>

              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[1].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactCapital" [(ngModel)]="savedData.comments[1].comment">
                  </textarea>
              </mat-form-field>
          </div>
          <div class="col-3">
              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[2].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactMargin" [(ngModel)]="savedData.comments[2].comment">
                  </textarea>
              </mat-form-field>

              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[3].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impact" [(ngModel)]="savedData.comments[3].comment">
                  </textarea>
              </mat-form-field>
          </div>
      </div>
      <br>
      <div class="row">
          <span class="header">Industry and Company Performance </span>
      </div>
      <br>
      <div class="row">
          <div class="col">
              <div class="widget">
                  <table class="table borderless performance-table" style="font-size: small">
                      <tr>
                          <th>Revenue Multiple</th>
                          <th *ngFor="let date of compPerformanceNumbers.headers" style="text-align: right">
                            {{date | date:'mediumDate'}}
                          </th>
                      </tr>

                      <ng-template ngFor [ngForOf]="compPerformanceNumbers.rows" let-row let-rowIndex="index">
                        <tr *ngIf="rowIndex < 12">
                            <td *ngFor="let value of row; let index=index" [style.text-align]="index == 0 ? 'left' : 'right'"> 
                              <ng-template [ngIf]="index == 0">{{value}}</ng-template>
                              <ng-template [ngIf]="index > 0 && value > 0">{{value | number: "1.1-1"}} x</ng-template>
                            </td>
                        </tr>
                      </ng-template>

                      <tr style="color: white">
                          <th>-</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.average" [style.text-align]="index == 0 ? 'left' : 'right'">-</th>
                      </tr>

                      <tr style="margin-top: 10px;">
                          <th>Average</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.average" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>Median</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.median" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>1st Quartile</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.q1" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>3rd Quartile</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.q3" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="col">
              <div class="widget">
                  <div class="row" style="margin: 0 0 10px 0;">

                      <div class="col topic">Multiple Evolution</div>

                      <div class="col" style="display: inline-block;">
                          <mat-form-field>
                              <mat-select [(ngModel)]="marketMultiple" name="marketMultiple" (ngModelChange)="prepareAreaChart(marketMultiple)">
                                  <mat-option value="bevRevenue"> 
                                      Revenue Multiple
                                  </mat-option>
                                  <mat-option value="bevEbitda"> 
                                      EBITDA Multiple
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="m-auto" [chart]="areaChart"></div>
              </div>
          </div>
          <div class="col">
              <div class="widget">
                  <div style="margin-bottom: 10px;">
                      <span class="topic">EBITDA / Revenue Multiple with Market Cap ({{latestValData[0].latestValuationDate | date: "mediumDate"}})
                      </span>
                  </div>
                  <div class="m-auto" [chart]="bubbleChartData"></div>
              </div>
          </div>
      </div>
      <br>
      <div class="row">
          <div class="col">
              <div class="widget" style="height: 410px;">
                  <div style="margin-bottom: 50px;">
                      <span class="topic">Significant Event - Market</span>
                  </div>
                  <mat-form-field class="text-area">
                      <textarea matInput rows="20" [(ngModel)]="savedData.significantEventMarket" style="font-size: small">
                      </textarea>
                  </mat-form-field>
              </div>
          </div>
          <div class="col">
              <div class="widget"  style="height: 410px; overflow: hidden; height: 100%;">
                  <div style="margin-bottom: 50px;">
                      <span class="topic">Significant Event - Company</span>
                  </div>
                  <mat-form-field class="text-area" style="font-size: small">
                      <textarea matInput rows="20" [(ngModel)]="savedData.significantEventCompany"> </textarea>
                  </mat-form-field>
              </div>
          </div>
          <div class="col">
              <div class="widget" style="overflow: hidden; height: 100%;">
                  <div style="margin-bottom: 20px;">
                      <span class="topic">Planned vs Actual Performance</span>
                      <button style="float: right;" mat-button class="primary-bg" type="submit" (click)="openPopUpModel(plannedPerformance)">Add</button>
                  </div>
                  <table class="table borderless" style="height: 85%; text-align: right;">
                      <tr>
                          <th style="width:100px"></th>
                          <th colspan="3"
                              style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;">
                              Second Quarter 2020 (June 30, 2020)</th>
                          <th colspan="3"
                              style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;">
                              Year to Date (June 30, 2020)</th>
                      </tr>
                      <tr>
                          <th></th>
                          <th class="subItemBorders">Actual</th>
                          <th class="subItemBorders">Budget</th>
                          <th class="subItemBorders">Delta (%)</th>
                          <th class="subItemBorders">Actual</th>
                          <th class="subItemBorders">Budget</th>
                          <th class="subItemBorders">Delta (%)</th>
                      </tr>
                      
                      <tr>
                          <td style="text-align: left">Revenue</td>
                          <td>{{savedData.performance.revenue.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.revenue.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.revenue.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.revenue.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.revenue.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.revenue.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.revenue.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.revenue.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">Gross Profit</td>
                          <td>{{savedData.performance.grossProfit.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.grossProfit.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.grossProfit.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.grossProfit.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.grossProfit.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.grossProfit.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.grossProfit.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.grossProfit.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">GP Margin (%)</td>
                          <td>{{savedData.performance.gpMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.gpMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.gpMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.gpMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.gpMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.gpMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.gpMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.gpMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr style="padding-top: 10px">
                          <td style="text-align: left">EBITDA</td>
                          <td>{{savedData.performance.ebitda.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitda.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitda.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitda.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitda.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitda.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitda.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitda.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">EBITDA Margin (%)</td>
                          <td>{{savedData.performance.ebitdaMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitdaMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitdaMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitdaMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitdaMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitdaMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitdaMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitdaMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr style="padding-top: 10px">
                          <td style="text-align: left">EBIT</td>
                          <td>{{savedData.performance.ebit.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebit.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebit.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebit.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebit.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebit.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebit.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebit.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">EBIT Margin (%)</td>
                          <td>{{savedData.performance.ebitMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                  </table>
              </div>
          </div>
      </div>

    </ng-template>
</div>

<div class="loading-container" *ngIf="!portfolioService.fundDetails">
  <p class="loading-text">Fetching Summary</p>
</div>

<div class="loading-container" *ngIf="portfolioService.fundDetails === 'error'">
  <p class="loading-text">Company Information is not found.</p>
</div>