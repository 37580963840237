<button mat-raised-button class="primary-bg floating-save" (click)="saveData()"> Save </button>
<div style="background-color: white;">
    <ng-template [ngIf]="portfolioService.fundDetails && portfolioService.fundDetails.organization">
      <div class="row">
          <div class="col-5">
              <table class="table" style="margin-bottom: 0">
                  <tr>
                      <td>
                          <img [src]="portfolioService.fundDetails.organization.logo_url" width="100" height="100">
                          <span style="margin-left: 5%;font-size: 25px;">
                              {{ portfolioService.fundDetails.organization.name }}</span>
                      </td>
                  </tr>
              </table>
          </div>
          <div class="col-7">
              <table class="table">
                  <tr>
                      <td style="width: 280px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" style="margin: auto;" height="30px" [src]="imageLoadService.getImage('value (1).svg')" alt="value">
                                  </div>
                                  <div class="col" style="width: fit-content; padding: 0;">
                                      <div style="font-size: 10px; text-align: center">
                                          Current Stake
                                      </div>
                                      <div style="font-size: 16px; font-weight: bold; white-space: nowrap; text-align: center">
                                          <ng-template [ngIf]="latestValData  && latestValData[0] && latestValData[0].stakeVal">
                                              {{ latestValData[0].currency }} {{ latestValData[0].stakeVal | number: '1.1-1'}} Mn
                                          </ng-template>
                                          <ng-template [ngIf]="  !latestValData || !latestValData[0] || !latestValData[0].stakeVal">
                                              <div> - </div>
                                          </ng-template>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td style="width: 200px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" style="margin: auto;" height="30px" [src]="imageLoadService.getImage('refresh.svg')" alt="refresh">
                                  </div>
                                  <div class="col" style="width: fit-content; padding: 0;">
                                      <div style="font-size: 10px;display: inline-block; white-space: nowrap;">
                                          Update from last date 
                                      </div> 
                                      <ng-template [ngIf]="latestValData && latestValData[0] && latestValData[0].changeInValPercentage">
                                          <div style="font-size: 16px; font-weight: bold; "> 
                                              {{ latestValData[0].changeInValPercentage * 100 | number:'1.1-1' }} %
                                              <img width="15px" height="15px" *ngIf="latestValData[0].changeInValPercentage > 0" [src]="imageLoadService.getImage('up-arrow.svg')" alt="up-arrow">
                                          </div>
                                          <!-- <span style="font-size: 15px;color: green;" *ngIf="latestValData[0].changeInValPercentage > 0"> 
                                              %Up
                                          </span>
                                          <span style="font-size: 15px;color: red;" *ngIf="latestValData[0].changeInValPercentage < 0"> 
                                              %Down
                                          </span> -->
                                      </ng-template>
                                      <ng-template [ngIf]="!latestValData || !latestValData[0] || !latestValData[0].changeInValPercentage">
                                          <div> - </div>
                                      </ng-template>
                                      <!-- <span style="font-size: 16px;">
                                          17%
                                      </span> -->
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td style="width: 200px">
                          <div class="box" style="height: 70px;">
                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" height="30px" [src]="imageLoadService.getImage('surface1.svg')" alt="value">
                                  </div>
                                  <div class="col">
                                      <div style="font-size: 10px;display: inline-block">
                                          <div>Current IRR</div>
                                          <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>                                           
                                      </div>
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.irr">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              {{ portfolioService.formDetailsObj.irr * 100 | number: '1.1-1'}}%
                                          </div>
                                      </ng-template>
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.irr">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              -
                                          </div>
                                      </ng-template>
                                  </div>
                              </div>
                          </div>
                      </td>
                      <td style="width: 200px">
                          <div class="box" style="height: 70px;">

                              <div class="row">
                                  <div class="col-3" style="width: fit-content; padding: 0;">
                                      <img width="30px" height="30px" [src]="imageLoadService.getImage('money.svg')" alt="value">
                                  </div>
                                  <div class="col">
                                      <div style="font-size: 10px;display: inline-block">
                                          <div>MOIC</div>
                                          <div>({{latestValData[0].latestValuationDate | date: "mediumDate"}})</div>
                                      </div>
                                      
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.moic">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              {{ portfolioService.formDetailsObj.moic | number: '1.1-1'}}x
                                          </div>
                                      </ng-template >
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.moic">
                                          <div style="font-size: 16px; font-weight: bold;">
                                              -
                                          </div>
                                      </ng-template>
                                  </div>
                              </div>

                          </div>
                      </td>
                  </tr>
              </table>
          </div>
      </div>

      <div class="row">
          <div class="col-5">
              <div class="row" style="font-size: small">
                  <div class="col spacing" style="background-color: #f5f4f4;color: #1D1563;">
                      <div style="border-right: solid 1px #d8dad8;margin-top: 2%;">
                          <table class="table borderless tableSpacing1">
                              <tr>
                                  <td style="font-weight: bold;">Location</td>
                                  <td class="rightAlignment">
                                      {{ this.portfolioService.fundDetails.organization.city }}, 
                                      {{ this.portfolioService.fundDetails.organization.state }}, 
                                      {{ this.portfolioService.fundDetails.organization.country }} 
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Sector</td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="!portfolioService.fundDetails.organization.name || portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0">
                                        {{ this.portfolioService.fundDetails.organization.industry | titlecase}}
                                    </ng-template>
                                    <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') >= 0">
                                        Defence & Aerospace
                                    </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Total Number of Employees</td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.fundDetails.organization.estimated_num_employees">
                                      {{ this.portfolioService.fundDetails.organization.estimated_num_employees | number:"1.0-0"}}
                                    </ng-template>
                                  </td>
                              </tr>
                              <!-- <tr>
                                  <td style="font-weight: bold;">Founded on</td>
                                  <td class="rightAlignment"></td>
                              </tr> -->
                              <tr>
                                  <td style="font-weight: bold;">Website</td>
                                  <td class="rightAlignment">
                                      {{ this.portfolioService.fundDetails.organization.website_url }}
                                  </td>
                              </tr>

                              <ng-template [ngIf]="this.portfolioService.fundDetails">
                                  <tr *ngFor="let people of this.portfolioService.fundDetails.people;let i=index">
                                      <ng-template [ngIf]="i < 4">
                                        <td style="font-weight: bold;float: left;" *ngIf="i==0">Key People</td>
                                        <td style="font-weight: bold;float: left;" *ngIf="i>0" [style.visibility]="'hidden'">
                                          Key People
                                        </td>
                                        <td class="rightAlignment">
                                            {{ people.name }} ({{people.title}})
                                        </td>
                                      </ng-template>
                                    </tr>
                              </ng-template>

                              <!-- <tr>
                                  <td style="font-weight: bold;">Total Funding</td>
                                  <td class="rightAlignment">
                                      USD {{ this.portfolioService.fundDetails.organization.total_funding }} Billion
                                  </td>
                              </tr> -->

                              <tr>
                                  <td style="font-weight: bold;">Investment Date</td>
                                  <td class="rightAlignment">{{latestValData[0].investmentDate | date: "mediumDate"}}</td>
                              </tr>
                              <tr>
                                    <td style="font-weight: bold;">
                                        Investment Amount
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(investmentAmount)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.totalInvestmentAmount">
                                        {{ latestValData[0].currency }} {{portfolioService.formDetailsObj.totalInvestmentAmount | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template [ngIf]=" !portfolioService.formDetailsObj || !portfolioService.formDetailsObj.totalInvestmentAmount">
                                        <span> - </span>
                                    </ng-template>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="font-weight: bold;">
                                        Partner
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(partner)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        <ng-template [ngIf]="savedData.partners && savedData.partners.length > 0">
                                            <span *ngFor="let partner of savedData.partners; let index = index">
                                                <ng-template [ngIf]="partner.name">
                                                    {{ partner.name | titlecase}} <span *ngIf="index < savedData.partners.length-1">,</span>
                                                </ng-template>
                                            </span>
                                        </ng-template>
                                        
                                    </td>
                              </tr>
                          </table>
                      </div>
                  </div>

                  <div class="col spacing" style="background-color:#f5f4f4 ;color: #1D1563;">
                      <div style="margin-top: 2%;">
                          <table class="table borderless tableSpacing2">
                              <tr>
                                    <td style="font-weight: bold;">
                                        Fully Diluted Ownership
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(fullyDilutedOwnership)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        <ng-template [ngIf]="savedData.fullyDilutedOwnership">
                                            {{ savedData.fullyDilutedOwnership | number:'1.2-2'}}%
                                        </ng-template>
                                        <!-- <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                        25%
                                        </ng-template> -->
                                    </td>
                              </tr>
                              <tr>
                                    <td style="font-weight: bold;">
                                        Security Type
                                        <span>
                                            <i class="fas fa-external-link-alt" (click)="openPopUpModel(securityType)"></i>
                                        </span>
                                    </td>
                                    <td class="rightAlignment">
                                        {{ savedData.securityType | titlecase }}
                                        <!-- <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('netflix') >= 0">
                                            Series B Preferred
                                        </ng-template> -->
                                    </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">IRR</td>
                                  <td class="rightAlignment">
                                      <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.irr">
                                          {{ portfolioService.formDetailsObj.irr * 100 | number: '1.1-1'}} %
                                      </ng-template>
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.irr">
                                          <span> - </span>
                                      </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">MOIC</td>
                                  <td class="rightAlignment">
                                      <ng-template [ngIf]=" portfolioService.formDetailsObj && portfolioService.formDetailsObj.moic">
                                          {{ portfolioService.formDetailsObj.moic | number: '1.1-1'}} x
                                      </ng-template>            
                                      <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.moic">
                                          <span> - </span>
                                      </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">
                                      Realised Proceeds
                                      <span>
                                          <i class="fas fa-external-link-alt" (click)="openPopUpModel(realisedProceeds)"></i>
                                      </span>
                                  </td>
                                  <td class="rightAlignment">
                                    <ng-template [ngIf]="portfolioService.formDetailsObj && portfolioService.formDetailsObj.totalRealisedProceeds">
                                        {{ latestValData[0].currency }} {{ portfolioService.formDetailsObj.totalRealisedProceeds | number: '1.1-1'}} Mn
                                    </ng-template>
                                    <ng-template [ngIf]="!portfolioService.formDetailsObj || !portfolioService.formDetailsObj.totalRealisedProceeds">
                                        <span> - </span>
                                    </ng-template>
                                  </td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Industry Median (EBITDA Multiple)</td>
                                  <td class="rightAlignment">{{summaryNumbers.industryBevEbitda | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Company Implied (EBITDA Multiple) LFY</td>
                                  <td class="rightAlignment">{{summaryNumbers.subjectCompBevEbitda | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Industry Median (Revenue Multiple)</td>
                                  <td class="rightAlignment">{{summaryNumbers.industryBevRevennue | number: "1.2-2"}} x</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">Company Implied (Revenue Multiple) LFY</td>
                                  <td class="rightAlignment">{{summaryNumbers.subjectCompBevRevenue | number: "1.2-2"}} x</td>
                              </tr>

                              <tr>
                                  <td style="font-weight: bold;">Revenue (2019)</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{ latestValData[0].currency }} {{summaryNumbers.revennue | number: "1.2-2"}} Million</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">EBITDA (2019)</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{ latestValData[0].currency }} {{summaryNumbers.ebitda | number: "1.2-2"}} Million</td>
                              </tr>
                              <tr>
                                  <td style="font-weight: bold;">EBITDA Margin (2019)</td>
                                  <td class="rightAlignment"><span style="color: green;">^</span>{{summaryNumbers.ebitdaMargin | number: "1.2-2"}} %</td>
                              </tr>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-7">  
            <div style="font-size: medium; margin: 5px">
              <!-- {{portfolioService.fundDetails.organization.short_description}} -->
              {{companyDescription}}
            </div>
                    
              <!-- <mat-form-field style="margin: 20px auto;" class="text-area">
                  <textarea matInput rows="7" cols="80px" style="font-size: small;" name="description" [(ngModel)]="this.portfolioService.fundDetails.organization.short_description">
                  </textarea>
              </mat-form-field> -->
              <br>
              <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('iberchem') >= 0">
                
                <mat-chip-list>
                    <mat-chip class="primary-bg">Specialty chemicals</mat-chip> 
                    <mat-chip class="primary-bg">Fragrances</mat-chip>
                    <mat-chip class="primary-bg">Natural & artifical flavours</mat-chip>
                    <mat-chip class="primary-bg">Personal Products</mat-chip>
                    <mat-chip class="primary-bg">Fabric Chemicals</mat-chip>
                </mat-chip-list>
              </ng-template>

              <ng-template [ngIf]="portfolioService.fundDetails.organization.name && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') >= 0">
                
                <mat-chip-list>
                    <mat-chip class="primary-bg">GPS GNSS</mat-chip> 
                    <mat-chip class="primary-bg">Position, Navigation & Timing</mat-chip> 
                    <mat-chip class="primary-bg">Precision Instruments</mat-chip> 
                    <mat-chip class="primary-bg">Aerial Safety Systems</mat-chip> 
                    <mat-chip class="primary-bg">Communications Equipment</mat-chip> 
                </mat-chip-list>
              </ng-template>
              <ng-template [ngIf]="!portfolioService.fundDetails.organization.name 
                    || (portfolioService.fundDetails.organization.name.toLowerCase().indexOf('iberchem') < 0
                    && portfolioService.fundDetails.organization.name.toLowerCase().indexOf('orolia') < 0)">
                <mat-chip-list>
                    <mat-chip class="primary-bg" *ngFor="let keyword of portfolioService.orgKeyWords">
                        {{ keyword | titlecase}}
                    </mat-chip>
                </mat-chip-list>
              </ng-template>
              <br>
              
              <div [chart]="valuationBarChart"></div>
          </div>
      </div>
      <br>
      <div class="row">
        <span class="header">
              Value Bridge <sub class="subScript">(Since Investment Date to Till Date)</sub>
        </span>
      </div>
      <br>
      <div class="row">
          <div class="col-6">
              <div class="m-auto" *ngIf="!valuationAlgorithms.processingWaterFall" [chart]="waterFallChartData"></div>
              
              <div *ngIf="valuationAlgorithms.processingWaterFall" id="loader-container">
                <div id="loader"></div>
                <div>
                  {{valuationAlgorithms.processingWaterFallMsg}}
                </div>
              </div>
          </div>
          <div class="col-3">
              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[0].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactRevenue" [(ngModel)]="savedData.comments[0].comment">
                  </textarea>
              </mat-form-field>

              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[1].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactCapital" [(ngModel)]="savedData.comments[1].comment">
                  </textarea>
              </mat-form-field>
          </div>
          <div class="col-3">
              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[2].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impactMargin" [(ngModel)]="savedData.comments[2].comment">
                  </textarea>
              </mat-form-field>

              <mat-form-field class="form-group" style="width: 100%;">
                  <input matInput class="text" placeholder="placeholder" type="text" [(ngModel)]="savedData.comments[3].title">
              </mat-form-field>
              <mat-form-field style="width: 100%;" class="text-area form-group">
                  <textarea matInput rows="10"  name="impact" [(ngModel)]="savedData.comments[3].comment">
                  </textarea>
              </mat-form-field>
          </div>
      </div>
      <br>
      <div class="row">
          <span class="header">Industry and Company Performance </span>
      </div>
      <br>
      <div class="row">
          <div class="col">
              <div class="widget">
                  <table class="table borderless performance-table" style="font-size: small">
                      <tr>
                          <th>Revenue Multiple</th>
                          <th *ngFor="let date of compPerformanceNumbers.headers" style="text-align: right">
                            {{date | date:'mediumDate'}}
                          </th>
                      </tr>

                      <ng-template ngFor [ngForOf]="compPerformanceNumbers.rows" let-row let-rowIndex="index">
                        <tr *ngIf="rowIndex < 12">
                            <td *ngFor="let value of row; let index=index" [style.text-align]="index == 0 ? 'left' : 'right'"> 
                              <ng-template [ngIf]="index == 0">{{value}}</ng-template>
                              <ng-template [ngIf]="index > 0 && value > 0">{{value | number: "1.1-1"}} x</ng-template>
                            </td>
                        </tr>
                      </ng-template>

                      <tr style="color: white">
                          <th>-</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.average" [style.text-align]="index == 0 ? 'left' : 'right'">-</th>
                      </tr>

                      <tr style="margin-top: 10px;">
                          <th>Average</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.average" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>Median</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.median" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>1st Quartile</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.q1" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                      <tr>
                          <th>3rd Quartile</th>
                          <th *ngFor="let v of compPerformanceNumbers.aggregations.q3" [style.text-align]="index == 0 ? 'left' : 'right'">{{v | number: "1.1-1"}} x</th>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="col">
              <div class="widget">
                  <div class="row" style="margin: 0 0 10px 0;">

                      <div class="col topic">Multiple Evolution</div>

                      <div class="col" style="display: inline-block;">
                          <mat-form-field>
                              <mat-select [(ngModel)]="marketMultiple" name="marketMultiple" (ngModelChange)="prepareAreaChart(marketMultiple)">
                                  <mat-option value="bevRevenue"> 
                                      Revenue Multiple
                                  </mat-option>
                                  <mat-option value="bevEbitda"> 
                                      EBITDA Multiple
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
                  <div class="m-auto" [chart]="areaChart"></div>
              </div>
          </div>
          <div class="col">
              <div class="widget">
                  <div style="margin-bottom: 10px;">
                      <span class="topic">EBITDA / Revenue Multiple with Market Cap ({{latestValData[0].latestValuationDate | date: "mediumDate"}})
                      </span>
                  </div>
                  <div class="m-auto" [chart]="bubbleChartData"></div>
              </div>
          </div>
      </div>
      <br>
      <div class="row">
          <div class="col">
              <div class="widget" style="height: 410px;">
                  <div style="margin-bottom: 50px;">
                      <span class="topic">Significant Event - Market</span>
                  </div>
                  <mat-form-field class="text-area">
                      <textarea matInput rows="20" [(ngModel)]="savedData.significantEventMarket" style="font-size: small">
                      </textarea>
                  </mat-form-field>
              </div>
          </div>
          <div class="col">
              <div class="widget"  style="height: 410px; overflow: hidden; height: 100%;">
                  <div style="margin-bottom: 50px;">
                      <span class="topic">Significant Event - Company</span>
                  </div>
                  <mat-form-field class="text-area" style="font-size: small">
                      <textarea matInput rows="20" [(ngModel)]="savedData.significantEventCompany"> </textarea>
                  </mat-form-field>
              </div>
          </div>
          <div class="col">
              <div class="widget" style="overflow: hidden; height: 100%;">
                  <div style="margin-bottom: 20px;">
                      <span class="topic">Planned vs Actual Performance</span>
                      <button style="float: right;" mat-button class="primary-bg" type="submit" (click)="openPopUpModel(plannedPerformance)">Add</button>
                  </div>
                  <table class="table borderless" style="height: 85%; text-align: right;">
                      <tr>
                          <th style="width:100px"></th>
                          <th colspan="3"
                              style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;">
                              Second Quarter 2020 (June 30, 2020)</th>
                          <th colspan="3"
                              style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;">
                              Year to Date (June 30, 2020)</th>
                      </tr>
                      <tr>
                          <th></th>
                          <th class="subItemBorders">Actual</th>
                          <th class="subItemBorders">Budget</th>
                          <th class="subItemBorders">Delta (%)</th>
                          <th class="subItemBorders">Actual</th>
                          <th class="subItemBorders">Budget</th>
                          <th class="subItemBorders">Delta (%)</th>
                      </tr>
                      
                      <tr>
                          <td style="text-align: left">Revenue</td>
                          <td>{{savedData.performance.revenue.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.revenue.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.revenue.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.revenue.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.revenue.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.revenue.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.revenue.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.revenue.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">Gross Profit</td>
                          <td>{{savedData.performance.grossProfit.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.grossProfit.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.grossProfit.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.grossProfit.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.grossProfit.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.grossProfit.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.grossProfit.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.grossProfit.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">GP Margin (%)</td>
                          <td>{{savedData.performance.gpMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.gpMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.gpMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.gpMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.gpMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.gpMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.gpMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.gpMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr style="padding-top: 10px">
                          <td style="text-align: left">EBITDA</td>
                          <td>{{savedData.performance.ebitda.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitda.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitda.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitda.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitda.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitda.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitda.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitda.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">EBITDA Margin (%)</td>
                          <td>{{savedData.performance.ebitdaMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitdaMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitdaMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitdaMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitdaMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitdaMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitdaMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitdaMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr style="padding-top: 10px">
                          <td style="text-align: left">EBIT</td>
                          <td>{{savedData.performance.ebit.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebit.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebit.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebit.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebit.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebit.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebit.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebit.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                      <tr>
                          <td style="text-align: left">EBIT Margin (%)</td>
                          <td>{{savedData.performance.ebitMargin.actualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitMargin.budgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitMargin.varModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitMargin.varModel| number: "1.2-2"}}
                          </td>
                          <td>{{savedData.performance.ebitMargin.ytdActualModel | number: "1.2-2"}}</td>
                          <td>{{savedData.performance.ebitMargin.ytdBudgetModel | number: "1.2-2"}}</td>
                          <td [style.color]="savedData.performance.ebitMargin.ytdVarModel < 0 ? 'red' : 'black'">
                            {{savedData.performance.ebitMargin.ytdVarModel | number: "1.2-2"}}
                          </td>
                      </tr>
                  </table>
              </div>
          </div>
      </div>

    </ng-template>

</div>

<div class="loading-container" *ngIf="!portfolioService.fundDetails">
  <p class="loading-text">Fetching Summary</p>
</div>

<ng-template #realisedProceeds let-realised>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="realised.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template [ngIf]="portfolioService.formDetailsObj.multipleRealisedProceeds && 
        portfolioService.formDetailsObj.multipleRealisedProceeds.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Date
                    </th>
                    <th style="text-align: right;">
                        Realised Proceeds
                    </th>
                    <th style="text-align: right;">
                        Currency
                    </th>
                    <th style="text-align: right;">
                        Exchange Value 
                    </th>
                    <th style="text-align: right;">
                        Exchange Rate
                    </th>
                    <th style="text-align: right;">
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="portfolioService.formDetailsObj.multipleRealisedProceeds" let-returns
                    let-rowIndex="index">
                    <tr>
                        <td>
                            {{ returns.date | date: "MMM, dd yyyy" }}
                        </td>
                        <td style="text-align: right;">
                            {{returns.valueBeforeConversion ? returns.valueBeforeConversion : returns.value | number: '1.1-1'}} {{ returns.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            {{ returns.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="returns.exchangeValue">
                                {{ returns.exchangeValue | number: '1.1-1' }} {{ returns.sourceCurrency }}
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="returns.exchangeRate">
                                {{ returns.exchangeRate | number: '1.1-1'}}
                            </ng-template>
                            <ng-template [ngIf]="!returns.exchangeRate">
                                1.00
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <i class="far fa-trash-alt" (click)="delete('realisedProceeds', rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Date </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field (click)="vDatePicker.open()">
                        <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="date">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label>Realised Proceeds ({{ latestValData[0].currency }} Mn)</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="value" name="realised" type="number"
                        (ngModelChange)="getCurrencyExchange($event, userSelectedCurrency, latestValData[0].currency, this.latestValData[0].valuationDate)">
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Currency </mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    <app-currency-dropdown 
                    [sourceCurrency]="latestValData[0].currency" 
                    [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency" 
                    [currencyConversionDate]="this.latestValData[0].valuationDate" 
                    (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Rate</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.exchangeRate | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Value</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.convertedValue | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit" (click)="addRealisedProceeds(); realised.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>

<ng-template #investmentAmount let-investment>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="investment.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-template [ngIf]="portfolioService.formDetailsObj.multipleInvestmentAmount && 
        portfolioService.formDetailsObj.multipleInvestmentAmount.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Date
                    </th>
                    <th style="text-align: right;">
                        Investment Amount 
                        <!-- ({{ latestValData[0].currency }} Mn) -->
                    </th>
                    <th style="text-align: right;">
                        Currency
                    </th>
                    <th style="text-align: right;">
                        Exchange Value 
                    </th>
                    <th style="text-align: right;">
                        Exchange Rate
                    </th>
                    <th style="text-align: right;">
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="portfolioService.formDetailsObj.multipleInvestmentAmount" let-amount
                    let-rowIndex="index">
                    <tr>
                        <td>
                            {{ amount.date | date: "MMM, dd yyyy"}}
                        </td>
                        <td style="text-align: right;">
                            {{amount.valueBeforeConversion | number: '1.1-1'}} {{ amount.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            {{ amount.userSelectedCurrency }}
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="amount.exchangeValue">
                                {{ amount.exchangeValue | number: '1.1-1' }} {{ amount.sourceCurrency }}
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <ng-template [ngIf]="amount.exchangeRate">
                                {{ amount.exchangeRate | number: '1.1-1'}}
                            </ng-template>
                            <ng-template [ngIf]="!amount.exchangeRate">
                                1.00
                            </ng-template>
                        </td>
                        <td style="text-align: right;">
                            <i class="far fa-trash-alt" (click)="delete('investmentAmount', rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Date </mat-label>
                </div>
                <div class="col"  style="text-align: right;">
                    <mat-form-field (click)="vDatePicker.open()">
                        <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="date">
                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Investment Amount ({{ latestValData[0].currency }} Mn)</mat-label>
                </div>
                <div class="col"  style="text-align: right;">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="value" name="realised" type="number"
                        (ngModelChange)="getCurrencyExchange($event, latestValData[0].currency, userSelectedCurrency)">
                    </mat-form-field>
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Currency </mat-label>
                </div>
                <div class="col"  style="text-align: right;">
                    <app-currency-dropdown 
                    [sourceCurrency]="latestValData[0].currency" 
                    [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency" 
                    [currencyConversionDate]="this.latestValData[0].valuationDate" 
                    (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Rate</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.exchangeRate | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>

        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Exchange Value</mat-label>
                </div>
                <div class="col" style="text-align: right;">
                    {{ currencyExchange.convertedValue | number: '1.2-2' }}
                </div>
                <div class="col-4"></div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit" (click)="addInvestmentAmount(); investment.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>

<!-- <ng-template #currencyConversion let-converter>
    <div class="currency-modal">
      <div class="modal">
        <div class="modal-header">
          <div class="modal-title">
      
          </div>
          <button type="button" class="close" aria-label="Close" (click)="converter.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <app-currency-dropdown [sourceCurrency]="latestValData[0].currency" [userSelectedCurrency]="userSelectedCurrency ? userSelectedCurrency: latestValData[0].currency" [currencyConversionDate]="latestValuationDate" (conversionMultiple)="currencyMultiple($event)"></app-currency-dropdown>
        </div>
      </div>
    </div>
</ng-template> -->

<!-- Partner -->
<ng-template #partner let-partnerName>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="partnerName.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <table class="table borderless">
                <tr>
                    <th></th>
                    <th>
                        Name
                    </th>
                    <th style="text-align: right;">
                        Delete
                    </th>
                </tr>
                <ng-template [ngIf]="masterPartnersList && masterPartnersList?.length > 0">
                    <ng-template ngFor [ngForOf]="masterPartnersList" let-partnerList let-rowIndex="index">
                        <tr>
                            <td>
                                <mat-checkbox [(ngModel)]="partnerList.isSelected" (ngModelChange)="addPartnerToCompany($event, partnerList)"></mat-checkbox>
                            </td>
                            <td style="width: 150px;">
                                {{ partnerList.name }}
                            </td>
                            <td style="text-align: right">
                                <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                            </td>
                        </tr>
                    </ng-template>
                </ng-template>
                <tr>
                    <td>
                        <mat-checkbox [(ngModel)]="portfolioService.partnerList.isSelected"></mat-checkbox>
                    </td>
                    <td style="width: 150px;">
                        <mat-form-field class="editable-form-field text-field">
                            <input matInput [(ngModel)]="portfolioService.partnerList.name" name="partner" type="text" [disabled]="!portfolioService.partnerList.isSelected">
                        </mat-form-field>
                    </td>
                    <td>

                    </td>
                </tr>
            </table>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit" [disabled]="!portfolioService.partnerList.name && !portfolioService.partnerList.isSelected" (click)="addPartnerList(); partnerName.dismiss('Cross click')">Add</button> &nbsp;
        </div>
    </div>
</ng-template>
<!-- End -->
<ng-template #fullyDilutedOwnership let-fullyDiluted>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="fullyDiluted.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Fully Diluted Ownership </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput style="text-align: right;" [(ngModel)]="portfolioService.fullyDilutedOwnership" name="partner" type="number">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit" [disabled]="!portfolioService.fullyDilutedOwnership" (click)="addFullyDilutedOwnership(); fullyDiluted.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>


<ng-template #securityType let-security>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="security.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <ng-template [ngIf]="savedData.partners && savedData.partners?.length > 0">
            <table class="table borderless">
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Delete
                    </th>
                </tr>
                <ng-template ngFor [ngForOf]="savedData.partners" let-name let-rowIndex="index">
                    <tr>
                        <td>
                            {{ name }}
                        </td>
                        <td>
                            <i class="far fa-trash-alt" (click)="deletePartnerName(rowIndex)"></i>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-template> -->
        <div class="form-group">
            <div class="row">
                <div class="col-4">
                    <mat-label> Security Type </mat-label>
                </div>
                <div class="col">
                    <mat-form-field class="editable-form-field text-field">
                        <input matInput [(ngModel)]="portfolioService.securityType" name="partner" type="text">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div>
            <button mat-raised-button class=" primary-bg" type="submit" [disabled]="!portfolioService.securityType" (click)="addSecurityType(); security.dismiss('Cross click')">Add</button>
        </div>
    </div>
</ng-template>


<ng-template #plannedPerformance let-plannedPerformance>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="plannedPerformance.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form #f="ngForm" (ngSubmit)="addPlanned(f); plannedPerformance.dismiss('Cross click')">
            <table class="table borderless">
                <tr>
                    <th style="width:1%"></th>
                    <th colspan="3"
                        style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;width: auto;">
                        Quarter</th>
                    <th colspan="3"
                        style="text-align: center;border-left: solid black 1px;border-right: solid black 1px;background-color: skyblue;width: auto;">
                        YTD</th>
                </tr>
                <tr>
                    <th></th>
                    <th class="subItemBorders">Actual</th>
                    <th class="subItemBorders">Budget</th>
                    <th class="subItemBorders">Delta %</th>
                    <th class="subItemBorders">Actual</th>
                    <th class="subItemBorders">Budget</th>
                    <th class="subItemBorders">Delta %</th>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">Revenue</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="revenueActualField"
                                [(ngModel)]="savedData.performance.revenue.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="revenueBudgetField"
                                [(ngModel)]="savedData.performance.revenue.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="revenueVarField"
                                [(ngModel)]="savedData.performance.revenue.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="revenueYtdActualField"
                                [(ngModel)]="savedData.performance.revenue.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="revenueYtdBudgetField"
                                [(ngModel)]="savedData.performance.revenue.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="revenueYtdVarField"
                                [(ngModel)]="savedData.performance.revenue.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">Gross Profit</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="grossProfitActualField"
                                [(ngModel)]="savedData.performance.grossProfit.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="grossProfitBudgetField"
                                [(ngModel)]="savedData.performance.grossProfit.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="grossProfitVarField"
                                [(ngModel)]="savedData.performance.grossProfit.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="grossProfitYtdActualField"
                                [(ngModel)]="savedData.performance.grossProfit.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="grossProfitYtdBudgetField"
                                [(ngModel)]="savedData.performance.grossProfit.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="grossProfitYtdVarField"
                                [(ngModel)]="savedData.performance.grossProfit.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">GP Margin (%)</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="gpMarginActualField"
                                [(ngModel)]="savedData.performance.gpMargin.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="gpMarginBudgetField"
                                [(ngModel)]="savedData.performance.gpMargin.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="gpMarginVarField"
                                [(ngModel)]="savedData.performance.gpMargin.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="gpMarginYtdActualField"
                                [(ngModel)]="savedData.performance.gpMargin.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="gpMarginYtdBudgetField"
                                [(ngModel)]="savedData.performance.gpMargin.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="gpMarginYtdVarField"
                                [(ngModel)]="savedData.performance.gpMargin.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">EBITDA</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitdaActualField"
                                [(ngModel)]="savedData.performance.ebitda.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitdaBudgetField"
                                [(ngModel)]="savedData.performance.ebitda.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaVarField"
                                [(ngModel)]="savedData.performance.ebitda.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaYtdActualField"
                                [(ngModel)]="savedData.performance.ebitda.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaYtdBudgetField"
                                [(ngModel)]="savedData.performance.ebitda.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaYtdVarField"
                                [(ngModel)]="savedData.performance.ebitda.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">EBITDA Margin (%)</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitdaMarginActualField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitdaMarginBudgetField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaMarginVarField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaMarginYtdActualField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaMarginYtdBudgetField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitdaMarginYtdVarField"
                                [(ngModel)]="savedData.performance.ebitdaMargin.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">EBIT</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitActualField"
                                [(ngModel)]="savedData.performance.ebit.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitBudgetField"
                                [(ngModel)]="savedData.performance.ebit.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitVarField"
                                [(ngModel)]="savedData.performance.ebit.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitYtdActualField"
                                [(ngModel)]="savedData.performance.ebit.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitYtdBudgetField"
                                [(ngModel)]="savedData.performance.ebit.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitYtdVarField"
                                [(ngModel)]="savedData.performance.ebit.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">EBIT Margin (%)</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitMarginActualField"
                                [(ngModel)]="savedData.performance.ebitMargin.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="ebitMarginBudgetField"
                                [(ngModel)]="savedData.performance.ebitMargin.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitMarginVarField"
                                [(ngModel)]="savedData.performance.ebitMargin.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitMarginYtdActualField"
                                [(ngModel)]="savedData.performance.ebitMargin.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitMarginYtdBudgetField"
                                [(ngModel)]="savedData.performance.ebitMargin.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="ebitMarginYtdVarField"
                                [(ngModel)]="savedData.performance.ebitMargin.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <!-- <tr>
                    <td style="white-space: nowrap;">Net Debt</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="netDebtActualField"
                                [(ngModel)]="netDebt.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="netDebtBudgetField"
                                [(ngModel)]="netDebt.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="netDebtVarField"
                                [(ngModel)]="netDebt.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="netDebtYtdActualField"
                                [(ngModel)]="netDebt.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="netDebtYtdBudgetField"
                                [(ngModel)]="netDebt.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="netDebtYtdVarField"
                                [(ngModel)]="netDebt.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td style="white-space: nowrap;">Cash Balance</td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="cashBalanceActualField"
                                [(ngModel)]="cashBalance.actualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="number" class="textInput" name="cashBalanceBudgetField"
                                [(ngModel)]="cashBalance.budgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="cashBalanceVarField"
                                [(ngModel)]="cashBalance.varModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="cashBalanceYtdActualField"
                                [(ngModel)]="cashBalance.ytdActualModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="cashBalanceYtdBudgetField"
                                [(ngModel)]="cashBalance.ytdBudgetModel">
                        </mat-form-field>
                    </td>
                    <td style="width:1%">
                        <mat-form-field style="width: 80px">
                            <input matInput type="text" class="textInput" name="cashBalanceYtdVarField"
                                [(ngModel)]="cashBalance.ytdVarModel">
                        </mat-form-field>
                    </td>
                </tr> -->
            </table>
            <div>
                <button mat-raised-button class=" primary-bg" type="submit">Add</button>
            </div>
        </form>
    </div>
</ng-template>