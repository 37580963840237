<div>
  <span class="red" *ngIf="!!item.approvalSystem && item.approvalSystem.approvalObjectStatus == 'REJECTED'">{{ translateService.getLabel("rejected") }}</span>
  <span class="green" *ngIf="!!item.approvalSystem && item.approvalSystem.approvalObjectStatus == 'APPROVED'">{{ translateService.getLabel("approved") }}</span>
  <span *ngIf="!item.approvalSystem">{{ translateService.getLabel("approval") }} - {{ translateService.getLabel("not_started") }}</span>
  <span *ngIf="!!item.approvalSystem && (item.approvalSystem.approvalObjectStatus == 'INITIATED' || item.approvalSystem.approvalObjectStatus == 'APPROVAL_IN_PROGRESS')">{{ translateService.getLabel("approval") }} - {{ translateService.getLabel("in_progress") }}</span>
  <span *ngIf="!!item.approvalSystem" style="float: right; padding-right: 13px;">
    <span mat-button [matMenuTriggerFor]="belowMenu" style="margin-left:25%" (menuClosed)="showComment(item)">
      <i *ngIf="!item.showComment" class="far fa-comment-alt comment-icon" (click)="item.showComment = true"></i>
      <i *ngIf="item.showComment" class="fas fa-comment-alt comment-icon"></i>
    </span>
    <mat-menu id="visibilityContainer" #belowMenu="matMenu" xPosition="before" yPosition="above">
      <div class="comment-box">
        <div class="comment-Body" *ngFor="let detail of item.approvalUserData">
          <div class="row comment-font" style="padding-bottom: 0 !important;">
            <div class="col-6 align-left" style="font-weight: 500;">{{detail?.approverName}}</div>
            <div class="col-6 align-right" [ngClass]="{'red': detail?.approverStatus == 'REJECTED', 'green': detail?.approverStatus == 'APPROVED', 'yellow': detail?.approverStatus == 'INITIATED'}">
              {{detail?.approverStatus | titlecase}}
            </div>
          </div>
          <div class="row font-size-small">
            <div class="col-6 align-left">
              <span *ngIf="!detail.approverLevel">Initiator</span>
              <span *ngIf="!!detail.approverLevel">Approver Level {{detail?.approverLevel}}</span>
            </div>
            <div class="col-6 align-right" >{{detail?.approveAt | date:'medium'}}</div>
          </div>
          <div class="row" style="font-size: 12px; padding-top: 5px !important;">
            <div class="col">{{detail?.approverComment}}</div>
          </div>
        </div>
      </div>
    </mat-menu>
  </span>
</div>