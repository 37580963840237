import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  constructor() {}

  transform(value: string): any {
    if (!value) {
      return '';
    }

    if(moment(value).format('MM/DD/YYYY') == moment(new Date()).format('MM/DD/YYYY')){
        return `Today at ${moment(value).format('hh:mm A')}`;
    }
    else {
        return `${moment(value).format('LL')} at ${moment(value).format('hh:mm A')}`;
    }    
  }
}
