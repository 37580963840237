<ng-template [ngIf]="!ums.currentUserCompanyPermissionReadOnly && item.actionName == approvalMatrixService.APPROVAL_ACTION.INITIATE_APPROVAL && item.status == 'Submitted'">
  <button style="border: 1px solid green; color: green; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;" 
  (click)="updateApprovalRequest(item, 'initiate'); item.actionName = approvalMatrixService.APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = approvalMatrixService.APPROVAL_STATUS.PENDING"
  class="btn-status">
    {{ translateService.getLabel("initiate_approval") }}
  </button>
</ng-template>

<ng-template [ngIf]="!ums.currentUserCompanyPermissionReadOnly && item.actionName == approvalMatrixService.APPROVAL_ACTION.NO_CONDITION">
  <div matTooltip="{{ translateService.getLabel('info_approval_not_available') }}" matTooltipPosition='above'>
    <button [disabled]="true" style="border: 1px solid grey; color: grey; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;" 
    (click)="updateApprovalRequest(item, 'initiate'); item.actionName = approvalMatrixService.APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = approvalMatrixService.APPROVAL_STATUS.PENDING"
    class="btn-status" >
      {{ translateService.getLabel("initiate_approval") }}
    </button>
  </div>
</ng-template>

<ng-template [ngIf]="item.actionName == approvalMatrixService.APPROVAL_ACTION.RECALL_APPROVAL">
  <button style="border: 1px solid red; color: red; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
  (click)="updateApprovalRequest(item, 'recall'); item.actionName = approvalMatrixService.APPROVAL_ACTION.INITIATE_APPROVAL; item.approvalStatus = approvalMatrixService.APPROVAL_STATUS.NOT_STARTED"
  class="btn-status">
    {{ translateService.getLabel("recall_approval") }}
  </button>
</ng-template>

<ng-template [ngIf]="item.actionName == approvalMatrixService.APPROVAL_ACTION.REJECT_APPROVE">
  <button class="button-theme-secondary" style="border: 1px solid; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
  (click)="updateApprovalRequest(item, 'approveOrReject')"
  class="btn-status">
      {{ translateService.getLabel("approve") }} / {{ translateService.getLabel("reject") }}
  </button>
</ng-template>

<ng-template [ngIf]="item.approvalStatus == approvalMatrixService.APPROVAL_STATUS.APPROVED || item.approvalStatus == approvalMatrixService.APPROVAL_STATUS.REJECTED">
    <div class="locked"><i class="fa fa-lock icon-lock" matTooltip="{{translateService.getLabel('this_version_is_not_editable')}}" matTooltipPosition='above'></i> </div>
</ng-template>

<ng-template [ngIf]="!item.actionName">
  {{ translateService.getLabel("loading_approval_information") }}
</ng-template>