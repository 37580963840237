
export const VALUATION_DATA_TEMPLATE = {
  id: "",
  "valuationDate": {
    "label": "Valuation Date",
    "value": ""
  },

  valuationMetadata : [
    {
      "key" : "valuerName",
      "label": "Valuer Name",
      "value": ""
    },
    {
      "key" : "fairValue",
      "label": "Fair Value",
      "value": ""
    },
    {
      "key" : "stake",
      "label": "Stake",
      "value": ""
    },
    {
      "key" : "stakeValue",
      "label": "Stake Value",
      "value": ""
    },
  ],
  
  "valueHeaders": [],

  "enterprise": [
    {
      "key": "bevRevenue",
      "label": "BEV / Revenue",
      "values": ""
    },
    {
      "key": "bevEbitda",
      "label": "BEV / EBITDA",
      "values": ""
    },
    {
      "key": "evEbit",
      "label": "EV / EBIT",
      "values": ""
    },
    {
      "key": "evEbitda_Capex",
      "label": "EV / EBITDA - Capex",
      "values": ""
    }
  ],
  "equity": [
    {
      "key": "psales",
      "label": "Price / Sales",
      "values": ""
    },
    {
      "key": "pe",
      "label": "Price / Earnings",
      "values": ""
    },
    {
      "key": "pbv",
      "label": "Price / Book Value",
      "values": ""
    }
  ]
};