
<mat-form-field class="input-box-company" *ngIf="isLoggedIn && router.url !== '/users' && router.url !== '/user-management'">
    <span matSuffix align="end" (click)="navigate()"><i class="fa fa-search" style="cursor: pointer;"></i></span>
    
    <mat-chip-list #chipList>
        <ng-template ngFor [ngForOf]="inputValues" let-value let-index="index">
            <mat-chip [selectable]="selectable" [removable]="removable" (removed)="remove(value)">
                {{value.name}}
                <mat-icon matChipRemove *ngIf="removable" class="close-mat-icon">close</mat-icon>
            </mat-chip>
            <span *ngIf="(index < 3) || (index == 3 && value.companyType == 'Debt')" aria-readonly="true" style="font-size: medium;"> / </span>
        </ng-template>
    
        <input #inputSearchElement [matChipInputFor]="chipList" [disabled]="dataFetchInProgress"
            [(ngModel)]="searchString" (keydown.enter)="getLatestSearchOnEnter()" 
            (ngModelChange)="searchCompanyDetails($event)" 
            [placeholder]="dataFetchInProgress ? translateService.getLabel('please_wait_search_is_getting_ready') 
            : translateService.getLabel('search_fund') + ', ' + translateService.getLabel('company') + ', ' + translateService.getLabel('valuation_date')"
            [matAutocomplete]="auto">
    
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <ng-template [ngIf]="showSavedEntities" [ngIfElse]="liveSearchOptions">
                <mat-option class="option-label">
                    {{ translateService.getLabel("recently_searched") }}
                </mat-option>
                <mat-option *ngFor="let option of matchingEntities" (onSelectionChange)="getRecentlySearchedOnEnter($event, option)" (click)="clickOnSavedOption(option)" class="option-value">
                    <span class="row">
                        <span class="col-10 column-alignment">
                            <ng-template ngFor [ngForOf]="option" let-comp let-i="index">
                                {{comp.name}}
                                    <ng-template [ngIf]="i < option.length - 1">
                                        /
                                    </ng-template>
                            </ng-template>
                        </span>

                        <span class="col-2 label-alignment">
                            <ng-template ngFor [ngForOf]="option" let-comp let-i="index">
                                <ng-template [ngIf]="i === option.length - 1">
                                    <ng-template [ngIf]=" comp.companyType == 'Equity'" [ngIfElse]="debtPortfolio">
                                        {{comp.companyType}}
                                    </ng-template>
                                    <ng-template #debtPortfolio>
                                        {{comp.companyType}}
                                    </ng-template>
                                </ng-template>
                            </ng-template>
                        </span>
                    </span>
                </mat-option>
            </ng-template>

            <ng-template #liveSearchOptions>
                <ng-template ngFor [ngForOf]="matchingEntities" let-option let-i="index">
                    <mat-option [value]="option.name"  (click)="addSelectedItemToChips(option)" class="option-value" [class.option-label]="option.type == 'label'">
                        
                        <ng-template [ngIf]="option.type == 'label'" [ngIfElse]="types">
                                {{option.name}}
                        </ng-template>
        
                        <ng-template #types>
                            <ng-template [ngIf]="option.type == 'FUND_AND_COMPANY'" [ngIfElse]="others">
                                <span class="row">
                                    <span class="col-10 column-alignment">
                                        {{option.fundName}} / <b>{{option.name}}</b>
                                    </span>
                                    <span class="col-2 label-alignment">
                                        <ng-template [ngIf]="option.companyType == 'Equity'" [ngIfElse]="debtPortfolio">
                                            {{option.companyType}}
                                        </ng-template>
                                        <ng-template #debtPortfolio>
                                            {{option.companyType}}
                                        </ng-template>
                                    </span>
                                </span>
                            </ng-template>
                            <ng-template #others>
                                <span class="row">
                                    <span class="col-10 column-alignment">
                                        {{option.name}}
                                    </span>
                                    <span class="col-2 label-alignment">
                                        <ng-template [ngIf]="option.companyType == 'Equity'" [ngIfElse]="debtPortfolio">
                                            {{option.companyType}}
                                        </ng-template>
                                        <ng-template #debtPortfolio>
                                            {{option.companyType}}
                                        </ng-template>
                                    </span>
                                </span>
                            </ng-template>
                        </ng-template>
                    </mat-option>
                </ng-template>
            </ng-template>

        </mat-autocomplete>
    </mat-chip-list>
    
</mat-form-field>

