<ng-template [ngIf] = "portfolioDataLoaded" [ngIfElse] = "portfolioDataNotLoaded">
  
  <!-- Reporting Currency Table Header and Add Company -->
  <div class="row" style="margin: auto 0 1%;">
    <div class="col-6">
      <span class="main-heading">{{ translateService.getLabel("reporting_currency")}} ({{ reportingCurrency }})</span>
    </div>
    
    <ng-template [ngIf] = "allowEdit">
      <div class="col-6 company-search-dropdown" style="text-align: right;">
        <!-- <mat-form-field style="width: 16.74vw; margin-right: 0.84vw;">
            <input matInput type="text" placeholder="Search" [(ngModel)]="sea rchString"
              (ngModelChange)="searchCompanies()">
            <mat-icon matSuffix>
              <i class="fas fa-search" style="color: #9D9D9D;"></i>
            </mat-icon>
          </mat-form-field> -->
        <button class="btn btn-add-company" (click)="addCompany()">
          {{translateService.getLabel("add_company")}}
        </button>
        <button class="btn-download-icon" (click)="excelExport()">
          <img src="assets/imgs/download.svg">
        </button>
        <button class="btn-delete-icon" (click)="deleteCompany()"
          *ngIf="currentPortfolioCompanies && currentPortfolioCompanies.length> 0">
          <img src="assets/imgs/delete.svg">
        </button>
      </div>
    </ng-template>
  </div>

  <!-- Portfolio Summary Header for Total Aggregations -->
  <div class="row pf-row pf-header-row">
    <div class="col pf-expand-icon"></div>

    <div class="col pf-header">{{translateService.getLabel("portfolio")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("currency")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("investment_amount")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("realised_proceeds")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("nav")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("total_value")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("gross_irr")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("moic")}}</div>

    <div class="col pf-numbers">{{translateService.getLabel("from_last_val_date")}}</div>
  </div>

  <!-- Summary Table In Reporting Currency -->
  <ng-template ngFor [ngForOf] = "typeOfPortfolios" let-typeOfPortfolio>
    <!-- Portfolio Summary Values with Respective Aggregations -->
    <div class="row pf-row pf-normal-row">
      <div class="col pf-expand-icon">
        <!--expance/collapse-->
        <i class="fas fa-chevron-right" (click)="portfolio[typeOfPortfolio].expandPortfolio = !portfolio[typeOfPortfolio].expandPortfolio"
          *ngIf="!portfolio[typeOfPortfolio].expandPortfolio"></i>
        <i class="fas fa-chevron-down" (click)="portfolio[typeOfPortfolio].expandPortfolio = !portfolio[typeOfPortfolio].expandPortfolio"
          *ngIf="portfolio[typeOfPortfolio].expandPortfolio"></i>
      </div>

      <div class="col pf-header">{{portfolio[typeOfPortfolio].name}}</div>

      <div class="col pf-numbers">{{reportingCurrency}}</div>

      <div class="col pf-numbers">
        {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalInvestment, 1, "", "0.0", false)}}
      </div>

      <div class="col pf-numbers">
        {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalRealisedProceeds, 1, "", "0.0", false)}}
      </div>

      <div class="col pf-numbers">
        {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalStakeValue, 1, "", "0.0", false)}}
      </div>

      <div class="col pf-numbers">
        {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalTotalValue, 1, "", "0.0", false)}}
      </div>

      <div class="col pf-numbers">

        <ng-template [ngIf] = "getTypeOfAggregationByPortfolio(typeOfPortfolio).totalIRR == 'loading'" [ngIfElse] = "portfolioIRRLoaded">
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20">
          </mat-spinner>
        </ng-template>

        <ng-template #portfolioIRRLoaded>
          <ng-template [ngIf]="getTypeOfAggregationByPortfolio(typeOfPortfolio).totalIRR >= 0" [ngIfElse] = "lessThanZeroIRR">
            {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalIRR, 1, "%", "0.0 %", false)}}
          </ng-template>

          <ng-template #lessThanZeroIRR>
            <span style="color: red;">
              {{utilService.getNegativeNumberDisplayFormat(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalIRR, 1, "%", "0.0 %")}}
            </span>
          </ng-template>
        </ng-template>
      </div>

      <div class="col pf-numbers">
        {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio).totalMOIC, 1, "x", "0.0 x", false)}}
      </div>

      <div class="col pf-numbers">
        <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage >= 0" [ngIfElse] = "lessThanZeroChangeInPercentge">
          {{utilService.getDisplayFormattedNumber(getTypeOfAggregationByPortfolio(typeOfPortfolio)
            .totalChangeInValPercentage, 1, "%", "0.0 %", false)}}
        </ng-template>
        <ng-template #lessThanZeroChangeInPercentge>
          <span style="color: red;">
            {{utilService.getNegativeNumberDisplayFormat(getTypeOfAggregationByPortfolio(typeOfPortfolio)
              .totalChangeInValPercentage, 1, "%", "0.0 %")}}
          </span>
        </ng-template>
      </div>
    </div>

    <!-- Portfolio Summary Table with Respective Companies -->
    <div class="row pf-row pf-normal-row" style="margin: 0 10px 10px 10px" *ngIf="portfolio[typeOfPortfolio].expandPortfolio">
      <app-equity-portfolio-grid [portfolioData]="getTypeOfCompaniesByPortfolio(typeOfPortfolio)" currencyKey="valuesInPortfolioCurrency"
        [totalAggregations]="getTypeOfAggregationByPortfolio(typeOfPortfolio)" [showAggregations]="true" (emitAction) = "emittingActionOnPortfolio($event)"
        currencyToBeDisplayed = "portfolioCurrency" (showHideColumnsUpdated)="updateShowHideColumns('reportingCurrencyExitedPortfolio', $event)" [showHideColumns]="showHideColumns.reportingCurrencyExitedPortfolio">
      </app-equity-portfolio-grid>
    </div>
  </ng-template>

  <!-- Total Aggregation of Summary -->
  <div class="row pf-row pf-footer-row">
    <div class="col pf-expand-icon"></div>

    <div class="col pf-header">{{translateService.getLabel("total(current+realised)")}}</div>

    <div class="col pf-numbers">{{reportingCurrency}}</div>

    <div class="col pf-numbers">
      {{ totalAggregations.totalInvestment | number: "1.1-1"}}
    </div>

    <div class="col pf-numbers">
      {{ totalAggregations.totalRealisedProceeds | number: "1.1-1"}}
    </div>

    <div class="col pf-numbers">
      {{ totalAggregations.totalStakeValue | number: '1.1-1' }}
    </div>

    <div class="col pf-numbers">
      {{ totalAggregations.totalTotalValue | number: '1.1-1' }}
    </div>

    <div class="col pf-numbers">
      <ng-template [ngIf] = "totalAggregations.totalIRR == 'loading'" [ngIfElse] = "portfolioTotalIRRLoaded">
        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20">
        </mat-spinner>
      </ng-template>
      
      <ng-template #portfolioTotalIRRLoaded>
        <ng-template [ngIf]="!totalAggregations.totalIRR || totalAggregations.totalIRR >= 0">
          {{utilService.getDisplayFormattedNumber(totalAggregations.totalIRR, 1, "%", "0.0 %", false)}}
        </ng-template>
  
        <ng-template [ngIf]="totalAggregations.totalIRR < 0">
         <span style="color: red;">{{utilService.getNegativeNumberDisplayFormat(totalAggregations.totalIRR, 1, "%", "0.0 %")}}</span>
        </ng-template>
      </ng-template>
    </div>

    <div class="col pf-numbers">
      {{utilService.getDisplayFormattedNumber(totalAggregations.totalMOIC, 1, "x", "0.0 x", false)}}
    </div>

    <div class="col pf-numbers">
      <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage >= 0">
        {{utilService.getDisplayFormattedNumber(totalAggregations.totalChangeInValPercentage, 1, "%", "0.0 %", false)}}
      </ng-template>
      <ng-template [ngIf]="totalAggregations.totalChangeInValPercentage < 0">
        <span style="color: red;">
          {{utilService.getNegativeNumberDisplayFormat(totalAggregations.totalChangeInValPercentage, 1, "%", "0.0 %")}}
        </span>
      </ng-template>
    </div>
  </div>

  <ng-template [ngIf] = "showCompaniesInLocalCurrency">
    <!-- Summary Table in Local Currency -->
    <div style="padding: 0.56vw; margin-top: 2%;">
      <span class="main-heading">{{translateService.getLabel("local_currency")}} - {{translateService.getLabel("current_portfolio")}}</span>
    </div>

    <app-equity-portfolio-grid [portfolioData]="currentPortfolioCompanies" currencyKey="valuesInLocalCurrency" 
    (emitAction) = "emittingActionOnPortfolio($event)" currencyToBeDisplayed = "localCurrency" (showHideColumnsUpdated)="updateShowHideColumns('reportingCurrencyExitedPortfolio', $event)" [showHideColumns]="showHideColumns.reportingCurrencyExitedPortfolio">
    </app-equity-portfolio-grid>

    <ng-template [ngIf]="exitedPortfolioCompanies && exitedPortfolioCompanies.length > 0">
      <div style="padding: 0.56vw; margin-top: 2%;">
        <span class="main-heading">{{translateService.getLabel("local_currency")}} - {{translateService.getLabel("realised_portfolio")}} </span>
      </div>

      <app-equity-portfolio-grid [portfolioData]="exitedPortfolioCompanies" currencyKey="valuesInLocalCurrency"
        [exitGrid]="true" (emitAction) = "emittingActionOnPortfolio($event)" currencyToBeDisplayed = "localCurrency" (showHideColumnsUpdated)="updateShowHideColumns('reportingCurrencyExitedPortfolio', $event)" [showHideColumns]="showHideColumns.reportingCurrencyExitedPortfolio">
      </app-equity-portfolio-grid>
    </ng-template>
  </ng-template>
  
</ng-template>

<a style="display: none;" #downloadExcelPortfolio></a>

<ng-template #portfolioDataNotLoaded>
  <div class="loading-container">
    <span class="loading-text">
      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
    </span>
  </div>
</ng-template>



