<div class="sub-header">
  <div class="mat-h2">
    Financials and General Details Recon
  </div>
  <div>
    Total Error count {{errorCountForFinsGdAndProfile + errorCountForAtrributeDetailsRecon + errorCountForUserCountRecon + errorCountUserCompanyDetailsRecon}}

    <div>
    </div>
  </div>
  <div>
    <button mat-icon-button color="primary" (click)="refreshData()"><mat-icon>autorenew</mat-icon></button>
  </div>
</div>

<div class="dr-container">
  <div class="wrapper">
    <div class="date-label">
      Client Filter
    </div>
    <mat-form-field>
      <mat-label>Clients</mat-label>
      <mat-select [formControl]="clientsFc" (selectionChange)="onClientSelection($event)">
        <mat-option *ngFor="let c of clients" [value]="c.orgId">{{c.orgName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="wrapper error-count">
    <div class="date-label">
      Error Count {{errorCountForFinsGdAndProfile}}
    </div>
    <div>
      
    </div>
    <div>
      Sync Status - <ng-container *ngIf="statusLoaded && financialsGeneralAndProfileDetails"> In Progress</ng-container> <ng-container *ngIf="statusLoaded && !financialsGeneralAndProfileDetails"> Completed</ng-container>
    </div>
  </div>
</div>
<div>
  <app-drd-financials-general-details [finsAndGd]="finsAndGdDiff"></app-drd-financials-general-details>
</div>

<!-- Snowflake - Financials and General Details Recon -->
<div class="snowflake-financial-and-gd">
  <div class="mat-h2">
    Snowflake - Financials and General Details Recon
  </div>
  <div class="errs">
    Error Count {{errorCountForSnowflakeFinsGdAndProfile}}
    <div>
    </div>
  </div>
  <div>
    Sync Status - <ng-container *ngIf="statusLoaded && snowflakeFinancialsGeneralAndProfileDetails"> In Progress</ng-container> <ng-container *ngIf="statusLoaded && !financialsGeneralAndProfileDetails"> Completed</ng-container>
  </div>
</div>
<div>
  <app-drd-snowflake-financials-general-details [snowflakeFinsAndGd]="snowflakeFinsAndGdDiff"></app-drd-snowflake-financials-general-details>
</div>
<!------------- end ------------>

<div class="attrs-recon">
  <div class="mat-h2">
    Attributes Details Recon
  </div>
  <div class="errs">
    Error Count  {{errorCountForAtrributeDetailsRecon}}
    <div>
     
    </div>
  </div>
  <div>
    Sync Status - <ng-container *ngIf="statusLoaded && attributeDetails"> In Progress</ng-container> <ng-container *ngIf="statusLoaded && !attributeDetails"> Completed</ng-container>
  </div>
</div>
<div>
  <app-drd-attr-details [attrsDiffDetails]="attributeDetailsRecon"></app-drd-attr-details>
</div>

<!-- <div class="user-details-recon">
  <div class="mat-h2">
    User Details Recon
  </div>
  <div class="errs">
    Error Count  {{errorCountUserCompanyDetailsRecon}}
    <div>
     
    </div>
  </div>
  <div>
    Sync Status - <ng-container *ngIf="statusLoaded && userCompanyDetails"> In Progress</ng-container> <ng-container *ngIf="statusLoaded && !userCompanyDetails"> Completed</ng-container>
  </div>
</div>
<div>
  <app-drd-user-details [companyCount]="userCompanyDetailsRecon"></app-drd-user-details>
</div> -->

<!-- <div class="user-count-recon">
  <div class="mat-h2">
    User Count Recon
  </div>
  <div class="errs">
    Error Count {{errorCountForUserCountRecon}}
    <div>
      
    </div>
  </div>
  <div>
    Sync Status - <ng-container *ngIf="statusLoaded && userDetails"> In Progress</ng-container> <ng-container *ngIf="statusLoaded && !userDetails"> Completed</ng-container>
  </div>
</div>
<div>
  <app-drd-user-count [userCount]="userCountRecon"></app-drd-user-count>
</div> -->