<div class="card" [class.additional-top-margin]="addTopMargin">
  <div class="card-body content">
    <div class="d-flex">
      <img id="image" src="{{ img }}" style="max-height: 40px; max-width:50px;"  alt="" />
      <h5 class="card-title p-3 ">{{ title }}</h5>
      <span class="p-3"> by 73 Strings </span>

    </div>
    <p class="card-text" style="height: 20vh; padding-top: 3px" [innerHTML]="text"></p>

    <!-- Button trigger modal 
      (click)="openModal(integrationPopup)" -->
    <button
      type="button"
      class="btn btn-primary"
      id="button" 
      >
      Manage integration on the go!
    </button>
  </div>
</div>


<ng-template #integrationPopup let-integrationPopupModal>
  <div class="integration-popup-modal">
    <div class="modal-header">
      <div class="modal-title">
        Manage Integration
      </div>
      <button type="button" class="close" aria-label="Close" (click)="integrationPopupModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">

      <div class="d-flex">
        <img src="{{ img }}" height="40px" width="auto" alt="" />
        <h5 class="card-title p-2">{{ title }}</h5>
        <span class="p-2 mt-1 fa-solid"> by 73 Strings </span>       
      </div>

      <div>
       <p class="modal-text"> Add and preview {{title}} files directly in 73 Strings SaaS Platform with real time sync.</p> 
      </div>

      <label for="url" class="my-3 pt-1 label-text" ><strong>Add URL</strong></label>
      <input id="url" type="text" class="form-control" placeholder="Add URL placeholder">

      <label for="organization" class="my-3 pt-1 label-text" ><strong>Add Organization</strong></label>
      <input id="organization" type="text" class="form-control" placeholder="Add organization placeholder">
    </div>

    <div class="modal-footer">
      <button type="button" (click)="integrationPopupModal.dismiss('Cross click')" id="cancel-btn" class="btn btn-secondary" data-bs-dismiss="modal"> Cancel </button>
      <button type="button" id="apply-btn" class="btn btn-primary">Apply Integration</button>
    </div> 
  </div>
</ng-template>

