import { OnInit, Component } from '@angular/core';
import { PortFolioService } from '../portfolio/portfolio.service';
import { QXP_PortFolioService } from './qxp-portfolio.service';

@Component({
  selector: 'app-corporate',
  templateUrl: './corporate.component.html',
  styleUrls: ['./corporate.component.scss']
})
export class CorporateComponent implements OnInit {

  constructor(public portfolioService: QXP_PortFolioService)
  {}

  ngOnInit() {
    
  }
}