import { Component, Input, OnInit } from '@angular/core';
import { CDK_DRAG_CONFIG, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};
providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
@Component({
  selector: 'app-qualitative-analysis-comments',
  templateUrl: './qualitative-analysis-comments.component.html',
  styleUrls: ['./qualitative-analysis-comments.component.scss']
})
export class QualitativeAnalysisCommentsComponent implements OnInit {

  constructor() { }
  @Input() qualitativeComments;
  @Input() evenIndexComments;
  @Input() oddIndexComments;

  ngOnInit(): void {
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
        );
        if(event.container.data[event.currentIndex]['order'] == 'left'){
          event.container.data[event.currentIndex]['order'] = 'right';
        }
        else{
          event.container.data[event.currentIndex]['order'] = 'left';
        }
      }
  }

}

