import { Component } from '@angular/core';
import { ImageLoadService } from '../services/image-load.service';
 
@Component({
  selector: 'app-scheduled-down',
  templateUrl: './app-scheduled-down.component.html',
  styleUrls: ['./app-scheduled-down.component.scss']
})
export class AppScheduledDownComponent {
   constructor(public loaderService:ImageLoadService) {}
}