import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-data-recon-dashboard',
  templateUrl: './data-recon-pluto.component.html',
  styleUrls: ['./data-recon-pluto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataReconPlutoComponent {

}
