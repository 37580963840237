<div style="text-align: center;">
  <span>{{latestCompanyValuationDate.valuationDate | date: "mediumDate"}} | {{latestCompanyValuationDate.versionName}}</span>
  <i class="fas fa-times cross-icon" (click)="userAction('CLOSE')"></i>
</div>

<ng-template [ngIf] = "!loadingConsolSummary" [ngIfElse] = "consolSummaryLoading">
  <ngb-tabset>
    <ngb-tab title="Consol Summary" id="consol-summary">
      <ng-template ngbTabContent>
        <div class="top-buttons" *ngIf = "!portfolioSummaryService.currentUserCompanyPermissionReadOnly">
            <button type="button" class="btn-custom-primary mat-button mat-button-base" (click)="editAdjustmentPopup(editAdjustment)">
              <span aria-hidden="true"> Edit Adjustments </span>
            </button>
        </div>

        <div class="row" style="margin: 10px;" *ngIf="businessUnitsWaterFall">
          <div class="col-7">
            <div [chart]="businessUnitsWaterFall"></div>
          </div>

          <div class="col-5 table-style">
            <div class="consol-summary" style="width: 100%;">
              <div class="row border-top-radius secondary-light-blue-sub-head sub_head">
                <div class=" col-8 headers" style="font-size: medium; font-weight: bold;">
                  Consolidated Enterprise Value ({{consolSummary.currency}} Mn)
                </div>

                <div class="col-1"></div>

                <div class="col-3" style="text-align: right; font-size: medium; font-weight: bold;">
                  <span [RawDataDirective]="consolSummary.sumOfTheParts">
                  {{consolSummary.sumOfTheParts | number: "1.1-1"}}
                </span>
                </div>
              </div>

              <div class="row table_body" *ngFor="let adj of consolSummary.sumOfPartsAdj; let sIndex=index" style="padding:.45rem">
                <div class=" col-8 headers" style="padding-left:2vw;font-style: italic;">
                  {{adj.name}}
                </div>

                <div class="col-1"  style="text-align: right;" *ngIf="adj.type == 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('add-circle.svg')" alt="add"/>
                </div>

                <div class="col-1" style="text-align: right;" *ngIf="adj.type !== 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('minus-circle.svg')" alt="add"/>
                </div>

                <div class="col-3" *ngIf="adj.value < 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw; color: red;">
                    <span [RawDataDirective]="adj.value * -1">
                    ({{adj.value * -1 | number: "1.1-1"}})
                  </span>
                  </span>
                </div>

                <div class="col-3" *ngIf="adj.value >= 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw;" [RawDataDirective]="adj.value">
                    {{adj.value | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <div class="row secondary-light-blue-sub-head sub_head no-vertical-borders">
                <div class=" col-8 headers ">Implied Fair Equity Value ({{consolSummary.currency}} Mn)</div>

                <div class=" col-1"></div>

                <div class=" col-3" style="text-align: right;">
                  <span [RawDataDirective]="consolSummary.weightedEquityValue">
                  {{consolSummary.weightedEquityValue | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <div class="row table_body" *ngFor="let adj of consolSummary.adjustmentsOnWeightedEquityValue; let sIndex=index" style="padding:.45rem">
                <div class=" col-8 headers" style="padding-left:2vw;font-style: italic;">
                  {{adj.name}}
                </div>

                <div class="col-1"  style="text-align: right;" *ngIf="adj.type == 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('add-circle.svg')" alt="add"/>
                </div>

                <div class="col-1" style="text-align: right;" *ngIf="adj.type !== 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('minus-circle.svg')" alt="add"/>
                </div>

                <div class="col-3" *ngIf="adj.value < 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw; color: red;">
                    ({{adj.value * -1 | number: "1.1-1"}})
                  </span>
                </div>

                <div class="col-3" *ngIf="adj.value >= 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw;">
                    {{adj.value | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <div class="row table_body sub_header">
                <div class="col-8 headers " style="padding-left: 2vw;">Concluded Adjusted Fair Value of Equity ({{consolSummary.currency}} Mn)</div>

                <div class="col-1"></div>

                <div class="col-3"  style="text-align: right;">
                  <span [RawDataDirective]="consolSummary.finalAdjustedEquityVal">
                  {{consolSummary.finalAdjustedEquityVal | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <div class="row table_body sub_header">
                <div class="col-8 headers text-blue" style="padding-left: 2vw;">
                  <ng-template [ngIf] = "consolSummary.selectedTypeOfStakeValue == 'sumOfTheUnits'" [ngIfElse] = "notSumOfTheUnits">
                    <span>Fully Diluted Stake</span>
                  </ng-template>
                  <ng-template #notSumOfTheUnits>
                    <span class="click-enabled" (click)="customStakePopup(stakePopup)">Fully Diluted Stake</span>
                  </ng-template>
                </div>

                <div class="col-1"></div>

                <div class="col-3" style="text-align: right; padding-right: 15px;">
                  <span style="padding-right: 4.5vw;" [RawDataDirective]="consolSummary.stake">
                    {{consolSummary.stake | number: '1.2-2'}} %
                  </span>
                </div>
              </div>

              <div class="row table_body" style="font-weight: bold;">
                <div class="col-8 headers sub_head no-vertical-borders">
                  <ng-template [ngIf]="consolSummary.selectedTypeOfStakeValue == 'fromWaterFallSummary'" 
                  [ngIfElse] = "notFromWaterFall">
                    <span class="click-enabled" (click)="customStakePopup(waterFallData)">
                      Fair Equity Value of the Stake
                    </span> 
                    ({{consolSummary.currency}} Mn) &nbsp;
                  </ng-template>

                  <ng-template #notFromWaterFall>
                    <span>Fair Equity Value of the Stake</span> ({{consolSummary.currency}} Mn) &nbsp;
                  </ng-template>

                  <mat-form-field class="custom-input-select" style="width: 180px;">
                    <mat-label>Select Type</mat-label>
                    <mat-select name="type" 
                    [(ngModel)]="consolSummary.selectedTypeOfStakeValue" 
                    [disabled] = "portfolioSummaryService.currentUserCompanyPermissionReadOnly"
                    (ngModelChange)="stakeValueTypeChanged(true)">
                      <mat-option [value]="'fullyDilutedShares'" > Fully Diluted Stake </mat-option>
                      <mat-option [value]="'fromWaterFallSummary'"> Waterfall Summary </mat-option>
                      <mat-option [value]="'sumOfTheUnits'"> Sum of the Units </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-1"></div>

                <div class="col-3 sub_head no-vertical-borders" style="text-align: right;">
                  <span [RawDataDirective]="consolSummary.stakeValue">
                  {{consolSummary.stakeValue | number: "1.1-1"}}
                </span>
                </div>
              </div>

              <div class="row table_body" *ngFor="let adj of consolSummary.finalAdjustments; let aIndex=index" style="font-style: italic;">
                <div class="col-8 headers" style="padding-left:2vw;font-style: italic;">
                  {{adj.name}}
                </div>

                <div class="col-1" style="text-align: right;" *ngIf="adj.type == 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('add-circle.svg')" alt="add"/>
                </div>

                <div class="col-1" style="text-align: right;" *ngIf="adj.type !== 'Add'">
                  <img  width=15 height=15 [src] = "imageLoadService.getImage('minus-circle.svg')" alt="add"/>
                </div>

                <div class="col-3" *ngIf="adj.value < 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw; color: red;">
                    ({{adj.value * -1 | number: "1.1-1"}})
                  </span>
                </div>

                <div class="col-3" *ngIf="adj.value >= 0" style="text-align: right;">
                  <span style="padding-right: 4.5vw;">
                    {{adj.value | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <div class="row secondary-light-blue-sub-head sub_head no-vertical-borders"
              [style.font-weight] = "thresholdValuationChange == THRESHOLD_VALUATION_CHANGE.NO_VALUATION_CHANGE 
                ? 'normal' : 'bold'">
                <div class="col-8 headers">
                  Concluded Fair Equity Value of the Stake({{consolSummary.currency}} Mn)
                </div>

                <div class="col-1"></div>

                <div class="col-3" style="text-align: right;">
                  <i [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')" 
                  class="fas fa-layer-group blink" 
                    *ngIf = "thresholdService.thresholdData.configurations.thresholdSelected && 
                    thresholdService.thresholdData.analysis.isThereAValuationChange 
                    != thresholdValuationChange">
                  </i>
                  <span [RawDataDirective]="consolSummary.actualStakeValue">
                  {{consolSummary.actualStakeValue | number: "1.1-1"}}
                  </span>
                </div>
              </div>

              <ng-template [ngIf] = "thresholdValuationChange == THRESHOLD_VALUATION_CHANGE.NO_VALUATION_CHANGE">
                <div class="row table_body">
                  <div class="col" style="padding: 14px 0px;"></div>
                </div>

                <div class="row secondary-light-blue-sub-head sub_head no-vertical-borders">
                  <div class="col-8 headers">
                    {{translateService.getLabel("fair_value_of_investment_as_per_pervious_mark")}} ({{consolSummary.currency}} Mn)
                  </div>

                  <div class="col-1"></div>

                  <div class="col-3" style="text-align: right;">
                    <i class="fas fa-layer-group blink" id="threshold-blinker" 
                      [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')"
                      *ngIf = "thresholdService.thresholdData.configurations.thresholdSelected == false 
                      && thresholdValuationChange == THRESHOLD_VALUATION_CHANGE.NO_VALUATION_CHANGE">
                    </i>
                    {{consolSummary.finalStakeValue | number: "1.1-1"}}
                  </div>
                </div>
              </ng-template>

              <div class="row table_body sub_header">
                <div style="padding-left: 2vw;"  class="col-8 headers ">No. of Shares Outstanding (Investor Level)</div>

                <div class="col-1"></div>

                <div class="col-3" style="text-align: right; padding-right: 5px;">
                  <mat-form-field class="custom-textbox editable-form-field" >
                    <input matInput type="number"
                    [disabled] = "portfolioSummaryService.currentUserCompanyPermissionReadOnly"
                    [(ngModel)]="consolSummary.numberOfShares"
                    (focusout) = "saveConsolSummaryData(consolSummary)">
                  </mat-form-field>
                </div>
              </div>

              <div  class="row table_body sub_header">
                <div style="padding-left: 2vw;" class="col-8 headers ">Fair Equity Value of Stake per Share({{consolSummary.currency}})</div>

                <div class="col-1"></div>

                <div class="col-3"  style="text-align: right;">
                  <ng-template [ngIf] = "consolSummary.numberOfShares > 0" [ngIfElse] = "zeroPriceShare">
                    {{ (consolSummary.finalStakeValue * 1000000 / consolSummary.numberOfShares)| number:"1.1-1"}}
                  </ng-template>
                  <ng-template #zeroPriceShare>
                    0.0
                  </ng-template>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- Threshold MBU -->
        <div class="row" style="margin: 10px;" *ngIf = "consolSummary">
          <div class="col-7">
            <app-threshold 
              [consolFormId] = "latestCompanyValuationDate.id" 
              [fundId] = "fundId" 
              [valuationCurrency] = "currency"
              [dataRefresh] = "dataRefresh"
              [thresholdValuationChange] = "thresholdValuationChange"
              (refreshThresholdData) = "refreshThresholdData(true)">
            </app-threshold>
          </div>
          <div class="col-5">
          </div>
        </div>

        <!-- SOTP Adjustments Summary -->
        <ng-template [ngIf] = "adjustmentTracker">
          <div class="row" style="margin: 0; margin-top: 2%; padding-left: 0;">
            <span class="header">
              SOTP Summary of Adjustments
            </span>
          </div>

          <div class="row" style="margin: 10px auto;">
            <table class="table table-data">
              <thead>
                <tr class="table-header">
                  <th></th>
                  <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                    <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                      {{multiple.companyNameInForm}}
                    </td>
                  </ng-template>
                  <th style="text-align: right;">SOTP</th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>Revenue</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.adjustedRevenue, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.adjustedRevenueSOTP, 1, "", "NMF", false) }}</td>
                  </tr>

                  <tr>
                    <td><i>% of SOTP</i></td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        <i>{{ utilService.getDisplayFormattedNumber(multiple.percentageOfRevenueSOTP, 1, "%", "NMF", false) }}</i>
                      </td>
                    </ng-template>
                    <td style="text-align: right;"><i>{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalPercentageOfRevenueSOTP, 1, "%", "NMF", false) }}</i></td>
                  </tr>

                  <tr>
                    <td>EBITDA</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.bevEbitda, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.ebitdaSOTP, 1, "", "NMF", false) }}</td>
                  </tr>

                  <tr>
                    <td><i>% of SOTP</i></td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        <i>{{ utilService.getDisplayFormattedNumber(multiple.percentageOfEbitdaSOTP, 1, "%", "NMF", false) }}</i>
                      </td>
                    </ng-template>
                    <td style="text-align: right;"><i>{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalPercentageOfEbitdaSOTP, 1, "%", "NMF", false) }}</i></td>
                  </tr>

                  <tr *ngFor="let adjKey of adjustmentTracker.allAdjustmentKeys">
                    <td>{{adjKey}}</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple[adjKey].value, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp[adjKey+'SOTP'], 1, "", "NMF", false) }}</td>
                  </tr>

                  <tr>
                    <td>Total Adjustments</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.totalAdjustment, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalAdjustmentSOTP, 1, "", "NMF", false) }}</td>
                  </tr>

                  <tr>
                    <td>PF Adjusted EBITDA</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.adjustedEbitda, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.adjustedEbitdaSOTP, 1, "", "NMF", false) }}</td>
                  </tr>

                  <tr>
                    <td> <i>% Margin</i> </td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        <i>{{ utilService.getDisplayFormattedNumber(multiple.percentageOfMargin, 1, "%", "NMF", false) }}</i>
                      </td>
                    </ng-template>
                    <td style="text-align: right;"><i>{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalPercentageOfMarginSOTP, 1, "%", "NMF", false) }}</i></td>
                  </tr>

                  <tr>
                    <td> <i>% Contribution</i> </td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        <i>{{ utilService.getDisplayFormattedNumber(multiple.percentageOfAdjustedEbitdaSOTP, 1, "%", "NMF", false) }}</i>
                      </td>
                    </ng-template>
                    <td style="text-align: right;"><i>{{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalPercentageOfAdjustedEbitdaSOTP, 1, "%", "NMF", false) }}</i></td>
                  </tr>

                  <tr>
                    <td>Implied EBITDA Multiple</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.ebitdaMultiple, 1, "x", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">
                      {{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.impliedEbitdaMultiple, 1, "x", "NMF", false) }}
                    </td>
                  </tr>

                  <tr>
                    <td>Implied Revenue Multiple</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.revenueMultiple, 1, "x", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">
                      {{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.impliedRevenueMultiple, 1, "x", "NMF", false) }}
                    </td>
                  </tr>

                  <tr>
                    <td>Enterprise Value</td>
                    <ng-template ngFor [ngForOf] = "adjustmentTracker.consolSummaryAdjustments" let-multiple>
                      <td style="text-align: right;" *ngIf = "isBuSubmitted(multiple.formId)">
                        {{ utilService.getDisplayFormattedNumber(multiple.enterpriseValue, 1, "", "NMF", false) }}
                      </td>
                    </ng-template>
                    <td style="text-align: right;">
                      {{ utilService.getDisplayFormattedNumber(adjustmentTracker.sotp.totalEnterpriseValue, 1, "", "NMF", false) }}
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ng-template>
    </ngb-tab>

    <!-- <ngb-tab title="Cap Table" id="cap-table">
      <ng-template ngbTabContent>
        <app-cap-table2 [latestCompanyValuationDate]="latestCompanyValuationDate" (stakeChange) = "stakeChangeEvent($event)" ></app-cap-table2>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="Waterfall" id="water-fall">
      <ng-template ngbTabContent>
        <div style="padding-top: 30px;">
          <app-cca [latestCompanyValuationDate]="latestCompanyValuationDate" [investmentDate]="investmentDateString" (stakeChangeEvent) = "stakeChangeEvent($event)"></app-cca>
        </div>
      </ng-template>
    </ngb-tab> -->

    <ngb-tab title="Cap Table + Waterfall Analysis" id="cap-table-waterfall">
      <ng-template ngbTabContent>
        <app-captable-waterfall-versioning [latestCompanyValuationDate]="latestCompanyValuationDate" [investmentDate]="investmentDateString" [companyId]="primaryCompanyId"></app-captable-waterfall-versioning>
      </ng-template>
    </ngb-tab>

    <ngb-tab title="Additional Analysis" id="addition_analysis">
      <ng-template ngbTabContent>
        <app-additional-analysis [latestCompanyValuationDate]="latestCompanyValuationDate" [investmentDate]="investmentDateString" [companyId]="primaryCompanyId" [consolSummary]="consolSummary" ></app-additional-analysis>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</ng-template>

<ng-template #consolSummaryLoading>
  <div class="loading-container">
    <p class="loading-text">Loading Consol Summary...</p>
  </div>
</ng-template>

<!-- Edit Adjustments PopUp-->
<ng-template #editAdjustment let-adjustment>
  <div class="modal-header">
    <div class="modal-title">
      <span class="header">
        <h4>Edit Adjustments</h4>
      </span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="adjustment.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <mat-form-field class="consol-adjustment-input-select">
      <mat-label>Select Type</mat-label>
      <mat-select name="type" [(ngModel)]="selectedAdjustmentType" (ngModelChange)="initAdjustmentObject()">
          <mat-option value="consolidatedEnterprise">Consolidated Enterprise Value</mat-option>
          <mat-option value="impliedFairEquityValue">Implied Fair Equity Value</mat-option>
          <mat-option value="stakeFairEquityValue">Fair Equity Value of the Stake </mat-option>
      </mat-select>
    </mat-form-field>

    <br>

    <app-adjustments
      [totalValueBeforeAdjustment]="adjustmentsObject.totalValueBeforeAdjustment"
      [headerLabel] = "adjustmentsObject.headerLabel"
      (finalAdjustments)="adjustmentsChanged($event);"
      [existingAdjustments]="adjustmentsObject.adjustments">
    </app-adjustments>

  </div>
</ng-template>

<!-- Stake -->
<ng-template #stakePopup let-customStake>
  <div class="modal-header">
    <div class="modal-title">
      <h4>Stake</h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="customStake.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <app-custom-stake-excel
          [stakeModel]="stakeModel"
          [investmentConsolID]="latestCompanyValuationDate.id"
          [consolSummary]="consolSummary"
          (updatedExcelData)="initCustomStakeUpdate('ALL', true); customStake.dismiss('Cross click')"
          (cancel)="customStake.dismiss('Cross click')">
      </app-custom-stake-excel>
  </div>
</ng-template>


<ng-template #waterFallData  let-waterFallmodel>
  <div class="sl-summary-popup">
    <div class="modal-header">
      <div class="modal-title">
        <h4> <span class="header"> Waterfall Summary ({{currency}} Mn)</span></h4>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="waterFallmodel.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
  
      <div class="tableScroll">
        <table class="summary-table" *ngIf = "slSummaryTable && slSummaryTable.length > 0">
          <ng-template ngFor [ngForOf] = "slSummaryTable" let-rows let-rowIndex = "index">
            <tr class=" border-top-radius row-height">
              <ng-template ngFor [ngForOf] = "rows" let-eachHeader let-eachHeaderIndex = "index">
                <th class="table-custom-header-yellow table-column-seperator col-width cell-padding" *ngIf = "rowIndex == 0" [ngClass]="{'freeze-col1 table-row-seperator align-left': eachHeaderIndex == 0, 'freeze-col2 align-right': eachHeaderIndex > 0}">
                  {{ eachHeader }}
                </th>
                <td style="background: white;" class="col-width cell-padding" *ngIf = "rowIndex > 0 && rowIndex != (slSummaryTable.length - 1)"  [ngClass]="{'freeze-col1 ': eachHeaderIndex == 0, 'freeze-col2 align-right': eachHeaderIndex > 0}">
                  {{ eachHeader }}
                </td>
                <td class="table-custom-header-yellow col-width cell-padding" *ngIf = "rowIndex == (slSummaryTable.length - 1)"  [ngClass]="{'freeze-col1': eachHeaderIndex == 0, 'freeze-col2 align-right': eachHeaderIndex > 0}">
                  {{ eachHeader }}
                </td>
              </ng-template>
            </tr>
          </ng-template>
        </table>
      </div>  
    </div>
  </div>
</ng-template>

<!-- Add Adjustments - Consol Summary - Business Units -->
<ng-template #consolAdjustments let-consolAdjustmentsModal>
  <div class="modal-header">
    <div class="modal-title">

    </div>
    <button type="button" class="close" aria-label="Close" (click)="consolAdjustmentsModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" (ngSubmit)="addConsolAdjustments(); consolAdjustmentsModal.dismiss('Cross click')">
      <div class="form-group">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-3">
            <mat-label>Enter name</mat-label>
          </div>
          <div class="col-4" style="text-align: right;">
            <mat-form-field >
              <input matInput style="text-align: right;" type="text" name="namefield" id="namefield" [(ngModel)]="newConsolAdjObject.name" autocomplete="off" required>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-3">
            <mat-label>Enter number</mat-label>
          </div>
          <div class="col-4" style="text-align: right;">
            <mat-form-field >
              <input matInput style="text-align: right;" type="number" name="numberfield" id="numberfield"
                [(ngModel)]="newConsolAdjObject.numberBeforeConversion" autocomplete="off" required
                (ngModelChange)="getCurrencyExchange(newConsolAdjObject, $event, consolSummary.currency, newConsolAdjObject.userSelectedCurrency)">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-3">
            <mat-label>Type</mat-label>
          </div>
          <div class="col-4" style="text-align: right;" >
            <mat-form-field >
              <mat-select name="typeSelect" style="text-align: right;" id="typeSelect" [(ngModel)]="newConsolAdjObject.type" required>
                <mat-option disabled value="">Select</mat-option>
                <mat-option value="Add">Add</mat-option>
                <mat-option value="Subtract">Subtract</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div align="center">
        <button mat-raised-button class="primary-bg" [disabled]="!f.form.valid">Save</button>
      </div>
    </form>
  </div>
</ng-template>
