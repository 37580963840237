import { Injectable, Injector } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilService } from 'src/app/utils/util.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICCARequest } from './input/model/input-model';
import * as moment from 'moment/moment';
import { OpmInputComponent } from "./opm-input/opm-input.component";
import { MatDialog } from "@angular/material";


@Injectable({
  providedIn: 'root'
})
export class CCAService {
  widgetKey = "CCA_MANPACK";
  widgetkeyExcel = "CCA_MANPACK_EXCEL";
  widgeKeyPageData = "CCA_MANPACK_PAGEDATA";

  shareHolderLoanWidgetKey = "CCA_SHAREHOLDERLOAN_DATA";

  manPackAnalysisReady = false;
  manPackAnalysisData: any = {};
  manPackExcelAnalysisReady = false;
  manPackExcelAnalysisData: any = {};
  input: any;
  dialogRef

  public lpInput =  new BehaviorSubject<ICCARequest>(null);
  public lpOutput = new BehaviorSubject<any>(null);
  public blackAndScholesInput = new BehaviorSubject<any>(null);
  public volitilityInput = new BehaviorSubject<any>(null);
  public riskFreeRateInput = new BehaviorSubject<any>(null);

  public manPackStakePer = new Subject();
  public slSummaryStakePer = new Subject();
  public manOptionStakePer = new Subject();
  public manOptionsExist = new Subject();

  public changeCCATab = new BehaviorSubject<string>(null);

  public manPackTab = 'manPackTab';
  public normalOptionTab = 'normalOptionTab';
  public manOptionTab = 'manOptionTab';
  public saleBonusTab = 'saleBonusTab';


  customStake = {
    stakeFromModel : { value: 0},
    bindEquityValue: {
      row: "",
      col: "",
      activeSheetIndex: "",
      activeSheetName: ""
    },
    bindStake: {
      row: "",
      col: "",
      activeSheetIndex: "",
      activeSheetName: ""
    }
  }

  showExcelUI = false;
  manpackUploadedJson;

  constructor(private ds: DataService,
    private utilService: UtilService,
    private msgService: MessageService,
    private dialog: MatDialog,
    private dataService:DataService,
    private injector: Injector){
  }

  latestCompanyValuationDate;
  investmentDateFormId


  setlatestCompanyValuationDate(data){
    this.latestCompanyValuationDate = data;
  }

  getlatestCompanyValuationDate(){
    return this.latestCompanyValuationDate
  }

  getHoldingPeriod(startDate, enddate) {
    return moment(new Date(enddate)).diff(moment(new Date(startDate)), 'months', true);
  }

  startAnalysis(data) {
    this.manPackAnalysisReady = false;

    let manPackDataToapi: any = {
      companyId: this.investmentDateFormId,
      fundId: localStorage.getItem('FUND_ID'),
      companyEquityValue: this.latestCompanyValuationDate.investment.equityValue.finalAdjustedEquityVal,

      moic: {
        investorStake: data.investorStake,
        manPacExists: data.manpackData,
        linkedToPreferredReturn: data.linkedToPreferredReturn,
        holdingPeriod: data.holdingPeriod,
        exitDate: data.exitDate,

        gradedVesting: data.gradedVesting
      }
    }

    if(data.linkedToPreferredReturn) {
      manPackDataToapi.moic.yield = data.yield;
    }

    manPackDataToapi.moic.moicCalculationBasedOn = data.moicCalculationBasedOn;
    manPackDataToapi.moic.manpackCalculationBasedOn = data.manpackCalculationBasedOn;

    if(data.moicCalculationBasedOn === '100PercentEquity') {
      manPackDataToapi.moic.totalInvestmentMOIC = this.utilService.getValidNumber(data.totalInvestmentMOIC);
      manPackDataToapi.moic.totalRealisedProceedsMOIC = this.utilService.getValidNumber(data.totalRealisedProceedsMOIC);

    } else {
      manPackDataToapi.moic.investorInvestmentMOIC = this.utilService.getValidNumber(data.investorInvestmentMOIC);
      manPackDataToapi.moic.investorRealisedProceedsMOIC = this.utilService.getValidNumber(data.investorRealisedProceedsMOIC);
    }


    if(data.manpackCalculationBasedOn === '100PercentEquity') {
      manPackDataToapi.moic.totalInvestmentManpack = this.utilService.getValidNumber(data.totalInvestmentManpack);
      manPackDataToapi.moic.totalRealisedProceedsManpack = this.utilService.getValidNumber(data.totalRealisedProceedsManpack);

    } else {
      manPackDataToapi.moic.investorInvestmentManpack = this.utilService.getValidNumber(data.investorInvestmentManpack);
      manPackDataToapi.moic.investorRealisedProceedsManpack = this.utilService.getValidNumber(data.investorRealisedProceedsManpack);
    }

    if(data.gradedVesting) {
      manPackDataToapi.moic.capOnPool = data.capOnPool;
      manPackDataToapi.moic.poolCap = data.poolCap;
      manPackDataToapi.moic.timeVesting = data.timeVesting;

      manPackDataToapi.moic.percentageOfTimeVesting = data.percentageOfTimeVesting;
      manPackDataToapi.moic.percentageOfTimeVesting = data.percentageOfTimeVesting;
      manPackDataToapi.moic.periodOfTimeVesting = data.periodOfTimeVesting;

      if(data.gradingCalculationBasedOn == "both") {
        manPackDataToapi.moic.moicBuckets = data.moicBuckets.map(rwo => {
          return {
            "lowerBound": rwo.lowerBound,
            "upperBound": rwo.upperBound,
            "valuePercentage": rwo.valuePercentage,
          }
        });
        manPackDataToapi.moic.irrBuckets = data.irrBuckets.map(rwo => {
          return {
            "lowerBound": rwo.lowerBound,
            "upperBound": rwo.upperBound,
            "valuePercentage": rwo.valuePercentage,
          }
        });
      } else if(data.gradingCalculationBasedOn == "moic")  {
        manPackDataToapi.moic.moicBuckets = data.moicBuckets.map(rwo => {
          return {
            "lowerBound": rwo.lowerBound,
            "upperBound": rwo.upperBound,
            "valuePercentage": rwo.valuePercentage,
          }
        });
      } else if(data.gradingCalculationBasedOn == "irr")  {
        manPackDataToapi.moic.irrBuckets = data.irrBuckets.map(rwo => {
          return {
            "lowerBound": rwo.lowerBound,
            "upperBound": rwo.upperBound,
            "valuePercentage": rwo.valuePercentage,
          }
        });
      }
    }
    // Grade vesting = NO
    else {
      manPackDataToapi.moic.retrocessionBasedOn = data.retrocessionBasedOn;

      manPackDataToapi.moic.retrocessionCondition = data.retrocessionCondition;

      manPackDataToapi.moic.retrocessionData = data.retrocessionData
        // .filter(row => data.gradingCalculationBasedOn === "both" || row.type === data.gradingCalculationBasedOn)
        .map(rwo => {
          return {
            "type": rwo.type,
            "value": rwo.value,
            "retrocession": rwo.retrocession,
          }
        });
    }

    // console.log("Manpack input to API: ", manPackDataToapi);



    this.ds.getManPackAnalysis(manPackDataToapi).subscribe(res => {
      // console.log("ManPack analysis out: ", res.body);

      this.manPackAnalysisData = res.body["response"];
      this.manPackAnalysisReady = true;
      this.manPackExcelAnalysisReady = false;

      const widgetdata = {
        input: data,
        analysis: this.manPackAnalysisData
      }
      this.msgService.publish('MANPACK_SAVE_DILUTED_STAKE', widgetdata.analysis.analysisData.dilutedStakeAfterManpack);
      this.ds.saveWidgetToDBAsync(this.widgetKey, widgetdata,this.latestCompanyValuationDate.id);
      this.msgService.publish('MANPACK_ANALYSIS_READY', true);

    }, error => {
      console.log("ManPack analysis ERROR: ", error);
      this.utilService.showMessage("Management Package Analysis failed.", "Ok", true);
      this.manPackAnalysisData = {};
      this.manPackAnalysisReady = false;
    });

  }

  startExcelAnalysis(data) {
    this.ds.postExcelManpackAnalysis(data.requestPayload).subscribe(res => {
      this.manPackExcelAnalysisReady = true;
      this.manPackAnalysisReady = false;

      const widgetdata = {
        excelInput: data,
        excelAnalysis: res['response']
      }
      this.manPackExcelAnalysisData = widgetdata;
      this.msgService.publish('MANPACK_SAVE_DILUTED_STAKE', widgetdata.excelAnalysis.dilutedStakePostManpack);
      this.ds.saveWidgetToDBAsync(this.widgetkeyExcel, widgetdata, this.latestCompanyValuationDate.id);
      this.msgService.publish('MANPACK_EXCEL_ANALYSIS_READY', true);

    }, error => {
      console.log("ManPack analysis ERROR: ", error);
      this.utilService.showMessage("Management Package Analysis failed.", "Ok", true);
      this.manPackExcelAnalysisData = {};
      this.manPackExcelAnalysisReady = false;
    });
  }

  startNormalOptionAnalysis(payload){
    return this.ds.postNormalOptionsAnalysis(payload)
  }

  startManagementOptionAnalysis(payload){
    return this.ds.postManagementOptionsAnalysis(payload)
  }

  startSaleBonusPoolAnalysis(payload){
    return this.ds.postSalesBonusPoolAnalysis(payload)
  }

  saveNormalOptionsInput(payload){
    return this.ds.postSaveNormalOptonInputs(payload).subscribe((res) => {})
  }

  saveManagementOptionsInput(payload){
    return this.ds.postSaveManagementOptonInputs(payload).subscribe((res) => {
      let manOptionExist = payload.input.managementOptionExist;
      this.msgService.publish('MANOPTION_EXIST', manOptionExist);
    })
  }


  saveSaleBonusPoolInput(payload){
    return this.ds.postSaveSaleBOnusPoolInputs(payload).subscribe((res) => {})
  }

  getNomalOptionsInput(companyId){
    return this.ds.getNormalOptionInputs(companyId)
  }

  getManagementOptionsInput(companyId){
    return this.ds.getManagementOptionInputs(companyId)
  }

  getSaleBonusPoolInput(companyId){
    return this.ds.getSaleBonusPoolInputs(companyId)
  }

  saveInputData(inputData) {
    const widgetdata = {
      input: inputData,
      analysis: null
    }

    // this.manPackAnalysisReady = false;
    this.ds.saveWidgetToDBAsync(this.widgetKey, widgetdata, this.latestCompanyValuationDate.id);
  }

  saveExcelInputData(inputData) {
    const widgetdata = {
      excelInput: inputData,
      excelAnalysis: null
    }

    // this.manPackExcelAnalysisReady = false;
    this.ds.saveWidgetToDBAsync(this.widgetkeyExcel, widgetdata , this.latestCompanyValuationDate.id);
  }

  saveManpackPageData(data) {
    this.ds.saveWidgetToDBAsync(this.widgeKeyPageData, data, this.latestCompanyValuationDate.id);
  }

  getFormatedHoldingPeriod(period) {
    let yrs = Math.floor(Math.abs(period) / 12)
    let mons = Math.abs(period) - yrs * 12;

    let holdingPeriod = yrs + " Yrs " + Math.round(mons) + " Months";

    return holdingPeriod;
  }

  getLPInput(): Observable<ICCARequest> {
    return this.lpInput.asObservable();
  }

  getLPOutput(): Observable<any>{
    return this.lpOutput.asObservable();
  }

  getVolitilityInput(): Observable<any>{
    return this.volitilityInput.asObservable();
  }
  getRiskFreeRateInput(): Observable<any>{
    return this.riskFreeRateInput.asObservable();
  }

  setLPInput(data): void {
    this.lpInput.next(data);
  }

  setLPOutput(data): void {
    this.lpOutput.next(data);
  }

  setVolitilityInput(data): void{
    this.volitilityInput.next(data);
  }

  setRiskFreeRateInput(data): void{
    this.riskFreeRateInput.next(data);
  }

  getBlackAndScholesInput(): Observable<any>{
    return this.blackAndScholesInput.asObservable();
  }
  setBlackAndScholesInput(data): void{
    this.blackAndScholesInput.next(data);
  }

  public showOPMInputs(payload) {
  this.dialogRef =  this.dialog.open(OpmInputComponent, {
          width: '35vw',
          data : payload,
          disableClose: true
        });
  }

  getVolitilityOptions(formId){
    return this.dataService.getVolitilityOptions(formId);
  }

  getVolitility(formId){
    return this.dataService.getVolitility(formId);
  }

  saveAnnualizedVolitility(formId, body){
    return this.dataService.saveAnnualizedVolitility(formId, body);
  }

  recalculateVolitility(formId, body){
    return this.dataService.recalculateVolitility(formId, body);
  }

  getSimilarCompanies(value, valuationDate){
    return this.dataService.getSimilarCompaniesData(value, valuationDate);
  }

  getRiskFreeRate(formId) {
    return this.dataService.getRiskFreeRate(formId);
  }

  calculateRiskFreeRate(formId,payload) {
    return this.dataService.calculateRiskFreeRate(formId, payload);
  }

  saveRiskFreeRate(formId,payload) {
    return this.dataService.saveRiskFreeRate(formId, payload);
  }
}
