import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clone } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioService } from '../portfolio/portfolio.service';
import * as moment from 'moment';
import { InvestmentSummaryService } from '../portfolio/investment-page-summary-v2/investment-summary.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-build-up',
  templateUrl: './build-up.component.html',
  styleUrls: ['./build-up.component.scss']
})
export class BuildUpComponent implements OnInit {

  constructor(private datePipe: DatePipe, 
    public utilService: UtilService,
    public ds: DataService,
    public translateService: TranslateService) { }

  @Input() companyId;

  @Output() closeBuildUp = new EventEmitter();

  @Output() buildUpSaved = new EventEmitter();

  allBuildUps = [];

  buildUpObject = {
    name: "",
    date: "",
    enterpriseValue: 0,
    netDebt: 0,
    equityValue: 0,
    adjustments: 0,
    stake: 0,
    stakeValue: 0,
    metricKey: "bevEbitda",
    metric: 0,
    impliedMultiple: 0
  }

  allMultiples = [{
    key: "bevRevenue",
    label: this.translateService.getLabel("revenue")
  },
  {
    key: "bevEbitda",
    label: this.translateService.getLabel("ebitda")
  },
  {
    key: "bevEbit",
    label: this.translateService.getLabel("ebit")
  },
  {
    key: "bevEbitda_Capex",
    label: this.translateService.getLabel("ebitda-capex")
  },
  {
    key: "evGrossProfit",
    label: this.translateService.getLabel("gross_profit")
  },
  {
    key: "psales",
    label: this.translateService.getLabel("price_sales")
  },
  {
    key: "pe",
    label: this.translateService.getLabel("price_earnings")
  },
  {
    key: "pbv",
    label: this.translateService.getLabel("price_book_value")
  }]

  addBuildUp = true;
  editBuildUp = false;
  viewBuildUp = false;

  showHelpTextForNetDebt = false;
  showHelpTextForNAV = false;

  buildUpIndex = -1;

  ngOnInit(): void {
    this.ds.getWidgetDataFromDB("VALUE_BRIDGE_BUILD_UPS", this.companyId).subscribe(res => {
      this.allBuildUps = res.body["response"][0].widgetData.buildUps;
    }, error => {
      console.log("Failed to fetch Build Ups", error);
    })
  }

  addAndSaveBuildUps(){

    if(!this.buildUpObject.name || !this.buildUpObject.date){
      return;
    }

    this.buildUpObject.date = (moment(this.buildUpObject.date)).format("YYYY-MM-DD");
    // this.buildUpObject.stakeValue = (this.buildUpObject.stake/100) * this.buildUpObject.equityValue;

    if(this.buildUpIndex == -1){
      this.allBuildUps.push(cloneDeep(this.buildUpObject));
    }
    else{
      this.allBuildUps[this.buildUpIndex] = cloneDeep(this.buildUpObject)
    }

    this.buildUpIndex = -1;
    this.addBuildUp = false;
    this.editBuildUp = false;

    this.buildUpObject = {
      name: "",
      date: "",
      enterpriseValue: 0,
      netDebt: 0,
      equityValue: 0,
      adjustments: 0,
      stake: 0,
      stakeValue: 0,
      metricKey: "bevEbitda",
      metric: 0,
      impliedMultiple: 0
    }

    this.saveBuildUpData(this.allBuildUps);
  }

  editBuildUpData(buildUpToBeEdited, index){
    // console.log("edit build", data)
    this.buildUpIndex = index;
    this.buildUpObject = cloneDeep(buildUpToBeEdited);
  }

  clearAllFields(){
    this.editBuildUp = false;
    this.viewBuildUp = false;
    this.addBuildUp = true;
    this.buildUpIndex = -1;

    this.buildUpObject = {
      name: "",
      date: "",
      enterpriseValue: 0,
      netDebt: 0,
      equityValue: 0,
      adjustments: 0,
      stake: 0,
      stakeValue: 0,
      metricKey: "bevEbitda",
      metric: 0,
      impliedMultiple: 0
    }
  }

  enableEditBuildUp(){
    this.viewBuildUp = false;
    this.addBuildUp = false;
    this.editBuildUp = true;
  }

  enableViewBuildUp(){
    this.addBuildUp = false;
    this.editBuildUp = false;
    this.viewBuildUp = true;
  }

  deleteBuildUp(index){
    const buildUpToBeDeleted = this.allBuildUps[index];
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("info_delete_build_up") + " " + buildUpToBeDeleted.name + " ?", this.translateService.getLabel("yes"), this.translateService.getLabel("no"));
    confirm.afterClosed().subscribe(confirmation => {
      if (confirmation === "Yes") {
        this.allBuildUps.splice(index, 1);   
        this.saveBuildUpData(this.allBuildUps); 
      }
    });
  }

  closeBuildUpUI(){
    this.closeBuildUp.emit();
  }
  
  calcNAV(){
    this.buildUpObject.stakeValue = +((this.buildUpObject.equityValue * (this.buildUpObject.stake/100)) + this.buildUpObject.adjustments).toFixed(2);
    this.showHelpTextForNAV = false;
  }

  initNAVCalc(){
    const actualStakeValue = +((this.buildUpObject.equityValue * (this.buildUpObject.stake/100)) + this.buildUpObject.adjustments).toFixed(2);

    const userEditedStakeValue = +this.buildUpObject.stakeValue.toFixed(2);

    this.showHelpTextForNAV = actualStakeValue == userEditedStakeValue ? false : true;
  }

  initNetDebtCalc(){
    const actualNetDebt = +(this.buildUpObject.equityValue - this.buildUpObject.enterpriseValue).toFixed(2);

    const userEditedNetDebt = +this.buildUpObject.netDebt.toFixed(2);

    this.showHelpTextForNetDebt = actualNetDebt == userEditedNetDebt ? false : true;
  }

  calcImpliedMultiple(){
    this.buildUpObject.impliedMultiple = +(+this.buildUpObject.enterpriseValue/+this.buildUpObject.metric).toFixed(2);
  }

  calcNetDebt(){
    this.buildUpObject.netDebt = +(this.buildUpObject.equityValue - this.buildUpObject.enterpriseValue).toFixed(2);
    this.showHelpTextForNetDebt = false;
  }

  saveBuildUpData(allBuildUps){
    const data = {
      buildUps: allBuildUps
    }
    this.ds.saveWidgetDataToDB("VALUE_BRIDGE_BUILD_UPS" , data, this.companyId).subscribe(res => {
      console.log("RES", res);
      this.buildUpSaved.emit();
    }, error => {
      console.log("Failed to save BuildUps", error);
    })
  }

  getMarketMultipleLabel(key){
    switch(key){
      case "bevRevenue":
        return this.translateService.getLabel("revenue")

      case "bevEbitda":
        return this.translateService.getLabel("ebitda")
      
      case "bevEbit":
        return this.translateService.getLabel("bevEbit")

      case "bevEbitda_Capex":
          return this.translateService.getLabel("ebitda-capex")
        
      case "evGrossProfit":
        return this.translateService.getLabel("gross_profit")

      case "pe":
        return this.translateService.getLabel("price_earnings")

      case "psales":
        return this.translateService.getLabel("price_sales")

      case "pbv":
        return this.translateService.getLabel("price_book_value")
    }

    return key
  }

}
