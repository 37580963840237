<div class="row" style="margin: 0;">
    <ngb-tabset #additionalAnalysis = "ngbTabset" style="width: 100%">

      <ngb-tab title="{{translateService.getLabel('external_valuer')}}" id="externalValuer">
        <ng-template ngbTabContent>
          <app-external-valuer [latestCompanyValuationDate]="latestCompanyValuationDate" [companyId]="companyId"></app-external-valuer>
        </ng-template>
      </ngb-tab>

    </ngb-tabset>
</div>
