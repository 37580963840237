import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

import { UtilService } from 'src/app/utils/util.service';
import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import mean from 'lodash/mean';
import min from 'lodash/min';
import max from 'lodash/max';
import { UserManagementService } from 'src/app/services/user-management.service';


@Injectable({
  providedIn: 'root'
})
export class CCMServiceV3 {
  algorithmKey = 'valuation_ccm';
  algoAPIversion = "5.0"

  balanceSheetAdjustment;
  dLocData = {
    region: { weekMonth: null, dayWeekMonth: null, name: ""},
    industry: { weekMonth: null, dayWeekMonth: null, name: ""},
    aggregation: null
  };
  
  formData;

  currencyExchangeRate = 1;

  result;
  resultClone;
  resultAvailable = new Subject();
  rangeAvailable = new Subject();

  similarCompaniesUpdated = new Subject();

  savedSimilarCompaniesFromOtherAPI = [] as any;

  impliedMultiple = {
    multipleType1: [],
    multipleType2: [], 
    type : ''
  }

  loadingProgress = 25;

  currentYear;
  fiscalYears = [];

  refresh = false;
  dbSimilarCompanies = [];
  adjustedMultiplesAPI = [];
  similarCompaniesFromOtherAPI = [];

  processedSimilarCompaniesCount = 0;

  similarCompanies = [];
  apiSimilarCompanies = [];
  similarCompanyFilters = { 
    marketCap: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    revenue: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    ebitdaMargin: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    revenueMultiple: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    ebitdaMultiple: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    similarityScore: { actual: {min: 0, max: 100}, selected: {min: 0, max: 100}},
    region: "Global" ,// Selected Region Value
    customCountries: [] as any
  };

  subjectCompany = {};
  balSheet = { debt: 0, cash: 0 };

  multipleType = { 
    selected: "enterprise", 
    options:  { "enterprise": [{key: "bevRevenue", label: "BEV / Revenue"}, {key: "bevEbitda", label: "BEV / EBITDA"}],
                "equity": [{key: "psales", label: "Price / Sales"}, {key: "pe", label: "Price / Earnings"}, {key: "pbv", label: "Price / Book Value"}]
              },
    table1Option: {key: "bevRevenue", suffix: "", label: "BEV / Revenue" },
    table2Option: {key: "bevEbitda", suffix: "%", label: "BEV / EBITDA" },
    
    bevRevenue : {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    bevEbitda : {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    psales : {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    pe : {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    pbv : {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    beta: {aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}},
    debtToEquity: {aggregations: {min: [] as any, max: [] as any, median: [] as any, q1: [] as any, q3: [] as any, average: [] as any}},

    adjustedMultiple: { 
      aggregations: { risk: {}, growth: {}, profitability: {}, concluded: {min: 0, max: 0, median: 0, q1: 0, q3: 0, average: 0}, selected: "median"}
    },

    userAdjustedMultiple: {
      bevRevenue: {
        adjustment: 100, // %
        aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}
      },
        
      bevEbitda: {
        adjustment: 100, // %
        aggregations: {min: [], max: [], median: [], q1: [], q3: [], average: []}
      }
    }
  }

  financials = { options: [ 
      { key: "iaRevenue", label:"Revenue", suffix: "", multiplier: 1}, { key: "iaEbitdaMargin", label:"EBITDA Margin", suffix: "%", multiplier: 100 },
      { key: "iaDepreciationOfSales", label:"Depreciation as % of Sales", suffix: "%", multiplier: 100 }, { key: "iaWorkingCapitalOfSales", label:"Working Capital as % of Sales", suffix: "%", multiplier: 100 },
      { key: "iaCapexOfSales", label:"CapEx as % of Sales", suffix: "%", multiplier: 100 },
      { key: "revenueGrowth", label:"Revenue Growth", suffix: "%", multiplier: 100 },
      { key: "ebitdaGrowth", label:"EBITDA Growth", suffix: "%", multiplier: 100 }
    ],
    table1Option: { key: "iaRevenue", suffix: "", label: "Revenue", multiplier: 1},
    table2Option: { key: "iaEbitdaMargin", suffix: "%", label: "EBITDA Margin", multiplier: 100 },
    aggregations: {
      iaRevenue: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0}, 
      iaEbitdaMargin: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      iaDepreciationOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      iaWorkingCapitalOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      iaCapexOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      revenueGrowth: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      ebitdaGrowth: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
    } as any
  }

  customAggregationUserSelection = {
    t1: [0, 0, 0, 0],
    t2: [0, 0, 0, 0]
  }

  industryMetrics = [];
  weightMetrix = { table1Multiple: { "FY2019-t0": 50 } , table2Multiple: { "FY2019-t0": 50 }, total: 100 }

  analysis = {concludedAnalysis: [], estimatedEnterpriseValue: 0, estimatedEquityValue: 0, totalEnterpriseValue: 0, totalEquityValue: 0, controlPremium: 10};

  range = { //control premium (cp), adjustment factor(af), multiple aggregation metric, 
    cp: {o1: {checked: true, value: 5, analysis: []}, o2: {checked: true, value: 10, analysis: []}, o3: {checked: true, value: 15, analysis: []}, o4: {checked: false, value: 20, analysis: []}},
    af: {o1: {checked: true, value: 5, analysis: []}, o2: {checked: true, value: 10, analysis: []}, o3: {checked: true, value: 15, analysis: []}, o4: {checked: false, value: 20, analysis: []}},
    mam: {o1: {checked: false, value: "min", analysis: []}, o2: {checked: true, value: "median", analysis: []}, o3: {checked: true, value: "average", analysis: []}, o4: {checked: false, value: "max", analysis: []}, o5: {checked: true, value: "q1", analysis: []}, o6: {checked: true, value: "q3", analysis: []}},
    region: {o1: {checked: true, value: "global", analysis: []}, o2: {checked: true, value: "local", analysis: []}, o3: {checked: false, value: null, analysis: []}},
    concludedVariation: {o1: {checked: true, value: 10, analysis: []}, o2: {checked: false, value: 20, analysis: []}}
  }

  similarCompanyRegions = []

  constructor(private ds: DataService, private utilService: UtilService, private ums: UserManagementService)
  { }

  getCurrentYear() {
    return this.formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_DOC[0].year ? 
          +this.formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_DOC[0].year :
          (new Date()).getFullYear();
  }
  
  initSavedValuation(apiData) {    
    this.result = apiData;
    this.resultClone = cloneDeep(this.result);

    this.initInstance(this.result, true);

    this.initSubjectFinancials(this.subjectCompany["multiples"]);

    if(!this.multipleType.adjustedMultiple.aggregations.selected) {
      this.multipleType.adjustedMultiple.aggregations.selected = "median";
    }

    this.similarCompanies.forEach(company=> this.assignRawBeta(company))
  }

  getAnalysis(input){

    // console.log(input,"GET ANALYSIS METHOD");

    this.balSheet = input.balSheet;

    this.multipleType= input.multipleType;

    this.apiSimilarCompanies = input.apiSimilarCompanies;

    this.currentYear = input.currentYear;

    this.financials = input.financials;

    this.subjectCompany = input.subjectCompany;

    this.similarCompanies = input.similarCompanies;

    this.similarCompanyFilters = input.similarCompanyFilters;

    this.fiscalYears  = input.fiscalYears;

    this.range = input.range;

    this.analysis.controlPremium = input.analysis.controlPremium;

     return this.analyze();

  }

  setDlocData(apiData, region, industry) {
    const aggregation = [];

    const cps = [];

    apiData.forEach(row => {
      if(row.regionIndustry === region) {
        if(row.timePeriod === "Day, Week, Month") {
          this.dLocData.region.dayWeekMonth = row;
        } else {
          this.dLocData.region.weekMonth = row;
        }

        this.dLocData.region.name = region;
      } else {
        if(row.timePeriod === "Day, Week, Month") {
          this.dLocData.industry.dayWeekMonth = row;
        } else {
          this.dLocData.industry.weekMonth = row;
        }

        this.dLocData.industry.name = industry;
      }

      cps.push(row.averageCp)
    });

    this.dLocData.aggregation = this.getAggregations(cps);

    if(!this.result || !this.result.analysis || !this.result.analysis.controlPremium || this.result.analysis.controlPremium <= 0) {
      this.analysis.controlPremium = +(this.dLocData.aggregation.median.toFixed(2));
    }

    this.analysis.controlPremium = +(this.analysis.controlPremium.toFixed(2));
  }

  initInstance(result, init?) {  
      this.currentYear = result.currentYear ? result.currentYear : this.getCurrentYear();
      this.fiscalYears = result.fiscalYears;
      
      if(result.apiSimilarCompanies) {
        this.apiSimilarCompanies = result.apiSimilarCompanies;
        this.similarCompanyFilters = result.similarCompanyFilters;
        
      } else {
        this.apiSimilarCompanies = result.similarCompanies;
      }

      this.similarCompanies = result.similarCompanies,
      this.subjectCompany = result.subjectCompany,
      this.balSheet = result.balSheet,
      this.multipleType = result.multipleType,
      this.financials = result.financials,
      this.industryMetrics = result.industryMetrics,
      this.weightMetrix = result.weightMetrix,
      this.analysis = result.analysis,
      this.range = result.range,
      this.balanceSheetAdjustment = result.balanceSheetAdjustment;

      if(result.customAggregationUserSelection) {
        this.customAggregationUserSelection = result.customAggregationUserSelection;
      }

      // ToDo : Backward support. Remove this code after sometime.
      this.financials.table1Option.multiplier = this.financials.table1Option.suffix === "%" ? 100 : 1;
      this.financials.table2Option.multiplier = this.financials.table2Option.suffix === "%" ? 100 : 1;
      // ToDo : End.
  }

  setBalanceSheet(result) {
    this.balSheet = result;
  }

  analyze() {
    this.initFiscalYears();
    this.initWeightMatrix();
    
    const sumProduct = sumBy(this.analysis.concludedAnalysis, row=>row.product);

    this.analysis.estimatedEnterpriseValue = sumProduct;

    if(this.multipleType.selected == "enterprise") {
      this.analysis.estimatedEquityValue = this.analysis.estimatedEnterpriseValue - this.balSheet.debt;
    } else {
      this.analysis.estimatedEquityValue = this.analysis.estimatedEnterpriseValue;
    }

    this.analysis.totalEnterpriseValue = ( this.analysis.estimatedEquityValue * (1 + (this.analysis.controlPremium/100)) ) + this.balSheet.debt;
    
    this.analysis.totalEquityValue = this.analysis.totalEnterpriseValue;

    if(this.balanceSheetAdjustment && this.balanceSheetAdjustment.length > 0){
      this.balanceSheetAdjustment.forEach(balSheetAdjust => {

        if(balSheetAdjust.selected === true){
          if(balSheetAdjust.isAddition===true){
            this.analysis.totalEquityValue = this.analysis.totalEquityValue + +balSheetAdjust.updatedValue;
          }
          else{
            this.analysis.totalEquityValue = this.analysis.totalEquityValue - +balSheetAdjust.updatedValue;
          }
        }
      });
    } else {
      this.analysis.totalEquityValue = max([(this.analysis.totalEnterpriseValue - this.balSheet.debt + this.balSheet.cash), 0]);
    }

    this.analysis.totalEquityValue = this.analysis.totalEquityValue * this.currencyExchangeRate;
    this.initImpliedMultiple();    

    return cloneDeep(this.result);
  }

  initImpliedMultiple(){
    const multipleType1 = [];
    const multipleType2 = [];

    let keyValue = "totalEnterpriseValue"

    if( this.multipleType.selected == "equity"){
      keyValue = "totalEquityValue"
    }

    
    this.subjectCompany[this.multipleType.table1Option.key].forEach((value, index) => {
      if(index != 0){
        multipleType1.push(this.analysis[keyValue]/value)
      }
    })

    this.subjectCompany[this.multipleType.table2Option.key].forEach((value, index) => {
      if(index != 0){
        multipleType2.push(this.analysis[keyValue]/value)
      }
    })

    // this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC.forEach((fin, index) => {
    //   if(index == this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC.length-1
    //      || index == this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC.length-2 ){
    //     revenue.push(this.analysis[keyValue]/fin.totalNetRevenue);
    //     eBitda.push(this.analysis[keyValue]/fin.eBITDA);
    //   }
    // });

    // this.formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_DOC.forEach((fin, index) => {
    //   if(index == 0 || index == 1 ){
    //     revenue.push(this.analysis[keyValue]/fin.revenue);
    //     eBitda.push(this.analysis[keyValue]/fin.ebitda);
    //   }
    // });

    this.impliedMultiple.multipleType1 = multipleType1
    this.impliedMultiple.multipleType2 = multipleType2;
    this.impliedMultiple.type = keyValue
  }
  
  initFiscalYears(){
    this.fiscalYears = []
    
    let fy = this.industryMetrics.map(m=>m.year) || [];
    fy.push(this.currentYear - 1);
    fy.push(this.currentYear);
    fy.push(this.currentYear + 1);

    fy = fy.sort()

    const uniqueFY = new Set(fy);
    uniqueFY.forEach((fy, index)=>{
      if(fy == (this.currentYear-1)) {
        this.fiscalYears.push({key: "FY" + index + "-t0", label: 'FY ' + fy, year: fy})

      } else if(fy == this.currentYear) {
        this.fiscalYears.push({key: "FY" + index + "-t1", label: "LTM", year: "LTM"})
        this.fiscalYears.push({key: "FY" + index + "-t2", label: 'FY ' + fy, year: fy})

      } else if(fy == (this.currentYear + 1)) {
        this.fiscalYears.push({key: "FY" + index + "-t3", label: 'FY ' + fy, year: fy})        
      } else {
        this.fiscalYears.push({key: "FY" + index, label: 'FY ' + fy, year: fy}) 
      }      
    }) 
  }
  
  initWeightMatrix() {
    let total = 0;
    this.analysis.concludedAnalysis = [];

    this.fiscalYears.forEach(fy=>{
      // if(!this.weightMetrix.table1Multiple[fy.key]) {
      //   this.weightMetrix.table1Multiple[fy.key] = fy.year == (this.currentYear - 1) ? 50: 0;
      // }

      // if(!this.weightMetrix.table2Multiple[fy.key]) {
      //   this.weightMetrix.table2Multiple[fy.key] = fy.year == (this.currentYear - 1) ? 50: 0;
      // }

      total += (this.weightMetrix.table1Multiple[fy.key] ? this.weightMetrix.table1Multiple[fy.key] : 0)
        + (this.weightMetrix.table2Multiple[fy.key] ? this.weightMetrix.table2Multiple[fy.key] : 0);

      let subjectCompanyRow;

      if(this.subjectCompany){
        const selectedYear = fy.year == "LTM" ? 0: fy.year;        
        subjectCompanyRow = this.subjectCompany["multiples"].find(m=>m.year === selectedYear);
      }

      const aggregationIndex = fy.year == (this.currentYear - 1) ? 0: fy.year == "LTM" ? 1: fy.year == this.currentYear ? 2 : 3

      const aggregationType = this.multipleType.adjustedMultiple.aggregations.selected;
      const adjustmentFactor = this.multipleType.adjustedMultiple.aggregations.concluded[aggregationType];

      if(this.weightMetrix.table1Multiple[fy.key] > 0) {
        let aggregation = 0; 

        if(aggregationType === "custom") {
          aggregation = this.customAggregationUserSelection.t1[aggregationIndex];
        } else {
          aggregation = this.multipleType[this.multipleType.table1Option.key].aggregations[aggregationType][aggregationIndex] * adjustmentFactor;
        }
        
        const row = {fy: fy.year, weight: this.weightMetrix.table1Multiple[fy.key], multiple: cloneDeep(this.multipleType.table1Option), metric: 0};
        row.metric = subjectCompanyRow ? subjectCompanyRow[this.multipleType.table1Option.key] : 0;

        this.addToConcludedAnalysis(row, aggregation)
      }

      if(this.weightMetrix.table2Multiple[fy.key] > 0) {
        let aggregation = 0; 
        
        if(aggregationType === "custom") {
          aggregation = this.customAggregationUserSelection.t2[aggregationIndex];
        } else {
          aggregation = this.multipleType[this.multipleType.table2Option.key].aggregations[aggregationType][aggregationIndex] * adjustmentFactor;
        }

        const row = {fy: fy.year, weight: this.weightMetrix.table2Multiple[fy.key], multiple: cloneDeep(this.multipleType.table2Option), metric: 0};
        row.metric = subjectCompanyRow ? subjectCompanyRow[this.multipleType.table2Option.key]: 0;

        this.addToConcludedAnalysis(row, aggregation)
      }

      this.industryMetrics.forEach(m=>{
        if(!this.weightMetrix[m.name]) {
          this.weightMetrix[m.name] = {}
        }

        if(!this.weightMetrix[m.name][fy.key]) {
          this.weightMetrix[m.name][fy.key] = 0;
        }

        total += this.weightMetrix[m.name][fy.key];
        
        if(this.weightMetrix[m.name][fy.key] > 0) {
          const row = {fy: fy.year, weight: this.weightMetrix[m.name][fy.key], multiple: {key: m.name, suffix: "", label: m.name, value: m.multiple}, metric: m.amount};
          this.addToConcludedAnalysis(row, m.multiple)
        }
      })
    })
    
    this.weightMetrix.total = total;
  }

  addToConcludedAnalysis(row, aggregation) {
    row.metric = (row.metric && !isNaN(row.metric)) ? row.metric : 0;

    row.multiple["value"] = aggregation && !isNaN(aggregation) ? aggregation : 0;

    row["value"] = row.metric * row.multiple["value"];

    if(row["value"] < 0) {
      row["value"] = 0;
    }

    row["product"] = row["value"] * (row.weight / 100);

    this.analysis.concludedAnalysis.push(row);
  }

  assignRawBeta(company) {
    const b1=company.beta_1y;
    const b3=company.beta_3y;
    const b5=company.beta_5y;
    company.iaBeta = b3? b3 : b5? b5 : b1? b1 : 0;
  }

  companyHasValidNumbers(numbers) {
    let valid = true;
    numbers.forEach((n, i) => {
      if(i == 0 || i == 1) {
        valid = valid && +n > 0;
      }
    })

    return valid;
  }

  assignAdjustedMultiple(selectedCompany) {
    if(!this.adjustedMultiplesAPI) {
      selectedCompany.adjustedMultiple = {
        risk: 0,
        growth: 0,
        profitability: 0,
        concluded: 1 //(1-risk) * (1-growth) * (1-0.profitability);
      }
      return;
    }

    const adjustedMultiple = this.adjustedMultiplesAPI && this.adjustedMultiplesAPI.find(comp => comp.ticker === selectedCompany.ticker);
    if(adjustedMultiple && adjustedMultiple.risk) {
      selectedCompany.adjustedMultiple = {
        risk: adjustedMultiple.risk,
        growth: adjustedMultiple.growth,
        profitability: adjustedMultiple.profitability,
        concluded: (1+adjustedMultiple.risk) * (1+adjustedMultiple.growth) * (1+adjustedMultiple.profitability)
      }
    } 
    else if(adjustedMultiple && !adjustedMultiple.risk){
      //Regression based adjustment factor
      selectedCompany.adjustedMultiple = {
        risk: null,
        growth: null,
        profitability: null,
        concluded: adjustedMultiple.totalAdjustment
      }
    }
    else {
      selectedCompany.adjustedMultiple = {
        risk: 0,
        growth: 0,
        profitability: 0,
        concluded: 1 //(1-risk) * (1-growth) * (1-0.profitability);
      }
    }
  }

  getFinMultiples(company, multiple) { 
    if(company[multiple] && company[multiple].length > 0) {
      company[multiple] = company[multiple].map(v=> v!==null? v: 0)
      
      // company[multiple] = company[multiple].replace("[", "");
      // company[multiple] = company[multiple].replace("]", "");
      // const values = company[multiple].split(",")

      return [
        (isNaN(company[multiple][3]) || company[multiple][3] < 0) ? 0 : +(+company[multiple][3]).toFixed(2), // Current FY - 2
        (isNaN(company[multiple][4]) || company[multiple][4] < 0) ? 0 : +(+company[multiple][4]).toFixed(2), // Current FY - 1
        (isNaN(company[multiple][5]) || company[multiple][5] < 0) ? 0 : +(+company[multiple][5]).toFixed(2), // LTM
        (isNaN(company[multiple][7]) || company[multiple][7] < 0) ? 0 : +(+company[multiple][7]).toFixed(2), // Current FY
        (isNaN(company[multiple][8]) || company[multiple][8] < 0) ? 0 : +(+company[multiple][8]).toFixed(2), // Current FY + 1
      ]

    } else {
      return [0, 0, 0, 0]
    }
  }

  getMultiples(company, multiple) {    
    if(company[multiple] && company[multiple].length > 0) {
      company[multiple] = company[multiple].map(v=> v!==null? v: 0)
      // company[multiple] = company[multiple].replace("[", "");
      // company[multiple] = company[multiple].replace("]", "");
      // const values = company[multiple].split(",")

      // return [
      //   isNaN(values[2]) ? 0 : +values[2], // Current FY - 1
      //   isNaN(values[3]) ? 0 : +values[3], // LTM
      //   isNaN(values[4]) ? 0 : +values[4], // Current FY
      //   isNaN(values[5]) ? 0 : +values[5], // Current FY + 1
      // ]
      return [company[multiple][4].toFixed(1), company[multiple][5].toFixed(1), company[multiple][7].toFixed(1 ), company[multiple][8].toFixed(1)]
    } else {
      return [0, 0, 0, 0]
    }
  }

  getDebtToEquity(company) {
    const totalLTMDebt = +this.getMultiples(company, 'total_debt')[1];
    const marketCap = +company.market_cap;
    if(isNaN(totalLTMDebt) || totalLTMDebt === 0)
      return 0;
    else if(isNaN(marketCap) || marketCap === 0)
      return 0;
    else 
      return (totalLTMDebt / marketCap).toFixed(2);
  }

  aggregateByMultiple(mutliple) {
    // aggregation: [FY-1, LTM, FY+1, FY+2]
    const aggregations1 = this.getAggregations(mutliple.map(m=>m[0]))
    const aggregations2 = this.getAggregations(mutliple.map(m=>m[1]))
    const aggregations3 = this.getAggregations(mutliple.map(m=>m[2]))
    const aggregations4 = this.getAggregations(mutliple.map(m=>m[3]))
    return { 
      max:[aggregations1.max, aggregations2.max, aggregations3.max, aggregations4.max], 
      min:[aggregations1.min, aggregations2.min, aggregations3.min, aggregations4.min],  
      median: [aggregations1.median, aggregations2.median, aggregations3.median, aggregations4.median],
      average: [aggregations1.average, aggregations2.average, aggregations3.average, aggregations4.average],
      q1: [aggregations1.q1, aggregations2.q1, aggregations3.q1, aggregations4.q1],
      q3: [aggregations1.q3, aggregations2.q3, aggregations3.q3, aggregations4.q3]
    }
  }

  aggregateBySubjectMultiple(mutliple) {
    // aggregation: [FY-1, LTM, FY+1, FY+2]
    const aggregations1 = this.getAggregations(mutliple.map(m=>m[0]))
    const aggregations2 = this.getAggregations(mutliple.map(m=>m[1]))
    const aggregations3 = this.getAggregations(mutliple.map(m=>m[2]))
    const aggregations4 = this.getAggregations(mutliple.map(m=>m[3]))
    const aggregations5 = this.getAggregations(mutliple.map(m=>m[4]))
    return { 
      max:[aggregations1.max, aggregations2.max, aggregations3.max, aggregations4.max, aggregations5.max], 
      min:[aggregations1.min, aggregations2.min, aggregations3.min, aggregations4.min, aggregations5.min],  
      median: [aggregations1.median, aggregations2.median, aggregations3.median, aggregations4.median, aggregations5.median],
      average: [aggregations1.average, aggregations2.average, aggregations3.average, aggregations4.average, aggregations5.average],
      q1: [aggregations1.q1, aggregations2.q1, aggregations3.q1, aggregations4.q1, aggregations5.q1],
      q3: [aggregations1.q3, aggregations2.q3, aggregations3.q3, aggregations4.q3, aggregations5.q3]
    }
  }
  
  getAggregations(source): any {
    let array = source.filter(v=> +v > 0);

    if(array.length === 0) array = source;

    const sortedArray = sortBy(array.map(a=> +a));
    const results: any = {};

    // Average
    results.average = ( sumBy(sortedArray) / sortedArray.length );

    // Max
    results.max = sortedArray[sortedArray.length - 1];

    // Min
    results.min = sortedArray[0];

    // Median
    const medianIndex = Math.floor(sortedArray.length / 2);
    if (sortedArray.length % 2 === 0) {
      results.median =
        (+sortedArray[medianIndex] + +sortedArray[medianIndex - 1]) /
        2;
    } else {
      results.median = sortedArray[medianIndex];
    }
    
    //quartile 1 (Q1)
    const quartiles = this.utilService.getAggreagtions(sortedArray);
    results.q1 = quartiles.q1;

    //quartile 3 (Q3)
    results.q3 = quartiles.q3;

    return results;
  }

  updateAdjustedMultiples() {
    let risk=[], growth=[], profitability=[], adjustedConcluded=[];

    this.similarCompanies.forEach(c=>{
      if(!c.disabled) {
        risk.push(c.adjustedMultiple.risk)
        growth.push(c.adjustedMultiple.growth)
        profitability.push(c.adjustedMultiple.profitability)
        adjustedConcluded.push(c.adjustedMultiple.concluded)
      }
    })

    this.multipleType.adjustedMultiple.aggregations.risk = this.getAggregations(risk);
    this.multipleType.adjustedMultiple.aggregations.growth = this.getAggregations(growth);
    this.multipleType.adjustedMultiple.aggregations.profitability = this.getAggregations(profitability);
    this.multipleType.adjustedMultiple.aggregations.concluded = this.getAggregations(adjustedConcluded);

  }

  analyzeWithNewSubjectCompFin(revenueProjectedValues, ebitdaMarginProjectedValues?) {
    //Update projected revenue, price& sales, revenueGrowth, ebitdaGrowth

    //Revenue : Current FY
    this.subjectCompany["bevRevenue"][3] = revenueProjectedValues[0] ? revenueProjectedValues[0]: this.subjectCompany["bevRevenue"][3];
    //Revenue : Current FY + 1
    this.subjectCompany["bevRevenue"][4] = revenueProjectedValues[1] ? revenueProjectedValues[1]: this.subjectCompany["bevRevenue"][4];

    this.subjectCompany["iaRevenue"] = this.subjectCompany["bevRevenue"]; 

    if(ebitdaMarginProjectedValues) {
      // EBITDA Margin : Current FY  
      this.subjectCompany["iaEbitdaMargin"][3] = ebitdaMarginProjectedValues[0] ? ebitdaMarginProjectedValues[0] : this.subjectCompany["iaEbitdaMargin"][3];
      
      // EBITDA Margin : Current FY + 1
      this.subjectCompany["iaEbitdaMargin"][4] = ebitdaMarginProjectedValues[1] ? ebitdaMarginProjectedValues[1] : this.subjectCompany["iaEbitdaMargin"][4];
    }

    // Price / Sales
    this.subjectCompany["psales"] = this.subjectCompany["iaRevenue"];

    this.subjectCompany["revenueGrowth"] = [
      0,
      this.calculateGrowth(this.subjectCompany["iaRevenue"][0], this.subjectCompany["iaRevenue"][1]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][1], this.subjectCompany["iaRevenue"][2]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][2], this.subjectCompany["iaRevenue"][3]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][3], this.subjectCompany["iaRevenue"][4])
    ];

    this.subjectCompany["ebitdaGrowth"] = [
      0,
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][0] * this.subjectCompany["iaRevenue"][0]), (this.subjectCompany["iaEbitdaMargin"][1] * this.subjectCompany["iaRevenue"][1]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][1] * this.subjectCompany["iaRevenue"][1]), (this.subjectCompany["iaEbitdaMargin"][2] * this.subjectCompany["iaRevenue"][2]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][2] * this.subjectCompany["iaRevenue"][2]), (this.subjectCompany["iaEbitdaMargin"][3] * this.subjectCompany["iaRevenue"][3]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][3] * this.subjectCompany["iaRevenue"][3]), (this.subjectCompany["iaEbitdaMargin"][4] * this.subjectCompany["iaRevenue"][4]) )
    ];

    const h2 = this.subjectCompany["multiples"].find(m=>m.year == (this.currentYear-2)) || {}; // Current FY - 2
    const h1 = this.subjectCompany["multiples"].find(m=>m.year == (this.currentYear-1)) || {}; // Current FY - 1

    const ltm = this.subjectCompany["multiples"].find(m=>m.year == 0) || {}; // LTM
    
    const p1 = this.subjectCompany["multiples"].find(m=>m.year == (this.currentYear)); // Current FY
    const p2 = this.subjectCompany["multiples"].find(m=>m.year == (this.currentYear+1)); // Current FY + 1

    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "bevRevenue", this.subjectCompany["bevRevenue"]);

    this.analyze();
  }

  initSubjectFinancials(multiples) {
    const h2 = multiples.find(m=>m.year == (this.currentYear-2)) || {}; // Current FY - 2
    const h1 = multiples.find(m=>m.year == (this.currentYear-1)) || {}; // Current FY - 1

    const ltm = multiples.find(m=>m.year == 0) || {}; // LTM
    
    const p1 = multiples.find(m=>m.year == (this.currentYear)); // Current FY
    const p2 = multiples.find(m=>m.year == (this.currentYear+1)); // Current FY + 1

    this.subjectCompany["multiples"] = multiples;


    // [Current FY - 2, Current FY - 1, LTM, Current FY, Current FY + 1]
    this.subjectCompany["bevRevenue"] = this.getSubjectFinMultiples("totalNetRevenue", "revenue");
    this.subjectCompany["bevEbitda"] = this.getSubjectFinMultiples("eBITDA", "ebitda");

    this.subjectCompany["iaRevenue"] = this.getSubjectFinMultiples("totalNetRevenue", "revenue");
    this.subjectCompany["iaEbitdaMargin"] = [h2.eBITDAMargin, h1.eBITDAMargin, ltm.eBITDAMargin, p1.eBITDAMargin, p2.eBITDAMargin]
    this.subjectCompany["iaDepreciationOfSales"] = [h2.depreciationAsPerOfSales, h1.depreciationAsPerOfSales, ltm.depreciationAsPerOfSales, p1.depreciationAsPerOfSales, p2.depreciationAsPerOfSales]
    this.subjectCompany["iaWorkingCapitalOfSales"] = [h2.workingCapitalAsPerOfSales, h1.workingCapitalAsPerOfSales, ltm.workingCapitalAsPerOfSales, p1.workingCapitalAsPerOfSales, p2.workingCapitalAsPerOfSales]
    this.subjectCompany["iaCapexOfSales"] = [h2.capitalExpenditureAsPerOfSales, h1.capitalExpenditureAsPerOfSales, ltm.capitalExpenditureAsPerOfSales, p1.capitalExpenditureAsPerOfSales, p2.capitalExpenditureAsPerOfSales]
    
    this.subjectCompany["revenueGrowth"] = [
      0,
      this.calculateGrowth(this.subjectCompany["iaRevenue"][0], this.subjectCompany["iaRevenue"][1]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][1], this.subjectCompany["iaRevenue"][2]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][2], this.subjectCompany["iaRevenue"][3]), 
      this.calculateGrowth(this.subjectCompany["iaRevenue"][3], this.subjectCompany["iaRevenue"][4])
    ];

    this.subjectCompany["ebitdaGrowth"] = [
      0,
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][0] * this.subjectCompany["iaRevenue"][0]), (this.subjectCompany["iaEbitdaMargin"][1] * this.subjectCompany["iaRevenue"][1]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][1] * this.subjectCompany["iaRevenue"][1]), (this.subjectCompany["iaEbitdaMargin"][2] * this.subjectCompany["iaRevenue"][2]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][2] * this.subjectCompany["iaRevenue"][2]), (this.subjectCompany["iaEbitdaMargin"][3] * this.subjectCompany["iaRevenue"][3]) ), 
      this.calculateGrowth( (this.subjectCompany["iaEbitdaMargin"][3] * this.subjectCompany["iaRevenue"][3]), (this.subjectCompany["iaEbitdaMargin"][4] * this.subjectCompany["iaRevenue"][4]) )
    ];

    // Price / Sales
    this.subjectCompany["psales"] = this.subjectCompany["iaRevenue"];

    // Price / Earnings
    this.subjectCompany["pe"] = this.getSubjectFinMultiples("netIncome", "netProfit");

    // Price / Book Value
    this.subjectCompany["pbv"] = this.getSubjectFinMultiples("totalShareholdersEquity", "totalShareholdersEquity");
              
    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "bevRevenue", this.subjectCompany["bevRevenue"]);
    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "bevEbitda", this.subjectCompany["bevEbitda"]);
    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "psales", this.subjectCompany["psales"]);
    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "pe", this.subjectCompany["pe"]);
    this.assignSubjectCompanyMultiples(h2, h1, ltm, p1, p2, "pbv", this.subjectCompany["pbv"]);

  }

  calculateGrowth(oldValue, newValue) {
    return (+newValue - +oldValue) / +oldValue;
  }

  assignSubjectCompanyMultiples(fy_2, fy_1, ltm, fy, fy_p_1, multiple, values) {
    fy_2[multiple] = values[0] // Current FY - 2
    fy_1[multiple] = values[1] // Current FY - 1
    ltm[multiple] = values[2] // LTM

    fy[multiple] = values[3] // Current FY (Projected)
    fy_p_1[multiple] = values[4] // Current FY + 1 (Projected)
  }
  
  getSubjectFinMultiples(historyKey, projectedKey) { 
    const historyFinForm = this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC;
    const projectedFin = this.formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_DOC;

    const historicalFin = this.getHistoryNumbers(historyFinForm, historyKey);
    return [
      isNaN(historicalFin[0]) ? 0 : +(+historicalFin[0]).toFixed(2), // Current FY - 2
      isNaN(historicalFin[1]) ? 0 : +(+historicalFin[1]).toFixed(2), // Current FY - 1
      historicalFin[2],
      isNaN(projectedFin[0][projectedKey]) ? 0 : +(+projectedFin[0][projectedKey]).toFixed(2), // Current FY
      isNaN(projectedFin[1][projectedKey]) ? 0 : +(+projectedFin[1][projectedKey]).toFixed(2), // Current FY + 1
    ]
  }

  getHistoryNumbers(financials, historyKey) {
    const totalYears = financials.length;
    if( financials[totalYears - 1].year == "LTM") {
      if(totalYears >= 3) {
        //last two years & LTM exists. Like 2018, 2019, LTM
        return [financials[totalYears - 3][historyKey], financials[totalYears - 2][historyKey], financials[totalYears - 1][historyKey]];

      } else if(totalYears == 2) {
        //last one year & LTM exists. Like 2019, LTM
        return [0, financials[totalYears - 2][historyKey], financials[totalYears - 1][historyKey]];

      } else {
        //last only LTM exists.
        return [0, 0, financials[totalYears - 1][historyKey]];
      }
    } else {
      if(totalYears >= 2) {
        //last two year & NO LTM exists. Like 2018, 2019
        return [financials[totalYears - 2][historyKey], financials[totalYears - 1][historyKey], 0];

      } else {
        //last only LAST year exists.
        return [0, financials[totalYears - 1][historyKey], 0];
      }
    }
  }

  selectMultipleType() {
    this.multipleType.table1Option.key = this.multipleType.options[this.multipleType.selected][0].key;
    this.multipleType.table1Option.label = this.multipleType.options[this.multipleType.selected][0].label;
    this.multipleType.table1Option.suffix = this.multipleType.options[this.multipleType.selected][0].suffix;

    this.multipleType.table2Option.key = this.multipleType.options[this.multipleType.selected][1].key;
    this.multipleType.table2Option.label = this.multipleType.options[this.multipleType.selected][1].label;
    this.multipleType.table2Option.suffix = this.multipleType.options[this.multipleType.selected][1].suffix;
    
    this.analyze();
  }

  selectMultipleTypeOption(table, option) {
    this.multipleType[table].key = option.key;
    this.multipleType[table].label = option.label;
    this.multipleType[table].suffix = option.suffix;
    
    this.analyze();
  }

  selectFinanceOption(option, table) {
    this.financials[table].key = option.key;
    this.financials[table].suffix = option.suffix;
    this.financials[table].label = option.label;
    this.financials[table].multiplier = option.suffix === "%" ? 100 : 1;
  }

  prepareMultiples() {
    let bevRevenue = [], bevEbitda=[], pe=[], psales=[], pbv=[];
    let beta=[], debtToEquity=[], risk=[], growth=[], profitability=[], adjustedConcluded=[];

    const enabledSimilarCompanies = this.similarCompanies.filter( c => !c.disabled );

    enabledSimilarCompanies.forEach(c=>{
      if(!c.disabled) {
        bevRevenue.push(c.bevRevenue);
        bevEbitda.push(c.bevEbitda);
        psales.push(c.psales);
        pbv.push(c.pbv);
        pe.push(c.pe);
        beta.push(c.iaBeta);
        debtToEquity.push(c.debtToEquity);

        risk.push(c.adjustedMultiple.risk)
        growth.push(c.adjustedMultiple.growth)
        profitability.push(c.adjustedMultiple.profitability)
        adjustedConcluded.push(c.adjustedMultiple.concluded)
      }
    })

    this.multipleType["bevRevenue"].aggregations = this.aggregateByMultiple(bevRevenue);
    this.multipleType["bevEbitda"].aggregations = this.aggregateByMultiple(bevEbitda);

    this.multipleType["psales"].aggregations = this.aggregateByMultiple(psales);
    this.multipleType["pe"].aggregations = this.aggregateByMultiple(pe);
    this.multipleType["pbv"].aggregations = this.aggregateByMultiple(pbv);

    this.multipleType["beta"].aggregations = this.getAggregations(beta);      
    this.multipleType["debtToEquity"].aggregations = this.getAggregations(debtToEquity);

    this.multipleType.adjustedMultiple.aggregations.risk = this.getAggregations(risk);
    this.multipleType.adjustedMultiple.aggregations.growth = this.getAggregations(growth);
    this.multipleType.adjustedMultiple.aggregations.profitability = this.getAggregations(profitability);
    this.multipleType.adjustedMultiple.aggregations.concluded = this.getAggregations(adjustedConcluded);

    if(!this.financials.aggregations){
      this.financials['aggregations'] = {
        iaRevenue: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0}, 
        iaEbitdaMargin: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
        iaDepreciationOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
        iaWorkingCapitalOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
        iaCapexOfSales: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
        revenueGrowth: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
        ebitdaGrowth: {min: 0, max: 0, average: 0, median: 0, q1: 0, q3: 0},
      }
    }
    this.financials.aggregations.iaRevenue = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.iaRevenue));
    this.financials.aggregations.iaEbitdaMargin = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.iaEbitdaMargin));
    this.financials.aggregations.iaDepreciationOfSales = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.iaDepreciationOfSales));
    this.financials.aggregations.iaWorkingCapitalOfSales = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.iaWorkingCapitalOfSales));
    this.financials.aggregations.iaCapexOfSales = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.iaCapexOfSales));
    this.financials.aggregations.revenueGrowth = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.revenueGrowth));
    this.financials.aggregations.ebitdaGrowth = this.aggregateBySubjectMultiple(enabledSimilarCompanies.map( comp => comp.ebitdaGrowth));
  }

  // Range Analysis
  
  cp = [0, 0];
  af = [0, 0];
  mam = [0, 0];
  region = [0, 0];
  concludedVariation = [0, 0];

  concludedRange = [0, 0];

  findRange() {
    this.controlPremiumChangeImpl();
    this.adjustmentFactorChangeImpl();
    this.multipleAdjustmentChangeImpl();
    this.regionChangeForAnalysisImpl();
    
    this.concludedChangeForAnalysisImpl();

    this.getFinalRange();
  }

  getFinalRange() {    
    const array = this.cp.concat(this.af).concat(this.mam).concat(this.region).concat(this.concludedVariation).sort((a,b)=> a>b? 1 : a==b ? 0 : -1).filter(v => v > 0)
    // console.log("All CCM Analysis", array);
    this.concludedRange = [array[0], array[array.length-1]];
  }
  
  controlPremiumChangeImpl() {
    this.cp = [0, 0];
    const currentAnalysis = cloneDeep(this.result);

    for(let i=1; i<=4; i++) {
      if(this.range.cp['o' + i].checked) {
        let analysis = [0, 0];

        this.analysis.controlPremium = this.analysis.controlPremium 
              - (this.analysis.controlPremium * (this.range.cp['o' + i].value / 100));

        this.analyze();

        analysis[0] = this.analysis.totalEquityValue

        // ----------------------------------
        this.resetAnalysis(currentAnalysis);

        this.analysis.controlPremium = this.analysis.controlPremium 
              + (this.analysis.controlPremium * (this.range.cp['o' + i].value / 100));

        this.analyze();

        analysis[1] = this.analysis.totalEquityValue

        // -----------------------------------
        analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);
        this.range.cp['o' + i].analysis = analysis;

        this.setRange(this.cp, analysis);
        this.resetAnalysis(currentAnalysis)
      } else {
        this.range.cp['o' + i].analysis = [];
      }
    }
  }

  adjustmentFactorChangeImpl() {
    this.af = [0, 0];
    let analysis = [];

    const currentAnalysis = cloneDeep(this.result);

    for(let i=1; i<=4; i++) {
      if(this.range.af['o' + i].checked) {

        let currentValues=[];

        const aggregation1 = this.multipleType.adjustedMultiple.aggregations.concluded
        this.multipleType.adjustedMultiple.aggregations.concluded = {
          min: aggregation1.min - (aggregation1.min * (this.range.af['o' + i].value / 100)), 
          max: aggregation1.max - (aggregation1.max * (this.range.af['o' + i].value / 100)), 
          median: aggregation1.median - (aggregation1.median * (this.range.af['o' + i].value / 100)), 
          q1: aggregation1.q1 - (aggregation1.q1 * (this.range.af['o' + i].value / 100)), 
          q3: aggregation1.q3 - (aggregation1.q3 * (this.range.af['o' + i].value / 100)), 
          average: aggregation1.average - (aggregation1.average * (this.range.af['o' + i].value / 100))
        }

        this.analyze();

        analysis.push(this.analysis.totalEquityValue);
        currentValues.push(this.analysis.totalEquityValue);

        // console.log("AF - ", this.multipleType.adjustedMultiple.aggregations.concluded, this.analysis.totalEquityValue);

        // ----------------------------------
        this.resetAnalysis(currentAnalysis);

        const aggregation2 = this.multipleType.adjustedMultiple.aggregations.concluded
        this.multipleType.adjustedMultiple.aggregations.concluded = {
          min: aggregation2.min + (aggregation2.min * (this.range.af['o' + i].value / 100)), 
          max: aggregation2.max + (aggregation2.max * (this.range.af['o' + i].value / 100)), 
          median: aggregation2.median + (aggregation2.median * (this.range.af['o' + i].value / 100)), 
          q1: aggregation2.q1 + (aggregation2.q1 * (this.range.af['o' + i].value / 100)), 
          q3: aggregation2.q3 + (aggregation2.q3 * (this.range.af['o' + i].value / 100)), 
          average: aggregation2.average + (aggregation2.average * (this.range.af['o' + i].value / 100))
        }

        this.analyze();

        analysis.push(this.analysis.totalEquityValue);
        currentValues.push(this.analysis.totalEquityValue);
        // console.log("AF + ", this.multipleType.adjustedMultiple.aggregations.concluded, this.analysis.totalEquityValue);
        
        // -----------------------------------
        currentValues = currentValues.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);
        this.range.af['o' + i].analysis = currentValues;
        
        this.resetAnalysis(currentAnalysis)
      } else {
        this.range.af['o' + i].analysis = [];
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);

    // console.log("Region - Range Analaysis Final", analysis);

    if(analysis.length > 0) {
      this.af = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  multipleAdjustmentChangeImpl() {
    this.mam = [0, 0];
    const currentAnalysis = cloneDeep(this.result);
    let analysis = [];

    for(let i=1; i<=6; i++) {
      if(this.range.mam['o' + i].checked) {

        this.multipleType.adjustedMultiple.aggregations.selected = this.range.mam['o' + i].value;

        this.analyze();

        analysis.push(this.analysis.totalEquityValue);

        this.range.mam['o' + i].analysis = [
          this.analysis.totalEquityValue, this.analysis.totalEquityValue
        ]

        this.resetAnalysis(currentAnalysis);
      } else {
        this.range.mam['o' + i].analysis = [];
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);
    // console.log("MAM ANalysis final", analysis)

    if(analysis.length > 0) {
      this.mam = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  regionChangeForAnalysisImpl() {
    this.region = [0, 0];
    let analysis = [];

    const currentAnalysis = cloneDeep(this.result);

    for(let i=1; i<=3; i++) {
      const regionType = this.range.region['o' + i];

      if(regionType.checked) {
        if(regionType.value == "local") {
          let country = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.name;
          country = country.toUpperCase();

          const selectedCompanies = this.similarCompanies.filter(comp=> comp.country_name && comp.country_name.toUpperCase() === country);
          this.similarCompanies = selectedCompanies || [];
        }
        else if(i == 3) {
          if(regionType.value && regionType.value.length >= 3) {
            const selectedCompanies = this.similarCompanies.filter(comp=> comp.country_name && comp.country_name.toUpperCase() === this.range.region.o3.value);
            this.similarCompanies = selectedCompanies || [];
          } else {
            this.similarCompanies = [];
          }
        }
        this.prepareMultiples();
        this.analyze();
        
        analysis.push(this.analysis.totalEquityValue);

        this.range.region['o' + i].analysis = [
          this.analysis.totalEquityValue, this.analysis.totalEquityValue
        ]

        this.resetAnalysis(currentAnalysis);
      } else {
        this.range.region['o' + i].analysis = [];
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);

    // console.log("Region - Range Analaysis Final", analysis);

    if(analysis.length > 0) {
      this.region = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  concludedChangeForAnalysisImpl() {
    // ToDo: Remove this after Nov 2020
    if(!this.range.concludedVariation) {
      this.range.concludedVariation = {o1: {checked: true, value: 10, analysis: []}, o2: {checked: false, value: 20, analysis: []}}
    }
    
    this.concludedVariation = [0, 0];
    let analysis = [];

    const totalEquityVal = this.analysis.totalEquityValue;

    // const array = this.cp.concat(this.af).concat(this.mam).concat(this.region).sort((a,b)=> a>b? 1 : a==b ? 0 : -1).filter(v => v > 0)

    // const concludedValue = [array[0], array[array.length-1]];

    for(let i=1; i<=2; i++) {
      const option = this.range.concludedVariation['o' + i];
      if(option.checked) {
        const positiveVariation = totalEquityVal + ((option.value/100) * totalEquityVal);
        const negativeVariation = totalEquityVal - ((option.value/100) * totalEquityVal);
        option.analysis = [positiveVariation, negativeVariation];

        analysis = analysis.concat(option.analysis);
      }
      else{
        option.analysis = [0, 0];
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1);
    // console.log("Concluded Variation - Range Analaysis Final", this.concludedRange, analysis);

    if(analysis.length > 0) {
      this.concludedVariation = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  setRange(type, analysis) {
    if(type[0] == 0 || type[0] > analysis[0]) {
      type[0] = analysis[0]
    }

    if(type[1] == 0 || type[1] < analysis[1]) {
      type[1] = analysis[1]
    }
  }

  resetAnalysis(currentAnalysis) {
    const rangeAnalysis = cloneDeep(this.range)
    this.result = cloneDeep(currentAnalysis);

    // Retain recently calculated range values.
    this.result.range = rangeAnalysis;

    this.initInstance(this.result);
  }
  
  analyseWithNewCurrencyRate() 
  {
    
  }
}