import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortFolioService } from '../portfolio.service';

import { NgForm } from '@angular/forms';

import { Chart } from 'angular-highcharts';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

import * as moment from 'moment';
import { MessageService } from 'src/app/services/message.service';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { cloneDeep } from 'lodash';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-company-valuation',
  templateUrl: './company-valuation.component.html',
  styleUrls: ['./company-valuation.component.scss']
})
export class CompanyValuationComponent implements OnInit {
  @ViewChild('valuationDatesGrid', { static: false }) public valuationDatesGrid: GridComponent;
  
  constructor(private activatedRoute: ActivatedRoute,
    public portfolioService: PortFolioService,
    private ms: MessageService,
    private ums: UserManagementService, public currencyService: CurrencyExchangeService,
    private router: Router, private datePipe: DatePipe,
    private dataService: DataService,
    private route : ActivatedRoute,
    private utilService: UtilService,
    private modalService:NgbModal, 
    private fundService : FundListService, private titlecasePipe: TitleCasePipe) 
  {}

  newCompany = {name: "", valuationDate: null, groupFormId: null, security: "EQUITY", businessUnitsNumber: 0};

  selectedCompanyConsolForm; // passing as an input to consol summary component

  selectedCompanyDates = [];
  initiatorsGroupMembers;

  APPROVAL_ACTION = {
    INITIATE_APPROVAL: "Initiate Approval",
    RECALL_APPROVAL: "Recall Approval",
    REJECT_APPROVE: "ApproveReject",
    NO_CONDITION: "No Condition",
    NONE: "none"
  }

APPROVAL_STATUS = {
  NOT_STARTED: "Not Started",
  INITIATED: "Initiated",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PENDING: "Pending",
  NO_CONDITION: "None",
  APPROVE_REJECT: "Approve / Reject"
}

  valuationDateGridDataSource: any = [
    {
      "loading": true
    }
  ];
  waterFallChart;
  primaryCompanyId;

  investmentDateInfo;

  showUserValDates = false;
  showValDates = false;
  showConsolSummary = false;

  showInvestment = false;
  formObject = {
    valuationDate:'',
    fairVal:0,
    stake: 0,
    stakeVal: 0
  };
  editedValuationDate;
  editedValuationDateIndex;

  investmentCurrency = "";

  selectedFormVersions;
  selectedBusinessUnits;
  newFormVersion = "";

  newVersionName = "";

  businessUnitsWaterFall;
  buEditMode = false;

  buShareMode = false;

  shareInputName;
  shareInputEmail;

  approvalConditionData;
  conditionsData;
  approvalData;
  allApprovalDataArray;

  showRejectApproveButton = false;
  showRecallButton = false;
  showInitiateApprovalButton = false;
  showNoActionButton = false;
  loggedInUser;
  allApprovalDetails;
  showApproveDialogBox;
  approveRejectObj;
  trackRecordData;
  
  showVersionNameDialog = false;
  selectedVersionForCopy = null;

  showUpdateVersionNameDialog = false;
  selectedVersionForUpdate = null;

  showBuildUpSummary = false;

  updateValueBridge = false;

  async ngOnInit() {   
    this.loggedInUser = this.ums.getSelectedUserDetails();
    console.log("User Details: ", this.ums.getSelectedUserDetails());

    this.investmentDateInfo = null;

    this.activatedRoute.paramMap.subscribe( async params => {
      this.investmentDateInfo = null;
    
      this.primaryCompanyId = params.get("company");
      this.portfolioService.companyId = this.primaryCompanyId;

      this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates( this.primaryCompanyId );

      this.selectedCompanyConsolForm = cloneDeep(this.selectedCompanyDates[0]); //loading consol summary of latest valuation date by default

      this.portfolioService.selectedListOfInvestors = {};

      if (!this.selectedCompanyDates || this.selectedCompanyDates.length == 0) {
        let parentId = this.route.snapshot.queryParamMap.get('parentId');
        this.portfolioService.selectedFundId = parentId;

        // this.portfolioService.selectedFundId = localStorage.getItem("  ");

        this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates(this.primaryCompanyId);

        try {
          const currencyResponse = await this.dataService.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.portfolioService.selectedFundId).toPromise();
          let widgetData = currencyResponse["body"]["response"][0].widgetData;
          this.portfolioService.selectedCurrency = widgetData.selectedCurrency;
          this.portfolioService.mapData.value = widgetData.valuationMap;
        }
        catch(e) {
          console.log("Error Retrieving data from Widget"+ JSON.stringify(e));
        }

          this.portfolioService.init(this.portfolioService.selectedFundId);
          this.portfolioService.portfolioUpdated.subscribe(response => {
         
          this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates(this.primaryCompanyId);

        if(this.selectedCompanyDates.length > 0)
        {
          this.prepareValuationGridDataSource();
        }

        //For Investments
          this.dataService.getWidgetDataFromDB("INVESTMENTS", this.portfolioService.companyId).subscribe( success => {
            // console.log('Succesfuly Fetch The investor names',success);
            this.portfolioService.selectedListOfInvestors = success.body["response"][0].widgetData
          }, error => {
            console.log('Failed to Fetch The investor names', error);
            this.initCompanyWithSelectedFund()
          })

          this.initValuationDates();
        });
        
      } else {
        //For Investments
        this.dataService.getWidgetDataFromDB("INVESTMENTS", this.portfolioService.companyId).subscribe( success => {
          // console.log('Succesfuly Fetch The investor names',success);
          this.portfolioService.selectedListOfInvestors = success.body["response"][0].widgetData
        }, error => {
          console.log('Failed to Fetch The investor names', error);
  
          this.initCompanyWithSelectedFund();
        })

        this.initValuationDates();
      }

      console.log("this.portfolioService.selectedFundId", this.portfolioService.selectedFundId);


      console.log("selectedCompanyDates: ", this.selectedCompanyDates)


      if(this.selectedCompanyDates.length > 0)
      {
        this.prepareValuationGridDataSource();
      }

    })
  }

  initValueBridge(){
    this.updateValueBridge = undefined

    setTimeout(() => {
      this.updateValueBridge = true;
    }, 400);
  }

  prepareGroupledValVersions(){
    let data = this.valuationDateGridDataSource;
    let dataMap = new Map();
    data.forEach(v => {
      if(dataMap.has(v.valuationDate)){
        let arr = dataMap.get(v.valuationDate);
        arr.push(v);
        dataMap.set(v.valuationDate, arr);
      }
      else dataMap.set(v.valuationDate, [v]);
    });

    let obj = {};
    let dataArr = [];
    let count = 0;
    for(let item of dataMap){
      obj = { name : item[0], value : item[1], index : count};
      dataArr.push(obj);
      count++;
    }

    this.valuationDateGridDataSource = dataArr;

  }

  async prepareValuationGridDataSource(){
    this.allApprovalDataArray = [];
    let allRequestIds = [];
    this.approvalConditionData = [];
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }

    let allValDates = [];
    this.selectedCompanyDates.forEach(eachDate => {
      allValDates.push(eachDate.valuationDate);
    });

    let response;
    try {
      response = await this.dataService.getAllFormsVersionsForAllValDates(this.primaryCompanyId, allValDates);
    } catch(e) {
      response = null;
    }

    let allVerAllValDate = [];
    if(response.body["response"][0]) {
      let allVersions = response.body["response"][0];
      // for (const key in allVersions){
      //   allVersions[key].forEach(entry => {
      //     allVerAllValDate.push(entry)
      //   });
      // }
      allValDates.forEach(eachate => {
        if(allVersions[eachate]) {
          const sortedVersionData = allVersions[eachate].sort((a,b) =>{
            return new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
          });
          allVersions[eachate] = sortedVersionData;

          allVersions[eachate].forEach(entry => {
            allVerAllValDate.push(entry)
          });
        }
      }); 

    } else {
      return;
    }

    try {
      const allConsolFormsIds = allVerAllValDate.map( comp => comp.id);
      const apiData = await this.dataService.getBUSummaryDataAndExchangeRates(allConsolFormsIds).toPromise();
      const buSummaryAPIData = apiData.body['response'];

      if(buSummaryAPIData.currencyExchangeRates) {
        Object.keys(buSummaryAPIData.currencyExchangeRates).forEach( key => {
          this.portfolioService.businessUnitsValSummaryForAllIds.currency[key] = buSummaryAPIData.currencyExchangeRates[key];
        })
      }

      for(let consolForm of allVerAllValDate) {
        if(consolForm.hasOwnProperty("businessUnits")) {
          const consolFormId = consolForm.id;
    
          if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData && buSummaryAPIData.consolFormWiseData[consolFormId]){
            if(buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary){
              this.portfolioService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
            }
    
            if(buSummaryAPIData.consolFormWiseData[consolFormId].investment){
              consolForm.investment = buSummaryAPIData.consolFormWiseData[consolFormId].investment
            }
          } else if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData){
            buSummaryAPIData.consolFormWiseData[consolFormId] = {
              consolSummary: {
              },

              investment: {
                currency : "",
                enterpriseValue: 0,
          
                equityValue: {
                  weightedEquityValue: 0,
                  finalAdjustedEquityVal: 0,
                  stake: 100,
                  finalStakeValue: 0,
                  stakeValue: 0,
                }
              }
            }

            this.portfolioService.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
          }

          const form = consolForm; 
          const details = JSON.parse(form.details);
      
          if(details) {               
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
              
            } else {
              form.companyNameInForm = form.companyName;
            }
      
            form.versionName = details["versionName"];
            form.frozen = details["frozen"];
            form.status = details["status"];
            form.currency = details["currency"];
            form.businessUnitsNumber = details["businessUnitsNumber"];
      
            if(form.businessUnits) {
              this.portfolioService.initBU_ConsolSummary(form);
            }
          }
        }
      }
    } catch (error) {
      console.log("Failed to fetch Consol Summary Data for all consol forms", error);
    }

    try {
      this.approvalConditionData = await this.dataService.getApprovalCondition(this.loggedInUser.organization.id, this.portfolioService.companyId, this.portfolioService.selectedFundId);
      this.approvalConditionData = this.approvalConditionData.body.response;

      if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {
        this.initiatorsGroupMembers = (await this.dataService.getGroupById(this.approvalConditionData.approvers[0].id)).body["response"].members;
      }
    } catch(e) {
      console.log("Error is getting condition and members");
    }

    allVerAllValDate.forEach((eachDate) => {
      if(eachDate.approvalRequestId){
        allRequestIds.push(eachDate.approvalRequestId)
      }
    });
    
    let allRequestIdObject = {
      approvalRequestIds: allRequestIds,
      firmId: this.loggedInUser.organization.id
    }

    this.allApprovalDetails = [];
    if(allRequestIds.length > 0){
        try {
        let resp = await this.dataService.getAllApprovalDetails(allRequestIdObject);
        if(resp?.body && resp?.body["response"]) {
          this.allApprovalDetails = resp.body["response"];
        }
      } catch(e) {
        response = null;
      }
    }

    this.valuationDateGridDataSource = [];
    allVerAllValDate.forEach((eachDate) => {
      this.getActionNameAndStatus(eachDate);
    });

    this.prepareGroupledValVersions();

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }
    this.utilService.closeAllPopups()
  }

  async getActionNameAndStatus(formVersion){

    const obj = {
      approvalStatus : null,
      actionName : null,
      approvalRequestId: null,
      editable: false
    }

    let approvalData = [];

      if(this.allApprovalDetails.length > 0){
        approvalData = this.allApprovalDetails.filter(eachApproval => eachApproval.id == formVersion.approvalRequestId );
      }

      if(approvalData.length > 0)
      {
        obj.approvalRequestId = approvalData[0].id;
  
        // if(this.checkIfAdmin())
        // {
        //   if(approvalData[0].status == "approved")
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        //   else if(approvalData[0].status == "rejected")
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        //   else
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        // }
        // else
        // {
          if(approvalData[0].status == "approved")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else if(approvalData[0].status == "rejected")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else
          {
            if(approvalData[0].status == "pending")
            {
              let isInitator = this.isInitiator();
              if(isInitator)
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.RECALL_APPROVAL;
              }
              else if(this.isCurrentApprover(approvalData))
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.REJECT_APPROVE;
              }
              else
              {
                // no action
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.NONE;
              }
      
            }
          }
        // }
      }
      else if(this.approvalConditionData && this.approvalConditionData.hasOwnProperty("conditionName")) 
      {
        let isInitator = this.isInitiator();
        if(isInitator)
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.INITIATE_APPROVAL;
          obj.editable = true;
        }
        else
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.NONE;
          obj.editable = true;
        }
      }
      else
      {
        // Disable initiate button
        obj.actionName = this.APPROVAL_ACTION.NO_CONDITION;
        obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
        obj.editable = true;
      }

      let formVersionForGrid = cloneDeep(formVersion);

      if(formVersion?.investment?.equityValue?.finalStakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.finalStakeValue
      } else if(formVersion?.investment?.equityValue?.stakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.stakeValue;
      } else {
        formVersionForGrid["stake"] = 0.0;
      }

      const details = JSON.parse(formVersion.details);

      formVersionForGrid["frozen"] = details.frozen;
      formVersionForGrid["status"] = details.status;

      formVersionForGrid["versionName"] = JSON.parse(formVersion.details).versionName;
      formVersionForGrid["currency"] = formVersion?.investment?.currency;

      formVersionForGrid["approvalStatus"] = obj["approvalStatus"];
      formVersionForGrid["actionName"] = obj["actionName"];
      formVersionForGrid["editable"] = obj["editable"];
      formVersionForGrid["approvalRequestId"] = obj["approvalRequestId"];

      formVersionForGrid["comments"] = null;
      
      this.valuationDateGridDataSource.push(formVersionForGrid);
  }

  isInitiator() {
    let isInitator = false;

    if(this.approvalConditionData.approvers[0].type == "user" && this.approvalConditionData.approvers[0].id == this.getLoggedInUserId())
    {
      isInitator = true;  
    }
    else if(this.approvalConditionData.approvers[0].type == "group")
    {
      let initatorObj = this.initiatorsGroupMembers.find(eachMember => eachMember.id == this.getLoggedInUserId());
      if(initatorObj) {
        isInitator = true;
      }
    }
    else {
      isInitator = false;
    }
    return isInitator;
  }

  isCurrentApprover(approvalData) {
    let isCurrentApprover = false;
    let firstPendingApprover = approvalData[0].approvals.find(eachApproval =>  eachApproval.status == "pending" );

    if(firstPendingApprover.hasOwnProperty("group"))
    {
      firstPendingApprover.group.members.forEach((member) => {
        if(member.id == this.getLoggedInUserId())
        {
          isCurrentApprover = true;
        }
      });
    } else if(firstPendingApprover.hasOwnProperty("user"))
    {
      if(firstPendingApprover.user.id == this.getLoggedInUserId())
      {
        isCurrentApprover = true;
      }
    }

    return isCurrentApprover;
  }

  checkIfAdmin() {
    if(this.ums.isMasterUser())
    { 
      return true 
    } 
    else 
    { 
      return false 
    }
  }

  getLoggedInUserId() {
    return this.loggedInUser.id;
  }

 async initiateApprovalRequest(valDateObject) 
  {
    this.utilService.showLoadingPopup();

    let requestObj = {
      new: {
        "id": "",
        "conditionId": this.approvalConditionData.conditionId,
        "conditionName": this.approvalConditionData.conditionName,
        "conditionLevel": this.approvalConditionData.conditionLevel,
        "fundId": this.portfolioService.selectedFundId,
        "firmId": this.loggedInUser.organization.id,
        "companyId": this.portfolioService.companyId,
        "objectLocked": "1",
        "status": "pending",
        "objectName": valDateObject.companyName+" / "+valDateObject.versionName,
        "objectId": valDateObject.id,
        "objectContent": environment.portalUrl + "/pv/#/valuation-summary?id=" + valDateObject.id + "&fv=4",
        "objectType": "valuation",
        "sendEmail": false
      }
    }

    requestObj.new["approvals"] = [];

    this.approvalConditionData.approvers.forEach((approver, index) => {
      if(approver.type == "group")
      {
        let groupObj = {
          "group": {
            "id": approver.id,
            "name": approver.name
          },
          "status": "pending",
          "comments": "",
          "timestamp": ""
        }

        requestObj.new["approvals"].push(groupObj);
      }
      else
      {
        let userObj = {
          "user": {
            "id": approver.id,
            "name": approver.name
          },
          "status": "pending",
          "comments": "",
          "timestamp": ""
        }

        requestObj.new["approvals"].push(userObj);
      }
    });  

    if(requestObj.new.conditionId)
    {
      let requestCreationStatus = await this.dataService.initiateApprovalRequest(requestObj);

      if(requestCreationStatus.status == 200)
      {
        valDateObject.approvalRequestId = requestCreationStatus.body["response"].id;
        this.approveRequest(valDateObject);
      }
    }

  }

  recallApprovalRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    let recallObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "recalled",
      "sendEmail": true
    }

    if(valDateObject.comments == null)
    {
      recallObj.comments = "Recalled"
    }
    else
    {
      recallObj.comments = valDateObject.comments;
    }

    this.dataService.recallRequest(recallObj).then(() => {
      this.prepareValuationGridDataSource();
    });
    
  }

  approveRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    this.hideApproveDialog();
    let approveObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "approved",
      "sendEmail": true
    }

    if(valDateObject.comments == null)
    {
      approveObj.comments = "Approved"
    }
    else
    {
      approveObj.comments = valDateObject.comments;
    }

    this.dataService.approveRequest(approveObj).then(() => {
      this.prepareValuationGridDataSource();
    });
    
  }

  rejectRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    this.hideApproveDialog();
    let rejectObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "rejected",
      "sendEmail": true
    }

    if(valDateObject.comments == null)
    {
      rejectObj.comments = "Rejected"
    }
    else
    {
      rejectObj.comments = valDateObject.comments;
    }
    
    this.dataService.rejectRequest(rejectObj).then(() => {
      this.prepareValuationGridDataSource();
    });
  }
  
  enableApproveDialogBox(valDate){
    this.utilService.showLoadingPopup();
    this.approveRejectObj = valDate;
    this.trackRecordData = undefined;

    let payload = {
      "orgId": this.loggedInUser.organization.id,
      "userId": this.getLoggedInUserId(),
      "fundId": this.portfolioService.selectedFundId,
      "formId": valDate.id
    };

    this.showApproveDialogBox = true;
    
    // this.dataService.getTrackRecordData(payload).then(resp => {
    //   this.utilService.closeAllPopups();
    //   console.log(resp);

    //   this.trackRecordData = "";
    //   this.showApproveDialogBox = true;
    // }).catch(err => {
    //   console.log("error is getting track record data");
    //   this.trackRecordData = undefined;
    //   this.utilService.closeAllPopups();
    //   this.showApproveDialogBox = true;
    // })
  }

  hideApproveDialog() {
    this.showApproveDialogBox = false;
    this.approveRejectObj = null;
  }
  
  //For Investments
  initCompanyWithSelectedFund(){
    this.selectedCompanyDates.forEach( company => {
      if(!this.portfolioService.selectedListOfInvestors[company.id]){
        this.portfolioService.selectedListOfInvestors[company.id] = []
      }
  
      this.portfolioService.selectedListOfInvestors[company.id].push({
        investorName : this.fundService.getFundById(this.portfolioService.selectedFundId).name,
        stake: company.investment ? company.investment.equityValue.stake : 0,
        noOfShares: 0
      })
    })
  }

  initValuationDates() {    
    this.selectedCompanyDates = this.selectedCompanyDates.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date > f2Date? -1: 1;
    })   
    
    this.newCompany.name = this.selectedCompanyDates[0].companyName;
    this.newCompany.groupFormId = this.primaryCompanyId;

    //START: Get number of business units in the latest valuation date
    const allValDates = this.selectedCompanyDates.filter(f => !f.userEntered)

    this.newCompany.businessUnitsNumber = allValDates[0].businessUnitsNumber ? allValDates[0].businessUnitsNumber: 0;
    // END

    const investmentForm = this.selectedCompanyDates.find(f => f.id === this.primaryCompanyId)

    if(investmentForm.investment) {
      this.investmentCurrency = investmentForm.investment.currency;
    }

    //investmentDateInfo - This is mainly used in currency dashboard
    this.initCurrencyExchangeRate();

    console.log("selectedCompanyDates: ", this.selectedCompanyDates)
  }

  initCurrencyExchangeRate() {
    this.investmentDateInfo = this.portfolioService.portfolioData.find(comp => comp.id == this.primaryCompanyId);

    let filteredForms = this.portfolioService.getSelectedCompanyDates(this.primaryCompanyId).filter( comp => !comp.userEntered && comp.status !== "Initiated")

    if(!filteredForms || filteredForms.length == 0) {
      //This is needed just after the first company is created in a fnew fund.
      return;
    }

    // Get form data for latest valuation date
    this.dataService.getFormData(filteredForms[0].id, this.ums.getApplicationVersion()).subscribe(data => {
      const formData = data.body["response"].formData;

      this.investmentDateInfo.formData = formData;
      // const sourceCurrencies = [formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY];

      const reqBody = [];

      // Hist Currency
      // this.currencyService.initExchangeRates(formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY,
      //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForFinHist")
      
        const histKey = formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY 
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[histKey]){
          this.investmentDateInfo["exchangeRateForFinHist"] = this.currencyService.exchangeRates[histKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForFinHist"] = exchangeRate
            this.currencyService.exchangeRates[histKey] = exchangeRate;
          })
        }

      //Proj Currency
      // this.currencyService.initExchangeRates(formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY,
      //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForProjHist")

        const projKey = formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[projKey]){
          this.investmentDateInfo["exchangeRateForProjHist"] = this.currencyService.exchangeRates[projKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.FINANCIALS.FIN_PROJ_FINANCIALS.FIN_FIN_PROJ_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForProjHist"] = exchangeRate
            this.currencyService.exchangeRates[histKey] = exchangeRate;
          })
        }

      if(formData.OTHERS && formData.OTHERS.OT_Secondaries && formData.OTHERS.OT_Secondaries.OT_SEC_Buyer_Name.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForSecondaries")

          const secondariesKey = formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[secondariesKey]){
          this.investmentDateInfo["exchangeRateForSecondaries"] = this.currencyService.exchangeRates[secondariesKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForSecondaries"] = exchangeRate
            this.currencyService.exchangeRates[secondariesKey] = exchangeRate;
          })
        }
      }

      if(formData.OTHERS && formData.OTHERS.OT_Bid_Details && formData.OTHERS.OT_Bid_Details.OT_BD_Bidder_Name.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForBidDetails")

        const bidDetailsKey = formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[bidDetailsKey]){
          this.investmentDateInfo["exchangeRateForBidDetails"] = this.currencyService.exchangeRates[bidDetailsKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForBidDetails"] = exchangeRate
            this.currencyService.exchangeRates[bidDetailsKey] = exchangeRate;
          })
        }
      }

      if(formData.OTHERS && formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND && formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_NAME.length > 0) {
        // sourceCurrencies.push(formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY);
        // this.currencyService.initExchangeRates(formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY,
        //   formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate, this.investmentDateInfo, "exchangeRateForPori")

        const poriKey = formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY
        + formData.GENERAL.GD_General_Details.GD_CR_CURRENCY 
        + formData.valuationDate

        if(this.currencyService.exchangeRates[poriKey]){
          this.investmentDateInfo["exchangeRateForPori"] = this.currencyService.exchangeRates[poriKey];
        }
        else{
          this.dataService.getValuationDateCurrency(formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY,
          formData.GENERAL.GD_General_Details.GD_CR_CURRENCY, formData.valuationDate).subscribe( res => {
            const exchangeRate = res.body['response'].value;
            this.investmentDateInfo["exchangeRateForPori"] = exchangeRate
            this.currencyService.exchangeRates[poriKey] = exchangeRate;
          })
        }
      }
    }, error => {
      // window.location.reload();
    })
  }

  // getExchangeRate(sourceCurrency, targetCurrency, valDate, key){
  //   let exchangeRate = this.currencyService.initExchangeRates(sourceCurrency, targetCurrency, valDate)

  //   exchangeRate.then( value => {
  //     this.investmentDateInfo[key] = value
  //   }).catch( error => {
  //     console.log("Error while returning a value", error)
  //   })
  // }

  initChart (waterFallNumbers) {
    this.waterFallChart = new Chart({
      chart: { type: 'waterfall' },
      title: { text: '' },
      xAxis: { type: 'category' },
      yAxis: { title: { text: '' } },
      legend: { enabled: false },
      tooltip: { enabled: false },
      credits:{ enabled: false},
      plotOptions: {
        waterfall: {
          negativeColor: '#EEFFC6'
        }
      },

      series: []
    });
  }

  copyVersion(selectedVersion) {
    this.showVersionNameDialog = true;
    this.selectedVersionForCopy = selectedVersion;
  }

  hideVersionNameDialog() {
    this.showVersionNameDialog = false;
    this.selectedVersionForCopy = null;
  }

  addValuationDateVersion() {
    if(!this.newFormVersion || this.newFormVersion.length === 0) return;

    // const selectedVersion = this.selectedFormVersions.find(fv => fv.frozen);
    const selectedVersion = this.selectedVersionForCopy;
    
    this.newCompany.name = selectedVersion.companyName;
    this.newCompany.groupFormId = this.primaryCompanyId;
    this.newCompany.valuationDate = selectedVersion.valuationDate;
    
    this.newCompany.businessUnitsNumber = selectedVersion.businessUnitsNumber ? selectedVersion.businessUnitsNumber: 0;

    this.modalService.dismissAll();

    this.createValuationDate(this.newCompany, this.newFormVersion, false, selectedVersion);
    this.hideVersionNameDialog();
  }

  hideUpdateVersionNameDialog() {
    this.showUpdateVersionNameDialog = false;
    this.selectedVersionForUpdate = null;
  }

  updateVersion(selectedVersion) {
    this.showUpdateVersionNameDialog = true;
    this.selectedVersionForUpdate = selectedVersion;
  }

  updateVersionName(){
    const selectedVersionData = this.selectedVersionForUpdate;

    const reqBody = {
      "id": selectedVersionData.id,
      "versionName": this.newVersionName
    };

    this.dataService.saveUpdatedVersionName(reqBody).subscribe(res => {
      const apiResponse = res.body["response"];

      selectedVersionData.versionName = this.newVersionName;
      
      this.utilService.showMessage("Version Name has been updated successfully", "Ok");
    }, error => {
      this.utilService.showMessage("Failed to update Version Name", "Ok", true);
    });

    this.hideUpdateVersionNameDialog();
  }

  addValuationDate() {

    if(!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;

    this.newCompany.valuationDate = (moment(this.newCompany.valuationDate)).format("YYYY-MM-DD");

    this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates( this.primaryCompanyId );

    let latestFormToCopy = this.selectedCompanyDates.find( comp => !comp.userEntered && comp.status !== "Initiated")
    
    this.createValuationDate(this.newCompany, "FIRST", true, latestFormToCopy);
  }

  createValuationDate(newCompanyInfo, versionName, frozen, copyDataFromForm) {

    this.utilService.showLoadingPopup();
    this.ms.publish("loadingMessage", "Preparing data for valuation date");

    let loggedInUserDetails = this.ums.getSelectedUserDetails();
    
    newCompanyInfo.oldConsolForm = copyDataFromForm? copyDataFromForm.id : null;
    const loggedInUser = this.ums.getUserDetails();

    this.dataService.createNewCompanyWithVersion(newCompanyInfo, loggedInUserDetails, this.ums.getApplicationVersion(), versionName, frozen, loggedInUser, this.selectedCompanyDates[0].fundCompany).subscribe(res=>{
      // console.log("Company Created", res.body);

      const newCompanyCreated = res.body["response"];

      if(frozen) {
        this.portfolioService.prepareCompanyData([ newCompanyCreated ]);

        this.selectedCompanyDates = this.portfolioService.getCompanyAllValuationDates( this.primaryCompanyId );
      }
      
      if(!copyDataFromForm) {
        // None of the forms are submitted. So skip copying answers
        this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);
      } else {

          this.dataService.copyFormAnswers(copyDataFromForm.id, newCompanyCreated.id).subscribe((saveResult)=>{
            // console.log("copy answers is successful", saveResult);

            this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);

          }, (error)=>{
            console.log("error in copying form answers", error);

            this.portfolioService.assignUsersToForm(newCompanyCreated, this.newCompany.name);
          }); 
      }

      this.prepareValuationGridDataSource();
    }, err=>{
      console.log("Error while creating company", err)
      this.utilService.showMessage("Sorry, failed to create " + this.newCompany.name + " valuation.", "Ok");
    })
    
  }

  //In case of Business Unit Forms, check if consol form currency is available.
  isCurrencyAvailable(businessUnitForm) {
    let consolForm = businessUnitForm;
    
    if(businessUnitForm.consolForm) {
      consolForm = this.selectedCompanyDates.find(f => f.id === businessUnitForm.consolForm);
    }

    if(!consolForm) {
      return "NotAvailable";
    }

    if(this.buEditMode && consolForm.id === businessUnitForm.id) {
      return true;
    }
    else if(consolForm.status === "Initiated") {
      return "NotAvailable";
    } else {
      return consolForm.investment && consolForm.investment.currency && consolForm.investment.currency.length > 1;
    }
  }

  openSAF(comp, businessUnitPopup, forceEdit?) {
    this.modalService.dismissAll();

    if(!comp.editable) return;

    if(!forceEdit && comp.businessUnitsNumber && comp.businessUnitsNumber > 0){
      this.buShareMode = false;
      this.buEditMode = true;
      this.openBusinessUnits(comp, businessUnitPopup)
      return;
    }

    const version = this.ums.getApplicationVersion() + "";
    
    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version); 
    localStorage.setItem('qubit-val-date', comp.valuationDate); 
    localStorage.setItem('qubit-investment-date-id', this.primaryCompanyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName = comp.versionName;

    let urlParameters = "v=" + version + "&id=" + comp.id + "&fundName="+fundName + "&versionName="+versionName;
    if(comp.consolForm) {
      const consolForm = this.selectedCompanyDates.find(f => f.id === comp.consolForm);

      if(consolForm) {
        urlParameters += "&srcCurrency=" + consolForm.currency 
          + "&consol=" + consolForm.companyNameInForm
          + "&marketDate=" + consolForm.marketDate;
      } else {
        const consolForm = this.selectedCompanyDates.find(f => f.id === this.portfolioService.companyId);
        urlParameters += "&srcCurrency=" + consolForm.currency 
          + "&consol=" + consolForm.companyNameInForm
          + "&marketDate=" + consolForm.marketDate;
      }
    }

    window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&" + urlParameters, "_self");
  }

  openValuation(comp, businessUnitPopup, forceOpen?) {
    this.modalService.dismissAll();

    if(comp.businessUnitsNumber && comp.businessUnitsNumber > 0){
      if(!forceOpen) {
        this.buShareMode = false;
        this.buEditMode = false;
        this.openBusinessUnits(comp, businessUnitPopup)
      }
      else {
        this.showByTopMenu('consol');
      }
      return;
    }

    this.portfolioService.openValuation(comp);
  }

  openSharePopUp(comp, businessUnitPopup, valuationSharePopUp){
    if(comp.businessUnitsNumber && comp.businessUnitsNumber > 0){
      this.buShareMode = true;
      this.openBusinessUnits(comp, businessUnitPopup)
      return
    }

    this.openValuationSharePopUp(comp, valuationSharePopUp)
  }

  openCurrencyDashboardPopup(content) {
    this.modalService.open(content, { centered: true })
  }

  openBusinessUnits(comp, content) {
    this.selectedBusinessUnits = null;

    this.utilService.showLoadingPopup();

    const consolForm = this.selectedCompanyDates.find(f => f.id === comp.id) || comp;

    this.selectedCompanyConsolForm = cloneDeep(consolForm); //loading consol summary selected valuation date, passing as an input to consol summary

    this.utilService.closeAllPopups();
    if(consolForm && consolForm.businessUnits) {
      this.selectedBusinessUnits = [];

      // Add consol form also in edit mode so that consol form can be opened into SAF
      // if(this.buEditMode) {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm);
      // }

      if(consolForm.status !== "Initiated") {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm.businessUnits);

        consolForm.businessUnits.forEach(form => {

          form.editable = comp.editable;

          const details = JSON.parse(form.details);

          if(details) {  
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
            }

            form.versionName = details["versionName"];
            form.businessUnitName = details["businessUnitName"];
            form.status = details["status"];
          }
        })

      }

      this.modalService.open(content, { centered: true })
    } else {
      this.openSAF(comp, null, true)
    }
  }

  openVersions(comp, content) {
    this.selectedFormVersions = null;

    this.utilService.showLoadingPopup();

    this.dataService.getAllFormsVersions(comp.groupFormId || comp.id, comp.valuationDate).subscribe(res => {
      this.selectedFormVersions = res.body["response"];
      this.utilService.closeAllPopups();

      // console.log("this.selectedFormVersions", this.selectedFormVersions);

      if(this.selectedFormVersions && this.selectedFormVersions.length > 0) {
        this.selectedFormVersions.forEach(form => {
          const details = JSON.parse(form.details);

          if(details) {               
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
              
            } else {
              form.companyNameInForm = form.companyName;
            }

            form.editable = comp.editable;

            form.versionName = details["versionName"];
            form.frozen = details["frozen"];
            form.status = details["status"];
            form.currency = details["currency"];
            form.businessUnitsNumber = details["businessUnitsNumber"];

            if(form.businessUnits) {
              this.portfolioService.initBU_ConsolSummary(form);
            }
          }
        })
        this.modalService.open(content, { centered: true })

      } else {
        this.utilService.showMessage("Failed fetch version information", "Ok", true);
      }
      
    }, err => {
      this.utilService.showMessage("Failed fetch version information", "Ok", true);
    })
  }

  selectVersion(comp) {

    // console.log("Freezing . . .");

    let formObj;

    this.selectedFormVersions = null;

    this.utilService.showLoadingPopup();

    this.dataService.getAllFormsVersions(comp.groupFormId || comp.id, comp.valuationDate).subscribe(res => {
      this.selectedFormVersions = res.body["response"];
      this.utilService.closeAllPopups();

      // console.log("this.selectedFormVersions", this.selectedFormVersions);

      if(this.selectedFormVersions && this.selectedFormVersions.length > 0) {
        this.selectedFormVersions.forEach(form => {
          const details = JSON.parse(form.details);

          if(details) {               
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);
              
            } else {
              form.companyNameInForm = form.companyName;
            }

            form.editable = comp.editable;

            form.versionName = details["versionName"];
            form.frozen = details["frozen"];
            form.status = details["status"];
            form.currency = details["currency"];
            form.businessUnitsNumber = details["businessUnitsNumber"];

            formObj = form;
            if(form.businessUnits) {
              this.portfolioService.initBU_ConsolSummary(form);
            }

            // const frozenForm = this.selectedFormVersions.find(fv => fv.frozen == true);

            if(form.frozen == true)
            {
              this.dataService.freezeFormVersion(form.valuationDate, form.id, comp.id).subscribe(res => {
                console.log("Freezing new form version is done");
          
                const dialog = this.utilService.showMessage("The selected valuation date is frozen. Page will be refreshed to show the update", "Ok")
                dialog.afterClosed().subscribe(()=>{
                  
                  window.location.reload();
                })
              }, error =>{
                console.log("Error while freezing form version - " + form.versionName);
                this.utilService.showMessage("Failed to freeze the form version - " + form.versionName, "Ok", true);
              })
            }

          }
        })
        // this.modalService.open(content, { centered: true })

      } else {
        this.utilService.showMessage("Failed fetch version information", "Ok", true);
      }
      
    }, err => {
      this.utilService.showMessage("Failed fetch version information", "Ok", true);
    })
  }

  freezeThisVersion(form) {
    //The version is already frozen
    if(form.frozen || !form.editable) return;
    
    this.modalService.dismissAll();

    this.utilService.showLoadingPopup();

    const frozenForm = this.selectedFormVersions.find(fv => fv.frozen);

    this.dataService.freezeFormVersion(frozenForm.valuationDate, frozenForm.id, form.id).subscribe(res => {
      // console.log("Freezing new form version is done");

      const dialog = this.utilService.showMessage("The selected valuation date is frozen. Page will be refreshed to show the update", "Ok")
      dialog.afterClosed().subscribe(()=>{
        
        window.location.reload();
      })
    }, error =>{
      console.log("Error while freezing form version - " + form.versionName);
      this.utilService.showMessage("Failed to freeze the form version - " + form.versionName, "Ok", true);
    })
  }

  deleteCompany(company){

    if(!company.editable) return;


    let comapnyIdList :string[] = [company.id];
    
    const companyValDate = this.datePipe.transform(company.valuationDate, 'mediumDate');
    const versionName = " (" + this.titlecasePipe.transform(company.versionName) + ")";

    const confirm = this.utilService.showConfirmMessage("Please confirm if you wish to delete - " + companyValDate + versionName, "Yes", "No");
    
    confirm.afterClosed().subscribe(confirmation => { 
      if(confirmation === "Yes") {

        if(!company.userEntered) {
          
            const orgId = this.ums.getSelectedUserDetails().organization.id;

            this.dataService.deleteCompanies(comapnyIdList, orgId).subscribe(response => {
              // console.log("Successfully Deleted");
              this.modalService.dismissAll(); 

              this.deleteCompanyFromList(company);

              this.utilService.showMessage("Successfully deleted - " + companyValDate + versionName, "Ok");
            }, error => {
              console.log("Error While Deleting Companies", error);
              this.modalService.dismissAll();
              this.utilService.showMessage("Error: Failed to delete - " + companyValDate + ". Please try after sometime.", "Ok", true);
            });
        } else {
          
          // this.dataService.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]), this.portfolioService.companyId).subscribe(res=>{
     
          const savedIndex = this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId].findIndex(comp => comp.id === company.id);
          this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId].splice(savedIndex, 1);

          //Save updated list of user entered companies
          this.dataService.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]), this.portfolioService.companyId).subscribe(res=>{
            
            this.deleteCompanyFromList(company);

            this.utilService.showMessage("Successfully deleted - " + companyValDate + versionName, "Ok");

          }, err => {
            this.utilService.showMessage("Error: Failed to delete - " + companyValDate + ". Please try after sometime.", "Ok", true);
          });
        }
      }
    });
  }

  deleteCompanyFromList(company) {
    const displayCompIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(displayCompIndex >= 0) {
      this.selectedCompanyDates.splice(displayCompIndex, 1)
    }

    const companyIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(companyIndex >= 0) {
      this.selectedCompanyDates.splice(companyIndex, 1)
    }

    this.utilService.showLoadingPopup();
    this.prepareValuationGridDataSource();
  }

  openPopupModal(content, index) {
    this.modalService.open(content, { centered: true });
    this.editedValuationDateIndex = index;
    // console.log(index);
  }

  addNewValuationDate(editValuationDateModal){
    let companyId = this.selectedCompanyDates[this.editedValuationDateIndex].id;
    let formattedDate = moment(this.editedValuationDate).format("YYYY-MM-DD");
    // console.log(formattedDate); 
    // console.log(companyId);
    let reqBody ={
      companyId: companyId,
      valuationDate: formattedDate
    };
    this.dataService.addNewValuationDate(reqBody).subscribe(result=>{
      // console.log("New Valuation date added successfully");
      this.selectedCompanyDates[this.editedValuationDateIndex].valuationDate = this.editedValuationDate;
      editValuationDateModal.dismiss('Cross click');
    },error=>{
      console.log("Error while adding Valuation date.")
    });

  }

  showBuildUps(){
    this.showBuildUpSummary = true;
  }
  
  showByTopMenu(ui, comp?) {
    this.showConsolSummary = false;
    this.showUserValDates = false;
    this.showValDates = false;
    this.showInvestment = false;

    if(ui === "vd") {
      this.showValDates = true;
    } else if(ui === "userVD") {
      this.showUserValDates = true;
    } else if(ui === "consol") {
      if(comp){
        this.selectedCompanyConsolForm = comp //when top menu is clicked loading latestValuationDate Consol Summary
      }
      this.showConsolSummary = true;
    } else if(ui === 'INVESTMENT') {
      this.showInvestment = true;
    }
  }

  // ----  ----  ----  ----  ----  ----  ----  ----  ---- 
  // ---- Valuation Date Share to external user ---- 
  openValuationSharePopUp(form, content) {
    this.selectedFormToShare = form;

    this.modalService.open(content, { centered: true })
  }

  share(popUp) {
    // console.log(this.shareInput);

    if (this.shareInput.users && this.shareInput.users.length > 0 && this.isValidShareInput(this.shareInput.users)) {

        const selectedTabs = this.shareInput.sharePermissions.filter(tab => tab['selected'] )
        if(selectedTabs.length > 0) {
          this.shareInput['sharedTabNames'] = selectedTabs.map( tab => tab.label).join(", ");
        } else {
          //No tabs are selected. Stay on the Input Popup.
          return;
        }

        popUp.dismiss('Cross click');

        this.shareInput["valuationDate"] = this.datePipe.transform(this.selectedFormToShare.valuationDate, "MMM dd, yyyy");
        this.shareInput["companyName"] = this.newCompany.name;

        this.dataService.shareFormToExternalUser(this.selectedFormToShare.id, this.shareInput).subscribe(res => {
          
          let shareInputUserNames = this.shareInput.users.map(obj=>obj.name).toString();
          this.utilService.showMessage("Valuation date (" + this.selectedFormToShare.valuationDate + ") is shared to " + shareInputUserNames, "Ok");
        }, error => {
          
          this.utilService.showMessage("Failed to share valuation date (" + this.selectedFormToShare.valuationDate + ")", "Ok", true);
        })
        
    } else {
      // Stay on the Input Popup.
    }    
  }

  isValidShareInput(users) {
    users.forEach(obj => {
      if (!(obj.name.length > 3 && obj.email.length > 3)) {
        return false;
      }
    });
    return true;
  }

  selectedFormToShare;

  shareInput = {
    users: [],
    name: null,
    email: null,
    sharePermissions: [
      {
        label: 'Valuation Summary',
        path: '/valuation-summary',
        id: 'valuation-summary',
        selected: false
      },
      {
        label: 'Valuation',
        path: '/valuation',
        id: 'valuation',
        selected: false
      },
      {
        label: 'Form',
        path: '/form',
        id: 'form',
        hide: true,
        selected: false
      },
      {
        label: 'Valuation Tracker',
        path: '/valuation-tracker',
        id: 'valuation-tracker',
        hide: true,
        selected: false
      },
      {
        label: 'Benchmarking',
        path: '/benchmarking',
        id: 'benchmarking',
        selected: false
      },
      {
        label: 'KPI',
        path: '/kpi',
        id: 'kpi',
        selected: false
      },
      {
        label: 'Additional Analysis',
        path: '/additionalAnalysis',
        id: 'additionalAnalysis',
        hide: true,
        selected: false
      },
      {
        label: 'Calibration System',
        path: '/caliberationSystem',
        id: 'caliberationSystem',
        selected: false
      },
      {
        label: 'Cap Table',
        path: '/capTable',
        id: 'capTable',
        selected: false
      },
      {
        label: 'Waterfall Analysis',
        path: '/cca',
        id: 'cca',
        selected: false
      },
      {
        label: 'Files',
        path: '/files',
        id: 'files',
        selected: false
      },
      {
        label: 'Audit Trail',
        path: '/auditTrail',
        id: 'auditTrail',
        selected: false
      },
      {
        label: 'News Analysis',
        path: '/reviewAnalysis',
        id: 'reviewAnalysis',
        hide: true,
        selected: false
      },
      {
        label: 'ECT Analysis',
        path: '/ectAnalysis',
        id: 'ectAnalysis',
        hide: true,
        selected: false
      }
    ]
  }

  checkUncheckAll(){
    if (this.shareInput.sharePermissions.every(val => val.selected == true))
      this.shareInput.sharePermissions.forEach(val => { val.selected = false });
    else
      this.shareInput.sharePermissions.forEach(val => { val.selected = true });
  }

  addShareInputs(){
    if(this.shareInputName && this.shareInputEmail 
      && this.shareInputName.length >= 3 && this.shareInputEmail.length >= 3)
    {
      this.shareInput.users.push({ name: this.shareInputName, email: this.shareInputEmail});
      
      this.shareInputName = "";
      this.shareInputEmail = "";
    }
  }

  removeShareInputs(index) {
    this.shareInput.users.splice(index, 1);
  }

}