import { Injectable } from '@angular/core';
import { UtilService } from '../../util.service';
import { ImpliedDecline, RiskReturnFramework, Threshold, DiscountRateSizePremium } from '../model/configuration-model';

@Injectable({
  providedIn: 'root'
})

//Attention : Any update in this Service should also be done in Waterfall-UI repository
export class ConfigurationService {

  configurationList = []

  VALUATION_RETURN_RISK_FRAMEWORK : RiskReturnFramework = new RiskReturnFramework();
  SIZE_PREMIUM : DiscountRateSizePremium = new DiscountRateSizePremium();

  IMPLIED_DECLINE_CALIBRATION : ImpliedDecline = new ImpliedDecline();

  THRESHOLD : Threshold = new Threshold();

  constructor(private utilService : UtilService){}

  initConfigurationDetails(configurationList){
    if(configurationList && configurationList.length > 0){
      this.initImpliedDeclineConfig(configurationList);

      this.initValuationRiskReturnConfig(configurationList);

      this.initThresholdConfig(configurationList);

      this.initDiscountRateSizePremium(configurationList)
    }
  }

  initImpliedDeclineConfig(configurationList){
    const configFound = configurationList.find(config => config.configuration == "enableImpliedDecline");
    if(configFound){
      this.IMPLIED_DECLINE_CALIBRATION.isSelected = this.getBooleanValue(configFound.value)
    }
  }

  initValuationRiskReturnConfig(configurationList){
    const configFound = configurationList.find(config => config.configuration == "valuationAndRiskReturn");
    if(configFound){``
      this.VALUATION_RETURN_RISK_FRAMEWORK.isSelected = this.getBooleanValue(configFound.value)
    }
  }

  initDiscountRateSizePremium(configurationList){
    const configFound = configurationList.find(config => config.configuration == "sizePremium");
    if(configFound){``
      this.SIZE_PREMIUM.isSelected = this.getBooleanValue(configFound.value)
    }
  }

  initThresholdConfig(configurationList){
    let configFound = configurationList.find(config => config.configuration == "valuationMarkThreshHold");
    if(configFound){
      this.THRESHOLD.isSelected = this.getBooleanValue(configFound.value)
    }

    configFound = configurationList.find(config => config.configuration == "applyChangeInValDateNav");
    if(configFound){
      this.THRESHOLD.changeInNAVBetweenValDates.isSelected = this.getBooleanValue(configFound.value)

      const value = configurationList.find(config => config.configuration == "changeInValDateNavPercentage");
      if(value){
        this.THRESHOLD.changeInNAVBetweenValDates.value = this.utilService.getValidNumber(configFound.value)
      }
    }

    configFound = configurationList.find(config => config.configuration == "applyChangeInTotalNav");
    if(configFound){
      this.THRESHOLD.changeInTotalPortfolioNAV.isSelected = this.getBooleanValue(configFound.value)

      const value = configurationList.find(config => config.configuration == "changeInTotalNavPercentage");
      if(value){
        this.THRESHOLD.changeInTotalPortfolioNAV.value = this.utilService.getValidNumber(configFound.value)
      }
    }

  }

  getBooleanValue(value){
    return value && value.toLowerCase() == "true" ? true : false;
  }
}
