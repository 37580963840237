import { Directive, HostListener, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material';

@Directive({
  selector: 'mat-checkbox[controlledCheckbox]',
})
export class MatControlledCheckboxDirective {
  @Input('checkBoxRef') checkbox: MatCheckbox;
  @Input() checked: string = '1';
  @Input() indeterminateValue: boolean = false;

  @HostListener('change', ['$event'])
  onChange(event) {
    // target.checked = !target.checked;
    event.checked = !event.checked;
  }
}
