<div class="waterfall-container">
  <div class="row" style="padding-bottom: 10px;">
    <div class="col-12 row">
      <span class="main-heading">{{translateService.getLabel("cx_as_of")}} </span>
      <span class="col-2">
      <mat-form-field class="custom-input-select">
        <mat-select disableOptionCentering [(ngModel)]="selectedValuationDate" (selectionChange)="onValuationDateChange($event)">
          <mat-option *ngFor="let valDate of valuationDates" [value]="valDate">
            {{valDate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="col-2 reset-mannual-inputs">
      <i class="fas fa-sync-alt" (click)="resetMannualInputs()"></i>
    </span>
    </div>
  </div>
  <ng-container *ngIf="showGoingConcernInputs">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="col-4 input-label"> {{translateService.getLabel("sensitivity")}}</div>
        <div class="col-6">
          <ejs-numerictextbox *ngIf="goingConcernInputs.hasOwnProperty('sensitivity')" class="input-field yellow-input" (input)="calculateGoingConcernInput()" min="0"
            format="#,###.0  '%'" [(ngModel)]="goingConcernInputs.sensitivity" [showSpinButton]="false">
          </ejs-numerictextbox>
        </div>
      </div>
    </div>
    <div class="col-6 align-right">
      <button class="btn-custom-primary" 
       [disabled] = "exceedsWeightLimit || noEvSelection"
      (click)="saveGoingConcernInputs()"> {{translateService.getLabel("save")}} </button>
    </div>
  </div>
  <div class="row">
    <div class="col-4" style="margin-top: 15px;">
        <div class="custom-accordion-warrants">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{translateService.getLabel("multiples_used_to_calculate_ev")}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngFor="let option of evMultiples">
                      <!-- [checked]="multipleBasedWarrant?.multipleBasedWarrantMetaDtoMap[option.key]?.checked" [disabled]="!editLockService.lockStatus" (change)="onMultiplesChange(option.key, $event)" -->
                        <ejs-checkbox 
                        [checked]="goingConcernInputs[option.key]?.checked"
                        (change)="onMultiplesChange(option.key, $event)" >
                        </ejs-checkbox><label style="padding-left: 10px;">{{option.displayValue}}</label>
                    </div>
                    <div class="error-msg" *ngIf="noEvSelection" >{{translateService.getLabel("select_any_one_multiple_to_proceed")}}</div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
  <div class="row">
    <div class="col-12">
      <div class="error-msg" *ngIf="exceedsWeightLimit">{{translateService.getLabel("total_weight_should_be")}} 100</div>
      <div class="table-custom-border table_wrapper">
        <table class="table">
          <tr class="table-custom-header">
            <th style="width: 45%"></th>
            <th class="align-right" style="width: 10%;">{{translateService.getLabel("weight")}}</th>
            <th class="align-right" style="width: 10%;">{{translateService.getLabel("low")}}</th>
            <th class="align-right" style="width: 15%;">{{translateService.getLabel("midpoint")}}</th>
            <th class="align-right" style="width: 10%;">{{translateService.getLabel("high")}}</th>
            <th style="width: 5%;"></th>
          </tr>
          <tbody *ngIf="goingConcernInputs">
            <tr *ngIf="goingConcernInputs.revenue?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("revenue")}}</td>
          <td class="align-right">
            <ng-container *ngIf="goingConcernInputs.revenue?.hasOwnProperty('weight')" >
              <ejs-numerictextbox  class="input-field yellow-input" min="0"
              [(ngModel)]="goingConcernInputs.revenue.weight" (input)="calculateGoingConcernInput('revenue')"
              format="#,###.0 '%'" [showSpinButton]="false">
            </ejs-numerictextbox>
            </ng-container>
          </td>
              <td class="align-right">

                {{ goingConcernInputs.revenue?.low | dynamicDecimalPipe }}
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.revenue?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.revenue, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.revenue }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.revenue.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('revenue', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">
                {{ goingConcernInputs.revenue?.high | dynamicDecimalPipe }}
              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.revenue?.checked"  class="table-custom-body">
              <td>{{translateService.getLabel("ev_revenue")}}</td>
              <td></td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.revenueMultiple?.hasOwnProperty('low')" >
                  <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.revenueMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.revenueMultiple }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.revenueMultiple.low" (input)="calculateGoingConcernInput();updateFieldStatus('revenueMultiple', 'low')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>

              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.revenueMultiple?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.revenueMultiple, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.revenueMultiple }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.revenueMultiple.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('revenueMultiple', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">

                <ng-container *ngIf="goingConcernInputs.revenueMultiple?.hasOwnProperty('high')" >
                  <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.revenueMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.revenueMultiple }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.revenueMultiple.high" (input)="calculateGoingConcernInput();updateFieldStatus('revenueMultiple', 'high')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>
              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.grossprofit?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("gross_profit")}}</td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.grossprofit?.hasOwnProperty('weight')" >
                  <ejs-numerictextbox  class="input-field yellow-input" min="0"
                  [(ngModel)]="goingConcernInputs.grossprofit.weight" (input)="calculateGoingConcernInput('grossprofit')"
                  format="#,###.0 '%'"[showSpinButton]="false">
                </ejs-numerictextbox>
                </ng-container>
              </td>
              <td class="align-right">
                {{ goingConcernInputs.grossprofit?.low | dynamicDecimalPipe }}
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.grossprofit?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.grossprofit, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.grossprofit }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.grossprofit.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('grossprofit', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">
               {{ goingConcernInputs.grossprofit?.high | dynamicDecimalPipe }}
              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.grossprofit?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("ev_gross_profit")}}</td>
              <td></td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.grossprofitMultiple?.hasOwnProperty('low')" >
                  <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.grossprofitMultiple ,
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.grossprofitMultiple }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.grossprofitMultiple.low" (input)="calculateGoingConcernInput();updateFieldStatus('grossprofitMultiple', 'low')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.grossprofitMultiple?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.grossprofitMultiple ,
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.grossprofitMultiple }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.grossprofitMultiple.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('grossprofitMultiple', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.grossprofitMultiple?.hasOwnProperty('high')" >
                  <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.grossprofitMultiple ,
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.grossprofitMultiple }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.grossprofitMultiple.high" (input)="calculateGoingConcernInput();updateFieldStatus('grossprofitMultiple', 'high')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>
              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.ebitda?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("ebitda")}}</td>
              <td class="align-right" >
              <ng-container *ngIf="goingConcernInputs.ebitda?.hasOwnProperty('weight')" >
              <ejs-numerictextbox  class="input-field yellow-input" min="0"
              [(ngModel)]="goingConcernInputs.ebitda.weight" (input)="calculateGoingConcernInput('ebitda')"
              format="#,###.0 '%'" [showSpinButton]="false">
            </ejs-numerictextbox>
          </ng-container>
          </td>
              <td class="align-right">
                {{ goingConcernInputs.ebitda?.low | dynamicDecimalPipe }}

              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebitda?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitda, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitda }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.ebitda.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('ebitda', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">

               {{ goingConcernInputs.ebitda?.high | dynamicDecimalPipe }}
              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.ebitda?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("ev_ebitda_multiple")}}</td>
              <td></td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebitdaMultiple?.hasOwnProperty('low')">
                  <ejs-numerictextbox  class="input-field" 
                  [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.ebitdaMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.ebitdaMultiple}"
                    min="0"
                    [(ngModel)]="goingConcernInputs.ebitdaMultiple.low" (input)="calculateGoingConcernInput();updateFieldStatus('ebitdaMultiple', 'low')"
                    format="#,###.0  'x'" [showSpinButton]="false">
                  </ejs-numerictextbox>
                  </ng-container>
              </td>
              <td class="align-right">
                <ng-container >
                <ejs-numerictextbox *ngIf="goingConcernInputs.ebitdaMultiple?.hasOwnProperty('midpoint')" class="input-field" 
                [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitdaMultiple, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitdaMultiple}"
                  min="0"
                  [(ngModel)]="goingConcernInputs.ebitdaMultiple.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('ebitdaMultiple', 'midpoint')"
                  format="#,###.0  'x'" [showSpinButton]="false">
                </ejs-numerictextbox>
                </ng-container>
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebitdaMultiple?.hasOwnProperty('high')">
                  <ejs-numerictextbox  class="input-field" 
                  [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.ebitdaMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.ebitdaMultiple}"
                    min="0"
                    [(ngModel)]="goingConcernInputs.ebitdaMultiple.high" (input)="calculateGoingConcernInput();updateFieldStatus('ebitdaMultiple', 'high')"
                    format="#,###.0  'x'" [showSpinButton]="false">
                  </ejs-numerictextbox>
                  </ng-container>

              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.ebit?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("ebit")}}</td>
              <td class="align-right" >
              <ng-container *ngIf="goingConcernInputs.ebit?.hasOwnProperty('weight')" >
              <ejs-numerictextbox  class="input-field yellow-input" min="0"
              [(ngModel)]="goingConcernInputs.ebit.weight" (input)="calculateGoingConcernInput('ebit')"
              format="#,###.0 '%'" [showSpinButton]="false">
            </ejs-numerictextbox>
          </ng-container>
          </td>
              <td class="align-right">
               
                {{ goingConcernInputs.ebit?.low | dynamicDecimalPipe }}
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebit?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field" 
                [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebit,
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebit }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.ebit.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('ebit', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">

                {{ goingConcernInputs.ebit?.high | dynamicDecimalPipe }}

              </td>
              <td></td>
            </tr>
            <tr *ngIf="goingConcernInputs.ebit?.checked" class="table-custom-body">
              <td>{{translateService.getLabel("ev_ebit")}}</td>
              <td class="align-right"></td>
              <td class="align-right">

                <ng-container *ngIf="goingConcernInputs.ebitMultiple?.hasOwnProperty('low')" >
                  <ejs-numerictextbox  class="input-field"
                  [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.ebitMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.low?.ebitMultiple
                }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.ebitMultiple.low" (input)="calculateGoingConcernInput();updateFieldStatus('ebitMultiple', 'low')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebitMultiple?.hasOwnProperty('midpoint')" >
                <ejs-numerictextbox  class="input-field"
                [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitMultiple, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.ebitMultiple
              }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.ebitMultiple.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('ebitMultiple', 'midpoint')"
                  format="#,###.0" [showSpinButton]="false">
                </ejs-numerictextbox>
              </ng-container>
              </td>
              <td class="align-right">
                <ng-container *ngIf="goingConcernInputs.ebitMultiple?.hasOwnProperty('high')" >
                  <ejs-numerictextbox  class="input-field"
                  [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.ebitMultiple, 
                  'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.high?.ebitMultiple }"
                    min="0"
                    [(ngModel)]="goingConcernInputs.ebitMultiple.high" (input)="calculateGoingConcernInput();updateFieldStatus('ebitMultiple', 'high')"
                    format="#,###.0" [showSpinButton]="false">
                  </ejs-numerictextbox>
                </ng-container>

              </td>
              <td></td>
            </tr>
            <tr class="table-custom-body">
              <td class="normal-cell">{{translateService.getLabel("enterprise_value")}}</td>
              <td></td>
              <td class="align-right">{{goingConcernInputs.enterpriseValue?.low | dynamicDecimalPipe}} </td>
              <td class="align-right">{{goingConcernInputs.enterpriseValue?.midpoint | dynamicDecimalPipe}} </td>
              <td class="align-right">{{goingConcernInputs.enterpriseValue?.high | dynamicDecimalPipe}} </td>
              <td></td>
            </tr>
            <tr class="table-custom-body">
              <td>{{translateService.getLabel("cash")}}</td>
              <td></td>
              <td class="align-right">{{goingConcernInputs.cash?.low | dynamicDecimalPipe}}</td>
              <td class="align-right">
                <ng-container >
                <ejs-numerictextbox *ngIf="goingConcernInputs.cash?.hasOwnProperty('midpoint')" class="input-field"
                [ngClass]="{'blue-input': !debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.cash, 
                'yellow-input': debtPortfolioService?.waterfallFieldStatuses?.goingConcernInputs?.midpoint?.cash }"
                  min="0"
                  [(ngModel)]="goingConcernInputs.cash.midpoint" (input)="calculateGoingConcernInput();updateFieldStatus('cash', 'midpoint')" format="#,###.0"
                  [showSpinButton]="false">
                </ejs-numerictextbox>
                </ng-container>
              </td>
              <td class="align-right">{{goingConcernInputs.cash?.high | dynamicDecimalPipe}}</td>
              <td></td>
            </tr>

            <tr class="table-custom-body" *ngFor="let row of goingConcernInputs.adjustments; let index = index">
              <td>{{row.name}}</td>
              <td></td>
              <td class="align-right normal-cell">{{row.amount | dynamicDecimalPipe}}</td>
              <td class="align-right">{{row?.amount | dynamicDecimalPipe}}</td>
              <td class="align-right">{{row?.amount | dynamicDecimalPipe}}</td>
              <td class="align-center">
                <em class="fas fa-edit action-icon primary-color" (click)="openPopUpEdit(applicationContent,row)"> </em>
                <i class="fa fa-trash action-icon primary-color" style="padding-left: 10px;"
                  (click)="deleteAdjustment(index)"></i>
              </td>
            </tr>
            <tr class="table-custom-body">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="align-center">
                <button class="btn-custom-primary" (click)="openPopUpAdd(applicationContent)"> {{translateService.getLabel("add")}} </button>
              </td>
            </tr>
            <tr class="table-last-total-row">
              <td class="last-row-cell">{{translateService.getLabel("total_distributable_value")}}</td>
              <td></td>
              <td class="align-right last-row-cell">{{goingConcernInputs.totalDistributableValue?.low | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">
                {{goingConcernInputs.totalDistributableValue?.midpoint | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">{{goingConcernInputs.totalDistributableValue?.high| dynamicDecimalPipe}}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div *ngIf="showWaterFall">
    <div class="row" style="padding-bottom: 10px; padding-top: 15px;">
      <div class="col-6">
        <span class="main-heading">{{translateService.getLabel("waterfall")}}</span>
      </div>
      <div class="col-6 align-right" *ngIf="!toggleEditClaims">
        <button class="btn-custom-primary" (click)="editClaims()"> {{translateService.getLabel("edit")}} </button>
      </div>
    </div>
    <div class="row" *ngIf="!toggleEditClaims">
      <div class="col-12">
        <div class="table-custom-border table_wrapper">
          <table class="table">
            <tr class="table-custom-header">
              <th rowspan="2">{{translateService.getLabel("security")}}</th>
              <th rowspan="2">{{translateService.getLabel("security_type")}}</th>
              <th rowspan="2" class="align-right">{{translateService.getLabel("ranking")}}</th>
              <th rowspan="2" class="align-right">{{translateService.getLabel("amount")}}</th>
              <th rowspan="2" class="align-right">{{translateService.getLabel("fund_exposure")}}</th>
              <th colspan="3" class="align-center">{{translateService.getLabel("payout")}}</th>
            </tr>
            <tr class="table-custom-header">
              <th class="align-right">{{translateService.getLabel("low")}}</th>
              <th class="align-right">{{translateService.getLabel("midpoint")}}</th>
              <th class="align-right">{{translateService.getLabel("high")}}</th>
            </tr>
            <tr class="table-custom-body" *ngIf="!waterfall?.length">
              <td colspan="8" class="normal-cell"> {{translateService.getLabel("no_records_to_display")}}</td>
            </tr>
            <ng-container *ngFor="let row of waterfall">
              <tr class="table-custom-body" [ngClass]="{'table-last-total-row': item.remainingDistributableValue}"
                *ngFor="let item of row.payouts">
                <ng-template [ngIf]="!item.remainingDistributableValue">
                  <td class="normal-cell">{{item.securityName}}</td>
                  <td class="normal-cell">{{item.securityType}}</td>
                  <td class="align-right">{{item.ranking}}</td>
                  <td class="align-right">{{item.amount | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{item.fundExposure ? 'Yes' : 'No' }}</td>
                  <td class="align-right">{{item.low | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{item.midpoint | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{item.high | dynamicDecimalPipe}}</td>
                </ng-template>
                <ng-template [ngIf]="item.remainingDistributableValue">
                  <td class="normal-cell" colspan="3">{{translateService.getLabel("remaining_distributable_value")}}</td>
                  <td></td>
                  <td></td>
                  <td class="align-right">{{item.remainingDistributableValue.low | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{item.remainingDistributableValue.midpoint | dynamicDecimalPipe}}</td>
                  <td class="align-right">{{item.remainingDistributableValue.high | dynamicDecimalPipe}}</td>
                </ng-template>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="toggleEditClaims">
      <div class="col-6">
        <div class="table-custom-border table_wrapper">
          <table class="table">
            <tr class="table-custom-header">
              <th>{{translateService.getLabel("security")}}</th>
              <td class="normal-cell">{{translateService.getLabel("security_type")}}</td>
              <th class="align-right">{{translateService.getLabel("ranking")}}</th>
              <th class="align-right">{{translateService.getLabel("amount")}}</th>
              <th class="align-right">{{translateService.getLabel("fund_exposure")}}</th>
              <th class="align-center"></th>
            </tr>
            <tr class="table-custom-body" *ngIf="!securities.length">
              <td colspan="6" class="normal-cell"> {{translateService.getLabel("no_records_to_display")}}</td>
            </tr>
            <tr class="table-custom-body" *ngFor="let item of securities ; let index = index">
              <td>
                {{item.securityName}}
              </td>
              <td >{{item.securityType}}</td>
              <td class="align-right normal-cell">
                {{item.ranking}}
              </td>
              <td class="align-right">
                {{item.amount | dynamicDecimalPipe}}
              </td> 
              <td class="align-right">
                {{item.fundExposure ? 'Yes' : 'No'}}
              </td>
              <td class="align-center">
                <em class="fas fa-edit action-icon primary-color" (click)="openPopupEditClaim(addClaim,item)"> </em>
                <i class="fa fa-trash action-icon primary-color" style="padding-left: 10px;"
                  (click)="deleteClaim(index, item)"></i>
              </td>
            </tr>
          </table>
        </div>
        <div class="row">
          <div class="col"><button class="btn-custom-primary" (click)="openPopupAddClaim(addClaim)"> {{translateService.getLabel("add")}} </button></div>
        </div>
        <!-- <div class="expected-exit">
          <div class="col-4 input-label">Expected Exit Date</div>
          <div class="col-4 input-label">
              {{ exitDate }}
          </div>
        </div> -->
           <div class="row">
          <div class="col-5 input-label">{{translateService.getLabel("expected_exit_date")}}</div>
          <div class="col-4">
            <!-- <ejs-datepicker *ngIf="toggleEditClaims" class="input-field" placeholder="yyyy-MM-dd" format='yyyy-MM-dd'[(ngModel)]="exitDate"  >
            </ejs-datepicker> -->
          <mat-form-field *ngIf="toggleEditClaims" class="dateInputBox custom-input-select" >
              <input matInput [matDatepicker]="picker" [(ngModel)]="exitDate" [placeholder]="translateService.getLabel('expected_exit_date')"  [min]="valuationDate" 
                  >
                  <!-- (dateChange)="onExitDateChange($event)" -->
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-8"></div>
          <div class="col-2 align-right">
            <button class="btn-custom-primary" (click)="saveAndCalculateGoingConcernWaterfall()"> {{translateService.getLabel("save")}} </button>
          </div>
          <div class="col-2">
            <button class="btn-custom-secondry" (click)="onCancelCalculateGoingConcernWaterfall()"> {{translateService.getLabel("cancel")}} </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="present-value" *ngIf="showPresentValue">
    <div class="row" style="padding-top: 15px; padding-bottom: 10px;">
      <div class="col-6">
        <span class="main-heading">{{translateService.getLabel("present_value_calculation")}}</span>
      </div>
      <!-- <div *ngIf="presentValErr" class="col-2 align-right present-value-err">
        Present value has unsaved changes.
      </div> -->
      <div class="col-6 align-right">
        <span *ngIf="presentValErr"  class="align-right present-value-err">
          {{translateService.getLabel("present_value_has_unsaved_changes")}}
        </span>
        <button class="btn-custom-primary" (click)="updatePresentvalues()" [disabled]="!presentValErr"> {{translateService.getLabel("save")}} </button>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
     
        <div *ngIf="!toggleEditClaims"  class="expected-exit">
          <div class="col-4 input-label">{{translateService.getLabel("expected_exit_date")}}</div>
          <div class="col-4 input-label">
              {{ exitDate }}
          </div>
        </div>
      </div>
    </div>
    <!-- {{ presentValue | json }} -->
    <div class="row" *ngIf="presentValue?.presentValue">
      <div class="col-12">
        <div class="table-custom-border table_wrapper">
          <table class="table">
            <tr class="table-custom-header">
              <th></th>
              <th class="align-right">{{translateService.getLabel("low")}}</th>
              <th class="align-right">{{translateService.getLabel("midpoint")}}</th>
              <th class="align-right">{{translateService.getLabel("high")}}</th>
            </tr>
            <ng-container *ngFor="let item of presentValue.presentValue;">
              <tr class="table-last-total-row">
                <td class="normal-cell" colspan="4">{{item.securityName}}</td>
              </tr>
              <tr class="table-custom-body">
                <td><span class="clickable" [class.not-clickable] = "!item?.debtModelId" (click)="navigateToDiscountRate(item)">{{translateService.getLabel("discount_rate")}} (%)</span></td>
                <td class="align-right normal-cell">
                  <!-- <mat-form-field class="custom-textbox">
                    <input class="align-right" matInput type="text" [ngModel]="item.low.discountRate | dmDecimal"
                    (ngModelChange)="item.low.discountRate=$event"
                    /> -->
                  <!-- {{getFormattedNumber(item.low.discountRate)}}% -->
                <!-- </mat-form-field> -->

                <ejs-numerictextbox  class="input-field yellow-input" #lowDiscountRate="ngModel"
                format="#,###.0 '%'" [ngModel]="item.low.discountRate" [showSpinButton]="false"
                  (ngModelChange) = "dirtyTrigger($event,lowDiscountRate);item.low.discountRate = $event"
                >
              </ejs-numerictextbox>
              <!-- <button [disabled] = "!lowDiscountRate.dirty" > SSSSS </button> -->
              <!-- {{ lowDiscountRate.control | json }} -->

                </td>
                <td class="align-right">
                  <!-- <mat-form-field class="custom-textbox">
                    <input class="align-right" matInput type="text" [(ngModel)]="item.mid.discountRate" />
                </mat-form-field>                   -->
                <ejs-numerictextbox  class="input-field yellow-input" #midDiscountRate="ngModel"
                format="#,###.0 '%'" [ngModel]="item.mid.discountRate" [showSpinButton]="false"
                (ngModelChange) = "dirtyTrigger($event,midDiscountRate);item.mid.discountRate = $event"
                >
              </ejs-numerictextbox>
                  </td>
                <td class="align-right">
                  <!-- <mat-form-field class="custom-textbox">
                    <input class="align-right" matInput type="text" [(ngModel)]="item.high.discountRate" />
                </mat-form-field> -->
                  <!-- {{getFormattedNumber(item.high.discountRate)}}% -->
                  <ejs-numerictextbox  class="input-field yellow-input" #highDiscountRate="ngModel"
                  format="#,###.0 '%'" [ngModel]="item.high.discountRate" [showSpinButton]="false"
                  (ngModelChange) = "dirtyTrigger($event,highDiscountRate);item.high.discountRate = $event"
                  >
                </ejs-numerictextbox>
                </td>
              </tr>
              <tr class="table-custom-body">
                <td>{{translateService.getLabel("discount_factor")}}</td>
                <td class="align-right normal-cell">{{item.low.discoutnFactor | dynamicDecimalPipe}}</td>
                <td class="align-right">{{item.mid.discoutnFactor | dynamicDecimalPipe}}</td>
                <td class="align-right">{{item.high.discoutnFactor | dynamicDecimalPipe}}</td>
              </tr>
              <tr class="table-custom-body">
                <td>{{translateService.getLabel("fair_value")}}</td>
                <td class="align-right normal-cell">{{item.low.fairValue | dynamicDecimalPipe}}</td>
                <td class="align-right">{{item.mid.fairValue | dynamicDecimalPipe}}</td>
                <td class="align-right">{{item.high.fairValue | dynamicDecimalPipe}}</td>
              </tr>
              <tr class="table-custom-body">
                <td>{{translateService.getLabel("stake_held_by_the_fund_in")}} {{ item?.securityName }} </td>
                <td class="align-right normal-cell">{{ item.low.stake | dynamicDecimalPipe }}%</td>
                <td class="align-right">
                  <!-- <mat-form-field class="custom-textbox">
                    <input class="align-right" matInput type="text" [value] ="getFormattedNumber(item.mid.stake)" />
                </mat-form-field> -->
                <ejs-numerictextbox  class="input-field yellow-input" strictMode="true" #midStake="ngModel"
                  format="#,###.0 '%'" [ngModel]="item.mid.stake" [showSpinButton]="false" min="0" max="100" 
                  (ngModelChange) = "dirtyTrigger($event,midStake);item.mid.stake = $event;item.low.stake = $event;item.high.stake = $event;updateConcludedNav(item)"
                  >
                </ejs-numerictextbox>
                </td>
                <td class="align-right">{{ item.high.stake | dynamicDecimalPipe }}%</td>
              </tr>
              <tr class="table-custom-body">
                <td>{{translateService.getLabel("concluded_NAV")}}</td>
                <td class="align-right normal-cell">
                  <!-- {{ item.low.fairValue  * (item.low.stake / 100) | number: '1.1-1' }} -->
                  {{ item.low.concluded | dynamicDecimalPipe }}

                </td>
                <td class="align-right">  {{ item.mid.concluded | dynamicDecimalPipe }} </td>
                <td class="align-right">  {{ item.high.concluded | dynamicDecimalPipe }} </td>
              </tr>
              <tr class="table-custom-body" *ngIf="item?.waterFallAnalysisSecurityType === 'Debt'">
                <td>{{translateService.getLabel("restructured_principal_outstanding")}}</td>
                <td class="align-right normal-cell">{{item.low.notionalOutstanding | dynamicDecimalPipe}}</td>
                <td class="align-right">
                  <!-- <mat-form-field class="custom-textbox">
                    <input class="align-right" matInput type="text" [value] ="getFormattedNumber(item.mid.notionalOutstanding)" />
                  </mat-form-field> -->
                  <!-- {{getFormattedNumber(item.mid.notionalOutstanding)}} -->
                  <ejs-numerictextbox  class="input-field yellow-input"  #midPrincipalOutstanding = "ngModel"
                  format="#,###.0" [ngModel]="item.mid.notionalOutstanding" [showSpinButton]="false"
                  (ngModelChange) = "dirtyTrigger($event,midPrincipalOutstanding);item.mid.notionalOutstanding = $event"
                  >
                </ejs-numerictextbox>
                </td>
                <td class="align-right">{{item.high.notionalOutstanding | dynamicDecimalPipe}}</td>
              </tr>
              <!-- <tr class="table-custom-body">
                <td>Notional Outstanding</td>
                <td class="align-right normal-cell">{{getFormattedNumber(item.low.notionalOutstanding)}}</td>
                <td class="align-right">{{getFormattedNumber(item.mid.notionalOutstanding)}}</td>
                <td class="align-right">{{getFormattedNumber(item.high.notionalOutstanding)}}</td>
              </tr> -->
              <tr class="table-custom-body" *ngIf="item?.waterFallAnalysisSecurityType === 'Debt'">
                <td>{{translateService.getLabel("of_par")}}</td>
                <td class="align-right normal-cell">{{ item.low.percentageOfPar | dynamicDecimalPipe }}%</td>
                <td class="align-right">{{ item.mid.percentageOfPar | dynamicDecimalPipe }}%</td>
                <td class="align-right">{{ item.high.percentageOfPar | dynamicDecimalPipe }}%</td>
              </tr>
            </ng-container>
            <tr class="table-last-total-row">
              <td class="last-row-cell">{{translateService.getLabel("total_value_of_fund_holdings")}}</td>
              <td class="align-right last-row-cell">{{ presentValue.totalFairValue.low | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">
                {{  presentValue.totalFairValue.mid  | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">{{ presentValue.totalFairValue.High | dynamicDecimalPipe}}
              </td>
              <!-- <td></td> -->
            </tr>
            <tr class="table-last-total-row">
              <td class="last-row-cell">{{translateService.getLabel("total_restructured_principal_outstanding")}}</td>
              <td class="align-right last-row-cell">{{presentValue.totalPrincipalOutstanding.low | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">
                {{presentValue.totalPrincipalOutstanding.mid | dynamicDecimalPipe}}
              </td>
              <td class="align-right last-row-cell">{{presentValue.totalPrincipalOutstanding.High | dynamicDecimalPipe}}
              </td>
              <!-- <td></td> -->
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="presentValue?.presentValue">
      <div class="col-12 row align-items-center conclude-waterfall">
        <div class="conclude-waterfall-desc input-label">{{translateService.getLabel("info_conclude_waterfall_analysis")}}?</div> <div class="col-2"> 
          <mat-form-field class="custom-input-select">
            <mat-label>{{translateService.getLabel("select")}}</mat-label>
            <mat-select name="type" [(ngModel)]="concludeOnWaterfall" (selectionChange)="onWaterfallConclude()">
                <mat-option [value]="true"> {{translateService.getLabel("yes")}} </mat-option>
                <mat-option [value]="false"> {{translateService.getLabel("no")}} </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      </div>
    </div>
  </div>
</ng-container>
</div>
<ng-template #applicationContent let-applicationModal>
  <form #adjustmentForm="ngForm" (ngSubmit)="addUpdateAdjustments(buttonLabel)">

  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">{{popupHeader}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content">
      <div  class="row">
        <div class="col-2">
          <mat-form-field class="custom-textbox">
            <mat-label>{{translateService.getLabel("name")}}</mat-label>
            <input matInput name="adjustmentName" type="text" [(ngModel)]="adjustment.name" required>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="custom-input-select">
            <mat-label>{{translateService.getLabel("select_type")}}</mat-label>
            <mat-select name="adjustmentType" [(ngModel)]="adjustment.operator" aria-required="true">
              <mat-option value="positive"> {{translateService.getLabel("add")}} </mat-option>
              <mat-option value="negative"> {{translateService.getLabel("less")}} </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4">
          <mat-form-field class="custom-textarea">
            <textarea matInput rows="1" name="adjustmentComments" [placeholder]="translateService.getLabel('add_comment')" name="comment"
              [(ngModel)]="adjustment.comment"></textarea>
          </mat-form-field>
        </div>
        <div class="col-2">
          <mat-form-field class="number-textbox custom-textbox align-right">
            <input matInput type="number" name="adjustmentAmount" [(ngModel)]="adjustment.amount" required>
          </mat-form-field>
        </div>
      </div>
     
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="submit" class="btn-custom-primary" [disabled]="!adjustmentForm.form.valid" >
      {{buttonLabel}}
    </button>
  </div>
</form>

</ng-template>


<ng-template #addClaim let-addClaimModal>
  <form #addClaimForm="ngForm" (ngSubmit)="addUpdateClaims(buttonLabel)">
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">{{popupHeader}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="addClaimModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body claims-modal-body">
    <div class="model-content">
      <!-- <div class="row edit-modal-label">
        
        
       
      </div> -->
      <div class="row claims-container">
        <div>
          <mat-label >{{translateService.getLabel("security")}}</mat-label>
          <mat-form-field class="security-field">
            <input matInput class="label-padding" name="securityName" [(ngModel)]="claim.securityName" [matAutocomplete]="auto" required>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <ng-container *ngFor="let option of securityNames ;let index = index">
                <mat-option [value]="option.securityName" style="line-height: unset;"
                  (click)="selectedSecurity(option)">
                  {{option.securityName}} <br>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="security-type">
          <mat-label>{{translateService.getLabel("security_type")}}</mat-label>
          <mat-form-field class="custom-input-select">
            <mat-select name="securityName"  [(ngModel)]="claim.securityType" required>
              <mat-option value="Debt">
                {{translateService.getLabel("Credit")}}
              </mat-option>
              <mat-option value="Equity">
                {{translateService.getLabel("equity")}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div >
          <!-- <div >Ranking</div> -->
          <mat-label>{{translateService.getLabel("ranking")}}</mat-label>
          <mat-form-field class="number-textbox custom-textbox align-right">
            <input matInput name="claimRanking" type="number" min="0" oninput="validity.valid||(value='');" [(ngModel)]="claim.ranking" required>
          </mat-form-field>
        </div>
        <div >
          <!-- <div >Amount</div> -->
          <mat-label>{{translateService.getLabel("amount")}}</mat-label>
          <mat-form-field class="number-textbox custom-textbox align-right">
            <input matInput name="claimAmount" type="number" [(ngModel)]="claim.amount" required>
          </mat-form-field>
        </div>
        <div  class="fund-exposure">
          <mat-checkbox [checked]="false" name="fundExposure" [(ngModel)]="claim.fundExposure">{{translateService.getLabel("fund_exposure")}}</mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn-custom-secondry" (click)="addClaimModal.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="submit" class="btn-custom-primary" [disabled]="!addClaimForm.form.valid" >
      {{buttonLabel}}
    </button>
  </div>
</form>
</ng-template>
