<!-- <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
   [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div> -->

<!-- <mat-menu #contextMenu="matMenu" class="comment-menu">
  <ng-template matMenuContent let-item="item"> -->
    <!-- <button mat-menu-item (click)="file.click()">
      Upload Logo
    </button> -->
      
      <!-- <label style="cursor: pointer;">
        <input (change)="getImageURL($event)" style="display:none" accept="image/*" #file type="file">
        Upload Logo
    </label>
  </ng-template>
</mat-menu> -->


<div class="row">
  <div class="col-6">
    <nav-menu-header headerLabel="Funds" headerToBeAppended="Monitoring"></nav-menu-header>
  </div>
  <div class="col-6">
    <div style="float: right;padding:10px 20px">
      <a (click)="navigateToPortfolio()" class="redirecting-Link">
        Go Back To Portfolio Summary
      </a>
    </div>
  </div>
</div>

<div class="top-header">
  <span style="font-size: 1.34vw; line-height: 1.61vw;">
    <ng-template [ngIf]="portfolioService.selectedFundId">
      {{fundService.getFundName(portfolioService.selectedFundId)}}
    </ng-template>
  </span>
</div>

<div class="page-styling" *ngIf="companies && companies != 'ERROR'">
  <div class="alignBox">
    <div class="row" style="padding-left: 2%;cursor: pointer;justify-content:start">
      <div class="col-3 company-container card-size" style="padding: 0vh;float:center;"
        *ngFor="let item of companies">
        <!-- (contextmenu)="onContextMenu($event,item)" -->     
        <i class="fas fa-upload align-right marginLeft" (click)="file.click()"></i>   
        <input style="display:none" accept="image/*" #file type="file" id="file" (change)="getImageURL($event, item)">
        <div style="height: 75px;">
          <ng-template [ngIf]="item.logo != null" [ngIfElse]="defaultLogo">
            <div class="col" style="text-align:center;">
              <img class="companyLogo" [src]="item.logo" (click)="monitoringNav(item);" (error)="item.logo = null">
            </div>
          </ng-template>

          <ng-template #defaultLogo>
            <div class="col" style="text-align:center;">
              <i class=" far fa-building icon-size" (click)="monitoringNav(item);" aria-hidden="true"></i>
            </div>
          </ng-template>
        </div>
        <div class="companyName" (click)="monitoringNav(item);">{{item.companyName}}</div>

      </div>
    </div>
  </div>
</div>

<div class="loading-container" *ngIf="companies.length == 0 && companies != 'ERROR'">
  <span class="loading-text">
      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
  </span>
</div>

<div class="loading-container" *ngIf="companies == 'ERROR'">
  <span class="loading-text">
      No companies are found in {{fundService.getFundName(portfolioService.selectedFundId)}}
  </span>
</div>