export const interestConstants = {
    interestType: ["Fixed", "Floating"],
    floatingBaseRateCurveOptions: ["AUD-LIBOR-1M", "AUD-LIBOR-3M", "AUD-LIBOR-6M", "AUD-LIBOR-1Y",
    "CAD-LIBOR-1M", "CAD-LIBOR-3M", "CAD-LIBOR-6M", "CAD-LIBOR-1Y",
    "CHF-LIBOR-1M", "CHF-LIBOR-3M", "CHF-LIBOR-6M", "CHF-LIBOR-1Y",
    "DKK-LIBOR-1M", "DKK-LIBOR-3M", "DKK-LIBOR-6M", "DKK-LIBOR-1Y",
    "EUR-EURIBOR-1M", "EUR-EURIBOR-3M", "EUR-EURIBOR-6M", "EUR-EURIBOR-1Y",
    "GBP-LIBOR-1M", "GBP-LIBOR-3M", "GBP-LIBOR-6M", "GBP-LIBOR-1Y",
    "HKD-HIBOR-1M", "HKD-HIBOR-3M", "HKD-HIBOR-6M", "HKD-HIBOR-1Y",
    "NOK-LIBOR-1M", "NOK-LIBOR-3M", "NOK-LIBOR-6M", "NOK-LIBOR-1Y",
    "NZD-LIBOR-1M", "NZD-LIBOR-3M", "NZD-LIBOR-6M", "NZD-LIBOR-1Y",
    "SEK-LIBOR-1M", "SEK-LIBOR-3M", "SEK-LIBOR-6M", "SEK-LIBOR-1Y",
    "USD-LIBOR-1M", "USD-LIBOR-3M", "USD-LIBOR-6M", "USD-LIBOR-1Y",
    "USD-SOFR-1M", "USD-SOFR-3M", "USD-SOFR-6M", "USD-SOFR-1Y",
    "GBP-Term SONIA-1M", "GBP-Term SONIA-3M", "GBP-Term SONIA-6M", "GBP-Term SONIA-1Y",],
    interestPaidOrAccrued: ["Paid", "Accrued"],
    spreadType: ["Fixed Spread", "Pricing Grid"],
    label: {
        interestPaidLabel: "Interest Paid or Accrued",
        interestRateTypeLabel: "Interest Type",
        floatingBaseRateCurveLabel: "Floating Base Rate Curve",
        fixedBaseRateLabel: "Fixed Interest Rate",
        spreadType:"Spread Type",
        spreadRate: "Spread Rate",
        selectOptionText: "selectOptionText"
    }
}