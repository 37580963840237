import { CCAService } from "./../cca.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import {
  GridComponent,
  QueryCellInfoEventArgs,
} from "@syncfusion/ej2-angular-grids";
import { Tooltip } from "@syncfusion/ej2-popups";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-volitility",
  templateUrl: "./volitility.component.html",
  styleUrls: ["./volitility.component.scss"],
})
export class VolitilityComponent implements OnInit {
  @ViewChild("grid") grid: GridComponent;
  annualizedVolatility: any = {
    annualizedVolatilityComparableCompanies:[]
  };
  loadingMessages= []
  loading = 'INIT';
  showWarningMsg: boolean = false;
  showAddCompanyInputs: boolean = false;
  fieldMapVolitility: Object = { text: "label", value: "value" };
  lookBackPeriod = [];
  frequency = [];
  companyNames = [];
  newCompanyName;
  valuationDate;
  selectedCompany;
  constructor(
    public dialogRef: MatDialogRef<VolitilityComponent>,
    private ccaService: CCAService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.getVolitilityOptions();
  }

  getVolitilityOptions() {
    this.ccaService
      .getVolitilityOptions(this.ccaService.latestCompanyValuationDate.id)
      .subscribe((response: any) => {
        const options = response.response;
        this.lookBackPeriod = options.lookbackOptions;
        this.frequency = options.frequencyOptions;
        this.valuationDate = options.valuationDate;
        this.getVolitility();
      });
  }

  saveAnnualizedVolitility() {
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ccaService
          .saveAnnualizedVolitility(
            this.ccaService.latestCompanyValuationDate.id,
            this.annualizedVolatility
          )
          .subscribe((response: any) => {
            this.annualizedVolatility = response.response;
            this.getAnnualisedVolitility();
          });
      }
    });
  }

  getAnnualisedVolitility() {
    const annualizedVolatility = this.annualizedVolatility.manualOverride
      ? this.annualizedVolatility.manualVolatility
      : this.annualizedVolatility.averageVolatility;

    this.ccaService.setVolitilityInput(annualizedVolatility);
  }

  toggleManualOverrideVolatility(data) {
    this.annualizedVolatility.manualOverride = data;
  }

  onChangeLookbackPeriod() {
    this.calculateDataPoints();
  }
  onChangeFrequency() {
    this.calculateDataPoints();
  }

  calculateDataPoints() {
    let dataPoints = 0;
    if (
      this.annualizedVolatility.frequency &&
      this.annualizedVolatility.lookbackPeriod
    ) {
      if (this.annualizedVolatility.frequency === "WEEKLY") {
        dataPoints = this.annualizedVolatility.lookbackPeriod * 7;
      } else if (this.annualizedVolatility.frequency === "MONTHLY") {
        dataPoints = this.annualizedVolatility.lookbackPeriod * 12;
      } else {
        dataPoints = this.annualizedVolatility.lookbackPeriod * 365;
      }
      this.showWarning(dataPoints);
    }
  }

  showWarning(dataPoints) {
    this.showWarningMsg = dataPoints < 60 ? true : false;
  }

  openAddCompanyPopUp() {
    this.showAddCompanyInputs = true;
  }

  dismiss() {
    this.dialogRef.close();
  }

  deleteCompany(index) {
    this.annualizedVolatility.annualizedVolatilityComparableCompanies.splice(
      index,
      1
    );
    this.refreshIndexOnDelete();
    this.annualizedVolatility.annualizedVolatilityComparableCompanies.forEach(
      (element) => {
        delete element.index;
      }
    );
    this.ccaService
      .recalculateVolitility(
        this.ccaService.latestCompanyValuationDate.id,
        this.annualizedVolatility
      )
      .subscribe((response: any) => {
        if(response?.response){
          this.annualizedVolatility = response.response;
        }
      });
  }

  refreshIndexOnDelete() {
    let index = 0;
    this.annualizedVolatility.annualizedVolatilityComparableCompanies.forEach(
      (entry) => {
        entry["index"] = index;
        index++;
      }
    );
    if (this.grid) {
      setTimeout(() => {
        this.grid.refresh();
      });
    }
  }

  companyNameChange() {
    if (this.newCompanyName && this.newCompanyName.length >= 3) {
      this.ccaService
        .getSimilarCompanies(this.newCompanyName, this.valuationDate)
        .subscribe(
          (response: any) => {
            if (response.response) {
              this.companyNames = response.response;
            } else {
              this.companyNames = [];
            }
          },
          (error) => {
            this.companyNames = [];
          }
        );
    } else {
      this.companyNames = [];
    }
  }

  selectedCompanyName(option) {
    let selectedCompany: any = {};
    (selectedCompany.ciqCompanyId = option.company_id),
      (selectedCompany.companyName = option.company_name),
      (selectedCompany.description = option.description),
      (selectedCompany.volatility = null);
    this.selectedCompany = selectedCompany;
  }

  addCompany() {
    this.annualizedVolatility.annualizedVolatilityComparableCompanies.push(
      this.selectedCompany
    );
    this.ccaService
      .recalculateVolitility(
        this.ccaService.latestCompanyValuationDate.id,
        this.annualizedVolatility
      )
      .subscribe((response: any) => {
        if(response?.response){
          this.annualizedVolatility = response.response;
          this.showAddCompanyInputs = false;
          this.companyNames = [];
        }
      });
  }

  getVolitility() {
    this.loading = "LOADING";
    this.ccaService
      .getVolitility(this.ccaService.latestCompanyValuationDate.id)
      .subscribe((response: any) => {
        this.loading = "DONE";
        if (response.response) {
          this.annualizedVolatility = response.response;
          this.getAnnualisedVolitility();
          this.calculateDataPoints();
        }
      },
      (error) => {
        this.loading = "DONE";
      });
  }

  tooltip(args: QueryCellInfoEventArgs) {
    if(args.data[args.column.field]){
    const tooltip: Tooltip = new Tooltip(
      {
        content: args.data[args.column.field]?.toString(),
      },
      args.cell as HTMLTableCellElement
    );
    }
  }
}
