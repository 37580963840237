<div style = "padding-left: 20px;" *ngFor= "let filter of filters">

    <ng-template [ngIf]="filter.children && filter.children.length > 0">
        <i class="fa fa-minus-square" style="font-size: 1em; margin-left: -22px; padding-right: 8px;" *ngIf="filter.expanded" (click)="filter.expanded = !filter.expanded"></i>
        <i class="fa fa-plus-square" style="font-size: 1em; margin-left: -22px; padding-right: 8px;" *ngIf="!filter.expanded" (click)="filter.expanded = !filter.expanded"></i>
    </ng-template>

    <mat-checkbox [(ngModel)] = "filter.selected" 
        (ngModelChange)= "selectionChange();" 
        [indeterminate]="isIndeterminate(filter)"
        (click)="onChangeParentOnClick(filter)">
        
        {{filter.label}}
    </mat-checkbox>

    <ng-template [ngIf]="filter.children && filter.children.length > 0">

        <ng-template [ngIf]="filter.expanded">

            <app-comparison-filters [filters]="filter.children" 
                (change)="childrenSelectionChange(filter,$event);" 
                *ngIf="filter.children && filter.children.length > 0">
            
            </app-comparison-filters>
        </ng-template>
    </ng-template>
</div>