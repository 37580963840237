import { DatePipe } from "@angular/common";
import { CCAService } from "./../cca.service";
import { DataService } from "src/app/services/data.service";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-risk-free-rate",
  templateUrl: "./risk-free-rate.component.html",
  styleUrls: ["./risk-free-rate.component.scss"],
})
export class RiskFreeRateComponent implements OnInit {
  annualizedRiskFreeRate: any = {};
  countries = [];
  series = [];
  fieldMapRiskFreeRate: Object = { text: "text", value: "value" };
  showSeriesDropdown: boolean = false;
  trackerOptions;
  loading = "INIT";
  loadingMessages = [];

  constructor(
    public dialogRef: MatDialogRef<RiskFreeRateComponent>,
    private dataService: DataService,
    private ccaService: CCAService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getRiskFreeRate();
  }

  toggleManualOverrideRiskFreeRate(data) {
    this.annualizedRiskFreeRate.manualOverride = data;
  }

  dismiss() {
    this.dialogRef.close();
  }

  getRiskFreeRate() {
    this.loading = "LOADING";
    this.ccaService
      .getRiskFreeRate(this.ccaService.latestCompanyValuationDate.id)
      .subscribe((response: any) => {
        if (response.response) {
          this.loading = "DONE";
          const countryOptions = response.response.countryOptions;
          const trackerOptions = response.response.trackerOptions;
          this.trackerOptions = response.response.trackerOptions;
          this.prepareDropdowns(countryOptions, trackerOptions);
          this.annualizedRiskFreeRate = response.response;
          this.annualizedRiskFreeRate.selectedDate =
            response.response.selectedDate;
          this.annualizedRiskFreeRate.selectedCountry =
            response.response.selectedCountry;
          this.annualizedRiskFreeRate.selectedSeries =
            response.response.selectedSeries;
          this.getAnnualisedRiskFreeRate();
        }
      },
      (error) => {
        this.loading = "DONE";
      }
      );
  }

  prepareDropdowns(countryOptions, trackerOptions) {
    if (countryOptions?.length > 0) {
      countryOptions.forEach((element) =>
        this.countries.push({ value: element, text: element })
      );
    }
    if (trackerOptions && Object.keys(trackerOptions)?.length > 0) {
      Object.keys(trackerOptions).forEach((element) =>
        this.series.push({ text: element, value: element })
      );
      this.showSeriesDropdown = true;
    } else {
      this.showSeriesDropdown = false;
    }
  }

  calculateRiskFreeRate() {
    const payload = {
      country: this.annualizedRiskFreeRate.selectedCountry,
      startDate: this.dateTransform(this.annualizedRiskFreeRate.selectedDate),
      series: this.annualizedRiskFreeRate.selectedSeries,
      formId: this.ccaService.latestCompanyValuationDate.id,
    };
    this.ccaService
      .calculateRiskFreeRate(this.ccaService.latestCompanyValuationDate.id, payload)
      .subscribe((response: any) => {
        if (response.response) {
          this.annualizedRiskFreeRate = response.response;
          const countryOptions = response.response.countryOptions;
          const trackerOptions = response.response.trackerOptions;
          this.trackerOptions = response.response.trackerOptions;
          this.annualizedRiskFreeRate.selectedDate =
            response.response.selectedDate;
          this.prepareDropdowns(countryOptions, trackerOptions);
        }
      });
  }

  dateTransform(selectedDate) {
    const date = new Date(selectedDate);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  saveRiskFreeRate() {
    const payload = {
      country: this.annualizedRiskFreeRate.selectedCountry,
      startDate: this.dateTransform(this.annualizedRiskFreeRate.selectedDate),
      series: this.annualizedRiskFreeRate.selectedSeries,
      formId: this.ccaService.latestCompanyValuationDate.id,
      riskFreeRate: this.annualizedRiskFreeRate.riskFreeRate,
      manualOverride: this.annualizedRiskFreeRate.manualOverride,
      comment: this.annualizedRiskFreeRate.comment,
      manualRiskFreeRate: this.annualizedRiskFreeRate.manualRiskFreeRate,
    };
    this.ccaService
      .saveRiskFreeRate(this.ccaService.latestCompanyValuationDate.id, payload)
      .subscribe((response: any) => {
        if (response.response) {
          this.annualizedRiskFreeRate = response.response;
          this.annualizedRiskFreeRate.selectedDate =
            response.response.startDate;
          this.getAnnualisedRiskFreeRate();
        }
      });
  }

  getAnnualisedRiskFreeRate() {
    const riskFreeRate = this.annualizedRiskFreeRate.manualOverride
      ? this.annualizedRiskFreeRate.manualRiskFreeRate
      : this.annualizedRiskFreeRate.riskFreeRate;

    this.ccaService.setRiskFreeRateInput(riskFreeRate);
  }

  getRiskFreeRateFromSeries() {
    this.annualizedRiskFreeRate.riskFreeRate = this.trackerOptions[
      this.annualizedRiskFreeRate.selectedSeries
    ].zeroYield;
  }
}
