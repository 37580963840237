import { Component, Output, EventEmitter, OnInit } from "@angular/core";

import * as _ from "lodash";
import { DataService } from "src/app/services/data.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MessagePopupComponent } from "src/app/utils/popup/message-popup.component";
import { UserManagementService } from "src/app/services/user-management.service";
import { PortFolioService } from 'src/app/qubit-x/portfolio/portfolio.service';
import { TranslateService } from "src/app/services/translation.service";

@Component({
  selector: "app-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"]
})
export class FilesUploadComponent implements OnInit {
  @Output() fileUploaded = new EventEmitter(true);

  fileTobeUploaded;
  fileDescriptionElements = [];
  selectedFiles = 0;
  description = '';
  // type = '';

  fileHeaders = [
    {
      fileName: "File Name",
      uploadedBy: "Uploaded By",
      tag: "Tag",
      uploadedDate: "Uploaded Date",
      delete: "Delete"
    }
  ];

  constructor(
    private dataService: DataService,
    private ums: UserManagementService,
    private dialog: MatDialog,
    private portfolioService: PortFolioService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
  }

  pickFile(event) {

    var list: FileList = event.target.files;

    if (!list || list.length === 0) return;

    this.fileTobeUploaded = list[0];
    // console.log(this.fileTobeUploaded);
  }

  upload() {
    if(!this.fileTobeUploaded || !this.description) return;

    const file = this.fileTobeUploaded;
    const userName = this.ums.getSelectedUserDetails().userName;

    const requestBody = {
      description: this.description,
      formId: this.portfolioService.companyId,
      user: userName
    }
    
    this.dataService.uploadDocument(file, JSON.stringify(requestBody)).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          // console.log("progres", Math.round((100 * event.loaded) / event.total));

        } else if (event instanceof HttpResponse) {
          this.selectedFiles--;

          // console.log("File is completely uploaded!", this.selectedFiles, event.body);

          const body = event["body"];
          const bodyJson = JSON.parse(body.toString());

          const fileUploaded = {
            id: bodyJson["response"],
            createdBy: userName,
            createdDate: new Date(),
            name: file.name,
            // type: this.type,
            description: this.description + ""
          }

          this.fileUploaded.emit(fileUploaded);
          this.description = ""
        }
        this.fileTobeUploaded = null;
      },
      error => {
        console.log("error in Uploading document", error);

        this.description = "";
        this.fileTobeUploaded = null;
        
        this.selectedFiles--;

        const dialogRef = this.dialog.open(MessagePopupComponent, {
          data: {
            okButtonMsg: "Ok",
            dialogMsg: "File " + file.name + " upload failed."
          }
        });
      }
    );
  }

  clickOnFileInput(fileInput) {
    fileInput.click();
  }
}
