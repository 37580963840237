<!-- <nav-menu-header [headerLabel]="newCompany.name"></nav-menu-header> -->
<nav-menu-header headerLabel="Funds" headerToBeAppended="InvestmentSummary"></nav-menu-header>
<div class="card">
  <div class="top-header">
    <span style="font-size: 20px">{{newCompany.name}}</span>

    <span ngbDropdown class="d-inline-block" placement="bottom-right" style="float: right;">
      <span id="toggleMenu" ngbDropdownToggle>
        <i class="fas fa-ellipsis-v ellipsis-icon"></i>
      </span>
      <div ngbDropdownMenu aria-labelledby="toggleMenu">
        <button ngbDropdownItem class="btn" (click)="showByTopMenu('vd');">
          Show valuation dates
        </button>

        <button ngbDropdownItem class="btn" (click)="showByTopMenu('userVD');">
          Add valuation dates (Custom)
        </button>

        <button ngbDropdownItem class="btn" (click)="showByTopMenu('consol', selectedCompanyDates[0]);" *ngIf="selectedCompanyDates && selectedCompanyDates[0] && selectedCompanyDates[0].businessUnits">
          Consol Summary
        </button>

        <button ngbDropdownItem class="btn" (click)="showBuildUps()">
          Add Build-Ups
        </button>

        <!-- <button ngbDropdownItem class="btn" (click)="openCurrencyDashboardPopup(currencyDashboard)">
          Currency Dashboard
        </button >
        <button ngbDropdownItem class="btn" (click)="showByTopMenu('INVESTMENT');">
          Investments
        </button> -->
      </div>
    </span>
  </div>

  <div *ngIf="showInvestment">
    <app-investment (action)="showInvestment = false"> </app-investment>
  </div>

  <ng-template [ngIf]="showValDates">
    <!-- <div [chart]="waterFallChart"></div> -->

    <div style="margin: 0px">
      <table class="table table-condensed" id="container">
        <tr class="bg primary-bg">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="width: 50px">
              <button mat-raised-button class="primary-light-bg p-button" (click)="showValDates = false">Close</button>
            </th>
        </tr>

        <!-- <ng-template ngFor [ngForOf]="selectedCompanyDates" let-comp  let-index="index">
          <tr [class.highlight-row]="comp.id === primaryCompanyId" [class.userAddedForm]="comp.userEntered" (click)="comp.open = !comp.open">
            <td>
              <div>{{comp.valuationDate | date: "mediumDate"}}</div>
              <div *ngIf="comp.versionName">{{comp.versionName}}</div>
            </td>
            <td>
              <ng-template [ngIf]="comp.investment">
                <ng-template [ngIf]="comp.investment.currency">
                  {{comp.investment.currency}}
                </ng-template>
                <ng-template [ngIf]="!comp.investment.currency">
                  {{investmentCurrency}}
                </ng-template>

                <ng-template [ngIf]="comp.investment.equityValue.finalStakeValue">
                  {{comp.investment.equityValue.finalStakeValue | number: "1.1-1"}} Mn
                </ng-template>
                <ng-template [ngIf]="!comp.investment.equityValue.finalStakeValue">
                  {{comp.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
                </ng-template>
              </ng-template>

              <div>
                <ng-template [ngIf]="!comp.approvalStatus">
                  Approval - Not Started
                </ng-template>
                
                <ng-template [ngIf]="comp.approvalStatus == 1">
                  Approval - In Progress
                </ng-template>
                
                <ng-template [ngIf]="comp.approvalStatus == 2">
                  Approval - Completed
                </ng-template>
              </div>

            </td>
            <td>{{comp.security}}</td>

            <td>
              <ng-template [ngIf]="!comp.userEntered">
                <i class="far fa-edit primary-color-medium action-icon" (click)="openSAF(comp, businessUnitPopup)" *ngIf="comp.editable"></i>
                <i class="fas fa-code-branch primary-color-light action-icon" (click)="openVersions(comp, formVersionPopup)" *ngIf="comp.versionName && comp.id !== primaryCompanyId && comp.status !== 'Initiated'"></i>
                <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(comp, businessUnitPopup)" *ngIf="comp.status !== 'Initiated'"></i>
              </ng-template>
            </td>

            <td style="width: 50px;">
              <i class="fa fa-trash action-icon primary-color-medium" *ngIf="comp.id !== primaryCompanyId" aria-hidden="true" (click)="deleteCompany(comp)"></i>
            </td>
            
            <td style="width: 20px;">
              <i class="fas fa-share-alt action-icon primary-color-medium" *ngIf="comp.status !== 'Initiated'" aria-hidden="true" (click)="openSharePopUp(comp, businessUnitPopup, valuationSharePopUp)"></i>
            </td>
          </tr>
        </ng-template>         -->
      </table>
    </div>
    
  </ng-template>

  <ng-template [ngIf]="showValDates">
    <div class="heading">

    </div>
    <div class="row">
      <div class="sf-table">
          <ejs-grid #valuationDatesGrid [dataSource]='valuationDateGridDataSource' height=335 width='auto'>
              <e-columns>
                  <e-column headerText='Valuation Date' width='150' textAlign='Left'></e-column>
                  <e-column headerText='Valuation Amount' width='150' textAlign='Left'></e-column>
                  <e-column headerText='Security' width='100' textAlign='Left'></e-column>
                  <e-column headerText='' width='25' textAlign='Left'></e-column>
                  <e-column headerText='' width='25' textAlign='Left'></e-column>
                  <e-column headerText='' width='25' textAlign='Left'></e-column>
                  <e-column headerText='' width='25' textAlign='Left'></e-column>
                  <e-column headerText='' width='150' textAlign='Left'></e-column>
                  <e-column headerText='Approval Status' width='150' textAlign='Left'></e-column>
                  <e-column headerText='Approval Requests' width='150' textAlign='Left'></e-column>
              </e-columns>
              <ng-template #rowTemplate let-data>

                <ng-template [ngIf]="!data.loading" [ngIfElse]="loadingMessage">  
                  <tr>
                      <td class="td-parent-cell pl-5" colspan="10">
                          {{data.name  | date: "mediumDate" }}
                      </td>
                  </tr>
                  <tr *ngFor="let item of data.value;let i = index;">
                      <td class="td-cell">
                          <div class="row m-0">
                              <div class="col-2">
                                <ejs-radiobutton label="" name="chkbox{{data.index}}" (change)="selectVersion(item)" cssClass="e-success" [checked]="item.frozen"></ejs-radiobutton>
                                <!-- <i class="fas fa-check-circle icon-active" *ngIf="item.frozen" style="margin-top: 3px;"></i> -->
                              </div>
                              <div class="col pl-0 versionName">
                                <span (click)="updateVersion(item)"><label class="row m-0" style="margin-left: 10px !important; cursor: pointer">{{item.versionName | titlecase}}</label></span>
                              </div>
                          </div>
                      </td>
                      <td class="td-cell">
                        <ng-template [ngIf]="item.stake">
                          {{item.currency}} {{item.stake | number : "1.1-1"}}
                        </ng-template>
                      </td>
                      <td class="td-cell">
                          {{item.security | titlecase}}
                      </td>

                      <td class="td-cell">
                        <i class="fa fa-edit action-icon grey-text"
                          (click)="openSAF(item, businessUnitPopup)" 
                          [ngClass]="{'icon-active' : item.approvalStatus == APPROVAL_STATUS.NOT_STARTED}">
                        </i>
                      </td>
                      <td class="td-cell">
                          <i class="fas fa-binoculars action-icon icon-active"
                            (click)="openValuation(item, businessUnitPopup)"
                            *ngIf="item.status == 'Submitted'">
                          </i>
                      </td>
                      <td class="td-cell">
                        <i class="fa fa-trash action-icon grey-text"
                          *ngIf="item.id !== primaryCompanyId"
                          aria-hidden="true"
                          (click)="deleteCompany(item)"
                          [ngClass]="{'icon-active' : item.approvalStatus == APPROVAL_STATUS.NOT_STARTED}">
                        </i>
                      </td>
                      <td class="td-cell">
                        <i class="fas fa-share-alt action-icon icon-active" *ngIf="item.status !== 'Initiated'" aria-hidden="true" (click)="openSharePopUp(item, businessUnitPopup, valuationSharePopUp)"></i>
                      </td>
                      <td class="td-cell">
                        <i class="far fa-copy action-icon icon-active" (click)="copyVersion(item)" *ngIf="item.status !== 'Initiated' && item.frozen && item.id !== primaryCompanyId"></i>
                      </td>

                      <td class="td-cell" style="padding-left: 10px;">
                          <span *ngIf="item.approvalStatus == APPROVAL_STATUS.REJECTED">Rejected</span>
                          <span *ngIf="item.approvalStatus == APPROVAL_STATUS.APPROVED">Approved</span>
                          <span *ngIf="item.approvalStatus ==  APPROVAL_STATUS.NOT_STARTED">Approval - Not Started</span>
                          <span *ngIf="item.approvalStatus ==  APPROVAL_STATUS.PENDING">Approval - In Progress</span>
                         
                      </td>
                      <td class="td-cell">
                        <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.INITIATE_APPROVAL && item.status == 'Submitted'">
                            <button style="border: 1px solid green; color: green; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;" 
                            (click)="initiateApprovalRequest(item); item.actionName = APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = APPROVAL_STATUS.PENDING"
                            class="btn-status">
                              Initiate Approval
                            </button>
                          </ng-template>
          
                          <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.NO_CONDITION">
                            <div matTooltip="Approval condition not available" matTooltipPosition='above'>
                              <button [disabled]="true" style="border: 1px solid grey; color: grey; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;" 
                              (click)="initiateApprovalRequest(item); item.actionName = APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = APPROVAL_STATUS.PENDING"
                              class="btn-status" >
                                Initiate Approval
                              </button>
                            </div>
                          </ng-template>
          
                          <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.RECALL_APPROVAL">
                            <button style="border: 1px solid red; color: red; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                            (click)="recallApprovalRequest(item); item.actionName = APPROVAL_ACTION.INITIATE_APPROVAL; item.approvalStatus = APPROVAL_STATUS.NOT_STARTED"
                            class="btn-status">
                              Recall Approval
                              <i class="fa fa-lock icon-lock"></i> 
                            </button>
                          </ng-template>
                          
                          <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.REJECT_APPROVE">
                            <button style="border: 1px solid #1D1563; color: #1D1563; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                            (click)="enableApproveDialogBox(item)"
                            class="btn-status">
                                Approve / Reject 
                                <i class="fa fa-lock icon-lock"></i> 
                            </button>
                          </ng-template>

                          <ng-template [ngIf]="item.approvalStatus == APPROVAL_STATUS.APPROVED || item.approvalStatus == APPROVAL_STATUS.REJECTED">
                              <div class="locked"><i class="fa fa-lock icon-lock" matTooltip="This version is not editable" matTooltipPosition='above'></i> </div>
                          </ng-template>
                      </td>
                  </tr>
                </ng-template>

                <ng-template #loadingMessage>
                  <div style="margin-left: 20px;">Loading...</div>
                </ng-template>

              </ng-template>
          </ejs-grid>
          <!-- <div class="col-12">
              <div class="row row-footer">
                  <div class="col-6 offset-6">
                      <div class="row">
                          <div class="col-4">
                              <select class="form-control txtSimple">
                                  <option value="">Select Valuation Date</option>
                              </select>
                          </div>
                          <div class="col-4">
                              <input type="text" class="form-control txtYellow" placeholder="Enter version name">
                          </div>
                          <div class="col-4">
                              <button class="btn btn-theme">Create Version</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div> -->
      </div>

      <div class="row" class="new-val-date">
        <table>
          <tr>
            <td style="border-top: 1px solid lightgray;">Valuation Date</td>
            <td style="border-top: 1px solid lightgray;">Business Units</td>

            <td style="border-top: 1px solid lightgray;"></td>

            <td style="border-top: 1px solid lightgray;"></td>
            <td style="border-top: 1px solid lightgray;"></td>
          </tr>
          
          <tr>
            <td style="border-bottom: 1px solid lightgray;">
              <mat-form-field (click)="vDatePicker.open()" style="width: 80%;">
                <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="newCompany.valuationDate">
                <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
              </mat-form-field>
            </td>

            <td style="border-bottom: 1px solid lightgray;">
              <mat-form-field>
                <input type="number" matInput [(ngModel)]="newCompany.businessUnitsNumber">
              </mat-form-field>
            </td>

            <td style="border-bottom: 1px solid lightgray;">
              <button mat-raised-button class="primary-bg p-button" (click)="addValuationDate()">
                Add
              </button>
            </td>
            <td style="border-bottom: 1px solid lightgray;"></td>
            <td style="border-bottom: 1px solid lightgray;"></td>
          </tr>
        </table>
      </div>
  </div>
  </ng-template>

  <app-consol-summary *ngIf="showConsolSummary" 
    [consolSummary]="consolSummary" 
    [latestCompanyValuationDate]="selectedCompanyConsolForm" 
    [primaryCompanyId]="primaryCompanyId"
    [consolSummary]="portfolioService.businessUnitsValSummaryForAllIds.summary[selectedCompanyConsolForm.id]"
    (action)="showConsolSummary = false">
  </app-consol-summary>

  <app-build-up *ngIf="showBuildUpSummary" (closeBuildUp) = "showBuildUpSummary = false" (buildUpSaved) = "initValueBridge()" ></app-build-up>

  <app-old-investments *ngIf="showUserValDates" (action)="showUserValDates = false"></app-old-investments>
  <br>

  <div *ngIf="(investmentDateInfo && investmentDateInfo.formData && primaryCompanyId && !showConsolSummary && !showInvestment)" >
    <app-investment-page-summary [formData]="investmentDateInfo.formData" [buildUpSaved] = "updateValueBridge" (consolFormSelected)="showByTopMenu('consol', $event)"></app-investment-page-summary>
  </div>

</div>

<ng-template #currencyDashboard let-currencyDashboard>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>Currency Dashboard</h6>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="currencyDashboard.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span style="font-weight: bold;">Valuation Date:</span> <span> {{investmentDateInfo.valuationDate | date: "mediumDate"}}</span><br><br>
    <div class="table table-condensed" id="container">
      <table class="table">
        <tr class="grey-bg">
          <th>Data</th>
          <th class="center-align">Input Currency</th>
          <th class="center-align">Valuation Currency</th>
          <th>Conversion</th>
        </tr>
        <tr>
          <td>Historical Financials</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr>
          <td>Projected Financials</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Bid_Details && investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_Bidder_Name.length > 0">
          <td>Bid Details</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForBidDetails'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Secondaries && investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_Buyer_Name.length > 0">
          <td>Secondaries</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForSecondaries'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND && investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_NAME.length > 0">
          <td>PORI</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign"> {{ investmentDateInfo['exchangeRateForPori'] | number: "1.2-2" }} </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #editValuationDateModal let-editValuationDateModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>Currency Dashboard</h6>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="editValuationDateModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class ='row'>
      <div class ='col-4'>
        <b>Valuation Date</b>
      </div>
      <div class ='col-8'>
        <mat-form-field (click)="vDatePicker.open()">
          <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="editedValuationDate">
          <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
      </mat-form-field>
      </div>
    </div>

    <div class ='row'>
      <div class ='col-12' style="text-align:right;margin-top:5px;">
        <button mat-raised-button class="primary-bg p-button" (click)="addNewValuationDate(editValuationDateModal)">
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valuationSharePopUp let-valuationSharePopUpModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h3>Share</h3>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="valuationSharePopUpModal.dismiss('Cross click')">

      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body external-share-popup-body">
    <div class="tabContainer" style="text-align: left">

      <div class="row" style="margin: 0; margin-top: 10px;">
        <div class="col-2">Name:</div>
        <div class="col-10 spacing" style="background-color: #e3e9f1; border-radius: 5px; height: 30px;">         
          <input type="text" style="margin-top: 5px;" matInput [(ngModel)]="shareInputName">
        </div>
      </div>

      <div class="row" style="margin: 0; margin-top: 10px;">
        <div class="col-2">Email:</div>
        <div class="col-10 spacing" style="background-color: #e3e9f1; border-radius: 5px; height: 30px;">        
          <input type="email" style="margin-top: 5px;" matInput [(ngModel)]="shareInputEmail">
        </div>
      </div>
      
      <div class="row" style="margin: 10px 0px;">
        <div class="col"><button mat-raised-button class="primary-bg p-button" style="float: right;" (click)="addShareInputs()">Add</button></div>
      </div>     

      <div>
        <mat-chip *ngFor="let user of shareInput.users; let i=index" [selectable]="selectable" [removable]="removable"
          (removed)="removeShareInputs(i)" style="margin-right: 7px; margin-bottom:2px;" class="chipColor">
          {{user.name }}
          <mat-icon matChipRemove (click)="removeShareInputs(i)">cancel</mat-icon>
        </mat-chip>
      </div>

      <div class="row">
        <div class="col">
          <h6 style="margin: 20px 0px;">Access Privileges</h6>
        </div>
        <div class="col">
          <button mat-raised-button class="primary-bg p-button" style="float: right; margin: 20px 0px;" type="button"
            (click)="checkUncheckAll()">Select All</button>
        </div>
      </div>
      
      <div class="row">
        <ng-template ngFor [ngForOf]="shareInput.sharePermissions" let-tab>
          <div class="col-6" *ngIf="!tab.hide" style="text-align: left">
            <mat-checkbox [checked]="tab.selected" (change)="tab.selected = $event.checked">{{tab.label}}</mat-checkbox>
          </div>
        </ng-template>
      </div>

      <div style="text-align: right;">
        <button mat-raised-button class="secondary-bg mat-raised-button mat-button-base" style="margin: 10px;" type="button" (click)="valuationSharePopUpModal.dismiss('Cross click')">Cancel</button>
        <button mat-raised-button class="primary-bg p-button" style="margin: 10px;" type="submit" (click)="share(valuationSharePopUpModal);">Share</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #formVersionPopup let-formVersionPopupModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>Versions</h6>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="formVersionPopupModal.dismiss('Cross click')">

      <span aria-hidden="true">&times;</span>
    </button>
      
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let fv of selectedFormVersions" style="margin: 10px;">

      <div class="col"> 
        {{fv.versionName}}
        <div>{{fv.valuationDate | date: "mediumDate"}}</div> 
      </div>

      <div class="col-4">
        
        <ng-template [ngIf]="fv.investment">
          {{fv.investment.currency}}
          
          <ng-template [ngIf]="fv.investment.enterpriseValue">
            {{fv.investment.enterpriseValue | number: "1.1-1"}} Mn
          </ng-template>
          <!-- <ng-template [ngIf]="!fv.investment.equityValue.finalStakeValue">
            {{fv.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
          </ng-template> -->
        </ng-template>
      </div>

      <div class="col-3">
        <i class="far fa-edit primary-color-medium action-icon" (click)="openSAF(fv, businessUnitPopup)" *ngIf="fv.editable"></i> &nbsp;&nbsp;
        
        <i class="fas fa-check disabled-color action-icon" [class.frozen-form]="fv.frozen" (click)="freezeThisVersion(fv)" *ngIf="!fv.businessUnits || (fv.investment && fv.investment.equityValue.stakeValue > 0)"></i> &nbsp;&nbsp;

        <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(fv, businessUnitPopup)" *ngIf="fv.status !== 'Initiated' && fv.frozen"></i>
              
      </div>
    </div>

    <div class="row" style="margin: 10px;">
      <div class="col">
        <mat-form-field style="width: 80%;">
          <input matInput [(ngModel)]="newFormVersion" required>
        </mat-form-field>
      </div>

      <div class="col-3">
        <button mat-raised-button class="primary-bg p-button" (click)="addValuationDateVersion()">
          Add
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #businessUnitPopup let-businessUnitModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>Business Units (Enterprise Value) </h6>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="businessUnitModal.dismiss('Cross click'); buShareMode = false">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngFor="let bu of selectedBusinessUnits" style="margin: 10px;" [class.consol-form]="bu.businessUnits">
      <!-- Not Showing parent consol form for Share PopUp -->
      <ng-template [ngIf] = "!bu.consolForm && !buShareMode">
        <div class="col"> 
          {{bu.companyNameInForm || bu.businessUnitName || bu.companyName}}
          <div style="font-size: 12px;">{{bu.versionName}} | {{bu.valuationDate | date: "mediumDate"}}</div> 
        </div>
  
  
        <div class="col-4">
          
          <ng-template [ngIf]="bu.investment">
            {{bu.investment.currency}}
  
            <ng-template [ngIf]="bu.investment.enterpriseValue">
              {{bu.investment.enterpriseValue | number: "1.1-1"}} Mn
            </ng-template>
            <!-- <ng-template [ngIf]="!bu.investment.equityValue.finalStakeValue">
              {{bu.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
            </ng-template> -->
          </ng-template>
        </div>
        
        <div class="col-3">
          <i class="far fa-edit primary-color-medium action-icon" (click)="openSAF(bu, null, !!bu.businessUnits)" *ngIf="bu.editable && buEditMode && !buShareMode"></i> &nbsp;&nbsp; <!-- && isCurrencyAvailable(bu) !== 'NotAvailable' -->
          <!-- <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "10" *ngIf="buEditMode && !isCurrencyAvailable(bu)"></mat-spinner> &nbsp;&nbsp; -->
  
          <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(bu, businessUnitPopup, !!bu.businessUnits)" *ngIf="!buEditMode && !buShareMode && bu.status !== 'Initiated'"></i>
          <i class="fas fa-share-alt action-icon primary-color-medium" *ngIf="buShareMode" aria-hidden="true" (click)="openValuationSharePopUp(bu, valuationSharePopUp); buShareMode = false; businessUnitModal.dismiss('Cross click')"></i>
        </div>
      </ng-template>

      <ng-template [ngIf] = "bu.consolForm">
        <div class="col"> 
          {{bu.companyNameInForm || bu.businessUnitName || bu.companyName}}
          <div style="font-size: 12px;">{{bu.versionName}} | {{bu.valuationDate | date: "mediumDate"}}</div> 
        </div>
  
  
        <div class="col-4">
          
          <ng-template [ngIf]="bu.investment">
            {{bu.investment.currency}}
  
            <ng-template [ngIf]="bu.investment.enterpriseValue">
              {{bu.investment.enterpriseValue | number: "1.1-1"}} Mn
            </ng-template>
            <!-- <ng-template [ngIf]="!bu.investment.equityValue.finalStakeValue">
              {{bu.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
            </ng-template> -->
          </ng-template>
        </div>
        
        <div class="col-3">
          <i class="far fa-edit primary-color-medium action-icon" (click)="openSAF(bu, null, !!bu.businessUnits)" *ngIf="buEditMode && !buShareMode"></i> &nbsp;&nbsp; <!-- && isCurrencyAvailable(bu) !== 'NotAvailable' -->
          <!-- <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "10" *ngIf="buEditMode && !isCurrencyAvailable(bu)"></mat-spinner> &nbsp;&nbsp; -->
  
          <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(bu, businessUnitPopup, !!bu.businessUnits)" *ngIf="!buEditMode && !buShareMode && bu.status !== 'Initiated'"></i>
          <i class="fas fa-share-alt action-icon primary-color-medium" *ngIf="buShareMode" aria-hidden="true" (click)="openValuationSharePopUp(bu, valuationSharePopUp); buShareMode = false; businessUnitModal.dismiss('Cross click')"></i>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>


<ejs-dialog #approveDialog  *ngIf="showApproveDialogBox"
  header='Approve/Reject Request' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  allowDragging=false
  closeOnEscape=false
  enableResize=false
  isModal=true
  [visible]="showApproveDialogBox"
  (close)="hideApproveDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          Comment
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" placeholder="Comments" maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="approveRejectObj.comments"/>
        </div>
      </div>
    </div>
    
    <div *ngIf="trackRecordData">
      <div class="row" style="align-items: center;" >
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            Implied Multiple
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            LTM Ebitda
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            Equity Value
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            Fund NAV
          </div>
          <div class="col dialog-data">
            -
        </div>
      </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            MOIC
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

    </div>

    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          <button style="border: 2px solid #1D1563; color: white; background-color: #1D1563; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px; width: 100px"
            (click)="approveRequest(approveRejectObj) ">
            Approve
          </button>
        </div>
        <div class="col dialog-data">
          <button style="border: 2px solid #1D1563; color: #1D1563; background-color: white; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px; width: 100px"
          (click)="rejectRequest(approveRejectObj)">
            Reject
          </button>
        </div>
      </div>
    </div>
    <!-- <button class="btn-btn saveApprovalObjectBtn" (click)="approveObject()">Approve</button>  -->
  </ng-template>
</ejs-dialog>


<ejs-dialog #approveDialog  *ngIf="showVersionNameDialog"
  header='Version Name' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  allowDragging=false
  closeOnEscape=false
  enableResize=false
  isModal=true
  [visible]="showVersionNameDialog"
  (close)="hideVersionNameDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          Version Name
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" placeholder="Version Name" maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="newFormVersion"/>
        </div>
      </div>
    </div>

    <div class="row" style="align-items: right;">
      <div class="row" style="align-items: right; margin: 1vw">
        <div class="col dialog-head">
          <button style="border: 2px solid #1D1563; color: white; background-color: #1D1563; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px;"
            (click)="addValuationDateVersion() ">
            Create Version
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>

<ejs-dialog #approveDialog  *ngIf="showUpdateVersionNameDialog"
  header='Update Version Name' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  allowDragging=false
  closeOnEscape=false
  enableResize=false
  isModal=true
  [visible]="showUpdateVersionNameDialog"
  (close)="hideUpdateVersionNameDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          Version Name
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" placeholder="Version Name" maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="newVersionName"/>
        </div>
      </div>
    </div>

    <div class="row" style="align-items: right;">
      <div class="row" style="align-items: right; margin: 1vw">
        <div class="col dialog-head">
          <button style="border: 2px solid #1D1563; color: white; background-color: #1D1563; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px;"
            (click)="updateVersionName() ">
            Update
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>