import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "src/app/services/translation.service";
import { DataService } from "src/app/services/data.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from "@angular/router";
import { DebtPortfolioService } from "../debt-portfolio.service";
import { UtilService } from 'src/app/utils/util.service';
import { forkJoin } from 'rxjs';
import { ITrancheStatus } from './interface/status';
import { ITrancheSecurity } from './interface/security';
import { ITrancheConclude } from './interface';
import { ToastService } from 'src/app/utils/toast.service';

type TrancheType = 'REVOLVER' | 'UNI_TRANCHE';

@Component({
  selector: 'app-tranching-analysis',
  templateUrl: './tranching-analysis.component.html',
  styleUrls: ['./tranching-analysis.component.scss']
})
export class TranchingAnalysisComponent implements OnInit, OnDestroy {
  valuationDates = [];
  issuerCompanyId;
  companyId;
  toggleEdit: boolean = false;
  popupHeader = "";
  inputForm: FormGroup;
  trancheForm: FormGroup;
  updateTrancheForm: FormGroup;
  inputResp: any;
  companyCapitalizationResp: any;
  tranchNames: [];
  updateTranchId:any;
  evData = {
    "ebitda": { "value": 0, "overwritten": false },
    "ebitdaMultiple": { "value": 0, "overwritten": false },
    "enterpriseValue": 0
  }
  marketCapitalizationResp: any;
  marketTranchingResp: any;
  valuationDate = new FormControl('');
  allTrancheSecurities:any[] = [];
  conclude = new FormControl('')

  trancheValuationStatus: ITrancheStatus = {
    status: "INITIAL",
    discountRate: 0,
    processedAt: 0
  };
  private trancheValuationStatusInterval = null;
  readonly TRANCHE = {
    REVOLVER: 'REVOLVER',
    UNI_TRANCHE: 'UNI_TRANCHE'
  } as const;
  trancheInformation: {
    trancheType: TrancheType
    value: number;
    securities: string[]
  } = {
    trancheType: null,
    value: 0,
    securities: []
  };
  securityIdToSecurityName: { [key: string]: string } = {};
  isaCategory: string = 'NA';
  isaValue: number = 0;
  isaComment: string = '';

  constructor(
    public translateService: TranslateService,
    private modalService: NgbModal,
    private dataService: DataService,
    public debtPortfolioService: DebtPortfolioService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private utilService: UtilService,
    private toastService: ToastService
  ) { }

  @ViewChild('investmentSpecificAdjustmentPopup') private investmentSpecificAdjustmentPopup: NgbModal;
  ngOnInit(): void {
    this.getIssuerComapnyId();
    this.createInputForm();
    this.createTrancheForm();
    this.updateTranchForm();
    // this.getAllTrancheSecurity()
  }

  ngOnDestroy(): void {
    if (this.trancheValuationStatusInterval) {
      // Due to concurrency
      clearInterval(this.trancheValuationStatusInterval - 1);
      clearInterval(this.trancheValuationStatusInterval);
      clearInterval(this.trancheValuationStatusInterval + 1);
    }
  }

  getPrettyDate(timestamp: number) {
    return new Date(timestamp).toString();
  }

  createInputForm() {
    this.inputForm = this.fb.group({
      type: [],
      geography: [],
      roundLeverageMultiple: []
    })
  }

  createTrancheForm() {
    this.trancheForm = this.fb.group({
      securityName: [],
      amount: [],
      securityId: [],
    })
  }

  updateTranchForm() {
    this.updateTrancheForm = this.fb.group({
      id: [],
      securityId: [],
      securityName: [],
      amount: []
    })
  }

  getIssuerComapnyId(fromEvent: boolean = false) {
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get("issuerCompanyId");
    this.getValuationDates(fromEvent);
    this.companyId = params.get("companyId");
  }

  getValuationDates(fromEvent: boolean) {
    this.dataService.getValuationDates(this.issuerCompanyId).subscribe((res) => {
      if (res) {
        this.valuationDates = res?.body?.response;
        if (!fromEvent) {
          this.valuationDate.setValue(this.valuationDates[0])
          this.getAllFormsAndTablesData(this.valuationDates[0])
          this.loadInvestmentSpecificAdjustment()
        }
      }
    })
  }

  async getTrancheValuationStatus() {
    try {
      const shouldRefetchTableData = this.trancheValuationStatus.status === 'IN_PROGRESS';
      const response = await this.dataService
        .getTrancheValuationStatus(this.issuerCompanyId, this.valuationDate.value)
        .toPromise();
      this.trancheValuationStatus = response.body.response;
      if (shouldRefetchTableData && this.trancheValuationStatus.status != 'IN_PROGRESS')
        this.fetchData(this.valuationDate.value);
    } catch (e) {
      this.trancheValuationStatus = {
        status: 'INITIAL',
        discountRate: 0,
        processedAt: 0
      };
    }
  }

  getTrancheConclude() {
    this.dataService.getTrancheConclude(this.issuerCompanyId, this.valuationDate.value)
      .subscribe(resp => {
        console.log(resp);
        this.conclude.setValue(resp.body.response.conclude);
      });
  }

  async getAllFormsAndTablesData(date: any) {
    this.utilService.showLoadingPopup();
    // Polling of status
    if (this.trancheValuationStatusInterval) clearInterval(this.trancheValuationStatusInterval);
    await this.getTrancheValuationStatus();
    this.trancheValuationStatusInterval = setInterval(async () => {
      await this.getTrancheValuationStatus();
    }, 3000);

    if (this.trancheValuationStatus.status === 'IN_PROGRESS') {
      this.utilService.closeAllPopups();
      return;
    }
    
    this.fetchData(date);
  }

  private fetchData(date: string): void {
    const firstApi = this.dataService.getInputsData(this.issuerCompanyId, date);
    const secondApi = this.dataService.getEvData(this.issuerCompanyId, date);
    forkJoin([firstApi, secondApi]).subscribe(res => {
      this.inputResp = res?.[0]['body']['response'];
      this.inputForm.patchValue({ 'type': this.inputResp?.type }),
      this.inputForm.patchValue({ 'geography': this.inputResp?.geography }),
      this.inputForm.patchValue({ 'roundLeverageMultiple': this.inputResp?.roundLeverageMultiple || 0.05 }),
      this.evData = res?.[1]['body']['response'];
      this.getCompanyCapitalization();
      this.getMarketCapitalizationData();
      this.getMarketReTranchingData();
      this.getTrancheConclude();
      this.utilService.closeAllPopups();
    })
  }

  getCompanyCapitalization(){
    this.dataService.getCompanyCapitalization(this.issuerCompanyId, this.valuationDate.value).subscribe((res) => {
      if(res){
        this.companyCapitalizationResp = res?.['body']['response'];
      }
    })
  }
  getMarketCapitalizationData(){
    this.dataService.getMarketCapitalization(this.issuerCompanyId, this.valuationDate.value).subscribe((res) => {
      if(res){
        this.marketCapitalizationResp = res?.['body']['response'];
      }
    })
  }
  getMarketReTranchingData(){
    this.dataService.getMarketReTranching(this.issuerCompanyId, this.valuationDate.value).subscribe((res) => {
      if(res){
        this.marketTranchingResp = res?.['body']['response'];
      }
    })
  }
  // getAllTrancheSecurity() {
  //   this.dataService.getAllTranche(this.issuerCompanyId).subscribe((resp: any) => {
  //     if (resp.body.response) {
  //       this.allTrancheSecurities = resp.body.response;
  //     }
  //   });
  // }

  saveInputs() {
    this.utilService.showLoadingPopup();
    let payload = this.inputForm.getRawValue();
    this.dataService.saveInputs(this.issuerCompanyId, this.valuationDate.value, payload)
      .subscribe({
        complete: () => {
          // Re-fetch the table data
          this.getCompanyCapitalization();
          this.getMarketCapitalizationData();
          this.getMarketReTranchingData();
          this.utilService.closeAllPopups();
        },
        error: () => {
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar("Error - Couldn't save Inputs");
        }
      });
  }

  saveEV() {
    this.utilService.showLoadingPopup();
    this.dataService.saveEvData(this.issuerCompanyId, this.valuationDate.value, this.evData)
      .subscribe({
        next: resp => {
          this.evData = resp?.body?.response;
        },
        complete: () => {
          // Re-fetch the table data
          this.getCompanyCapitalization();
          this.getMarketCapitalizationData();
          this.getMarketReTranchingData();
          this.utilService.closeAllPopups();
        },
        error: () => {
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar("Error - Couldn't save EV");
        }
      });
  }

  resetEV() {
    this.utilService.showLoadingPopup();
    this.dataService.resetEvData(this.issuerCompanyId, this.valuationDate.value)
      .subscribe((resp: any) => {
        this.utilService.closeAllPopups();
        this.evData = resp?.body?.response;

        // Re-fetch the table data
        this.getCompanyCapitalization();
        this.getMarketCapitalizationData();
        this.getMarketReTranchingData();
      });
  }

  saveInputsAndEv() {
    let payload = this.inputForm.getRawValue();
    const saveInputData = this.dataService.saveInputs(this.issuerCompanyId, this.valuationDate.value, payload);
    const saveEvData = this.dataService.saveEvData(this.issuerCompanyId, this.valuationDate.value, this.evData);
    forkJoin([saveInputData, saveEvData]).subscribe(response => {
      console.log(this.evData,payload,'payload')
    })
  }
  openPopupCompanyCapitalization(content) {
    this.popupHeader = this.translateService.getLabel("add");
    this.modalService.open(content, {
      centered: true,
      windowClass: "claims",
    });
  }
  openPopupEditClaim(content: TemplateRef<any>, trancheType: TrancheType) {
    let value: number = 0;
    switch (trancheType) {
      case 'REVOLVER':
        this.popupHeader = this.translateService.getLabel('credit_revolver');
        value = this.companyCapitalizationResp.revolver.amount;
        break;
      
      case 'UNI_TRANCHE':
        this.popupHeader = this.translateService.getLabel('credit_unitranche');
        value = this.companyCapitalizationResp.uniTranche.amount;
        break;
    }
    this.trancheInformation = {
      trancheType,
      value,
      securities: []
    };
    this.utilService.showLoadingPopup();
    const allSecurities = this.dataService.getAllSecuritiesForTrancheAnalysis(this.issuerCompanyId);
    const allSecuritiesAllocatedToTranche = this.dataService.getAllSecuritiesAllocatedToTranche(
      this.issuerCompanyId, this.valuationDate.value, trancheType
    );
    forkJoin([allSecurities, allSecuritiesAllocatedToTranche]).subscribe(resp => {
      const allSecuritiesResp = resp[0].body.response;
      const allSecuritiesAllocatedToTrancheResp = resp[1].body.response;
      this.securityIdToSecurityName = allSecuritiesResp.reduce((acc, security) => {
        acc[security.securityId] = security.securityName;
        return acc;
      }, {});
      this.trancheInformation.securities = allSecuritiesAllocatedToTrancheResp.map(security => security.securityId);
      console.log(this.trancheInformation);
      console.log({ allSecuritiesResp, allSecuritiesAllocatedToTrancheResp });
      this.utilService.closeAllPopups();
      this.modalService.open(content, {
        centered: true,
        size: 'md',
        // windowClass: "claims",
      });
    });
    // this.updateTranchId = data?.id;
    // this.updateTrancheForm.patchValue({'securityName':data?.name}),
    // this.updateTrancheForm.patchValue({'amount':data?.amount}),
    // this.updateTrancheForm.patchValue({'id':data?.id})
    // this.updateTrancheForm.patchValue({'securityId':data?.securityId})
  }
  addTranche(){
    let securityId = this.allTrancheSecurities.find(s => s.securityName == this.trancheForm.value.securityName)?.securityId;
    this.trancheForm.patchValue({'securityId': securityId || ''})
    let payload = this.trancheForm.getRawValue();
    this.dataService.addTranche(this.issuerCompanyId, this.valuationDate.value, payload).subscribe(res => {
      this.modalService.dismissAll();
      this.getCompanyCapitalization();
      this.getMarketCapitalizationData();
      this.getMarketReTranchingData();
    })
  }

  updateTranche(){
    // let payload = this.updateTrancheForm.getRawValue();
    // console.log(this.trancheInformation);
    this.utilService.showLoadingPopup();
    const securities: ITrancheSecurity[] = this.trancheInformation.securities.reduce((acc, securityId) => {
      acc.push({ securityId, securityName: this.securityIdToSecurityName[securityId] });
      return acc;
    }, [])
    const updateTranche = this.dataService.updateTranche(
      this.issuerCompanyId, this.valuationDate.value,
      { trancheType: this.trancheInformation.trancheType, amount: this.trancheInformation.value }
    );
    const allocateSecuritiesToTranche = this.dataService.allocateSecuritiesToTranche(
      this.issuerCompanyId, this.valuationDate.value,
      {
        trancheType: this.trancheInformation.trancheType,
        securities
      }
    );
    forkJoin([updateTranche, allocateSecuritiesToTranche]).subscribe({
      complete: () => {
        this.utilService.closeAllPopups();
        this.modalService.dismissAll();
        // Re-fetch the table data
        this.getCompanyCapitalization();
        this.getMarketCapitalizationData();
        this.getMarketReTranchingData();
      },
      error: () => {
        this.utilService.closeAllPopups();
        this.modalService.dismissAll();
        this.toastService.openSnackBar('Error - Could not update tranche');
      },
    });
  }

  onTranchngAnalysisConclude() {
    this.utilService.showLoadingPopup();
    const concludeTranching: ITrancheConclude = { conclude: this.conclude.value };
    console.log(concludeTranching);
    this.dataService
      .saveConcludeTrancheValuation(this.issuerCompanyId, this.valuationDate.value, concludeTranching)
      .subscribe({
        complete: () => {
          this.utilService.closeAllPopups();
        },
        error: () => {
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar('Error - Could not save tranche valuation conclustion!');
        }
      });
  }

  evaluate() {
    this.utilService.showConfirmMessage("Do you want to start the evaluation process?", 'Yes', 'No')
      .afterClosed()
      .subscribe(status => {
        if (status === 'Yes') {
          this.utilService.showLoadingPopup();
          this.dataService.evaluateTrancheValuation(this.issuerCompanyId, this.valuationDate.value)
            .subscribe({
              complete: () => {
                this.utilService.closeAllPopups();
              },
              error: () => {
                this.utilService.closeAllPopups();
                this.toastService.openSnackBar('Error - Could not start evaluation process');
              }
            })
        }
      });
  }

  openInvestmentSpecificAdjustment() {
    this.isaCategory = 'NA';
    this.isaValue = 0;
    this.isaComment = '';

    this.dataService.getInvestmentSpecificAdjustment(this.issuerCompanyId, this.valuationDate.value)
      .subscribe((res: any) => {
        this.isaCategory = res?.body?.response?.isaCategory || 'NA';
        this.isaValue = res?.body?.response?.isaValue || 0;
        this.isaComment = res?.body?.response?.comment || '';
        this.modalService.open(this.investmentSpecificAdjustmentPopup, { centered: true, windowClass: 'popUp', size: 'lg' });
      });
  }

  loadInvestmentSpecificAdjustment() {
    this.dataService.getInvestmentSpecificAdjustment(this.issuerCompanyId, this.valuationDate.value)
      .subscribe((res: any) => {
        this.isaValue = res?.body?.response?.isaValue || 0;
      });
  }

  saveInvestmentSpecificAdjustment() {
    this.utilService.showLoadingPopup();
    const payload = {
      isaCategory: this.isaCategory,
      isaValue: this.isaValue,
      comment: this.isaComment,
    };
    this.dataService.postInvestmentSpecificAdjustment(this.issuerCompanyId, this.valuationDate.value, payload).subscribe((response: any) => {
      this.utilService.closeAllPopups();
    });
  }

}