
<div class="mappping-filter">
    <div class="mat-h1">
        Mapping Management
    </div>
    <div *ngIf="showUploadDate" class="filter-section">

        <mat-icon class="info-icon"
            matTooltip="Choose a date to view the map and version that present in the system on that specific date"
            matTooltipPosition="left">info_outline</mat-icon>
        <div class="upload-date-wrapper">
            <div class="date-label">
                Upload Date
            </div>
            <mat-form-field>
                <input matInput [matDatepicker]="uploadDatePicker" placeholder="Upload Date"
                    [formControl]="uploadDateFc" [max]="today">
                <mat-datepicker-toggle matSuffix [for]="uploadDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #uploadDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="create-list">
            <button *ngIf="showCreateListBtn" mat-flat-button class="btn-custom-primary create-btn"
                [routerLink]="'./create-version'"> Create
                List</button>
            <button *ngIf="showCreateMapBtn" mat-flat-button class="btn-custom-primary create-btn"
                [routerLink]="'./create-attributes-map'"> Create
                Map</button>
            <!-- <button *ngIf="showAssignBtn" mat-flat-button class="btn-custom-primary create-btn">
                Assign</button> -->
        </div>
    </div>
</div>