import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

import * as moment from 'moment';
import { MessageService } from 'src/app/services/message.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { cloneDeep } from 'lodash';
import { DebtPortfolioService } from '../debt-portfolio.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TranslateService } from 'src/app/services/translation.service';
import { NotificationService } from 'src/app/utils/notification/notification.service';

@Component({
  selector: 'app-debt-company-valuation',
  templateUrl: './debt-company-valuation.component.html',
  styleUrls: ['./debt-company-valuation.component.scss']
})
export class DebtCompanyValuationComponent implements OnInit {
  @ViewChild('valuationDatesGrid', { static: false }) public valuationDatesGrid: GridComponent;
  newVersionName: any;

  constructor(private activatedRoute: ActivatedRoute,
    public debtPortfolioService: DebtPortfolioService,
    private ms: MessageService,
    private ums: UserManagementService, public currencyService: CurrencyExchangeService,
    private router: Router, private datePipe: DatePipe,
    private dataService: DataService,
    private utilService: UtilService,
    private modalService:NgbModal,
    private fundService : FundListService, 
    private protfolioServiceV2: PortFolioSummaryServiceV2,
    public translateService: TranslateService,
    private notificationService: NotificationService)
  {}

  newCompany = {name: "", valuationDate: null, groupFormId: null, security: "DEBT", businessUnitsNumber: 0, issuerCompanyId:""};

  selectedCompanyConsolForm; // passing as an input to consol summary component

  selectedCompanyDates = [];
  initiatorsGroupMembers;

  APPROVAL_ACTION = {
    INITIATE_APPROVAL: "Initiate Approval",
    RECALL_APPROVAL: "Recall Approval",
    REJECT_APPROVE: "ApproveReject",
    NO_CONDITION: "No Condition",
    NONE: "none"
  }

APPROVAL_STATUS = {
  NOT_STARTED: "Not Started",
  INITIATED: "Initiated",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PENDING: "Pending",
  NO_CONDITION: "None",
  APPROVE_REJECT: "Approve / Reject"
}

  valuationDateGridDataSource: any = [
    {
      "loading": true
    }
  ];
  waterFallChart;
  primaryCompanyId;

  investmentDateInfo;

  showUserValDates = false;
  showValDates = false;
  showConsolSummary = false;

  showInvestment = false;
  formObject = {
    valuationDate:'',
    fairVal:0,
    stake: 0,
    stakeVal: 0
  };
  editedValuationDate;
  editedValuationDateIndex;

  investmentCurrency = "";

  selectedFormVersions;
  selectedBusinessUnits;
  newFormVersion = "";

  businessUnitsWaterFall;
  buEditMode = false;

  buShareMode = false;

  shareInputName;
  shareInputEmail;

  approvalConditionData;
  conditionsData;
  approvalData;
  allApprovalDataArray;

  showRejectApproveButton = false;
  showRecallButton = false;
  showInitiateApprovalButton = false;
  showNoActionButton = false;
  loggedInUser;
  allApprovalDetails;
  showApproveDialogBox;
  approveRejectObj;
  trackRecordData;

  showVersionNameDialog = false;
  selectedVersionForCopy = null;

  selectedSecurityType;
  selectedCompany;
  issuerCompanyId;

  showUpdateVersionNameDialog = false;
  selectedVersionForUpdate = null;
  allVerAllValDate = null;
  allVersions = null;

  createEquityForm = false;

  debtCompanyDetails;
  fundId: any;
  allValuationDates = [];
  isVersionSaved: boolean = false;
  rolloverDetails: any;
  showOnboardingText: boolean = false;
  compareValuationData:any[] = []
  securityLinkageData:any[] = [];
  securityLinkage: boolean = false;
  orgId;

  async ngOnInit() {
      this.loggedInUser = this.ums.getSelectedUserDetails();
      const userDetails = this.ums.getSelectedUserDetails();
      this.orgId = userDetails?.organization.id;

      const params  = this.activatedRoute.snapshot.queryParamMap;
      this.primaryCompanyId = params.get("companyId");
      this.debtPortfolioService.selectedCompanyId = this.primaryCompanyId;
      const fundId = params.get("fundId");
      this.fundId = fundId;

      this.dataService.getDebtCompanyDetails(this.debtPortfolioService.selectedCompanyId)
        .subscribe((resp:any) => {
          if(resp?.body["response"]) {
            this.debtCompanyDetails = resp.body["response"].companyDetails;
          }
        })

        this.debtPortfolioService.selectedFundId = fundId;
        this.getValuationGridDataSource();
      
        this.notificationService.notificationStatusUpdateForCredit.subscribe((details : any) => {      
          this.refreshVersionsList(true);
        })
      
   }

 

   getValuationGridDataSource(isRefresh?) {
    this.dataService.getAllValuationDateForms(this.loggedInUser.id, this.fundId, this.primaryCompanyId, this.loggedInUser.organization.id).pipe(
      mergeMap((res: any) => {
        if (res?.body?.response) {
          const payload = res.body.response;
        return this.debtPortfolioService.getDebtValuationSummary(payload);
      }
        return of(null);
    }))
    .subscribe((resp: any) => {
      if (resp?.body["response"]) {
          let data = resp.body["response"];

        if (data?.companies[0]?.valuationDates?.length) {
          data.companies[0].valuationDates.sort((a, b) => {
              return <any>new Date(a.date) - <any>new Date(b.date);
          });

          this.selectedCompanyDates = data?.companies[0]?.valuationDates;
          this.prepareValuationGridDataSource();

          if (!isRefresh) {
            this.dataService.getIssuerCompanyId(this.selectedCompanyDates[0].valuationDateId).subscribe((response: any) => {
              this.issuerCompanyId = response.body['response'].issuerCompanyId;

              let datesArr = [];
              this.selectedCompanyDates.map(eachDate => {
                datesArr.push(eachDate.qXvaluationDate);
              });

              let latestDate = new Date(datesArr[datesArr.length - 1]);
              let modifiedDate = moment(latestDate).add(1, 'quarter').endOf('quarter');
              this.rolloverDetails = {
                "issuerCompanyId": this.issuerCompanyId,
                "source": "USER_ROLLOVER",
                "orgId": this.loggedInUser.organization.id,
                "userId": this.loggedInUser.id,
                "valuationDate" : (moment(modifiedDate)).format("YYYY-MM-DD")
              };
            })
          }
        }
      }
    })
   }


  prepareGroupledValVersions(){
    let data = this.valuationDateGridDataSource;
    let dataMap = new Map();
    data.forEach(v => {
      if(dataMap.has(v.valuationDate)){
        let arr = dataMap.get(v.valuationDate);
        arr.push(v);
        dataMap.set(v.valuationDate, arr);
      }
      else dataMap.set(v.valuationDate, [v]);
    });

    let obj = {};
    let dataArr = [];
    let count = 0;
    for(let item of dataMap){
      obj = { name : item[0], value : item[1], index : count};
      dataArr.push(obj);
      count++;
    }
    dataArr.sort((a, b) => {
      return <any>new Date(b.name) - <any>new Date(a.name);
    });
    this.valuationDateGridDataSource = dataArr;
    if(this.valuationDateGridDataSource.length) {
      let dateArr = cloneDeep(this.valuationDateGridDataSource);
      dateArr.sort(function(a,b){
        return new Date(b.name).valueOf() - new Date(a.name).valueOf();
      });

      let latestDate = new Date(dateArr[0].name);
      let modifiedDate = moment(latestDate).add(1, 'quarter').endOf('quarter');
      this.newCompany.valuationDate = (moment(modifiedDate)).format("YYYY-MM-DD");
      if(this.valuationDateGridDataSource[0]?.value[0]?.status == null){
        this.showOnboardingText = true;
      } else {
        this.showOnboardingText = false;
      }
    }
  }

  async prepareValuationGridDataSource(){
    this.allApprovalDataArray = [];
    let allRequestIds = [];
    this.approvalConditionData = [];
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }

    let allValDates = [];
    this.selectedCompanyDates.forEach(eachDate => {
      allValDates.push(eachDate.qXvaluationDate);
      this.allValuationDates.push(eachDate.qXvaluationDate);
    });

    let response;
    try {
      response = await this.dataService.getAllDebtFormsVersionsForAllValDates(this.primaryCompanyId, allValDates);
    } catch(e) {
      response = null;
    }

    this.allVerAllValDate = [];
    this.allVersions = [];
    if(response.body["response"][0]) {
      this.allVersions = response.body["response"][0];
      this.getSecurityLinkageData(allValDates,response.body["response"][0]) 

      // for (const key in allVersions){
      //   allVersions[key].forEach(entry => {
      //     this.allVerAllValDate.push(entry)
      //   });
      // }
      allValDates.forEach(eachate => {
        if(this.allVersions[eachate]) {
          this.allVersions[eachate].forEach(entry => {
            this.allVerAllValDate.push(entry)
          });
        }
      });

    } else {
      return;
    }

    try {
      this.approvalConditionData = await this.dataService.getApprovalCondition(this.loggedInUser.organization.id, this.debtPortfolioService.companyId, this.protfolioServiceV2.selectedFundId);
      this.approvalConditionData = this.approvalConditionData.body.response;

      if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {
        this.initiatorsGroupMembers = (await this.dataService.getGroupById(this.approvalConditionData.approvers[0].id)).body["response"].members;
      }
    } catch(e) {
      console.log("Error is getting condition and members");
    }

    this.allVerAllValDate.forEach((eachDate) => {
      if(eachDate.approvalRequestId){
        allRequestIds.push(eachDate.approvalRequestId)
      }
    });

    let allRequestIdObject = {
      approvalRequestIds: allRequestIds,
      firmId: this.loggedInUser.organization.id
    }

    this.allApprovalDetails = [];
    if(allRequestIds.length > 0){
        try {
        let resp = await this.dataService.getAllApprovalDetails(allRequestIdObject);
        if(resp?.body && resp?.body["response"]) {
          this.allApprovalDetails = resp.body["response"];
        }
      } catch(e) {
        response = null;
      }
    }

    this.valuationDateGridDataSource = [];
    this.allVerAllValDate.forEach((eachDate) => {
      this.getActionNameAndStatus(eachDate);
    });

    this.prepareGroupledValVersions();

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }
    this.utilService.closeAllPopups()
  }

  async getActionNameAndStatus(formVersion){

    const obj = {
      approvalStatus : null,
      actionName : null,
      approvalRequestId: null,
      editable: false
    }

    let approvalData = [];

      if(this.allApprovalDetails.length > 0){
        approvalData = this.allApprovalDetails.filter(eachApproval => eachApproval.id == formVersion.approvalRequestId );
      }

      if(approvalData.length > 0)
      {
        obj.approvalRequestId = approvalData[0].id;

        // if(this.checkIfAdmin())
        // {
        //   if(approvalData[0].status == "approved")
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        //   else if(approvalData[0].status == "rejected")
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        //   else
        //   {
        //     obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
        //     obj.actionName = this.APPROVAL_ACTION.NONE;
        //     obj.canEdit = true;
        //   }
        // }
        // else
        // {
          if(approvalData[0].status == "approved")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else if(approvalData[0].status == "rejected")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else
          {
            if(approvalData[0].status == "pending")
            {
              let isInitator = this.isInitiator();
              if(isInitator)
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.RECALL_APPROVAL;
              }
              else if(this.isCurrentApprover(approvalData))
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.REJECT_APPROVE;
              }
              else
              {
                // no action
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.NONE;
              }

            }
          }
        // }
      }
      else if(this.approvalConditionData && this.approvalConditionData.hasOwnProperty("conditionName"))
      {
        let isInitator = this.isInitiator();
        if(isInitator)
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.INITIATE_APPROVAL;
          obj.editable = true;
        }
        else
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.NONE;
          obj.editable = true;
        }
      }
      else
      {
        // Disable initiate button
        obj.actionName = this.APPROVAL_ACTION.NO_CONDITION;
        obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
        obj.editable = true;
      }

      let formVersionForGrid = cloneDeep(formVersion);

      formVersionForGrid["frozen"] = formVersion.frozen;
      formVersionForGrid["status"] = formVersion.formStatus;
      formVersionForGrid["stake"] = formVersion.value;
      formVersionForGrid["versionName"] = formVersion.versionName;

      // formVersionForGrid["versionName"] = JSON.parse(formVersion.details).versionName;
      formVersionForGrid["currency"] = formVersion.currency;

      formVersionForGrid["approvalStatus"] = obj["approvalStatus"];
      formVersionForGrid["actionName"] = obj["actionName"];
      formVersionForGrid["editable"] = obj["editable"];
      formVersionForGrid["approvalRequestId"] = obj["approvalRequestId"];

      formVersionForGrid["comments"] = null;

      this.valuationDateGridDataSource.push(formVersionForGrid);
      console.log(this.valuationDateGridDataSource,'valuationDateGridDataSource')
     
  }

    //for security linkage 
  getSecurityLinkageData(allValDates: any[], formVersionForGrid: any[]) {
    let comId = [this.primaryCompanyId]
    //To check linked securities
    this.dataService.getGlobalConfig(this.orgId).pipe(
      mergeMap((res:any) => {
        let allSettings = res?.body?.response?.orgSettings;
        this.securityLinkage = allSettings?.find( e => e?.orgSettingsKey == 'SECURITY_LINKAGE')?.isActive || false;
        // this.securityLinkage = false;
        if (this.securityLinkage){
          return this.dataService.getSecurityLinkage(comId);
        }
      }),
    ).subscribe((res) => {
      if(this.securityLinkage) {
        let securityLinkageData = res['body']['response']['securities'];
        let i = 0;
        allValDates.forEach(e => {
          formVersionForGrid[e]?.forEach(element => {
            for (let sec of securityLinkageData) {
              sec?.debtForms?.forEach((form: any, index: number) => {
                if (index > 0 && formVersionForGrid[e]?.find(e => e.id == form?.valuationDateId)) {
                  let uniqueNames = Array.from(new Set(form?.childSecurities.map(e => e.companyName)));
                  let securityName = uniqueNames.length > 1 ? uniqueNames.slice(0, -1).join(", ") + " & " + uniqueNames[uniqueNames.length - 1] : uniqueNames.join(", ");
                  this.compareValuationData.push(
                    {
                      isIdMatched: !!formVersionForGrid[e]?.find(e => e.id == form?.valuationDateId),
                      id: form?.valuationDateId,
                      securityType: form?.securityOnboardingType,
                      isDeletedOrNot: (form?.childSecurities.length == 0) ? true : false,
                      securityName: securityName,
                      date: e
                    },
                  )
                }
                i++
              })
            }
          })
        })
        this.compareValuationData.reverse();
      }
    })
  }

  securityLinkageIdFunct(id, compareValuationData) {
    if (compareValuationData) {
      return (compareValuationData?.find(e => e.id == id)) ? (compareValuationData?.find(e => e.id == id)) : false;
    }
  }

  isInitiator() {
    let isInitator = false;

    if(this.approvalConditionData.approvers[0].type == "user" && this.approvalConditionData.approvers[0].id == this.getLoggedInUserId())
    {
      isInitator = true;
    }
    else if(this.approvalConditionData.approvers[0].type == "group")
    {
      let initatorObj = this.initiatorsGroupMembers.find(eachMember => eachMember.id == this.getLoggedInUserId());
      if(initatorObj) {
        isInitator = true;
      }
    }
    else {
      isInitator = false;
    }
    return isInitator;
  }

  isCurrentApprover(approvalData) {
    let isCurrentApprover = false;
    let firstPendingApprover = approvalData[0].approvals.find(eachApproval =>  eachApproval.status == "pending" );

    if(firstPendingApprover.hasOwnProperty("group"))
    {
      firstPendingApprover.group.members.forEach((member) => {
        if(member.id == this.getLoggedInUserId())
        {
          isCurrentApprover = true;
        }
      });
    } else if(firstPendingApprover.hasOwnProperty("user"))
    {
      if(firstPendingApprover.user.id == this.getLoggedInUserId())
      {
        isCurrentApprover = true;
      }
    }

    return isCurrentApprover;
  }

  checkIfAdmin() {
    if(this.loggedInUser.organization.masterUser)
    {
      return true
    }
    else
    {
      return false
    }
  }

  getLoggedInUserId() {
    return this.loggedInUser.id;
  }

 async initiateApprovalRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();

    let requestObj = {
      new: {
        "id": "",
        "conditionId": this.approvalConditionData.conditionId,
        "conditionName": this.approvalConditionData.conditionName,
        "conditionLevel": this.approvalConditionData.conditionLevel,
        "fundId": this.protfolioServiceV2.selectedFundId,
        "firmId": this.loggedInUser.organization.id,
        "companyId": this.debtPortfolioService.companyId,
        "objectLocked": "1",
        "status": "pending",
        "objectName": valDateObject.companyName+" / "+ valDateObject.versionName,
        "objectId": valDateObject.id,
        "objectContent": environment.portalUrl + "/pv/#/valuation-summary?id=" + valDateObject.id + "&fv=4",
        "objectType": "valuation",
        "sendEmail": false
      }
    }

    requestObj.new["approvals"] = [];

    this.approvalConditionData.approvers.forEach((approver, index) => {
      if(approver.type == "group")
      {
        let groupObj = {
          "group": {
            "id": approver.id,
            "name": approver.name
          },
          "status": "pending",
          "comments": "",
          "timestamp": ""
        }

        requestObj.new["approvals"].push(groupObj);
      }
      else
      {
        let userObj = {
          "user": {
            "id": approver.id,
            "name": approver.name
          },
          "status": "pending",
          "comments": "",
          "timestamp": ""
        }

        requestObj.new["approvals"].push(userObj);
      }
    });

    if(requestObj.new.conditionId)
    {
      let requestCreationStatus = await this.dataService.initiateApprovalRequest(requestObj);

      if(requestCreationStatus.status == 200)
      {
        valDateObject.approvalRequestId = requestCreationStatus.body["response"].id;
        this.approveRequest(valDateObject);
      }
    }

  }

  recallApprovalRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    let recallObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "recalled",
      "sendEmail": false
    }

    if(valDateObject.comments == null)
    {
      recallObj.comments = "Recalled"
    }
    else
    {
      recallObj.comments = valDateObject.comments;
    }

    this.dataService.recallRequest(recallObj).then(() => {
      this.prepareValuationGridDataSource();
    });

  }

  approveRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    this.hideApproveDialog();
    let approveObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "approved",
      "sendEmail": false
    }

    if(valDateObject.comments == null)
    {
      approveObj.comments = "Approved"
    }
    else
    {
      approveObj.comments = valDateObject.comments;
    }

    this.dataService.approveRequest(approveObj).then(() => {
      this.prepareValuationGridDataSource();
    });

  }

  rejectRequest(valDateObject)
  {
    this.utilService.showLoadingPopup();
    this.hideApproveDialog();
    let rejectObj = {
      "approvalRequestID": valDateObject.approvalRequestId,
      "userId": this.getLoggedInUserId(),
      "comments": "",
      "status": "rejected",
      "sendEmail": false
    }

    if(valDateObject.comments == null)
    {
      rejectObj.comments = "Rejected"
    }
    else
    {
      rejectObj.comments = valDateObject.comments;
    }

    this.dataService.rejectRequest(rejectObj).then(() => {
      this.prepareValuationGridDataSource();
    });
  }

  enableApproveDialogBox(valDate){
    this.utilService.showLoadingPopup();
    this.approveRejectObj = valDate;
    this.trackRecordData = undefined;

    let payload = {
      "orgId": this.loggedInUser.organization.id,
      "userId": this.getLoggedInUserId(),
      "fundId": this.protfolioServiceV2.selectedFundId,
      "formId": valDate.id
    };

    this.showApproveDialogBox = true;

    // this.dataService.getTrackRecordData(payload).then(resp => {
    //   this.utilService.closeAllPopups();
    //   console.log(resp);

    //   this.trackRecordData = "";
    //   this.showApproveDialogBox = true;
    // }).catch(err => {
    //   console.log("error is getting track record data");
    //   this.trackRecordData = undefined;
    //   this.utilService.closeAllPopups();
    //   this.showApproveDialogBox = true;
    // })
  }

  hideApproveDialog() {
    this.showApproveDialogBox = false;
    this.approveRejectObj = null;
  }

  initCompanyWithSelectedFund(){
    this.selectedCompanyDates.forEach( company => {
      if(!this.debtPortfolioService.selectedListOfInvestors[company.id]){
        this.debtPortfolioService.selectedListOfInvestors[company.id] = []
      }

      this.debtPortfolioService.selectedListOfInvestors[company.id].push({
        investorName : this.fundService.getFundById(this.debtPortfolioService.selectedFundId).name,
        stake: company.investment ? company.investment.equityValue.stake : 0
      })
    })
  }

  copyVersion(selectedVersion) {
    this.showVersionNameDialog = true;
    this.selectedVersionForCopy = selectedVersion;
  }

  hideVersionNameDialog() {
    this.showVersionNameDialog = false;
    this.selectedVersionForCopy = null;
  }

  addValuationDateVersion() {
    if(!this.newFormVersion || this.newFormVersion.length === 0) return;

    const selectedVersion = this.selectedVersionForCopy;

    this.newCompany.name = selectedVersion.companyName;
    this.newCompany.groupFormId = this.primaryCompanyId;
    this.newCompany.valuationDate = selectedVersion.valuationDate;
    this.newCompany.businessUnitsNumber = 0;

    this.modalService.dismissAll();

    this.createValuationDate(this.newCompany, this.newFormVersion, 'false', selectedVersion);
    this.hideVersionNameDialog();
  }

  async addValuationDate() {

    if(!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;

    this.newCompany.valuationDate = (moment(this.newCompany.valuationDate)).format("YYYY-MM-DD");
    this.newCompany.name = this.selectedCompanyDates[0].qxCompanyName;
    this.newCompany.groupFormId = this.primaryCompanyId;

    const submittedForms = this.selectedCompanyDates.filter(valDate =>  valDate.qxFormStatus == "SUBMITTED")
    let latestFormToCopy;
    if(submittedForms.length > 0) {
      let sortedForms = submittedForms.sort((a,b) => {
        return Number(new Date(b.qXvaluationDate)) - Number(new Date(a.qXvaluationDate)) 
      });
      latestFormToCopy = sortedForms[0];
    } else {
      latestFormToCopy = this.selectedCompanyDates[0];
    }

    this.createValuationDate(this.newCompany, "FIRST", 'true', latestFormToCopy);
  }

  createValuationDate(newCompanyInfo, versionName, frozen, copyDataFromForm) {
    this.utilService.showLoadingPopup();
    this.ms.publish("loadingMessage", this.translateService.getLabel("preparing_data_for_valuation_date"));

    const previousValuationDateId = copyDataFromForm?.valuationDateId || copyDataFromForm?.id;
    const valuationDate = newCompanyInfo.valuationDate;
    const orgId = this.loggedInUser.organization.id;
    const userId = this.loggedInUser.id;

    this.dataService.equityFormSubmission(previousValuationDateId, valuationDate, versionName, frozen, orgId, userId)
      .subscribe((res: any) => {
        res?.body?.success && this.refreshVersionsList();
      })
  }

  refreshVersionsList(isRolledOver?) {
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    this.getValuationGridDataSource(true);
  }


  openDebtForm(comp) {
    this.modalService.dismissAll();

    this.dataService.getDebtModelId(comp.id).then((data: any) => {
      let debtModelId = data.body["response"]?.debtModelId;

      if(debtModelId) {
        let url = environment.portalUrl + environment.debtModel + `/#/valuation/${debtModelId}/set-up-preview`;
        window.open(url, "_self");
        //window.open(url);
      }
      else {
        this.dataService.getIssuerCompanyId(comp.id).subscribe((response:any)=> {
          const issuerCompanyId = response.body.response.issuerCompanyId
          let params = `fundId=${this.debtPortfolioService.selectedFundId}&companyId=${this.debtPortfolioService.selectedCompanyId}&valuationDateId=${comp.id}&valuationDate=${comp.valuationDate}&issuerCompanyId=${issuerCompanyId}`;
          let url = environment.portalUrl + environment.debtModel + "/#/valuation/set-up?" + params;
          window.open(url, "_self");
          // window.open(url);
        })
      }
    })

   }

  openValuation(comp) {
    this.modalService.dismissAll();
    comp.valuationDateId = comp.id;
    this.debtPortfolioService.openDebtValuation(comp);
  }

  openSharePopUp(comp, businessUnitPopup, valuationSharePopUp){
    if(comp.businessUnitsNumber && comp.businessUnitsNumber > 0){
      this.buShareMode = true;
      return
    }

    this.openValuationSharePopUp(comp, valuationSharePopUp)
  }

  hideUpdateVersionNameDialog() {
    this.showUpdateVersionNameDialog = false;
    this.selectedVersionForUpdate = null;
  }

  updateVersion(selectedVersion) {
    this.showUpdateVersionNameDialog = true;
    this.selectedVersionForUpdate = selectedVersion;
  }

  updateVersionName(){
    this.utilService.showLoadingPopup();
    const selectedVersionData = this.selectedVersionForUpdate;

    const reqBody = {
      "id": selectedVersionData.id,
      "versionName": this.newVersionName
    };

    this.dataService.saveUpdatedVersionName(reqBody).subscribe(res => {
      const apiResponse = res.body["response"];
      this.utilService.showMessage(this.translateService.getLabel("suc_update_version"), this.translateService.getLabel("ok"));
    });

    this.selectedVersionForUpdate.versionName = this.newVersionName;
    this.valuationDatesGrid.refresh();
    this.utilService.closeAllPopups();
    this.hideUpdateVersionNameDialog();
  }

  openCurrencyDashboardPopup(content) {
    this.modalService.open(content, { centered: true })
  }

  openVersions(comp, content) {
    this.selectedFormVersions = null;

    this.utilService.showLoadingPopup();

    this.dataService.getAllFormsVersions(comp.groupFormId || comp.id, comp.valuationDate).subscribe(res => {
      this.selectedFormVersions = res.body["response"];
      this.utilService.closeAllPopups();

      // console.log("this.selectedFormVersions", this.selectedFormVersions);

      if(this.selectedFormVersions && this.selectedFormVersions.length > 0) {
        this.selectedFormVersions.forEach(form => {
          const details = JSON.parse(form.details);

          if(details) {
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
              form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);

            } else {
              form.companyNameInForm = form.companyName;
            }

            form.editable = comp.editable;

            form.versionName = details["versionName"];
            form.frozen = details["frozen"];
            form.status = details["status"];
            form.currency = details["currency"];
            form.businessUnitsNumber = details["businessUnitsNumber"];

            if(form.businessUnits) {
              this.debtPortfolioService.initBU_ConsolSummary(form);
            }
          }
        })
        this.modalService.open(content, { centered: true })

      } else {
        this.utilService.showMessage(this.translateService.getLabel("err_failed_version_info"), this.translateService.getLabel("ok"), true);
      }

    }, err => {
      this.utilService.showMessage(this.translateService.getLabel("err_failed_version_info"), this.translateService.getLabel("ok"), true);
    })
  }

  selectVersion(comp) {
    let allVersionValuationDates = [];
    this.utilService.showLoadingPopup();
    if (this.isVersionSaved) {
      this.dataService.getAllDebtFormsVersionsForAllValDates(this.primaryCompanyId, this.allValuationDates)
        .then((response: any) => {
          if (response?.body["response"][0]) {
            let allVers = response?.body["response"][0];
            this.allValuationDates.forEach(eachate => {
              if (allVers[eachate]) {
                allVers[eachate].forEach(entry => {
                  allVersionValuationDates.push(entry);
                });
              }
            });
            this.freezeTheVersion(allVersionValuationDates, comp)
          }
        })
    } else {
      this.isVersionSaved = true;
      this.freezeTheVersion(this.allVerAllValDate, comp)
    }
  }

  freezeTheVersion(valuationDates, comp) {
    valuationDates.forEach(form => {
      if(form.valuationDate == comp.valuationDate && form.frozen == true) {
        this.dataService.freezeDebtFormVersion(form.valuationDate, form.id, comp.id).subscribe(res => {
          this.valuationDateGridDataSource.map((valDates: any) => {
            if (valDates.name == comp.valuationDate) {
              let oldFrozenValueIndex = valDates.value.findIndex((oldFrozenValue:any) => oldFrozenValue.id == form.id);
              let newFrozenValueIndex = valDates.value.findIndex((oldFrozenValue:any) => oldFrozenValue.id == comp.id);
              //Update the values
              valDates.value[oldFrozenValueIndex].frozen = false;
              valDates.value[newFrozenValueIndex].frozen = true;
            }
            return valDates;
          })
          console.log("Freezing new form version is done", res);
        }, error =>{
          console.log("Error while freezing form version - " + form.versionName);
          this.utilService.showMessage(this.translateService.getLabel("err_failed_freeze_form") + " - " + form.versionName, this.translateService.getLabel("ok"), true);
        })
      }
    });
    this.utilService.closeAllPopups();
  }

  freezeThisVersion(form) {
    //The version is already frozen
    if(form.frozen || !form.editable) return;

    this.modalService.dismissAll();

    this.utilService.showLoadingPopup();

    const frozenForm = this.selectedFormVersions.find(fv => fv.frozen);

    this.dataService.freezeFormVersion(frozenForm.valuationDate, frozenForm.id, form.id).subscribe(res => {
      // console.log("Freezing new form version is done");

      const dialog = this.utilService.showMessage(this.translateService.getLabel("info_valuation_date_frozen"), this.translateService.getLabel("ok"))
      dialog.afterClosed().subscribe(()=>{

        window.location.reload();
      })
    }, error =>{
      console.log("Error while freezing form version - " + form.versionName);
      this.utilService.showMessage(this.translateService.getLabel("err_failed_freeze_form") + " - " + form.versionName, this.translateService.getLabel("ok"), true);
    })
  }

  deleteCompany(company, allVersionsOfValDate: { id: string }[]): void {
    if(!company.editable) return;

    const isCurrentValDateFrozen = company.frozen;
    const comapnyIdList: string[] = isCurrentValDateFrozen ? 
      allVersionsOfValDate.map((el) => el.id) : 
      [company.id];
    const companyValDate = this.datePipe.transform(company.valuationDate, 'mediumDate');
    let confirmationMessage = this.translateService.getLabel("info_confirm_delete") + " - " + companyValDate;
    if (isCurrentValDateFrozen && allVersionsOfValDate.length > 1)
      confirmationMessage = `
      <strong>${this.translateService.getLabel("info_confirm_delete") + " - " + companyValDate}</strong>
      <br><br>Deleting the concluded version will lead to deletion of other versions
    `;
    const confirm = this.utilService.showConfirmMessage(
      confirmationMessage, 
      this.translateService.getLabel("yes"), 
      this.translateService.getLabel("no")
    );

    confirm.afterClosed().subscribe(confirmation => {
      if(confirmation === "Yes") {

          this.dataService.deleteDebtCompanies(comapnyIdList).subscribe(response => {
            this.modalService.dismissAll();

            this.refreshVersionsList();

            this.utilService.showMessage(this.translateService.getLabel("successfully_deleted") + " - " + companyValDate, this.translateService.getLabel("ok"));
          }, error => {
            console.log("Error While Deleting Companies", error);
            this.modalService.dismissAll();
            this.utilService.showMessage(this.translateService.getLabel("err_failed_delete") + " - " + companyValDate + ". " + this.translateService.getLabel("info_try_after_sometime") + ".", this.translateService.getLabel("ok"), true);
          });
      }
    });
  }

  openPopupModal(content, index) {
    this.modalService.open(content, { centered: true });
    this.editedValuationDateIndex = index;
    // console.log(index);
  }

  addNewValuationDate(editValuationDateModal){
    let companyId = this.selectedCompanyDates[this.editedValuationDateIndex].valuationDateId;
    let formattedDate = moment(this.editedValuationDate).format("YYYY-MM-DD");
    // console.log(formattedDate);
    // console.log(companyId);
    let reqBody ={
      companyId: companyId,
      valuationDate: formattedDate
    };
    this.dataService.addNewValuationDate(reqBody).subscribe(result=>{
      // console.log("New Valuation date added successfully");
      this.selectedCompanyDates[this.editedValuationDateIndex].valuationDate = this.editedValuationDate;
      editValuationDateModal.dismiss('Cross click');
    },error=>{
      console.log("Error while adding Valuation date.")
    });

  }

  showByTopMenu(ui, comp?) {
    this.showConsolSummary = false;
    this.showUserValDates = false;
    this.showValDates = false;
    this.showInvestment = false;

    if(ui === "vd") {
      this.showValDates = true;
    } else if(ui === "userVD") {
      this.showUserValDates = true;
    } else if(ui === "consol") {
      if(comp){
        this.selectedCompanyConsolForm = comp //when top menu is clicked loading latestValuationDate Consol Summary
      }
      this.showConsolSummary = true;
    } else if(ui === 'INVESTMENT') {
      this.showInvestment = true;
    }
  }

  // ----  ----  ----  ----  ----  ----  ----  ----  ----
  // ---- Valuation Date Share to external user ----
  openValuationSharePopUp(form, content) {
    this.selectedFormToShare = form;

    this.modalService.open(content, { centered: true })
  }

  share(popUp) {
    // console.log(this.shareInput);

    if (this.shareInput.users && this.shareInput.users.length > 0 && this.isValidShareInput(this.shareInput.users)) {

        const selectedTabs = this.shareInput.sharePermissions.filter(tab => tab['selected'] )
        if(selectedTabs.length > 0) {
          this.shareInput['sharedTabNames'] = selectedTabs.map( tab => tab.label).join(", ");
        } else {
          //No tabs are selected. Stay on the Input Popup.
          return;
        }

        popUp.dismiss('Cross click');

        this.shareInput["valuationDate"] = this.datePipe.transform(this.selectedFormToShare.valuationDate, "MMM dd, yyyy");
        this.shareInput["companyName"] = this.newCompany.name;

        this.dataService.shareFormToExternalUser(this.selectedFormToShare.id, this.shareInput).subscribe(res => {

          let shareInputUserNames = this.shareInput.users.map(obj=>obj.name).toString();
          this.utilService.showMessage(this.translateService.getLabel("valuation_date") + " (" + this.selectedFormToShare.valuationDate + ") " + this.translateService.getLabel("is_shared_to") + " " + shareInputUserNames, this.translateService.getLabel("ok"));
        }, error => {

          this.utilService.showMessage(this.translateService.getLabel("err_failed_share") + " (" + this.selectedFormToShare.valuationDate + ")", this.translateService.getLabel("ok"), true);
        })

    } else {
      // Stay on the Input Popup.
    }
  }

  isValidShareInput(users) {
    users.forEach(obj => {
      if (!(obj.name.length > 3 && obj.email.length > 3)) {
        return false;
      }
    });
    return true;
  }

  selectedFormToShare;

  shareInput = {
    users: [],
    name: null,
    email: null,
    sharePermissions: [
      {
        label: 'Valuation Summary',
        path: '/valuation-summary',
        id: 'valuation-summary',
        selected: false
      },
      {
        label: 'Valuation',
        path: '/valuation',
        id: 'valuation',
        selected: false
      },
      {
        label: 'Form',
        path: '/form',
        id: 'form',
        hide: true,
        selected: false
      },
      {
        label: 'Valuation Tracker',
        path: '/valuation-tracker',
        id: 'valuation-tracker',
        hide: true,
        selected: false
      },
      {
        label: 'Benchmarking',
        path: '/benchmarking',
        id: 'benchmarking',
        selected: false
      },
      {
        label: 'KPI',
        path: '/kpi',
        id: 'kpi',
        selected: false
      },
      {
        label: 'Additional Analysis',
        path: '/additionalAnalysis',
        id: 'additionalAnalysis',
        hide: true,
        selected: false
      },
      {
        label: 'Calibration System',
        path: '/caliberationSystem',
        id: 'caliberationSystem',
        selected: false
      },
      {
        label: 'Cap Table',
        path: '/capTable',
        id: 'capTable',
        selected: false
      },
      {
        label: 'Waterfall Analysis',
        path: '/cca',
        id: 'cca',
        selected: false
      },
      {
        label: 'Files',
        path: '/files',
        id: 'files',
        selected: false
      },
      {
        label: 'Audit Trail',
        path: '/auditTrail',
        id: 'auditTrail',
        selected: false
      },
      {
        label: 'News Analysis',
        path: '/reviewAnalysis',
        id: 'reviewAnalysis',
        hide: true,
        selected: false
      },
      {
        label: 'ECT Analysis',
        path: '/ectAnalysis',
        id: 'ectAnalysis',
        hide: true,
        selected: false
      }
    ]
  }

  checkUncheckAll(){
    if (this.shareInput.sharePermissions.every(val => val.selected == true))
      this.shareInput.sharePermissions.forEach(val => { val.selected = false });
    else
      this.shareInput.sharePermissions.forEach(val => { val.selected = true });
  }

  addShareInputs(){
    if(this.shareInputName && this.shareInputEmail
      && this.shareInputName.length >= 3 && this.shareInputEmail.length >= 3)
    {
      this.shareInput.users.push({ name: this.shareInputName, email: this.shareInputEmail});

      this.shareInputName = "";
      this.shareInputEmail = "";
    }
  }

  removeShareInputs(index) {
    this.shareInput.users.splice(index, 1);
  }

  openValuationDatePopup(content){
    this.modalService.open(content, { centered: true, size: "lg",windowClass: 'center-popup-modal'})
  }

  submitRollover(payload) {
    this.dataService.saveCommonRollover(payload).subscribe();
  }

}
