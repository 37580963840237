import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApprovalSystemService } from "src/app/services/approval-system.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from "src/app/services/data.service";
import { TranslateService } from "src/app/services/translation.service";
import { UserManagementService } from "src/app/services/user-management.service";
import { UtilService } from "src/app/utils/util.service";
import { ApprovalMatrixService } from "../../check-list-pop-up/approval-matrix/approval-matrix.service";
import { InvestmentSummaryService } from "../../investment-page-summary-v2/investment-summary.service";

@Component({
  selector: "app-new-approval-requests",
  templateUrl: "./new-approval-requests.component.html",
  styleUrls: ["./new-approval-requests.component.scss"]
})
export class NewApprovalRequestsComponent implements OnInit {

  @Input() item;
  @Output() reloadValDates = new EventEmitter();

  constructor(
    public approvalMatrixService: ApprovalMatrixService,
    public translateService: TranslateService,
    private modalService: NgbModal,
    public ums: UserManagementService,
    public approvalSystemService: ApprovalSystemService,
    public invSummaryService : InvestmentSummaryService,
    private utilService: UtilService,
    private ds: DataService
  ) {}

  ngOnInit() {
  }

  openPopupModal(content, data){

    if(!data.selectedCondition || !data.selectedCondition.id || data.selectedCondition.conditionName == "None"){
      return;
    }

    this.modalService.open(content, { centered: true , windowClass: 'modal-xl', size: 'md'});
  }

  async updateOnAction(data){

    this.utilService.showLoadingPopup();

    const userDetails = this.ums.getSelectedUserDetails();

    // checking whether the document/version has ever recalled/unlocked in the past
    let actionTypeBasedOnDocHistory: any = null;

    if(data.actionName == 'Initiate'){
      try{
        const res = (await this.ds.getDocumentRecalledStatus(data.id).toPromise() as any).body.response;
        if(!!res){
          actionTypeBasedOnDocHistory = res.isActionType;
        }
      }catch(err){
        console.log("Failed to get the Document recalled status: ", err);
        actionTypeBasedOnDocHistory = null;
      }
    }

    const reqBody = {
      "module": "EQUITY",
      "orgId": userDetails.organization.id,
      "fundId": data.fundCompany,
      "companyId": data.groupFormId ? data.groupFormId : data.id,
      "userId": userDetails.id,
      "approvalObjectId": data.id,
      "comments": data.comment ? data.comment : '',
      "action": data.actionName,
      "conditionId": data.selectedCondition.id,
      "existingApprovalObjectId": !!actionTypeBasedOnDocHistory ? data.id : null,
      "isActionType": actionTypeBasedOnDocHistory
    }

    this.ds.updateApprovalObject(reqBody).subscribe( res => {
      console.log("Aprroval has been updated!", res);

        this.reloadValDates.emit();

    }, error => {

      console.log("Aprroval failed to update: ", error);

    })
  }

}
