<nav-menu-header headerLabel="Replicate Funds"></nav-menu-header>

<div class="prospects-report">
  <div class="mat-elevation-z8">
    <div class="row" style=" margin: 10px; margin-bottom: 1%;">
      <div class="col">
        <span class="main-heading">Replicate Funds</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="row select-fund-row">
          <span>Select Funds</span>
        </div>
        <div class="row input-box-row">
          <form>
            <input class="select-copy-fund"
            type="text"
            placeholder="Search Organization"
            [matAutocomplete]="autoSelectOrg"
            (input)="filterOrgs($event)"
            >
            <mat-autocomplete #autoSelectOrg="matAutocomplete">
              <mat-option *ngFor="let org of filteredOrgs" [value]="org.orgName" style="font-size: 11px; height: 35px !important;" (onSelectionChange)="selectOrganization(org)">
                  {{org.orgName}} - {{org.createdDate | date: "mediumDate"}}
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
        <div class="row input-box-row">
          <form>
            <input class="select-copy-fund"
            type="text"
            placeholder="Search Funds"
            [matAutocomplete]="autoFund"
            (input)="filterFunds($event)"
            >
            <mat-autocomplete #autoFund="matAutocomplete">
              <mat-option *ngFor="let fund of filteredFunds" [value]="fund.name" style="font-size: 11px; height: 35px !important;" (onSelectionChange)="selectFund(fund)">
                  {{fund.name}}
              </mat-option>
            </mat-autocomplete>
          </form>
        </div>
      </div>
      <div class="col-3">
        <div class="row select-fund-row">
          <span>Copy To</span>
        </div>

        <div class="row input-box-row">
          <input class="select-copy-fund"
            type="text"
            placeholder="Search Organization"
            [matAutocomplete]="autoCopyOrg"
            (input)="filterOrgs($event)"
          >
          <mat-autocomplete #autoCopyOrg="matAutocomplete">
            <mat-option *ngFor="let org of filteredOrgs" [value]="org.orgName" style="font-size: 11px; height: 35px !important;" (click)="selectCopyToOrg(org)">
                {{org.orgName}} - {{org.createdDate | date: "mediumDate"}}
            </mat-option>
          </mat-autocomplete>
        </div>
        
        <div class="row input-box-row">
          <input class="select-copy-fund"
            type="text"
            placeholder="Search User"
            [matAutocomplete]="autoCopyUser"
          >
          <mat-autocomplete #autoCopyUser="matAutocomplete">
            <mat-option *ngFor="let u of usersInSelectedOrg" [value]="u.userName" style="font-size: 11px; height: 35px !important;" (click)="selectCopyToUser(u)">
                {{u.userName}} - {{u.userId}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div class="row input-box-row">
          <input class="select-copy-fund"
            type="text"
            placeholder="Fund Name"
            [(ngModel)]="selectedFundName"
          >
        </div>
      </div>
      <div class="col-1">
        <button
        [ngClass]="{'button copy-button-disabled' : selectedOrg === undefined || selectedFund === undefined || selectedCopyToOrg === undefined || !userCopyTo, 'button copy-button-enabled pointer': selectedOrg !== undefined && selectedFund !== undefined && selectedCopyToOrg !== undefined || userCopyTo}"
        (click)="copyToOrg()" [disabled]="selectedOrg === undefined || selectedFund === undefined || selectedCopyToOrg === undefined || !userCopyTo">Copy</button>
      </div>
    </div>
    <div class="row status-row">
      <span>Status</span>
    </div>
  
    <div class="row table-row">
      <div class="col util-lis-grid">
        <ejs-grid #usersListGrid [dataSource]="statusSource" [allowResizing]="true" [allowTextWrap]="true"
          [allowSorting]="true" [allowReordering]="true" [allowPaging]="true" [showColumnChooser]="true" [filterSettings]="gridFilterSettings"
          [allowFiltering]="true"
          height=300>
          <e-columns>
            <e-column field='sourceFundName' headerText='Source Fund' width='100px'></e-column>
            <e-column field='targetFundName' headerText='Target Fund' width='100px'></e-column>
            <e-column field='sourceOrgName' headerText='Copied From' width='100px'></e-column>
            <e-column field='targetOrgName' headerText='Copied To' width='100px'></e-column>
            <e-column field='createdDate' headerText='Timestamp' width='150px'>
              <ng-template #template let-data>
                {{data.createdDate | date:'medium'}}
              </ng-template>
            </e-column>
            <e-column field='superAdminName' headerText='Super Admin' width='100px'></e-column>
            <e-column field='cloneStatus' headerText='Equity Status' width='100px'></e-column>
            <e-column field='errorStack' headerText='Equity Error' width='110px'></e-column>
            <e-column field='debtCloneStatus' headerText='Debt Status' width='100px'></e-column>
            <e-column field='debtErrorStack' headerText='Debt Error' width='110px'></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</div>