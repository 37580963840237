import { Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { TranslateService } from 'src/app/services/translation.service';
import { MappingMgmtConfigService } from './mapping-mgmt-config.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mapping-mgmt-container',
  templateUrl: './mapping-mgmt-container.component.html',
  styleUrls: ['./mapping-mgmt-container.component.scss'],
})
export class MappingManagementContainerComponent implements OnDestroy {
  private commonSub = new Subscription();

  constructor(
    private mappingMgmtConfigService: MappingMgmtConfigService,
    private router: Router,
    public translateService : TranslateService
  ) {
    this.commonSub.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((e: NavigationEnd) => {
          this.mappingMgmtConfigService.setCurrentRoute(e.urlAfterRedirects);
        })
    );
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
