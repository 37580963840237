<nav-menu-header headerLabel="Profiler - Personalized AI"></nav-menu-header>
<div class="block">
  <div class="card block-card">
    <div class="card-header">
      Profiler - Personalized AI
    </div>
    <div class="card-body block-card-body">

        <div class="row">
          <div class="col-2 profile-image-col">
            <div class="profile-image-container m-auto"> 
              <img class="logo-preview-image" [src]="logoImage" *ngIf="logoImage;else defaultLogo" />
              
              <ng-template #defaultLogo>
                <!-- <span class="user-icon m-auto"> -->
                  <i class="fas fa-landmark"></i>
                <!-- </span>   -->
              </ng-template>
            </div>
            <ng-template [ngIf]="editable">
              <input type="file" accept="image/*" #logoInput class="file-input" (change)="pickImage($event)" />
              <button class="btn upload-btn" (click)="pickFile(logoInput)">
                <span class="upload-file-icon">
                  <i class="fa fa-upload"></i>
                </span>
                Upload
              </button>
            </ng-template>
          </div>
          <div class="col">
            <div class="float-right">
              <span (click)="editable = true" class="edit-icon" *ngIf="!editable && isMasterUser">
                <i class="fas fa-pen"></i> Edit
              </span>
              <span (click)="save()" class="save-icon" *ngIf="editable">
                <i class="far fa-save"></i> Save
              </span>
            </div>
            
            <ng-template [ngIf]="!editable">
              <h2 class="profile-name">
                {{profile.name}}
              </h2>
            </ng-template>
            <mat-form-field *ngIf="editable">
              <input matInput [(ngModel)]="profile.name" type="text">
            </mat-form-field>
            
            <div class="row social-icon-row">
              <div class="icon-col" *ngFor="let socialIcon of profile.socialIcons; let iconIndex=index">
                <a style="text-decoration:none; cursor: pointer;" *ngIf="!editable && socialIcon.link && socialIcon.link.length>0" (click)="openUrl(socialIcon.link)">
                  <i [class]="socialIcon.icon + ' circle-icon'" [style.background]="socialIcon.background"></i>
                </a>
                <ng-template [ngIf]="editable">
                  <mat-form-field>
                    <span matPrefix><i [class]="socialIcon.icon" style="padding-right: 5px;"></i></span>
                    <input matInput [(ngModel)]="socialIcon.link" type="text">
                  </mat-form-field>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="row key-value-row">
          <div class="col-2 key">
            Headquarters
          </div>
          <div class="col value">
            <ng-template [ngIf]="!editable">
              {{profile.location}}
            </ng-template>
            <mat-form-field *ngIf="editable">
              <input matInput [(ngModel)]="profile.location" type="text">
            </mat-form-field>
          </div>
        </div>
        <div class="row key-value-row">
          <div class="col-2 key">
            Founded In
          </div>
          <div class="col value">
            <ng-template [ngIf]="!editable">
              {{profile.foundedIn|date}}
            </ng-template>
            <mat-form-field *ngIf="editable" (click)="myDatepicker.open()">
              <input matInput [matDatepicker]="myDatepicker" readonly class="date-field" [max]="today" [(ngModel)]="profile.foundedIn">
              <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #myDatepicker class="datepicker"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row key-value-row">
          <div class="col-2 key">
            About
          </div>
          <div class="col value">
            <ng-template [ngIf]="!editable">
              {{profile.about}}
            </ng-template>
            <mat-form-field *ngIf="editable" class="text-area-field">
              <textarea matInput rows="5" [(ngModel)]="profile.about"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row key-value-row">
          <div class="col-2 key">
            Team
          </div>
          <div class="col value">
            <div class="row" style="margin: 10px 0">
              <div class="col" style="padding-left: 0"> Name <span style="color: red"> * </span></div>              
              <div class="col"> LinkedIn Profile URL <span style="color: red"> * </span></div>              
              <div class="col-1"> </div>
            </div>

            <div class="row" *ngFor="let member of profile.team; let index=index" style="margin: 10px 0">
              <div class="col" style="padding-left: 0">
                <ng-template [ngIf]="!editable"> {{member.name}} </ng-template>
                <mat-form-field *ngIf="editable">
                  <input matInput type="text" [(ngModel)]="member.name">
                </mat-form-field>
              </div>
              
              <div class="col">
                <ng-template [ngIf]="!editable"> {{member.link}} </ng-template>
                <mat-form-field *ngIf="editable">
                  <input matInput type="text" [(ngModel)]="member.link">
                </mat-form-field>
              </div>

              <div class="col-1" style="padding-top: 5px">
                <span (click)="removeNewMember(index)" class="edit-icon" *ngIf="editable">
                  <i class="fas fa-minus-circle"></i>
                </span>
              </div>
            </div>

            <div class="row" style="margin: 10px 0" *ngIf="editable">
              <div class="col" style="padding-left: 0">
                <mat-form-field>
                  <input matInput type="text" [(ngModel)]="newMember.name" placeholder="Name shall be minimum 3 characters long." [disabled]="!editable">
                </mat-form-field>
              </div>
              
              <div class="col">
                <mat-form-field>
                  <input matInput type="text" [(ngModel)]="newMember.link" [disabled]="!editable" placeholder="Enter Valid linkedIn URL.">
                </mat-form-field>
              </div>
              
              <div class="col-1" style="padding-top: 5px">
                <span (click)="addNewMember()" class="edit-icon">
                  <i class="fas fa-plus-circle"></i> Add
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row key-value-row">
          <div class="col-2 key">
            Country
          </div>
          <div class="col value">
            
            <mat-chip-list *ngIf="!editable">
              <mat-chip *ngFor="let value of regionInputValues; let index=index">
                {{value.name}}
              </mat-chip>
            </mat-chip-list>

            <ng-template [ngIf]="editable">
              Start typing to see suggestions<br/>

              <mat-form-field class="text-form-field" [class.focus-text]="focused == true">

                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let value of regionInputValues; let index=index" 
                    [selectable]="selectable" 
                    [removable]="removable" 
                    (removed)="remove(value, index)">
                    {{value.name}}
                    <mat-icon matChipRemove *ngIf="removable" class="close-mat-icon">close</mat-icon>
                  </mat-chip>
                
                  <input matInput type="text"
                      [ngModel]="regionInputValues[0]?.name" [matAutocomplete]="auto" 
                      (ngModelChange)="regionInputChange($event)" 
                      [matChipInputFor]="chipList" 
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      [matChipInputAddOnBlur]="addOnBlur" 
                      (matChipInputTokenEnd)="addRegion($event)" #ref>
                </mat-chip-list>
                  
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions" [value]="option.name" (click)="addRegionFromPopup(option)">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

            </ng-template> 
          </div>
        </div>

        <div class="row key-value-row">
          <div class="col-2 key">
            Save Company Labels
          </div>
          <div class="col value">
            
            <mat-chip-list *ngIf="!editable">
              <mat-chip *ngFor="let value of companyLabels; let index=index">
                {{value.label}}
              </mat-chip>
            </mat-chip-list>

            <ng-template [ngIf]="editable">
              <ng-template ngFor [ngForOf]="companyLabels" let-value let-index="index">
                <div class="row" style="margin: 10px 0" *ngIf="!value.delete">
                  <div class="col" style="padding-left: 0">
                    <mat-form-field>
                      <input matInput type="text" [(ngModel)]="value.label" [disabled]="!editable" placeholder="Update the label.">
                    </mat-form-field>
                  </div>
                  
                  <div class="col" style="padding-top: 5px">
                    <span (click)="removeLabel(value, index)" class="edit-icon">
                      <i class="fas fa-minus-circle"></i>
                    </span>
                  </div>
                </div>
              </ng-template>
              
              <div class="row" style="margin: 10px 0">
                <div class="col" style="padding-left: 0">
                  <mat-form-field>
                    <input matInput type="text" [(ngModel)]="newLabel" [disabled]="!editable" placeholder="Add a label.">
                  </mat-form-field>
                </div>
                
                <div class="col" style="padding-top: 5px">
                  <span (click)="addNewLabel()" class="edit-icon">
                    <i class="fas fa-plus-circle"></i> Add
                  </span>
                </div>
              </div>
            </ng-template> 
          </div>
        </div>
        
        <div class="row key-value-row">
          <div class="col-2 key">
            Currency
          </div>
          <div class="col value">           
            <div *ngIf="!editable && profile.currency && profile.currency['id']">{{profile.currency.id + ' - ' + profile.currency.country}}</div>

            <mat-form-field style="width: 50%;" *ngIf="editable">
              <input type="text" matInput [ngModel]="currencySelection" [matAutocomplete]="autoCurrency" (input)="currencySearch($event)">
              <mat-autocomplete #autoCurrency="matAutocomplete" (optionSelected)="currencySelected($event.option.value)">
                <mat-option *ngFor="let cur of filteredCurrencies" [value]="cur.id">{{cur.id + ' - ' + cur.country}}</mat-option>
              </mat-autocomplete>
            </mat-form-field> 
          </div>
        </div>
    </div>
  </div>
</div>