<ng-template ngFor [ngForOf] = "selectedBusinessUnits" let-bu let-buI="index">

    <ng-template [ngIf] = "getWhetherToViewTheBU(bu)" >
      <div class="row" style="margin: 10px;" [class.consol-form]="bu.businessUnits">
        <div class="col"> 
          {{bu.companyNameInForm || bu.businessUnitName || bu.companyName}}
          <div style="font-size: 12px;">{{bu.versionName}} | {{bu.valuationDate | date: "mediumDate"}}</div> 
        </div>
  
        <div class="col-4">
          
          <ng-template [ngIf]="bu.investment">
            {{bu.investment.currency}}
  
            <ng-template [ngIf]="bu.investment.enterpriseValue">
              {{bu.investment.enterpriseValue | number: "1.1-1"}} Mn
            </ng-template>
            <!-- <ng-template [ngIf]="!bu.investment.equityValue.finalStakeValue">
              {{bu.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
            </ng-template> -->
          </ng-template>
        </div>
        
        <div class="col-3">
          <i class="far fa-edit primary-color-medium action-icon" (click)="emitTheActionType('VIEW_SAF', bu);" 
          *ngIf="mode == 'EDIT_TYPE'"></i> &nbsp;&nbsp; <!-- && isCurrencyAvailable(bu) !== 'NotAvailable' -->
  
          <i class="fas fa-binoculars primary-color action-icon" (click)="emitTheActionType('VIEW_VALUATION', bu);" 
          *ngIf="mode == 'VIEW_TYPE'"></i>
          
          <i class="fas fa-share-alt action-icon primary-color-medium" *ngIf="mode == 'SHARE_TYPE'" aria-hidden="true" 
          (click)="emitTheActionType('VIEW_SHARE', bu)"></i>
        </div>
      </div>
    </ng-template>
  </ng-template>