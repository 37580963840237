
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Chart } from 'angular-highcharts';
import { MapChart } from 'angular-highcharts';
import worldMap from '../world-data.data';

import { UserManagementService } from 'src/app/services/user-management.service';
import { colors } from 'src/app/utils/colors';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataService } from 'src/app/services/data.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import moment from 'moment';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { MessageService } from 'src/app/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from 'src/app/services/translation.service';
import { PortfolioUtilService } from '../portfolio-util.service';
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import { DatePipe } from '@angular/common';
import { DrillDownService } from 'src/app/drill-down.service';
import { environment } from 'src/environments/environment';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-equity-portfolio',
  templateUrl: './equity-portfolio.component.html',
  styleUrls: ['./equity-portfolio.component.scss']
})
export class EquityPortfolioComponent implements OnInit {
  portfolio: any = {
    current : {
      expandPortfolio: true,
      totalPreviousMOIC: 0,
      totalChangeMOIC: 0
    },
    exit: {
      expandPortfolio: false,
      totalPreviousMOIC: 0,
      totalChangeMOIC: 0
    }
  }
  multipleBusinessUnitExists = false;
  currentPortfolioCompanies = [];
  exitedPortfolioCompanies = [];
  portfolioData = [];

  showHideColumns = {
    "reportingCurrencyCurrentPortfolio": {
      geography: false,
      sector: false,
      security: false,
      exit_date: false,
      changeInMOIC_valuesInPortfolioCurrency: false,
      fullyDilutedStake_valuesInPortfolioCurrency:false
    },
    "reportingCurrencyExitedPortfolio": {
      geography: false,
      sector: false,
      security: false,
      changeInMOIC_valuesInPortfolioCurrency: false,
      fullyDilutedStake_valuesInPortfolioCurrency:false
    },
    "localCurrencyCurrentPortfolio": {
      geography: false,
      sector: false,
      security: false,
      exit_date: false,
      changeInMOIC_valuesInLocalCurrency: false,
      fullyDilutedStake_valuesInLocalCurrency:false
    },
    "localCurrencyExitedPortfolio": {
      geography: false,
      sector: false,
      security: false,
      changeInMOIC_valuesInLocalCurrency: false,
      fullyDilutedStake_valuesInLocalCurrency:false,
    },
  }

  currentPortfolioTotals:any = {};
  exitedPortfolioTotals:any = {};
  totalAggregations: any = {};

  allCompaniesList = [];
  allValDates = [];
  
  selectedValDate = "latest_valuation_date";

  mapChart;
  columnChart;

  portfolioViewMode = "both";
  isCompanySelected = false;

  @ViewChild('downloadExcelPortfolio', { static: true }) downloadLink: ElementRef;

  @ViewChild("portfolioSummaryGrid") 
  public portfolioSummaryGrid: GridComponent; 

  constructor(private ums: UserManagementService, private router: Router,
    public portfolioService: PortFolioSummaryServiceV2,
    public utilService: UtilService, public currencyService: CurrencyExchangeService,
    private ds: DataService, private modalService: NgbModal, 
    public fundService: FundListService, 
    private ms: MessageService, public translateService : TranslateService,
    private portfolioUtil : PortfolioUtilService,
    private invSummaryService : InvestmentSummaryService,
    private datePipe: DatePipe, public drillDownService: DrillDownService) { 
      this.ds.getWidgetDataFromDB("PORTFOLIO_SUMMARY_GRID_COL_SELECTION", this.portfolioService.selectedFundId).subscribe(res => {
      const widgetData = res.body["response"][0].widgetData; 
      if(widgetData){
      this.showHideColumns = widgetData;
      }
      },
      (error) => {
        console.log("PORTFOLIO_SUMMARY_GRID_COL_SELECTION", error);
      })
    }

  async ngOnInit() {
    this.portfolioService.companiesAvailable$.subscribe(async ()=> {
      await this.init();
    })

    if(this.portfolioService.summary[this.portfolioService.selectedFundId]) {
      await this.init();
    }

    this.ms.subscribe("REPORTING_CURRENCY_CHANGE_DEBT", (change) => {
      if(change == true) {
        this.changeInFundCurrency(this.portfolioService.selectedCurrency);
      }
    });

    this.ds.getDistinctValDates([this.portfolioService.selectedFundId]).subscribe(res => {
      const apiResponse = res.body["response"];
      this.allValDates = ["latest_valuation_date"]
      if(apiResponse){
        apiResponse.forEach(a => this.allValDates.push(a))
      }
      console.log("this.allValDates", this.allValDates)
    }, error => {
      console.log("Failed to fetch Distinct valuation dates");
    })
  }

  async init() {
    this.currentPortfolioCompanies = this.portfolioService.summary[this.portfolioService.selectedFundId].currentPortfolio.portfolioData;
    this.currentPortfolioCompanies.sort((c1, c2) => c1.investmentDate > c2.investmentDate ? -1: 1);

    this.currentPortfolioCompanies.forEach(form =>{
      if(form.sector && form.sectorFull){
        const translatedSector = this.translateService.getLabel("SAF_industryType_" + form.sectorFull);
        form.sector = translatedSector.split(",")[0];
      }
      if(form.geography && form["geography-cbAlias"]){
        const translatedGeography = this.translateService.getLabel("country_" + form["geography-cbAlias"]);
        form.geography = translatedGeography;
      }
    });

    this.exitedPortfolioCompanies = this.portfolioService.summary[this.portfolioService.selectedFundId].realisedPortfolio.portfolioData;
    this.exitedPortfolioCompanies.sort((c1, c2) => c1.investmentDate > c2.investmentDate ? -1: 1);

    this.exitedPortfolioCompanies.forEach(form =>{
      if(form.sector && form.sectorFull){
        const translatedSector = this.translateService.getLabel("SAF_industryType_" + form.sectorFull);
        form.sector = translatedSector.split(",")[0];
      }
      if(form.geography && form["geography-cbAlias"]){
        const translatedGeography = this.translateService.getLabel("country_" + form["geography-cbAlias"]);
        form.geography = translatedGeography;
      }
    });

    this.addTooltips(this.currentPortfolioCompanies, "valuesInPortfolioCurrency");
    this.addTooltips(this.currentPortfolioCompanies, "valuesInLocalCurrency");
    this.addTooltips(this.exitedPortfolioCompanies, "valuesInPortfolioCurrency");
    this.addTooltips(this.exitedPortfolioCompanies, "valuesInLocalCurrency");

    this.currentPortfolioTotals = this.portfolioService.summary[this.portfolioService.selectedFundId].currentPortfolio.aggregations;
    this.exitedPortfolioTotals = this.portfolioService.summary[this.portfolioService.selectedFundId].realisedPortfolio.aggregations;
    this.totalAggregations = this.portfolioService.summary[this.portfolioService.selectedFundId].totalAggregations;

    this.zeroInForOneDecimalFormat();
    
    this.currentPortfolioTotals.name = this.translateService.getLabel("current_portfolio");
    this.currentPortfolioTotals.key = "current";
    
    this.currentPortfolioTotals.expandPortfolio = true;
    this.portfolio.current = this.currentPortfolioTotals;


    this.exitedPortfolioTotals.name = this.translateService.getLabel("realised_portfolio");
    this.exitedPortfolioTotals.key = "exit";
    
    this.exitedPortfolioTotals.expandPortfolio = false;
    this.portfolio.exit = this.exitedPortfolioTotals;

    this.allCompaniesList = this.currentPortfolioCompanies.concat(this.exitedPortfolioCompanies).map(c => {
      return {
        name: c.companyName,
        id: c.id,
        logo: c.logo,
        prevValuationDate : c.prevValuationDate ? c.prevValuationDate : c.investmentDate
      }
    })

    this.columnChart = this.prepareColumnChartData(this.currentPortfolioCompanies.concat(this.exitedPortfolioCompanies));
    this.mapChart = this.prepareMapInformation(this.currentPortfolioCompanies.concat(this.exitedPortfolioCompanies));

    await this.initAllIRR();
    this.preparePrecentageChangeInMOIC()
    this.prepareFullyDilutedStakeValueRequest()
  }

  addTooltips(allComps, currencyKey) {    
    // adding tooltip text for change NAV from prev valuation date.
    allComps.forEach(comp => {
      if(comp.prevValuationDate) {
        let tooltip = comp[currencyKey].changeInValuationPercentage + " %"

          + "\n" + this.translateService.getLabel("latest_ValuationDate_Label")
          + ": " + this.datePipe.transform(comp.latestValuationDate, "mediumDate")
          + "\n" + this.translateService.getLabel("latest_value")
          + ": " + comp[currencyKey].stakeValue

          + "\n" + this.translateService.getLabel("previous_val_date")
          + ": " + this.datePipe.transform(comp.prevValuationDate, "mediumDate")
          + "\n" + this.translateService.getLabel("previous_value")
          + ": " + comp[currencyKey].prevStakeValue;

        comp[currencyKey].changeInValuationTooltip = tooltip;

      } else {
        comp[currencyKey].changeInValuationTooltip = comp[currencyKey].changeInValuationPercentage;
      }
    });
  }

  zeroInForOneDecimalFormat() {
    this.currentPortfolioCompanies.forEach(comp => {
      comp.valuesInLocalCurrency.changeInValuationPercentage = this.utilService.zeroInForOneDecimalFormat(comp.valuesInLocalCurrency.changeInValuationPercentage);
      
      comp.valuesInPortfolioCurrency.changeInValuationPercentage = this.utilService.zeroInForOneDecimalFormat(comp.valuesInPortfolioCurrency.changeInValuationPercentage);
    })

    this.exitedPortfolioCompanies.forEach(comp => {
      comp.valuesInLocalCurrency.changeInValuationPercentage = this.utilService.zeroInForOneDecimalFormat(comp.valuesInLocalCurrency.changeInValuationPercentage);
      
      comp.valuesInPortfolioCurrency.changeInValuationPercentage = this.utilService.zeroInForOneDecimalFormat(comp.valuesInPortfolioCurrency.changeInValuationPercentage);
    })

    this.currentPortfolioTotals.totalChangeInValPercentage = this.utilService.zeroInForOneDecimalFormat(this.currentPortfolioTotals.totalChangeInValPercentage);
    this.exitedPortfolioTotals.totalChangeInValPercentage = this.utilService.zeroInForOneDecimalFormat(this.exitedPortfolioTotals.totalChangeInValPercentage);
    this.totalAggregations.totalChangeInValPercentage = this.utilService.zeroInForOneDecimalFormat(this.totalAggregations.totalChangeInValPercentage);
  }

  summaryTableCreated() {
    this.portfolioSummaryGrid.detailRowModule.expand(0);
  }

  excelExport(localCurrency?) {
    let fileData = this.getExcelDownLoadRequestBody(localCurrency);

    this.ds.downloadExcelFileV2(fileData).subscribe((fileData) => {
      this.manageFileDownload(fileData, "xlsx");
    }, err => {
      console.log("Failed to download file", err)
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    });
  }

  getExcelDownLoadRequestBody(localCurrency?) {

    let headers = [];
    const values = [];

    if(localCurrency){
      values.push([this.getBlankCell(), this.getHeaderCell("Name"), this.getHeaderCell("Investment Date"), this.getHeaderCell("Valuation Date"), this.getHeaderCell("Exit Date"), this.getHeaderCell("Currency"), this.getHeaderCell("Stake (%)", "right"),
      this.getHeaderCell("Investment Amount", "right"), this.getHeaderCell("Realised Proceeds", "right"), this.getHeaderCell("NAV", "right"), this.getHeaderCell("Total Value", "right"), this.getHeaderCell("Gross IRR (%)", "right"), this.getHeaderCell("MOIC (x)", "right"), this.getHeaderCell("% Change in MOIC", "right"), this.getHeaderCell("From Last Val Date (%)", "right")])

      values.push([this.getBlankCell(), {"type": "text", "value": "Local Currency - Current Portfolio", "bold": true, "fontSize": 11}, this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);

      this.prepareExcelRowsForCompsInLocalCurrency(this.currentPortfolioCompanies, values, true)

      if(this.exitedPortfolioCompanies.length > 0){
        values.push([this.getBlankCell(), {"type": "text", "value": "Local Currency - Realised Portfolio", "bold": true, "fontSize": 11}, this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);
  
        this.prepareExcelRowsForCompsInLocalCurrency(this.exitedPortfolioCompanies, values, false)
      }
    }
    else{
      values.push([this.getBlankCell(), this.getHeaderCell("Name"), this.getHeaderCell("Investment Date"), this.getHeaderCell("Valuation Date"), this.getHeaderCell("Exit Date"), this.getHeaderCell("Currency"), this.getHeaderCell("Stake (%)", "right"),
      this.getHeaderCell("Investment Amount", "right"), this.getHeaderCell("Realised Proceeds", "right"), this.getHeaderCell("NAV", "right"), this.getHeaderCell("Total Value", "right"), this.getHeaderCell("Gross IRR (%)", "right"), this.getHeaderCell("MOIC (x)", "right"), this.getHeaderCell("% Change in MOIC", "right"), this.getHeaderCell("From Last Val Date (%)", "right")]);

      values.push([this.getBlankCell(), {"type": "text", "value": "Current Portfolio", "bold": true, "fontSize": 11}, this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(),this.getBlankCell()]);

      this.prepareExcelRowsForComps(this.currentPortfolioCompanies, values, "", true);
  
      this.prepareExcelRowsForAggregations("Total (Current)", this.portfolio.current, values, true);
  
      this.prepareExcelRowsForComps(this.exitedPortfolioCompanies, values, "Realised Portfolio", false);
  
      this.prepareExcelRowsForAggregations("Total (Realised)", this.portfolio.exit, values, true);
            
      this.prepareExcelRowsForAggregations("Total (Current + Realised)", this.totalAggregations, values, true);
    }

    return [{ "tabName": "Portfolio", "data": {"headers": headers, "values": values} }];
  }

  getHeaderCell(columnName, alignment?){
    if(alignment){
      const obj = {
        "type": "text",
        "value": columnName,
        "background": "D3CFFF",
        "color": "1D1563",
        "bold": true,
        "alignment": alignment,
        "fontSize": 12
      }
      return obj
    }
    else{
      const obj = {
        "type": "text",
        "value": columnName,
        "background": "D3CFFF",
        "color": "1D1563",
        "bold": true,
        "fontSize": 12
      }
      return obj
    }
  }

  getBlankCell(aggregations?) {
    if(aggregations){
      const obj = {
        "type": "text",
        "value": "",
        "background": "F4F5FD"
      }
  
      return obj;
    }
    else{
      const obj = {
        "type": "text",
        "value": ""
      }
  
      return obj;
    }
  }

  prepareExcelRowsForCompsInLocalCurrency(comps, values, currentPortfolio?){
    //If any new column or row has to be added please update the values in below objects with type "formula" if needed
    //and also check with the index accordingly
    comps.forEach((comp, index) =>{
      let i = currentPortfolio ? index + 4 : index + this.currentPortfolioCompanies.length + 6;

      values.push([this.getBlankCell(), {"type": "text", "value": comp.companyName, "fontSize": 10}, 
        {"type": "text", "value": this.utilService.displayFormattedDate(comp.investmentDate, "ll"), "fontSize": 10},

        {"type": "text", "value": this.utilService.displayFormattedDate(comp.latestValuationDate, "ll"), "fontSize": 10 },

        currentPortfolio ? {"type": "text", "value": "NA", "color": "FF0000", "fontSize": 10} : {"type": "text", "value": this.utilService.displayFormattedDate(comp.exitDate, "ll"), "fontSize": 10},
        
        {"type": "text", "value": comp["localCurrency"] ? comp["localCurrency"] : "", "fontSize": 10},
        
        // Stake
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.stake)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.stake) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}, 

        // Investment amount 
        comp.valuesInLocalCurrency.investmentAmount != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.investmentAmount), "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.investmentAmount) == -1 ? "FF0000" : "000000", "fontSize": 10}
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10},
          
        // Realised Proceeds
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.realisedProceeds), "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.realisedProceeds) == -1 ? "FF0000" : "000000", "fontSize": 10}, 

        // NAV
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.stakeValue), "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.stakeValue) == -1 ? "FF0000" : "000000", "fontSize": 10}, 

        // Total Value
        {"type": "formula", "value": "##8!!" + i + "+##9!!" + i, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.totalValue) == -1 ? "FF0000" : "000000", "fontSize": 10},

        // Gross IRR
        comp.valuesInLocalCurrency.grossIRR != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.grossIRR)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.grossIRR) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10}, 

        // MOIC (Formula : Checking if investment amount != 0 and then building formula for moic)
        comp.valuesInLocalCurrency.investmentAmount != 0 ? {"type": "formula", "value": "##10!!" + i + "/##7!!" + i, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.moic) == -1 ? "FF0000" : "000000", "suffix": "x", "fontSize": 10} 
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10},

        // % Change in MOIC
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.changeInMOIC)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.changeInMOIC) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10},

        // From Last Val Date
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInLocalCurrency.changeInValuationPercentage)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInLocalCurrency.changeInValuationPercentage) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}
      ]);
    })

    values.push([ this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);
  
  }

  prepareExcelRowsForComps(comps, values, header?, currentPortfolio?) {
    if(header){
      values.push([ this.getBlankCell(), {"type": "text", "value": header, "bold": true}, this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);
    }

    //If any new column or row has to be added please update the values in below objects with type "formula" if needed
    //and also check with the index accordingly
    comps.forEach((comp, index) =>{
      let i = currentPortfolio ? index + 4 : index + this.currentPortfolioCompanies.length + 7;

      values.push([this.getBlankCell(), {"type": "text", "value": comp.companyName, "fontSize": 10}, 
        {"type": "text", "value": this.utilService.displayFormattedDate(comp.investmentDate, "ll"), "fontSize": 10},

        {"type": "text", "value": this.utilService.displayFormattedDate(comp.latestValuationDate, "ll"), "fontSize": 10 },

        currentPortfolio ? {"type": "text", "value": "NA", "color": "FF0000", "fontSize": 10} : {"type": "text", "value": this.utilService.displayFormattedDate(comp.exitDate, "ll"), "fontSize": 10},
        
        {"type": "text", "value": this.portfolioService.selectedCurrency, "fontSize": 10},

        // Stake
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.stake)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.stake) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}, 
          
        // Investment Amount
        comp.valuesInPortfolioCurrency.investmentAmount != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.investmentAmount), "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.investmentAmount) == -1 ? "FF0000" : "000000", "fontSize": 10}
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10},

        // Realised Proceeds
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.realisedProceeds), "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.realisedProceeds) == -1 ? "FF0000" : "000000", "fontSize": 10}, 

        // NAV
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.stakeValue), "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.stakeValue) == -1 ? "FF0000" : "000000", "fontSize": 10}, 

        // Total Value
        {"type": "formula", "value": "##8!!" + i + "+##9!!" + i, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.totalValue) == -1 ? "FF0000" : "000000", "fontSize": 10}, 

        // Gross IRR
        comp.valuesInPortfolioCurrency.grossIRR != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.grossIRR)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.grossIRR) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10},

        // MOIC (Formula : Checking if investment amount != 0 and then building formula for moic)
        comp.valuesInPortfolioCurrency.investmentAmount != 0 ? {"type": "formula", "value": "##10!!" + i + "/##7!!" + i, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.moic) == -1 ? "FF0000" : "000000", "suffix": "x", "fontSize": 10} 
          : {"type": "text", "value": "NA", "color": "FF0000", "alignment": "right", "fontSize": 10},

        // % Change in MOIC
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.changeInMOIC)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.changeInMOIC) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10},

        // From Last Val Date
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.changeInValuationPercentage)/100, "decimal":"1",
          "color": Math.sign(comp.valuesInPortfolioCurrency.changeInValuationPercentage) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 10}
      ]);
    })
  }

  prepareExcelRowsForAggregations(header, totals, values, boldText?) {

    if(header == "Total (Current + Realised)"){
      values.push([ this.getBlankCell(), {"type": "text", "value": header, "color": "1D1563", "background": "F4F5FD", "bold": boldText, "fontSize": 11}, this.getBlankCell(true), this.getBlankCell(true), this.getBlankCell(true), {"type": "text", "value": this.portfolioService.selectedCurrency, "color": "1D1563", "background": "F4F5FD", "bold": boldText, "fontSize": 11},
      this.getBlankCell(true),
      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalInvestment), "background": "F4F5FD", "bold": boldText, "decimal":"1",
      "color": Math.sign(totals.totalInvestment) == -1 ? "FF0000" : "1D1563", "fontSize": 11}, 

      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalRealisedProceeds), "background": "F4F5FD", "bold": boldText, "decimal":"1",
      "color": Math.sign(totals.totalRealisedProceeds) == -1 ? "FF0000" : "1D1563", "fontSize": 11},

      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalStakeValue), "background": "F4F5FD", "bold": boldText, "decimal":"1",
      "color": Math.sign(totals.totalStakeValue) == -1 ? "FF0000" : "1D1563", "fontSize": 11},

      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalTotalValue), "background": "F4F5FD", "bold": boldText, "decimal":"1",
      "color": Math.sign(totals.totalTotalValue) == -1 ? "FF0000" : "1D1563", "fontSize": 11},

      totals.totalIRR != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalIRR)/100, "background": "F4F5FD", "bold": boldText,
      "decimal":"1", "color": Math.sign(totals.totalIRR) == -1 ? "FF0000" : "1D1563", "suffix": "%", "fontSize": 11}
      : {"type": "number", "value": "0.0", "color": "FF0000", "background": "F4F5FD", "alignment": "right", "suffix": "%", "fontSize": 11},

      totals.totalMOIC != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalMOIC), "background": "F4F5FD", "bold": boldText, "decimal":"1",
      "color": Math.sign(totals.totalMOIC) == -1 ? "FF0000" : "1D1563", "suffix": "x", "fontSize": 11}
      : {"type": "text", "value": "NA", "color": "FF0000", "background": "F4F5FD", "alignment": "right", "fontSize": 11},

      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalPercentageChangeInMOIC) / 100, "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalPercentageChangeInMOIC) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 11},

      {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalChangeInValPercentage)/100, "background": "F4F5FD", "bold": boldText,
      "decimal":"1", "color": Math.sign(totals.totalChangeInValPercentage) == -1 ? "FF0000" : "1D1563", "suffix": "%", "fontSize": 11}
      ]);
    }
    else{
      values.push([ this.getBlankCell(), {"type": "text", "value": header, "background": "F4F5FD", "bold": boldText, "fontSize": 11}, this.getBlankCell(true), this.getBlankCell(true), this.getBlankCell(true), {"type": "text", "value": this.portfolioService.selectedCurrency, "background": "F4F5FD", "bold": boldText, "fontSize": 11},
      this.getBlankCell(true),
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalInvestment), "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalInvestment) == -1 ? "FF0000" : "000000", "fontSize": 11}, 
  
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalRealisedProceeds), "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalRealisedProceeds) == -1 ? "FF0000" : "000000", "fontSize": 11},
  
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalStakeValue), "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalStakeValue) == -1 ? "FF0000" : "000000", "fontSize": 11},
  
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalTotalValue), "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalTotalValue) == -1 ? "FF0000" : "000000", "fontSize": 11},
  
        totals.totalIRR != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalIRR)/100, "background": "F4F5FD", "bold": boldText,
        "decimal":"1", "color": Math.sign(totals.totalIRR) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 11}
        : {"type": "number", "value": "0.0", "color": "FF0000", "background": "F4F5FD", "alignment": "right", "suffix": "%", "fontSize": 11},
  
        totals.totalMOIC != 0 ? {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalMOIC), "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalMOIC) == -1 ? "FF0000" : "000000", "suffix": "x", "fontSize": 11}
        : {"type": "text", "value": "NA", "color": "FF0000", "background": "F4F5FD", "alignment": "right", "fontSize": 11},
  
        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalChangeMOIC) / 100, "background": "F4F5FD", "bold": boldText, "decimal":"1",
        "color": Math.sign(totals.totalChangeMOIC) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 11},

        {"type": "number", "value": this.utilService.getValueForExcelWithNegatives(totals.totalChangeInValPercentage)/100, "background": "F4F5FD", "bold": boldText,
        "decimal":"1", "color": Math.sign(totals.totalChangeInValPercentage) == -1 ? "FF0000" : "000000", "suffix": "%", "fontSize": 11}
      ]);

      values.push([ this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell(), this.getBlankCell()]);
    }
  }

  getTotalForAggregations(companies, values, columnNumber){
    let total = "";
    companies.forEach((comp, index) => {
      let i = index + 3;
      
      if(i == values.length){
        total = total + "##" + columnNumber + "!!" + i;
      }
      else{
        total = total + "##" + columnNumber + "!!" + i + "+";
      }
    });

    return total;
  }

  manageFileDownload(fileData, type) {
    let fundName = this.fundService.getFundName(this.portfolioService.selectedFundId);
    let date = moment().format("MMM DD YYYY").replace(/ /gi, "-");
    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      this.downloadLink.nativeElement.href = url;
      this.downloadLink.nativeElement.target = "_blank";
      this.downloadLink.nativeElement.download = fundName + "_Portfolio_" + date + "." + type;
      this.downloadLink.nativeElement.click();
    } catch (e) {
      console.log("Failed to download file", e)
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    }
  }

  downloadCSVForPortfolioSummaryPIF(){
    let fileData = this.prepareJsonDataForPortfolioSummaryPIF();

    let fundName = this.fundService.getFundName(this.portfolioService.selectedFundId);
    let date = moment().format("MMM DD YYYY").replace(/ /gi, "-");

    this.downloadFile(fileData, fundName + "_Portfolio_" + date)
  }

  downloadFile(data, filename='data') {
    let headerList = ["Asset ID", "Asset Name", "Valuation Date", "Valuation Currency", "Concluded Valuation"];
    let csvData = this.ConvertToCSV(data, headerList);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for(let i=0; i<headerList.length; i++){
      if(i == headerList.length - 1){
        row += headerList[i];
      }
      else{
        row += headerList[i] + ',';
      }
    }

    // row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = "";

        let count = Object.keys(array[i]).length;
        let k = 1;
        for (const [key, value] of Object.entries(array[i])) {
          if(k == count){
            line += array[i][key]
          }
          else{
            line += array[i][key] + ',';
          }

          k++;
        }


        str += line + '\r\n';
    }
    return str;
  }

  prepareJsonDataForPortfolioSummaryPIF(){
    let portfolioSummaryData = [];
    
    portfolioSummaryData.push({"asset_id": "ID", "asset_name": "Name", "valuation_date": "Valuation Date", "valuation_currency": "Valuation Currency", "nav": "NAV", "realised_proceeds": "Realised Proceeds"});

    portfolioSummaryData.push({"portfolio_name": "Current Portfolio"});

    // Current Portfolio Companies
    this.currentPortfolioCompanies.forEach(comp => {
      let compObj = {
        "asset_id": comp.id,
        "asset_name": comp.companyName,
        "valuation_date": this.utilService.displayFormattedDate(comp.latestValuationDate, "ll"),
        "valuation_currency": comp.portfolioCurrency,
        "nav": comp.valuesInPortfolioCurrency.stakeValue,
        "realised_proceeds": comp.valuesInPortfolioCurrency.realisedProceeds
      }

      portfolioSummaryData.push(compObj)
    })

    portfolioSummaryData.push({"portfolio_name": "Realised Portfolio"});

    portfolioSummaryData.push({"asset_id": "ID", "asset_name": "Name", "valuation_date": "Valuation Date", "exit_date": "Exit Date", "valuation_currency": "Valuation Currency", "nav": "NAV", "realised_proceeds": "Realised Proceeds"});

    this.exitedPortfolioCompanies.forEach(comp => {
      let compObj = {
        "asset_id": comp.id,
        "asset_name": comp.companyName,
        "valuation_date": this.utilService.displayFormattedDate(comp.latestValuationDate, "ll"),
        "exit_date": this.utilService.displayFormattedDate(comp.exitDate, "ll"),
        "valuation_currency": comp.portfolioCurrency,
        "nav": comp.valuesInPortfolioCurrency.stakeValue,
        "realised_proceeds": comp.valuesInPortfolioCurrency.realisedProceeds
      }
      portfolioSummaryData.push(compObj)
    })

    return portfolioSummaryData;
  }

  emittingActionOnPortfolio(event){
    this.portfolioUtil.emittingActionOnPortfolio(event, this.portfolioService.selectedFundId);
  }

  prepareColumnChart(columnChartData){
    const __this = this;

    const columnChart = new Chart({
      chart: { 
        renderTo: 'column-chart-container',
        type: 'column' 
      },
      lang: {
        contextButtonTitle: this.translateService.getLabel("chart_context_menu")
      },
      title: { text: '' },
      xAxis: {
          categories: columnChartData.map( data => data.sectorName),
          crosshair: true
      },
      yAxis:{ title:{ text: this.translateService.getLabel('value') + " (" + this.portfolioService.selectedCurrency + " Mn)" } },
      credits: { enabled: false },
      exporting: {
        enabled: true,
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.getLabel("view_in_full_screen")
          },
          printChart: {
            text: this.translateService.getLabel("print_chart")
          },
          downloadPNG: {
            text: this.translateService.getLabel("download_png_image")
          },
          downloadJPEG: {
            text: this.translateService.getLabel("download_jpeg_image")
          },
          downloadPDF: {
            text: this.translateService.getLabel("download_pdf_document")
          },
          downloadSVG: {
            text: this.translateService.getLabel("download_svg_vector_image")
          },
          downloadCSV: {
            text: this.translateService.getLabel("download_csv")
          },
          downloadXLS: {
            text: this.translateService.getLabel("download_xls")
          },
          viewData: {
            text: this.translateService.getLabel("view_data_table")
          }
        }
      },
      tooltip: {
        formatter: function() {
          return __this.portfolioService.selectedCurrency + " " + Number(this.y.toFixed(1)).toLocaleString() + " Mn"
        }
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        { name: this.translateService.getLabel('previous_valuation'), data: columnChartData.map( data => data.prevValDate), color: colors.chartPreviousValuationColor},
        { name: this.translateService.getLabel('current_valuation'), data:  columnChartData.map( data => data.currValDate), color: colors.chartCurrentValuationColor}
      ] as any
    })

    return columnChart;

  }

  prepareColumnChartData(formsArray) {
    let columnChartData = [];

    const sectors = [];

    formsArray.forEach(form =>{
      if(form.sector){
        if(sectors.indexOf(form.sector) == -1){
          sectors.push(form.sector);
        }
      }
    });

    sectors.forEach(sector => {
      const formsWithSameSector = formsArray.filter(form => form.sector == sector);
      let prevValDate: number = 0;
      let currValDate: number = 0;


      formsWithSameSector.forEach((comp) => {
        const currentValDateStakeValue = this.utilService.getValidNumber(comp.valuesInPortfolioCurrency.stakeValue);
        // const changeInStakeValue = comp.stakeVal * comp.changeInValPercentage

        // const prevValDateStakeValue = this.utilService.getValidNumber((currentValDateStakeValue - comp.changeInStakeValue) * comp['exchangeRate']);
        const prevValDateStakeValue = this.utilService.getValidNumber(comp.valuesInPortfolioCurrency.prevStakeValue);

        currValDate += currentValDateStakeValue;

        prevValDate += prevValDateStakeValue;
      })

      columnChartData.push({sectorName: sector, prevValDate: prevValDate, currValDate: currValDate});
    });

    columnChartData = columnChartData.sort((sec1, sec2) => {
      return (sec2.currValDate - sec1.currValDate);
    });

    return this.prepareColumnChart(columnChartData);
  }

  prepareMapData(mapData){
    const mapChart = new MapChart({
      chart: { 
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          name: mapData.name,
          joinBy: ['iso-a3', 'code3'],
          data: mapData.data,
          color: colors.chartCurrentValuationColor,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormatter: function() {
                return this.name+ ":" + Number(this.z.toFixed(1)).toLocaleString()
              }
          }
      }] as any
    });    
    return mapChart
  }

  updateWorldMap(geographicalFormData){
    this.portfolioService.mapData.data = [];

    geographicalFormData.forEach((formData)=>{

      const data = { code3:'', name:'', z: 0 }
      
      if(this.portfolioService.mapData.value == "changeInVal"){
        this.portfolioService.mapData.name = "Change In Valuation";
        let changeInValCountryWise = 0;

        formData.forms.map((form) => {
          changeInValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = changeInValCountryWise;
      }
      else if(this.portfolioService.mapData.value == "prevQuarterVal"){
        this.portfolioService.mapData.name = "Previous Quarter Valuation";
        let prevQuarValCountryWise = 0;

        formData.forms.forEach((form) => {          
          prevQuarValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = prevQuarValCountryWise;
      }
      else if(this.portfolioService.mapData.value == "noOfComp"){
        this.portfolioService.mapData.name = "Total Number of Companies";
        let totalNoOfComp = formData.forms.length? formData.forms.length: 0 ;
        data.z = totalNoOfComp;
      }
      else{
        this.portfolioService.mapData.name = "Current Valuation";
        let totalValCountryWise = 0;

        formData.forms.forEach((form) => {
          
          totalValCountryWise += form.valuesInPortfolioCurrency.stakeValue
        });
        data.z = totalValCountryWise;
      }

      data.code3 = formData.countryCode;
      data.name = formData.countryName;
      this.portfolioService.mapData.data.push(data);
    });

    return this.prepareMapData(this.portfolioService.mapData);
  }

  prepareMapInformation(portfolioArray) {

    let codeCountryName = [];

    const geographicalFormData = [];

    portfolioArray.map(form => {
      if(form.geography){
        codeCountryName.push({
          alias: form["geography-cbAlias"], 
          countryName: form.geography
        });
      }
    })

    let alias = '';
    let countryName = '';
    let filteredData = codeCountryName.filter((data)=>{
      if(alias == ''){
        alias=data.alias;
        countryName = data.countryName;
      }
      else{
        if(alias == data.alias && countryName == data.countryName)
          return false
        else
          alias = data.alias
          countryName = data.countryName
          return true
      }

    })

    let geoCode = codeCountryName.map((code) => code.alias)

    let geo = geoCode.filter( (code, index) => {
      return geoCode.indexOf(code) == index;
    })
  
    geo.map( geoCode =>{
      let formData = [];
      portfolioArray.filter(form =>{
        if(form.geography){
          if(form["geography-cbAlias"] == geoCode){
            formData.push(form);
          }
        }
      });
      const geoData = {
        countryName:'',
        countryCode:'', 
        forms:[]
      }

      let countryName;
      filteredData.map( data =>{
        if(data.alias == geoCode){
          countryName = data.countryName;
        }
      })
      geoData.countryName = countryName;
      geoData.countryCode = geoCode;
      geoData.forms = formData;
      geographicalFormData.push(geoData);
    })
    return this.updateWorldMap(geographicalFormData);
  }


  async preparePrecentageChangeInMOIC(){
    const valuationDates = [];

    this.currentPortfolioCompanies.forEach(comp => {
      this.prepareChangeInMOIC_ReqBody(comp, valuationDates);
    });

    this.exitedPortfolioCompanies.forEach(comp => {
      this.prepareChangeInMOIC_ReqBody(comp, valuationDates);
    });

    let requestBody = {
      "valuationDates": valuationDates,
      "allTransactionsNeeded": false
    }

    this.ds.getPrecentageChangeinMoicForPortfolioCurrency(requestBody, this.portfolioService.selectedFundId, this.portfolioService.selectedCurrency).subscribe(res => {
      let transcationsInCurrencyResponse = res.body["response"];
      
      this.currentPortfolioCompanies.forEach(comp => {
        this.assignChangeInMOIC(comp, transcationsInCurrencyResponse);
      });

      this.exitedPortfolioCompanies.forEach(comp => {
        this.assignChangeInMOIC(comp, transcationsInCurrencyResponse);
      });

      // Current: total change in MOIC
      this.portfolio.current.totalPreviousMOIC = this.portfolio.current.totalInvestment > 0 ? (this.portfolio.current.totalPrevStakeValue
        + this.portfolio.current.totalRealisedProceeds)
        / this.portfolio.current.totalInvestment : "NA";

      this.portfolio.current.totalChangeMOIC = this.portfolio.current.totalPreviousMOIC != 'NA' ? (this.portfolio.current.totalMOIC - this.portfolio.current.totalPreviousMOIC) * 100 / this.portfolio.current.totalPreviousMOIC : 0;

      // Exit: total change in MOIC
      this.portfolio.exit.totalPreviousMOIC = this.portfolio.exit.totalInvestment > 0 ? (this.portfolio.exit.totalPrevStakeValue
        + this.portfolio.exit.totalRealisedProceeds)
        / this.portfolio.exit.totalInvestment : "NA";

      this.portfolio.exit.totalChangeMOIC = this.portfolio.exit.totalPreviousMOIC != 'NA' ? (this.portfolio.exit.totalMOIC - this.portfolio.exit.totalPreviousMOIC) * 100 / this.portfolio.exit.totalPreviousMOIC : 0;

      this.totalAggregations.totalPercentageChangeInMOIC = this.portfolio.current.totalChangeMOIC + this.portfolio.exit.totalChangeMOIC;

      this.currentPortfolioCompanies = cloneDeep(this.currentPortfolioCompanies);
      this.exitedPortfolioCompanies = cloneDeep(this.exitedPortfolioCompanies);
    });
  }

  prepareChangeInMOIC_ReqBody(comp, valuationDates) {
    let currency;

    if(comp.prevValuationDateId == null || !comp.prevValuationDateId){
      comp.valuesInPortfolioCurrency.changeInMOIC = 0
    }
    else {
      currency = comp.valuesInLocalCurrency.currency;
      const valDate = {
        "valuationDateId": comp.prevValuationDateId,
        "latestValuationDate": comp.prevValuationDate,
        "valuesInLocalCurrency": {
          "currency": comp.valuesInLocalCurrency.currency
        }
      }

      valuationDates.push(valDate);
    }
  }

  assignChangeInMOIC(comp, transcations) {
    
    if(comp.prevValuationDateId == null || !comp.prevValuationDateId){
      comp.valuesInPortfolioCurrency.changeInMOIC = 0
      comp.valuesInPortfolioCurrency.previousMOIC = 0;
    }
    else if(transcations[comp.prevValuationDateId]){
      let investmentAmount = 0; 
      let realisedProceeds = 0;

      if(transcations[comp.prevValuationDateId].investmentAmountTrans) {
        investmentAmount = this.utilService.getValidNumber(transcations[comp.prevValuationDateId].investmentAmountTrans.totalAmount); 
      } 
      
      if(transcations[comp.prevValuationDateId].realisedProceedsTrans) {
        realisedProceeds = this.utilService.getValidNumber(transcations[comp.prevValuationDateId].realisedProceedsTrans.totalAmount); 
      }

      if(investmentAmount) {
      
        comp.valuesInPortfolioCurrency.previousMOIC = (comp.valuesInPortfolioCurrency.prevStakeValue
          + realisedProceeds)
          / investmentAmount;

        const moicDiff = (comp.valuesInPortfolioCurrency.moic - comp.valuesInPortfolioCurrency.previousMOIC)
        
        comp.valuesInPortfolioCurrency.changeInMOIC = ( moicDiff
          / comp.valuesInPortfolioCurrency.previousMOIC) 
          * 100;

      } else {
        comp.valuesInPortfolioCurrency.previousMOIC = 0;
        comp.valuesInPortfolioCurrency.changeInMOIC = 0;
      }
    }

    comp.changeInMOIC_valuesInPortfolioCurrency = comp.valuesInPortfolioCurrency.changeInMOIC;
  }


  async prepareFullyDilutedStakeValueRequest(){
    const valuationDatesIds = [];

    this.currentPortfolioCompanies.forEach((comp, index) => {
      valuationDatesIds.push(comp.latestValuationDateId)
    });

    this.exitedPortfolioCompanies.forEach(comp => {
      valuationDatesIds.push(comp.latestValuationDateId)
    });

    this.fetchFullyDilutedStakeValue(valuationDatesIds)

  }


  async fetchFullyDilutedStakeValue(reqbody){
    try {
      let res = await this.ds.getFullyDilutedStakeForPortfolioSummaryTable(reqbody).toPromise()
      let fullyDilutedstake = res.body['response']

      if (fullyDilutedstake) {
        let currentPortfolioCompanies = cloneDeep(this.currentPortfolioCompanies)

        currentPortfolioCompanies.forEach(row => {
            row.valuesInLocalCurrency.fullyDilutedStake = fullyDilutedstake && fullyDilutedstake[row.latestValuationDateId]?.capTableStake ? fullyDilutedstake[row.latestValuationDateId]?.capTableStake : null
            row.valuesInPortfolioCurrency.fullyDilutedStake = fullyDilutedstake && fullyDilutedstake[row.latestValuationDateId]?.capTableStake ? fullyDilutedstake[row.latestValuationDateId]?.capTableStake : null
        })   
        this.currentPortfolioCompanies = cloneDeep(currentPortfolioCompanies)

// --------------------------------------------------------------------------------------------------------------------------------------------------
        let exitedPortfolioCompanies = cloneDeep(this.exitedPortfolioCompanies)
        exitedPortfolioCompanies.forEach(row => {
          row.valuesInLocalCurrency.fullyDilutedStake = fullyDilutedstake && fullyDilutedstake[row.latestValuationDateId]?.capTableStake ? fullyDilutedstake[row.latestValuationDateId].capTableStake : null
          row.valuesInPortfolioCurrency.fullyDilutedStake = fullyDilutedstake && fullyDilutedstake[row.latestValuationDateId]?.capTableStake ? fullyDilutedstake[row.latestValuationDateId].capTableStake : null
        })
  
        this.exitedPortfolioCompanies = cloneDeep(exitedPortfolioCompanies)

      }

    }
    catch(err){
      console.log("Error Failed to Fetch Fully Diluted Stake Value", err)
    }
  }

  async initAllIRR() {
    if(!this.totalAggregations.totalIRR) {
      this.initGroupIRR(this.totalAggregations, "TOTAL")  
    }
    
    this.initGroupIRR(this.currentPortfolioTotals, "CURRENT")
    
    this.initGroupIRR(this.exitedPortfolioTotals, "EXIT") 

    let IRR_ReqBodyForAllCompanies = {
      "request_id": this.portfolioService.selectedFundId,
      "data": [],
    };

    
    this.currentPortfolioCompanies.forEach(comp => {
      // IRR in Valuation Currency is already stored
      // IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInLocalCurrency", "IRRReqBody", "LOCAL"));
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInPortfolioCurrency", "IRRInDestCurrReqBody", "DEST"));
    })

    this.exitedPortfolioCompanies.forEach(comp => {
      // IRR in Valuation Currency is already stored
      // IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInLocalCurrency", "IRRReqBody", "LOCAL"));
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInPortfolioCurrency", "IRRInDestCurrReqBody", "DEST"));
    })

    IRR_ReqBodyForAllCompanies.data = IRR_ReqBodyForAllCompanies.data.filter(reqBody => reqBody != null);

    //Prepare Company batches as the IRR API should be getting IRR values in the batch of 5 companies
    let totalBatchCount: any = (IRR_ReqBodyForAllCompanies.data.length / 5) + 1;

    //taking only before decimal number into consideration
    totalBatchCount = totalBatchCount.toString().split(".")[0] 

    let eachBatch = [];

    let finalArrayOfBatches = [];

    for(let i=1; i<=totalBatchCount; i++){
      if(i < totalBatchCount){              //For the batches with all 5 companies
        for(let j=0; j<5; j++){
          eachBatch.push(IRR_ReqBodyForAllCompanies.data[j + ((i-1)*5)])
        }
      }
      else{                                 //For the last batch which will have the remaining companies (less than 5 companies)
        for(let j=(i-1)*5; j<IRR_ReqBodyForAllCompanies.data.length; j++){
          eachBatch.push(IRR_ReqBodyForAllCompanies.data[j])
        }
      }
      if(eachBatch.length > 0){
        finalArrayOfBatches.push(eachBatch);     //This will have an array of batches  
        eachBatch = [];
      }
    }

    //Getting IRR for each Comany batch wise
    for(let eachBatchReqBody of finalArrayOfBatches) {
      let reqBody = {
        "request_id": this.portfolioService.selectedFundId,
        "data": eachBatchReqBody,
      };

      let compWiseData = [];

      try {
        const res : any = await this.ds.getIRRForMultipleRequests(reqBody).toPromise();
        compWiseData = res.body["response"]["complete_processed_data"][0]

        // Setting IRR to 0 if the API doesn't return any value for the particular company
        eachBatchReqBody.forEach(batch => {
          if(!compWiseData[batch.request_id]){
            const currentComp = this.currentPortfolioCompanies.find(c => (c.id + "_DEST") == batch.request_id);
            if(currentComp){
              currentComp["valuesInLocalCurrency"].grossIRR = 0;
              currentComp["valuesInPortfolioCurrency"].grossIRR = 0;
            }
            const exitedComp = this.exitedPortfolioCompanies.find(c => (c.id + "_DEST") == batch.request_id);
            if(exitedComp){
              exitedComp["valuesInLocalCurrency"].grossIRR = 0;
              exitedComp["valuesInPortfolioCurrency"].grossIRR = 0;
            }
          }
        })
      } catch (error) {
        console.log("Failed to fetch IRR", error);
        
      }

      for (let comp of this.currentPortfolioCompanies) {
        if(!!(compWiseData[comp.id + "_DEST"])){
          try {
            // if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
            //   comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100)
            // }
  
            if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
              comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
            }
          } catch (e) {
            comp["valuesInLocalCurrency"].grossIRR = 0;
            comp["valuesInPortfolioCurrency"].grossIRR = 0;
          }
        }      
      }

      for (let comp of this.exitedPortfolioCompanies) {
        if(!!(compWiseData[comp.id + "_DEST"])){
          try {
            // if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
            //   comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100)
            // }
  
            if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
              comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
            }
          } catch (e) {
            comp["valuesInLocalCurrency"].grossIRR = 0;
            comp["valuesInPortfolioCurrency"].grossIRR = 0;
          }
        }      
      }
    }
    
    this.portfolio.exit.expandPortfolio = !this.portfolio.exit.expandPortfolio;
    this.portfolio.current.expandPortfolio = !this.portfolio.current.expandPortfolio;
    setTimeout(() => {
      this.portfolio.exit.expandPortfolio = !this.portfolio.exit.expandPortfolio;
      this.portfolio.current.expandPortfolio = !this.portfolio.current.expandPortfolio;
    })

    // this.ds.getIRRForMultipleRequests(IRR_ReqBodyForAllCompanies).subscribe(res => {

    //   const compWiseData = res.body["response"]["complete_processed_data"][0];

    // this.currentPortfolioCompanies.forEach(comp => {
    //   try {
    //     if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
    //       comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100)
    //     }

    //     if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
    //       comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
    //     }
    //   } catch (e) {
    //     comp["valuesInLocalCurrency"].grossIRR = 0;
    //     comp["valuesInPortfolioCurrency"].grossIRR = 0;
    //   }
    // })

    //   this.exitedPortfolioCompanies.forEach(comp => {
    //     if (!comp.grossIRR) {
    //       try {
    //         if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
    //           comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100);
    //         }
    //         if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
    //           comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
    //         }
    //       } catch (e) {
    //         comp["valuesInLocalCurrency"].grossIRR = 0;
    //         comp["valuesInPortfolioCurrency"].grossIRR = 0;
    //       }
    //     }
    //   })

    // }, error => {
    //   console.log("Error", error);
    // })
  }

  prepareIRR_ReqBodyForCompany(comp, type, reqBodyKey, currencyType) {
    if(comp && comp[type] && comp[type][reqBodyKey]){
      comp[type].grossIRR = "loading";

      const IRRInDestCurrReqBody = {
        "request_id": comp.id + "_" + currencyType,
        "data": comp[type][reqBodyKey]
      }

      return IRRInDestCurrReqBody;
    }

    return null;
  }

  initGroupIRR(aggregation, type) {
    aggregation.totalIRR = "loading";

    const IRRInDestCurrReqBody = {
      "request_id": this.portfolioService.selectedFundId + "_" + type,
      "data": aggregation.IRRInDestCurrReqBody
    }

    this.ds.getIRR(IRRInDestCurrReqBody).subscribe(res => {
      try {
        aggregation.totalIRR = this.utilService.zeroInForOneDecimalFormat(res.body["response"].data.complete_processed_data * 100);
      } catch(e) {
        aggregation.totalIRR = 0
      }
    }, error => {
      aggregation.totalIRR = 0
    });
  }

  async changeInFundCurrencyByUser(newCurrency) {
    this.ms.publish("REPORTING_CURRENCY_CHANGE_EQUITY", true);
    this.changeInFundCurrency(newCurrency);
  }

  async changeInFundCurrency(newCurrency) {
    this.portfolioService.loading = true;

    this.portfolioService.summary[this.portfolioService.selectedFundId] = null;

    this.portfolioService.selectedCurrency = newCurrency;

    await this.portfolioService.saveFundPortfolioWidget();

    this.portfolioService.fetchPortfolioData(this.portfolioService.selectedFundId);
  }

  
  // -------------------------------------
  // New Company Addition
  // -------------------------------------
  newCompany = {name: "", valuationDate: null, security: "EQUITY", investmentAmount: 0, logo: null, 
  businessUnitsNumber: 0, company_id: null, valuationType : ""};
  searchString = ""
  searchedCompanyNames = [];

  selectedFormForAssignment;
  
  openPopupModal(content) {
    this.newCompany.businessUnitsNumber = 0;
    this.multipleBusinessUnitExists = false
    this.modalService.open(content, { centered: true, windowClass: 'center-popup-modal', size: 'lg' });
  }

  selectedCompanyName(option) {
    // console.log("company selected", option)
    this.newCompany.company_id = option.company_id
  }
  
  startApplication() {
    const selectedUserDetails = this.ums.getSelectedUserDetails();
    if (this.newCompany.name !== '' && this.newCompany.valuationDate) {
      this.newCompany.valuationDate = (moment(this.newCompany.valuationDate)).format("YYYY-MM-DD");

      //Selecting REVALUE when the company gets created for the first time, invform is always REVALUE
      this.newCompany.valuationType = this.invSummaryService.TYPE_OF_SUBMISSION.REVALUE;
      
      this.modalService.dismissAll();
      
      const loggedInUser = this.ums.getUserDetails();

      this.ds.createNewCompanyWithVersion(this.newCompany, selectedUserDetails, this.ums.getApplicationVersion(), "FIRST", true, loggedInUser, this.portfolioService.selectedFundId).subscribe(res => {
        this.selectedFormForAssignment = res.body["response"];
        this.newCompany.company_id = this.selectedFormForAssignment.id

        if (this.newCompany.security == "DEBT") {
          let payload = {
            fundId: this.portfolioService.selectedFundId,
            fundName: this.fundService.getFundName(this.portfolioService.selectedFundId),
            companyId: this.selectedFormForAssignment.id,
            companyName: this.newCompany.name,
            investmentDate: this.newCompany.valuationDate
          }

          this.ds.createDebtModelMetaData(payload).then(resp => {
            console.log("Saved new company metaata to debt model");
          }).catch(err => {
            console.log("Error: unable to saved new company metaata to debt model", err);
          })

          this.ms.publish("ADD_NEW_DEBT_INVESTMENT", this.newCompany);

        } else {
          this.addNewCompanyToTable(this.selectedFormForAssignment);
        }

        this.utilService.showMessage(this.newCompany.name + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));

      }, err => {
        console.log("Error while creating company", err)
        this.utilService.showMessage(this.translateService.getLabel("err_failed_create") + " " + this.newCompany.name + " " + this.translateService.getLabel("valuation"), this.translateService.getLabel("ok"));
      })
    }
    else {
      this.utilService.showMessage(this.translateService.getLabel("req_all_details"), this.translateService.getLabel("ok"));
    }
  }

  addNewCompanyToTable(newComp) {
    this.portfolioService.loading = true;

    const allCurrentComps = this.portfolioService.summary[this.portfolioService.selectedFundId].currentPortfolio.portfolioData;
    const newEntry = {
      "id": newComp.id,
      "logo": "",
      "companyName": newComp.companyName,
      "investmentDate": newComp.valuationDate,
      "geography": "",
      "geography-cbAlias": "",
      "fundId": this.portfolioService.selectedFundId,
      "sector": "",
      "type": "",
      "version": 4,
      "security": "EQUITY",
      "exitStatus": "CURRENT PORTFOLIO",
      "exitDate": null,
      "valuesInLocalCurrency": {},
      "valuesInPortfolioCurrency": {}
    }

    allCurrentComps.push(newEntry);

    this.currentPortfolioCompanies = cloneDeep(allCurrentComps);

    setTimeout(() => {
      this.portfolioService.loading = false;
    });
  }

  companyNameChange() {

    if (this.newCompany.name && this.newCompany.name.length >= 3) {

      this.ds.getNewMatchingCompanyNames(this.newCompany.name).subscribe(result => {

        if (result.body["response"]) {

          this.searchedCompanyNames = result.body["response"];

        } else {
          this.searchedCompanyNames = []
        }
      }, error => {

        console.log("error", error);
        this.searchedCompanyNames = []
      })

    } else {
      this.searchedCompanyNames = []
    }
  }

  navigateToleaverageCost(){
    let fundId = this.portfolioService.selectedFundId
    window.open(environment.portalUrl + "/lcf/#/leaverage-cost?fundId="+fundId,"_self")
  }

  hasCompanyDeleteTrue(companyList){
    for(let i = 0 ; i < companyList.length ; i++){
      if(companyList[i]?.delete == true){
        this.isCompanySelected = true;
      }
    }
  }

  resetCompanyList(){
    this.isCompanySelected = false;
    this.allCompaniesList.forEach(obj => {
      if (obj.hasOwnProperty('delete')) {
        delete obj.delete;
      }
    })
  }

  confirmAndDelete() {
    const compsToBeDeleted = this.allCompaniesList.filter(c => c.delete);
    this.hasCompanyDeleteTrue(this.allCompaniesList)
    if(this.isCompanySelected == false){
      return true;
    }
    const dialog = this.utilService.showConfirmMessage(this.translateService.getLabel("info_confirm_delete") + " " + compsToBeDeleted.map(c => c.name).join(", "), this.translateService.getLabel("ok"), this.translateService.getLabel("cancel"));

    dialog.afterClosed().subscribe(confirmation => { 
      if(confirmation === "Yes") {
        
        const orgId = this.ums.getSelectedUserDetails().organization.id;

        this.ds.deleteCompanies(compsToBeDeleted.map(c => c.id), orgId).subscribe(response => {
          this.isCompanySelected = false;
          const success = this.utilService.showMessage(this.translateService.getLabel("suc_delete_company") + ": " + compsToBeDeleted.map(c => c.name).join(", "), this.translateService.getLabel("ok"));

          success.afterClosed().subscribe(res=>{
            window.location.reload();
          }) 
        });
      } else {
        this.utilService.closeAllPopups();
      }
    });
  }

  onAsOnDateChange(event) {
    this.portfolioService.loading = true;

    this.portfolioService.summary[this.portfolioService.selectedFundId] = null;

    // Load the portfolio as on date selected by the user
    if(this.selectedValDate != "latest_valuation_date"){
      this.portfolioService.fetchPortfolioData(this.portfolioService.selectedFundId, this.selectedValDate);
    }
    // else, load the default portfolio
    else{
      this.portfolioService.fetchPortfolioData(this.portfolioService.selectedFundId);
    }
  }

  // Persist the column hide/show status for each portfolio table 
  // grid key identifies each portfolio table uniquely
  updateShowHideColumns(gridKey, columnsVisibility) {
    columnsVisibility.forEach(c => {
      this.showHideColumns[gridKey][c.columnKey] = c.visible;
    })
    this.ds.saveWidgetDataToDB("PORTFOLIO_SUMMARY_GRID_COL_SELECTION",this.showHideColumns, this.portfolioService.selectedFundId).subscribe
    (res => {
      console.log("SUCCESSFULLY SAVED PORTFOLIO_SUMMARY_GRID_COL_SELECTION", res);
    },
    (error) => {
      console.log("PORTFOLIO_SUMMARY_GRID_COL_SELECTION", error);
    })
  }
}
