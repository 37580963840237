<nav-menu-header headerLabel="Portfolio Summary"></nav-menu-header>

<button mat-raised-button class="save-btn secondary-bg" (click)="saveSummary()">Save</button>

<div class="row" style="margin: 0;">
    
</div>

<div class="row" style="background-color: white">
    <div class="col">
        <div class="row" style="margin: 20px 0;">
            <div class="col"></div>
            <div class="col-3" style="text-align: right;">
                <mat-slide-toggle [checked] = "autoNumberFormat" (change) = "autoNumberFormat = !autoNumberFormat">
                    Auto Number Format
                </mat-slide-toggle>
            </div>
        </div>
        <div class="row">
            <div class="col" style="width:75%;max-width: 75%;">
                <div class="row">
                    <div class="col-2" style="padding-left:5% ;">
                        <img [src]="logoImage" *ngIf="logoImage;else defaultLogo">
                        <ng-template #defaultLogo>
                            <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                        </ng-template>
                    </div>
                    <div class="col-9">
                        <h2 style="color: #1D1563;">{{ user["organization"].orgName }}</h2>
                    </div>
                </div>
        
                <table class="table borderless">
                    <tr>
                        <td style="padding: 25px;">
                            <div style="border-bottom: solid #e3e6e3 2px;text-align: center;">
                                <ng-template [ngIf]="portfolioService.totalPortfolioData.totalStakeVal">
                                    <span style="color: green;"> 
                                        USD 
                                        <span style="font-size: 25px;">
                                            <ng-template [ngIf] = "portfolioService.totalPortfolioData.totalStakeVal > 1000 && autoNumberFormat">
                                                {{ (portfolioService.totalPortfolioData.totalStakeVal / 1000) | number: '1.1-1'}} Bn
                                            </ng-template>
                                            <ng-template [ngIf] = "!autoNumberFormat || portfolioService.totalPortfolioData.totalStakeVal < 1000">
                                                {{ portfolioService.totalPortfolioData.totalStakeVal | number: '1.1-1'}}Mn
                                            </ng-template>
                                        </span>
                                    </span>
                                </ng-template>
                                <ng-template [ngIf]="!portfolioService.totalPortfolioData.totalStakeVal">
                                    <span style="margin-top: 17px; display: block;"> - </span>
                                </ng-template>
                            </div>
                            <div style="text-align: center;">
                                <span style="font-size: 15px;">Current Valuation</span>
                            </div>
                        </td>
                        <td style="padding: 25px;">
                            <div style="border-bottom: solid #e3e6e3 2px;text-align: center;">
                                <ng-template [ngIf]="portfolioService.totalPortfolioData.totalChangeInValPercentage">
                                    <span style="font-size: 25px;"> 
                                        {{ portfolioService.totalPortfolioData.totalChangeInValPercentage * 100 | number: '1.1-1'}}
                                    </span>
                                    <span style="font-size: 15px;color: green;" *ngIf="portfolioService.totalPortfolioData.totalChangeInValPercentage > 0"> 
                                        %Up
                                    </span>
                                    <span style="font-size: 15px;color: red;" *ngIf="portfolioService.totalPortfolioData.totalChangeInValPercentage < 0"> 
                                        %Down
                                    </span>
                                </ng-template>
                                <ng-template [ngIf]="!portfolioService.totalPortfolioData.totalChangeInValPercentage">
                                    <span style="margin-top: 17px; display: block;"> - </span>
                                </ng-template>
                            </div>
                            <div style="text-align: center;">
                                <span style="font-size: 15px;">From Last Valuation Date</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding: 25px;">
                            <div style="border-bottom: solid #e3e6e3 2px;text-align: center; color: green;">
                                <ng-template [ngIf]="portfolioService.totalPortfolioData.totalIRR">
                                    <span style="font-size: 25px;">
                                        {{ portfolioService.totalPortfolioData.totalIRR * 100 | number: '1.1-1'}} 
                                    </span> %
                                </ng-template>
                                <ng-template [ngIf]="!portfolioService.totalPortfolioData.totalIRR">
                                    <span style="margin-top: 17px; display: block;"> - </span>
                                </ng-template>                       
                            </div>
                            <div style="text-align: center;">
                                <span style="font-size: 15px;">Current IRR</span>
                            </div>
                        </td>
                        <td style="padding: 25px;">
                            <div style="border-bottom: solid #e3e6e3 2px;text-align: center;">
                                <ng-template [ngIf]="portfolioService.totalPortfolioData.totalMOIC">
                                    <span style="font-size: 25px;color: green;">
                                        {{ portfolioService.totalPortfolioData.totalMOIC | number: '1.1-1'}} x
                                    </span>
                                </ng-template>
                                <ng-template [ngIf]="!portfolioService.totalPortfolioData.totalMOIC">
                                    <span style="margin-top: 17px; display: block;"> - </span>
                                </ng-template>      
                            </div>
                            <div style="text-align: center;">
                                <span style="font-size: 15px;">MOIC</span>
                            </div>
                        </td>
                    </tr>
                </table>
        
        
                <div class="containerStyling">
                    <div class="headers">
                        <mat-form-field style="width: 50%">
                            <input matInput type="text" [(ngModel)]="summaryPage.dbData.companiesList.firstSet.header"
                                style="font-weight: bold;font-size: 12px;">
                        </mat-form-field>
                        <button mat-raised-button class="addBtn"
                            (click)="openPopUpAddCompanies(companyList,'topGainers')">Add</button>
                    </div>
        
                    <div *ngFor="let list of summaryPage.dbData.companiesList.firstSet.list;let i=index"
                        class="row-data" [class.border-top-radius]="i==0" [class.border-bottom-radius]="i== (summaryPage.dbData.companiesList.firstSet.list.length - 1)" 
                        [class.list-item-border]="i < (summaryPage.dbData.companiesList.firstSet.list.length - 1)" [style.background-color]="summaryPage.dbData.companiesList.firstSet.color">
                        
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-1">
                              <img [src]="list.logo" (error)="logoSourceError($event,list)" *ngIf="list.logo;else defaultLogo" width="30" height="30">
                              
                              <ng-template #defaultLogo>
                                  <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                              </ng-template>
                            </div>
                            <div class="col-4"><span class="font-size"> {{ list.name }} </span></div>
                            <div class="col-3">
                                <span class="font-size"> 
                                    <ng-template [ngIf] = "list.investment > 1000 && autoNumberFormat">
                                        USD {{ (list.investment / 1000) | number:'1.1-1' }} Billion
                                    </ng-template>
                                    <ng-template [ngIf] = "!autoNumberFormat || list.investment < 1000">
                                        USD {{ list.investment | number:'1.1-1' }} Million
                                    </ng-template>
                                </span>
                            </div>
                            <div class="col-2"><span class="font-size"> {{ list.growth | number:'1.1-1' }} % Up</span></div>
                            <div class="col" style="text-align: right">
                                <!-- <span style="margin-right: 30%;"><i class="fas fa-pencil-alt font-size"></i></span> -->
                                <span (click)="deleteGainer(summaryPage.dbData.companiesList.firstSet.list, i)"><i class="far fa-trash-alt font-size"></i></span>
                            </div>
                        </div>
                        <div>
                            <mat-form-field class="text-area-green form-group">
                                <textarea matInput rows="7" cols="80px" style="font-size: 15;" name="description"
                                    [(ngModel)]="list.description">
                            </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <div class="containerStyling">
                    <div class="headers">
                        <mat-form-field style="width: 50%">
                            <input matInput type="text" [(ngModel)]="summaryPage.dbData.companiesList.secondSet.header"
                                style="font-weight: bold;font-size: 12px;">
                        </mat-form-field>
                        <button mat-raised-button class="addBtn"
                            (click)="openPopUpAddCompanies(companyList,'topLoosers')">Add</button>
                    </div>
                    <div *ngFor="let list of summaryPage.dbData.companiesList.secondSet.list;let i=index"
                        class="row-data" [class.border-top-radius]="i==0" [class.border-bottom-radius]="i== (summaryPage.dbData.companiesList.secondSet.list.length - 1)"
                        [class.list-item-border]="i < (summaryPage.dbData.companiesList.secondSet.list.length - 1)" [style.background-color]="summaryPage.dbData.companiesList.secondSet.color">
                        
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-1">
                              <img [src]="list.logo" (error)="logoSourceError($event,list)" *ngIf="list.logo;else defaultLogo" width="30" height="30">
                                    
                              <ng-template #defaultLogo>
                                  <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                              </ng-template>
                            </div>
                            <div class="col-4"><span class="font-size"> {{ list.name }} </span></div>
                            <div class="col-3">
                                <span class="font-size"> 
                                    <ng-template [ngIf] = "list.investment > 1000 && autoNumberFormat">
                                        USD {{ (list.investment / 1000) | number:'1.1-1' }} Billion
                                    </ng-template>
                                    <ng-template [ngIf] = "!autoNumberFormat || list.investment < 1000">
                                        USD {{ list.investment | number:'1.1-1' }} Million
                                    </ng-template>
                                </span>
                            </div>
                            <div class="col-2"><span class="font-size"> {{ list.growth | number:'1.1-1' }} % Down</span></div>
                            <div class="col" style="text-align: right">
                                <!-- <span style="margin-right: 30%;"><i class="fas fa-pencil-alt font-size"></i></span> -->
                                <span (click)="deleteGainer(summaryPage.dbData.companiesList.secondSet.list, i)"><i class="far fa-trash-alt font-size"></i></span>
                            </div>
                        </div>
                        <div>
                            <br>
                            <mat-form-field class="text-area-red form-group">
                                <textarea matInput rows="7" cols="80px" name="description"
                                    [(ngModel)]="list.description">
                            </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
        
                <div class="containerStyling">
                    <div class="headers">
                        <mat-form-field style="width: 50%">
                            <input matInput type="text" class="textType" [(ngModel)]="summaryPage.dbData.companiesList.thirdSet.header"
                                style="font-weight: bold;font-size: 12px;">
                        </mat-form-field>
                        <button mat-raised-button class="addBtn"
                            (click)="openPopUpAddCompanies(companyList,'oldInvestments')">Add</button>
                    </div>
                    <div *ngFor="let list of summaryPage.dbData.companiesList.thirdSet.list;let i = index"
                        class="row-data" [class.border-top-radius]="i==0" [class.border-bottom-radius]="i== (summaryPage.dbData.companiesList.thirdSet.list.length - 1)" 
                        [class.list-item-border]="i < (summaryPage.dbData.companiesList.thirdSet.list.length - 1)" [style.background-color]="summaryPage.dbData.companiesList.thirdSet.color">
                        
                        <div class="row" style="margin-bottom: 10px;">
                            <div class="col-1"><img [src]="list.logo" (error)="logoSourceError($event, list)" *ngIf="list.logo;else defaultLogo" width="30" height="30">
                                    
                              <ng-template #defaultLogo>
                                  <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                              </ng-template>
                            </div>
                            <div class="col-4"><span class="font-size"> {{ list.name }} </span></div>
                            <div class="col-3">
                                <span class="font-size"> 
                                    <ng-template [ngIf] = "list.investment > 1000 && autoNumberFormat">
                                        USD {{ (list.investment / 1000) | number:'1.1-1' }} Billion
                                    </ng-template>
                                    <ng-template [ngIf] = "!autoNumberFormat || list.investment < 1000">
                                        USD {{ list.investment | number:'1.1-1' }} Million
                                    </ng-template>
                                </span></div>
                            <div class="col-2"><span class="font-size"> {{ list.growth | number:'1.1-1' }} % Up</span></div>
                            <div class="col" style="text-align: right">
                                <!-- <span style="margin-right: 30%;"><i class="fas fa-pencil-alt font-size"></i></span> -->
                                <span (click)="deleteGainer(summaryPage.dbData.companiesList.thirdSet.list, i)"><i class="far fa-trash-alt font-size"></i></span>
                            </div>
                        </div>
                        <div>
                            <br>
                            <mat-form-field class="text-area-orange form-group">
                                <textarea matInput rows="7" cols="80px" name="description" [(ngModel)]="list.description">
                                </textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col" style="border: solid #e3e6e3 1px; width: 300px; max-width: 400px;">
                <span><b>Value Change Break Up (Company View)</b></span><br>
                <ng-template [ngIf]="pieGraphData && pieGraphData.length > 0">
                    <!-- <span>
                        <ng-template [ngIf]="portfolioService.topGainers.length>0">
                            {{ portfolioService.topGainers[0].companyName }} attributed to {{ portfolioService.topGainers[0].changeInValPercentage * 100 | number:'1.1-1' }}% of the value profit
                        </ng-template>
                        <ng-template [ngIf]="portfolioService.topGainers.length == 0 && portfolioService.topLosers.length > 0">
                            {{ portfolioService.topLosers[0].companyName }} attributed to {{ portfolioService.topLosers[0].changeInValPercentage * 100 | number:'1.1-1' }}% of the value loss
                        </ng-template>
                    </span> -->
                    <div style="width: 380px;" [chart]="pieGraph"></div>
                    <div class="pieData">
                        <span style="font-size: 10px;">Total value change</span><br>
                        <span>
                            <ng-template [ngIf] = "totalValueChange > 1000 && autoNumberFormat">
                                USD {{ (totalValueChange / 1000) | number:'1.1-1' }} Billion
                            </ng-template>
                            <ng-template [ngIf] = "!autoNumberFormat || totalValueChange < 1000">
                                USD {{ totalValueChange | number:'1.1-1' }} Million
                            </ng-template>
                        </span>
                        <br>
                        <span>(5% up)</span>
                    </div>
                </ng-template>
        
                <span><b>Value Change Break Up (Sector View)</b></span><br>
                <ng-template [ngIf]="squareGraphData && squareGraphData.length > 0">

                    <!-- <span>{{ squareGraphData[0].name }} is the most impacted sector with {{ squareGraphData[0].value * 100 | number: '1.1-1' }}% change </span> -->
                    
                    <div style="width: 380px;" [chart]="squareGraph"></div>
                    <br><br>
                </ng-template>
        
                <!-- <span class="head"><b>Currency Impact on the Portfolio</b></span><br>
                <span class="subHead">10% Value Change Impact (Negative)</span><br><br>
                <div>
                    <mat-chip-list>
                        <mat-chip class="chip">
                            EUR/USD
                        </mat-chip>
                        <mat-chip class="chip" style="background: #5FAFF2">
                            GBP/USD
                        </mat-chip>
                        <mat-chip class="chip" style="background: #5FAFF2">
                            INR/USD
                        </mat-chip>
                    </mat-chip-list>
                </div><br>
                <figure class="highcharts-figure">
                    <div style="width: 380px;" id="timeSeries123"></div>
                </figure>
                
                <br>
                <div>
                    <span class="head"><b>Portfolio Performance</b></span><br>
                    <span class="subHead">Portfolio return was 2% above the benchmark index return</span><br><br>
                    <div style="width: 380px;" [chart]="portFolioChart"></div>
                </div> -->
                
                <br>
                <div class="right-graph-block">
                    <span class="head"><b>Geographical Breakdown</b></span><br>
                    <!-- <span class="subHead">United Kingdom was 3% down being the most impacted country</span><br><br> -->
                    <div style="width: 380px;" [chart]="worldRegionChart"></div>
                </div>
                <!-- <br> -->
        
                <!-- <div class="right-graph-block">
                    <span class="head"><b>Investments</b></span>
                    <br>
                    <span class="subHead">{{summaryPage.nonDbData.investments.subHeader}}</span><br><br>
                    <table class="table borderless">
                        <tr *ngFor="let logos of summaryPage.nonDbData.investments.logos;let i=index">
                            <td style="padding-top: 3%;">
                              <img [src]="logos.logo" width="30" height="30" (error)="logoSourceError($event, logos)" *ngIf="logos.logo; else defaultLogo">
                                    
                              <ng-template #defaultLogo>
                                <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                              </ng-template>
                            </td>
        
                            <td [chart]="summaryPage.nonDbData.investments.barCharts[i]" style="width: 300px;"></td>
                        </tr>
                    </table>
                    <div style="float: left;margin-left: 5%;">
                        <span class="rectBlue"></span><br>
                        Valuation
                    </div>
                    <div style="float: right;margin-right: 5%;">
                        <span class="rectYellow"></span><br>
                        Investment Amount
                    </div>
                </div><br> -->
                <!-- <br><br> -->
                <!-- <div class="right-graph-block">
                    <span class="head"><b>Exits</b></span>
                    <button style="margin: 0 5px; float: right;" mat-raised-button class="primary-bg" (click)="openPopUpModel(exitCompany)">Add</button>
                    <br>
                    <ng-template [ngIf]="summaryPage.dbData.exits.list && summaryPage.dbData.exits.list.length > 0" >
                        <span class="subHead">{{summaryPage.dbData.exits.subHeader}}</span><br><br>
                        <table class="table borderless">
                            <ng-template ngFor [ngForOf]="summaryPage.dbData.exits.list" let-exitComp let-ei="index">
                                <tr>
                                    <td style="padding-top: 3%;">
                                      <img [src]= "exitComp.logo" width="30" height="30" (error)="logoSourceError($event, exitComp)" *ngIf="exitComp.logo; else defaultLogo">
                                    
                                      <ng-template #defaultLogo>
                                          <i class="fas fa-landmark" style="font-size: xx-large;"></i>
                                      </ng-template>
                                    </td>
                                    <td [chart]="summaryPage.nonDbData.exits.barCharts[ei]" style="width: 200px;"></td>
                                    <td [chart]="summaryPage.nonDbData.exits.pieCharts[ei]"></td>
                                </tr>
                            </ng-template>
                        </table>
        
                        <div style="float: left;margin-left: 5%;">
                            <span class="rectBlue"></span><br>
                            Valuation
                        </div>
                        <div style="float: right;margin-right: 5%;">
                            <span class="rectYellow"></span><br>
                            Investment Amount
                        </div>
                        <br><br>
        
                        <div style="float: left;margin-left: 5%;">
                            <div [chart]="smallPieChartsLegends[0]"></div>
                            Partial Exit
                        </div>
                        <div style="float: right;margin-right: 20%;">
                            <div [chart]="smallPieChartsLegends[1]"></div>
                            Full Exit
                        </div>
                    </ng-template>
                    <br>
                </div><br> -->
            </div>
        </div>
    </div>
</div>

<!-- modal popUp-->
<ng-template #companyList let-companyList>
    <div class="modal-header">
        <div class="modal-title">

        </div>
        <button type="button" class="close" aria-label="Close" (click)="companyList.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      
        <div class="table table-condensed" id="container">
            <table class="a">
                <tr class="bg primary-bg">
                    <th style="width: 40px"></th>
                    <th style="width: 200px">Name</th>
                    <th style="width: 100px">Investment Date</th>
                    <th style="width: 100px"> Geography </th>
                    <th style="width: 100px">Sector</th>
                    <th style="width: 150px">Fair Value</th>
                </tr>
            </table>
        </div>

        <div class="table table-condensed inv-table-scrollable" id="container">
            <table class="a">
                <ng-template ngFor [ngForOf]="portfolioService.portfolioData" let-comp let-rowIndex="index">
                    <tr>
                        <td style="width: 40px">
                            <mat-checkbox [(ngModel)]="comp['checked']"></mat-checkbox>
                        </td>
                        <td style="width: 200px">
                          <span class="p-company-name">{{comp.companyName}}</span>
                        </td>
                        <td style="width: 100px">{{comp.investmentDate | date: "MMM, dd yyyy"}}</td>
                        <td style="width: 100px">
                            <ng-template [ngIf]="comp.geography">{{comp.geography.name | uppercase}}</ng-template>
                        </td>
                        <td style="width: 100px">{{comp.sector}}</td>
                        <td style="width: 100px">
                            <ng-template [ngIf]="comp.fairVal != 0">{{comp.fairVal| number: "1.1-1"}}</ng-template>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </div>

        <div style="text-align: center;">
            <button mat-raised-button class=" primary-bg" type="button" style="margin-right: 2%;"
                (click)="addToList()">Add</button>
        </div>
    </div>
</ng-template>

<ng-template #exitCompany let-exitCompany>
    <div class="modal-header">
        <div class="modal-title">
        </div>
        <button type="button" class="close" aria-label="Close" (click)="exitCompany.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row mt-2">
            <div class="col">
                <mat-label> Company Name </mat-label>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [(ngModel)]="exitsObj.companyName" name="companyName"  type="text">
                </mat-form-field>
            </div>
        </div>        
        <div class="row mt-2">
            <div class="col">
                <mat-label> Website </mat-label>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [(ngModel)]="exitsObj.website" name="website" type="text">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <mat-label> Valuation </mat-label>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [(ngModel)]="exitsObj.valuation" name="valuation" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <mat-label> Investment Amount </mat-label>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [(ngModel)]="exitsObj.investmentAmount" name="investmentAmount" type="number">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <mat-label> Exit Type </mat-label>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-select [(ngModel)] ="exitsObj.exitType">
                        <mat-option value="" disabled>Select</mat-option>
                        <mat-option value="partialExit">Partial Exit</mat-option>
                        <mat-option value="fullExit">Full Exit</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-3">
            <button style="margin-left: 80%;" mat-raised-button class="primary-bg" [disabled]="exitsObj.companyName=='' || exitsObj.website=='' || exitsObj.exitType==''" (click)="createExitCompanyList(); exitCompany.dismiss('Cross click')">
                Add
            </button>
        </div>
    </div>
</ng-template>