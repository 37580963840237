import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { QXI_PortFolioService } from '../qxi-portfolio.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { cloneDeep } from 'lodash';

import * as moment from 'moment';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { CCMServiceV3 } from '../../portfolio/valuation-algorithms/ccm-new-v3.service';
import { QXI_ValuationAlgorithmService } from '../qxi-valuation-algorithms/qxi-valuation-algorithms.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

@Component({
  selector: 'app-qxi-investment-page-summary',
  templateUrl: './qxi-investment-page-summary.component.html',
  styleUrls: ['./qxi-investment-page-summary.component.scss']
})
export class QXI_InvestmentPageSummaryComponent implements OnInit {
  @Input() companyDescription = "";
  
  bubbleChartData: any
  waterFallChartData: any
  areaChart
  impactCapitalExpDescription
  impactRevenueDescription
  impactMarginExpDescription
  plannedData
  selectedCompanyDates;
  logoLink
  fundCompanyDetails:any

  masterPartnersList = [];

  compPerformanceNumbers = { rows: [], headers: [], aggregations: {}};

  latestValData;
  latestValuationDate;
  valuationAlgoData

  investmentDateCCM;
  valuationDateCCM;

  areaChartData = {
    thirdQuartile: [],
    firstQuartile: [],
    median: [],
    average: [],
    subjectComp: [],
    categories: [],
    revenue: [],
    ebitda: [],
    ebitdaMargin: []
  }
  marketMultiple;

  revenue = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  grossProfit = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }
  
  gpMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitda = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitdaMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebit = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  ebitMargin = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  netDebt = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  cashBalance = {
    actualModel: 0,
    budgetModel:0,
    varModel:0,
    ytdActualModel:0,
    ytdBudgetModel:0,
    ytdVarModel:0
  }

  realisedProceeds = {
    date:'',
    value: 0
  }

  summaryNumbers = {} as any;

  waterFallNumbers = [];

  multipleRealisedProceeds = [];

  multipleInvestmentAmount = [];

  latestFormData;

  userSelectedCurrency;

  currencyExchange = {
    exchangeRate: 1,
    convertedValue: 0
  }


  investmentAmount = {
    date:'',
    value: 0
  }

  selectedIndex;
  date: null;
  value : 0;

  orgId;

  savedData = {
    comments: [{title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}, {title: "", comment: ""}],
    significantEventMarket: "",
    significantEventCompany: "",
    performance: { revenue: {}, grossProfit: {}, gpMargin: {}, ebitda: {}, ebitdaMargin: {}, ebit: {}, ebitMargin: {} },
    partners: [],
    fullyDilutedOwnership : 0,
    securityType: ''
  }

  valuationBarChart

  constructor(public ccmService: CCMServiceV3,    public portfolioService: QXI_PortFolioService, 
              private utilService: UtilService,   private datePipe: DatePipe,
              private modalService:NgbModal,      private ds: DataService,
              private valuationAlgorithms: QXI_ValuationAlgorithmService,
              private currencyExchangeService : CurrencyExchangeService,
              public imageLoadService: ImageLoadService, private ums: UserManagementService) 
  { }

  async ngOnInit() {
    this.orgId = this.ums.getSelectedUserDetails().organization.id;
    try {
      const res = await this.ds.getWidgetDataFromDB("PARTNER_MASTER_LIST", this.orgId).toPromise();
      if(res){
        this.masterPartnersList = res["body"]["response"][0].widgetData;
      }
      
    } catch (error) {
      console.log("Failed to fetch the master partner list", error);
    }
    // this.ds.getWidgetDataFromDB("PARTNER_MASTER_LIST", this.orgId).subscribe(res => {
    //   this.masterPartnersList = res.body["response"][0].widgetData;
    // }, error => {
    //   console.log("Failed to fetch the master partner list", error);
    // })

    this.ds.getWidgetDataFromDB("INVESTMENT_SUMMARY", this.portfolioService.companyId).subscribe(res =>{
      this.savedData = res.body["response"][0].widgetData;
      this.portfolioService.fullyDilutedOwnership = this.savedData.fullyDilutedOwnership;
      this.portfolioService.securityType = this.savedData.securityType;
      const partnerNames = this.masterPartnersList.map( m => m.name.toLowerCase());
      this.savedData.partners = this.savedData.partners.filter( s => {
        return partnerNames.indexOf(s.name.toLowerCase()) >= 0
      })
      this.masterPartnersList.forEach(m => {
        m.isSelected = false;
        this.savedData.partners.forEach( s => {
          if(s.name.toLowerCase() === m.name.toLowerCase()){
            m.isSelected = s.isSelected;
          }
        })
      })
    }, error =>{
      this.masterPartnersList.forEach( m => {
        if(m.isSelected){
          m.isSelected = !m.isSelected;
        }
      })
      console.log("Failed to fetch Investment Summary details", error)
    })

    this.ds.getformDetails(this.portfolioService.companyId).subscribe(res=>{
      const formDetails = res.body['response'].details;
      this.portfolioService.formDetailsObj = JSON.parse(formDetails);
    }, error =>{
      console.log("Failed to fetch the selected form id details", error)
    })

    this.getSelectedCompany(this.portfolioService.companyId);
    this.latestValData = this.portfolioService.portfolioData.filter(comp => comp.id == this.portfolioService.companyId);
    this.latestValuationDate = new Date(this.latestValData[0].valuationDate);
    
    this.initwaterFallChart();
    let valuationDateForms = this.portfolioService.getSelectedCompanyDates(this.portfolioService.companyId)
    
    if(valuationDateForms.length > 1){
      valuationDateForms = valuationDateForms.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      });
    }
          
    this.portfolioService.getSelectedCompanyOrgDetails(this.utilService.getDomainName(this.portfolioService.selectedForm[0].website), this.companyDescription)
    
    this.prepareCharts();
    this.initBarChart(valuationDateForms);

    this.ds.getformDetails(this.portfolioService.companyId).subscribe(res=>{
      const formDetails = res.body['response'].details;
      this.portfolioService.formDetailsObj = JSON.parse(formDetails);
    }, error =>{
      console.log("Failed to fetch the selected form id details")
    })
  }

  initBarChart(valuationDateForms) {
    const __this = this;
    valuationDateForms = valuationDateForms.filter(c => c.status !== 'Initiated');

    this.valuationBarChart = new Chart({
      chart: { type: 'bar', height: 250 },
      title: { text: '' },
      xAxis: { 
        visible: true, 
        categories: valuationDateForms.map((data) => { return this.datePipe.transform(data.valuationDate, "mediumDate") }) 
      },
      yAxis: { title: { text: '' }, visible: false },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        enabled: false,
        formatter(event) {
          return this.point.series.name + " (" + this.y + ")";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: { enabled: true, formatter: function() { return __this.latestValData[0].currency + " " + (+this.y.toFixed(0)).toLocaleString()} },
        },
        series: {
          cursor: 'pointer',
          events: {
            click: (e) => {
              this.portfolioService.openValuation(valuationDateForms[e.point["index"]]);
            }
          }
        }
      },
      series: [
        { name: "", 
          data: valuationDateForms.map((data) => { 
            return Number(data.recoverable) 
          }), 
          color: "#1D1563" 
        },
      ] as any
    });
  }

  openModalPopUp(content, index){
    this.selectedIndex = index;
    this.modalService.open(content, {backdrop: 'static', size: 'lg', centered: true });
  }

  initBubbleChart(valuationData) {
    try {
      const chartValues = [];
      const similarCompanies = JSON.parse(valuationData[this.portfolioService.companyId].CCM).similarCompanies;

      similarCompanies.forEach(comp => {
        const marketCap = comp.market_cap ? +comp.market_cap : 0;

        if(+comp.bevRevenue[0] > 0 && +comp.bevRevenue[0] < 500 && +comp.bevEbitda[0] > 0 && +comp.bevEbitda[0] < 500) {
          chartValues.push([
            +comp.bevRevenue[0], +comp.bevEbitda[0], marketCap
          ])
        } else {
          // console.log("No Bubble ", comp.company_name, comp.bevRevenue[0], comp.bevEbitda[0]);
        }
      });

      const __this = this;

      this.bubbleChartData = new Chart({
        chart: {
          type: 'bubble', zoomType: 'xy', height: 300
        },
        title: { text: '' },
        credits: { enabled: false },
        exporting: { enabled: false },
        tooltip : {
          formatter: function() {
            const compName = similarCompanies[this.point["index"]].company_name;

            return '<b>' + compName + '</b>' +
              '<br><b>EBITDA Multiple (x)</b> : ' + this.y.toLocaleString() + 
              '<br><b>Revenue Multiple (x)</b> : ' + this.x.toLocaleString() +
              '<br><b>Market Cap ('+ __this.latestValData[0].currency +' Mn)</b> : ' + this.point["z"].toLocaleString();
          }
        },
        xAxis: {
          title:{ text: 'Revenue Multiple' },
          gridLineWidth: 1,
          gridLineColor: '#BEBEBE',

          labels: { formatter: function () { return  this.value + ' x' } }
        },
        yAxis: {
          title:{ text: 'EBITDA Multiple' },
          gridLineWidth: 1,
          gridLineColor: '#BEBEBE',

          labels: { formatter: function () { return  this.value + ' x' } }
        },
        series: [
          {
            data: chartValues,
            name: "Market Cap ("+ __this.latestValData[0].currency +" Mn)"
          }
        ] as any
      })
    } catch (e) {
      console.log("Error while creating bubble graph")
    }
  }

  getCCMComparableCompanies() {
    
    const compPerformanceNumbers = {};
    const headers = [];
    const aggregations = { average: [], median: [], q1: [], q3: []}

    let allDates: string [] = Object.keys(this.valuationAlgoData);
    allDates = allDates.reverse();

    allDates.forEach( (valDateId, valDateIndex) => {
      const selectedForm = this.portfolioService.companies.find(f => f.id === valDateId);
      
      headers.push(selectedForm.valuationDate);

      if(this.valuationAlgoData[valDateId].CCM){
        const ccmAlgoSimilarCompanies = JSON.parse(this.valuationAlgoData[valDateId].CCM).similarCompanies.filter(c => !c.disabled);

        ccmAlgoSimilarCompanies.forEach( comp => {
          if(!compPerformanceNumbers[comp.ticker]) {
            compPerformanceNumbers[comp.ticker] = [comp.company_name];
          }

          for(let i = (compPerformanceNumbers[comp.ticker].length - 1); i<valDateIndex; i++) {
            compPerformanceNumbers[comp.ticker].push(0);
          }

          compPerformanceNumbers[comp.ticker].push(comp['bevRevenue'][0]);
        })

        const aggrValues = JSON.parse(this.valuationAlgoData[valDateId].CCM).multipleType.bevRevenue.aggregations;
        aggregations.average.push(aggrValues.average[0])
        aggregations.median.push(aggrValues.median[0])
        aggregations.q1.push(aggrValues.q1[0])
        aggregations.q3.push(aggrValues.q3[0])
      }
    })

    this.compPerformanceNumbers.rows = Object.values(compPerformanceNumbers).sort( (r1: any, r2: any) =>{
      const r1ValidNumbers = r1.filter( v => v > 0 );
      const r2ValidNumbers = r2.filter( v => v > 0 );

      if(r1ValidNumbers.length === allDates.length) return -1;
      if(r2ValidNumbers.length === allDates.length) return 1;
      return 0;
    });

    this.compPerformanceNumbers.aggregations = aggregations;
    this.compPerformanceNumbers.headers = headers;

    this.summaryNumbers.bevRevenue = aggregations.median[aggregations.median.length - 1]
  }

  getSelectedCompany(companyId) {
    this.portfolioService.selectedForm = this.portfolioService.companies.filter(comp => comp.id == companyId);
  }

  initwaterFallChart() {
    this.waterFallChartData = new Chart({
      chart: {
        type: 'waterfall',
        backgroundColor: "#e6e6e6",
        events: { 
          load: function() {
            this.renderer.text('Under Construction', 300, 100)
              .css({
                  color: '#5FAFF2',
                  fontSize: '20px'
              })
              .add()
          }
        }        
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend:{ enabled: false},

      xAxis: {
        type: 'category',
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      yAxis: {
        title: { text: '' },
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      plotOptions: {
        series: { stacking: 'normal' },
        waterfall: {
          negativeColor: '#EEFFC6'
        }
      },
      tooltip: {
        formatter: function () {
            return '<b>' + this.point.name + '</b> : ' + this.y.toLocaleString();
        }
      },
      series: [{
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: [],
        pointPadding: 0
      } as any]
    })

    // console.log("waterfall", this.waterFallNumbers, this.waterFallChartData)
  }

  getMockNumbers() {
    const waterFallNumbers = [];
    const currentCompanyDate = this.portfolioService.companies.filter(comp=> comp.id === this.latestValData[0].id);
    const val1 = this.latestValData[0].valuation;
    const val2 = this.latestValData[0].fairVal;

    waterFallNumbers.push({name: moment(this.latestValData[0].investmentDate).format("MMM DD, YYYY"), y: this.latestValData[0].valuation})
    // waterFallNumbers.push({name: 'Balance Sheet', y: (val2 - val1)* 0.408})
    // waterFallNumbers.push({name: 'Multiples', y: (val2 - val1)* 1.266 })
    // waterFallNumbers.push({name: 'Control Premium', y: (val2 - val1)* 1.853 })
    // waterFallNumbers.push({name: 'Multiplication Acquisition Factor', y: (val2 - val1)* 1.847 * -1})
    // waterFallNumbers.push({name: 'General Inputs', y: (val2 - val1)* 0.339})
    // waterFallNumbers.push({name: 'DCF Assumptions', y: (val2 - val1)* 1.069 * -1 })
    // waterFallNumbers.push({name: 'Others', y: (val2 - val1)* 0.05})
    // waterFallNumbers.push({name: 'Multiplication Acquisition Factor', y: (val2 - val1)* 0.45})
    // waterFallNumbers.push({name: 'Weights', y: (val2 - val1)* 0.20 * -1 })
    // waterFallNumbers.push({name: 'Merger & Acquisition', y: (val2 - val1)* 0.30 })
    // waterFallNumbers.push({name: 'General Inputs', y: (val2 - val1)* 0.30 })
    // waterFallNumbers.push({name: 'DCF Assumptions', y: (val2 - val1)* 0.14 })
    waterFallNumbers.push({name: 'Revenue Growth', y: 323 })
    waterFallNumbers.push({name: 'EBITDA Margin', y:  647 })
    waterFallNumbers.push({name: 'Net Debt Reduction', y: 152 })
    waterFallNumbers.push({name: 'Multiple Expansion', y: 1045 })
    waterFallNumbers.push({name: 'Change In Discount Rate & TVM', y: 334 })
    waterFallNumbers.push({name: 'Gross Equity Value', isIntermediateSum: true })
    waterFallNumbers.push({name: 'FX Impact', y: -436})
    waterFallNumbers.push({name: 'Other', y: 698 })
    waterFallNumbers.push({name: moment(this.latestValData[0].latestValuationDate).format("MMM DD, YYYY"), y: this.latestValData[0].fairVal - 567, isSum: true })
    return waterFallNumbers;
  }

  createMarketMultipleChart(userSelected) {
    this.areaChart = new Chart({
      chart: { type: 'area', backgroundColor: "#e6e6e6", height: 300 },
      title: { text: '' },
      legend:{ layout: 'horizontal' },
      xAxis: { categories: this.areaChartData.categories.reverse(), gridLineWidth: 1, gridLineColor: '#BEBEBE' },
      yAxis: { title:{ text: ''} , labels: { format: '{value}x' }, gridLineWidth: 1, gridLineColor: '#BEBEBE'},
      tooltip: { enabled: false },
      credits: { enabled: false },
      plotOptions: {
        area: {
          stacking: 'normal', pointStart: 0,
          marker: {
            enabled: false, symbol: 'circle', radius: 2,
            states: { hover: { enabled: true } }
          }
        }
      },
      exporting: { enabled: false },
      series: [{
        name: "3rd Quartile",
        data: this.areaChartData.thirdQuartile.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q3[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q3[1]],
        type: 'area',
        color: '#EEFFC6',
      }, {
        name: '1st Quartile',
        data: this.areaChartData.firstQuartile.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.q1[1],this.valuationDateCCM.multipleType[userSelected].aggregations.q1[1]],
        type: "area",
        color: "transparent"
      }, {
        name: ' Median',
        type: "spline",
        data: this.areaChartData.median.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.median[1],this.valuationDateCCM.multipleType[userSelected].aggregations.median[1]],
        color: "#5FAFF2"

      }, {
        name: ' Average',
        type: "spline",
        data: this.areaChartData.average.reverse(),
        // data: [this.investmentDateCCM.multipleType[userSelected].aggregations.average[1],this.valuationDateCCM.multipleType[userSelected].aggregations.average[1]],
        color: "#FFD32B"

      }, {
        name: 'Subject Company',
        type: "spline",
        data: this.areaChartData.subjectComp.reverse(),
        // data: [this.investmentDateCCM.subjectCompany[userSelected][1], this.valuationDateCCM.subjectCompany[userSelected][1]],
        color: "#1D1563"
      }]
    });
  }

  openPopUpModel(content) {
    this.modalService.open(content, { centered: true, size: 'lg' })
  }

  async getCurrencyExchange(value, srcCurr, userSelCurr, valuationDate){
    if(!userSelCurr){
      userSelCurr = srcCurr
    }
    let conversionMultiple = 1;
    if(this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate]){
      conversionMultiple = this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate];
    }
    else{
      try {
        const currConverter = await this.ds.getValuationDateCurrency(userSelCurr, srcCurr, valuationDate).toPromise();
        conversionMultiple = currConverter.body['response'].value;
        this.currencyExchangeService.exchangeRates[userSelCurr + '' + srcCurr+ '' + valuationDate] = conversionMultiple;
      } 
      catch (error) {
        console.log("Error while fetching exchange rates", error)
      }
    }
    this.currencyExchange.exchangeRate = conversionMultiple;
    this.currencyExchange.convertedValue = value * conversionMultiple;
  }

  currencyMultiple(converter){
    this.value = this.value? this.value : 0;
    this.userSelectedCurrency = converter.userSelectedCurrency
    this.currencyExchange.exchangeRate = converter.conversionMultiple;
    this.currencyExchange.convertedValue = this.value * converter.conversionMultiple;
  }

  addRealisedProceeds(){
    if( this.date && this.value){
      const realisedProceedsObj = {
        date: '',
        value: 0,
        sourceCurrency: this.latestValData[0].currency,
        userSelectedCurrency: this.userSelectedCurrency ? this.userSelectedCurrency : this.latestValData[0].currency,
        exchangeRate: this.currencyExchange.exchangeRate,
        valueBeforeConversion: this.value,
        exchangeValue: this.currencyExchange.convertedValue,
      }
      realisedProceedsObj.date = this.date
      realisedProceedsObj.value = this.currencyExchange.convertedValue
  
      if(!this.portfolioService.formDetailsObj.multipleRealisedProceeds){
        this.portfolioService.formDetailsObj.multipleRealisedProceeds = [];
      }
      this.portfolioService.formDetailsObj.multipleRealisedProceeds.push(realisedProceedsObj);

      this.date = null;
      this.value = 0;
      this.userSelectedCurrency = '';
      this.currencyExchange = {
        exchangeRate: 1,
        convertedValue: 0
      }
      let length = this.portfolioService.formDetailsObj.multipleRealisedProceeds.length;
      if( length > 0){
        if(!this.portfolioService.formDetailsObj.totalRealisedProceeds){
          this.portfolioService.formDetailsObj.totalRealisedProceeds = 0; 
        }
        this.portfolioService.formDetailsObj.totalRealisedProceeds += this.portfolioService.formDetailsObj.multipleRealisedProceeds[length-1].value;
      }

      this.portfolioService.selectedForm[0].details = this.portfolioService.formDetailsObj;
    }

    this.portfolioService.selectedForm[0].details = this.portfolioService.formDetailsObj;
    // this.portfolioService.calculateMultiples();
  }

  addInvestmentAmount(){
    if(this.date && this.value){
      const investmentAmountObj = {
        date: '',
        value: 0,
        sourceCurrency: this.latestValData[0].currency,
        userSelectedCurrency: this.userSelectedCurrency ? this.userSelectedCurrency : this.latestValData[0].currency,
        exchangeRate: this.currencyExchange.exchangeRate,
        valueBeforeConversion: this.value,
        exchangeValue: this.currencyExchange.convertedValue,
      }
      investmentAmountObj.date = this.date;
      investmentAmountObj.value = this.currencyExchange.convertedValue;
  
      if(!this.portfolioService.formDetailsObj.multipleInvestmentAmount){
        this.portfolioService.formDetailsObj.multipleInvestmentAmount = [];
      }
      this.portfolioService.formDetailsObj.multipleInvestmentAmount.push(investmentAmountObj);


      this.date = null;
      this.value = 0;
      this.userSelectedCurrency = '';
      this.currencyExchange = {
        exchangeRate: 1,
        convertedValue: 0
      }
      let length = this.portfolioService.formDetailsObj.multipleInvestmentAmount.length;
      if(length > 0){
        if(!this.portfolioService.formDetailsObj.totalInvestmentAmount){
          this.portfolioService.formDetailsObj.totalInvestmentAmount = 0; 
        }
        this.portfolioService.formDetailsObj.totalInvestmentAmount += this.portfolioService.formDetailsObj.multipleInvestmentAmount[length-1].value
      }

      this.portfolioService.selectedForm[0].details = this.portfolioService.formDetailsObj;
    }
    
    this.portfolioService.selectedForm[0].details = this.portfolioService.formDetailsObj;
    // this.portfolioService.calculateMultiples();
  }

  delete(type, index){
    if(type == "realisedProceeds"){
      const deleteObj = this.portfolioService.formDetailsObj.multipleRealisedProceeds.splice(index, 1);
      this.portfolioService.formDetailsObj.totalRealisedProceeds -= deleteObj[0].value > 0 ? deleteObj[0].value : -(deleteObj[0].value);
      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    }
    else{
      const deleteObj  = this.portfolioService.formDetailsObj['multipleInvestmentAmount'].splice(index, 1);
      this.portfolioService.formDetailsObj.totalInvestmentAmount -= deleteObj[0].value > 0 ? deleteObj[0].value : -(deleteObj[0].value);
      this.portfolioService.selectedForm[0].details = cloneDeep(this.portfolioService.formDetailsObj);
    }
  }

  saveData(){
    this.ds.saveWidgetDataToDB("INVESTMENT_SUMMARY", this.savedData, this.portfolioService.companyId).subscribe(res =>{
      this.utilService.showMessage("Summary is saved successfully.", "Ok");
    }, error =>{
      this.utilService.showMessage("Failed to save summary", "Ok");
    })

    this.ds.saveWidgetDataToDB("PARTNER_MASTER_LIST", this.masterPartnersList, this.orgId).subscribe(res => {
      // console.log("Master Partner list saved successfully");
    }, error => {
      console.log("Error while saving Master Partner list", error);
    })

  //  this.portfolioService.selectedForm[0].details = this.portfolioService.formDetailsObj;
    if(this.portfolioService.formDetailsObj.multipleInvestmentAmount && this.portfolioService.formDetailsObj.multipleInvestmentAmount.length > 0){
      this.portfolioService.calculateMultiples();
    }else{
      this.ds.saveFormDetails(this.portfolioService.selectedForm[0].details).subscribe(data => {
        // console.log("Form Details is Saved Successfully", data);
      }, error => {
        console.log("Failed to save the Form", error);
      })
    }

  }
  
  addPlanned(form: NgForm) {
    // console.log("data items", this.savedData.performance)
  }

  async prepareCharts() {
    
    this.selectedCompanyDates = this.portfolioService.getSelectedCompanyDates( this.portfolioService.companyId );

    const selectedCompIds = this.selectedCompanyDates.map(comp => comp.id);

    const valuationDateResponse = await this.ds.getValuationAlgo(selectedCompIds).toPromise();

    const valuationData = valuationDateResponse.body["response"];

    this.valuationAlgoData = valuationData;

    this.prepareWaterFallChart(valuationData, this.selectedCompanyDates);
    this.initBubbleChart(valuationData);

    this.prepareAreaChart('bevEbitda');
    this.summaryNumbers.subjectCompBevEbitda = this.areaChartData.subjectComp[this.areaChartData.subjectComp.length - 1];
    this.summaryNumbers.industryBevEbitda = this.areaChartData.median[this.areaChartData.median.length - 1];

    this.prepareAreaChart('bevRevenue');
    this.summaryNumbers.subjectCompBevRevenue = this.areaChartData.subjectComp[this.areaChartData.subjectComp.length - 1];
    this.summaryNumbers.industryBevRevennue = this.areaChartData.median[this.areaChartData.median.length - 1];
    this.summaryNumbers.revennue = this.areaChartData.revenue[0];
    this.summaryNumbers.ebitdaMargin = this.areaChartData.ebitdaMargin[0] * 100;
    this.summaryNumbers.ebitda = this.areaChartData.revenue[0] * this.areaChartData.ebitdaMargin[0];

    this.getCCMComparableCompanies();

    if(this.portfolioService.formDetailsObj.multipleInvestmentAmount){
      this.portfolioService.calculateMultiples();
    }
  }

  async prepareWaterFallChart(valuationData, forms){
    if(valuationData && Object.keys(valuationData).length > 1){

      const formVersion = forms[0].formVersion;

      const valuationDoneForms = Object.keys(valuationData);

      const formsInMap = valuationDoneForms.filter( formId => valuationData[formId].CCM && valuationData[formId].CTM && valuationData[formId].IA)
      
      if(formsInMap && formsInMap.length > 1) {
        const newFormId = formsInMap[formsInMap.length - 1];
        const oldFormId = formsInMap[formsInMap.length - 2];

        const oldForm = forms.find(f => f.id == oldFormId);

        const newForm = forms.find(f => f.id == newFormId);
        
        this.initwaterFallChart();
        this.valuationAlgorithms.initWaterFallBetweenValuationDates(this.waterFallChartData, valuationData[oldFormId], valuationData[newFormId], oldForm, newForm);
      }
    }
  }
  
  getTotalEquityValue(ccmEquity, ctmEquity, IAEquity){
    return ((ccmEquity + ctmEquity + IAEquity)/3);
  }

  async prepareAreaChart(userSelected){ 
    this.areaChartData = {
      thirdQuartile: [],
      firstQuartile: [],
      median: [],
      average: [],
      subjectComp: [],
      categories: [],
      revenue: [],
      ebitda: [],
      ebitdaMargin: []
    }

    if(this.valuationAlgoData && Object.keys(this.valuationAlgoData).length > 0){
      const valuationDoneForms = Object.keys(this.valuationAlgoData);
      const formsInMap = valuationDoneForms.filter( formId => this.valuationAlgoData[formId].CCM);

      if(formsInMap && formsInMap.length > 0){

        formsInMap.forEach((id, index) =>{
          const companyInvestmentData = this.selectedCompanyDates.find(c => c.id === id);

          this.areaChartData.categories.push((moment(companyInvestmentData.valuationDate)).format("MMM DD, YYYY"))

          const valDate = JSON.parse(this.valuationAlgoData[id].CCM);

          this.areaChartData.thirdQuartile.push(valDate.multipleType[userSelected].aggregations.q3[0]);
          this.areaChartData.firstQuartile.push(valDate.multipleType[userSelected].aggregations.q1[0]);
          this.areaChartData.median.push(valDate.multipleType[userSelected].aggregations.median[0]);
          this.areaChartData.average.push(valDate.multipleType[userSelected].aggregations.average[0]);

          this.areaChartData.subjectComp.push(companyInvestmentData.investment[userSelected] ? companyInvestmentData.investment[userSelected] : 0)

          this.areaChartData.revenue.push(valDate.subjectCompany['iaRevenue'][1])
          this.areaChartData.ebitda.push(valDate.subjectCompany['iaRevenue'][1])
          this.areaChartData.ebitdaMargin.push(valDate.subjectCompany['iaEbitdaMargin'][1])
        })

        this.createMarketMultipleChart(userSelected);
      }
    }
  }

  addPartnerList(){
    this.masterPartnersList.push(this.portfolioService.partnerList);
    if(this.portfolioService.partnerList.isSelected){
      if(!this.savedData.partners){
        this.savedData.partners = []
      }
      this.savedData.partners.push(this.portfolioService.partnerList);
    }
    this.portfolioService.partnerList = {
      name: '',
      isSelected: false
    }
  }

  deletePartnerName(index){
    const removedPartner = this.masterPartnersList.splice(index, 1);
    const deletedPartnerIndex = this.savedData.partners.findIndex( s => s.name.toLowerCase() === removedPartner[0].name.toLowerCase())
    if(deletedPartnerIndex >= 0){
      this.savedData.partners.splice(deletedPartnerIndex, 1);
    }
  }

  addFullyDilutedOwnership(){
    this.savedData.fullyDilutedOwnership = +this.portfolioService.fullyDilutedOwnership;
  }

  addSecurityType(){
    this.savedData.securityType = this.portfolioService.securityType;
    this.portfolioService.securityType = '';
  }

  addPartnerToCompany(event, list){
    if(event){
      if(!this.savedData.partners){
        this.savedData.partners = []
      }
      this.savedData.partners.push(list);
    }else{
      this.savedData.partners = this.masterPartnersList.filter( p => {
        return p.isSelected;
      });
    }
  }

  // savePartnerList(){
  //   this.ds.saveWidgetDataToDB("PARTNER_MASTER_LIST", this.masterPartnersList, this.orgId).subscribe(res => {
  //     console.log("Master Partner list saved successfully");
  //   }, error => {
  //     console.log("Error while saving Master Partner list", error);
  //   })
  // }
}
