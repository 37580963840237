import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { InvestmentSummaryService } from '../investment-summary.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserManagementService } from 'src/app/services/user-management.service';
import { TranslateService } from 'src/app/services/translation.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { UtilService } from 'src/app/utils/util.service';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-custom-attribute-popup',
  templateUrl: './custom-attribute-popup.component.html',
  styleUrls: ['./custom-attribute-popup.component.scss']
})
export class CustomAttributePopupComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() companyId;
  @Input() generalAttributes
  @Input() latestValuationDate;
  @Input() popupState = "CLOSE"
  @Input() currency;
  @Input() showHideStatusOfGeneralAttributes = {}

  @Output() updated = new EventEmitter();

  @Output() updateGeneralAttrsStatus = new EventEmitter();

  @ViewChild('openManageAttrsPopup', { static: false }) openManageAttrsPopup: ElementRef;

  constructor(
    public invSummaryService : InvestmentSummaryService,
    public portfolioService : PortFolioSummaryServiceV2,
    public utilService : UtilService,
    private ds: DataService, 
    private modalService : NgbModal,
    public ums: UserManagementService,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) { }
  

  filteredAttributeOptions = [];
  attributeNamesInConfiguation = [];

  selectedValuationDateId;
  customAttributes = [];
  showErrorMsg = false;
  showDuplicateMsg = false;
  MAX_ALLOWED = 30;
  fundId = ""
  attributeType = "general";
  invalidCharLength = false;
  showErrorMessage = false;
  showDuplicateAttr = false;
  allCharacters;

  ngOnInit(){
    this.fundId = this.route.snapshot.queryParamMap.get('parentId');

    this.ds.getConfigurationDetails(this.fundId).subscribe(res =>{
      const configuration = res.body["response"].find(t => t.configuration=="customAttributes")

      if(configuration && configuration.value) {
        this.attributeNamesInConfiguation = JSON.parse(configuration.value);
      }
      this.initDefaultValuationDate();
    })
    
  }

  ngAfterViewInit(): void {
  }

  preparefilteredAttributesList(row, index){
    const value = !!row ? row.attributeName : "";
    this.prepareFilteredAttributeOptions(index);
    this.filteredAttributeOptions = this.filteredAttributeOptions.filter((attr) => attr.label.toLocaleLowerCase().includes(value.toLocaleLowerCase()));

    if(value?.length > 255){
      this.invalidCharLength = true;
    }
    else{
      this.invalidCharLength = false;
    }
  }

  prepareFilteredAttributeOptions(index){
    this.filteredAttributeOptions = [];
    if(this.attributeNamesInConfiguation && this.attributeNamesInConfiguation.length > 0){
      this.attributeNamesInConfiguation.forEach(attr => {
        const attributeNameExist = this.customAttributes.find((custAttr, i) => custAttr.attributeName.toLowerCase() == attr.label.toLowerCase() && index != i);
        if(!attributeNameExist){
          this.filteredAttributeOptions.push(attr);
        }
      });
    }
  }


  initDefaultValuationDate(): void {
    this.selectedValuationDateId = this.latestValuationDate.id
    this.setData()
    this.getCustomAttributeData() 
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes && 
      changes.popupState && 
      changes.popupState.currentValue == "OPEN"){
        this.openManageAttrsPopup.nativeElement.click(); 
    }
  }

  changeDate(){
    this.setData();
    this.getCustomAttributeData() 
  }

  setData(){
    this.customAttributes = this.getDefaultListOfAttributes();
  }

  openCustomAttributeModal(content){
    this.initDefaultValuationDate();
    this.modalService.open(content, { centered: true , windowClass: 'customAttribute-popup-modal',size:'lg'});
  }

  addNewCustomAttribute(){
    const currentMaxSequence = this.customAttributes.reduce((a,b)=>a.attributeSequence > b.attributeSequence ? a : b).attributeSequence;
    let obj = {
      "companyId": this.companyId,
      "valuationDateId": this.selectedValuationDateId,
      "attributeKey": "",
      "attributeName": "",
      "attributeValue": "",
      "attributeSequence": currentMaxSequence + 1,
      "show": false
    }

    if(this.customAttributes.length < this.MAX_ALLOWED){
      this.customAttributes.push(obj);
    }
    
    this.preparefilteredAttributesList(null, null);
  }


  saveCustomAttribute() {
    if(this.invalidCharLength == true){
      return
    }
    this.utilService.showLoadingPopup();
    const user = this.ums.getSelectedUserDetails();
    this.customAttributes.forEach(attr => {
      if(!attr.id) {
        attr.createdBy = user.id
      }

      const attrInConfig = this.attributeNamesInConfiguation.find(ac => attr.attributeName && ac.label.toLocaleLowerCase() == attr.attributeName.toLocaleLowerCase());
      
      if(attrInConfig) {
        attr.attributeKey = attrInConfig.id;
      } else {
        attr.attributeKey = attr.attributeKey ? attr.attributeKey : this.utilService.getUUID();
      }

      if(attr.attributeSequence > 3 && attr.attributeName == "" || attr.attributeName == null) {
        this.showErrorMsg = true;
      }
    });

    if(this.showErrorMsg == false) {

      this.customAttributes.forEach(ca => {
        delete ca?.showDuplicateMsg;
      })
      
      const requestBody = {
        attributes: this.customAttributes,
        valuationDateIds: this.invSummaryService.allValuationDatesWithVersions.map(v => v.id)
      }
      
      this.ds.saveCustomAttribute(requestBody).subscribe(res => {
        this.getCustomAttributeData();

        this.updated.next();
      }, error => {
        this.setData()
        this.utilService.closeAllPopups()
      })
    }
    this.selectedValuationDateId = this.latestValuationDate.id;
  }

  validateCustomAttributeName(row, index){
    const value = row.attributeName;
    setTimeout(() => {
      if(value == "" || value == null){
        //if attribute's name is blank - handling on UI already
      }else{
        //check if attribute's name duplicates any of the existing one!
        const attributeNameExists = this.customAttributes.find((attr, i) => attr.attributeName.toLowerCase() == value.toLowerCase() && index != i)
        if(attributeNameExists){
          row.showDuplicateMsg = true;
        }else{
          row.showDuplicateMsg = false;
        }
      }
    });
  }

  getCustomAttributeData() {
    this.invSummaryService.allCustomAttributes = null;

    this.ds.getCustomAttributeWidgetData(this.selectedValuationDateId).subscribe(res => {
      if (res.body["response"].length == 0) {
        this.setData();
      }
      else {
        const attributesSaved = res.body["response"];
        const defaultAttributes = this.getDefaultListOfAttributes();

        let finalListOfAttributes = [];

        // Add default attributes if not saved.
        defaultAttributes.forEach(a => {
          const attributeExistsInDB = attributesSaved.find(savedAttr => savedAttr.attributeKey == a.attributeKey);
          if(!attributeExistsInDB) {
            finalListOfAttributes.push(a);
          } else {
            finalListOfAttributes.push(attributeExistsInDB);
          }
        })

        // Add saved attributes excluding default attributes
        attributesSaved.forEach(a => {
          const attributeExists = finalListOfAttributes.find(attr => attr.attributeKey == a.attributeKey);
          if(!attributeExists) {
            finalListOfAttributes.push(a);
          }
        })

        //Rearrange sequnce
        finalListOfAttributes.forEach((a, i) => {
          a.attributeSequence = (i+1);
        })

        this.customAttributes = finalListOfAttributes;
        const user = this.ums.getSelectedUserDetails();

        this.customAttributes.forEach(attr => {
          delete attr.new;
          attr.lastModifiedBy = user.id;
        })

        this.prepareFilteredAttributeOptions(null);

        this.invSummaryService.allCustomAttributes = this.customAttributes;
      }

      this.utilService.closeAllPopups()
    }, error => {
      this.setData()
      console.log("Error while getCustomAttributeData Value", error);
      this.utilService.closeAllPopups()
    })
  }

  deleteCustomAttribute(index) {
    const toBeDelete = this.customAttributes[index];

    if(toBeDelete){

      //If the Attr is added locally and trying to delete that, locally delete the data as id doesnot exists.
      if(!toBeDelete.id){
        this.customAttributes.splice(index, 1);
        return;
      }

      this.utilService.showLoadingPopup();

      this.ds.deleteCustomAttributeById(toBeDelete.id).subscribe(res => {
        console.log("Succesfully Deleted custom Attribute");
        this.customAttributes.splice(index, 1);
        this.preparefilteredAttributesList(null, null);
        this.utilService.showMessage("Succesfully Deleted Custom Attribute", "Ok");
      }, error => {
        console.log("Failed to Delete custom Attribute ", error);
        this.preparefilteredAttributesList(null, null);
        this.utilService.showMessage("Failed to Delete custom Attribute", "Ok", true);
      })
    }
  }

  checkSaveBtnDisableState(){
    const isAttrNameBlankOrDuplicated = this.customAttributes.find(attr => attr.attributeName == "" || attr.attributeName == null || attr.showDuplicateMsg);
    if(isAttrNameBlankOrDuplicated){
      return true;
    }else{
      return false;
    }
  }

  manageAttribute(attributeType){
    if(attributeType == 'general'){
      this.attributeType = "general"
    }
    else{
      this.attributeType = "custom"
    }

  }

  saveGeneralAttributeStatus(){
    this.utilService.showLoadingPopup()
    this.updateGeneralAttrsStatus.emit(this.showHideStatusOfGeneralAttributes);
  }

  updateGeneralAttrsStatusOnChange(event){
    this.showHideStatusOfGeneralAttributes = cloneDeep(event);
  }

  generalData = {
    // "location": this.countryName,
    "sector": "",
    "formData": this.ds.formData,
    // "latestValDateTrackRecord": this.latestValDateTrackRecord,
    // "summaryNumbers": this.summaryNumbers
  }

  getDefaultListOfAttributes() {
    return [
      {
        "companyId": this.companyId,
        "valuationDateId":  this.selectedValuationDateId,
        "attributeKey": "no_of_employees",
        "attributeName": this.translateService.getLabel("no_of_employees"),
        "attributeValue": "",
        "attributeSequence": 1,
        "show": false
      }, {
        "companyId": this.companyId,
        "valuationDateId":  this.selectedValuationDateId,
        "attributeKey": "key_people",
        "attributeName": this.translateService.getLabel("key_people"),
        "attributeValue": "",
        "attributeSequence": 2,
        "show": false
      }, {
        "companyId": this.companyId,
        "valuationDateId":  this.selectedValuationDateId,
        "attributeKey": "security_type",
        "attributeName": this.translateService.getLabel("security_type"),
        "attributeValue": "",
        "attributeSequence": 3,
        "show": false
      },
       {
        "companyId": this.companyId,
        "valuationDateId":  this.selectedValuationDateId,
        "attributeKey": "partners",
        "attributeName": this.translateService.getLabel("partners"),
        "attributeValue": "",
        "attributeSequence": 4,
        "show": false
      }
    ]
  }
}
