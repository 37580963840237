import { CseComponent } from './qubit-x/portfolio/consol-summary/cca/cse/cse.component';
import { VolitilityComponent } from './qubit-x/portfolio/consol-summary/cca/volitility/volitility.component';
import { OpmInputComponent } from './qubit-x/portfolio/consol-summary/cca/opm-input/opm-input.component';
import { OpmComponent } from './qubit-x/portfolio/consol-summary/cca/opm/opm.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  NgbModalModule,
  NgbTabsetModule,
  NgbDropdownModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NavMenuHeaderComponent } from './nav-menu-header/nav-menu-header.component';
import { NavMenuFooterComponent } from './nav-menu-footer/nav-menu-footer.component';

import { AuthComponent } from './auth/auth.component';
import { MessagePopupComponent } from './utils/popup/message-popup.component';
import { NotificationComponent } from './utils/notification/notification.component';

import { ProfileComponent } from './profile/profile.component';
import { UserManagementComponent } from './user-management/user-management.component';

import { ResetComponent } from './auth/reset-password/reset-password.component';
import { LoginActivate } from './auth/login-activate.service';
import { UsersListComponent } from './users-list/users-list.component';
import { LoadingPopupComponent } from './utils/loading-popup/loading-popup.component';
import { MaterialModule } from './material.module';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { ApplicationFormsComponent } from './application-forms/application-forms.component';
import { BugsComponent } from './bugs/bugs.component';
import { SearchHomeComponent } from './search-home/search-home.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';

import * as data from 'highcharts/modules/data.src';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as exportdata from 'highcharts/modules/export-data.src';
import * as heatmapdata from 'highcharts/modules/heatmap.src';
import * as highmaps from 'highcharts/modules/map.src';
import * as treeMap from 'highcharts/modules/treemap.src';
import * as networkmaps from 'highcharts/modules/networkgraph.src';

import { GridModule, AggregateService, ToolbarService, ResizeService, PageService, SortService, FilterService, GroupService, DetailRowService,  ReorderService, ColumnChooserService, FreezeService,EditService, ExcelExportService, ContextMenuService } from '@syncfusion/ej2-angular-grids';

import { WorldHeatMapComponent } from './graph/world-heat-map/world-heat-map.component';
import { FullPieChartComponent } from './graph/full-pie-chart/full-pie-chart.component';
import { BarChartComponent } from './graph/bar-chart/bar-chart.component';
import { TitleCasePipe, DatePipe, DecimalPipe } from '@angular/common';
import { LineChartComponent } from './graph/line-chart/line-chart.component';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { PortfolioComponent } from './qubit-x/portfolio/portfolio.component';
import { CompanyValuationComponent } from './qubit-x/portfolio/company-valuation/company-valuation.component';
import { InvestmentPageSummaryComponent } from './qubit-x/portfolio/investment-page-summary/investment-page-summary.component';
import { PortfolioSummaryComponent } from './qubit-x/portfolio/portfolio-summary/portfolio-summary.component';
import { OldInvestmentsComponent } from './qubit-x/portfolio/old-investments/old-investments.component';
import { ExitSimulationComponent } from './qubit-x/portfolio/exit-simulation/exit-simulation.component';
import { FundListUIComponent } from './qubit-x/portfolio/fund-list-ui/fund-list-ui.component';
import { CustomSummaryComponentComponent } from './qubit-x/portfolio/fund-list-ui/custom-summary-component/custom-summary-component.component';
import { ProtfolportfolioCurrencyDashboardComponent } from './qubit-x/portfolio/currency-dashboard/protfolportfolio-currency-dashboard.component';
import { CorporateComponent } from './qubit-x/corporate/corporate.component';
import { LegalEntitiesComponent } from './qubit-x/corporate/legal-entities/legal-entities.component';
import { NetworkChartComponent } from './graph/network-chart/network-chart.component';

import { QXI_PortfolioComponent } from './qubit-x/impairement/qxi-portfolio.component';
import { QXI_CompanyValuationComponent } from './qubit-x/impairement/qxi-company-valuation/qxi-company-valuation.component';
import { QXI_InvestmentPageSummaryComponent } from './qubit-x/impairement/qxi-investment-page-summary/qxi-investment-page-summary.component';
import { QXI_PortfolioSummaryComponent } from './qubit-x/impairement/qxi-portfolio-summary/qxi-portfolio-summary.component';
import { QXI_ExitSimulationComponent } from './qubit-x/impairement/qxi-exit-simulation/qxi-exit-simulation.component';
import { QXI_FundListUIComponent } from './qubit-x/impairement/fund-list-ui/qxi-fund-list-ui.component';
import { QXI_CustomSummaryComponentComponent } from './qubit-x/impairement/fund-list-ui/qxi-custom-summary-component/qxi-custom-summary-component.component';
import { QXI_ProtfolportfolioCurrencyDashboardComponent } from './qubit-x/impairement/qxi-currency-dashboard/qxi-protfolportfolio-currency-dashboard.component';
import { QXP_PortfolioSummaryComponent } from './qubit-x/corporate/qxp-portfolio-summary/qxp-portfolio-summary.component';
import { QXP_InvestmentPageSummaryComponent } from './qubit-x/corporate/qxp-investment-page-summary/qxp-investment-page-summary.component';
import { QXP_FundListUIComponent } from './qubit-x/corporate/qxp-fund-list-ui/qxp-fund-list-ui.component';
import { QXP_CustomSummaryComponentComponent } from './qubit-x/corporate/qxp-fund-list-ui/qxp-custom-summary-component/qxp-custom-summary-component.component';
import { QXP_CompanyValuationComponent } from './qubit-x/corporate/qxp-company-valuation/qxp-company-valuation.component';
import { QXP_PortfolioComponent } from './qubit-x/corporate/qxp-portfolio.component';
import { FilesComponent } from './files/files.component';
import { FilesUploadComponent } from './files/files-upload/files-upload.component';
import { AddOldValuationComponent } from './qubit-x/portfolio/old-investments/add-old-valuation/add-old-valuation.component';
import { ExcelDataSelectorWidgetComponent } from './utils/excel-data-selector-widget/excel-data-selector-widget.component';
import { InvestmentSummaryV2Component } from './qubit-x/portfolio/investment-summary-v2/investment-summary-v2.component';
import { PlannedVsActualComponent } from './qubit-x/portfolio/investment-page-summary/planned-vs-actual/planned-vs-actual.component';
import { ConsolSummaryComponent } from './qubit-x/portfolio/consol-summary/consol-summary.component';
import { CustomStakeExcelComponent } from './qubit-x/portfolio/consol-summary/custom-stake-excel/custom-stake-excel.component';
import { SharedAccessComponent } from './shared-access/shared-access.component';
import { InvestmentComponent } from './qubit-x/portfolio/investment/investment.component';
import { TransactionsComponent } from './qubit-x/portfolio/transactions/transactions.component';
import { ExitSimulationV2Component } from './exit-simulation-v2/exit-simulation-v2.component';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { PortfolioTableComponent } from './qubit-x/portfolio/portfolio-table/portfolio-table.component';
import { QualitativeAnalysisComponent } from './qualitative-analysis/qualitative-analysis.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AppScheduledDownComponent } from '../app/app-scheduled-down/app-scheduled-down.component';
import { ValueBridgeFundLevelComponent } from './value-bridge-fund-level/value-bridge-fund-level.component';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { RadioButtonModule , CheckBoxModule, SwitchModule} from '@syncfusion/ej2-angular-buttons';
import { AdjustmentsComponent } from './adjustments/adjustments.component';
import { DebtInvestmentPageSummaryComponent } from './qubit-x/portfolio/debt/debt-investment-page-summary/debt-investment-page-summary.component';
import { DebtPortfolioComponent } from './qubit-x/portfolio/debt/debt-portfolio/debt-portfolio.component';
import { DebtCompanyValuationComponent } from './qubit-x/portfolio/debt/debt-company-valuation/debt-company-valuation.component';
import { NavigationService } from './services/navigation.service';
import { AdminComponent } from './administration/admin.component';
import { CapTable2Component } from './qubit-x/portfolio/consol-summary/cap-table2/cap-table2.component';
import { InputComponent } from './qubit-x/portfolio/consol-summary/cca/input/components/input/input.component';
import { OutputComponent } from './qubit-x/portfolio/consol-summary/cca/output/output.component';
import { FormDialogComponent } from './qubit-x/portfolio/consol-summary/cca/input/components/form-dialog/form-dialog.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule, AutoCompleteModule, MultiSelectModule, MultiSelectAllModule  } from '@syncfusion/ej2-angular-dropdowns';
import { SlSummaryComponent } from "./qubit-x/portfolio/consol-summary/cca/sl-summary/sl-summary.component";
import { AccordionModule, ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { CCAComponent } from './qubit-x/portfolio/consol-summary/cca/cca.component';
import { NormalOptionsInputComponent } from './qubit-x/portfolio/consol-summary/cca/normal-options/normal-options-input/normal-options-input.component';
import { NormalOptionsComponent } from './qubit-x/portfolio/consol-summary/cca/normal-options/normal-options/normal-options.component';
import { ManagementOptionComponent } from './qubit-x/portfolio/consol-summary/cca/management-option/management-option.component';
import { SalesBonusPoolComponent } from './qubit-x/portfolio/consol-summary/cca/sales-bonus-pool/sales-bonus-pool.component';
import { ManPackInputComponent } from "./qubit-x/portfolio/consol-summary/cca/man-pack-input/man-pack-input.component";
import { DebtPortfolioCompanyComponent } from './qubit-x/portfolio/debt/debt-portfolio-company/debt-portfolio-company.component';
import { DebtCompanySummaryComponent } from './qubit-x/portfolio/debt/debt-company-summary/debt-company-summary.component';
import { MatDividerModule } from "@angular/material";
import { CoverageAnalysisComponent } from './qubit-x/portfolio/debt/coverage-analysis/coverage-analysis.component';
import { WaterfallAnalysisComponent } from './qubit-x/portfolio/debt/waterfall-analysis/waterfall-analysis.component';
import { GoingConcernAnalysisComponent } from './qubit-x/portfolio/debt/going-concern-analysis/going-concern-analysis.component';
import { CovenantsComponent } from './qubit-x/portfolio/debt/covenants/covenants.component';
import { RiskFreeRateComponent } from "./qubit-x/portfolio/consol-summary/cca/risk-free-rate/risk-free-rate.component";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BuildUpComponent } from './qubit-x/build-up/build-up.component';
import { FundAllocationComponent } from './qubit-x/portfolio/fund-allocation/fund-allocation.component';
import { MonitoringNavigationComponent } from './qubit-x/portfolio/monitoring-navigation/monitoring-navigation.component';
import { LeverageRatioComponent } from './qubit-x/portfolio/leverage-ratio/leverage-ratio.component';
import { StatusFundLevelComponent } from './status-fund-level/status-fund-level.component';
import { CompanyValuationV2Component } from './qubit-x/portfolio/company-valuation-v2/company-valuation-v2.component';
import { InvestmentPageSummaryV2Component } from './qubit-x/portfolio/investment-page-summary-v2/investment-page-summary-v2.component';
import { InvestmentV2Component } from './qubit-x/portfolio/investment-v2/investment-v2.component';
import { ToastComponent } from './utils/toast/toast.component';
import { VersionComparisonComponent } from './qubit-x/portfolio/company-valuation-v2/version-comparison/version-comparison.component';
import { ComparisonFiltersComponent } from './qubit-x/portfolio/company-valuation-v2/version-comparison/comparison-filters/comparison-filters.component';
import { PortfolioSummaryV2Component } from './qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.component';
import { EquityPortfolioComponent } from './qubit-x/portfolio/equity-portfolio/equity-portfolio.component';
import { EquityPortfolioGridComponent } from './qubit-x/portfolio/equity-portfolio/equity-portfolio-grid/equity-portfolio-grid.component';
import { DataManagementComponent } from './data-management/data-management.component'
import { ReplicateFundsComponent } from './qubit-x/replicate-funds/replicate-funds.component'
import { StatusPageComponent } from './status-page/status-page.component'
import { MomemtDatePipe } from './pipes/moment-date/moment-date.pipe';
import { DmDecimalPipe } from './pipes/dm-decimal/dm-decimal.pipe';
import { DynamicDecimalPipe } from './pipes/dynamic-decimal/dynamic-dm-decimal.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { ValueBridgeFundLevelV2Component } from './value-bridge-fund-level-v2/value-bridge-fund-level-v2.component';
import { OneBridgeSystemComponent } from './value-bridge-fund-level-v2/one-bridge-system/one-bridge-system.component';
import { MultiBridgeSystemComponent } from './value-bridge-fund-level-v2/multi-bridge-system/multi-bridge-system.component';
import { RestructuredSecuritiesComponent } from './qubit-x/portfolio/debt/restructured-securities/restructured-securities.component';
import { CompanySearchComponent } from './company-search/company-search.component';
import { BreadCrumbsComponent } from './bread-crumbs/bread-crumbs.component';
import { TypeOfPipe } from './pipes/type-of/type-of.pipe';
import { CustomAttributePopupComponent } from './qubit-x/portfolio/investment-page-summary-v2/custom-attribute-popup/custom-attribute-popup.component';
import { TrackRecordPreferencesComponent } from './qubit-x/portfolio/investment-page-summary-v2/track-record-preferences/track-record-preferences.component';
import { LoginFailedComponent } from './auth/login-failed/login-failed.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { OrganizationChooseComponent } from './auth/organization-choose/organization-choose.component';
import { TranslateService } from './services/translation.service';

import { CreditRatingComponent } from './qubit-x/portfolio/debt/credit-rating-upload/credit-rating-upload.component';
import { RawDataDirective } from './utils/raw-data.directive';
import { BusinessUnitPopupUiComponent } from './qubit-x/portfolio/company-valuation-v2/business-unit-popup-ui/business-unit-popup-ui.component';
import { CheckListPopUpComponent } from './qubit-x/portfolio/check-list-pop-up/check-list-pop-up.component';
import { CompanyListInCheckListComponent } from './qubit-x/portfolio/check-list-pop-up/company-list-in-check-list/company-list-in-check-list.component';
import { GuestUsersSharePopupComponent } from './qubit-x/portfolio/company-valuation-v2/guest-users-share-popup/guest-users-share-popup.component';
import { CaptableWaterfallVersioningComponent } from "./qubit-x/portfolio/consol-summary/captable-waterfall-versioning/captable-waterfall-versioning.component";
import { AddValuationPopupComponent } from './qubit-x/portfolio/company-valuation-v2/add-valuation-popup/add-valuation-popup.component';
import { EquityPortfolioV2Component } from './qubit-x/portfolio/equity-portfolio-v2/equity-portfolio-v2.component';
import { EquitySummaryChartComponent } from './qubit-x/portfolio/equity-portfolio-v2/equity-summary-chart/equity-summary-chart.component';
import { PortfolioTableV2Component } from './qubit-x/portfolio/equity-portfolio-v2/portfolio-table-v2/portfolio-table-v2.component';
import { OrganizationEzComponent } from './auth/organization-ez/organization-ez.component';
import { OrganizationComponent } from './auth/organization/organization.component';
import { IntegrationToolComponent } from './product-integration-tool/integration-tool.component';
import { ProductIntegerationComponent } from './product-integration-tool/product-integeration/product-integeration.component';
import { ProductIntegrationToolComponent } from './product-integration-tool/product-integration-tool.component';
import { AuditTrailGlobalComponent } from './qubit-x/portfolio/leverage-ratio/audit-trail-global/audit-trail-global.component';
import { MasterCompanyInfoComponent } from './qubit-x/portfolio/master-company-info/master-company-info.component';
import { DataDrillDownMenuComponent } from './data-drill-down-menu/data-drill-down-menu.component';
import { DrillDownComponent } from './data-drill-down-menu/drill-down/drill-down.component';
import { RolloverComponent } from './qubit-x/portfolio/rollover/rollover.component';
import { AdditionalAnalysisComponent } from './qubit-x/portfolio/consol-summary/additional-analysis/additional-analysis.component';
import { ExternalValuerComponent } from './external-valuer/external-valuer.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DebtCompanySummaryDetailsComponent } from './qubit-x/portfolio/debt/debt-company-summary/debt-company-summary-details/debt-company-summary-details.component';
import { SplineChartComponent } from './graph/spline-chart/spline-chart.component';
import { ColumnLineComponent } from './graph/column-line/column-line.component';
import { ThresholdComponent } from './qubit-x/portfolio/consol-summary/threshold/threshold.component';
import { SideMenuComponent } from './side-menu/side-menu.component';

registerLocaleData(localeFr);
import { OldApprovalStatusComponent } from './qubit-x/portfolio/company-valuation-v2/old-approval-status/old-approval-status.component';
import { NewApprovalStatusComponent } from './qubit-x/portfolio/company-valuation-v2/new-approval-status/new-approval-status.component';
import { NewApprovalRequestsComponent } from './qubit-x/portfolio/company-valuation-v2/new-approval-requests/new-approval-requests.component';
import { OldApprovalRequestsComponent } from './qubit-x/portfolio/company-valuation-v2/old-approval-requests/old-approval-requests.component';
import { ApprovalSystemService } from './services/approval-system.service';
import { ConfigurationComponent } from './configuration/configuration.component';
import { CustomTooltipDirective } from './utils/custom-tool-tip.directive';
import { CustomToolTipComponent } from './utils/custom-tool-tip/custom-tool-tip.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { QualitativeAnalysisCommentsComponent } from './qualitative-analysis-comments/qualitative-analysis-comments.component';
import { QualitativeAnalysisV2Component } from './qualitative-analysis-v2/qualitative-analysis-v2.component';
import { QualitativeAnalysisV2AddTitleCommentComponent } from './qualitative-analysis-v2-add-title-comment/qualitative-analysis-v2-add-title-comment.component';
import { QualitativeAnalysisV2ListComponent } from './qualitative-analysis-v2-list/qualitative-analysis-v2-list.component';
import { QualitativeAnalysisV2MessagePopupComponent } from './qualitative-analysis-v2-message-popup/qualitative-analysis-v2-message-popup.component';
import { TermandconditionComponent } from './termandcondition/termandcondition.component';
import { MappingManagementContainerComponent } from './mapping-managment-config/mapping-mgmt-container.component';
import { AttributesComponent } from './mapping-managment-config/attributes/attributes.component';
import { MappingComponent } from './mapping-managment-config/mapping/mapping.component';
import { MappingFilterComponent } from './mapping-managment-config/mapping-filter/mapping-filter.component';
import { MappingInfoComponent } from './mapping-managment-config/mapping-info/mapping-info.component';
import { VersionsComponent } from './mapping-managment-config/versions/versions.component';
import { CompanyAssignmentComponent } from './mapping-managment-config/company-assignment/company-assignment.component';
import { VersionListComponent } from './mapping-managment-config/versions/version-list/version-list.component';
import { AttrMappingTableComponent } from './mapping-managment-config/attributes/attr-mapping-table/attr-mapping-table.component';
import { AssignmentTableComponent } from './mapping-managment-config/company-assignment/assignment-table/assignment-table.component';
import { AssignMapVersionDialogComponent } from './mapping-managment-config/company-assignment/assign-map-version-dialog/assign-map-version-dialog.component';
import { CompanyListComponent } from './mapping-managment-config/company-assignment/assign-map-version-dialog/company-list/company-list.component';
import { CaFilterComponent } from './mapping-managment-config/company-assignment/assign-map-version-dialog/ca-form/ca-form.component';
import { CreateVlComponent } from './mapping-managment-config/versions/create-vl/create-vl.component';
import { SearchDropDownComponent } from './utils/search-drop-down/search-drop-down.component';
import { HistoricalTableComponent } from './mapping-managment-config/versions/create-vl/historical-table/historical-table.component';
import { ProjectedTableComponent } from './mapping-managment-config/versions/create-vl/projected-table/projected-table.component';
import { MatControlledCheckboxDirective } from './utils/directives/controlled-checkbox.directive';
import { DebtCapitalStructureComponent } from './qubit-x/portfolio/debt/debt-capital-structure/debt-capital-structure.component';
import { CommentsComponent } from './qubit-x/portfolio/company-valuation-v2/comments/comments.component';
import { CommentsSectionComponent } from './qubit-x/portfolio/company-valuation-v2/comments-section/comments-section.component';
import { AttributeListComponent } from './mapping-managment-config/attribute-list-container/attribute-list/attribute-list.component';
import { AttributeListContainerComponent } from './mapping-managment-config/attribute-list-container/attribute-list-container.component';
import { GsFilterContainerComponent } from './mapping-managment-config/company-assignment/assign-map-version-dialog/gs-filter-container/gs-filter-container.component';
import { TranchingAnalysisComponent } from './qubit-x/portfolio/debt/tranching-analysis/tranching-analysis.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CreditCompanyAssignmentComponent } from './mapping-managment-config/company-assignment/credit-company-assignment/credit-company-assignment.component';
import { DataReconContainerComponent } from './data-recon-dashboard/data-recon-container/data-recon-container.component';
import { DrdUserCountComponent } from './data-recon-dashboard/data-recon-container/drd-user-count/drd-user-count.component';
import { DrdFinancialsGeneralDetailsComponent } from './data-recon-dashboard/data-recon-container/drd-financials-general-details/drd-financials-general-details.component';
import { DrdAttrDetailsComponent } from './data-recon-dashboard/data-recon-container/drd-attr-details/drd-attr-details.component';
import { DataReconDashboardComponent } from './data-recon-dashboard/data-recon-dashboard.component';
import { DrdUserDetailsComponent } from './data-recon-dashboard/data-recon-container/drd-user-details/drd-user-details.component';
import { GeneralAttributePopupComponent } from './qubit-x/portfolio/investment-page-summary-v2/general-attribute-popup/general-attribute-popup.component';
import { CreditBiDashboardComponent } from './credit-bi-dashboard/credit-bi-dashboard.component';
import { DataReconPlutoComponent } from './data-recon-pluto/data-recon-pluto.component';
import { DataReconPlutoContainerComponent } from './data-recon-pluto/data-recon-pluto-container/data-recon-pluto-container.component';
import { DrpPageHeaderComponent } from './data-recon-pluto/data-recon-pluto-container/drp-page-header/drp-page-header.component';
import { DrpAttributeDetailsComponent } from './data-recon-pluto/data-recon-pluto-container/drp-attribute-details/drp-attribute-details.component';
import { DrpFinancialsGeneralProfileDetailsComponent } from './data-recon-pluto/data-recon-pluto-container/drp-financials-general-profile-details/drp-financials-general-profile-details.component';
import { DrpOrganizationUserDetailsComponent } from './data-recon-pluto/data-recon-pluto-container/drp-organization-user-details/drp-organization-user-details.component';
import { DrpUserCompanyAccessDetailsComponent } from './data-recon-pluto/data-recon-pluto-container/drp-user-company-access-details/drp-user-company-access-details.component';
import { DrdSnowflakeFinancialsGeneralDetailsComponent } from './data-recon-dashboard/data-recon-container/drd-snowflake-financials-general-details/drd-snowflake-financials-general-details.component';
import { PowerBiReportComponent } from './qubit-x/power-bi/power-bi-report/power-bi-report.component';
import { PowerBiConfigComponent } from './power-bi-config/power-bi-config.component';

function getTranslationData(translateService: TranslateService) {
  return () => translateService.getTranslations();
}

function getCurrentTranslation() {
  let selectedLang: string = window.localStorage.getItem("selectedLang") ? window.localStorage.getItem("selectedLang") : 'EN';
  return selectedLang;
}



@NgModule({
  declarations: [
    AppComponent,
    NavMenuHeaderComponent,
    NavMenuFooterComponent,
    SideMenuComponent,

    BreadCrumbsComponent,
    ProfileComponent,
    StatusPageComponent,
    UserManagementComponent,
    UsersListComponent,
    AdminComponent,
    ResetComponent,
    LoadingPopupComponent,
    UserProfileComponent,
    ApplicationFormsComponent,
    AuthComponent,
    MessagePopupComponent,
    NotificationComponent,
    CheckListPopUpComponent,
    CompanyListInCheckListComponent,
    OldApprovalStatusComponent,
    NewApprovalStatusComponent,
    OldApprovalRequestsComponent,
    NewApprovalRequestsComponent,

    BugsComponent,
    SearchHomeComponent,
    ThresholdComponent,

    IntegrationToolComponent,
    ProductIntegerationComponent,
    ProductIntegrationToolComponent,

    MonitoringNavigationComponent,

    SharedAccessComponent,


    WorldHeatMapComponent,
    FullPieChartComponent,
    BarChartComponent,
    LineChartComponent,
    NetworkChartComponent,
    SplineChartComponent,
    ColumnLineComponent,

    PortfolioComponent,
    CompanyValuationComponent,
    CompanyValuationV2Component,
    AddValuationPopupComponent,
    GuestUsersSharePopupComponent,
    InvestmentPageSummaryComponent,
    InvestmentPageSummaryV2Component,
    CustomAttributePopupComponent,
    TrackRecordPreferencesComponent,
    InvestmentSummaryV2Component,
    PortfolioSummaryComponent,
    OldInvestmentsComponent,
    AddOldValuationComponent,
    ExitSimulationComponent,
    FundListUIComponent,
    FundAllocationComponent,
    CreditRatingComponent,
    LeverageRatioComponent,
    AuditTrailGlobalComponent,
    CustomSummaryComponentComponent,
    CurrencyDropdownComponent,
    ProtfolportfolioCurrencyDashboardComponent,
    PlannedVsActualComponent,
    ConsolSummaryComponent,
    CustomStakeExcelComponent,
    InvestmentComponent,
    TransactionsComponent,
    ExitSimulationV2Component,
    PortfolioTableComponent,
    QualitativeAnalysisComponent,
    ValueBridgeFundLevelComponent,
    AdjustmentsComponent,
    BuildUpComponent,
    VersionComparisonComponent,
    ComparisonFiltersComponent,
    MasterCompanyInfoComponent,
    RawDataDirective,
    CustomTooltipDirective,
    MatControlledCheckboxDirective,
    PowerBiReportComponent,
    PowerBiConfigComponent,

    PortfolioSummaryV2Component,
    EquityPortfolioComponent,
    EquityPortfolioGridComponent,
    ValueBridgeFundLevelV2Component,
    OneBridgeSystemComponent,
    MultiBridgeSystemComponent,
    CompanySearchComponent,
    BusinessUnitPopupUiComponent,
    CustomToolTipComponent,
    DataDrillDownMenuComponent,
    DrillDownComponent,

    //Impairment Components
    QXI_PortfolioComponent,
    QXI_CompanyValuationComponent,
    QXI_InvestmentPageSummaryComponent,
    QXI_PortfolioSummaryComponent,
    QXI_ExitSimulationComponent,
    QXI_FundListUIComponent,
    QXI_CustomSummaryComponentComponent,
    QXI_ProtfolportfolioCurrencyDashboardComponent,

    //Corporate Components
    CorporateComponent,
    LegalEntitiesComponent,
    QXP_PortfolioComponent,
    QXP_CompanyValuationComponent,
    QXP_InvestmentPageSummaryComponent,
    QXP_PortfolioSummaryComponent,
    QXP_FundListUIComponent,
    QXP_CustomSummaryComponentComponent,
    FilesComponent,
    FilesUploadComponent,

    ExcelDataSelectorWidgetComponent,

    //App Scheduled Down
    AppScheduledDownComponent,

    CaptableWaterfallVersioningComponent,

    CapTable2Component,
    InputComponent,
    FormDialogComponent,
    OutputComponent,
    SlSummaryComponent,
    CCAComponent,
    NormalOptionsComponent,
    NormalOptionsInputComponent,
    ManagementOptionComponent,
    SalesBonusPoolComponent,
    ManPackInputComponent,
    OpmComponent,
    OpmInputComponent,
    VolitilityComponent,
    RiskFreeRateComponent,
    CseComponent,

    //Debt
    DebtPortfolioComponent,
    DebtCompanyValuationComponent,
    DebtInvestmentPageSummaryComponent,
    DebtPortfolioCompanyComponent,
    DebtCompanySummaryComponent,
    CoverageAnalysisComponent,
    DebtCapitalStructureComponent,
    RestructuredSecuritiesComponent,
    WaterfallAnalysisComponent,
    GoingConcernAnalysisComponent,
    CovenantsComponent,
    StatusFundLevelComponent,
    InvestmentV2Component,
    ToastComponent,
    DataManagementComponent,
    ReplicateFundsComponent,
    DebtCompanySummaryDetailsComponent,
    TranchingAnalysisComponent,

    // Custom Pipes
    MomemtDatePipe,
    DmDecimalPipe,
    DynamicDecimalPipe,
    DateFormatPipe,
    TypeOfPipe,

    // New Equity portfolio Components to Display portfolio Summary
    EquityPortfolioV2Component,
    EquitySummaryChartComponent, // Component to render the Summary Chart and Total Aggregations
    PortfolioTableV2Component, //Component to render the Current & Realised Portfolio for Reporting and Local Currency
    LoginFailedComponent,
    LogoutComponent,
    OrganizationChooseComponent,
    OrganizationEzComponent,
    OrganizationComponent,
    RolloverComponent,
    ConfigurationComponent,
    RolloverComponent,
    AdditionalAnalysisComponent,
    ExternalValuerComponent,
    QualitativeAnalysisCommentsComponent,
    QualitativeAnalysisV2Component,
    QualitativeAnalysisV2AddTitleCommentComponent,
    QualitativeAnalysisV2ListComponent,
    QualitativeAnalysisV2MessagePopupComponent,
    TermandconditionComponent,
    MappingManagementContainerComponent,
    AttributesComponent,
    MappingComponent,
    MappingFilterComponent,
    MappingInfoComponent,
    VersionsComponent,
    CompanyAssignmentComponent,
    VersionListComponent,
    AttrMappingTableComponent,
    AttributeListContainerComponent,
    AttributeListComponent,
    AssignmentTableComponent,
    AssignMapVersionDialogComponent,
    CompanyListComponent,
    CaFilterComponent,
    CreateVlComponent,
    SearchDropDownComponent,
    HistoricalTableComponent,
    ProjectedTableComponent,
    GsFilterContainerComponent,
    CommentsComponent,
    CommentsSectionComponent,
    CreditBiDashboardComponent,
    GeneralAttributePopupComponent,
    CreditCompanyAssignmentComponent,
    DataReconContainerComponent,
    DrdUserCountComponent,
    DrdFinancialsGeneralDetailsComponent,
    DrdAttrDetailsComponent,
    DataReconDashboardComponent,
    DrdUserDetailsComponent,
    DrdSnowflakeFinancialsGeneralDetailsComponent,

    // Data Recon Pluto Components
    DataReconPlutoComponent,
    DataReconPlutoContainerComponent,
    DrpPageHeaderComponent,
    DrpAttributeDetailsComponent,
    DrpFinancialsGeneralProfileDetailsComponent,
    DrpOrganizationUserDetailsComponent,
    DrpUserCompanyAccessDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModalModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbPopoverModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    ChartModule,
    DialogModule,
    RadioButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule.forRoot(),
    ScrollingModule,

    // Syncfusion
    GridModule,
    NumericTextBoxModule,
    RichTextEditorAllModule,
    CheckBoxModule,
    DropDownListModule,
    AutoCompleteModule,
    MultiSelectModule,
    MultiSelectAllModule,
    DatePickerModule,
    AccordionModule,
    ContextMenuModule,
    SwitchModule,
    ScrollingModule
  ],
  providers: [
    LoginActivate,
    TitleCasePipe,
    DatePipe,
    DecimalPipe,
    MomemtDatePipe,
    DmDecimalPipe,
    DynamicDecimalPipe,
    TypeOfPipe,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        data, more,
        heatmapdata,
        highmaps,
        treeMap,
        networkmaps,
        exporting,
        exportdata
      ]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getTranslationData,
      deps: [TranslateService],
      multi: true,
    },
    { provide: LOCALE_ID,
      useFactory: getCurrentTranslation,
    },

    //Syncfusion
    PageService,
    SortService,
    FilterService,
    GroupService,
    ResizeService,
    ToolbarService,
    AggregateService,
    DetailRowService,
    ReorderService,
    ColumnChooserService,
    FreezeService,
    ResizeService,
    EditService,
    FreezeService,
    NavigationService,
    ApprovalSystemService,
    ExcelExportService,
    ContextMenuService
  ],
  entryComponents: [MessagePopupComponent, LoadingPopupComponent, VolitilityComponent, RiskFreeRateComponent, ToastComponent, AssignMapVersionDialogComponent],
  exports: [RawDataDirective, CustomTooltipDirective, OverlayModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
