export interface ICountry {
  name: string;
  code: string;
  bucket_name?: string;
}

export const countryConstants: ICountry[] = [
  {
    "name": "Andorra",
    "code": "AND",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "United Arab Emirates",
    "code": "ARE",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Afghanistan",
    "code": "AFG",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Antigua and Barbuda",
    "code": "ATG",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Anguilla",
    "code": "AIA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Albania",
    "code": "ALB",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Armenia",
    "code": "ARM",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Netherlands Antilles",
    "code": null,
    "bucket_name": null
  },
  {
    "name": "Angola",
    "code": "AGO",
    "bucket_name": "Africa"
  },
  {
    "name": "Antarctica",
    "code": "ATA",
    "bucket_name": "Others"
  },
  {
    "name": "Argentina",
    "code": "ARG",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "American Samoa",
    "code": "ASM",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Austria",
    "code": "AUT",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Australia",
    "code": "AUS",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Aruba",
    "code": "ABW",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Aland Islands",
    "code": "ALA",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Azerbaijan",
    "code": "AZE",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Bosnia and Herzegovina",
    "code": "BIH",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Barbados",
    "code": "BRB",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Bangladesh",
    "code": "BGD",
    "bucket_name": "India, Pakistan, Bangladesh, Sri Lanka"
  },
  {
    "name": "Belgium",
    "code": "BEL",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Burkina Faso",
    "code": "BFA",
    "bucket_name": "Africa"
  },
  {
    "name": "Bulgaria",
    "code": "BGR",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Bahrain",
    "code": "BHR",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Burundi",
    "code": "BDI",
    "bucket_name": "Africa"
  },
  {
    "name": "Benin",
    "code": "BEN",
    "bucket_name": "Africa"
  },
  {
    "name": "Saint Barthelemy",
    "code": "BLM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Bermuda",
    "code": "BMU",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Brunei",
    "code": "BRN",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Bolivia",
    "code": "BOL",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Bonaire, Sint Eustatius and Saba",
    "code": "BES",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Brazil",
    "code": "BRA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Bahamas",
    "code": "BHS",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Bhutan",
    "code": "BTN",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Bouvet Island",
    "code": "BVT",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Botswana",
    "code": "BWA",
    "bucket_name": "Africa"
  },
  {
    "name": "Belarus",
    "code": "BLR",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Belize",
    "code": "BLZ",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Canada",
    "code": "CAN",
    "bucket_name": "United States & Canda"
  },
  {
    "name": "Cocos Islands",
    "code": "CCK",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "The Democratic Republic Of Congo",
    "code": "COD",
    "bucket_name": "Africa"
  },
  {
    "name": "Central African Republic",
    "code": "CAF",
    "bucket_name": "Africa"
  },
  {
    "name": "Congo",
    "code": "COG",
    "bucket_name": "Africa"
  },
  {
    "name": "Switzerland",
    "code": "CHE",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Cote dIvoire",
    "code": "CIV",
    "bucket_name": "Africa"
  },
  {
    "name": "Cook Islands",
    "code": "COK",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Chile",
    "code": "CHL",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Cameroon",
    "code": "CMR",
    "bucket_name": "Africa"
  },
  {
    "name": "China",
    "code": "CHN",
    "bucket_name": "China"
  },
  {
    "name": "Colombia",
    "code": "COL",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Costa Rica",
    "code": "CRI",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Cuba",
    "code": "CUB",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Cape Verde",
    "code": "CPV",
    "bucket_name": "Africa"
  },
  {
    "name": "Curacao",
    "code": "CUW",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Christmas Island",
    "code": "CXR",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Cyprus",
    "code": "CYP",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Czech Republic",
    "code": "CZE",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Germany",
    "code": "DEU",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Djibouti",
    "code": "DJI",
    "bucket_name": "Africa"
  },
  {
    "name": "Denmark",
    "code": "DNK",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Dominica",
    "code": "DMA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Dominican Republic",
    "code": "DOM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Algeria",
    "code": "DZA",
    "bucket_name": "Africa"
  },
  {
    "name": "Ecuador",
    "code": "ECU",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Estonia",
    "code": "EST",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Egypt",
    "code": "EGY",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Western Sahara",
    "code": "ESH",
    "bucket_name": "Africa"
  },
  {
    "name": "Eritrea",
    "code": "ERI",
    "bucket_name": "Africa"
  },
  {
    "name": "Spain",
    "code": "ESP",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Ethiopia",
    "code": "ETH",
    "bucket_name": "Africa"
  },
  {
    "name": "Finland",
    "code": "FIN",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Fiji",
    "code": "FJI",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Falkland Islands",
    "code": "FLK",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Micronesia",
    "code": "FSM",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Faroe Islands",
    "code": "FRO",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "France",
    "code": "FRA",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Gabon",
    "code": "GAB",
    "bucket_name": "Africa"
  },
  {
    "name": "United Kingdom",
    "code": "GBR",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Grenada",
    "code": "GRD",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Georgia",
    "code": "GEO",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "French Guiana",
    "code": "GUF",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Guernsey",
    "code": "GGY",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Ghana",
    "code": "GHA",
    "bucket_name": "Africa"
  },
  {
    "name": "Gibraltar",
    "code": "GIB",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Greenland",
    "code": "GRL",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Gambia",
    "code": "GMB",
    "bucket_name": "Africa"
  },
  {
    "name": "Guinea",
    "code": "GIN",
    "bucket_name": "Africa"
  },
  {
    "name": "Guadeloupe",
    "code": "GLP",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Equatorial Guinea",
    "code": "GNQ",
    "bucket_name": "Africa"
  },
  {
    "name": "Greece",
    "code": "GRC",
    "bucket_name": "Western Europe"
  },
  {
    "name": "South Georgia And The South Sandwich Islands",
    "code": "SGS",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Guatemala",
    "code": "GTM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Guam",
    "code": "GUM",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Guinea-Bissau",
    "code": "GNB",
    "bucket_name": "Africa"
  },
  {
    "name": "Guyana",
    "code": "GUY",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Hong Kong",
    "code": "HKG",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Heard Island And McDonald Islands",
    "code": "HMD",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Honduras",
    "code": "HND",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Croatia",
    "code": "HRV",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Haiti",
    "code": "HTI",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Hungary",
    "code": "HUN",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Indonesia",
    "code": "IDN",
    "bucket_name": "Singapore, South Korea, Taiwan, Malaysia, Indonesia"
  },
  {
    "name": "Ireland",
    "code": "IRL",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Israel",
    "code": "ISR",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Isle Of Man",
    "code": "IMN",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "India",
    "code": "IND",
    "bucket_name": "India, Pakistan, Bangladesh, Sri Lanka"
  },
  {
    "name": "British Indian Ocean Territory",
    "code": "IOT",
    "bucket_name": "Africa"
  },
  {
    "name": "Iraq",
    "code": "IRQ",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Iran",
    "code": "IRN",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Iceland",
    "code": "ISL",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Italy",
    "code": "ITA",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Jersey",
    "code": "JEY",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Jamaica",
    "code": "JAM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Jordan",
    "code": "JOR",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Japan",
    "code": "JPN",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Kenya",
    "code": "KEN",
    "bucket_name": "Africa"
  },
  {
    "name": "Kyrgyzstan",
    "code": "KGZ",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Cambodia",
    "code": "KHM",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Kiribati",
    "code": "KIR",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Comoros",
    "code": "COM",
    "bucket_name": "Africa"
  },
  {
    "name": "Saint Kitts And Nevis",
    "code": "KNA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "North Korea",
    "code": "PRK",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "South Korea",
    "code": "KOR",
    "bucket_name": "Singapore, South Korea, Taiwan, Malaysia, Indonesia"
  },
  {
    "name": "Kuwait",
    "code": "KWT",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Cayman Islands",
    "code": "CYM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Kazakhstan",
    "code": "KAZ",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Laos",
    "code": "LAO",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Lebanon",
    "code": "LBN",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Saint Lucia",
    "code": "LCA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Liechtenstein",
    "code": "LIE",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Sri Lanka",
    "code": "LKA",
    "bucket_name": "India, Pakistan, Bangladesh, Sri Lanka"
  },
  {
    "name": "Liberia",
    "code": "LBR",
    "bucket_name": "Africa"
  },
  {
    "name": "Lesotho",
    "code": "LSO",
    "bucket_name": "Africa"
  },
  {
    "name": "Lithuania",
    "code": "LTU",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Luxembourg",
    "code": "LUX",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Latvia",
    "code": "LVA",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Libya",
    "code": "LBY",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Morocco",
    "code": "MAR",
    "bucket_name": "Africa"
  },
  {
    "name": "Monaco",
    "code": "MCO",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Moldova",
    "code": "MDA",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Montenegro",
    "code": "MNE",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Saint Martin",
    "code": "MAF",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Madagascar",
    "code": "MDG",
    "bucket_name": "Africa"
  },
  {
    "name": "Marshall Islands",
    "code": "MHL",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Macedonia",
    "code": "MKD",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Mali",
    "code": "MLI",
    "bucket_name": "Africa"
  },
  {
    "name": "Myanmar",
    "code": "MMR",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Mongolia",
    "code": "MNG",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Macao",
    "code": "MAC",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Northern Mariana Islands",
    "code": "MNP",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Martinique",
    "code": "MTQ",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Mauritania",
    "code": "MRT",
    "bucket_name": "Africa"
  },
  {
    "name": "Montserrat",
    "code": "MSR",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Malta",
    "code": "MLT",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Mauritius",
    "code": "MUS",
    "bucket_name": "Africa"
  },
  {
    "name": "Maldives",
    "code": "MDV",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Malawi",
    "code": "MWI",
    "bucket_name": "Africa"
  },
  {
    "name": "Mexico",
    "code": "MEX",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Malaysia",
    "code": "MYS",
    "bucket_name": "Singapore, South Korea, Taiwan, Malaysia, Indonesia"
  },
  {
    "name": "Mozambique",
    "code": "MOZ",
    "bucket_name": "Africa"
  },
  {
    "name": "Namibia",
    "code": "NAM",
    "bucket_name": "Africa"
  },
  {
    "name": "New Caledonia",
    "code": "NCL",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Niger",
    "code": "NER",
    "bucket_name": "Africa"
  },
  {
    "name": "Norfolk Island",
    "code": "NFK",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Nigeria",
    "code": "NGA",
    "bucket_name": "Africa"
  },
  {
    "name": "Nicaragua",
    "code": "NIC",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Netherlands",
    "code": "NLD",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Norway",
    "code": "NOR",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Nepal",
    "code": "NPL",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Nauru",
    "code": "NRU",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Niue",
    "code": "NIU",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "New Zealand",
    "code": "NZL",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Oman",
    "code": "OMN",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Panama",
    "code": "PAN",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Peru",
    "code": "PER",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "French Polynesia",
    "code": "PYF",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Papua New Guinea",
    "code": "PNG",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Philippines",
    "code": "PHL",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Pakistan",
    "code": "PAK",
    "bucket_name": "India, Pakistan, Bangladesh, Sri Lanka"
  },
  {
    "name": "Poland",
    "code": "POL",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Saint Pierre And Miquelon",
    "code": "SPM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Pitcairn",
    "code": "PCN",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Puerto Rico",
    "code": "PRI",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Palestine",
    "code": "PSE",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Portugal",
    "code": "PRT",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Palau",
    "code": "PLW",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Paraguay",
    "code": "PRY",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Qatar",
    "code": "QAT",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Reunion",
    "code": "REU",
    "bucket_name": "Africa"
  },
  {
    "name": "Romania",
    "code": "ROU",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Serbia",
    "code": "SRB",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Russia",
    "code": "RUS",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Rwanda",
    "code": "RWA",
    "bucket_name": "Africa"
  },
  {
    "name": "Saudi Arabia",
    "code": "SAU",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Solomon Islands",
    "code": "SLB",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Seychelles",
    "code": "SYC",
    "bucket_name": "Africa"
  },
  {
    "name": "Sudan",
    "code": "SDN",
    "bucket_name": "Africa"
  },
  {
    "name": "Sweden",
    "code": "SWE",
    "bucket_name": "Western Europe"
  },
  {
    "name": "Singapore",
    "code": "SGP",
    "bucket_name": "Singapore, South Korea, Taiwan, Malaysia, Indonesia"
  },
  {
    "name": "Saint Helena",
    "code": "SHN",
    "bucket_name": "Africa"
  },
  {
    "name": "Slovenia",
    "code": "SVN",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Svalbard And Jan Mayen",
    "code": "SJM",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Slovakia",
    "code": "SVK",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Sierra Leone",
    "code": "SLE",
    "bucket_name": "Africa"
  },
  {
    "name": "San Marino",
    "code": "SMR",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Senegal",
    "code": "SEN",
    "bucket_name": "Africa"
  },
  {
    "name": "Somalia",
    "code": "SOM",
    "bucket_name": "Africa"
  },
  {
    "name": "Suriname",
    "code": "SUR",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "South Sudan",
    "code": "SSD",
    "bucket_name": "Africa"
  },
  {
    "name": "Sao Tome And Principe",
    "code": "STP",
    "bucket_name": "Africa"
  },
  {
    "name": "El Salvador",
    "code": "SLV",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Sint Maarten (Dutch part)",
    "code": "SXM",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Syria",
    "code": "SYR",
    "bucket_name": "Developed Middle East"
  },
  {
    "name": "Swaziland",
    "code": "SWZ",
    "bucket_name": null
  },
  {
    "name": "Turks And Caicos Islands",
    "code": "TCA",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Chad",
    "code": "TCD",
    "bucket_name": "Africa"
  },
  {
    "name": "French Southern Territories",
    "code": "ATF",
    "bucket_name": "Africa"
  },
  {
    "name": "Togo",
    "code": "TGO",
    "bucket_name": "Africa"
  },
  {
    "name": "Thailand",
    "code": "THA",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Tajikistan",
    "code": "TJK",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Tokelau",
    "code": "TKL",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Timor-Leste",
    "code": "TLS",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Turkmenistan",
    "code": "TKM",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Tunisia",
    "code": "TUN",
    "bucket_name": "Africa"
  },
  {
    "name": "Tonga",
    "code": "TON",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Turkey",
    "code": "TUR",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Trinidad and Tobago",
    "code": "TTO",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Tuvalu",
    "code": "TUV",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Taiwan",
    "code": "TWN",
    "bucket_name": "Singapore, South Korea, Taiwan, Malaysia, Indonesia"
  },
  {
    "name": "Tanzania",
    "code": "TZA",
    "bucket_name": "Africa"
  },
  {
    "name": "Ukraine",
    "code": "UKR",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Uganda",
    "code": "UGA",
    "bucket_name": "Africa"
  },
  {
    "name": "United States Minor Outlying Islands",
    "code": "UMI",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "United States",
    "code": "USA",
    "bucket_name": "United States & Canda"
  },
  {
    "name": "Uruguay",
    "code": "URY",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Uzbekistan",
    "code": "UZB",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Vatican",
    "code": "VAT",
    "bucket_name": "Rest of the Europe including Russia"
  },
  {
    "name": "Saint Vincent And The Grenadines",
    "code": "VCT",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Venezuela",
    "code": "VEN",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "British Virgin Islands",
    "code": "VGB",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "U.S. Virgin Islands",
    "code": "VIR",
    "bucket_name": "Rest of American Continent"
  },
  {
    "name": "Vietnam",
    "code": "VNM",
    "bucket_name": "Rest of Asia"
  },
  {
    "name": "Vanuatu",
    "code": "VUT",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Wallis And Futuna",
    "code": "WLF",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Samoa",
    "code": "WSM",
    "bucket_name": "Australia & New Zealand"
  },
  {
    "name": "Yemen",
    "code": "YEM",
    "bucket_name": "Rest of Middle East"
  },
  {
    "name": "Mayotte",
    "code": "MYT",
    "bucket_name": "Africa"
  },
  {
    "name": "South Africa",
    "code": "ZAF",
    "bucket_name": "Africa"
  },
  {
    "name": "Zambia",
    "code": "ZMB",
    "bucket_name": "Africa"
  },
  {
    "name": "Zimbabwe",
    "code": "ZWE",
    "bucket_name": "Africa"
  },
  {
    "name": "Republic of Kosovo",
    "code": null,
    "bucket_name": null
  },
  {
    "name": "Eurozone",
    "code": "EURO_ZONE",
    "bucket_name": null
  }
].sort((a, b) => {
  return a.name.localeCompare(b.name);
});
