<div class="row margin-zero">

    <!-- Component to render the Summary Chart and Total Aggregations -->
    <div class="col padding-zero">
        <app-equity-summary-chart 
        [allPortfolioData] = "allPortfolioData"
        [reportingCurrency] = "reportingCurrency"
        [allowEdit] = "false"
        [IRR_LOADING_PROGRESS] = "IRR_LOADING_PROGRESS"
        (emitAction) = emittingActionOnPortfolio($event)>

        </app-equity-summary-chart>
    </div>
</div>

<div class="row margin-zero">
    <!-- Component to render the Portfolio Summary Table With Aggregations (Portfolio ) -->
    <div class="col padding-zero">
        <app-portfolio-table-v2
        [allPortfolioData] = "allPortfolioData"
        [reportingCurrency] = "reportingCurrency"
        [allowEdit] = "false" 
        [showCompaniesInLocalCurrency] = "false"
        [IRR_LOADING_PROGRESS] = "IRR_LOADING_PROGRESS"
        (emitAction) = emittingActionOnPortfolio($event)>

        </app-portfolio-table-v2>
    </div>
</div>