import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';
import { Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ToastService } from '../toast.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from 'src/app/services/translation.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  latestNotification$ = new Subject();

  notificationData = [];
  allNotifications = [];

  showOnlyUnRead = false;

  notification = { message: "", label: "" }

  notifyStatusUpdate: Subject<Boolean> = new Subject();

  alreadyShownNotifications = {};

  notificationStatusUpdateWithAllDetails = new Subject();
  notificationStatusUpdateForCredit = new Subject();
  notificationStatusUpdateForEquity = new Subject();

  notificationSubcribed;

  templatestnotifydata = [];
  constructor(private ds: DataService,
    private toastService: ToastService,
    private ums: UserManagementService,
    private translateService: TranslateService) { }

  setLatestNotification(message, label) {
    this.notification.message = message;
    this.notification.label = label;

    this.latestNotification$.next();
  }

  init() {
    if(!this.notificationSubcribed) {
      //Fetch it once page is loaded.
      setTimeout(() => {
        this.fetchData();
      }, 2000);

      //Periodically fetch the notifications
      this.notificationSubcribed = setInterval(() => {
        this.fetchData();
      }, 10000)
    }
  }

  notificatioNmarkAllAsRead() {
    const reqBody = this.allNotifications.map(n => {
      return {
        eventObjectId: n.eventObjectId,
        readStatus: true
      }
    });

    this.ds.updateReadStatusOfNotifications(reqBody).subscribe(res => {
      this.fetchData();
    })
  }

  fetchData() {
    const user = this.ums.getSelectedUserDetails();

    if (!user.organization) return;

    this.ds.getNotificationsV2(user.id, user.organization.id).subscribe(res => {
      this.allNotifications = res.body["response"];

      if(this.showOnlyUnRead) {
        this.notificationData = this.allNotifications.filter(item => !item.readStatus);
      } else {
        this.notificationData = this.allNotifications;
      }

      this.allNotifications.forEach(n => {
        // "{\"fund\":\"Alcatraz\",\"companyName\":\"Kraft Heinz International B.V.\",\"valuationNode\":\"2022-02-04\",\"version\":\"FIRST\"}",

        const labelObj = JSON.parse(n.label);

        if(n.eventType == "FORM_SUBMISSION"){
          n.labelString = labelObj.fund + " -> " + labelObj.companyName + " -> " +
            labelObj.valuationNode + " -> " + labelObj.version;
        }
        else if(n.eventType == "CREDIT_FORM_SUBMISSION"  || n.eventType == "CREDIT_FORM_RE_SUBMISSION" ){
          n.labelString = labelObj.fund + " -> " + labelObj.companyName + " -> " + labelObj.securityName + " -> " +
            labelObj.valuationNode + " -> " + labelObj.version;
          if(n.eventStatus == "ERROR" && labelObj?.debtModelId) {
            n.debtModelId = labelObj.debtModelId;
          }
        }
        else if(n.eventType == "REPORT"){
          n.labelString = labelObj.reportName + " -> " + labelObj.reportProgress + "%";
        }

        n.isConsolForm = !!labelObj.isConsolForm;
      })

      const redBadgeElement = document.getElementById('red-badge');

      if(redBadgeElement) {
        if (this.notificationData.some(x => x.readStatus === false)) {
          redBadgeElement.classList.add('custom-red-badge');
        }
        else {
          redBadgeElement.classList.remove('custom-red-badge');
        }
      }
      
      let showSnackBar = false;

      this.notificationData.forEach(x => {
        if (x.eventStatus == 'IN_PROGRESS') {
          if (this.templatestnotifydata.length == 0) {
            this.templatestnotifydata.push(x);
          } else {
            if (!this.templatestnotifydata.find(z => z.id == x.id)) {
              // When a notification with status IN_PROGRESS comes in.
              this.templatestnotifydata.push(x);
            }
          }

          //check id the event has happened in last 2 minutes.
          const timeLapsed = ( (new Date()).getTime() - x.createdDate ) / 1000;

          if(timeLapsed <= 120 && !x.readStatus && !this.alreadyShownNotifications[x.id]) {
            showSnackBar = true;
            this.alreadyShownNotifications[x.id] = true;
          }
        }
      })

      //Latest record whose status is updated from In progress to complete and call 1 time 
      this.notificationData.forEach(xl => {
        let index = this.templatestnotifydata.findIndex(x => x.id == xl.id && xl.eventStatus == 'COMPLETED');
        let indexForCreditForm = this.templatestnotifydata.findIndex(x => x.id == xl.id && xl.eventStatus == 'COMPLETED' && 
        xl.eventType == 'CREDIT_FORM_SUBMISSION');

        //For Equity Form
        if (index > -1) {
          // Both the Below Subscriptions are Triggering the submission, so keeping 'notificationStatusUpdateWithAllDetails' 
          // subscription as it will reload the page
          // this.notifyStatusUpdate.next(true);

          //For Credit - Coverage Analysis Only
          this.notificationStatusUpdateForEquity.next(true);

          this.notificationStatusUpdateWithAllDetails.next(this.templatestnotifydata[index]);
          this.templatestnotifydata.splice(index, 1);

          showSnackBar = true;
        }

        //For Credit Form
        if(indexForCreditForm > -1) {
          this.notificationStatusUpdateForCredit.next(true);
        }
        
      })

      if(showSnackBar) {
        this.toastService.openSnackBar(this.translateService.getLabel('info_click_bell'));
      }
    })
  }

  updateReadStatusById(id) {
    const url = environment.apiVersion + '/event_notification_v2/update_read_status_by_id?id=' + id + '&readStatus=true';

    this.ds.appHttpRequest("GET", "vcp", url, null).subscribe();;
  }
}
