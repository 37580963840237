import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyExchangeService } from '../services/currency-exchange.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-currency-dropdown',
  templateUrl: './currency-dropdown.component.html',
  styleUrls: ['./currency-dropdown.component.scss']
})
export class CurrencyDropdownComponent implements OnInit, OnChanges {

  @Input() userSelectedCurrency;
  @Input() sourceCurrency;
  @Input() currencyConversionDate;

  
  
  @Output() conversionMultiple = new EventEmitter();

  constructor(private ds: DataService, private modalService: NgbModal, private currencyExchangeService : CurrencyExchangeService) { }
  

  ngOnInit() {
    if(!this.userSelectedCurrency){
      this.userSelectedCurrency = this.sourceCurrency;
    }
  }

  ngOnChanges(){
  }

  openPopUpModel(content){
    this.modalService.open(content, {backdrop: 'static', size: 'lg', centered: true });
  }

  currencyChanged(){
    if(this.currencyExchangeService.exchangeRates[this.userSelectedCurrency + '' + this.sourceCurrency + '' + this.currencyConversionDate]){
      this.conversionMultiple.emit({ 
        conversionMultiple: this.currencyExchangeService.exchangeRates[this.userSelectedCurrency + '' + this.sourceCurrency + '' + this.currencyConversionDate], 
        sourceCurrency: this.sourceCurrency,
        userSelectedCurrency: this.userSelectedCurrency,
        conversionDate: this.currencyConversionDate
      });
    }
    else{
      this.ds.getValuationDateCurrency(this.userSelectedCurrency, this.sourceCurrency, this.currencyConversionDate).subscribe( res => {
        // console.log("Currency Conversion Multple Fetched ", res);
        this.conversionMultiple.emit({ 
          conversionMultiple: res.body['response'].value, 
          sourceCurrency: this.sourceCurrency,
          userSelectedCurrency: this.userSelectedCurrency,
          conversionDate: this.currencyConversionDate
        });
      }, error => {
        console.log("Failed to fetch the Currency Conversion", error)
      })
    }
  }

  
  
  currencies = [    
    { id: "USD", country: "United States Dollar" },
    { id: "GBP", country: "British Pound Sterling" },
    { id: "EUR", country: "Euro" },
    { id: "CHF", country: "Swiss Franc" },
    { id: "AED", country: "United Arab Emirates Dirham" },
    { id: "AFN", country: "Afghan Afghani" },
    { id: "ALL", country: "Albanian Lek" },
    { id: "AMD", country: "Armenian Dram" },
    { id: "ANG", country: "Netherlands Antillean Guilder" },
    { id: "AOA", country: "Angolan Kwanza" },
    { id: "ARS", country: "Argentine Peso" },
    { id: "AUD", country: "Australian Dollar" },
    { id: "AWG", country: "Aruban Florin" },
    { id: "AZN", country: "Azerbaijani Manat" },
    { id: "BAM", country: "Bosnia-Herzegovina Convertible Mark" },
    { id: "BBD", country: "Barbadian Dollar" },
    { id: "BDT", country: "Bangladeshi Taka" },
    { id: "BGN", country: "Bulgarian Lev" },
    { id: "BHD", country: "Bahraini Dinar" },
    { id: "BIF", country: "Burundian Franc" },
    { id: "BMD", country: "Bermudan Dollar" },
    { id: "BND", country: "Brunei Dollar" },
    { id: "BOB", country: "Bolivian Boliviano" },
    { id: "BRL", country: "Brazilian Real" },
    { id: "BSD", country: "Bahamian Dollar" },
    { id: "BTC", country: "Bitcoin" },
    { id: "BTN", country: "Bhutanese Ngultrum" },
    { id: "BWP", country: "Botswanan Pula" },
    { id: "BYN", country: "Belarusian Ruble" },
    { id: "BYR", country: "Belarusian Ruble" },
    { id: "BZD", country: "Belize Dollar" },
    { id: "CAD", country: "Canadian Dollar" },
    { id: "CDF", country: "Congolese Franc" },
    { id: "CLF", country: "Chilean Unit of Account (UF)" },
    { id: "CLP", country: "Chilean Peso" },
    { id: "CNY", country: "Chinese Yuan" },
    { id: "COP", country: "Colombian Peso" },
    { id: "CRC", country: "Costa Rican Colón" },
    { id: "CUC", country: "Cuban Convertible Peso"},
    { id: "CUP", country: "Cuban Peso" },
    { id: "CVE", country: "Cape Verdean Escudo" },
    { id: "CZK", country: "Czech Republic Koruna" },
    { id: "DJF", country: "Djiboutian Franc" },
    { id: "DKK", country: "Danish Krone" },
    { id: "DOP", country: "Dominican Peso" },
    { id: "DZD", country: "Algerian Dinar" },
    { id: "EEK", country: "Estonian Kroon" },
    { id: "EGP", country: "Egyptian Pound" },
    { id: "ERN", country: "Eritrean Nakfa" },
    { id: "ETB", country: "Ethiopian Birr" },
    { id: "FJD", country: "Fijian Dollar" },
    { id: "FKP", country: "Falkland Islands Pound" },
    { id: "GEL", country: "Georgian Lari" },
    { id: "GGP", country: "Guernsey Pound" },
    { id: "GHS", country: "Ghanaian Cedi" },
    { id: "GIP", country: "Gibraltar Pound" },
    { id: "GMD", country: "Gambian Dalasi" },
    { id: "GNF", country: "Guinean Franc" },
    { id: "GTQ", country: "Guatemalan Quetzal" },
    { id: "GYD", country: "Guyanaese Dollar" },
    { id: "HKD", country: "Hong Kong Dollar" },
    { id: "HNL", country: "Honduran Lempira" },
    { id: "HRK", country: "Croatian Kuna" },
    { id: "HTG", country: "Haitian Gourde" },
    { id: "HUF", country: "Hungarian Forint" },
    { id: "IDR", country: "Indonesian Rupiah" },
    { id: "ILS", country: "Israeli New Sheqel" },
    { id: "IMP", country: "Manx pound" },
    { id: "INR", country: "Indian Rupee" },
    { id: "IQD", country: "Iraqi Dinar" },
    { id: "IRR", country: "Iranian Rial" },
    { id: "ISK", country: "Icelandic Króna" },
    { id: "JEP", country: "Jersey Pound" },
    { id: "JMD", country: "Jamaican Dollar" },
    { id: "JOD", country: "Jordanian Dinar" },
    { id: "JPY", country: "Japanese Yen" },
    { id: "KES", country: "Kenyan Shilling" },
    { id: "KGS", country: "Kyrgystani Som" },
    { id: "KHR", country: "Cambodian Riel" },
    { id: "KMF", country: "Comorian Franc" },
    { id: "KPW", country: "North Korean Won" },
    { id: "KRW", country: "South Korean Won" },
    { id: "KWD", country: "Kuwaiti Dinar" },
    { id: "KYD", country: "Cayman Islands Dollar" },
    { id: "KZT", country: "Kazakhstani Tenge" },
    { id: "LAK", country: "Laotian Kip" },
    { id: "LBP", country: "Lebanese Pound" },
    { id: "LKR", country: "Sri Lankan Rupee" },
    { id: "LRD", country: "Liberian Dollar" },
    { id: "LSL", country: "Lesotho Loti" },
    { id: "LTL", country: "Lithuanian Litas" },
    { id: "LVL", country: "Latvian Lats" },
    { id: "LYD", country: "Libyan Dinar" },
    { id: "MAD", country: "Moroccan Dirham" },
    { id: "MDL", country: "Moldovan Leu" },
    { id: "MGA", country: "Malagasy Ariary" },
    { id: "MKD", country: "Macedonian Denar" },
    { id: "MMK", country: "Myanma Kyat" },
    { id: "MNT", country: "Mongolian Tugrik" },
    { id: "MOP", country: "Macanese Pataca" },
    { id: "MRO", country: "Mauritanian Ouguiya" },
    { id: "MUR", country: "Mauritian Rupee" },
    { id: "MVR", country: "Maldivian Rufiyaa" },
    { id: "MWK", country: "Malawian Kwacha" },
    { id: "MXN", country: "Mexican Peso" },
    { id: "MYR", country: "Malaysian Ringgit" },
    { id: "MZN", country: "Mozambican Metical" },
    { id: "NAD", country: "Namibian Dollar" },
    { id: "NGN", country: "Nigerian Naira" },
    { id: "NIO", country: "Nicaraguan Córdoba" },
    { id: "NOK", country: "Norwegian Krone" },
    { id: "NPR", country: "Nepalese Rupee" },
    { id: "NZD", country: "New Zealand Dollar" },
    { id: "OMR", country: "Omani Rial" },
    { id: "PAB", country: "Panamanian Balboa" },
    { id: "PEN", country: "Peruvian Nuevo Sol" },
    { id: "PGK", country: "Papua New Guinean Kina" },
    { id: "PHP", country: "Philippine Peso" },
    { id: "PKR", country: "Pakistani Rupee" },
    { id: "PLN", country: "Polish Zloty" },
    { id: "PYG", country: "Paraguayan Guarani" },
    { id: "QAR", country: "Qatari Rial" },
    { id: "RON", country: "Romanian Leu" },
    { id: "RSD", country: "Serbian Dinar" },
    { id: "RUB", country: "Russian Ruble" },
    { id: "RWF", country: "Rwandan Franc" },
    { id: "SAR", country: "Saudi Riyal" },
    { id: "SBD", country: "Solomon Islands Dollar" },
    { id: "SCR", country: "Seychellois Rupee" },
    { id: "SDG", country: "Sudanese Pound" },
    { id: "SEK", country: "Swedish Krona" },
    { id: "SGD", country: "Singapore Dollar" },
    { id: "SHP", country: "Saint Helena Pound" },
    { id: "SLL", country: "Sierra Leonean Leone" },
    { id: "SOS", country: "Somali Shilling" },
    { id: "SRD", country: "Surinamese Dollar" },
    { id: "STD", country: "São Tomé and Príncipe Dobra" },
    { id: "SVC", country: "Salvadoran Colón" },
    { id: "SYP", country: "Syrian Pound" },
    { id: "SZL", country: "Swazi Lilangeni" },
    { id: "THB", country: "Thai Baht" },
    { id: "TJS", country: "Tajikistani Somoni" },
    { id: "TMT", country: "Turkmenistani Manat" },
    { id: "TND", country: "Tunisian Dinar" },
    { id: "TOP", country: "Tongan Paʻanga" },
    { id: "TRY", country: "Turkish Lira" },
    { id: "TTD", country: "Trinidad and Tobago Dollar" },
    { id: "TWD", country: "New Taiwan Dollar" },
    { id: "TZS", country: "Tanzanian Shilling" },
    { id: "UAH", country: "Ukrainian Hryvnia" },
    { id: "UGX", country: "Ugandan Shilling" },
    { id: "UYU", country: "Uruguayan Peso" },
    { id: "UZS", country: "Uzbekistan Som" },
    { id: "VEF", country: "Venezuelan Bolívar Fuerte" },
    { id: "VND", country: "Vietnamese Dong" },
    { id: "VUV", country: "Vanuatu Vatu" },
    { id: "WST", country: "Samoan Tala" },
    { id: "XAF", country: "CFA Franc BEAC" },
    { id: "XAG", country: "Silver (troy ounce)" },
    { id: "XAU", country: "Gold (troy ounce)" },
    { id: "XCD", country: "East Caribbean Dollar" },
    { id: "XDR", country: "Special Drawing Rights" },
    { id: "XOF", country: "CFA Franc BCEAO" },
    { id: "XPF", country: "CFP Franc" },
    { id: "YER", country: "Yemeni Rial" },
    { id: "ZAR", country: "South African Rand" },
    { id: "ZMK", country: "Zambian Kwacha (pre-2013)" },
    { id: "ZMW", country: "Zambian Kwacha" },
    { id: "ZWL", country: "Zimbabwean Dollar" }
]



}
