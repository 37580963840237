import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UserManagementService } from '../services/user-management.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styleUrls: ['./termandcondition.component.scss']
})

export class TermandconditionComponent implements OnInit {
  closeResult: string = '';
  constructor(private modalService: NgbModal, private router: Router, public us: UserManagementService, public ds: DataService) { }

  ngOnInit(): void {
    document.getElementById('modal-open').click();
  }

  async saveTandCStatus() {
    this.ds.saveTandCStatus().subscribe(
      (data) => {
        this.modalService.dismissAll();
        this.router.navigate(['auth']);
      },
      error => {
        console.log("eeeeee", error);
      }
    )
  }

  onDecline() {
    this.modalService.dismissAll();
    const details: any = this.us.getUserDetails();
    this.us.addUserAction("User Logout", details.id, details.userName, "User Management");
    localStorage.removeItem('73S-User');
    localStorage.removeItem('73S-Auth');
    window.open(environment.portalUrl + '/#/logout', "_self");
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  } getDismissReason(reason: any) {
    throw new Error('Method not implemented.');
  }
}
