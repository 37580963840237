import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Application } from './application.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '../services/message.service';
import { DataService } from '../services/data.service';

import * as moment from 'moment/moment';
import { Router, ActivatedRoute } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UserManagementService } from '../services/user-management.service';
import { LoadingPopupComponent } from '../utils/loading-popup/loading-popup.component';
import { environment } from 'src/environments/environment';
import { UtilService } from '../utils/util.service';

@Component({
  selector: 'app-application-forms',
  templateUrl: './application-forms.component.html',
  styleUrls: ['./application-forms.component.scss']
})
export class ApplicationFormsComponent implements OnInit {
  
  emailId = "";
  founderUserName = "";
  companyName = "";
  searchNameString;

  userId;
  isCorpUser;
  myOrgUsers = [];
  assignedUsers = {};
  formDetails = {};

  formTypeSelection = "ENTR";
  allForms = [];

  searchName;
  user73s = false;
  searchedCompanyList = [];

  statusValues = ["In Progress", "Completed", "Needs Clarification", "On Hold"];

  questionTypes = [ 
    {label: "Short Text", type: "text"},
    {label: "Paragraph", type: "text-area"},
    {label: "Multi Choice", type: "checkbox"},
    {label: "Single Choice", type: "radio"}
  ]

  additionalQuestions = []

  radioCheckedCompany: boolean = false;
  modalCompanyName: string;
  selectedFormId: string;
  selectedForm;

  sendTypes = [
    "Enterpreneur",
    "VC"
  ]

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  enterPreneurEmailIds = []
  
  loading:boolean = true;
  @ViewChild('closeBtn', { static: false }) private closeModal: ElementRef;

  roleSlideToggleChecked: boolean[];
  groupSlideToggleChecked: boolean[];
  userSlideToggleChecked: boolean[];
  
  selectedRoleRadio
  selectedGroupRadio
  selectedUserRadio
  
  editable = {"T2": [], "T7": [], "ENTR": [], "IV": []};
  roleEditable: boolean[];
  groupEditable: boolean[];
  userEditable: boolean[];

  ELEMENT_DATA: Application[] = [];
  
  summary = [
    {
      "header": "Total Number of Applications",
      "value": "",
      "footer": {
        "label": "New This Month",
        "value": ""
      }
    },
    {
      "header": "Applications with Enterpreneur",
      "value": ""
    },
    {
      "header": "Applications with 73 Strings",
      "value": ""
    },
    {
      "header": "Applications Completed",
      "value": ""
    }
  ]

  displayedColumns: string[] = [
    "select",
    "projectName",
    "name",
    "vScore",
    "assignedPerson",
    "initiationDate",
    "status",
    "lastUpdatedOn",
    "view",
    "edit"
  ];
  
  dataSource = new MatTableDataSource<Application>(this.ELEMENT_DATA);


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  isProduction = true;

  constructor(private modalService: NgbModal, 
      private router: Router,
      private um: UserManagementService, 
      private dataService:DataService, 
      private ms: MessageService,
      private activatedRoute: ActivatedRoute, 
      private dialog: MatDialog,
      private popupMessageService: UtilService) { }

  ngOnInit() {
    this.isProduction = environment.production;
    this.user73s = this.um.is73sAdmin();    
    
    this.isCorpUser = this.um.isSelectedUserCorp();
    this.formTypeSelection = this.isCorpUser? 'CORP' : 'T2';

    this.getAllForms();

    const myDetails = this.um.getSelectedUserDetails();
    this.um.getUsers(myDetails.organization.id).subscribe(result=>{
      this.myOrgUsers = result.body["response"];

      this.myOrgUsers = this.myOrgUsers.filter(user=>{
        return user.userId !== myDetails.userId
      })
    }, error=>{
      console.log("No users are found in my organization", error);
    })
  }

  getAllForms() {
    this.activatedRoute.paramMap.subscribe(params => {
      const userId = params.get("userId");
      if(userId) {
        this.popupMessageService.showLoadingPopup();

        this.userId = userId;

        this.dataService.getAllVCForms(userId, null).subscribe( forms =>{
          if(forms.body["response"] && forms.body["response"].length > 0) {            
            this.getAssignedForms(forms.body["response"]);
          } else {
            this.getAssignedForms();
          }
        }, (error)=>{
          this.getAssignedForms();
          console.log("Error in fetching all forms", error);
        })
      }
    })      
  }

  async getAssignedForms(selfCreatedForms?) {
    let allForms = []
    if(selfCreatedForms) {
      allForms = allForms.concat(selfCreatedForms)
    }

    await this.getIVSavedForms(allForms);

    this.dataService.getAssignedForms(this.userId).subscribe(results=>{
      allForms = allForms.concat(results.body["response"]);
      this.parseFormsData(allForms);

      this.loading = false;
      this.popupMessageService.closeAllPopups();
    }, error=>{
      this.parseFormsData(allForms);

      this.loading = false;
      this.popupMessageService.closeAllPopups();
      console.log("Error in fetching assigned forms", error);
    })
  }

  async getIVSavedForms(allForms) {
    const userId = this.um.getSelectedUserDetails().id;
    try {
      const ivForms = (await this.dataService.getIVSavedForms(userId).toPromise() as any).body.response;
      ivForms.forEach(formData => {
        const form = formData.form;  
        form.details = formData.formDetails;
        form.assessorUserId = form.vcId;
        form.tag = formData.tag;

        allForms.push(form);
      });
    } catch(e) {
      console.log("Error in fetching iv forms", e);
    }

    return;
  }

  parseFormsData(forms) {    
    let countSubmitted = 0;
    let countInitiated = 0;
    let countCompleted = 0;

    this.allForms = [];

    forms.forEach((safForm, index) => {                
      const dbForm = this.prepareDefaultTableData(safForm);
      
      if(safForm.type === "ENTR" || safForm.type === "T7") {
        dbForm.assignees = this.getAssignedUsers(safForm, safForm.assessorDetails);
      } else {
        // type == IV & T+2
        dbForm.assignees = this.getAssignedUsers(safForm, safForm.assignees);
      }

      this.formDetails[safForm.id] = safForm.details ? JSON.parse(safForm.details): {};

      // if(dbForm.status === STATUS.INITIATED) {
      //   countInitiated ++;

      // } else if(dbForm.status === STATUS.SUBMITTED) {
      //   countSubmitted ++;

      // } else if(dbForm.status === STATUS.COMPLETED) {
      //   countCompleted ++;
      // }

      this.allForms.push(dbForm);
    });

    this.filterForms();

    this.loading = false;

    // this.summary[0].value = this.ELEMENT_DATA.length + "";
    // this.summary[1].value = countInitiated + "";
    // this.summary[2].value = countSubmitted + "";
    // this.summary[3].value = countCompleted + "";
  }

  getAssignedUsers(form, assigneeDetails) {
    const loggedInUserDetails = this.um.getSelectedUserDetails();
    if(assigneeDetails) {
      const details = JSON.parse(assigneeDetails);
      
      this.assignedUsers[form.id] = details.assignees;

      let assignees = details.assignees.map(user=>user.userName);
      return [details.createdBy.name].concat(assignees).join(", ");
    } else {
      return loggedInUserDetails.userName
    }
  }

  filterForms() {  
    const forms = this.allForms.filter(f=>{
      return f.formType.toUpperCase() === this.formTypeSelection 
            && (!this.searchNameString 
              || this.searchNameString.length < 3
              || f.name.toLowerCase().indexOf(this.searchNameString.toLowerCase()) >= 0
              );
    })

    this.editable[this.formTypeSelection] = new Array(forms.length);
    this.editable[this.formTypeSelection].forEach((flag, index) => {
      this.editable[this.formTypeSelection].push(false);
    });

    this.ELEMENT_DATA = forms.sort((f1, f2)=>{
      const f1Date = new Date(f1.sortDate);
      const f2Date = new Date(f2.sortDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })

    // this.ELEMENT_DATA = this.ELEMENT_DATA.sort((f1, f2)=>{
    //   return f1.status === f2.status? 0: f1.status < f2.status? 1: -1;
    // })

    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  searchCompanyName(event) {
    if(!event.srcElement.value || event.srcElement.value.length < 3) {
      if(event.srcElement.value && event.srcElement.value.length === 2) {
        this.filterForms()
      }
      return;
    } 

    this.filterForms()
  }

  prepareDefaultTableData(safForm) {
    let application: Application;
    const details = JSON.parse(safForm.details)

    if(details) {
      application = {
        id: safForm.id,
        status: this.getFormStatus(safForm, details),
        name: details["companyName"],
        sector: details["sector"],
        initiationDate: moment(safForm.createdDate).format('ll'),
        stakeOffered: details["stakeOffered"],
        valuationAsked: details["valuationAsked"],
        lastUpdatedOn : safForm.lastModifiedDate? moment(safForm.lastModifiedDate).format('ll') : "",
        sortDate: safForm.lastModifiedDate? moment(safForm.lastModifiedDate).format('ll') : moment(safForm.createdDate).format('ll'),
        formType: safForm.formType,
        assessorUserId: safForm.assessorUserId,
        tag: safForm.tag,
        projectName: details["projectName"],
        assignees: null,
        vScore: details["vScore"],
        vScoreTrace: details["vScoreTrace"]
      }
    } else {
      application = {
        id: safForm.id,
        status: STATUS.INITIATED,
        name: "",
        sector: "",
        initiationDate: moment(safForm.createdDate).format('ll'),
        stakeOffered: "",
        valuationAsked: "",
        lastUpdatedOn : safForm.lastModifiedDate? moment(safForm.lastModifiedDate).format('ll') : "",
        sortDate: safForm.lastModifiedDate? moment(safForm.lastModifiedDate).format('ll') : moment(safForm.createdDate).format('ll'),
        formType: safForm.formType,
        assessorUserId: this.userId,
        tag: safForm.tag,
        projectName: "",
        assignees: null,
        vScore: null,
        vScoreTrace: ""
      }
    }

    return application;
  }
  
  getFormStatus(form, details) {
    if(form.status) {
      return form.status
    } else if(details["status"] && details["status"].length > 0) {
      return details["status"]
    } else {
      return STATUS.INITIATED;
    }
  }

  formTypeSelectionChange(event) {
    this.formTypeSelection = event;
    this.radioCheckedCompany = false;
    this.filterForms();
  }

  clickView(form) {    
    localStorage.setItem('formId', form.id);
    localStorage.setItem('formTag', form.tag);
    this.dataService.setFormType(form.formType);

    if(this.dataService.isT2Form() || this.dataService.isInstaValForm()) {
      window.open(environment.portalUrl + environment.ivForms + "/#/summary", "_self")

    } else if(this.um.isSelectedUserCorp()){
      window.open(environment.portalUrl + environment.corpForms + "/#/valuation-summary?id=" + form.id + "&fv=" + 2, "_self")

    } else {
      window.open(environment.portalUrl + environment.generalForms + "/#/summary", "_self")
    }
  }

  openCreateNewUser(content) {
    this.modalService.open(content, { centered: true , windowClass: 'create-new-group-modal'});
  }

  openStartApplication(content) {
    this.additionalQuestions = [];
    this.modalService.open(content, { centered: true , windowClass: 'start-application-modal'});
  }

  openPopupModal(content){
    this.modalService.open(content, { centered: true , windowClass: 'send-enterpreneur-modal'});
  }

  roleRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt1') {
        
    }
  }

  groupRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt2') {
        
    }
  }

  userRadioChange($event: MatRadioChange) {
    // console.log($event.source.name, $event.value);

    if ($event.source.name === 'radioOpt3') {
        
    }
  }

  matRoleSlideToggleChange(event: MatSlideToggleChange) {
    // console.log('toggle', event.checked);
  }

  matGroupSlideToggleChange(event: MatSlideToggleChange) {
    // console.log('toggle', event.checked);
  }

  matUserSlideToggleChange(event: MatSlideToggleChange) {
    // console.log('toggle', event.checked);
  }

  startApplication() {
    if(!this.companyName || (!this.isCorpUser && (!this.emailId || !this.userId))) return;

    this.modalService.dismissAll();

    this.dialog.open(LoadingPopupComponent, { disableClose: true });
    this.ms.publish("loadingMessage", "Creating Company Profile.");
    
    if(!this.isCorpUser) {
      this.um.getUserDetailsByID(this.emailId).subscribe((userDetails)=>{
        if(userDetails.body["success"]) {        
          // console.log("userDetails", userDetails.body["response"].userId); 
          if(userDetails.body["response"] && userDetails.body["response"].userId && userDetails.body["response"].userId === this.emailId) {
            this.ms.publish("loadingMessage", "Company Profile Created.");
            this.createSAFForm();

          } else {
            this.createSAFOrg();
          }
        } else {
          // console.log("No user details found.");
          this.createSAFOrg();
        }
      }, error=>{
        console.log("Error in fetching user details", error);
        this.createSAFOrg();
      });
    } else {
      this.createSAFForm();
    }
  }

  createSAFOrg() {
    this.um.createOrg({
      email: this.emailId,
      orgName: this.companyName,
      type: "saf",
      userName: this.founderUserName
    }).subscribe(org=>{

      // console.log("Created new Org successfully", org);

      this.ms.publish("loadingMessage", "Company Profile Created.");
      this.createSAFForm();

    }, error=>{
      console.log("Error in creating SAF org", error);
      this.ms.publish("loadingMessage", "Company Profile Creation Failed.");

      setTimeout(() => {
        this.dialog.closeAll();
      }, 2000);      
    })
  }

  deleteSavedForm() {
    const dialogRef = this.popupMessageService.showConfirmMessage("Are you sure that you wish to delete " + this.modalCompanyName + " application ?", "Yes", "No")
    
    dialogRef.afterClosed().subscribe(confirmation => { 
      if(confirmation === "Yes") {
        this.popupMessageService.showLoadingPopup();

        this.dataService.deleteSavedForm(this.selectedFormId).subscribe(result=>{
           
          const formIndex = this.allForms.findIndex(f=>{
            return f.id === this.selectedFormId;
          })

          if(formIndex >= 0) {
            this.allForms.splice(formIndex, 1);
            this.filterForms();
          }
          this.popupMessageService.closeAllPopups();
          
        }, error=>{
          console.log("deleteSavedForm error", error);
          this.popupMessageService.showMessage("Failed to delete " + this.modalCompanyName + " application. Please try later.", "Ok");
        })
      }
    });
  }

  createSAFForm() {

    let loggedInUserDetails = this.um.getUserDetails();

    if(this.um.is73sAdmin()) {      
      loggedInUserDetails = this.um.getSelectedUserDetails();
    }
    
    let type = "ENTR";
    if(this.isCorpUser) {
      this.emailId = loggedInUserDetails.userId;
      type = "corp";
    }

    this.additionalQuestions.forEach((question, index)=>{
      question.id = "EID_"+index;
      question.keyName = "ADD_QS_"+index;
    })

    this.dataService.createNewForm(this.companyName, this.emailId, type, loggedInUserDetails, this.additionalQuestions).subscribe((status)=>{
      // console.log("Created new form successfully", status);
      
      const application: Application = {
        id: status.body["response"].formId,
        status: STATUS.INITIATED,
        name: this.companyName,
        sector: "",
        initiationDate: moment(new Date()).format('ll'),
        stakeOffered: "",
        valuationAsked: "",
        lastUpdatedOn : "",
        sortDate: moment(new Date()).format('ll'),
        formType: type,
        assessorUserId: this.userId,
        tag: null,
        projectName: "",
        assignees: null,
        vScore: null,
        vScoreTrace: ""
      }

      this.ELEMENT_DATA = [application].concat(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.ms.publish("loadingMessage", "Application is created.");
      setTimeout(() => {
        this.dialog.closeAll();
      }, 2000);   

    }, error=>{
      console.log("Failed to create new form", error);
      this.ms.publish("loadingMessage", "Application Creation Failed.");

      setTimeout(() => {
        this.dialog.closeAll();
      }, 2000);   
    })
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.enterPreneurEmailIds.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }
  remove(value): void {
    const index = this.enterPreneurEmailIds.indexOf(value);

    if (index >= 0) {
      this.enterPreneurEmailIds.splice(index, 1);
    }
  }

  checkedCompany(checkedIndex){
    this.radioCheckedCompany = true;

    this.modalCompanyName = this.dataSource.data[checkedIndex].name;
    this.selectedFormId = this.dataSource.data[checkedIndex].id;
    this.selectedForm = this.dataSource.data[checkedIndex];
  }

  questionInputChange(index, questionType){
    // console.log("questionType.type", questionType);

    const question = this.additionalQuestions[index];
    question.elementType = questionType;

    question.elementOptions = [];

    switch(questionType) {
      case "radio" : question.elementOptions.push({ "id": 0, "optionValue": "" }); break;
      case "checkbox" : question.elementOptions.push({ "id": 0, "optionValue": "" }); break;
    }
  }

  addQuestion(){
    // console.log("addQuestion 1");
    if(this.additionalQuestions.length === 3) return;

    this.additionalQuestions.push(new Question());
    // console.log("addQuestion", this.additionalQuestions);
  }

  deleteQuestion(index){
    this.additionalQuestions.splice(index,1);
  }

  addOption(qi){
    const id = this.additionalQuestions[qi].elementOptions.length;

    if(id === 4)  return;
    
    this.additionalQuestions[qi].elementOptions.push({
      "id": id,
      "optionValue": ""
    })
  }

  deleteOption(qi,oi){
    this.additionalQuestions.forEach((question,questionIndex)=>{
      if(questionIndex == qi){
        question.elementOptions.splice(oi,1);
      }
    })
  }

  sendToEnterpreneur() {
    
  }

  openSAFCorpForm() {
    window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&id=" + this.selectedFormId, "_self");
  }

  orgUserSelectionChange(event, user) {

    if(!this.assignedUsers[this.selectedFormId]) {
      this.assignedUsers[this.selectedFormId] = []
    }
    
    this.assignedUsers[this.selectedFormId] = this.assignedUsers[this.selectedFormId].filter( u => u.id !== user.id )
    if(event.checked) {
      this.assignedUsers[this.selectedFormId].push(user);
    } 
  }

  assignUsersToForm() {    
    this.modalService.dismissAll();

    if(this.assignedUsers[this.selectedFormId]) {
      this.popupMessageService.showLoadingPopup();
      
      const userDetails = this.um.getSelectedUserDetails();

      const usersList = [];
      const assignees = [];
      this.assignedUsers[this.selectedFormId].forEach(u => {
        usersList.push(u.id)
        const uInfo = {
          id: u.id,
          userName: u.userName,
          userId: u.userId
        }
        assignees.push(uInfo)
      });

      const createdBy = {
        id: userDetails.id,        
        name: userDetails.userName,
        email: userDetails.userId
      }

      if(this.selectedForm.type === "ENTR" || this.selectedForm.type === "T7") {
        const body = {
          "users": usersList,
          "formId": this.selectedForm.id,
          "usersInfo": JSON.stringify({createdBy: createdBy, assignees: assignees})
        }

        this.dataService.assignUsersToSAFForm(body).subscribe(result=>{
          // console.log("Assignmet result", result);
          this.popupMessageService.showMessage("User Assignment is successfully completed.", "Ok")

          this.dataService.addEventNotification("FORM", JSON.stringify({createdBy: createdBy, assignees: assignees, companyName: this.selectedForm.name}), "Assigned", this.selectedForm.id, userDetails.organization.id).subscribe(notification=>{
            // console.log("FORM assignment form notification is added");

            this.selectedForm.assignees = [createdBy.name].concat(assignees.map(a=>a.userName)).join(", ")
          }, error=>{
            console.log("FORM assignment Event notification save failed", error);
          });
        }, error=>{
          console.log("Assignmet error", error);
          this.popupMessageService.showMessage("Failed to assign the selected users to form.", "Ok")
        })
         
      } else {

        const body = {
          "users": usersList,
          "formId": this.selectedForm.id,
          "orgId": userDetails.organization.id,
          "event": JSON.stringify({createdBy: createdBy, assignees: assignees, companyName: this.selectedForm.name}),
          "usersInfo": JSON.stringify({createdBy: createdBy, assignees: assignees})
        }

        this.dataService.assignUsersToForm(body).subscribe(result=>{
          // console.log("Assignmet result", result);
          this.popupMessageService.showMessage("User Assignment is successfully completed.", "Ok")

          this.selectedForm.assignees = [createdBy.name].concat(assignees.map(a=>a.userName)).join(", ")
        }, error=>{
          console.log("Assignmet error", error);
          this.popupMessageService.showMessage("Failed to assign the selected users to form.", "Ok")
        })
      }
    } 
  }

  isUserAssigned(userId) {
    if(!this.assignedUsers[this.selectedFormId]) return false;

    const foundIndex = this.assignedUsers[this.selectedFormId].findIndex(u=>{
      return u.id === userId
    })

    return  foundIndex >= 0; 
  }

  saveFormStatus(form) {
    const userDetails = this.um.getSelectedUserDetails();

    const details = this.formDetails[form.id];
    details["projectName"] = form.projectName;
    
    if(form.vScore) {
      details["vScore"] = form.vScore;
      const currentUpdate = userDetails.userName + " - " + (form.vScore ? form.vScore : 0);

      if(details["vScoreTrace"] && details["vScoreTrace"].indexOf(currentUpdate) < 0) {
        details["vScoreTrace"] = details["vScoreTrace"] + ", " +  currentUpdate;

      } else if(!details["vScoreTrace"]) {
        details["vScoreTrace"] = currentUpdate;
      }
    }

    this.dataService.saveFormStatus(form.id, {status: form.status, formDetails: JSON.stringify(details)}).subscribe(result=>{
      // console.log("Form status saved successfully", result.body)
    }, error=>{
      console.log("Failed to save form status.", error)
    })
  }

  vScoreChange(event, company) {
    const regex = /[^0-9.]/gi;
    event.target.value = event.target.value.replace(regex, '');

    const value = +event.target.value;

    if (value > 10) {
      event.target.value = event.target.value.substring(
        0,
        event.target.value.length - 1
      );
    }

    company.vScore = event.target.value;
  }

  openSearchHome() {
    this.router.navigateByUrl("/search-home")
  }
}


enum STATUS {
  INITIATED = "Form Initiated",
  SUBMITTED = "Submitted",
  COMPLETED = "Completed"
}

export class Question {
          "children": [];
          "defaultReplication" = null;
          "dependentElementId" = null;
          "dependentElementOptionId" = null;
          "elementOptions" = [];
          "elementSequence": number = 0;
          "elementType" = "text";
          "formId" = null;
          "helpDataId" = null;
          "icon": null;
          "id" = "";
          "inputLimit" = 2000;
          "keyName" = "ADD_QS_";
          "label" = "";
          "maxReplication"= null;
          "placeHolder"= null;
          "previewMode" = false;
          "questionId" = "";
          "replicationEnabled" = false;
          "required" = true;
          "toolTip" = "";
          "topicIn"= null;
          "topicOut"= null;
          "version": 1;
    }
