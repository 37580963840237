import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { UserManagementService } from '../services/user-management.service';
import { DataService } from '../services/data.service';
import { UtilService } from '../utils/util.service';

import cloneDeep from 'lodash/cloneDeep';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-search-home',
  templateUrl: './search-home.component.html',
  styleUrls: ['./search-home.component.scss']
})
export class SearchHomeComponent implements OnInit{

  @ViewChild('inputBox', {static: false}) inputBox: ElementRef;
  
  isUAT = false;

  apiUsageStatistics;
  countries;
  
  instaValCompanyName;
  ivSearchList = [];
  searchingCompany = false;
  searchingMessage = "";
  showSearchBox = true;
  selectedSearchBox = "Name"
  searchedCompanies = []
  searchSource = "111";

  newCompanyRequest = { name: "", description: "", linkedinURL: "", website: "", yearFounded: "", location: {city: "", state: "", country: ""}}

  suggestedCompanies = []

  constructor(public ds: DataService, private us: UserManagementService,
    private router: Router, private utilService: UtilService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.isUAT = !environment.production;

    this.getApiUsageStatistics();
    this.getRelatedCompanies();
  }

  getRelatedCompanies() {
    const details = this.us.getSelectedUserDetails()["organization"].details
    if(details) {
      const orgInfo = JSON.parse(details).orgInfo
      this.countries = orgInfo && orgInfo.region ? orgInfo.region.map(c=>c.name) : null;
    }

    if(!this.countries) return;

    this.ds.getRelatedCompanies(this.countries).subscribe(results=>{
      this.suggestedCompanies = results.body["response"]
    }, error=>{
      console.log("Failed to fetch related companies to show on search home page", error)
    })
  }

  getApiUsageStatistics() {
    this.us.getApiUsageStatistics().subscribe(result=>{
      // console.log("Statistics", result)
      this.apiUsageStatistics = result["response"];
    }, error=>{
      console.log("error while getting api usage statistics", error)
    })
  }

  searchInstaCompany() {
    if (!this.instaValCompanyName) {
      return;
    }
    this.ivSearchList = [];
    this.searchingCompany = true;
    this.searchingMessage = "Searching . . .";

    this.ds.searchCompany(this.instaValCompanyName).subscribe(result=>{
      // console.log("search results", result.body["response"]);
      if(result.body["response"]) {
        this.removeDuplicatesAndMerge(this.ivSearchList, result.body["response"].results, result.body["response"].source)
      }
      this.searchingCompany = false;
      this.searchingMessage = "No matching companies found.";

    }, error=>{
      this.searchingCompany = false;
      this.searchingMessage = "No matching companies found.";
      console.log("Error in searching the companies", error);
    })

    const loggedInUser = this.us.getSelectedUserDetails();

    this.ds.searchSavedCompany(loggedInUser.id, this.instaValCompanyName).subscribe(result=>{
      // console.log("search results - saved companies", result.body);
      const savedForms = [];
      result.body["response"].forEach(f => {
        const formDetails = JSON.parse(f.formDetails)
        if(formDetails && formDetails.cbInfo) {
          // console.log("Saved form", f);
          formDetails.cbInfo["tag"] = f.tag 
          formDetails.cbInfo["formId"] = f.formId 

          if(!formDetails.cbInfo["source"]) {
            formDetails.cbInfo["source"] = "111"
          }

          savedForms.push(formDetails.cbInfo);
        }
      });

      this.removeDuplicatesAndMerge(savedForms, this.ivSearchList)

    }, error=>{
      this.searchingCompany = false;
      console.log("Error in searching saved companies", error);
    })
  }

  removeDuplicatesAndMerge(existingArray, newArray, source?) {
    let duplicateIndex = -1;
    existingArray.forEach(c => {
      duplicateIndex = newArray.findIndex(newCompany=>{
        return newCompany["uuid"] === c["uuid"];
      })
    });

    if(source) {
      newArray.forEach(newCompany => {
        newCompany["source"] = source;
      });
    }

    if(duplicateIndex > -1) {
      existingArray.push(newArray[duplicateIndex]);
      newArray.splice(duplicateIndex, 1);
    }

    this.ivSearchList = existingArray.concat(newArray)

    // console.log("Merged List", this.ivSearchList);
  }

  openInstaValForm(company) {
    if(this.apiUsageStatistics && this.apiUsageStatistics.SEARCH.usage >= this.apiUsageStatistics.SEARCH.maxAllowedUsage) {
      this.utilService.showMessage("You have exceeded allowed number of searches. Please contact us to increase the limit", "Ok");
      return;
    }

    localStorage.setItem('formId', null);
    localStorage.setItem('formTag', null);
    this.ds.setFormType('IV');

    if(company.tag) {
      localStorage.setItem('formId', company.formId);
      localStorage.setItem('formTag', company.tag);

      if(company.tag === "T2") {
        this.ds.setFormType("T2");
      }
    }

    localStorage.setItem('iv-company', JSON.stringify(company));
    localStorage.setItem('iv-company-name', company.name);

    this.modalService.dismissAll();
    window.open(environment.portalUrl + environment.ivForms + '/#/summary', "_self");
  }

  openRequestForm(content) {
    this.router.navigateByUrl('/new-company');
  }

  openCompanySearch() {
    this.showSearchBox = true;
  }

  hideCompanySearch() {
    this.showSearchBox = true; //this.searchedCompanies.length !== 0;
  }

  closeSearchBox() {
    this.showSearchBox = true;
    this.searchedCompanies = [];
  }

  selectSearchedCompany(event, newCompanyContent) {
    this.instaValCompanyName = "";

    if(event.option.value === 'NEW_COMPANY') {
      this.openRequestForm(newCompanyContent)
      return;
    }

    const selectedCompany = this.ivSearchList.find(company=>{
      return company.uuid === event.option.value
    })
    
    this.openInstaValForm(selectedCompany);
  }

  searchCompanyNameChange() {
    if(!this.instaValCompanyName || this.instaValCompanyName < 3) return;

    this.searchingCompany = true;
    this.searchedCompanies = [{uuid: "SEARCHING", name: "Searching " + this.instaValCompanyName}]

    // this.ds.searchCompany(event.srcElement.value).subscribe(result=>{searchCompanyAutoComplete
    this.ds.searchCompanyAutoComplete(this.instaValCompanyName, this.selectedSearchBox, this.ds.apiVersion).subscribe(result=>{
      this.searchingCompany = false;
      if(result.body["response"] && result.body["response"].results) {
        this.searchedCompanies = result.body["response"].results
        this.ivSearchList = cloneDeep(this.searchedCompanies);

      } else {
        this.searchedCompanies = [{uuid: "NEW_COMPANY", name: "Request for " + this.instaValCompanyName}]
      }
      // this.inputBox.nativeElement.focus();
    }, error=>{
      this.searchingCompany = false;
      this.searchedCompanies = [{uuid: "NEW_COMPANY", name: "Request for " + this.instaValCompanyName}]
      // this.inputBox.nativeElement.focus();
    })
  }

  logoSourceError(event, company) {
    company.logo_url = null;
  }

  openSavedCompanies() {
    const user = this.us.getSelectedUserDetails();
    this.router.navigateByUrl('/application-forms/' + user.id);
  }
}
