
<div class="row">
  <div class="col-5 file-name data-col">
    <input class="form-control" style="width:100%;" [(ngModel)]="description" placeholder="{{ translateService.getLabel('upload_new_file') }}" />
  </div>
  <!-- <div class="col-3 file-name data-col">
    <select class="form-control" style="width:100%;" [(ngModel)]="type">
      <option value="Financials">Financials</option>
      <option value="Legals">Legal</option>
      <option value="Others">Others</option>
    </select>
  </div> -->
  <div class="col file-name data-col">
    <button class="btn upload-btn" (click)="clickOnFileInput(fileInput)">
      {{ translateService.getLabel("select_file") }}
    </button>
    <input type="file" style="display: none;" #fileInput class="custom-file-input" (change)="pickFile($event)"/>
    <ng-template [ngIf]="fileTobeUploaded && fileTobeUploaded.name">{{fileTobeUploaded.name}}</ng-template>
  </div>
  <div class="col-1">          
    <button class="btn upload-btn" (click)="upload()" [disabled]="!fileTobeUploaded || !description" style="background: darkblue; color: white;">
      <span class="upload-file-icon">
        <i class="fa fa-upload"></i>
      </span>
      {{ translateService.getLabel("upload") }}
    </button>
  </div>
</div>