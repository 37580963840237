<ng-template [ngIf] = "allPortfolioData">
  <div class="row" style=" margin: auto 1.11vw; margin-bottom: 2%;">
    <div class="col-5 border-shadow-class">

      <div class="valuation-heading" style="margin-left: 5px;">{{translateService.getLabel("valuation_by_sectors")}}</div>
      <div [chart]="columnChart" style="padding-top: 1.11vw; width: 650px;"></div>

    </div>

    <div class="col-2" style="margin-right: 0.33vw;">
      <div class="row bubble">
        <p class="col-12 portfolio-bubble-heading">
          <ng-template [ngIf]="totalAggregations">

            <ng-template [ngIf] = "totalAggregations.totalIRR == 'loading'" [ngIfElse] = "totalIRRLoaded">
              <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20">
              </mat-spinner>
            </ng-template>

            <ng-template #totalIRRLoaded>
              <ng-template [ngIf]="totalAggregations.totalIRR >= 0" [ngIfElse] = "totalIRRLessThanZero">
                {{ totalAggregations.totalIRR | number: '1.1-1'}}%
              </ng-template>
  
              <ng-template #totalIRRLessThanZero>
                <span style="color: red;">{{ totalAggregations.totalIRR | number: '1.1-1'}}% </span> 
              </ng-template>
            </ng-template>

          </ng-template>
        </p>
        <p class="col-12 portfolio-total-header">
          {{translateService.getLabel("gross_irr")}}
        </p>
      </div>
      <div class="row bubble">
        <p class="col-12 portfolio-bubble-heading">
          <ng-template [ngIf]="totalAggregations">
            {{ totalAggregations.totalMOIC | number: '1.1-1'}}x
          </ng-template>

          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
            *ngIf="!totalAggregations"></mat-spinner>
        </p>
        <p class="col-12 portfolio-total-header">
          {{translateService.getLabel("moic")}}
        </p>
      </div>
      <div class="row bubble">
        <p class="col-12 portfolio-bubble-heading">
          <ng-template [ngIf]="totalAggregations">
            <ng-template [ngIf]="totalAggregations.totalInvestment >= 100" [ngIfElse]="million">
              {{ reportingCurrency }} {{ totalAggregations.totalInvestment / 1000 | number: "1.2-2"}} Bn
            </ng-template>
            <ng-template #million>
              {{ reportingCurrency }} {{ totalAggregations.totalInvestment | number: "1.1-1"}} Mn
            </ng-template>
          </ng-template>

          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
            *ngIf="!totalAggregations"></mat-spinner>
        </p>
        <p class="col-12 portfolio-total-header">
          {{translateService.getLabel("investment_amount")}}
        </p>
      </div>
      <div class="row bubble" style="margin-bottom: 0;">
        <p class="col-12 portfolio-bubble-heading">
          <ng-template [ngIf]="totalAggregations">
            <ng-template [ngIf]="totalAggregations.totalRealisedProceeds >= 100" [ngIfElse]="million">
              {{ reportingCurrency }} {{ totalAggregations.totalRealisedProceeds / 1000 | number:
              "1.2-2"}} Bn
            </ng-template>
            <ng-template #million>
              {{ reportingCurrency }} {{ totalAggregations.totalRealisedProceeds | number: "1.1-1"}} Mn
            </ng-template>
          </ng-template>

          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20"
            *ngIf="!totalAggregations"></mat-spinner>
        </p>
        <p class="col-12 portfolio-total-header">
          {{translateService.getLabel("realised_proceeds")}}
        </p>
      </div>
    </div>

    <div class="col border-shadow-class">
      <!-- <div [chart] = "portfolioService.columnChart" style="height: 400px; width: 450px;"> -->
      <div class="valuation-heading">{{translateService.getLabel("geography")}}</div>
      <div [chart]="mapChart" id="map-chart" style="width: 27.9vw; margin: auto;">
      </div>
    </div>
  </div>
</ng-template>