<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' headerToBeAppended="Portfolio"></nav-menu-header>

<div class="top-header">
    <span style="font-size: 1.34vw; line-height: 1.61vw;">
        <ng-template [ngIf]="fundId">
            {{fundService.getFundName(fundId)}} -
        </ng-template>
        {{translateService.getLabel("portfolio_summary")}}
        
    </span>
</div>


<div *ngIf="transactions">
    <app-transactions (action)="transactions = false" [transactions]="portfolioService.transactionData">
    </app-transactions>
</div>

<!-- Equity Portfolio -->
<ng-template [ngIf]="!transactions">

    <!-- Debt Portfolio -->
    <app-debt-portfolio></app-debt-portfolio>

    <!-- Equity Portfolio Tables-->

    <app-equity-portfolio></app-equity-portfolio>

</ng-template>
