import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { CompanyValuationComponent } from '../company-valuation/company-valuation.component';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { PortFolioService } from '../portfolio.service';
import { cloneDeep } from "lodash";
import { UtilService } from 'src/app/utils/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/services/translation.service';
export interface User {
  name: string;
}

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent implements OnInit {

  constructor( private dataService: DataService, private router: Router,
    public fundService : FundListService, public portfolioService : PortFolioService, 
    public utilService : UtilService, private modalService : NgbModal, public translateService: TranslateService)
    {}
  investors = [];
  allValuationDates;
  selectedDate;
  capTableData;

  stakeValueAdjustments = {
    totalValueBeforeAdjustment: 0,
    totalValueAfterAdjustment: 0,
    adjustments: [],
    headerLabel: ""
  }

  selectedBSAssignment = ""

  investorName = "";

  loadingCaptable : boolean = false;

  loadingInvestorName: boolean = false;

  selectedFund;

  selectedInvestorName = "";
  warningMsg = "Cap Table does not exist!"

  capTableExists = true;

  myControl = new FormControl();
  options: User[] = [];

  suggestions = ""

  bsAssignmentName;

  // noOfShares;

  // options: User[] = [];
  filteredOptions: Observable<User[]>;

  // portfolioService.investmentTableData = [];

  investorWiseStakeValueAdjustments = {}

  ngOnInit() {
    if(!this.portfolioService.selectedFundId) {
      this.router.navigateByUrl('/users');
      return;

    } else if(!this.portfolioService.portfolioData || this.portfolioService.portfolioData.length == 0) {

      const dataReceived = this.portfolioService.isCompaniesAvailable.subscribe(() => {
        this.init();
      })
    } else {

      this.init();
    }
  }

  async init() {
    this.portfolioService.investmentTableData = [];
    this.allValuationDates = this.portfolioService.getCompanyAllValuationDates( this.portfolioService.companyId );

    this.allValuationDates = this.allValuationDates.filter( form => {
      return form.details ? JSON.parse(form.details).status != "Initiated" : false;
    });

    this.selectedDate = this.allValuationDates[0];

    this.selectedFund = this.fundService.getFundById(this.portfolioService.selectedFundId);
    this.loadingCaptable = true;

    try {
      const apiData = await this.dataService.getWidgetDataFromDB("STAKE_VALUE_ADJUSTMENTS", this.selectedDate.id).toPromise();
      this.investorWiseStakeValueAdjustments = apiData.body["response"][0].widgetData ? apiData.body["response"][0].widgetData : {};
    } catch (error) {
      console.error("Failed to fetch the STAKE_VALUE_ADJUSTMENTS widget", error);
    }
    
    let tableData;
    try {
      const captableAPIData = await this.dataService.getConcludedWaterfallVersion(this.selectedDate.id).toPromise();
      tableData = captableAPIData.body['response'].table as any
      this.capTableData = tableData;
      this.options = tableData.map( (investorNames, index) => {
        if(index != 0 && index != tableData.length - 1 && investorNames[0]){
          return { name: investorNames[0] };
        }
      }).filter( v => v);

      this.loadingCaptable = false;
      this.capTableExists = true;

    } catch (error) {
      console.log("Failed to fetch CapTable Data from DB", error);
      this.loadingCaptable = false;
      this.capTableExists = false;
    }      

    if(this.portfolioService.selectedListOfInvestors[this.selectedDate.id] && this.portfolioService.selectedListOfInvestors[this.selectedDate.id].length > 0){
      this.portfolioService.selectedListOfInvestors[this.selectedDate.id].forEach( data => {
        const nameAlreadyExists = this.investors.find(option => {
          return data.investorName == option.name;
        })

        if(!nameAlreadyExists){
          this.investors.push({name: data.investorName});
        }
        
        let customParameters = {};
        customParameters["bsAssignment"] = data["bsAssignment"] ? data["bsAssignment"] : "";

        this.prepareJSONForTable(this.selectedDate, data.investorName, data.noOfShares, customParameters, this.capTableData);
      })

      this.loadingCaptable = false;
    }    
    else{
      this.prepareJSONForTable(this.selectedDate, this.selectedFund.name, 0, {});
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.options.slice()));
  }

  displayFn(user: User): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().indexOf(filterValue) >= 0);
  }

  addOption(option){
    this.loadingInvestorName = true;

    const nameAlreadyExists = this.investors.find(data => {
      return data.name == option.name
    })

    if(!nameAlreadyExists){
      this.investors.push(option);
      this.selectedInvestorName = "";
      //adding investors
      this.prepareJSONForTable(this.selectedDate, option.name, 0, {}, this.capTableData);
    }
  }

  removeOption(index){
    const removedInvestor = this.investors.splice(index, 1)
    const investorToBeRemoved =  this.portfolioService.investmentTableData.findIndex( data => data.name === removedInvestor[0].name);
    this.portfolioService.investmentTableData.splice(investorToBeRemoved, 1);

    if(this.portfolioService.selectedListOfInvestors[this.selectedDate.id] && this.portfolioService.selectedListOfInvestors[this.selectedDate.id].length > 0){
      const investorToBeDeleted = this.portfolioService.selectedListOfInvestors[this.selectedDate.id].findIndex( data => data.investorName == removedInvestor[0].name)
      this.portfolioService.selectedListOfInvestors[this.selectedDate.id].splice(investorToBeDeleted, 1);
    }

  }

  async valuationDateChange(date){
    //changing valdate
    this.loadingCaptable = true;
    this.capTableExists = true;
    this.selectedBSAssignment = "";
    this.bsAssignmentName = "";
    this.selectedDate = cloneDeep(date);
    this.options = [];
    this.investors = [];
    this.capTableData = null;
    this.portfolioService.investmentTableData = [];
    this.investorWiseStakeValueAdjustments = {};

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice()));

    try {
      const apiData = await this.dataService.getWidgetDataFromDB("STAKE_VALUE_ADJUSTMENTS", this.selectedDate.id).toPromise();
      this.investorWiseStakeValueAdjustments = apiData.body["response"][0].widgetData ? apiData.body["response"][0].widgetData : {};
    } catch (error) {
      console.error("Failed to fetch the STAKE_VALUE_ADJUSTMENTS widget", error);
    }  

    let tableData;
    try {
      const captableAPIData = await this.dataService.getConcludedWaterfallVersion(this.selectedDate.id).toPromise();
      tableData = captableAPIData.body['response'].table as any
      this.capTableData = tableData;
      this.options = tableData.map( (investorNames, index) => {
        if(index != 0 && index != tableData.length - 1 && investorNames[0]){
          return { name: investorNames[0] };
        }
      }).filter( v => v);

      this.loadingCaptable = false;
      this.capTableExists = true;

    } catch (error) {
      console.log("Failed to fetch CapTable Data from DB", error);
      this.loadingCaptable = false;
      this.capTableExists = false;
    }      

    if(this.portfolioService.selectedListOfInvestors[this.selectedDate.id] && this.portfolioService.selectedListOfInvestors[this.selectedDate.id].length > 0){
      this.portfolioService.selectedListOfInvestors[this.selectedDate.id].forEach( data => {

        const nameAlreadyExists = this.investors.find(option => {
          return data.investorName == option.name;
        })

        if(!nameAlreadyExists){
          this.investors.push({name: data.investorName});
        }

        let customParameters = {};
        customParameters["bsAssignment"] = data["bsAssignment"] ? data["bsAssignment"] : "" 

        this.prepareJSONForTable(this.selectedDate, data.investorName, data.noOfShares, customParameters, this.capTableData);
      })

      this.loadingCaptable = false;
    }    
    else{
      this.prepareJSONForTable(this.selectedDate, this.selectedFund.name, 0, {});
    }
  }

  getTotalNoOfSharesbyCaptableData(capTableData, investorName){
    let totalNoOfShares = 0;

    const columnHeaders = capTableData[0];
    const indexOfTotalSharesColumn = columnHeaders.findIndex( name => name == "Total Shares");
    if(indexOfTotalSharesColumn >= 0){
      const investorExists = capTableData.find(investors => investors[0] == investorName);
      totalNoOfShares = investorExists[indexOfTotalSharesColumn] ? investorExists[indexOfTotalSharesColumn] : 0
    }

    return totalNoOfShares;
  }

  prepareJSONForTable(selectedDate, investorName, noOfShares, customParameters, capTableData?, stakeToBeUsed?){
    let stake = 0;
    let stakeValue = 0;
    const isItSelectedFund = investorName == this.selectedFund.name

    let adjustedStakeValue = 0;

    //if it si the selecetd fund
    if(isItSelectedFund){
      stake = selectedDate.investment ? selectedDate.investment.equityValue.stake : 0;
      if(selectedDate.investment){
        stakeValue = selectedDate.investment.equityValue.stakeValue ? selectedDate.investment.equityValue.stakeValue : 0;
        adjustedStakeValue = selectedDate.investment.equityValue.finalStakeValue ? selectedDate.investment.equityValue.finalStakeValue : selectedDate.investment.equityValue.stakeValue ? selectedDate.investment.equityValue.stakeValue : 0;
      }

      noOfShares = selectedDate.investment.numberOfShares ? selectedDate.investment.numberOfShares : 0;
    }
    else if(capTableData){
      // if it is the investor from captable data
      this.loadingInvestorName = true;
      const finalAdjustedEquityVal = selectedDate.investment ? selectedDate.investment.equityValue.finalAdjustedEquityVal : 0

      if(isNaN(stakeToBeUsed)){
        const investorDetails = capTableData.find( data => data[0] === investorName);
        if(investorDetails){
          // Assuming absolute value of stake is coming from excel
          stake = investorDetails[investorDetails.length - 1]; //* 100;
          stakeValue = finalAdjustedEquityVal * (stake/100);
        }
      }
      else if(!isNaN(stakeToBeUsed)){
        // stake = stakeToBeUsed
        stake = stakeToBeUsed > 100 ? (+stakeToBeUsed/100) : stakeToBeUsed;
        stakeValue = finalAdjustedEquityVal * (stake/100);
      }

      noOfShares = this.getTotalNoOfSharesbyCaptableData(capTableData, investorName);

      adjustedStakeValue = stakeValue;

      if(this.investorWiseStakeValueAdjustments[investorName] 
        && this.investorWiseStakeValueAdjustments[investorName].adjustments
        && this.investorWiseStakeValueAdjustments[investorName].adjustments.length > 0){
          adjustedStakeValue = this.calculateAdjustments(stakeValue, this.investorWiseStakeValueAdjustments[investorName].adjustments);
      }
    }

    

    const investmentDate = this.allValuationDates.find( comp => comp.groupFormId == null)
    const portfolioComp = this.portfolioService.portfolioData.find( comp => comp.id == investmentDate.id);
    
    const realisedProceeds = this.getRealisedProceeds(selectedDate, investorName);
    const investmentAmount = this.getInvestmentFromTransactions(selectedDate, investorName);

    const response = this.portfolioService.prepareMultiplesForIRRAndMoic(portfolioComp, selectedDate, false, adjustedStakeValue, investorName, selectedDate.valuationDate)
    // const body = this.portfolioService.prepareMultiplesForIRR(investmentDate, portfolioComp, selectedDate, stakeValue);
    const reqBodyForIRR = response.reqBodyIRRForLocalCurrency; //Values are in localCurrency/ valuation currency

    let irr = 0

    // console.log("--------------------- IRR req body", investorName, selectedDate.valuationDate, reqBodyForIRR);

    this.dataService.getIRR(reqBodyForIRR).subscribe( res => {
      const apiData = res.body["response"].data.complete_processed_data;
      irr = apiData ? apiData : 0;
      const indexOfInvestorExists = this.portfolioService.investmentTableData.findIndex( data => {
        return data.name == investorName;
      })

      console.log("investment data", this.portfolioService.investmentTableData);
      // If Investor already this.capTableExists, re-initialize it
      if(indexOfInvestorExists >= 0){
        this.portfolioService.investmentTableData[indexOfInvestorExists] = {
          name: investorName,
          stake: stake,
          stakeValue: stakeValue,
          adjustedStakeValue: adjustedStakeValue,          
          realisedProceeds: realisedProceeds,
          investmentAmount: investmentAmount,
          irr: irr,
          moic: response.moicBeforeConversion, //Values are in localCurrency/ valuation currency
          noOfShares: noOfShares,
          pricePerShare: noOfShares ? (stakeValue * 1000000 / noOfShares) : 0,
          bsAssignment: customParameters["bsAssignment"] ? customParameters["bsAssignment"] : ""
        }
      }
      // if investor does not exists, push it in to an array
      else{
        this.portfolioService.investmentTableData.push({
          name: investorName,
          stake: stake,
          stakeValue: stakeValue,
          adjustedStakeValue: adjustedStakeValue,          
          realisedProceeds: realisedProceeds,
          investmentAmount: investmentAmount,
          irr: irr,
          moic: response.moicBeforeConversion, //Values are in localCurrency/ valuation currency
          noOfShares: noOfShares,
          pricePerShare: noOfShares ? (stakeValue * 1000000 / noOfShares) : 0,
          bsAssignment: customParameters["bsAssignment"] ? customParameters["bsAssignment"] : ""
        })
      }

      

      if(!this.portfolioService.selectedListOfInvestors[this.selectedDate.id]){
        this.portfolioService.selectedListOfInvestors[this.selectedDate.id] = []
      }

      const investorNameExists = this.portfolioService.selectedListOfInvestors[this.selectedDate.id].find( data => {
        return data.investorName == investorName;
      })

      if(!investorNameExists){
        this.portfolioService.selectedListOfInvestors[this.selectedDate.id].push({
          investorName : investorName,
          stake: stake,
          noOfShares: 0
        })
      }

      this.loadingInvestorName = false;
    }, error => {
      console.log("Failed to calculate IRR", error);

      const indexOfInvestorExists = this.portfolioService.investmentTableData.findIndex( data => {
        return data.name == investorName;
      })

       // If Investor already this.capTableExists, re-initialize it
       if(indexOfInvestorExists >= 0){
        this.portfolioService.investmentTableData[indexOfInvestorExists] = {
          name: investorName,
          stake: stake,
          stakeValue: stakeValue,
          adjustedStakeValue: adjustedStakeValue,          
          realisedProceeds: realisedProceeds,
          investmentAmount: investmentAmount,
          irr: irr,
          moic: response.moicBeforeConversion, //Values are in localCurrency/ valuation currency
          noOfShares: noOfShares,
          pricePerShare: noOfShares ? (stakeValue * 1000000 / noOfShares) : 0,
          bsAssignment: customParameters["bsAssignment"] ? customParameters["bsAssignment"] : ""
        }
      }
      // if investor does not exists, push it in to an array
      else{
        this.portfolioService.investmentTableData.push({
          name: investorName,
          stake: stake,
          stakeValue: stakeValue,
          adjustedStakeValue: adjustedStakeValue,          
          realisedProceeds: realisedProceeds,
          investmentAmount: investmentAmount,
          irr: irr,
          moic: response.moicBeforeConversion, //Values are in localCurrency/ valuation currency
          noOfShares: noOfShares,
          pricePerShare: noOfShares ? (stakeValue * 1000000 / noOfShares) : 0,
          bsAssignment: customParameters["bsAssignment"] ? customParameters["bsAssignment"] : ""
        })
      }
      
      this.loadingInvestorName = false;
    })
  }

  getRealisedProceeds(selectedDateForm, investorName) {
    // const valDate = new Date(selectedDateForm.valuationDate);

    let totalRealisedProceeds = 0;

    const validTransactions = this.portfolioService.getValidTransactionsForSelectedFund(this.portfolioService.companyId, investorName, selectedDateForm.valuationDate);

    const multipleRealisedProceeds = validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0 
    ? cloneDeep(validTransactions["multipleRealisedProceeds"]) : [] 

    multipleRealisedProceeds.forEach( rp => {
      // const transDate = new Date(rp.Date); //filtering WRT to ValDate
      // if(transDate <= valDate){
        totalRealisedProceeds += this.utilService.getValidNumber(rp.convertedValue); //Values are in localCurrency/ valuation currency
      // }
    })

    return totalRealisedProceeds;
  }

  getInvestmentFromTransactions(selectedDateForm, investorName) {
    // const valDate = new Date(selectedDateForm.valuationDate);

    const validTransactions = this.portfolioService.getValidTransactionsForSelectedFund(this.portfolioService.companyId, investorName, selectedDateForm.valuationDate);

    const multipleInvestmentAmount = validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0 
    ? cloneDeep(validTransactions["multipleInvestmentAmount"]) : [] 

    // const applicableTransactions = multipleInvestmentAmount.filter(tr => {
    //   let trDate = new Date(tr.Date);
    //   let valdate = new Date(valDate);
    //   return trDate <= valdate;
    // });
    
    // const applicableTransactions = multipleInvestmentAmount;

    let investmentAmount = 0;

    if(multipleInvestmentAmount && multipleInvestmentAmount.length > 0) {

      multipleInvestmentAmount.forEach((comp, index) => {
        investmentAmount += this.utilService.getValidNumber(comp.convertedValue);  //Values are in localCurrency/ valuation currency
      })
    }

    return investmentAmount;
  }

  saveNoOfShares(data) {
    const investorInSavedWidget = this.portfolioService.selectedListOfInvestors[this.selectedDate.id];
   
    investorInSavedWidget.forEach(inv => {
      if(inv.investorName == data.name) {
        inv.noOfShares = data.noOfShares
      }
    });

    this.saveInvestmentWidgetData(true);
  }

  saveInvestments(showMessage?){
    const allValuationDates = Object.keys(this.portfolioService.selectedListOfInvestors);

    allValuationDates.forEach(vdId => {

      try {
        // formId = vdId
        //Keep only unique investors.
        const selectedListOfInvestors = this.portfolioService.selectedListOfInvestors[vdId];

        const company = this.portfolioService.companies.find(comp => comp.id == vdId);

        const defaultInvName = this.fundService.getFundById(this.portfolioService.selectedFundId).name;
        // const defaultInvExists = this.portfolioService.investmentTableData.find( data => {
        //   return data.name == defaultInvName;
        // })
        const uniqueInvestors = [{
          investorName : defaultInvName,
          stake: company.investment ? company.investment.equityValue.stake : 0,
          noOfShares: 0,
          bsAssignment: ""
        }];

        if(selectedListOfInvestors) {

          selectedListOfInvestors.forEach(investor => {
            const investorIndexExists = uniqueInvestors.findIndex(i => i.investorName === investor.investorName);

            if(investorIndexExists == -1) {
              uniqueInvestors.push(investor);
            }
            else{
              uniqueInvestors[investorIndexExists] = investor
            }
            
            if(this.selectedDate && this.selectedDate.id == vdId){
              const investorInSavedWidget = this.portfolioService.investmentTableData.find( data => {
                return data.name == investor.investorName;
              })
    
              if(investorInSavedWidget) {
                investor["noOfShares"] = investorInSavedWidget["noOfShares"];
                investor["bsAssignment"] = investorInSavedWidget["bsAssignment"];
              }
            }
          });

          this.portfolioService.selectedListOfInvestors[vdId] = uniqueInvestors;
        }   
      } catch (e) {
        console.log("Warning: Not able to valuation date to investors", e)
      }   
    })

    this.saveInvestmentWidgetData(showMessage);
  }

  saveInvestmentWidgetData(showMessage?) {
    this.dataService.saveWidgetDataToDB("INVESTMENTS", this.portfolioService.selectedListOfInvestors, this.portfolioService.companyId).subscribe( success => {
      // console.log('Succesfuly Saved The investor names',success);
      if(showMessage) {
        this.utilService.showMessage(this.translateService.getLabel("succ_investment_info"), this.translateService.getLabel("ok"));
      }
    }, error => {
      console.log('Failed to Save The investor names', error);
      this.utilService.showMessage(this.translateService.getLabel("err_investment_info"), this.translateService.getLabel("ok"), true);
    })
  }

  enableSelector(investorName, selectedBSAssignment){
    if(this.bsAssignmentName != investorName) {
      this.bsAssignmentName = investorName
      this.selectedBSAssignment = selectedBSAssignment

    } else {
      // If dropdown is already shown for the same investor, hide the dropdown.
      this.bsAssignmentName = null;
      this.selectedBSAssignment = null;
    }
  }

  addSelectedBSAssignment(selectedBSAssignment){
    this.selectedBSAssignment = selectedBSAssignment;
    this.portfolioService.investmentTableData.forEach(data => {
      if(data.name == this.bsAssignmentName){
        data.bsAssignment = selectedBSAssignment;
        // console.log("data bsAssignment", data.bsAssignment);
      }
    })
  }

  openAdjustmentsPopUp(content, rowData){
    this.investorName = rowData.name;
    this.stakeValueAdjustments.totalValueBeforeAdjustment = rowData.stakeValue;
    this.stakeValueAdjustments.adjustments = this.investorWiseStakeValueAdjustments[rowData.name] 
    && this.investorWiseStakeValueAdjustments[rowData.name].adjustments ? this.investorWiseStakeValueAdjustments[rowData.name].adjustments : [];
    this.stakeValueAdjustments.headerLabel = "Stake Value (" + rowData.name + ")"; 

    this.modalService.open(content, { centered: true, windowClass: "stake-value-adjustments"});
  }

  emittedAdjustedStakeValue(adjustment){
    this.utilService.showLoadingPopup();
    const investorName = this.investorName;
    if(!this.investorWiseStakeValueAdjustments[investorName]){
      this.investorWiseStakeValueAdjustments[investorName] = {
        adjustments: []
      }
    }

    this.investorWiseStakeValueAdjustments[investorName].adjustments = adjustment.adjustments;

    this.dataService.saveWidgetDataToDB("STAKE_VALUE_ADJUSTMENTS", this.investorWiseStakeValueAdjustments, this.selectedDate.id).subscribe(res => {
      console.log("Successfully saved adjustments", res);
      this.utilService.showMessage(this.translateService.getLabel("suc_adjustment_success"), this.translateService.getLabel("ok"))

      const investorInSavedWidget = this.portfolioService.selectedListOfInvestors[this.selectedDate.id];
   
      let noOfShares = 0;
      let customParameters = {};

      investorInSavedWidget.forEach(inv => {
        if(inv.investorName == investorName) {
          noOfShares = inv.noOfShares
          customParameters["bsAssignment"] = inv["bsAssignment"] ? inv["bsAssignment"] : "";
        }
      });


      this.prepareJSONForTable(this.selectedDate, investorName, noOfShares, customParameters, this.capTableData);
    }, error => {
      console.log("Failed to save adjustments", error);
      this.utilService.showMessage(this.translateService.getLabel("err_save_adjustment"), this.translateService.getLabel("ok"), true)
    })

    this.stakeValueAdjustments = {
      totalValueBeforeAdjustment: 0,
      totalValueAfterAdjustment: 0,
      adjustments: [],
      headerLabel: ""
    }

    this.investorName = "";
  }

  calculateAdjustments(totalValueBeforeAdjustment, adjustments){
    let totalValueAfterAdjustment = totalValueBeforeAdjustment ? totalValueBeforeAdjustment : 0;
    if(adjustments && adjustments.length > 0){
      adjustments.forEach( adj => {
        if(adj.type == "Add") {
          totalValueAfterAdjustment += +adj.value;
        }
        else {
          totalValueAfterAdjustment -= +adj.value;
        }
      })

    }
    return totalValueAfterAdjustment;
  }

  closeInvestments(){
    this.router.navigateByUrl('/company-valuation/' + this.portfolioService.companyId + "?parentId=" + this.portfolioService.selectedFundId);
  }
}
