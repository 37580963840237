<form class="pb-3 pt-0" style="min-width: 430px;">
    <div class="">
        <div class="header row pl-4 my-1">
            <div class="col-4 font-16">{{ translateService.getLabel("notifications") }}</div>
            
            <div class="col-7 font-12 font-w-100 ml-3 d-flex justify-content-end color-violet">
                <a class="text-underline" (click)="filterunread()">{{dataFilterTxt}}</a>
                <span class="ml-1 mr-1">|</span>
                <a class="text-underline" (click)="markAllAsRead()">{{ translateService.getLabel("mark_all_as_read") }}</a>
            </div>
        </div>

        <div class="body line-height-20 overflowY-auto">
            <div *ngIf="notificationService.notificationData.length > 0">
                <div *ngFor="let row of notificationService.notificationData">

                    <div class="row d-flex minheight-90 pl-4 pt-2"
                        [ngClass]="row.readStatus == false? 'color-dark' : 'color-light' "
                        (click)="readEvent($event,row.id)">

                        <div class="col-8 d-flex flex-column">
                            <span class="font-14 ">{{eventStatusOnText(row.eventStatus, row.eventType)}}</span>
                            <span class="font-14 font-w-100">{{row.labelString}}</span>
                            <div class="d-flex flex-row">
                                <div class="flex">
                                    <a class="font-12 font-weight-600 color-violet" (click)="actionClicked(row)"
                                    *ngIf="!row.isConsolForm"
                                    [style.visibility]="row.eventStatus == 'ERROR' || row.eventStatus == 'COMPLETED' ? 'visible' : 'hidden'">
                                    {{row.eventStatus == 'COMPLETED' ? translateService.getLabel("view") :
                                    row.eventStatus == 'ERROR' ? translateService.getLabel("retry") : translateService.getLabel("view")}}</a>
                                    </div>&nbsp;&nbsp;
                                <div class="flex" *ngIf="row.eventStatus == 'ERROR'">
                                    <a class="font-12 font-weight-600 color-violet" (click)="editClicked(row)" *ngIf="!row.isConsolForm && row.eventStatus == 'ERROR' && row.eventType == 'FORM_SUBMISSION'"
                                    [style.visibility]="row.eventStatus == 'ERROR'? 'visible' : 'hidden'">
                                    {{translateService.getLabel("edit")}}</a>

                                    <!-- For Credit Edit Button -->
                                    <a class="font-12 font-weight-600 color-violet ml-3" (click)="actionClicked(row, true)"
                                    *ngIf="row.debtModelId" [style.visibility]="'visible'">
                                    {{translateService.getLabel("edit")}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex word-wrap line-height-15">
                            <span class="font-12 font-w-100 mt-1">{{row.createdDate | dateFormat}}</span>
                        </div>
                    </div>

                    <hr class="m-0" />
                </div>
            </div>

            <div *ngIf="notificationService.notificationData.length == 0">
                <hr>
                <div class="d-flex align-items-center justify-content-center minheight-90 px-4"
                    style="width: 400px;">
                    {{ translateService.getLabel("no_new_notifiction") }}
                </div>
            </div>
        </div>
    </div>
</form>