import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '../services/translation.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Output()
  navToggle: EventEmitter<any> = new EventEmitter();

  constructor(
    public navigationService: NavigationService,
    public translateService: TranslateService) { }

  ngOnInit(): void {
  }


  toggleMenuArrow(index) {

    for (let i = 0; i < this.navigationService.menuList.length; i++) {
      if (i === index) {
        this.navigationService.menuList[index].showmenuarrow = !this.navigationService.menuList[index].showmenuarrow;
      } else {
        this.navigationService.menuList[i].showmenuarrow = false;
      }
    }
  }

  openSidenavLinks(menu) {
    if(menu.show == 'DISABLED') {
      return;
    }
    
    this.navToggle.next();
    this.navigationService.openSidenavLinks(menu)
  }
}
