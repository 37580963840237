
import { Injectable } from '@angular/core';

import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import floor from 'lodash/floor';
import _max from 'lodash/max';
import _min from 'lodash/min';

import { DataService } from 'src/app/services/data.service';
import { Subject, merge } from 'rxjs';
import { IAService } from './income-approach.service';
import { UtilService } from 'src/app/utils/util.service';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CTMService {

  show = true;
  analysisAndScenarios = false;
  dirtyData = false;
  saveDataEvent;
  dbDataFetched = false;
  transactionsFetchingFromDB = true;

  balanceSheetAdjustment;

  capitalAdvantagePeriod = 1;
  selectedYear = 0;
  companyMetricsFromSAF;
  iaData = [];

  mNaSimilarCompanies = [];
  peSimilarCompanies = [];

  mergersAndAcquisitions = {
    dealMultiples: [],
    dealMultiplesCalculation: {
      average : {
        calculation: 'Average', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      median: {
        calculation: 'Median', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      max: {
        calculation: 'Max', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      min : {
        calculation: 'Min', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      q1 : {
        calculation: '1st Quartile', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      q3 : {
        calculation: '3rd Quartile', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      custom : {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
  };

  fundingSituations = {
    dealMultiples: [],
    dealMultiplesCalculation: {
      average : {
        calculation: 'Average', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      median: {
        calculation: 'Median', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      max: {
        calculation: 'Max', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      min : {
        calculation: 'Min', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      q1 : {
        calculation: '1st Quartile', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      q3 : {
        calculation: '3rd Quartile', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: '0', evEbitda: '0'
      },
      custom : {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
  };

  analyzedData = {
    concludedSet: {
      evRevenue: { average: 0, min: 0, max: 0, median: 0 },
      evEbitda: { average: 0, min: 0, max: 0, median: 0 },
      valuation: {} as any
    },
    weights: [100, 0],
    mNafactor: 20
  };

  currencyExchangeRate = 1;

  range = { // adjustment factor(af)
    af: {o1: {checked: false, value: 0, analysis: []}, o2: {checked: true, value: 2, analysis: []}, o3: {checked: true, value: 3, analysis: []}, o4: {checked: false, value: 0, analysis: [], mergersAndAcquisitions: [], fundingSituations: []}, analysis:[0, 0], o5: {checked: false, value: 1, analysis: []}},
    mam: {o1: {checked: false, value: "min", analysis: []}, o2: {checked: true, value: "median", analysis: []}, o3: {checked: true, value: "average", analysis: []}, o4: {checked: false, value: "max", analysis: []}, o5: {checked: true, value: "q1", analysis: []}, o6: {checked: true, value: "q3", analysis: []}, analysis:[0, 0]},
    region: {o1: {checked: true, value: "global", analysis: []}, o2: {checked: true, value: "local", analysis: []}, o3: {checked: false, value: null, analysis: []}, analysis:[0, 0]},
    concludedVariation: {o1: {checked: true, value: 10, analysis: []}, o2: {checked: false, value: 20, analysis: []}, analysis: [0, 0]}
  }

  adjustedMultiple = { 
    aggregations: {
      selected: "median"
    }
  }

  ctmRange = [0, 0];

  transactionFilters = { 
    amount: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    revenueMultiple: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    ebitdaMultiple: { actual: {min: 0, max: 10}, selected: {min: 0, max: 10}},
    region: "Global" // Selected Region Value
  };

  allRegions = [];

  apiTransactions = {
    MnAs: [],
    fundings: []
  }

  localTransactionsExists = false;

  formData;
  result;
  resultClone;
  resultAvailable = new Subject();
  loadingMessage = "Analyzing Vectors";

  loadingProgress = 25;

  constructor(private ds: DataService, private iaService: IAService, 
    private utilService: UtilService,
    private datePipe: DatePipe)
  {}

  initSavedValuation(apiData) {
    this.result = apiData;
    this.initInstance();
    
    this.resultClone = cloneDeep(this.result);

    this.dbDataFetched = true;
    this.transactionsFetchingFromDB = false;

    this.initIncomeApproachData();
  }

  analyzeUpdate(ctmInput, iaData?){

    const input = ctmInput;

    this.apiTransactions = input.apiTransactions;

    this.fundingSituations = input.fundingSituations;

    this.mergersAndAcquisitions = input.mergersAndAcquisitions;

    this.range = input.range;

    if(iaData===undefined){
      return this.updateAnalysis();
    }
    else{
      return this.initIncomeApproachMultiples(iaData)
    }
  }

  addLogoURL(similarCompanies) {
    similarCompanies.forEach(company=>{
      if(company.targetWebsite && company.targetWebsite.indexOf("Invalid") >= 0) {
        company.targetWebsite = null;
      }
      if(company.targetWebsite && company.targetWebsite.length > 3) {
        company.targetLogo = "https://logo.allindigital.se/" + this.utilService.getDomainName(company.targetWebsite);
      }

      if(company.buyerWebsite && company.buyerWebsite.indexOf("Invalid") >= 0) {
        company.buyerWebsite = null;
      }
      if(company.buyerWebsite && company.buyerWebsite.length > 3) {
        company.buyerLogo = "https://logo.allindigital.se/" + this.utilService.getDomainName(company.buyerWebsite);
      }
    })
  }

  sortCompanies(similarCompanies) {    
    const subjectCompRegion = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME;
    
    const subjectCompanyCountry = [
      subjectCompRegion.name ? subjectCompRegion.name.toUpperCase(): "--", 
      subjectCompRegion.alias1 ? subjectCompRegion.alias1.toUpperCase(): "--", 
      subjectCompRegion.linkedinAlias ? subjectCompRegion.linkedinAlias.toUpperCase(): "--"
    ]

    //Sort based on maximum keyword matches
    similarCompanies = similarCompanies.sort((c1, c2)=>{return (+c2.keywordMatches) - (+c1.keywordMatches)});

    

    return similarCompanies;
  }

  initInstance(initOnlyRange?) {
    if(!initOnlyRange) {
      this.mergersAndAcquisitions = this.result.mergersAndAcquisitions;
      this.fundingSituations = this.result.fundingSituations;
      this.analyzedData = this.result.analyzedData;
      this.balanceSheetAdjustment = this.result.balanceSheetAdjustment;
      this.peSimilarCompanies = this.result.peSimilarCompanies;
      this.mNaSimilarCompanies = this.result.mNaSimilarCompanies
      this.mNaFactorInput = this.analyzedData.mNafactor;
      this.mNafactor = this.analyzedData.mNafactor;
      this.companyMetrics = this.result.companyMetrics;

      if(this.result.adjustedMultiple) {
        this.adjustedMultiple = this.result.adjustedMultiple;
      }

      if(!this.result.apiTransactions || this.result.apiTransactions.MnAs.length == 0 ||this.result.apiTransactions.fundings.length == 0) {
        this.apiTransactions = {
          MnAs: this.mergersAndAcquisitions.dealMultiples,
          fundings: this.fundingSituations.dealMultiples
        }
      }
      else{
        this.apiTransactions = this.result.apiTransactions
      }

      this.initSimilarCompanyFilters();
    }

    this.range = this.result.range ? this.result.range: this.range;

    if(!this.range.af["o5"]) {
      this.range.af["o5"] = {checked: false, value: 0, analysis: []};
    }
    
    if(!this.range.af.o4.fundingSituations || this.range.af.o4.fundingSituations.length == 0) {
      this.range.af.o4.fundingSituations = cloneDeep(this.fundingSituations.dealMultiples);
    }
    if(!this.range.af.o4.mergersAndAcquisitions || this.range.af.o4.mergersAndAcquisitions.length == 0) {
      this.range.af.o4.mergersAndAcquisitions = cloneDeep(this.mergersAndAcquisitions.dealMultiples);
    }
  }

  initSimilarCompanyFilters() {
    this.initTransactionFilters();
    this.initRegions();
  }

  initTransactionFilters() {

    //for MnA
    let mNatransactions = []
    let mNafilters = cloneDeep(this.transactionFilters);
    mNatransactions = this.mergersAndAcquisitions.dealMultiples;
    if(!mNatransactions || mNatransactions.length == 0) {
      return;
    }

    //Transaction Amount
    let amount = mNatransactions.map(comp=> comp.valuation && +comp.valuation > 0? +comp.valuation: 0);
    mNafilters.amount.actual.min = _min(amount);
    mNafilters.amount.actual.max = _max(amount);

    mNafilters.amount.selected = cloneDeep(mNafilters.amount.actual);

    //ebitdaMultiple    
    let ebitdaMultiple = mNatransactions.map(comp=> comp.evEbitda && +comp.evEbitda > 0? +comp.evEbitda: 0);
    mNafilters.ebitdaMultiple.actual.min = _min(ebitdaMultiple);
    mNafilters.ebitdaMultiple.actual.max = _max(ebitdaMultiple);

    mNafilters.ebitdaMultiple.selected = cloneDeep(mNafilters.ebitdaMultiple.actual);

    //revenueMultiple    
    let revenueMultiple = mNatransactions.map(comp=> comp.evRevenue && +comp.evRevenue > 0? +comp.evRevenue: 0);
    mNafilters.revenueMultiple.actual.min = _min(revenueMultiple);
    mNafilters.revenueMultiple.actual.max = _max(revenueMultiple);

    mNafilters.revenueMultiple.selected = cloneDeep(mNafilters.revenueMultiple.actual);

    // for Funding
    let fundingTransactions = []
    let fundingFilters = cloneDeep(this.transactionFilters);

    fundingTransactions = this.fundingSituations.dealMultiples;
    if(!fundingTransactions || fundingTransactions.length == 0) {
      return;
    }
   
    //Transaction Amount
    amount = fundingTransactions.map(comp=> comp.valuation && +comp.valuation > 0? +comp.valuation: 0);
    fundingFilters.amount.actual.min = _min(amount);
    fundingFilters.amount.actual.max = _max(amount);

    fundingFilters.amount.selected = cloneDeep(fundingFilters.amount.actual);

    //ebitdaMultiple    
    ebitdaMultiple = fundingTransactions.map(comp=> comp.evEbitda && +comp.evEbitda > 0? +comp.evEbitda: 0);
    fundingFilters.ebitdaMultiple.actual.min = _min(ebitdaMultiple);
    fundingFilters.ebitdaMultiple.actual.max = _max(ebitdaMultiple);

    fundingFilters.ebitdaMultiple.selected = cloneDeep(fundingFilters.ebitdaMultiple.actual);

    //revenueMultiple    
    revenueMultiple = fundingTransactions.map(comp=> comp.evRevenue && +comp.evRevenue > 0? +comp.evRevenue: 0);
    fundingFilters.revenueMultiple.actual.min = _min(revenueMultiple);
    fundingFilters.revenueMultiple.actual.max = _max(revenueMultiple);

    fundingFilters.revenueMultiple.selected = cloneDeep(fundingFilters.revenueMultiple.actual);

    this.transactionFilters.region = "Global";
    //Transaction Amount
    amount = [mNafilters.amount.actual.min, fundingFilters.amount.actual.min, mNafilters.amount.actual.max, fundingFilters.amount.actual.max];
    this.transactionFilters.amount.actual.min = _min(amount);
    this.transactionFilters.amount.actual.max = _max(amount);
    this.transactionFilters.amount.selected = cloneDeep(this.transactionFilters.amount.actual)

    //revenueMultiple   
    revenueMultiple = [mNafilters.revenueMultiple.actual.min, fundingFilters.revenueMultiple.actual.min, mNafilters.revenueMultiple.actual.max, fundingFilters.revenueMultiple.actual.max];
    this.transactionFilters.revenueMultiple.actual.min = _min(revenueMultiple);
    this.transactionFilters.revenueMultiple.actual.max = _max(revenueMultiple);
    this.transactionFilters.revenueMultiple.selected = cloneDeep(this.transactionFilters.revenueMultiple.actual);

    //ebitdaMultiple 
    ebitdaMultiple = [mNafilters.ebitdaMultiple.actual.min, fundingFilters.ebitdaMultiple.actual.min, mNafilters.ebitdaMultiple.actual.max, fundingFilters.ebitdaMultiple.actual.max];
    this.transactionFilters.ebitdaMultiple.actual.min = _min(ebitdaMultiple);
    this.transactionFilters.ebitdaMultiple.actual.max = _max(ebitdaMultiple);
    this.transactionFilters.ebitdaMultiple.selected = cloneDeep(this.transactionFilters.ebitdaMultiple.actual);
  }
  
  initRegions() {
    const regionsSet = new Set();
    this.apiTransactions.MnAs.forEach(comp=>{
      if(comp.country) {
        regionsSet.add(comp.country.toUpperCase());
      }
    });

    this.apiTransactions.fundings.forEach(comp=>{
      if(comp.country) {
        regionsSet.add(comp.country.toUpperCase());
      }
    });

    let regions = Array.from(regionsSet);
    regions = regions.sort();
    this.allRegions = regions;
  }

  filter() {
    this.mergersAndAcquisitions.dealMultiples = this.apiTransactions.MnAs.filter(comp=>{
      return this.isFilterMatch(comp);
    })

    this.fundingSituations.dealMultiples = this.apiTransactions.fundings.filter(comp=>{
      return this.isFilterMatch(comp);
    })

    this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
    if(!this.mergersAndAcquisitions.dealMultiplesCalculation.custom){
      this.mergersAndAcquisitions.dealMultiplesCalculation.custom = {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
    this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);
    if(!this.fundingSituations.dealMultiplesCalculation.custom){
      this.fundingSituations.dealMultiplesCalculation.custom = {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
    this.updateAnalysis();

    this.resultAvailable.next();
  }

  isFilterMatch(company) {
    let regionFilter = true;

    if(this.transactionFilters.region == "Local") {
      let country = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.name;
      country = country.toUpperCase();

      regionFilter = company.country && company.country.toUpperCase() === country;

    } else if(this.transactionFilters.region == "Global") {
      regionFilter = true;

    } else {
      //Country Selection By User
      if(this.transactionFilters.region && this.transactionFilters.region.length >= 3) {
        regionFilter = company.country && company.country.toUpperCase() === this.transactionFilters.region;
        
      } else {
        //Ignore if not country is selected
        regionFilter = true;
        this.transactionFilters.region = "Global";
      }
    }

    return regionFilter
      && +company.valuation >= this.transactionFilters.amount.selected.min
      && +company.valuation <= this.transactionFilters.amount.selected.max

      && +company.evEbitda >= this.transactionFilters.ebitdaMultiple.selected.min
      && +company.evEbitda <= this.transactionFilters.ebitdaMultiple.selected.max

      && +company.evRevenue >= this.transactionFilters.revenueMultiple.selected.min
      && +company.evRevenue <= this.transactionFilters.revenueMultiple.selected.max
  }

  initIncomeApproachMultiples(data?) {
    this.capitalAdvantagePeriod = (+this.iaService.generalInputs[4].selected);
    // this.iaData = this.iaService.result["response"].analysis.analysisData;

    this.iaData = data;

    this.companyMetricValues = this.iaData.map((row)=>{
      return row.year;
    })

    this.companyMetrics[0].value = this.companyMetricValues[0];
    return this.updateCompanyMetrics({ value: this.companyMetricValues[0] });
  }

  initIncomeApproachData() {    
    if(this.iaService.result) {
      this.initIncomeApproachMultiples();
    } else {
      
      this.iaService.resultAvaible.subscribe(()=>{
        this.initIncomeApproachMultiples();
      })
    }
  }
  
  initSimilarCompanies(similarCompanies, type) {
    similarCompanies.forEach(c=>{
      const multiple = {
        date: c.date, acquirer: c.fundName, target: c.target, targetWebsite: c.targetWebsite, buyerWebsite: c.buyerWebsite,
        targetLogo: c.targetLogo, buyerLogo: c.buyerLogo,
        country: c.country, bucketName: c.bucketName, acquirerDesc: this.utilService.cleanString(c.buyerDescription), targetDesc: this.utilService.cleanString(c.targetDescription),
        amount: 0, stake: c.stake, valuation: c.transValue,
        ltmRevenue: 0, ltmEbitda: 0, evRevenue: c.ev_revenue, evEbitda: c.ev_ebitda
      };
      if('mNa' == type) {
        this.mergersAndAcquisitions.dealMultiples.push(multiple);
      } else {
        this.fundingSituations.dealMultiples.push(multiple);
      }
    })

    if('mNa' === type) { 
      this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
      if(!this.mergersAndAcquisitions.dealMultiplesCalculation.custom){
        this.mergersAndAcquisitions.dealMultiplesCalculation.custom = {
          calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
          ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
        }
      }
      this.apiTransactions.MnAs = this.mergersAndAcquisitions.dealMultiples;
    } else {
      this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);
      if(!this.fundingSituations.dealMultiplesCalculation.custom){
        this.fundingSituations.dealMultiplesCalculation.custom = {
          calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
          ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
        }
      }
      this.apiTransactions.fundings = this.fundingSituations.dealMultiples;
    }
  }
  
  calculation(input) {
    const results = cloneDeep(input.dealMultiplesCalculation);
    this.calculateResults(input, 'stake', results);
    this.calculateResults(input, 'valuation', results);
    this.calculateResults(input, 'evRevenue', results);
    this.calculateResults(input, 'evEbitda', results);

    return results;
  }

  calculateResults(input, parameter, results) {
    if(!input.dealMultiples || input.dealMultiples.length == 0) {
      results.average[parameter] = 0;
      results.min[parameter] = 0;
      results.max[parameter] = 0;
      results.median[parameter] = 0;
      results.q1[parameter] = 0;
      results.q3[parameter] = 0;
      return;
    }

    let array = sortBy(input.dealMultiples, sortValue => {
      return +sortValue[parameter];
    });

    array = array.filter(v=> +v[parameter] > 0);

    if(array.length === 0) array = input.dealMultiples;

    // Average
    results.average[parameter] = (
      sumBy(array, sumValue => {
        return +sumValue[parameter];
      }) / array.length
    ).toFixed(2);

    // Max
    results.max[parameter] = array[array.length - 1][parameter];

    // Min
    results.min[parameter] = array[0][parameter];

    // Median
    const medianIndex = floor(array.length / 2);
    if (array.length % 2 === 0) {
      results.median[parameter] =
        (+array[medianIndex][parameter] + +array[medianIndex - 1][parameter]) /
        2;
    } else {
      results.median[parameter] = array[medianIndex][parameter];
    }
    
    const quartiles = this.utilService.getAggreagtions(array.map(e => e[parameter]));
    results.q1[parameter] = quartiles.q1;
    results.q3[parameter] = quartiles.q3;
  }

  updateIAFromBaseScanario(iaData) {
    this.iaData = iaData;

    const multiple = this.iaData.find(row=>row.year == this.companyMetrics[0].value);
    if(multiple) {
      this.companyMetrics[1].value = multiple.revenue; //Math.round(this.companyMetricsFromSAF.revenue[event.value] * 100) / 100 + '';
      this.companyMetrics[2].value = multiple.eBITDA; //Math.round(this.companyMetricsFromSAF.ebidta[event.value] * 100) / 100 + '';
    }
  }

  updateAnalysisAndSave() {
    this.updateAnalysis();
     
    this.result = {
      mergersAndAcquisitions: this.mergersAndAcquisitions,
      fundingSituations: this.fundingSituations,
      analyzedData: this.analyzedData,
      range: this.range,
      apiTransactions: this.apiTransactions,
      mNaSimilarCompanies: this.mNaSimilarCompanies,
      peSimilarCompanies: this.peSimilarCompanies,
      balanceSheetAdjustment: this.balanceSheetAdjustment,
      transactionFilters: this.transactionFilters,
      baseOfAnalysis: this.baseOfAnalysis,
      companyMetrics: this.companyMetrics,
      concludedEquityValue: this.concludedEquityValue,
      adjustedMultiple: this.adjustedMultiple          
    }
    this.findRangeAnalysis();

    this.saveWidget();
  }

  updateAnalysisTracker(currentAnalysis, oldSummaryValue, newSummaryValue){
    if(!this.resultClone) return;
    
    const changes = {
      mergerAndAcquisitions: this.changesInMergerAndAcquisitions(currentAnalysis),
      fundingSituations: this.changesInFundingSituations(currentAnalysis),
      baseOfAnalysis: this.changesInBaseOfAnalysis(currentAnalysis),
      mNafactor: this.changesInMnAFactor(currentAnalysis),
      companyMetrics: this.changeInCompanyMetrics(currentAnalysis),

      oldAnalysis: this.resultClone.concludedEquityValue,
      newAnalysis: currentAnalysis.concludedEquityValue,
      
      oldSummaryValue: oldSummaryValue,
      newSummaryValue: newSummaryValue
    };

    if(changes.mergerAndAcquisitions.length > 0
      || changes.fundingSituations.length > 0 
      || changes.baseOfAnalysis.length > 0
      || changes.mNafactor.length > 0
      || changes.companyMetrics.length > 0 ){

      const analysis = {newAnlysis: currentAnalysis, oldAnalysis: this.resultClone}
      
      this.ds.addAnalysisTracker("CTM", analysis, changes);
      this.resultClone = cloneDeep(currentAnalysis);
    }
  }

  changeInCompanyMetrics(currentAnalysis){
    const changes = [];
    this.resultClone.companyMetrics.forEach((metric, index)=> {
      if(metric.value !== currentAnalysis.companyMetrics[index].value){
        changes.push({
          key: metric.key,
          oldValue: metric.value,
          newValue: currentAnalysis.companyMetrics[index].value
        });
      }
    });

    return changes;
  }
  changesInMnAFactor(currentAnalysis){
    const changes = [];
    if(this.resultClone.analyzedData.mNafactor!==currentAnalysis.analyzedData.mNafactor){
      changes.push({
        key:'CTM_mNaFactor',
        oldValue: this.resultClone.analyzedData.mNafactor,
        newValue: currentAnalysis.analyzedData.mNafactor
      })
    }
    return changes;
  }
  changesInBaseOfAnalysis(currentAnalysis){
    const changes = [];
    if(this.resultClone.baseOfAnalysis!== currentAnalysis.baseOfAnalysis){
      changes.push({
        key:'CTM_CONCLUSION_MECHANISM', 
        oldValue: this.resultClone.baseOfAnalysis === 'multipleBased'? 'Multiple Based' : 'Enterprise Value Based',
        newValue: currentAnalysis.baseOfAnalysis === 'multipleBased'? 'Multiple Based' : 'Enterprise Value Based'
      });
    }
    return changes;
  }
  changesInMergerAndAcquisitions(currentAnalysis){
    const changes = [];
    this.resultClone.mergersAndAcquisitions.dealMultiples.forEach(oldAcquirer => {
      const newAcquirer = currentAnalysis.mergersAndAcquisitions.dealMultiples.find( merger => {
        return merger.acquirer === oldAcquirer.acquirer && merger.target === oldAcquirer.target
      })
      if(!newAcquirer){
        changes.push({
          key: 'CTM_ACQUIRERS', 
          oldValue:{acquirer: oldAcquirer.acquirer, merger: oldAcquirer.merger}, 
          newValue: null, 
          action: 'DELETE'
        })
      }
    });

    currentAnalysis.mergersAndAcquisitions.dealMultiples.forEach(newAcquirer => {
      const oldAcquirer = this.resultClone.mergersAndAcquisitions.dealMultiples.find(merger => {
        return merger.acquirer == newAcquirer.acquirer && merger.target == newAcquirer.target
      });
      if(!oldAcquirer){
        changes.push({
          key: 'CTM_ACQUIRERS', 
          oldValue: null, 
          newValue: {acquirer: newAcquirer.acquirer, merger: newAcquirer.merger}, 
          action: 'ADD'
        })
      }
    });
    return changes;
  }

  changesInFundingSituations(currentAnalysis){
    const changes = [];

    return changes;
  }

  updateAnalysis(aggregationType?) {
    if(!aggregationType) {
      aggregationType = this.adjustedMultiple.aggregations.selected
    }

    if (this.baseOfAnalysis === 'enterPriseValueBased') {
      if (this.mergersAndAcquisitions.dealMultiplesCalculation && this.fundingSituations.dealMultiplesCalculation) {
        this.analyzedData = {
          concludedSet: {
            evRevenue: {
              average: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.average.evRevenue * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.average.evRevenue * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              min: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.min.evRevenue * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.min.evRevenue * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              max: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.max.evRevenue * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.max.evRevenue * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              median: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.median.evRevenue * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.median.evRevenue * ((100 - this.mNafactor) / 100)
              ).toFixed(2)
            },
            evEbitda: {
              average: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.average.evEbitda * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.average.evEbitda * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              min: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.min.evEbitda * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.min.evEbitda * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              max: +(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.max.evEbitda * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.max.evEbitda * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              median: (+(
                +this.mergersAndAcquisitions.dealMultiplesCalculation.median.evEbitda * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.median.evEbitda * ((100 - this.mNafactor) / 100)
              ).toFixed(2))
            },
            valuation: {
              average: (
                +this.mergersAndAcquisitions.dealMultiplesCalculation.average.valuation * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.average.valuation * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              min: (
                +this.mergersAndAcquisitions.dealMultiplesCalculation.min.valuation * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.min.valuation * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              max: (
                +this.mergersAndAcquisitions.dealMultiplesCalculation.max.valuation * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.max.valuation * ((100 - this.mNafactor) / 100)
              ).toFixed(2),
              median: (
                +this.mergersAndAcquisitions.dealMultiplesCalculation.median.valuation * (this.mNafactor / 100) +
                +this.fundingSituations.dealMultiplesCalculation.median.valuation * ((100 - this.mNafactor) / 100)
              ).toFixed(2)
            }
          },
          weights: [
            +this.concludedValues.evRevenue[1],
            100 - +this.concludedValues.evRevenue[1]
          ],
          mNafactor: this.mNafactor
        };

        this.concludedValues.evRevenue[0] = +this.mergersAndAcquisitions.dealMultiplesCalculation[aggregationType].valuation;

        this.concludedValues.evEbitda[0] = +this.fundingSituations.dealMultiplesCalculation[aggregationType].valuation;

        const concludedRevenue = (
          (+this.concludedValues.evRevenue[0] *
            +this.concludedValues.evRevenue[1]) /
          100
        ).toFixed(2);
        const concludedEbitda = (
          (+this.concludedValues.evEbitda[0] *
            +this.concludedValues.evEbitda[1]) /
          100
        ).toFixed(2);

        this.concludedEnterpriceValue = +(
          +concludedEbitda + +concludedRevenue
        ).toFixed(2);
        this.concludedEquityValue = _max([
          (+this.concludedEnterpriceValue - this.balSheet.debt + this.balSheet.cash),
          0
        ])
      }
    } else {
      this.analyzedData = {
        concludedSet: {
          evRevenue: {
            average: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.average.evRevenue * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.average.evRevenue * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            min: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.min.evRevenue * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.min.evRevenue * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            max: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.max.evRevenue * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.max.evRevenue * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            median: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.median.evRevenue * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.median.evRevenue * ((100 - this.mNafactor) / 100)
            ).toFixed(2)
          },
          evEbitda: {
            average: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.average.evEbitda * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.average.evEbitda * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            min: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.min.evEbitda * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.min.evEbitda * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            max: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.max.evEbitda * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.max.evEbitda * ((100 - this.mNafactor) / 100)
            ).toFixed(2),
            median: +(
              +this.mergersAndAcquisitions.dealMultiplesCalculation.median.evEbitda * (this.mNafactor / 100) +
              +this.fundingSituations.dealMultiplesCalculation.median.evEbitda * ((100 - this.mNafactor) / 100)
            ).toFixed(2)
          }
        } as any,
        weights: [
          +this.concludedValues.evRevenue[1],
          100 - +this.concludedValues.evRevenue[1]
        ],
        mNafactor: this.mNafactor
      };
      
      this.concludedValues.evRevenue[0] = (
        +this.mergersAndAcquisitions.dealMultiplesCalculation[aggregationType].evRevenue * (this.mNafactor / 100) +
        +this.fundingSituations.dealMultiplesCalculation[aggregationType].evRevenue * ((100 - this.mNafactor) / 100)
      ).toFixed(2);
      this.concludedValues.evEbitda[0] = (
        +this.mergersAndAcquisitions.dealMultiplesCalculation[aggregationType].evEbitda * (this.mNafactor / 100) +
        +this.fundingSituations.dealMultiplesCalculation[aggregationType].evEbitda * ((100 - this.mNafactor) / 100)
      ).toFixed(2);

      const concludedRevenue = (
        (+this.companyMetrics[1].value *
          +this.concludedValues.evRevenue[0] *
          +this.concludedValues.evRevenue[1]) /
        100
      ).toFixed(2);
      const concludedEbitda = (
        (+this.companyMetrics[2].value *
          +this.concludedValues.evEbitda[0] *
          +this.concludedValues.evEbitda[1]) /
        100
      ).toFixed(2);

      this.concludedEnterpriceValue = +(
        +concludedEbitda + +concludedRevenue
      ).toFixed(2);

      this.concludedEquityValue = this.concludedEnterpriceValue;
      
      if(this.balanceSheetAdjustment && this.balanceSheetAdjustment.length > 0){
        this.balanceSheetAdjustment.forEach(balSheetAdjust => {
          if(balSheetAdjust.selected === true){
            if(balSheetAdjust.isAddition === true){
              this.concludedEquityValue = this.concludedEquityValue + Number(balSheetAdjust.updatedValue);
            }
            else{
              this.concludedEquityValue = this.concludedEquityValue - Number(balSheetAdjust.updatedValue);
            }
          }
        });
      } else {
        this.concludedEquityValue = _max([
          (+this.concludedEnterpriceValue - this.balSheet.debt + this.balSheet.cash),
          0
        ])
      }

      this.concludedEquityValue = this.concludedEquityValue * this.currencyExchangeRate;
    }
  }

  setBalanceSheet(newValue) {
    this.balSheet = newValue;
  }

  reset(){
    this.mergersAndAcquisitions.dealMultiplesCalculation.custom.evRevenue = 0;
    this.mergersAndAcquisitions.dealMultiplesCalculation.custom.evEbitda = 0
  }

  updateCompanyMetrics(event) {
    this.companyMetrics[1].key = 'Revenue for ' + event.value;
    this.companyMetrics[2].key = 'EBITDA for ' + event.value;

    const multiple = this.iaData.find(row=>row.year == event.value);
    if(multiple) {
      this.companyMetrics[1].value = multiple.revenue; //Math.round(this.companyMetricsFromSAF.revenue[event.value] * 100) / 100 + '';
      this.companyMetrics[2].value = multiple.eBITDA; //Math.round(this.companyMetricsFromSAF.ebidta[event.value] * 100) / 100 + '';
    } else {
      const historicalFin = this.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC.find(row => row.year === event.value);
      this.companyMetrics[1].value = historicalFin.totalNetRevenue;
      this.companyMetrics[2].value = historicalFin.eBITDA;
    }

    this.updateAnalysisAndSave();
  }
  
  mNafactor = 100;
  mNaFactorInput = 100;
  concludedEquityValue = 0;
  concludedEnterpriceValue = 0;
  balSheet = { debt: 0, cash: 0 };

  baseOfAnalysis = 'multipleBased';

  adjustmentFactorSlider = [
    {
      factor: 'Acquisition',
      adjustmentFactorData: {
        legendEnabled: false,
        series: [
          { values: [{ name: 'Scorecard Method', y: 100, color: '#1D1563' }, { name: 'Checklist Method', y: 0, color: '#F2F2F2' } ],
            color: 'black',
            type: 'pie'
          }
        ]
      }
    },
    {
      factor: 'Funding',
      adjustmentFactorData: {
        legendEnabled: false,
        series: [
          {
            values: [{ name: 'Scorecard Method', y: 0, color: '#2C846A' }, { name: 'Checklist Method', y: 100, color: '#F2F2F2' }],
            color: 'black',
            type: 'pie'
          }
        ]
      }
    }
  ];

  acquisitionFunding = {
    average: ['5.43', '6.42', '7.5', '4.21', '7.5', '4.21'],
    median: ['5.43', '6.42', '7.5', '4.21', '7.5', '4.21'],
    max: ['5.43', '6.42', '7.5', '4.21', '7.5', '4.21'],
    min: ['5.43', '6.42', '7.5', '4.21', '7.5', '4.21'],
    weightedAverage: ['5.43', '6.42', '7.5', '4.21', '7.5', '4.21']
  };

  companyMetricValues = [];

  companyMetrics = [
    { key: 'Company Metric', value: null },
    { key: 'Revenue for 3 years', value: '0' },
    { key: 'EBITDA for 3 years', value: '0' }
  ];

  concludedValues = { evRevenue: ['0', 100], evEbitda: ['0', 0] };

  // Range Analysis
  findRangeAnalysis() {
    this.adjustmentFactorChange();
    this.multipleAdjustmentChange();
    this.regionChangeForAnalysis();

    this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
    if(!this.mergersAndAcquisitions.dealMultiplesCalculation.custom){
      this.mergersAndAcquisitions.dealMultiplesCalculation.custom = {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
    this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);
    if(!this.fundingSituations.dealMultiplesCalculation.custom){
      this.fundingSituations.dealMultiplesCalculation.custom = {
        calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
        ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
      }
    }
    this.updateAnalysis();
    this.resultAvailable.next();
  }
  
  adjustmentFactorChange() {
    this.range.af.analysis = [0, 0];
    const currentAnalysis = cloneDeep(this.result);

    let analysis = []
    const currentYear = (new Date()).getFullYear();
    for(let i=1; i<=5; i++) {
      if(this.range.af['o' + i].checked) {

        if(i < 4) {
          this.mergersAndAcquisitions.dealMultiples = this.mergersAndAcquisitions.dealMultiples.filter(tr=>{
            const trYear = (new Date(tr.date)).getFullYear();
            return (currentYear - this.range.af['o' + i].value) <= trYear;
          })

          this.fundingSituations.dealMultiples = this.fundingSituations.dealMultiples.filter(tr=>{
            const trYear = (new Date(tr.date)).getFullYear();
            return (currentYear - this.range.af['o' + i].value) <= trYear;
          })
        } else if( i == 4){
          this.mergersAndAcquisitions.dealMultiples = this.range.af['o' + i].mergersAndAcquisitions.filter(tr=>tr.selected)
          this.fundingSituations.dealMultiples = this.range.af['o' + i].fundingSituations.filter(tr=>tr.selected)
        }

        this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
        if(!this.mergersAndAcquisitions.dealMultiplesCalculation.custom){
          this.mergersAndAcquisitions.dealMultiplesCalculation.custom = {
            calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
            ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
          }
        }
        this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);
        if(!this.fundingSituations.dealMultiplesCalculation.custom){
          this.fundingSituations.dealMultiplesCalculation.custom = {
            calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
            ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
          }
        }
        this.updateAnalysis();

        analysis.push(this.concludedEquityValue);

        this.resetAnalysis(currentAnalysis);
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1).filter(v => v > 0);

    if(analysis.length > 0) {
      this.range.af.analysis = [analysis[0], analysis[analysis.length - 1] ]
    }
    
    // console.log("CTM AF ANalysis final", this.range.af.analysis)
  }

  multipleAdjustmentChange() {
    this.range.mam.analysis = [0, 0];
    const currentAnalysis = cloneDeep(this.result);
    let analysis = [];

    for(let i=1; i<=6; i++) {
      if(this.range.mam['o' + i].checked) {

        this.updateAnalysis(this.range.mam['o' + i].value)

        analysis.push(this.concludedEquityValue);

        this.resetAnalysis(currentAnalysis);
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1).filter(v => v > 0);
    // console.log("MAM ANalysis final", analysis)

    if(analysis.length > 0) {
      this.range.mam.analysis = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  regionChangeForAnalysis() {
    this.range.region.analysis = [0, 0];
    let analysis = [];

    const currentAnalysis = cloneDeep(this.result);

    for(let i=1; i<=3; i++) {
      const regionType = this.range.region['o' + i];

      if(regionType.checked) {
        if(regionType.value == "local") {
          let countryAlias1 = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.alias1;
          let countryAlias2 = this.formData.GENERAL.GD_General_Details.GD_CT_COUNTRY_NAME.name;
          countryAlias1 = countryAlias1? countryAlias1.toUpperCase() : "";
          countryAlias2 = countryAlias2? countryAlias2.toUpperCase() : "";

          const selectedCompanies1 = this.mergersAndAcquisitions.dealMultiples.filter(comp=> comp.country && (comp.country.toUpperCase() === countryAlias1 || comp.country.toUpperCase() === countryAlias2));
          this.mergersAndAcquisitions.dealMultiples = selectedCompanies1 || [];

          const selectedCompanies2 = this.fundingSituations.dealMultiples.filter(comp=> comp.country && (comp.country.toUpperCase() === countryAlias1 || comp.country.toUpperCase() === countryAlias2));
          this.fundingSituations.dealMultiples = selectedCompanies2 || [];
        }
        else if(i == 3) {
          if(regionType.value && regionType.value.length >= 3) {
            const selectedCompanies1 = this.mergersAndAcquisitions.dealMultiples.filter(comp=> comp.country && comp.country.toUpperCase() === this.range.region.o3.value);
            this.mergersAndAcquisitions.dealMultiples = selectedCompanies1 || [];

            const selectedCompanies2 = this.fundingSituations.dealMultiples.filter(comp=> comp.country && comp.country.toUpperCase() === this.range.region.o3.value);
            this.fundingSituations.dealMultiples = selectedCompanies2 || [];

          } else {
            this.mergersAndAcquisitions.dealMultiples = [];
            this.fundingSituations.dealMultiples = [];
          }
        }

        this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
        if(!this.mergersAndAcquisitions.dealMultiplesCalculation.custom){
          this.mergersAndAcquisitions.dealMultiplesCalculation.custom = {
            calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
            ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
          }
        }
        this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);
        if(!this.fundingSituations.dealMultiplesCalculation.custom){
          this.fundingSituations.dealMultiplesCalculation.custom = {
            calculation: 'Custom', acquirer: '', target: '', amount: '', stake: '0', valuation: '0',
            ltmRevenue: '', ltmEbitda: '', evRevenue: 0, evEbitda: 0
          }
        }
        this.updateAnalysis();

        analysis.push(this.concludedEquityValue);

        this.resetAnalysis(currentAnalysis);
      }
    }

    analysis = analysis.sort((a,b)=> a>b? 1 : a==b ? 0 : -1).filter(v => v > 0);

    // console.log("Region Analaysis Final", analysis);

    if(analysis.length > 0) {
      this.range.region.analysis = [analysis[0], analysis[analysis.length - 1] ]
    }
  }

  setRange(type, analysis) {
    if(type[0] == 0 || type[0] > analysis[0]) {
      type[0] = analysis[0]
    }

    if(type[1] == 0 || type[1] < analysis[1]) {
      type[1] = analysis[1]
    }
  }

  resetAnalysis(currentAnalysis) {
    this.result = cloneDeep(currentAnalysis);
    this.initInstance();
    this.mergersAndAcquisitions.dealMultiplesCalculation = this.calculation(this.mergersAndAcquisitions);
    this.fundingSituations.dealMultiplesCalculation = this.calculation(this.fundingSituations);

    this.updateAnalysis();
  }
  
  saveWidget() {    
    this.result = {
      mergersAndAcquisitions: this.mergersAndAcquisitions,
      fundingSituations: this.fundingSituations,
      analyzedData: this.analyzedData,
      range: this.range,
      apiTransactions: this.apiTransactions,
      mNaSimilarCompanies: this.mNaSimilarCompanies,
      peSimilarCompanies: this.peSimilarCompanies,
      balanceSheetAdjustment: this.balanceSheetAdjustment,
      transactionFilters: this.transactionFilters,
      baseOfAnalysis: this.baseOfAnalysis,
      companyMetrics: this.companyMetrics,
      concludedEquityValue: this.concludedEquityValue,
      adjustedMultiple: this.adjustedMultiple       
    }
  }

  setBalanceSheetAdjustment(adj){
    this.balanceSheetAdjustment = adj;
    this.updateAnalysisAndSave();
  }
  
  analyseWithNewCurrencyRate() 
  {
    
  }
}