import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MessagePopupComponent } from 'src/app/utils/popup/message-popup.component';

@Component({
  selector: 'app-choose',
  templateUrl: './organization-choose.component.html',
  styleUrls: ['./organization-choose.component.scss']
})
export class OrganizationChooseComponent implements OnInit {
  domain: string;

  constructor(
    public loaderService: ImageLoadService,
    private route: ActivatedRoute,
    private um: UserManagementService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.um.logout();
  }

  loginClick() {
    if(this.domain.length >= 3) {
      this.um.getSingleSignOnURL().subscribe((res: HttpResponse<any>) => {
        let redirectUrl = res.body["response"] + "&domain_hint="+this.domain;
        window.location.replace(redirectUrl);
      }, error => {
        console.log("Login error", error);
        this.showMessage("Single Sign On Failed");
      });
    } else {
      this.showMessage("Please enter valid domain name");
    }
  }

  showMessage(msg) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessagePopupComponent, {
      data: {
        okButtonMsg: "Ok",
        dialogMsg: msg
      }
    });
  }
}
