<ng-template [ngIf] = "thresholdService.thresholdDataLoaded && (thresholdService.thresholdData.configurations.thresholdSelected 
|| configurationService.THRESHOLD.isSelected == true && thresholdService.thresholdData.configurations.thresholdSelected == false)">
  <div class="row" style="margin: 10px 5px 25px 5px; border: 4px solid lightgray; border-radius: 15px;">
    <div class="col">
      
      <!-- Threshold % Change in NAV Between the Val Dates (Current and Prev) -->
      <div class="row" style="padding: 20px 30px 0 30px; padding-bottom: 0;">
        <ng-template [ngIf] = "thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInNAVBetweenTheDates"
          [ngIfElse] = "changeInNAVBetweenValDatesNotSelected">
          <div class="col-7">
            <div class="padding-around-threshold" style="font-weight: bold;">
              {{translateService.getLabel("threshold_change_NAV_bw_valDates")}}
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("curr_NAV")}} ({{valuationCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("NAV_ason")}} {{thresholdService.thresholdData.inputs.prevValuationDate | date: 'mediumDate'}} 
              ({{valuationCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("percent_change_NAV_in_comparison_to")}} {{thresholdService.thresholdData.inputs.prevValuationDate | date: 'mediumDate'}}
            </div>
          </div>
          <div class="col-5 align-right">
            <div class="padding-around-threshold" style="font-weight: bold;">
              <i class="fas fa-layer-group blink" 
              [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')"
              *ngIf = "(thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInNAVBetweenTheDatesInSync
              != thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInNAVBetweenTheDates)
              || (thresholdService.thresholdData.configurations.thresholdForPercentageChangeInNAVBetweenTheDates
              != thresholdService.thresholdData.configurations.thresholdForPercentageChangeInNAVBetweenTheDatesInSync)">
              </i>
              <span [RawDataDirective]="thresholdService.thresholdData.configurations.thresholdForPercentageChangeInNAVBetweenTheDates">
                {{
                  utilService.getDisplayFormattedNumber(thresholdService.thresholdData.configurations
                  .thresholdForPercentageChangeInNAVBetweenTheDates, 2, '%', 'NMF', false)
                }}
              </span>
            </div>
            <div class="padding-around-threshold">
              <span [RawDataDirective]="thresholdService.thresholdData.inputs.currentNAV">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.currentNAV, 
                2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold">
              <i class="fas fa-layer-group blink"
              [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')" 
              *ngIf = "(thresholdService.thresholdData.inputs.navasOfPrevValuationDate
              != thresholdService.thresholdData.inputs.navasOfPrevValuationDateInSync)">
              </i>
              <span [RawDataDirective]="thresholdService.thresholdData.inputs.navasOfPrevValuationDate">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.navasOfPrevValuationDate, 
                2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold enterprise-value">
              <span [RawDataDirective]="thresholdService.thresholdData.analysis.percentageChangeInNAVBetweenValuationDates">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.analysis.percentageChangeInNAVBetweenValuationDates, 
                2, '%', 'NMF', false)
              }}
              </span>
            </div>
          </div>
        </ng-template>
        <ng-template #changeInNAVBetweenValDatesNotSelected>
          <div class="col-7">
            <div class="padding-around-threshold">
              {{translateService.getLabel("curr_NAV")}} ({{valuationCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("NAV_ason")}} {{thresholdService.thresholdData.inputs.prevValuationDate | date: 'mediumDate'}} ({{valuationCurrency}} Mn) 
            </div>
          </div>
          <div class="col-5 align-right">
            <div class="padding-around-threshold">
              <span [RawDataDirective]="thresholdService.thresholdData.inputs.currentNAV">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.currentNAV, 
                2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold">
              <span [RawDataDirective]="thresholdService.thresholdData.inputs.navasOfPrevValuationDate">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.navasOfPrevValuationDate, 
                2, '', 'NMF', false)
              }}
              </span>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- Threshold % Change in Total Portfolio NAV asof Prev Val Date -->
      <ng-template [ngIf] = "thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInTotalPortfolioNAV">
        <div class="row" style="padding: 20px 30px 0 30px;">
          <div class="col-7">
            <div class="padding-around-threshold" style="font-weight: bold;">
              {{translateService.getLabel("threshold_change_in_NAV_as_percent_of_total_NAV")}}
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("nav_of")}} <b>{{fundName}}</b> {{translateService.getLabel("as_on")}} {{thresholdService.thresholdData.inputs.prevValuationDate | date: 'mediumDate'}} 
              ({{thresholdService.thresholdData.inputs.reportingCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("curr_NAV")}} ({{thresholdService.thresholdData.inputs.reportingCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("NAV_ason")}} {{thresholdService.thresholdData.inputs.prevValuationDate | date: 'mediumDate'}} 
              ({{thresholdService.thresholdData.inputs.reportingCurrency}} Mn) 
            </div>
            <div class="padding-around-threshold">
              {{translateService.getLabel("change_in_NAV_as_portfolio_invest_NAV_percentage")}}
            </div>
          </div>
          <div class="col-5 align-right">
            <div class="padding-around-threshold" style="font-weight: bold;">
              <i class="fas fa-layer-group blink"
              [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')"
              *ngIf = "(thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInTotalPortfolioNAV
                != thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInTotalPortfolioNAVInSync)
                || (thresholdService.thresholdData.configurations.thresholdForPercentageChangeInTotalPortfolioNAV
                != thresholdService.thresholdData.configurations.thresholdForPercentageChangeInTotalPortfolioNAVInSync)">
              </i>
              <span [RawDataDirective]="thresholdService.thresholdData.configurations.thresholdForPercentageChangeInTotalPortfolioNAV">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.configurations.thresholdForPercentageChangeInTotalPortfolioNAV, 
                2, '%', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold">
              <i class="fas fa-layer-group blink"
              [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')"
              *ngIf = "(thresholdService.thresholdData.inputs.totalPortfolioNAVAsOfPrevValuationDate
                != thresholdService.thresholdData.inputs.totalPortfolioNAVAsOfPrevValuationDateInSync)">
              </i>
              <span [RawDataDirective]="thresholdService.thresholdData.inputs.totalPortfolioNAVAsOfPrevValuationDate">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.totalPortfolioNAVAsOfPrevValuationDate, 
                2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold">
              <span [RawDataDirective]="(thresholdService.thresholdData.inputs.currentNAV * thresholdService.thresholdData.inputs.currentValDateExchangeRate)">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.currentNAV 
                * thresholdService.thresholdData.inputs.currentValDateExchangeRate, 2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold">
              <i class="fas fa-layer-group blink"
              [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')" 
              *ngIf = "(thresholdService.thresholdData.inputs.navasOfPrevValuationDate
              != thresholdService.thresholdData.inputs.navasOfPrevValuationDateInSync)">
              </i>
              <span [RawDataDirective]="(thresholdService.thresholdData.inputs.navasOfPrevValuationDate * thresholdService.thresholdData.inputs.prevValDateExchangeRate)">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.inputs.navasOfPrevValuationDate 
                * thresholdService.thresholdData.inputs.prevValDateExchangeRate, 2, '', 'NMF', false)
              }}
            </span>
            </div>
            <div class="padding-around-threshold enterprise-value">
              <span [RawDataDirective]="(thresholdService.thresholdData.analysis.percentageChangeInTotalPortfolioNAV)">
              {{
                utilService.getDisplayFormattedNumber(thresholdService.thresholdData.analysis.percentageChangeInTotalPortfolioNAV, 
                2, '%', 'NMF', false)
              }}
            </span>
            </div>
          </div>
        </div>
      </ng-template>
      
      <!-- Displaying the Application Status of Threshold (Valuation Change / No Valuation Change) -->
      <div class="row" style="padding: 20px 30px 0 30px;">
        <div class="col-7 align-left">
          <div class="padding-around-threshold" style="font-weight: bold;">
            {{translateService.getLabel("val_change_ques")}}?
          </div>
        </div>
        <div class="col-5 align-right">
          <div class="padding-around-threshold" style="font-weight: bold;">
            {{ translateService.getLabel(thresholdService.thresholdData.analysis.isThereAValuationChange) }}
          </div>
        </div>
      </div>

      <!-- Refreshing threshold Information from Configuration Tab -->
      <div class="row" style="padding: 15px;">
        <div class="col align-right" style="padding-right: 33px;">
          <i class="fas fa-layer-group blink" style="margin-right: 5px;"
          [matTooltip]="translateService.getLabel('click_refresh_to_apply_threshold_settings')"
            *ngIf = "(thresholdService.thresholdData.inputs.reportingCurrency
              != thresholdService.thresholdData.inputs.reportingCurrencyInSync)
            || (thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInTotalPortfolioNAV
              != thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInTotalPortfolioNAVInSync)
            || (thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInNAVBetweenTheDatesInSync
              != thresholdService.thresholdData.configurations.applyThresholdForPercentageChangeInNAVBetweenTheDates)
            || (thresholdService.thresholdData.configurations.thresholdSelected && 
              thresholdService.thresholdData.analysis.isThereAValuationChange 
              != thresholdValuationChange)
            || (configurationService.THRESHOLD.isSelected == true 
              && thresholdService.thresholdData.configurations.thresholdSelected == false)"
              >
          </i>


          <button mat-raised-button class="btn-custom-primary" type="button"
            (click)="refreshThresholdDataUpdateandSaveConsolSummary(true)">
              {{translateService.getLabel("refresh")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>