import { CCAService } from './cca/cca.service';
import { Component, Input, OnChanges, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DataService } from 'src/app/services/data.service';
import { colors } from 'src/app/utils/colors';
import { PortFolioService } from '../portfolio.service';

import cloneDeep from 'lodash/cloneDeep';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/utils/util.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { CapTable2Service } from "./cap-table2/cap-table2.service";
import { InvestmentSummaryService } from '../investment-page-summary-v2/investment-summary.service';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { TranslateService } from 'src/app/services/translation.service';
import { THRESHOLD_VALUATION_CHANGE } from './threshold/model/threshold';
import { ConfigurationService } from 'src/app/utils/configuration/services/configuration.service';
import { A } from '@angular/cdk/keycodes';
import { ThresholdService } from './threshold/services/threshold.service';

@Component({
  selector: 'app-consol-summary',
  templateUrl: './consol-summary.component.html',
  styleUrls: ['./consol-summary.component.scss']
})
export class ConsolSummaryComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() latestCompanyValuationDate;
  @Input() primaryCompanyId;
  @Input() consolSummary;

  @Input() selectedCompanyDates = [];

  @Output() action = new EventEmitter();

  businessUnitsWaterFall;
  investmentDateString;

  source = "CONSOL_SUMMARY_UPDATE"

  stakeModel = {
    showExcelUI: false,
    userUploadedJson: null
  };

  selected="fully diluted";
  selectedAdjustmentType="consolidatedEnterprise";

  adjustmentsObject = {
    totalValueBeforeAdjustment: 0,
    totalValueAfterAdjustment: 0,
    adjustments: {},
    headerLabel: ""
  }

  adjustmentTracker;

  slSummaryTable;

  fundId = "";
  currencyExchangeRates = {} as any;

  currency = ""

  loadingConsolSummary = true;
  waterfallVersionId

  dataRefresh = "";

  THRESHOLD_VALUATION_CHANGE = THRESHOLD_VALUATION_CHANGE;

  thresholdValuationChange = THRESHOLD_VALUATION_CHANGE.VALUATION_CHANGE;

  constructor(private dataService: DataService,
    private portfolioService: PortFolioService,
    public portfolioSummaryService: PortFolioSummaryServiceV2,
    private modalService: NgbModal,
    public utilService : UtilService,
    public ds : DataService, public imageLoadService: ImageLoadService,
    private ccaService: CCAService,
    public capTable2Service: CapTable2Service,
    private route: ActivatedRoute,
    private invSummaryService : InvestmentSummaryService,
    private thresholdService : ThresholdService,
    public ums: UserManagementService,
    public translateService: TranslateService,
    public configService : ConfigurationService)
  { }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.fundId = this.route.snapshot.queryParamMap.get('parentId');
    this.ds.fundId = this.fundId;
  }

  rollOverID;

  ngAfterViewInit() {
    window.addEventListener("message", this.stakeChange.bind(this), false);
  }

  stakeChange(e) {
    if(!e.data.key || !e.data.id || !e.data.valuationDateId){
      return
    }

    if(this.latestCompanyValuationDate.id != e.data.valuationDateId){
      console.log("Do not allow to trigger analysis for Different instance of Consol Form Id, Current Id : " 
      + this.latestCompanyValuationDate.id + " Waterfall trigger Id : " + e.data.valuationDateId);
      return;
    }

    console.log("RECEIVING Data from Iframe", e.data.key, e.data.id, e.data.valuationDateId);
    let typeOfStakeToBeUpdated = "";
    
    this.waterfallVersionId = e.data.id;

    switch(e.data.key){
      case 'dilutedStake':
        typeOfStakeToBeUpdated = e.data.key;
      break;
      case 'impliedStakeWaterfall':
        typeOfStakeToBeUpdated = e.data.key;
      break;
      case 'impliedStakeManpack':
        typeOfStakeToBeUpdated = e.data.key;
      break;
      case 'impliedStakeManOption':
        typeOfStakeToBeUpdated = e.data.key;
      break;
      case 'teasuryStockStake':
        typeOfStakeToBeUpdated = e.data.key;
        break;
      case 'versionChange':
        typeOfStakeToBeUpdated = 'ALL';
      break;
    }

    this.consolSummary.customStake.selectedOption = typeOfStakeToBeUpdated;

    this.initStakeValueBySelectedType()

    setTimeout(() => {
      this.initCustomStakeUpdate(typeOfStakeToBeUpdated)
    }, 1000);
  }

  initNewCustomStakeKeys(){
    this.consolSummary.customStake.dilutedStake = {value: this.capTable2Service.getDilutedStake()}
    this.consolSummary.customStake.impliedStakeWaterfall = {value: this.capTable2Service.getImpliedStakeFromWaterFall()}
    this.consolSummary.customStake.impliedStakeManpack = {
      value: this.capTable2Service.getManPackStake(),
      exists: this.capTable2Service.getManPackStakeIsSelected()
    }
    this.consolSummary.customStake.impliedStakeManOption = {
      value: this.capTable2Service.getManPackOptionStake(),
      exists: this.capTable2Service.getManPackOptionStakeIsSelected()
    }
  }

  async ngOnChanges() {
    if(this.latestCompanyValuationDate) {
      this.fundId = this.route.snapshot.queryParamMap.get('parentId');

      try {
        const apiData = await this.ds.getConfigurationDetails(this.fundId).toPromise();
        this.configService.configurationList = apiData.body["response"];
        this.configService.initConfigurationDetails(this.configService.configurationList);
      } 
      catch (error) {
        console.log("Failed to fetch Configuration details", error);
      }

      try {
        const apiData : any = await this.ds.getBUSummaryDataAndExchangeRates([this.latestCompanyValuationDate.id]).toPromise();
        const data = apiData.body["response"];
        this.consolSummary = data.consolFormWiseData[this.latestCompanyValuationDate.id].consolSummary;
        
        if(!this.consolSummary.thresholdValuationChange){
          this.consolSummary.thresholdValuationChange = THRESHOLD_VALUATION_CHANGE.VALUATION_CHANGE;
        }
        this.thresholdValuationChange = this.consolSummary.thresholdValuationChange;

        if(!this.consolSummary.actualStakeValue){
          this.consolSummary.actualStakeValue = this.consolSummary.finalStakeValue;
        }
        
        this.latestCompanyValuationDate.businessUnits = this.invSummaryService
        .addInfoToFormobjectFromDetails(data.consolFormWiseData[this.latestCompanyValuationDate.id].businessUnits);

        this.latestCompanyValuationDate.businessUnits = this.invSummaryService
        .sortBusinessUnitsByBusinessUnitNameOrderByASC(this.latestCompanyValuationDate.businessUnits);
        
        this.currencyExchangeRates = data.currencyExchangeRates;
      } catch (error) {
        
        console.log("Failed to Consol Summary widget", error)
      }

     if(Object.keys(this.currencyExchangeRates).length == 0){
      try {
        const input = this.latestCompanyValuationDate.businessUnits.filter(bu => bu.investment).map(bu => {
          return {
            from: bu.investment.currency,
            to: this.consolSummary.currency,
            date: (moment(this.latestCompanyValuationDate.valuationDate)).format("YYYY-MM-DD")
          }
        })

        const data = await this.ds.getCurrencyExchangeRatesByCurrencyLayer(input).toPromise();
        this.currencyExchangeRates = data.body['response'].exchangeRates ? data.body['response'].exchangeRates : {}
      } catch (error) {
        console.log("Failed to get the Exchange rates", error)
      }
      // if(Object.keys(this.invSummaryService.businessUnitsValSummaryForAllIds.currency).length > 0){
      //   this.currencyExchangeRates = this.invSummaryService.businessUnitsValSummaryForAllIds.currency;
      // }
      // else{
      //   try {
      //     const input = this.latestCompanyValuationDate.businessUnits.filter(bu => bu.investment).map(bu => {
      //       return {
      //         from: bu.investment.currency,
      //         to: this.consolSummary.currency,
      //         date: (moment(this.latestCompanyValuationDate.valuationDate)).format("YYYY-MM-DD")
      //       }
      //     })

      //     const data = await this.ds.getCurrencyExchangeRatesByCurrencyLayer(input).toPromise();
      //     this.currencyExchangeRates = data.body['response'].exchangeRates ? data.body['response'].exchangeRates : {}
      //   } catch (error) {
      //     console.log("Failed to get the Exchange rates", error)
      //   }
      // }
     }

      this.currency = this.consolSummary.currency;

      this.createBusinessUnitsWaterFall();
      this.getAdjustmentsTracker();

      this.loadingConsolSummary = false;

      this.consolSummary.finalAdjustments = this.getFormattedAdjustments(this.consolSummary.finalAdjustments);
      this.consolSummary.sumOfPartsAdj = this.getFormattedAdjustments(this.consolSummary.sumOfPartsAdj);

      if(this.consolSummary.customStake){
        if(!this.consolSummary.customStake.dilutedStake){
          this.consolSummary.customStake.dilutedStake = {value: 100}
        }

        if(!this.consolSummary.customStake.impliedStakeWaterfall){
          this.consolSummary.customStake.impliedStakeWaterfall = {value: 100}
        }

        if(!this.consolSummary.customStake.impliedStakeManpack){
          this.consolSummary.customStake.impliedStakeManpack = {value: 100, exists: false}
        }

        if(!this.consolSummary.customStake.impliedStakeManOption){
          this.consolSummary.customStake.impliedStakeManOption = {value: 100, exists: false}
        }

        if(!this.consolSummary.customStake.stakeFromModel){
          this.consolSummary.customStake.stakeFromModel = {value: 0}
        }
      }

      this.capTable2Service.setlatestCompanyValuationDate(this.latestCompanyValuationDate)
      this.ccaService.setlatestCompanyValuationDate(this.latestCompanyValuationDate)
      await this.capTable2Service.init(); // to update Captable data within platform

      // this.initStakeValueBySelectedType()

      // this.initSLSummary();

      // if(!this.rollOverID) {
      //   // Update only for first time of component load.
      //   // There are update waterfall only on change in equity value
      //   await this.updateWaterFall();
      // }

      if(this.consolSummary.slSummaryTable){
        this.slSummaryTable = this.getFormattedSlSummaryTable(this.consolSummary.slSummaryTable);
      }
      else{
        this.getSLSummaryData()
      }
    }

    if(this.primaryCompanyId) {
      const investmentDate = this.selectedCompanyDates.find(comp => comp.id == this.primaryCompanyId);
      this.investmentDateString = investmentDate.valuationDate;
    }
  }

  getSLSummaryData(){
    if(!this.consolSummary.slSummaryTable){
      this.ds.getSLSummaryData(this.latestCompanyValuationDate.id).subscribe(res => {
        console.log("SL Summary", res);
        this.slSummaryTable = this.getFormattedSlSummaryTable(res.body["response"]);
      }, error => {
        console.log("Failed to fetch SL Summary", error);
      })
    }
  }

  getFormattedSlSummaryTable(slSummaryTable){
    let formattedSLSummaryTable = [];
    const headers = [];
    try {
      if(slSummaryTable != null && Object.keys(slSummaryTable).length > 0){
        const investors = Object.keys(slSummaryTable).filter((item) => item != "stakeValue");

        headers.push("Waterfall Summary");

        investors.forEach(inv => {
          headers.push(inv)
        })

        formattedSLSummaryTable.push(headers);

        let allSharesInAllInvestors = [] as any;
        investors.forEach( inv => {
          const arrayOfShares = slSummaryTable[inv];
          arrayOfShares.forEach(share => {
            const key = Object.keys(share).length > 0 ? Object.keys(share)[0] : null;
            allSharesInAllInvestors.push(key)
          })
        })

        allSharesInAllInvestors = allSharesInAllInvestors.filter(share => share != null && share != "Total");

        allSharesInAllInvestors = new Set(allSharesInAllInvestors);

        allSharesInAllInvestors.forEach(share =>{
          const formattedShareNamesWithValues = [];

          let totalValueOfShareInAllTheInvestors = 0;

          investors.forEach(inv => {
            if(slSummaryTable[inv]){
              const shareValueExists = slSummaryTable[inv].find(shares => shares[share]);
              totalValueOfShareInAllTheInvestors += shareValueExists && shareValueExists[share]
              ? this.utilService.getValidNumber(shareValueExists[share]) : 0;
            }
          })

          if(totalValueOfShareInAllTheInvestors > 0){
            formattedShareNamesWithValues.push(share);
          }

          investors.forEach(inv => {
            if(slSummaryTable[inv] && totalValueOfShareInAllTheInvestors > 0){
              const shareValueExists = slSummaryTable[inv].find(shares => shares[share]);
              const value = shareValueExists && shareValueExists[share]
              ? this.utilService.getDisplayFormattedNumber(shareValueExists[share], 2, "", "-", true) : "-";

              formattedShareNamesWithValues.push(value)
            }
          })

          formattedShareNamesWithValues && formattedShareNamesWithValues.length > 0 ?
           formattedSLSummaryTable.push(formattedShareNamesWithValues) : [];
        })

        const footers = [];
        footers.push("Total");

        investors.forEach(inv => {
          if(slSummaryTable[inv]){
            const shareValueExists = slSummaryTable[inv].find(shares => shares["Total"]);

            const value = shareValueExists && shareValueExists["Total"]
            ? this.utilService.getDisplayFormattedNumber(shareValueExists["Total"], 2, "", "-", true) : "-";

            footers.push(value)
          }
        })

        formattedSLSummaryTable.push(footers);
      }
    } catch (error) {
      console.log("Error while formatting SL Summary table", error)
    }

    return formattedSLSummaryTable
  }

  //This either rolls over waterfall from older consol form or updates waterfall with new equity value
  async updateWaterFall() {
    try {
      if(!this.rollOverID) {
        const res = await this.ds.getWidgetDataFromDB("ROLL_OVER", this.latestCompanyValuationDate.id).toPromise();
        const rollOverData = res.body['response'][0].widgetData;

        if(!rollOverData.done) {
          //Consider rollover data only once. From next time, use the same consol form id.
          this.rollOverID = rollOverData.selectedRollOverId;

          rollOverData.done = true

          this.ds.saveWidgetToDBAsync("ROLL_OVER", rollOverData, this.latestCompanyValuationDate.id);
        } else {
          this.rollOverID = this.latestCompanyValuationDate.id;
        }
      }
    } catch (error) {
      console.log("Rollover ID does not exists", error);
      this.rollOverID = this.latestCompanyValuationDate.id;
    }

    try {
      const companyEquityValue = this.latestCompanyValuationDate.investment.equityValue.finalAdjustedEquityVal;
      const waterfallEquityValue = this.capTable2Service.capTable2.waterfallData.lpInput["companyEquityValue"];

      if(waterfallEquityValue && (companyEquityValue != waterfallEquityValue || this.rollOverID != this.latestCompanyValuationDate.id)){
        const reqBody = {
          "fromFormId": this.rollOverID,
          "toFormId": this.latestCompanyValuationDate.id,
          "companyEquityValue": companyEquityValue,
          "valuationDate": this.ds.getDateYYYYMMDD(this.latestCompanyValuationDate.valuationDate)
        }

        try {
          this.utilService.showLoadingPopup();

          await this.ds.getCCARollOver(reqBody).toPromise();
          await this.capTable2Service.init();
          await this.capTable2Service.fetchRollOverStake();

          this.utilService.closeAllPopups();

          this.initNewCustomStakeKeys();

          this.initStakeValueBySelectedType();

          this.initSLSummary();

          this.saveData()

        } catch (error) {
          this.utilService.closeAllPopups();
          console.log("Failed to do CCA Roll over", error);
        }
      }
    } catch(e) {
      console.log("Error occured during CCA rollover", e)
    }
  }

  isBuSubmitted(buFormId){
    return this.latestCompanyValuationDate && this.latestCompanyValuationDate.businessUnits
    && this.latestCompanyValuationDate.businessUnits.find(bu => bu.id == buFormId && bu.status == "Submitted");
  }

  getAdjustmentsTracker(){
    if(this.latestCompanyValuationDate && this.latestCompanyValuationDate.businessUnits){
      const reqBody = {
        consolFormId: this.latestCompanyValuationDate.id,
        metrics: ["bevRevenue", "bevEbitda"],
        year: ["LTM"]
      }

      this.ds.getAdjustmentsTrackerForConsolSummary(reqBody).subscribe( res => {
        console.log("Sucessfully fetched adjustments tracker for Consol Summary", res);
        const apiData = res.body['response'];
        this.adjustmentTracker = {
          consolSummaryAdjustments: apiData.consolSummaryAdjustments,
          sotp: apiData.sotp,
          allAdjustmentKeys: apiData.allAdjustmentKeys,
        }
      }, error => {
        console.log("Failed to fetch adjustments tracker consol summary", error);
      })
    }
  }

  createBusinessUnitsWaterFall() {
    if(this.latestCompanyValuationDate && this.latestCompanyValuationDate.businessUnits) {

      let sumOfParts = 0;
      const __this = this;

      // const currency = this.latestCompanyValuationDate.investment && this.latestCompanyValuationDate.investment.currency
      // ? this.latestCompanyValuationDate.investment.currency
      // : this.consolSummary && this.consolSummary.currency
      // ? this.consolSummary.currency : "";

      const currency = this.currency;

      let allowedBusinessUnitsInChart = [];

      const waterFallNumbers = this.latestCompanyValuationDate.businessUnits.filter(bu => bu.investment).map( (bu, index) => {
        const details = JSON.parse(bu.details);

        if(bu.investment && bu.investment.currency) {
          const currencyCode = bu.investment.currency + this.consolSummary.currency + this.latestCompanyValuationDate.valuationDate;
          // Example currency code USDEUR2021-03-31

          let exchangeRate = this.currencyExchangeRates[currencyCode] ? this.currencyExchangeRates[currencyCode] : 1;

          exchangeRate = exchangeRate > 0 ? exchangeRate : 1

          sumOfParts += (bu.investment.enterpriseValue * exchangeRate);

          allowedBusinessUnitsInChart.push(bu);

          return {
            y: (bu.investment.enterpriseValue * exchangeRate),
            name: details.companyNameInForm
          }
        } else {
          allowedBusinessUnitsInChart.push(bu);

          return {
            y: 0,
            name: details.companyNameInForm
          }
        }
      })

      waterFallNumbers.push({
        name: "Consol",
        isSum: true,
        color: colors.primaryColor
      })

      //portfolioService.openValuation(bu)

      const _this = this;
      this.businessUnitsWaterFall = new Chart({
        chart: {
          type: 'waterfall',
          // backgroundColor: "#e6e6e6"
        },
        title: { text: '' },
        credits: { enabled: false },
        exporting: { enabled: false },
        legend:{ enabled: false},

        xAxis: {
          type: 'category',
          gridLineWidth: 0,
          gridLineColor: '#BEBEBE'
        },

        yAxis: {
          title: { text: '' },
          gridLineWidth: 1,
          gridLineColor: '#BEBEBE',
          labels: {
            formatter: function(){
                return _this.utilService.getDisplayFormattedNumber(this.value, 0, "", "", false);
            }
          }
        },

        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: (e) => {
                if(e.point.options["isSum"]){
                  return;
                }

                const selectedBusinessUnit = allowedBusinessUnitsInChart[e.point["index"]];

                this.portfolioService.openValuation(selectedBusinessUnit);
              }
            }
          },
          waterfall: {
            negativeColor: colors.chartColorsArray[3]
          }
        },
        tooltip: {
          formatter: function () {
              return '<b>' + this.point.name + '</b> : ' + currency + " " + (_this.utilService.getDisplayFormattedNumber(this.y, 1, "", "", false)).toLocaleString() + ' Mn';
          }
        },
        series: [{
          upColor: colors.chartColorsArray[2],
          color: colors.chartColorsArray[2],
          borderWidth: 0,
          data: waterFallNumbers,
          pointPadding: 0,

          dataLabels: {
              enabled: true,
              verticalAlign: 'top',
              x: 0,
              y: -30,
              formatter: function () {
                  return  currency + " " + (_this.utilService.getDisplayFormattedNumber(this.y, 1, "", "", false)).toLocaleString() + ' Mn';
              },
              color: '#000',
              style: {
                  fontWeight: 'bold',
              }
          },
        } as any]
      })
    }
  }

  deleteFinalAdjustments(aIndex) {
    this.consolSummary.finalAdjustments.splice(aIndex, 1);
    // this.saveConsolSummary();
    this.initConsolSummaryAnalysis();
  }

  deleteSumOfParts(sIndex) {
    this.consolSummary.sumOfPartsAdj.splice(sIndex, 1);
    // this.saveConsolSummary();
    this.initConsolSummaryAnalysis();
  }

  // saveConsolSummary() {
  //   this.portfolioService.initBU_ConsolSummary(this.latestCompanyValuationDate);

  //   this.dataService.saveWidgetDataToDB("BUSINESS_UNIT_CONSOL_SUMMARY", this.consolSummary, this.latestCompanyValuationDate.id).subscribe(res => {
  //     // console.log("Consol Summary is saved successfully")
  //   }, err => {
  //     console.log("Failed to save consol summary", err)
  //   })

  //   this.updatePortfolioData();
  // }

  saveConsolSummaryData(consolSummary){
    this.portfolioSummaryService.summary[this.portfolioSummaryService.selectedFundId] = null;
    this.portfolioSummaryService.loading = true;

    this.thresholdValuationChange = this.consolSummary.thresholdValuationChange;
    this.dataRefresh = "REFRESH";
    setTimeout(() => {
      this.dataRefresh = ""
    }, 1000);

    this.dataService.saveWidgetDataToDB("BUSINESS_UNIT_CONSOL_SUMMARY", consolSummary, this.latestCompanyValuationDate.id).subscribe(res => {
      // console.log("Consol Summary is saved successfully")
      this.updateSummaryAndValuationDateAttributesByCompany(this.source);
    }, err => {
      console.log("Failed to save consol summary", err)
      this.utilService.closeAllPopups();
    })
  }

  updateSummaryAndValuationDateAttributesByCompany(source){
    const reqBody = {
      companyId : this.primaryCompanyId,
      fundId  : this.fundId,
      formIds : [this.latestCompanyValuationDate.id],
      source  : source
    }
    this.ds.updateSummaryAndValuationDateAttributes(reqBody).subscribe(res => {
      console.log("Sucessfully updated the Summary", res);
      this.utilService.closeAllPopups();
    }, error => {
      console.log("Failed to updated the Summary", error);
      this.utilService.closeAllPopups();
    })
  }

  updatePortfolioData() {

    const portfolioComp = this.portfolioService.portfolioData.find(comp => comp.id === this.latestCompanyValuationDate.id);
    if(portfolioComp) {
      portfolioComp.stake = this.latestCompanyValuationDate.investment.equityValue.stake;

      portfolioComp.fairVal = this.latestCompanyValuationDate.investment.equityValue.finalAdjustedEquityVal;
      portfolioComp.stakeVal = this.latestCompanyValuationDate.investment.equityValue.finalStakeValue;

      portfolioComp.totalValue = portfolioComp.stakeVal + portfolioComp.realisedProceeds;
    }
  }

  initSLSummary() {
    try {
      this.slSummaryTable = this.getMappedJSONWithTotalValueForClassName(this.capTable2Service.diluteStake.diluteStakesFullData.slSummaryStakePer);
    } catch (error) {
      console.log("Error while initializing SL Summary Data", error);
    }
  }

  getMappedJSONWithTotalValueForClassName(slSummaryTable){
    const waterfallSummaryTable = {
      totalValueByClassName: {},
      summaryTable: []
    }

    if(slSummaryTable && slSummaryTable.length > 0){
      const temp = cloneDeep(slSummaryTable);

      const classNames = temp[0].nav.map(cN => cN.className);

      const totalValueByClassName = {}

      classNames.forEach( cN => {
        let totalValue = 0;
        temp.forEach(shareHolder => {
          const classNameExists = shareHolder.nav.find(sH => sH.className == cN);
          totalValue += classNameExists ? classNameExists.nav : 0;
        })

        totalValueByClassName[cN] = totalValue;
      })

      waterfallSummaryTable.totalValueByClassName = totalValueByClassName;
      waterfallSummaryTable.summaryTable = temp
    }

    console.log("Mapped SL Summary table", waterfallSummaryTable);

    return waterfallSummaryTable;
  }

  initAdjustmentObject() {
    if(this.selectedAdjustmentType =='consolidatedEnterprise'){
      this.adjustmentsObject.totalValueBeforeAdjustment = this.consolSummary.sumOfTheParts;
      this.adjustmentsObject.totalValueAfterAdjustment = this.consolSummary.weightedEquityValue;
      this.adjustmentsObject.adjustments = { adjustments : this.consolSummary.sumOfPartsAdj }
      this.adjustmentsObject.headerLabel = "Consolidated Enterprise Value";
    }
    else if(this.selectedAdjustmentType == "impliedFairEquityValue") {
      this.adjustmentsObject.totalValueBeforeAdjustment = this.consolSummary.weightedEquityValue;
      this.adjustmentsObject.totalValueAfterAdjustment = this.consolSummary.finalAdjustedEquityVal;
      this.adjustmentsObject.adjustments = { adjustments : this.consolSummary.adjustmentsOnWeightedEquityValue }
      this.adjustmentsObject.headerLabel = "Implied Fair Equity Value";
    }
    else if(this.selectedAdjustmentType =='stakeFairEquityValue') {
      this.adjustmentsObject.totalValueBeforeAdjustment = this.consolSummary.stakeValue;
      this.adjustmentsObject.totalValueAfterAdjustment = this.consolSummary.finalStakeValue;
      this.adjustmentsObject.adjustments = { adjustments : this.consolSummary.finalAdjustments }
      this.adjustmentsObject.headerLabel = "Fair Equity Value of the Stake";
    }
  }

  getFormattedAdjustments(adjustments:[]){
    if(!adjustments){
      return [];
    }

    const formattedAdjustments = adjustments.map(oldAdj =>{
      return {
        type: oldAdj["type"] == "Subtract" || oldAdj["type"] == "Less" ? "Less" : "Add",
        name: oldAdj["name"],
        value: oldAdj["convertedValue"] || oldAdj["value"],
        comment: oldAdj["comment"],
        convertedValue: (oldAdj["convertedValue"] || oldAdj["value"])
      }
    })

    return formattedAdjustments;
  }

  openPopUpModel(content, adjustmentFor){
    this.adjustmentFor = adjustmentFor;

    this.newConsolAdjObject = cloneDeep(this.CONSOL_SUMMARY_ADJUSTMENT_TEMPLATE);

    this.modalService.open(content, {backdrop: 'static', size: 'lg', centered: true });
  }

  adjustmentsChanged(adjObj) {

    if(this.selectedAdjustmentType =='consolidatedEnterprise' && adjObj.adjustments) {

      this.consolSummary.sumOfPartsAdj = cloneDeep(this.getFormattedAdjustments(adjObj.adjustments));

    } else if(this.selectedAdjustmentType =='stakeFairEquityValue' && adjObj.adjustments) {

      this.consolSummary.finalAdjustments = cloneDeep(this.getFormattedAdjustments(adjObj.adjustments));

    } else if(this.selectedAdjustmentType == "impliedFairEquityValue") {

      this.consolSummary.adjustmentsOnWeightedEquityValue = cloneDeep(this.getFormattedAdjustments(adjObj.adjustments));
    }

    this.modalService.dismissAll();

    // this.saveConsolSummary();
    this.initConsolSummaryAnalysis();
  }

  initConsolSummaryAnalysis(userAction?){
    const reqBody = {
      type: "ANALYSIS",
      consolSummary: this.consolSummary,
      concludedVersionId: this.waterfallVersionId,
      fundId: this.fundId
    }

    userAction ? this.utilService.showLoadingPopup() : null

    this.dataService.initBUSummaryAnalysis(this.latestCompanyValuationDate.id, reqBody).subscribe(res => {
      this.consolSummary = res.body["response"];
      this.slSummaryTable = this.getFormattedSlSummaryTable(this.consolSummary.slSummaryTable);
      this.saveConsolSummaryData(this.consolSummary);
      this.invSummaryService.businessUnitsValSummaryForAllIds.summary[this.latestCompanyValuationDate.id] = cloneDeep(this.consolSummary);
      this.utilService.closeAllPopups();
    }, error => {
      this.utilService.closeAllPopups();
      console.log("Failed to do the Cnsol Summary Analysis", error)
    })
  }

  initCustomStakeUpdate(typeOfStakeToBeUpdated, userAction?){

    if(this.consolSummary.customStake.selectedOption == "impliedStakeWaterfall"){
      this.consolSummary.selectedTypeOfStakeValue = "fromWaterFallSummary"
    }
    else{
      this.consolSummary.selectedTypeOfStakeValue = "fullyDilutedShares"
    }

    const reqBody = {
      type: "CUSTOM_STAKE_UPDATE",
      consolSummary: this.consolSummary,
      typeOfStakeToBeUpdated: typeOfStakeToBeUpdated,
      concludedVersionId: this.waterfallVersionId,
      fundId: this.fundId
    }

    userAction ? this.utilService.showLoadingPopup() : null;

    this.dataService.initBUSummaryAnalysis(this.latestCompanyValuationDate.id, reqBody).subscribe(res => {
      this.consolSummary = res.body["response"];
      this.saveConsolSummaryData(this.consolSummary);
      this.slSummaryTable = this.getFormattedSlSummaryTable(this.consolSummary.slSummaryTable);

      if(this.stakeModel.userUploadedJson){
        //saving excel JSON
        this.dataService.saveWidgetDataToDB("CONSOL_EXCEL_STAKE_MODEL", this.stakeModel.userUploadedJson, this.latestCompanyValuationDate.id).subscribe( res => {
          // console.log("Sucessfully saved Excel json", res)
        }, error => {
          console.log("failed to save Excel json", error)
        })
      }
      
      this.utilService.closeAllPopups();
    }, error => {
      this.utilService.closeAllPopups();
      console.log("Failed to do the Cnsol Summary Analysis", error)
    })
  }

  editAdjustmentPopup(content){
    this.initAdjustmentObject();
    this.modalService.open(content, {backdrop: 'static', size: 'xl' , windowClass: 'add-adjustments-popup-modal', centered: true });
  }

  customStakePopup(content){
    if(this.ums.currentUserCompanyPermissionReadOnly){
      return;
    }

    this.modalService.open(content, {backdrop: 'static', size: 'xl' , windowClass: 'add-stake-popup-modal', centered: true });
  }

  stakeValueTypeChanged(userAction?) {
    if(this.consolSummary.selectedTypeOfStakeValue == "fromWaterFallSummary"){
      this.consolSummary.customStake.selectedOption = "impliedStakeWaterfall"
    }
    else if(this.consolSummary.selectedTypeOfStakeValue == "sumOfTheUnits"){
      //Doing nothing
    }
    else{
      this.consolSummary.customStake.selectedOption = "dilutedStake"
    }

    this.consolSummary.stake = this.consolSummary.customStake[this.consolSummary.customStake.selectedOption].value;

    this.initConsolSummaryAnalysis(userAction)
  }

  // initStakeValueBySelectedType(){
  //   if(this.consolSummary.selectedTypeOfStakeValue == "fromWaterFallSummary"){
  //     this.consolSummary.customStake.selectedOption = "impliedStakeWaterfall"

  //     let totalStakeValue = 0

  //     if(this.capTable2Service.diluteStake.diluteStakesFullData.slSummaryStakePer){
  //       this.capTable2Service.diluteStake.diluteStakesFullData.slSummaryStakePer.forEach( share => {
  //         totalStakeValue += share.totalNav;
  //       })
  //     }

  //     this.consolSummary.stakeValue = totalStakeValue;
  //   }
  //   else if(this.consolSummary.customStake.selectedOption == "impliedStakeWaterfall"){
  //     this.consolSummary.customStake.selectedOption = "dilutedStake";
  //   }

  //   this.consolSummary.stake = this.consolSummary.customStake[this.consolSummary.customStake.selectedOption].value;
  // }

  initStakeValueBySelectedType(){
    if(this.consolSummary.selectedTypeOfStakeValue == "fromWaterFallSummary"){
      this.consolSummary.customStake.selectedOption = "impliedStakeWaterfall"
    }

    this.consolSummary.stake = this.consolSummary.customStake[this.consolSummary.customStake.selectedOption].value;
  }

  async getCurrencyExchange(sourceObj, value, srcCurr, userSelCurr){
    if(!userSelCurr){
      userSelCurr = srcCurr
    }
    let conversionMultiple = 1;
    // if(userSelCurr != srcCurr){
    //   try {
    //     const currConverter = await this.ds.getValuationDateCurrency(userSelCurr, srcCurr).toPromise();
    //     conversionMultiple = currConverter.body['response'].value;
    //     sourceObj.exchangeRate = conversionMultiple;
    //     sourceObj.convertedValue = value * conversionMultiple;
    //   }
    //   catch (error) {
    //     console.log("Error while fetching exchange rates", error)
    //   }
    // }
    sourceObj.convertedValue = sourceObj.numberBeforeConversion * conversionMultiple
  }

  userAction(event) {
    this.loadingConsolSummary = true;
    this.action.next(event);
  }

  adjustmentFor = 'SUM_OF_THE_PARTS';

  newConsolAdjObject;

  CONSOL_SUMMARY_ADJUSTMENT_TEMPLATE = {
    type: "Add",
    name: "",
    number: null,
    sourceCurrency: '',
    numberBeforeConversion: 0,
    userSelectedCurrency: '',
    exchangeRate: 1,
    convertedValue: 0,
    conversionDate: ''
  }


  saveData(excelModelUpdated?){

    if(this.consolSummary.customStake.selectedOption == "impliedStakeWaterfall"){
      this.consolSummary.selectedTypeOfStakeValue = "fromWaterFallSummary"
    }
    else{
      this.consolSummary.selectedTypeOfStakeValue = "fullyDilutedShares"
    }

    if(this.consolSummary.customStake.selectedOption){
      this.consolSummary.stake = this.consolSummary.customStake[this.consolSummary.customStake.selectedOption].value;
      // this.saveConsolSummary();
      this.initConsolSummaryAnalysis();
    }

    if(excelModelUpdated) {
      //saving excel JSON
      this.dataService.saveWidgetDataToDB("CONSOL_EXCEL_STAKE_MODEL", this.stakeModel.userUploadedJson, this.latestCompanyValuationDate.id).subscribe( res => {
        // console.log("Sucessfully saved Excel json", res)
      }, error => {
        console.log("failed to save Excel json", error)
      })
    }
  }

  refreshThresholdData(action?){
    const reqBody = {
      type: "THRESHOLD_REFRESH_AND_UPDATE_STAKE_VALUE",
      consolSummary: this.consolSummary,
      concludedVersionId: this.waterfallVersionId,
      fundId: this.fundId
    }

    action ? this.utilService.showLoadingPopup() : null

    this.dataService.initBUSummaryAnalysis(this.latestCompanyValuationDate.id, reqBody).subscribe(res => {
      this.consolSummary = res.body["response"];
      this.slSummaryTable = this.getFormattedSlSummaryTable(this.consolSummary.slSummaryTable);
      this.saveConsolSummaryData(this.consolSummary);
      this.invSummaryService.businessUnitsValSummaryForAllIds.summary[this.latestCompanyValuationDate.id] = cloneDeep(this.consolSummary);
      this.utilService.closeAllPopups();
    }, error => {
      this.utilService.closeAllPopups();
      console.log("Failed to do the Cnsol Summary Analysis", error)
    })
  }
}
