import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilService } from '../utils/util.service';
import { PortFolioService } from '../qubit-x/portfolio/portfolio.service';
import { DatePipe } from '@angular/common';
import { UserManagementService } from '../services/user-management.service';
import { DateUtilService } from '../date-util.service';
import { TranslateService } from '../services/translation.service';

@Component({
  selector: 'app-qualitative-analysis-v2-add-title-comment',
  templateUrl: './qualitative-analysis-v2-add-title-comment.component.html',
  styleUrls: ['./qualitative-analysis-v2-add-title-comment.component.scss']
})
export class QualitativeAnalysisV2AddTitleCommentComponent implements OnInit {
  @Output() added = new EventEmitter();

  title = '';
  comment = '';

  constructor(
    public translateService: TranslateService,) { }

  ngOnInit(): void {
  }

  save(){
    if (this.title == '' || this.comment == ''){
      return;
    }

    this.added.next({title: this.title, comment: this.comment});

    this.title = '';
    this.comment = '';
  }
}
