<button style="display: none" #openManageAttrsPopup (click)="openCustomAttributeModal(customAttributePopup)">{{
    translateService.getLabel("manage_attributes") }}
</button>

<ng-template class="customAttribute " #customAttributePopup let-customAttributeModal>
    <div class="modal-header">
        <div class="d-flex justify-content-between" style="width: 100%;">
            <div class="modal-title">
                <h6>{{ translateService.getLabel("manage_attributes") }}</h6>
            </div>
            <button type="button" class="close" aria-label="Close"
                (click)="customAttributesRows =[] ; selectedValuationDateId = latestValuationDate.id; 
                customAttributeModal.dismiss('Cross click'); attributeType = 'general'">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body" style="border-bottom: 1px solid #e9ecef;">

        <div class='parent'>
            <div class='child' style="border-radius: 6px;
        border: 2px solid lightgray; display: inline-flex;
        height: 30px; width: 150px; padding-right: 3px;">
                <div class="gentypattr" [ngClass]="{'generalAttributes' : this.attributeType == 'general'}"
                    (click)="manageAttribute('general')">General</div>
                <div class="custypattr" [ngClass]="{'customAttributes' : this.attributeType == 'custom'}"
                    (click)="manageAttribute('custom')">Custom</div>
            </div>
        </div>

        <ng-template [ngIf]="attributeType == 'general'" [ngIfElse]="elseBlock">
            <app-general-attribute-popup [currency]="currency"
                [generalAttributes]="generalAttributes" 
                [showHideStatusOfGeneralAttributes] = "showHideStatusOfGeneralAttributes"
                (updateGeneralAttrsStatus)="updateGeneralAttrsStatusOnChange($event)"></app-general-attribute-popup>
        </ng-template>
        <ng-template #elseBlock>
            <div class="d-flex justify-content-end" style="margin-right: 10px;font-weight: 500 ;margin-top: -30px; ">
                <label style="margin-right: 10px;">{{ translateService.getLabel("select_valuation_date") }}</label>
                <mat-form-field class="border-sqr" style="margin-top: -5px;">
                    <mat-select [(ngModel)]="selectedValuationDateId" (ngModelChange)="changeDate()">
                        <mat-option *ngFor="let vd of invSummaryService.allValuationDates" [value]="vd.id"
                            style="margin-top:10px;">
                            {{vd.valuationDate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="model-content" style="padding-top: 20px;">
                <div class="row" style="font-weight: 600;">
                    <div class="col" style="padding-bottom: 10px;"><span>Show / Hide Attribute</span></div>
                    <div class="col-5"><span>Input</span></div>
                </div>
                <div class="row marginBottom">
                    <div class="col"></div>
                    <div class="col"></div>
                    <div class="col-1 " style="text-align: end;"></div>
                </div>
                <div class="row marginBottom" *ngFor="let row of customAttributes; let i=index">
                    <div class="col-2 chck-align">
                        <mat-checkbox [checked]="row.show" (change)="row.show = !row.show"></mat-checkbox>
                    </div>
                    <div class="col" *ngIf="i < 4">{{translateService.getLabel(row.attributeName)}}</div>
                    <div class="col" *ngIf="i >= 4">
                        <mat-form-field class="width-100">
                            <input matInput (input)="validateCustomAttributeName(row, i)" type="text" placeholder="Enter Title"
                                [(ngModel)]="row.attributeName" [matAutocomplete]="auto"
                                (ngModelChange)="preparefilteredAttributesList(row, i)">

                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredAttributeOptions"
                                    (click)="row.attributeName = option.label; validateCustomAttributeName(row, i); preparefilteredAttributesList(null, null)"
                                    style="white-space: normal; line-height: inherit; height: auto; margin: 5px 0;">
                                    {{option.label}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <ng-template [ngIf]="row.attributeName == '' || row.attributeName == null" [ngIfElse]="checkDuplication">
                            <div style="text-align: left; color: red">
                                <span>{{translateService.getLabel("attribute_name_cant_be_blank")}}</span>
                            </div>
                        </ng-template>

                        <ng-template #checkDuplication>
                            <ng-template [ngIf]="row.showDuplicateMsg">
                                <div style="text-align: left; color: red">
                                    <span>{{translateService.getLabel("qx_duplicate_attribute_name_found")}}</span>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>

                    <div class="col-4">
                        <mat-form-field class="width-100">
                            <input matInput type="text" [(ngModel)]="row.attributeValue" placeholder="Enter Value">
                        </mat-form-field>
                    </div>
                    <div class="col-1 custAttr-check" *ngIf="i < 4"></div>
                    <div class="col-1 custAttr-check" *ngIf="i >= 4">
                        <span>
                            <i class="far fa-trash-alt" (click)="deleteCustomAttribute(i);"></i>
                        </span>
                    </div>
                    <!-- <div class="col-2 custAttr-check checkboxAlignment">
                    <mat-checkbox class="example-margin" [(ngModel)]="row.show">
                    </mat-checkbox>
                </div> -->
                </div>
                <ng-template [ngIf]="invalidCharLength == true">
                    <div class="col" style="text-align: left; color: red ; margin-left: 7vw;">
                        <span>{{translateService.getLabel("attribute_name_should_not_be_more_than_255_characters")}}</span>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </div>
    <div class="row modal-footer justify-content-between" style="border: none;">
        <div class="col">
            <ng-template [ngIf]="attributeType == 'custom'">
                <button mat-stroked-button class="color-blue p-button"
                    [disabled]="customAttributes.length == MAX_ALLOWED" (click)="addNewCustomAttribute()">
                    {{ translateService.getLabel("add_custom_attribute") }}
                </button>
            </ng-template>
        </div>
        <div class="col-2">
            <ng-template [ngIf]="attributeType == 'custom'" [ngIfElse] = "generalAttribute">
                <button mat-stroked-button class="primary-bg p-button" [ngClass]="{'greyOut': checkSaveBtnDisableState() || invalidCharLength}" 
                    [disabled]="checkSaveBtnDisableState() || invalidCharLength"
                    (click)="saveCustomAttribute(); customAttributesRows = []; customAttributeModal.dismiss('Cross click'); attributeType = 'general'">
                    {{ translateService.getLabel("save") }}
                </button>
            </ng-template>
            <ng-template #generalAttribute>
                <button mat-stroked-button class="primary-bg p-button" [ngClass]="{'greyOut': checkSaveBtnDisableState() || invalidCharLength}"
                    [disabled]="checkSaveBtnDisableState() || invalidCharLength"
                    (click)="saveGeneralAttributeStatus(); customAttributesRows = []; customAttributeModal.dismiss('Cross click'); attributeType = 'general'">
                    {{ translateService.getLabel("save") }}
                </button>
            </ng-template>
        </div>
    </div>
</ng-template>