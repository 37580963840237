import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {

  constructor(private datePipe: DatePipe) { }

  getDateYYYYMMDD(date: Date) {
    let dateStr = date.getFullYear() + "-";

    const month = date.getMonth() + 1;

    if(month < 10) {
      dateStr += "0";
    }

    dateStr += month + "-";

    const dayInMonth = date.getDate();

    if(dayInMonth < 10) {
      dateStr += "0";
    }

    dateStr += dayInMonth;

    return dateStr;
  }

  convertDDMMYYYYToDate(date) {

    if (date) {
      const dateParts = date.toString().split("-");
      const newDate = new Date()
      newDate.setFullYear(+dateParts[0])
      newDate.setMonth(+dateParts[1] - 1)
      newDate.setDate(+dateParts[2])
      return newDate;

    }
    
  }

  getDateAndTimeMMMMdyyyyhmma(){
    let currentDate = this.datePipe.transform(new Date(), 'MMMM d, yyyy h:mm:ss a');
    return currentDate;
  }

}
