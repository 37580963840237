<div style="margin-top: 60px;" >
    <app-product-integeration>
    </app-product-integeration>
</div>

<div style="background-color: #f9f9f9;">
    
    <div class="row m-3">
        <div class="col-md-3">
            <span class="blue-header">Technical Integration</span>
        </div>
    </div>

    <div class="row m-3">
        <div class="col-md-3" *ngFor="let box of technicalIntegrations; let i=index" style="padding-right: 0.5vw; padding-left: 0.5vw;">
            <app-integration-tool [img]="box.img" [title]="box.title" [text]="box.text" [addTopMargin]="i >= 4">            
            </app-integration-tool>
        </div>
    </div>
    
    <div class="row m-3">
        <div class="col-md-3">
            <span class="blue-header">Product Integration</span>
        </div>
    </div>

    <div class="row m-3">
        <div class="col-md-3" *ngFor="let box of functionalIntegration; let i=index" style="padding-right: 0.5vw; padding-left: 0.5vw;">
            <app-integration-tool [img]="box.img" [title]="box.title" [text]="box.text" [addTopMargin]="i >= 4">            
            </app-integration-tool>
        </div>
    </div>
</div>