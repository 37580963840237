import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { CCAService } from '../cca/cca.service';
// import { ValuationService } from '../valuation/valuation.service';

@Injectable({
  providedIn: 'root'
})

export class CapTable2Service {

  public capTable2StakePer = new Subject();
  latestCompanyValuationDate

  public diluteStake = {
    diluteStakesPercentage : {
      manPackStakePer : null, //Implied Stack from Man Pack
      slSummaryStakePer: null, //implied Stake from Waterfall
      capTable2StakePer: null, //Diluted Stake
      manOptionStakePer: null, // Management Option Diluted Stake
      manOptionsExist:null,
      manPackExists: null
    },
    diluteStakesFullData : {
      manPackStakePer : null,
      slSummaryStakePer: null,
      capTable2StakePer: null,
      manOptionStakePer: null,
      manOptionsExist:null,
    }
  }

  widgetKeyDiluteStake = 'DILUTED_STAKE';

  isStakeReady = false;

  capTable2WidgetKey = "CAP_TABLE2_WIDGET";

  capTable2 = {
    table: [],
    columnUpdates: [],
    addToSummary: {},
    tableFormattedData: {
      table1: [],
      table2: []
    },
    waterfallData: {
      lpInput: {},
      lpOutput: null,
      companyId: null
    }
  }

  newWaterfallData = {
    lpInput: {},
    lpOutput: null,
    companyId: null
  }

  public isCapTable2DataAvailable = new Subject();

  constructor(
    private ds: DataService,
    private msgService: MessageService,
    private ccaService: CCAService,
    // public valuationService: ValuationService
  ) { }

  saveDiluteStakeValue() {
    this.ds.saveWidgetToDBAsync(this.widgetKeyDiluteStake, this.diluteStake,this.latestCompanyValuationDate.id);
  }

  getDilutedStakeValue() {
    return this.ds.getWidgetDataFromDB(this.widgetKeyDiluteStake, this.latestCompanyValuationDate.id);
  }

  setlatestCompanyValuationDate(data){
    this.latestCompanyValuationDate = data;
  }

  getlatestCompanyValuationDate(){
    return this.latestCompanyValuationDate
  }

  async init() {
    try {
      this.latestCompanyValuationDate = this.getlatestCompanyValuationDate();

      try {
        let resultCapTable2 = await this.ds.getWidgetDataFromDB(this.capTable2WidgetKey,this.latestCompanyValuationDate.id).toPromise();
      this.capTable2 = resultCapTable2.body["response"][0].widgetData;
      this.isCapTable2DataAvailable.next(true);
      } catch (error) {
        console.log("Failed to read " + this.capTable2WidgetKey, error);
      }

      if(!this.capTable2.addToSummary) {
        this.capTable2.addToSummary = {};
      }

      try {
        let resultDilutedStake = await this.getDilutedStakeValue().toPromise();
        this.diluteStake = JSON.parse(JSON.stringify(resultDilutedStake.body["response"][0].widgetData));

        if(this.diluteStake.diluteStakesPercentage.manPackExists == undefined){
          const res = await this.ds.getWidgetDataFromDB(this.ccaService.widgeKeyPageData,this.latestCompanyValuationDate.id).toPromise();
          let pageInitData = { ...res.body["response"][0].widgetData };
          this.diluteStake.diluteStakesPercentage.manPackExists = pageInitData.isManpackExists;
        }

        this.isStakeReady = true;
        this.msgService.publish('DILUTE_STAKE_READY', true);
      } catch (error) {
        console.log("Failed to read " + this.widgetKeyDiluteStake, error);
      }
    // this.fetchRollOverStake();
    } catch (error) {
      console.log("failed to initiliaze captable", error)
    }
  }

  slsDilutedStakeUpdate() {
    if(this.capTable2.waterfallData.lpOutput) {
      const updatedData = this.capTable2.waterfallData.lpOutput[this.capTable2.waterfallData.lpOutput.length - 1];
      if(updatedData.length !== 0 && this.diluteStake.diluteStakesPercentage.slSummaryStakePer) {
        let result = this.diluteStake.diluteStakesFullData.slSummaryStakePer.map(element => {
          const slsInd = updatedData.footer?.navTotal.findIndex(data => data.shareHolderName == element.shareHolderName);
          if (slsInd !== -1) {
            return { 'nav': this.fetchNavWithClassName(updatedData.distribution, slsInd), ...updatedData.footer?.navTotal[slsInd] };
          }
        });
        const sum = result.reduce((val, ele1) =>{
          return val + ele1.impliedDilutedStake;
        }, 0);
        this.diluteStake.diluteStakesPercentage.slSummaryStakePer = sum;
        this.diluteStake.diluteStakesFullData.slSummaryStakePer = result;
        this.saveDiluteStakeValue();
        // this.ccaService.slSummaryStakePer.next(sum);
      }
    }
  }

  getManPackStake(){
    return this.diluteStake.diluteStakesPercentage.manPackStakePer
      ? this.diluteStake.diluteStakesPercentage.manPackStakePer : 0;
  }

  getManPackOptionStake(){
    return this.diluteStake.diluteStakesPercentage.manOptionStakePer
      ? this.diluteStake.diluteStakesPercentage.manOptionStakePer : 0;
  }

  getManPackStakeIsSelected(){
    return this.diluteStake.diluteStakesPercentage.manPackExists
      ? this.diluteStake.diluteStakesPercentage.manPackExists : null;
  }

  getManPackOptionStakeIsSelected(){
    return this.diluteStake.diluteStakesPercentage.manOptionsExist
      ? this.diluteStake.diluteStakesPercentage.manOptionsExist : null;
  }

  getImpliedStakeFromWaterFall(){
    return this.diluteStake.diluteStakesPercentage.slSummaryStakePer
      ? this.diluteStake.diluteStakesPercentage.slSummaryStakePer : 0;
  }

  getDilutedStake(){
    return this.diluteStake.diluteStakesPercentage.capTable2StakePer
      ? this.diluteStake.diluteStakesPercentage.capTable2StakePer : 0;
  }

  async fetchRollOverStake() {
    try {
      const widgetKeyDiluteStake = await this.ds.getWidgetDataFromDB(this.widgetKeyDiluteStake,this.latestCompanyValuationDate.id).toPromise();
      if (widgetKeyDiluteStake && widgetKeyDiluteStake.ok) {
        let resultStake = widgetKeyDiluteStake.body["response"][0].widgetData;
        try {
          if (resultStake.diluteStakesPercentage.manPackStakePer) {
            const res = await this.ds.getWidgetDataFromDB(this.ccaService.widgeKeyPageData,this.latestCompanyValuationDate.id).toPromise();
            let pageInitData = { ...res.body["response"][0].widgetData };
            const key = (pageInitData.isExcelTabActive) ? this.ccaService.widgetkeyExcel : this.ccaService.widgetKey;
            const manPackResult = await this.ds.getWidgetDataFromDB(key,this.latestCompanyValuationDate.id).toPromise();
            if (pageInitData.isExcelTabActive) {
              resultStake.diluteStakesPercentage.manPackStakePer = manPackResult.body["response"][0].widgetData.excelAnalysis.dilutedStakePostManpack;
              resultStake.diluteStakesPercentage.manPackExists = pageInitData.isManpackExists
            } else {
              resultStake.diluteStakesPercentage.manPackStakePer = manPackResult.body["response"][0].widgetData.analysis.analysisData.dilutedStakeAfterManpack;
              resultStake.diluteStakesPercentage.manPackExists = pageInitData.isManpackExists
            }
            resultStake.diluteStakesFullData.manPackStakePer = null;
          }
        } catch (e) { console.error(e) }
        try {
          if (resultStake.diluteStakesPercentage.capTable2StakePer || resultStake.diluteStakesPercentage.slSummaryStakePer) {
            const CaptableResult = await this.ds.getWidgetDataFromDB(this.capTable2WidgetKey,this.latestCompanyValuationDate.id).toPromise();
            let captableFullData = CaptableResult.body["response"][0].widgetData;
            if (resultStake.diluteStakesPercentage.capTable2StakePer) {
              const resultCaptableStake = resultStake.diluteStakesFullData.capTable2StakePer.map(element => {
                const ctInd = captableFullData.tableFormattedData.table1.findIndex(ele => element.Shareholder == ele.Shareholder)
                if (ctInd !== -1) {
                  return captableFullData.tableFormattedData.table1[ctInd];
                }
              });
              resultStake.diluteStakesFullData.capTable2StakePer = JSON.parse(JSON.stringify(resultCaptableStake));
              resultStake.diluteStakesPercentage.capTable2StakePer = resultStake.diluteStakesFullData.capTable2StakePer.reduce((val, ele) => {
                return val + ele['% Stake'];
              }, 0);
            }
            if (resultStake.diluteStakesPercentage.slSummaryStakePer) {
              let slsData = captableFullData.waterfallData.lpOutput[captableFullData.waterfallData.lpOutput.length - 1];
              const resultslsStake = resultStake.diluteStakesFullData.slSummaryStakePer.map(element => {
                const slsInd = slsData.footer?.navTotal.findIndex(data => data.shareHolderName == element.shareHolderName);
                if (slsInd !== -1) {
                  return { 'nav': this.fetchNavWithClassName(slsData.distribution, slsInd), ...slsData.footer?.navTotal[slsInd] };
                }
              })
              resultStake.diluteStakesFullData.slSummaryStakePer = JSON.parse(JSON.stringify(resultslsStake));
              resultStake.diluteStakesPercentage.slSummaryStakePer = resultStake.diluteStakesFullData.slSummaryStakePer.reduce((val, ele) => {
                return val + ele.impliedDilutedStake;
              }, 0);
            }
          }
        } catch (e) { console.error(e) }
        this.diluteStake = resultStake;
        this.ds.saveWidgetToDBAsync(this.widgetKeyDiluteStake, resultStake, this.latestCompanyValuationDate.id);
      }
    }
    catch (e) { console.error(e) }
  }

  fetchNavWithClassName = (data, index)=> {
    let navArr = [];
    data.forEach(element => {
      navArr.push({className: element.shareType,nav: element.nav[index].nav});
    });
    return navArr;
  }
}
