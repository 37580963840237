<div class="row box-shadow">
  <div class="col-5 ml-5 mt-mb-25">
    <img width="50" [src]="loaderService.getImage('73_Strings_logo_blueUE.png')" class="company-logo" alt="">
  </div>
  <div class="col-6 flex-reverse">
    <a target="_blank" href="/assets/static/73StringsSupport.htm">
      Support Center<i class="fas fa-headset ml-2 mr-2"></i><i class="fas fa-chevron-down"></i>
    </a>
  </div>
</div>
<div class="ml-5 mt-5" *ngIf="showPageOne">
  <h1 class="green-color">All Systems are Operational</h1>
  <br>
  <div>
    <div class="row ml-1 tb-hd">
      <h4>73 Strings Products Status</h4>
    </div>
    <div class="row mt-5">
      <div class="col-2 d-flex flex-column">
        <span class="mb-1">Qubit X - Equity</span>
        <span class="mb-1">Qubit X - Debt</span>
        <span class="mb-1">Qubit X - Monitoring</span>
      </div>
      <div class="col-2 d-flex flex-column">
        <span class="mb-1"><i class="fas fa-check mr-2 green-color"></i>No Issue Detected!</span>
        <span class="mb-1"><i class="fas fa-check  mr-2 green-color"></i>No Issue Detected!</span>
        <span><i class="fas fa-check  mr-2 green-color"></i>No Issue Detected!</span>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="row ml-1 tb-hd">
      <h4>Past Incidents</h4>
    </div>
    <div class="row mt-3 ml-2" *ngFor="let item of data">
      <div class="col-2 d-flex flex-column">
        <span class="mb-1 border-bottom-grey">{{item.date}}</span>
        <span class="grey-color">{{item.tag}}</span>
      </div>
    </div>
  </div>
  <div class="row mt-3 ml-2 blue-color cursor-pointer">
    <a href="javascript:void(0);" (click)="clkIncidentHistory()"> Incident History >> </a>
  </div>
</div>

<!-- Page 2 -->
<div class="ml-5" *ngIf="!showPageOne">
  <div class="row mt-3 ml-2 blue-color cursor-pointer">
    <a href="javascript:void(0);" (click)="clkReturnStatus()">
      << Return to Status Page </a>
  </div>
  <div class="mt-5">
    <div class="row ml-1 tb-hd">
      <div class="col-6">
        <h4>Status History</h4>
        <div class="font-16 mt-4">
          <!-- Equity <i class="fas fa-angle-down ml-2 font-16"></i>  -->
          <select class="select-ddp">
            <option selected>Qubit X - Equity</option>
            <option>Qubit X - Debt</option>
            <option>Qubit X - Monitoring</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-3 ml-3" *ngFor="let item of yearTransaction">
      <div class="col-2 d-flex flex-column">
        <span class="mb-1 border-bottom-grey">{{item.date}}</span>
        <span class="grey-color">{{item.tag}}</span>
      </div>
    </div>
  </div>
</div>