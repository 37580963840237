import { Injectable } from '@angular/core';
import { UtilService } from '../utils/util.service';
import { DataService } from '../services/data.service';

@Injectable({
  providedIn: 'root'
})
export class CommentServiceService {
  comments = []
  commentsForValuationDate={}
  latestComment;

  valuationDatetoFetchComments;
  fetchComments = false
  constructor(private ds: DataService, private utilService: UtilService) { }

  getAllComments(key) {
    return this.comments.filter(commentsData => {
      if (commentsData.commentId == key) {
        return commentsData.comment
      }
    })
  }

  saveComment(body, filteredComments, edited) {
    let saveStatus
    let responseData
    this.ds.saveComment1(body).subscribe((success) => {
      saveStatus = "Comment saved successfully!"
      responseData = success.body["response"]
      if(!edited){
        this.comments.push(responseData);
      }
      filteredComments = this.comments;
      // console.log("Message save done", success)
    },
      (error) => {
        saveStatus = "Oops! Failed to save your comments. Please try later"
        console.log("Message save fail", error);
        this.utilService.showMessage("Failed to save comment", "OK")
      })
  }

  deleteComment(id){
    return  this.ds.deleteComment(id)
  }

  updateAddToReportComment(comment) {
    let response
    comment.addToReport = !comment.addToReport
    delete comment.new

    this.ds.updateAddToReportComment(comment).subscribe(success => {
      response = success;
    },
      error => {
        comment.addToReport = !comment.addToReport
        this.utilService.showMessage("Failed to add it to report", "OK")
      })
  }

  getReportComments() {
    return this.comments.filter(c => c.addToReport)
  }
}
