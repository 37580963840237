interface AttrMap {
  qxKey: string;
  qxLabel: string;
  gxKey: string;
  gxLabel: string;
  isGxGlobalAttribute: boolean;
  financialStatementType: string | null;
  financialComponentType: string | null;
  gxAttributeId: string;
  qxKeyOrder: number;
}

interface AttributeListDetails {
  name: string;
  uploadDate: string;
  userId: string;
  orgId: string;
  fundId: string;
  backwardLookingMappings: AttrMap[];
  forwardLookingMappings: AttrMap[];
}

interface NewAttributesMap extends AttributeListDetails {}

interface AttributeListName {
  fundId: string;
  name: string;
  excludeAttributeListId?: string;
}

enum ATTRIBUTES_LIST_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  READ = 'READ',
  REPLICATE = 'REPLICATE'
}

export { AttrMap, AttributeListDetails, NewAttributesMap, ATTRIBUTES_LIST_MODE, AttributeListName };