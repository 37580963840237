import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { cloneDeep } from 'lodash';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { InvestmentSummaryService } from '../qubit-x/portfolio/investment-page-summary-v2/investment-summary.service';
import { DataService } from '../services/data.service';
import { NavigationService } from '../services/navigation.service';
import { TranslateService } from '../services/translation.service';
import { colors } from '../utils/colors';
import { ToastService } from '../utils/toast.service';
import { UtilService } from '../utils/util.service';

@Component({
  selector: 'app-external-valuer',
  templateUrl: './external-valuer.component.html',
  styleUrls: ['./external-valuer.component.scss']
})
export class ExternalValuerComponent implements OnInit {

  @Input() latestCompanyValuationDate ;
  @Input() companyId ;
  

  
  columnChart;
  externalValuerList = [];
  externalValuer = {
    nameOfValuer: "",
    enterpriseValue: {
      low: undefined,
      high: undefined
    },
    equityValue: {
      low: undefined,
      high: undefined
    },
    comment: "",
    valuationDate:""
  };

  fundId;
  formData
  externalValuerStakeArray = [];
  externalValuerDatesArray = [];
  seriesData = [];
  uniqueNamesOfValuersList: string[] = [];

  externalIndex = undefined;
  externalName;
  enterpriseLow;
  enterpriseHigh;
  equityLow;
  equityHigh;
  externalComment;

  constructor(
    public ds: DataService,
    private datePipe: DatePipe, 
    private route: ActivatedRoute,
    private investmentSummaryService: InvestmentSummaryService,
    private utilService:UtilService, 
    private toastService:ToastService,
    private navService: NavigationService, 
    public translateService: TranslateService, 
    public fundService:FundListService
  ) { }

   ngOnInit(){
    this.getFormDetails()
    this.fundId = this.route.snapshot.queryParamMap.get('parentId');
      this.ds.getWidgetDataFromDB("EXTERNAL_VALUER", this.latestCompanyValuationDate.id).subscribe(res => {
        this.externalValuerList = res.body["response"][0].widgetData;
      })
      this.initTrackerChart();
      this.clearFormContent();
  }

    async getFormDetails() {
      let data = await this.ds.getFormData(this.latestCompanyValuationDate.id, 4).toPromise()
      this.formData = data.body["response"].formData;
      this.externalValuer.valuationDate = this.formData.valuationDate
    }

  initTrackerChart() {
    this.externalValuerStakeArray = [];
    this.externalValuerDatesArray = [];
    this.seriesData = [];
    this.uniqueNamesOfValuersList = [];

    this.ds.getDataForExternalValuer(this.latestCompanyValuationDate.id).subscribe(res => {
      let sortedDates = Object.entries(res["body"]["response"]).filter(valuationDate => valuationDate[1]["valuationDate"] <= this.formData.valuationDate);
      sortedDates.sort((a, b) => {
        return new Date(a[1]["valuationDate"]).valueOf() - new Date(b[1]["valuationDate"]).valueOf()
      });

      const valuationDates = sortedDates;
      let allUniqueValuers: Set<string> = new Set();

      valuationDates.forEach(valdate => {
        let stakeValue = valdate[1]["stake_value"];

        if(valdate[0] === this.latestCompanyValuationDate.id && this.investmentSummaryService && this.investmentSummaryService.equityValue.finalAdjustedEquityVal) {
          stakeValue = this.investmentSummaryService.equityValue.finalAdjustedEquityVal;
        }

        this.externalValuerStakeArray.push(stakeValue);
        this.externalValuerDatesArray.push(this.datePipe.transform(valdate[1]["valuationDate"], 'MMM y'));

        let externalValuerExist = valdate[1]["external_valuers"] && valdate[1]["external_valuers"].length > 0;

        if(externalValuerExist) {
          valdate[1]["external_valuers"].forEach(v => {
            allUniqueValuers.add(v.nameOfValuer);
          })
        }
      });

      this.uniqueNamesOfValuersList = Array.from(allUniqueValuers);

      let valuerSeriesData = {}

      this.uniqueNamesOfValuersList.forEach((valuer, valuerIndex) => {
        valuerSeriesData[valuer + "-max"] = {
          name: valuer + " (High)",
          data: [], stack: valuer,
          tooltip: {
            enabled: true,

            formatter: function() {
              return "Valuer (High) " + this.y.toFixed(2);
            }
          }
        };
        valuerSeriesData[valuer + "-min"] =
        {
            name: valuer + " (Low)",
            data: [], stack: valuer, color: "#FFFFFF00",
            tooltip: {
              enabled: true,

              formatter: function() {
                return "Valuer (Low) " + this.y.toFixed(2);
              }
            }
        };

        valuationDates.forEach(valdate => {

          let externalValuerExist = valdate[1]["external_valuers"] && valdate[1]["external_valuers"].length > 0;
          externalValuerExist = externalValuerExist && valdate[1]["external_valuers"].find( v => v.nameOfValuer === valuer);

          if (externalValuerExist) {
            const externalValuer = valdate[1]["external_valuers"].find( v => v.nameOfValuer === valuer);

            let equityValue = externalValuer["equityValue"];

            valuerSeriesData[valuer + "-min"].data.push(equityValue.low);
            valuerSeriesData[valuer + "-max"].data.push(equityValue.high - equityValue.low);
          }
          else {
            valuerSeriesData[valuer + "-min"].data.push(null);
            valuerSeriesData[valuer + "-max"].data.push(null);
          }

        })
      })

      if(this.uniqueNamesOfValuersList && this.uniqueNamesOfValuersList.length > 0) {
        this.seriesData = this.seriesData.concat(Object.values(valuerSeriesData));
      }

      let fundName =  this.fundService.getFundName(this.fundId)

      this.seriesData.push({
        type: 'spline',
        name: fundName || "NAV",
        data: this.externalValuerStakeArray,
        marker: {
          lineWidth: 2,
          lineColor: colors.chartColorsArrayV2[1],
          fillColor: 'blue'
        }
      })
      this.prepareChart();
    });
  }

  prepareChart() {
    const __this = this;

    this.columnChart = new Chart({
      chart: { type: 'column'},
      lang: {
        contextButtonTitle: this.translateService.getLabel("chart_context_menu")
      },
      title: { text: ''},
      xAxis: {
        visible: true,
        categories: this.externalValuerDatesArray
      },
      yAxis: {
        min: 0,
        title: { text: this.translateService.getLabel("equity") + " (" + __this.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY+ " Mn)" },
      },

      legend: {
        enabled: true,

        labelFormatter: function () {
          const highIndex = this.name.indexOf("(High)");
          const lowIndex = this.name.indexOf("(Low)");

          if(highIndex > 0) {
            const valuer = this.name.substring(0, highIndex);
            return valuer;

          } else if(lowIndex < 0) {
            //Legend for line series
            return this.name;
          }

          //if valuer (low), series, don't display the legend.
        },
      },

      exporting: {
        enabled: true,
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.getLabel("view_in_full_screen")
          },
          printChart: {
            text: this.translateService.getLabel("print_chart")
          },
          downloadPNG: {
            text: this.translateService.getLabel("download_png_image")
          },
          downloadJPEG: {
            text: this.translateService.getLabel("download_jpeg_image")
          },
          downloadSVG: {
            text: this.translateService.getLabel("download_svg_vector_image")
          },
          downloadPDF: {
            text: this.translateService.getLabel("download_pdf")
          }
        },
        buttons: {
          contextButton: {
            menuItems: [
              'viewFullscreen',
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG'
            ]
          }
        }
      },
      credits: { enabled: false },
      tooltip: {
        enabled: true ,

        formatter: function() {
          const highIndex = this.series.name.indexOf("(High)");
          if(highIndex > 0) {
            const valuer = this.series.name.substring(0, highIndex);
            const lowValueSeries = __this.seriesData.find(s => s.name == valuer + "(Low)");

            if(lowValueSeries) {
              return (this.y + lowValueSeries.data[this.point['index']]).toFixed(1);
            }
          }

          return this.y.toFixed(1);
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            inside: false,
            // color: "#000000",
            // borderRadius: 5,
            // backgroundColor: '#FFFFFF',
            // style: { textOutline: 'none' },

            formatter: function() {
              const highIndex = this.series.name.indexOf("(High)");
              if(highIndex > 0) {
                const valuer = this.series.name.substring(0, highIndex);
                const lowValueSeries = __this.seriesData.find(s => s.name == valuer + "(Low)");

                if(lowValueSeries) {
                  return Number((this.y + lowValueSeries.data[this.point['index']]).toFixed(1)).toLocaleString('en-US');
                }
              }

              return Number(this.y.toFixed(1)).toLocaleString('en-US');
            }
          }
        },
        series: {
          cursor: 'pointer',
          stacking: 'normal',

          dataLabels: {
            enabled: true,
            // style: { textOutline: 'none' },
            // borderRadius: 5,
            // backgroundColor: '#FFFFFF',
            // color: "#000000",

            formatter: function() {
              return this.y > 0 ? Number(this.y.toFixed(1)).toLocaleString('en-US') : null;
            }
          },
        }
      },
      series: this.seriesData,
    });
  }

  editExternalValuer(externalValuer, index) {
    this.externalIndex = index;
    this.externalName = externalValuer.nameOfValuer;
    this.enterpriseLow = externalValuer.enterpriseValue.low;
    this.enterpriseHigh = externalValuer.enterpriseValue.high;
    this.equityLow = externalValuer.equityValue.low;
    this.equityHigh = externalValuer.equityValue.high;
    this.externalComment = externalValuer.comment;
  }

  deleteExternalValuer(index) {
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("Info_sure_delete?"), (this.translateService.getLabel("yes")), (this.translateService.getLabel("no")));
    confirm.afterClosed().subscribe(status => {
      if (status === "Yes") {
        this.externalValuerList.splice(index, 1);
        this.save();
      };
    });
  }

  saveExternalValuer(valuerData){
    if(this.externalIndex == undefined){
      let obj = cloneDeep(this.externalValuer);
      obj.nameOfValuer = valuerData.externalName;
      obj.enterpriseValue.low = valuerData.enterpriseLow;
      obj.enterpriseValue.high = valuerData.enterpriseHigh;
      obj.equityValue.low = valuerData.equityLow;
      obj.equityValue.high = valuerData.equityHigh;
      obj.comment = valuerData.externalComment;
      this.externalValuerList.push(obj);
    }
    else{
      let obj = cloneDeep(this.externalValuer);
      obj.nameOfValuer = valuerData.externalName;
      obj.enterpriseValue.low = valuerData.enterpriseLow;
      obj.enterpriseValue.high = valuerData.enterpriseHigh;
      obj.equityValue.low = valuerData.equityLow;
      obj.equityValue.high = valuerData.equityHigh;
      obj.comment = valuerData.externalComment;
      this.externalValuerList[this.externalIndex] = obj;
    }

    this.save();
    this.externalIndex = undefined;
    setTimeout(() => {
      this.clearFormContent();
    })
    this.saveSuccessfullyMessage();
  }

  save() {
    this.ds.saveWidgetDataToDB("EXTERNAL_VALUER", this.externalValuerList, this.latestCompanyValuationDate.id ).subscribe(res => {
      this.initTrackerChart();
    }, error => {
      console.log("Error while saving External Valuer data", error);
    });
  }

  
  clearFormContent(){
    this.externalName = '';
    this.enterpriseLow = undefined;
    this.enterpriseHigh = undefined;
    this.equityLow = undefined;
    this.equityHigh = undefined;
    this.externalComment = '';
  }

  saveSuccessfullyMessage(){
    this.toastService.openSnackBar(this.translateService.getLabel("suc_saved"));
  }



}
