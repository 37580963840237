import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DataReconService } from '../data-recon.service';
import { DataReconFilterService } from '../data-recon-filter.service';
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { InSyncStatus, UserCompanyDetailsRecon } from '../data-recon';
import { Subscription, interval } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/utils/toast.service';

@Component({
  selector: 'app-data-recon-container',
  templateUrl: './data-recon-container.component.html',
  styleUrls: ['./data-recon-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataReconContainerComponent implements OnInit, OnDestroy {
  statusInterval = interval(15000);

  clientsFc = new FormControl();
  orgNameLookUp = {};

  financialsGeneralAndProfileDetails: boolean;
  attributeDetails: boolean;
  userCompanyDetails: boolean;
  userDetails: boolean;
  statusLoaded: boolean;
  snowflakeFinancialsGeneralAndProfileDetails: boolean;
  private commonSub = new Subscription();

  clients = [];
  selectedClient: string;

  finsAndGdDiff = [];
  snowflakeFinsAndGdDiff = [];
  attributeDetailsRecon = [];
  userCountRecon = [];
  userCompanyDetailsRecon = [];

  gxFinancialsGeneralDetailsAndProfileLookUp = {};
  reportingFinancialsGeneralDetailsAndProfileLookUp = {};

  gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp = {};
  snowflakeFinancialsGeneralDetailsAndProfileLookUp = {};

  gxAttrsLookUp = {};
  reportsAttrsLookUp = {};

  gxUserCountLookUp = {};
  reportingUserCountLookUp = {};

  gxUserCompanyDetailsLookUp = {};
  reportingUserCompanyDetailsLookUp = {};

  errorCountAcrossOrgs = 0;

  errorCountForFinsGdAndProfile = 0;
  errorCountForAtrributeDetailsRecon = 0;
  errorCountForUserCountRecon = 0;
  errorCountUserCompanyDetailsRecon = 0;
  errorCountForSnowflakeFinsGdAndProfile = 0;

  constructor(private dataReconService: DataReconService, dataReconFilterService: DataReconFilterService, private snackBarService: ToastService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.getDataFromAllOrgs();
    const orgIds = this.clients.map((c) => c['orgId']);
    this.triggerStatusPolling(orgIds);
    this.commonSub.add(
      this.dataReconService.inSyncStatus$
        .pipe(
          distinctUntilChanged((prev, curr) => this.checkIfStatusChanged(prev, curr))
          // distinctUntilKeyChanged('financialsGeneralAndProfileDetails')
        )
        .subscribe((inSyncStatusResp) => {
          console.log(inSyncStatusResp, 'inSyncStatusResp updated...');
          this.financialsGeneralAndProfileDetails = inSyncStatusResp.financialsGeneralAndProfileDetails;
          this.attributeDetails = inSyncStatusResp.attributeDetails;
          this.userCompanyDetails = inSyncStatusResp.userCompanyDetails;
          this.userDetails = inSyncStatusResp.userDetails;
          this.snowflakeFinancialsGeneralAndProfileDetails = inSyncStatusResp.financialsGeneralAndProfileDetails;
          this.cdr.detectChanges();
        })
    );
  }

  async getClients() {
    try {
      const clientsResponse = await this.dataReconService.getClients();
      const allorgs = clientsResponse.body['response'];
      this.orgNameLookUp = { ...allorgs };
      for (const orgId in allorgs) {
        this.clients.push({
          orgId,
          orgName: allorgs[orgId],
        });
      }
      console.log('All orgs', this.clients);
    } catch (error) {
      console.log(error, 'Error ocurred while getting client list');
    }
  }

  getDiffGdAndFins(orgIds: string[]) {
    this.dataReconService.getFinancialsAndGeneralDetailsDiff(orgIds).subscribe(
      (resp: any) => {
        console.log(resp, '<<<<<');
        const res = resp;
        let orgId = '';
        let orgName = '';
        res.forEach((r) => {
          console.log('For org', r.orgName);
          r.reporting.forEach((rp) => {
            orgId = r.orgId;
            orgName = r.orgName;
            // console.log('Assigning', r.orgName, 'to', rp, 'In reporting lookup');
            this.reportingFinancialsGeneralDetailsAndProfileLookUp[rp['companyId']] = {
              orgId: r.orgId,
              orgName: r.orgName,
              ...rp,
            };
          });
          // console.log(this.reportingFinancialsGeneralDetailsAndProfileLookUp, 'Reporting lookup', Object.keys(this.reportingFinancialsGeneralDetailsAndProfileLookUp).length);
          if (r.monitoring?.length > 0) {
            r.monitoring.forEach((mr) => {
              orgId = r.orgId;
              orgName = r.orgName;
              // console.log('Assigning', r.orgName, 'to', mr, 'In monitoring lookup');
              this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']] = {
                orgId: r.orgId,
                orgName: r.orgName,
                ...mr,
              };

              // console.log(mr['companyId'] in this.reportingFinancialsGeneralDetailsAndProfileLookUp, mr, 'Company ID', r);
              if (mr['companyId'] in this.reportingFinancialsGeneralDetailsAndProfileLookUp) {
                const currentCompanyFinancialRowDiff = this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'] - this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'];
                const currentCompanyGeneralDetailsRowsDiff = this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'] - this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'];
                const currentCompanyProfileDetailsRows = this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'] - this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'];

                this.finsAndGdDiff.push({
                  // ...this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']],
                  // orgName: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']['orgName']],
                  orgId: r.orgId,
                  orgName: r.orgName,
                  companyName: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['companyName'],
                  gxFinancialRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
                  reportsFinancialRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
                  gxGeneralDetailsRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
                  reportsGeneralDetailsRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
                  gxProfileDetailsRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
                  reportsProfileDetailsRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
                  financialRowsDiff: currentCompanyFinancialRowDiff,
                  generalDetailsRowsDiff: currentCompanyGeneralDetailsRowsDiff,
                  profileDetailsRowsDiff: currentCompanyProfileDetailsRows,
                });
                if (currentCompanyFinancialRowDiff !== 0 || currentCompanyGeneralDetailsRowsDiff !== 0 || currentCompanyProfileDetailsRows !== 0) {
                  this.errorCountForFinsGdAndProfile++;
                }
              } 
              // else {
              //   console.log('monitoring only', mr['companyId'], '>>>',(this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]), '?????', this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['companyId']);
              //   this.finsAndGdDiff.push({
              //     // ...this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']],
              //     // orgName: this.reportingFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']['orgName']],
              //     orgId: r.orgId,
              //     orgName: r.orgName,
              //     companyName: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']].companyName ? this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']['companyName']] : this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['companyId'],
              //     gxFinancialRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
              //     reportsFinancialRows: 0,
              //     gxGeneralDetailsRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
              //     reportsGeneralDetailsRows: 0,
              //     gxProfileDetailsRows: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
              //     reportsProfileDetailsRows: 0,
              //     financialRowsDiff: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
              //     generalDetailsRowsDiff: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
              //     profileDetailsRowsDiff: this.gxFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
              //     isCompanyMissingInReporting: true,
              //   });
              //   // missing a company in reports so bump up error count
              //   this.errorCountForFinsGdAndProfile++;
              // }
            });
          }
          //if a company exists in reporting and not in monitoring add to table data source
          // r.reporting.forEach((reportingCompany) => {
          //   // console.log('Reporting company...', reportingCompany, Object.keys(this.gxFinancialsGeneralDetailsAndProfileLookUp)?.length);
          //   if (Object.keys(this.gxFinancialsGeneralDetailsAndProfileLookUp)?.length === 0 || !(reportingCompany['companyId'] in this.gxFinancialsGeneralDetailsAndProfileLookUp)) {
          //     // console.log(reportingCompany['companyId'], 'not found in monitoring');
          //     this.finsAndGdDiff.push({
          //       orgId: r.orgId,
          //       orgName: r.orgName,
          //       ...reportingCompany,
          //       gxFinancialRows: 0,
          //       reportsFinancialRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['financialRows'],
          //       gxGeneralDetailsRows: 0,
          //       reportsGeneralDetailsRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['generalDetailsRows'],
          //       gxProfileDetailsRows: 0,
          //       reportsProfileDetailsRows: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['profileDetailsRows'],
          //       financialRowsDiff: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['financialRows'],
          //       generalDetailsRowsDiff: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['generalDetailsRows'],
          //       profileDetailsRowsDiff: this.reportingFinancialsGeneralDetailsAndProfileLookUp[reportingCompany['companyId']]['profileDetailsRows'],
          //       isCompanyMissingInMonitoring: true,
          //     });
          //     this.errorCountForFinsGdAndProfile++;
          //   }
          // });
        });
        console.log(this.gxFinancialsGeneralDetailsAndProfileLookUp, 'monitoring lookup...');
        console.log(this.reportingFinancialsGeneralDetailsAndProfileLookUp, 'reports lookup...');
        console.log(this.finsAndGdDiff, '?????');
        this.finsAndGdDiff = [...this.finsAndGdDiff];
        // this.finsAndGdDiff = res.response.map((r) => {

        // });

        this.cdr.detectChanges();
      },
      (err) => {
        console.log(err);
        this.snackBarService.openSnackBar('Failed to get diff for financials, general details and profile.');
      }
    );
  }

  getSnowflakeDiffGdAndFins(orgIds: string[]){  
    this.dataReconService.getSnowflakeDiffGdAndFins(orgIds).subscribe(
      (resp: any) => {
        console.log(resp, '<<<<<');
        const res = resp;
        let orgId = '';
        let orgName = '';
        res.forEach((r) => {
          console.log('For org', r.orgName);
          r.reporting.forEach((rp) => {
            orgId = r.orgId;
            orgName = r.orgName;
            this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[rp['companyId']] = {
              orgId: r.orgId,
              orgName: r.orgName,
              ...rp,
            };
          });
          if (r.monitoring?.length > 0) {
            r.monitoring.forEach((mr) => {
              orgId = r.orgId;
              orgName = r.orgName;
              this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']] = {
                orgId: r.orgId,
                orgName: r.orgName,
                ...mr,
              };

              if (mr['companyId'] in this.snowflakeFinancialsGeneralDetailsAndProfileLookUp) {
                const currentCompanyFinancialRowDiff = this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'] - this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'];
                const currentCompanyGeneralDetailsRowsDiff = this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'] - this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'];
                const currentCompanyProfileDetailsRows = this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'] - this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'];

                this.snowflakeFinsAndGdDiff.push({
                  orgId: r.orgId,
                  orgName: r.orgName,
                  companyName: this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['companyName'],
                  gxFinancialRows: this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
                  reportsFinancialRows: this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['financialRows'],
                  gxGeneralDetailsRows: this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
                  reportsGeneralDetailsRows: this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['generalDetailsRows'],
                  gxProfileDetailsRows: this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
                  reportsProfileDetailsRows: this.snowflakeFinancialsGeneralDetailsAndProfileLookUp[mr['companyId']]['profileDetailsRows'],
                  financialRowsDiff: currentCompanyFinancialRowDiff,
                  generalDetailsRowsDiff: currentCompanyGeneralDetailsRowsDiff,
                  profileDetailsRowsDiff: currentCompanyProfileDetailsRows,
                });
                if (currentCompanyFinancialRowDiff !== 0 || currentCompanyGeneralDetailsRowsDiff !== 0 || currentCompanyProfileDetailsRows !== 0) {
                  this.errorCountForSnowflakeFinsGdAndProfile++;
                }
              }
            });
          }
        });
        console.log(this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp, 'monitoring lookup...');
        console.log(this.snowflakeFinancialsGeneralDetailsAndProfileLookUp, 'reports lookup...');
        console.log(this.snowflakeFinsAndGdDiff, '?????');
        this.snowflakeFinsAndGdDiff = [...this.snowflakeFinsAndGdDiff];
        this.cdr.detectChanges();
      },
      (err) => {
        console.log(err);
        this.snackBarService.openSnackBar('Failed to get snowflake diff for financials, general details and profile.');
      }
    );
  }

  getAttributesDiff(orgIds) {
    this.dataReconService.getAttributesDiff(orgIds).subscribe(
      (res) => {
        console.log('Attrs Diff', res);
        res.forEach((r) => {
          r.reporting.forEach((rp) => {
            this.reportsAttrsLookUp[rp['orgId']] = {
              orgId: r.orgId,
              orgName: r.orgName,
              ...rp,
            };
          });

          if (r.monitoring?.length > 0) {
            r.monitoring.forEach((mr) => {
              this.gxAttrsLookUp[mr['orgId']] = {
                orgId: r.orgId,
                orgName: r.orgName,
                ...mr,
              };

              console.log(mr['orgId'] in this.reportsAttrsLookUp, mr['orgId'], 'Org ID', this.reportsAttrsLookUp);
              if (mr['orgId'] in this.reportsAttrsLookUp) {
                const currentOrgGlobalAttrDiff = this.gxAttrsLookUp[mr['orgId']]['globalAttributes'] - this.reportsAttrsLookUp[mr['orgId']]['globalAttributes'];
                const currentOrgCustomAttrsDiff = this.gxAttrsLookUp[mr['orgId']]['customAttributes'] - this.reportsAttrsLookUp[mr['orgId']]['customAttributes'];
                const currentOrgAttributeFormulaDiff = this.gxAttrsLookUp[mr['orgId']]['attributeFormula'] - this.reportsAttrsLookUp[mr['orgId']]['attributeFormula'];
                const currentOrgAttributeFormulaDetailsDiff = this.gxAttrsLookUp[mr['orgId']]['attributeFormulaDetails'] - this.reportsAttrsLookUp[mr['orgId']]['attributeFormulaDetails'];
                const currentOrgAttributeFormulaCompaniesDiff = this.gxAttrsLookUp[mr['orgId']]['attributeFormulaCompanies'] - this.reportsAttrsLookUp[mr['orgId']]['attributeFormulaCompanies'];
                this.attributeDetailsRecon.push({
                  ...this.reportsAttrsLookUp[mr['orgId']],
                  gxGlobalAttributes: this.gxAttrsLookUp[mr['orgId']]['globalAttributes'],
                  reportsGlobalAttributes: this.reportsAttrsLookUp[mr['orgId']]['globalAttributes'],
                  gxCustomAttributes: this.gxAttrsLookUp[mr['orgId']]['customAttributes'],
                  reportsCustomAttributes: this.reportsAttrsLookUp[mr['orgId']]['customAttributes'],
                  gxAttributeFormula: this.gxAttrsLookUp[mr['orgId']]['attributeFormula'],
                  reportsAttributeFormula: this.reportsAttrsLookUp[mr['orgId']]['attributeFormula'],
                  gxAttributeFormulaDetails: this.gxAttrsLookUp[mr['orgId']]['attributeFormulaDetails'],
                  reportsAttributeFormulaDetails: this.reportsAttrsLookUp[mr['orgId']]['attributeFormulaDetails'],
                  gxAttributeFormulaCompanies: this.gxAttrsLookUp[mr['orgId']]['attributeFormulaCompanies'],
                  reportsAttributeFormulaCompanies: this.reportsAttrsLookUp[mr['orgId']]['attributeFormulaCompanies'],
                  globalAttrsDiff: currentOrgGlobalAttrDiff,
                  customAttrsDiff: currentOrgCustomAttrsDiff,
                  attributeFormulaDiff: currentOrgAttributeFormulaDiff,
                  attributeFormulaDetailsDiff: currentOrgAttributeFormulaDetailsDiff,
                  attributeFormulaCompaniesDiff: currentOrgAttributeFormulaCompaniesDiff,
                });
                if (currentOrgGlobalAttrDiff !== 0 || currentOrgCustomAttrsDiff !== 0 || currentOrgAttributeFormulaDiff !== 0 || currentOrgAttributeFormulaDetailsDiff !== 0 || currentOrgAttributeFormulaCompaniesDiff !== 0) {
                  this.errorCountForAtrributeDetailsRecon++;
                }
              } else {
                console.log('org in monitoring only', mr['orgId']);
                this.attributeDetailsRecon.push({
                  ...this.reportsAttrsLookUp[mr['orgId']],
                  gxGlobalAttributes: this.gxAttrsLookUp[mr['orgId']]['globalAttributes'],
                  reportsGlobalAttributes: 0,
                  gxCustomAttributes: this.gxAttrsLookUp[mr['orgId']]['customAttributes'],
                  reportsCustomAttributes: 0,
                  gxAttributeFormula: this.gxAttrsLookUp[mr['orgId']]['attributeFormula'],
                  reportsAttributeFormula: 0,
                  gxAttributeFormulaDetails: this.gxAttrsLookUp[mr['orgId']]['attributeFormulaDetails'],
                  reportsAttributeFormulaDetails: 0,
                  gxAttributeFormulaCompanies: this.gxAttrsLookUp[mr['orgId']]['attributeFormulaCompanies'],
                  reportsAttributeFormulaCompanies: 0,
                });
                this.errorCountForAtrributeDetailsRecon++;
              }
            });
          }
          //if an attribute exists in reporting and not in monitoring add to table data source
          r.reporting.forEach((reportingAttribute) => {
            console.log('Reporting attribute...', reportingAttribute, Object.keys(this.gxAttrsLookUp)?.length);
            if (Object.keys(this.gxAttrsLookUp)?.length === 0 || !(reportingAttribute['orgId'] in this.gxAttrsLookUp)) {
              console.log(reportingAttribute['orgId'], 'not found in monitoring');
              this.attributeDetailsRecon.push({
                orgId: r.orgId,
                orgName: r.orgName,
                ...reportingAttribute,
                gxGlobalAttributes: 0,
                reportsGlobalAttributes: this.reportsAttrsLookUp[reportingAttribute['orgId']]['globalAttributes'],
                gxCustomAttributes: 0,
                reportsCustomAttributes: this.reportsAttrsLookUp[reportingAttribute['orgId']]['customAttributes'],
                gxAttributeFormula: 0,
                reportsAttributeFormula: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormula'],
                gxAttributeFormulaDetails: 0,
                reportsAttributeFormulaDetails: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormulaDetails'],
                gxAttributeFormulaCompanies: 0,
                reportsAttributeFormulaCompanies: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormulaCompanies'],
                globalAttrsDiff: this.reportsAttrsLookUp[reportingAttribute['orgId']]['globalAttributes'],
                customAttrsDiff: this.reportsAttrsLookUp[reportingAttribute['orgId']]['customAttributes'],
                attributeFormulaDiff: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormula'],
                attributeFormulaDetailsDiff: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormulaDetails'],
                attributeFormulaCompaniesDiff: this.reportsAttrsLookUp[reportingAttribute['orgId']]['attributeFormulaCompanies'],
              });
              this.errorCountForAtrributeDetailsRecon++;
            }
          });
        });

        this.attributeDetailsRecon = [...this.attributeDetailsRecon];
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Failed to get attributes diff...', err);
        this.snackBarService.openSnackBar('Failed to get attributes diff.');
      }
    );
  }

  getUserCountDiff(orgIds: string[]) {
    this.dataReconService.getUserCountsDiff(orgIds).subscribe(
      (userCountDiffResp: any) => {
        console.log(userCountDiffResp, 'User Details Diff response...');
        const userCountDiff = userCountDiffResp;
        userCountDiff.forEach((uco) => {
          uco.reporting.forEach((reportingOrg) => {
            this.reportingUserCountLookUp[reportingOrg['orgId']] = {
              ...reportingOrg,
              orgName: this.orgNameLookUp[reportingOrg['orgId']],
            };
          });

          uco.monitoring.forEach((monitoringOrg) => {
            this.gxUserCountLookUp[monitoringOrg['orgId']] = {
              ...monitoringOrg,
              orgName: this.orgNameLookUp[monitoringOrg['orgId']],
            };
            if (monitoringOrg['orgId'] in this.reportingUserCountLookUp) {
              const userCountDiff = this.gxUserCountLookUp[monitoringOrg['orgId']]['userCount'] - this.reportingUserCountLookUp[monitoringOrg['orgId']]['userCount'];
              this.userCountRecon.push({
                ...monitoringOrg,
                orgName: this.orgNameLookUp[monitoringOrg['orgId']],
                gxUserCount: this.gxUserCountLookUp[monitoringOrg['orgId']]['userCount'],
                reportingUserCount: this.reportingUserCountLookUp[monitoringOrg['orgId']]['userCount'],
                userCountDiff,
              });
              if (userCountDiff !== 0) {
                this.errorCountForUserCountRecon++;
              }
            } else {
              this.userCountRecon.push({
                ...monitoringOrg,
                orgName: this.orgNameLookUp[monitoringOrg['orgId']],
                gxUserCount: this.gxUserCountLookUp[monitoringOrg['orgId']]['userCount'],
                reportingUserCount: 0,
                userCountDiff: this.gxUserCountLookUp[monitoringOrg['orgId']]['userCount'],
              });
              this.errorCountForUserCountRecon++;
            }
          });
          uco.reporting.forEach((reportingOrg) => {
            // if org exits in reporting and not in monitoring add to table data source
            if (Object.keys(this.gxUserCountLookUp).length === 0 || !(reportingOrg['orgId'] in this.gxUserCountLookUp)) {
              this.userCountRecon.push({
                ...reportingOrg,
                orgName: this.orgNameLookUp[reportingOrg['orgId']],
                gxUserCount: 0,
                reportingUserCount: this.reportingUserCountLookUp[reportingOrg['orgId']]['userCount'],
                userCountDiff: this.reportingUserCountLookUp[reportingOrg['orgId']]['userCount'],
              });
              this.errorCountForUserCountRecon++;
            }
          });
        });
        console.log(this.gxUserCountLookUp, 'Gx user count');
        console.log(this.reportingUserCountLookUp, 'Report user count');
        console.log(this.userCountRecon, 'User count recon data source...');
        this.userCountRecon = [...this.userCountRecon];
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Failed to get user count diff', err);
        this.snackBarService.openSnackBar('Failed to get user count diff.');
      }
    );
  }

  getUserCompanyDetails(orgIds: string[]) {
    this.dataReconService.getUserCompanyDetailsDiff(orgIds).subscribe(
      (userCompanyDetailsResp) => {
        console.log(userCompanyDetailsResp, 'User company details');
        const userCompanyDetails: UserCompanyDetailsRecon[] = userCompanyDetailsResp;
        let orgId = '';
        let orgName = '';
        userCompanyDetails.forEach((ucd) => {
          orgId = ucd.orgId;
          orgName = ucd.orgName;
          ucd.reporting.forEach((ru) => {
            this.reportingUserCompanyDetailsLookUp[ru['userId']] = {
              ...ru,
              orgId,
              orgName,
            };
          });
          if (ucd.monitoring?.length > 0) {
            ucd.monitoring.forEach((mu) => {
              this.gxUserCompanyDetailsLookUp[mu['userId']] = {
                ...mu,
                orgId,
                orgName,
              };
              if (mu.userId in this.reportingUserCompanyDetailsLookUp) {
                const currentUserCompanyCountDiff = mu.companyCount - this.reportingUserCompanyDetailsLookUp[mu['userId']]['companyCount'];
                this.userCompanyDetailsRecon.push({
                  ...mu,
                  orgId,
                  orgName,
                  gxCompanyCount: mu.companyCount,
                  reportingCompanyCount: this.reportingUserCompanyDetailsLookUp[mu['userId']]['companyCount'],
                  companyCountDiff: currentUserCompanyCountDiff,
                });
                this.errorCountUserCompanyDetailsRecon++;
              } else {
                this.userCompanyDetailsRecon.push({
                  ...mu,
                  orgId,
                  orgName,
                  gxCompanyCount: this.gxUserCompanyDetailsLookUp[mu['userId']]['companyCount'],
                  reportingCompanyCount: 0,
                  companyCountDiff: this.gxUserCompanyDetailsLookUp[mu['userId']]['companyCount'],
                });
                this.errorCountUserCompanyDetailsRecon++;
              }
            });
          }

          ucd.reporting.forEach((ru) => {
            // if userId exists in reporting and not in monitoring add to table data source
            if (Object.keys(this.gxUserCompanyDetailsLookUp).length === 0 || !(ru.userId in this.gxUserCompanyDetailsLookUp)) {
              this.userCompanyDetailsRecon.push({
                ...ru,
                orgId,
                orgName,
                gxCompanyCount: 0,
                reportingCompanyCount: this.reportingUserCompanyDetailsLookUp[ru['userId']]['companyCount'],
                companyCountDiff: this.reportingUserCompanyDetailsLookUp[ru['userId']]['companyCount'],
              });
              this.errorCountUserCompanyDetailsRecon++;
            }
          });
        });
        this.userCompanyDetailsRecon = [...this.userCompanyDetailsRecon];
        this.cdr.detectChanges();
      },
      (err) => {
        console.log('Failed to get user company details...', err);
        this.snackBarService.openSnackBar('Failed to get user company details diff.');
      }
    );
  }

  onClientSelection(event) {
    console.log(event, 'client change');
    this.finsAndGdDiff = [];
    this.snowflakeFinsAndGdDiff = [];
    this.attributeDetailsRecon = [];
    this.userCountRecon = [];
    this.userCompanyDetailsRecon = [];
    this.gxFinancialsGeneralDetailsAndProfileLookUp = {};
    this.reportingFinancialsGeneralDetailsAndProfileLookUp = {};
    this.gxAttrsLookUp = {};
    this.reportsAttrsLookUp = {};
    this.gxUserCompanyDetailsLookUp = {};
    this.gxUserCountLookUp = {};
    this.errorCountAcrossOrgs = 0;
    this.errorCountForFinsGdAndProfile = 0;
    this.errorCountForSnowflakeFinsGdAndProfile = 0;
    this.errorCountForAtrributeDetailsRecon = 0;
    this.errorCountForUserCountRecon = 0;
    this.errorCountUserCompanyDetailsRecon = 0;
    this.getDiffGdAndFins([event.value]);
    this.getAttributesDiff([event.value]);
    // this.getUserCountDiff([event.value]);
    // this.getUserCompanyDetails([event.value]);
    this.getSnowflakeDiffGdAndFins([event.value]);
  }

  triggerStatusPolling(orgIds) {
    this.commonSub.add(
      this.statusInterval
        .pipe(
          startWith(0),
          switchMap((i) => {
            // console.log('Interval...', i);
            return this.dataReconService.getInSyncStatus(orgIds);
          })
        )
        .subscribe(
          (inSyncStatusResp: InSyncStatus) => {
            console.log('inSyncStatusResp...', inSyncStatusResp);
            if (inSyncStatusResp) {
              this.statusLoaded = true;
              this.dataReconService.setInSyncStatus(inSyncStatusResp);
              // console.log(this.dataReconService.inSyncStatusHistory.slice(1).slice(-3), ')))))');
              // const lastThreeHistoryItems = this.dataReconService.inSyncStatusHistory.slice(1).slice(-3);
              // this.statusLoaded = true;
              // if(lastThreeHistoryItems.length === 0 ) {
              //   this.financialsGeneralAndProfileDetails = inSyncStatusResp.financialsGeneralAndProfileDetails;
              //   this.attributeDetails = inSyncStatusResp.attributeDetails;
              //   this.userCompanyDetails = inSyncStatusResp.userCompanyDetails;
              //   this.userDetails = inSyncStatusResp.userDetails;
              // } else {
              //   this.financialsGeneralAndProfileDetails = lastThreeHistoryItems[1].financialsGeneralAndProfileDetails || lastThreeHistoryItems[2].financialsGeneralAndProfileDetails || lastThreeHistoryItems[3].financialsGeneralAndProfileDetails;
              //   this.attributeDetails = lastThreeHistoryItems[1].attributeDetails || lastThreeHistoryItems[2].attributeDetails || lastThreeHistoryItems[3].attributeDetails;
              //   this.userCompanyDetails = lastThreeHistoryItems[1].userCompanyDetails || lastThreeHistoryItems[2].userCompanyDetails || lastThreeHistoryItems[3].userCompanyDetails;
              //   this.userDetails = lastThreeHistoryItems[1].userDetails || lastThreeHistoryItems[2].userDetails || lastThreeHistoryItems[3].userDetails;
              // }
            }
            this.cdr.detectChanges();
          },
          (err) => {
            this.statusLoaded = false;
            console.log(err, 'Failed to get In Sync Status...');
          }
        )
    );
  }

  async getDataFromAllOrgs() {
    await this.getClients();
    const orgIds = this.clients.map((c) => c['orgId']);
    this.getDiffGdAndFins(orgIds);
    this.getAttributesDiff(orgIds);
    // this.getUserCountDiff(orgIds);
    // this.getUserCompanyDetails(orgIds);
    this.getSnowflakeDiffGdAndFins(orgIds);
  }

  refreshData() {
    this.finsAndGdDiff = [];
    this.attributeDetailsRecon = [];
    this.userCountRecon = [];
    this.userCompanyDetailsRecon = [];
    this.clients = [];
    this.clientsFc.reset();
    this.gxFinancialsGeneralDetailsAndProfileLookUp = {};
    this.reportingFinancialsGeneralDetailsAndProfileLookUp = {};
    this.gxSnowflakeFinancialsGeneralDetailsAndProfileLookUp = {};
    this.snowflakeFinancialsGeneralDetailsAndProfileLookUp = {};
    this.gxAttrsLookUp = {};
    this.reportsAttrsLookUp = {};
    this.gxUserCompanyDetailsLookUp = {};
    this.gxUserCountLookUp = {};
    this.errorCountAcrossOrgs = 0;
    this.errorCountForFinsGdAndProfile = 0;
    this.errorCountForSnowflakeFinsGdAndProfile = 0;
    this.errorCountForAtrributeDetailsRecon = 0;
    this.errorCountForUserCountRecon = 0;
    this.errorCountUserCompanyDetailsRecon = 0;
    this.getDataFromAllOrgs();
  }

  checkIfStatusChanged(previousVal: InSyncStatus, currentVal: InSyncStatus): boolean {
    return previousVal.financialsGeneralAndProfileDetails === currentVal.financialsGeneralAndProfileDetails || previousVal.attributeDetails === currentVal.attributeDetails || previousVal.userCompanyDetails === currentVal.userCompanyDetails || previousVal.userDetails === currentVal.userDetails || previousVal.snowflakeFinancialsGeneralAndProfileDetails === currentVal.userDetails;
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
