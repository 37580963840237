import { Directive,ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[RawDataDirective]',
  providers: [MatTooltip]

})
export class RawDataDirective {
  tooltip: MatTooltip;
  
  @Input('RawDataDirective') value;

  constructor(private elementRef: ElementRef,
    tooltip: MatTooltip) { 
    this.tooltip = tooltip;
  }
 
  @HostListener('dblclick') onDoubleClick() {
    this.CopyTextOnDBClick(this.value);
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.message = this.value;
    this.tooltip.show();
  }

  @HostListener('mouseleave') onMouseLeave(): void {  
    this.tooltip.message = this.value;      
    this.tooltip.hide(); 
  } 
  
  
  private CopyTextOnDBClick(val) {
    // this.elementRef.nativeElement.style.backgroundColor = color;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  @HostListener('click') click() {
    let type  = typeof(this.value)
    if(type == 'number'){
      this.CopyTextOnDBClick(this.value);
    }
  }

}
