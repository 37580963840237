import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-product-integration-tool',
    templateUrl: './product-integration-tool.component.html',
    styleUrls: ['./product-integration-tool.component.scss']
})
export class ProductIntegrationToolComponent implements OnInit {

    constructor()
    {}

    ngOnInit() {        
    }


    title = 'Product Page';

    technicalIntegrations = [
        {
            img:"../assets/imgs/Azure.png",
            title: "Azure AD SSO",
            text:"Azure Active Directory (Azure AD), is an enterprise identity service that provides single sign-on, multifactor authentication, and conditional access to guard against 99.9 percent of cybersecurity attacks."
        },

        {
            img:"../assets/imgs/Okta.jpeg",
            title: "Okta SSO",
            text:"An enterprise-grade, identity management service, built for the cloud, but compatible with many on-premises applications. Okta runs in the cloud, on a secure, reliable, extensively audited platform, which integrates deeply with on-premises applications, directories, and identity management systems."
        }
        // {img:"../assets/imgs/google drive.png",title: "Google Drive",text:"Google Drive is a file storage and synchronization service developed by Google"},

        // {img:"../assets/imgs/airtable.png",title: "Airtable",text:"Airtable is a low-code platform for building collaborative apps. Customize your workflow,collaborate,and achieve ambitious"},

        // {img:"../assets/imgs/Dropbox.png",title: "Dropbox",text:"Dropbox brings everything-traditional files,cloud content,and web shortcuts-together in one place."}
    ]

    functionalIntegration = [
        {
            img:"../assets/imgs/iLevel.png",
            title: "ilevel",
            text:"Integrate ilevel to pull & push financials, cashflows, captable through various methods including but not limited to<br>1. Via API<br>2. Via File based integration<br>3. Via Excel Plugin of ilevel (iGet) & Qubit X (Qx Push)"
        },

        {
            img:"../assets/imgs/jedox.png",
            title: "Jedox",
            text:"Seemless Integration with Jedox which provides Accelerate planning, forecasting and reporting through automation and predictive analytics in one unified solution."
        },
        
        {
            img:"../assets/imgs/LemonEdge.jpg",
            title: "LemonEdge",
            text:"Seemless Integration with LemonEdge platform, its a first and only low-code development platform for the complex accounting demands of the private equity industry."
        }
    ]
}