import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssignMapVersionDialogComponent } from './assign-map-version-dialog/assign-map-version-dialog.component';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from 'src/app/utils/util.service';
import { CompanyListService } from './company-list.service';
import { AssignedCompany, Company, CompanyDetails, CompanyValuation, Geography } from './ca-interfaces';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { ToastService } from 'src/app/utils/toast.service';
import { GsFilterService } from './assign-map-version-dialog/gs-filter-container/gs-filter.service';

@Component({
  selector: 'app-company-assignment',
  templateUrl: './company-assignment.component.html',
  styleUrls: ['./company-assignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyAssignmentComponent implements OnInit, OnDestroy {
  private commonSub = new Subscription();
  allCompanies: AssignedCompany[];
  assignedCompanies: AssignedCompany[];
  fundId: string;

  tableData: AssignedCompany[] = [];

  constructor(
    private dialog: MatDialog,
    private toastService: ToastService,
    private userMgmtService: UserManagementService,
    private activatedRouteSnap: ActivatedRoute,
    private utilService: UtilService,
    public clService: CompanyListService,
    private gsFilterService: GsFilterService,
    private mappingMgmtService: MappingMgmtConfigService,
    private cdr: ChangeDetectorRef
  ) {
    const routeSnapShot = this.activatedRouteSnap.snapshot;
    this.fundId = routeSnapShot.paramMap.get('fundId');
  }

  ngOnInit(): void {
    // this.getCompanyAssociations();
    this.getAllAndAssignedCompanies(this.fundId);
  }

  getAllAndAssignedCompanies(fundId: string) {
    this.utilService.showLoadingPopup();
    this.commonSub.add(
      this.clService.getAssignedCompaniesAndAllComapniesInFund(fundId).subscribe(
        (res) => {
          console.log('Merged res...', res);
          this.utilService.closeAllPopups();
          let logo = '';
          let earliestValudationDateIndex: number;
          // const allCompaniesInFund: Company[] = res[1].body.response[fundId]?.map((c) => {
          const allCompaniesInFund: Company[] = res[1].body.response.companies
            ?.map((c: CompanyValuation): Company => {
              earliestValudationDateIndex = res[1].body.response.companyWiseValuationForms[c.id]?.length - 1;
              logo = res[1].body.response.companyWiseValuationForms[c.id][earliestValudationDateIndex]?.logo;
              if (res[1].body.response.companyWiseValuationForms[c.id]) {
                const d = JSON.parse(res[1].body.response.companyWiseValuationForms[c.id][0]?.details) as CompanyDetails;
                // const d = JSON.parse(c.details as string || '');
                return {
                  companyId: c.id,
                  companyName: c.companyName,
                  createdById: '',
                  createdByName: '',
                  createdOn: '',
                  details: d as CompanyDetails,
                  exitDate: c.exitDate,
                  exitStatus: c.exitStatus,
                  logo: logo,
                  valuationDate: c.valuationDate,
                };
              }
              return {
                companyId: c.id,
                companyName: c.companyName,
                createdById: '',
                createdByName: '',
                createdOn: '',
                details: '',
                exitDate: c.exitDate,
                exitStatus: c.exitStatus,
                logo: logo,
                valuationDate: c.valuationDate,
              };
            })
            .sort((a, b) => (a?.companyName > b?.companyName ? 1 : -1));
          console.log(allCompaniesInFund, 'allCompaniesInFund');
          let countries: Geography[] = [];
          let sectors: string [] = [];
          allCompaniesInFund.forEach((c) => {
            countries.push((c.details as CompanyDetails)?.geography);
            sectors.push((c.details as CompanyDetails)?.sector);
          });
          console.log('countries', countries);
          this.gsFilterService.allCountries = [...countries];
          this.gsFilterService.allSectors = [...sectors];
          this.clService.setAllCompaniesInFund(allCompaniesInFund);
          this.clService.cloneAllComapniesInFundClone(allCompaniesInFund);
          let lookUp = {};
          // read comapnies that have been assigned a version list
          res[0].body.response?.forEach((ac) => {
            if (ac.companyId in lookUp) {
              lookUp[ac.companyId] = [...lookUp[ac.companyId], { ...ac }];
            } else {
              lookUp[ac.companyId] = [{ ...ac }];
            }
          });
          // prepare data to display default list as all companies have at the very least default version list and attributes mapped
          const defaultCaData: AssignedCompany[] = [];
          let defData: AssignedCompany;
          let tableData: AssignedCompany[] = [];
          let data: AssignedCompany;
          allCompaniesInFund?.forEach((c) => {
            if (!(c.companyId in lookUp)) {
              defData = {
                id: '',
                companyName: c['companyName'],
                companyId: c['companyId'],
                versionList: { name: 'Default List', id: 'default' },
                attributeList: { name: 'Default Map', id: 'default' },
                assignedBy: 'System',
                uploadDate: c.valuationDate,
                logo: c.logo,
                details: c.details as CompanyDetails,
                actions: undefined,
              };
              defaultCaData.push(defData);
            }
            if (c.companyId in lookUp) {
              lookUp[c.companyId].forEach((cwla) => {
                data = {
                  id: cwla.id,
                  companyName: c.companyName,
                  companyId: cwla['companyId'],
                  versionList: { ...cwla.versionList },
                  attributeList: cwla.attributeList ? { ...cwla.attributeList } : { name: 'Default Map', id: 'default' },
                  assignedBy: cwla.assignedBy,
                  uploadDate: cwla.uploadDate,
                  logo: c.logo,
                  details: c.details as CompanyDetails,
                  actions: cwla.actions,
                };
                tableData.push(data);
              });
            }
          });
          // console.log(defaultCaData, 'defaultCaData');
          this.tableData = [...tableData, ...defaultCaData].sort((a, b) => {
            // return a.companyName?.toLowerCase() > b.companyName?.toLowerCase() ? 1 : -1;
            return moment(a.uploadDate) < moment(b.uploadDate) ? 1 : -1;
          });
          this.cdr.detectChanges();
        },
        (err) => {
          console.log(err, 'combineLatest error');
          this.utilService.closeAllPopups();
          this.toastService.openSnackBar('Failed to get companies.');
        }
      )
    );
  }

  onAssign() {
    const dialogRef = this.dialog.open(AssignMapVersionDialogComponent, {
      panelClass: 'assign-map-version-dialog',
      minHeight: '100vh',
      data: {
        // companies: this.allCompanies,
        fundId: this.fundId,
      },
    });

    dialogRef.afterClosed().subscribe((r: { resetCl: boolean }) => {
      if (r?.resetCl) {
        this.mappingMgmtService.resetUploadDate();
      }
    });
  }

  onAssignmentDelete(company: AssignedCompany) {
    console.log(company, 'Company');
    const dialogRef = this.utilService.showConfirmMessage('Are you sure you want to delete this map.', 'Yes', 'No');
    dialogRef.afterClosed().subscribe((r) => {
      console.log(r);
      if (r === 'Yes') {
        this.clService.deleteCompanyAssignment(company.id).subscribe(
          (res) => {
            console.log('Company assignment deleted...', res);
            this.toastService.openSnackBar('Deleted Sucessfully.');
            // trigger an api request by resetting upload date to get updated company assignments
            this.mappingMgmtService.resetUploadDate();
          },
          (err) => {
            console.log('Failed to delete company assignment...', err);
            this.toastService.openSnackBar('Delete failed.');
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
    this.mappingMgmtService.resetUploadDate();
  }
}
