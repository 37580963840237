import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MappingMgmtConfigService {

  today = new Date();

  readonly _uploadDate = new BehaviorSubject<Date>(this.today);

  readonly uploadDate$ = this._uploadDate.asObservable();

  readonly _currentRoute = new BehaviorSubject<string>('');

  readonly currentRoute$ = this._currentRoute.asObservable();

  readonly _resetUploadDate = new BehaviorSubject<Date>(this.today);

  readonly resetUploadDate$ = this._resetUploadDate.asObservable();


  constructor() {}

  setUploadDate(val: Date) {
    this._uploadDate.next(val);
  }

  resetUploadDate(date?: Date) {
    this._resetUploadDate.next(date ? date : this.today);
  }

  setCurrentRoute(route: string) {
    this._currentRoute.next(route);
  }
}
