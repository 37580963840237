import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserManagementService } from '../services/user-management.service';

import { UtilService } from '../utils/util.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-bugs',
  templateUrl: './bugs.component.html',
  styleUrls: ['./bugs.component.scss']
})
export class BugsComponent implements OnInit{

  bugs = [];

  constructor(private ds: DataService, private ums: UserManagementService) {

  }
  ngOnInit() {
    const user = this.ums.getUserDetails(); 
    this.ds.getBugsByUser(user.id).subscribe(result=>{
      // console.log("All bugs", result.body)
      this.bugs = result.body["response"]
    }, error=>{
      console.log("error while fetching bugs")
    })
  }
}
