<nav-menu-header headerLabel="Admin"></nav-menu-header>
<app-power-bi-config></app-power-bi-config>

<div class="prospects-report">

  <div class="mat-elevation-z8">

    <div class="header-container">

      <h4 class="navigation-content-header"><i class="fas fa-users"></i> Admin Sftp Content</h4>

        <span class="float-right">
        <h3> List of all available Files</h3>
          <span>
            <select class = "form-control">
              <option *ngIf = "allAvailableFilesList.length == 0" value = "default"> 
                ---No files available---
              </option>
              <option *ngFor = "let file of allAvailableFilesList" [value] = "file">
                {{file}}
              </option> 
            </select>
          </span>
          <span> <br> <br> </span>
          <hr>

          <span class="icon" (click)="processSftpFiles()">
            <button class="btn" color="primary" class = "btn-custom-secondry" type="button" [disabled]="sftpFilesButtonDisabled">Process Latest SFTP File</button>
          </span>
          <!-- <br>
          <h3> Reprocess file section </h3>
          <label for="reprocessFileType">Select the type to reprocess</label>
          <select name = "reprocessFileType" class = "form-control" [(ngModel)] = "reprocessFileType">

            <option value = "PME" [selected] = "true"> PME </option>

            <option value = "NON-PME"> NON PME </option>
            <option value = "both"> Both </option>

          </select>
          <span> <br> <br> </span>
          <span>
            <span class="icon" (click)="reprocessSftpFile()">
              <button class="btn" color="primary" class = "btn-custom-secondry" type="button" [disabled]="reprocessFileButton"> Re Process Sftp Files</button>
            </span>
          </span> -->

        </span>

        <br>

        <h5> Add Company details form </h5>

        <div class = "col-6">

            <form #companyDetails = "ngForm">

                <div class = "form-group">

                    <label for="companyName">Company Name</label>

                    <input type = "text" class = "form-control" [(ngModel)] ="companyName"

                    width = "50%" required name = "companyName">

                </div>

                <div class = "form-group">

                    <label for="companyMappingName">Company Mapping Name</label>

                    <input type = "text" class = "form-control" [(ngModel)] ="companyMappingName"

                    width = "50%" required name = "companyMappingName">

                </div>

                <div class = "form-group">

                    <label for="companyOrganizationName">User organization Name</label>

                    <select name = "companyOrganizationName" class="form-control" [(ngModel)] ="companyOrganizationName">

                      <option value = "Eurazeo" [selected] = "true"> Eurazeo </option>

                    </select>

                </div>

                <div class = "form-group">

                    <label for="companyType">Company Type</label>

                    <select name = "companyType" class = "form-control" [(ngModel)] = "companyType">

                      <option value = "PME" [selected] = "true"> PME </option>

                      <option value = "NON-PME"> NON PME </option>

                    </select>

                </div>

                <button type = "submit" (click)="addCompany()" class = "btn-custom-primary" [disabled] = "companyDetails.invalid">

                Add Company

                </button>

                <button type = "submit" style = "margin-left : 20%" (click)="refreshAddData()" class = "btn-custom-secondry">

                Reset form

                </button>

            </form>

            <br>


            <div><h5>Select a company for edit or delete purpose</h5></div>

            <div class = "col-6">

              <select class = "form-control" (change) = "companySelectionDone()" 

              [(ngModel)] = "selectedCompany">

              <option [ngValue] = "null" disabled> Select company </option>

                <option *ngFor = "let company of allCompanyDetails" [ngValue] = "company"> 

                  {{company.companyName}}

                </option>

              </select> 

            </div>


            <br>

            <div *ngIf = "selectedCompanyId"><h5>Update and Delete Company details form</h5></div>

            <form #companyUpdateDetails = "ngForm" *ngIf = "selectedCompanyId">

                <div class = "form-group">

                    <label for="updateCompanyName">Company Name</label>

                    <input type = "text" class = "form-control" [(ngModel)] ="updateCompanyName"

                    width = "50%" required name = "updateCompanyName" disabled>

                </div>

                <div class = "form-group">

                    <label for="updateCompanyMappingName">Company Mapping Name</label>

                    <input type = "text" class = "form-control" [(ngModel)] ="updateCompanyMappingName"

                    width = "50%" required name = "updateCompanyMappingName">

                </div>

                <div class = "form-group">

                    <label for="updateCompanyOrganizationName">User organization Name</label>

                    <select name = "updateCompanyOrganizationName" class="form-control" [(ngModel)] ="updateCompanyOrganizationName">

                      <option value = "Eurazeo" [selected] = "true"> Eurazeo </option>

                    </select>

                </div>

                <div class = "form-group">

                    <label for="updateCompanyType">Company Type</label>

                    <select name = "updateCompanyType" class = "form-control" [(ngModel)] = "updateCompanyType">

                      <option value = "PME" [selected] = "true"> PME </option>

                      <option value = "NON-PME"> NON PME </option>

                    </select>

                </div>

                <button type = "submit" (click)="updateCompanyDetails('update')" class = "btn-custom-primary" [disabled] = "companyUpdateDetails.invalid">

                Update Company

                </button>

                <button type = "submit" style = "margin-left: 10%" class ="btn-custom-secondry" (click)="updateCompanyDetails('delete')" [disabled] = "companyUpdateDetails.invalid">
                Delete Company
                </button>
                <button type = "submit" style = "margin-left: 10%" class ="btn-custom-secondry" (click)="refreshUpdateDeleteData()">
                Reset Form
                </button>
            </form>
        </div>
        <br>

        <div class = "col-6">
          <div><h5> Upload File To Sftp Server </h5></div>
            <form #uploadFile = "ngForm">
                <div class = "form-group">
                    <label for="uploadFileType">Company Type</label>

                    <select name = "uploadFileType" class = "form-control" [(ngModel)] = "uploadFileType">

                      <option value = "PME" [selected] = "true"> PME </option>
                      <option value = "NON-PME"> NON PME </option>
                    </select>
                </div>
                <div class="form-group">
                  <label for="uploadFileContent">Upload File</label><br>
                  <input type="file" accept=".csv,.xls,.xlsx" id="uploadFileContent" name = "uploadFileContent" [(ngModel)] = "fileToUpload" (change)="selectFileToUpload($event)" />
                  <br>
                  <h6> (Only CSV and xlsx are allowed - csv is preferable)</h6>
                </div>

                <button type = "submit" (click)="uploadDataFile()" class = "btn-custom-primary" [disabled]="!fileToUpload">
                  Upload File
                </button>
            </form>

        </div>
    </div>
  </div>
</div>

