import { Component, OnInit, OnChanges, Input } from "@angular/core";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { HttpParams } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { FundListService } from "../../fund-list-ui/fund-list.service";
import { PortFolioSummaryServiceV2 } from "../../portfolio-summary-v2/portfolio-summary-v2.service";

@Component({
  selector: "app-captable-waterfall-versioning",
  templateUrl: "./captable-waterfall-versioning.component.html",
  styleUrls: ["./captable-waterfall-versioning.component.scss"],
})
export class CaptableWaterfallVersioningComponent implements OnInit, OnChanges {

  @Input() latestCompanyValuationDate;
  @Input() investmentDate;
  @Input() companyId;

  url: string = "http://localhost:4300/versions";
  urlSafe: SafeResourceUrl;
  loadIframe = false;
  fundName;
  constructor(
    public sanitizer: DomSanitizer,
    private fundService: FundListService,
    private portfolioService: PortFolioSummaryServiceV2,
  ) {}

  ngOnInit(): void {
    this.fundName= this.fundService.getFundName(this.portfolioService.selectedFundId);
    if(this.latestCompanyValuationDate.id){
      setTimeout(() => {
        this.prepareUrlAndLoadIframe();
      }, 1000);
    }
  }


  ngOnChanges(): void {
    this.fundName= this.fundService.getFundName(this.portfolioService.selectedFundId);
    console.log("@@");
    console.log(this.latestCompanyValuationDate)
    console.log(this.investmentDate)
  }

  prepareUrlAndLoadIframe() {
    const valuationServiceData = {
      formId: this.latestCompanyValuationDate.id,
      companyId: this.companyId, // companyId: this.ds.investmentDateFormId,
      companyName: this.latestCompanyValuationDate.companyName,
      fundName: this.fundName,
      investmentValuationDate: null,
      investmentFormDate: this.investmentDate,
      formValuationDate: this.latestCompanyValuationDate.valuationDate,
      currency: this.latestCompanyValuationDate.currency,
      enterpriseValue: this.latestCompanyValuationDate.investment.enterpriseValue,
      typeOfSystem: null,
      finalAdjustedEquityVal: this.latestCompanyValuationDate.investment.equityValue.finalAdjustedEquityVal,
      lockStatus: 'true'
    };
    const queryParamsString = new HttpParams({
      fromObject: valuationServiceData,
    }).toString();
    this.url = `${environment.portalUrl}/captable-waterfall/?` + queryParamsString;
    // this.url = `http://localhost:4300/captable-waterfall?` + queryParamsString;


    console.log(this.url);

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    this.loadIframe = true;
  }
}
