import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';

declare let GC: any;

@Component({
  selector: 'app-custom-stake-excel',
  templateUrl: './custom-stake-excel.component.html',
  styleUrls: ['./custom-stake-excel.component.scss']
})
export class CustomStakeExcelComponent implements OnInit, AfterViewInit {

  constructor(private ds : DataService, 
    public utilService : UtilService,
    public translateService: TranslateService) { }

  @ViewChild('excelUI', { static: false }) excelUI: ElementRef;
  @ViewChild('excelToolBar', { static: false }) excelToolBar: ElementRef;

  @Output() updatedExcelData = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @Input() stakeModel;
  @Input() investmentConsolID;
  @Input() consolSummary;

  excelIO = new GC.Spread.Excel.IO();

  file;
  spread;
  activeSheet;

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    
    this.stakeModel.showExcelUI = false;

    if(this.stakeModel.userUploadedJson){
      this.stakeModel.showExcelUI = true;

      setTimeout(() => {
        this.spread = new GC.Spread.Sheets.Workbook(this.excelUI.nativeElement);
        // new GC.Spread.Sheets.Designer.Designer(this.excelToolBar.nativeElement, spreadJSToolbarConfig, this.spread);
        this.activeSheet = this.spread.getActiveSheet();
        
        this.spread.fromJSON(this.stakeModel.userUploadedJson, { ignoreFormula: false });
        this.spread.refresh();
      });
    }
  }

  ngOnChanges(){
    if(!this.consolSummary.customStake || !this.consolSummary.customStake.selectedOption) {
      this.consolSummary.customStake = {
        "selectedOption": 'manualEntry',
        "stakeFromModel": { value: 0 },
        "manualEntry" : { value: 100 },
        "dilutedStake": { value: 0 },
        "impliedStakeWaterfall": { value: 0 },
        "impliedStakeManpack": { value: 0 },
        "impliedStakeManOption": { value: 0 },
      }
    }
  }

  uploadExcelEvent(event) {    
    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length === 0 || target.files.length !== 1) {
      this.utilService.showMessage("Please select only one file.", "Ok");
      return;
    }

    if(!target.files[0].name.endsWith("xlsx")) {
      this.utilService.showMessage("Please select file in xlsx format.", "Ok");
      return;
    }

    if(target.files[0].size > 1000000) {
      this.utilService.showMessage("Please select file of size less than 1 MB", "Ok");
      return;
    }

    let list:FileList = event.target.files;
    this.file = list.item(0);

    
    this.stakeModel.showExcelUI = true;

    setTimeout(() => {
      this.excelIO.open(this.file, (json) => {
        this.stakeModel.userUploadedJson = json
        if(this.stakeModel.userUploadedJson){

          if(!this.spread) {            
            this.spread = new GC.Spread.Sheets.Workbook(this.excelUI.nativeElement);
            // new GC.Spread.Sheets.Designer.Designer(this.excelToolBar.nativeElement, spreadJSToolbarConfig, this.spread);
            this.activeSheet = this.spread.getActiveSheet();
          }

          this.spread.fromJSON(this.stakeModel.userUploadedJson, { ignoreFormula: false });

          this.spread.refresh();
        }
      });
    });
  }

  getValueFromModel() {
    const valueFromModel = (this.getFromSheet())[1];
    
    const regex = /[^0-9.]/gi;
    const parsedValue = (valueFromModel+"").replace(regex, '');

    if(!isNaN(+parsedValue)) {
      let finalParsedValue = +parsedValue;
      
      if(valueFromModel && valueFromModel.indexOf("%") < 0){
        finalParsedValue = finalParsedValue * 100;
      }

      this.consolSummary.customStake.stakeFromModel.value = finalParsedValue;
    }
  }

  getFromSheet() {
    let activeSheet = this.spread.getActiveSheet();
    
    const sheetName = activeSheet.name();
    let row = activeSheet.getActiveRowIndex();
    let col = activeSheet.getActiveColumnIndex();
    const value = activeSheet.getCell(row, col).text();

    return [sheetName, value, row, col];
  }

  downloadExcelData(){
    this.ds.getWidgetDataFromDB("CONSOL_EXCEL_STAKE_MODEL", this.investmentConsolID).subscribe( res => {
      // console.log("Succesfully Fetched Excel Json for Custom Stake", res);
      this.stakeModel.userUploadedJson = res.body['response'][0].widgetData;
      
      this.stakeModel.showExcelUI = true;

      if(this.stakeModel.userUploadedJson){
        setTimeout(() => {

          if(!this.spread) {            
            this.spread = new GC.Spread.Sheets.Workbook(this.excelUI.nativeElement);
            // new GC.Spread.Sheets.Designer.Designer(this.excelToolBar.nativeElement, spreadJSToolbarConfig, this.spread);
            this.activeSheet = this.spread.getActiveSheet();
          }
          
          this.spread.fromJSON(this.stakeModel.userUploadedJson, { ignoreFormula: false });
          this.spread.refresh();
        });

      } else {
        this.utilService.showMessage("No file has been uploaded", "Ok", true);
      }
    }, error => {
      console.log("Failed to Fetch Excel Json for Custom Stake", error);
      this.utilService.showMessage("No file has been uploaded", "Ok", true);
    })
  }
  

  clickOnFileInput(fileInput) {
    fileInput.click();
  }

  copyEquityValue() {
    let activeSheet = this.spread.getActiveSheet();
    
    let row = activeSheet.getActiveRowIndex();
    let col = activeSheet.getActiveColumnIndex();

    activeSheet.setValue(row, col, +(this.consolSummary.sumOfTheParts.toFixed(2)));
  }

  saveData(){
    if(this.spread) {
      this.stakeModel.userUploadedJson = this.spread.toJSON({ ignoreFormula: false, ignoreStyle: false });
    }

    this.consolSummary.stake = this.consolSummary.customStake[this.consolSummary.customStake.selectedOption]?.value;
    
    this.updatedExcelData.emit()
  }

  dismissPopUp(){
    this.cancel.emit();
  }
}
