import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { UtilService } from 'src/app/utils/util.service';
import { VALUATION_DATA_TEMPLATE } from './old-valuation-data.model';

import * as moment from 'moment';

import { environment } from 'src/environments/environment';
import { PortFolioService } from '../../portfolio.service';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';

declare let GC: any;

@Component({
  selector: 'app-add-old-valuation',
  templateUrl: './add-old-valuation.component.html',
  styleUrls: ['./add-old-valuation.component.scss']
})
export class AddOldValuationComponent implements OnInit {
  @Output() save = new EventEmitter();

  progressMessage = "";
  isReady = false;
  selecctionDone = false;
  yday = new Date();

  spread;
  excelIO = new GC.Spread.Excel.IO();
  userUploadedJson;
  @ViewChild('excelUI', { read: ElementRef, static: true}) excelUI: ElementRef;

  valuationDate;
  valuationBeingEdited;
  valuationJSONData;

  constructor(private utilService: UtilService, 
    private portfolioService: PortFolioService,
    private dataService: DataService,
    public translateService: TranslateService) { }

  ngOnInit() {
    GC.Spread.Sheets.LicenseKey = environment.grapeCityLicense;
    GC.Spread.Excel.IO.LicenseKey = environment.grapeCityLicense;

    this.spread = new GC.Spread.Sheets.Workbook(this.excelUI.nativeElement);

    this.yday.setDate(this.yday.getDate() - 1);
    
    this.progressMessage = "";
    this.isReady = true;

    if(!this.portfolioService.userSavedOldFormsExcel) {
      this.portfolioService.userSavedOldFormsExcel = {};
      this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId] = []

    } else if(!this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]) {
      this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId] = []
    }
    
    this.valuationJSONData = this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId];
  }

  addOldValuation() {
    this.selecctionDone = true;
    this.valuationDate = moment(this.valuationDate).format("MMM DD, YYYY");
    // console.log("add old valuation", this.valuationDate);
    this.prepareNewSheet();
    this.readWriteExcel(true);
  }

  editOldValuation() {
    this.selecctionDone = true;
    // console.log("edit old valuation", this.valuationBeingEdited);
    this.readWriteExcel(true);
  }

  prepareNewSheet() {

    if(!this.portfolioService.userSavedOldFormsExcel) {
      this.portfolioService.userSavedOldFormsExcel = {};
      this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId] = []

    } else if(!this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]) {
      this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId] = []
    }

    const savedForms = this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]

    savedForms.push(JSON.parse(JSON.stringify(VALUATION_DATA_TEMPLATE)));
    this.valuationBeingEdited = savedForms.length - 1;

    savedForms[this.valuationBeingEdited].valuationDate.value = this.valuationDate;
    savedForms[this.valuationBeingEdited].id = this.portfolioService.companyId + "_S" + (new Date()).getTime()

    //Calculation valuation data entry headers
    let header = [];

    let valuationYear = Number(moment(this.valuationDate).format("YYYY"));
    header.push("FY " + (valuationYear -1));
    header.push("LTM");
    header.push("FY " + (valuationYear));
    header.push("FY " + (valuationYear +1));

    savedForms[this.valuationBeingEdited].valueHeaders = header;
  }

  readWriteExcel(writeToExcel: boolean) {
    let rowCursor = 1;
    let colCursor = 1;

    if(writeToExcel) {
      this.spread.refresh();
      this.spread.setSheetCount(1);
      this.spread.sheets[0].name("Old Valuation");

      let sheet0 = this.spread.getActiveSheet();
      sheet0.setColumnWidth(colCursor, 130.0);
      sheet0.setColumnWidth(colCursor+1, 130.0);
      sheet0.options.isProtected = true;
    }
    
    let sheet0 = this.spread.getSheet(0);

    //Meta data entry
    this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId][this.valuationBeingEdited].valuationMetadata.forEach(entry => {
      let colIndexMeta = colCursor;
      if(writeToExcel) {
        sheet0.setValue(rowCursor, colIndexMeta, entry.label);
        this.styleNonEditableCell(sheet0.getCell(rowCursor, colIndexMeta))

        sheet0.setValue(rowCursor, ++colIndexMeta, entry.value);
        this.styleEditableCell(sheet0.getCell(rowCursor, colIndexMeta));
      } else {
        entry.value = (sheet0.getCell(rowCursor, ++colIndexMeta)).value();
      }

      rowCursor++;
    });

    //Data grid header
    rowCursor++;
    let colIndexHeader = colCursor + 1;
    this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId][this.valuationBeingEdited].valueHeaders.forEach(entry => {
      if(writeToExcel) {
        sheet0.setValue(rowCursor, colIndexHeader, entry);
        this.styleNonEditableCell(sheet0.getCell(rowCursor, colIndexHeader))
      }
      colIndexHeader++;
    });

    //Data grid
    rowCursor++;
    const dataGrid = this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId][this.valuationBeingEdited].enterprise.concat(this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId][this.valuationBeingEdited].equity);
    dataGrid.forEach(entry => {
      let colIndexData = colCursor;

      if(writeToExcel) {
        sheet0.setValue(rowCursor, colIndexData, entry.label);
        this.styleNonEditableCell(sheet0.getCell(rowCursor, colIndexData));
      }
      //Not reading label for NOW
      
      //Write to Excel
      if(writeToExcel) {
        for(let i=0; i<4 ; i++) {
          sheet0.setValue(rowCursor, ++colIndexData, entry.values[i]);
          this.styleEditableCell(sheet0.getCell(rowCursor, colIndexData));
        }
      }

      //Read from excel
      if(!writeToExcel) {
        let updatedValues = [];
        for(let i=0; i<4 ; i++) {
          updatedValues.push(sheet0.getCell(rowCursor, ++colIndexData).value());
        }
        entry.values = updatedValues;
      }

      rowCursor++;
    }); 
  }

  styleEditableCell(cell) {
    cell.locked(false);
    // cell.backColor("#4EE476");
    // this.setCellBorder(cell);
  }

  styleNonEditableCell(cell) {
    cell.backColor("lightgrey");
    // cell.font("bold 14.6px Calibri");
    // this.setCellBorder(cell);
  }

  setCellBorder(cell) {
    // const border = new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
    // cell.borderLeft(border);
    // cell.borderRight(border);
    // cell.borderTop(border);
    // cell.borderBottom(border);
  }

  // async uploadOldValuationExcel(event) {    
  //   const target: DataTransfer = <DataTransfer>(event.target);

  //   if (target.files.length === 0 || target.files.length !== 1) {
  //     this.utilService.showMessage("Please select only one filled template.", "Ok");
  //     return;
  //   }

  //   if(!target.files[0].name.endsWith("xlsx")) {
  //     this.utilService.showMessage("Please select filled template in xlsx format.", "Ok");
  //     return;
  //   }

  //   this.progressMessage = "Uploading the file . . . .";

  //   let list:FileList = event.target.files;
  //   let file = list.item(0);

  //   this.excelIO.open(file, (json) => {
  //     this.userUploadedJson = json
  //     this.progressMessage = '';
  //     event.target.value = "";

  //     if(json){
  //       this.spread.fromJSON(json, { ignoreFormula: false })
  //     }
  //   });
  // }

  saveExcel() {
    this.progressMessage = this.translateService.getLabel("saving") + "...";
    // console.log("Saving...", this.valuationBeingEdited);

    this.readWriteExcel(false);
    // console.log("Read done...", this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]);

    this.dataService.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId]), this.portfolioService.companyId).subscribe(res=>{
      // console.log("Old Valuation data saved to db", res.body);
      this.progressMessage = '';
      
      this.utilService.showMessage(this.translateService.getLabel("suc_save_old_valuation_data"), this.translateService.getLabel("ok"));

      // Get all selected companies/forms whose ID field is null
      const existingSveForms = this.portfolioService.getSelectedCompanyDates(this.portfolioService.companyId).filter(c => !c.id || c.id.indexOf("_S") > 0);

      if(!existingSveForms || this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId].length > existingSveForms.length) {
        this.portfolioService.addOldValuationDate(this.portfolioService.userSavedOldFormsExcel[this.portfolioService.companyId][this.valuationBeingEdited]);
      } else {
        this.portfolioService.updatePortfolioData();
      }

      this.save.next();

    }, error=>{
      console.log("Failed to save Old Valuation dat to db", error);
      this.progressMessage = '';

      this.utilService.showMessage(this.translateService.getLabel("err_failed_save_old_valuation_data"), this.translateService.getLabel("ok"));
    });
  }
}
