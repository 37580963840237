



    <div class="table table-condensed" id="container">
      <table class="a">
        <tr class="bg primary-bg">
          <th>Logo</th>
          <th style="width: 160px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('companyName', 'string')">Name</th>
          <th style="width: 120px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('investmentDate', 'string')">Investment Date</th>
          <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('geography', 'string')"> Geography </th>
          <th style="width: 150px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('sector', 'string')">Sector</th>
          <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('security', 'string')">Type of Security</th>
          <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('currency', 'string')">Currency</th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('fairVal', 'number')">Fair Value</th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('stake', 'number')"> Stake </th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('stakeVal', 'number')">Stake Value</th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('investmentAmount', 'number')">Investment Amount</th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('realisedProceeds', 'number')"> Realised Proceeds </th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('moic', 'number')"> MOIC </th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('grossIRR', 'number')"> Gross IRR </th>
          <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('changeInValPercentage', 'number')"><i class="fas fa-caret-up"></i>From Last Val Date</th>
        </tr>
      </table>
    </div>