<nav-menu-header headerLabel="Funds" headerToBeAppended="Exit Simulation"></nav-menu-header>

<span class="header">Exit Model</span>

<ng-template [ngIf] = "exitSimulationLoaded">

    <!-- Input Table -->
    <div class="card page">
        <div class="row">
            <div class="col" style="padding: 0;">
                <div class="widget">
                    <span class="header">Input</span>
                    <div class="control-section" style="margin-top: 10px;">
                        <ejs-grid #batchgrid id='Batchgrid' *ngIf = "exitSimulationData && exitSimulationData.length > 0" 
                            [dataSource]="exitSimulationData"
                             (actionComplete) = "actionComplete($event)">
                            <e-columns>
                                <e-column field='companyName' headerText='Company Name' isPrimaryKey='true' width='100'>
                                    <!-- <ng-template #template let-data>
                                        {{ data.companyName }}
                                    </ng-template> -->
                                </e-column>

                                <!-- Currency -->
                                <e-column field='currency' headerText='Currency' width='65' 
                                textAlign="right" minWidth=10></e-column>
    
                                <e-column field='exitDate' textAlign="right" headerText='Exit Date' 
                                type = "date" width='115' placeholder="">
                                    <!-- <ng-template #editTemplate let-data>
                                        {{ data.exitDate | date: "mediumDate" }}
                                    </ng-template> -->
                                    <ng-template #template let-data>
                                        <mat-form-field class="dateInputBox">
                                            <!-- <input class="custom-input-box" matInput [(value)]="data.exitDate" type="date"> -->
                                            <!-- <mat-label>Choose a date</mat-label> -->
                                            <input matInput [matDatepicker]="picker" class="align-right"
                                            [(ngModel)]="exitSimulationData[data.index].exitDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </ng-template>
                                </e-column>
                                
                                <e-column field='metricMultiple' textAlign="right" headerText='Exit Multiple Type' 
                                editType="dropdownedit" [edit]='multipleParams' width='110'>
                                    <!-- <ng-template #template let-data>
                                        {{ getLabel(data.metricMultiple) | titlecase }}
                                    </ng-template> -->
                                    <ng-template #template let-data>
                                        <mat-form-field appearance="fill" class="textInputBox" style="text-align: center;">
                                            <!-- <mat-label>Favorite food</mat-label> -->
                                            <mat-select [(ngModel)]="exitSimulationData[data.index].metricMultiple" 
                                            (ngModelChange) = "updateInExitMultipleType($event, data.index)">
                                              <mat-option value="Revenue"> REVENUE  </mat-option>
                                              <mat-option value="EBITDA"> EBITDA </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </ng-template>
                                </e-column>
                                
                                <!-- <e-column field='amountBaseCase' textAlign="right" headerText='Variation' editType = "numericedit" format = "N2" width='80'>
                                    
                                    <ng-template #template let-data>
                                        <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                            <input class="custom-input-box" matInput [(ngModel)]="exitSimulationData[data.index].variationPercentage" type="number" >
                                            <span matSuffix>%</span>
                                        </mat-form-field>
                                    </ng-template>
                                </e-column> -->
                                
                                <e-column field='amountBaseCase' textAlign="right" headerText='Metric Amount (Mn)' 
                                editType = "numericedit" format = "N2" width='100'>
                                    <!-- <ng-template #template let-data>
                                        {{ exitSimulationData[data.index].amountBaseCase }}
                                        <ejs-numerictextbox format="###.#" min="0" [showSpinButton]="false"
                                            [(value)]="exitSimulationData[data.index].amountBaseCase">
                                        </ejs-numerictextbox>
                                    </ng-template> -->
                                    <ng-template #template let-data>
                                        <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                            <input class="custom-input-box" matInput 
                                            [(ngModel)]="exitSimulationData[data.index].amountBaseCase" type="number" >
                                        </mat-form-field>
                                    </ng-template>
                                </e-column>

                                <e-column field='multipleAtExitBaseCase' textAlign="right" headerText='Exit Multiple' 
                                editType = "numericedit" format = "N2" width='100'> 
                                    <!-- <ng-template #template let-data>
                                        <ejs-numerictextbox format="###.# 'x'" min="0" max="100" [showSpinButton]="false"
                                            [(value)]="exitSimulationData[data.index].multipleAtExitBaseCase">
                                        </ejs-numerictextbox>
                                    </ng-template> -->
                                    <!-- <ng-template #template let-data>
                                        <ejs-numerictextbox 
                                            format="###.# 'x'" 
                                            min="0" max="100" 
                                            [showSpinButton]="false" 
                                            [placeholder]="'Enter multiple'"
                                            [(value)]="exitSimulationData[data.index].multipleAtExitBaseCase" 
                                            >
                                        </ejs-numerictextbox>
                                        </ng-template> -->
                                        <ng-template #template let-data>
                                            <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                                <input class="custom-input-box" matInput 
                                                [(ngModel)]="exitSimulationData[data.index].multipleAtExitBaseCase" type="number" >
                                            </mat-form-field>                      
                                        </ng-template>
                                </e-column>
    
                                <e-column field='netDebt' textAlign="right" headerText='Net Debt' editType = "numericedit" format = "N2" width='100'>
                                    <!-- <ng-template #template let-data>
                                        <ejs-numerictextbox format="###.# " [showSpinButton]="false"
                                            [(value)]="exitSimulationData[data.index].netDebt">
                                        </ejs-numerictextbox>
                                    </ng-template> -->
                                    <ng-template #template let-data>
                                        <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                            <input class="custom-input-box" matInput 
                                            [(ngModel)]="exitSimulationData[data.index].netDebt" type="number" >
                                        </mat-form-field>
                                    </ng-template>
                                </e-column>

                                <!-- Stake % -->
                                <e-column field='stake' textAlign="right" headerText='Stake (%)' editType = "numericedit" 
                                format = "N2" width='80'>
                                    
                                    <ng-template #template let-data>
                                        <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                            <input class="custom-input-box" matInput [(ngModel)]="exitSimulationData[data.index].stake" type="number" >
                                            <span matSuffix>%</span>
                                        </mat-form-field>
                                    </ng-template>
                                </e-column>
    
                                <!-- Adj to Stake Value is netDebtAtExit -->
                                <e-column field='netDebtAtExit' textAlign="right" headerText='Adj to Stake Value' editType = "numericedit" format = "N2" width='100'>
                                    <!-- <ng-template #template let-data>
                                        <ejs-numerictextbox format="###.# " [showSpinButton]="false"
                                            [(value)]="exitSimulationData[data.index].netDebtAtExit">
                                        </ejs-numerictextbox>
                                    </ng-template> -->
                                    <ng-template #template let-data>
                                        <mat-form-field class="textInputBox" style="margin-right: 1vw;">
                                            <input class="custom-input-box" matInput 
                                            [(ngModel)]="exitSimulationData[data.index].netDebtAtExit" type="number" >
                                        </mat-form-field>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                    <div class="row" *ngIf = "exitSimulationData && exitSimulationData.length > 0" >
                        <div class="col"></div>
                        <div class="col-1 align-right" id="button-control">
                            <!-- <button ejs-button cssClass="e-outline e-info" (click) = "initExitSimulationCalculation()">Analyse</button> -->
                            <button ejs-button class="e-btn cca-button cca-button-primary" cssClass="e-outline e-info" (click) = "initAnalysis()">Analyse</button>
                        </div>
                        <div id="button-control" class="col-1 align-right">
                            <!-- <button ejs-button cssClass="e-success" (click) = "prepareExitSimulationInput()" >Refresh</button> -->
                            <button ejs-button class="e-btn cca-button cca-button-primary" matTooltip="Clicking on Refresh will fetch latest data from Portfolio Summary. Any change in list of companies and metric amount will be fetched by clicking on the Refresh button."
                                cssClass="e-success" (click) = "defaultSimulation()" >
                                Refresh
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Analysis Table -->
        <div class="row" style="margin-top: 3.8%;">
            <div class="col" style="padding: 0;">
                <div class="widget analysis">
                    <span class="header">Analysis Details</span>
                    <div class="control-section" style="margin: 20px auto; overflow-y: auto;">
                        <ejs-grid #analysisTableGrid id='Batchgrid' [dataSource]="exitSimulationData" [allowPaging]='false'
                        *ngIf = "showReturnDetailsGridTable && exitSimulationData && exitSimulationData.length > 0">
                            <e-columns>
                                <e-column field='index' headerText='S NO.' width='70' textAlign="center" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ data.index + 1 }}
                                    </ng-template>
                                </e-column>

                                <!-- Company Name -->
                                <e-column field='companyName' headerText='Company Name' width='100' 
                                textAlign="left" minWidth=10></e-column>

                                <!-- Currency -->
                                <e-column field='currency' headerText='Currency' width='80' 
                                textAlign="right" minWidth=10></e-column>
        
                                <!-- Metric name -->
                                <e-column field='metricMultiple' headerText='Metric' width='100' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ data.metricMultiple }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns -->
                                <!-- <e-column textAlign="center" width='100' minWidth=10
                                    [columns]='metricMultipleColumn' class="sub-heading">
                                </e-column> -->
        
                                                            
                                <!-- Metric Amount -->
                                <e-column field='amountBaseCase' headerText='Metric Amount' width='100' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.amountBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                            
        
                                <!-- Exit Multiple Column -->
                                <e-column field='multipleAtExitBaseCase' headerText='Exit Multiple' width='100' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.multipleAtExitBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns -->
                                <!-- <e-column headerText='Multiple' textAlign="Center" [columns]='exitMultipleColumn'
                                    style="background-color: #eed426c9!important;">
                                    <ng-template #template let-data>
                                        {{ data.metricMultiple | titlecase }}
                                    </ng-template>
                                </e-column> -->
        
        
                                <!-- EV Bearish -->
                                <!-- <e-column headerText='Bearish case' textAlign="Right" minWidth=10
                                    [columns]='eVBearishCase' class="sub-heading">
                                </e-column> -->
        
                                <!-- EV Base -->
                                 <!-- <e-column headerText='Base case' textAlign="Right" minWidth=10
                                    [columns]='eVBaseCase' class="sub-heading">
                                </e-column> -->
        
                                <!-- EV Bullish -->
                                 <!-- <e-column headerText='Bullish case' textAlign="Right" minWidth=10
                                    [columns]='eVBullishCase' class="sub-heading">
                                </e-column> -->

                                <!-- Enterprise value -->
                                <e-column field='eVBaseCase' headerText='Enterprise Value' width='100' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.eVBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns 
                                <e-column headerText='Enterprise Value' textAlign="Center" [columns]='evColumn'
                                    style="background-color: #eed426c9!important;">
                                </e-column> -->
                                
                                <!-- At Exit -->
                                <e-column field='netDebt' headerText='Net Debt' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.netDebt, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns
                                <e-column headerText='At Exit' textAlign="Right" minWidth=10
                                    [columns]='netDebt' class="sub-heading">
                                </e-column> -->
        
                                <!-- Equity Value Bearish -->
                                <!-- <e-column headerText='Bearish case' textAlign="Right" minWidth=10
                                    [columns]='equityValBearishCase' class="sub-heading">
                                </e-column> -->
        
                                <!-- Equity Value Base -->
                                <!-- <e-column headerText='Base case' textAlign="Right" minWidth=10
                                    [columns]='equityValBaseCase' class="sub-heading">
                                </e-column> -->
        
                                <!-- Equity Value Bullish -->
                                <!-- <e-column headerText='Bullish case' textAlign="Right" minWidth=10
                                    [columns]='equityValBullishCase' class="sub-heading">
                                </e-column> -->

                                <!-- Equity Value -->
                                <e-column field='equityValBaseCase' headerText='Equity Value' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.equityValBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns
                                <e-column headerText='Equity Value' textAlign="Center" [columns]='equityColumn'
                                    style="background-color: #eed426c9!important;">
                                </e-column> -->
        
                                <!-- Stake % -->
                                <e-column field='stake' headerText='Stake %' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.stake, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns
                                <e-column type= "number" textAlign="Right" minWidth=10
                                    [columns]='stakeColumn' class="sub-heading">
                                </e-column> -->
        
                                <!-- Nav -->
                                <e-column field='NAVBaseCase' headerText='NAV' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.NAVBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns
                                <e-column headerText='NAV' textAlign="Center" [columns]='navColumn'
                                    style="background-color: #eed426c9!important;">
                                </e-column> -->
        
                                <!-- Net Debt At Exit / Adj to Stake Value -->
                                <e-column field='netDebtAtExit' headerText='Adj to Stake Value' width='100' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.netDebtAtExit, 2, "", "0.00", false) }}
                                    </ng-template>
                                </e-column>

                                <!-- Nested Columns
                                <e-column textAlign="Right" minWidth=10
                                    [columns]='netDebtAtExit' class="sub-heading">
                                </e-column> -->
        
                                <!-- Total Value / Consol (After adding Adj To Stake Value) -->
                                <e-column field='totalValBaseCase' headerText='Consol' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.totalValBaseCase, 2, "", "0.00", true) }}
                                    </ng-template>
                                </e-column>
                                
                                <!-- Nested Columns
                                <e-column headerText='Consol' textAlign="Center" [columns]='totalColumn'
                                    style="background-color: #eed426c9!important;">
                                </e-column> -->

                                <!-- IRR -->
                                <e-column field='irrBaseCase' headerText='IRR %' width='80' 
                                textAlign="right" minWidth=10>
                                    <ng-template #template let-data>
                                        {{ utilService.getDisplayFormattedNumber(data.irrBaseCase, 2, "", "0.00", false) }}
                                    </ng-template>
                                </e-column>

                                 <!-- Moic -->
                                 <e-column field='moicBaseCase' headerText='MOIC x' width='80' 
                                 textAlign="right" minWidth=10>
                                     <ng-template #template let-data>
                                         {{ utilService.getDisplayFormattedNumber(data.moicBaseCase, 2, "", "0.00", false) }}
                                     </ng-template>
                                 </e-column>
                                
                            </e-columns>
    
                            <!-- Hiding Total Aggregate Columns -->
                            <!-- <e-aggregates>
                                <e-aggregate>
                                    <e-columns>
    
                                        <e-column  columnName="eVBearishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEVBearish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                                            
                                        <e-column  columnName="eVBaseCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEVBase | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                        
                                        <e-column  columnName="eVBullishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEVBullish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
    
    
                                        <e-column  columnName="equityValBearishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEquityValueBearish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                                            
                                        <e-column  columnName="equityValBaseCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEquityValueBase | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                        
                                        <e-column  columnName="equityValBullishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalEquityValueBullish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
    
    
                                        <e-column  columnName="NAVBearishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalNAVBearish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                                            
                                        <e-column  columnName="NAVBaseCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalNAVBase | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                        
                                        <e-column  columnName="NAVBullishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalNAVBullish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
    
    
    
                                        <e-column  columnName="totalValBearishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalValueBearish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                                            
                                        <e-column  columnName="totalValBaseCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalValueBase | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                        
                                        <e-column  columnName="totalValBullishCase"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalValueBullish | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
    
                                        <e-column  columnName="netDebtAtExit"  textAlign='Right' type="Custom">
                                            <ng-template #footerTemplate let-data>
                                            <span style="font-weight: bolder;">
                                                {{ totalSimulationData.totalNetDebtAtExit | number: '1.2-2' }}
                                            </span>
                                            </ng-template>
                                        </e-column>
                                      
                                    </e-columns>
                                </e-aggregate>
                            </e-aggregates> -->
                        </ejs-grid>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row" style="margin-top: 3.8%;">
            <div class="col">

                <span class="header">
                    Exit Value Evolution
                </span>
            
                <div>           
                    <div [chart]="summaryChart"></div>
                </div>
            </div>
        </div> -->

        <!-- <div class="row">
            <div class="col-10"></div>
            <div class="col-2">
                <button [ngClass]="{'primary-btn': selectedView === 'bar', 'secondary-btn': selectedView === 'bubble'}" (click)="selectedView = 'bar'; prepareSectionHeadingChart(); prepareSectionHeadingChartSeries()">Bar View</button>
                <button [ngClass]="{'secondary-btn': selectedView === 'bar', 'primary-btn': selectedView === 'bubble'}" (click)="selectedView = 'bubble';">Bubble View</button>
            </div>
        </div> -->
        <ng-template [ngIf]="selectedCase">
            <!-- && selectedView == 'bar' -->
            <!-- <span class="header" style="width: max-content">
                {{ selectedCaseLAbel }}
            </span> -->
            <!-- <h4>Top Heading for Chart 2</h4> -->
            
                    <!-- <br> -->
            
            <!-- <div [chart]="sectionHeadingChart"></div> -->
        </ng-template>
        <ng-template [ngIf]="selectedView == 'bubble'">
            <!-- <div [chart]="sectionHeadingChart"></div> -->
            Put bubble chart here
        </ng-template>
    </div>
</ng-template>

<ng-template [ngIf] = "!exitSimulationLoaded">
    <div class="loading-container-simulation" >
        <p class="loading-text">Loading Exit Simulation...</p>
    </div>
</ng-template>

