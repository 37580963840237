interface S73Product {
    productKey: ProductKeyType;
    productName: string;
    productId: string;
  }
  
  enum ProductKey {
    EQUITY = 'equity',
    CREDIT = 'debt',
    GRAVITON = 'monitoring',
    ORGADMIN = 'org_admin',
    TRANSACTION = 'transaction',
    MISCELLANEOUS = 'misc',
    S73ADMIN = '73s_admin'
  }
  
  type ProductKeyType = ProductKey.EQUITY | ProductKey.CREDIT | ProductKey.GRAVITON | ProductKey.ORGADMIN | ProductKey.TRANSACTION | ProductKey.MISCELLANEOUS | ProductKey.S73ADMIN;
  
  export { S73Product, ProductKey, ProductKeyType };
  