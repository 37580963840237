import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageLoadService {

  constructor() { }
  
  imageOnLoad(event) {
    const imagePath = event.path[0];
    if (imagePath.height > imagePath.width) {
      imagePath.style.height = "100%";
      imagePath.style.width = "auto";
    }
    else {
      imagePath.style.height = "auto";
      imagePath.style.width = "100%";
    }
  }
  
  getImage(fileName){
    return environment.imagePath + fileName;
  }
}
