import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-business-unit-popup-ui',
  templateUrl: './business-unit-popup-ui.component.html',
  styleUrls: ['./business-unit-popup-ui.component.scss']
})
export class BusinessUnitPopupUiComponent implements OnInit {

  constructor() { }

  @Input() selectedBusinessUnits = [];
  @Input() mode = "VIEW_TYPE";

  @Output() emitAction = new EventEmitter();

  ngOnInit(): void {
  }

  getWhetherToViewTheBU(businessUnit){
    switch(this.mode){
      case "VIEW_TYPE":
        return businessUnit.status != "Initiated"
        
      case "EDIT_TYPE":
        return true;

      case "SHARE_TYPE":
        return businessUnit.consolForm && businessUnit.status != "Initiated";  
    }

    return true;
  }

  getTheViewIcon(bu){
    return this.mode == "VIEW_TYPE" && bu.status !== 'Initiated'
  }

  emitTheActionType(type, businessUnit){
    this.emitAction.emit({
      type: type,
      bu: businessUnit
    })
  }

}
