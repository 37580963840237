<div class="row" style="padding-bottom: 10px;">
    <div class="col-12 row">
        <span class="main-page-header">{{translateService.getLabel("cx_as_of")}} </span>
        <span class="col-2">
            <mat-form-field class="custom-input-select">
                <mat-select disableOptionCentering [(ngModel)]="selectedValuationDate"
                    (selectionChange)="onValuationDateChange($event)">
                    <mat-option *ngFor="let valDate of valuationDates" [value]="valDate">
                        {{valDate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
        <span class="col-2 reset-mannual-inputs">
            <i class="fas fa-sync-alt" (click)="resetTransctionOverview()"></i>
        </span>
    </div>
</div>
<div class="transaction-overview-grid margin-top padding-top">
    <div class="e-card-header d-flex justify-content-between align-items-center mb-3">
        <span class="main-page-header">{{translateService.getLabel('credit_capital_structure')}}</span>
        <div class="ml-auto">
            <button *ngIf="isEditClicked" class="btn-custom-secondry" style="margin-right: 25px;" (click)="cancel()">Cancel</button>
            <button *ngIf="!isEditClicked"class="btn-custom-secondry" style="margin-right: 15px;" (click)="addCashDebt()">Edit</button>
            <button *ngIf="isEditClicked" class="btn-custom-primary" (click)="save()">Save</button>
            <button *ngIf="!isEditClicked && isSaveVisible" class="btn-custom-primary" (click)="save()">Save</button>
        </div>
    </div>
    <div class="table-custom-border table_wrapper">
        <div class="table-container">
        <table class="table">
            <tr class="table-custom-header">
                <th>{{translateService.getLabel('Financial Date')}}</th>
                <th *ngFor="let header of financialDates">{{ header | date:'mediumDate' }}</th>
            </tr>
            <ng-container *ngFor="let adjustment of addelements; index as i">
                <ng-template [ngIf]="i == 0">
                    <ng-container>
                        <tr *ngFor="let data of debtData; index as i" class="table_body table_sub_body tableRow">
                            <td>
                                <ng-template [ngIf]="isEditClicked">
                                    <div class="d-flex">
                                            <mat-form-field class="number-textbox custom-textbox align-right">
                                                <input matInput type="text" [(ngModel)]="data['companyName']" (ngModelChange)="debitDataChange(data['companyName'], i)">
                                            </mat-form-field>
                                            <span class="col-1" *ngIf="!data.companyId">
                                                <mat-checkbox type="checkbox" class="checkbox" [(ngModel)]="data.isExposure"></mat-checkbox>
                                            </span>
                                            <span class="col-1" *ngIf="data.companyId"></span>
                                            <i class="fa fa-trash action-icon grey-text delete-icon" (click)="deleteRow(i, 'debt')"></i>
                                    </div>
                                </ng-template>
                                <ng-template [ngIf]="!isEditClicked">
                                    {{ data['companyName'] }}
                                </ng-template>
                            </td>
                          <td *ngFor="let header of financialDates; index as j">
                            <ng-template [ngIf]="isEditClicked">
                              <div class="input-wrapper_edit">
                                <mat-form-field class="number-textbox custom-textbox align-right">
                                  <input matInput type="number" [(ngModel)]="data[header]" (ngModelChange)="debitDataChange(data[header], i,j)">
                                </mat-form-field>
                              </div>
                            </ng-template>
                            <ng-template [ngIf]="!isEditClicked">
                              {{ data[header] }}
                            </ng-template>
                          </td>
                        </tr>
                      </ng-container>
                    <tr *ngIf="isEditClicked" class="table_body table_sub_body tableRow">
                        <td>
                            <button class="button" (click)="openPopupAddClaim(addClaim,'debit')">
                                [Add Debt]
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let data of totalDebtData" style="text-align: left; font-weight: bold;"
                        class="table_body table_sub_body tableRow">
                        <td>Total Debt</td>
                        <td *ngFor="let header of financialDates">
                            {{ data[header] | dynamicDecimalPipe }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="i == 1">
                    <ng-container *ngIf="cashData.length > 0">
                        <tr *ngFor="let data of cashData ; index as i" class="table_body table_sub_body tableRow">
                            <td>
                                <ng-template [ngIf]="isEditClicked">
                                  <div class="d-flex">
                                    <mat-form-field class="number-textbox custom-textbox align-right">
                                      <input matInput type="text" [(ngModel)]="data['companyName']" (ngModelChange)="cashDataChange(data['companyName'], i)">
                                    </mat-form-field>
                                    <span class="col-1"></span>
                                    <i class="fa fa-trash action-icon grey-text delete-icon" (click)="deleteRow(i, 'cash')"></i>
                                  </div>
                                </ng-template>
                                <ng-template [ngIf]="!isEditClicked">
                                  {{ data['companyName'] }}
                                </ng-template>
                              </td>
                            <td *ngFor="let header of financialDates; index as j">
                                <ng-template [ngIf]="isEditClicked">
                                    <div class="input-wrapper_edit">
                                        <mat-form-field class="number-textbox custom-textbox align-right">
                                            <input matInput type="number" [(ngModel)]="data[header]" (ngModelChange)="cashDataChange(data[header],i,j)">
                                        </mat-form-field>
                                    </div>
                                </ng-template>
                                <ng-template [ngIf]="!isEditClicked">
                                    {{ data[header] }}
                                </ng-template>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="isEditClicked" class="table_body table_sub_body tableRow">
                        <td>
                            <button class="button" (click)="openPopupAddClaim(addClaim,'cash')">
                                [Add Cash]
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let data of totalCashData" style="text-align: left; font-weight: bold;"
                        class="table_body table_sub_body tableRow">
                        <td>Total Net Debt</td>
                        <td *ngFor="let header of financialDates">
                            {{ data[header] | dynamicDecimalPipe }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template [ngIf]="i == 2">
                    <ng-container *ngIf="equityData.length > 0">
                        <tr *ngFor="let data of equityData ; index as i" class="table_body table_sub_body  tableRow">
                            <td>
                                <ng-template [ngIf]="isEditClicked">
                                  <div class="d-flex">
                                    <mat-form-field class="number-textbox custom-textbox align-right">
                                      <input matInput type="text" [(ngModel)]="data['companyName']" (ngModelChange)="equityDataChange(data['companyName'], i)">
                                    </mat-form-field>
                                    <span class="col-1"></span>
                                    <i class="fa fa-trash action-icon grey-text delete-icon" (click)="deleteRow(i, 'equity')"></i>
                                  </div>
                                </ng-template>
                                <ng-template [ngIf]="!isEditClicked">
                                  {{ data['companyName'] }}
                                </ng-template>
                              </td>
                            <td *ngFor="let header of financialDates; index as j">
                                <ng-template [ngIf]="isEditClicked">
                                    <div class="input-wrapper_edit">
                                        <mat-form-field class="number-textbox custom-textbox align-right">
                                            <input matInput type="number" [(ngModel)]="data[header]" (ngModelChange)="equityDataChange(data[header],i,j)">
                                        </mat-form-field>
                                    </div>
                                </ng-template>
                                <ng-template [ngIf]="!isEditClicked">
                                    {{ data[header] }}
                                </ng-template>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="isEditClicked" class="table_body table_sub_body tableRow">
                        <td>
                            <button class="button" (click)="openPopupAddClaim(addClaim ,'equity')">
                                [Add Equity]
                            </button>
                        </td>
                    </tr>
                    <tr *ngFor="let data of totalEquityData" style="text-align: left; font-weight: bold;"
                        class="table_body table_sub_body table_bottom_body tableRow">
                        <td>Total Capitalization </td>
                        <td *ngFor="let header of financialDates">
                            {{ data[header] | dynamicDecimalPipe }}
                        </td>
                    </tr>
                </ng-template>
            </ng-container>
            <ng-template #noData>
                <tr>
                    <td class="no-data-message" style="text-align: center; font-size: medium;">
                        No data to display
                    </td>
                </tr>
            </ng-template>
        </table>
    </div>
        <div class="table-container">
        <table class="table2">
            <tr class="table-custom-header2">
                <th>Financial Date</th>
                <th *ngFor="let header of financialDates">{{ header }}</th>
            </tr>
            <ng-container *ngIf="totalEbitdaData.length > 0">
                <tr *ngFor="let data of totalEbitdaData" style="text-align: left; font-weight: bold;"
                    class="table_body table_sub_body table_top_body tableRow">
                    <td>EBITDA</td>
                    <td *ngFor="let header of financialDates">
                        {{ data[header] }}
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="totalLeverageData.length > 0">
                <tr *ngFor="let data of totalLeverageData" style="text-align: left; font-weight: bold;"
                    class="table_body table_sub_body table_bottom_body tableRow">
                    <td>Net Leverage</td>
                    <td *ngFor="let header of financialDates">
                        {{ (data[header] | dynamicDecimalPipe) + 'x' }}
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
    </div>
</div>

<ng-template #editTransactionOverview let-editTransactionOverview>
    <div class="modal-header">
        <div class="modal-title">
            <span style="font-size: 22px;">{{this.translateService.getLabel("edit")}}</span>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close" (click)="resetTransactionOverviewOnClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body claim-model-body">
      <div class="model-content">
        <!-- <div class="row">
            <div class="col-4 input-label">
              {{translateService.changeKeyAndGetLabel(interestFormConstant.label.interestPaidLabel)}}</div>
            <div class="col-4">
                <mat-form-field class="custom-input-select">
                    <mat-select [(ngModel)]="selectedSecurity.interestPaidOrAccrued">
                        <mat-option value="" disabled>{{translateService.getLabel(interestFormConstant.label.selectOptionText)}}</mat-option>
                        <mat-option *ngFor="let value of interestFormConstant.interestPaidOrAccrued" [value]="value.toUpperCase()">
                            {{ value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <div class="row">
          <div class="col-4 input-label">
            {{translateService.changeKeyAndGetLabel(interestFormConstant.label.interestRateTypeLabel)}}</div>
          <div class="col-4">
            <mat-form-field class="custom-input-select">
                <mat-select [(ngModel)]="selectedSecurity.interestRateType">
                    <mat-option value="" disabled>{{translateService.getLabel(interestFormConstant.label.selectOptionText)}}</mat-option>
                    <mat-option *ngFor="let value of interestFormConstant.interestType" [value]="value.toUpperCase()" >
                        {{ value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selectedSecurity.interestRateType == 'FLOATING'">
          <div class="col-4 input-label">
            {{translateService.changeKeyAndGetLabel(interestFormConstant.label.floatingBaseRateCurveLabel)}}</div>
          <div class="col-4">
            <mat-form-field class="custom-input-select">
                <mat-select [(ngModel)]="selectedSecurity.floatingBaseRateCurve">
                    <mat-option value="" disabled>{{translateService.getLabel(interestFormConstant.label.selectOptionText)}}</mat-option>
                    <mat-option *ngFor="let value of interestFormConstant.floatingBaseRateCurveOptions" [value]="value">
                        {{ value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selectedSecurity.interestRateType == 'FIXED'">
          <div class="col-4 input-label">
            {{translateService.changeKeyAndGetLabel(interestFormConstant.label.fixedBaseRateLabel)}}</div>
          <div class="percentage-suffix col-7">
            <mat-form-field *ngIf="selectedSecurity.interestPaidOrAccrued === 'PAID'" class="number-textbox custom-input-textbox align-right">
                <input matInput type="text" [(ngModel)]="selectedSecurity.pricingCash">
            </mat-form-field>
            <mat-form-field *ngIf="selectedSecurity.interestPaidOrAccrued !== 'PAID'" class="number-textbox custom-input-textbox align-right">
                <input matInput type="text" [(ngModel)]="selectedSecurity.pricingPik">
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="selectedSecurity.interestRateType == 'FLOATING'">
            <div class="col-4 input-label">
                {{interestFormConstant.label.spreadRate}}</div>
            <div class="percentage-suffix col-7">
              <mat-form-field class="number-textbox custom-input-textbox align-right">
                  <input matInput type="text" [(ngModel)]="selectedSecurity.fixedInterestRate">
              </mat-form-field>
            </div>
          </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-custom-secondry" (click)="resetTransactionOverviewOnClose();">
            {{translateService.getLabel("cancel")}}
        </button>
        <button type="button" class="btn-custom-primary" (click)="updateTransactionOverviewModalData()">
            {{ translateService.getLabel("apply") }}
        </button>
    </div>
</ng-template>

<!-- <div class="row margin-top padding-top">
    <div class="col"><button class="btn-custom-primary">
            {{translateService.getLabel("add")}} </button></div>
</div> -->
<ng-template #addClaim let-addClaimModal>
    <div class="modal-header">
        <div class="modal-title">
            <span style="font-size: 22px;">{{popupHeader}}</span>
        </div>
        <button #closeBtn type="button" class="close" aria-label="Close" (click)="addClaimModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body claim-model-body">
        <div class="model-content">
            <div class="row">
                <div class="input-wrapper" *ngIf="showDebtSecurityname">
                    <mat-label>{{ translateService.getLabel('security') }}</mat-label>
                    <mat-form-field class="number-textbox custom-textbox align-right">
                        <input matInput [matAutocomplete]="auto" required [(ngModel)]="selectedSecurityName"
                            (ngModelChange)="checkIfSecurityDebtExists()" #securityInput />
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <ng-container *ngFor="let company of companySecurities">
                                <mat-option *ngIf="!securityDebtExists" [value]="company.companyName"
                                    style="line-height: unset;">
                                    {{ company.companyName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-error *ngIf="securityDebtExists">{{ translateService.getLabel('security_name_already_exists')
                        }}</mat-error>
                </div>
                <div class="input-wrapper" *ngIf="showcCashSecurityname">
                    <mat-label>{{ translateService.getLabel("security") }}</mat-label>
                    <mat-form-field class="number-textbox custom-textbox align-right">
                        <input matInput [matAutocomplete]="auto" required [(ngModel)]="selectedSecurityName"
                            (ngModelChange)="checkIfSecurityCashExists()" #securityInput />
                    </mat-form-field>
                    <mat-error *ngIf="securityCashExists">{{ translateService.getLabel('security_name_already_exists')
                        }}</mat-error>
                </div>
                <div class="input-wrapper" *ngIf="showEquitySecurityname">
                    <mat-label>{{ translateService.getLabel("security") }}</mat-label>
                    <mat-form-field class="number-textbox custom-textbox align-right">
                        <input matInput [matAutocomplete]="auto" required [(ngModel)]="selectedSecurityName"
                            (ngModelChange)="checkIfSecurityEquityExists()" #securityInput />
                    </mat-form-field>
                    <mat-error *ngIf="securityEquityExists">{{ translateService.getLabel('security_name_already_exists')
                        }}</mat-error>
                </div>
                <div *ngFor="let header of financialDates" class="input-wrapper">
                    <mat-label>{{ header }}</mat-label>
                    <mat-form-field class="number-textbox custom-textbox align-right">
                        <input matInput type="number" [(ngModel)]="financialDateValues[header]" #debitInput>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn-custom-secondry"
            (click)="addClaimModal.dismiss('Cross click');this.securityDebtExists= false;
            this.securityCashExists= false;this.securityEquityExists= false">
            {{translateService.getLabel("cancel")}}
        </button>
        <button type="button" [disabled]="securityDebtExists || securityCashExists || securityEquityExists"
            class="btn-custom-primary" (click)="updateCapitalStructureData()">
            {{ translateService.getLabel("add") }}
        </button>
    </div>
</ng-template>

<div *ngIf="transactionOverviewData && transactionOverviewData.length > 0"
    class="transaction-overview-grid margin-top padding-top">
    <div class="e-card-header d-flex justify-content-between align-items-center mb-3">
        <span class="main-page-header">Transaction Overview</span>
        <div  *ngIf="isManualSecurityTransactionExists" class="ml-auto">
            <button *ngIf="isTransactionEditClicked" class="btn-custom-secondry" style="margin-right: 25px;" (click)="cancelTransactionOverviewData()">Cancel</button>
            <button *ngIf="!isTransactionEditClicked"class="btn-custom-secondry" style="margin-right: 15px;" (click)="editTransactionOverviewData()">Edit</button>
        </div>
    </div>
    <div class="table-custom-border table_wrapper table-overflow ">
        <table class="table">
            <thead>
                <tr class="table-custom-header">
                    <th>{{translateService.getLabel("security_name")}}</th>
                    <th>{{translateService.getLabel("fair_value")}}</th>
                    <th>{{translateService.getLabel('Leverage')}}</th>
                    <th>{{translateService.getLabel('investment_date')}}</th>
                    <th>{{translateService.getLabel('exit_date')}}</th>
                    <th>{{translateService.getLabel('credit_Pricing_Cash')}}</th>
                    <th>{{translateService.getLabel('credit_pricing_pik')}}</th>
                    <th>{{translateService.getLabel('credit_rates')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transaction of transactionOverviewData"
                    class="table_body table_sub_body table_bottom_body tableRow">
                    <td>{{ transaction.companyName }}
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions" class="edit-icon"><i class="fa fa-save" (click)="transaction.isSecuritySelectedFromTransactions = false; updateTransactionOverview(transaction);"></i></span>
                        <span *ngIf="!transaction.sSecuritySelectedFromTransactions &&  isTransactionEditClicked && transaction.securityType == 'MANUAL_SECURITY'" class="edit-icon"><i class="fas fa-edit" (click)="transaction.isSecuritySelectedFromTransactions = true;"></i></span>
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions" class="edit-icon"><i class="fa fa-times" (click)="transaction.isSecuritySelectedFromTransactions = false; resetTransactionTempUpdates();"></i></span>
                    </td>
                    <td>
                        <span *ngIf="!transaction.isSecuritySelectedFromTransactions">{{ transaction?.fairValue | dynamicDecimalPipe }}</span>
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions">
                            <mat-form-field class="number-textbox custom-textbox align-right">
                                <input matInput type="text" [(ngModel)]="transaction.fairValue">
                            </mat-form-field>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!transaction.isSecuritySelectedFromTransactions">
                            {{ transaction?.leverage | dynamicDecimalPipe }}x
                        </span>
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions">
                            <mat-form-field class="number-textbox custom-textbox align-right">
                                <input matInput type="text" [(ngModel)]="transaction.leverage">
                            </mat-form-field>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!transaction.isSecuritySelectedFromTransactions">
                            {{ transaction?.investmentDate | date:'mediumDate' }}
                        </span>
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions">
                            <mat-form-field class="dateInputBox">
                                <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.investmentDate" class="date-field">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="!transaction.isSecuritySelectedFromTransactions">
                            {{ transaction?.exitDate | date:'mediumDate' }}
                        </span>
                        <span *ngIf="transaction.isSecuritySelectedFromTransactions">
                            <mat-form-field class="dateInputBox">
                                <input matInput [matDatepicker]="picker" [(ngModel)]="transaction.exitDate" class="date-field">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="transaction?.pricingCash">
                            {{ transaction?.pricingCash || '-' }}
                        </span>
                        <span style="padding: 10px;" *ngIf="isTransactionEditClicked && transaction.securityType == 'MANUAL_SECURITY'">
                            <i class="fas fa-edit" (click)="editCustomSecurity(editTransactionOverview, transaction, 'PAID');"></i>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="transaction?.pricingPik">
                            {{ transaction?.pricingPik || '-' }}
                        </span>
                        <span style="padding: 10px;" *ngIf="isTransactionEditClicked && transaction.securityType == 'MANUAL_SECURITY'">
                            <i class="fas fa-edit" (click)="editCustomSecurity(editTransactionOverview, transaction, 'ACCRUED');"></i>
                        </span>
                    </td>
                    <td>
                      <span *ngIf="!transaction.isSecuritySelectedFromTransactions">
                        {{ transaction?.interestRate| dynamicDecimalPipe}}%
                      </span>
                      <span *ngIf="transaction.isSecuritySelectedFromTransactions">
                        <mat-form-field class="number-textbox custom-textbox align-right">
                          <input matInput type="text" [(ngModel)]="transaction.interestRate">
                        </mat-form-field>
                      </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>