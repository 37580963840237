<div class="compay-list-wrapper" mat-dialog-content>
    <div class="sticky-select-all">
        <div>
            <mat-checkbox class="select-all" controlledCheckbox #selecAllChkbx color="primary"
                [checkBoxRef]="selecAllChkbx" [checked]="checkBoxState" [indeterminate]="areSomeInSet()"
                (change)="onCheckboxStateChange($event)">
                Select All
            </mat-checkbox>
        </div>
        <div class="selected-sort">
            <mat-slide-toggle labelPosition="before" name="selectedOnlyToggle" [disableRipple]="true"
                [checked]="selectedOnly" (change)="sortBySelectedOnly($event)">Selected only</mat-slide-toggle>
        </div>
    </div>
    <mat-list class="company-list">
        <ng-container *ngFor="let company of allCompanies; trackBy: trackByIdFn">
            <mat-checkbox *ngIf="!selectedOnly" class="company-item" color="primary"
                (change)="toggleCompanySelection($event, company)" [checked]="selectedCompanies.has(company)"
                [ngClass]="{ 'company-error-state': _companyIdsWithConflict[company?.companyId] && selectedCompanies.has(company) }">
                <mat-list-item class="item-contents">
                    <!-- <div > -->
                    <div class="logo-div" [style.visibility]="company?.logo ? 'visible' : 'hidden' ">
                        <img [src]="company?.logo" alt="{{company?.name ? company.name + 'logo' : ''}}" />
                    </div>
                    <div class="company-labels">
                        <div class="company-name">
                            {{ company?.companyName}}
                        </div>
                        <div class="mat-h5 sector">
                            {{ company?.details?.sector }}
                        </div>
                    </div>
                    <!-- </div> -->
                </mat-list-item>
            </mat-checkbox>
            <!-- <ng-template  #default> -->
            <mat-checkbox *ngIf="selectedOnly && selectedCompanies.has(company)" class="company-item" color="primary"
                (change)="toggleCompanySelection($event, company)" [checked]="selectedCompanies.has(company)"
                [ngClass]="{ 'company-error-state': _companyIdsWithConflict[company?.companyId] && selectedCompanies.has(company) }">
                <mat-list-item class="item-contents">
                    <!-- <div > -->
                    <div class="logo-div" [style.visibility]="company?.logo ? 'visible' : 'hidden' ">
                        <img [src]="company?.logo" alt="{{company?.name ? company.name + 'logo' : ''}}" />
                    </div>
                    <div class="company-labels">
                        <div class="company-name">
                            {{ company?.companyName}}
                        </div>
                        <div class="mat-h5 sector">
                            {{ company?.details?.sector }}
                        </div>
                    </div>
                    <!-- </div> -->
                </mat-list-item>
            </mat-checkbox>
            <!-- </ng-template> -->
        </ng-container>
    </mat-list>
</div>