
        
<button mat-raised-button class="secondaryBgButton" (click)="openPopupComment(comments)" style="margin: 10px 10px 0 0;">
  <mat-icon class="comment-icon">visibility</mat-icon><span style="margin-left: 4px;">{{translateService.getLabel("qualitative_analysis")}}</span>
</button>

<ng-template #comments let-comments>
    <div class="modal-header qa-popup">
      <div class="modal-title">
        <span class="popup-header">{{translateService.getLabel("qualitative_analysis")}}</span>
      </div>
      <button #closeBtn type="button" class="close" aria-label="Close" (click)="comments.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" style="height: 60vh; overflow: hidden;">

      <app-qualitative-analysis-v2-add-title-comment (added)="commentAdded($event)"></app-qualitative-analysis-v2-add-title-comment>
      
      <app-qualitative-analysis-v2-list [comments]="allComments"></app-qualitative-analysis-v2-list>
      
    </div>
  </ng-template>