import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Client, DataLoadStatus } from '../../data-recon-pluto.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-drp-page-header',
  templateUrl: './drp-page-header.component.html',
  styleUrls: ['./drp-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrpPageHeaderComponent {

  @Input() clients: Client[];

  @Input() dataLoadStatus: DataLoadStatus;

  @Input() errorCount?: number;

  @Input() loading: boolean;

  @Input() error: string | null;

  @Output() clientSelectionEvent = new EventEmitter<string>();

  @Output() refreshEvent = new EventEmitter<void>();

  clientsFormControl = new FormControl();

  onClientSelection(event) {
    this.clientSelectionEvent.emit(event.value);
  }

  onRefreshClick() {
    this.refreshEvent.emit();
  }
}
