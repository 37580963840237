<div class="row" style="margin: 0;">
    <div class="col" style="padding: 0;">
        <strong (click)="openPopUpModel(currencyConversion)" style="cursor: pointer;">
            {{ userSelectedCurrency ? userSelectedCurrency : sourceCurrency }}
        </strong> /
        <span >
        {{ sourceCurrency }}
        </span> 
    </div>
</div>

<ng-template #currencyConversion let-converter>
    <div class="currency-modal">
      <div class="modal">
        <div class="modal-header">
          <div class="modal-title">
      
          </div>
          <button type="button" class="close" aria-label="Close" (click)="converter.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row" style="margin: 0;">
                <div class="col">
                    <div class="row" style="margin: 0;">
                        <div class="col-3">
                            <span>Currency</span>
                        </div>
                        <div class="col-6">
                            <mat-form-field style="margin: 5px 0; width: 80%;">
                                <mat-select [(ngModel)]="userSelectedCurrency">
                                    <mat-option [value]="cur.id" *ngFor="let cur of currencies">
                                        {{ cur.country }} - {{ cur.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field >
                        </div>
                    </div>
            
                    <div class="row" style="margin: 0;">
                        <div class="col-3">
                            <mat-label>Date </mat-label>
                        </div>
                        <div class="col-6">
                            <mat-form-field style="margin: 5px 0; width: 80%;" (click)="vDatePicker.open()">
                                <input matInput disabled [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="currencyConversionDate">
                                <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col-2">
                    <button mat-raised-button class="primary-bg" (click)="currencyChanged(); converter.dismiss('Cross click')">
                        Submit
                    </button>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
      </div>
    </div>
</ng-template>