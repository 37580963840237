import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss'],
})
export class MappingComponent implements OnInit, OnDestroy {
  showCreateMap = false;
  setAttributeTabActive: boolean;
  setVersionTabActive: boolean;
  setCompanyAssignmentActive: boolean;

  constructor(private activatedRouteSnapShot: ActivatedRoute) {
    const routeSnapShot = this.activatedRouteSnapShot.snapshot;
    const url = routeSnapShot.url.toString();
    console.log(url, '+++++');
    if (
      url.includes('edit-version') ||
      url === 'create-version' ||
      url.includes('version-details')
    ) {
      this.setAttributeTabActive = true;
    }
  }

  ngOnInit(): void {}

  createMap() {
    this.showCreateMap = true;
  }

  onEditMapClick(event) {
    console.log(event);
  }

  onDeleteMapClick(event) {
    console.log(event);
  }

  onDownloadMapClick(event) {
    console.log(event);
  }

  onTabClick(event) {
    console.log(event);
  }

  isVersionActive(rla) {
    console.log(rla, 'RLA');
    return rla.isActive || rla.router.url?.includes('create-version');
  }

  ngOnDestroy(): void {
    this.showCreateMap = false;
  }
}
