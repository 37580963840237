<div class="drp-header-container">
  <div class="grid-item">
      <h6>Client Filter</h6>
      <mat-form-field>
        <mat-label>Select Client</mat-label>
        <mat-select [formControl]="clientsFormControl" (selectionChange)="onClientSelection($event)">
          <mat-option *ngFor="let c of clients" [value]="c.id">{{c.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-icon-button
        color="primary"
        matTooltip="Refresh"
        (click)="onRefreshClick()">
        <mat-icon [ngClass]="{'loading': loading }">autorenew</mat-icon>
      </button>
  </div>

  <div class="grid-item w-300">
    <h6>Gx</h6>
    <div class="item justify">
      <div class="label">Last Updated: </div>
      <div class="value">{{ dataLoadStatus?.gxLastUpdatedTime | date: 'MMM d, y HH:mm:ss' }}</div>
    </div>
    <div class="separator"></div>
    <h6>Pluto</h6>
    <div class="item justify">
      <div class="label">Last ETL job start time:</div>
      <div class="value">{{ dataLoadStatus?.plutoLastJobStartTime | date: 'MMM d, y HH:mm:ss' }}</div>
    </div>
    <div class="item justify">
      <div class="label">Last ETL job success time:</div>
      <div class="value">{{ dataLoadStatus?.plutoLastJobSuccessTime | date: 'MMM d, y HH:mm:ss' }}</div>
    </div>
  </div>

  <div class="grid-item bottom-align">
    <div>
      <div class="item">
        <div class="label">Sync Status:</div>
        <div class="value">
          <mat-basic-chip disabled [ngClass]="dataLoadStatus?.plutoJobStatus == 'STARTED' ? 'secondary-bg' : 'primary-light-bg'">
            {{ dataLoadStatus?.plutoJobStatus || '-' }}
          </mat-basic-chip></div>
      </div>
      <div class="item">
        <div class="label">Error Count:</div>
        <div class="value"><mat-chip color="warn" selected="{{errorCount > 0 }}">{{errorCount}}</mat-chip></div>
      </div>
    </div>
  </div>

  <div class="grid-item">
  </div>

  <div class="error-container" *ngIf="error">
    <mat-icon>error</mat-icon>
    <span>{{error}}</span>
  </div>
</div>
