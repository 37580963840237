<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' headerToBeAppended="Company Master"></nav-menu-header>

<div class="row companyTableCss"  style="padding: 2.5rem;">
    <div class="col-7" id="tableBorder" style="padding: 0px;">
        <div class="sf-table">
            <ejs-grid #valuationDatesGrid 
            [dataSource]='companyListDetails'
             height='auto' width='auto'
             [allowResizing]="true" 
             [allowTextWrap]="true" 
             [allowSorting]="true" 
             [allowFiltering]="true"
             [filterSettings]="gridFilterSettings">
                <e-columns>
                    <e-column headerText='{{ translateService.getLabel("logo")}}' width='40' textAlign='Left'>
                        <ng-template #template let-comp>
                            <img width=40 height=40 [src]="comp.logo" class="companyLogo" (error)="comp.logo = null"
                                *ngIf="comp.logo; else defaultLogo" alt="">
                            <ng-template #defaultLogo>
                                <i class="far fa-building" style="margin-left: 6px; font-size: 30px;"></i>
                            </ng-template>
                        </ng-template>
                
                    </e-column>
                    <e-column field="companyName" headerText='{{ translateService.getLabel("company_name") }}' width='200' textAlign='Left'></e-column>
                    <e-column headerText='{{ translateService.getLabel("edit") }}' width='35' textAlign='center'>
                        <ng-template #template let-data>
                            <i class="fas fa-edit" (click)="openPopUpModel(editPopup, data)"></i>
                        </ng-template>
                    </e-column>
                    <e-column headerText='{{ translateService.getLabel("external_id") }}' width='40' textAlign='center'>
                        <ng-template #template let-data>
                            <i class="fa fa-plus" (click)="openAddExternalCompanyIDPopUpModel(addPopup, data)" aria-hidden="true"></i>
                        </ng-template>
                    </e-column>
                    <e-column headerText='{{ translateService.getLabel("delete") }}' width='35' textAlign='center'>
                        <ng-template #template let-data let-rowIndex="index">
                            <i class="far fa-trash-alt" (click)="deleteCompany(data)"></i>
                        </ng-template>
                    </e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
    <div class="col"></div>
  </div>

  <ng-template class="editPopup" #editPopup let-companyDetails>
    <div class="modal-header">
        <div class="modal-title">
            <h4>{{translateService.getLabel("edit_company_name")}}</h4>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="companyDetails.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class=" modal-body">
        <mat-form-field class="width-100">
            <input matInput style="text-align:left;" [(ngModel)]="editCompanyData.companyName" type="text" name="namefield">
        </mat-form-field>                                                               
    </div>
    <div class="modal-footer">
        <button class="btn-custom-primary"  (click)="saveCompanyMasterChanges(companyDetails); companyDetails.dismiss('Cross click')">{{translateService.getLabel("save")}}</button>
    </div>
</ng-template>

<ng-template class="addPopup" #addPopup let-companyExternalDetails>
    <div class="modal-header">
        <div class="modal-title">
            <h4>{{translateService.getLabel("add_external_company_id_to")}} <b class="addPopupCompanyName">{{addExternalCompanyIdData.companyName}}</b></h4>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="companyExternalDetails.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class=" modal-body">
        <mat-form-field class="width-100">
            <input matInput style="text-align:left;" [(ngModel)]="addExternalCompanyIdData.externalId" type="text" name="namefield">
        </mat-form-field>                                                               
    </div>
    <div class="modal-footer">
        <button class="btn-custom-primary"  (click)="saveExternalIDForCompanyMasterChanges(companyDetails); companyExternalDetails.dismiss('Cross click')">{{translateService.getLabel("save")}}</button>
    </div>
</ng-template>
