<i class="fas fa-tasks" [autoClose]="false" data-toggle="popover" data-placement="right" [ngbPopover]="checkListContent" [disablePopover]="checkListService.companies.length == 0" (click)="refreshChecklist();"></i>
<span class="custom-red-badge-2" *ngIf="checkListService.companies.length > 0"></span>

<ng-template #checkListContent>
    <div style="min-width: 345px; max-height: 90vh; overflow-y: auto; border-radius: 6px;">
        <div class="row" style="background-color: white; margin: 0px;">
            <div class="col-12 align-left" style="margin: 15px 0 0 0; padding: 0;">
                <span style="font-size: medium; margin-left: 7px;"> 
                    <b> {{ translateService.getLabel("approval_checklist") }} </b> 
                    <em class="fa fa-info-circle info-icon primary-color-theme" style="font-size: small !important;" matTooltip="{{translateService.getLabel('approval_system_checklist_icon')}}"></em>
                </span>
            </div>
        </div>
        
        <div style="padding: 0 7px 0px 7px">
            <app-company-list-in-check-list [companylistForCheckList]="companylistForCheckList" (refreshChecklistOnRejection)="refreshChecklistOnRejection()"></app-company-list-in-check-list>
        </div>
    </div>    
</ng-template>