import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CCAService } from "../cca.service";
import { VolitilityComponent } from "../volitility/volitility.component";
import { RiskFreeRateComponent } from "../risk-free-rate/risk-free-rate.component";

@Component({
  selector: "app-opm-input",
  templateUrl: "./opm-input.component.html",
  styleUrls: ["./opm-input.component.scss"],
})
export class OpmInputComponent implements OnInit {
  blackAndSholesData: any = {};
  constructor(
    public dialogRef: MatDialogRef<OpmInputComponent>,
    private ccaService: CCAService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.blackAndSholesData = this.data;
    this.ccaService.getVolitilityInput().subscribe((volitility: any) => {
      if (volitility || volitility === 0) {
        this.blackAndSholesData.volatility = volitility;
      }
    });
    this.ccaService.getRiskFreeRateInput().subscribe((riskFreeRate: any) => {
      if (riskFreeRate || riskFreeRate === 0) {
        this.blackAndSholesData.riskFreeInterestRate = riskFreeRate;
      }
    });
  }

  saveInputs() {
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ccaService.setBlackAndScholesInput(this.blackAndSholesData);
      }
    });
  }

  editAnnualisedVolitility() {
    this.dialog.open(VolitilityComponent, {
      width: "50vw",
      data: "",
      disableClose: true,
    });
  }

  editRiskFreeRate() {
    this.dialog.open(RiskFreeRateComponent, {
      width: "50vw",
      data: "",
      disableClose: true,
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
