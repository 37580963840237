
<div *ngIf="debtPortfolioService.isDebtLoaded">

<!-- <div class="card" style="padding: 10px; height: 100%; overflow: hidden;" *ngIf="portfolioSummaryReady">
  <div class="row" class="valuation-dropdown">
    <mat-form-field class="currencySelect" style="float: right;">
      <mat-select [(ngModel)]="portfolioService.selectedCurrency" (ngModelChange)="initExchangeRate()">
        <mat-option *ngFor="let currency of portfolioService.currencyList" value="{{ currency.id }}">
          {{ currency.country }} - {{ currency.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="add-portfolio-company-bubble" (click)="openPopupModal(applicationContent)">
    <div>
    <img src="assets/imgs/add_icon.svg">
    </div>
    <div class="new-portfolio-header">
      Click to Add a New Debt Holding
    </div>
  </div>
</div> -->


<div class="card" style="padding: 0.55vw;">
  <div>
    <div class="row" class="valuation-dropdown">
      <mat-form-field class="currencySelect" style="float: right;">
        <mat-select [(ngModel)]="portfolioServiceV2.selectedCurrency" (ngModelChange)="changeReportingCurrency()">
          <mat-option *ngFor="let currency of currencyExchange.currencyList" value="{{ currency.id }}">{{ currency.country }} - {{ currency.id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row" style="padding: 0.55vw; margin-bottom: 1%;">
      <div class="col-5">
        <span class="main-heading">{{translateService.getLabel("debt_portfolio")}}</span>
        <mat-form-field class="custom-input-select" 
        [matTooltip]="translateService.getLabel('portfolio_summary_ason_date_dropdown')"
        matTooltipPosition = "above">
            <mat-select [disabled]="!payloadForIssuerLoaded" placeholder="this.translateService.getLabel('latest_ValuationDate_Label')" [(ngModel)]="selectedValDate"
                (selectionChange)="onAsOnDateChange($event)">
                <mat-option *ngFor="let data of allValDates" [value]="data">
                  <span>
                    {{ data }}
                  </span>
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="padding: 0.55vw; margin-bottom: 1%;">
      <!-- Skeleton Loader -->
      <div class="col-5" [hidden]="debtPortfolioService.debtColumnChart">
        <ngx-skeleton-loader count="1" appearance="line"
          [theme]="{ height: '500px', 'border-radius': '6px', 'background-color': '#F4F5FD', 'box-shadow': '0px 3px 6px #e9ecf9;' }">
        </ngx-skeleton-loader>
      </div>
    
      <div class="col-5 border-shadow-class" style="margin-left: 1.11vw;" [hidden]="!debtPortfolioService.debtColumnChart">
        <div class="valuation-heading">{{translateService.getLabel("valuation_by_sectors")}}</div>
        <div [chart] = "debtPortfolioService.debtColumnChart" id="column-chart-container" style="padding-top: 1.11vw;"></div>
      </div>

      <div class="col-2" style="margin-right: 0.33vw;">
        <!-- Skeleton Loader -->
        <ng-container *ngIf="(headerBoxgrossIRR == undefined && totalPortfolioValue == undefined && headerBoxpercentOfPar == undefined)">
          <div class="skeleton-bubble" *ngIf="!disableClientSpecificFields">
            <ngx-skeleton-loader  count="1" appearance="line"
            [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '3vw',
            'width': '60%' }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader  count="1" appearance="line"
              [theme]="{ height: '1vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '0.27vw',
              'width': '50%', 'margin-bottom': '1vw' }">
            </ngx-skeleton-loader>
          </div>

          <div class="skeleton-bubble">
            <ngx-skeleton-loader  count="1" appearance="line"
            [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '3vw',
            'width': '60%' }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader  count="1" appearance="line"
              [theme]="{ height: '1vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '0.27vw',
              'width': '50%', 'margin-bottom': '1vw' }">
            </ngx-skeleton-loader>
          </div>

          <div class="skeleton-bubble">
            <ngx-skeleton-loader  count="1" appearance="line"
            [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '3vw',
            'width': '60%' }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader  count="1" appearance="line"
              [theme]="{ height: '1vw', 'border-radius': '6px', 'background-color': '#eaecf9', 'margin-top': '0.27vw',
              'width': '50%', 'margin-bottom': '1vw' }">
            </ngx-skeleton-loader>
          </div>
        </ng-container>

        <ng-container *ngIf="(headerBoxgrossIRR != undefined && totalPortfolioValue != undefined && headerBoxpercentOfPar != undefined)">
          <!-- <div class="row bubble" *ngIf="!disableClientSpecificFields">
            <p class="col-12 portfolio-bubble-heading">
              {{ headerBoxgrossIRR | number: "1.1-1" }}%
            </p>
            <p class="col-12 portfolio-total-header">
              {{translateService.getLabel("gross_irr")}}
            </p>
          </div> -->

          <div class="row bubble">
            <p class="col-12 portfolio-bubble-heading">
                {{this.portfolioServiceV2.selectedCurrency}} {{totalPortfolioValue | dynamicDecimalPipe}} {{totalPortfolioValueUnit}}
            </p>
            <p class="col-12 portfolio-total-header">
              {{translateService.getLabel("total_invested_amount")}}
            </p>
          </div>

          <div class="row bubble">
            <p class="col-12 portfolio-bubble-heading">
              {{ headerBoxpercentOfPar | dynamicDecimalPipe }}%
            </p>
            <p class="col-12 portfolio-total-header">
              {{translateService.getLabel("percent_of_par")}}
            </p>
          </div>

          <div class="row bubble" style="margin-bottom: 0;">
            <p class="col-12 portfolio-bubble-heading">
              {{ percentOfParChange | dynamicDecimalPipe }}%
            </p>
            <p class="col-12 portfolio-total-header">
              {{translateService.getLabel("percent_of_par_change")}}
            </p>
          </div>
        </ng-container>
      </div>

      <!-- Skeleton Loader -->
      <div class="col" [hidden]="debtPortfolioService.debtMapChart">
        <ngx-skeleton-loader count="1" appearance="line"
          [theme]="{ height: '500px', 'border-radius': '6px', 'background-color': '#F4F5FD', 'box-shadow': '0px 3px 6px #e9ecf9;' }">
        </ngx-skeleton-loader>
      </div>      

      <div class="col border-shadow-class" [hidden]="!debtPortfolioService.debtMapChart" style="margin-right: 1.11vw;">
        <div class="valuation-heading">{{translateService.getLabel("geography_ps")}}</div>
        <div [chart]="debtPortfolioService.debtMapChart" id="map-chart" style="width: 27.9vw; margin: auto;">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="payloadForIssuerLoaded">
        <app-debt-portfolio-company [triggerCompanyTableChange]="triggerCompanyTableChange" [onDeleteCompanies]="onDeleteCompanies"></app-debt-portfolio-company>
      </div>
    </div>

    <!-- Skeleton Loader -->
    <div style="padding: 0.55vw; margin-bottom: 1%;" *ngIf="(!securityDetailsReportingCurrencyCurrent?.length && !securityDetailsReportingCurrencyExited?.length)">
      <ngx-skeleton-loader count="1" appearance="line"
        [theme]="{ height: '2vw', 'width': '30%', 'border-radius': '6px', 'background-color': '#e9ecf9' }">
      </ngx-skeleton-loader>

      <ngx-skeleton-loader count="1" appearance="line"
        [theme]="{ height: '2vw', 'width': '10vw', 'border-radius': '6px', 'background-color': '#e9ecf9',
        'white-space': 'nowrap', 'margin-left' : '51vw' }">
      </ngx-skeleton-loader>

      <ngx-skeleton-loader count="2" appearance="line"
        [theme]="{ height: '2vw', 'width': '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9',
        'white-space': 'nowrap', 'margin-left' : '1vw' }">
      </ngx-skeleton-loader>

      <!-- Table Skeleton -->
      <div class="skeleton-table">
        <ngx-skeleton-loader count="1" [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
      </div>
    </div>

    <div [hidden]="!(securityDetailsReportingCurrencyCurrent?.length || securityDetailsReportingCurrencyExited?.length)">
      <div class="row" style="margin: auto 0 1%;">
        <div class="col-6">
          <span class="main-heading">{{translateService.getLabel("security_level")}} - {{translateService.getLabel("reporting_currency")}} ({{ portfolioServiceV2.selectedCurrency }})</span>
        </div>
        <div class="col-6 company-search-dropdown" style="text-align: right;">
          <!-- <mat-form-field style="width: 16.74vw; margin-right: 0.84vw;">
            <input matInput type="text" placeholder="Search" [(ngModel)]="searchString"
              (ngModelChange)="searchCompanies()">
            <mat-icon matSuffix>
              <i class="fas fa-search" style="color: #9D9D9D;"></i>
            </mat-icon>
          </mat-form-field> -->
          <button class="btn btn-add-company" (click)="openPopupModal(applicationContent)">{{translateService.getLabel("add_security")}}</button>
          <button class="btn-delete-icon" (click)="openPopupModal(deleteCompanyContent)">
            <img alt="Delete" [src]="imageLoadService.getImage('delete.svg')">
          </button>
        </div>
      </div>

      <div class="row pf-row pf-header-row">
        <div class="col pf-expand-icon"></div>
        <div class="col-8 pf-header">{{translateService.getLabel("investment_status")}}</div>
        <div class="col pf-numbers"></div>
        <div class="col pf-numbers">{{translateService.getLabel("value")}} (Mn)</div>
        <div class="col pf-numbers">{{translateService.getLabel("percent_of_par")}}</div>
        <!-- <div class="col pf-numbers">{{translateService.getLabel("gross_irr")}}</div> -->
      </div>

      <div class="row pf-row pf-normal-row">
        <div class="col pf-expand-icon">
          <!--expance/collapse-->
          <i class="fas fa-chevron-right cursor-pointer" (click)="portfolio.current.expandPortfolio = !portfolio.current.expandPortfolio"
            *ngIf="!portfolio.current.expandPortfolio"></i>
          <i class="fas fa-chevron-down cursor-pointer" (click)="portfolio.current.expandPortfolio = !portfolio.current.expandPortfolio"
            *ngIf="portfolio.current.expandPortfolio"></i>
        </div>

        <div class="col-8 pf-header">{{portfolio.current.name}}</div>
        <div class="col pf-numbers"></div>
        <div class="col pf-numbers">
          <span *ngIf="portfolio.current.value != undefined" [RawDataDirective]="portfolio.current.value">{{ portfolio.current.value | dynamicDecimalPipe}}</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.current.value == undefined"></mat-spinner>
        </div>
        <div class="col pf-numbers">
          <span *ngIf="portfolio.current.percentOfPar != undefined" [RawDataDirective]="portfolio.current.percentOfPar">{{ portfolio.current.percentOfPar | dynamicDecimalPipe}}%</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.current.percentOfPar  == undefined"></mat-spinner>
        </div>
        <!-- <div class="col pf-numbers">
          <span *ngIf="portfolio.current.grossIRR != undefined" [RawDataDirective]="portfolio.current.grossIRR">{{ portfolio.current.grossIRR  | number: "1.1-1" }}%</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.current.grossIRR == undefined"></mat-spinner>
        </div> -->
      </div>

      <!-- Summary Table With User Selected Currency Included -->
      <div class="row pf-row pf-normal-row" style="margin: 0 10px 10px 10px" [hidden]="!portfolio.current.expandPortfolio">
        <div class="table table-condensed" id="container" class="investment-summary-grid">
        <ejs-grid #investmentSummaryGridCurrent
          [dataSource]="securityDetailsReportingCurrencyCurrent"
          [allowResizing]="true"
          [allowTextWrap]="true"
          [allowGrouping]="true"
          [allowExcelExport]="true"
          [allowSorting]="true"
          [allowReordering]="true"
          [allowPaging]="true"
          [pageSettings]="gridPageSettings"
          [showColumnChooser]="true"
          [toolbar]="gridToolbar"
          [allowFiltering]="true"
          [filterSettings]="gridFilterSettings"
          (actionComplete)="filteringReportingCurrency($event, 'CURRENT')"
          (rowSelected)="companyRowSelected($event)">
          <e-columns>

            <e-column field='logo' [headerText]='translateService.getLabel("logo")' width='50px'>
              <ng-template #template let-comp>
                <img class="companyLogo" [src]="comp.logoURL" (error)="comp.logoURL = null" *ngIf="comp.logoURL; else defaultLogo">
                <ng-template #defaultLogo>
                  <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
                </ng-template>
              </ng-template>
            </e-column>

            <e-column field='issuerName' [headerText]='translateService.getLabel("issuer_name")' width='70px'>
              <ng-template #template let-comp>
                <!-- <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i> -->
                <span style="font-weight: 500;">{{comp.issuerName}}</span>
              </ng-template>
            </e-column>

            <e-column field='investmentDate' [headerText]='translateService.getLabel("investment_date")' width='80px'>
              <ng-template #template let-comp>
                {{ comp.investmentDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='investmentStatus' [headerText]='translateService.getLabel("investment_status")' width='80px'>
              <ng-template #template let-comp>
                {{ comp.investmentStatus }}
              </ng-template>
            </e-column>

            <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px'>
              <ng-template #template let-comp>
                {{ comp.valuationDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='exitDate' [headerText]='translateService.getLabel("exit_date")' width='70px'>
              <ng-template #template let-comp>
                {{ comp.exitDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='currency' [headerText]='translateService.getLabel("currency")' width='50px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.currency">{{comp.currency}}</ng-template>
              </ng-template>
            </e-column>

            <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='70px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.securityName">{{comp.securityName}}</ng-template>
              </ng-template>
            </e-column>

            <!-- <e-column field='seniority' headerText='Seniority' width='70px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.seniority">{{comp.seniority | titlecase}}</ng-template>
              </ng-template>
            </e-column> -->

            <e-column field='value' [headerText]='translateService.getLabel("value") + " (Mn)"' width='70px' textAlign='Right'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.value && comp.value != 0" [ngIfElse]="defaultValue">
                  <span [RawDataDirective]="comp.value">
                    {{comp.value | dynamicDecimalPipe}}
                  </span>
                </ng-template>
                <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}</ng-template>
              </ng-template>
            </e-column>

            <e-column field='percentOfPar' [headerText]='translateService.getLabel("percent_of_par")' width='60px' textAlign='Right'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.percentOfPar && comp.percentOfPar != 0" [ngIfElse]="defaultValue">
                  <ng-container *ngIf="(comp.percentOfPar | typeOf) === 'number'">
                    <span [RawDataDirective]="comp.percentOfPar">
                      {{ comp.percentOfPar | dynamicDecimalPipe}}%
                    </span>
                  </ng-container>
                  <ng-container *ngIf="(comp.percentOfPar | typeOf) === 'string'" >
                    <span [RawDataDirective]="comp.percentOfPar">
                      {{ comp.percentOfPar}}
                    </span>
                  </ng-container>
                </ng-template>
                <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe }}%</ng-template>
              </ng-template>
            </e-column>

            <e-column field='grossIRR' [visible]='false' [headerText]='translateService.getLabel("gross_irr")' width='60px' textAlign='Right'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.grossIRR && comp.grossIRR > 0">
                    <div>
                      <span [RawDataDirective]="comp.grossIRR">
                        {{comp.grossIRR | dynamicDecimalPipe}}%
                      </span>
                    </div>
                </ng-template>
                <ng-template [ngIf]="comp.grossIRR && comp.grossIRR < 0">
                  <div style="color:red">
                    <span [RawDataDirective]="comp.grossIRR">
                      {{comp.grossIRR | dynamicDecimalPipe}}%
                    </span>
                  </div>
                </ng-template>
                <ng-template [ngIf]="!comp.grossIRR || comp.grossIRR == 0">{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
              </ng-template>
            </e-column>

            <e-column field='syntheticCreditRating' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("credit_rating")' width='70px'>
              <ng-template #template let-comp>
                {{comp.syntheticCreditRating }}
              </ng-template>
            </e-column>

            <e-column field='performanceClass' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("performance_class")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.performanceClass | titlecase }}
              </ng-template>
            </e-column>
    
            <e-column field='couponType' [visible]='false' [headerText]='translateService.getLabel("coupon_type")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.couponType }}
              </ng-template>
            </e-column>
            
            <e-column field='baseRateIndex' [visible]='false' [headerText]='translateService.getLabel("base_rate_index")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.baseRateIndex }}
              </ng-template>
            </e-column>

          </e-columns>

          <e-aggregates>
            <e-aggregate>
              <e-columns>
                  <e-column field="issuerName" type="Custom">
                    <ng-template #footerTemplate let-data>
                      <span class="total-row">
                        {{translateService.getLabel("total")}}
                      </span>

                    </ng-template>
                  </e-column>

                  <e-column field="value" textAlign='Right' type="Custom">
                    <ng-template #footerTemplate let-data>
                      <span *ngIf="portfolio.current.value != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.current.value">
                        {{ portfolio.current.value | dynamicDecimalPipe}}
                      </span>

                      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.current.value == undefined"></mat-spinner>
                    </ng-template>
                  </e-column>

                  <e-column field="percentOfPar" textAlign='Right' type="Custom">
                    <ng-template #footerTemplate let-data>
                      <span *ngIf="portfolio.current.percentOfPar != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.current.percentOfPar">
                        {{ portfolio.current.percentOfPar | dynamicDecimalPipe}}%
                      </span>

                      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.current.percentOfPar == undefined"></mat-spinner>
                    </ng-template>
                  </e-column>

                  <e-column field="grossIRR" textAlign='Right' type="Custom">
                    <ng-template #footerTemplate let-data>
                      <span *ngIf="portfolio.current.grossIRR != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.current.grossIRR">
                        {{ portfolio.current.grossIRR  | dynamicDecimalPipe }}%
                      </span>

                      <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf="portfolio.current.grossIRR  == undefined"></mat-spinner>
                    </ng-template>
                  </e-column>

              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
        </div>
      </div>

      <div class="row pf-row pf-normal-row">
        <div class="col pf-expand-icon">
          <!--expance/collapse-->
          <i class="fas fa-chevron-right cursor-pointer" (click)="portfolio.exited.expandPortfolio = !portfolio.exited.expandPortfolio"
            *ngIf="!portfolio.exited.expandPortfolio"></i>
          <i class="fas fa-chevron-down cursor-pointer" (click)="portfolio.exited.expandPortfolio = !portfolio.exited.expandPortfolio"
            *ngIf="portfolio.exited.expandPortfolio"></i>
        </div>

        <div class="col-8 pf-header">{{portfolio.exited.name}}</div>
        <div class="col pf-numbers"></div>
        <div class="col pf-numbers">
          <span *ngIf="portfolio.exited.value != undefined" [RawDataDirective]="portfolio.exited.value">{{ portfolio.exited.value | dynamicDecimalPipe}}</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.exited.value == undefined"></mat-spinner>
        </div>
        <div class="col pf-numbers">
          <span >
            <ng-container *ngIf="(portfolio.exited.percentOfPar | typeOf) === 'number'">
              <span [RawDataDirective]="portfolio.exited.percentOfPar">
                {{ portfolio.exited.percentOfPar | dynamicDecimalPipe}}%
              </span>
          </ng-container>
          <ng-container *ngIf="(portfolio.exited.percentOfPar | typeOf) === 'string'" >
            {{ portfolio.exited.percentOfPar}}
          </ng-container>
          </span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.exited.percentOfPar == undefined"></mat-spinner>
        </div>
        <!-- <div class="col pf-numbers">
          <span *ngIf="portfolio.exited.grossIRR != undefined" [RawDataDirective]="portfolio.exited.grossIRR">{{ portfolio.exited.grossIRR | number: "1.1-1" }}%</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.exited.grossIRR == undefined"></mat-spinner>
        </div> -->
      </div>

      <div class="row pf-row pf-normal-row" style="margin: 0 10px 10px 10px" [hidden]="!portfolio.exited.expandPortfolio">
        <div class="table table-condensed" id="container" class="investment-summary-grid" [hidden]="!securityDetailsReportingCurrencyExited?.length">
          <ejs-grid #investmentSummaryGridExited
            [dataSource]="securityDetailsReportingCurrencyExited"
            [allowResizing]="true"
            [allowTextWrap]="true"
            [allowGrouping]="true"
            [allowExcelExport]="true"
            [allowSorting]="true"
            [allowReordering]="true"
            [allowPaging]="true"
            [pageSettings]="gridPageSettings"
            [showColumnChooser]="true"
            [toolbar]="gridToolbar"
            [allowFiltering]="true"
            [filterSettings]="gridFilterSettings"
            (actionComplete)="filteringReportingCurrency($event, 'EXITED')"
            (rowSelected)="companyRowSelected($event)">
            <e-columns>
    
              <e-column field='logo' [headerText]='translateService.getLabel("logo")' width='50px'>
                <ng-template #template let-comp>
                  <img class="companyLogo" [src]="comp.logoURL" (error)="comp.logoURL = null" *ngIf="comp.logoURL; else defaultLogo">
                  <ng-template #defaultLogo>
                    <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
                  </ng-template>
                </ng-template>
              </e-column>
    
              <e-column field='issuerName' [headerText]='translateService.getLabel("issuer_name")' width='70px'>
                <ng-template #template let-comp>
                  <!-- <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i> -->
                  <span style="font-weight: 500;">{{comp.issuerName}}</span>
                </ng-template>
              </e-column>
    
              <e-column field='investmentDate' [headerText]='translateService.getLabel("investment_date")' width='80px'>
                <ng-template #template let-comp>
                  {{ comp.investmentDate | date: "mediumDate" }}
                </ng-template>
              </e-column>

              <e-column field='investmentStatus' [headerText]='translateService.getLabel("investment_status")' width='80px'>
                <ng-template #template let-comp>
                  {{ comp.investmentStatus }}
                </ng-template>
              </e-column>

              <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px'>
                <ng-template #template let-comp>
                  {{ comp.valuationDate | date: "mediumDate" }}
                </ng-template>
              </e-column>
    
              <e-column field='exitDate' [headerText]='translateService.getLabel("exit_date")' width='70px'>
                <ng-template #template let-comp>
                  {{ comp.exitDate | date: "mediumDate" }}
                </ng-template>
              </e-column>
    
              <e-column field='currency' [headerText]='translateService.getLabel("currency")' width='50px'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.currency">{{comp.currency}}</ng-template>
                </ng-template>
              </e-column>
    
              <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='70px'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.securityName">{{comp.securityName}}</ng-template>
                </ng-template>
              </e-column>
    
              <!-- <e-column field='seniority' headerText='Seniority' width='70px'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.seniority">{{comp.seniority | titlecase}}</ng-template>
                </ng-template>
              </e-column> -->
    
              <e-column field='value' [headerText]='translateService.getLabel("value") + " (Mn)"' width='70px' textAlign='Right'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.value && comp.value != 0" [ngIfElse]="defaultValue">
                    <span [RawDataDirective]="comp.value">
                      {{comp.value | dynamicDecimalPipe}}
                    </span>
                  </ng-template>
                  <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}</ng-template>
                </ng-template>
              </e-column>
    
              <e-column field='percentOfPar' [headerText]='translateService.getLabel("percent_of_par")' width='60px' textAlign='Right'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.percentOfPar && comp.percentOfPar != 0" [ngIfElse]="defaultValue">
                    <span [RawDataDirective]="comp.percentOfPar">
                      {{comp.percentOfPar | dynamicDecimalPipe}}%
                    </span>
                  </ng-template>
                  <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
    
              <e-column field='grossIRR' [headerText]='translateService.getLabel("gross_irr")' width='60px' textAlign='Right' [visible]='false'>
                <ng-template #template let-comp>
                  <ng-template [ngIf]="comp.grossIRR && comp.grossIRR > 0">
                      <div>
                        <span [RawDataDirective]="comp.grossIRR">
                          {{comp.grossIRR | dynamicDecimalPipe}}%
                        </span>
                      </div>
                  </ng-template>
                  <ng-template [ngIf]="comp.grossIRR && comp.grossIRR < 0">
                    <div style="color:red">
                      <span [RawDataDirective]="comp.grossIRR">
                        {{comp.grossIRR | dynamicDecimalPipe}}%
                      </span>
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="!comp.grossIRR || comp.grossIRR == 0">{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
                </ng-template>
              </e-column>
    
              <e-column field='syntheticCreditRating' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("credit_rating")' width='70px'>
                <ng-template #template let-comp>
                  {{comp.syntheticCreditRating }}
                </ng-template>
              </e-column>
    
              <e-column field='performanceClass' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("performance_class")' width='70px' textAlign='Left'>
                <ng-template #template let-comp>
                  {{comp.performanceClass | titlecase }}
                </ng-template>
              </e-column>

              <e-column field='couponType' [visible]='false' [headerText]='translateService.getLabel("coupon_type")' width='70px' textAlign='Left'>
                <ng-template #template let-comp>
                  {{comp.couponType }}
                </ng-template>
              </e-column>

              <e-column field='baseRateIndex' [visible]='false' [headerText]='translateService.getLabel("base_rate_index")' width='70px' textAlign='Left'>
                <ng-template #template let-comp>
                  {{comp.baseRateIndex }}
                </ng-template>
              </e-column>
    
            </e-columns>
    
            <e-aggregates>
              <e-aggregate>
                <e-columns>
                    <e-column field="issuerName" type="Custom">
                      <ng-template #footerTemplate let-data>
                        <span class="total-row">
                          {{translateService.getLabel("total")}}
                        </span>
    
                      </ng-template>
                    </e-column>
    
                    <e-column field="value" textAlign='Right' type="Custom">
                      <ng-template #footerTemplate let-data>
                        <span *ngIf="portfolio.exited.value != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.exited.value">
                          {{ portfolio.exited.value | dynamicDecimalPipe}}
                        </span>
    
                        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.exited.value == undefined"></mat-spinner>
                      </ng-template>
                    </e-column>
    
                    <e-column field="percentOfPar" textAlign='Right' type="Custom">
                      <ng-template #footerTemplate let-data>
                        <span *ngIf="portfolio.exited.percentOfPar != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.exited.percentOfPar">
                          {{ portfolio.exited.percentOfPar | dynamicDecimalPipe}}%
                        </span>
    
                        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "portfolio.exited.percentOfPar == undefined"></mat-spinner>
                      </ng-template>
                    </e-column>
    
                    <e-column field="grossIRR" textAlign='Right' type="Custom">
                      <ng-template #footerTemplate let-data>
                        <span *ngIf="portfolio.exited.grossIRR != undefined" class="currency-table-footer" [RawDataDirective]="portfolio.exited.grossIRR">
                          {{ portfolio.exited.grossIRR  | dynamicDecimalPipe }}%
                        </span>
    
                        <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf="portfolio.exited.grossIRR  == undefined"></mat-spinner>
                      </ng-template>
                    </e-column>
    
                </e-columns>
              </e-aggregate>
            </e-aggregates>
          </ejs-grid>
        </div>
      </div>

      <div class="row pf-row pf-footer-row">
        <div class="col pf-expand-icon"></div>
        <div class="col-8 pf-header">{{translateService.getLabel("total")}}</div>
        <div class="col pf-numbers"></div>
        <div class="col pf-numbers">
          <span *ngIf="totalPortfolioValueTable != undefined" [RawDataDirective]="totalPortfolioValueTable">{{ totalPortfolioValueTable | dynamicDecimalPipe}}</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "totalPortfolioValueTable == undefined"></mat-spinner>
        </div>
        <div class="col pf-numbers">
          <span *ngIf="percentOfPar != undefined" [RawDataDirective]="percentOfPar">{{ percentOfPar | dynamicDecimalPipe}}%</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "percentOfPar == undefined"></mat-spinner>
        </div>
        <!-- <div class="col pf-numbers">
          <span *ngIf="grossIRR != undefined" [RawDataDirective]="grossIRR">{{ grossIRR | number: "1.1-1" }}%</span>
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter] = "20" *ngIf = "grossIRR == undefined"></mat-spinner>
        </div> -->
      </div>
    </div>

    <!-- Summary Table in Valuation Currency -->

    <!-- Skeleton Loader -->
    <div *ngIf="!isSecurityDetailsLocalCurrencyCurrentLoaded" style="padding: 0.56vw; margin-top: 2%;">
      <ngx-skeleton-loader count="1" appearance="line"
        [theme]="{ height: '2vw', 'width': '30%', 'border-radius': '6px', 'background-color': '#e9ecf9' }">
      </ngx-skeleton-loader>
        
      <!-- Table Skeleton -->
      <div class="skeleton-table">
        <ngx-skeleton-loader count="1" [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
  
        <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
        'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
      </div>
    </div>

    <div style="padding: 0.56vw; margin-top: 2%;" [hidden]="!isSecurityDetailsLocalCurrencyCurrentLoaded">
      <span class="main-heading">{{translateService.getLabel("current_security_level")}} - {{translateService.getLabel("local_currency")}}</span>
    </div>

    <!-- Current Data - Local Currency -->
    <div class="table table-condensed" id="container" class="investment-summary-grid" [hidden]="!isSecurityDetailsLocalCurrencyCurrentLoaded">
      <ejs-grid #localCurrencyInvestmentSummaryGridCurrent
        [dataSource]="securityDetailsLocalCurrencyCurrent"
        [allowResizing]="true"
        [allowTextWrap]="true"
        [allowGrouping]="true"
        [allowExcelExport]="true"
        [allowSorting]="true"
        [allowReordering]="true"
        [allowPaging]="true"
        [pageSettings]="gridPageSettings"
        [showColumnChooser]="true"
        [toolbar]="gridToolbar"
        [allowFiltering]="true"
        [filterSettings]="gridFilterSettings"
        (rowSelected)="companyRowSelected($event)"
        (actionComplete)="localCurrencyAction($event, 'CURRENT')">
        <e-columns>

          <e-column field='logo' [headerText]='translateService.getLabel("logo")' width='50px'>
            <ng-template #template let-comp>
              <img class="companyLogo" [src]="comp.logoURL" (error)="comp.logoURL = null" *ngIf="comp.logoURL; else defaultLogo">
              <ng-template #defaultLogo>
                <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
              </ng-template>
            </ng-template>
          </e-column>

          <e-column field='issuerName' [headerText]='translateService.getLabel("issuer_name")' width='70px'>
            <ng-template #template let-comp>
              <!-- <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i> -->
              <span style="font-weight: 500;">{{comp.issuerName}}</span>
            </ng-template>
          </e-column>

          <e-column field='investmentDate' [headerText]='translateService.getLabel("investment_date")' width='80px'>
            <ng-template #template let-comp>
              {{ comp.investmentDate | date: "mediumDate" }}
            </ng-template>
          </e-column>

          <e-column field='investmentStatus' [headerText]='translateService.getLabel("investment_status")' width='80px'>
            <ng-template #template let-comp>
              {{ comp.investmentStatus }}
            </ng-template>
          </e-column>

          <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px'>
            <ng-template #template let-comp>
              {{ comp.valuationDate | date: "mediumDate" }}
            </ng-template>
          </e-column>

          <e-column field='exitDate' [headerText]='translateService.getLabel("exit_date")' width='70px'>
            <ng-template #template let-comp>
              {{ comp.exitDate | date: "mediumDate" }}
            </ng-template>
          </e-column>

          <e-column field='currency' [headerText]='translateService.getLabel("currency")' width='50px'>
            <ng-template #template let-comp>
              <ng-template [ngIf]="comp.currency && comp.currency != 0">{{comp.currency }}</ng-template>
            </ng-template>
          </e-column>

          <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='70px'>
            <ng-template #template let-comp>
              <ng-template [ngIf]="comp.securityName">{{comp.securityName}}</ng-template>
            </ng-template>
          </e-column>

          <!-- <e-column field='seniority' headerText='Seniority' width='70px'>
            <ng-template #template let-comp>
              <ng-template [ngIf]="comp.seniority">{{comp.seniority | titlecase}}</ng-template>
            </ng-template>
          </e-column> -->

          <e-column field='value' [headerText]='translateService.getLabel("value") + " (Mn)"' width='70px' textAlign='Right'>
            <ng-template #template let-comp>
              <ng-template [ngIf]="comp.value && comp.value != 0" [ngIfElse]="defaultValue">
                <span [RawDataDirective]="comp.value">
                  {{comp.value | dynamicDecimalPipe}}
                </span>
              </ng-template>
              <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}</ng-template>
            </ng-template>
          </e-column>

          <e-column field='percentOfPar' [headerText]='translateService.getLabel("percent_of_par")' width='60px' textAlign='Right'>
            <ng-template #template let-comp>
            <ng-template [ngIf]="comp.percentOfPar && comp.percentOfPar != 0" [ngIfElse]="defaultValue">
              <ng-container *ngIf="(comp.percentOfPar | typeOf) === 'number'">
                <span [RawDataDirective]="comp.percentOfPar">
                  {{ comp.percentOfPar | dynamicDecimalPipe}}%
                </span>
              </ng-container>
              <ng-container *ngIf="(comp.percentOfPar | typeOf) === 'string'" >
                {{ comp.percentOfPar}}
              </ng-container>
            </ng-template>
            <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
            </ng-template>
          </e-column>

          <e-column field='grossIRR' [headerText]='translateService.getLabel("gross_irr")' width='60px' textAlign='Right' [visible]='false'>
            <ng-template #template let-comp>
              <ng-template [ngIf]="comp.grossIRR && comp.grossIRR >= 0">
                <div>
                  <span [RawDataDirective]="comp.grossIRR">
                    {{comp.grossIRR | dynamicDecimalPipe}}%
                  </span>
                </div>
            </ng-template>
            <ng-template [ngIf]="comp.grossIRR && comp.grossIRR < 0">
              <div style="color:red">
                <span [RawDataDirective]="comp.grossIRR">
                  {{comp.grossIRR | dynamicDecimalPipe}}%
                </span>
              </div>
            </ng-template>
            <ng-template [ngIf]="!comp.grossIRR || comp.grossIRR == 0">{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
            </ng-template>
          </e-column>

          <e-column field='syntheticCreditRating' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("credit_rating")' width='70px'>
            <ng-template #template let-comp>
              {{comp.syntheticCreditRating }}
            </ng-template>
          </e-column>

          <e-column field='performanceClass' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("performance_class")' width='70px' textAlign='Left'>
            <ng-template #template let-comp>
              {{comp.performanceClass | titlecase }}
            </ng-template>
          </e-column>

          <e-column field='couponType' [visible]='false' [headerText]='translateService.getLabel("coupon_type")' width='70px' textAlign='Left'>
            <ng-template #template let-comp>
              {{comp.couponType }}
            </ng-template>
          </e-column>

          <e-column field='baseRateIndex' [visible]='false' [headerText]='translateService.getLabel("base_rate_index")' width='70px' textAlign='Left'>
            <ng-template #template let-comp>
              {{comp.baseRateIndex }}
            </ng-template>
          </e-column>

        </e-columns>

      </ejs-grid>
    </div>

    <!-- Exited Data - Local Currency -->
    <div [hidden]="!securityDetailsLocalCurrencyExited?.length">
      <div style="padding: 0.56vw; margin-top: 2%;">
        <span class="main-heading">{{translateService.getLabel("exited_security_level")}} - {{translateService.getLabel("local_currency")}}</span>
      </div>

      <div class="table table-condensed" id="container" class="investment-summary-grid">
        <ejs-grid #localCurrencyInvestmentSummaryGridExited
          [dataSource]="securityDetailsLocalCurrencyExited"
          [allowResizing]="true"
          [allowTextWrap]="true"
          [allowGrouping]="true"
          [allowExcelExport]="true"
          [allowSorting]="true"
          [allowReordering]="true"
          [allowPaging]="true"
          [pageSettings]="gridPageSettings"
          [showColumnChooser]="true"
          [toolbar]="gridToolbar"
          [allowFiltering]="true"
          [filterSettings]="gridFilterSettings"
          (rowSelected)="companyRowSelected($event)"
          (actionComplete)="localCurrencyAction($event, 'EXITED')">
          <e-columns>

            <e-column field='logo' [headerText]='translateService.getLabel("logo")' width='50px'>
              <ng-template #template let-comp>
                <img class="companyLogo" [src]="comp.logoURL" (error)="comp.logoURL = null" *ngIf="comp.logoURL; else defaultLogo">
                <ng-template #defaultLogo>
                  <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
                </ng-template>
              </ng-template>
            </e-column>

            <e-column field='issuerName' [headerText]='translateService.getLabel("issuer_name")' width='70px'>
              <ng-template #template let-comp>
                <!-- <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i> -->
                <span style="font-weight: 500;">{{comp.issuerName}}</span>
              </ng-template>
            </e-column>

            <e-column field='investmentDate' [headerText]='translateService.getLabel("investment_date")' width='80px'>
              <ng-template #template let-comp>
                {{ comp.investmentDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='investmentStatus' [headerText]='translateService.getLabel("investment_status")' width='80px'>
              <ng-template #template let-comp>
                {{ comp.investmentStatus }}
              </ng-template>
            </e-column>

            <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px'>
              <ng-template #template let-comp>
                {{ comp.valuationDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='exitDate' [headerText]='translateService.getLabel("exit_date")' width='70px'>
              <ng-template #template let-comp>
                {{ comp.exitDate | date: "mediumDate" }}
              </ng-template>
            </e-column>

            <e-column field='currency' [headerText]='translateService.getLabel("currency")' width='50px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.currency && comp.currency != 0">{{comp.currency }}</ng-template>
              </ng-template>
            </e-column>

            <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='70px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.securityName">{{comp.securityName}}</ng-template>
              </ng-template>
            </e-column>

            <!-- <e-column field='seniority' headerText='Seniority' width='70px'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.seniority">{{comp.seniority | titlecase}}</ng-template>
              </ng-template>
            </e-column> -->

            <e-column field='value' [headerText]='translateService.getLabel("value") + " (Mn)"' width='70px' textAlign='Right'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.value && comp.value != 0" [ngIfElse]="defaultValue">
                  <span [RawDataDirective]="comp.value">
                    {{comp.value | dynamicDecimalPipe}}
                  </span>
                </ng-template>
                <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}</ng-template>
              </ng-template>
            </e-column>

            <e-column field='percentOfPar' [headerText]='translateService.getLabel("percent_of_par")' width='60px' textAlign='Right'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.percentOfPar && comp.percentOfPar != 0" [ngIfElse]="defaultValue">
                  <span [RawDataDirective]="comp.percentOfPar">
                    {{comp.percentOfPar | dynamicDecimalPipe}}%
                  </span>
                </ng-template>
                <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
              </ng-template>
            </e-column>

            <e-column field='grossIRR' [headerText]='translateService.getLabel("gross_irr")' width='60px' textAlign='Right' [visible]='false'>
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.grossIRR && comp.grossIRR >= 0">
                  <div>
                    <span [RawDataDirective]="comp.grossIRR">
                      {{comp.grossIRR | dynamicDecimalPipe}}%
                    </span>
                  </div>
              </ng-template>
              <ng-template [ngIf]="comp.grossIRR && comp.grossIRR < 0">
                <div style="color:red">
                  <span [RawDataDirective]="comp.grossIRR">
                    {{comp.grossIRR | dynamicDecimalPipe}}%
                  </span>
                </div>
              </ng-template>
              <ng-template [ngIf]="!comp.grossIRR || comp.grossIRR == 0">{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
              </ng-template>
            </e-column>

            <e-column field='syntheticCreditRating' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("credit_rating")' width='70px'>
              <ng-template #template let-comp>
                {{comp.syntheticCreditRating }}
              </ng-template>
            </e-column>

            <e-column field='performanceClass' [visible]='!disableClientSpecificFields' [headerText]='translateService.getLabel("performance_class")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.performanceClass | titlecase }}
              </ng-template>
            </e-column>

            <e-column field='couponType' [visible]='false' [headerText]='translateService.getLabel("coupon_type")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.couponType }}
              </ng-template>
            </e-column>

            <e-column field='baseRateIndex' [visible]='false' [headerText]='translateService.getLabel("base_rate_index")' width='70px' textAlign='Left'>
              <ng-template #template let-comp>
                {{comp.baseRateIndex }}
              </ng-template>
            </e-column>

          </e-columns>

        </ejs-grid>
      </div>
    </div>
  </div>
</div>
</div>

<ng-template #applicationContent let-applicationModal>
  <div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">{{translateService.getLabel("add_security")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="model-content">
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("issuer_name")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field style="width: 80%;">

            <input matInput  [(ngModel)]="newSecurity.name" [matAutocomplete]="auto" (keyup)="companyNameChange($event)" required>

            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" >
              <ng-container *ngFor="let option of combinedCompanyNames ;let index = index" >
                <mat-option [value]="option.company_name ? option.company_name : option.issuerCompanyName" style="line-height: unset;" (click)="selectedCompanyName(option)">
                  {{option.company_name ? option.company_name : option.issuerCompanyName}} <br>
                  <span style="font-size: 10px;">{{option?.country ? option?.country : translateService.getLabel("existing_company")}}</span>
                </mat-option>
                <mat-divider *ngIf="showDividerAt == index"></mat-divider>
            </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("investment_date")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field (click)="vDatePicker.open()" style="width: 80%;">
            <input matInput [matDatepicker]="vDatePicker" readonly class="date-field"
              [(ngModel)]="newSecurity.valuationDate" [max]="todayDate" required>
            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <!-- security name -->
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("security_name")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field style="width: 80%;">
            <input matInput [(ngModel)]="newSecurity.onboardedSecurityName" required>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          No. of Business Units
        </div>
        <div class="col modal-textbox">
          <mat-form-field style="width: 80%;">
            <input matInput type="number" [(ngModel)]="newSecurity.businessUnitsNumber" required>
          </mat-form-field>
        </div>
      </div> -->

    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn btn-cancel" (click)="applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="button" class="btn btn-save" style="width: 12.5%;" (click)="startApplication()">
      {{translateService.getLabel("save")}}
    </button>
  </div>
</ng-template>

<!--Start : Added Code to show Modal to delete company-->
<ng-template #deleteCompanyContent let-deleteCompanyContent>
  <div class="custom-modal">
    <div class="modal-header">
      <div class="modal-title">
        <span style="font-size: 24px;">{{translateService.getLabel("delete_company")}}</span>
      </div>
      <button #closeBtn type="button" class="close" aria-label="Close" (click)="deleteCompanyContent.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
  
      <div class="model-content deletePopUpContent">
        <form [formGroup]="filterCompaniesFormGroup">
        <div class="search-companies-form">
        <mat-form-field class="custom-input-select col-6">
          <mat-label>{{translateService.getLabel("search_company_security")}}</mat-label>
          <input matInput formControlName="companyName" type="text" [placeholder]='translateService.getLabel("search_company_security")'/>
          <span matSuffix>
            <i class="fa fa-search"></i>
        </span>
        </mat-form-field>
      </div>
    </form>
        <div *ngFor="let company of securityDetailsTotalReportingCurrency">
              <div class="row p-modal-row">
              <mat-checkbox class="example-margin" (change)="deleteCompanySelectionChange($event, company)">
                  <span style="font-weight: 500;font-size: 16px;">{{company.issuerName}} - {{company.securityName}}</span>
              </mat-checkbox>
              </div>
        </div>
      </div>
  
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel" (click)="deleteCompanyContent.dismiss('Cross click')">
        {{translateService.getLabel("cancel")}}
      </button>
      <button type="button" class="btn btn-save" (click)="validationBeforeDeleteConfirm(deleteCompanyConfirm,securityConfirm)">
        {{translateService.getLabel("delete")}}
      </button>
    </div>
  </div>
</ng-template>
<!--End : Added Code to show Modal to delete company-->

<!-- securitypopup -->
<ng-template #securityConfirm let-securityConfirm>
  <div class="modal-header">
    <div class="modal-title pl-3">
      <span style="font-size: 22px;">{{translateService.getLabel("delete_companies")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="securityConfirm.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <ng-template [ngIf]="notSubmittedSecurityName?.length > 0">
        <span>Deleting the securities below will permanently remove all valuation versions and associated data.</span><br>
        <span>
          <li *ngFor="let notSubmitSecurityName of combinedNotSubmittedSecurityName">{{notSubmitSecurityName}}</li>
        </span><br>
      </ng-template>

      <ng-template [ngIf]="securityDeletable?.length > 0">
        <span>Please click on Delete button below to delete all valuation versions and associated data for securities that are not linked.</span><br>
        <span>
          <li *ngFor="let securityDeletableName of combinedDeletableSecurities">{{securityDeletableName}}</li>
        </span><br>
      </ng-template>

      <ng-template [ngIf]="securityNonDeletable?.length > 0">
        <span>A few securities that you have selected for deletion serve as references for other securities and therefore can not be deleted. These securities are -</span><br>
        <span>
          <li *ngFor="let securityNonDeletableName of combinedNonDeletableSecurities">{{securityNonDeletableName}}</li>
        </span>
      </ng-template>

      <ng-template [ngIf]="allSecurityDeletable?.length > 0">
        <span>Deleting the below securities will result in all valuation versions and associated data being deleted permanently.</span><br>
        <span>
          <li *ngFor="let allSecurityDeletableName of combinedAllDeletableSecurities">{{allSecurityDeletableName}}</li>
        </span><br>
        <span>Please confirm that you want to delete these securities.</span>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="securityConfirm.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="button" class="btn btn-save" (click)="deleteCompanies(deleteSuccessModal)">
      {{translateService.getLabel("delete")}}
    </button>
  </div>
</ng-template>

<!-- Delete companies confirmation modal -->
<ng-template #deleteCompanyConfirm let-deleteCompanyConfirm>
  <div class="modal-header">
    <div class="modal-title pl-3">
      <span style="font-size: 22px;">{{translateService.getLabel("delete_companies")}}</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="deleteCompanyConfirm.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content deleteConfirmPopUpContent">
      <span>Deleting the below securities will result in all valuation versions and associated data being deleted permanently.</span><br>
      <span>
        <li *ngFor="let companyAndSecurityName of companyAndSecurityNames">{{companyAndSecurityName}}</li>
      </span><br>
      <span>Please confirm that you want to delete these securities.</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="deleteCompanyConfirm.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button type="button" class="btn btn-save" (click)="deleteCompanies(deleteSuccessModal)">
      {{translateService.getLabel("delete")}}
    </button>
  </div>
</ng-template>

<!-- Deleted succesfull Modal-->
<ng-template #deleteSuccessModal let-deleteSuccessModal>
  <div class="modal-body">
    <div class="model-content" style="text-align: center;">
      <div class="col">
        <i class="col fas fa-check-circle delete-success-popup"></i>
        <span class="primary-color" style="font-size: 22px;">{{translateService.getLabel("deleted_successfully")}}!</span>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="display: block; text-align: center;">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
    <button type="button" class="btn btn-save" style="width: 16%;" (click)="deleteSuccessModal.dismiss('Cross click')">
      {{translateService.getLabel("ok")}}
    </button>
  </div>
</ng-template>


<!-- Shifted to new Component -->
<ng-template #userPopUpContent let-userPopUp>
  <div class="modal-header">
    <div class="modal-title">
      <h4 class="modal-title"> {{ selectedFormForAssignment?.companyName }} </h4>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="userPopUp.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body form-user-assignment">
    <div style="padding-left: 15px; max-height: 40vh;">
      <!-- <div class="row">
        <div class="col-7">
          <mat-checkbox class="example-margin" [(ngModel)]="user.assigned">
              <span style="font-weight: bold;">{{ user.userName }}</span> - <i>{{user.userId}}</i>
          </mat-checkbox>
        </div>

        <div class="col-4">
          <mat-form-field>
            <mat-select disableOptionCentering [(ngModel)]="user.editable">
              <mat-option [value]="true"> Write </mat-option>
              <mat-option [value]="false"> View Only </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <table class="table user-assingment-table">
        <tr>
          <th>{{translateService.getLabel("username")}}</th>
          <th>{{translateService.getLabel("email")}}</th>
          <th>{{translateService.getLabel("access_type")}}</th>
        </tr>
        <tr *ngFor="let user of debtPortfolioService.myOrgUsers">
          <td style="font-weight: 500;">{{ user.userName }}</td>
          <td style="font-style: italic;">{{ user.userId }}</td>
          <td>
            <mat-form-field class="permission-options">
              <mat-select disableOptionCentering [(ngModel)]="user.editable">
                <mat-option [value]="''" default>{{translateService.getLabel("no_access")}}</mat-option>
                <mat-option [value]="'W'">{{translateService.getLabel("edit")}}</mat-option>
                <mat-option [value]="'R'">{{translateService.getLabel("view")}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-save" (click)="debtPortfolioService.assignUsersToForm(selectedFormForAssignment)">
        {{translateService.getLabel("save")}}
      </button>
    </div>
</ng-template>
