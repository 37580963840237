import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LabelService {

  getLabel(key) {
    if(this.labels[key])
      return this.labels[key];
    else 
      return key;
  }

  labels = {
    "bevRevenue": "EV / Revenue",
    "bevEbitda": "EV / EBITDA",
    "evEbit": "EV / EBIT",
    "evEbitda_Capex": "EV / EBITDA - Capex",
    "bevEbit": "EV / EBIT",
    "bevEbitda_Capex": "EV / EBITDA - Capex",
    "psales": "Price / Sales",
    "pe": "Price / Earnings",
    "pbv": "Price / Book Value",
    "evGrossProfit": "BEV / Gross Profit",
    "evGMV": "EV / GMV",
    "evARR": "EV / ARR",

    "bevRevenue_metric": "Revenue",
    "bevEbitda_metric": "EBITDA",
    "evEbit_metric": "EBIT",
    "evEbitda_Capex_metric": "EBITDA - Capex",
    "bevEbit_metric": "EBIT",
    "bevEbitda_Capex_metric": "EBITDA - Capex",
    "psales_metric": "Sales",
    "pe_metric": "Earnings",
    "pbv_metric": "Book Value",
    "evGrossProfit_metric": "Gross Profit",
    "evGMV_metric": "GMV",
    "evARR_metric": "ARR"
  }
}