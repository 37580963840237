import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { QXP_PortFolioService } from './qxp-portfolio.service';
import { QXP_FundListService } from './qxp-fund-list-ui/qxp-fund-list.service';

@Component({
  selector: 'app-qxp-portfolio',
  templateUrl: './qxp-portfolio.component.html',
  styleUrls: ['./qxp-portfolio.component.scss']
})
export class QXP_PortfolioComponent implements OnInit, AfterViewInit {
  newCompany = {name: "", valuationDate: null, security: "EQUITY", investmentAmount: 0, logo: null};
  searchString = ""
  userId;
  fundId;
  fundName;

  showMenu = false
  myOrgUsers;
  selectedFormForAssignment;

  showSummary;
  showExitSimulation;

  constructor(private modalService: NgbModal, private ums: UserManagementService, 
    private dataService: DataService, private utilService: UtilService,
    public portfolioService: QXP_PortFolioService, private router:Router,
    private activatedRoute: ActivatedRoute,
    private fundService: QXP_FundListService) 
  {}

  async ngOnInit() {
    
    this.activatedRoute.paramMap.subscribe(params => {
      this.fundId = params.get("fundId");

      if(!this.fundService.allFunds || this.fundService.allFunds.length == 0) {
        
        const myDetails = this.ums.getSelectedUserDetails();
        this.fundService.getFunds(myDetails);
      }

      if(this.fundId !== "TEST") {
        this.fundName = this.fundService.getFundName(this.fundId);

        this.portfolioService.init(this.fundId);
      } else {
        this.portfolioService.init();
      }
      this.updateMap();
      this.getUsers()
    });
  }

  getUsers() {
    const myDetails = this.ums.getSelectedUserDetails();
    this.ums.getUsers(myDetails.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(user => user.id !== myDetails.id);;
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  userPopup(content, form) {
    
    this.resetUserAssignment();

    const dbForm = this.portfolioService.companies.find(c => c.id === form.id);

    if(dbForm.assessorDetails && this.myOrgUsers && this.myOrgUsers.length > 0) {
      const assignees = JSON.parse(dbForm.assessorDetails).assignees;
      if(assignees) {
        this.myOrgUsers.forEach(user => {
          const assignedUser = assignees.find(u => u.id === user.id);
          
          user.assigned = !!assignedUser;
          user.editable = assignedUser ? assignedUser.editable : null;
        })
      }
    } else if(!this.myOrgUsers || this.myOrgUsers.length == 0) {
      return;
    }

    this.selectedFormForAssignment = dbForm;

    this.modalService.open(content, { centered: true })
  }

  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.editable = false;
    })
  }

  ngAfterViewInit(){
    this.portfolioService.initColumnChart();
  }


  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  openPopupModal(content){
    this.modalService.open(content, { centered: true , windowClass: 'center-popup-modal'});
  }

  startApplication() {      
    const loggedInUserDetails = this.ums.getSelectedUserDetails();


    if(this.newCompany.name!=='' && this.newCompany.valuationDate){
      
      this.newCompany.name = this.utilService.cleanString(this.newCompany.name);

      this.modalService.dismissAll();
      this.dataService.createNewCompany(this.newCompany, loggedInUserDetails, this.ums.getApplicationVersion(), this.fundId).subscribe(res=>{
        this.initNewForms([res.body["response"]]);
      }, err=>{
        console.log("Error while creating company", err)
        this.utilService.showMessage("Sorry, failed to create " + this.newCompany.name + " valuation.", "Ok");
      })
    }
    else{
      this.utilService.showMessage("Please enter all the details", "Ok");
    }
  }

  initNewForms(newForms) { 
    this.portfolioService.prepareCompanyData(newForms);
    this.portfolioService.preparePortfolioData();

    this.portfolioService.searchedForms = this.portfolioService.getInvestmentDateForms();

    this.utilService.showMessage(this.newCompany.name + " Valuation is initiated", "Ok");

    // User Assignment
    const selectedFund = this.fundService.getFundById(this.fundId);
    this.resetUserAssignment();
    this.selectedFormForAssignment = newForms;

    if(selectedFund.details && this.myOrgUsers && this.myOrgUsers.length > 0) {
      const assignees = JSON.parse(selectedFund.details).assignees;
      if(assignees) {
        this.myOrgUsers.forEach(user => {
          const assignedUser = assignees.find(u => u.id === user.id);
      
          user.assigned = !!assignedUser;
          user.editable = assignedUser ? assignedUser.editable : null;
        })
      }

      this.assignUsersToForm(true);
    }
  }

  updateMap(){
    this.portfolioService.updateWorldMap();
  }

  searchCompanies() {
    if (this.searchString != "") {
      this.portfolioService.searchedForms = this.portfolioService.portfolioData.filter((item) => {
        if (item.companyName.toUpperCase().indexOf(this.searchString.toUpperCase()) >= 0 || item.id === this.searchString)
          return true
      })
    }
    else
      this.portfolioService.searchedForms = this.portfolioService.portfolioData;
  }

  oldInvestments() {
    this.router.navigate(['/qxp-oldInvestments'])
  }

  investmentSummary(){
    this.router.navigate(['/qxp-investmentPageSummary'])
  }

  assignUsersToForm(newForm?) {

    const userDetails = this.ums.getSelectedUserDetails();

    const usersList = [];
    const assignees = [];
    const editable = [];

    this.myOrgUsers.forEach(u => {
      if(u.assigned) {
        usersList.push(u.id);
        editable.push(!!u.editable);

        const uInfo = {
          id: u.id,
          userName: u.userName,
          userId: u.userId,
          editable: !!u.editable
        }

        assignees.push(uInfo);
      }
    });
    
    const createdBy = {
      id: userDetails.id,        
      name: userDetails.userName,
      email: userDetails.userId
    }

    const body = {
      "users": usersList,
      "editable": editable,
      "formId": this.selectedFormForAssignment.id,
      "orgId": userDetails.organization.id,
      "usersInfo": JSON.stringify({createdBy: createdBy, assignees: assignees})
    }

    this.dataService.assignUsersToSAFForm(body).subscribe(result=>{
      // console.log("Assignmet result", result);

      if(!newForm) {
        this.utilService.showMessage("User Assignment is successfully completed.", "Ok")
      } else {
        this.utilService.closeAllPopups();
      }
    }, error=>{
      console.log("Assignmet error", error);

      if(!newForm) {
        this.utilService.showMessage("Failed to assign the selected users to form.", "Ok")
      } else {
        this.utilService.closeAllPopups();
      }
    })
  }
}