import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortFolioService } from '../qubit-x/portfolio/portfolio.service';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translation.service';
import { UserManagementService } from '../services/user-management.service';

@Component({
  selector: 'app-qualitative-analysis-v2',
  templateUrl: './qualitative-analysis-v2.component.html',
  styleUrls: ['./qualitative-analysis-v2.component.scss']
})
export class QualitativeAnalysisV2Component implements OnInit {
  @Input() groupId;
  @Input() commentId;
  @Input() id;

  allComments = [];

  constructor(private modalService: NgbModal, 
    private ums: UserManagementService,
    public translateService: TranslateService,
    private ds: DataService) 
  { }

  ngOnInit(): void {
    this.ds.getAllCommentsByFormId(this.id).subscribe(res => {
      this.allComments = res.body["response"];
    })
  }

  openPopupComment(content){
    this.modalService.open(content, { centered: true, size: 'lg' })
  }

  commentAdded(c) {
    const user = this.ums.getSelectedUserDetails();

    let obj = {
      groupId: this.groupId,
      commentId: this.commentId,
      formId: this.id,
      comment: c.comment,
      commentTitle: c.title,
      createdBy: user.userName,
    }

    this.ds.saveComment(obj).subscribe(res => {
      this.allComments.push(res.body["response"]);
    })

  }
}
