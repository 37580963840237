import { AttributeList } from './attr-list-interfaces';

const DEFAULT_ATTRIBUTES_LIST: AttributeList[] = [
  {
    id: 'default-attribute-map',
    name: 'Default Map',
    createdBy: 'System',
    uploadDate: '',
    actions: {
      canDelete: false,
      canEdit: false,
    },
  },
];

export { DEFAULT_ATTRIBUTES_LIST };
