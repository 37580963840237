import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange, MatDatepickerInputEvent } from '@angular/material';
import * as moment from 'moment';
import { debounceTime, startWith } from 'rxjs/operators';
import { AttributeList } from 'src/app/mapping-managment-config/attribute-list-container/attr-list-interfaces';
import { VersionList } from 'src/app/mapping-managment-config/versions/version-interfaces';

@Component({
  selector: 'app-ca-form',
  templateUrl: './ca-form.component.html',
  styleUrls: ['./ca-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaFilterComponent implements OnInit {
  today = new Date();

  uploadDate = this.today;

  private _versionLists: VersionList[];

  private _mapLists: AttributeList[];

  filteredVersionLists: VersionList[];
  filteredMapLists: AttributeList[];

  versionListSearchFc = new FormControl();
  mapListSearchFc = new FormControl();

  @Input() set versionLists(lists: VersionList[]) {
    if (lists?.length > 0) {
      this._versionLists = [...lists];
      this.filteredVersionLists = [...lists];
    }else{
      this._versionLists = [];
      this.filteredVersionLists = [];
    }
  }

  @Input() set mapLists(lists: AttributeList[]) {
    if (lists?.length > 0) {
      this._mapLists = [...lists];
      this.filteredMapLists = [...lists];
    }else{
      this._mapLists = [];
      this.filteredMapLists = [];
    }
  }

  @Output() uploadDateChange = new EventEmitter();

  @Output() versionListChange = new EventEmitter();

  @Output() mapListChange = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.versionListSearch();
    this.mapListSearch();
  }

  versionListSearch() {
    this.versionListSearchFc.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.filteredVersionLists = this._versionLists?.filter((vl) => {
        return vl.name.toLowerCase().includes(value?.trim()?.toLowerCase());
      });
      this.cdr.detectChanges();
    });
  }

  mapListSearch() {
    this.mapListSearchFc.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.filteredMapLists = this._mapLists?.filter((ml) => {
        return ml.name.toLowerCase().includes(value?.trim()?.toLowerCase());
      });
      this.cdr.detectChanges();
    });
  }

  onUploadDateChange(event: MatDatepickerInputEvent<Date>) {
    console.log(event, 'date event');
    const date = event.value;
    this.uploadDateChange.emit(date);
  }

  onVlSelected(event: MatSelectChange) {
    console.log(event);
    this.versionListChange.emit(event.value);
    this.versionListSearchFc.setValue('');
  }

  onMapSelected(event: MatSelectChange) {
    console.log(event);
    this.mapListChange.emit(event.value);
    this.mapListSearchFc.setValue('');
  }
}
