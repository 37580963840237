<section class="section">
    <div ngbDropdown class="row d-inline-block" placement="bottom-right" style="float: right;" [ngClass]="{'icon-disabled' : noEquityData}">
      <span id="toggleMenu" ngbDropdownToggle>
        <i class="fas fa-ellipsis-v ellipsis-icon"></i>
      </span>
      <div ngbDropdownMenu aria-labelledby="toggleMenu">
        <button ngbDropdownItem class="btn" (click)="showValDates = true">
          {{translateService.getLabel("show_valuation_dates")}}
        </button>
        <button ngbDropdownItem class="btn" (click)="openValuationDatePopup(valuationPopup);showValDates = true"> 
          Rollover
        </button>
      </div>
    </div>

    <ng-template [ngIf]="showValDates">
      <div class="row show-valuation-top">
        <table class="table table-condensed" id="container">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th style="width: 50px">
              <button mat-raised-button class="primary-light-bg" (click)="showValDates = false">{{translateService.getLabel("close")}}</button>
            </th>
          </tr>
        </table>
      </div>

      <div class="row" style="margin: 0 auto;">
        <div class="sf-table">
          <ejs-grid #valuationDatesGrid [dataSource]='valuationDateGridDataSource' height=220 width='auto'>
            <e-columns>
              <e-column [headerText]='translateService.getLabel("valuation_date")' width='150' textAlign='Left'></e-column>
              <e-column [headerText]='translateService.getLabel("valuation_amount")' width='150' textAlign='Left'></e-column>
              <e-column [headerText]='translateService.getLabel("security")' width='100' textAlign='Left'></e-column>
              <e-column headerText='' width='25' textAlign='Left'></e-column>
              <e-column headerText='' width='25' textAlign='Left'></e-column>
              <e-column headerText='' width='25' textAlign='Left'></e-column>
              <e-column headerText='' width='25' textAlign='Left'></e-column>
              <e-column headerText='' width='150' textAlign='Left'></e-column>
              <!-- <e-column headerText='Approval Status' width='150' textAlign='Left'></e-column>
              <e-column headerText='Approval Requests' width='150' textAlign='Left'></e-column> -->
              <e-column headerText='' width='150' textAlign='Left'></e-column>
              <e-column headerText='' width='150' textAlign='Left'></e-column>
            </e-columns>

            <ng-template #rowTemplate let-data>
              <ng-template [ngIf]="!data.loading" [ngIfElse]="loadingMessage">
                <tr>
                  <td class="td-parent-cell pl-5" colspan="10">
                    {{data.name  | date: "mediumDate" }} &nbsp; &nbsp;
                    <i class="fas fa-grip-horizontal action-icon icon-active" (click)="showVersionComparison(data)"
                      *ngIf="data.value.length > 1"></i>
                  </td>
                </tr>

                <tr *ngFor="let item of data.value;let i = index;">
                  <td class="td-cell">
                    <div class="row m-0">
                      <div class="col-2">
                        <ejs-radiobutton label="" name="chkbox{{data.index}}" (change)="selectVersion(item)"
                          cssClass="e-success" [checked]="item.frozen"></ejs-radiobutton>
                      </div>
                      <div class="col pl-0 versionName">
                        <span (click)="updateVersion(item)"><label class="row m-0"
                            style="margin-left: 10px !important; cursor: pointer">{{item.version | titlecase}}</label></span>
                      </div>
                    </div>
                  </td>
                  <td class="td-cell">
                    <ng-template [ngIf]="item.stake || item.stake >= 0">
                      {{item.currency}} {{item.stake | dynamicDecimalPipe}}
                    </ng-template>
                  </td>
                  <td class="td-cell">
                    {{item.security | titlecase}}
                  </td>
                  <td class="td-cell">
                    <i class="fa fa-edit action-icon grey-text" (click)="openSAF(item, businessUnitPopup)"
                      [ngClass]="{'icon-active' : item.approvalStatus == APPROVAL_STATUS.NOT_STARTED}">
                    </i>
                  </td>
                  <td class="td-cell">
                    <i class="fas fa-binoculars action-icon icon-active"
                      (click)="openValuation(item, businessUnitPopup)"
                      *ngIf="item.status == 'Submitted' && checkWhetherBusinessUnitIsSubmitted(item)">
                    </i>
                  </td>
                  <td class="td-cell">
                    <i class="fa fa-trash action-icon grey-text" *ngIf="data.index != (valuationDateGridDataSource?.length - 1)" aria-hidden="true"
                      (click)="deleteCompany(item)"
                      [ngClass]="{'icon-active' : item.approvalStatus == APPROVAL_STATUS.NOT_STARTED}">
                    </i>
                  </td>
                  <td class="td-cell">
                    <!-- <i class="fas fa-share-alt action-icon icon-active" *ngIf="item.status !== 'Initiated'"
                      aria-hidden="true" (click)="openSharePopUp(item, businessUnitPopup, valuationSharePopUp)"></i> -->
                  </td>
                  <td class="td-cell">
                    <!-- <i class="far fa-copy action-icon icon-active" *ngIf="item.status !== 'Initiated' 
                            && item.frozen 
                            && item.id !== primaryCompanyId" (click)="copyVersion(item)"></i> -->
                  </td>

                  <!-- <td class="td-cell" style="padding-left: 10px;">
                    <span *ngIf="item.approvalStatus == APPROVAL_STATUS.REJECTED">Rejected</span>
                    <span *ngIf="item.approvalStatus == APPROVAL_STATUS.APPROVED">Approved</span>
                    <span *ngIf="item.approvalStatus ==  APPROVAL_STATUS.NOT_STARTED">Approval - Not Started</span>
                    <span *ngIf="item.approvalStatus ==  APPROVAL_STATUS.PENDING">Approval - In Progress</span>

                  </td> -->
                  <!-- <td class="td-cell">
                    <ng-template
                      [ngIf]="item.actionName == APPROVAL_ACTION.INITIATE_APPROVAL && item.status == 'Submitted'">
                      <button
                        style="border: 1px solid green; color: green; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                        (click)="initiateApprovalRequest(item); item.actionName = APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = APPROVAL_STATUS.PENDING"
                        class="btn-status">
                        Initiate Approval
                      </button>
                    </ng-template>

                    <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.NO_CONDITION">
                      <div matTooltip="Approval condition not available" matTooltipPosition='above'>
                        <button [disabled]="true"
                          style="border: 1px solid grey; color: grey; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                          (click)="initiateApprovalRequest(item); item.actionName = APPROVAL_ACTION.RECALL_APPROVAL; item.approvalStatus = APPROVAL_STATUS.PENDING"
                          class="btn-status">
                          Initiate Approval
                        </button>
                      </div>
                    </ng-template>

                    <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.RECALL_APPROVAL">
                      <button
                        style="border: 1px solid red; color: red; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                        (click)="recallApprovalRequest(item); item.actionName = APPROVAL_ACTION.INITIATE_APPROVAL; item.approvalStatus = APPROVAL_STATUS.NOT_STARTED"
                        class="btn-status">
                        Recall Approval
                        <i class="fa fa-lock icon-lock"></i>
                      </button>
                    </ng-template>

                    <ng-template [ngIf]="item.actionName == APPROVAL_ACTION.REJECT_APPROVE">
                      <button
                        style="border: 1px solid #1D1563; color: #1D1563; background-color: white; border-radius: 6px; cursor: pointer; width: fit-content;"
                        (click)="enableApproveDialogBox(item)" class="btn-status">
                        Approve / Reject
                        <i class="fa fa-lock icon-lock"></i>
                      </button>
                    </ng-template>

                    <ng-template
                      [ngIf]="item.approvalStatus == APPROVAL_STATUS.APPROVED || item.approvalStatus == APPROVAL_STATUS.REJECTED">
                      <div class="locked"><i class="fa fa-lock icon-lock" matTooltip="This version is not editable"
                          matTooltipPosition='above'></i> </div>
                    </ng-template>
                  </td> -->
                </tr>
              </ng-template>

              <ng-template #loadingMessage>
                <div style="margin-left: 20px;">{{translateService.getLabel("loading")}}...</div>
              </ng-template>

            </ng-template>
          </ejs-grid>
        </div>
     
        <!-- <div class="row" class="new-val-date">
          <table>
            <tr>
              <td>{{translateService.getLabel("valuation_date")}}</td> -->
              <!-- <td>Business Units</td> -->
             <!-- <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>
                <mat-form-field (click)="vDatePicker.open()" style="width: 80%;">
                  <input matInput [matDatepicker]="vDatePicker" readonly class="date-field"
                    [(ngModel)]="newCompany.valuationDate">
                  <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                </mat-form-field>
              </td>  -->
              <!-- <td">
                <mat-form-field>
                  <input type="number" matInput [(ngModel)]="newCompany.businessUnitsNumber">
                </mat-form-field>
              </td> -->
             <!-- <td>
                <button mat-raised-button class="primary-bg p-button" (click)="addValuationDate()">
                  {{translateService.getLabel("add")}}
                </button>
              </td>
              <td></td>
              <td></td>
            </tr>
          </table>
        </div> -->
      </div>
    </ng-template>

    <!-- create new common rollover -->
    <ng-template #valuationPopup let-valuationDateSelectionPopup>
      <app-rollover [valuationDateSelectionPopup]="valuationDateSelectionPopup" [newCompany]="newCompany"  [rolloverDetails]="rolloverDetails" [valuationDateGridDataSource]="valuationDateGridDataSource"
      (rolloverSubmit)="submitRollover($event)"></app-rollover>
    </ng-template>

    <!-- <div class="row" *ngIf="!noEquityData && !equityFormSubmitInProgress"> -->
      <div class="row" style="margin: 20px 0;">
        <div class="col"></div>
        <div class="col-6" style="text-align: right; display: flex; align-items: center;">
          <span style="font-weight: bold; font-size: 20px;">Equity Ownership in the Company</span>
          <span style="margin-left: 15px;"></span>
          <mat-slide-toggle [checked]="toggleState" (change)="onToggleChange($event)"></mat-slide-toggle>
         <span>
          <mat-icon class="mat-info-icon" [matTooltip]="translateService.getLabel('credit_equity_ownership_toggle_message')"
          matTooltipPosition="below" [matTooltipClass]="'custom-tooltip'">info_outline</mat-icon>
         </span>
        </div>
      </div>
    <div class="row" *ngIf="!noEquityData">
        <button class="btn-custom-primary p-button" (click)="goToEquity()">
            {{translateService.getLabel("go_to_equity")}}
        </button>
    </div>
    <div class="row">
        <div class="col-6" *ngIf="noEquityData">
            <div>
                <p>{{translateService.getLabel("no_equity_data_found")}}</p>
            </div>
        </div>
        <div class="col-6" *ngIf="equityFormNotSubmitted">
            <div>
                <p>{{translateService.getLabel("equity_form_not_submitted")}}</p>
            </div>
        </div>
        <div class="col-6" *ngIf="equityFormSubmitInProgress">
            <div>
                <p>{{translateService.getLabel("equity_form_submission_is_in_progress")}}, {{translateService.getLabel("please_wait")}}.</p>
            </div>
        </div>
        <div class="col-6" *ngIf="enterpriseValue">
            <div class="bubble">
                <p class="bubble-heading">
                    {{translateService.getLabel("enterprise_value")}} - {{ enterpriseValue | dynamicDecimalPipe }} {{ enterpriseValueUnit }}
                </p>
            </div>
        </div>
        <!-- <div class="col-6"  *ngIf="enterpriseValueCoverage">
            <div class="bubble">
                <p class="bubble-heading">
                    Enterprise Value Coverage - {{ enterpriseValueCoverage | number: "1.1-1" }} %
                </p>
            </div>
        </div> -->
    </div>

    <div class="row">
        <div class="col-6" *ngIf="!equityFormNotSubmitted">
            <span class="main-heading">{{translateService.getLabel("capitalization_as_of")}} {{ equityForm?.valuationDate | date: "mediumDate" }}</span>
        </div>
        <div class="col-6">
            <span class="main-heading">{{translateService.getLabel("enterprise_value_coverage")}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="sf-table" *ngIf="capitalizationDataReady">
                <ejs-grid #capitalizationGrid class="coverage-analysis-grid" [dataSource]="capitalizationData">
                    <e-columns>
                        <e-column field='label' headerText='' width='75px' textAlign='left'></e-column>
                        <e-column field='ltm' [headerText]='translateService.getLabel("ltm")' width='75px' textAlign='center'>
                            <ng-template #template let-data>
                                {{ data.ltm | dynamicDecimalPipe }}
                            </ng-template>
                        </e-column>
                        <e-column field='impliedMultiple' [headerText]='translateService.getLabel("implied_multiple")' width='75px' textAlign='right'>
                            <ng-template #template let-data>
                                <ng-template [ngIf]="data.impliedMultiple!=''">
                                    {{ data.impliedMultiple | dynamicDecimalPipe}}x
                                </ng-template>
                                <ng-template #template let-data [ngIf]="data.impliedMultiple==''">
                                    {{ data.impliedMultiple | dynamicDecimalPipe}}
                                </ng-template>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </ejs-grid>
            </div>
              <div class="row align-items">
                <div class="col-12">
                  <div class="row">
                <div class="col-7 custom-label">
                  {{translateService.getLabel("do_you_want_to_perform_a_waterfall_analysis")}}?
                </div>
                <div class="col-2">
                  <mat-form-field class="custom-input-select">
                    <mat-label>{{translateService.getLabel("select_type")}}</mat-label>
                    <mat-select name="type" [(ngModel)]="waterfallExist" (selectionChange)="updateWaterfallSelection()">
                      <mat-option [value]="true"> {{translateService.getLabel("yes")}} </mat-option>
                      <mat-option [value]="false"> {{translateService.getLabel("no")}} </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <mat-spinner *ngIf="debtPortfolioService.coverageDataAvailable == false" style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner> -->
        <div class="col-6">
            <div>
                <app-covenants></app-covenants>
            </div>
        </div>
    </div>
</section>
