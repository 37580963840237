<nav-menu-header headerLabel="Currency Dashboard"></nav-menu-header>

<div class="page">
    <div class="page-inner">
        <h6 class="header">Currency Dashboard</h6>
        <table class="table">
            <tr class="grey-bg">
                <th>Logo</th>
                <th>Company Name</th>
                <th>Currency</th>
                <th>Exchange Rate</th>
            </tr>
            <tr *ngFor="let comp of portfolioService.portfolioData">
                <td><img [src]="comp.logo" width="50" height="50"></td>
                <td>{{ comp.companyName }}</td>
                <td>{{ comp.currency }} / {{ portfolioService.selectedCurrency }}</td>
                <td>{{ comp.exchangeRate | number:'1.2-2'}}</td>
            </tr>
        </table>
    </div>
</div>