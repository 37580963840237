import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart, MapChart } from 'angular-highcharts';
import worldMap from '../../../portfolio/world-data.data';
import { colors } from 'src/app/utils/colors';
import { PortfolioUtilService } from '../../portfolio-util.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-equity-summary-chart',
  templateUrl: './equity-summary-chart.component.html',
  styleUrls: ['./equity-summary-chart.component.scss']
})
export class EquitySummaryChartComponent implements OnInit, OnChanges {

  constructor(public portfolioUtil : PortfolioUtilService,
    public translateService : TranslateService,
    public utilService : UtilService) { }

  @Input() allPortfolioData;
  @Input() reportingCurrency = "USD"
  @Input() allowEdit = false; //For future reference
  @Input() IRR_LOADING_PROGRESS = {
    "TOTAL_IRR": false,
    "CURRENT_PORTFOLIO_IRR": false,
    "REALISED_PORTFOLIO_IRR": false,
    "CONSOLIDATED_IRR": false,
  }

  @Output() emitAction = new EventEmitter();

  currentPortfolioCompanies = [];
  exitedPortfolioCompanies = [];

  totalAggregations;

  mapChart;
  columnChart;

  ngOnInit(): void {
    if(this.allPortfolioData){
      this.currentPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);

      this.exitedPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);

      this.totalAggregations = this.portfolioUtil.getTotalPortfolioSummaryAggregations(this.allPortfolioData);
      
      // this.exitedPortfolioCompanies.sort((c1, c2) => c1.investmentDate > c2.investmentDate ? -1: 1);

      // this.currentPortfolioTotals = this.summary[this.selectedFundId].currentPortfolio.aggregations;
      // this.exitedPortfolioTotals = this.summary[this.selectedFundId].realisedPortfolio.aggregations;
      // this.totalAggregations = this.summary[this.selectedFundId].totalAggregations;

      this.mapChart = this.prepareMapInformation(this.currentPortfolioCompanies
        .concat(this.exitedPortfolioCompanies));

      this.columnChart = this.prepareColumnChartInformation(this.currentPortfolioCompanies
        .concat(this.exitedPortfolioCompanies));
    }
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes.IRR_LOADING_PROGRESS && 
      changes.IRR_LOADING_PROGRESS.currentValue && changes.IRR_LOADING_PROGRESS.previousValue){
      const typeOfIRRLoadingProgress = Object.keys(changes.IRR_LOADING_PROGRESS.currentValue);

      const changeExists = typeOfIRRLoadingProgress.find(type => changes.IRR_LOADING_PROGRESS.currentValue[type] 
        != changes.IRR_LOADING_PROGRESS.previousValue[type]);

      if(changeExists){
        this.currentPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);
        this.exitedPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);
        this.totalAggregations = this.portfolioUtil.getTotalPortfolioSummaryAggregations(this.allPortfolioData);
      }  
    }
  }

  prepareMapInformation(portfolioCompanies){
    const mapData = this.portfolioUtil.prepareMapInformation(portfolioCompanies);

    return this.prepareMapChart(mapData);
  }

  prepareMapChart(mapData){
    const mapChart = new MapChart({
      chart: { 
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          name: mapData.name,
          joinBy: ['iso-a3', 'code3'],
          data: mapData.data,
          color: colors.chartCurrentValuationColor,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormatter: function() {
                return this.name+ ":" + Number(this.z.toFixed(1)).toLocaleString()
              }
          }
      }] as any
    });    
    return mapChart
  }

  prepareColumnChartInformation(portfolioCompanies){
    const columnChartData = this.portfolioUtil.prepareColumnChartInformation(portfolioCompanies);

    return this.prepareColumnChart(columnChartData);
  }

  prepareColumnChart(columnChartData){
    const __this = this;

    const columnChart = new Chart({
      chart: { 
        renderTo: 'column-chart-container',
        type: 'column' 
      },
      lang: {
        contextButtonTitle: this.translateService.getLabel("chart_context_menu")
      },
      title: { text: '' },
      xAxis: {
          categories: columnChartData.map( data => data.sectorName),
          crosshair: true
      },
      yAxis:{ title:{ text: this.translateService.getLabel('value') + " (" + this.reportingCurrency + " Mn)" } },
      credits: { enabled: false },
      exporting: {
        enabled: true,
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.getLabel("view_in_full_screen")
          },
          printChart: {
            text: this.translateService.getLabel("print_chart")
          },
          downloadPNG: {
            text: this.translateService.getLabel("download_png_image")
          },
          downloadJPEG: {
            text: this.translateService.getLabel("download_jpeg_image")
          },
          downloadPDF: {
            text: this.translateService.getLabel("download_pdf_document")
          },
          downloadSVG: {
            text: this.translateService.getLabel("download_svg_vector_image")
          },
          downloadCSV: {
            text: this.translateService.getLabel("download_csv")
          },
          downloadXLS: {
            text: this.translateService.getLabel("download_xls")
          },
          viewData: {
            text: this.translateService.getLabel("view_data_table")
          }
        }
      },
      tooltip: {
        formatter: function() {
          return __this.reportingCurrency + " " + Number(this.y.toFixed(1)).toLocaleString() + " Mn"
        }
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        { name: this.translateService.getLabel('previous_valuation'), data: columnChartData.map( data => data.prevValDate), color: colors.chartPreviousValuationColor},
        { name: this.translateService.getLabel('current_valuation'), data:  columnChartData.map( data => data.currValDate), color: colors.chartCurrentValuationColor}
      ] as any
    })

    return columnChart;

  }

}
