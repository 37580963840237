<table class="table">
    <tr>
        <th>Versions</th>
        <th>Rank</th>
    </tr>

    <tr *ngFor="let historicalVersion of dataSource;let i = index">
        <td class="tdAlign" class="tdAlign">
            <div *ngIf="!isReadOnly" class="item">
                <app-search-drop-down placeholder="Select a version" [defaultValue]="historicalVersion.versionId"
                    [dataSource]="historicalVersionOptions" (openChange)="onVersionOpened($event, i)"
                    (onSelection)="onVersionChange($event, historicalVersion)"></app-search-drop-down>
                <mat-icon matRipple class="action-icons" (click)="onDelete(historicalVersion, i)">delete</mat-icon>
            </div>
            <div class="mat-error" *ngIf="isHistVerDirty[i] && !historicalVersion?.versionId">
                Version is required.
            </div>
            <div *ngIf="isReadOnly">
                {{ historicalVersion?.versionName }}
            </div>
        </td>
        <td class="tdAlign">
            <div *ngIf="!isReadOnly" class="item">
                <mat-form-field>
                    <mat-select placeholder="Select a rank" (selectionChange)="onRankChange($event, historicalVersion)"
                        [value]="historicalVersion.rank" (openedChange)="onRankOpened($event, i)" required>
                        <mat-option *ngFor="let rank of rankOptions" [value]="rank">
                            {{ rank }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mat-error" *ngIf="isRankDirty[i] && !historicalVersion.rank">
                Rank is required.
            </div>
            <div *ngIf="isReadOnly">
                {{ historicalVersion?.rank }}
            </div>
        </td>
    </tr>
</table>