
<div class="row" style="margin: 10px;">
    <div class="col">
        <button mat-raised-button class="btn-custom-primary" style="float: right;" (click)="closeBuildUpUI()">
            {{ translateService.getLabel("close") }}
        </button>
    </div>
</div>
<div class="row" style="margin: 10px;">
    <div class="col">
        <div class="card card-radius">
            <!-- <ng-template [ngIf]="addBuildUp == true || editBuildUp == true || viewBuildUp == true"> -->
                <div class="card-header">
                    <div class="row">
                        <div class="col" *ngIf="addBuildUp == true">
                            <span>{{ translateService.getLabel("add_build_up") }}</span>
                        </div>
                        <div class="col" *ngIf="editBuildUp == true">
                            <span>{{ translateService.getLabel("edit_build_up") }}</span>
                        </div>
                        <div class="col" *ngIf="viewBuildUp == true">
                            <span>{{ translateService.getLabel("view_build_up") }}</span>
                        </div>
                        <div class="col">
                            <!-- <span style="font-size: large; float: right;"> x </span> -->
                        </div>
                    </div>
                </div>
            <!-- </ng-template> -->
            <div class="card-body">
                <div class="row" *ngIf="addBuildUp == true || editBuildUp == true">
                    <div class="col">
                        <form #buildUpValues="ngForm">
                            <div class="row">
                                <div class="col-2 text-size">{{ translateService.getLabel("name") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="text" matInput name="Amount" [(ngModel)]="buildUpObject.name" name="buildUpName" autocomplete="off">
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("date") }}</div>
                                <div class="col-4">
                                    <mat-form-field (click)="vDatePicker.open()" name="value" class="input-custom-textbox">
                                        <input matInput [matDatepicker]="vDatePicker" class="date-field" 
                                          name="buildUpDate" [(ngModel)]="buildUpObject.date" autocomplete="off">
                                        <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("enterprise_value") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpEnterpriseValue" [(ngModel)]="buildUpObject.enterpriseValue"
                                        (ngModelChange) = "calcNetDebt(); calcImpliedMultiple()">
                                        <span matSuffix>Mn</span>
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("net_debt") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpNetDebt" [(ngModel)]="buildUpObject.netDebt"
                                        (ngModelChange) = "initNetDebtCalc()">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" style="margin: -8px auto;" *ngIf = "showHelpTextForNetDebt">
                                <div class="col-3 padding-zero"></div>
                                <div class="col-4 padding-zero">
                                    <small style="color: red; font-size: x-small;">* {{translateService.getLabel("equity_value")}} - {{translateService.getLabel("enterprise_value")}} = {{translateService.getLabel("net_debt")}}</small>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("equity_value") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpEquityValue" [(ngModel)]="buildUpObject.equityValue"
                                        (ngModelChange) = "calcNAV(); calcNetDebt()">
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("adjustments") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpAdjustments" [(ngModel)]="buildUpObject.adjustments"
                                        (ngModelChange) = "calcNAV()">
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("stake") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpStake" [(ngModel)]="buildUpObject.stake" 
                                        (ngModelChange) = "calcNAV()">
                                        <span matSuffix >%</span>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("nav") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpStakeValue" [(ngModel)]="buildUpObject.stakeValue"
                                        (ngModelChange) = "initNAVCalc()">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row" style="margin: -8px auto;" *ngIf = "showHelpTextForNAV">
                                <div class="col-3 padding-zero"></div>
                                <div class="col-4 padding-zero">
                                    <small style="color: red; font-size: x-small;">* ({{translateService.getLabel("equity_value")}} * {{translateService.getLabel("stake")}}) + {{translateService.getLabel("adjustments")}} = {{translateService.getLabel("nav")}}</small>
                                </div>
                            </div>

                            <div class="row field-margin">
                                <div class="col-2 text-size">
                                    <mat-form-field class="custom-input-select">
                                        <mat-label>{{ translateService.getLabel("select_metric") }}</mat-label>
                                        <mat-select name="type" [(ngModel)]="buildUpObject.metricKey">
                                            <ng-template ngFor [ngForOf] = "allMultiples" let-m>
                                                <mat-option [value]="m.key"> {{ m.label }} </mat-option>
                                            </ng-template>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="metric" [(ngModel)]="buildUpObject.metric"
                                        (ngModelChange) = "calcImpliedMultiple()">
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2 text-size">{{ translateService.getLabel("implied_multiple") }}</div>
                                <div class="col-4">
                                    <mat-form-field name="value" class="input-custom-textbox">
                                        <input type="number" matInput name="buildUpImpliedMultiple" [(ngModel)]="buildUpObject.impliedMultiple">
                                        <span matSuffix>x</span>
                                    </mat-form-field>
                                </div>
                            </div>
            
                            <div class="row field-margin">
                                <div class="col-2"></div>
                                <div class="col-4">
                                    <div class="row" style="float: right;">
                                        <div class="col">
                                            <button type="button" mat-raised-button class="btn-custom-secondry" (click)="clearAllFields()">
                                                {{ translateService.getLabel("cancel") }}
                                            </button>
                                        </div>
                                        <div class="col">
                                            <button mat-raised-button class="btn-custom-primary" (click) = "addAndSaveBuildUps()">
                                                {{ translateService.getLabel("save") }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </form>
                    </div>
                </div>

                <div *ngIf="viewBuildUp == true" class="row" style="margin-left: 10px;">
                    <div class="col-5 view-styling">
                        <div style="margin: 2% 0% 2% 0%;font-size: small">
                            <div class="row field-margin" >
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("name") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.name }}
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("date") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.date | date: "mediumDate" }}                             
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("enterprise_value") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.enterpriseValue | number: "1.2-2" }}                                
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("net_debt") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.netDebt | number: "1.2-2" }}                                
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("equity_value") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.equityValue | number: "1.2-2" }}                                
                              
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("adjustments") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.adjustments | number: "1.2-2" }}                                
                             
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("stake") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.stake | number: "1.2-2" }} %                                                              
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("nav") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.stakeValue | number: "1.2-2" }}                                                            
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class"> {{ getMarketMultipleLabel(buildUpObject.metricKey) }} </span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.metric | number: "1.2-2" }}                                
                                </div>
                            </div>
                            <div class="row field-margin">
                                <div class="col-6"><span class="label-class">{{ translateService.getLabel("implied_multiple") }}</span></div>
                                <div class="col-2"></div>
                                <div class="col-4 values-class">
                                    {{ buildUpObject.impliedMultiple | number: "1.2-2" }} x                                                         
                                </div>
                            </div>
                        </div>              
                    </div>
                </div>

                <div class="row" *ngIf="allBuildUps && allBuildUps.length > 0">
                    <div class="col-12" style="margin-top: 10px;">
                        <button mat-raised-button class="btn-custom-primary" style="float: right;" (click)="clearAllFields()">
                            {{ translateService.getLabel("add_new") }}
                        </button>
                    </div>
                </div>
            
                <div *ngIf="allBuildUps && allBuildUps.length > 0" class="build-up-table">
                    <div class="table" style="margin-bottom: 0px;">
                        <div class="row text-size m-header " style="margin: 0px; border-radius: 6px 6px 0px 0px;">
                            <div class="col-3">{{ translateService.getLabel("name") }}</div>
                            <div class="col-3">{{ translateService.getLabel("date") }}</div>
                            <div class="col-3" style="text-align: right;">{{ translateService.getLabel("nav") }}</div>
                            <div class="col-1" style="text-align: right;">{{ translateService.getLabel("edit") }}</div>
                            <div class="col-1" style="text-align: right;">{{ translateService.getLabel("view") }}</div>
                            <div class="col-1" style="text-align: right;">{{ translateService.getLabel("delete") }}</div>
                        </div>
                        <div class="row text-size three-sides" *ngFor="let data of allBuildUps; let index=index">
                            <div class="col-3">{{data.name}}</div>
                            <div class="col-3">{{data.date | date: "mediumDate"}}</div>
                            <div class="col-3" style="text-align: right;">{{data.stakeValue | number: '1.1-1'}}Mn</div>
                            <div class="col-1" style="text-align: right;">
                                <span>
                                    <i class="far fa-edit action-icon row-icon" (click)="editBuildUpData(data, index); enableEditBuildUp()"></i>
                                </span>
                            </div>
                            <div class="col-1" style="text-align: right;">
                                <span>
                                    <i class="fas fa-binoculars action-icon icon-active" (click)="editBuildUpData(data, index); enableViewBuildUp()"></i>
                                </span>
                            </div>
                            <div class="col-1" style="text-align: right;">
                                <span>
                                    <i class="fas fa-trash action-icon row-icon" (click)="deleteBuildUp(index)"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>