import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DatePipe } from '@angular/common';
import { numberFormat } from 'highcharts';
import { DynamicDecimalPipe } from 'src/app/pipes/dynamic-decimal/dynamic-dm-decimal.pipe';
import Highcharts from 'highcharts';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-column-line',
  templateUrl: './column-line.component.html',
  styleUrls: ['./column-line.component.scss']
})
export class ColumnLineComponent implements OnChanges {
  @Input() data;
  @Input()currency;
  columnLineChart;
  dateSort:any=[];
  chartColors = ["#1D1563","#269999","#5a8ad9","#eb5f6c","#a36aa9"]

  constructor(public dynamicDecimalPipe: DynamicDecimalPipe,
    private utilService: UtilService) 
  { }

  ngOnInit(): void {
  }

  getFormatedDate(myDate){
    myDate.forEach((values,index)=>{
      if(!!Date.parse(values)){
        this.dateSort.push(new DatePipe('en-US').transform(values, 'MMM dd, yyyy  '));
      }else{
        this.dateSort.push(values)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const __this = this;
    
    this.getFormatedDate(this.data?.xAxisLabels);
    if (!this.data) {
      return;
    }
    const columnLineChart = new Chart({
      chart : {
        zoomType: 'xy'
      },
      title : {
        text: ''   
      },   
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis : {
      categories: this.dateSort,
      crosshair: true
      },
      plotOptions:{ 
        series: {
          stacking: 'normal'
        },
      },
      yAxis : [
        { // Primary yAxis
          title: { text:  undefined },
            labels: {
              formatter: function(){
                return __this.utilService.getDisplayFormattedNumber(this.value, 1, "%", "NA");
              }
            },
            opposite: true,
            allowDecimals:true
        }, 
        
        { // Secondary yAxis
            title: {
              text: 'In '+this.currency+' Mn',
            },
            labels: {
              formatter: function(){
                return __this.utilService.getDisplayFormattedNumber(this.value, 1, "%", "NA");
              },
            },
            allowDecimals:true
        },
        
      ],
      tooltip: {
        enabled: true,
      },
      legend: {
        align: 'center',
        layout: 'horizontal',
        y:-50
      },
      series : []
    });
    let i = 0;
    this.columnLineChart = columnLineChart;
    this.data?.barDataPoints?.forEach((value,index) => {
      const pointsValues=[];
      value.values?.forEach((pointval,index)=>{
        pointsValues.push(parseFloat(this.dynamicDecimalPipe.transform(pointval)))
      })
      columnLineChart.addSeries(
        {
          type: 'column',
          color: this.chartColors[index],
          name: value.name,
          data: pointsValues,
          yAxis:1,
          tooltip:{
            pointFormat:'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>'
          }     
        },
        true,
        false
      );
      i++
    });
    this.data?.lineDataPoints?.forEach((value,index) => {
      const pointsValues=[];
      value.values?.forEach((pointval,index)=>{
         pointsValues.push(parseFloat(this.dynamicDecimalPipe.transform(100*pointval)))
        //pointsValues.push(parseFloat(numberFormat(100*pointval,1)))
      })
      columnLineChart.addSeries(
        {
          type: 'spline',
          color: '',
          name: value.name,
          data: pointsValues,
          tooltip:{
            valueSuffix:'%',
            pointFormat:'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>'
          }
        },
        true,
        false
      );
    });
    columnLineChart.ref$.subscribe(chart=>{
      chart.reflow();
    });
  }
}
