import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { ConfigurationService } from 'src/app/utils/configuration/services/configuration.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { THRESHOLD_VALUATION_CHANGE } from './model/threshold';
import { ThresholdService } from './services/threshold.service';


@Component({
  selector: 'app-threshold',
  templateUrl: './threshold.component.html',
  styleUrls: ['./threshold.component.scss']
})
export class ThresholdComponent implements OnInit, OnChanges  {

  @Input() fundId : string = "";
  @Input() consolFormId : string = "";
  @Input() valuationCurrency : string = "";
  @Input() dataRefresh : string = "";
  @Input() thresholdValuationChange : string = THRESHOLD_VALUATION_CHANGE.VALUATION_CHANGE;

  @Output() refreshThresholdData = new EventEmitter();

  THRESHOLD_VALUATION_CHANGE = THRESHOLD_VALUATION_CHANGE.VALUATION_CHANGE;

  fundName : string = ""; 



  constructor(public thresholdService: ThresholdService,
    public configurationService: ConfigurationService,
    public translateService: TranslateService,
    public utilService: UtilService,
    public fundService: FundListService,
    public ds: DataService
    ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataRefresh && changes.dataRefresh.currentValue == "REFRESH"){
      this.thresholdService.loadThresholdData(this.consolFormId);
    }
  }

  ngOnInit(): void {
    this.fundName = this.fundService.getFundName(this.fundId);
    this.thresholdService.init(this.consolFormId);
  }

  refreshThresholdDataUpdateandSaveConsolSummary(){
    this.refreshThresholdData.emit()
  }
  

}
