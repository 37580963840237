import { DataService } from './../../../../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { DebtPortfolioService } from "../debt-portfolio.service";
import { countryConstants } from '../constants/country.constants';
import { mergeMap } from 'rxjs/operators';
import { UserManagementService } from '../../../../services/user-management.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-debt-portfolio-company',
  templateUrl: './debt-portfolio-company.component.html',
  styleUrls: ['./debt-portfolio-company.component.scss']
})
export class DebtPortfolioCompanyComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('investmentSummaryGrid', { static: false }) public investmentSummaryGrid: GridComponent;
  @Input() onDeleteCompanies;
  @Input() triggerCompanyTableChange: boolean = false;
  gridPageSettings = { pageSizes: true, pageSize: 10 };
  gridToolbar = ['ColumnChooser'];
  gridFilterSettings = { type: 'Excel' };
  fundId
  issuerPorfolioSummaryTotal
  companyLevelSelectedColumns = [];
  orgId;
  nullDefaultValue=0;

  constructor(public debtPortfolioService: DebtPortfolioService,
    private activatedRoute:ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private um: UserManagementService , public portfolioServiceV2: PortFolioSummaryServiceV2,
    public translateService: TranslateService) { }

   ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.fundId = params.get("fundId");
      this.getIssuerPortfioliSummary();
    })

    this.debtPortfolioService.currencyChanged.subscribe((res) => {
      res && this.getIssuerPortfioliSummary();
    })

    const userDetails = this.um.getSelectedUserDetails();
    this.orgId = userDetails?.organization.id;

    this.dataService.getWidgetDataFromDB('COMPANY_LEVEL_COLUMNS', this.orgId)
      .subscribe((res:any) => {
        this.companyLevelSelectedColumns = res?.body?.response[0]?.widgetData;
        this.companyLevelSelectedColumns?.length && this.investmentSummaryGrid.hideColumns(this.companyLevelSelectedColumns);
      })
  }

  ngOnChanges(){
    if(this.onDeleteCompanies){
      this.investmentSummaryGrid?.refresh();
    }
    if (this.triggerCompanyTableChange) {
      this.debtPortfolioService.issuerPorfolioSummary = null;
      this.getIssuerPortfioliSummary();
    }
  }

  getIssuerPortfioliSummary(){
    // this.debtPortfolioService.getIssuerPorfolioPayload(this.fundId).then((payload)=>{
      this.debtPortfolioService.payloadForIssuer.toCurrency = this.portfolioServiceV2.selectedCurrency;
        this.dataService.getIssuerPortfioliSummary(this.debtPortfolioService.payloadForIssuer).pipe(mergeMap((response:any)=> {
          this.debtPortfolioService.issuerPorfolioSummary = response?.body['response']?.companies
          this.debtPortfolioService.issuerPorfolioSummary.forEach((companyData: any) => {
            companyData.valuationDates = companyData.valuationDates[0];
            companyData.valuationDates['logoURL'] = this.debtPortfolioService.allCompanyLogos && this.debtPortfolioService.allCompanyLogos[companyData.companyId];
            companyData.valuationDates.geoGraphy = (countryConstants.find(ele => ele.code === companyData.valuationDates.geoGraphy))?.name;
            if(!companyData.valuationDates.issuerName){
              companyData.valuationDates.issuerName = companyData.valuationDates.qxCompanyName;
            }
            companyData.loading = true;
          })
          this.debtPortfolioService.isIssuerPortfolioResponse = true;
          return this.dataService.getIssuerPortfioliSummaryGrossIRR(this.debtPortfolioService.payloadForIssuer);
        })).subscribe((res:any) => {
          let responseOfGrossIRR = res?.body['response']?.companies;
          this.debtPortfolioService.issuerPorfolioSummary.forEach((companyData: any, index: number) => {
            companyData.valuationDates.grossIRR = responseOfGrossIRR[index]?.valuationDates[0]?.grossIRR;
            companyData.valuationDates.percentageOfPar = responseOfGrossIRR[index]?.valuationDates[0]?.percentageOfPar;
            companyData.valuationDates.value = responseOfGrossIRR[index]?.valuationDates[0]?.value;
            companyData.loading = false;
          })
          this.investmentSummaryGrid.refresh();
        })
        // this.dataService.getIssuerPortfiolioSummaryTotal(this.debtPortfolioService.payloadForIssuer).subscribe((response:any)=>{
        //   this.issuerPorfolioSummaryTotal = response.body['response'].total;
        // })
    // });
  }

  companyRowSelected(event) {
    this.router.navigateByUrl(`/debt-company-summary?issuerCompanyId=${event.data.issuerCompanyId}&companyId=${event.data.companyId}&fundId=${this.debtPortfolioService.selectedFundId}`);
  }

  columnsSelected(event) {
    if(event.requestType == 'columnstate' && !event.columns?.name) {
      let selectedColumns = [...this.companyLevelSelectedColumns];
      event.columns.map((column:any) => {
        if(!column.visible) {
          selectedColumns.push(column.headerText);
        } else {
          let index = selectedColumns.indexOf(column.headerText);
          if(index > -1)
          selectedColumns.splice(index, 1)
        }
      })
      this.dataService.saveWidgetDataToDB('COMPANY_LEVEL_COLUMNS', selectedColumns, this.orgId)
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.debtPortfolioService.issuerPorfolioSummary = null;
  }

}
