import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { InvestmentSummaryService } from '../investment-summary.service';
import { TrackRecordService } from './track-record-preferences.service';

@Component({
  selector: 'app-track-record-preferences',
  templateUrl: './track-record-preferences.component.html',
  styleUrls: ['./track-record-preferences.component.scss']
})
export class TrackRecordPreferencesComponent implements OnInit {
  @Input() fundId;

  @Input() metricOneKey;
  @Input() metricOneLabel;

  @Input() metricTwoKey;
  @Input() metricTwoLabel;

  @Input() metricThreeKey;
  @Input() metricThreeLabel;

  @Input() primaryPeriodLabel;
  @Input() secondaryPeriodLabel;
  @Input() tertiaryPeriodLabel;


  @Input() trackRecord;

  @Input() settings

  showAdjustedEquityWaterfall = false;
  
  customAttributes = [];

  preferences = {}

  isEZ;

  fetchingPreferences = true;

  constructor(private invSummaryService: InvestmentSummaryService,
    private ums: UserManagementService,
    public trService: TrackRecordService,
    private modalService: NgbModal,
    public fundListService: FundListService,
    private ds: DataService,
    public translateService: TranslateService) { }

  ngOnInit() {
    this.init();
  }

  async init() {
    
    this.isEZ = this.ums.isEZUser(); 
    this.trService.preferences = cloneDeep(this.trService.templatePreferences);

    this.trService.preferences[this.metricOneKey + '_primary'] = true;
    this.trService.preferences[this.metricTwoKey + '_secondary'] = true;
    this.trService.preferences[this.metricThreeKey + '_tertiary'] = true;
    this.trService.preferences[this.metricOneKey + 'Multiple' + '_primary'] = true;
    this.trService.preferences[this.metricTwoKey + 'Multiple' + '_secondary'] = true;
    this.trService.preferences[this.metricThreeKey + 'Multiple' + '_tertiary'] = true;

    this.preferences = cloneDeep(this.trService.preferences);

    this.fetchingPreferences = true;

    try {
      const user = this.ums.getSelectedUserDetails();

      const prefWidget = await this.ds.getWidgetDataFromDB("TRACK_RECORD_PREFERENCES", this.invSummaryService.companyId).toPromise();

      if(prefWidget["body"]["response"][0]["widgetData"]) {
        this.preferences = prefWidget["body"]["response"][0]["widgetData"];
        if(!this.preferences[this.metricOneKey + '_primary'] && this.preferences[this.metricOneKey + '_primary'] != false) {
          this.preferences[this.metricOneKey + '_primary'] = true;
          this.preferences[this.metricOneKey + 'Multiple' + '_primary'] = true;
        }
        
        if(!this.preferences[this.metricTwoKey + '_secondary'] && this.preferences[this.metricTwoKey + '_secondary'] != false) {
          this.preferences[this.metricTwoKey + '_secondary'] = true;
          this.preferences[this.metricTwoKey + 'Multiple' + '_secondary'] = true;
        }

        if(this.metricThreeKey + '_tertiary' && !this.preferences[this.metricThreeKey + '_tertiary'] && this.preferences[this.metricThreeKey + '_tertiary'] != false) {
          this.preferences[this.metricThreeKey + '_tertiary'] = true;
          this.preferences[this.metricThreeKey + 'Multiple' + '_tertiary'] = true;
        }	

        this.trService.preferences = cloneDeep(this.preferences) as any;
      }
    } catch(e) {}

    try {
      const attrListAPI = await this.ds.getUniqueAttributesList(this.invSummaryService.companyId).toPromise();
      this.customAttributes = attrListAPI.body["response"]

      this.customAttributes.forEach(attr => {
        if(!this.preferences[attr.attributeKey]) {
          this.preferences[attr.attributeKey] = false;
        }
      })

      this.trService.preferences = cloneDeep(this.preferences) as any;
    } catch(e) {

    }
    
    this.fetchingPreferences = false;
  }

  save() {
    const user = this.ums.getSelectedUserDetails();

    const widgetId = this.invSummaryService.companyId;
    
    this.ds.saveWidgetDataToDB("TRACK_RECORD_PREFERENCES", this.preferences, widgetId).subscribe(res => {
      this.trService.preferences = cloneDeep(this.preferences) as any;
    })
  }

  openTrackRecordPrefPopup(trackRecordPreferencePopup) {
    this.modalService.open(trackRecordPreferencePopup, { centered: true , size: 'sm', backdrop: 'static' });

    this.init();
  }
}
