<div class="card" style="padding: 0.55vw;">
  <!-- Skeleton Loader -->
  <div *ngIf="!debtPortfolioService.issuerPorfolioSummary">
    <ngx-skeleton-loader count="1" appearance="line"
      [theme]="{ height: '2vw', 'width': '30%', 'border-radius': '6px', 'background-color': '#e9ecf9' }">
    </ngx-skeleton-loader>

    <!-- Table Skeleton -->
    <div class="skeleton-table">
      <ngx-skeleton-loader count="1" [theme]="{ height: '2vw', 'border-radius': '6px', 'background-color': '#e9ecf9' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>

      <ngx-skeleton-loader count="10" [theme]="{ height: '2vw', 'width': '9vw', 'margin-right': '0.3vw', 'white-space': 'nowrap',
      'border-radius': '1px', 'background-color': '#F4F5FD', 'margin-left': '0.3vw' }"></ngx-skeleton-loader>
    </div>
  </div>

  <div [hidden]="!debtPortfolioService.issuerPorfolioSummary">
    <div class="row">
      <div class="col-6">
        <span class="main-heading"> {{translateService.getLabel("company_level")}} - {{translateService.getLabel("reporting_currency")}} ({{ portfolioServiceV2.selectedCurrency }})</span>
      </div>
    </div>
    <div class="row">
      <div class="table table-condensed" id="container" class="investment-summary-grid">
        <ejs-grid #investmentSummaryGrid [dataSource]="debtPortfolioService.issuerPorfolioSummary" [allowResizing]="true"
          [allowTextWrap]="true" [allowGrouping]="true" [allowExcelExport]="true" [allowSorting]="true"
          [allowReordering]="true" [allowPaging]="true" [pageSettings]="gridPageSettings" [showColumnChooser]="true"
          [toolbar]="gridToolbar" [allowFiltering]="true" [filterSettings]="gridFilterSettings"
          (rowSelected)="companyRowSelected($event)" (actionComplete)="columnsSelected($event)">
          <e-columns>

            <e-column field='valuationDates.logoURL' [headerText]='translateService.getLabel("logo")' width="40px">
              <ng-template #template let-comp>
                <img class="companyLogo" [src]="comp?.valuationDates.logoURL"
                  (error)="comp?.valuationDates.logoURL = null"
                  *ngIf="comp?.valuationDates.logoURL; else defaultLogo">
                <ng-template #defaultLogo>
                  <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
                </ng-template>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.issuerName' [headerText]='translateService.getLabel("issuer_name")' width="70px">
              <ng-template #template let-comp>
                <!-- <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i> -->
                <span style="font-weight: 500;">{{comp.valuationDates.issuerName}}</span>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.geoGraphy' [headerText]='translateService.getLabel("geography_ps")' width="70px">
              <ng-template #template let-comp>
                <span>{{comp.valuationDates.geoGraphy}}</span>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.sector' [headerText]='translateService.getLabel("sector")' width="70px">
              <ng-template #template let-comp>
                <span>{{comp.valuationDates.sector}}</span>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.noOfSecurities' [headerText]='translateService.getLabel("securities_outstanding")' textAlign='Right' width="80px">
              <ng-template #template let-comp>
                <span>{{comp.valuationDates.noOfSecurities }}</span>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.securityNames' [headerText]='translateService.getLabel("security_name")' width="70px">
              <ng-template #template let-comp>
                <span>{{comp.valuationDates.securityNames}}</span>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.value' [headerText]='translateService.getLabel("value") + " (Mn)"' textAlign='Right' width="70px">
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.valuationDates.value && comp.valuationDates.value != 0" [ngIfElse]="defaultValue">
                  <span [RawDataDirective]="comp.valuationDates.value">
                    {{comp.valuationDates.value | dynamicDecimalPipe}}
                  </span>
                </ng-template>
                  <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}</ng-template>
                  <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20" *ngIf="comp.loading"></mat-spinner>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.percentageOfPar' [headerText]='translateService.getLabel("percent_of_par")' textAlign='Right' width="50px">
              <ng-template #template let-comp>
                <ng-template
                  [ngIf]="comp.valuationDates.percentageOfPar && comp.valuationDates.percentageOfPar != 0" [ngIfElse]="defaultValue">
                  <ng-container *ngIf="(comp.valuationDates.percentageOfPar | typeOf) === 'number'">
                    <span [RawDataDirective]="comp.valuationDates.percentageOfPar">
                      {{ comp.valuationDates.percentageOfPar | dynamicDecimalPipe}}%
                    </span>
                  </ng-container>
                  <ng-container *ngIf="(comp.valuationDates.percentageOfPar | typeOf) === 'string'" >
                    {{ comp.valuationDates.percentageOfPar}}
                  </ng-container>
                </ng-template>
                  <ng-template #defaultValue>{{nullDefaultValue | dynamicDecimalPipe}}%</ng-template>
                  <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20" *ngIf="comp.loading"></mat-spinner>
              </ng-template>
            </e-column>

            <e-column field='valuationDates.grossIRR' [headerText]='translateService.getLabel("gross_irr")' textAlign='Right' [visible]='false' width="50px">
              <ng-template #template let-comp>
                <ng-template [ngIf]="comp.valuationDates.grossIRR && comp.valuationDates.grossIRR > 0 && !comp.loading">
                  <div>
                    <span [RawDataDirective]="comp.valuationDates.grossIRR">
                      {{comp.valuationDates.grossIRR | dynamicDecimalPipe}}%
                    </span>
                  </div>
                </ng-template>
                <ng-template [ngIf]="comp.valuationDates.grossIRR && comp.valuationDates.grossIRR < 0 && !comp.loading">
                  <div style="color:red">
                    <span [RawDataDirective]="comp.valuationDates.grossIRR">
                      {{comp.valuationDates.grossIRR | dynamicDecimalPipe}}%
                    </span>
                  </div>
                </ng-template>
                <ng-template [ngIf]="(!comp.valuationDates.grossIRR || comp.valuationDates.grossIRR == 0) && !comp.loading">{{nullDefaultValue | dynamicDecimalPipe}}%
                </ng-template>
                <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="20" *ngIf="comp.loading"></mat-spinner>
              </ng-template>
            </e-column>

            <e-column field='syntheticCreditRating' [headerText]='translateService.getLabel("credit_rating")' width='60px' textAlign='Right'>
              <ng-template #template let-comp>
                {{comp.valuationDates.syntheticCreditRating }}
              </ng-template>
            </e-column>

          </e-columns>
          <!-- <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column field="logo" type="sum">
                  <ng-template #footerTemplate>
                    Total
                  </ng-template>
                </e-column>
                <e-column field="value" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                    {{ issuerPorfolioSummaryTotal?.value | number: '1.1-1'}}
                  </ng-template>
                </e-column>
                <e-column field="percentageOfPar" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                    {{ issuerPorfolioSummaryTotal?.percentageOfPar | number: '1.1-1'}}%
                  </ng-template>
                </e-column>
                <e-column field="grossIRR" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                    {{ issuerPorfolioSummaryTotal?.grossIRR | number: '1.1-1'}}%
                  </ng-template>
                </e-column>
              </e-columns>
            </e-aggregate>
          </e-aggregates> -->
        </ejs-grid>
      </div>
    </div>
  </div>
</div>
