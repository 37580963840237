import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MessagePopupComponent } from 'src/app/utils/popup/message-popup.component';

@Component({
  selector: 'app-organization-ez',
  templateUrl: './organization-ez.component.html',
  styleUrls: ['./organization-ez.component.scss']
})
export class OrganizationEzComponent implements OnInit {

  constructor(
    public loaderService: ImageLoadService,
    private route: ActivatedRoute,
    private um: UserManagementService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.um.logout();
  }

  loginClick() {
      this.um.getSingleSignOnURL().subscribe((res: HttpResponse<any>) => {
        let redirectUrl = res.body["response"] + "&domain_hint=eurazeo.com";
        window.location.replace(redirectUrl);
      }, error => {
        console.log("Login error", error);
        this.showMessage("Single Sign On Failed");
      });
  }

  showMessage(msg) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessagePopupComponent, {
      data: {
        okButtonMsg: "Ok",
        dialogMsg: msg
      }
    });
  }

}
