<ng-template [ngIf]="generalAttributes">
    <div class="row" style="font-weight: 600;">
        <div class="col" style="padding-bottom: 10px; padding-top: 20px;"><span>Show / Hide Attribute</span></div>
        <div class="col-5" style="padding-top: 20px;"><span>Input</span></div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.location.show" (change)="updateShowHideStatus($event, 'location')"></mat-checkbox>
        </div>
         <div class="col"><span>{{ translateService.getLabel("location") }} </span>  </div>
        <div class="col-5">{{ generalAttributes.location }} </div>
    </div>
  
       <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.sector.show" (change)="updateShowHideStatus($event, 'sector')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("sector") }} </span></div>
        <div class="col-5">{{ generalAttributes.sector }} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.website.show" (change)="updateShowHideStatus($event, 'website')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("website") }} </span></div>
        <div class="col-5">{{ generalAttributes.website}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.investmentDate.show" (change)="updateShowHideStatus($event, 'investmentDate')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("investment_date") }} </span></div>
        <div class="col-5">{{ generalAttributes.investmentDate | date: "mediumDate"}}</div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.intialInvestment.show" (change)="updateShowHideStatus($event, 'intialInvestment')"></mat-checkbox>
            <!-- {{utilService.getDisplayFormattedNumber(bu.investment.enterpriseValue , 1, "Mn", "0")}} -->
        </div>
        <div class="col"><span>{{ translateService.getLabel("initial_investment") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.intialInvestment,1 ," Mn", "0")}}
        </div>
        <!-- <div>  </div> -->
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.investmentToDate.show" (change)="updateShowHideStatus($event, 'investmentToDate')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("investment_to_date") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.investmentToDate,1
            ," Mn", "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedownership.show" (change)="updateShowHideStatus($event, 'impliedownership')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("fully_diluted_ownership") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedownership, 1,"%", "0")}}
        </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.irr.show" (change)="updateShowHideStatus($event, 'irr')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("irr") }} </span></div>
        <div class="col-5">
            <span *ngIf="generalAttributes.irr<0" style="color: red"> {{generalAttributes.irr | number: "1.1-1"}}%
            </span>
            <span *ngIf="generalAttributes.irr>=0"> {{generalAttributes.irr | number: "1.1-1"}} % </span>
        </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.moic.show" (change)="updateShowHideStatus($event, 'moic')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("moic") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.moic,2 ,"x", "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.realisedproceeds.show" (change)="updateShowHideStatus($event, 'realisedproceeds')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("realised_proceeds") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.realisedproceeds,1 ," Mn", "0")}}
        </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedebitdamultiple.show" (change)="updateShowHideStatus($event, 'impliedebitdamultiple')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_ebitda_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedebitdamultiple,1 ,"x",
            "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedrevenuemultiple.show" (change)="updateShowHideStatus($event, 'impliedrevenuemultiple')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_revenue_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedrevenuemultiple,1 ,"x",
            "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.revenue.show" (change)="updateShowHideStatus($event, 'revenue')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("revenue") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.revenue,1 ," Mn", "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.ebitda.show" (change)="updateShowHideStatus($event, 'ebitda')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("ebitda") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.ebitda,1 ," Mn", "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.ebitdamargin.show" (change)="updateShowHideStatus($event, 'ebitdamargin')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("ebitda_margin") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.ebitdamargin,1 ,"%", "0")}} </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedebitmultiple.show" (change)="updateShowHideStatus($event, 'impliedebitmultiple')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_ebit_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedebitmultiple, 1 ,"x", "0")}}
        </div>
    </div>
    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedgrossprofitmultiple.show" (change)="updateShowHideStatus($event, 'impliedgrossprofitmultiple')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_gross_profit_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedgrossprofitmultiple,1 ,"x",
            "0")}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.priceearnings.show" (change)="updateShowHideStatus($event, 'priceearnings')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("price_of_earnings") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.priceearnings,1 ," Mn", "0")}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.pricebookvalue.show" (change)="updateShowHideStatus($event, 'pricebookvalue')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("price_of_book_value") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.pricebookvalue,1 ," Mn", "0")}}
        </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.pricesales.show" (change)="updateShowHideStatus($event, 'pricesales')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("price_of_sales") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.pricesales,1 ," Mn", "0")}} </div>
    </div>



    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedpricesales.show" (change)="updateShowHideStatus($event, 'impliedpricesales')"></mat-checkbox>
        </div>
        <div class="col"><span> {{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("sales_multiple") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.impliedpricesales,1 ," Mn", "0")}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedpricebookvalue.show" (change)="updateShowHideStatus($event, 'impliedpricebookvalue')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("book_value_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedpricebookvalue,1 ," Mn", "0")}}
        </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.impliedpriceearnings.show" (change)="updateShowHideStatus($event, 'impliedpriceearnings')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("implied_price") }} / {{ translateService.getLabel("earnings_multiple") }} </span></div>
        <div class="col-5">{{utilService.getDisplayFormattedNumber(generalAttributes.impliedpriceearnings,1 ,"x", "0")}} </div>
    </div>



    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.ebitmargin.show" (change)="updateShowHideStatus($event, 'ebitmargin')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("ebit_margin") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.ebitmargin,1 ," Mn", "0")}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.ebit.show" (change)="updateShowHideStatus($event, 'ebit')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("ebit") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.ebit,1 ," Mn", "0")}} </div>
    </div>

    <div class="row marginBottom">
        <div class="col-2 chck-align">
            <mat-checkbox [checked]="showHideStatusOfGeneralAttributes.grossprofit.show" (change)="updateShowHideStatus($event, 'grossprofit')"></mat-checkbox>
        </div>
        <div class="col"><span>{{ translateService.getLabel("gross_profit") }} </span></div>
        <div class="col-5">{{currency}} {{utilService.getDisplayFormattedNumber(generalAttributes.grossprofit,1 ," Mn", "0")}} </div>
    </div>



</ng-template>