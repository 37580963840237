<div class="box-with-shadow" style="margin : 10px 25px">
    <div class="row" style="margin: 5px -12px;">
        <div class="col" style="margin-left: 0;">
            <span class = "header" style="margin-left: 0;">
                {{translateService.getLabel("version_comparison")}} ({{valuationDate | date: "mediumDate"}})
            </span>
        </div>

        <div class="col"  style="text-align: right;">
            <button class="btn-custom-primary" style="line-height: 10px;" (click)="openFilters(filtersPopup)">{{translateService.getLabel("filters")}}</button> &nbsp;
            <button class="btn-custom-secondry" style="line-height: 10px;" (click)="closeWindow()">{{translateService.getLabel("close")}}</button>
        </div>
    </div>

    <div class="row table_sub_body table_header_style" style="margin: 0;">
        <div class="col-3">{{translateService.getLabel("particulars")}} ({{ versionsToBeUsed[0].currency }} Mn)</div> 

        <ng-template ngFor [ngForOf]="versionsToBeUsed" let-v>
            <div class="col">
                {{v.versionName | titlecase}}
            </div>
        </ng-template>
    </div>

    <ng-template ngFor [ngForOf]="comparisonData" let-row let-ri="index">
        <div class="row table_sub_body" style="margin: 0;" [class.table-last-row]="ri == (comparisonData.length - 1)">

            <ng-template ngFor [ngForOf]="row" let-columnValue let-ci="index">
                <div [class.col-3]="ci==0" [class.col]="ci>0">
                    {{columnValue}}
                </div>
            </ng-template>

        </div>
    </ng-template>

    <div  class="loading-container" *ngIf="!comparisonData || comparisonData.length == 0">
        <span class="loading-text">
          <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
        </span>
    </div>
</div>

<ng-template #filtersPopup let-filtersPopupModal>
    <div class="modal-header">
      <div class="modal-title" style="width: 100%;">
        <h6>{{translateService.getLabel("version_comparison_filters")}}</h6>
      </div>

      <button type="button" class="close" aria-label="Close" (click)="filtersPopupModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
        <app-comparison-filters [filters]="filtersForSelection" 
            (change)="filtersSelectionChange(filter, $event);">

        </app-comparison-filters>
    </div>

    <div class="modal-footer" style="text-align: right;">
      <button class="btn-custom-primary" (click)="saveFilterPreferences(); filtersPopupModal.dismiss('Cross click')">{{translateService.getLabel("save")}}</button>
    </div>
</ng-template>
