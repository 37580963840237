import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckbox, MatSlideToggleChange } from '@angular/material';
import { Subscription } from 'rxjs';
import { Company } from '../../ca-interfaces';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListComponent implements OnInit, AfterViewInit, OnDestroy {
  private commonSub = new Subscription();

  allCompanies: Company[] = [];

  trackByIdFn = (index: number, company: Company) => company.companyId;

  @ViewChild('selecAllChkbx') selecAllChkbx: MatCheckbox;

  selectedCompanies = new Set<Company>();

  checkBoxState = false;
  indeterminateState = false;

  selectedOnly = false;

  _companyIdsWithConflict: { [key: string]: boolean } = {};

  @Input() set companies(companies: Company[]) {
    if (companies) {
      this.allCompanies = [...companies];
      if (this.selectedCompanies.size > 0) {
        this.selectedCompanies.clear();
        this.checkBoxState = false;
        this.selectedOnly = false;
        this.companySelectionChange.emit(this.selectedCompanies);
      }
    }
  }

  @Input() set companyIdsWithConflict(values) {
    if (values) {
      this._companyIdsWithConflict = values;
    }
  }

  // _defaultSelectedCompanies: Set<Company>;

  // @Input() set defaultSelectedCompanies(values: Set<Company>) {
  //   if (values.size > 0) {
  //     this._defaultSelectedCompanies = values;
  //     // this.markCompanyAsSelected();
  //   }
  // }

  @Output() companySelectionChange = new EventEmitter();

  @Output() applyClicked = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  areAllInSet() {
    return this.allCompanies.length === this.selectedCompanies.size;
  }

  areSomeInSet() {
    return this.selectedCompanies.size && this.allCompanies.length > this.selectedCompanies.size;
  }

  // markCompanyAsSelected() {
  //   this.selectedCompanies.clear();
  //   this._defaultSelectedCompanies.forEach((dsc) => {
  //     this.allCompanies.forEach((c) => {
  //       console.log(dsc.companyId === c.companyId);
  //       if (dsc.companyId === c.companyId) {
  //         this.selectedCompanies.add(c);
  //       } else {
  //         this.selectedCompanies.delete(c);
  //       }
  //     });
  //   });
  // }

  toggleCompanySelection(event, company) {
    // add or remove company to set
    if (this.selectedCompanies.size > 0 && this.selectedCompanies.has(company)) {
      this.selectedCompanies.delete(company);
    } else {
      this.selectedCompanies.add(company);
    }

    // change controlled checkbox state
    if (this.areAllInSet()) {
      this.checkBoxState = true;
      this.indeterminateState = false;
      this.selecAllChkbx.indeterminate = false;
    } else if (this.areSomeInSet()) {
      this.checkBoxState = false;
      this.indeterminateState = true;
      this.selecAllChkbx.indeterminate = true;
    } else {
      this.checkBoxState = false;
      this.indeterminateState = false;
      this.selecAllChkbx.indeterminate = false;
    }
    // console.log(this.selectedCompanies, 'Selected');
    this.companySelectionChange.emit(this.selectedCompanies);
  }

  onCheckboxStateChange(event) {
    if (!event?.checked) {
      if (this.areAllInSet() || this.areSomeInSet()) {
        this.allCompanies.forEach((c) => this.selectedCompanies.delete(c));
        this.checkBoxState = false;
        this.selecAllChkbx.checked = false;
      } else {
        this.allCompanies.forEach((c) => this.selectedCompanies.add(c));
        this.checkBoxState = true;
      }
    } else {
      this.allCompanies.forEach((c) => this.selectedCompanies.delete(c));
      this.checkBoxState = false;
    }

    this.companySelectionChange.emit(this.selectedCompanies);
  }

  sortBySelectedOnly(e: MatSlideToggleChange) {
    this.selectedOnly = e.checked;
    this.checkBoxState = e.checked;
    this.indeterminateState = !e.checked;
    this.selecAllChkbx.indeterminate = !e.checked;
    this.selecAllChkbx.checked = e.checked;
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
