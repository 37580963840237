import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class TrackRecordService {
    preferences = {
        bevRevenue_primary: true,
        bevEbitda_secondary: true,
        bevRevenueMultiple_primary: true,
        bevEbitdaMultiple_secondary: true,
        enterpriseValue: true,
        netDebt: true,
        equityValue: true,
        adjustmentsToEquityValue: true,
        adjustedEquityValue: true,
        adjustedEquityValueWaterfall: false,
        stake: true,
        stakeValue: true,
        debtAndOthers: true,
        value: true,
        investedAmount: true,
        realisedProceeds: true,
        investmentMultiple: true,
        dpi: true,
        irr_YTD:false,
        irr: true
    }
    
    templatePreferences = {
        bevRevenue_primary: true,
        bevEbitda_secondary: true,
        bevRevenueMultiple_primary: true,
        bevEbitdaMultiple_secondary: true,
        enterpriseValue: true,
        netDebt: true,
        equityValue: true,
        adjustmentsToEquityValue: true,
        adjustedEquityValue: true,
        adjustedEquityValueWaterfall: false,
        stake: true,
        stakeValue: true,
        debtAndOthers: true,
        value: true,
        investedAmount: true,
        realisedProceeds: true,
        investmentMultiple: true,
        dpi: true,
        irr_YTD:false,
        irr: true
    }
}