<div class="container" style="padding: 0;">

    <div class="row">
        <div class="col">
            <div class="row marginTop">
                <div class="col-10">
                    <mat-radio-button name="customStakeSelection"
                        (change)="consolSummary.customStake.selectedOption = 'stakeFromModel'"
                        [checked]="consolSummary.customStake.selectedOption === 'stakeFromModel'">
                        Automated Stake From the Model
                    </mat-radio-button>
                </div>
                <div class="col-2 rightAlignment">
                    {{consolSummary.customStake.stakeFromModel.value | number: '1.2-2'}} %
                    <!-- <ng-template [ngIf]="consolSummary.customStake.stakeFromModel">{{consolSummary.customStake.stakeFromModel.value | number: '1.2-2'}} % </ng-template> -->
                </div>
                <!-- <div class="col-2 rightAlignment">
                    <button mat-raised-button class="secondary-bg" (click)="getValueFromModel()">
                        Get selected from model
                    </button>
                </div> -->
            </div>
            <div class="row marginTop">
                <div class="col-10">
                    <mat-radio-button name="customStakeSelection"
                        (change)="consolSummary.customStake.selectedOption = 'manualEntry'"
                        [checked]="consolSummary.customStake.selectedOption === 'manualEntry'">
                        Manual Entry
                    </mat-radio-button>
                </div>
                <div class="col-2 rightAlignment">
                    <mat-form-field class="custom-textbox formFieldWidth rightAlignment">
                        <input type="text" matInput [(ngModel)]="consolSummary.customStake.manualEntry.value"
                            (input)="utilService.acceptOnlyPercentage($event, consolSummary.customStake.manualEntry, 'value')">
                        <span matSuffix>%</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="row marginTop">
                <div class="col-10">
                    <mat-radio-button name="customStakeSelection"
                        (change)="consolSummary.customStake.selectedOption = 'dilutedStake'"
                        [checked]="consolSummary.customStake.selectedOption === 'dilutedStake'">
                        Fully Diluted Stake from Cap Table
                    </mat-radio-button>
                </div>
                <div class="col-2 rightAlignment">
                    {{consolSummary.customStake.dilutedStake.value | number: '1.2-2'}} %
                </div>
            </div>
            <div class="row marginTop">
                <div class="col-10">
                    <mat-radio-button name="customStakeSelection"
                        (change)="consolSummary.customStake.selectedOption = 'impliedStakeWaterfall'"
                        [checked]="consolSummary.customStake.selectedOption === 'impliedStakeWaterfall'">
                        Implied stake from Waterfall
                    </mat-radio-button>
                </div>
                <div class="col-2 rightAlignment">
                    {{consolSummary.customStake.impliedStakeWaterfall.value | number: '1.2-2'}} %
                </div>
            </div>

            <!-- Man Pack Stake -->
            <div class="row marginTop" *ngIf = "consolSummary.customStake.impliedStakeManpack.exists; else manOption">
                <div class="col-10 ">
                    <mat-radio-button name="customStakeSelection"
                        (change)="consolSummary.customStake.selectedOption = 'impliedStakeManpack'"
                        [checked]="consolSummary.customStake.selectedOption === 'impliedStakeManpack'">
                        Implied stake from Management Package / Management Options
                    </mat-radio-button>
                </div>
                <div class="col-2 rightAlignment">
                    {{consolSummary.customStake.impliedStakeManpack.value | number: '1.2-2'}} %
                </div>
            </div>

            <!-- Man Option stake -->
            <ng-template #manOption>
                <div class="row marginTop">
                    <div class="col-10">
                        <mat-radio-button name="customStakeSelection"
                            (change)="consolSummary.customStake.selectedOption = 'impliedStakeManOption'"
                            [checked]="consolSummary.customStake.selectedOption === 'impliedStakeManOption'">
                            Implied stake from Management Package / Management Options
                        </mat-radio-button>
                    </div>
                    <div class="col-2 rightAlignment">
                        {{consolSummary.customStake.impliedStakeManOption.value | number: '1.2-2'}} %
                    </div>
                </div>
            </ng-template>
            <!-- <div class="row marginTop">
                  <div class="col-10">
                      <mat-radio-button name="customStakeSelection"
                          (change)="consolSummary.customStake.selectedOption = 'teasuryStockStake'"
                          [checked]="consolSummary.customStake.selectedOption === 'teasuryStockStake'">
                          Net Diluted Stake – Treasury Stock Method
                      </mat-radio-button>
                  </div>
                  <div class="col-2 rightAlignment">
                    {{utilService.getDisplayFormattedNumber(consolSummary.customStake.teasuryStockStake?.value, 2, '%', '0.00 %')}}
                  </div>
            </div> -->

        </div>
    </div>
    <div class="row marginAround" *ngIf = "stakeModel.showExcelUI">
        <div class="col">

        </div>
        <div class="col-3" style="text-align: right;max-width: fit-content;">
            <button mat-raised-button class="btn-custom-secondry" style="padding: 10px !important;" (click)="getValueFromModel()" matTooltip="Select a cell in the excel and click on this to copy the stake %.">
                Copy Stake Percentage
            </button>
        </div>
        <div class="col-2" style="text-align: right;max-width: fit-content;">
            <button mat-raised-button class="btn-custom-primary" style="padding: 10px !important;"  (click)="copyEquityValue()"[matTooltip]="translateService.getLabel('select_excel_sheet_equity_value_selected_cell')">
                {{translateService.getLabel("copy_equity_value")}}
            </button>
        </div>
    </div>
    <div class="marginAround">
        <div #excelUI style="height: 300px; width: 100%;" class="excel-view" *ngIf = "stakeModel.showExcelUI" ></div>
    </div>
    <div class="row marginAround">
        <div class="col">
            <button class="btn-custom-secondry" (click)="clickOnFileInput(fileInput)">
                <input type="file" accept=".xls,.xlsx" style="display: none;" #fileInput class="custom-file-input"
                    (change)="uploadExcelEvent($event)" />
                {{translateService.getLabel("upload")}}
            </button>

            &nbsp;
            <button mat-raised-button class="btn-custom-primary" (click) = "downloadExcelData()">
                {{translateService.getLabel("fetch_uploaded_file")}}
            </button>
        </div>

        <!-- <div class="col-3" style="text-align: right;" *ngIf = "stakeModel.showExcelUI">
            <button mat-raised-button class="btn-custom-secondry" (click)="copyEquityValue()" matTooltip="Select a cell in excel sheet and click here to copy adjusted equity value into selected cell.">
                Copy Equity Value
            </button>
        </div> -->

        <div class="col-2" style="text-align: right;">
            <button mat-raised-button class="btn-custom-primary" (click)="saveData()">
                {{translateService.getLabel("save")}}
            </button>
        </div>
    </div>
</div>
