import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "src/app/services/translation.service";
import { UserManagementService } from "src/app/services/user-management.service";
import { ApprovalMatrixService } from "../../check-list-pop-up/approval-matrix/approval-matrix.service";

@Component({
  selector: "app-old-approval-requests",
  templateUrl: "./old-approval-requests.component.html",
  styleUrls: ["./old-approval-requests.component.scss"]
})
export class OldApprovalRequestsComponent implements OnInit {

  @Input() item;
  @Output() updateValDatesTable = new EventEmitter();

  constructor(
    public approvalMatrixService: ApprovalMatrixService,
    public translateService: TranslateService,
    public ums: UserManagementService,
  ) {}

  ngOnInit() {


  }

  updateApprovalRequest(item, action){

    const request = {
      "data" : item,
      "action" : action
    }
    this.updateValDatesTable.emit(request);
    
  }

}
