<!-- <nav-menu-header headerLabel="Funds" headerToBeAppended="Portfolio"></nav-menu-header> -->
<div class="top-header">
  <span style="font-size: 20px">
    Valuations
  </span>
</div>

<div style="margin: 0px">
  <table class="table table-condensed" id="container">
    <tr class="bg primary-bg">
        <!-- <th>Name</th> -->
        <th>Valuation Date</th>
        <th>Valuation Amount</th>
        <th>Security</th>       

        <td style="width: 115px;">Shared On</td>

        <th></th>
        <th style="width: 50px"></th>
    </tr>

    <ng-template ngFor [ngForOf]="sharedCompanyDates" let-comp  let-index="index">
      <tr>
        <!-- <td>{{comp.companyName}}</td> -->
        <td style="width: 30%;">
          <div>{{comp.companyNameInForm}}</div>
          <div>{{comp.valuationDate | date: "mediumDate"}}</div>
          <div *ngIf="comp.versionName">{{comp.versionName}}</div>
          <!-- <i class="far fa-edit primary-color-medium action-icon" (click)="openPopupModal(editValuationDateModal, index)"></i> -->
        </td>
        <td>
          <ng-template [ngIf]="comp.investment && comp.investment.equityValue.stakeValue > 0">
            <ng-template [ngIf]="comp.investment.currency">
              {{comp.investment.currency}}
            </ng-template>
            
            <ng-template [ngIf]="comp.investment.equityValue.finalStakeValue">
              {{comp.investment.equityValue.finalStakeValue | number: "1.1-1"}} Mn
            </ng-template>
            <ng-template [ngIf]="!comp.investment.equityValue.finalStakeValue">
              {{comp.investment.equityValue.stakeValue | number: "1.1-1"}} Mn
            </ng-template>
          </ng-template>
        </td>
        <td>{{comp.security}}</td>

        <td style="width: 115px;">
          <ng-template [ngIf]="comp.sharedOn">
            {{comp.sharedOn | date: 'mediumDate'}}
          </ng-template>
        </td>

        <td>
          <ng-template [ngIf]="!comp.userEntered">
            <i class="fas fa-binoculars primary-color action-icon" (click)="openValuation(comp)" *ngIf="comp.status !== 'Initiated'"></i>
          </ng-template>
        </td>
        
        <td>
        </td>
      </tr>
    </ng-template>
  </table>
</div>