import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Chart, MapChart } from 'angular-highcharts';
import { PortFolioService } from '../portfolio.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import cloneDeep from 'lodash/cloneDeep';
import worldMap from '../world-data.data';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { FundListService } from '../fund-list-ui/fund-list.service';
import { TranslateService } from 'src/app/services/translation.service';
import { ThemeService } from 'src/app/utils/theme.service';

@Component({
  selector: 'app-portfolio-summary',
  templateUrl: './portfolio-summary.component.html',
  styleUrls: ['./portfolio-summary.component.scss']
})
export class PortfolioSummaryComponent implements OnInit {
  user
  logoImage
  pieGraph
  selectedGainers
  squareGraph
  timeChart

  autoNumberFormat: boolean = false;

  invest
  exitsObj = {
    companyName : '',
    website: '',
    valuation: 0,
    investmentAmount: 0,
    exitType: ''
  }
 
  partialExitPie = [{ name: 'Other', y: 1, color: '#FFF'}, { name: 'Other', y: 3, color: this.themeService.primaryColor}];
  fullExitPie = [{ name: 'Other', y: 3, color: this.themeService.primaryColor}];
  smallPieChartsLegends = [];

  summaryPage = {
    dbData: {
      exits: { list: [], subHeader: "" },
      autoNumberFormat: false,
      companiesList : {        
        "firstSet": { "header": "Top Gainers", color: "#EDF6F1", list: [] },
        "secondSet": { "header": "Top Losers", color: "#fbefef", list: [] },
        "thirdSet": { "header": "Old Investments", color: "#fbf7d9", list: [] }    
      }
    },
    nonDbData: {
      exits: { barCharts: [], pieCharts: [] },
      investments: { barCharts: [], logos: [], subHeader: ""}
    }
  }

  worldRegionChart;
  fundName
  
  squareGraphData = [];
  pieGraphData = [];
  totalValueChange = 0;
  totalValueChangePercentage = 0;
  latestValData;

  portFolioChart
  smallPieCharts = []
  pieChartColors = [this.themeService.primaryColor, "#5FAFF2", "#A3CDFA", "#EEFFC6", "#EDED4A", "#FFD32B"]

  constructor(private ums: UserManagementService, private dataService: DataService, 
    private sanitizer: DomSanitizer, public portfolioService:PortFolioService, 
    private modalService:NgbModal, private utilService: UtilService, private currExchangeService: CurrencyExchangeService,
    private fundService: FundListService, public translateService: TranslateService,
    private themeService: ThemeService) 
  { }

  ngOnInit() {

    this.fundName = this.fundService.getFundName(this.portfolioService.selectedFundId);
    // console.log("portfolioSummary", this.portfolioService.portfolioSummary)
    this.portfolioService.portfolioDataClone.forEach( comp => {
      if(this.currExchangeService.exchangeRates[comp.currency + this.portfolioService.selectedCurrency + comp.valuationDate]){
        const currencyKey = comp.currency + this.portfolioService.selectedCurrency + comp.valuationDate;
        
        comp['exchangeRate'] = this.currExchangeService.exchangeRates[currencyKey] 
          ? this.currExchangeService.exchangeRates[currencyKey] 
          : 1;
      }
    })
    if(this.portfolioService.portfolioSummary){
      this.summaryPage.dbData = this.portfolioService.portfolioSummary
    }
    this.init()
    this.prepareAreaChart();
    this.preparePieGraph();

    this.initInvestments();

    this.initMapChart();

    this.portFolioPerformaneChart()
    // this.initTimeSeriesChart()
    //this.portfolioService.portfolioSummary
    if(false) {
      this.summaryPage.dbData = this.portfolioService.portfolioSummary;
    } else {
      this.createExitCompanyList();
      this.initCompanyListData(this.portfolioService.topGainers, this.portfolioService.topLosers, null);
    }

    this.initExits();
  }

  initExits() {    
    this.initExitBarChart()
    this.initSmallPieChart()
  }

  initInvestments() {
    this.portfolioService.portfolioData.forEach((comp, index) =>{
      if(this.summaryPage.nonDbData.investments.barCharts.length < 3 && comp.fairVal && comp.fairVal > 0) {
        const valuation = comp.changeInValPercentage ? (comp.fairVal * comp.changeInValPercentage / 100).toFixed(2): 0;
        this.summaryPage.nonDbData.investments.barCharts.push(this.initBarCharts(valuation, comp.fairVal));

        this.summaryPage.nonDbData.investments.logos.push({ logo: comp.logo });

        const totalRecentInvestments = this.summaryPage.nonDbData.investments.barCharts.reduce((total, row)=> total + row.options.series[1].data[0], 0);

        this.summaryPage.nonDbData.investments.subHeader 
          = "Invested in " + this.summaryPage.nonDbData.investments.barCharts.length 
          + " New Companies a total of " 
          + (totalRecentInvestments && !isNaN(totalRecentInvestments) ? Number(totalRecentInvestments).toLocaleString() : 0)
          + " Million";
      }
    })
  }

  initCompanyListData(topGainers, topLosers, others) {
    this.initCompanyList(this.summaryPage.dbData.companiesList.firstSet, topGainers);

    this.initCompanyList(this.summaryPage.dbData.companiesList.secondSet, topLosers);

    this.initCompanyList(this.summaryPage.dbData.companiesList.thirdSet, others);
  }

  initCompanyList(uiSet, companies) {
    if(companies && companies.length > 0) {
      uiSet.list = companies.map((data) => {
        
        if(!data) return {};
        
        return {
          "logo": data.logo,
          "name": data.companyName,
          "investment": data.stakeVal,
          "growth": (data.changeInValPercentage) * 100,
          "description": ""
        }
      })
    }
  }

  initMapChart() {
    const regionMapSeries = [];

    this.portfolioService.geographicalFormData.forEach(region =>{
      if(!region.countryCode) return;
      
      let totalChangeInValueInRegion = 0;

      region.forms.forEach((form) => {
        const calculatedForm = this.portfolioService.portfolioData.find(pForm => pForm.id === form.id);
        if(calculatedForm && calculatedForm.changeInStakeValue) {
          totalChangeInValueInRegion += +calculatedForm.changeInStakeValue;
        }
      })

      const concludedChangeInRegion = (totalChangeInValueInRegion / region.forms.length) * 100;

      regionMapSeries.push({
        code3: region.countryCode,
        name: region.countryName,
        z: +concludedChangeInRegion.toFixed(1)
      })
    })

    const worldRegionChart = new MapChart({
      chart: { 
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          type: 'mapbubble',
          name: '',
          joinBy: ['iso-a3', 'code3'],
          data: regionMapSeries,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormat: '{point.properties.name}:{point.z}'
          }
      }] as any
    });

    this.worldRegionChart = worldRegionChart;
  }

  logoSourceError(event, company) {
    company.logo = null;
  }

  init() {
    
  }

  initPieGraph() {

    this.pieGraph = new Chart({
      chart: { type: 'pie' },

      title: { text: '' },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          innerSize: '70%',
          dataLabels: { enabled: false }
        },
      },
      credits: { enabled: false },
      colors: this.pieChartColors,
      series: [{ 
        name: "Total Value Change",
        colorByPoint: true,
        data: this.pieGraphData }] as any
    })
  }

  initSquareGraph() {
    this.squareGraph = new Chart({
      title: { text: '' },
      plotOptions: {
        treemap: { dataLabels: { enabled: false } }
      },

      tooltip: {
        enabled: true
      },


      credits: { enabled: false },
      colors: this.pieChartColors,
      series: [{
        type: "treemap",
        layoutAlgorithm: 'stripes',
        alternateStartingDirection: true,
        colorByPoint: true,
        levels: [{
          level: 1,
          layoutAlgorithm: 'sliceAndDice',
          dataLabels: {
            enabled: false,
            align: 'left',
            verticalAlign: 'top',
            style: { fontSize: '15px', fontWeight: 'bold' }
          }
        }],
        data: this.squareGraphData
      }] as any,
    })
  }

  initExitBarChart() {
    let chartBar
    for (let i = 0; i < this.summaryPage.dbData.exits.list.length; i++) {
      chartBar = this.initBarCharts(this.summaryPage.dbData.exits.list[i].valuation, this.summaryPage.dbData.exits.list[i].investmentAmount)
      this.summaryPage.nonDbData.exits.barCharts.push(cloneDeep(chartBar));
    }
  }

  initBarCharts(valuation, investment) {
    let chart = new Chart({
        chart: { type: 'bar', height: 60 },
        title: { text: '' },
        xAxis: { visible: false },
        yAxis: { title: { text: '' }, visible: false },
        legend: { enabled: false },
        exporting: { enabled: false },
        credits: { enabled: false },
        tooltip: {
          enabled: false,
          formatter(event) {
            return this.point.series.name + " (" + this.y + ")";
          }
        },
        plotOptions: {
          bar: {
            dataLabels: { enabled: false }
          }
        },
        series: [
          { name: "", data: [valuation], color: this.themeService.primaryColor },
          { name: "", data: [investment], color: "#FFD32B" }
        ] as any
      });

      return chart;
  }

  initSmallPieChart() {
    this.smallPieChartsLegends = [this.getSmallPieChart(this.partialExitPie, 30, 30), this.getSmallPieChart(this.fullExitPie, 30, 30)];

    for (let i = 0; i < this.summaryPage.dbData.exits.list.length; i++) {
      let chart = this.getSmallPieChart(this.summaryPage.dbData.exits.list[i].data, 50, 50);
      this.summaryPage.nonDbData.exits.pieCharts[i] = cloneDeep(chart);
    }
  }

  getSmallPieChart(data, width, height) {
    let chart = new Chart({
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          width: width,
          height: height,
          
          spacingTop: 0,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          margin: [0,0,0,0]
        },
        credits: { enabled: false },
        title: { text: '' },
        tooltip: { enabled: false },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            animation: false,
            cursor: 'pointer',
            dataLabels: { enabled: false },
            showInLegend: false,
            events:{ click: function(e){ } },
            states: { hover: { enabled: false } },
          }
        },
        series: [{ name: '', colorByPoint: false, data: data, }] as any
      })

    return chart;
  }

  portFolioPerformaneChart() {
    this.portFolioChart = new Chart({
      chart: {
        type: 'bar',
      },
      title: {
        text: ''
      },
      xAxis: { visible: true, categories: ["Services", "Electronics", "Consumer", "Real Estate", "Technology", "Healthcare", "Overall Portfolio", "S&P 500"] },
      yAxis: { title: { text: '' }, visible: true },
      legend: { enabled: true },
      exporting: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        enabled: false,
        formatter(event) {
          return this.point.series.name + " (" + this.y + ")";
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: false
          }
        },
        series: {

        }
      },
      series: [
        { name: "hello", data: [1, 2, 3, 5, -6, 8, 3, 7], color: "#5FAFF2" },
        { name: "hello", data: [1, -2, 7, 8, 3, 4, -3, 6], color: this.themeService.primaryColor }
      ] as any
    });
  }

  prepareAreaChart(){
    this.portfolioService.sectors.forEach((sector, index) => {
      const sectorWiseForms = this.portfolioService.portfolioData.filter(form => form.sector == sector);
      let changeInValue = 0;

      // console.log("sectorWiseForms", sectorWiseForms);
      sectorWiseForms.forEach(sectForm => {
        changeInValue += sectForm.changeInStakeValue ? (sectForm.changeInStakeValue * sectForm.exchangeRate) : 0;
      });
      this.squareGraphData.push({
        id: sector.toLowerCase(),
        name: sector,
        parent: sector+'dept',
        value: changeInValue,
      })
    });

    this.squareGraphData = this.squareGraphData.sort((data1, data2)=>{
      return data2.value == data1.value? 0 : (data2.value > data1.value)? 1 : -1;
    });

    // console.log("squareGraphData", this.squareGraphData)
    this.initSquareGraph();
  }

  preparePieGraph(){
    // console.log(this.portfolioService.portfolioDataClone)

    const forms = this.portfolioService.portfolioDataClone.sort((data1, data2)=>{
      return data1.stakeVal == data2.stakeVal? 0 : data2.stakeVal > data1.stakeVal? 1 : -1;
    });

    forms.forEach( data => {
      this.pieGraphData.push([data.companyName, data.stakeVal, data.prevStakeValue, data.exchangeRate]);
    });

    let totalPrevStakeValue = 0
    let totalCurrStakeValue = 0
    this.portfolioService.portfolioDataClone.map( data => {
      this.totalValueChange += ((data.stakeVal - data.prevStakeValue) * data.exchangeRate);
      totalPrevStakeValue += (data.prevStakeValue * data.exchangeRate);
      totalCurrStakeValue += (data.stakeVal * data.exchangeRate);
    })

    // console.log(totalPrevStakeValue)
    // console.log(totalCurrStakeValue)
    // console.log(this.totalValueChange)

    this.totalValueChangePercentage = this.totalValueChange/totalPrevStakeValue;

    // console.log("pieGraphData", this.pieGraphData)
    this.initPieGraph();
  }

  openPopUpAddCompanies(content, type) {
    if (type == "topGainers")
      this.selectedGainers = "topGainers"
    else if (type == "topLoosers")
      this.selectedGainers = "topLoosers"
    else
      this.selectedGainers = "oldInvestments";
      
    this.portfolioService.portfolioData.forEach((comp) => {
      comp.checked = false;
    });

    this.modalService.open(content, { centered: true, size: "lg" })
  }

  openPopUpModel(content){
    this.modalService.open(content,{ centered: true })
  }

  createExitCompanyList(){
    const exitCompObject = {
      companyName: this.exitsObj.companyName,
      logo: this.exitsObj.website ? "https://logo.allindigital.se/" + this.utilService.getDomainName(this.exitsObj.website) : null,
      website: this.exitsObj.website,
      valuation: this.exitsObj.valuation,
      investmentAmount: this.exitsObj.investmentAmount,
      exitType: this.exitsObj.exitType,
      data: this.exitsObj.exitType == "partialExit" ? this.partialExitPie : this.fullExitPie
    }
    this.summaryPage.dbData.exits.list.push(exitCompObject);

    const sumOfExits = this.summaryPage.dbData.exits.list.reduce( (total, curValue)=> total + +curValue.investmentAmount, 0);

    this.summaryPage.dbData.exits.subHeader = "Exited from " + this.summaryPage.dbData.exits.list.length + " Companies a total of "+ sumOfExits +" Million"

    this.summaryPage.nonDbData.exits.barCharts.push(this.initBarCharts(exitCompObject.valuation, exitCompObject.investmentAmount));

    this.initSmallPieChart();
    this.exitsObj = {
      companyName : '',
      website: '',
      valuation: 0,
      investmentAmount: 0,
      exitType: ''
    }
  }

  addToList() {
    let selectedArray = this.portfolioService.portfolioData.filter( comp => comp.checked );

    if (this.selectedGainers == "topGainers") {
      if(!this.summaryPage.dbData.companiesList.firstSet["list"]) {
        this.summaryPage.dbData.companiesList.firstSet["list"] = []
      }

      if(selectedArray) {
        this.summaryPage.dbData.companiesList.firstSet["list"] = this.summaryPage.dbData.companiesList.firstSet["list"].concat(this.getFormattedSelectedGainers(selectedArray))
      } 
    } else if(this.selectedGainers == "topLoosers") {
      if(!this.summaryPage.dbData.companiesList.secondSet["list"]) {
        this.summaryPage.dbData.companiesList.secondSet["list"] = []
      }

      if(selectedArray) {
        this.summaryPage.dbData.companiesList.secondSet["list"] = this.summaryPage.dbData.companiesList.secondSet["list"].concat(this.getFormattedSelectedGainers(selectedArray))
      }
    } else {
      if(!this.summaryPage.dbData.companiesList.thirdSet["list"]) {
        this.summaryPage.dbData.companiesList.thirdSet["list"] = []
      }

      if(selectedArray) {
        this.summaryPage.dbData.companiesList.thirdSet["list"] = this.summaryPage.dbData.companiesList.thirdSet["list"].concat(this.getFormattedSelectedGainers(selectedArray))
      } 
    }

    this.modalService.dismissAll();
  }

  getFormattedSelectedGainers(selectedArray) {
    const returnArray = selectedArray.map( comp => {
      return {
        "logo": comp.logo,
        "name": comp.companyName,
        "investment": comp.fairVal,
        "growth": (comp.changeInValPercentage) * 100,
        "description": ""
      }
    })

    return returnArray;
  }

  deleteGainer(gainersList, index) {
    gainersList.splice(index, 1);
  }

  initTimeSeriesChart() {
    let data = [
      [
        1167609600000,
        0.7537
      ],
      [
        1167696000000,
        0.7537
      ],
      [
        1167782400000,
        0.7559
      ],
      [
        1167868800000,
        0.7631
      ],
      [
        1167955200000,
        0.7644
      ],
      [
        1168214400000,
        0.769
      ],
      [
        1168300800000,
        0.7683
      ],
      [
        1168387200000,
        0.77
      ],
      [
        1168473600000,
        0.7703
      ],
      [
        1168560000000,
        0.7757
      ],
      [
        1168819200000,
        0.7728
      ],
      [
        1168905600000,
        0.7721
      ],
      [
        1168992000000,
        0.7748
      ],
      [
        1169078400000,
        0.774
      ],
      [
        1169164800000,
        0.7718
      ],
      [
        1169424000000,
        0.7731
      ],
      [
        1169510400000,
        0.767
      ],
      [
        1169596800000,
        0.769
      ],
      [
        1169683200000,
        0.7706
      ],
      [
        1169769600000,
        0.7752
      ],
      [
        1170028800000,
        0.774
      ],
      [
        1170115200000,
        0.771
      ],
      [
        1170201600000,
        0.7721
      ],
      [
        1170288000000,
        0.7681
      ],
      [
        1170374400000,
        0.7681
      ],
      [
        1170633600000,
        0.7738
      ],
      [
        1170720000000,
        0.772
      ],
      [
        1170806400000,
        0.7701
      ],
      [
        1170892800000,
        0.7699
      ],
      [
        1170979200000,
        0.7689
      ],
      [
        1171238400000,
        0.7719
      ],
      [
        1171324800000,
        0.768
      ],
      [
        1171411200000,
        0.7645
      ],
      [
        1171497600000,
        0.7613
      ],
      [
        1171584000000,
        0.7624
      ],
      [
        1171843200000,
        0.7616
      ],
      [
        1171929600000,
        0.7608
      ],
      [
        1172016000000,
        0.7608
      ],
      [
        1172102400000,
        0.7631
      ],
      [
        1172188800000,
        0.7615
      ],
      [
        1172448000000,
        0.76
      ],
      [
        1172534400000,
        0.756
      ],
      [
        1172620800000,
        0.757
      ],
      [
        1172707200000,
        0.7562
      ],
      [
        1172793600000,
        0.7598
      ],
      [
        1173052800000,
        0.7645
      ],
      [
        1173139200000,
        0.7635
      ],
      [
        1173225600000,
        0.7614
      ],
      [
        1173312000000,
        0.7604
      ],
      [
        1173398400000,
        0.7603
      ],
      [
        1173657600000,
        0.7602
      ],
      [
        1173744000000,
        0.7566
      ],
      [
        1173830400000,
        0.7587
      ],
      [
        1173916800000,
        0.7562
      ],
      [
        1174003200000,
        0.7506
      ],
      [
        1174262400000,
        0.7518
      ],
      [
        1174348800000,
        0.7522
      ],
      [
        1174435200000,
        0.7524
      ],
      [
        1174521600000,
        0.7491
      ],
      [
        1174608000000,
        0.7505
      ],
      [
        1174867200000,
        0.754
      ],
      [
        1174953600000,
        0.7493
      ],
      [
        1175040000000,
        0.7493
      ],
      [
        1175126400000,
        0.7491
      ],
      [
        1175212800000,
        0.751
      ],
      [
        1175472000000,
        0.7483
      ],
      [
        1175558400000,
        0.7487
      ],
      [
        1175644800000,
        0.7491
      ],
      [
        1175731200000,
        0.7479
      ],
      [
        1175817600000,
        0.7479
      ],
      [
        1176076800000,
        0.7479
      ],
      [
        1176163200000,
        0.7449
      ],
      [
        1176249600000,
        0.7454
      ],
      [
        1176336000000,
        0.7427
      ],
      [
        1176422400000,
        0.7391
      ],
      [
        1176681600000,
        0.7381
      ],
      [
        1176768000000,
        0.7382
      ],
      [
        1176854400000,
        0.7366
      ],
      [
        1176940800000,
        0.7353
      ],
      [
        1177027200000,
        0.7351
      ],
      [
        1177286400000,
        0.7377
      ],
      [
        1177372800000,
        0.7364
      ],
      [
        1177459200000,
        0.7328
      ],
      [
        1177545600000,
        0.7356
      ],
      [
        1177632000000,
        0.7331
      ],
      [
        1177891200000,
        0.7351
      ],
      [
        1177977600000,
        0.7351
      ],
      [
        1178064000000,
        0.736
      ],
      [
        1178150400000,
        0.7347
      ],
      [
        1178236800000,
        0.7375
      ],
      [
        1178496000000,
        0.7346
      ],
      [
        1178582400000,
        0.7377
      ],
      [
        1178668800000,
        0.7389
      ],
      [
        1178755200000,
        0.7394
      ],
      [
        1178841600000,
        0.7416
      ],
      [
        1179100800000,
        0.7382
      ],
      [
        1179187200000,
        0.7388
      ],
      [
        1179273600000,
        0.7368
      ],
      [
        1179360000000,
        0.74
      ],
      [
        1179446400000,
        0.7421
      ],
      [
        1179705600000,
        0.7439
      ],
      [
        1179792000000,
        0.7434
      ],
      [
        1179878400000,
        0.7414
      ],
      [
        1179964800000,
        0.7437
      ],
      [
        1180051200000,
        0.7441
      ],
      [
        1180310400000,
        0.7434
      ],
      [
        1180396800000,
        0.7403
      ],
      [
        1180483200000,
        0.7453
      ],
      [
        1180569600000,
        0.7434
      ],
      [
        1180656000000,
        0.7444
      ],
      [
        1180915200000,
        0.7418
      ],
      [
        1181001600000,
        0.7391
      ],
      [
        1181088000000,
        0.7401
      ],
      [
        1181174400000,
        0.7425
      ],
      [
        1181260800000,
        0.7492
      ],
      [
        1181520000000,
        0.7489
      ],
      [
        1181606400000,
        0.7494
      ],
      [
        1181692800000,
        0.7527
      ],
      [
        1181779200000,
        0.7518
      ],
      [
        1181865600000,
        0.7512
      ],
      [
        1182124800000,
        0.7461
      ],
      [
        1182211200000,
        0.7462
      ],
      [
        1182297600000,
        0.7449
      ],
      [
        1182384000000,
        0.7465
      ],
      [
        1182470400000,
        0.7441
      ],
      [
        1182729600000,
        0.743
      ],
      [
        1182816000000,
        0.743
      ],
      [
        1182902400000,
        0.7443
      ],
      [
        1182988800000,
        0.7427
      ],
      [
        1183075200000,
        0.7406
      ],
      [
        1183334400000,
        0.736
      ],
      [
        1183420800000,
        0.7353
      ],
      [
        1183507200000,
        0.7344
      ],
      [
        1183593600000,
        0.7332
      ],
      [
        1183680000000,
        0.7356
      ],
      [
        1183939200000,
        0.7343
      ],
      [
        1184025600000,
        0.7318
      ],
      [
        1184112000000,
        0.7272
      ],
      [
        1184198400000,
        0.7254
      ],
      [
        1184284800000,
        0.7257
      ],
      [
        1184544000000,
        0.7257
      ],
      [
        1184630400000,
        0.7263
      ],
      [
        1184716800000,
        0.7258
      ],
      [
        1184803200000,
        0.7237
      ],
      [
        1184889600000,
        0.7246
      ],
      [
        1185148800000,
        0.7236
      ],
      [
        1185235200000,
        0.723
      ],
      [
        1185321600000,
        0.7277
      ],
      [
        1185408000000,
        0.7289
      ],
      [
        1185494400000,
        0.7326
      ],
      [
        1185753600000,
        0.7322
      ],
      [
        1185840000000,
        0.7297
      ],
      [
        1185926400000,
        0.732
      ],
      [
        1186012800000,
        0.732
      ],
      [
        1186099200000,
        0.7303
      ],
      [
        1186358400000,
        0.7238
      ],
      [
        1186444800000,
        0.7251
      ],
      [
        1186531200000,
        0.7251
      ],
      [
        1186617600000,
        0.7285
      ],
      [
        1186704000000,
        0.7327
      ],
      [
        1186963200000,
        0.7326
      ],
      [
        1187049600000,
        0.7359
      ],
      [
        1187136000000,
        0.7422
      ],
      [
        1187222400000,
        0.7461
      ],
      [
        1187308800000,
        0.7434
      ],
      [
        1187568000000,
        0.7422
      ],
      [
        1187654400000,
        0.7404
      ],
      [
        1187740800000,
        0.7412
      ],
      [
        1187827200000,
        0.7368
      ],
      [
        1187913600000,
        0.7346
      ],
      [
        1188172800000,
        0.7323
      ],
      [
        1188259200000,
        0.732
      ],
      [
        1188345600000,
        0.7337
      ],
      [
        1188432000000,
        0.7349
      ],
      [
        1188518400000,
        0.7298
      ],
      [
        1188777600000,
        0.7337
      ],
      [
        1188864000000,
        0.7365
      ],
      [
        1188950400000,
        0.736
      ],
      [
        1189036800000,
        0.7317
      ],
      [
        1189123200000,
        0.7302
      ],
      [
        1189382400000,
        0.725
      ],
      [
        1189468800000,
        0.7235
      ],
      [
        1189555200000,
        0.7203
      ],
      [
        1189641600000,
        0.7197
      ],
      [
        1189728000000,
        0.7216
      ],
      [
        1189987200000,
        0.7207
      ],
      [
        1190073600000,
        0.7212
      ],
      [
        1190160000000,
        0.7157
      ],
      [
        1190246400000,
        0.7129
      ],
      [
        1190332800000,
        0.7119
      ],
      [
        1190592000000,
        0.7087
      ],
      [
        1190678400000,
        0.709
      ],
      [
        1190764800000,
        0.708
      ],
      [
        1190851200000,
        0.7053
      ],
      [
        1190937600000,
        0.7054
      ],
      [
        1191196800000,
        0.7027
      ],
      [
        1191283200000,
        0.7061
      ],
      [
        1191369600000,
        0.7046
      ],
      [
        1191456000000,
        0.7089
      ],
      [
        1191542400000,
        0.7075
      ],
      [
        1191801600000,
        0.7099
      ],
      [
        1191888000000,
        0.7125
      ],
      [
        1191974400000,
        0.707
      ],
      [
        1192060800000,
        0.7044
      ],
      [
        1192147200000,
        0.7057
      ],
      [
        1192406400000,
        0.703
      ],
      [
        1192492800000,
        0.7068
      ],
      [
        1192579200000,
        0.7043
      ],
      [
        1192665600000,
        0.6994
      ],
      [
        1192752000000,
        0.7
      ],
      [
        1193011200000,
        0.706
      ],
      [
        1193097600000,
        0.7017
      ],
      [
        1193184000000,
        0.7028
      ],
      [
        1193270400000,
        0.699
      ],
      [
        1193356800000,
        0.6953
      ],
      [
        1193616000000,
        0.695
      ],
      [
        1193702400000,
        0.6942
      ],
      [
        1193788800000,
        0.6923
      ],
      [
        1193875200000,
        0.6934
      ],
      [
        1193961600000,
        0.6908
      ],
      [
        1194220800000,
        0.6903
      ],
      [
        1194307200000,
        0.6875
      ],
      [
        1194393600000,
        0.6794
      ],
      [
        1194480000000,
        0.6819
      ],
      [
        1194566400000,
        0.6812
      ],
      [
        1194825600000,
        0.686
      ],
      [
        1194912000000,
        0.6847
      ],
      [
        1194998400000,
        0.6804
      ],
      [
        1195084800000,
        0.6832
      ],
      [
        1195171200000,
        0.6826
      ],
      [
        1195430400000,
        0.6825
      ],
      [
        1195516800000,
        0.6765
      ],
      [
        1195603200000,
        0.6751
      ],
      [
        1195689600000,
        0.6745
      ],
      [
        1195776000000,
        0.6754
      ],
      [
        1196035200000,
        0.6737
      ],
      [
        1196121600000,
        0.6724
      ],
      [
        1196208000000,
        0.6782
      ],
      [
        1196294400000,
        0.6786
      ],
      [
        1196380800000,
        0.6776
      ],
      [
        1196640000000,
        0.6819
      ],
      [
        1196726400000,
        0.6785
      ],
      [
        1196812800000,
        0.6794
      ],
      [
        1196899200000,
        0.6872
      ],
      [
        1196985600000,
        0.6827
      ],
      [
        1197244800000,
        0.6795
      ],
      [
        1197331200000,
        0.6817
      ],
      [
        1197417600000,
        0.6815
      ],
      [
        1197504000000,
        0.6812
      ],
      [
        1197590400000,
        0.6893
      ],
      [
        1197849600000,
        0.6949
      ],
      [
        1197936000000,
        0.6938
      ],
      [
        1198022400000,
        0.6953
      ],
      [
        1198108800000,
        0.697
      ],
      [
        1198195200000,
        0.6955
      ],
      [
        1198454400000,
        0.6946
      ],
      [
        1198540800000,
        0.6946
      ],
      [
        1198627200000,
        0.6946
      ],
      [
        1198713600000,
        0.689
      ],
      [
        1198800000000,
        0.6807
      ],
      [
        1199059200000,
        0.6794
      ],
      [
        1199145600000,
        0.6794
      ],
      [
        1199232000000,
        0.6809
      ],
      [
        1199318400000,
        0.6779
      ],
      [
        1199404800000,
        0.6791
      ],
      [
        1199664000000,
        0.6793
      ],
      [
        1199750400000,
        0.6801
      ],
      [
        1199836800000,
        0.6813
      ],
      [
        1199923200000,
        0.6821
      ],
      [
        1200009600000,
        0.6761
      ],
      [
        1200268800000,
        0.6715
      ],
      [
        1200355200000,
        0.6719
      ],
      [
        1200441600000,
        0.6761
      ],
      [
        1200528000000,
        0.6808
      ],
      [
        1200614400000,
        0.6816
      ],
      [
        1200873600000,
        0.6906
      ],
      [
        1200960000000,
        0.69
      ],
      [
        1201046400000,
        0.6863
      ],
      [
        1201132800000,
        0.6821
      ],
      [
        1201219200000,
        0.6801
      ],
      [
        1201478400000,
        0.6778
      ],
      [
        1201564800000,
        0.677
      ],
      [
        1201651200000,
        0.6753
      ],
      [
        1201737600000,
        0.6726
      ],
      [
        1201824000000,
        0.6717
      ],
      [
        1202083200000,
        0.6745
      ],
      [
        1202169600000,
        0.6809
      ],
      [
        1202256000000,
        0.684
      ],
      [
        1202342400000,
        0.6865
      ],
      [
        1202428800000,
        0.6891
      ],
      [
        1202688000000,
        0.6878
      ],
      [
        1202774400000,
        0.688
      ],
      [
        1202860800000,
        0.6857
      ],
      [
        1202947200000,
        0.6838
      ],
      [
        1203033600000,
        0.6816
      ],
      [
        1203292800000,
        0.6833
      ],
      [
        1203379200000,
        0.6784
      ],
      [
        1203465600000,
        0.6824
      ],
      [
        1203552000000,
        0.6787
      ],
      [
        1203638400000,
        0.6736
      ],
      [
        1203897600000,
        0.675
      ],
      [
        1203984000000,
        0.6724
      ],
      [
        1204070400000,
        0.6648
      ],
      [
        1204156800000,
        0.6614
      ],
      [
        1204243200000,
        0.6594
      ],
      [
        1204502400000,
        0.6579
      ],
      [
        1204588800000,
        0.6577
      ],
      [
        1204675200000,
        0.6582
      ],
      [
        1204761600000,
        0.6529
      ],
      [
        1204848000000,
        0.6487
      ],
      [
        1205107200000,
        0.652
      ],
      [
        1205193600000,
        0.6503
      ],
      [
        1205280000000,
        0.6462
      ],
      [
        1205366400000,
        0.6421
      ],
      [
        1205452800000,
        0.6427
      ],
      [
        1205712000000,
        0.6342
      ],
      [
        1205798400000,
        0.6342
      ],
      [
        1205884800000,
        0.6374
      ],
      [
        1205971200000,
        0.6485
      ],
      [
        1206057600000,
        0.6485
      ],
      [
        1206316800000,
        0.6485
      ],
      [
        1206403200000,
        0.6424
      ],
      [
        1206489600000,
        0.6366
      ],
      [
        1206576000000,
        0.6336
      ],
      [
        1206662400000,
        0.6332
      ],
      [
        1206921600000,
        0.6325
      ],
      [
        1207008000000,
        0.6387
      ],
      [
        1207094400000,
        0.6398
      ],
      [
        1207180800000,
        0.6442
      ],
      [
        1207267200000,
        0.6362
      ],
      [
        1207526400000,
        0.6373
      ],
      [
        1207612800000,
        0.6373
      ],
      [
        1207699200000,
        0.636
      ],
      [
        1207785600000,
        0.63
      ],
      [
        1207872000000,
        0.6317
      ],
      [
        1208131200000,
        0.6303
      ],
      [
        1208217600000,
        0.6319
      ],
      [
        1208304000000,
        0.6279
      ],
      [
        1208390400000,
        0.6301
      ],
      [
        1208476800000,
        0.6338
      ],
      [
        1208736000000,
        0.6291
      ],
      [
        1208822400000,
        0.6278
      ],
      [
        1208908800000,
        0.6275
      ],
      [
        1208995200000,
        0.6343
      ],
      [
        1209081600000,
        0.6413
      ],
      [
        1209340800000,
        0.64
      ],
      [
        1209427200000,
        0.6423
      ],
      [
        1209513600000,
        0.6436
      ],
      [
        1209600000000,
        0.6436
      ],
      [
        1209686400000,
        0.647
      ],
      [
        1209945600000,
        0.6469
      ],
      [
        1210032000000,
        0.6441
      ],
      [
        1210118400000,
        0.6482
      ],
      [
        1210204800000,
        0.6517
      ],
      [
        1210291200000,
        0.647
      ],
      [
        1210550400000,
        0.6482
      ],
      [
        1210636800000,
        0.6464
      ],
      [
        1210723200000,
        0.6478
      ],
      [
        1210809600000,
        0.6463
      ],
      [
        1210896000000,
        0.6453
      ],
      [
        1211155200000,
        0.6421
      ],
      [
        1211241600000,
        0.6395
      ],
      [
        1211328000000,
        0.6349
      ],
      [
        1211414400000,
        0.6348
      ],
      [
        1211500800000,
        0.6353
      ],
      [
        1211760000000,
        0.6346
      ],
      [
        1211846400000,
        0.6346
      ],
      [
        1211932800000,
        0.6388
      ],
      [
        1212019200000,
        0.6431
      ],
      [
        1212105600000,
        0.6449
      ],
      [
        1212364800000,
        0.6444
      ],
      [
        1212451200000,
        0.6414
      ],
      [
        1212537600000,
        0.6467
      ],
      [
        1212624000000,
        0.6494
      ],
      [
        1212710400000,
        0.6412
      ],
      [
        1212969600000,
        0.6337
      ],
      [
        1213056000000,
        0.6442
      ],
      [
        1213142400000,
        0.6446
      ],
      [
        1213228800000,
        0.6487
      ],
      [
        1213315200000,
        0.6522
      ],
      [
        1213574400000,
        0.647
      ],
      [
        1213660800000,
        0.6462
      ],
      [
        1213747200000,
        0.6456
      ],
      [
        1213833600000,
        0.6461
      ],
      [
        1213920000000,
        0.6407
      ],
      [
        1214179200000,
        0.6444
      ],
      [
        1214265600000,
        0.6424
      ],
      [
        1214352000000,
        0.6412
      ],
      [
        1214438400000,
        0.6358
      ],
      [
        1214524800000,
        0.6351
      ],
      [
        1214784000000,
        0.6345
      ],
      [
        1214870400000,
        0.634
      ],
      [
        1214956800000,
        0.6328
      ],
      [
        1215043200000,
        0.6296
      ],
      [
        1215129600000,
        0.6382
      ],
      [
        1215388800000,
        0.639
      ],
      [
        1215475200000,
        0.6376
      ],
      [
        1215561600000,
        0.6364
      ],
      [
        1215648000000,
        0.6367
      ],
      [
        1215734400000,
        0.6316
      ],
      [
        1215993600000,
        0.6311
      ],
      [
        1216080000000,
        0.6255
      ],
      [
        1216166400000,
        0.6295
      ],
      [
        1216252800000,
        0.6311
      ],
      [
        1216339200000,
        0.6324
      ],
      [
        1216598400000,
        0.6307
      ],
      [
        1216684800000,
        0.6283
      ],
      [
        1216771200000,
        0.6354
      ],
      [
        1216857600000,
        0.638
      ],
      [
        1216944000000,
        0.6357
      ],
      [
        1217203200000,
        0.6352
      ],
      [
        1217289600000,
        0.6368
      ],
      [
        1217376000000,
        0.6416
      ],
      [
        1217462400000,
        0.6407
      ],
      [
        1217548800000,
        0.6422
      ],
      [
        1217808000000,
        0.6425
      ],
      [
        1217894400000,
        0.6458
      ],
      [
        1217980800000,
        0.6462
      ],
      [
        1218067200000,
        0.6465
      ],
      [
        1218153600000,
        0.6635
      ],
      [
        1218412800000,
        0.6662
      ],
      [
        1218499200000,
        0.6709
      ],
      [
        1218585600000,
        0.6711
      ],
      [
        1218672000000,
        0.6709
      ],
      [
        1218758400000,
        0.679
      ],
      [
        1219017600000,
        0.6802
      ],
      [
        1219104000000,
        0.6814
      ],
      [
        1219190400000,
        0.6788
      ],
      [
        1219276800000,
        0.6751
      ],
      [
        1219363200000,
        0.6754
      ],
      [
        1219622400000,
        0.6773
      ],
      [
        1219708800000,
        0.6851
      ],
      [
        1219795200000,
        0.6773
      ],
      [
        1219881600000,
        0.6771
      ],
      [
        1219968000000,
        0.6788
      ],
      [
        1220227200000,
        0.684
      ],
      [
        1220313600000,
        0.689
      ],
      [
        1220400000000,
        0.6926
      ],
      [
        1220486400000,
        0.6903
      ],
      [
        1220572800000,
        0.702
      ],
      [
        1220832000000,
        0.7036
      ],
      [
        1220918400000,
        0.7071
      ],
      [
        1221004800000,
        0.7096
      ],
      [
        1221091200000,
        0.7178
      ],
      [
        1221177600000,
        0.711
      ],
      [
        1221436800000,
        0.7068
      ],
      [
        1221523200000,
        0.701
      ],
      [
        1221609600000,
        0.7031
      ],
      [
        1221696000000,
        0.6897
      ],
      [
        1221782400000,
        0.7025
      ],
      [
        1222041600000,
        0.6864
      ],
      [
        1222128000000,
        0.6789
      ],
      [
        1222214400000,
        0.6808
      ],
      [
        1222300800000,
        0.6804
      ],
      [
        1222387200000,
        0.6832
      ],
      [
        1222646400000,
        0.697
      ],
      [
        1222732800000,
        0.6993
      ],
      [
        1222819200000,
        0.7103
      ],
      [
        1222905600000,
        0.7194
      ],
      [
        1222992000000,
        0.723
      ],
      [
        1223251200000,
        0.7336
      ],
      [
        1223337600000,
        0.7337
      ],
      [
        1223424000000,
        0.7284
      ],
      [
        1223510400000,
        0.731
      ],
      [
        1223596800000,
        0.7365
      ],
      [
        1223856000000,
        0.7333
      ],
      [
        1223942400000,
        0.7273
      ],
      [
        1224028800000,
        0.734
      ],
      [
        1224115200000,
        0.7405
      ],
      [
        1224201600000,
        0.7461
      ],
      [
        1224460800000,
        0.745
      ],
      [
        1224547200000,
        0.7586
      ],
      [
        1224633600000,
        0.7787
      ],
      [
        1224720000000,
        0.7807
      ],
      [
        1224806400000,
        0.794
      ],
      [
        1225065600000,
        0.8027
      ],
      [
        1225152000000,
        0.7984
      ],
      [
        1225238400000,
        0.7832
      ],
      [
        1225324800000,
        0.7673
      ],
      [
        1225411200000,
        0.784
      ],
      [
        1225670400000,
        0.78
      ],
      [
        1225756800000,
        0.7801
      ],
      [
        1225843200000,
        0.7771
      ],
      [
        1225929600000,
        0.7832
      ],
      [
        1226016000000,
        0.784
      ],
      [
        1226275200000,
        0.7758
      ],
      [
        1226361600000,
        0.7846
      ],
      [
        1226448000000,
        0.7982
      ],
      [
        1226534400000,
        0.7985
      ],
      [
        1226620800000,
        0.7891
      ],
      [
        1226880000000,
        0.79
      ],
      [
        1226966400000,
        0.7904
      ],
      [
        1227052800000,
        0.7916
      ],
      [
        1227139200000,
        0.7974
      ],
      [
        1227225600000,
        0.7936
      ],
      [
        1227484800000,
        0.783
      ],
      [
        1227571200000,
        0.7807
      ],
      [
        1227657600000,
        0.7732
      ],
      [
        1227744000000,
        0.7753
      ],
      [
        1227830400000,
        0.7858
      ],
      [
        1228089600000,
        0.7932
      ],
      [
        1228176000000,
        0.7877
      ],
      [
        1228262400000,
        0.7923
      ],
      [
        1228348800000,
        0.7925
      ],
      [
        1228435200000,
        0.7897
      ],
      [
        1228694400000,
        0.7781
      ],
      [
        1228780800000,
        0.779
      ],
      [
        1228867200000,
        0.7738
      ],
      [
        1228953600000,
        0.7568
      ],
      [
        1229040000000,
        0.7497
      ],
      [
        1229299200000,
        0.7403
      ],
      [
        1229385600000,
        0.7306
      ],
      [
        1229472000000,
        0.7114
      ],
      [
        1229558400000,
        0.6843
      ],
      [
        1229644800000,
        0.7175
      ],
      [
        1229904000000,
        0.7159
      ],
      [
        1229990400000,
        0.7155
      ],
      [
        1230076800000,
        0.7141
      ],
      [
        1230163200000,
        0.7141
      ],
      [
        1230249600000,
        0.7141
      ],
      [
        1230508800000,
        0.7009
      ],
      [
        1230595200000,
        0.7094
      ],
      [
        1230681600000,
        0.7186
      ],
      [
        1230768000000,
        0.7186
      ],
      [
        1230854400000,
        0.7213
      ],
      [
        1231113600000,
        0.7364
      ],
      [
        1231200000000,
        0.7502
      ],
      [
        1231286400000,
        0.7357
      ],
      [
        1231372800000,
        0.7345
      ],
      [
        1231459200000,
        0.7309
      ],
      [
        1231718400000,
        0.7467
      ],
      [
        1231804800000,
        0.7541
      ],
      [
        1231891200000,
        0.7592
      ],
      [
        1231977600000,
        0.7643
      ],
      [
        1232064000000,
        0.7537
      ],
      [
        1232323200000,
        0.7587
      ],
      [
        1232409600000,
        0.7735
      ],
      [
        1232496000000,
        0.7747
      ],
      [
        1232582400000,
        0.7703
      ],
      [
        1232668800000,
        0.7817
      ],
      [
        1232928000000,
        0.7699
      ],
      [
        1233014400000,
        0.7592
      ],
      [
        1233100800000,
        0.7542
      ],
      [
        1233187200000,
        0.7628
      ],
      [
        1233273600000,
        0.7804
      ],
      [
        1233532800000,
        0.7838
      ],
      [
        1233619200000,
        0.7784
      ],
      [
        1233705600000,
        0.7803
      ],
      [
        1233792000000,
        0.7796
      ],
      [
        1233878400000,
        0.7816
      ],
      [
        1234137600000,
        0.7689
      ],
      [
        1234224000000,
        0.7713
      ],
      [
        1234310400000,
        0.773
      ],
      [
        1234396800000,
        0.7793
      ],
      [
        1234483200000,
        0.7799
      ],
      [
        1234742400000,
        0.7835
      ],
      [
        1234828800000,
        0.7916
      ],
      [
        1234915200000,
        0.794
      ],
      [
        1235001600000,
        0.7872
      ],
      [
        1235088000000,
        0.7943
      ],
      [
        1235347200000,
        0.7815
      ],
      [
        1235433600000,
        0.7836
      ],
      [
        1235520000000,
        0.7817
      ],
      [
        1235606400000,
        0.7825
      ],
      [
        1235692800000,
        0.791
      ],
      [
        1235952000000,
        0.794
      ],
      [
        1236038400000,
        0.7928
      ],
      [
        1236124800000,
        0.7966
      ],
      [
        1236211200000,
        0.7966
      ],
      [
        1236297600000,
        0.7901
      ],
      [
        1236556800000,
        0.796
      ],
      [
        1236643200000,
        0.7824
      ],
      [
        1236729600000,
        0.7822
      ],
      [
        1236816000000,
        0.7825
      ],
      [
        1236902400000,
        0.775
      ],
      [
        1237161600000,
        0.7669
      ],
      [
        1237248000000,
        0.7728
      ],
      [
        1237334400000,
        0.7617
      ],
      [
        1237420800000,
        0.7316
      ],
      [
        1237507200000,
        0.7382
      ],
      [
        1237766400000,
        0.7377
      ],
      [
        1237852800000,
        0.7405
      ],
      [
        1237939200000,
        0.7412
      ],
      [
        1238025600000,
        0.735
      ],
      [
        1238112000000,
        0.7523
      ],
      [
        1238371200000,
        0.7581
      ],
      [
        1238457600000,
        0.7515
      ],
      [
        1238544000000,
        0.755
      ],
      [
        1238630400000,
        0.7468
      ],
      [
        1238716800000,
        0.745
      ],
      [
        1238976000000,
        0.7411
      ],
      [
        1239062400000,
        0.7545
      ],
      [
        1239148800000,
        0.7559
      ],
      [
        1239235200000,
        0.7535
      ],
      [
        1239321600000,
        0.7535
      ],
      [
        1239580800000,
        0.7535
      ],
      [
        1239667200000,
        0.7533
      ],
      [
        1239753600000,
        0.7592
      ],
      [
        1239840000000,
        0.7579
      ],
      [
        1239926400000,
        0.7659
      ],
      [
        1240185600000,
        0.7713
      ],
      [
        1240272000000,
        0.7734
      ],
      [
        1240358400000,
        0.7725
      ],
      [
        1240444800000,
        0.7664
      ],
      [
        1240531200000,
        0.7558
      ],
      [
        1240790400000,
        0.762
      ],
      [
        1240876800000,
        0.7698
      ],
      [
        1240963200000,
        0.7539
      ],
      [
        1241049600000,
        0.7534
      ],
      [
        1241136000000,
        0.7534
      ],
      [
        1241395200000,
        0.7564
      ],
      [
        1241481600000,
        0.7462
      ],
      [
        1241568000000,
        0.7507
      ],
      [
        1241654400000,
        0.7484
      ],
      [
        1241740800000,
        0.745
      ],
      [
        1242000000000,
        0.7368
      ],
      [
        1242086400000,
        0.7309
      ],
      [
        1242172800000,
        0.7342
      ],
      [
        1242259200000,
        0.7374
      ],
      [
        1242345600000,
        0.7399
      ],
      [
        1242604800000,
        0.7412
      ],
      [
        1242691200000,
        0.7347
      ],
      [
        1242777600000,
        0.7306
      ],
      [
        1242864000000,
        0.7263
      ],
      [
        1242950400000,
        0.7158
      ],
      [
        1243209600000,
        0.7139
      ],
      [
        1243296000000,
        0.7191
      ],
      [
        1243382400000,
        0.7195
      ],
      [
        1243468800000,
        0.7218
      ],
      [
        1243555200000,
        0.7094
      ],
      [
        1243814400000,
        0.7033
      ],
      [
        1243900800000,
        0.7024
      ],
      [
        1243987200000,
        0.704
      ],
      [
        1244073600000,
        0.7096
      ],
      [
        1244160000000,
        0.7055
      ],
      [
        1244419200000,
        0.7213
      ],
      [
        1244505600000,
        0.7165
      ],
      [
        1244592000000,
        0.7092
      ],
      [
        1244678400000,
        0.716
      ],
      [
        1244764800000,
        0.7142
      ],
      [
        1245024000000,
        0.7221
      ],
      [
        1245110400000,
        0.72
      ],
      [
        1245196800000,
        0.7226
      ],
      [
        1245283200000,
        0.7185
      ],
      [
        1245369600000,
        0.7179
      ],
      [
        1245628800000,
        0.7217
      ],
      [
        1245715200000,
        0.7155
      ],
      [
        1245801600000,
        0.7129
      ],
      [
        1245888000000,
        0.7175
      ],
      [
        1245974400000,
        0.7095
      ],
      [
        1246233600000,
        0.7114
      ],
      [
        1246320000000,
        0.7076
      ],
      [
        1246406400000,
        0.7095
      ],
      [
        1246492800000,
        0.7119
      ],
      [
        1246579200000,
        0.7139
      ],
      [
        1246838400000,
        0.7197
      ],
      [
        1246924800000,
        0.7134
      ],
      [
        1247011200000,
        0.7195
      ],
      [
        1247097600000,
        0.7149
      ],
      [
        1247184000000,
        0.7195
      ],
      [
        1247443200000,
        0.7157
      ],
      [
        1247529600000,
        0.7148
      ],
      [
        1247616000000,
        0.7099
      ],
      [
        1247702400000,
        0.7078
      ],
      [
        1247788800000,
        0.7098
      ],
      [
        1248048000000,
        0.7035
      ],
      [
        1248134400000,
        0.7032
      ],
      [
        1248220800000,
        0.7048
      ],
      [
        1248307200000,
        0.7029
      ],
      [
        1248393600000,
        0.703
      ],
      [
        1248652800000,
        0.7009
      ],
      [
        1248739200000,
        0.7029
      ],
      [
        1248825600000,
        0.7091
      ],
      [
        1248912000000,
        0.7117
      ],
      [
        1248998400000,
        0.7074
      ],
      [
        1249257600000,
        0.6993
      ],
      [
        1249344000000,
        0.6953
      ],
      [
        1249430400000,
        0.6941
      ],
      [
        1249516800000,
        0.696
      ],
      [
        1249603200000,
        0.6966
      ],
      [
        1249862400000,
        0.7042
      ],
      [
        1249948800000,
        0.706
      ],
      [
        1250035200000,
        0.7058
      ],
      [
        1250121600000,
        0.6997
      ],
      [
        1250208000000,
        0.6997
      ],
      [
        1250467200000,
        0.7107
      ],
      [
        1250553600000,
        0.7093
      ],
      [
        1250640000000,
        0.7087
      ],
      [
        1250726400000,
        0.7022
      ],
      [
        1250812800000,
        0.6979
      ],
      [
        1251072000000,
        0.6983
      ],
      [
        1251158400000,
        0.6982
      ],
      [
        1251244800000,
        0.7009
      ],
      [
        1251331200000,
        0.701
      ],
      [
        1251417600000,
        0.6963
      ],
      [
        1251676800000,
        0.7008
      ],
      [
        1251763200000,
        0.6987
      ],
      [
        1251849600000,
        0.7033
      ],
      [
        1251936000000,
        0.6977
      ],
      [
        1252022400000,
        0.7013
      ],
      [
        1252281600000,
        0.6979
      ],
      [
        1252368000000,
        0.691
      ],
      [
        1252454400000,
        0.6887
      ],
      [
        1252540800000,
        0.6876
      ],
      [
        1252627200000,
        0.6853
      ],
      [
        1252886400000,
        0.6869
      ],
      [
        1252972800000,
        0.6845
      ],
      [
        1253059200000,
        0.6817
      ],
      [
        1253145600000,
        0.6798
      ],
      [
        1253232000000,
        0.6801
      ],
      [
        1253491200000,
        0.6823
      ],
      [
        1253577600000,
        0.6767
      ],
      [
        1253664000000,
        0.6766
      ],
      [
        1253750400000,
        0.6772
      ],
      [
        1253836800000,
        0.6818
      ],
      [
        1254096000000,
        0.6827
      ],
      [
        1254182400000,
        0.6874
      ],
      [
        1254268800000,
        0.683
      ],
      [
        1254355200000,
        0.6879
      ],
      [
        1254441600000,
        0.688
      ],
      [
        1254700800000,
        0.6843
      ],
      [
        1254787200000,
        0.6794
      ],
      [
        1254873600000,
        0.6806
      ],
      [
        1254960000000,
        0.6775
      ],
      [
        1255046400000,
        0.6781
      ],
      [
        1255305600000,
        0.6774
      ],
      [
        1255392000000,
        0.6729
      ],
      [
        1255478400000,
        0.6721
      ],
      [
        1255564800000,
        0.6729
      ],
      [
        1255651200000,
        0.6726
      ],
      [
        1255910400000,
        0.6704
      ],
      [
        1255996800000,
        0.6681
      ],
      [
        1256083200000,
        0.6703
      ],
      [
        1256169600000,
        0.6668
      ],
      [
        1256256000000,
        0.6659
      ],
      [
        1256515200000,
        0.6659
      ],
      [
        1256601600000,
        0.6724
      ],
      [
        1256688000000,
        0.6765
      ],
      [
        1256774400000,
        0.6763
      ],
      [
        1256860800000,
        0.6758
      ],
      [
        1257120000000,
        0.6771
      ],
      [
        1257206400000,
        0.6823
      ],
      [
        1257292800000,
        0.6776
      ],
      [
        1257379200000,
        0.6727
      ],
      [
        1257465600000,
        0.673
      ],
      [
        1257724800000,
        0.6675
      ],
      [
        1257811200000,
        0.6683
      ],
      [
        1257897600000,
        0.6651
      ],
      [
        1257984000000,
        0.6703
      ],
      [
        1258070400000,
        0.6727
      ],
      [
        1258329600000,
        0.6683
      ],
      [
        1258416000000,
        0.6724
      ],
      [
        1258502400000,
        0.6687
      ],
      [
        1258588800000,
        0.6729
      ],
      [
        1258675200000,
        0.6751
      ],
      [
        1258934400000,
        0.6682
      ],
      [
        1259020800000,
        0.6681
      ],
      [
        1259107200000,
        0.6631
      ],
      [
        1259193600000,
        0.6636
      ],
      [
        1259280000000,
        0.6704
      ],
      [
        1259539200000,
        0.6657
      ],
      [
        1259625600000,
        0.6635
      ],
      [
        1259712000000,
        0.6628
      ],
      [
        1259798400000,
        0.6615
      ],
      [
        1259884800000,
        0.6638
      ],
      [
        1260144000000,
        0.6764
      ],
      [
        1260230400000,
        0.677
      ],
      [
        1260316800000,
        0.6772
      ],
      [
        1260403200000,
        0.679
      ],
      [
        1260489600000,
        0.6777
      ],
      [
        1260748800000,
        0.6828
      ],
      [
        1260835200000,
        0.6878
      ],
      [
        1260921600000,
        0.6869
      ],
      [
        1261008000000,
        0.6973
      ],
      [
        1261094400000,
        0.6976
      ],
      [
        1261353600000,
        0.6961
      ],
      [
        1261440000000,
        0.7004
      ],
      [
        1261526400000,
        0.7006
      ],
      [
        1261612800000,
        0.6946
      ],
      [
        1261699200000,
        0.6946
      ],
      [
        1261958400000,
        0.6943
      ],
      [
        1262044800000,
        0.693
      ],
      [
        1262131200000,
        0.6975
      ],
      [
        1262217600000,
        0.6943
      ],
      [
        1262304000000,
        0.6943
      ],
      [
        1262563200000,
        0.6951
      ],
      [
        1262649600000,
        0.6925
      ],
      [
        1262736000000,
        0.697
      ],
      [
        1262822400000,
        0.6992
      ],
      [
        1262908800000,
        0.7007
      ],
      [
        1263168000000,
        0.6884
      ],
      [
        1263254400000,
        0.6907
      ],
      [
        1263340800000,
        0.6868
      ],
      [
        1263427200000,
        0.6904
      ],
      [
        1263513600000,
        0.6958
      ],
      [
        1263772800000,
        0.696
      ],
      [
        1263859200000,
        0.7004
      ],
      [
        1263945600000,
        0.7077
      ],
      [
        1264032000000,
        0.7111
      ],
      [
        1264118400000,
        0.7076
      ],
      [
        1264377600000,
        0.7068
      ],
      [
        1264464000000,
        0.7101
      ],
      [
        1264550400000,
        0.7107
      ],
      [
        1264636800000,
        0.7144
      ],
      [
        1264723200000,
        0.7161
      ],
      [
        1264982400000,
        0.7189
      ],
      [
        1265068800000,
        0.7176
      ],
      [
        1265155200000,
        0.7152
      ],
      [
        1265241600000,
        0.7223
      ],
      [
        1265328000000,
        0.7305
      ],
      [
        1265587200000,
        0.7314
      ],
      [
        1265673600000,
        0.7268
      ],
      [
        1265760000000,
        0.7279
      ],
      [
        1265846400000,
        0.7291
      ],
      [
        1265932800000,
        0.7369
      ],
      [
        1266192000000,
        0.735
      ],
      [
        1266278400000,
        0.7328
      ],
      [
        1266364800000,
        0.7286
      ],
      [
        1266451200000,
        0.7372
      ],
      [
        1266537600000,
        0.7398
      ],
      [
        1266796800000,
        0.734
      ],
      [
        1266883200000,
        0.7366
      ],
      [
        1266969600000,
        0.7383
      ],
      [
        1267056000000,
        0.7414
      ],
      [
        1267142400000,
        0.737
      ],
      [
        1267401600000,
        0.7395
      ],
      [
        1267488000000,
        0.7382
      ],
      [
        1267574400000,
        0.7332
      ],
      [
        1267660800000,
        0.7317
      ],
      [
        1267747200000,
        0.7364
      ],
      [
        1268006400000,
        0.7321
      ],
      [
        1268092800000,
        0.7377
      ],
      [
        1268179200000,
        0.7349
      ],
      [
        1268265600000,
        0.7323
      ],
      [
        1268352000000,
        0.7266
      ],
      [
        1268611200000,
        0.7298
      ],
      [
        1268697600000,
        0.7288
      ],
      [
        1268784000000,
        0.7271
      ],
      [
        1268870400000,
        0.7322
      ],
      [
        1268956800000,
        0.7382
      ],
      [
        1269216000000,
        0.7424
      ],
      [
        1269302400000,
        0.7398
      ],
      [
        1269388800000,
        0.7498
      ],
      [
        1269475200000,
        0.7488
      ],
      [
        1269561600000,
        0.749
      ],
      [
        1269820800000,
        0.7424
      ],
      [
        1269907200000,
        0.7418
      ],
      [
        1269993600000,
        0.742
      ],
      [
        1270080000000,
        0.7426
      ],
      [
        1270166400000,
        0.7426
      ],
      [
        1270425600000,
        0.7426
      ],
      [
        1270512000000,
        0.7466
      ],
      [
        1270598400000,
        0.7497
      ],
      [
        1270684800000,
        0.7522
      ],
      [
        1270771200000,
        0.7473
      ],
      [
        1271030400000,
        0.7362
      ],
      [
        1271116800000,
        0.7363
      ],
      [
        1271203200000,
        0.7346
      ],
      [
        1271289600000,
        0.7384
      ],
      [
        1271376000000,
        0.7389
      ],
      [
        1271635200000,
        0.7446
      ],
      [
        1271721600000,
        0.7416
      ],
      [
        1271808000000,
        0.7479
      ],
      [
        1271894400000,
        0.7498
      ],
      [
        1271980800000,
        0.7514
      ],
      [
        1272240000000,
        0.7508
      ],
      [
        1272326400000,
        0.7525
      ],
      [
        1272412800000,
        0.7551
      ],
      [
        1272499200000,
        0.7545
      ],
      [
        1272585600000,
        0.7511
      ],
      [
        1272844800000,
        0.7555
      ],
      [
        1272931200000,
        0.7641
      ],
      [
        1273017600000,
        0.7739
      ],
      [
        1273104000000,
        0.7858
      ],
      [
        1273190400000,
        0.7847
      ],
      [
        1273449600000,
        0.7712
      ],
      [
        1273536000000,
        0.7876
      ],
      [
        1273622400000,
        0.7884
      ],
      [
        1273708800000,
        0.7946
      ],
      [
        1273795200000,
        0.8006
      ],
      [
        1274054400000,
        0.8099
      ],
      [
        1274140800000,
        0.8047
      ],
      [
        1274227200000,
        0.8151
      ],
      [
        1274313600000,
        0.8109
      ],
      [
        1274400000000,
        0.8003
      ],
      [
        1274659200000,
        0.8092
      ],
      [
        1274745600000,
        0.8182
      ],
      [
        1274832000000,
        0.8125
      ],
      [
        1274918400000,
        0.8161
      ],
      [
        1275004800000,
        0.8076
      ],
      [
        1275264000000,
        0.8126
      ],
      [
        1275350400000,
        0.8228
      ],
      [
        1275436800000,
        0.8186
      ],
      [
        1275523200000,
        0.8152
      ],
      [
        1275609600000,
        0.8293
      ],
      [
        1275868800000,
        0.8363
      ],
      [
        1275955200000,
        0.8375
      ],
      [
        1276041600000,
        0.8327
      ],
      [
        1276128000000,
        0.8303
      ],
      [
        1276214400000,
        0.8247
      ],
      [
        1276473600000,
        0.8165
      ],
      [
        1276560000000,
        0.8159
      ],
      [
        1276646400000,
        0.8146
      ],
      [
        1276732800000,
        0.809
      ],
      [
        1276819200000,
        0.8084
      ],
      [
        1277078400000,
        0.8071
      ],
      [
        1277164800000,
        0.8159
      ],
      [
        1277251200000,
        0.815
      ],
      [
        1277337600000,
        0.8156
      ],
      [
        1277424000000,
        0.8135
      ],
      [
        1277683200000,
        0.8105
      ],
      [
        1277769600000,
        0.8199
      ],
      [
        1277856000000,
        0.815
      ],
      [
        1277942400000,
        0.8113
      ],
      [
        1278028800000,
        0.797
      ],
      [
        1278288000000,
        0.7981
      ],
      [
        1278374400000,
        0.7951
      ],
      [
        1278460800000,
        0.7958
      ],
      [
        1278547200000,
        0.79
      ],
      [
        1278633600000,
        0.7914
      ],
      [
        1278892800000,
        0.7955
      ],
      [
        1278979200000,
        0.7957
      ],
      [
        1279065600000,
        0.7873
      ],
      [
        1279152000000,
        0.7796
      ],
      [
        1279238400000,
        0.7693
      ],
      [
        1279497600000,
        0.7719
      ],
      [
        1279584000000,
        0.7787
      ],
      [
        1279670400000,
        0.7803
      ],
      [
        1279756800000,
        0.7783
      ],
      [
        1279843200000,
        0.7755
      ],
      [
        1280102400000,
        0.7734
      ],
      [
        1280188800000,
        0.7674
      ],
      [
        1280275200000,
        0.7698
      ],
      [
        1280361600000,
        0.7653
      ],
      [
        1280448000000,
        0.7677
      ],
      [
        1280707200000,
        0.765
      ],
      [
        1280793600000,
        0.7565
      ],
      [
        1280880000000,
        0.7573
      ],
      [
        1280966400000,
        0.7586
      ],
      [
        1281052800000,
        0.7591
      ],
      [
        1281312000000,
        0.7546
      ],
      [
        1281398400000,
        0.7615
      ],
      [
        1281484800000,
        0.7684
      ],
      [
        1281571200000,
        0.782
      ],
      [
        1281657600000,
        0.7814
      ],
      [
        1281916800000,
        0.7801
      ],
      [
        1282003200000,
        0.7777
      ],
      [
        1282089600000,
        0.7765
      ],
      [
        1282176000000,
        0.7792
      ],
      [
        1282262400000,
        0.7868
      ],
      [
        1282521600000,
        0.7873
      ],
      [
        1282608000000,
        0.7931
      ],
      [
        1282694400000,
        0.7929
      ],
      [
        1282780800000,
        0.7879
      ],
      [
        1282867200000,
        0.7867
      ],
      [
        1283126400000,
        0.7875
      ],
      [
        1283212800000,
        0.7887
      ],
      [
        1283299200000,
        0.7814
      ],
      [
        1283385600000,
        0.7803
      ],
      [
        1283472000000,
        0.7793
      ],
      [
        1283731200000,
        0.7769
      ],
      [
        1283817600000,
        0.7848
      ],
      [
        1283904000000,
        0.7877
      ],
      [
        1283990400000,
        0.7866
      ],
      [
        1284076800000,
        0.786
      ],
      [
        1284336000000,
        0.7813
      ],
      [
        1284422400000,
        0.7783
      ],
      [
        1284508800000,
        0.77
      ],
      [
        1284595200000,
        0.7647
      ],
      [
        1284681600000,
        0.7658
      ],
      [
        1284940800000,
        0.765
      ],
      [
        1285027200000,
        0.7623
      ],
      [
        1285113600000,
        0.7484
      ],
      [
        1285200000000,
        0.7507
      ],
      [
        1285286400000,
        0.7457
      ],
      [
        1285545600000,
        0.7421
      ],
      [
        1285632000000,
        0.743
      ],
      [
        1285718400000,
        0.7348
      ],
      [
        1285804800000,
        0.7328
      ],
      [
        1285891200000,
        0.7286
      ],
      [
        1286150400000,
        0.7298
      ],
      [
        1286236800000,
        0.7258
      ],
      [
        1286323200000,
        0.7218
      ],
      [
        1286409600000,
        0.7159
      ],
      [
        1286496000000,
        0.7209
      ],
      [
        1286755200000,
        0.7177
      ],
      [
        1286841600000,
        0.723
      ],
      [
        1286928000000,
        0.7165
      ],
      [
        1287014400000,
        0.7093
      ],
      [
        1287100800000,
        0.7099
      ],
      [
        1287360000000,
        0.7197
      ],
      [
        1287446400000,
        0.7217
      ],
      [
        1287532800000,
        0.7215
      ],
      [
        1287619200000,
        0.7136
      ],
      [
        1287705600000,
        0.7178
      ],
      [
        1287964800000,
        0.7128
      ],
      [
        1288051200000,
        0.7189
      ],
      [
        1288137600000,
        0.7246
      ],
      [
        1288224000000,
        0.7218
      ],
      [
        1288310400000,
        0.7218
      ],
      [
        1288569600000,
        0.7182
      ],
      [
        1288656000000,
        0.7135
      ],
      [
        1288742400000,
        0.7137
      ],
      [
        1288828800000,
        0.7021
      ],
      [
        1288915200000,
        0.7101
      ],
      [
        1289174400000,
        0.7186
      ],
      [
        1289260800000,
        0.7172
      ],
      [
        1289347200000,
        0.7263
      ],
      [
        1289433600000,
        0.73
      ],
      [
        1289520000000,
        0.7294
      ],
      [
        1289779200000,
        0.734
      ],
      [
        1289865600000,
        0.7347
      ],
      [
        1289952000000,
        0.7419
      ],
      [
        1290038400000,
        0.7329
      ],
      [
        1290124800000,
        0.7314
      ],
      [
        1290384000000,
        0.7329
      ],
      [
        1290470400000,
        0.7411
      ],
      [
        1290556800000,
        0.7498
      ],
      [
        1290643200000,
        0.7508
      ],
      [
        1290729600000,
        0.7562
      ],
      [
        1290988800000,
        0.7608
      ],
      [
        1291075200000,
        0.7694
      ],
      [
        1291161600000,
        0.7626
      ],
      [
        1291248000000,
        0.7603
      ],
      [
        1291334400000,
        0.755
      ],
      [
        1291593600000,
        0.7531
      ],
      [
        1291680000000,
        0.7484
      ],
      [
        1291766400000,
        0.7577
      ],
      [
        1291852800000,
        0.7569
      ],
      [
        1291939200000,
        0.7552
      ],
      [
        1292198400000,
        0.7538
      ],
      [
        1292284800000,
        0.7444
      ],
      [
        1292371200000,
        0.7486
      ],
      [
        1292457600000,
        0.7555
      ],
      [
        1292544000000,
        0.7542
      ],
      [
        1292803200000,
        0.7607
      ],
      [
        1292889600000,
        0.7603
      ],
      [
        1292976000000,
        0.7628
      ],
      [
        1293062400000,
        0.7656
      ],
      [
        1293148800000,
        0.7635
      ],
      [
        1293408000000,
        0.7614
      ],
      [
        1293494400000,
        0.758
      ],
      [
        1293580800000,
        0.7614
      ],
      [
        1293667200000,
        0.7531
      ],
      [
        1293753600000,
        0.7485
      ],
      [
        1294012800000,
        0.7493
      ],
      [
        1294099200000,
        0.7452
      ],
      [
        1294185600000,
        0.7569
      ],
      [
        1294272000000,
        0.764
      ],
      [
        1294358400000,
        0.7716
      ],
      [
        1294617600000,
        0.7751
      ],
      [
        1294704000000,
        0.7724
      ],
      [
        1294790400000,
        0.7709
      ],
      [
        1294876800000,
        0.7577
      ],
      [
        1294963200000,
        0.7492
      ],
      [
        1295222400000,
        0.7514
      ],
      [
        1295308800000,
        0.748
      ],
      [
        1295395200000,
        0.7405
      ],
      [
        1295481600000,
        0.7424
      ],
      [
        1295568000000,
        0.7397
      ],
      [
        1295827200000,
        0.737
      ],
      [
        1295913600000,
        0.7356
      ],
      [
        1296000000000,
        0.731
      ],
      [
        1296086400000,
        0.7292
      ],
      [
        1296172800000,
        0.7295
      ],
      [
        1296432000000,
        0.7305
      ],
      [
        1296518400000,
        0.7271
      ],
      [
        1296604800000,
        0.7246
      ],
      [
        1296691200000,
        0.7276
      ],
      [
        1296777600000,
        0.7337
      ],
      [
        1297036800000,
        0.7379
      ],
      [
        1297123200000,
        0.7335
      ],
      [
        1297209600000,
        0.7329
      ],
      [
        1297296000000,
        0.7352
      ],
      [
        1297382400000,
        0.7395
      ],
      [
        1297641600000,
        0.7441
      ],
      [
        1297728000000,
        0.7403
      ],
      [
        1297814400000,
        0.7403
      ],
      [
        1297900800000,
        0.7376
      ],
      [
        1297987200000,
        0.7339
      ],
      [
        1298246400000,
        0.7317
      ],
      [
        1298332800000,
        0.7318
      ],
      [
        1298419200000,
        0.7284
      ],
      [
        1298505600000,
        0.7262
      ],
      [
        1298592000000,
        0.7267
      ],
      [
        1298851200000,
        0.723
      ],
      [
        1298937600000,
        0.7234
      ],
      [
        1299024000000,
        0.7243
      ],
      [
        1299110400000,
        0.7221
      ],
      [
        1299196800000,
        0.7166
      ],
      [
        1299456000000,
        0.713
      ],
      [
        1299542400000,
        0.7196
      ],
      [
        1299628800000,
        0.7181
      ],
      [
        1299715200000,
        0.7238
      ],
      [
        1299801600000,
        0.7262
      ],
      [
        1300060800000,
        0.717
      ],
      [
        1300147200000,
        0.7204
      ],
      [
        1300233600000,
        0.7169
      ],
      [
        1300320000000,
        0.7142
      ],
      [
        1300406400000,
        0.7078
      ],
      [
        1300665600000,
        0.7046
      ],
      [
        1300752000000,
        0.7038
      ],
      [
        1300838400000,
        0.7075
      ],
      [
        1300924800000,
        0.7079
      ],
      [
        1301011200000,
        0.7086
      ],
      [
        1301270400000,
        0.7128
      ],
      [
        1301356800000,
        0.711
      ],
      [
        1301443200000,
        0.7098
      ],
      [
        1301529600000,
        0.704
      ],
      [
        1301616000000,
        0.7073
      ],
      [
        1301875200000,
        0.7023
      ],
      [
        1301961600000,
        0.706
      ],
      [
        1302048000000,
        0.6994
      ],
      [
        1302134400000,
        0.7002
      ],
      [
        1302220800000,
        0.6945
      ],
      [
        1302480000000,
        0.6929
      ],
      [
        1302566400000,
        0.6912
      ],
      [
        1302652800000,
        0.6901
      ],
      [
        1302739200000,
        0.6945
      ],
      [
        1302825600000,
        0.6921
      ],
      [
        1303084800000,
        0.7006
      ],
      [
        1303171200000,
        0.6993
      ],
      [
        1303257600000,
        0.689
      ],
      [
        1303344000000,
        0.6858
      ],
      [
        1303430400000,
        0.6858
      ],
      [
        1303689600000,
        0.6858
      ],
      [
        1303776000000,
        0.6842
      ],
      [
        1303862400000,
        0.6819
      ],
      [
        1303948800000,
        0.676
      ],
      [
        1304035200000,
        0.673
      ],
      [
        1304294400000,
        0.6741
      ],
      [
        1304380800000,
        0.6767
      ],
      [
        1304467200000,
        0.6721
      ],
      [
        1304553600000,
        0.6751
      ],
      [
        1304640000000,
        0.6897
      ],
      [
        1304899200000,
        0.6947
      ],
      [
        1304985600000,
        0.6966
      ],
      [
        1305072000000,
        0.6966
      ],
      [
        1305158400000,
        0.7067
      ],
      [
        1305244800000,
        0.7004
      ],
      [
        1305504000000,
        0.7072
      ],
      [
        1305590400000,
        0.7058
      ],
      [
        1305676800000,
        0.703
      ],
      [
        1305763200000,
        0.7011
      ],
      [
        1305849600000,
        0.7025
      ],
      [
        1306108800000,
        0.7134
      ],
      [
        1306195200000,
        0.7099
      ],
      [
        1306281600000,
        0.7109
      ],
      [
        1306368000000,
        0.7059
      ],
      [
        1306454400000,
        0.7011
      ],
      [
        1306713600000,
        0.7008
      ],
      [
        1306800000000,
        0.6953
      ],
      [
        1306886400000,
        0.6942
      ],
      [
        1306972800000,
        0.6917
      ],
      [
        1307059200000,
        0.6903
      ],
      [
        1307318400000,
        0.6852
      ],
      [
        1307404800000,
        0.6826
      ],
      [
        1307491200000,
        0.6847
      ],
      [
        1307577600000,
        0.6844
      ],
      [
        1307664000000,
        0.6904
      ],
      [
        1307923200000,
        0.6968
      ],
      [
        1308009600000,
        0.6922
      ],
      [
        1308096000000,
        0.6998
      ],
      [
        1308182400000,
        0.7099
      ],
      [
        1308268800000,
        0.7009
      ],
      [
        1308528000000,
        0.7026
      ],
      [
        1308614400000,
        0.6958
      ],
      [
        1308700800000,
        0.6947
      ],
      [
        1308787200000,
        0.7037
      ],
      [
        1308873600000,
        0.7033
      ],
      [
        1309132800000,
        0.7041
      ],
      [
        1309219200000,
        0.7013
      ],
      [
        1309305600000,
        0.6933
      ],
      [
        1309392000000,
        0.692
      ],
      [
        1309478400000,
        0.6903
      ],
      [
        1309737600000,
        0.6898
      ],
      [
        1309824000000,
        0.6916
      ],
      [
        1309910400000,
        0.6985
      ],
      [
        1309996800000,
        0.702
      ],
      [
        1310083200000,
        0.7022
      ],
      [
        1310342400000,
        0.7115
      ],
      [
        1310428800000,
        0.7157
      ],
      [
        1310515200000,
        0.7107
      ],
      [
        1310601600000,
        0.7042
      ],
      [
        1310688000000,
        0.707
      ],
      [
        1310947200000,
        0.7121
      ],
      [
        1311033600000,
        0.7063
      ],
      [
        1311120000000,
        0.704
      ],
      [
        1311206400000,
        0.7032
      ],
      [
        1311292800000,
        0.695
      ],
      [
        1311552000000,
        0.6955
      ],
      [
        1311638400000,
        0.6911
      ],
      [
        1311724800000,
        0.6923
      ],
      [
        1311811200000,
        0.7014
      ],
      [
        1311897600000,
        0.7014
      ],
      [
        1312156800000,
        0.6938
      ],
      [
        1312243200000,
        0.7058
      ],
      [
        1312329600000,
        0.6994
      ],
      [
        1312416000000,
        0.7029
      ],
      [
        1312502400000,
        0.7066
      ],
      [
        1312761600000,
        0.7031
      ],
      [
        1312848000000,
        0.701
      ],
      [
        1312934400000,
        0.6961
      ],
      [
        1313020800000,
        0.7072
      ],
      [
        1313107200000,
        0.7019
      ],
      [
        1313366400000,
        0.699
      ],
      [
        1313452800000,
        0.6965
      ],
      [
        1313539200000,
        0.6909
      ],
      [
        1313625600000,
        0.696
      ],
      [
        1313712000000,
        0.6953
      ],
      [
        1313971200000,
        0.6939
      ],
      [
        1314057600000,
        0.6916
      ],
      [
        1314144000000,
        0.693
      ],
      [
        1314230400000,
        0.6934
      ],
      [
        1314316800000,
        0.6944
      ],
      [
        1314576000000,
        0.6904
      ],
      [
        1314662400000,
        0.6944
      ],
      [
        1314748800000,
        0.6921
      ],
      [
        1314835200000,
        0.7001
      ],
      [
        1314921600000,
        0.7016
      ],
      [
        1315180800000,
        0.708
      ],
      [
        1315267200000,
        0.7094
      ],
      [
        1315353600000,
        0.7126
      ],
      [
        1315440000000,
        0.7121
      ],
      [
        1315526400000,
        0.7238
      ],
      [
        1315785600000,
        0.7324
      ],
      [
        1315872000000,
        0.733
      ],
      [
        1315958400000,
        0.7285
      ],
      [
        1316044800000,
        0.725
      ],
      [
        1316131200000,
        0.7268
      ],
      [
        1316390400000,
        0.7332
      ],
      [
        1316476800000,
        0.7295
      ],
      [
        1316563200000,
        0.7335
      ],
      [
        1316649600000,
        0.7437
      ],
      [
        1316736000000,
        0.7447
      ],
      [
        1316995200000,
        0.7408
      ],
      [
        1317081600000,
        0.7365
      ],
      [
        1317168000000,
        0.7337
      ],
      [
        1317254400000,
        0.7346
      ],
      [
        1317340800000,
        0.7407
      ],
      [
        1317600000000,
        0.7505
      ],
      [
        1317686400000,
        0.7588
      ],
      [
        1317772800000,
        0.7499
      ],
      [
        1317859200000,
        0.7537
      ],
      [
        1317945600000,
        0.7445
      ],
      [
        1318204800000,
        0.7358
      ],
      [
        1318291200000,
        0.735
      ],
      [
        1318377600000,
        0.7265
      ],
      [
        1318464000000,
        0.7286
      ],
      [
        1318550400000,
        0.7244
      ],
      [
        1318809600000,
        0.726
      ],
      [
        1318896000000,
        0.7313
      ],
      [
        1318982400000,
        0.7233
      ],
      [
        1319068800000,
        0.7244
      ],
      [
        1319155200000,
        0.7248
      ],
      [
        1319414400000,
        0.7218
      ],
      [
        1319500800000,
        0.7186
      ],
      [
        1319587200000,
        0.7181
      ],
      [
        1319673600000,
        0.7125
      ],
      [
        1319760000000,
        0.7063
      ],
      [
        1320019200000,
        0.7143
      ],
      [
        1320105600000,
        0.7339
      ],
      [
        1320192000000,
        0.7243
      ],
      [
        1320278400000,
        0.7262
      ],
      [
        1320364800000,
        0.7262
      ],
      [
        1320624000000,
        0.7278
      ],
      [
        1320710400000,
        0.7254
      ],
      [
        1320796800000,
        0.7336
      ],
      [
        1320883200000,
        0.7345
      ],
      [
        1320969600000,
        0.7327
      ],
      [
        1321228800000,
        0.7322
      ],
      [
        1321315200000,
        0.7391
      ],
      [
        1321401600000,
        0.7417
      ],
      [
        1321488000000,
        0.7419
      ],
      [
        1321574400000,
        0.7367
      ],
      [
        1321833600000,
        0.7432
      ],
      [
        1321920000000,
        0.7389
      ],
      [
        1322006400000,
        0.7471
      ],
      [
        1322092800000,
        0.7479
      ],
      [
        1322179200000,
        0.756
      ],
      [
        1322438400000,
        0.7493
      ],
      [
        1322524800000,
        0.75
      ],
      [
        1322611200000,
        0.7454
      ],
      [
        1322697600000,
        0.7413
      ],
      [
        1322784000000,
        0.7402
      ],
      [
        1323043200000,
        0.744
      ],
      [
        1323129600000,
        0.7467
      ],
      [
        1323216000000,
        0.7477
      ],
      [
        1323302400000,
        0.7458
      ],
      [
        1323388800000,
        0.7473
      ],
      [
        1323648000000,
        0.7548
      ],
      [
        1323734400000,
        0.7588
      ],
      [
        1323820800000,
        0.7697
      ],
      [
        1323907200000,
        0.7682
      ],
      [
        1323993600000,
        0.7656
      ],
      [
        1324252800000,
        0.767
      ],
      [
        1324339200000,
        0.765
      ],
      [
        1324425600000,
        0.7661
      ],
      [
        1324512000000,
        0.7666
      ],
      [
        1324598400000,
        0.766
      ],
      [
        1324857600000,
        0.766
      ],
      [
        1324944000000,
        0.7653
      ],
      [
        1325030400000,
        0.765
      ],
      [
        1325116800000,
        0.776
      ],
      [
        1325203200000,
        0.773
      ],
      [
        1325462400000,
        0.7732
      ],
      [
        1325548800000,
        0.7685
      ],
      [
        1325635200000,
        0.7724
      ],
      [
        1325721600000,
        0.7794
      ],
      [
        1325808000000,
        0.7828
      ],
      [
        1326067200000,
        0.7858
      ],
      [
        1326153600000,
        0.7809
      ],
      [
        1326240000000,
        0.7864
      ],
      [
        1326326400000,
        0.7853
      ],
      [
        1326412800000,
        0.7831
      ],
      [
        1326672000000,
        0.7894
      ],
      [
        1326758400000,
        0.782
      ],
      [
        1326844800000,
        0.7795
      ],
      [
        1326931200000,
        0.7746
      ],
      [
        1327017600000,
        0.7752
      ],
      [
        1327276800000,
        0.7683
      ],
      [
        1327363200000,
        0.7692
      ],
      [
        1327449600000,
        0.7728
      ],
      [
        1327536000000,
        0.7608
      ],
      [
        1327622400000,
        0.7608
      ],
      [
        1327881600000,
        0.7629
      ],
      [
        1327968000000,
        0.7591
      ],
      [
        1328054400000,
        0.7591
      ],
      [
        1328140800000,
        0.7638
      ],
      [
        1328227200000,
        0.76
      ],
      [
        1328486400000,
        0.7669
      ],
      [
        1328572800000,
        0.7627
      ],
      [
        1328659200000,
        0.7535
      ],
      [
        1328745600000,
        0.7527
      ],
      [
        1328832000000,
        0.7583
      ],
      [
        1329091200000,
        0.7546
      ],
      [
        1329177600000,
        0.7595
      ],
      [
        1329264000000,
        0.7639
      ],
      [
        1329350400000,
        0.7704
      ],
      [
        1329436800000,
        0.76
      ],
      [
        1329696000000,
        0.7539
      ],
      [
        1329782400000,
        0.7564
      ],
      [
        1329868800000,
        0.756
      ],
      [
        1329955200000,
        0.752
      ],
      [
        1330041600000,
        0.7457
      ],
      [
        1330300800000,
        0.747
      ],
      [
        1330387200000,
        0.7434
      ],
      [
        1330473600000,
        0.744
      ],
      [
        1330560000000,
        0.7513
      ],
      [
        1330646400000,
        0.7567
      ],
      [
        1330905600000,
        0.7565
      ],
      [
        1330992000000,
        0.7604
      ],
      [
        1331078400000,
        0.7623
      ],
      [
        1331164800000,
        0.7553
      ],
      [
        1331251200000,
        0.7582
      ],
      [
        1331510400000,
        0.7624
      ],
      [
        1331596800000,
        0.766
      ],
      [
        1331683200000,
        0.7657
      ],
      [
        1331769600000,
        0.766
      ],
      [
        1331856000000,
        0.7625
      ],
      [
        1332115200000,
        0.7606
      ],
      [
        1332201600000,
        0.7578
      ],
      [
        1332288000000,
        0.7562
      ],
      [
        1332374400000,
        0.7596
      ],
      [
        1332460800000,
        0.7553
      ],
      [
        1332720000000,
        0.7533
      ],
      [
        1332806400000,
        0.7501
      ],
      [
        1332892800000,
        0.7499
      ],
      [
        1332979200000,
        0.7536
      ],
      [
        1333065600000,
        0.7488
      ],
      [
        1333324800000,
        0.7509
      ],
      [
        1333411200000,
        0.7511
      ],
      [
        1333497600000,
        0.761
      ],
      [
        1333584000000,
        0.7653
      ],
      [
        1333670400000,
        0.7653
      ],
      [
        1333929600000,
        0.7653
      ],
      [
        1334016000000,
        0.7626
      ],
      [
        1334102400000,
        0.7617
      ],
      [
        1334188800000,
        0.7604
      ],
      [
        1334275200000,
        0.7607
      ],
      [
        1334534400000,
        0.7679
      ],
      [
        1334620800000,
        0.7616
      ],
      [
        1334707200000,
        0.7639
      ],
      [
        1334793600000,
        0.7643
      ],
      [
        1334880000000,
        0.7581
      ],
      [
        1335139200000,
        0.7617
      ],
      [
        1335225600000,
        0.7599
      ],
      [
        1335312000000,
        0.7573
      ],
      [
        1335398400000,
        0.7568
      ],
      [
        1335484800000,
        0.756
      ],
      [
        1335744000000,
        0.7569
      ],
      [
        1335830400000,
        0.7569
      ],
      [
        1335916800000,
        0.7617
      ],
      [
        1336003200000,
        0.7621
      ],
      [
        1336089600000,
        0.7616
      ],
      [
        1336348800000,
        0.7674
      ],
      [
        1336435200000,
        0.7679
      ],
      [
        1336521600000,
        0.7723
      ],
      [
        1336608000000,
        0.7716
      ],
      [
        1336694400000,
        0.7727
      ],
      [
        1336953600000,
        0.7775
      ],
      [
        1337040000000,
        0.7787
      ],
      [
        1337126400000,
        0.7852
      ],
      [
        1337212800000,
        0.7886
      ],
      [
        1337299200000,
        0.7862
      ],
      [
        1337558400000,
        0.7844
      ],
      [
        1337644800000,
        0.7833
      ],
      [
        1337731200000,
        0.7901
      ],
      [
        1337817600000,
        0.7965
      ],
      [
        1337904000000,
        0.7972
      ],
      [
        1338163200000,
        0.7959
      ],
      [
        1338249600000,
        0.7986
      ],
      [
        1338336000000,
        0.8041
      ],
      [
        1338422400000,
        0.8064
      ],
      [
        1338508800000,
        0.8117
      ],
      [
        1338768000000,
        0.8042
      ],
      [
        1338854400000,
        0.8047
      ],
      [
        1338940800000,
        0.8011
      ],
      [
        1339027200000,
        0.7941
      ],
      [
        1339113600000,
        0.8022
      ],
      [
        1339372800000,
        0.7973
      ],
      [
        1339459200000,
        0.8006
      ],
      [
        1339545600000,
        0.7979
      ],
      [
        1339632000000,
        0.7968
      ],
      [
        1339718400000,
        0.794
      ],
      [
        1339977600000,
        0.7926
      ],
      [
        1340064000000,
        0.7926
      ],
      [
        1340150400000,
        0.7873
      ],
      [
        1340236800000,
        0.7894
      ],
      [
        1340323200000,
        0.7976
      ],
      [
        1340582400000,
        0.8009
      ],
      [
        1340668800000,
        0.8017
      ],
      [
        1340755200000,
        0.8015
      ],
      [
        1340841600000,
        0.8054
      ],
      [
        1340928000000,
        0.7944
      ],
      [
        1341187200000,
        0.7942
      ],
      [
        1341273600000,
        0.7953
      ],
      [
        1341360000000,
        0.7963
      ],
      [
        1341446400000,
        0.8049
      ],
      [
        1341532800000,
        0.8081
      ],
      [
        1341792000000,
        0.8136
      ],
      [
        1341878400000,
        0.8141
      ],
      [
        1341964800000,
        0.8158
      ],
      [
        1342051200000,
        0.8213
      ],
      [
        1342137600000,
        0.8208
      ],
      [
        1342396800000,
        0.8213
      ],
      [
        1342483200000,
        0.8144
      ],
      [
        1342569600000,
        0.8175
      ],
      [
        1342656000000,
        0.814
      ],
      [
        1342742400000,
        0.8198
      ],
      [
        1343001600000,
        0.8262
      ],
      [
        1343088000000,
        0.8273
      ],
      [
        1343174400000,
        0.8242
      ],
      [
        1343260800000,
        0.8158
      ],
      [
        1343347200000,
        0.812
      ],
      [
        1343606400000,
        0.8167
      ],
      [
        1343692800000,
        0.8142
      ],
      [
        1343779200000,
        0.8132
      ],
      [
        1343865600000,
        0.8101
      ],
      [
        1343952000000,
        0.8168
      ],
      [
        1344211200000,
        0.8079
      ],
      [
        1344297600000,
        0.8042
      ],
      [
        1344384000000,
        0.8107
      ],
      [
        1344470400000,
        0.813
      ],
      [
        1344556800000,
        0.8156
      ],
      [
        1344816000000,
        0.8105
      ],
      [
        1344902400000,
        0.8097
      ],
      [
        1344988800000,
        0.8147
      ],
      [
        1345075200000,
        0.8145
      ],
      [
        1345161600000,
        0.8107
      ],
      [
        1345420800000,
        0.8131
      ],
      [
        1345507200000,
        0.8047
      ],
      [
        1345593600000,
        0.8034
      ],
      [
        1345680000000,
        0.7968
      ],
      [
        1345766400000,
        0.7997
      ],
      [
        1346025600000,
        0.7982
      ],
      [
        1346112000000,
        0.797
      ],
      [
        1346198400000,
        0.7972
      ],
      [
        1346284800000,
        0.7973
      ],
      [
        1346371200000,
        0.7931
      ],
      [
        1346630400000,
        0.7958
      ],
      [
        1346716800000,
        0.7951
      ],
      [
        1346803200000,
        0.7951
      ],
      [
        1346889600000,
        0.7914
      ],
      [
        1346976000000,
        0.7871
      ],
      [
        1347235200000,
        0.7828
      ],
      [
        1347321600000,
        0.7821
      ],
      [
        1347408000000,
        0.7755
      ],
      [
        1347494400000,
        0.7747
      ],
      [
        1347580800000,
        0.7638
      ],
      [
        1347840000000,
        0.7643
      ],
      [
        1347926400000,
        0.7661
      ],
      [
        1348012800000,
        0.7692
      ],
      [
        1348099200000,
        0.7721
      ],
      [
        1348185600000,
        0.77
      ],
      [
        1348444800000,
        0.7743
      ],
      [
        1348531200000,
        0.7734
      ],
      [
        1348617600000,
        0.7786
      ],
      [
        1348704000000,
        0.7769
      ],
      [
        1348790400000,
        0.7735
      ],
      [
        1349049600000,
        0.7767
      ],
      [
        1349136000000,
        0.7735
      ],
      [
        1349222400000,
        0.7751
      ],
      [
        1349308800000,
        0.7722
      ],
      [
        1349395200000,
        0.7692
      ],
      [
        1349654400000,
        0.7718
      ],
      [
        1349740800000,
        0.7721
      ],
      [
        1349827200000,
        0.776
      ],
      [
        1349913600000,
        0.7742
      ],
      [
        1350000000000,
        0.7711
      ],
      [
        1350259200000,
        0.7711
      ],
      [
        1350345600000,
        0.7666
      ],
      [
        1350432000000,
        0.7623
      ],
      [
        1350518400000,
        0.7624
      ],
      [
        1350604800000,
        0.7673
      ],
      [
        1350864000000,
        0.7656
      ],
      [
        1350950400000,
        0.769
      ],
      [
        1351036800000,
        0.7728
      ],
      [
        1351123200000,
        0.7697
      ],
      [
        1351209600000,
        0.7748
      ],
      [
        1351468800000,
        0.7754
      ],
      [
        1351555200000,
        0.7716
      ],
      [
        1351641600000,
        0.7697
      ],
      [
        1351728000000,
        0.7708
      ],
      [
        1351814400000,
        0.7783
      ],
      [
        1352073600000,
        0.7828
      ],
      [
        1352160000000,
        0.7814
      ],
      [
        1352246400000,
        0.7847
      ],
      [
        1352332800000,
        0.7853
      ],
      [
        1352419200000,
        0.7879
      ],
      [
        1352678400000,
        0.7853
      ],
      [
        1352764800000,
        0.7877
      ],
      [
        1352851200000,
        0.7859
      ],
      [
        1352937600000,
        0.784
      ],
      [
        1353024000000,
        0.7847
      ],
      [
        1353283200000,
        0.7837
      ],
      [
        1353369600000,
        0.7808
      ],
      [
        1353456000000,
        0.781
      ],
      [
        1353542400000,
        0.7757
      ],
      [
        1353628800000,
        0.7748
      ],
      [
        1353888000000,
        0.7715
      ],
      [
        1353974400000,
        0.7716
      ],
      [
        1354060800000,
        0.7758
      ],
      [
        1354147200000,
        0.7697
      ],
      [
        1354233600000,
        0.7702
      ],
      [
        1354492800000,
        0.766
      ],
      [
        1354579200000,
        0.7639
      ],
      [
        1354665600000,
        0.7655
      ],
      [
        1354752000000,
        0.7651
      ],
      [
        1354838400000,
        0.775
      ],
      [
        1355097600000,
        0.7735
      ],
      [
        1355184000000,
        0.7697
      ],
      [
        1355270400000,
        0.767
      ],
      [
        1355356800000,
        0.7648
      ],
      [
        1355443200000,
        0.7646
      ],
      [
        1355702400000,
        0.76
      ],
      [
        1355788800000,
        0.7589
      ],
      [
        1355875200000,
        0.7519
      ],
      [
        1355961600000,
        0.755
      ],
      [
        1356048000000,
        0.7572
      ],
      [
        1356307200000,
        0.7566
      ],
      [
        1356566400000,
        0.7539
      ],
      [
        1356652800000,
        0.7587
      ],
      [
        1356912000000,
        0.758
      ],
      [
        1357084800000,
        0.7541
      ],
      [
        1357171200000,
        0.7633
      ],
      [
        1357257600000,
        0.7686
      ],
      [
        1357516800000,
        0.767
      ],
      [
        1357603200000,
        0.7643
      ],
      [
        1357689600000,
        0.766
      ],
      [
        1357776000000,
        0.7627
      ],
      [
        1357862400000,
        0.7535
      ],
      [
        1358121600000,
        0.7497
      ],
      [
        1358208000000,
        0.7505
      ],
      [
        1358294400000,
        0.7533
      ],
      [
        1358380800000,
        0.7482
      ],
      [
        1358467200000,
        0.7506
      ],
      [
        1358726400000,
        0.7507
      ],
      [
        1358812800000,
        0.751
      ],
      [
        1358899200000,
        0.7503
      ],
      [
        1358985600000,
        0.7492
      ],
      [
        1359072000000,
        0.7425
      ],
      [
        1359331200000,
        0.7439
      ],
      [
        1359417600000,
        0.7445
      ],
      [
        1359504000000,
        0.7386
      ],
      [
        1359590400000,
        0.7381
      ],
      [
        1359676800000,
        0.733
      ],
      [
        1359936000000,
        0.738
      ],
      [
        1360022400000,
        0.7388
      ],
      [
        1360108800000,
        0.7399
      ],
      [
        1360195200000,
        0.7383
      ],
      [
        1360281600000,
        0.7478
      ],
      [
        1360540800000,
        0.7469
      ],
      [
        1360627200000,
        0.7443
      ],
      [
        1360713600000,
        0.7419
      ],
      [
        1360800000000,
        0.7505
      ],
      [
        1360886400000,
        0.7506
      ],
      [
        1361145600000,
        0.7491
      ],
      [
        1361232000000,
        0.7492
      ],
      [
        1361318400000,
        0.748
      ],
      [
        1361404800000,
        0.7585
      ],
      [
        1361491200000,
        0.7585
      ],
      [
        1361750400000,
        0.7518
      ],
      [
        1361836800000,
        0.7648
      ],
      [
        1361923200000,
        0.7636
      ],
      [
        1362009600000,
        0.7618
      ],
      [
        1362096000000,
        0.7693
      ],
      [
        1362355200000,
        0.7689
      ],
      [
        1362441600000,
        0.7673
      ],
      [
        1362528000000,
        0.7673
      ],
      [
        1362614400000,
        0.7687
      ],
      [
        1362700800000,
        0.764
      ],
      [
        1362960000000,
        0.7697
      ],
      [
        1363046400000,
        0.7662
      ],
      [
        1363132800000,
        0.7705
      ],
      [
        1363219200000,
        0.7731
      ],
      [
        1363305600000,
        0.7643
      ],
      [
        1363564800000,
        0.7736
      ],
      [
        1363651200000,
        0.7727
      ],
      [
        1363737600000,
        0.7726
      ],
      [
        1363824000000,
        0.7747
      ],
      [
        1363910400000,
        0.7724
      ],
      [
        1364169600000,
        0.7732
      ],
      [
        1364256000000,
        0.7776
      ],
      [
        1364342400000,
        0.7833
      ],
      [
        1364428800000,
        0.781
      ],
      [
        1364860800000,
        0.7789
      ],
      [
        1364947200000,
        0.7796
      ],
      [
        1365033600000,
        0.7803
      ],
      [
        1365120000000,
        0.7727
      ],
      [
        1365379200000,
        0.768
      ],
      [
        1365465600000,
        0.767
      ],
      [
        1365552000000,
        0.7643
      ],
      [
        1365638400000,
        0.7624
      ],
      [
        1365724800000,
        0.7663
      ],
      [
        1365984000000,
        0.7646
      ],
      [
        1366070400000,
        0.7618
      ],
      [
        1366156800000,
        0.7618
      ],
      [
        1366243200000,
        0.7667
      ],
      [
        1366329600000,
        0.7626
      ],
      [
        1366588800000,
        0.7671
      ],
      [
        1366675200000,
        0.7699
      ],
      [
        1366761600000,
        0.769
      ],
      [
        1366848000000,
        0.7646
      ],
      [
        1366934400000,
        0.7694
      ],
      [
        1367193600000,
        0.7627
      ],
      [
        1367280000000,
        0.7651
      ],
      [
        1367452800000,
        0.7582
      ],
      [
        1367539200000,
        0.7626
      ],
      [
        1367798400000,
        0.7631
      ],
      [
        1367884800000,
        0.7631
      ],
      [
        1367971200000,
        0.7614
      ],
      [
        1368057600000,
        0.761
      ],
      [
        1368144000000,
        0.77
      ],
      [
        1368403200000,
        0.7709
      ],
      [
        1368489600000,
        0.7707
      ],
      [
        1368576000000,
        0.7775
      ],
      [
        1368662400000,
        0.7759
      ],
      [
        1368748800000,
        0.7772
      ],
      [
        1369008000000,
        0.7781
      ],
      [
        1369094400000,
        0.7773
      ],
      [
        1369180800000,
        0.7739
      ],
      [
        1369267200000,
        0.776
      ],
      [
        1369353600000,
        0.773
      ],
      [
        1369612800000,
        0.773
      ],
      [
        1369699200000,
        0.773
      ],
      [
        1369785600000,
        0.7722
      ],
      [
        1369872000000,
        0.7727
      ],
      [
        1369958400000,
        0.769
      ],
      [
        1370217600000,
        0.7689
      ],
      [
        1370304000000,
        0.7639
      ],
      [
        1370390400000,
        0.7654
      ],
      [
        1370476800000,
        0.7624
      ],
      [
        1370563200000,
        0.7542
      ],
      [
        1370822400000,
        0.7572
      ],
      [
        1370908800000,
        0.7535
      ],
      [
        1370995200000,
        0.7533
      ],
      [
        1371081600000,
        0.7511
      ],
      [
        1371168000000,
        0.7518
      ],
      [
        1371427200000,
        0.7499
      ],
      [
        1371513600000,
        0.7478
      ],
      [
        1371600000000,
        0.746
      ],
      [
        1371686400000,
        0.7577
      ],
      [
        1371772800000,
        0.7588
      ],
      [
        1372032000000,
        0.7643
      ],
      [
        1372118400000,
        0.7615
      ],
      [
        1372204800000,
        0.7679
      ],
      [
        1372291200000,
        0.7674
      ],
      [
        1372377600000,
        0.7646
      ],
      [
        1372636800000,
        0.7671
      ],
      [
        1372723200000,
        0.7683
      ],
      [
        1372809600000,
        0.7718
      ],
      [
        1372896000000,
        0.7703
      ],
      [
        1372982400000,
        0.7763
      ],
      [
        1373241600000,
        0.7783
      ],
      [
        1373328000000,
        0.7779
      ],
      [
        1373414400000,
        0.7806
      ],
      [
        1373500800000,
        0.7667
      ],
      [
        1373587200000,
        0.7673
      ],
      [
        1373846400000,
        0.7686
      ],
      [
        1373932800000,
        0.7624
      ],
      [
        1374019200000,
        0.7614
      ],
      [
        1374105600000,
        0.7639
      ],
      [
        1374192000000,
        0.7621
      ],
      [
        1374451200000,
        0.7596
      ],
      [
        1374537600000,
        0.7588
      ],
      [
        1374624000000,
        0.755
      ],
      [
        1374710400000,
        0.7576
      ],
      [
        1374796800000,
        0.7542
      ],
      [
        1375056000000,
        0.7537
      ],
      [
        1375142400000,
        0.7529
      ],
      [
        1375228800000,
        0.7534
      ],
      [
        1375315200000,
        0.7556
      ],
      [
        1375401600000,
        0.7575
      ],
      [
        1375660800000,
        0.7544
      ],
      [
        1375747200000,
        0.7531
      ],
      [
        1375833600000,
        0.7517
      ],
      [
        1375920000000,
        0.7486
      ],
      [
        1376006400000,
        0.7479
      ],
      [
        1376265600000,
        0.7531
      ],
      [
        1376352000000,
        0.7525
      ],
      [
        1376438400000,
        0.7552
      ],
      [
        1376524800000,
        0.7521
      ],
      [
        1376611200000,
        0.7497
      ],
      [
        1376870400000,
        0.7495
      ],
      [
        1376956800000,
        0.7468
      ],
      [
        1377043200000,
        0.7473
      ],
      [
        1377129600000,
        0.7507
      ],
      [
        1377216000000,
        0.7489
      ],
      [
        1377475200000,
        0.7485
      ],
      [
        1377561600000,
        0.7498
      ],
      [
        1377648000000,
        0.7493
      ],
      [
        1377734400000,
        0.7539
      ],
      [
        1377820800000,
        0.7557
      ],
      [
        1378080000000,
        0.7573
      ],
      [
        1378166400000,
        0.7593
      ],
      [
        1378252800000,
        0.7593
      ],
      [
        1378339200000,
        0.7576
      ],
      [
        1378425600000,
        0.7625
      ],
      [
        1378684800000,
        0.758
      ],
      [
        1378771200000,
        0.7554
      ],
      [
        1378857600000,
        0.7538
      ],
      [
        1378944000000,
        0.7525
      ],
      [
        1379030400000,
        0.7523
      ],
      [
        1379289600000,
        0.7488
      ],
      [
        1379376000000,
        0.7488
      ],
      [
        1379462400000,
        0.7491
      ],
      [
        1379548800000,
        0.7384
      ],
      [
        1379635200000,
        0.7401
      ],
      [
        1379894400000,
        0.7404
      ],
      [
        1379980800000,
        0.7423
      ],
      [
        1380067200000,
        0.7406
      ],
      [
        1380153600000,
        0.7409
      ],
      [
        1380240000000,
        0.7388
      ],
      [
        1380499200000,
        0.7406
      ],
      [
        1380585600000,
        0.7379
      ],
      [
        1380672000000,
        0.74
      ],
      [
        1380758400000,
        0.7357
      ],
      [
        1380844800000,
        0.7358
      ],
      [
        1381104000000,
        0.7369
      ],
      [
        1381190400000,
        0.7367
      ],
      [
        1381276800000,
        0.74
      ],
      [
        1381363200000,
        0.7391
      ],
      [
        1381449600000,
        0.7372
      ],
      [
        1381708800000,
        0.7373
      ],
      [
        1381795200000,
        0.7412
      ],
      [
        1381881600000,
        0.7375
      ],
      [
        1381968000000,
        0.7321
      ],
      [
        1382054400000,
        0.7309
      ],
      [
        1382313600000,
        0.7318
      ],
      [
        1382400000000,
        0.7314
      ],
      [
        1382486400000,
        0.7273
      ],
      [
        1382572800000,
        0.7245
      ],
      [
        1382659200000,
        0.7259
      ],
      [
        1382918400000,
        0.7256
      ],
      [
        1383004800000,
        0.7264
      ],
      [
        1383091200000,
        0.7271
      ],
      [
        1383177600000,
        0.7332
      ],
      [
        1383264000000,
        0.7406
      ],
      [
        1383523200000,
        0.7405
      ],
      [
        1383609600000,
        0.7412
      ],
      [
        1383696000000,
        0.7399
      ],
      [
        1383782400000,
        0.7483
      ],
      [
        1383868800000,
        0.7446
      ],
      [
        1384128000000,
        0.7467
      ],
      [
        1384214400000,
        0.7446
      ],
      [
        1384300800000,
        0.7455
      ],
      [
        1384387200000,
        0.7444
      ],
      [
        1384473600000,
        0.743
      ],
      [
        1384732800000,
        0.7399
      ],
      [
        1384819200000,
        0.7407
      ],
      [
        1384905600000,
        0.7394
      ],
      [
        1384992000000,
        0.7424
      ],
      [
        1385078400000,
        0.7399
      ],
      [
        1385337600000,
        0.7401
      ],
      [
        1385424000000,
        0.7383
      ],
      [
        1385510400000,
        0.7356
      ],
      [
        1385596800000,
        0.7358
      ],
      [
        1385683200000,
        0.7348
      ],
      [
        1385942400000,
        0.7389
      ],
      [
        1386028800000,
        0.7366
      ],
      [
        1386115200000,
        0.7358
      ],
      [
        1386201600000,
        0.7357
      ],
      [
        1386288000000,
        0.7321
      ],
      [
        1386547200000,
        0.7289
      ],
      [
        1386633600000,
        0.7274
      ],
      [
        1386720000000,
        0.7265
      ],
      [
        1386806400000,
        0.7261
      ],
      [
        1386892800000,
        0.7286
      ],
      [
        1387152000000,
        0.726
      ],
      [
        1387238400000,
        0.7274
      ],
      [
        1387324800000,
        0.7274
      ],
      [
        1387411200000,
        0.7318
      ],
      [
        1387497600000,
        0.7324
      ],
      [
        1387756800000,
        0.7299
      ],
      [
        1387843200000,
        0.7309
      ],
      [
        1388102400000,
        0.724
      ],
      [
        1388361600000,
        0.7256
      ],
      [
        1388448000000,
        0.7252
      ],
      [
        1388620800000,
        0.7323
      ],
      [
        1388707200000,
        0.7336
      ],
      [
        1388966400000,
        0.7353
      ],
      [
        1389052800000,
        0.7332
      ],
      [
        1389139200000,
        0.7357
      ],
      [
        1389225600000,
        0.7347
      ],
      [
        1389312000000,
        0.7361
      ],
      [
        1389571200000,
        0.7325
      ],
      [
        1389657600000,
        0.7318
      ],
      [
        1389744000000,
        0.7351
      ],
      [
        1389830400000,
        0.7356
      ],
      [
        1389916800000,
        0.7363
      ],
      [
        1390176000000,
        0.7372
      ],
      [
        1390262400000,
        0.7394
      ],
      [
        1390348800000,
        0.7372
      ],
      [
        1390435200000,
        0.7333
      ],
      [
        1390521600000,
        0.7307
      ],
      [
        1390780800000,
        0.7323
      ],
      [
        1390867200000,
        0.7328
      ],
      [
        1390953600000,
        0.735
      ],
      [
        1391040000000,
        0.7368
      ],
      [
        1391126400000,
        0.74
      ],
      [
        1391385600000,
        0.741
      ],
      [
        1391472000000,
        0.7398
      ],
      [
        1391558400000,
        0.7385
      ],
      [
        1391644800000,
        0.7411
      ],
      [
        1391731200000,
        0.7368
      ],
      [
        1391990400000,
        0.7333
      ],
      [
        1392076800000,
        0.7313
      ],
      [
        1392163200000,
        0.7369
      ],
      [
        1392249600000,
        0.7314
      ],
      [
        1392336000000,
        0.7297
      ],
      [
        1392595200000,
        0.7301
      ],
      [
        1392681600000,
        0.7284
      ],
      [
        1392768000000,
        0.7276
      ],
      [
        1392854400000,
        0.7297
      ],
      [
        1392940800000,
        0.7297
      ],
      [
        1393200000000,
        0.7282
      ],
      [
        1393286400000,
        0.7272
      ],
      [
        1393372800000,
        0.7286
      ],
      [
        1393459200000,
        0.7324
      ],
      [
        1393545600000,
        0.7241
      ],
      [
        1393804800000,
        0.7264
      ],
      [
        1393891200000,
        0.7264
      ],
      [
        1393977600000,
        0.7283
      ],
      [
        1394064000000,
        0.7276
      ],
      [
        1394150400000,
        0.7198
      ],
      [
        1394409600000,
        0.7205
      ],
      [
        1394496000000,
        0.7221
      ],
      [
        1394582400000,
        0.7202
      ],
      [
        1394668800000,
        0.7174
      ],
      [
        1394755200000,
        0.7204
      ],
      [
        1395014400000,
        0.7192
      ],
      [
        1395100800000,
        0.7194
      ],
      [
        1395187200000,
        0.7189
      ],
      [
        1395273600000,
        0.7267
      ],
      [
        1395360000000,
        0.7258
      ],
      [
        1395619200000,
        0.7261
      ],
      [
        1395705600000,
        0.7253
      ],
      [
        1395792000000,
        0.7252
      ],
      [
        1395878400000,
        0.7269
      ],
      [
        1395964800000,
        0.7269
      ],
      [
        1396224000000,
        0.7254
      ],
      [
        1396310400000,
        0.7253
      ],
      [
        1396396800000,
        0.725
      ],
      [
        1396483200000,
        0.7263
      ],
      [
        1396569600000,
        0.73
      ],
      [
        1396828800000,
        0.7288
      ],
      [
        1396915200000,
        0.7261
      ],
      [
        1397001600000,
        0.7251
      ],
      [
        1397088000000,
        0.7212
      ],
      [
        1397174400000,
        0.721
      ],
      [
        1397433600000,
        0.7233
      ],
      [
        1397520000000,
        0.7246
      ],
      [
        1397606400000,
        0.7226
      ],
      [
        1397692800000,
        0.7219
      ],
      [
        1398124800000,
        0.7238
      ],
      [
        1398211200000,
        0.723
      ],
      [
        1398297600000,
        0.7237
      ],
      [
        1398384000000,
        0.7231
      ],
      [
        1398643200000,
        0.7215
      ],
      [
        1398729600000,
        0.7234
      ],
      [
        1398816000000,
        0.7221
      ],
      [
        1398988800000,
        0.7215
      ],
      [
        1399248000000,
        0.7209
      ],
      [
        1399334400000,
        0.7172
      ],
      [
        1399420800000,
        0.7181
      ],
      [
        1399507200000,
        0.7168
      ],
      [
        1399593600000,
        0.7257
      ],
      [
        1399852800000,
        0.7266
      ],
      [
        1399939200000,
        0.7299
      ],
      [
        1400025600000,
        0.729
      ],
      [
        1400112000000,
        0.7322
      ],
      [
        1400198400000,
        0.7302
      ],
      [
        1400457600000,
        0.7292
      ],
      [
        1400544000000,
        0.7299
      ],
      [
        1400630400000,
        0.7313
      ],
      [
        1400716800000,
        0.7317
      ],
      [
        1400803200000,
        0.7338
      ],
      [
        1401062400000,
        0.7335
      ],
      [
        1401148800000,
        0.7333
      ],
      [
        1401235200000,
        0.735
      ],
      [
        1401321600000,
        0.7347
      ],
      [
        1401408000000,
        0.735
      ],
      [
        1401667200000,
        0.7348
      ],
      [
        1401753600000,
        0.733
      ],
      [
        1401840000000,
        0.7339
      ],
      [
        1401926400000,
        0.7372
      ],
      [
        1402012800000,
        0.7331
      ],
      [
        1402272000000,
        0.735
      ],
      [
        1402358400000,
        0.7383
      ],
      [
        1402444800000,
        0.7383
      ],
      [
        1402531200000,
        0.7393
      ],
      [
        1402617600000,
        0.739
      ],
      [
        1402876800000,
        0.7391
      ],
      [
        1402963200000,
        0.7371
      ],
      [
        1403049600000,
        0.7374
      ],
      [
        1403136000000,
        0.7343
      ],
      [
        1403222400000,
        0.736
      ],
      [
        1403481600000,
        0.7356
      ],
      [
        1403568000000,
        0.7344
      ],
      [
        1403654400000,
        0.7346
      ],
      [
        1403740800000,
        0.7351
      ],
      [
        1403827200000,
        0.7343
      ],
      [
        1404086400000,
        0.7323
      ],
      [
        1404172800000,
        0.7307
      ],
      [
        1404259200000,
        0.7324
      ],
      [
        1404345600000,
        0.7329
      ],
      [
        1404432000000,
        0.736
      ],
      [
        1404691200000,
        0.7358
      ],
      [
        1404777600000,
        0.736
      ],
      [
        1404864000000,
        0.7352
      ],
      [
        1404950400000,
        0.7352
      ],
      [
        1405036800000,
        0.7357
      ],
      [
        1405296000000,
        0.7339
      ],
      [
        1405382400000,
        0.7347
      ],
      [
        1405468800000,
        0.7391
      ],
      [
        1405555200000,
        0.7395
      ],
      [
        1405641600000,
        0.7395
      ],
      [
        1405900800000,
        0.7399
      ],
      [
        1405987200000,
        0.7419
      ],
      [
        1406073600000,
        0.7428
      ],
      [
        1406160000000,
        0.7424
      ],
      [
        1406246400000,
        0.7441
      ],
      [
        1406505600000,
        0.7445
      ],
      [
        1406592000000,
        0.7448
      ],
      [
        1406678400000,
        0.7463
      ],
      [
        1406764800000,
        0.7475
      ],
      [
        1406851200000,
        0.7466
      ],
      [
        1407110400000,
        0.7451
      ],
      [
        1407196800000,
        0.7474
      ],
      [
        1407283200000,
        0.7494
      ],
      [
        1407369600000,
        0.7482
      ],
      [
        1407456000000,
        0.747
      ],
      [
        1407715200000,
        0.7471
      ],
      [
        1407801600000,
        0.7494
      ],
      [
        1407888000000,
        0.7486
      ],
      [
        1407974400000,
        0.7479
      ],
      [
        1408060800000,
        0.747
      ],
      [
        1408320000000,
        0.7473
      ],
      [
        1408406400000,
        0.7489
      ],
      [
        1408492800000,
        0.7529
      ],
      [
        1408579200000,
        0.7541
      ],
      [
        1408665600000,
        0.7538
      ],
      [
        1408924800000,
        0.7577
      ],
      [
        1409011200000,
        0.7581
      ],
      [
        1409097600000,
        0.759
      ],
      [
        1409184000000,
        0.7589
      ],
      [
        1409270400000,
        0.7584
      ],
      [
        1409529600000,
        0.7615
      ],
      [
        1409616000000,
        0.7626
      ],
      [
        1409702400000,
        0.7605
      ],
      [
        1409788800000,
        0.7684
      ],
      [
        1409875200000,
        0.7724
      ],
      [
        1410134400000,
        0.7725
      ],
      [
        1410220800000,
        0.7752
      ],
      [
        1410307200000,
        0.7736
      ],
      [
        1410393600000,
        0.7736
      ],
      [
        1410480000000,
        0.7734
      ],
      [
        1410739200000,
        0.7746
      ],
      [
        1410825600000,
        0.7724
      ],
      [
        1410912000000,
        0.7719
      ],
      [
        1410998400000,
        0.777
      ],
      [
        1411084800000,
        0.7782
      ],
      [
        1411344000000,
        0.7786
      ],
      [
        1411430400000,
        0.7758
      ],
      [
        1411516800000,
        0.7798
      ],
      [
        1411603200000,
        0.7868
      ],
      [
        1411689600000,
        0.7855
      ],
      [
        1411948800000,
        0.7874
      ],
      [
        1412035200000,
        0.7948
      ],
      [
        1412121600000,
        0.7936
      ],
      [
        1412208000000,
        0.7918
      ],
      [
        1412294400000,
        0.7927
      ],
      [
        1412553600000,
        0.796
      ],
      [
        1412640000000,
        0.7933
      ],
      [
        1412726400000,
        0.7909
      ],
      [
        1412812800000,
        0.7836
      ],
      [
        1412899200000,
        0.7914
      ],
      [
        1413158400000,
        0.7888
      ],
      [
        1413244800000,
        0.7909
      ],
      [
        1413331200000,
        0.7896
      ],
      [
        1413417600000,
        0.7845
      ],
      [
        1413504000000,
        0.7799
      ],
      [
        1413763200000,
        0.783
      ],
      [
        1413849600000,
        0.7837
      ],
      [
        1413936000000,
        0.7879
      ],
      [
        1414022400000,
        0.7894
      ],
      [
        1414108800000,
        0.7901
      ],
      [
        1414368000000,
        0.7888
      ],
      [
        1414454400000,
        0.7845
      ],
      [
        1414540800000,
        0.7852
      ],
      [
        1414627200000,
        0.7939
      ],
      [
        1414713600000,
        0.7986
      ],
      [
        1414972800000,
        0.8005
      ],
      [
        1415059200000,
        0.7992
      ],
      [
        1415145600000,
        0.8014
      ],
      [
        1415232000000,
        0.799
      ],
      [
        1415318400000,
        0.807
      ],
      [
        1415577600000,
        0.801
      ],
      [
        1415664000000,
        0.805
      ],
      [
        1415750400000,
        0.8022
      ],
      [
        1415836800000,
        0.8029
      ],
      [
        1415923200000,
        0.8042
      ],
      [
        1416182400000,
        0.8004
      ],
      [
        1416268800000,
        0.7992
      ],
      [
        1416355200000,
        0.7979
      ],
      [
        1416441600000,
        0.7976
      ],
      [
        1416528000000,
        0.8051
      ],
      [
        1416787200000,
        0.8059
      ],
      [
        1416873600000,
        0.805
      ],
      [
        1416960000000,
        0.8017
      ],
      [
        1417046400000,
        0.8014
      ],
      [
        1417132800000,
        0.8012
      ],
      [
        1417392000000,
        0.8021
      ],
      [
        1417478400000,
        0.805
      ],
      [
        1417564800000,
        0.8111
      ],
      [
        1417651200000,
        0.8124
      ],
      [
        1417737600000,
        0.809
      ],
      [
        1417996800000,
        0.8159
      ],
      [
        1418083200000,
        0.8086
      ],
      [
        1418169600000,
        0.8071
      ],
      [
        1418256000000,
        0.8047
      ],
      [
        1418342400000,
        0.8033
      ],
      [
        1418601600000,
        0.8049
      ],
      [
        1418688000000,
        0.7977
      ],
      [
        1418774400000,
        0.8034
      ],
      [
        1418860800000,
        0.8141
      ],
      [
        1418947200000,
        0.8145
      ],
      [
        1419206400000,
        0.8158
      ],
      [
        1419292800000,
        0.8189
      ],
      [
        1419379200000,
        0.8185
      ],
      [
        1419811200000,
        0.82
      ],
      [
        1419897600000,
        0.8225
      ],
      [
        1419984000000,
        0.8238
      ],
      [
        1420156800000,
        0.8305
      ],
      [
        1420416000000,
        0.8394
      ],
      [
        1420502400000,
        0.8394
      ],
      [
        1420588800000,
        0.8453
      ],
      [
        1420675200000,
        0.8499
      ],
      [
        1420761600000,
        0.8466
      ],
      [
        1421020800000,
        0.8473
      ],
      [
        1421107200000,
        0.8489
      ],
      [
        1421193600000,
        0.8494
      ],
      [
        1421280000000,
        0.8542
      ],
      [
        1421366400000,
        0.8631
      ],
      [
        1421625600000,
        0.8618
      ],
      [
        1421712000000,
        0.8637
      ],
      [
        1421798400000,
        0.8627
      ],
      [
        1421884800000,
        0.8608
      ],
      [
        1421971200000,
        0.8931
      ],
      [
        1422230400000,
        0.8895
      ],
      [
        1422316800000,
        0.8846
      ],
      [
        1422403200000,
        0.8816
      ],
      [
        1422489600000,
        0.8839
      ],
      [
        1422576000000,
        0.8847
      ],
      [
        1422835200000,
        0.8843
      ],
      [
        1422921600000,
        0.8791
      ],
      [
        1423008000000,
        0.8738
      ],
      [
        1423094400000,
        0.8765
      ],
      [
        1423180800000,
        0.8737
      ],
      [
        1423440000000,
        0.887
      ],
      [
        1423526400000,
        0.8853
      ],
      [
        1423612800000,
        0.884
      ],
      [
        1423699200000,
        0.8829
      ],
      [
        1423785600000,
        0.8788
      ],
      [
        1424044800000,
        0.8767
      ],
      [
        1424131200000,
        0.8761
      ],
      [
        1424217600000,
        0.8795
      ],
      [
        1424304000000,
        0.8783
      ],
      [
        1424390400000,
        0.8852
      ],
      [
        1424649600000,
        0.8852
      ],
      [
        1424736000000,
        0.8829
      ],
      [
        1424822400000,
        0.8815
      ],
      [
        1424908800000,
        0.8837
      ],
      [
        1424995200000,
        0.8898
      ],
      [
        1425254400000,
        0.8908
      ],
      [
        1425340800000,
        0.8955
      ],
      [
        1425427200000,
        0.8991
      ],
      [
        1425513600000,
        0.9035
      ],
      [
        1425600000000,
        0.9123
      ],
      [
        1425859200000,
        0.9209
      ],
      [
        1425945600000,
        0.9314
      ],
      [
        1426032000000,
        0.9455
      ],
      [
        1426118400000,
        0.9423
      ],
      [
        1426204800000,
        0.946
      ],
      [
        1426464000000,
        0.9473
      ],
      [
        1426550400000,
        0.9404
      ],
      [
        1426636800000,
        0.9442
      ],
      [
        1426723200000,
        0.9367
      ],
      [
        1426809600000,
        0.9281
      ],
      [
        1427068800000,
        0.9165
      ],
      [
        1427155200000,
        0.9133
      ],
      [
        1427241600000,
        0.9104
      ],
      [
        1427328000000,
        0.9114
      ],
      [
        1427414400000,
        0.9212
      ],
      [
        1427673600000,
        0.9222
      ],
      [
        1427760000000,
        0.9296
      ],
      [
        1427846400000,
        0.9299
      ],
      [
        1427932800000,
        0.9235
      ],
      [
        1428364800000,
        0.922
      ],
      [
        1428451200000,
        0.9207
      ],
      [
        1428537600000,
        0.9283
      ],
      [
        1428624000000,
        0.9462
      ],
      [
        1428883200000,
        0.9478
      ],
      [
        1428969600000,
        0.9467
      ],
      [
        1429056000000,
        0.9454
      ],
      [
        1429142400000,
        0.9337
      ],
      [
        1429228800000,
        0.9248
      ],
      [
        1429488000000,
        0.9327
      ],
      [
        1429574400000,
        0.9347
      ],
      [
        1429660800000,
        0.9309
      ],
      [
        1429747200000,
        0.9284
      ],
      [
        1429833600000,
        0.924
      ],
      [
        1430092800000,
        0.9241
      ],
      [
        1430179200000,
        0.9153
      ],
      [
        1430265600000,
        0.909
      ],
      [
        1430352000000,
        0.8918
      ],
      [
        1430697600000,
        0.8968
      ],
      [
        1430784000000,
        0.8996
      ],
      [
        1430870400000,
        0.8906
      ],
      [
        1430956800000,
        0.8847
      ],
      [
        1431043200000,
        0.8913
      ],
      [
        1431302400000,
        0.8976
      ],
      [
        1431388800000,
        0.8899
      ],
      [
        1431475200000,
        0.8913
      ],
      [
        1431561600000,
        0.8758
      ],
      [
        1431648000000,
        0.8829
      ],
      [
        1431907200000,
        0.8781
      ],
      [
        1431993600000,
        0.8946
      ],
      [
        1432080000000,
        0.8995
      ],
      [
        1432166400000,
        0.8983
      ],
      [
        1432252800000,
        0.8958
      ],
      [
        1432512000000,
        0.911
      ],
      [
        1432598400000,
        0.9153
      ],
      [
        1432684800000,
        0.9207
      ],
      [
        1432771200000,
        0.9179
      ],
      [
        1432857600000,
        0.9117
      ],
      [
        1433116800000,
        0.9138
      ],
      [
        1433203200000,
        0.9068
      ],
      [
        1433289600000,
        0.8982
      ],
      [
        1433376000000,
        0.8837
      ],
      [
        1433462400000,
        0.8915
      ],
      [
        1433721600000,
        0.896
      ],
      [
        1433808000000,
        0.8891
      ],
      [
        1433894400000,
        0.8867
      ],
      [
        1433980800000,
        0.8904
      ],
      [
        1434067200000,
        0.8914
      ],
      [
        1434326400000,
        0.8915
      ],
      [
        1434412800000,
        0.8918
      ],
      [
        1434499200000,
        0.8867
      ],
      [
        1434585600000,
        0.877
      ],
      [
        1434672000000,
        0.8851
      ],
      [
        1434931200000,
        0.8815
      ],
      [
        1435017600000,
        0.8926
      ],
      [
        1435104000000,
        0.8919
      ],
      [
        1435190400000,
        0.8925
      ],
      [
        1435276800000,
        0.8928
      ],
      [
        1435536000000,
        0.8983
      ],
      [
        1435622400000,
        0.8938
      ],
      [
        1435708800000,
        0.901
      ],
      [
        1435795200000,
        0.9038
      ],
      [
        1435881600000,
        0.9013
      ],
      [
        1436140800000,
        0.9085
      ],
      [
        1436227200000,
        0.9149
      ],
      [
        1436313600000,
        0.9072
      ],
      [
        1436400000000,
        0.9047
      ],
      [
        1436486400000,
        0.8942
      ],
      [
        1436745600000,
        0.9052
      ],
      [
        1436832000000,
        0.9066
      ],
      [
        1436918400000,
        0.9084
      ],
      [
        1437004800000,
        0.9203
      ],
      [
        1437091200000,
        0.9185
      ],
      [
        1437350400000,
        0.9216
      ],
      [
        1437436800000,
        0.9203
      ],
      [
        1437523200000,
        0.9174
      ],
      [
        1437609600000,
        0.9093
      ],
      [
        1437696000000,
        0.9143
      ],
      [
        1437955200000,
        0.9044
      ],
      [
        1438041600000,
        0.9071
      ],
      [
        1438128000000,
        0.9067
      ],
      [
        1438214400000,
        0.9129
      ],
      [
        1438300800000,
        0.9119
      ],
      [
        1438560000000,
        0.9133
      ],
      [
        1438646400000,
        0.9114
      ],
      [
        1438732800000,
        0.919
      ],
      [
        1438819200000,
        0.9188
      ],
      [
        1438905600000,
        0.9141
      ],
      [
        1439164800000,
        0.9125
      ],
      [
        1439251200000,
        0.9047
      ],
      [
        1439337600000,
        0.8966
      ],
      [
        1439424000000,
        0.9003
      ],
      [
        1439510400000,
        0.8953
      ],
      [
        1439769600000,
        0.901
      ],
      [
        1439856000000,
        0.9043
      ],
      [
        1439942400000,
        0.9058
      ],
      [
        1440028800000,
        0.8943
      ],
      [
        1440115200000,
        0.8865
      ],
      [
        1440374400000,
        0.8699
      ],
      [
        1440460800000,
        0.8692
      ],
      [
        1440547200000,
        0.8771
      ],
      [
        1440633600000,
        0.8863
      ],
      [
        1440720000000,
        0.8876
      ],
      [
        1440979200000,
        0.8918
      ],
      [
        1441065600000,
        0.8901
      ],
      [
        1441152000000,
        0.8886
      ],
      [
        1441238400000,
        0.8907
      ],
      [
        1441324800000,
        0.8979
      ],
      [
        1441584000000,
        0.8973
      ],
      [
        1441670400000,
        0.896
      ],
      [
        1441756800000,
        0.8978
      ],
      [
        1441843200000,
        0.8942
      ],
      [
        1441929600000,
        0.8876
      ],
      [
        1442188800000,
        0.8847
      ],
      [
        1442275200000,
        0.8835
      ],
      [
        1442361600000,
        0.8907
      ],
      [
        1442448000000,
        0.8841
      ],
      [
        1442534400000,
        0.8758
      ],
      [
        1442793600000,
        0.889
      ],
      [
        1442880000000,
        0.8966
      ],
      [
        1442966400000,
        0.897
      ],
      [
        1443052800000,
        0.8897
      ],
      [
        1443139200000,
        0.8969
      ],
      [
        1443398400000,
        0.8954
      ],
      [
        1443484800000,
        0.8926
      ],
      [
        1443571200000,
        0.8927
      ],
      [
        1443657600000,
        0.8967
      ],
      [
        1443744000000,
        0.8962
      ],
      [
        1444003200000,
        0.8901
      ],
      [
        1444089600000,
        0.891
      ],
      [
        1444176000000,
        0.8877
      ],
      [
        1444262400000,
        0.8887
      ],
      [
        1444348800000,
        0.8802
      ],
      [
        1444608000000,
        0.8794
      ],
      [
        1444694400000,
        0.8793
      ],
      [
        1444780800000,
        0.8765
      ],
      [
        1444867200000,
        0.8743
      ],
      [
        1444953600000,
        0.8804
      ],
      [
        1445212800000,
        0.8825
      ],
      [
        1445299200000,
        0.8794
      ],
      [
        1445385600000,
        0.8808
      ],
      [
        1445472000000,
        0.884
      ],
      [
        1445558400000,
        0.9023
      ],
      [
        1445817600000,
        0.9083
      ],
      [
        1445904000000,
        0.9042
      ],
      [
        1445990400000,
        0.9022
      ],
      [
        1446076800000,
        0.915
      ],
      [
        1446163200000,
        0.9078
      ],
      [
        1446422400000,
        0.9066
      ],
      [
        1446508800000,
        0.9112
      ],
      [
        1446595200000,
        0.9146
      ],
      [
        1446681600000,
        0.919
      ],
      [
        1446768000000,
        0.9206
      ],
      [
        1447027200000,
        0.9281
      ],
      [
        1447113600000,
        0.9337
      ],
      [
        1447200000000,
        0.9333
      ],
      [
        1447286400000,
        0.9324
      ],
      [
        1447372800000,
        0.9291
      ],
      [
        1447632000000,
        0.9327
      ],
      [
        1447718400000,
        0.9373
      ],
      [
        1447804800000,
        0.9377
      ],
      [
        1447891200000,
        0.9358
      ],
      [
        1447977600000,
        0.9357
      ],
      [
        1448236800000,
        0.9407
      ],
      [
        1448323200000,
        0.939
      ],
      [
        1448409600000,
        0.9447
      ],
      [
        1448496000000,
        0.9424
      ],
      [
        1448582400000,
        0.9453
      ],
      [
        1448841600000,
        0.9454
      ],
      [
        1448928000000,
        0.9435
      ],
      [
        1449014400000,
        0.9424
      ],
      [
        1449100800000,
        0.9372
      ],
      [
        1449187200000,
        0.9174
      ],
      [
        1449446400000,
        0.9253
      ],
      [
        1449532800000,
        0.9196
      ],
      [
        1449619200000,
        0.9141
      ],
      [
        1449705600000,
        0.9139
      ],
      [
        1449792000000,
        0.9133
      ],
      [
        1450051200000,
        0.9106
      ],
      [
        1450137600000,
        0.91
      ],
      [
        1450224000000,
        0.9148
      ],
      [
        1450310400000,
        0.9225
      ],
      [
        1450396800000,
        0.9229
      ],
      [
        1450656000000,
        0.9201
      ],
      [
        1450742400000,
        0.9132
      ],
      [
        1450828800000,
        0.9162
      ],
      [
        1450915200000,
        0.9136
      ],
      [
        1451260800000,
        0.9123
      ],
      [
        1451347200000,
        0.9132
      ],
      [
        1451433600000,
        0.9153
      ],
      [
        1451520000000,
        0.9186
      ],
      [
        1451865600000,
        0.9177
      ],
      [
        1451952000000,
        0.9307
      ],
      [
        1452038400000,
        0.931
      ],
      [
        1452124800000,
        0.9202
      ],
      [
        1452211200000,
        0.9208
      ],
      [
        1452470400000,
        0.9185
      ],
      [
        1452556800000,
        0.9229
      ],
      [
        1452643200000,
        0.9247
      ],
      [
        1452729600000,
        0.9181
      ],
      [
        1452816000000,
        0.9164
      ],
      [
        1453075200000,
        0.9182
      ],
      [
        1453161600000,
        0.9202
      ],
      [
        1453248000000,
        0.9169
      ],
      [
        1453334400000,
        0.9181
      ],
      [
        1453420800000,
        0.9253
      ],
      [
        1453680000000,
        0.9247
      ],
      [
        1453766400000,
        0.9229
      ],
      [
        1453852800000,
        0.9185
      ],
      [
        1453939200000,
        0.9173
      ],
      [
        1454025600000,
        0.9159
      ],
      [
        1454284800000,
        0.9189
      ],
      [
        1454371200000,
        0.9159
      ],
      [
        1454457600000,
        0.9148
      ],
      [
        1454544000000,
        0.8925
      ],
      [
        1454630400000,
        0.8928
      ],
      [
        1454889600000,
        0.9009
      ],
      [
        1454976000000,
        0.8901
      ],
      [
        1455062400000,
        0.8884
      ],
      [
        1455148800000,
        0.8814
      ],
      [
        1455235200000,
        0.887
      ],
      [
        1455494400000,
        0.8946
      ],
      [
        1455580800000,
        0.8957
      ],
      [
        1455667200000,
        0.8981
      ],
      [
        1455753600000,
        0.9023
      ],
      [
        1455840000000,
        0.9013
      ],
      [
        1456099200000,
        0.907
      ],
      [
        1456185600000,
        0.909
      ],
      [
        1456272000000,
        0.9108
      ],
      [
        1456358400000,
        0.907
      ],
      [
        1456444800000,
        0.9087
      ],
      [
        1456704000000,
        0.9185
      ],
      [
        1456790400000,
        0.9199
      ],
      [
        1456876800000,
        0.9212
      ],
      [
        1456963200000,
        0.9174
      ],
      [
        1457049600000,
        0.9117
      ],
      [
        1457308800000,
        0.9131
      ],
      [
        1457395200000,
        0.9069
      ],
      [
        1457481600000,
        0.9114
      ],
      [
        1457568000000,
        0.9212
      ],
      [
        1457654400000,
        0.9018
      ],
      [
        1457913600000,
        0.8995
      ],
      [
        1458000000000,
        0.9003
      ],
      [
        1458086400000,
        0.9039
      ],
      [
        1458172800000,
        0.8842
      ],
      [
        1458259200000,
        0.8867
      ],
      [
        1458518400000,
        0.8873
      ],
      [
        1458604800000,
        0.892
      ],
      [
        1458691200000,
        0.8953
      ],
      [
        1458777600000,
        0.8966
      ],
      [
        1459209600000,
        0.8934
      ],
      [
        1459296000000,
        0.8832
      ],
      [
        1459382400000,
        0.8784
      ],
      [
        1459468800000,
        0.8748
      ],
      [
        1459728000000,
        0.8788
      ],
      [
        1459814400000,
        0.8798
      ],
      [
        1459900800000,
        0.8822
      ],
      [
        1459987200000,
        0.8801
      ],
      [
        1460073600000,
        0.8801
      ],
      [
        1460332800000,
        0.8781
      ],
      [
        1460419200000,
        0.8776
      ],
      [
        1460505600000,
        0.8852
      ],
      [
        1460592000000,
        0.8888
      ],
      [
        1460678400000,
        0.8863
      ],
      [
        1460937600000,
        0.8846
      ],
      [
        1461024000000,
        0.8817
      ],
      [
        1461110400000,
        0.8789
      ],
      [
        1461196800000,
        0.8808
      ],
      [
        1461283200000,
        0.888
      ],
      [
        1461542400000,
        0.8879
      ],
      [
        1461628800000,
        0.8861
      ],
      [
        1461715200000,
        0.8848
      ],
      [
        1461801600000,
        0.8805
      ],
      [
        1461888000000,
        0.8771
      ],
      [
        1462147200000,
        0.8702
      ],
      [
        1462233600000,
        0.8645
      ],
      [
        1462320000000,
        0.8693
      ],
      [
        1462406400000,
        0.8743
      ],
      [
        1462492800000,
        0.8752
      ],
      [
        1462752000000,
        0.8777
      ],
      [
        1462838400000,
        0.8792
      ],
      [
        1462924800000,
        0.8766
      ],
      [
        1463011200000,
        0.8781
      ],
      [
        1463097600000,
        0.8813
      ],
      [
        1463356800000,
        0.8832
      ],
      [
        1463443200000,
        0.8836
      ],
      [
        1463529600000,
        0.8867
      ],
      [
        1463616000000,
        0.8932
      ],
      [
        1463702400000,
        0.8914
      ],
      [
        1463961600000,
        0.8918
      ],
      [
        1464048000000,
        0.8955
      ],
      [
        1464134400000,
        0.8973
      ],
      [
        1464220800000,
        0.8955
      ],
      [
        1464307200000,
        0.8955
      ],
      [
        1464566400000,
        0.8978
      ],
      [
        1464652800000,
        0.8966
      ],
      [
        1464739200000,
        0.895
      ],
      [
        1464825600000,
        0.8939
      ],
      [
        1464912000000,
        0.8966
      ],
      [
        1465171200000,
        0.8812
      ],
      [
        1465257600000,
        0.8813
      ],
      [
        1465344000000,
        0.879
      ],
      [
        1465430400000,
        0.8817
      ],
      [
        1465516800000,
        0.8847
      ],
      [
        1465776000000,
        0.8876
      ],
      [
        1465862400000,
        0.891
      ],
      [
        1465948800000,
        0.8906
      ],
      [
        1466035200000,
        0.895
      ],
      [
        1466121600000,
        0.8887
      ],
      [
        1466380800000,
        0.8826
      ],
      [
        1466467200000,
        0.884
      ],
      [
        1466553600000,
        0.8864
      ],
      [
        1466640000000,
        0.8781
      ],
      [
        1466726400000,
        0.9038
      ],
      [
        1466985600000,
        0.9094
      ],
      [
        1467072000000,
        0.9032
      ],
      [
        1467158400000,
        0.9018
      ],
      [
        1467244800000,
        0.9008
      ],
      [
        1467331200000,
        0.8982
      ],
      [
        1467590400000,
        0.8979
      ],
      [
        1467676800000,
        0.8973
      ],
      [
        1467763200000,
        0.9035
      ],
      [
        1467849600000,
        0.9026
      ],
      [
        1467936000000,
        0.9034
      ],
      [
        1468195200000,
        0.9052
      ],
      [
        1468281600000,
        0.9017
      ],
      [
        1468368000000,
        0.9033
      ],
      [
        1468454400000,
        0.8964
      ],
      [
        1468540800000,
        0.8987
      ],
      [
        1468800000000,
        0.9048
      ],
      [
        1468886400000,
        0.9063
      ],
      [
        1468972800000,
        0.9081
      ],
      [
        1469059200000,
        0.908
      ],
      [
        1469145600000,
        0.908
      ],
      [
        1469404800000,
        0.9107
      ],
      [
        1469491200000,
        0.9094
      ],
      [
        1469577600000,
        0.9099
      ],
      [
        1469664000000,
        0.9018
      ],
      [
        1469750400000,
        0.8999
      ],
      [
        1470009600000,
        0.8958
      ],
      [
        1470096000000,
        0.8935
      ],
      [
        1470182400000,
        0.893
      ],
      [
        1470268800000,
        0.8981
      ],
      [
        1470355200000,
        0.8965
      ],
      [
        1470614400000,
        0.9021
      ],
      [
        1470700800000,
        0.9028
      ],
      [
        1470787200000,
        0.8942
      ],
      [
        1470873600000,
        0.8967
      ],
      [
        1470960000000,
        0.8963
      ],
      [
        1471219200000,
        0.8946
      ],
      [
        1471305600000,
        0.8854
      ],
      [
        1471392000000,
        0.8869
      ],
      [
        1471478400000,
        0.8834
      ],
      [
        1471564800000,
        0.883
      ],
      [
        1471824000000,
        0.8846
      ],
      [
        1471910400000,
        0.882
      ],
      [
        1471996800000,
        0.8876
      ],
      [
        1472083200000,
        0.8858
      ],
      [
        1472169600000,
        0.8858
      ],
      [
        1472428800000,
        0.8954
      ],
      [
        1472515200000,
        0.8955
      ],
      [
        1472601600000,
        0.8984
      ],
      [
        1472688000000,
        0.8973
      ],
      [
        1472774400000,
        0.8935
      ],
      [
        1473033600000,
        0.8965
      ],
      [
        1473120000000,
        0.8962
      ],
      [
        1473206400000,
        0.89
      ],
      [
        1473292800000,
        0.8854
      ],
      [
        1473379200000,
        0.8876
      ],
      [
        1473638400000,
        0.8909
      ],
      [
        1473724800000,
        0.8892
      ],
      [
        1473811200000,
        0.8915
      ],
      [
        1473897600000,
        0.8887
      ],
      [
        1473984000000,
        0.8909
      ],
      [
        1474243200000,
        0.8958
      ],
      [
        1474329600000,
        0.8942
      ],
      [
        1474416000000,
        0.897
      ],
      [
        1474502400000,
        0.8899
      ],
      [
        1474588800000,
        0.8918
      ],
      [
        1474848000000,
        0.888
      ],
      [
        1474934400000,
        0.8914
      ],
      [
        1475020800000,
        0.891
      ],
      [
        1475107200000,
        0.8913
      ],
      [
        1475193600000,
        0.8961
      ],
      [
        1475452800000,
        0.8901
      ],
      [
        1475539200000,
        0.8961
      ],
      [
        1475625600000,
        0.8921
      ],
      [
        1475712000000,
        0.8942
      ],
      [
        1475798400000,
        0.8978
      ],
      [
        1476057600000,
        0.8962
      ],
      [
        1476144000000,
        0.9027
      ],
      [
        1476230400000,
        0.9075
      ],
      [
        1476316800000,
        0.9061
      ],
      [
        1476403200000,
        0.909
      ],
      [
        1476662400000,
        0.9097
      ],
      [
        1476748800000,
        0.9098
      ],
      [
        1476835200000,
        0.9109
      ],
      [
        1476921600000,
        0.9108
      ],
      [
        1477008000000,
        0.9187
      ],
      [
        1477267200000,
        0.9183
      ],
      [
        1477353600000,
        0.9199
      ],
      [
        1477440000000,
        0.9154
      ],
      [
        1477526400000,
        0.9153
      ],
      [
        1477612800000,
        0.9157
      ],
      [
        1477872000000,
        0.9137
      ],
      [
        1477958400000,
        0.9071
      ],
      [
        1478044800000,
        0.9014
      ],
      [
        1478131200000,
        0.9039
      ],
      [
        1478217600000,
        0.9016
      ],
      [
        1478476800000,
        0.9041
      ],
      [
        1478563200000,
        0.9061
      ],
      [
        1478649600000,
        0.9074
      ],
      [
        1478736000000,
        0.918
      ],
      [
        1478822400000,
        0.9172
      ],
      [
        1479081600000,
        0.928
      ],
      [
        1479168000000,
        0.929
      ],
      [
        1479254400000,
        0.9345
      ],
      [
        1479340800000,
        0.9332
      ],
      [
        1479427200000,
        0.9409
      ],
      [
        1479686400000,
        0.9407
      ],
      [
        1479772800000,
        0.942
      ],
      [
        1479859200000,
        0.9433
      ],
      [
        1479945600000,
        0.9481
      ],
      [
        1480032000000,
        0.9442
      ],
      [
        1480291200000,
        0.9446
      ],
      [
        1480377600000,
        0.9456
      ],
      [
        1480464000000,
        0.9404
      ],
      [
        1480550400000,
        0.9411
      ],
      [
        1480636800000,
        0.9398
      ],
      [
        1480896000000,
        0.9345
      ],
      [
        1480982400000,
        0.9317
      ],
      [
        1481068800000,
        0.9321
      ],
      [
        1481155200000,
        0.9293
      ],
      [
        1481241600000,
        0.9472
      ],
      [
        1481500800000,
        0.9439
      ],
      [
        1481587200000,
        0.9426
      ],
      [
        1481673600000,
        0.9396
      ],
      [
        1481760000000,
        0.9599
      ],
      [
        1481846400000,
        0.958
      ],
      [
        1482105600000,
        0.9596
      ],
      [
        1482192000000,
        0.965
      ],
      [
        1482278400000,
        0.9597
      ],
      [
        1482364800000,
        0.9576
      ],
      [
        1482451200000,
        0.9574
      ],
      [
        1482796800000,
        0.9575
      ],
      [
        1482883200000,
        0.9615
      ],
      [
        1482969600000,
        0.9568
      ],
      [
        1483056000000,
        0.9488
      ],
      [
        1483315200000,
        0.9557
      ],
      [
        1483401600000,
        0.963
      ],
      [
        1483488000000,
        0.9582
      ],
      [
        1483574400000,
        0.9524
      ],
      [
        1483660800000,
        0.9445
      ],
      [
        1483920000000,
        0.951
      ],
      [
        1484006400000,
        0.9464
      ],
      [
        1484092800000,
        0.9522
      ],
      [
        1484179200000,
        0.9365
      ],
      [
        1484265600000,
        0.9381
      ],
      [
        1484524800000,
        0.944
      ],
      [
        1484611200000,
        0.9361
      ],
      [
        1484697600000,
        0.9378
      ],
      [
        1484784000000,
        0.9375
      ],
      [
        1484870400000,
        0.9407
      ],
      [
        1485129600000,
        0.9334
      ],
      [
        1485216000000,
        0.9305
      ],
      [
        1485302400000,
        0.9309
      ],
      [
        1485388800000,
        0.9347
      ],
      [
        1485475200000,
        0.9363
      ],
      [
        1485734400000,
        0.9408
      ],
      [
        1485820800000,
        0.9299
      ],
      [
        1485907200000,
        0.9269
      ],
      [
        1485993600000,
        0.9253
      ],
      [
        1486080000000,
        0.9311
      ],
      [
        1486339200000,
        0.9336
      ],
      [
        1486425600000,
        0.9369
      ],
      [
        1486512000000,
        0.9377
      ],
      [
        1486598400000,
        0.9354
      ],
      [
        1486684800000,
        0.9409
      ],
      [
        1486944000000,
        0.9409
      ],
      [
        1487030400000,
        0.9415
      ],
      [
        1487116800000,
        0.9475
      ],
      [
        1487203200000,
        0.9389
      ],
      [
        1487289600000,
        0.9391
      ],
      [
        1487548800000,
        0.9421
      ],
      [
        1487635200000,
        0.9491
      ],
      [
        1487721600000,
        0.9513
      ],
      [
        1487808000000,
        0.9459
      ],
      [
        1487894400000,
        0.9427
      ],
      [
        1488153600000,
        0.9447
      ],
      [
        1488240000000,
        0.9438
      ],
      [
        1488326400000,
        0.9495
      ],
      [
        1488412800000,
        0.9512
      ],
      [
        1488499200000,
        0.9466
      ],
      [
        1488758400000,
        0.9442
      ],
      [
        1488844800000,
        0.9456
      ],
      [
        1488931200000,
        0.9474
      ],
      [
        1489017600000,
        0.9479
      ],
      [
        1489104000000,
        0.943
      ],
      [
        1489363200000,
        0.9379
      ],
      [
        1489449600000,
        0.9407
      ],
      [
        1489536000000,
        0.9415
      ],
      [
        1489622400000,
        0.9324
      ],
      [
        1489708800000,
        0.9315
      ],
      [
        1489968000000,
        0.9302
      ],
      [
        1490054400000,
        0.9259
      ],
      [
        1490140800000,
        0.9254
      ],
      [
        1490227200000,
        0.9272
      ],
      [
        1490313600000,
        0.9256
      ],
      [
        1490572800000,
        0.9185
      ],
      [
        1490659200000,
        0.921
      ],
      [
        1490745600000,
        0.9305
      ],
      [
        1490832000000,
        0.9315
      ],
      [
        1490918400000,
        0.9355
      ],
      [
        1491177600000,
        0.9381
      ],
      [
        1491264000000,
        0.939
      ],
      [
        1491350400000,
        0.9366
      ],
      [
        1491436800000,
        0.9377
      ],
      [
        1491523200000,
        0.9408
      ],
      [
        1491782400000,
        0.9455
      ],
      [
        1491868800000,
        0.9421
      ],
      [
        1491955200000,
        0.9431
      ],
      [
        1492041600000,
        0.9408
      ],
      [
        1492473600000,
        0.9363
      ],
      [
        1492560000000,
        0.9325
      ],
      [
        1492646400000,
        0.9308
      ],
      [
        1492732800000,
        0.9349
      ],
      [
        1492992000000,
        0.9219
      ],
      [
        1493078400000,
        0.9183
      ],
      [
        1493164800000,
        0.9181
      ],
      [
        1493251200000,
        0.9191
      ],
      [
        1493337600000,
        0.915
      ],
      [
        1493683200000,
        0.9163
      ],
      [
        1493769600000,
        0.9159
      ],
      [
        1493856000000,
        0.9153
      ],
      [
        1493942400000,
        0.9124
      ],
      [
        1494201600000,
        0.9143
      ],
      [
        1494288000000,
        0.9185
      ],
      [
        1494374400000,
        0.919
      ],
      [
        1494460800000,
        0.9209
      ],
      [
        1494547200000,
        0.9196
      ],
      [
        1494806400000,
        0.9115
      ],
      [
        1494892800000,
        0.9043
      ],
      [
        1494979200000,
        0.8996
      ],
      [
        1495065600000,
        0.8987
      ],
      [
        1495152000000,
        0.8946
      ],
      [
        1495411200000,
        0.8895
      ],
      [
        1495497600000,
        0.8918
      ],
      [
        1495584000000,
        0.8935
      ],
      [
        1495670400000,
        0.8918
      ],
      [
        1495756800000,
        0.8933
      ],
      [
        1496016000000,
        0.8939
      ],
      [
        1496102400000,
        0.8951
      ],
      [
        1496188800000,
        0.8913
      ],
      [
        1496275200000,
        0.8914
      ],
      [
        1496361600000,
        0.8916
      ],
      [
        1496620800000,
        0.8891
      ],
      [
        1496707200000,
        0.8884
      ],
      [
        1496793600000,
        0.8916
      ],
      [
        1496880000000,
        0.8907
      ],
      [
        1496966400000,
        0.8949
      ],
      [
        1497225600000,
        0.8913
      ],
      [
        1497312000000,
        0.8916
      ],
      [
        1497398400000,
        0.8927
      ],
      [
        1497484800000,
        0.8957
      ],
      [
        1497571200000,
        0.8956
      ],
      [
        1497830400000,
        0.893
      ],
      [
        1497916800000,
        0.8965
      ],
      [
        1498003200000,
        0.8972
      ],
      [
        1498089600000,
        0.8954
      ],
      [
        1498176000000,
        0.8951
      ],
      [
        1498435200000,
        0.894
      ],
      [
        1498521600000,
        0.8868
      ],
      [
        1498608000000,
        0.8792
      ],
      [
        1498694400000,
        0.8763
      ],
      [
        1498780800000,
        0.8764
      ],
      [
        1499040000000,
        0.8797
      ],
      [
        1499126400000,
        0.8809
      ],
      [
        1499212800000,
        0.8828
      ],
      [
        1499299200000,
        0.8784
      ],
      [
        1499385600000,
        0.8764
      ],
      [
        1499644800000,
        0.8783
      ],
      [
        1499731200000,
        0.8769
      ],
      [
        1499817600000,
        0.8735
      ],
      [
        1499904000000,
        0.876
      ],
      [
        1499990400000,
        0.8761
      ],
      [
        1500249600000,
        0.8725
      ],
      [
        1500336000000,
        0.8655
      ],
      [
        1500422400000,
        0.8672
      ],
      [
        1500508800000,
        0.8708
      ],
      [
        1500595200000,
        0.8591
      ],
      [
        1500854400000,
        0.8586
      ],
      [
        1500940800000,
        0.8552
      ],
      [
        1501027200000,
        0.8589
      ],
      [
        1501113600000,
        0.8552
      ],
      [
        1501200000000,
        0.8527
      ],
      [
        1501459200000,
        0.8528
      ],
      [
        1501545600000,
        0.8467
      ],
      [
        1501632000000,
        0.8455
      ],
      [
        1501718400000,
        0.8433
      ],
      [
        1501804800000,
        0.8427
      ],
      [
        1502064000000,
        0.8478
      ],
      [
        1502150400000,
        0.8466
      ],
      [
        1502236800000,
        0.8525
      ],
      [
        1502323200000,
        0.8525
      ],
      [
        1502409600000,
        0.8501
      ],
      [
        1502668800000,
        0.8478
      ],
      [
        1502755200000,
        0.8516
      ],
      [
        1502841600000,
        0.8541
      ],
      [
        1502928000000,
        0.855
      ],
      [
        1503014400000,
        0.8519
      ],
      [
        1503273600000,
        0.8504
      ],
      [
        1503360000000,
        0.8496
      ],
      [
        1503446400000,
        0.8476
      ],
      [
        1503532800000,
        0.8471
      ],
      [
        1503619200000,
        0.847
      ],
      [
        1503878400000,
        0.8387
      ],
      [
        1503964800000,
        0.8301
      ],
      [
        1504051200000,
        0.8393
      ],
      [
        1504137600000,
        0.8458
      ],
      [
        1504224000000,
        0.839
      ],
      [
        1504483200000,
        0.8401
      ],
      [
        1504569600000,
        0.8411
      ],
      [
        1504656000000,
        0.8383
      ],
      [
        1504742400000,
        0.8355
      ],
      [
        1504828800000,
        0.8293
      ],
      [
        1505088000000,
        0.8336
      ],
      [
        1505174400000,
        0.8381
      ],
      [
        1505260800000,
        0.8349
      ],
      [
        1505347200000,
        0.8415
      ],
      [
        1505433600000,
        0.836
      ],
      [
        1505692800000,
        0.8371
      ],
      [
        1505779200000,
        0.8354
      ],
      [
        1505865600000,
        0.8329
      ],
      [
        1505952000000,
        0.8401
      ],
      [
        1506038400000,
        0.8362
      ],
      [
        1506297600000,
        0.8428
      ],
      [
        1506384000000,
        0.8485
      ],
      [
        1506470400000,
        0.8518
      ],
      [
        1506556800000,
        0.8491
      ],
      [
        1506643200000,
        0.8471
      ],
      [
        1506902400000,
        0.8516
      ],
      [
        1506988800000,
        0.8509
      ],
      [
        1507075200000,
        0.8485
      ],
      [
        1507161600000,
        0.8517
      ],
      [
        1507248000000,
        0.8543
      ],
      [
        1507507200000,
        0.8515
      ],
      [
        1507593600000,
        0.8478
      ],
      [
        1507680000000,
        0.8454
      ],
      [
        1507766400000,
        0.8436
      ],
      [
        1507852800000,
        0.8468
      ],
      [
        1508112000000,
        0.8473
      ],
      [
        1508198400000,
        0.8505
      ],
      [
        1508284800000,
        0.8512
      ],
      [
        1508371200000,
        0.8451
      ],
      [
        1508457600000,
        0.8463
      ],
      [
        1508716800000,
        0.8519
      ],
      [
        1508803200000,
        0.8504
      ],
      [
        1508889600000,
        0.8486
      ],
      [
        1508976000000,
        0.8509
      ],
      [
        1509062400000,
        0.8618
      ],
      [
        1509321600000,
        0.8613
      ],
      [
        1509408000000,
        0.8594
      ],
      [
        1509494400000,
        0.8613
      ],
      [
        1509580800000,
        0.8588
      ],
      [
        1509667200000,
        0.858
      ],
      [
        1509926400000,
        0.8629
      ],
      [
        1510012800000,
        0.865
      ],
      [
        1510099200000,
        0.8629
      ],
      [
        1510185600000,
        0.8599
      ],
      [
        1510272000000,
        0.8582
      ],
      [
        1510531200000,
        0.858
      ],
      [
        1510617600000,
        0.8515
      ],
      [
        1510704000000,
        0.8447
      ],
      [
        1510790400000,
        0.8496
      ],
      [
        1510876800000,
        0.8479
      ],
      [
        1511136000000,
        0.8489
      ],
      [
        1511222400000,
        0.8535
      ],
      [
        1511308800000,
        0.8512
      ],
      [
        1511395200000,
        0.8441
      ],
      [
        1511481600000,
        0.8421
      ],
      [
        1511740800000,
        0.8368
      ],
      [
        1511827200000,
        0.8413
      ],
      [
        1511913600000,
        0.8456
      ],
      [
        1512000000000,
        0.8441
      ],
      [
        1512086400000,
        0.8415
      ],
      [
        1512345600000,
        0.8429
      ],
      [
        1512432000000,
        0.8442
      ],
      [
        1512518400000,
        0.8463
      ],
      [
        1512604800000,
        0.8486
      ],
      [
        1512691200000,
        0.8517
      ],
      [
        1512950400000,
        0.8478
      ],
      [
        1513036800000,
        0.85
      ],
      [
        1513123200000,
        0.8522
      ],
      [
        1513209600000,
        0.8443
      ],
      [
        1513296000000,
        0.8471
      ],
      [
        1513555200000,
        0.8479
      ],
      [
        1513641600000,
        0.8459
      ],
      [
        1513728000000,
        0.8443
      ],
      [
        1513814400000,
        0.8433
      ],
      [
        1513900800000,
        0.8438
      ],
      [
        1514332800000,
        0.8408
      ],
      [
        1514419200000,
        0.838
      ],
      [
        1514505600000,
        0.8339
      ]
    ]
    let chartOptions: any = {
      chart: {
        zoomType: 'x'
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: 'Exchange rate'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },

            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          marker: {
            radius: 2
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          dataLabels: {
            enabled: false
          },
          threshold: null
        }
      },
      tooltip: { enabled: false },
      credits: { enabled: false },
      series: [{
        type: 'area',
        name: 'USD to EUR',
        data: data
      }]
    };
    Highcharts.chart('timeSeries123', chartOptions);
  }

  saveSummary() {
    this.dataService.saveWidgetDataToDB(this.portfolioService.portfolioSummaryWidget, this.summaryPage.dbData, this.portfolioService.selectedFundId).subscribe(res=>{
      this.utilService.showMessage(this.translateService.getLabel("suc_saved_info") + "!", this.translateService.getLabel("ok"));

      this.portfolioService.portfolioSummary = this.summaryPage.dbData;
    }, error=>{
      this.utilService.showMessage(this.translateService.getLabel("err_failed_profile_info"), this.translateService.getLabel("ok"));
    })
  }
}
