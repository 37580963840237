<div class="progress-message">{{progressMessage}}</div>

<!-- <div *ngIf="pageState == PAGESTATE_UPLOAD_CH_EXCEL" class="row" class="row-div" id=""> -->
<!-- <div *ngIf="(pageState == PAGESTATE_EDIT_CH_EXCEL) || (pageState == PAGESTATE_UPLOAD_CH_EXCEL)" class="row" class="row-div" id=""> -->
<div *ngIf="!portfolioService.companies || portfolioService.companies.length === 0">
  <div class="col-3">
    <input type="file" accept=".xlsx" (change)="upload($event)"/>
  </div>
  <div class="col"></div>
    
</div>

<!-- <div *ngIf="pageState == PAGESTATE_SHOW_CH_DATA" class="row"  class="row-div"> -->
<div style="margin: 10px;" *ngIf="portfolioService.companies && portfolioService.companies.length> 0">
    <button mat-raised-button class="primary-bg" (click) = "crossHoldingValuation()">
      Calculate Cross-holding
    </button>

    <button mat-raised-button class="secondary-bg button-style" (click) = "showExcelUI()">
      Edit Cross-Holding
    </button>
</div>

<div *ngIf="portfolioService.companies && portfolioService.companies.length> 0" class="table table-condensed" id="container">
  <table style="margin: 10px;">
    <tr class="bg primary-bg">
      <th style="width: 160px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('companyName', 'string')">Name</th>
      <!-- <th style="width: 120px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('investmentDate', 'string')">Investment Date</th> -->
      <th style="width: 120px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('type', 'string')">Type</th>
      <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('geography', 'string')"> Geography </th>
      <th style="width: 150px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('sector', 'string')">Sector</th>
      <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('businessType', 'string')">Business Unit</th>
      <th style="width: 100px; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('currency', 'string')">Currency</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('carryingValue', 'number')">Carrying Value</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('stake', 'number')"> Stake </th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('fairVal', 'number')">Fair Value (Operating)</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('fairValNO', 'number')">Fair Value (Non-Operating)</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('totalFairValue', 'number')">Fair Value (Total)</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('FairValueChange', 'number')"> Change in Fair Value (%)</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('concludedDR', 'number')"> Concluded Discount Rate </th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('evRevenue', 'number')"> Implied EV/Revenue (FY Last Year)</th>
      <th style="text-align: right; cursor: pointer;" (click)="portfolioService.sortPortfolioCompanies('evEbitda', 'number')"> Implied EV/EBITDA (FY Last Year)</th>
    </tr>

    <ng-template ngFor [ngForOf]="portfolioService.searchedForms" let-comp let-rowIndex="index">
      <tr>
        <!-- <td [class.user-added-form]="comp.userEntered">
          <img [src]="comp.logo" width="40" height="40" (error)="comp.logo = null" *ngIf="comp.logo; else defaultLogo">
          <ng-template #defaultLogo>
            <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
          </ng-template>
        </td> -->
        <td>
          <i class="fa fa-user icon-style" (click)="userPopup(userPopUpContent, comp)" aria-hidden="true"></i>
          <span class="p-company-name" [routerLink]="'/qxp-company-valuation/' + comp.id"> {{comp.companyName}} </span>
        </td>

        <td>{{comp.security}}</td>

        <!-- <td>{{comp.investmentDate | date: "MMM, dd yyyy"}}</td> -->

        <td>
          <ng-template [ngIf]="comp.geography">{{comp.geography.name | uppercase}}</ng-template>
        </td>

        <td>{{comp.sector}}</td>

        <td>{{comp.businessUnit}}</td>

        <td> {{ comp.currency }} </td>
        
        <td style="text-align: right;">
          <ng-template [ngIf]="comp.carryingValue != 0">{{comp.carryingValue| number: "1.1-1"}}</ng-template>
        </td>

        <td style="text-align: right;">
          <ng-template [ngIf]="comp.stake != 0">{{comp.stake | number: "1.1-1"}}</ng-template>
        </td>

        <td style="text-align: right;">
          <ng-template [ngIf]="comp.fairVal != 0">{{comp.fairVal| number: "1.1-1"}}</ng-template>
        </td>

        <td style="text-align: right;">
          <ng-template [ngIf]="comp.fairValNO != 0">{{comp.fairValNO | number: "1.1-1"}}</ng-template>
        </td>

        <td style="text-align: right;">
          <ng-template [ngIf]="comp.totalFairValue != 0">{{comp.totalFairValue | number: "1.1-1"}}</ng-template>
        </td>
        
        <td style="text-align: right;">   
          <ng-template [ngIf]="comp.FairValueChange && comp.FairValueChange != 0">{{comp.FairValueChange * 100 | number: "1.1-1"}}%</ng-template>
        </td>
        <td style="text-align: right;"> <ng-template [ngIf]="comp.concludedDR && comp.concludedDR != 0">{{ comp.concludedDR * 100 | number: "1.1-1"}}%</ng-template> </td>
        <td style="text-align: right;"> <ng-template [ngIf]="comp.evRevenue != 0">{{comp.evRevenue | number: "1.1-1"}}</ng-template> </td>
        <td style="text-align: right;"> <ng-template [ngIf]="comp.evEbitda != 0">{{comp.evEbitda | number: "1.1-1"}}</ng-template> </td>
      </tr>
      <!-- <ng-template [ngIf]= "rowIndex+1 == portfolioService.searchedForms.length">
        <tr style="font-weight: bold;">
          <td> </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right;">
            {{ portfolioService.totalPortfolioData.totalStakeVal | number: '1.1-1' }}
          </td>

          <td style="text-align: right;"> 
            {{ portfolioService.totalPortfolioData.totalInvestment | number: '1.1-1'}} 
          </td>
          <td style="text-align: right;"> 
            {{ portfolioService.totalPortfolioData.totalRealisedProceeds | number: '1.1-1'}} 
          </td>
          <td style="text-align: right;"> 
            {{ portfolioService.totalPortfolioData.totalMOIC | number: '1.1-1'}} x
          </td>
          <td style="text-align: right; white-space: nowrap;"> 
            {{ portfolioService.totalPortfolioData.totalIRR * 100| number: '1.1-1'}} %
          </td>
          <td style="text-align: right;">
            {{ portfolioService.totalPortfolioData.totalChangeInValPercentage * 100 | number: '1.1-1' }} %
          </td>
        </tr>
      </ng-template> -->
    </ng-template>
  </table>
</div>

<div *ngIf="pageState == PAGESTATE_EDIT_CH_EXCEL" class="row"  class="row-div">
  <button mat-raised-button class="primary-bg" (click) = "processExcel()">
    Save
  </button>

  <button mat-raised-button class="secondary-bg button-style" (click) = "cancelExcelExit()">
    Cancel
  </button>
</div>

<div [hidden]="pageState != PAGESTATE_EDIT_CH_EXCEL" class="row" class="excel-view" #excelUI>
</div>

<div *ngIf="pageState == PAGESTATE_SHOW_CH_DATA" class="row" class="table table-condensed" class="row-div" id="tableContainer">
  <table class="a">
    <tr class="bg primary-bg">
      <th class="table-head">Legal Entity</th>
      <!-- <th class="table-head">Valuation Done</th>
      <th class="table-head">Operating Value</th>
      <th class="table-head">Non Operating Value</th> -->
      <th class="table-head">Fair Value Without Cross-holding</th>
      <th class="table-head">Cross-holding Investment Value</th>
      <th class="table-head">Fair Value with Cross-holding Investment</th>
    </tr>

    <tr *ngFor="let entity of entityValuationData | keyvalue">
      <td>{{ entity.key }}</td>
    
      <!-- <td *ngIf="entity.value.valuationStatus; else noValuation">Yes</td>
      <ng-template #noValuation>
        <td class="not-ready">No</td>
      </ng-template> 

      <td>{{ entity.value.operatingValue | number: '1.1-1' }}</td>
      <td>{{ entity.value.nonOperatingValue | number: '1.1-1' }}</td> -->
      <td>{{ entity.value.fairValuePreCrossHolding | number: '1.1-1' }}</td>

      <td *ngIf="entity.value.crossHoldingValue; else NA">
        {{ entity.value.crossHoldingValue | number: '1.1-1' }}
      </td>
      <td *ngIf="entity.value.fairValuePostCrossHolding; else NA">
        {{ entity.value.fairValuePostCrossHolding | number: '1.1-1' }}
      </td>
      <ng-template #NA>
        <td></td>
      </ng-template> 
      

    </tr>
  </table>
</div>


<!-- <div *ngIf="pageState == PAGESTATE_SHOW_CH_DATA" class="row" class="chart">
  <hc-network-chart [data]="networkChartData"></hc-network-chart>
</div> -->