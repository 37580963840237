import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "typeOf",
})
export class TypeOfPipe implements PipeTransform {
  constructor() {}

  transform(value): any {
    return typeof(value);
  }
}
