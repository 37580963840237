<div class="drp-wrapper">
  <h4 class="drp-table-header">
    Attributes
    <div class="drp-table-subheader">
      Error Count: <mat-chip color="warn" selected="{{dataSource.data.length > 0 }}">{{dataSource.data.length}}</mat-chip>
    </div>
  </h4>
  <div class="drp-table-wrapper mat-elevation-z1">
    <table mat-table [dataSource]="dataSource" class="drp-table">
      <!-- Column Groups -->
      <ng-container matColumnDef="empty">
        <th mat-header-cell *matHeaderCellDef class="no-border">&nbsp;</th>
      </ng-container>
      <ng-container matColumnDef="globalAttributes">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Global Attributes</th>
      </ng-container>
      <ng-container matColumnDef="customAttributes">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Custom Attributes</th>
      </ng-container>
      <ng-container matColumnDef="attributeFormulae">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Attribute Formulae</th>
      </ng-container>
      <ng-container matColumnDef="formulaDetails">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Formula Details</th>
      </ng-container>
      <ng-container matColumnDef="formulaCompanies">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="column-group">Formula Companies</th>
      </ng-container>

      <!-- Columns -->
      <ng-container matColumnDef="organizationName">
        <th mat-header-cell *matHeaderCellDef class="left-align">Organization</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.organizationName}}</td>
      </ng-container>
      <!-- Global Attributes -->
      <ng-container matColumnDef="gxGlobalAttributesCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxGlobalAttributesCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoGlobalAttributesBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoGlobalAttributesBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoGlobalAttributesFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoGlobalAttributesFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="globalAttributesDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.globalAttributesDelta !== 0 }">
          {{row?.globalAttributesDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxGlobalAttributesLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxGlobalAttributesLastUpdated ? (row?.gxGlobalAttributesLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- Custom Attributes -->
      <ng-container matColumnDef="gxCustomAttributesCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxCustomAttributesCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoCustomAttributesBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoCustomAttributesBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoCustomAttributesFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoCustomAttributesFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="customAttributesDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.customAttributesDelta !== 0 }">
          {{row?.customAttributesDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxCustomAttributesLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxCustomAttributesLastUpdated ? (row?.gxCustomAttributesLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- Attribute Formulae -->
      <ng-container matColumnDef="gxAttributeFormulaCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxAttributeFormulaCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoAttributeFormulaBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoAttributeFormulaBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoAttributeFormulaFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoAttributeFormulaFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="attributeFormulaDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.attributeFormulaDelta !== 0 }">
          {{row?.attributeFormulaDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxAttributeFormulaLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxAttributeFormulaLastUpdated ? (row?.gxAttributeFormulaLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- Formula Details -->
      <ng-container matColumnDef="gxFormulaDetailsCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxFormulaDetailsCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFormulaDetailsBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFormulaDetailsBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFormulaDetailsFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFormulaDetailsFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="formulaDetailsDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.formulaDetailsDelta !== 0 }">
          {{row?.formulaDetailsDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxFormulaDetailsLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxFormulaDetailsLastUpdated ? (row?.gxFormulaDetailsLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>
      <!-- Formula Companies -->
      <ng-container matColumnDef="gxFormulaCompaniesCount">
        <th mat-header-cell *matHeaderCellDef>Gx</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxFormulaCompaniesCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFormulaCompaniesBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFormulaCompaniesBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoFormulaCompaniesFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto<br/>(Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoFormulaCompaniesFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="formulaCompaniesDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.formulaCompaniesDelta !== 0 }">
          {{row?.formulaCompaniesDelta}}
        </td>
      </ng-container>
      <ng-container matColumnDef="gxFormulaCompaniesLastUpdated">
        <th mat-header-cell *matHeaderCellDef>Gx<br/>Last Updated</th>
        <td mat-cell *matCellDef="let row; index as i">
          {{row?.gxFormulaCompaniesLastUpdated ? (row?.gxFormulaCompaniesLastUpdated | date: 'MMM d, y HH:mm:ss') : '-'}}
        </td>
      </ng-container>

      <!-- Loading, Empty & Error rows -->
      <ng-container matColumnDef="loading">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <div class="progress-loader">
            <div class="indeterminate"></div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="nodata">
        <td *matFooterCellDef [colSpan]="displayColumns.length">No delta found.</td>
      </ng-container>
      <ng-container matColumnDef="error">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <mat-icon>error</mat-icon> <span>{{error}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumnGroups"></tr>
      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns" (click)="onRowClick($event,row)"></tr>
      <tr mat-footer-row [hidden]="dataSource?.data?.length > 0 || loading || error" *matFooterRowDef="['nodata']" class="empty-row"></tr>
      <tr mat-footer-row [hidden]="!error" *matFooterRowDef="['error']" class="error-row"></tr>
      <tr mat-footer-row [hidden]="!loading" *matFooterRowDef="['loading']" class="loader-row"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>
</div>
