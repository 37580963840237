import { Component, OnInit, ViewChild } from '@angular/core';
import { EditService, ToolbarService, PageService, IEditCell } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { GridComponent, GroupSettingsModel } from '@syncfusion/ej2-angular-grids';
import { EditSettingsModel, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { DataService } from '../services/data.service';
import { PortFolioService } from '../qubit-x/portfolio/portfolio.service';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { CurrencyExchangeService } from '../services/currency-exchange.service';

import moment from 'moment';

import { Chart } from 'angular-highcharts';

import { cloneDeep } from 'lodash';
import { UtilService } from '../utils/util.service';
import { colors } from '../utils/colors';
import Highcharts from 'highcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { UserManagementService } from '../services/user-management.service';
import { ThemeService } from '../utils/theme.service';

@Component({
  selector: 'app-exit-simulation-v2',
  templateUrl: './exit-simulation-v2.component.html',
  styleUrls: ['./exit-simulation-v2.component.scss'],
  providers: [ToolbarService, EditService, PageService, SortService, FilterService, GroupService]
})
export class ExitSimulationV2Component implements OnInit {

  // exitSimulationV2Key = "EXIT_SIMULATION_V2"

  exitInputData = [];
  sectionHeadingSeries;
  // portfolioService.exitSimulationData : Object[] = [];

  metricMultipleColumn = [];
  serialNoColumn = [];

  selectedCaseLAbel;

  exitSimulationLoaded = false;

  historicalDatesLength = -1;

  selectedCase;
  selectedView = "bar";

  metricAmountColumn = [];
  exitMultipleColumn = [];
  BearishCase = [];
  BaseCase = [];
  BullishCase = [];
  
  chartHistoricalData = {};

  
  aggregatedHistoricalDataExitDateWise = {};

  stakeColumn = [];

  netDebtAtExit = [];

  enterpriseValue = [];
  netDebt = [];

  totalValBearishColumn = []
  totalValBaseColumn = []
  totalValBullishColumn = []

  irrColumn = [];
  moicColumn = [];
  evColumn = [];
  equityColumn = [];
  navColumn = [];
  totalColumn = [];
  
  exitDates: Object;
  companyvalue: Object;
  metricAmount: Object;
  exitMultiple: Object;

  financialData;

  totalSimulationData =  {
    totalEVBearish : 0,
    totalEVBase : 0,
    totalEVBullish : 0,

    totalEquityValueBearish : 0,
    totalEquityValueBase : 0,
    totalEquityValueBullish : 0,

    totalNAVBearish : 0,
    totalNAVBase : 0,
    totalNAVBullish : 0,

    totalValueBearish : 0,
    totalValueBase : 0,
    totalValueBullish : 0,

    totalNetDebtAtExit: 0,
  }

  valuationDates = {
    currentValDates : [],
    prevToLatestValDates : [],
    beforeToPrevValDates : [],

    minCurrentValDate: {} as any,
    minPrevToLatestValDates: {} as any,
    minBeforeToPrevValDates: {} as any,
  }

  public multipleParams : IEditCell;
  public multipleObj : DropDownList;

  numericParams : IEditCell;

  multipleElem : HTMLElement;

  @ViewChild('batchgrid', { static: false }) private inputGridTable: GridComponent;
  @ViewChild('returnDetails', { static: false }) private returnDetailsGridTable: GridComponent;
  @ViewChild('analysisTableGrid', { static: false }) private analysisTableGrid: GridComponent;

  
  showInputGridTable = false;
  showReturnDetailsGridTable = false;

  fieldMap: Object = { text: 'label', value: 'key' };

  multipleType : { [key: string]: Object }[] = [
    {label: 'Revenue', key: 'revenue'},
    {label: 'EBITDA', key: 'ebitda'}
  ];

  summaryChart: Chart;
  sectionHeadingChart: Chart;

  exitSimulationV2Key = "EXIT_SIMULATION_V2";
  exitSimulationData = [];
  exitSimulationChartData = {
    xAxis: [],
    yAxis: {
      column: {
        nav: [],
        realisedProceeds: [],
        investments: [],
        irr: [],
        moic: []
      },
      line: {
        bearishCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
        baseCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
        bullishCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
      }
    }
  }  

  transactions = [];
  allCompanyValuationDates = []
  exchangeRates = {} as any;

  public editSettings: EditSettingsModel;
  public toolbar: ToolbarItems[];
  public groupOptions: GroupSettingsModel;

  trackRecordCompanyWise = {}
  

  constructor(private ds: DataService, 
    public portfolioService: PortFolioSummaryServiceV2,
    public fundService : FundListService, 
    private utilService: UtilService,
    private router: Router,
    private ums : UserManagementService,
    private themeService: ThemeService) { }

  async ngOnInit() {
    const fundId = this.portfolioService.selectedFundId

    if(!fundId) {
      this.router.navigateByUrl('/fundList');
      return;
    }

    this.initColumnDefinition();

    this.multipleParams = {
      create:()=>{
      this.multipleElem = document.createElement('input');
          return this.multipleElem;
      },
      read:()=>{
          return this.multipleObj.text ? this.multipleObj.text : "Revenue";
      },
      destroy:()=>{
          this.multipleObj.destroy();
      },
      write:()=>{
        this.multipleObj = new DropDownList({
        dataSource: this.multipleType,
        fields: { value: 'key', text: 'label' },
        enabled: true ,
        placeholder: 'Select a Multiple',
        // floatLabelType: 'Never'
      });
      this.multipleObj.appendTo(this.multipleElem);
    }};

    await this.loadingDataNeededForAnalysis();

    this.loadExitSimulation();
  }

  async loadingDataNeededForAnalysis(){
    try {
      const apiData : any = await this.ds.getWidgetDataFromDB("ALL_TRANSACTIONS", this.portfolioService.selectedFundId).toPromise();
      this.transactions = apiData.body["response"][0].widgetData;
    } catch (error) {
      console.log("Failed to Read Transactions", error);
    }

    try {
      const userId = this.ums.getSelectedUserDetails().id;
      const apiData : any = await this.ds.getCompanyAndLatestValuationDates(userId, this.portfolioService.selectedFundId).toPromise();
      this.allCompanyValuationDates = apiData.body["response"]
    } catch (error) {
      console.log("Failed to All Companies", error);
    }

    const allFormIds = this.allCompanyValuationDates.map( comp => comp.id );
    try {
      const apiData : any = await this.ds.getInvestmentData(allFormIds).toPromise();
      const investmentAPIData = apiData.body["response"];

      const consolFormIds = []

      if(investmentAPIData) {
        this.allCompanyValuationDates.forEach( comp => {
          comp.details = JSON.parse(comp.details);
          if(investmentAPIData[comp.id]) {
            comp.investment = JSON.parse(investmentAPIData[comp.id]);           
          }

          //Identifying the MBUs
          if(comp.details.businessUnitsNumber > 0 && comp.consolForm == null){
            consolFormIds.push(comp.id);
          }
        })
      }

      // Fetching Consol Summary if MBUs Exists
      if(consolFormIds.length > 0){
        const res : any = await this.ds.getWidgetDatForAllIds("BUSINESS_UNIT_CONSOL_SUMMARY", consolFormIds).toPromise();
        const summary = res.body["response"];

        const ids = Object.keys(summary)

        this.allCompanyValuationDates.forEach(comp => {
          if(ids.indexOf(comp.id) > -1){
            const consolSummary = JSON.parse(summary[comp.id])
            comp.investment = {
              currency: consolSummary.currency,
              enterpriseValue: consolSummary.sumOfTheParts,
              equityValue: {
                weightedEquityValue: consolSummary.weightedEquityValue,
                finalAdjustedEquityVal: consolSummary.finalAdjustedEquityVal,
                stakeValue: consolSummary.stakeValue,
                stake: consolSummary.stake,
                finalStakeValue: consolSummary.finalStakeValue
              }
            }
          }
        })
      }

      this.allCompanyValuationDates = this.allCompanyValuationDates.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      }).filter(f => f.details.status !== "Initiated");
    } catch (error) {
      console.log("Failed to All Companies", error);
    }

    try {
      const reqBody = this.allCompanyValuationDates.map( comp => {
        if(comp.details && comp.details.currency && this.portfolioService.selectedCurrency 
          && comp.details.currency != this.portfolioService.selectedCurrency){
          return {
            from: comp.details.currency,
            to: this.portfolioService.selectedCurrency,
            date: comp.valuationDate
          }
        }
        else{
          return null
        }
      }).filter(comp => comp != null)  

      const data = await this.ds.getCurrencyExchangeRatesByCurrencyLayer(reqBody).toPromise();
      this.exchangeRates = data.body['response']
    } catch (error) {
      console.log("Failed to Fetch currency Exchange rates", error);
    }

    try {
      const apiData : any = await this.ds.getWidgetDataFromDB(this.exitSimulationV2Key, this.portfolioService.selectedFundId).toPromise();
      const widgetData = apiData.body["response"][0].widgetData;
      this.exitSimulationData = widgetData.simulationData ? widgetData.simulationData : [];
      if(widgetData.chartData){
        this.exitSimulationChartData = widgetData.chartData;
      }
      if(this.exitSimulationData) {
        this.exitSimulationData.forEach((row: any) => {
          const comp = this.allCompanyValuationDates.find(comp => comp.id == row.id);
          row["companyName"] = comp && comp.companyName ? comp.companyName : comp.details.companyName

          if(!row.variationPercentage) {
            row.variationPercentage = 10;
          }
        })
      }
    } catch (error) {
      console.log("Failed to Read Exit Simulation", error);
    }

  }

  loadExitSimulation(){
    if(this.exitSimulationData && this.exitSimulationData.length > 0){
      this.showReturnDetailsGridTable = true;

      // Total Aggregate Columns are not Needed 
      // this.calculateTotal();

      this.exitSimulationLoaded = true;

      
      this.prepareSummaryChart();
      this.prepareSectionHeadingChart();

      setTimeout(() => {
        this.refreshTables()
      }, 2000);
    }
    else{
      this.defaultSimulation();
    }
  }

  refreshTables(){
    if(this.inputGridTable){
      this.inputGridTable.refresh()
    }

    if(this.returnDetailsGridTable){
      this.returnDetailsGridTable.refresh();
    }   

    if(this.analysisTableGrid){
      this.analysisTableGrid.refresh()
    }

  }

  getFinancials(){
    const forms = this.getLatestValIdsForAllForms();

    const latestValDateIds = forms.latestValDateIds;
    const invCompForms = forms.invCompForms;

    const reqBody = latestValDateIds.map( form => form.id);

    this.ds.getExitSimulationData(reqBody).subscribe( res => {
      this.financialData = res.body["response"];   
      // console.log("Fetched Financials", this.financialData) 
    }, error => {
      console.log(error)
    })
  }
  
  initColumnDefinition(){
    this.metricMultipleColumn = [this.getColumnDefinition("Metric", "metricMultiple", "center", 100)];
    this.serialNoColumn = [this.getColumnDefinition("S No.", "index", "center")];
    
    // [this.getColumnDefinition("Name of Metric", "metricMultiple")];

    this.metricAmountColumn = [
      this.getColumnDefinition("Bearish Case", "amountBearishCase","right", true, 150),
      this.getColumnDefinition("Base Case", "amountBaseCase","right", true, 150),
      this.getColumnDefinition("Bullish Case", "amountBullishCase","right", true, 150),
    ];
    

    this.exitMultipleColumn = [
      this.getColumnDefinition("Bearish Case", "multipleAtExitBearishCase" ,"right", true, 150),
      this.getColumnDefinition("Base Case", "multipleAtExitBaseCase" ,"right", true, 150),
      this.getColumnDefinition("Bullish Case", "multipleAtExitBullishCase" ,"right", true, 150),
    ]
    
    this.irrColumn = [
      this.getColumnDefinition("Bearish Case", "irrBearishCase","right", true),
      this.getColumnDefinition("Base Case", "irrBaseCase","right", true),
      this.getColumnDefinition("Bullish Case", "irrBullishCase","right", true),
    ]

    this.moicColumn = [
      this.getColumnDefinition("Bearish Case", "moicBearishCase" ,"right", true),
      this.getColumnDefinition("Base Case", "moicBaseCase" ,"right", true),
      this.getColumnDefinition("Bullish Case", "moicBullishCase" ,"right", true),
    ]

    this.evColumn = [
      this.getColumnDefinition("Bearish Case", "eVBearishCase","right", true, 150),
      this.getColumnDefinition("Base Case", "eVBaseCase","right", true, 150),
      this.getColumnDefinition("Bullish Case", "eVBullishCase","right", true, 150),
    ];

    this.netDebt = [
      this.getColumnDefinition("Net Debt", "netDebt" ,"right", true, 150),
    ];

    this.equityColumn = [
      this.getColumnDefinition("Bearish Case", "equityValBearishCase","right", true, 150),
      this.getColumnDefinition("Base Case", "equityValBaseCase","right", true, 150),
      this.getColumnDefinition("Bullish Case", "equityValBullishCase","right", true, 150),
    ];
    
    this.stakeColumn =  [
      this.getColumnDefinition("Stake (%)", "stake", "right", true, 150),
    ]

    this.navColumn = [
      this.getColumnDefinition("Bearish Case", "NAVBearishCase","right", true, 150),
      this.getColumnDefinition("Base Case", "NAVBaseCase","right", true, 150),
      this.getColumnDefinition("Bullish Case", "NAVBullishCase","right", true, 150),
    ];

    this.netDebtAtExit =  [
      this.getColumnDefinition("Debt Value At Exit", "netDebtAtExit","right", true, 150),
    ]

    this.totalColumn = [
      this.getColumnDefinition("Bearish Case", "totalValBearishCase","right", true, 150),
      this.getColumnDefinition("Base Case", "totalValBaseCase","right", true, 150),
      this.getColumnDefinition("Bullish Case", "totalValBullishCase","right", true, 150),
    ];
    
    this.editSettings = { allowEditing: true, allowAdding: false };
    this.toolbar = ['Edit', 'Update', 'Cancel'];
    this.companyvalue = { required: true, };
    this.numericParams = { params: { decimals: 2 } };
    this.exitDates = { required: true, date: true };
    this.metricAmount = { required: true };
    this.exitMultiple = { required: true };
  }

  // actionBegin(event){
  //   console.log(event);
  //   // this.inputGridTable.batchUpdate()
  // }

  // saveBatch(event){
  //   console.log(Event)
  // }

  // selectEvent(event){
  //   console.log(event);
 
  // }

  actionComplete(event){
    // console.log("actionComplete event", event);

    // if(event.requestType == "batchsave"){
    //   this.initExitSimulationCalculation();
    // }
  }

  getColumnDefinition(headerText, columnKey, alignment?, isNumber?, width?){
    const definition = {
      field: columnKey,
      headerText: headerText,
      textAlign: alignment ? alignment : "right",
      width: width ? width : 75,
      minWidth: 10,
    }

    const suffix = columnKey.indexOf("irr") >= 0 ? "%" : "x"

    if(columnKey.indexOf("irr") >= 0 || columnKey.indexOf("moic") >= 0){
      definition['valueAccessor'] = (field: string, data: any, column: object) => {
        return this.utilService.getValidNumber(data[field]).toFixed(2) + suffix;
      }
    }
    else if(isNumber){
      definition["format"] = 'N2';
    }

    return definition
  }

  getLabel(key){
    let label = "";

    switch (key) {
      case "revenue":
        label = "Revenue"
        break;
    
      case "ebitda": 
        label = "EBITDA"
        break;
    }

    return label;
  }

  getSelectedCompanyDates(companyId){
    return this.allCompanyValuationDates.filter(comp => comp.id== companyId || comp.groupFormId == companyId);
  }

  getLatestValIdsForAllForms(){
    const invCompForms = this.allCompanyValuationDates
    .filter(comp => comp.groupFormId == null)
    .map( comp => {
      let valuationDateForms = this.getSelectedCompanyDates(comp.id);
      return {
        id: comp.id, 
        companyName: comp && comp.companyName ? comp.companyName : comp.details.companyName, 
        stake: valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.stake : 0,
        currency: valuationDateForms[0].investment ? valuationDateForms[0].investment.currency : "",
        latestValuationDate: valuationDateForms[0].valuationDate
      }
    })

    let latestValDateForms = invCompForms.map( comp => {
      let valuationDateForms = this.getSelectedCompanyDates(comp.id);
      valuationDateForms = valuationDateForms.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      });
      return valuationDateForms[0];
    })

    // const currentYear = new Date().getFullYear();
    // latestValDateForms = latestValDateForms.filter(form => {
    //   const valYear = new Date(form.valuationDate).getFullYear();
    //   return valYear == currentYear && form.id
    // })

    const latestValDateIds = latestValDateForms.map(form => {
      return {
        id: form.id,
        groupFormId: form.groupFormId,
        valuationDate: form.valuationDate
      }
    })

    return {
      invCompForms: invCompForms,
      latestValDateIds: latestValDateIds
    }

  }

  async defaultSimulation(){
    if(this.allCompanyValuationDates && this.allCompanyValuationDates.length > 0){
      this.exitSimulationData = [];

      const currentDate = new Date(); 
    
      const forms = this.getLatestValIdsForAllForms();

      const latestValDateIds = forms.latestValDateIds;
      let invCompForms = forms.invCompForms;

      invCompForms = invCompForms.sort((comp1, comp2) => comp1.companyName > comp2.companyName ? 1 : -1);

      const reqBody = latestValDateIds.map( form => form.id);
      try {
        const apiData = await this.ds.getExitSimulationData(reqBody).toPromise();
        this.financialData = apiData.body["response"];

        for (let i = 0; i < invCompForms.length; i++) {
          try {
            const index = i;
            const comp = invCompForms[index];
            
            const exitDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + index + 1, 0);

            const multiple = "Revenue"

            const valDateId = latestValDateIds.find(form => {
              return form.groupFormId == comp.id || form.id == comp.id;
            }).id
    
            const metricValueBase = this.getFinancialMetricValue(this.financialData, valDateId, multiple.toLowerCase());
            const metricValueBearish = this.getVariation(metricValueBase, 10).min;
            const metricValueBullish = this.getVariation(metricValueBase, 10).max;

            const currency = comp.currency;
    
            const netDebt = 0;
            const netDebtAtExit = 0;
    
            const multipleAtExitBase = 0;
            const multipleAtExitBearish = this.getVariation(multipleAtExitBase, 10).min;
            const multipleAtExitBullish = this.getVariation(multipleAtExitBase, 10).max;
    
            const eVBearishCase = this.getProduct(metricValueBearish, multipleAtExitBearish);
            const eVBaseCase = this.getProduct(metricValueBase, multipleAtExitBase);
            const eVBullishCase = this.getProduct(metricValueBullish, multipleAtExitBullish);
    
            const equityValBearishCase = this.getEquityValue(netDebt, eVBearishCase);
            const equityValBaseCase = this.getEquityValue(netDebt, eVBaseCase);
            const equityValBullishCase = this.getEquityValue(netDebt, eVBullishCase);
    
            const NAVBearishCase = this.getProduct(comp.stake/100, equityValBearishCase);
            const NAVBaseCase = this.getProduct(comp.stake/100, equityValBaseCase);
            const NAVBullishCase = this.getProduct(comp.stake/100, equityValBullishCase);
    
            const totalValBearishCase = this.getSum(NAVBearishCase, netDebtAtExit);
            const totalValBaseCase = this.getSum(NAVBaseCase, netDebtAtExit);
            const totalValBullishCase = this.getSum(NAVBullishCase, netDebtAtExit);
    
            const transactionData = await this.getTransactionsForCompany(comp.id);

            const multipleRealisedProceeds = transactionData.multipleRealisedProceeds;
            const multipleInvestments = transactionData.multipleInvestments;
            const totalInvestmentAmount = transactionData.totalInvestmentAmount;
            const totalRealisedProceeds = transactionData.totalRealisedProceeds;

            const reqBodyForBase = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, totalValBaseCase, comp.id, comp.latestValuationDate)
            const reqBodyForBearish = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, totalValBearishCase, comp.id, comp.latestValuationDate)
            const reqBodyForBullish = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, totalValBullishCase, comp.id, comp.latestValuationDate)
    
            const moicBearishCase = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, totalValBearishCase);
            const moicBaseCase = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, totalValBaseCase);
            const moicBullishCase = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, totalValBullishCase);
    
            const irrBearishCase = await this.getIRR(reqBodyForBearish);
            const irrBaseCase = await this.getIRR(reqBodyForBase);
            const irrBullishCase = await this.getIRR(reqBodyForBullish);
    
            const exitMultipleData =  {
              index: index,
              id: comp.id,
              companyName: comp.companyName,
              exitDate: exitDate,
              metricMultiple: multiple,
              amountBearishCase: metricValueBearish,
              amountBaseCase: metricValueBase,
              amountBullishCase: metricValueBullish,
              multipleAtExitBearishCase: multipleAtExitBearish,
              multipleAtExitBaseCase: multipleAtExitBase,
              multipleAtExitBullishCase: multipleAtExitBullish,
              eVBearishCase: eVBearishCase,
              eVBaseCase: eVBaseCase,
              eVBullishCase: eVBullishCase,
              netDebt: netDebt,
              equityValBearishCase: equityValBearishCase,
              equityValBaseCase: equityValBaseCase,
              equityValBullishCase: equityValBullishCase,
              stake: comp.stake,
              NAVBearishCase: NAVBearishCase,
              NAVBaseCase: NAVBaseCase,
              NAVBullishCase: NAVBullishCase,
              netDebtAtExit: netDebtAtExit, // Adj To Stake Value
              totalValBearishCase: totalValBearishCase,
              totalValBaseCase: totalValBaseCase,
              totalValBullishCase: totalValBullishCase,
              irrBearishCase: irrBearishCase,
              irrBaseCase: irrBaseCase,
              irrBullishCase: irrBullishCase,
              moicBearishCase: moicBearishCase,
              moicBaseCase: moicBaseCase,
              moicBullishCase: moicBullishCase,
              variationPercentage: 10,
              currency: currency
            }
    
            this.exitSimulationData.push(exitMultipleData);

            if(this.inputGridTable){
              this.inputGridTable.refresh()
            }
          } catch (error) {
            console.log(error)
          }
        }

        if(this.inputGridTable){
          this.inputGridTable.refresh()
        }

        this.showReturnDetailsGridTable = true;

        // Total Aggregate Columns are not Needed 
        // this.calculateTotal();

        setTimeout(() => {
          if(this.returnDetailsGridTable){
            this.returnDetailsGridTable.refresh();
          }   

          if(this.analysisTableGrid){
            this.analysisTableGrid.refresh();
          }
        }, 2000);
        // console.log("EXIT", this.exitSimulationData)

        this.prepareSimulationChartData();

      } catch (error) {
        console.log(error)
      }
    }
  }

  calculateTotal(){
    const total = {
      totalEVBearish : 0,
      totalEVBase : 0,
      totalEVBullish : 0,

      totalEquityValueBearish : 0,
      totalEquityValueBase : 0,
      totalEquityValueBullish : 0,

      totalNAVBearish : 0,
      totalNAVBase : 0,
      totalNAVBullish : 0,

      totalValueBearish : 0,
      totalValueBase : 0,
      totalValueBullish : 0,

      totalNetDebtAtExit: 0,
    }

    this.exitSimulationData.forEach( (data : any)=> {
      total.totalEVBearish += (+data.eVBearishCase)
      total.totalEVBase += (+data.eVBaseCase)
      total.totalEVBullish += (+data.eVBullishCase)

      total.totalEquityValueBearish += (+data.equityValBearishCase)
      total.totalEquityValueBase += (+data.equityValBaseCase)
      total.totalEquityValueBullish += (+data.equityValBullishCase)

      total.totalNAVBearish += (+data.NAVBearishCase)
      total.totalNAVBase += (+data.NAVBaseCase)
      total.totalNAVBullish += (+data.NAVBullishCase)

      total.totalValueBearish += (+data.totalValBearishCase)
      total.totalValueBase += (+data.totalValBaseCase)
      total.totalValueBullish += (+data.totalValBullishCase)
      
      total.totalNetDebtAtExit += (+data.netDebtAtExit)
    })

    this.totalSimulationData = total;
  }


  prepareSimulationChartData(){

    //Re-initializing
    this.valuationDates = {
      currentValDates : [] as any,
      prevToLatestValDates : [],
      beforeToPrevValDates : [],
  
      minCurrentValDate: {},
      minPrevToLatestValDates: {},
      minBeforeToPrevValDates: {},
    }

    this.exitSimulationData.forEach( comp => {
      let valuationDateForms = this.getSelectedCompanyDates(comp.id)
      
      if(valuationDateForms.length > 1){
        valuationDateForms = valuationDateForms.sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
        }).filter(f => f.status !== "Initiated" && !f.userEntered);
      }

      if(valuationDateForms.length >= 3){

        this.valuationDates.currentValDates.push(valuationDateForms[0])

        this.valuationDates.prevToLatestValDates.push(valuationDateForms[1]);

        this.valuationDates.beforeToPrevValDates.push(valuationDateForms[2]);
      }
      else if(valuationDateForms.length >= 2){
        this.valuationDates.currentValDates.push(valuationDateForms[0])

        this.valuationDates.prevToLatestValDates.push(valuationDateForms[1]);

        // this.valuationDates.beforeToPrevValDates.push(null);
      }
      else if(valuationDateForms.length == 1) {
        this.valuationDates.currentValDates.push(valuationDateForms[0])

        // this.valuationDates.prevToLatestValDates.push(null);

        // this.valuationDates.beforeToPrevValDates.push(null);
      }
    })

    const beforeToPrevValDateExits = this.valuationDates.beforeToPrevValDates.filter( data => data);
    const beforeToPrevValDateLength = beforeToPrevValDateExits.length;

    const prevToLatestValDateExits = this.valuationDates.prevToLatestValDates.filter( data => data);
    const prevToLatestValDateLength = prevToLatestValDateExits.length;

    const currentValDateExits = this.valuationDates.currentValDates.filter( data => data);
    const currentValDateLength = currentValDateExits.length;

    if(beforeToPrevValDateLength > 0){
      this.historicalDatesLength = 3
    }
    else if(prevToLatestValDateLength > 0){
      this.historicalDatesLength = 2
    }
    else if(currentValDateLength > 0){
      this.historicalDatesLength = 1
    }

    // sorting dates in ascending to capture the min date
    if(this.historicalDatesLength >= 2){
      this.valuationDates.currentValDates = this.valuationDates.currentValDates.sort((f1, f2) => {
        if(f1 && f2) {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return this.utilService.areTwoDatesEqual(f1Date, f2Date) ? 0: f1Date < f2Date? -1: 1;
        } else {
          return 0;
        }
      }).filter(f => f.status !== "Initiated" && !f.userEntered);
  
      this.valuationDates.prevToLatestValDates = this.valuationDates.prevToLatestValDates.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return this.utilService.areTwoDatesEqual(f1Date, f2Date)? 0: f1Date < f2Date? -1: 1;
      }).filter(f => f.status !== "Initiated" && !f.userEntered);
  
      this.valuationDates.beforeToPrevValDates = this.valuationDates.beforeToPrevValDates.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return this.utilService.areTwoDatesEqual(f1Date, f2Date)? 0: f1Date < f2Date? -1: 1;
      }).filter(f => f.status !== "Initiated" && !f.userEntered);
  
  
      this.valuationDates.minCurrentValDate = this.valuationDates.currentValDates[0];
      this.valuationDates.minPrevToLatestValDates = this.valuationDates.prevToLatestValDates[0];
      this.valuationDates.minBeforeToPrevValDates = this.valuationDates.beforeToPrevValDates[0];
  
      // console.log(this.valuationDates);
  
      this.prepareHistoricalData(this.valuationDates.minBeforeToPrevValDates);
      this.prepareHistoricalData(this.valuationDates.minPrevToLatestValDates);
      this.prepareHistoricalData(this.valuationDates.minCurrentValDate);
  
      this.prepareActualChartData();
    }

  }

  getAllValuationDatesForTheCompany(companyId){
    const valDates = [];
    const comp = this.allCompanyValuationDates.find(comp => comp.id === companyId);
    if(comp){
      valDates.push({
        id: comp.latestValuationDateId,
        groupFormId: comp.id,
        valuationDate: comp.latestValuationDate,
        status: "Submitted",
      })

      valDates.push({
        id: comp.id,
        groupFormId: null,
        valuationDate: comp.investmentDate,
        status: "Submitted"
      })
    }

    return valDates; //val Dates are in Descending order, Only 2dates
  }

  prepareSummaryChart() {
    const allData = this.exitSimulationChartData;
    console.log("this.exitSimulationChartData", this.exitSimulationChartData);
    const __this = this;

    this.summaryChart = new Chart({
      legend: { enabled: true },
      title: { text: '' },
      credits: { enabled: false },
      yAxis: {title: { text: "Exit Value (" + this.portfolioService.selectedCurrency + " Mn)"} },

      tooltip: { 
        enabled: true, 
          
        formatter: function() {
          if(this.series.name === "NAV") {
            const xIndex = allData.xAxis.findIndex(x => x == this.x);
            return "NAV: " 
                + __this.utilService.getDisplayFormattedNumber(this.y, 1, "", "NA", true) 
                + "<br>Realised Proceeds: " 
                + __this.utilService.getDisplayFormattedNumber(allData.yAxis.column.realisedProceeds[xIndex], 1, "", "NA", true) 
          } else {
            return this.series.name + ": " + __this.utilService.getDisplayFormattedNumber(this.y, 1, "", "NA", true)
          }
        }
      },
      exporting: { enabled: false },

      xAxis: {
        categories: allData.xAxis
      },
      plotOptions: {
        column: { 
          stacking: "normal",

          dataLabels: {
            enabled: true,
            
            inside: false,

            formatter:function() {              
              if(this.series.name == "NAV") {
                const xIndex = allData.xAxis.findIndex(x => x == this.x);

                const irr = allData.yAxis.column.irr[xIndex];
                const moic = allData.yAxis.column.moic[xIndex];

                return "<b>MOIC : </b> " 
                    + __this.utilService.getDisplayFormattedNumber(moic, 1, "x", "NA", true) 
                    + "<br><b>IRR : </b>" 
                    + __this.utilService.getDisplayFormattedNumber(irr, 1, "%", "NA", true)
              }
            }
          }
        },    
      },
      series: [{
        name: "NAV",
        type: "column",
        data: allData.yAxis.column.nav
      },{
        name: "Realised Proceeds",
        type: "column",
        data: allData.yAxis.column.realisedProceeds
      },{
        name: "Bearish Case",
        type: "line",
        data: allData.yAxis.line.bearishCase.nav.map((nav, i) => nav != null ? (nav + allData.yAxis.line.bearishCase.realisedProceeds[i]) : null),
        events: {
          click: (e) => {
            this.prepareSectionHeadingChartSeries("bearishCase", "Bearish Case");
          }
        }
      },{
        name: "Base Case",
        type: "line",
        data: allData.yAxis.line.baseCase.nav.map((nav, i) => nav != null ? (nav + allData.yAxis.line.baseCase.realisedProceeds[i]) : null),
        events: {
          click: (e) => {
            this.prepareSectionHeadingChartSeries("baseCase", "Base Case");
          }
        }
      },{
        name: "Bullish Case",
        type: "line",
        data: allData.yAxis.line.bullishCase.nav.map((nav, i) => nav != null ? (nav + allData.yAxis.line.bullishCase.realisedProceeds[i]) : null),
        events: {
          click: (e) => {
            this.prepareSectionHeadingChartSeries("bullishCase", "Bullish Case");
          }
        }
      }]
    })

    this.summaryChart.ref$.subscribe(ch => {
      // Find out number of non-null values in NAV 
      // (number of past valuation dates getting plotted)  

      const pointIndex = allData.yAxis.column.nav.length - 1;   

      this.addLabelToChart(ch, allData.yAxis.line.bearishCase, 2, pointIndex, 20);

      this.addLabelToChart(ch, allData.yAxis.line.baseCase, 3, pointIndex, 0);

      this.addLabelToChart(ch, allData.yAxis.line.bullishCase, 4, pointIndex, -20);
    })
  }

  prepareSectionHeadingChartSeries(selectedCase, selectedCaseLAbel){

    this.selectedCaseLAbel = selectedCaseLAbel;
    this.selectedCase = selectedCase;
    this.sectionHeadingSeries = [];
    
    let columnInvestments = this.exitSimulationChartData.yAxis.column.nav.filter(value => { return value!=null });
    let clickedLineData = this.exitSimulationChartData.yAxis.line[selectedCase].nav.map((nav, i) => nav != null ? (nav +  this.exitSimulationChartData.yAxis.line[selectedCase].realisedProceeds[i]) : null)
    
    clickedLineData = clickedLineData.filter(value => { return value!=null });
   
    clickedLineData.shift();
    
    console.log("clickedLineData", clickedLineData);

    let obj = {
      name: 'Historical',
      color: this.themeService.primaryColor,
      data: []
    }

    columnInvestments.forEach((value, index) => {
      obj.data.push({y: value, color: this.themeService.primaryColor});
    });
    
    clickedLineData.forEach(value => {
      obj.data.push({y: value, color: "#E3E1F6"});
    })

    this.sectionHeadingSeries.push(obj);

    obj = {
      name: 'Projected',
      color: "#E3E1F6",
      data: []
    }

    columnInvestments.forEach((value, index) => {
      obj.data.push({y: value, color: this.themeService.primaryColor});
    });
    
    clickedLineData.forEach(value => {
      obj.data.push({y: value, color: "#E3E1F6"});
    })

    this.sectionHeadingSeries.push(obj);

    this.sectionHeadingChart.removeSeries(0);
    this.sectionHeadingChart.removeSeries(0);

    // this.sectionHeadingSeries.splice(columnInvestments.length, 1);

    // console.log("New data: ", this.sectionHeadingSeries)

    this.sectionHeadingChart.addSeries(this.sectionHeadingSeries[0], true, true);
    this.sectionHeadingChart.addSeries(this.sectionHeadingSeries[1], true, true);
    
    this.sectionHeadingChart.ref.redraw();
  }

  prepareSectionHeadingChart() {
    const allData = this.exitSimulationChartData;

    const __this = this;

    this.sectionHeadingChart = new Chart({
      chart: { type: 'column', },
      title: { text: '' },
      
      xAxis: {
        categories: allData.xAxis,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: { text: "Exit Value (" + this.portfolioService.selectedCurrency + " Mn)"} ,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          borderWidth: 0,
          pointWidth: 90,

          dataLabels: {
            enabled: true,
            
            inside: false,

            formatter:function() {              
              if(this.series.name == "Projected") {
                const xIndex = allData.xAxis.findIndex(x => x == this.x);

                let irr = allData.yAxis.line[__this.selectedCase].irr;
                let moic = allData.yAxis.line[__this.selectedCase].moic;

                if(allData.yAxis.column.irr[xIndex]) {
                  irr = allData.yAxis.column.irr[xIndex];
                  moic = allData.yAxis.column.moic[xIndex];
                }

                return "<p>MOIC : </p>" 
                    + __this.utilService.getDisplayFormattedNumber(moic, 1, "x", "NA", true) 
                    + "<br><p>IRR : </p>" 
                    + __this.utilService.getDisplayFormattedNumber(irr, 1, "%", "NA", true)
              }
            }
          }
        },
      },
      series: this.sectionHeadingSeries as any,
    })
  }

  addLabelToChart(ch, seriesCase, seriesIndex, pointIndex, yPadding) { 
    const x = ch.series[seriesIndex].points[pointIndex]["plotX"] + ch.plotLeft - 80;
    const y = ch.series[seriesIndex].points[pointIndex]["plotY"] + ch.plotTop + yPadding;

    ch.renderer.text("<b>MOIC : </b> " + this.utilService.getDisplayFormattedNumber(seriesCase.moic, 1, "x", "NA", true) 
      + "<br><b>IRR : </b>" + this.utilService.getDisplayFormattedNumber(seriesCase.irr, 1, "%", "NA", true), x, y, true)

    .css({ color: colors.primaryColor }) // ch.series[seriesIndex].color
    .add()
    .toFront();
  }

  async prepareActualChartData(){
    if(!this.aggregatedHistoricalDataExitDateWise)
      this.aggregatedHistoricalDataExitDateWise = {};

    this.exitSimulationChartData.xAxis = [];

    //Before to Prev

    const beforeToPrevValDate = (moment(this.valuationDates.minBeforeToPrevValDates.valuationDate)).format("YYYY-MM-DD")
    this.exitSimulationChartData.xAxis.push(beforeToPrevValDate)
    this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate] = this.getAggregationsForHistorical(beforeToPrevValDate)

    //MOIC Calculation
    this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].moic = this.getMoic(this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].totalInvestmentAmount, 
            this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].totalRealisedProceeds, 
            this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].totalStakeValue)

    const beforeReqBodyIRR = this.getRequestBodyCases(this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].multipleRealisedProceeds,
      this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].multipleInvestments,
      this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].totalStakeValue, (new Date()).getTime(), beforeToPrevValDate)
    
      this.aggregatedHistoricalDataExitDateWise[beforeToPrevValDate].irr = await this.getIRR(beforeReqBodyIRR);  


    //Prev to latest  
    const prevToLatestDate = (moment(this.valuationDates.minPrevToLatestValDates.valuationDate)).format("YYYY-MM-DD")
    this.exitSimulationChartData.xAxis.push(prevToLatestDate)
    this.aggregatedHistoricalDataExitDateWise[prevToLatestDate] = this.getAggregationsForHistorical(prevToLatestDate)

    //MOIC Calculation
    this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].moic = this.getMoic(this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].totalInvestmentAmount, 
      this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].totalRealisedProceeds, 
      this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].totalStakeValue)

    const prevReqBodyIRR = this.getRequestBodyCases(this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].multipleRealisedProceeds,
      this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].multipleInvestments,
      this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].totalStakeValue, (new Date()).getTime(), prevToLatestDate)  

    this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].irr = await this.getIRR(prevReqBodyIRR);  



    //Current
    const currentValDate = (moment(this.valuationDates.minCurrentValDate.valuationDate)).format("YYYY-MM-DD")
    this.exitSimulationChartData.xAxis.push(currentValDate)
    this.aggregatedHistoricalDataExitDateWise[currentValDate] = this.getAggregationsForHistorical(currentValDate)

    //MOIC Calculation
    this.aggregatedHistoricalDataExitDateWise[currentValDate].moic = this.getMoic(this.aggregatedHistoricalDataExitDateWise[currentValDate].totalInvestmentAmount, 
      this.aggregatedHistoricalDataExitDateWise[currentValDate].totalRealisedProceeds, 
      this.aggregatedHistoricalDataExitDateWise[currentValDate].totalStakeValue)

    const currentReqBodyIRR = this.getRequestBodyCases(this.aggregatedHistoricalDataExitDateWise[currentValDate].multipleRealisedProceeds,
      this.aggregatedHistoricalDataExitDateWise[currentValDate].multipleInvestments,
      this.aggregatedHistoricalDataExitDateWise[currentValDate].totalStakeValue, (new Date()).getTime(), currentValDate)  

    this.aggregatedHistoricalDataExitDateWise[currentValDate].irr = await this.getIRR(currentReqBodyIRR);  


    let sortedTableData = cloneDeep(this.exitSimulationData)

    sortedTableData = sortedTableData.sort((obj1: any, obj2: any) => {
      const date1 = new Date(obj1.exitDate);
      const date2 = new Date(obj2.exitDate);
      if (date1 > date2) {return 1;}
      if (date1 < date2) {return -1;}
      return 0;
    });

    sortedTableData.forEach((data : any) => {
      const exitDate = (moment(data.exitDate)).format("YYYY-MM-DD")
      this.exitSimulationChartData.xAxis.push(exitDate)
    })

    // this.exitSimulationChartData.xAxis.forEach( (exitDate, index) => {
      
    // })

    for (let index = 0; index < this.exitSimulationChartData.xAxis.length; index++) {
      const exitDate = this.exitSimulationChartData.xAxis[index];

      if(!this.aggregatedHistoricalDataExitDateWise[exitDate]){
        this.aggregatedHistoricalDataExitDateWise[exitDate] = {};
      }

      if(index > this.historicalDatesLength - 1){

        // no of cases are only 3
        for (let caseIndex = 0; caseIndex < 3; caseIndex++) {          
          if(caseIndex == 0){
            this.aggregatedHistoricalDataExitDateWise[exitDate]["baseCase"] = await this.getAggregationsForExitDate(exitDate, this.exitSimulationChartData.xAxis[index - 1], "baseCase", index, this.exitSimulationChartData.xAxis.length - 1);
          }
          else if(caseIndex == 1){
            this.aggregatedHistoricalDataExitDateWise[exitDate]["bearishCase"] = await this.getAggregationsForExitDate(exitDate, this.exitSimulationChartData.xAxis[index - 1], "bearishCase", index, this.exitSimulationChartData.xAxis.length - 1);
          }
          else{
            this.aggregatedHistoricalDataExitDateWise[exitDate]["bullishCase"] = await this.getAggregationsForExitDate(exitDate, this.exitSimulationChartData.xAxis[index - 1], "bullishCase", index, this.exitSimulationChartData.xAxis.length - 1);
          }
          
        }
      }
    }

    this.exitSimulationChartData.yAxis.column.investments = this.exitSimulationChartData.xAxis.map( exitDate => {
      if(this.aggregatedHistoricalDataExitDateWise[exitDate] && this.aggregatedHistoricalDataExitDateWise[exitDate].historical){
        return this.aggregatedHistoricalDataExitDateWise[exitDate].totalInvestmentAmount ? this.aggregatedHistoricalDataExitDateWise[exitDate].totalInvestmentAmount : 0;
      }
      else{
         return null;
      }
    })

    this.exitSimulationChartData.yAxis.column.nav = this.exitSimulationChartData.xAxis.map( exitDate => {
      if(this.aggregatedHistoricalDataExitDateWise[exitDate] && this.aggregatedHistoricalDataExitDateWise[exitDate].historical){
        return this.aggregatedHistoricalDataExitDateWise[exitDate].totalStakeValue ? this.aggregatedHistoricalDataExitDateWise[exitDate].totalStakeValue : 0;
      }
      else{
         return null;
      }
    })

    this.exitSimulationChartData.yAxis.column.realisedProceeds = this.exitSimulationChartData.xAxis.map( exitDate => {
      if(this.aggregatedHistoricalDataExitDateWise[exitDate] && this.aggregatedHistoricalDataExitDateWise[exitDate].historical){
        return this.aggregatedHistoricalDataExitDateWise[exitDate].totalRealisedProceeds ? this.aggregatedHistoricalDataExitDateWise[exitDate].totalRealisedProceeds : 0;
      }
      else{
         return null;
      }
    })

    this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].irr

    this.exitSimulationChartData.yAxis.column.irr = this.exitSimulationChartData.xAxis.map( exitDate => {
      if(this.aggregatedHistoricalDataExitDateWise[exitDate] && this.aggregatedHistoricalDataExitDateWise[exitDate].historical){
        return this.aggregatedHistoricalDataExitDateWise[exitDate].irr ? this.aggregatedHistoricalDataExitDateWise[exitDate].irr : 0;
      }
      else{
         return null;
      }
    })

    this.aggregatedHistoricalDataExitDateWise[prevToLatestDate].irr

    this.exitSimulationChartData.yAxis.column.moic = this.exitSimulationChartData.xAxis.map( exitDate => {
      if(this.aggregatedHistoricalDataExitDateWise[exitDate] && this.aggregatedHistoricalDataExitDateWise[exitDate].historical){
        return this.aggregatedHistoricalDataExitDateWise[exitDate].moic ? this.aggregatedHistoricalDataExitDateWise[exitDate].moic : 0;
      }
      else{
         return null;
      }
    })

    this.exitSimulationChartData.yAxis.line.baseCase.nav = this.getDataPointsForCase("baseCase", "totalValue");
    this.exitSimulationChartData.yAxis.line.bearishCase.nav = this.getDataPointsForCase("bearishCase", "totalValue");
    this.exitSimulationChartData.yAxis.line.bullishCase.nav = this.getDataPointsForCase("bullishCase", "totalValue");

    this.exitSimulationChartData.yAxis.line.baseCase.realisedProceeds = this.getDataPointsForCase("baseCase", "totalRealisedProceeds");
    this.exitSimulationChartData.yAxis.line.bearishCase.realisedProceeds = this.getDataPointsForCase("bearishCase", "totalRealisedProceeds");
    this.exitSimulationChartData.yAxis.line.bullishCase.realisedProceeds = this.getDataPointsForCase("bullishCase", "totalRealisedProceeds");

    this.exitSimulationChartData.yAxis.line.baseCase.investments = this.getDataPointsForCase("baseCase", "totalInvestmentAmount");
    this.exitSimulationChartData.yAxis.line.bearishCase.investments = this.getDataPointsForCase("bearishCase", "totalInvestmentAmount");
    this.exitSimulationChartData.yAxis.line.bullishCase.investments = this.getDataPointsForCase("bullishCase", "totalInvestmentAmount");

    this.exitSimulationChartData.yAxis.line.baseCase.irr = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["baseCase"].irr;
    this.exitSimulationChartData.yAxis.line.bearishCase.irr = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["bearishCase"].irr
    this.exitSimulationChartData.yAxis.line.bullishCase.irr = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["bullishCase"].irr

    this.exitSimulationChartData.yAxis.line.baseCase.moic = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["baseCase"].moic;
    this.exitSimulationChartData.yAxis.line.bearishCase.moic = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["bearishCase"].moic
    this.exitSimulationChartData.yAxis.line.bullishCase.moic = this.aggregatedHistoricalDataExitDateWise[this.exitSimulationChartData.xAxis[this.exitSimulationChartData.xAxis.length - 1]]["bullishCase"].moic


    // console.log("aggregatedHistoricalDataExitDateWise", this.aggregatedHistoricalDataExitDateWise)
    // console.log(sortedTableData)
    // console.log(" chartData ", this.exitSimulationChartData)

    this.exitSimulationLoaded = true;

    this.prepareSummaryChart();
    this.prepareSectionHeadingChart();

    this.saveExitSimulationData();

    setTimeout(() => {
      this.refreshTables()
    }, 2000);
  }

  getDataPointsForCase(caseName, parameter){

    const dataPoints = this.exitSimulationChartData.xAxis.map( (exitDate, index) => {
      if(index == this.historicalDatesLength - 1){
        return this.aggregatedHistoricalDataExitDateWise[exitDate][parameter] ? this.aggregatedHistoricalDataExitDateWise[exitDate][parameter] : 0
      }
      else if(index < this.historicalDatesLength - 1){
        return null
      }
      else{
        return this.aggregatedHistoricalDataExitDateWise[exitDate] 
        && this.aggregatedHistoricalDataExitDateWise[exitDate][caseName]
        && this.aggregatedHistoricalDataExitDateWise[exitDate][caseName][parameter]
        ? this.aggregatedHistoricalDataExitDateWise[exitDate][caseName][parameter] : 0
      }
    })

    return dataPoints;
  }

  async getAggregationsForExitDate(exitDate, prevExitDate, caseName, currentIndex, lastIndex){
    const aggregations = {
      totalValue: 0,
      totalRealisedProceeds: 0,
      totalInvestmentAmount: 0,
      totalStakeValue: 0, 
      irr: 0,
      moic: 0,
      companiesInvolved: [],
      companiesExcluded: [],
      multipleInvestments: [],
      multipleRealisedProceeds: []
    }

    if(currentIndex == lastIndex){
      // console.log("Last Iteratuin")
    }

    let exitDateTotalValue = 0

    this.exitSimulationData.forEach( async (data: any, index) => {
      const exitDateOfComp = new Date(data.exitDate)
      const rowExitDate = new Date(exitDate);

      if(this.utilService.areTwoDatesEqual(exitDateOfComp, rowExitDate)){
        const tempValue = caseName == "baseCase" ? +data.totalValBaseCase : caseName == "bearishCase" ? +data.totalValBearishCase : +data.totalValBullishCase;

        exitDateTotalValue = +tempValue;
        aggregations.companiesExcluded.push({id: data.id, companyName: data.companyName})

      }
      else if(exitDateOfComp < rowExitDate){

      } else if(exitDateOfComp > rowExitDate) {
        const tempValue = caseName == "baseCase" ? +data.totalValBaseCase : caseName == "bearishCase" ? +data.totalValBearishCase : +data.totalValBullishCase;

        aggregations.totalValue += +tempValue
        
        aggregations.companiesInvolved.push({id: data.id, companyName: data.companyName})

        // aggregations.multipleRealisedProceeds = aggregations.multipleRealisedProceeds.concat(multipleRealisedProceeds)

        // aggregations.totalRealisedProceeds += +data.totalRealisedProceeds
        // aggregations.totalInvestmentAmount += +data.totalInvestmentAmount
        // aggregations.totalStakeValue += +data.stakeValue
        // aggregations.multipleInvestments = aggregations.multipleInvestments.concat(data.multipleInvestments)
        // aggregations.multipleRealisedProceeds = aggregations.multipleRealisedProceeds.concat(data.multipleRealisedProceeds)
      }

      const transactionData = await this.getTransactionsForCompany(data.id);

      const multipleRealisedProceeds = transactionData.multipleRealisedProceeds;
      const multipleInvestments = transactionData.multipleInvestments;
      const totalInvestmentAmount = transactionData.totalInvestmentAmount;
      const totalRealisedProceeds = transactionData.totalRealisedProceeds;

      aggregations.multipleInvestments = aggregations.multipleInvestments.concat(multipleInvestments)
      aggregations.totalInvestmentAmount = totalInvestmentAmount

    })

    if(this.aggregatedHistoricalDataExitDateWise[prevExitDate] && this.aggregatedHistoricalDataExitDateWise[prevExitDate][caseName]){
      aggregations.totalRealisedProceeds = this.aggregatedHistoricalDataExitDateWise[prevExitDate][caseName].totalRealisedProceeds 
      ? this.aggregatedHistoricalDataExitDateWise[prevExitDate][caseName].totalRealisedProceeds + exitDateTotalValue 
      : exitDateTotalValue
    }
    else if(this.aggregatedHistoricalDataExitDateWise[prevExitDate]){
      aggregations.totalRealisedProceeds = this.aggregatedHistoricalDataExitDateWise[prevExitDate].totalRealisedProceeds ? this.aggregatedHistoricalDataExitDateWise[prevExitDate].totalRealisedProceeds + exitDateTotalValue : exitDateTotalValue
    }

    // aggregations.totalRealisedProceeds = this.aggregatedHistoricalDataExitDateWise[prevExitDate] && this.aggregatedHistoricalDataExitDateWise[prevExitDate][caseName]
    // ? (this.aggregatedHistoricalDataExitDateWise[prevExitDate][caseName].totalRealisedProceeds + exitDateTotalValue) : (this.aggregatedHistoricalDataExitDateWise[prevExitDate].totalRealisedProceeds + exitDateTotalValue);

    if(currentIndex == lastIndex){

      aggregations.moic = aggregations.totalInvestmentAmount > 0 ? aggregations.totalRealisedProceeds/aggregations.totalInvestmentAmount : 0

      const rp = [{date: exitDate, amount: aggregations.totalRealisedProceeds}];
      const inv = aggregations.multipleInvestments.map( data => {
        return {
          date: data.date,
          amount: data.amount * -1
        }
      })
      const sortedMultiples =  rp.concat(inv).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
  
      const reqBody = {
        data: sortedMultiples.map( data => {
          return { date: (moment(data.date)).format("DD/MM/YY"),amount: data.amount }
        }),
        request_id: new Date().getTime()
      }
  
      let IRR = 0;
      try {
        const apiResponse = await this.ds.getIRR(reqBody).toPromise();
        IRR = apiResponse.body["response"].data.complete_processed_data;      
        // console.log("IRR RESPONSE", portfolioComp.companyName, apiResponse.body["response"].data.complete_processed_data);
  
      } catch(e) {
        console.log("Failed to calculated IRR for -");
      }
  
      IRR = IRR ? IRR * 100 : 0;

      aggregations.irr = IRR;
    }

    return aggregations;
  }

  getAggregationsForHistorical(valuationDate){
    const aggregations = {
      totalValue: 0,
      totalRealisedProceeds: 0,
      totalInvestmentAmount: 0,
      totalStakeValue: 0,
      bearishCase: 0, 
      baseCase: 0, 
      historical: true,
      bullishCase: 0, 
      multipleInvestments: [],
      multipleRealisedProceeds: []
    }

    if(this.chartHistoricalData[valuationDate] && this.chartHistoricalData[valuationDate].length > 0){
      this.chartHistoricalData[valuationDate].forEach( data => {
        aggregations.totalValue += +data.totalValue
        aggregations.totalRealisedProceeds += +data.totalRealisedProceeds
        aggregations.totalInvestmentAmount += +data.totalInvestmentAmount
        aggregations.totalStakeValue += +data.stakeValue
        aggregations.multipleInvestments = aggregations.multipleInvestments.concat(data.multipleInvestments)
        aggregations.multipleRealisedProceeds = aggregations.multipleRealisedProceeds.concat(data.multipleRealisedProceeds)
      })
    }

    return aggregations;
  }

  prepareHistoricalData(form){

    if(form && form.valuationDate){
      this.chartHistoricalData[form.valuationDate] = []
    
      this.exitSimulationData.forEach( async (data: any) => {
        let valuationDateForms = this.getSelectedCompanyDates(data.id).filter(f => f.status !== "Initiated" && !f.userEntered);
        
        let minDateExists = false; 
        let stakeValue = 0;
        let stake = 0;

        const invForm = valuationDateForms[valuationDateForms.length - 1];

        let portfolioComp

        if(invForm){
          portfolioComp = this.allCompanyValuationDates.find( comp => comp.id == invForm.id)
        }

        portfolioComp = this.allCompanyValuationDates.find( comp => comp.id == data.id)        

        const exchangeRate = this.exchangeRates[portfolioComp.details?.currency + "" + this.portfolioService.selectedCurrency + "" + (moment(portfolioComp.valuationDate)).format("YYYY-MM-DD")] 
                                ? this.exchangeRates[portfolioComp.details?.currency + "" + this.portfolioService.selectedCurrency + "" + (moment(portfolioComp.valuationDate)).format("YYYY-MM-DD")] : 1

        valuationDateForms.forEach( f1 => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(form.valuationDate);
          if(f1Date.getFullYear() === f2Date.getFullYear()
          && f1Date.getDate() === f2Date.getDate()
          && f1Date.getMonth() === f2Date.getMonth()){
            minDateExists = true;
            if(f1.investment && f1.investment.equityValue){
              stakeValue = f1.investment.equityValue.finalStakeValue ? f1.investment.equityValue.finalStakeValue * exchangeRate : f1.investment.equityValue.stakeValue ? f1.investment.equityValue.stakeValue * exchangeRate : 0;
              stake = f1.investment.equityValue.stake ? f1.investment.equityValue.stake/100 : 0; 
            }
          }
        })
  
        const transactionData = await this.getTransactionsForCompany(data.id);
  
        const multipleRealisedProceeds = transactionData.multipleRealisedProceeds;
        const multipleInvestments = transactionData.multipleInvestments;
        const totalInvestmentAmount = transactionData.totalInvestmentAmount;
        const totalRealisedProceeds = transactionData.totalRealisedProceeds;
  
        const historicalData = {
          id: data.id,
          companyName: data.companyName,
          exitDate: data.exitDate,
          stake: stake,
          stakeValue: stakeValue,
          multipleRealisedProceeds: multipleRealisedProceeds,
          multipleInvestments: multipleInvestments,
          totalInvestmentAmount: totalInvestmentAmount,
          totalRealisedProceeds: totalRealisedProceeds,
          totalValue: (stakeValue + totalRealisedProceeds)
        }
  
        if(minDateExists){
          this.chartHistoricalData[form.valuationDate].push(historicalData);
        }
  
        // this.chartHistoricalData[form.valuationDate].push(historicalData);
      })
  
    }
    // console.log("chartHistoricalData", this.chartHistoricalData)

  }

  saveExitSimulationData(){
    const dataToBeSaved = {
      simulationData: this.exitSimulationData,
      chartData: this.exitSimulationChartData
    }

    this.ds.saveWidgetDataToDB(this.exitSimulationV2Key, dataToBeSaved, this.portfolioService.selectedFundId).subscribe( success => {
      // console.log("Exit Simulation Data saved", success);
    }, error => {
      console.log("Failed to save Exit Simulation Data", error);
    })
    
  }

  getValidTransactionsForSelectedFund(invFormId, selectedFundName, valDateFilter?){ // valDateFilter - specific date for filtering
    const transactions = {};

    const allValDateForms = this.getSelectedCompanyDates(invFormId);

    if(allValDateForms.length == 0){
      return transactions;
    }
    
    let dateForTransactionsFilter = allValDateForms[0].valuationDate; // by default transactions are filtered by latest val date.

    if(valDateFilter){
      dateForTransactionsFilter = valDateFilter;
    }

    //Seller Matching for Realised Proceeds
    transactions["multipleRealisedProceeds"] = this.transactions.filter( comp => {
      return invFormId == comp.companyId && comp.Type.toLowerCase() == "realised proceeds"
              && comp.Seller && comp.Seller.toLowerCase() === selectedFundName.toLowerCase();
    }).sort((f1, f2) => {
      const f1Date = new Date(f1.Date);
      const f2Date = new Date(f2.Date);
      return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    }).filter(f1 => { //filter trans wrt latest val date
      const valDate = new Date(dateForTransactionsFilter);
      const transDate = new Date(f1.Date);
      return transDate <= valDate;
    });

    //Buyer Matching for Investment Amount 
    transactions["multipleInvestmentAmount"] = this.transactions.filter( comp => {
      return invFormId == comp.companyId && comp.Type.toLowerCase() == "investment"
                && comp.Buyer && comp.Buyer.toLowerCase() === selectedFundName.toLowerCase();
    }).sort((f1, f2) => {
      const f1Date = new Date(f1.Date);
      const f2Date = new Date(f2.Date);
      return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    }).filter(f1 => { //filter trans wrt latest val date
      const valDate = new Date(dateForTransactionsFilter);
      const transDate = new Date(f1.Date);
      return transDate <= valDate;
    });

    return transactions;
  }

  async getTransactionsForCompany(id){
    // const selectedFund = this.fundService.getFundById(this.portfolioService.selectedFundId).name;
  
    // const validTransactions = this.getValidTransactionsForSelectedFund(id, selectedFund); //to fetch transactions based on buyer and seller

    // // const portfolioComp = this.allCompanyValuationDates.find( comp => comp.id === id);

    let valuationDateForms = this.getSelectedCompanyDates(id);

    const latestValDateForm = valuationDateForms[0];

    let record : any = {}

    // Saving Already Read Track record Data
    if(this.trackRecordCompanyWise[id]){
      return this.trackRecordCompanyWise[id]
    }

    // If Not Exists Call the API
    const reqBody = {
      "fundId": this.portfolioService.selectedFundId,
      "formId": id,
      "valDateIds": [latestValDateForm.id],
      "skipIRR": true
    }

    const res = await this.ds.getTrackRecord(reqBody).toPromise();

    record = res.body["response"][0];

    // if(validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0){
    //   multipleInvestments = validTransactions["multipleInvestmentAmount"].map( comp => {
    //     let finalConvertedValue = 0;
    //     if(this.portfolioService.selectedCurrency === comp.Currency){
    //       finalConvertedValue = comp.Amount
    //     }
    //     // if user selectedCurrency and transaction currency are different then calc exchnage rate from the transactionDate
    //     else{
    //       const exchangeRate = this.exchangeRates[comp.Currency + "" + this.portfolioService.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] 
    //                             ? this.exchangeRates[comp.Currency + "" + this.portfolioService.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] : 1

    //       finalConvertedValue = comp.Amount * exchangeRate; 
    //     }
        
    //     totalInvestmentAmount += finalConvertedValue ? +finalConvertedValue : 0

    //     return { 
    //       date: new Date(comp.Date), 
    //       amount: finalConvertedValue, 
    //     };
    //   }).filter( comp => {
    //     const latestValDate = new Date(latestValDateForm.valuationDate);
    //     const transactionDate = new Date(comp.date)
    //     if(transactionDate < latestValDate){
    //       return true;
    //     }
    //     else{
    //       return false;
    //     }
    //   });
    // }

    // if(validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0){
    //   multipleRealisedProceeds = validTransactions["multipleRealisedProceeds"].map( comp => {
    //     let finalConvertedValue = 0;
    //     if(this.portfolioService.selectedCurrency === comp.Currency){
    //       finalConvertedValue = comp.Amount
    //     }
    //     // if user selectedCurrency and transaction currency are different then calc exchnage rate from the transactionDate
    //     else{
    //       const exchangeRate = this.exchangeRates[comp.Currency + "" + this.portfolioService.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] 
    //                             ? this.exchangeRates[comp.Currency + "" + this.portfolioService.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] : 1

    //       finalConvertedValue = comp.Amount * exchangeRate; 
    //     }
        
    //     totalRealisedProceeds += finalConvertedValue ? +finalConvertedValue : 0
        
    //     return { 
    //       date: new Date(comp.Date), 
    //       amount: finalConvertedValue, 
    //     };
    //   }).filter( comp => {
    //     const latestValDate = new Date(latestValDateForm.valuationDate);
    //     const transactionDate = new Date(comp.date)
    //     if(transactionDate < latestValDate){
    //       return true;
    //     }
    //     else{
    //       return false;
    //     }
    //   });;
    // }

    let multipleInvestments = record.investmentAmountTrans ? record.investmentAmountTrans : [];
    let multipleRealisedProceeds = record.realisedProceedsTrans ? record.realisedProceedsTrans : [];

    multipleInvestments = multipleInvestments.map(inv => {
      return {
        date: new Date(inv.date), 
        amount: inv.valueToBeConsidered, 
      }
    })

    multipleRealisedProceeds = multipleRealisedProceeds.map(rp => {
      return {
        date: new Date(rp.date), 
        amount: rp.valueToBeConsidered, 
      }
    })

    this.trackRecordCompanyWise[id] = {
      multipleRealisedProceeds: multipleRealisedProceeds,
      multipleInvestments: multipleInvestments,
      totalRealisedProceeds: record.realisedProceeds,
      totalInvestmentAmount: record.investmentAmount
    }

    return this.trackRecordCompanyWise[id];
  }

  updateInExitMultipleType(event, index){
    const forms = this.getLatestValIdsForAllForms();
    const latestValDateIds = forms.latestValDateIds;

    const valDateId = latestValDateIds.find(form => {
      return form.groupFormId == this.exitSimulationData[index].id || form.id == this.exitSimulationData[index].id;
    }).id

    const metricValueBase = this.getFinancialMetricValue(this.financialData, valDateId, 
      this.exitSimulationData[index].metricMultiple.toLowerCase());
    this.exitSimulationData[index].amountBaseCase = metricValueBase;
  }

  async initAnalysis(){
    
    if(!this.exitSimulationData || this.exitSimulationData.length == 0){
      return;
    }

    if(this.returnDetailsGridTable){
      this.returnDetailsGridTable.showSpinner();
    }

    if(this.analysisTableGrid){
      this.analysisTableGrid.showSpinner();
    }
    
    if(this.inputGridTable){
      this.inputGridTable.showSpinner();
    }

    const forms = this.getLatestValIdsForAllForms();

    const latestValDateIds = forms.latestValDateIds;
    const invCompForms = forms.invCompForms;

    const reqBody = latestValDateIds.map( form => form.id);

    let fetchedNewFinancials = false;

    if(!this.financialData){
      try {
        const apiData = await this.ds.getExitSimulationData(reqBody).toPromise();
        this.financialData = apiData.body["response"];
        fetchedNewFinancials = true;
      } catch (error) {
        console.log("Fetch Financials Data", error) 
      }
    }


    for (let i = 0; i < this.exitSimulationData.length; i++) {
      try {
        const elem : any = this.exitSimulationData[i]

        const valDateId = latestValDateIds.find(form => {
          return form.groupFormId == elem.id || form.id == elem.id;
        }).id

        const baseAmount = fetchedNewFinancials ? this.getFinancialMetricValue(this.financialData, valDateId, elem.metricMultiple.toLowerCase()) : this.exitSimulationData[i]["amountBaseCase"]
  
        this.exitSimulationData[i]["amountBaseCase"] = baseAmount;
        //Amount 
        const amount = this.getVariation(baseAmount, this.exitSimulationData[i]["variationPercentage"])
        this.exitSimulationData[i]["amountBearishCase"] = amount.min;
        this.exitSimulationData[i]["amountBullishCase"] = amount.max;
  
        //Exit Multiple
        const multipleVal = this.getVariation(
          this.exitSimulationData[i]["multipleAtExitBaseCase"],
          this.exitSimulationData[i]["variationPercentage"]);

        this.exitSimulationData[i]["multipleAtExitBearishCase"] = multipleVal.min;
        this.exitSimulationData[i]["multipleAtExitBullishCase"] = multipleVal.max;
  
        this.exitSimulationData[i]["eVBearishCase"] = this.getProduct(this.exitSimulationData[i]["amountBearishCase"], this.exitSimulationData[i]["multipleAtExitBearishCase"]);
        this.exitSimulationData[i]["eVBaseCase"] = this.getProduct(this.exitSimulationData[i]["amountBaseCase"], this.exitSimulationData[i]["multipleAtExitBaseCase"]);
        this.exitSimulationData[i]["eVBullishCase"] = this.getProduct(this.exitSimulationData[i]["amountBullishCase"] , this.exitSimulationData[i]["multipleAtExitBullishCase"]);
  
        this.exitSimulationData[i]["equityValBearishCase"] = this.getEquityValue(this.exitSimulationData[i]["netDebt"], this.exitSimulationData[i]["eVBearishCase"]);
        this.exitSimulationData[i]["equityValBaseCase"] = this.getEquityValue(this.exitSimulationData[i]["netDebt"], this.exitSimulationData[i]["eVBaseCase"]);
        this.exitSimulationData[i]["equityValBullishCase"] = this.getEquityValue(this.exitSimulationData[i]["netDebt"], this.exitSimulationData[i]["eVBullishCase"]);
  
        this.exitSimulationData[i]["NAVBearishCase"] = this.getProduct(this.exitSimulationData[i]["stake"]/100, this.exitSimulationData[i]["equityValBearishCase"]);
        this.exitSimulationData[i]["NAVBaseCase"] = this.getProduct(this.exitSimulationData[i]["stake"]/100, this.exitSimulationData[i]["equityValBaseCase"]);
        this.exitSimulationData[i]["NAVBullishCase"] = this.getProduct(this.exitSimulationData[i]["stake"]/100, this.exitSimulationData[i]["equityValBullishCase"]);
  
        this.exitSimulationData[i]["totalValBearishCase"] = this.getSum(this.exitSimulationData[i]["NAVBearishCase"], this.exitSimulationData[i]["netDebtAtExit"]);
        this.exitSimulationData[i]["totalValBaseCase"] = this.getSum(this.exitSimulationData[i]["NAVBaseCase"], this.exitSimulationData[i]["netDebtAtExit"]);
        this.exitSimulationData[i]["totalValBullishCase"] = this.getSum(this.exitSimulationData[i]["NAVBullishCase"], this.exitSimulationData[i]["netDebtAtExit"]);
  
        
        const transactionData = await this.getTransactionsForCompany(elem.id);
  
        const multipleRealisedProceeds = transactionData.multipleRealisedProceeds;
        const multipleInvestments = transactionData.multipleInvestments;
        const totalInvestmentAmount = transactionData.totalInvestmentAmount;
        const totalRealisedProceeds = transactionData.totalRealisedProceeds;
  
        const reqBodyForBase = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, this.exitSimulationData[i]["totalValBaseCase"], elem.id, elem.exitDate)
        const reqBodyForBearish = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, this.exitSimulationData[i]["totalValBearishCase"], elem.id, elem.exitDate)
        const reqBodyForBullish = this.getRequestBodyCases(multipleRealisedProceeds, multipleInvestments, this.exitSimulationData[i]["totalValBullishCase"], elem.id, elem.exitDate)
  
        this.exitSimulationData[i]["moicBearishCase"] = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, this.exitSimulationData[i]["totalValBearishCase"]);
        this.exitSimulationData[i]["moicBaseCase"] = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, this.exitSimulationData[i]["totalValBaseCase"]);
        this.exitSimulationData[i]["moicBullishCase"] = this.getMoic(totalInvestmentAmount, totalRealisedProceeds, this.exitSimulationData[i]["NAVButotalValBullishCasellishCase"]);
    
        this.exitSimulationData[i]["irrBearishCase"] = await this.getIRR(reqBodyForBearish);
        this.exitSimulationData[i]["irrBaseCase"] = await this.getIRR(reqBodyForBase);
        this.exitSimulationData[i]["irrBullishCase"] = await this.getIRR(reqBodyForBullish);

      } catch (error) {
        console.log("Error: Failed to simulate exit model", i, error, this.exitSimulationData[i]["companyName"])
      }

      if(this.inputGridTable && (i + 1) == this.exitSimulationData.length){
        console.log("---Final Output", this.exitSimulationData);
        this.inputGridTable.hideSpinner();
        this.inputGridTable.refresh();
      }

      if(this.returnDetailsGridTable && (i + 1) == this.exitSimulationData.length ){
        this.returnDetailsGridTable.hideSpinner();
        this.returnDetailsGridTable.refresh();
      }  

      if(this.analysisTableGrid && (i + 1) == this.exitSimulationData.length ){
        this.analysisTableGrid.hideSpinner();
        this.analysisTableGrid.refresh();
      }
    }
    
    this.showReturnDetailsGridTable = true;  
    
    this.saveExitSimulationData();

    this.prepareSimulationChartData();
  }

  getMoic(totalInvestmentAmount, totalRealisedProceeds, stakeValue){
    const moic = totalInvestmentAmount > 0 ?  ((+stakeValue) + (+totalRealisedProceeds))/totalInvestmentAmount : 0;

    return moic;
  }

  async getIRR(reqBodyIRR){
    let IRR = 0;
    try {
      const apiResponse = await this.ds.getIRR(reqBodyIRR).toPromise();
      IRR = apiResponse.body["response"].data.complete_processed_data;      
      // console.log("IRR RESPONSE", portfolioComp.companyName, apiResponse.body["response"].data.complete_processed_data);

    } catch(e) {
      console.log("Failed to calculated IRR for -");
    }

    return IRR ? IRR * 100 : 0;
  }

  getRequestBodyCases(rp, inv, caseStakeValue, id, latestValuationDate){
    let stakeValArrayForCase;

    stakeValArrayForCase = [{
      date: latestValuationDate,
      amount: caseStakeValue,
    }]

    inv = inv.map( data => {
      return {
        date: data.date,
        amount: data.amount * -1
      }
    })

    const sortedMultiples =  rp.concat(inv).concat(stakeValArrayForCase).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {return 1;}
      if (obj1.date < obj2.date) {return -1;}
      return 0;
    });

    return {
      data: sortedMultiples.map( data => {
        return { date: (moment(data.date)).format("DD/MM/YY"),amount: data.amount }
      }),
      request_id: id
    }

  }

  getProduct(metricValue, multipleValue){
    const product = +metricValue * +multipleValue
    return isNaN(product) ? 0 : product;
  }

  getSum(stakeValue, debtAtExit){
    const sum = +stakeValue + +debtAtExit;
    return isNaN(sum) ? 0 : sum;
  }

  getEquityValue(netDebt, enterpriseValue){
    const diff = +enterpriseValue - +netDebt;
    return isNaN(diff) ? 0 : diff;
  }

  getVariation(value, variationPercentage){
    const variation = {
      min: 0, max: 0
    }

    if(value > 0){
      variation.min = +value - ((variationPercentage/100) * +value);
      variation.max = +value + ((variationPercentage/100) * +value)
    }

    return variation;
  }

  getFinancialMetricValue(financialData, formId, multiple){
    let metric = 0;
    if(financialData 
      && financialData[formId] 
      && financialData[formId].exitSimulation 
      && financialData[formId].exitSimulation.financials){

      const finData = financialData[formId].exitSimulation.financials
      const finMultiple = finData.find(fin => {
        return fin.year == "LTM" 
      });

      metric = finMultiple ? finMultiple[multiple] : 0;      
    }

    return metric;
  }
}
