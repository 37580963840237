import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userDetail
  statistics
  defaultValuesIncomeAppraoch
  defaultValuesComparableAppraoch
  defaultValuesComparableTransactionAppraoch
  analysisApproach
 

  constructor(private router: Router, private um: UserManagementService, private utilService: UtilService, private dataService:DataService) { }

  ngOnInit() {
    this.userDetail = this.um.getUserDetails()
    // console.log("userDetail",this.userDetail)

    this.getApiUsageStatistics();

    this.defaultvalueInit()  
     
  }

  defaultvalueInit()
  {
    this.dataService.getDefaultsForUser().subscribe(data =>
      {
        data.body["response"].forEach(element => {
        if(element.analysisApproach == "INCOME_APPROACH")
          this.defaultValuesIncomeAppraoch = JSON.parse(element.defaultValue)
        else if(element.analysisApproach == "COMPARABLE_COMPANY")
          this.defaultValuesComparableAppraoch = JSON.parse(element.defaultValue)
        else if(element.analysisApproach == "COMPARABLE_TRANSACTION")
          this.defaultValuesComparableTransactionAppraoch = JSON.parse(element.defaultValue)  
        });     
      })
  }

  openResetForm() {
    this.router.navigateByUrl('/reset-password');
  }

  getApiUsageStatistics() {
    this.um.getApiUsageStatistics().subscribe(result=>{
      // console.log("Statistics", result)
      this.statistics = result["response"];
    }, error=>{
      console.log("error while getting api usage statistics", error)
    })
  }

  increaseLimit(api){
    this.um.requestApiLimitIncrease(api, 10).subscribe(result=>{
      // console.log("Api limit request accepted");
      this.utilService.showMessage("Request to increase api limit is accepted.", "Ok");
      this.statistics[api].request = true;
    }, error=>{
      
      console.log("Failed to accept Api limit request", error);
      this.utilService.showMessage("failed to accept your request. Please contact us directly.", "Ok");
    })
  }
}
