import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subject } from 'rxjs';
import { UtilService } from 'src/app/utils/util.service';
import cloneDeep from 'lodash/cloneDeep';
import min from 'lodash/min';
import max from 'lodash/max';
import { CCMServiceV3 } from './ccm-new-v3.service';

@Injectable({
  providedIn: 'root'
})
export class IAService {
  constructor(private ds: DataService, public utilService: UtilService, private ccmService: CCMServiceV3)
  {}

  formData;

  result;
  resultClone;
  resultAvaible = new Subject();

  loadingProgress = 50;

  rangeAvailable = new Subject();
  stChangeFactor = 10;
  stYAxis = "LTGR"
  stTable;
  range = {algo: "IA", values: [0, 0]};

  percentageUserInputs = {};

  ccmSimilarCompanies = [];
  ccmInit = true;

  irrRequired = false;

  balanceSheetAdjustment;

  netOperatingLossAdjustments;

  initSavedValuation(apiData, formData) {
    this.formData = formData;

    this.result = apiData;

    this.resultClone = cloneDeep(this.result);

    this.netOperatingLossAdjustments = this.result.input.analysisUpdates ? 
      this.result.input.analysisUpdates.netOperatingLossAdjustments : null;

    this.initDCFAssumptions(this.result.input.dcfAssumptions);
    if(this.result["dcfOverride"]) {
      this.dcfRelatedAssumptions = this.result["dcfOverride"];
    }

    this.initSTInputs();
    
    if(!this.result.input.analysisUpdates) this.result.input.analysisUpdates = {};

    if(!this.result.input.analysisUpdates.taxPayables) {
      this.result.input.analysisUpdates.taxPayables = this.result.response.analysis.analysisData.map(row => {
        return { "year": row.year, "value": +row.taxPayable, isEdited: false };
      });
    }
  }

  initDCFAssumptions(dcfAssumptions) {
    this.dcfRelatedAssumptions.forEach(assumption => {
      if(assumption.suffix === "%") {
        assumption.marketInput = (+dcfAssumptions[assumption.dbKey] * 100).toFixed(2);
      } else {
        assumption.marketInput = (+dcfAssumptions[assumption.dbKey]).toFixed(2);
      }
      assumption.concluded = assumption.marketInput;
      assumption.value = "Market Input";
    });
  }

  initInputs(inputData, newDcfAssumptions) {    
    const gi = inputData.generalInputs;
    this.initGeneralInputs("dcfModelType", gi["dcfModelType"], "dcfModelType");
    this.initGeneralInputs("computationModel", gi["computationModel"], "computationModel");
    this.initGeneralInputs("exitMultipleUse", gi["exitMultipleUse"], "exitMultipleUse");
    this.initGeneralInputs("exitMultipleYear", gi["exitMultipleYear"], "exitMultipleYear");
    this.initGeneralInputs("vdBalanceSheet", gi["vdBalanceSheet"], "vdBalanceSheet");
    this.initGeneralInputs("capitalAP", gi["capitalAP"], "capitalAP"); 
    this.initGeneralInputs("includeFutureFund", gi["includeFutureFund"], "includeFutureFund");

    this.dcfRelatedAssumptions.forEach((assumption, index) => {
      if(assumption.suffix === "%") {
        assumption.marketInput = (+newDcfAssumptions[assumption.dbKey] * 100).toFixed(2);
      } else {
        assumption.marketInput = (+newDcfAssumptions[assumption.dbKey]).toFixed(2);
      }

      if(assumption.value === "Market Input") {
        assumption.concluded = assumption.marketInput;
      }
    });
  }

  initGeneralInputs(appInputName, backendInputValue, dbKeyName) {    
    const giInput = this.generalInputs.find((input)=>{
      return input.keyName === appInputName;
    })

    giInput["dbKeyName"] = dbKeyName;
    giInput["selected"] = (backendInputValue + "").toUpperCase();
  }

  initSTInputs(customAnalysis?) {
    if(this.result.response.rangeAlgoResponse) {   
      const rangeData = this.result.response.rangeAlgoResponse;

      if(customAnalysis) {
        rangeData.changeFactor = this.stChangeFactor;
        rangeData.sensitivityType = this.stYAxis;

      } else {        
        this.stChangeFactor = rangeData.changeFactor
        this.stYAxis = rangeData.sensitivityType
      }

      this.percentageUserInputs["stChangeFactor"] = (rangeData.changeFactor * 100).toFixed(2);
      
      this.prepareHeatMap(rangeData);

      this.range = {algo: "IA", values: [rangeData.low, rangeData.high]};

      this.rangeAvailable.next(this.range);
    }
  }
  
  acceptOnlyDigitsPercentage(event, actualObj, parameter) {
    const regex = /[^0-9.]/gi;
    event.target.value = event.target.value.replace(regex, "");

    /** Don't allow value more than 100 (incase of % input) */
    const value = +event.target.value;
    if (value > 100) {
      event.target.value = event.target.value.substring(0, event.target.value.length - 1);
    }

    this.percentageUserInputs[parameter] = +event.target.value;
    actualObj[parameter] = this.percentageUserInputs[parameter] / 100;
  }

  getCustomAnalysisInput() {    
    this.generalInputs.forEach((input)=>{
      this.result.input.generalInputs[input.dbKeyName] = input.selected;
    })

    this.dcfRelatedAssumptions.forEach((input)=>{
      if(input.suffix === "%") {
        this.result.input["dcfAssumptions"][input.dbKey] = (+input.concluded) / 100;
      } else {
        this.result.input["dcfAssumptions"][input.dbKey] = input.concluded
      }
    })

    this.result.input.generalInputs.computationModel = this.camelCase(this.result.input.generalInputs.computationModel);

    this.result.input.skipSave = true;
    return this.prepareAlgorithmInput(this.result.input);
  }

  camelCase(str) { 
    const array = str.split(" ");
    array.forEach((a,i) => {
      array[i] = a.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) 
        { 
          try {
            return index == 0 ? word.toUpperCase() : word.toLowerCase(); 
          } catch (e) {
            console.log("IA - Re-analysis - To Upper Case Null Error Check", str);
            return "";
          }
        }).replace(/\s+/g, ' ');
    });
    return array.join(' '); 
  }

  prepareAlgorithmInput(dbValue) {
    const algorithmInput = cloneDeep(dbValue);

    if(!algorithmInput.analysisUpdates){
      algorithmInput.analysisUpdates = {};
    }

    algorithmInput.analysisUpdates.taxPayables = this.result.response.analysis.analysisData.map(row => {
      const input = { "year": row.year, "value": +row.taxPayable, isEdited: false };

      if(this.result.input.analysisUpdates.taxPayables) {
        const taxPayable = this.result.input.analysisUpdates.taxPayables.find(r => r.year === row.year);
        
        if(taxPayable) {
          input.value = taxPayable.value;
          input.isEdited = taxPayable.isEdited;
        }
      }

      return input;
    });

    if(this.netOperatingLossAdjustments) {
      algorithmInput.analysisUpdates.netOperatingLossAdjustments = this.netOperatingLossAdjustments.map((adj, index) => { 
        return {
          "name": adj.name,
          "action": adj.action,
          "values": adj.values
        }
      });
    }

    algorithmInput.generalInputs.exitMultipleYear = min([+algorithmInput.generalInputs.exitMultipleYear, +algorithmInput.generalInputs.capitalAP])

    algorithmInput.rangeAlgoInputs = this.getIARangeAPIInput(algorithmInput.generalInputs.discountRate, algorithmInput.dcfAssumptions.longTermGrowthRate, 0.25);

    delete algorithmInput.costOfCapitalDebtCalculation.debt;
    delete algorithmInput.dcfAssumptions.stableEBITDAMarginManualOverride;

    return algorithmInput;
  }
  
  analyseWithNewCurrencyRate() 
  {
    
  }

  getIARangeAPIInput(discountRate, yAxisCenter, stChangeFactor) {    
    const columns = [ 
      discountRate - (2 * +stChangeFactor),
      discountRate - (1 * +stChangeFactor),
      discountRate,
      discountRate + (1 * +stChangeFactor),
      discountRate + (2 * +stChangeFactor),
    ];
    
    const rows = [
      yAxisCenter - (2 * +stChangeFactor),
      yAxisCenter - (1 * +stChangeFactor),
      yAxisCenter,
      yAxisCenter + (1 * +stChangeFactor),
      yAxisCenter + (2 * +stChangeFactor),
    ]
    
    return {
      "rowHeader": rows,
      "columnHeader": columns,
      "sensitivityType": "LTGR"
    }
  }

  prepareHeatMap(tableData) {
    const array = [];
    const xAxis = [];

    tableData.rowHeader.forEach((rV, rI)=>{
      xAxis.push((rV * 100).toFixed(2) + "%");

      tableData.columnHeader.forEach((cV, cI)=>{
        let value = (+(tableData.rangeMatrix["R"+(rI + 1) + "_" + "C" + (cI + 1)] || 0).toFixed(2));       
        array.push([rI, cI ,value]);

      })
    })

    const yAxis = tableData.columnHeader.map(cV=> (cV * 100).toFixed(2) + "%");

    this.stTable = {
      xAxis: xAxis,
      yAxis: yAxis,
      series: [ { type: "heatmap", values: array } ]
    };
  }

  /**
   * Capital Expenditure Numbers of projected years
   * for report generation
   */

  getCapExNumbers() {
    const capEx = [];
    this.result.response.analysis.analysisData.forEach(year=>{
      if(year.type === "PROJECTED")
        capEx.push(+year.lessCapitalExpenditureRevenue);
    })
    
    return { min: min(capEx), max: max(capEx) }
  }

  getStableEbitdaMargin() {
    const stableEM = this.dcfRelatedAssumptions.find(assumption => assumption.dbKey === "stableEBITDAMargin");

    return { value: stableEM.concluded, manualOverride: stableEM.value === "Manual Overide" }
  }

  setBalanceSheetAdjustment(adj) {
    this.balanceSheetAdjustment = adj;
    // console.log("IA balanceSheetAdjustment", this.balanceSheetAdjustment)

    if(!this.result.input.analysisUpdates){
      this.result.input.analysisUpdates = {};
    }

    const balanceSheetAdjs = []

    this.balanceSheetAdjustment.forEach( adj => {
      
      if(adj.selected) {
        const adjustment = { "name": adj.label, "action": "subtract", "value": adj.updatedValue};

        if(adj.isAddition) {
          adjustment.action = "add"
        }

        balanceSheetAdjs.push(adjustment);
      }
    })

    this.result.input.analysisUpdates.balanceSheetAdjustments = balanceSheetAdjs;

    // console.log("IA balanceSheetAdjustment", this.result.input)
  }

  dcfRelatedAssumptions = [
    {
      "key":"Stable EBITDA Margin", dbKey: "stableEBITDAMargin", "value":"Manual Override",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%" 
    },
    {
      "key":"Terminal Year Depreciation", dbKey: "terminalYearDepreciation", "value":"Manual Override",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"Terminal Year Debt to EBITDA", dbKey: "terminalYearDebitToEBITDA", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "x"
    },
    {
      "key":"Interest Rate Input For Future Years", dbKey: "interestRateInputForFutureYears", "value":"Manual Override",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%" 
    },
    {
      "key":"Stable Capital Expenditure as % of Sales", dbKey: "stableCapitalExpenditureOfSales", "value":"Manual Override",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"Stable Working Capital as % of Sales", dbKey: "stableWorkingCapitalOfSales", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"Long Term Growth Rate", dbKey: "longTermGrowthRate", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"Debt To Total Capital Ratio", dbKey: "debtToTotalCapitalRatio", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"EBITDA Multiple", dbKey: "ebitdaMultiple", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "x"
    },
    {
      "key":"Revenue Multiple", dbKey: "revenueMultiple", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "x"
    },
    {
      "key":"Company Specific Risk Premium", dbKey: "companySpecificRiskPremium", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    },
    {
      "key":"Terminal Year Tax Rate", dbKey: "terminalYearTaxRate", "value":"Market Input",
      "manualOverride":"", "marketInput":"", "concluded":"", suffix: "%"
    } 
  ];

  generalInputs = [
    {keyName: "dcfModelType", q: "What Type of DCF Model do you want to use?", values : [{key:"FCFE", label:"FCFE"}, {key:"FCFF", label:"FCFF"}], dbKeyName: "", selected: ""},
    {keyName: "computationModel", q: "Please select the terminal Year Computation Model", values : [{key:"GORDON GROWTH MODEL", label:"Gordon Growth Model"}, {key:"EXIT MULTIPLE", label:"Exit Multiple"}, {key:"H-GROWTH MODEL", label:"H Growth Model"}], dbKeyName: "", selected: ""},
    {keyName: "exitMultipleUse", q: "Which Exit Multiple you want to use ?", values : [{key:"REVENUE", label:"Revenue"}, {key:"EBITDA", label:"EBITDA"}], dbKeyName: "", selected: ""},
    {keyName: "vdBalanceSheet", q: "Do we have Valuation Date Balance Sheet?", values : [{key:"YES", label:"Yes"}, {key:"NO", label:"No"}], dbKeyName: "", selected: ""},
    {keyName: "capitalAP", q: "Capital Advantage Period", values : [{key:"1", label:"1 year"}, {key:"2", label:"2 years"}, {key:"3", label:"3 years"}, {key:"4", label:"4 years"}, {key:"5", label:"5 years"}, {key:"6", label:"6 years"}, {key:"7", label:"7 years"}, {key:"8", label:"8 years"}, {key:"9", label:"9 years"}, {key:"10", label:"10 years"}, {key:"11", label:"11 years"}, {key:"12", label:"12 years"}, {key:"13", label:"13 years"}, {key:"14", label:"14 years"},
          {key:"15", label:"15 year"}, {key:"16", label:"16 years"}, {key:"17", label:"17 years"}, {key:"18", label:"18 years"}, {key:"19", label:"19 years"}, {key:"20", label:"20 years"}, {key:"21", label:"21 years"}, {key:"22", label:"22 years"}, {key:"23", label:"23 years"}, {key:"24", label:"24 years"}, {key:"25", label:"25 years"}], dbKeyName: "", selected: ""},
    {keyName: "includeFutureFund", q: "Do you want to Include future Funding Amounts?", values : [{key:"YES", label:"Yes"}, {key:"NO", label:"No"}], dbKeyName: "", selected: ""},
    {keyName: "exitMultipleYear", q: "Exit Multiple Year", values : [{key:"1", label:"1 year"}, {key:"2", label:"2 years"}, {key:"3", label:"3 years"}, {key:"4", label:"4 years"}, {key:"5", label:"5 years"}, {key:"6", label:"6 years"}, {key:"7", label:"7 years"}, {key:"8", label:"8 years"}, {key:"9", label:"9 years"}, {key:"10", label:"10 years"}, {key:"11", label:"11 years"}, {key:"12", label:"12 years"}, {key:"13", label:"13 years"}, {key:"14", label:"14 years"}]}
  ]
}