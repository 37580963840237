import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { environment } from 'src/environments/environment';
import { FundListService } from '../../portfolio/fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';

@Component({
  selector: 'app-monitoring-navigation',
  templateUrl: './monitoring-navigation.component.html',
  styleUrls: ['./monitoring-navigation.component.scss']
})
export class MonitoringNavigationComponent implements OnInit {
  companies: any = [];
  fundId;

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  constructor(private modalService: NgbModal, 
    public portfolioService: PortFolioSummaryServiceV2, 
    private route: ActivatedRoute,
    public router: Router, private ums: UserManagementService,
    private ds: DataService,
    public fundService: FundListService) { }

  selectedCompany;
  companyId;
  
  ngOnInit(): void {
    this.fundId = this.route.snapshot.queryParamMap.get("parentId");

    const user = this.ums.getSelectedUserDetails();

    if(!this.fundId) {
      this.router.navigateByUrl('/fundList');
    } else {
      const fundName = this.fundService.getFundName(this.fundId);

      if(!fundName) {
        this.fundService.getFunds(user);
      }
    }

    // this.ds.getCompanyListFromFunds(user.id, "ALL", this.fundId).subscribe(res => {
    //   this.companies = res.body["response"];

    //   if(!this.companies || this.companies.length == 0) {
    //     this.companies = "ERROR";
    //   }
    // }, error => {
    //   this.companies = "ERROR";
    //   console.log("Error while fetching list of companies for monitoring listing", error)
    // })

    this.getCompanyListForMonitoring();
  }

  getCompanyListForMonitoring() {
    const user = this.ums.getSelectedUserDetails();

    let monitoringPayload = {
      "fundIds": [this.fundId],
      "userId": user.id
    }

    this.ds.getCompanyListForMonitoring(monitoringPayload).subscribe(res => {
      this.companies = res.body["response"][this.fundId];

      if(!this.companies || this.companies.length == 0) {
        this.companies = "ERROR";
      }
    }, error => {
      this.companies = "ERROR";
      console.log("Error while fetching list of companies for monitoring listing", error)
    })
  }

  monitoringNav(company){
    const fundName = this.fundService.getFundName(this.fundId);
    window.open(environment.portalUrl + "/monitoring/#/general-detail?companyName=" + company.companyName + "&companyId=" + company.companyId + "&fundId=" + this.fundId + "&fundName=" + fundName);
  }

  navigateToPortfolio(){
    this.router.navigateByUrl('/portfolio/' + this.fundId);
  }

  onContextMenu(event: MouseEvent,company) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item : company }
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  async getImageURL(event, item){
    try {
      const imageUrl = await this.ds.getImageUrl(event.target.files[0], item.companyId + event.target.files[0].name);
      
      const requestBody = {
          url: imageUrl.body["response"],
          companyId: item.companyId
      }
      await this.ds.saveLogoUrl(requestBody);

      // this.investmentDateValuation["logo"] = imageUrl.body["response"];
      this.getCompanyListForMonitoring();
    }
    catch(e) {
      console.log("Error while saving company logo", e);
    }
  }

}
