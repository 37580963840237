<mat-card class="config-container">
    <h3 class="change-log-header"> Power BI Config <span *ngIf="orgName"> - {{ orgName }}</span> </h3>

    <form #reportConfigForm="ngForm"  class="config-form">
        <div class="field-wrapper">
            <label>Report Type</label>
            <mat-form-field class="report-type">
                <mat-select placeholder="Select a report type" (selectionChange)="onReportTypeChange($event)">
                    <mat-option *ngFor="let r of reportTypes" [value]="r?.id">
                        {{ r?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="field-wrapper">
            <label>Org ID</label>
            <mat-form-field>
                <input matInput name="org-id" type="text" [(ngModel)]="orgId" placeholder="Enter Org ID" />
            </mat-form-field>
        </div>

        <div class="field-wrapper">
            <label>Report ID</label>
            <mat-form-field>
                <input matInput name="power-bi-reportId" type="text" [(ngModel)]="powerBiReportId"
                    placeholder="Enter power BI report ID" />
            </mat-form-field>
        </div>

        <div>
            <button mat-flat-button class="save-btn"
                (click)="saveButton()">{{translateService.getLabel("save")}}</button>
        </div>
    </form>
</mat-card>