<div class="col mx-0 px-0" *ngIf="debtPortfolioService.coverageDataAvailable">
    <div class="row sf-table">
        <table class="table mb-1">
            <tr class="table_header_style">
                <th>{{translateService.getLabel("security")}}</th>
                <th class="align-right">{{translateService.getLabel("coverage")}}</th>
            </tr>
            <tr *ngFor="let row of coverageData;">
                <td>{{ row.securityName }}</td>
                <td class="align-right">{{ row.currentValue == 'NA' ? 'NA' : (row.currentValue * 100 | dynamicDecimalPipe) + '%'}}</td> 
            </tr>
        </table>
    </div>
    <div class="row">
        <span class="main-heading ml-3">{{translateService.getLabel("covenants")}}</span>
    </div>
    <div class="row float-right covenants-save-btn">
        <button class="btn-custom-primary" (click)="saveCovenantMaxValue()">{{translateService.getLabel("save")}}</button>
    </div>
    <div class="row sf-table">

        <table class="table">
            <tr class="table_header_style">
                <th>{{translateService.getLabel("list_of_covenants")}}</th>
                <th class="align-right">{{translateService.getLabel("credit_current_value")}}</th>
                <th class="align-right">{{translateService.getLabel("credit_max_value")}}</th>
                <th class="align-right"></th>
            </tr>
            <ng-template ngFor let-key [ngForOf]="covenantKeys" let-i="index">
                <tr>                    
                    <td>{{ covenantLabelsArray[i] }}</td>

                    <td class="align-right">{{  covenantData[key][0].currentValue | dynamicDecimalPipe }}</td>
                    <td class="align-right">
                      <ejs-numerictextbox min="0" [(value)]="covenantData[key][0].maxValue" (change)="onMaxValueChange($event, key)"
                        class="round-leverage-input-field yellow-input d-inline-block w-auto" [showSpinButton]="false"
                        format="#,###.0">
                      </ejs-numerictextbox>
                    </td>
                    
                    <td class="align-right">
                        <button class="btn send-btn" 
                            (click)="deleteCovenant(key, i)">
                            <span class="send-icon">
                                <i class="far fa-trash-alt"></i>
                            </span>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </table>
        
        <div class="row m-3 d-flex align-items-center">
            <div class="col-6">
                <mat-form-field class="custom-input-select">
                    <mat-label>{{translateService.getLabel("select_covenant")}}</mat-label>
                    <mat-select name="type" [(ngModel)]="selectedCovenant">
                        <mat-option *ngFor="let data of covenantsDropDownList" value="{{data.value}}"> {{data.label}} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 align-right">
                <button class="btn-custom-secondry" (click)="addCovenant()">{{translateService.getLabel("add")}}</button>
            </div>
        </div>
    </div>
</div>
