import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DataService } from '../services/data.service';
import { UserManagementService } from '../services/user-management.service';
import { colors } from '../utils/colors';
import { UtilService } from '../utils/util.service';
import Highcharts from 'highcharts';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';

@Injectable({
  providedIn: 'root'
})
export class ValueBridgeFundLevelService {

  //Fund Level VB
  fundId;

  valuationBridge;
  companyBridge;
  fundLevelVBData = [];
  fundLevelVBTabularData = [];
  valDatesToBeConsideredForFundLevelVB = []
  selectedMetricForFundLevelVB = "bevEbitda";
  selectedPeriodForFundLevelVB = "fy";

  preparedFundLevelVB = false;
  preparedCompanyBridge = false;

  vbDataLoaded = false;
  
  waterFallLabelsForVB = false;
  waterFallLabelsForCB = false;

  aggregatedWaterFallNumbers = {
    totalStartStakeValue : 0,
    totalFirstMetricImpact : 0,
    totalFirstMultipleImpact : 0,
    totalFirstNetDebtImpact : 0,
    totalFirstFxImpact : 0,
    totalFirstStakeImpact : 0,
    totalFirstOthersImpact : 0,
    totalFirstRealisedProceedsImpact : 0,

    totalIntermediateStakeValue : 0,

    totalSecondMetricImpact : 0,
    totalSecondMultipleImpact : 0,
    totalSecondNetDebtImpact : 0,
    totalSecondFxImpact : 0,
    totalSecondStakeImpact : 0,
    totalSecondOthersImpact : 0,
    totalSecondRealisedProceedsImpact : 0,
    totalEndStakeValue : 0,
  }
  //Fund Level VB
  
  constructor(private ums: UserManagementService, public utilService : UtilService,
    private dataService : DataService, private datePipe : DatePipe, private portfolioService: PortFolioSummaryServiceV2,
    private fundService: FundListService) { }

  async initValueBridgeFundLevel(){
    this.preparedFundLevelVB = false;
    this.preparedCompanyBridge = false;
    this.vbDataLoaded = false;
    
    this.valDatesToBeConsideredForFundLevelVB = this.getValDatesToBeConsideredForFundLevelVB();

    let fundObj = this.fundService.getFundById(this.fundId);

    if(fundObj.type == "FUND"){
      const reqBody = {
        "valuationDates": this.valDatesToBeConsideredForFundLevelVB,
        "destinationCurrency": this.portfolioService.selectedCurrency,
        "metricImpactToBeConsidered": this.selectedMetricForFundLevelVB,
        "period": this.selectedPeriodForFundLevelVB
      }
  
      const assessorUserId = this.ums.getSelectedUserDetails().id;

      try{
        const res = await this.dataService.getFundLevelVB(this.fundId, assessorUserId, reqBody).toPromise();
        this.fundLevelVBData = res.body["response"];
      }
      catch(e){
        this.preparedCompanyBridge = true;
        this.preparedFundLevelVB = true;
        this.vbDataLoaded = true;
        console.log("Failed to load Value Bridge Fund Level", e);
      }
    }
    else{
      let reqBody = {
        "valuationDates":  this.valDatesToBeConsideredForFundLevelVB
      }

      try{
        const res = await this.dataService.getValueBridgeForYellowReport(reqBody, this.fundId).toPromise();
        this.fundLevelVBData = res.body["response"];
      }
      catch(e){
        this.preparedCompanyBridge = true;
        this.preparedFundLevelVB = true;
        this.vbDataLoaded = true;
        console.log("Failed to load Value Bridge Fund Level", e);
      }
      
    }

    if(this.fundLevelVBData) {
      this.fundLevelVBTabularData = this.getFormattedValueBridgeData(this.fundLevelVBData); //Tabular Format Data for
      this.valuationBridge = this.drawFundLevelValuationBridge(this.fundLevelVBTabularData, this.waterFallLabelsForVB); //For Initializing the VB chart
      this.companyBridge = this.drawValueCreationBridge(this.fundLevelVBTabularData, this.waterFallLabelsForCB); //For Initializing the CB chart

      this.vbDataLoaded = true;
    }
    
  }

  initwaterFallChart(waterFallGraphShowLabels?) {
    const waterFallChartData = new Chart({
      chart: {
        type: 'waterfall',
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend:{ enabled: false},

      xAxis: {
        type: 'category',
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE',
      },

      yAxis: {
        title: { text: '' },
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE',
      },

      plotOptions: {
        series: { 
          stacking: 'normal'
        },
        waterfall: {
          negativeColor: colors.secondaryColor,
      
          dataLabels: {
            enabled: waterFallGraphShowLabels && waterFallGraphShowLabels == true ? true : false,
            formatter: function () {
                  return Highcharts.numberFormat(this.y, 1, '.', ',') + ' Mn';
              },
              verticalAlign: 'top',
              x: 3,
              y: -30,
              color: "black",
              
              style: { textOutline: 'none' }      
          }
        }
      },
      tooltip: {
        formatter: function () {
            return '<b>' + this.point.name + '</b> : ' + this.y.toLocaleString();
        }
      },
      series: [{
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: [],
        pointPadding: 0
      } as any]
    })

    return waterFallChartData;
  }

  drawFundLevelValuationBridge(fundLevelVBData, waterFallGraphShowLabels){

    if(this.valuationBridge){
      this.preparedFundLevelVB = false;
      this.valuationBridge.destroy()
    }

    let waterFallNumbers =  this.getWaterFallNumbersForFundLevelVB(fundLevelVBData)
    
    const waterFallChartData = this.drawWaterFallChart(waterFallNumbers, waterFallGraphShowLabels);

    this.preparedFundLevelVB = true;

    return waterFallChartData;
  }

  drawValueCreationBridge(fundLevelVBData, waterFallGraphShowLabels){

    let waterFallNumbers =  this.getWaterFallNumbersForValueCreationBridge(fundLevelVBData);
    
    const waterFallChartData = this.drawWaterFallChart(waterFallNumbers, waterFallGraphShowLabels);
    
    this.preparedCompanyBridge = true;

    return waterFallChartData;
  }

  drawWaterFallChart(waterFallNumbers, waterFallGraphShowLabels){
    const waterFallChartData = this.initwaterFallChart(waterFallGraphShowLabels);
    
    //Filterting waterfallnumbers whose value is zero 
    waterFallNumbers = waterFallNumbers.filter((data, i) => {
      return (data.isIntermediateSum == true || data.isSum == true || i == 0) || +data.y != 0;
    })
    
    waterFallChartData.addSeries({
      upColor: "#5FAFF2",
      color: "#5FAFF2",
      data: waterFallNumbers,
      pointPadding: 0
    } as any, true, false);
    
    return waterFallChartData;
  }

  getFormattedValueBridgeData(fundLevelVBData){
    return fundLevelVBData.map( vbData => {
      const vbFormattedJSON = {
        companyName: vbData.companyName,
        startStakeValue: +this.getDisplayValue(vbData.valueBridgeData[0].startStakeValue ? vbData.valueBridgeData[0].startStakeValue : 0),
        firstMetricImpact: +this.getDisplayValue(vbData.valueBridgeData[0].metricImpact ? vbData.valueBridgeData[0].metricImpact : 0),
        firstMultipleImpact: +this.getDisplayValue(vbData.valueBridgeData[0].multipleImpact ? vbData.valueBridgeData[0].multipleImpact : 0),
        firstNetDebtImpact: +this.getDisplayValue(vbData.valueBridgeData[0].netDebtImpact ? vbData.valueBridgeData[0].netDebtImpact : 0),
        firstFxImpact: +this.getDisplayValue(vbData.valueBridgeData[0].fxImpact ? vbData.valueBridgeData[0].fxImpact : 0),
        firstStakeImpact: +this.getDisplayValue(vbData.valueBridgeData[0].stakeImpact ? vbData.valueBridgeData[0].stakeImpact : 0),
        firstOthersImpact: +this.getDisplayValue(vbData.valueBridgeData[0].othersImpact ? vbData.valueBridgeData[0].othersImpact : 0),
        firstRealisedProceedsImpact: +this.getDisplayValue(vbData.valueBridgeData[0].realisedProceedsImpact ? vbData.valueBridgeData[0].realisedProceedsImpact : 0),
        metricKey: vbData.valueBridgeData[0].metricKey,

        firstNewInvestmentsImpact: +this.getDisplayValue(vbData.valueBridgeData[0].newInvestments ? vbData.valueBridgeData[0].newInvestments : 0),
        firstExitInvestmentsImpact: +this.getDisplayValue(vbData.valueBridgeData[0].exitInvestments ? vbData.valueBridgeData[0].exitInvestments : 0),

        intermediateStakeValue: +this.getDisplayValue(vbData.valueBridgeData[1].startStakeValue ? vbData.valueBridgeData[1].startStakeValue : 0),
        secondMetricImpact: +this.getDisplayValue(vbData.valueBridgeData[1].metricImpact ? vbData.valueBridgeData[1].metricImpact : 0),
        secondMultipleImpact: +this.getDisplayValue(vbData.valueBridgeData[1].multipleImpact ? vbData.valueBridgeData[1].multipleImpact : 0),
        secondNetDebtImpact: +this.getDisplayValue(vbData.valueBridgeData[1].netDebtImpact ? vbData.valueBridgeData[1].netDebtImpact : 0),
        secondFxImpact: +this.getDisplayValue(vbData.valueBridgeData[1].fxImpact ? vbData.valueBridgeData[1].fxImpact : 0),
        secondStakeImpact: +this.getDisplayValue(vbData.valueBridgeData[1].stakeImpact ? vbData.valueBridgeData[1].stakeImpact : 0),
        secondOthersImpact: +this.getDisplayValue(vbData.valueBridgeData[1].othersImpact ? vbData.valueBridgeData[1].othersImpact : 0),
        secondRealisedProceedsImpact: +this.getDisplayValue(vbData.valueBridgeData[1].realisedProceedsImpact ? vbData.valueBridgeData[1].realisedProceedsImpact : 0),
        endStakeValue: +this.getDisplayValue(vbData.valueBridgeData[1].endStakeValue ? vbData.valueBridgeData[1].endStakeValue : 0),

        secondNewInvestmentsImpact: +this.getDisplayValue(vbData.valueBridgeData[1].newInvestments ? vbData.valueBridgeData[1].newInvestments : 0),
        secondExitInvestmentsImpact: +this.getDisplayValue(vbData.valueBridgeData[1].exitInvestments ? vbData.valueBridgeData[1].exitInvestments : 0),

      }

      return vbFormattedJSON;

    })
  }

  getWaterFallNumbersForValueCreationBridge(fundLevelVBData){
    let startStakeValue = 0;
    let endStakeValue = 0;
    let intermediateStakeValue = 0;
    
    fundLevelVBData.forEach(row => {
      startStakeValue += row.startStakeValue;
      endStakeValue += row.endStakeValue;
      intermediateStakeValue += row.intermediateStakeValue;
    })

    const waterFallNumbers = [];

    // Start Date and Intermediate Date
    waterFallNumbers.push({name: this.datePipe.transform(this.valDatesToBeConsideredForFundLevelVB[0], 'mediumDate'), y: startStakeValue && !isNaN(startStakeValue) ? +startStakeValue : 0, color: colors.primaryColor });

    fundLevelVBData.forEach(row => {
      const changeInNAV = row.intermediateStakeValue - row.startStakeValue;
      waterFallNumbers.push({name: row.companyName + " (1)", y: changeInNAV && !isNaN(changeInNAV) ? +changeInNAV : 0});
    })

    waterFallNumbers.push({name: this.datePipe.transform(this.valDatesToBeConsideredForFundLevelVB[1], 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
    // Start Date and Intermediate Date


    // Intermediate Date and endDate

    fundLevelVBData.forEach(row => {
      const changeInNAV = row.endStakeValue - row.intermediateStakeValue;
      waterFallNumbers.push({name: row.companyName + " (2)", y: changeInNAV && !isNaN(changeInNAV) ? +changeInNAV : 0});
    })

    waterFallNumbers.push({name: this.datePipe.transform(this.valDatesToBeConsideredForFundLevelVB[2], 'mediumDate'), isSum: true, color: colors.primaryColor });

    // Intermediate Date and endDate

    this.preparedCompanyBridge = true;

    return waterFallNumbers;
  }

  getDisplayValue(num): number {
    if(!num) {
      num = 0
    }

    if(isNaN(num)){
      num = 0;
    }

    return +num
  }

  getWaterFallNumbersForFundLevelVB(fundLevelVBData){
    const waterFallNumbers = [];

    const aggregatedWaterFallNumbers = {
      totalStartStakeValue : 0,
      totalFirstMetricImpact : 0,
      totalFirstMultipleImpact : 0,
      totalFirstNetDebtImpact : 0,
      totalFirstFxImpact : 0,
      totalFirstStakeImpact : 0,
      totalFirstOthersImpact : 0,
      totalFirstRealisedProceedsImpact : 0,

      totalFirstNewInvestmentsImpact: 0,
      totalFirstExitInvestmentsImpact: 0,
  
      totalIntermediateStakeValue : 0,
      totalSecondMetricImpact : 0,
      totalSecondMultipleImpact : 0,
      totalSecondNetDebtImpact : 0,
      totalSecondFxImpact : 0,
      totalSecondStakeImpact : 0,
      totalSecondOthersImpact : 0,
      totalSecondRealisedProceedsImpact : 0,

      totalSecondNewInvestmentsImpact: 0,
      totalSecondExitInvestmentsImpact: 0,
  
      totalEndStakeValue : 0,
    }

   
    fundLevelVBData.forEach(data => {
      aggregatedWaterFallNumbers.totalStartStakeValue += this.getDisplayValue(data.startStakeValue ? data.startStakeValue : 0);
      aggregatedWaterFallNumbers.totalFirstMetricImpact += this.getDisplayValue(data.firstMetricImpact ? data.firstMetricImpact : 0);
      aggregatedWaterFallNumbers.totalFirstMultipleImpact += this.getDisplayValue(data.firstMultipleImpact ? data.firstMultipleImpact : 0);
      aggregatedWaterFallNumbers.totalFirstNetDebtImpact += this.getDisplayValue(data.firstNetDebtImpact ? data.firstNetDebtImpact : 0);
      aggregatedWaterFallNumbers.totalFirstFxImpact += this.getDisplayValue(data.firstFxImpact ? data.firstFxImpact : 0);
      aggregatedWaterFallNumbers.totalFirstStakeImpact += this.getDisplayValue(data.firstStakeImpact ? data.firstStakeImpact : 0);
      aggregatedWaterFallNumbers.totalFirstOthersImpact += this.getDisplayValue(data.firstOthersImpact ? data.firstOthersImpact : 0);
      aggregatedWaterFallNumbers.totalFirstRealisedProceedsImpact += this.getDisplayValue(data.firstRealisedProceedsImpact ? data.firstRealisedProceedsImpact : 0);
      aggregatedWaterFallNumbers.totalIntermediateStakeValue += this.getDisplayValue(data.intermediateStakeValue ? data.intermediateStakeValue : 0);

      aggregatedWaterFallNumbers.totalFirstNewInvestmentsImpact += this.getDisplayValue(data.firstNewInvestmentsImpact ? data.firstNewInvestmentsImpact : 0);
      aggregatedWaterFallNumbers.totalFirstExitInvestmentsImpact += this.getDisplayValue(data.firstExitInvestmentsImpact ? data.firstExitInvestmentsImpact : 0);

      aggregatedWaterFallNumbers.totalSecondMetricImpact += this.getDisplayValue(data.secondMetricImpact ? data.secondMetricImpact : 0);
      aggregatedWaterFallNumbers.totalSecondMultipleImpact += this.getDisplayValue(data.secondMultipleImpact ? data.secondMultipleImpact : 0);
      aggregatedWaterFallNumbers.totalSecondNetDebtImpact += this.getDisplayValue(data.secondNetDebtImpact ? data.secondNetDebtImpact : 0);
      aggregatedWaterFallNumbers.totalSecondFxImpact += this.getDisplayValue(data.secondFxImpact ? data.secondFxImpact : 0);
      aggregatedWaterFallNumbers.totalSecondStakeImpact += this.getDisplayValue(data.secondStakeImpact ? data.secondStakeImpact : 0);
      aggregatedWaterFallNumbers.totalSecondOthersImpact += this.getDisplayValue(data.secondOthersImpact ? data.secondOthersImpact : 0);
      aggregatedWaterFallNumbers.totalSecondRealisedProceedsImpact += this.getDisplayValue(data.secondRealisedProceedsImpact ? data.secondRealisedProceedsImpact : 0); 
      aggregatedWaterFallNumbers.totalEndStakeValue += this.getDisplayValue(data.endStakeValue  ? data.endStakeValue : 0);

      aggregatedWaterFallNumbers.totalSecondNewInvestmentsImpact += this.getDisplayValue(data.secondNewInvestmentsImpact ? data.secondNewInvestmentsImpact : 0);
      aggregatedWaterFallNumbers.totalSecondExitInvestmentsImpact += this.getDisplayValue(data.secondExitInvestmentsImpact ? data.secondExitInvestmentsImpact : 0);

    })

    this.aggregatedWaterFallNumbers = aggregatedWaterFallNumbers;

    const keys = Object.keys(aggregatedWaterFallNumbers);

    keys.forEach( key => {
        if(key == "totalStartStakeValue" || key == "totalEndStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), y: aggregatedWaterFallNumbers[key] ? +aggregatedWaterFallNumbers[key] : 0, color: colors.primaryColor });
        }
        else if(key == "totalIntermediateStakeValue"){
          waterFallNumbers.push({name: this.datePipe.transform(this.getValueBridgeLabelByKey(key), 'mediumDate'), isIntermediateSum: true, color: colors.primaryColor });
        }
        else{
          waterFallNumbers.push({name: this.getValueBridgeLabelByKey(key), y: aggregatedWaterFallNumbers[key] ? +aggregatedWaterFallNumbers[key] : 0});
        }
    })

    return waterFallNumbers
  }

  getValDatesToBeConsideredForFundLevelVB(){
    let valDates = [];

    const currentDateObj = new Date();

    //Current Quarter
    const currentYear = currentDateObj.getFullYear();

    const quarterName = this.utilService.getQuarterName(this.datePipe.transform(currentDateObj, 'yyyy-MM-dd'));

    const currentQuarterMonth = this.utilService.getIndexOfMonthByQuarterName(quarterName);

    const currentQuarterDate = this.datePipe.transform(new Date(currentYear, currentQuarterMonth, 0), 'yyyy-MM-dd') //Identifying the last Day of the month
    valDates.push(currentQuarterDate);

    //Previous Quarter
    const prevQuarter = this.getPrevQuarterDateByMonthAndYear(currentQuarterMonth, currentYear);
    valDates.push(prevQuarter.prevQuarterDate);

    //Previous To Previous Quarter
    const prevToPrevQuarter = this.getPrevQuarterDateByMonthAndYear(prevQuarter.previousQuarterMonth, prevQuarter.prevYear);

    valDates.push(prevToPrevQuarter.prevQuarterDate);

    valDates = valDates.sort((date1, date2) => {
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2);
      
      const isdate1GreaterThanDate2 = this.utilService.compareDates(date1Obj, date2Obj);
      
      return isdate1GreaterThanDate2 == -1 ? 1 : isdate1GreaterThanDate2 == 0 ? 0 : -1;
    })

    return valDates;
  }

  getPrevQuarterDateByMonthAndYear(currentQuarterMonth, currentYear){
    let prevYear = currentYear;
    let previousQuarterMonth = 0;

    if(currentQuarterMonth == 3){
      prevYear = currentYear - 1;
      previousQuarterMonth = 12
    }
    else if(currentQuarterMonth == 6 || currentQuarterMonth == 9 || currentQuarterMonth == 12 ){
      prevYear = currentYear;
      previousQuarterMonth = currentQuarterMonth - 3;
    }

    const prevQuarterDate = this.datePipe.transform(new Date(prevYear, previousQuarterMonth, 0), 'yyyy-MM-dd')

    return {
      prevQuarterDate: prevQuarterDate,
      previousQuarterMonth: previousQuarterMonth,
      prevYear : prevYear
    }
  }

  getValueBridgeLabelByKey(key){
    let label = "";

    switch (key) {
      case "totalStartStakeValue":
        label = this.valDatesToBeConsideredForFundLevelVB[0];
        break;
    
      case "totalFirstMetricImpact":
        label = "Metric (1)";
        break;

      case "totalFirstOthersDebtImpact":
        label = "Debt & Others (1)";
        break;  
          
      case "totalFirstMultipleImpact":
        label = "Multiple (1)";
        break;

      case "totalFirstNetDebtImpact":
        label = "Net Debt (1)";
        break;
    
      case "totalFirstFxImpact":
        label = "FX (1)";
        break;
          
      case "totalFirstStakeImpact":
        label = "Stake (1)";
        break;

      case "totalFirstRealisedProceedsImpact":
        label = "Realised Proceeds (1)";
        break;

      case "totalFirstNewInvestmentsImpact":
        label = "New Investments (1)"
        break;

      case "totalFirstExitInvestmentsImpact":
        label = "Exit Investments (1)"
        break; 

      case "totalIntermediateStakeValue":
        label = this.valDatesToBeConsideredForFundLevelVB[this.valDatesToBeConsideredForFundLevelVB.length - 2];
        break;
    
      case "totalSecondMetricImpact":
        label = "Metric (2)";
        break;
    
      case "totalSecondOthersDebtImpact":
        label = "Debt & Others (2)";
        break; 

      case "totalSecondMultipleImpact":
        label = "Multiple (2)";
        break;

      case "totalSecondNetDebtImpact":
        label = "Net Debt (2)";
        break;
    
      case "totalSecondFxImpact":
        label = "FX (2)";
        break;
          
      case "totalSecondStakeImpact":
        label = "Stake (2)";
        break;

      case "totalSecondRealisedProceedsImpact":
        label = "Realised Proceeds (2)";
        break;

      case "totalSecondOthersImpact":
        label = "Others (2)"
        break; 
        
      case "totalSecondNewInvestmentsImpact":
        label = "New Investments (2)"
        break;

      case "totalFirstOthersImpact":
        label = "Exit Investments (2)"
        break;  

      case "totalSecondExitInvestmentsImpact":
        label = this.valDatesToBeConsideredForFundLevelVB[this.valDatesToBeConsideredForFundLevelVB.length - 1];
        break;    
    }

    return label;
  }

  getColumnFieldMappingByAggregatedKey(key){
    let mappingKey = "";

    switch (key) {
      case "totalStartStakeValue":
        mappingKey = "startStakeValue"
        break;
    
      case "totalFirstMetricImpact":
        mappingKey = "firstMetricImpact";
        break;
          
      case "totalFirstMultipleImpact":
        mappingKey = "firstMultipleImpact";
        break;

      case "totalFirstNetDebtImpact":
        mappingKey = "firstNetDebtImpact";
        break;
    
      case "totalFirstFxImpact":
        mappingKey = "firstFxImpact";
        break;
          
      case "totalFirstStakeImpact":
        mappingKey = "firstStakeImpact";
        break;

        case "totalFirstRealisedProceedsImpact":
          mappingKey = "firstRealisedProceedsImpact";
          break;

      case "totalFirstOthersImpact":
        mappingKey = "firstOthersImpact"
        break;

      case "totalIntermediateStakeValue":
        mappingKey = "intermediateStakeValue";
        break;
    
      case "totalSecondMetricImpact":
        mappingKey = "secondMetricImpact";
        break;
          
      case "totalSecondMultipleImpact":
        mappingKey = "secondMultipleImpact";
        break;

      case "totalSecondNetDebtImpact":
        mappingKey = "secondNetDebtImpact";
        break;
    
      case "totalSecondFxImpact":
        mappingKey = "secondFxImpact";
        break;
          
      case "totalSecondStakeImpact":
        mappingKey = "secondStakeImpact";
        break;

      case "totalSecondRealisedProceedsImpact":
        mappingKey = "secondRealisedProceedsImpact";
        break;

      case "totalSecondOthersImpact":
        mappingKey = "secondOthersImpact"
        break;  

      case "totalEndStakeValue":
        mappingKey = "endStakeValue";
        break;    
    }

    return mappingKey;
  }

  drawFundLevelValuationBridgeV2(waterFallNumbers, waterFallGraphShowLabels){
    if(this.valuationBridge){
      this.valuationBridge.destroy()
    }
    
    const waterFallChartData = this.drawWaterFallChart(waterFallNumbers, waterFallGraphShowLabels);

    return waterFallChartData;
  }

  drawValueCreationBridgeV2(waterFallNumbers, waterFallGraphShowLabels){    
    const waterFallChartData = this.drawWaterFallChart(waterFallNumbers, waterFallGraphShowLabels);
    
    return waterFallChartData;
  }

  getAggregatedNumbersForOneBridgeSystem(fundLevelVBData){
    const aggregatedWaterFallNumbers = {
      totalStartStakeValue : 0,
      
      totalFirstMetricImpact : 0,
      totalFirstMultipleImpact : 0,
      totalFirstRealisedProceedsImpact : 0,
      totalFirstNetDebtImpact : 0,
      totalFirstAdjustmentsToEquityValueImpact : 0,
      totalFirstOthersDebtImpact: 0,
      totalFirstStakeImpact : 0,
      totalFirstFxImpact : 0,
      totalFirstWaterfallImpact : 0,
      totalFirstOthersImpact : 0,

      totalFirstNewInvestmentsImpact: 0,
      totalFirstExitInvestmentsImpact: 0,

      totalEndStakeValue : 0,
    }

    fundLevelVBData.forEach(data => {
      aggregatedWaterFallNumbers.totalStartStakeValue += data.startStakeValue ? data.startStakeValue : 0;
      
      aggregatedWaterFallNumbers.totalFirstMetricImpact += data.firstMetricImpact ? data.firstMetricImpact : 0;
      aggregatedWaterFallNumbers.totalFirstMultipleImpact += data.firstMultipleImpact ? data.firstMultipleImpact : 0;
      aggregatedWaterFallNumbers.totalFirstRealisedProceedsImpact += data.firstRealisedProceedsImpact ? data.firstRealisedProceedsImpact : 0;
      aggregatedWaterFallNumbers.totalFirstNetDebtImpact += data.firstNetDebtImpact ? data.firstNetDebtImpact : 0;
      aggregatedWaterFallNumbers.totalFirstAdjustmentsToEquityValueImpact += data.firstAdjustmentsToEquityValueImpact ? data.firstAdjustmentsToEquityValueImpact : 0;
      aggregatedWaterFallNumbers.totalFirstOthersDebtImpact += data.firstOthersDebtImpact ? data.firstOthersDebtImpact : 0;
      aggregatedWaterFallNumbers.totalFirstStakeImpact += data.firstStakeImpact ? data.firstStakeImpact : 0;
      aggregatedWaterFallNumbers.totalFirstFxImpact += data.firstFxImpact ? data.firstFxImpact : 0;
      aggregatedWaterFallNumbers.totalFirstWaterfallImpact += data.firstWaterfallImpact ? data.firstWaterfallImpact : 0;
      aggregatedWaterFallNumbers.totalFirstOthersImpact += data.firstOthersImpact ? data.firstOthersImpact : 0;

      aggregatedWaterFallNumbers.totalFirstNewInvestmentsImpact += data.firstNewInvestmentsImpact ? data.firstNewInvestmentsImpact : 0;
      aggregatedWaterFallNumbers.totalFirstExitInvestmentsImpact += data.firstExitInvestmentsImpact ? data.firstExitInvestmentsImpact : 0;
      

      aggregatedWaterFallNumbers.totalEndStakeValue += data.endStakeValue  ? data.endStakeValue : 0;
    })

    return aggregatedWaterFallNumbers
  }

  getFormattedValueBridgeDataForOneBridgeSystem(fundLevelVBData){
    return fundLevelVBData.map( vbData => {
      const vbFormattedJSON = {
        companyName: vbData.companyName,
        startStakeValue: vbData.valueBridgeData[0].startStakeValue ? vbData.valueBridgeData[0].startStakeValue : 0,
        
        firstMetricImpact: vbData.valueBridgeData[0].metricImpact ? vbData.valueBridgeData[0].metricImpact : 0,
        firstMultipleImpact: vbData.valueBridgeData[0].multipleImpact ? vbData.valueBridgeData[0].multipleImpact : 0,
        firstNetDebtImpact: vbData.valueBridgeData[0].netDebtImpact ? vbData.valueBridgeData[0].netDebtImpact : 0,
        firstFxImpact: vbData.valueBridgeData[0].fxImpact ? vbData.valueBridgeData[0].fxImpact : 0,
        firstStakeImpact: vbData.valueBridgeData[0].stakeImpact ? vbData.valueBridgeData[0].stakeImpact : 0,
        firstOthersImpact: vbData.valueBridgeData[0].othersImpact ? vbData.valueBridgeData[0].othersImpact : 0,
        firstRealisedProceedsImpact: vbData.valueBridgeData[0].realisedProceedsImpact ? vbData.valueBridgeData[0].realisedProceedsImpact : 0,
        firstOthersDebtImpact: vbData.valueBridgeData[0].othersDebtImpact ? vbData.valueBridgeData[0].othersDebtImpact : 0,
        firstAdjustmentsToEquityValueImpact: vbData.valueBridgeData[0].adjustmentsToEquityValueImpact ? vbData.valueBridgeData[0].adjustmentsToEquityValueImpact : 0,
        firstWaterfallImpact: vbData.valueBridgeData[0].waterfallImpact ? vbData.valueBridgeData[0].waterfallImpact : 0,
        metricKey: vbData.valueBridgeData[0].metricKey,

        firstNewInvestmentsImpact: +vbData.valueBridgeData[0].newInvestments ? vbData.valueBridgeData[0].newInvestments : 0,
        firstExitInvestmentsImpact: +vbData.valueBridgeData[0].exitInvestments ? vbData.valueBridgeData[0].exitInvestments : 0,
        
        endStakeValue: vbData.valueBridgeData[0].endStakeValue ? vbData.valueBridgeData[0].endStakeValue : 0
      }

      return vbFormattedJSON;
    })
  }

  getAggregatedNumbersForMultiBridgeSystem(fundLevelVBData){
    const aggregatedWaterFallNumbers = {
      totalStartStakeValue : 0,

      totalFirstMetricImpact : 0,
      totalFirstMultipleImpact : 0,
      totalFirstNetDebtImpact : 0,
      totalFirstRealisedProceedsImpact : 0,
      totalFirstAdjustmentsToEquityValueImpact : 0,
      totalFirstOthersDebtImpact: 0,
      totalFirstStakeImpact : 0,
      totalFirstFxImpact : 0,
      totalFirstWaterfallImpact : 0,
      totalFirstOthersImpact : 0,

      totalFirstNewInvestmentsImpact: 0,
      totalFirstExitInvestmentsImpact: 0,
  
      totalIntermediateStakeValue : 0,
  
      totalSecondMetricImpact : 0,
      totalSecondMultipleImpact : 0,
      totalSecondNetDebtImpact : 0,
      totalSecondRealisedProceedsImpact : 0,
      totalSecondAdjustmentsToEquityValueImpact : 0,
      totalSecondOthersDebtImpact: 0,
      totalSecondStakeImpact : 0,
      totalSecondFxImpact : 0,
      totalSecondWaterfallImpact : 0,
      totalSecondOthersImpact : 0,
  
      totalSecondNewInvestmentsImpact: 0,
      totalSecondExitInvestmentsImpact: 0,
  
      totalEndStakeValue : 0,
    }

    fundLevelVBData.forEach(data => {
      aggregatedWaterFallNumbers.totalStartStakeValue += data.startStakeValue ? data.startStakeValue : 0;
      aggregatedWaterFallNumbers.totalFirstMetricImpact += data.firstMetricImpact ? data.firstMetricImpact : 0;
      aggregatedWaterFallNumbers.totalFirstMultipleImpact += data.firstMultipleImpact ? data.firstMultipleImpact : 0;
      aggregatedWaterFallNumbers.totalFirstNetDebtImpact += data.firstNetDebtImpact ? data.firstNetDebtImpact : 0;
      aggregatedWaterFallNumbers.totalFirstRealisedProceedsImpact += data.firstRealisedProceedsImpact ? data.firstRealisedProceedsImpact : 0;
      aggregatedWaterFallNumbers.totalFirstAdjustmentsToEquityValueImpact += data.firstAdjustmentsToEquityValueImpact ? data.firstAdjustmentsToEquityValueImpact : 0;
      aggregatedWaterFallNumbers.totalFirstOthersDebtImpact += data.firstOthersDebtImpact ? data.firstOthersDebtImpact : 0;
      aggregatedWaterFallNumbers.totalFirstStakeImpact += data.firstStakeImpact ? data.firstStakeImpact : 0;
      aggregatedWaterFallNumbers.totalFirstFxImpact += data.firstFxImpact ? data.firstFxImpact : 0;
      aggregatedWaterFallNumbers.totalFirstWaterfallImpact += data.firstWaterfallImpact ? data.firstWaterfallImpact : 0;
      aggregatedWaterFallNumbers.totalFirstOthersImpact += data.firstOthersImpact ? data.firstOthersImpact : 0;
      aggregatedWaterFallNumbers.totalFirstNewInvestmentsImpact += data.firstNewInvestmentsImpact ? data.firstNewInvestmentsImpact : 0;
      aggregatedWaterFallNumbers.totalFirstExitInvestmentsImpact += data.firstExitInvestmentsImpact ? data.firstExitInvestmentsImpact : 0;

      aggregatedWaterFallNumbers.totalIntermediateStakeValue += data.intermediateStakeValue ? data.intermediateStakeValue : 0;

      aggregatedWaterFallNumbers.totalSecondMetricImpact += data.secondMetricImpact ? data.secondMetricImpact : 0;
      aggregatedWaterFallNumbers.totalSecondMultipleImpact += data.secondMultipleImpact ? data.secondMultipleImpact : 0;
      aggregatedWaterFallNumbers.totalSecondRealisedProceedsImpact += data.secondRealisedProceedsImpact ? data.secondRealisedProceedsImpact : 0; 
      aggregatedWaterFallNumbers.totalSecondNetDebtImpact += data.secondNetDebtImpact ? data.secondNetDebtImpact : 0;
      aggregatedWaterFallNumbers.totalSecondAdjustmentsToEquityValueImpact += data.secondAdjustmentsToEquityValueImpact ? data.secondAdjustmentsToEquityValueImpact : 0;
      aggregatedWaterFallNumbers.totalSecondOthersDebtImpact += data.secondOthersDebtImpact ? data.secondOthersDebtImpact : 0;
      aggregatedWaterFallNumbers.totalSecondStakeImpact += data.secondStakeImpact ? data.secondStakeImpact : 0;
      aggregatedWaterFallNumbers.totalSecondFxImpact += data.secondFxImpact ? data.secondFxImpact : 0;
      aggregatedWaterFallNumbers.totalSecondWaterfallImpact += data.secondWaterfallImpact ? data.secondWaterfallImpact : 0;
      aggregatedWaterFallNumbers.totalSecondOthersImpact += data.secondOthersImpact ? data.secondOthersImpact : 0;
      
      aggregatedWaterFallNumbers.totalSecondNewInvestmentsImpact += data.secondNewInvestmentsImpact ? data.secondNewInvestmentsImpact : 0;
      aggregatedWaterFallNumbers.totalSecondExitInvestmentsImpact += data.secondExitInvestmentsImpact ? data.secondExitInvestmentsImpact : 0;

      aggregatedWaterFallNumbers.totalEndStakeValue += data.endStakeValue  ? data.endStakeValue : 0;
    })

    return aggregatedWaterFallNumbers;
  }

  getFormattedValueBridgeDataForMultipleBridgeSystem(fundLevelVBData){
    return fundLevelVBData.map( vbData => {
      const vbFormattedJSON = {
        companyName: vbData.companyName,
        startStakeValue: vbData.valueBridgeData[0].startStakeValue ? vbData.valueBridgeData[0].startStakeValue : 0,
        firstMetricImpact: vbData.valueBridgeData[0].metricImpact ? vbData.valueBridgeData[0].metricImpact : 0,
        firstMultipleImpact: vbData.valueBridgeData[0].multipleImpact ? vbData.valueBridgeData[0].multipleImpact : 0,
        firstNetDebtImpact: vbData.valueBridgeData[0].netDebtImpact ? vbData.valueBridgeData[0].netDebtImpact : 0,
        firstFxImpact: vbData.valueBridgeData[0].fxImpact ? vbData.valueBridgeData[0].fxImpact : 0,
        firstStakeImpact: vbData.valueBridgeData[0].stakeImpact ? vbData.valueBridgeData[0].stakeImpact : 0,
        firstOthersImpact: vbData.valueBridgeData[0].othersImpact ? vbData.valueBridgeData[0].othersImpact : 0,
        firstRealisedProceedsImpact: vbData.valueBridgeData[0].realisedProceedsImpact ? vbData.valueBridgeData[0].realisedProceedsImpact : 0,
        firstOthersDebtImpact: vbData.valueBridgeData[0].othersDebtImpact ? vbData.valueBridgeData[0].othersDebtImpact : 0,
        firstAdjustmentsToEquityValueImpact :vbData.valueBridgeData[0].adjustmentsToEquityValueImpact ? vbData.valueBridgeData[0].adjustmentsToEquityValueImpact : 0,
        firstWaterfallImpact : vbData.valueBridgeData[0].waterfallImpact ? vbData.valueBridgeData[0].waterfallImpact : 0,
        metricKey: vbData.valueBridgeData[0].metricKey,

        firstNewInvestmentsImpact: +vbData.valueBridgeData[0].newInvestments ? vbData.valueBridgeData[0].newInvestments : 0,
        firstExitInvestmentsImpact: +vbData.valueBridgeData[0].exitInvestments ? vbData.valueBridgeData[0].exitInvestments : 0,
        
        intermediateStakeValue: vbData.valueBridgeData[1].startStakeValue ? vbData.valueBridgeData[1].startStakeValue : 0,
        
        secondMetricImpact: vbData.valueBridgeData[1].metricImpact ? vbData.valueBridgeData[1].metricImpact : 0,
        secondMultipleImpact: vbData.valueBridgeData[1].multipleImpact ? vbData.valueBridgeData[1].multipleImpact : 0,
        secondNetDebtImpact: vbData.valueBridgeData[1].netDebtImpact ? vbData.valueBridgeData[1].netDebtImpact : 0,
        secondFxImpact: vbData.valueBridgeData[1].fxImpact ? vbData.valueBridgeData[1].fxImpact : 0,
        secondStakeImpact: vbData.valueBridgeData[1].stakeImpact ? vbData.valueBridgeData[1].stakeImpact : 0,
        secondOthersImpact: vbData.valueBridgeData[1].othersImpact ? vbData.valueBridgeData[1].othersImpact : 0,
        secondRealisedProceedsImpact: vbData.valueBridgeData[1].realisedProceedsImpact ? vbData.valueBridgeData[1].realisedProceedsImpact : 0,
        secondOthersDebtImpact: vbData.valueBridgeData[1].othersDebtImpact ? vbData.valueBridgeData[1].othersDebtImpact : 0,
        secondAdjustmentsToEquityValueImpact :vbData.valueBridgeData[1].adjustmentsToEquityValueImpact ? vbData.valueBridgeData[1].adjustmentsToEquityValueImpact : 0,
        secondWaterfallImpact : vbData.valueBridgeData[1].waterfallImpact ? vbData.valueBridgeData[1].waterfallImpact : 0,
        secondNewInvestmentsImpact: +vbData.valueBridgeData[1].newInvestments ? vbData.valueBridgeData[1].newInvestments : 0,
        secondExitInvestmentsImpact: +vbData.valueBridgeData[1].exitInvestments ? vbData.valueBridgeData[1].exitInvestments : 0,

        endStakeValue: vbData.valueBridgeData[1].endStakeValue ? vbData.valueBridgeData[1].endStakeValue : 0
      }

      return vbFormattedJSON;
    })
  }
}
