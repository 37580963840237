import { DatePipe, DecimalPipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from 'src/environments/environment';
import { DebtPortfolioService } from "../debt-portfolio.service";
import { DmDecimalPipe } from "src/app/pipes/dm-decimal/dm-decimal.pipe";
import { UtilService } from "src/app/utils/util.service";
import { ToastService } from "src/app/utils/toast.service";
import moment from "moment";
import { of } from "rxjs";
import { catchError, concatMap } from "rxjs/operators";
import { TranslateService } from "src/app/services/translation.service";

@Component({
  selector: "app-going-concern-analysis",
  templateUrl: "./going-concern-analysis.component.html",
  styleUrls: ["./going-concern-analysis.component.scss"],
})
export class GoingConcernAnalysisComponent implements OnInit {

  presentValErr: boolean;
  waterfall: any = [];
  presentValue: any = {};
  toggleEditClaims: boolean = false;
  exitDate;
  securityTypeObj:any= {
    Credit:this.translateService.getLabel("Credit"),
    Equity:this.translateService.getLabel("Equity"),
  };
  claim: any = {
    securityName: "",
    valuationDateId: "",
    amount: 0,
    ranking: 0,
  };
  adjustment: any = {};
  popupHeader = "";
  buttonLabel = "";
  goingConcernInputs : any = {
    adjusments: { low: 0, midpoint: 0 , high: 0},
    evMultiple: { low: 0, midpoint: 0 , high: 0},
    ebitda: { low: 0, midpoint: 0 , high: 0},
    cash: { low: 0, midpoint: 0 , high: 0},
    enterpriseValue: { low: 0, midpoint: 0 , high: 0},
    revenue : { low: 0, midpoint: 0 , high: 0},
    grossprofit: { low: 0, midpoint: 0 , high: 0},
    ebit: { low: 0, midpoint: 0 , high: 0},
    adjustments: [],
    sensitivity: 0
  };
  securityNames = [];
  selectedSecurityId;

  issuerCompanyId;
  valuationDate;
  securities;
  showWaterFall: boolean = false;
  showPresentValue: boolean = false;
  debtWaterFallWidgetKey = "DEBT_WATERFALL_DATA";
  fetchedDebtModelId: any;

  expectedExitDate: string;
  pristineExpectedExitDate: string;
  pristineSecurities = [];
  companyId: string;
  waterfallId: string;
  securitiesToBeDeleted: string[] = [];
  exceedsWeightLimit: boolean;

  evMultiples = [
    {
      key: 'revenue',
      displayValue: this.translateService.getLabel('ev_revenue')
    },
    {
      key: 'grossprofit',
      displayValue: this.translateService.getLabel('ev_gross_profit')
    },
    {
      key: 'ebitda',
      displayValue: this.translateService.getLabel('ev_ebitda')
    },
    {
      key: 'ebit',
      displayValue: this.translateService.getLabel('ev_ebit')
    }
  ];

  valuationDates = [];

  noEvSelection: boolean;
  concludeOnWaterfall: boolean;
  selectedValuationDate: string;

  showGoingConcernInputs: boolean;

  @Output() valuationDateEvent = new EventEmitter<string>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private dataService: DataService,
    private datePipe: DatePipe,
    private numberFormatter: DecimalPipe,
    public debtPortfolioService: DebtPortfolioService,
    public dmDecimalPipe: DmDecimalPipe,
    private snackBarService: ToastService,
    private utilService: UtilService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // this.selectedValuationDate = this.debtPortfolioService.recentValuationDate;
    this.getIssuerComapnyId();
  }

  onValuationDateChange(event) {
    console.log(event);
    console.log(this.selectedValuationDate);
    this.selectedValuationDate = event.value;
    this.getIssuerComapnyId(true);
  }

  getIssuerComapnyId(fromEvent: boolean = false) {
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get("issuerCompanyId");
    this.companyId = params.get("companyId");
    this.getWaterfallId(fromEvent);
    // this.getEquitytForm();
    // this.getGoingConcernInputs();
    // this.getGoingConcernWaterfall();
  }

  async getWaterfallId(fromEvent: boolean) {
    try {
    this.utilService.showLoadingPopup();
    const valuationDatesResp = await this.dataService.getWaterfallValuationDates(this.issuerCompanyId);
    const valudationDates = valuationDatesResp['body']['response']['valuationDates'];
    valudationDates.sort();
    valudationDates.reverse();
    this.valuationDates = valudationDates;
    if(!fromEvent) {
      this.selectedValuationDate = valudationDates[0];
    }
    this.debtPortfolioService.getWaterfallFieldStatuses(this.companyId, this.selectedValuationDate);
    const response = await this.dataService.getWaterfallId(this.issuerCompanyId, this.selectedValuationDate)
    this.utilService.closeAllPopups();
      if(response) {
        this.waterfallId = response.body['response']['waterfallId'];
        this.concludeOnWaterfall = response.body['response']['concludedOnWaterfall'];
        //this.getEquitytForm();
        this.getAllSecurities();
        this.getGoingConcernInputs();
        this.getGoingConcernWaterfall();
      }
    } catch(error) {
      this.utilService.closeAllPopups();
      console.log(error);
    }
  }

  getEquitytForm() {
    this.dataService
      .getEquityFormWithValDate(this.issuerCompanyId, this.selectedValuationDate)
      .then((response: any) => {
        const equityData = response.body.response[0];
        this.valuationDate = equityData.valuationDate;
        this.valuationDateEvent.emit(this.valuationDate);
        this.getAllSecurities();
      });
  }

  getAllSecurities() {
    this.dataService
      .getAllSecurities(this.issuerCompanyId, this.selectedValuationDate, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.securityNames = response.body.response.securities;
        }
      });
  }

  getGoingConcernInputs() {
    this.dataService
      .getGoingConcernInputs(this.issuerCompanyId, this.selectedValuationDate, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.showGoingConcernInputs = true;
          this.goingConcernInputs = response.body.response;
        }
      }, 
      (err) => {
        this.snackBarService.openSnackBar(this.translateService.getLabel("failed_to_load_inputs"));
        this.showGoingConcernInputs = false;
        console.log(err);
      });
  }

  updateFieldStatus(key: string, valueType: string) {
    this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs[valueType][key] = true;
  }

  calculateGoingConcernInput() {
    this.validateWeight(this.goingConcernInputs);
    const payload = {
      sensitivity: this.goingConcernInputs?.sensitivity,
      ebitda: this.goingConcernInputs.ebitda?.midpoint,
      evMultiple: this.goingConcernInputs.evMultiple?.midpoint,
      cash: this.goingConcernInputs.cash?.midpoint,
      adjustments: this.goingConcernInputs?.adjustments
        ? this.goingConcernInputs?.adjustments
        : [],
      // weight: this.goingConcernInputs
    };
    const getResp = this.goingConcernInputs;
    console.log('There I am', getResp);
    this.dataService
      .calculateGoingConcernInput(getResp, this.selectedValuationDate ,this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.goingConcernInputs = response.body.response;
          this.showWaterFall = false;
          this.showPresentValue = false;
        }
      });
  }

  saveGoingConcernInputs() {
    this.dataService
      .saveGoingConcernInputs(this.issuerCompanyId, this.goingConcernInputs, this.selectedValuationDate, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.debtPortfolioService.saveWaterfallFieldsStatusesToWidgetDB(this.selectedValuationDate, this.debtPortfolioService.waterfallFieldStatuses, this.companyId);
          this.snackBarService.openSnackBar(this.translateService.getLabel("suc_saved"));
          this.saveAndCalculateGoingConcernWaterfall();
          this.showWaterFall = true;
        }
      });
  }

  getGoingConcernWaterfall() {
    this.dataService
      .getGoingConcernWaterfall(this.issuerCompanyId, this.selectedValuationDate , this.companyId, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.waterfall = response.body.response.waterfall || [];
          this.securities = response.body.response.securities || [];
          this.pristineSecurities = this.securities.map(o => { return {...o } } ) || [];
          this.toggleEditClaims = false;
          this.showPresentValue = true;
          this.showWaterFall = true;
          this.exitDate = response.body.response.exitDate || "";
        } else {
          this.waterfall = [];
          this.securities = [];
          this.showWaterFall = true;
          this.toggleEditClaims = false;
          this.showPresentValue = false;
        }
        if(this.exitDate) {
          this.getGoingConcernPresentValue();
        }
      });
  }

  editClaims() {
    this.pristineExpectedExitDate = moment(this.exitDate).format('YYYY-MM-DD');
    this.toggleEditClaims = true;
  }

  saveAndCalculateGoingConcernWaterfall() {
    let payload = {
      securities: this.securities,
      exitDate: moment(this.exitDate).format('YYYY-MM-DD'),
      valuationDate: this.selectedValuationDate
    };
    if(this.securitiesToBeDeleted?.length > 0) {
      this.dataService.deleteSecurityByIds(this.waterfallId, this.securitiesToBeDeleted).subscribe((deleteResp) => {
        console.log(deleteResp);
        this.securitiesToBeDeleted = [];
      });
    }
    this.dataService
      .calculateGoingConcernWaterfall(this.issuerCompanyId, payload, this.companyId, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          this.securities = response.body.response.securities;
          this.pristineSecurities = this.securities.map(o => { return {...o } } ) || [];
          this.waterfall = response.body.response.waterfall;
          this.exitDate = response.body.response.exitDate;
          // this.expectedExitDate = response.body.exitDate;
          this.toggleEditClaims = false;
          this.showPresentValue = true;
          if(Object.keys(this.presentValue).length) {
            this.updatePresentvalues();
          } else {
            this.getGoingConcernPresentValue();
          }
        }
      });
  }

  deleteClaim(index, security) {
    if(security.id) {
      this.securitiesToBeDeleted.push(security.id);
    }
    this.securities.splice(index, 1);
  }

  dirtyTrigger(event, ngModel) {
    this.presentValErr = ngModel?.dirty;
  }

  updateConcludedNav(security) {
    security.high.concluded = (security.high.fairValue) * (security.high.stake/100);
    security.mid.concluded = (security.mid.fairValue) * (security.mid.stake/100);
    security.low.concluded = (security.low.fairValue) * (security.low.stake/100);
  }

  openPopUpEdit(content, dataInput) {
    this.popupHeader = this.translateService.getLabel("edit");
    this.buttonLabel = this.translateService.getLabel("save");
    this.modalService.open(content, {
      centered: true,
      windowClass: "adjustments",
      size: "lg",
    });

    this.adjustment = dataInput;
  }
  openPopUpAdd(content) {
    this.popupHeader = this.translateService.getLabel("add");
    this.buttonLabel = this.translateService.getLabel("add");;
    this.modalService.open(content, {
      centered: true,
      windowClass: "adjustments",
      size: "lg",
    });
    this.adjustment = {};
  }

  addUpdateAdjustments(addUpdateFlag) {
    if (addUpdateFlag === "Add") {
      this.goingConcernInputs.adjustments.push(this.adjustment);
      this.adjustment = {};
      this.calculateGoingConcernInput();
      this.modalService.dismissAll();
    } else {
      this.calculateGoingConcernInput();
      this.modalService.dismissAll();
    }
  }
  deleteAdjustment(index) {
    this.goingConcernInputs.adjustments.splice(index, 1);
    this.calculateGoingConcernInput();
  }

  selectedSecurity(option) {
    this.claim.valuationDateId = option.valuationDateId;
    // this.claim.companyId = option.companyId ?? '';
    this.claim.fundExposure = true;
  }

  openPopupAddClaim(content) {
    this.popupHeader = this.translateService.getLabel("add");
    this.buttonLabel = this.translateService.getLabel("add");;
    this.modalService.open(content, {
      centered: true,
      windowClass: "claims",
      // size: "lg",
    });
    this.claim = {};
  }
  openPopupEditClaim(content, dataInput) {
    this.popupHeader = this.translateService.getLabel("edit");
    this.buttonLabel = this.translateService.getLabel("save");
    this.modalService.open(content, {
      centered: true,
      windowClass: "claims",
      size: "lg",
    });
    this.claim = dataInput;
  }
  addUpdateClaims(addUpdateFlag) {
    console.log(this.claim, 'pre filter');
    if (addUpdateFlag === "Add") {
      this.claim.valuationDateId = this.claim.valuationDateId
        ? this.claim.valuationDateId
        : "";
      // this.claim.companyId = this.claim.companyId ?? "";
      this.claim = {...this.claim, id: this.claim.id ?? '', fundExposure: this.claim.fundExposure ?? false };
      console.log(this.claim, 'post id');
      this.securities.push(this.claim);
      this.claim = {};
      this.modalService.dismissAll();
    } else {
      this.modalService.dismissAll();
    }
  }

  getGoingConcernPresentValue() {
    //this.saveExitDateInWidget();
    // const securities = this.securities;
    // const selectedSecurities = securities.filter((each) => {
    //   if (each.valuationDateId) {
    //     return each;
    //   }
    // });
    // selectedSecurities.map((x:any) => {
    //   x.amount_high = x.amount;
    //   x.amount_mid = x.amount;
    //   x.amount_low = x.amount;
    //   delete x.amount;
    //   return x;
    // })
    const payload = {
      exitDate: this.datePipe.transform(this.exitDate, "yyyy-MM-dd"),
      valuationDate: this.selectedValuationDate,
      issuerCompanyId: this.issuerCompanyId,
      // selectedSecurities: selectedSecurities,
    };
    this.dataService
      .getGoingConcernPresentValue(this.companyId, payload, this.waterfallId)
      .subscribe((response: any) => {
        if (response.body.response) {
          // this.presentValue = response.body.response;
          // this.fetchedDebtModelId = response.body.response.presentValue[0].debtModelId;
          this.presentValue = response.body.response;
          this.fetchedDebtModelId = response.body.response.presentValue[0]?.debtModelId;
        }
      });
  }

  getFormattedNumber(num) {
    if (num === 'undefined') { return 0; }
    if (isNaN(num)) return num;
    const newValue = this.numberFormatter.transform(Math.abs(num), "1.1-1");
    return num < 0 ? `(${newValue})` : newValue;
  }

  navigateToDiscountRate(item) {
    const debtModelId = item?.debtModelId;
    if(!debtModelId) {
      return;
    }
    let url = environment.portalUrl + environment.debtModel + "#/valuation/" + debtModelId + "/discount-rate";
    window.open(url);
  }

  updatePresentvalues() {
    const payload = {
      exitDate: moment(this.exitDate).format('YYYY-MM-DD'),
      valuationDate: this.presentValue.valuationDate,
      issuerCompanyId: this.issuerCompanyId,
      securities: this.presentValue.presentValue,
    };
    this.utilService.showLoadingPopup();
    this.dataService
      .updateGoingConcernPresentValue(this.companyId, payload, this.waterfallId)
      .subscribe((response: any) => {
        this.utilService.closeAllPopups();
        this.snackBarService.openSnackBar(this.translateService.getLabel("suc_saved"));
        // if (response.body.response) {
          this.presentValErr = false;
          this.getGoingConcernPresentValue();
        // }
      }, 
      (err) => {
        this.utilService.closeAllPopups();
        this.utilService.showMessage(("err_failed_save"), this.translateService.getLabel("ok"));
        console.log(`Failed to save present values...`, err);
      }
      );
  }

  modelChange(event) {
    console.log(event, this.dmDecimalPipe.transform(event));
    return this.dmDecimalPipe.transform(event);
  }

  onMultiplesChange(key, event) {
    this.goingConcernInputs[key].checked = event?.checked;
    if(!event.checked) {
      this.goingConcernInputs[key].weight = 0;
    }
    this.noEvSelection = !(this.goingConcernInputs.revenue.checked || this.goingConcernInputs.grossprofit.checked || this.goingConcernInputs.ebitda.checked || this.goingConcernInputs.ebit.checked);
    this.calculateGoingConcernInput();
  }

  validateWeight(goingConcernInputs) {
    const weightLimit = 100;
    let totalWeight = goingConcernInputs.revenue.weight + goingConcernInputs.grossprofit.weight + goingConcernInputs.ebitda.weight + goingConcernInputs.ebit.weight;
    this.exceedsWeightLimit = totalWeight > weightLimit;
  }

  onWaterfallConclude() {
    this.dataService.updateWaterfallId(this.issuerCompanyId, this.selectedValuationDate, this.concludeOnWaterfall).subscribe((response) => {
      console.log(response);
      this.snackBarService.openSnackBar(this.translateService.getLabel("suc_saved"));
    },
    (err) => {
      console.log(err);
      this.snackBarService.openSnackBar(this.translateService.getLabel("err_failed_save"));
    }
    );
  }

  resetMannualInputs() {
    this.utilService.showLoadingPopup();
    this.dataService.getResetValuesForWaterfallInputs(this.waterfallId).pipe(concatMap((resetWaterFallResponse) => {
      if (resetWaterFallResponse['body']['response']) {
        const inputResetValues = resetWaterFallResponse['body']['response'];
        this.resetValues(inputResetValues);
        return this.dataService.calculateGoingConcernInput(this.goingConcernInputs, this.selectedValuationDate, this.waterfallId);
      } else {
        this.utilService.closeAllPopups();
        this.snackBarService.openSnackBar(this.translateService.getLabel("failed_to_get_default_inputs"));
        return of(null)
      }
    }),
      catchError((err) => {
          this.utilService.closeAllPopups();
          return of(err) 
        })
    ).subscribe((response) => {
      if (response['body']['response']) {
        this.utilService.closeAllPopups();
        this.goingConcernInputs = response.body.response;
        this.showWaterFall = false;
        this.showPresentValue = false;
      } else {
        this.utilService.closeAllPopups();
        this.snackBarService.openSnackBar(this.translateService.getLabel("failed_calculate_inputs"));
        return of(null)
      }
    }),
      catchError((err) => {
        this.utilService.closeAllPopups();
        return of(err) 
      });
  }

  resetValues(inputResetValues) {
    // reset only if multiples are selected in the UI
    if(this.goingConcernInputs.ebit.checked) {
      // mutate table object to reflect values
      this.goingConcernInputs.ebit.midpoint = inputResetValues.ebit.midpoint;

      this.goingConcernInputs.ebitMultiple.low = inputResetValues.ebitMultiple.low;
      this.goingConcernInputs.ebitMultiple.midpoint = inputResetValues.ebitMultiple.midpoint;
      this.goingConcernInputs.ebitMultiple.high = inputResetValues.ebitMultiple.high;

      // reset field colors
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.low.ebitMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.ebitMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.high.ebitMultiple = false;
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.ebit = false;
    }

    if(this.goingConcernInputs.ebitda.checked) {
      // mutate table object to reflect values
      this.goingConcernInputs.ebitda.midpoint = inputResetValues.ebitda.midpoint;

      this.goingConcernInputs.ebitdaMultiple.low = inputResetValues.ebitdaMultiple.low;
      this.goingConcernInputs.ebitdaMultiple.midpoint = inputResetValues.ebitdaMultiple.midpoint;
      this.goingConcernInputs.ebitdaMultiple.high = inputResetValues.ebitdaMultiple.high;

      // reset field colors
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.low.ebitdaMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.ebitdaMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.high.ebitdaMultiple = false;
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.ebitda = false;
    }

    if(this.goingConcernInputs.grossprofit.checked) {
      // mutate table object to reflect values
      this.goingConcernInputs.grossprofit.midpoint = inputResetValues.grossprofit.midpoint;

      this.goingConcernInputs.grossprofitMultiple.low = inputResetValues.grossprofitMultiple.low;
      this.goingConcernInputs.grossprofitMultiple.midpoint = inputResetValues.grossprofitMultiple.midpoint;
      this.goingConcernInputs.grossprofitMultiple.high = inputResetValues.grossprofitMultiple.high;

      // reset field colors
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.low.grossprofitMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.grossprofitMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.high.grossprofitMultiple = false;
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.grossprofit = false;
    }

    if(this.goingConcernInputs.revenue.checked) {
      // mutate table object to reflect values
      this.goingConcernInputs.revenue.midpoint = inputResetValues.revenue.midpoint;

      this.goingConcernInputs.revenueMultiple.low = inputResetValues.revenueMultiple.low;
      this.goingConcernInputs.revenueMultiple.midpoint = inputResetValues.revenueMultiple.midpoint;
      this.goingConcernInputs.revenueMultiple.high = inputResetValues.revenueMultiple.high;

      // reset field colors
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.low.revenueMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.revenueMultiple = this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.high.revenueMultiple = false;
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.revenue = false;
    }

      // mutate cash table object
      this.goingConcernInputs.cash.low = inputResetValues.cash.low;
      this.goingConcernInputs.cash.midpoint = inputResetValues.cash.midpoint;
      this.goingConcernInputs.cash.high = inputResetValues.cash.high;

      // reset field colors
      this.debtPortfolioService.waterfallFieldStatuses.goingConcernInputs.midpoint.cash = false;

  }

  onCancelCalculateGoingConcernWaterfall() {
    this.securitiesToBeDeleted = [];
    this.toggleEditClaims = false;
    this.securities = this.pristineSecurities.map(o => { return {...o}});
    this.exitDate = this.pristineExpectedExitDate;
  }
}