import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import cloneDeep from 'lodash/cloneDeep';
import { DataService } from '../services/data.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UserManagementService } from '../services/user-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../utils/util.service';
import { Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material';

declare let noUiSlider: any;
declare let wNumb: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  logoImage;
  editable: boolean = false;
  
  today = new Date();

  isMasterUser = false;
  
  @ViewChild("ref", { static: false }) inputRef: ElementRef;

  readonly visible = true;
  readonly selectable = false;
  readonly removable = true;
  readonly addOnBlur = false;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  regionInputValues = [];
  filteredOptions = [];

  profile = {
    name: "",
    location: "",
    foundedIn: "",
    about: "",
    image: "",
    team: [],
    region: [],
    companyLabels: [],
    labelUpdates: null,
    currency: {},
    socialIcons : [
      {
        "icon": "fab fa-linkedin-in",
        "link":"",
        "background":"#1686B0"
      },
      {
        "icon": "fab fa-twitter",
        "link":"",
        "background":"#1CB7EB"
      },
      {
        "icon": "fab fa-facebook-f",
        "link":"",
        "background":"#4E71A8"
      }
    ]
  }

  newMember = {name: "", link: ""}
  companyLabels = []
  newLabel = ""
  user = {};
  
  constructor(public dataService: DataService, private ums: UserManagementService, private sanitizer: DomSanitizer,
    private utilService: UtilService, private router: Router) { }

  ngOnInit() {
    this.user = this.ums.getUserDetails();
    this.isMasterUser = this.ums.isMasterUser();

    const details = this.user["organization"].details
    if(details) {
      this.profile = JSON.parse(details).orgInfo
      this.regionInputValues = this.profile.region ? this.profile.region : [];

      if(this.profile.companyLabels) {
        this.companyLabels = this.profile.companyLabels.map(item => { return {label: item, oldLabel: item} })
      }
    } 
    
    this.profile.name = this.user["organization"].orgName;

    if(!this.profile["team"]) {
      this.profile["team"] = [];
    }

    this.currencySelected(this.profile.currency ? this.profile.currency["id"]: null);

    this.downloadImage(this.profile.image);
  }


  currencySearch(event) {
    if(!event.target.value || event.target.value.length === 0) {
      this.filteredCurrencies = this.currencyOptions
    } else {
      this.filteredCurrencies = this.currencyOptions.filter(cur=>cur.id.indexOf(event.target.value.toUpperCase()) >= 0 || cur.country.toUpperCase().indexOf(event.target.value.toUpperCase()) >= 0)
    }
  }

  currencySelected(value) {
    if(!value || value.length == 0) return;

    this.currencySelection = value
    this.profile.currency = this.currencyOptions.find(cur=>cur.id.indexOf(value.toUpperCase()) == 0)

    this.filteredCurrencies = this.currencyOptions.filter(cur=>cur.id.indexOf(value.toUpperCase()) >= 0 || cur.country.toUpperCase().indexOf(value.toUpperCase()) >= 0)
  }

  pickFile(logoInput){
    logoInput.click();  
  }

  pickImage(event): void {
    var list:FileList = event.target.files
    var imageFile: File = list.item(0);

    // console.log("File selected", imageFile.name)
    this.readImage(imageFile);
    this.upload(imageFile);
  }

  readImage(imageFile: File): void {
    var reader = new FileReader();

    // read file as data url
    reader.readAsDataURL(imageFile); 

    // called once readAsDataURL is completed
    reader.onload = (event) => { 
      // console.log("readAsDataURL is completed");
      
      const imageSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        reader.result.toString()
      );
      this.logoImage = imageSrc;
    }
  }

  upload(imageFile){
    const orgId = this.ums.getUserDetails().organization.id;

    this.dataService.upload(imageFile, orgId).subscribe((event)=>{
      // console.log("upload image", event);

      if (event.type === HttpEventType.UploadProgress) {
        // console.log("progres", Math.round(100 * event.loaded / event.total));

      } else if (event instanceof HttpResponse) {
        // console.log('File is completely uploaded!', event.body);

        const body = event["body"];
        const bodyJson = JSON.parse(body.toString());
        // console.log("Response", bodyJson["response"]);
        this.profile.image = bodyJson["response"].fileId;
      }
    }, (error)=>{
      console.log("error in Uploaded",error)
    })
  }

  downloadImage(fileId) {
    if(!fileId) return;

    this.dataService.download(fileId).subscribe(
      (res: any) => {
        const reader = new FileReader();
        reader.addEventListener( 'load', () => {
            this.logoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
              reader.result.toString()
            );
          }, false
        );

        if (res) {
          reader.readAsDataURL(res.body);
        }
      },
      error => {
        console.log('error in fetching the logo', error);
      }
    );
  }

  save(){
    if(!this.addNewMember(true)) {
      return;
    }

    if(this.profile.team && this.profile.team.length > 0) {
      let emptyField = false;
      this.profile.team.forEach(m => {
        if(m.name && m.name.length >= 3 && m.link && m.link.length > 4) {

        } else {
          emptyField = true;
        }
      });
      if(emptyField) {
       this.utilService.showMessage("Please provide valid team member name & linkedIn url.", "Ok");
       return;
      }
    }

    this.validateCompanyLabels();

    this.editable = false; 
    
    this.ums.saveOrgDetails(this.profile.name, this.profile).subscribe(result=>{
      // console.log("Company Profile is saved successfully!", result.body)      
      const dialog = this.utilService.showMessage("Successfully saved the new updates. Please re-login.", "Ok")
      dialog.afterClosed().subscribe(()=>{
        this.ums.logout();
        
        window.location.reload();
      })
    }, error=>{
      console.log("Failed to save company profile.", error);
      this.utilService.showMessage("Failed to save organization updates. Please try again later.", "Ok")
    })
  }

  validateCompanyLabels() {
    this.addNewLabel();
    const activeLabels = this.companyLabels.filter(item=>!item.delete && item.label && item.label.length > 0);
    if(activeLabels) {
      const labels = activeLabels.map(value=>value.label)
      this.profile.companyLabels = labels;
    } else {
      this.profile.companyLabels = []
    }

    const updatedLabels = this.companyLabels.filter(item=>!item.newLabel && item.label !== item.oldLabel);
    if(updatedLabels) {
      updatedLabels.forEach(item=>{
        item.update = item.label !== item.oldLabel;
      })

      this.profile.labelUpdates = updatedLabels;
    }
  }

  openUrl(link) {
    window.open(link, "_blank")
  }

  addNewMember(saving?) {
    if((this.newMember.name && this.newMember.name.length >= 3) && (this.newMember.link && this.newMember.link.length > 4)) {
      this.profile.team.push(cloneDeep(this.newMember));
      this.newMember.name = "";
      this.newMember.link = "";
    } else if(saving && ((this.newMember.name && this.newMember.name.length >= 0) || (this.newMember.link && this.newMember.link.length > 0))) {
      this.utilService.showMessage("Please provide valid team member name & linkedIn url.", "Ok");
      return false
    }
    return true
  }

  removeNewMember(index) {
    this.profile.team.splice(index, 1)
  }
  
  regionInputChange(value) {
    // console.log("inputChange", value);

    if(value && value.length >= 3) {
      this.dataService.getSimilarCountries(value).subscribe(result=>{
        // console.log("result", result);
        if(result.body["response"]) {
          this.filteredOptions = result.body["response"];
          // this.filteredOptions = options.map((option) =>{
          //   return option["skill"];
          // })
        } else {
          this.filteredOptions = []
        }
      }, error=>{
        console.log("error", error);
        this.filteredOptions = []
      })
    } else {
      this.filteredOptions = []
    }
  }

  addRegion(event: MatChipInputEvent): void {
    // console.log("MatChipInputEvent", event);

    const input = event.input;
    const value = this.filteredOptions[0];

    if (value) {
      this.regionInputValues.push(value);
      this.profile.region = this.regionInputValues;
    }

    if (input) {
      input.value = '';
    }
  }

  addRegionFromPopup(value: string){
    this.regionInputValues.push(value);
    this.profile.region = this.regionInputValues;
    this.inputRef.nativeElement.value = '';
  }

  remove(value, index): void {
    if (index >= 0) {
      this.regionInputValues.splice(index, 1);
      this.profile.region = this.regionInputValues;
    }
  }

  removeLabel(value, index): void {
    if (index >= 0) {
      this.companyLabels[index].delete = true;
    }
  }

  addNewLabel(): void {

    if(!this.newLabel || this.newLabel.length === 0) return;

    this.companyLabels.push({label: this.newLabel, oldLabel: this.newLabel, newLabel: true});

    this.newLabel = ""
  }

  filteredCurrencies = []
  currencySelection = ""

  currencyOptions = [    
      { id: "AED", country: "United Arab Emirates Dirham" },
      { id: "AFN", country: "Afghan Afghani" },
      { id: "ALL", country: "Albanian Lek" },
      { id: "AMD", country: "Armenian Dram" },
      { id: "ANG", country: "Netherlands Antillean Guilder" },
      { id: "AOA", country: "Angolan Kwanza" },
      { id: "ARS", country: "Argentine Peso" },
      { id: "AUD", country: "Australian Dollar" },
      { id: "AWG", country: "Aruban Florin" },
      { id: "AZN", country: "Azerbaijani Manat" },
      { id: "BAM", country: "Bosnia-Herzegovina Convertible Mark" },
      { id: "BBD", country: "Barbadian Dollar" },
      { id: "BDT", country: "Bangladeshi Taka" },
      { id: "BGN", country: "Bulgarian Lev" },
      { id: "BHD", country: "Bahraini Dinar" },
      { id: "BIF", country: "Burundian Franc" },
      { id: "BMD", country: "Bermudan Dollar" },
      { id: "BND", country: "Brunei Dollar" },
      { id: "BOB", country: "Bolivian Boliviano" },
      { id: "BRL", country: "Brazilian Real" },
      { id: "BSD", country: "Bahamian Dollar" },
      { id: "BTC", country: "Bitcoin" },
      { id: "BTN", country: "Bhutanese Ngultrum" },
      { id: "BWP", country: "Botswanan Pula" },
      { id: "BYN", country: "Belarusian Ruble" },
      { id: "BYR", country: "Belarusian Ruble" },
      { id: "BZD", country: "Belize Dollar" },
      { id: "CAD", country: "Canadian Dollar" },
      { id: "CDF", country: "Congolese Franc" },
      { id: "CHF", country: "Swiss Franc" },
      { id: "CLF", country: "Chilean Unit of Account (UF)" },
      { id: "CLP", country: "Chilean Peso" },
      { id: "CNY", country: "Chinese Yuan" },
      { id: "COP", country: "Colombian Peso" },
      { id: "CRC", country: "Costa Rican Colón" },
	    { id: "CUC", country: "Cuban Convertible Peso"},
      { id: "CUP", country: "Cuban Peso" },
      { id: "CVE", country: "Cape Verdean Escudo" },
      { id: "CZK", country: "Czech Republic Koruna" },
      { id: "DJF", country: "Djiboutian Franc" },
      { id: "DKK", country: "Danish Krone" },
      { id: "DOP", country: "Dominican Peso" },
      { id: "DZD", country: "Algerian Dinar" },
      { id: "EEK", country: "Estonian Kroon" },
      { id: "EGP", country: "Egyptian Pound" },
      { id: "ERN", country: "Eritrean Nakfa" },
      { id: "ETB", country: "Ethiopian Birr" },
      { id: "EUR", country: "Euro" },
      { id: "FJD", country: "Fijian Dollar" },
      { id: "FKP", country: "Falkland Islands Pound" },
      { id: "GBP", country: "British Pound Sterling" },
      { id: "GEL", country: "Georgian Lari" },
      { id: "GGP", country: "Guernsey Pound" },
      { id: "GHS", country: "Ghanaian Cedi" },
      { id: "GIP", country: "Gibraltar Pound" },
      { id: "GMD", country: "Gambian Dalasi" },
      { id: "GNF", country: "Guinean Franc" },
      { id: "GTQ", country: "Guatemalan Quetzal" },
      { id: "GYD", country: "Guyanaese Dollar" },
      { id: "HKD", country: "Hong Kong Dollar" },
      { id: "HNL", country: "Honduran Lempira" },
      { id: "HRK", country: "Croatian Kuna" },
      { id: "HTG", country: "Haitian Gourde" },
      { id: "HUF", country: "Hungarian Forint" },
      { id: "IDR", country: "Indonesian Rupiah" },
      { id: "ILS", country: "Israeli New Sheqel" },
      { id: "IMP", country: "Manx pound" },
      { id: "INR", country: "Indian Rupee" },
      { id: "IQD", country: "Iraqi Dinar" },
      { id: "IRR", country: "Iranian Rial" },
      { id: "ISK", country: "Icelandic Króna" },
      { id: "JEP", country: "Jersey Pound" },
      { id: "JMD", country: "Jamaican Dollar" },
      { id: "JOD", country: "Jordanian Dinar" },
      { id: "JPY", country: "Japanese Yen" },
      { id: "KES", country: "Kenyan Shilling" },
      { id: "KGS", country: "Kyrgystani Som" },
      { id: "KHR", country: "Cambodian Riel" },
      { id: "KMF", country: "Comorian Franc" },
      { id: "KPW", country: "North Korean Won" },
      { id: "KRW", country: "South Korean Won" },
      { id: "KWD", country: "Kuwaiti Dinar" },
      { id: "KYD", country: "Cayman Islands Dollar" },
      { id: "KZT", country: "Kazakhstani Tenge" },
      { id: "LAK", country: "Laotian Kip" },
      { id: "LBP", country: "Lebanese Pound" },
      { id: "LKR", country: "Sri Lankan Rupee" },
      { id: "LRD", country: "Liberian Dollar" },
      { id: "LSL", country: "Lesotho Loti" },
      { id: "LTL", country: "Lithuanian Litas" },
      { id: "LVL", country: "Latvian Lats" },
      { id: "LYD", country: "Libyan Dinar" },
      { id: "MAD", country: "Moroccan Dirham" },
      { id: "MDL", country: "Moldovan Leu" },
      { id: "MGA", country: "Malagasy Ariary" },
      { id: "MKD", country: "Macedonian Denar" },
      { id: "MMK", country: "Myanma Kyat" },
      { id: "MNT", country: "Mongolian Tugrik" },
      { id: "MOP", country: "Macanese Pataca" },
      { id: "MRO", country: "Mauritanian Ouguiya" },
      { id: "MUR", country: "Mauritian Rupee" },
      { id: "MVR", country: "Maldivian Rufiyaa" },
      { id: "MWK", country: "Malawian Kwacha" },
      { id: "MXN", country: "Mexican Peso" },
      { id: "MYR", country: "Malaysian Ringgit" },
      { id: "MZN", country: "Mozambican Metical" },
      { id: "NAD", country: "Namibian Dollar" },
      { id: "NGN", country: "Nigerian Naira" },
      { id: "NIO", country: "Nicaraguan Córdoba" },
      { id: "NOK", country: "Norwegian Krone" },
      { id: "NPR", country: "Nepalese Rupee" },
      { id: "NZD", country: "New Zealand Dollar" },
      { id: "OMR", country: "Omani Rial" },
      { id: "PAB", country: "Panamanian Balboa" },
      { id: "PEN", country: "Peruvian Nuevo Sol" },
      { id: "PGK", country: "Papua New Guinean Kina" },
      { id: "PHP", country: "Philippine Peso" },
      { id: "PKR", country: "Pakistani Rupee" },
      { id: "PLN", country: "Polish Zloty" },
      { id: "PYG", country: "Paraguayan Guarani" },
      { id: "QAR", country: "Qatari Rial" },
      { id: "RON", country: "Romanian Leu" },
      { id: "RSD", country: "Serbian Dinar" },
      { id: "RUB", country: "Russian Ruble" },
      { id: "RWF", country: "Rwandan Franc" },
      { id: "SAR", country: "Saudi Riyal" },
      { id: "SBD", country: "Solomon Islands Dollar" },
      { id: "SCR", country: "Seychellois Rupee" },
      { id: "SDG", country: "Sudanese Pound" },
      { id: "SEK", country: "Swedish Krona" },
      { id: "SGD", country: "Singapore Dollar" },
      { id: "SHP", country: "Saint Helena Pound" },
      { id: "SLL", country: "Sierra Leonean Leone" },
      { id: "SOS", country: "Somali Shilling" },
      { id: "SRD", country: "Surinamese Dollar" },
      { id: "STD", country: "São Tomé and Príncipe Dobra" },
      { id: "SVC", country: "Salvadoran Colón" },
      { id: "SYP", country: "Syrian Pound" },
      { id: "SZL", country: "Swazi Lilangeni" },
      { id: "THB", country: "Thai Baht" },
      { id: "TJS", country: "Tajikistani Somoni" },
      { id: "TMT", country: "Turkmenistani Manat" },
      { id: "TND", country: "Tunisian Dinar" },
      { id: "TOP", country: "Tongan Paʻanga" },
      { id: "TRY", country: "Turkish Lira" },
      { id: "TTD", country: "Trinidad and Tobago Dollar" },
      { id: "TWD", country: "New Taiwan Dollar" },
      { id: "TZS", country: "Tanzanian Shilling" },
      { id: "UAH", country: "Ukrainian Hryvnia" },
      { id: "UGX", country: "Ugandan Shilling" },
      { id: "USD", country: "United States Dollar" },
      { id: "UYU", country: "Uruguayan Peso" },
      { id: "UZS", country: "Uzbekistan Som" },
      { id: "VEF", country: "Venezuelan Bolívar Fuerte" },
      { id: "VND", country: "Vietnamese Dong" },
      { id: "VUV", country: "Vanuatu Vatu" },
      { id: "WST", country: "Samoan Tala" },
      { id: "XAF", country: "CFA Franc BEAC" },
      { id: "XAG", country: "Silver (troy ounce)" },
      { id: "XAU", country: "Gold (troy ounce)" },
      { id: "XCD", country: "East Caribbean Dollar" },
      { id: "XDR", country: "Special Drawing Rights" },
      { id: "XOF", country: "CFA Franc BCEAO" },
      { id: "XPF", country: "CFP Franc" },
      { id: "YER", country: "Yemeni Rial" },
      { id: "ZAR", country: "South African Rand" },
      { id: "ZMK", country: "Zambian Kwacha (pre-2013)" },
      { id: "ZMW", country: "Zambian Kwacha" },
      { id: "ZWL", country: "Zimbabwean Dollar" }
  ]
}
