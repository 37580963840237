import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { default as _rollupMoment, Moment } from 'moment';
import moment from 'moment';
import { filter } from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { NotificationService } from './notification.service';
import { UtilService } from '../util.service';
import { FundListService } from 'src/app/qubit-x/portfolio/fund-list-ui/fund-list.service';
import { TranslateService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  dataFilterTxt = this.translateService.getLabel('filter_by_unread');
  dataMarkTxt = this.translateService.getLabel('mark_all_as_read');
  
  // {
  //   "id" : "aa191a59-1b36-4508-bf2d-86ee99cf87e7",
  //   "createdBy" : "e6025da8-b869-498d-bea1-8712ff5dd929",
  //   "createdDate" : 1644022775000,
  //   "lastModifiedBy" : "STATUS_UPDATE",
  //   "lastModifiedDate" : 1644022836000,
  //   "orgId" : "b2ccc9fd-fe19-41e3-b49e-a65b4113d8b3",
  //   "eventType" : "FORM_SUBMISSION",
  //   "eventObjectId" : "aba567f6-cecd-41a3-ace8-8542e6bb6486",
  //   "eventObjectParentId" : "2ba0423b-9a88-440f-b1a2-f098a88af90f",
  //   "eventStatus" : "COMPLETED",
  //   "readStatus" : false,
  //   "label" : "{\"fund\":\"Alcatraz\",\"companyName\":\"TAL Education Group\",\"valuationNode\":\"2020-10-31\",\"version\":\"FIRST\"}",
  //   "new" : false
  // }
  // "eventStatus": "IN_PROGRESS | COMPLETED | ERROR | RETRIED",

  notificationsRefresh;
  isDebtModelId;
  constructor(private router: Router,
    private ds: DataService,
    private ums: UserManagementService,
    public notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private fundService: FundListService,
    public translateService: TranslateService) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    if(this.notificationsRefresh) {
      clearInterval(this.notificationsRefresh);
    }
  }

  eventStatusOnText(eventStatus, eventType) {
    if (eventStatus == 'IN_PROGRESS') {
      if(eventType == "FORM_SUBMISSION" || eventType == "CREDIT_FORM_SUBMISSION"){
        return this.translateService.getLabel("form_submission_in_progress");
      }
      else if(eventType == "CREDIT_FORM_RE_SUBMISSION"){
        return this.translateService.getLabel("form_re_submission_in_progress");
      }
      else if(eventType == "REPORT"){
        return this.translateService.getLabel("report_in_progress");
      } 
    }
    else if (eventStatus == 'COMPLETED') {
      if(eventType == "FORM_SUBMISSION" || eventType == "CREDIT_FORM_SUBMISSION"){
        return this.translateService.getLabel("form_submission_completed");
      }
      else if(eventType == "CREDIT_FORM_RE_SUBMISSION"){
        return this.translateService.getLabel("form_re_submission_completed");
      }
      else if(eventType == "REPORT"){
        return this.translateService.getLabel("report_generation_completed");
      }
    }
    else if (eventStatus == 'ERROR') {
      if(eventType == "FORM_SUBMISSION" || eventType == "CREDIT_FORM_SUBMISSION"){
        return this.translateService.getLabel("form_submission_failed");
      }
      else if(eventType == "REPORT"){
        return this.translateService.getLabel("report_generation_failed");
      }
    }
  }

  filterunread() {
    this.notificationService.showOnlyUnRead = !this.notificationService.showOnlyUnRead;
    
    if(this.notificationService.showOnlyUnRead) {
      this.notificationService.notificationData = this.notificationService.allNotifications.filter(item => !item.readStatus);
    } else {
      this.notificationService.notificationData = this.notificationService.allNotifications;
    }

    this.dataFilterTxt = this.notificationService.showOnlyUnRead ? this.translateService.getLabel('show_all') : this.translateService.getLabel('filter_by_unread');
  }

  markAllAsRead() {
    this.notificationService.notificatioNmarkAllAsRead();
  }

  readEvent(event, id) {
    event.stopPropagation();
    event.preventDefault();
    const filterIndex = this.notificationService.notificationData.findIndex(item => item.id == id);
    if (filterIndex > -1 && !this.notificationService.notificationData[filterIndex]['readStatus']) {
      this.notificationService.notificationData[filterIndex]['readStatus'] = true;
      this.notificationService.updateReadStatusById(id);
    }
  }

  async actionClicked(row, isEdit?:boolean) {
    if(!row.readStatus) {
      const reqBody = [{
          eventObjectId: row.eventObjectId,
          readStatus: true
      }]
      
      //Mark the clicked event read status as `true`
      await this.ds.updateReadStatusOfNotifications(reqBody).toPromise();
    }

    switch(row.eventType) {

      case "FORM_SUBMISSION":

        if(row.eventStatus == 'COMPLETED') {          

          const labelObj = JSON.parse(row.label);

          const formAPI = await this.ds.getformDetails(row.eventObjectId).toPromise();
          const form = formAPI.body["response"];

          this.utilService.navigateToValuationSummary(form, labelObj.fund, labelObj.companyName, labelObj.version, null, "Notification")
        }
        else if(row.eventStatus == 'ERROR') {
          const user = this.ums.getSelectedUserDetails();
          const retryReqBody = {
            "orgId": user.organization.id,
            "userId": user.id,
            "newFormId": row.eventObjectId,
            "source": "EQUITY-RETRY"
          }

          this.ds.submitForm(retryReqBody).subscribe(res => {
            console.log("form submission is successful. " + row.eventObjectId);
          })
        }

        break;

      case "CREDIT_FORM_SUBMISSION":
      case "CREDIT_FORM_RE_SUBMISSION":
        if(row.eventStatus == 'COMPLETED') {          
          this.utilService.navigateToDebtValuationSummary(row.eventObjectId)
        }

        //It is for Edit button to navigate to debt form
        if(isEdit && row.debtModelId) {
          this.utilService.navigateToDebtValuationSetUp(row.debtModelId);
        }

        break;  
        
      case "REPORT":

        if(row.eventStatus == 'COMPLETED') {   
                 
          window.open(environment.portalUrl + "/dashboard/#/dashboard-equity");
        }

        break;
    }
  }

  async editClicked(row){
    let label = JSON.parse(row.label);
    let fundName = label.fund;
    let versionName = label.version;

    let consolFormObj = null;

    if(label.isConsolForm) { 
      const res = await this.ds.getformDetails(row.eventObjectId).toPromise();

      const formDetails = res.body['response'].details;
      const formDetailsObj = JSON.parse(formDetails);
      consolFormObj = {
        srcCurrency: formDetailsObj.currency ,
        consol: formDetailsObj.companyNameInForm,
        marketDate: formDetailsObj.marketDate
      }
    }

    this.utilService.navigateToSAFForm(row.eventObjectId, label.companyId, label.valuationNode, 
      versionName, consolFormObj, 
      row.eventObjectParentId, fundName);
  }
}
