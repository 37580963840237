import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { TranslateService } from 'src/app/services/translation.service';
import { CommentServiceService } from 'src/app/utils/comment-service.service';
import { UtilService } from 'src/app/utils/util.service';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  
  @Input() grpId;
  @Input() formId;
  @Input() widgetKey;
  @Input() label;
  @Input() iconColor;
  @Input() showIcon;

  saveStatus = "";
  comments = [];

  activeCommentIconIndex = 1;

  keyName = "";

  constructor(
    private ds: DataService, 
    public utilService: UtilService,
    private modalService: NgbModal,
    public commentService: CommentServiceService,
    private ms: MessageService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
  }

  openPopupModalComment(content) {

      const uniqueIdForkeyName = this.formId.split('-')[0];

      this.keyName = this.grpId +"_" + this.widgetKey + "_" + uniqueIdForkeyName;
      this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl', centered: true });
  }
  
  refreshListofComments(){
    this.commentService.fetchComments = false;
    this.ms.publish("refreshComments", {
      'refresh': true,
      'id': this.formId
    });
  }
}
