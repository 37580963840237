import { Injectable } from '@angular/core';
import { Geography } from '../../ca-interfaces';

@Injectable({
  providedIn: 'root',
})
export class GsFilterService {
  private selectedCountries = new Map<string, { name: string, countryCode: string}>();

  private selectedSectors = new Map<string, string>();

  allCountries: Geography[] = [];

  allSectors: string[] = [];

  getSelectedCountries() {
    return this.selectedCountries;
  }

  setSelectedCountries(countries) {
    this.selectedCountries = countries;
  }

  setSelectedSectors(sectors) {
    this.selectedSectors = sectors;
  }

  getSelectedSectors() {
    return this.selectedSectors;
  }

  constructor() {}
}
