<div>
    <a id="modal-open" (click)="open(popupmodal2)" style="display: none;"></a>
</div>

<ng-template #popupmodal2 let-modal class="modal-dialog-centered modal-content-activity">
    <div class="popup-component2">
        <div class="modal-header" style="border-bottom: 0px;">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <h6>Legal Terms and Conditions</h6>
            </div>
        </div>
        <div class="modal-body" style="margin-top: -18px; max-height: 400px; overflow: auto;">
            <div>
                <h4 class="heading2">
                    INTRODUCTION
                </h4>
                <div class="paragraph">
                    <p>Welcome to QubitX. We start every new subscriber relationship with the acceptance of our terms
                        and
                        conditions.
                    </p>
                    <p>
                        Please read these Standard Terms of Use (“Terms”) carefully. By using the Qubit X ("73 Strings
                        Platforms")
                        platform or signing up for
                        an account,
                        you are agreeing to these Terms, which will result in a legal agreement between you and 73
                        Strings SAS (“73
                        Strings,”
                        “we,” or “us”). Your failure to follow the Terms & Conditions would be considered breach of this
                        agreement.
                    </p>

                    <p>
                        If you are accessing the website or any of such services offered by 73 Strings, on behalf of
                        your
                        Institution or another
                        entity or company, you represent and warrant that you have the authority to agree to these
                        terms.
                    </p>


                    <p>
                        Your usage and purpose of usage is bound by our licensing agreement, any unauthorised usage
                        would be
                        considered a breach
                        you agree to in terms of purpose of usage responsible for safeguarding the password that you use
                        to access
                        Qubit X,
                        sharing or usage of your login details and password.
                    </p>

                    <h4 class="heading2">
                        REGISTRATION AND ACCESS
                    </h4>


                    <p>
                        You will be required to register with 73 Strings prior to receiving a username and password to
                        access Qubit
                        X. Once
                        registration is verified, you will receive a username and password granting access to Qubit X.
                        By
                        registering with 73
                        Strings, you agree that all information provided is accurate and complete. You will have the
                        ability to
                        maintain the
                        registration information and change the information as necessary. However, you shall be solely
                        responsible
                        for the
                        upkeep and security of the username and password.
                    </p>


                    <p>
                        You hereby agree to keep all information confidential and notify 73 Strings if theft or any
                        other breach of
                        secure
                        information occurs. 73 Strings reserves the right to grant or deny access to Qubit X and modify
                        or delete
                        your access at
                        any time for any reason without notice or liability. 73 Strings will not be held responsible for
                        damages
                        incurred due to
                        your noncompliance with the above statements.
                    </p>



                    <h4 class="heading2">
                        PROPRIETARY RIGHTS
                    </h4>
                    <p>
                        We own/ authorised to use the intellectual property rights to any and all protectable components
                        of our
                        system, such
                        components include but are not limited to computer software, the related documentation, the
                        end-user
                        interfaces, design,
                        the name of our system, concept, many of the individual features.
                    </p>

                    <h4 class="heading2">
                        USER CONDUCT
                    </h4>


                    <p>
                        You hereby agree not to use Qubit X in any way that is unlawful or prohibited by these terms and
                        conditions.
                        Qubit X may
                        only be used for the purposes intended by 73 Strings.
                    </p>


                    <p>
                        You shall not;<br />
                        (a) reverse engineer, decompile, or disassemble any software disclosed to it under this
                        agreement and shall
                        not remove,
                        overprint, or deface any notice of confidentiality, copyright, trademark, logo, legend;<br />

                        (b) copy, modify, adapt, translate, resell, or distribute the 73 Strings Platform;<br />

                        (c) allow any third parties to use (or benefit from the use of) the 73 Strings Platform;<br />

                        (d) remove or modify any proprietary marking or restrictive legends placed on the 73 Strings
                        Platform;<br />

                        (e) create derivative works of the 73 Strings Platform or any aspect or portion thereof,
                        including without
                        limitation,
                        source code and algorithms;<br />

                        (f) sell, rent, lease, license, sub-license, assign or transfer, or otherwise provide access to
                        all or part
                        of, the 73
                        Strings Platform;<br />

                        (g) use the 73 Strings Platform in violation of any applicable law or regulation or internal
                        policies and
                        conditions of
                        73 Strings.
                    </p>



                </div>
            </div>
        </div>
        <div class="modal-footer" style="border-top: 0px;">
            <button class="no-button" (click)="onDecline()"
                style="background-color: white !important; border-radius: 5px; cursor: pointer;">
                Decline
            </button>
            <button mat-flat-button class="yes-button" (click)="saveTandCStatus()">
                Accept
            </button>
        </div>

    </div>
</ng-template>