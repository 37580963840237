/* tslint:disable:variable-name */
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { UserCompanyAccessRow } from '../../data-recon-pluto.model';

@Component({
  selector: 'app-drp-user-company-access-details',
  templateUrl: './drp-user-company-access-details.component.html',
  styleUrls: ['../table-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrpUserCompanyAccessDetailsComponent {
  displayColumns = ['organizationName', 'userName', 'userId', 'gxUserCompaniesCount', 'plutoUserCompaniesBaseCount', 'plutoUserCompaniesFactCount', 'userCompaniesDelta'];

  _data: UserCompanyAccessRow[];

  dataSource: MatTableDataSource<UserCompanyAccessRow>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() loading: boolean;

  @Input() error: string | null;

  @Input() set data(data) {
    this._data = [...data];
    if (this._data) {
      this.dataSource = new MatTableDataSource(this._data);
      this.dataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
