<div class="main">
  <div class="banner-container">
    <img [src]="loaderService.getImage('login-image.png')" class="banner-image1" alt="">
    <div class="text-container">
      <div class="get-form">
        <img [src]="loaderService.getImage('73_Strings_logo_blueUE.png')" class="company-logo" alt="">
        <div class="welcome-label">Login Failed</div>

        <br/>

        <button mat-raised-button class="login-button"
        (click)="login()">
        Login Again
        </button>
        
        <div class="copyright-text">
          <label>Copyright © * 73 Strings. All rights reserved.</label>
        </div>
      </div>

    </div>    
  </div>
</div>
