import { Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FundListService } from './fund-list.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { DebtPortfolioService } from '../../portfolio/debt/debt-portfolio.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-fund-list-ui',
  templateUrl: './fund-list-ui.component.html',
  styleUrls: ['./fund-list-ui.component.scss']
})
export class FundListUIComponent implements OnInit {

  @ViewChild('deleteSuccessModal', { static: false }) public deleteSuccessModal: NgbModal;

  fundName = null;
  cardSelectedForAssignment;
  showFundDropDown = false;
  userAssignmentPopHeader;
  deleteFundType;
  deleteFundName;
  deleteFund;

  orgId;

  isMasterUser = false;
  fundExist: any = false;

  constructor(private router: Router,     private um: UserManagementService, 
    public fundService: FundListService,  private modalService: NgbModal,
    private utilService: UtilService,     private ds: DataService,
    public imageLoadService: ImageLoadService,
    private injector: Injector, private debtPortfolioService: DebtPortfolioService,
    public translateService: TranslateService) 
  { }

  ngOnInit() {
    const myDetails = this.um.getSelectedUserDetails();
    this.debtPortfolioService.isDebtLoaded = false;
    this.isMasterUser = this.um.isMasterUser();
    this.fundService.getFunds(myDetails);
    this.fundService.allFunds.map(f => { f["showDropDown"] = false });

    this.orgId = myDetails.organization.id;
  }

  fundDropDownToggle(row){
    row.showDropDown = !row.showDropDown;
  }

  fundDropDownToggleAll()
  {
    this.fundService.allFunds.map(f => { f["showDropDown"] = false });
  }

  userPopup(content, card) {
    this.fundService.resetUserAssignment();

    if(card.details && this.fundService.myOrgUsers && this.fundService.myOrgUsers.length > 0) {
      const assignees = JSON.parse(card.details).assignees;
      if(assignees) {
        this.fundService.myOrgUsers.forEach(user => {
          const assignedUser = assignees.find(u => u.id === user.id);
          
          user.assigned = !!assignedUser;
          user.permission = assignedUser ? assignedUser.permission : "FN";
        })
      }
    } else if(!this.fundService.myOrgUsers || this.fundService.myOrgUsers.length == 0){
      return;
    }

    this.cardSelectedForAssignment = card;
    
    this.modalService.open(content, { centered: true, size: 'lg'});

    this.userAssignmentPopHeader = card.name
  }

  addReport() {
    if(this.fundName)
    {
      const myDetails = this.um.getSelectedUserDetails();
      this.fundService.createFund({type: "REPORT", name: this.fundName}, myDetails);
      this.modalService.dismissAll();
      this.fundName = null;
    }
  }

  addFund() {
    this.fundExist = this.fundService.allFunds.some(obj => obj.fund.name.toLowerCase() === this.fundName.toLowerCase());

    if(this.fundName && !this.fundExist)
    {
      const myDetails = this.um.getSelectedUserDetails();
      this.fundService.createFund({type: "FUND", name: this.fundName}, myDetails);
      this.modalService.dismissAll();
      this.fundName = null;
    }
  }

  checkFundNameEmptyOrNot(){

    if(!this.fundName){
    this.fundExist = false;
    }

  }

  openPopup(content) {
    this.fundName = "";
    this.fundExist = false;

    this.modalService.open(content, { centered: true , windowClass: 'create-new-fund'});
  }

  cardClicked(card) {
    this.um.addUserAction("Fund Selection", card.id, card.name, "Funds List");
    if(card.type === "REPORT") {
      this.router.navigateByUrl('/customSummary/' + card.id);
    } else {
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      ps.selectedFundId = null;
      ps.summary[card.id] = null;
      ps.loading = true;
      
      this.router.navigateByUrl('/portfolio/' + card.id);
    }
  }

  onClickOfFundDelete(fund){
    // const confirm = this.utilService.showConfirmMessage("Please confirm if you wish to delete - " + fund.name, "Yes", "No");
    
    const confirmation = "Yes";
    // confirm.afterClosed().subscribe(confirmation => { 
      if(confirmation === "Yes") {
        this.ds.deleteFundById(fund.id).subscribe(response=>{
          // this.utilService.showMessage("Successfully deleted - " + fund.name, "OK");
          this.modalService.open(this.deleteSuccessModal, { centered: true });
          
          const fIndex = this.fundService.allFunds.findIndex(f => f.fund.id === fund.id);
          this.fundService.allFunds.splice(fIndex, 1);
        },error=>{
          this.utilService.showMessage(this.translateService.getLabel("err_failed_delete_fund") + "- " + fund.name + ". " + this.translateService.getLabel("info_try_after_sometime"), this.translateService.getLabel("ok"), true);
        });
      }
    // })
  }

  deleteFundPopUp(content, fund)
  {
    if(fund.type == "FUND") { this.deleteFundType = "fund"; }
    if(fund.type == "REPORT") { this.deleteFundType = "Report"; }

    this.deleteFundName = fund.name;
    this.deleteFund = fund;
    this.modalService.open(content, { centered: true });
  }

  redirectToV2Navigation(){
    let firstFund = this.fundService.allFunds?.find(i=>i?.fund?.type ==='FUND');
    const url = `https://aiplatform-uat.73strings.com/v2-home`;
    // const url = `https://aiplatform-uat.73strings.com/v2-monitoring/#/portfolio-summary?parentId=${firstFund?.fund?.id}`;
    window.location.href = url;
  }
}
