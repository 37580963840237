import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { cloneDeep, indexOf } from 'lodash';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { colors } from '../utils/colors';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UtilService } from '../utils/util.service';
import { TranslateService } from '../services/translation.service';

@Component({
  selector: 'app-adjustments',
  templateUrl: './adjustments.component.html',
  styleUrls: ['./adjustments.component.scss']
})
export class AdjustmentsComponent implements OnInit, OnChanges {

  @Input() totalValueBeforeAdjustment;
  @Input() headerLabel;
  @Input() existingAdjustments;

  @Input() isBalanceSheetAdjustments;

  @Output() newEvent = new EventEmitter();
  @Output() finalAdjustments = new EventEmitter<any>();

  filteredOptions = [];

  waterFallChartData: any;
  waterFallNumbers = [];
  balanceSheetAdjustmentOptions=[
   {
    key:"debt",label:"Debt" , helpText:"Can include items like long-term debt, short-term debt, loans,borrowings, current portion of long-term debt or items of similar nature"
   },
   {
    key:"cash",label:"Cash" , helpText:"Can include items like cash and bank balance, bank deposits or items of similar nature"
   },
   {
    key:"investments",label:"Investments" , helpText:"Can include items like short-term and long-term investments, securities held-for-trading, trading asset securities, assets held for sale, marketable securities, or items of similar nature"
   },
   {
    key:"leases",label:"Leases", helpText:"Can included items like short-term and long-term lease or items of similar nature"
   },
   {
    key:"other-debt",label:"Other Debt" , helpText:"Can include other debt-like items such as Convertible Loans,Shareholder Loan, or similar items"
   },
   {
    key:"other-non-operating-assets",label:"Other Non-Operating Assets" , helpText:""
   },
   {
    key:"other-non-operating-liabilities",label:"Other Non-Operating Liabilities" , helpText:""
   },
   {
    key:"contingent-liabilities",label:"Contingent Liabilities", helpText:""
   },
   {
    key:"covid-impact",label:"Covid Impact", helpText:""
   },
   {
    key:"accounting-related-adjustment",label:"Accounting Related Adjustment", helpText:"such as IFRS Related Adjustment"
   },
   {
    key:"fx-adjustment",label:"FX Adjustment", helpText:""
   },
   {
    key:"other-one-time-adjustment",label:"Other One-Time Adjustment", helpText:""
   },
   {
    key:"erp-data-reversal",label:"ERP Data Reversal", helpText:"such as Jedox Reversal, etc."
   },
   {
    key:"others",label:"Others", helpText:"To include balancing figure of any off the items which could not be categorised as part of the list above"
   }
  ]

  // This will be used to have backward compatibility
  // where previous adj name may not be from pre-defined list
  balanceSheetAdjOptionsClone = [];

  constructor(public utilService: UtilService, public translateService: TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.totalValueBeforeAdjustment == null || this.totalValueBeforeAdjustment == undefined) {
      return;
    }

    if(this.existingAdjustments){
      this.adjustmentOnTotalValue.adjustments = this.existingAdjustments.adjustments ? cloneDeep(this.existingAdjustments.adjustments) : [];
      // console.log("adjs", this.adjustmentOnTotalValue.adjustments);
    }

    if(this.isBalanceSheetAdjustments) {
      this.balanceSheetAdjOptionsClone = cloneDeep(this.balanceSheetAdjustmentOptions);

      this.adjustmentOnTotalValue.adjustments.forEach( adj => {
        const adjExistsInOptions = this.balanceSheetAdjustmentOptions.find(o => o.label.toLowerCase() === adj.name.toLowerCase());

        if(!adjExistsInOptions) {

          // This will be used to have backward compatibility
          // where previous adj name may not be from pre-defined list
          this.balanceSheetAdjOptionsClone.push({
            key: adj.name,
            label: adj.name,
            helpText: adj.name
          })
        } else {

          adj.name = adjExistsInOptions.label;
        }
      })
    }

    this.adjustmentOnTotalValue.totalValueBeforeAdjustment = this.totalValueBeforeAdjustment ? this.totalValueBeforeAdjustment : 0;;
    this.adjustmentOnTotalValue.totalValueAfterAdjustment = this.calculateAdjustments();
    this.prepareWaterFallChartData(this.totalValueBeforeAdjustment, this.adjustmentOnTotalValue.adjustments);
    this.returnWaterfallNumbers();
  }

  displayFn(comp?): string {
    return comp && comp.name ? comp.name : '';
  }

  inputChange(value){
    this.filteredOptions = [];
    if (value && value.length) {
      const options = this.balanceSheetAdjustmentOptions.filter( item => item.label.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0);

      this.filteredOptions = Object.values(options);
    } else {
      this.filteredOptions = this.balanceSheetAdjustmentOptions;
    }
  }

  newAdjustmentModel = { name: "", type: "", value: 0, comment: ""};

  adjustmentOnTotalValue = {
    totalValueBeforeAdjustment: "",
    totalValueAfterAdjustment: "",
    adjustments: []
  }

  emitAdjustedValue(){
    if(this.adjustmentOnTotalValue.adjustments) {
      this.adjustmentOnTotalValue.adjustments.forEach(a => {
        a.convertedValue = a.value;
      })
    }
    
    this.finalAdjustments.emit(this.adjustmentOnTotalValue);
  }

  addAdjustment(){
    if(isNaN(this.newAdjustmentModel.value) || !this.newAdjustmentModel.name || !this.newAdjustmentModel.type) {
      return;
    }

    this.adjustmentOnTotalValue.adjustments.push(this.newAdjustmentModel);

    const adjustedValue =  this.calculateAdjustments();

    this.adjustmentOnTotalValue.totalValueAfterAdjustment = +adjustedValue as any;

    //initializing to empty values
    this.newAdjustmentModel = { name: "", type: "", value: 0, comment: ""};
    this.prepareWaterFallChartData(this.totalValueBeforeAdjustment, this.adjustmentOnTotalValue.adjustments);
  }

  deleteAdjustments(index){
    this.adjustmentOnTotalValue.adjustments.splice(index, 1);
    this.adjustmentOnTotalValue.totalValueAfterAdjustment = this.calculateAdjustments();
    this.prepareWaterFallChartData(this.totalValueBeforeAdjustment, this.adjustmentOnTotalValue.adjustments);
  }

  editAdjustments(){
    this.adjustmentOnTotalValue.totalValueAfterAdjustment = this.calculateAdjustments();
    this.prepareWaterFallChartData(this.totalValueBeforeAdjustment, this.adjustmentOnTotalValue.adjustments);
  }

  calculateAdjustments(){
    let tempValue = this.totalValueBeforeAdjustment;
    if(this.adjustmentOnTotalValue && this.adjustmentOnTotalValue.adjustments && this.adjustmentOnTotalValue.adjustments.length > 0){
      this.adjustmentOnTotalValue.adjustments.forEach( adj => {
        if(adj.type == "Add") {
          tempValue += +adj.value;
        }
        else {
          tempValue -= +adj.value;
        }
      })

    }
    return tempValue;
  }

  initwaterFallChart() {
    this.waterFallChartData = new Chart({
      chart: {
        type: 'waterfall',
        backgroundColor: "#e6e6e6",
      },
      title: { text: '' },
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: { enabled: false },

      xAxis: {
        type: 'category',
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      yAxis: {
        title: { text: '' },
        gridLineWidth: 1,
        gridLineColor: '#BEBEBE'
      },

      plotOptions: {
        series: { stacking: 'normal' },
        waterfall: {
          negativeColor: '#EDED4A'
        }
      },
      tooltip: {
        formatter: function () {
          return '<b>' + this.point.name + '</b> : ' + this.y.toLocaleString();
        }
      },
      series: [{
        upColor: "#5FAFF2",
        color: "#5FAFF2",
        data: [],
        pointPadding: 0,

        dataLabels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.y, 0, ',') + 'Mn';
          },
          style: {
            fontWeight: 'bold',
          }
        },
      } as any]
    })

    // console.log("waterfall", this.waterFallNumbers, this.waterFallChartData)
  }

  getWaterfallNumbers(totalValueBeforeAdjustment, adjustmentsList) {
    const waterFallNumbers = [];
    waterFallNumbers.push({ name: "Initial Value", y: +totalValueBeforeAdjustment });
    for (let ajustment of adjustmentsList) {
      if (ajustment.type == "Add") {
        waterFallNumbers.push({ name: ajustment.name, y: +ajustment.value });
      }
      else {
        waterFallNumbers.push({ name: ajustment.name, y: +("-" + ajustment.value) });
      }
    }
    waterFallNumbers.push({ name: "Adjusted Value", isSum: true, color: colors.primaryColor});
    // console.log("useraddeddata",waterFallNumbers);
    this.waterFallNumbers= waterFallNumbers;
    this.returnWaterfallNumbers();
    return waterFallNumbers;
  }

  returnWaterfallNumbers(){
    this.newEvent.emit(this.waterFallNumbers);
    }

  prepareWaterFallChartData(totalValueBeforeAdjustment, adjustmentsList) {
    if (adjustmentsList && adjustmentsList.length > 0) {
      setTimeout(() => {
        this.initwaterFallChart();
        this.waterFallChartData.addSeries({
          upColor: "#5FAFF2",
          color: "#5FAFF2",
          data: this.getWaterfallNumbers(totalValueBeforeAdjustment,
            adjustmentsList),
          pointPadding: 0
        } as any, true, false);
      });
    }
  }

  deleteConfirmationModal(rowIndex) {
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("confirm_delete") + "?", this.translateService.getLabel("yes"), this.translateService.getLabel("no"))
    confirm.afterClosed().subscribe(status => {
      if(status == "Yes"){
        this.deleteAdjustments(rowIndex);
      }
    })
  }

}
