<mat-form-field style="width: 100%;">
    <mat-select [placeholder]="placeholder" name="search-dropdown" (selectionChange)="onSelectionChange($event)"
        [value]="defaultValue" (openedChange)="onOpenedChange($event)" required>
        <div class="search-input">
            <mat-icon>search</mat-icon>
            <input placeholder="Search" matInput type="text" (keyup)="onKeyUp($event)" [(ngModel)]="searchString" />
        </div>
        <mat-option *ngFor="let option of filteredOptions" [value]="option?.value">
            {{ option?.displayValue }}
        </mat-option>
        <mat-option *ngIf="filteredOptions.length < 1">
            No results found.
        </mat-option>
    </mat-select>
</mat-form-field>