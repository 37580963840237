import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiResponse, DataService } from 'src/app/services/data.service';
import { ToastService } from 'src/app/utils/toast.service';
import { DEFAULT_ATTRIBUTES_LIST } from './attribute-list-data';
import { AttributeList } from './attr-list-interfaces';
import { UserManagementService } from 'src/app/services/user-management.service';
import { HttpResponse } from '@angular/common/http';
import { CompaniesInFundMap } from '../company-assignment/ca-interfaces';
import { DateUtilService } from 'src/app/date-util.service';

@Injectable({
  providedIn: 'root',
})
export class AttributeListService {
  private user;

  private readonly _attributeLists = new BehaviorSubject<AttributeList[]>([]);

  readonly allVersionLists$ = this._attributeLists.asObservable();

  set attributeLists(val) {
    this._attributeLists.next(val);
  }

  constructor(private dataService: DataService, private userMgmtService: UserManagementService, private toastService: ToastService, private dateUtilSvc: DateUtilService) {
    this.user = this.userMgmtService.getSelectedUserDetails();
  }

  loadAttributeListsFromApiResponse(val) {
    this.attributeLists = val;
  }

  getAttributesLists(uploadDate: string, fundId: string): Observable<AttributeList[]> | Observable<undefined> {
    return this.dataService.getAttributesLists(uploadDate, fundId).pipe(
      map((response) => response.body?.response),
      catchError((err) => {
        this.toastService.openSnackBar('Failed to get attribute maps.Please try again.');
        console.log(err, 'CatchError...Failed to attribute lists.');
        return of(undefined);
      })
    );
  }

  getAttributeListsOrDefault(uploadDate: string, fundId: string): Observable<AttributeList[]> | Observable<undefined> {
    const allCompaniesInFund$ = this.dataService.getCompanyDetailsForCompanyMaster({
      userId: this.user?.id,
      fundIds: [fundId],
    }) as Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>>;

    const allCompaniesPiped$ = allCompaniesInFund$.pipe(
      map((res) => {
        const companies = res.body?.response[fundId]?.sort((a, b) => (new Date(a.valuationDate) > new Date(b.valuationDate) ? 1 : -1));
        const earliestInvestmentDateAcrossCompanies = companies?.length > 0 ? companies[0]?.valuationDate : undefined;
        if (earliestInvestmentDateAcrossCompanies) {
          const defaultListWithInvestmentDate: AttributeList[] = [{ ...DEFAULT_ATTRIBUTES_LIST[0], uploadDate: earliestInvestmentDateAcrossCompanies }];
          return defaultListWithInvestmentDate;
        } else {
          const today = this.dateUtilSvc.getDateYYYYMMDD(new Date());
          const defaultListWithCurrentDate: AttributeList[] = [{ ...DEFAULT_ATTRIBUTES_LIST[0], uploadDate: today }];
          return defaultListWithCurrentDate;
        }
      })
    );

    return this.dataService.getAttributesLists(uploadDate, fundId).pipe(
      map((response) => response.body?.response),
      switchMap((response) => {
        if (response?.length > 0) {
          return of(response);
        } else {
          return allCompaniesPiped$;
        }
      }),
      catchError((err) => {
        this.toastService.openSnackBar('Failed to get attribute maps.Please try again.');
        console.log(err, 'CatchError...Failed to get attribute lists.');
        return of(undefined);
      })
    );
  }

  deleteAttributeList(attributeListId: string) {
    return this.dataService.deleteAttributeList(attributeListId);
  }

  downloadAttributeListExcel(attributeListId: string) {
    return this.dataService.downloadAttributeList(attributeListId);
  }
}
