<nav-menu-header headerLabel="Funds" headerToBeAppended="Custom Portfolio Summary"></nav-menu-header>

<div class="card" style="padding: 0.55vw;">
  <div class="report-name">
    
    <span style="margin-left: 20vw;">{{ selectedReport?.name }}</span>
    <span style="float:right;min-width: 20.55vw">
      <span *ngIf="unsavedChanges" style="
        margin-right: 10px;
        color: red;
        font-size: 16px;
        font-weight: 500;">There are some unsaved changes </span>
      <span style="float: right;"><button mat-raised-button class="primary-bg" (click)="onClickOfFilter()">Refresh</button>
      </span>
    </span>
    </div>

  <mat-expansion-panel style="margin: 10px 5px 30px 5px;" *ngIf="isMasterUser">
    <mat-expansion-panel-header>
      <mat-panel-title>
         Filters
      </mat-panel-title>
     </mat-expansion-panel-header>
    <form>
      <div class="first-form-element">
        <div class="row" style="text-align: left; margin: 5px">
          <div class="col leftAlign">Fund</div>
          <!-- <div class="col leftAlign">Company</div> -->
          <div class="col leftAlign">Reporting Currency </div>          
          <div class="col leftAlign">Valuation Date</div>
          <div class="col leftAlign">Geography</div>
          <div class="col leftAlign">Industry </div>
          <div class="col leftAlign">Custom Attribute</div>         
          <!-- <div class="col-2"></div> -->
        </div>

        <div class="row">
          <div class="col leftAlign">

            <mat-form-field class="field">
              <mat-select placeholder="Select fund" (selectionChange)="detectChanges();fundSelected()">
                  <mat-option value="" disabled>Select</mat-option>
                  <mat-option *ngFor="let fund of fundCompanyList" (click)="select('FUND', fund)">
                    {{ fund.name }}
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col leftAlign">
            <mat-form-field class="field">
                <mat-select placeholder="Select currency" (selectionChange)="detectChanges()">
                  <mat-option value="" disabled>Select Currency</mat-option>
                  <mat-option *ngFor="let currency of currencyExchangeService.currencyList" 
                  (click)="select('CURRENCY', currency)">{{ currency.id }} - {{ currency.country}}</mat-option>
                </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="col leftAlign">
            <mat-form-field class="field">
              <mat-select placeholder="Select Partner" (ngModelChange)="addPartner($event)" [ngModel]="userSelection.partnerList[0]"  [ngModelOptions]="{standalone: true}">
                  <mat-option value="" disabled>Select</mat-option>
                  <mat-option *ngFor="let partner of partnersList" value="{{ partner }}">{{ partner }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

          <div class="col leftAlign">
            <mat-form-field class="field">
              <mat-select placeholder="Select Valuation Date" (selectionChange)="detectChanges()">
                <mat-option *ngFor="let valdate of allValDates"
                  (click)="select('VALUATION_DATE', valdate)">{{translateService.getLabel(valdate)}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col leftAlign">
            <mat-form-field class="field">
                <mat-select placeholder="Select geography" (selectionChange)="detectChanges()">
                    <mat-option value="" disabled>Select</mat-option>
                    <mat-option *ngFor="let geographyItem of geography" (click)="select('GEOGRAPHY', geographyItem)">
                      {{ geographyItem }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>

          <div class="col leftAlign">
            <mat-form-field class="field">
              <mat-select placeholder="Select industry" (selectionChange)="detectChanges()">
                  <mat-option value="" disabled>Select</mat-option>
                  <mat-option *ngFor="let sectorItem of sector" (click)="select('SECTOR', sectorItem)">{{ sectorItem }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

            <div class="col leftAlign">
              <mat-form-field class="field">
                <mat-select placeholder="Select Custom Attribute" (selectionChange)="detectChanges()">
                    <mat-option value="" disabled>Select</mat-option>
                    <mat-option *ngFor="let attrName of customAttributeNames" (click)="select('CUSTOM_ATTRIBUTES', attrName)">
                      {{ attrName }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        
        </div>

        <div class="row">
          <div class="col">
            <mat-chip-list>
              <ng-template ngFor [ngForOf]="filters.userSelection.funds" let-fund let-index="index">
                
                <mat-chip class="chips-container"
                  [selectable]="true" 
                  [removable]="true" 
                  (removed)="removeSelection('FUND', index)"
                  [ngClass]="clickedFundId == fund.id ? 'selectedChipClass' : 'regularChipClass'"
                  (click)="getCompanyListFromFunds(fund);">

                  {{fund.name}}
                  <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
                </mat-chip>

              </ng-template>
            </mat-chip-list>
          </div>

          <div class="col">                
            <mat-chip-list>
              <mat-chip>
                {{ reportingCurrency }}
              </mat-chip>
            </mat-chip-list>
          </div>

          <div class="col">                
            <mat-chip-list>
              <ng-template [ngIf]="filters.userSelection.valuationDate">
                <mat-chip>
                  {{translateService.getLabel(filters.userSelection.valuationDate)}}
                </mat-chip>
              </ng-template>
            </mat-chip-list>
          </div>
          
          <div class="col">                
            <mat-chip-list>
              <mat-chip class="chips-container" *ngFor="let value of filters.userSelection.geography; let index=index" 
                [selectable]="false" 
                [removable]="true" 
                (removed)="removeSelection('GEOGRAPHY', index)">
                {{value}}
                <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>

          <div class="col">                
            <mat-chip-list>
              <mat-chip class="chips-container" *ngFor="let value of filters.userSelection.sector; let index=index" 
                [selectable]="false" 
                [removable]="true" 
                (removed)="removeSelection('SECTOR', index)">
                {{value}}
                <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>

            <div class="col">
              <mat-chip-list>
                <ng-template ngFor [ngForOf]="getFormattedSelectedCustomAttributeNames()" let-attrName let-index="index">
                  <mat-chip class="chips-container" 
                    [selectable]="true" 
                    [removable]="true" 
                    (removed)="removeSelection('CUSTOM_ATTRIBUTES', -1, attrName)"
                    >
                    {{ attrName }}
                    <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
                  </mat-chip>
                </ng-template>
              </mat-chip-list>
            </div>
            
        </div>
        <hr *ngIf="this.filters.userSelection.companies.length > 0">
        <!-- <div class="row" style="text-align: left; margin: 5px">
          <div class="col leftAlign">Company</div>
        </div>

         <div class="row">
          <div class="col leftAlign">
            <mat-form-field class="field">
              <mat-select placeholder="Select company">
                <ng-template ngFor [ngForOf]="fundCompanyList" let-fund >
                  <ng-template [ngIf]="fund.id == clickedFundId">
                    <ng-template ngFor [ngForOf]="fund.companyList" let-comp let-index="index">
                      <mat-option (click)="select('COMPANY', comp)">
                        {{comp.name}}
                      </mat-option>
                    </ng-template>
                  </ng-template>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </div>
        </div>  -->
        <div *ngIf="fundSelectFlag"><span style="display: inline-flex;align-items: center;"><mat-icon>touch_app</mat-icon>Click on a selected fund to see the companies.</span></div> 
        <ng-template ngFor [ngForOf]="filters.userSelection.funds" let-fund let-fundIndex="index">
          <div class="row mt-4" *ngIf="fund.companyList != null && fund.companyList.length > 0">
            <div class="col-2 row" style="display:flex;align-items:center">
              <div class="col-10">{{fund.name}}</div>
              <div class="col-2"><mat-checkbox matTooltip="Click to select entire fund" [checked]="fund.allCompanies" (change)="getAllFundCompanies(fund)"></mat-checkbox></div>
            </div>
            <div class="col">
              <mat-chip-list>
                <ng-template ngFor [ngForOf]="filters.userSelection.companies" let-comp let-index="index">
                  <ng-template [ngIf]="fund.id == comp.fundId">
                    <mat-chip [selectable]="true" [removable]="true" (removed)="removeSelection('COMPANY', index)">
                      {{comp.name}}
                      <mat-icon *ngIf="!fund.allCompanies" matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
                    </mat-chip>
                  </ng-template>
                </ng-template>
              </mat-chip-list>
            </div>
          </div>
        </ng-template>

        <div class="row mt-3">
          <div class="col-2"></div>
          <div class="col">
            <mat-chip-list>
              <ng-template ngFor [ngForOf]="filters.userSelection.companies" let-comp let-index="index">
                <ng-template [ngIf]="!comp.hasOwnProperty('fundId')">
                  <mat-chip [selectable]="true" [removable]="true" (removed)="removeSelection('COMPANY', index)">
                    {{comp.name}}
                    <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
                  </mat-chip>
                </ng-template>
              </ng-template>
            </mat-chip-list>
          </div>
        </div>
        <hr *ngIf="this.filters.userSelection.customAttribute.length > 0">

        <ng-template ngFor [ngForOf]="getFormattedSelectedCustomAttributeNames()" let-attrName>
          <div class="row mt-4" *ngIf="selectedCustomAttributeNameToValueMapping[attrName] 
          && selectedCustomAttributeNameToValueMapping[attrName].length > 0">
            <div class="col-2" style="display:flex;align-items:center">
              {{ attrName }}
            </div>
            <div class="col">
              <mat-chip-list>
                <ng-template ngFor [ngForOf]="selectedCustomAttributeNameToValueMapping[attrName]" let-attrValue let-index="index">
                    <mat-chip class="chips-container" [selectable]="true" [removable]="true" (removed)="removeAttributeValues(attrName, attrValue)">
                      {{ attrValue }}
                      <mat-icon matChipRemove class="close-mat-icon" (click)="detectChanges()">close</mat-icon>
                    </mat-chip>
                </ng-template>
              </mat-chip-list>
            </div>
          </div>
        </ng-template>
        
      </div>
    </form>
  </mat-expansion-panel>


  <div class="row margin-zero">
    <div class="col padding-zero">
      <ng-template [ngIf] = "allPortfolioData" [ngIfElse] = "portfolioDataLoading">
        <app-equity-portfolio-v2
          [allPortfolioData] = "allPortfolioData"
          [reportingCurrency] = "reportingCurrency"
          [allowEdit] = "false"
          (emitAction) = "emittingActionOnPortfolio($event)">
        </app-equity-portfolio-v2>
      </ng-template>

      <ng-template #portfolioDataLoading>
        <div class="loading-container">
          <span class="loading-text">
            <!-- Building Yellow Report Summary -->
            <mat-spinner style="display: inline-block; margin: 0 auto;" [diameter]="50"></mat-spinner>
          </span>
        </div>
      </ng-template>
    </div>
  </div>
</div>