<div class="widget-container">
  <div class="card widget-card" style="border: none;">
    <!-- <div class="card-header">
      Uploaded Files
    </div> -->
    <div class="card-body" *ngIf="files && files.length>0">
      <div class="row header-row">
        <div class="col-3 file-name">
          {{translateService.getLabel("file_name")}}
        </div>
        <div class="col-3 file-name">
          {{translateService.getLabel("file_description")}}
        </div>
        <!-- <div class="col-1">
            File Type
        </div> -->
        <div class="col-2">
          {{translateService.getLabel("uploaded_on")}}
        </div>
        <div class="col-2">
          {{translateService.getLabel("uploaded_by")}}
        </div>
        <div class="col-1">
          {{translateService.getLabel("download")}}
        </div>
        <div class="col">
          {{translateService.getLabel("delete")}}
        </div>
      </div>
      <div class="data-container">
        <div class="row data-row" *ngFor="let document of files; let index=index">
          <div class="col-3 file-name data-col">
            {{document.name}}
          </div>
          <div class="col-3 file-name data-col">
            {{document.description}}
          </div>
          <!-- <div class="col-1 data-col">
            {{document.type}}
          </div> -->
          <div class="col-2 data-col">
            {{document.createdDate | date:'mediumDate'}}
          </div>
          <div class="col-2 data-col">
            {{document.createdBy}}
          </div>
          <div class="col-1" style="cursor: pointer; font-size: 14px;">
            <button mat-button class="btn" type="button" (click)="download(document.id)" style="background: none">
              <!-- <span class="download-text">Download</span> -->
              <ng-template [ngIf]="document.name.endsWith('xlsx') || document.name.endsWith('xls')">
                <i class="far fa-file-excel"></i>
              </ng-template>
              <ng-template [ngIf]="document.name.endsWith('doc') || document.name.endsWith('docx')">
                <i class="far fa-file-word"></i>              
              </ng-template>
              <ng-template [ngIf]="document.name.endsWith('pdf')">
                <i class="far fa-file-pdf"></i>              
              </ng-template>
              <ng-template [ngIf]="!document.name.endsWith('xlsx') && !document.name.endsWith('xls') && !document.name.endsWith('doc') && !document.name.endsWith('docx') && !document.name.endsWith('pdf')">
                <i class="fas fa-file-download"></i>
              </ng-template>
            </button>
          </div>
          <div class="col" style="font-size: 14px;">
            <button mat-button class="btn" type="button" (click)="delete(document, index)" style="background: none">
              <i class="far fa-trash-alt download-text"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="!files || files.length === 0">
      {{ translateService.getLabel("no_files_are_uploaded_yet") }}.
    </div>
  </div>
</div>
<br/>

<div class="widget-container">
  <div class="card widget-card upload-row" style="border: none;">
    <div class="card-header">
      {{ translateService.getLabel("upload_new_file") }}
    </div>
    <div class="card-body">
      <app-files-upload (fileUploaded)="handleFileUpload($event)"></app-files-upload>
    </div>
  </div>
</div>

<!-- Deleted Files-->
<br/><br/>
<div class="card" *ngIf="deletedFiles && deletedFiles.length > 0">
  <div class="card-header">
    {{ translateService.getLabel("deleted_files") }}
  </div>
  <div class="card-body">
    <div class="row header-row">
      <div class="col-2 file-name">
        {{ translateService.getLabel("file_name") }}
      </div>
      <div class="col-3 file-name">
        {{ translateService.getLabel("file_description") }}
      </div>
      <!-- <div class="col-1">
        File Type
      </div> -->
      <div class="col-2">
        {{ translateService.getLabel("uploaded_on") }}
      </div>
      <div class="col-2">
        {{ translateService.getLabel("uploaded_by") }}
      </div>
      <div class="col-1">
        {{ translateService.getLabel("deleted_on") }}
      </div>
      <div class="col">
        {{ translateService.getLabel("deleted_by") }}
      </div>
    </div>

    <div class="data-container">
      <div class="row data-row" *ngFor="let document of deletedFiles; let index=index">
        <div class="col-2 file-name data-col">
          {{document.name}}
        </div>
        <div class="col-3 file-name data-col">
          {{document.description}}
        </div>
        <!-- <div class="col-1 data-col">
          {{document.type}}
        </div> -->
        <div class="col-2 data-col">
          {{document.createdDate| date:'mediumDate'}}
        </div>
        <div class="col-2 data-col">
          {{document.createdBy}}
        </div>
        <div class="col-1">
          {{document.lastModifiedDate | date:'mediumDate'}}
        </div>
        <div class="col">
          {{document.lastModifiedBy}}
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="!deletedFiles || deletedFiles.length === 0">
    {{ translateService.getLabel("no_files_are_uploaded_yet") }}.
  </div>
</div>
<a style="display: none;" #downloadLink></a>