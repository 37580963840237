<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' [headerToBeAppended]="showConsolSummary ? 'InvestmentSummary_ConsolSummary' : 'InvestmentSummary'" (navClick)="navClicked($event)"></nav-menu-header>

<div class="card">
    <div class="top-header">
        <span style="font-size: 20px">{{newCompany.name}}</span>
    
        <span ngbDropdown class="d-inline-block" placement="bottom-right" style="float: right;">
          <span id="toggleMenu" ngbDropdownToggle>
            <i class="fas fa-ellipsis-v ellipsis-icon" style="font-size: large;"></i>
          </span>
          <div ngbDropdownMenu aria-labelledby="toggleMenu">
            <button ngbDropdownItem class="btn" 
             (click)="showValDates = false; initiateApprovalSystem()">
              {{ translateService.getLabel("show_valuation_dates") }}
            </button>
    
            <button ngbDropdownItem class="btn" *ngIf ="!ums.currentUserCompanyPermissionReadOnly" 
            (click)="showByTopMenu('userVD');">
              {{ translateService.getLabel("add_valuation_dates") }} ({{ translateService.getLabel("custom") }})
            </button>
    
            <button ngbDropdownItem class="btn" (click)="showByTopMenu('consol', selectedCompanyDates[0]);" 
            *ngIf="checkIfLatestValDateIsMBU(selectedCompanyDates[0])">
              {{ translateService.getLabel("consol_summary") }}
            </button>
    
            <button ngbDropdownItem class="btn" *ngIf ="!ums.currentUserCompanyPermissionReadOnly"
            (click)="showBuildUps()">
              {{ translateService.getLabel("add_build_ups") }}
            </button>

            <button ngbDropdownItem class="btn" *ngIf ="!ums.currentUserCompanyPermissionReadOnly"
            (click)="openValuationDatePopup(valuationPopup)">
              {{ translateService.getLabel("roll_over") }}
            </button>
    
            <!-- <button ngbDropdownItem class="btn" (click)="openCurrencyDashboardPopup(currencyDashboard)">
              Currency Dashboard
            </button >
            <button ngbDropdownItem class="btn" (click)="showByTopMenu('INVESTMENT');">
              Investments
            </button> -->
          </div>
        </span>

        <ng-template [ngIf]="!ums.currentUserCompanyPermissionReadOnly && (showValDates || showValDatesBeta)">
          <span style="float: right; cursor: pointer;">
            <i class="fas fa-cog" style="font-size: large;"
            (click)="openSettingsOption()"></i>
          </span>
        </ng-template>
    </div>
    
    <div *ngIf="showInvestment">
        <app-investment (action)="showInvestment = false"> </app-investment>
    </div>

    <!-- <ng-template [ngIf]="showValDates">
        <div [chart]="waterFallChart"></div>
    
        <div style="margin: 0px">
          <table class="table table-condensed" id="container">
            <tr class="bg primary-bg">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th style="width: 50px">
                  <button mat-raised-button class="primary-light-bg p-button" (click)="showValDates = false">Close</button>
                </th>
            </tr>
            </table>
        </div>
    </ng-template> -->

    <ng-template [ngIf]="showValDates">
      <div style="margin: 0px">
        <table class="table table-condensed" id="container" style="margin: 0;">
          <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="width: 50px">
                <button mat-raised-button class="primary-light-bg p-button" (click)="showValDates = false">{{ translateService.getLabel("close") }}</button>
              </th>
          </tr>
        </table>
      </div>

        <div class="heading">
    
        </div>
        <div class="row">
          <div class="sf-table">
              <ejs-grid #valuationDatesGrid [dataSource]='valuationDateGridDataSource' height=335 width='auto'>
                  <e-columns>
                      <e-column headerText='{{ translateService.getLabel("valuation_date") }}' width='150' textAlign='Left'></e-column>
                      <e-column headerText='{{ translateService.getLabel("valuation_amount") }}' width='100' textAlign='Left'></e-column>
                      <e-column headerText='{{ translateService.getLabel("security") }}' width='100' textAlign='Left'></e-column>
                      <e-column headerText='{{ translateService.getLabel("current_step") }}' width='125' textAlign='Left'></e-column>
                      <e-column *ngIf="showValDatesBeta" headerText='{{ translateService.getLabel("approval_condition") }}' width='175' textAlign='Left'></e-column>
                      <e-column headerText='{{ translateService.getLabel("approval_requests") }}' headerTextAlign="Center" width='125'></e-column>
                      <e-column headerText='' width='25' textAlign='Left'></e-column>
                      <e-column headerText='' width='25' textAlign='Left'></e-column>
                      <e-column headerText='' width='25' textAlign='Left'></e-column>
                      <e-column headerText='' width='25' textAlign='Left'></e-column>
                      <e-column headerText='' width='50' textAlign='Left'></e-column>
                  </e-columns>
                  <ng-template #rowTemplate let-data>
    
                    <ng-template [ngIf]="!data.loading" [ngIfElse]="loadingMessage">  
                      <tr>
                          <td *ngIf="!showValDatesBeta" class="td-parent-cell pl-5" colspan="10">
                            
                            {{data.name  | date: "mediumDate" }} &nbsp; &nbsp;
                            
                            <i class="fas fa-grip-horizontal action-icon icon-active"
                            (click)="showVersionComparison(data)" *ngIf="data.value.length > 1"></i>
                              
                          </td>
                          <td *ngIf="showValDatesBeta" class="td-parent-cell pl-5" colspan="11">

                            {{data.name  | date: "mediumDate" }} &nbsp; &nbsp;
                            
                            <i class="fas fa-grip-horizontal action-icon icon-active"
                            (click)="showVersionComparison(data)" *ngIf="data.value.length > 1"></i>
                              
                          </td>
                      </tr>
                      <tr *ngFor="let item of data.value;let i = index;">
                          <td class="td-cell" (mouseenter)="activeCommentIconId = item.id" (mouseleave)="activeCommentIconId = 0">
                              <div class="row m-0">
                                  <div class="col-3">
                                    <ejs-radiobutton label="" name="chkbox{{data.index}}" 
                                    [disabled] = "ums.currentUserCompanyPermissionReadOnly"
                                    (change)="selectVersionV2(data.value, item)" 
                                    cssClass="e-success" 
                                    [checked]="item.frozen"></ejs-radiobutton>
                                    <!-- <i class="fas fa-check-circle icon-active" *ngIf="item.frozen" style="margin-top: 3px;"></i> -->
                                  </div>
                                  <div class="col pl-0 versionName">
                                    <span (click)="updateVersion(item)">
                                      <label class="row m-0" style="margin-left: 10px !important; cursor: pointer">
                                        {{(item.versionName || item.version)}}
                                      </label>
                                    </span>
                                  </div>
                                  <div class="col">
                                    <app-comments *ngIf="item.showIcon || activeCommentIconId == item.id" style="padding-left: 10px;" [grpId]="'INVESTMENT_SUMMARY'" [widgetKey]="'VERSIONS'" [formId]="item.id" [showIcon]="item.showIcon"> 
                                    </app-comments>
                                  </div>
                              </div>
                          </td>
                          <td class="td-cell">
                            {{item.currency}} 
                            <ng-template [ngIf]="item.status == 'Submitted' && invSummaryService.checkWhetherBusinessUnitIsSubmitted(item) 
                            && (item.stake || item.stake >= 0)" [ngIfElse] = "showZeroStakeValue"> 
                              {{item.stake | number : "1.1-1"}}
                            </ng-template>
                            <ng-template #showZeroStakeValue>
                              0.0
                            </ng-template>
                          </td>
                          <td class="td-cell">
                              {{item.security | titlecase}}
                          </td>
     
                          <td *ngIf="!showValDatesBeta" class="td-cell" style="padding-left: 10px;">
                            <app-old-approval-status [item]="item"></app-old-approval-status>
                          </td>
                          <td *ngIf="!showValDatesBeta" class="td-cell" style="text-align: center;">
                            <app-old-approval-requests [item]="item" (updateValDatesTable)="updateApprovalMatrix($event)"></app-old-approval-requests>
                          </td>
                          <td *ngIf="showValDatesBeta" class="td-cell" style="padding-left: 10px;">
                            <app-new-approval-status [item]="item"></app-new-approval-status>
                          </td>
                          <td *ngIf="showValDatesBeta" class="td-cell">
                            <ng-template [ngIf]="item.status == 'Submitted' && invSummaryService.checkWhetherBusinessUnitIsSubmitted(item)">
                              <div class="disable-text" *ngIf="!!item.approvalSystem && !!item.selectedCondition">
                                <span>{{item.selectedCondition.conditionName}} </span>
                                  <em class="fa fa-info-circle info-icon" style="padding-left: 4px" customToolTip [contentTemplate]="showTooltip">
                                  </em>
                              </div>
                              <ng-template #showTooltip>
                                <div style="display: flex; flex-direction: column">
                                  <span *ngFor="let condition of approvalService.allConditions">
                                    <span *ngIf="item.selectedCondition.id == condition.id">
                                        <div class="tooltip-approver" style="padding-bottom: 8px;">{{condition.conditionName}}</div>
                                        <div class="condition-desc">{{condition.description}}</div>
                                        <ng-template ngFor [ngForOf]="condition.approverDetails" let-details let-i="index">
                                          <div style="padding-bottom: 2px;">
                                            <span class="tooltip-approver">Approver {{i+1}}: </span>
                                            <ng-template ngFor [ngForOf]="details.approverDetailsPerLevel" let-approver let-l="index">
                                              <span *ngIf="(details.approverDetailsPerLevel.length - 1) != l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}},</span>
                                              <span *ngIf="(details.approverDetailsPerLevel.length - 1) == l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}}</span>
                                            </ng-template>
                                          </div>
                                        </ng-template>
                                    </span>
                                  </span>
                                </div>
                              </ng-template>
                              <mat-form-field *ngIf="!item.approvalSystem" class="custom-input-select">
                                <mat-select disableOptionCentering panelClass="myPanelClass" [placeholder]="translateService.getLabel('select_condition')" [value]="item.selectedCondition.id" (selectionChange)="selectCondition($event, item)">
                                  <mat-option style="padding-right: 0;" *ngFor="let condition of approvalService.allEnabledConditions" [value]="condition.id">
                                    <div class="row">
                                      <div class="col-10" style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" *ngIf="condition.conditionName != null">
                                        {{condition.conditionName}}
                                      </div>
                                      <div class="col-2" style="padding-left: 5px;">
                                        <em *ngIf="condition.conditionName != 'None'" class="fa fa-info-circle info-icon" customToolTip [contentTemplate]="showTooltipDropdown">
                                        </em>
                                      </div>
                                    </div>
                                    <ng-template #showTooltipDropdown>  
                                      <div style="display: flex; flex-direction: column">
                                        <div class="tooltip-approver" style="padding-bottom: 8px;">{{condition.conditionName}}</div>
                                        <div class="condition-desc">{{condition.description}}</div>
                                        <ng-template ngFor [ngForOf]="condition.approverDetails" let-details let-i="index">
                                          <div style="padding-bottom: 2px;">
                                            <span class="tooltip-approver">Approver {{i+1}}: </span>
                                            <ng-template ngFor [ngForOf]="details.approverDetailsPerLevel" let-approver let-l="index">
                                              <span *ngIf="(details.approverDetailsPerLevel.length - 1) != l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}},</span>
                                              <span *ngIf="(details.approverDetailsPerLevel.length - 1) == l" [ngClass]="{'tooltip-userName' : approver.type == 'user', 'tooltip-groupName' : approver.type == 'group'}"> {{approver.name}}</span>
                                            </ng-template>
                                          </div>
                                        </ng-template>
                                      </div>
                                    </ng-template>
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>                  
                            </ng-template>
                          </td>
                          <td *ngIf="showValDatesBeta" class="td-cell" style="text-align: center;">
                            <app-new-approval-requests [item]="item" (reloadValDates)="reloadValdates()"></app-new-approval-requests>
                          </td>
                          <td class="td-cell">
                            <ng-template [ngIf]="!ums.currentUserCompanyPermissionReadOnly && !item.approvalSystemStatus">
                              <i class="fa fa-edit action-icon icon-active"
                                (click)="openSAF(item, businessUnitPopup)">
                              </i>
                            </ng-template>
                          </td>
                          <td class="td-cell">
                              <i class="fas fa-binoculars action-icon icon-active"
                                (click)="openValuation(item, businessUnitPopup)"
                                *ngIf="item.status == 'Submitted' && invSummaryService.checkWhetherBusinessUnitIsSubmitted(item)">
                              </i>
                          </td>
                          <td class="td-cell">
                            <span [matTooltip]="item.frozen && data.value.length > 1 ? 'Please change the concluded version to delete this version' : ''">
                              <i class="fa fa-trash action-icon"
                              [ngClass]="item.frozen && data.value.length > 1 ? 'disabled-icon' : 'icon-active'"
                                *ngIf="!item.approvalSystemStatus && item.id !== primaryCompanyId"
                                aria-hidden="true"
                                (click)="deleteCompany(item)">
                              </i>
                            </span>
                          </td>
                          <td class="td-cell">
                            <i class="fas fa-share-alt action-icon icon-active" *ngIf="!item.approvalSystemStatus && item.status !== 'Initiated' && !ums.currentUserCompanyPermissionReadOnly" aria-hidden="true" 
                            (click)="openSharePopUp(item, businessUnitPopup, valuationSharePopUp)"></i>
                          </td>
                          <td class="td-cell" style="text-align: right; padding-right: 16px;">
                            <i class="far fa-copy action-icon icon-active" 
                            *ngIf="item.status !== 'Initiated' 
                            && item.frozen 
                            && item.id !== primaryCompanyId"
                            (click)="copyVersion(item)"></i>

                            <!-- Keep tootltip text in two lines to make text wrap work -->
                            <i class="fas fa-info-circle action-icon icon-active" 
                                matTooltip="{{translateService.getLabel('created_by')}}: {{item.createdByName}}
                                {{translateService.getLabel('created_on')}}: {{item.createdDate | date:'medium'}}" 
                                style="padding-left: 10px;" 
                                matTooltipClass="mat-tooltip-sts"
                           ></i>
                          </td>

                        </tr>
                    </ng-template>
    
                    <ng-template #loadingMessage>
                      <div style="margin-left: 20px;">{{ translateService.getLabel("loading") }}...</div>
                    </ng-template>
    
                  </ng-template>
              </ejs-grid>
              <!-- <div class="col-12">
                  <div class="row row-footer">
                      <div class="col-6 offset-6">
                          <div class="row">
                              <div class="col-4">
                                  <select class="form-control txtSimple">
                                      <option value="">Select Valuation Date</option>
                                  </select>
                              </div>
                              <div class="col-4">
                                  <input type="text" class="form-control txtYellow" placeholder="Enter version name">
                              </div>
                              <div class="col-4">
                                  <button class="btn btn-theme">Create Version</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div> -->
          </div>
        </div>
    </ng-template>

    <app-version-comparison [versions]="versionsForCompare" [companyId]="primaryCompanyId"
      (close)="showVersionComparisonUI = false" *ngIf="showVersionComparisonUI">

    </app-version-comparison>


    <app-consol-summary *ngIf="showConsolSummary && selectedCompanyConsolForm && selectedCompanyConsolForm.investment" 
      [latestCompanyValuationDate]="selectedCompanyConsolForm" 
      [primaryCompanyId]="primaryCompanyId"
      [selectedCompanyDates] = "selectedCompanyDatesWithAllVersions"
      (action)="showConsolSummary = false; updateInvestmentInForm()">
    </app-consol-summary>

    <!-- [consolSummary]="invSummaryService.businessUnitsValSummaryForAllIds?.summary[selectedCompanyConsolForm.id]" -->

  <app-build-up *ngIf="showBuildUpSummary" [companyId]="primaryCompanyId" (closeBuildUp) = "showBuildUpSummary = false" (buildUpSaved) = "initValueBridge()" ></app-build-up>

  <app-old-investments *ngIf="showUserValDates" (action)="showUserValDates = false"></app-old-investments>
  <br>

  <ng-template [ngIf]="invSummaryService.showSettings == true">
    <div class="widget settings-styles" id="is_Settings">
      <div class="row">
        <div class="col-6">
          <span class="header">{{ translateService.getLabel("settings") }}</span>
        </div>
        <div class="col-6" style="text-align: right;">    
          <button mat-raised-button class="primary-bg p-button" (click) = "saveInvestmentSummarySettings()">
            {{ translateService.getLabel("save") }}
          </button>
            
          <button style="margin: 0px 20px 0px 20px;" mat-raised-button class="primary-bg p-button" (click) = "closeSettingsOption()">
            {{ translateService.getLabel("close") }}
          </button>
        </div>
      </div>

      <div class="row" style="margin-top: 10px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("primary_metric") }} </span>
        </div>
        <div class="col-3">
          <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.metric">
                <mat-option value="bevRevenue">{{ translateService.getLabel("revenue") }}</mat-option>
                <mat-option value="bevEbitda">{{ translateService.getLabel("ebitda") }}</mat-option>
                <mat-option value="bevEbit">{{ translateService.getLabel("ebit") }}</mat-option>
                <mat-option value="bevEbitda_Capex">{{ translateService.getLabel("ebitda-capex") }}</mat-option>
                <mat-option value="evGrossProfit">{{ translateService.getLabel("gross_profit") }}</mat-option>
                <mat-option value="pe">{{ translateService.getLabel("net_income") }}</mat-option>
                <mat-option value="pbv">{{ translateService.getLabel("book_value") }}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin-top: 10px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("secondary_metric") }} </span>
        </div>
        <div class="col-3">
          <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.secondaryMetric">
                <mat-option value="bevRevenue">{{ translateService.getLabel("revenue") }}</mat-option>
                <mat-option value="bevEbitda">{{ translateService.getLabel("ebitda") }}</mat-option>
                <mat-option value="bevEbit">{{ translateService.getLabel("ebit") }}</mat-option>
                <mat-option value="bevEbitda_Capex">{{ translateService.getLabel("ebitda-capex") }}</mat-option>
                <mat-option value="evGrossProfit">{{ translateService.getLabel("gross_profit") }}</mat-option>
                <mat-option value="pe">{{ translateService.getLabel("net_income") }}</mat-option>
                <mat-option value="pbv">{{ translateService.getLabel("book_value") }}</mat-option>
            </mat-select>
        </mat-form-field>
        </div>
     </div>

      <div class="row" style="margin-top: 10px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("primary_period") }} </span>
        </div>
        <div class="col-3">
          <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.period">
                <mat-option value="fy">{{ translateService.getLabel("ltm") }}</mat-option>
                <mat-option value="ntm">{{ translateService.getLabel("ntm") }}</mat-option>
                <mat-option value="ntm+1">{{ translateService.getLabel("ntm") }} + 1</mat-option>
                <mat-option value="fy-1">FY - 1</mat-option>
                <mat-option value="fy+1">FY + 1</mat-option>
                <mat-option value="fy+2">FY + 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin-top: 10px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("secondary_period") }} </span>
        </div>
        <div class="col-3">
          <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.secondaryPeriod">
                <mat-option value="fy">{{ translateService.getLabel("ltm") }}</mat-option>
                <mat-option value="ntm">{{ translateService.getLabel("ntm") }}</mat-option>
                <mat-option value="ntm+1">{{ translateService.getLabel("ntm") }} + 1</mat-option>
                <mat-option value="fy-1">FY - 1</mat-option>
                <mat-option value="fy+1">FY + 1</mat-option>
                <mat-option value="fy+2">FY + 2</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" style="margin-top: 10px;padding: 2px 0px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("reporting_currency") }} </span>
        </div>
        <div class="col-3"  style="margin-left: 21px;padding-top: 5px;">
          <!-- <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.currency">
              <mat-option *ngFor="let currency of currencyExchange.currencyList" value="{{ currency.id }}">{{ currency.country }} - {{ currency.id }}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          {{companyValuationSettingsInput.currency}}
        </div>
      </div>

      <div class="row" style="margin-top: 10px;">
        <div class="col-2">
          <span class="labels-style"> {{ translateService.getLabel("type_of_value_bridge") }} </span>
        </div>
        <div class="col-3">
          <mat-form-field class="settings-field">
            <mat-select [(ngModel)]="companyValuationSettingsInput.valueBridge">
                <mat-option value="ENTERPRISE">{{ translateService.getLabel("ev_bridge") }}</mat-option>
                <mat-option value="EQUITY">{{ translateService.getLabel("equity_value_bridge") }}</mat-option>
                <mat-option value="NAV">{{ translateService.getLabel("nav_bridge") }}</mat-option>
                <mat-option value="ADVANCED_NAV_BRIDGE" 
                  [disabled] = "latestValuationDate && latestValuationDate.businessUnits && latestValuationDate.businessUnits.length > 0">
                  {{ translateService.getLabel("adv_nav_bridge") }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-template>

    <div *ngIf="(investmentDateInfo && investmentDateInfo.formData && primaryCompanyId && !showConsolSummary && !showInvestment)" >
        <app-investment-page-summary-v2 
          [formData]="investmentDateInfo.formData"
          [companyId]="primaryCompanyId"
          [selectedValuationDates]="selectedCompanyDates"
          [buildUpSaved] = "updateValueBridge" 
          [valDateUpperLimit]="valDateUpperLimit"
          [settings]="companyValuationSettings"
          (consolFormSelected)="showByTopMenu('consol', $event)"
          (upperLimitChanged) = "upperLimitChanged($event)"
        ></app-investment-page-summary-v2>
    </div>
</div>

<ng-template #currencyDashboard let-currencyDashboard>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>{{ translateService.getLabel("currency_dashboard") }}</h6>
    </div>
    <button type="button" class="close" aria-label="{{ translateService.getLabel('close') }}" (click)="currencyDashboard.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span style="font-weight: bold;">{{ translateService.getLabel("valuation_date") }}:</span> <span> {{investmentDateInfo.valuationDate | date: "mediumDate"}}</span><br><br>
    <div class="table table-condensed" id="container">
      <table class="table">
        <tr class="grey-bg">
          <th>{{ translateService.getLabel("data") }}</th>
          <th class="center-align">{{ translateService.getLabel("input_currency") }}</th>
          <th class="center-align">{{ translateService.getLabel("valuation_currency") }}</th>
          <th>{{ translateService.getLabel("conversion") }}</th>
        </tr>
        <tr>
          <td>{{ translateService.getLabel("historical_financials") }}</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr>
          <td>{{ translateService.getLabel("projected_financials") }}</td>
          <td class="center-align">{{investmentDateInfo.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForFinHist'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Bid_Details && investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_Bidder_Name.length > 0">
          <td>{{ translateService.getLabel("bid_details") }}</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Bid_Details.OT_BD_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForBidDetails'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Secondaries && investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_Buyer_Name.length > 0">
          <td>{{ translateService.getLabel("secondaries") }}</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Secondaries.OT_SEC_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign">{{ investmentDateInfo['exchangeRateForSecondaries'] | number: "1.2-2"}}</td>
        </tr>
        <tr *ngIf="investmentDateInfo.formData.OTHERS && investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND && investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_NAME.length > 0">
          <td>{{ translateService.getLabel("pori") }}</td>
          <td class="center-align">{{investmentDateInfo.formData.OTHERS.OT_Others.OT_PARENT_LAST_FUND.OT_LAST_FUND_CURRENCY}}</td>
          <td class="center-align">{{investmentDateInfo.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}}</td>
          <td class="rightAlign"> {{ investmentDateInfo['exchangeRateForPori'] | number: "1.2-2" }} </td>
        </tr>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #editValuationDateModal let-editValuationDateModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>{{ translateService.getLabel("currency_dashboard") }}</h6>
    </div>
    <button type="button" class="close" aria-label="{{ translateService.getLabel('close') }}" (click)="editValuationDateModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class ='row'>
      <div class ='col-4'>
        <b>{{ translateService.getLabel("valuation_date") }}</b>
      </div>
      <div class ='col-8'>
        <mat-form-field (click)="vDatePicker.open()">
          <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" [(ngModel)]="editedValuationDate">
          <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
      </mat-form-field>
      </div>
    </div>

    <div class ='row'>
      <div class ='col-12' style="text-align:right;margin-top:5px;">
        <button mat-raised-button class="primary-bg p-button" (click)="addNewValuationDate(editValuationDateModal)">
          {{translateService.getLabel("add")}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valuationSharePopUp let-valuationSharePopUpModal>
    <app-guest-users-share-popup [shareInput]="shareInput" [selectedFormToShare]="selectedFormToShare" [newCompany]="newCompany" [valuationSharePopUpModal]="valuationSharePopUpModal"></app-guest-users-share-popup>
</ng-template>

<ng-template #businessUnitPopup let-businessUnitModal>
  <div class="modal-header">
    <div class="modal-title" style="width: 100%;">
      <h6>{{translateService.getLabel("business_units_enterprise_value")}} </h6>
    </div>
    <button type="button" class="close" aria-label="{{ translateService.getLabel('close') }}" 
    (click)="businessUnitModal.dismiss('Cross click'); mode = this.typeOfModes.VIEW_TYPE">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-template [ngIf] = "selectedBusinessUnits && selectedBusinessUnits.length > 0" [ngIfElse] = "loadingBusinessUnitsPopUp">
      <app-business-unit-popup-ui [selectedBusinessUnits] = "selectedBusinessUnits" [mode] = "mode" 
      (emitAction) = "emitAction($event, valuationSharePopUp)"></app-business-unit-popup-ui>
    </ng-template>
    <ng-template #loadingBusinessUnitsPopUp>
      <div class="loading-container">
        <p class="loading-text">Loading...</p>
      </div>
    </ng-template>
  </div>
</ng-template>

<!-- create new valuation date popup -->
<ng-template #valuationPopup let-valuationDateSelectionPopup>
  <app-add-valuation-popup [valuationDateSelectionPopup]="valuationDateSelectionPopup" [newCompany]="newCompany" (saveValuationDateDetails)="addValuationDateDetails($event)"></app-add-valuation-popup>
</ng-template>


<ejs-dialog #approveDialog  *ngIf="showApproveDialogBox"
  header='{{ translateService.getLabel("approve") }}/{{ translateService.getLabel("reject") }} {{ translateService.getLabel("request") }}' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  allowDragging=false
  closeOnEscape=false
  enableResize=false
  isModal=true
  [visible]="showApproveDialogBox"
  (close)="hideApproveDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          {{ translateService.getLabel("comment") }}
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" placeholder="{{ translateService.getLabel('comments') }}" maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="approveRejectObj.comments"/>
        </div>
      </div>
    </div>
    
    <div *ngIf="trackRecordData">
      <div class="row" style="align-items: center;" >
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            {{ translateService.getLabel("implied_multiple") }}
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            {{ translateService.getLabel("ltm") }} {{ translateService.getLabel("ebitda") }}
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            {{ translateService.getLabel("equity_value") }}
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            {{ translateService.getLabel("fund") }} NAV
          </div>
          <div class="col dialog-data">
            -
        </div>
      </div>
      </div>

      <div class="row" style="align-items: center;">
        <div class="row" style="align-items: center; margin: 1vw">
          <div class="col dialog-head">
            {{translateService.getLabel("moic")}}
          </div>
          <div class="col dialog-data">
            -
          </div>
        </div>
      </div>

    </div>

    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          <button class="button-theme-primary" style="border: 2px solid; color: white; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px; width: 100px"
            (click)="approveRequest(approveRejectObj) ">
            {{ translateService.getLabel("approve") }}
          </button>
        </div>
        <div class="col dialog-data">
          <button class="button-theme-secondary" style="border: 2px solid; background-color: white; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px; width: 100px"
          (click)="rejectRequest(approveRejectObj)">
            {{ translateService.getLabel("reject") }}
          </button>
        </div>
      </div>
    </div>
    <!-- <button class="btn-btn saveApprovalObjectBtn" (click)="approveObject()">Approve</button>  -->
  </ng-template>
</ejs-dialog>


<ejs-dialog #approveDialog  *ngIf="showVersionNameDialog"
  [header]='translateService.getLabel("version_name")' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  closeOnEscape=false
  isModal=true
  [visible]="showVersionNameDialog"
  (close)="hideVersionNameDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          {{ translateService.getLabel("version_name") }}
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" [placeholder]='translateService.getLabel("version_name")' maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="newFormVersion"/>
        </div>
      </div>
    </div>

    <div class="row" style="align-items: right;">
      <div class="row" style="align-items: right; margin: 1vw">
        <div class="col dialog-head">
          <button class="button-theme-primary" style="border: 2px solid; color: white; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px;"
            (click)="addValuationDateVersion() ">
            {{ translateService.getLabel("create_version") }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>

<ejs-dialog #approveDialog  *ngIf="showUpdateVersionNameDialog"
  header='{{ translateService.getLabel("update_version_name") }}' 
  showCloseIcon=true
  width='400px' 
  height='250px'
  allowDragging=false
  closeOnEscape=false
  enableResize=false
  isModal=true
  [visible]="showUpdateVersionNameDialog"
  (close)="hideUpdateVersionNameDialog()">

  <ng-template #content>
    <div class="row" style="align-items: center;">
      <div class="row" style="align-items: center; margin: 1vw">
        <div class="col dialog-head">
          {{ translateService.getLabel("version_name") }}
        </div>
        <div class="col dialog-data">
          <input class="yellowBackground" type="text" [placeholder]='translateService.getLabel("version_name")' maxlength="100" style="width: 180px; background-color: #FEFBEE; border-radius: 6px; border: 1px solid #FFD32B80; height: 30px"  [(ngModel)]="newVersionName"/>
        </div>
      </div>
    </div>

    <div class="row" style="align-items: right;">
      <div class="row" style="align-items: right; margin: 1vw">
        <div class="col dialog-head">
          <button class="button-theme-primary" style="border: 2px solid; color: white; border-radius: 6px; cursor: pointer; margin: 0px 10px 0px 10px;"
            (click)="updateVersionName() ">
            {{ translateService.getLabel("update") }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ejs-dialog>