import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatMenuTrigger } from '@angular/material';
import { TranslateService } from '../services/translation.service';
import { UtilService } from '../utils/util.service';

@Component({
  selector: 'app-data-drill-down-menu',
  templateUrl: './data-drill-down-menu.component.html',
  styleUrls: ['./data-drill-down-menu.component.scss']
})
export class DataDrillDownMenuComponent implements OnInit {
  @Input() value;
  @Input() suffix;
  @Input() decimalPoint;
  @Input() valueInPlaceOfZero;
  @Input() onlyPositiveAllowed;

  @Input() label;
  @Input() valuationDate;
  @Input() formula;
  @Input() underlyingMetrics;
  @Input() source;
  @Input() valDateId;
  @Input() currency;

  @Input() displayNegativeNo;
  @Input() textField;

  @Input() typeOfSource;

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('drawer') drawer: MatDrawer;

  openDrawerContainerForFirstTime = false;

  contextMenuPosition = { x: '0px', y: '0px' };

  drillDown = {
    "item": "",
    "valDate": "",
    "metric": 0,
    "source": "",
    "formula": [] ,
    "underlyingMetrics": "",
    "valDateId": "",
    "currency" : "",
    "suffix": "",
    "typeOfSource": ""
  };

  constructor(public utilService: UtilService, public translateService: TranslateService) {}

  ngOnInit(): void {
  }

  openDrawer(){
    this.utilService.open(this.drawer);
    this.utilService.drawerOpened = true;
  }

  prepareDrillDown(){
    this.drillDown["item"] = this.label;
    this.drillDown["valDate"] = this.valuationDate;
    this.drillDown["metric"] = this.value;
    this.drillDown["source"] = this.source;
    this.drillDown["formula"] = this.formula;
    this.drillDown["underlyingMetrics"] = this.underlyingMetrics;
    this.drillDown["valDateId"] = this.valDateId;
    this.drillDown["suffix"] = this.suffix;
    this.drillDown["currency"] = this.currency;
    this.drillDown["typeOfSource"] = this.typeOfSource ? this.typeOfSource : "User Entry";
  }

  openMenuList(event: MouseEvent){
    if(!this.openDrawerContainerForFirstTime){
      this.openDrawerContainerForFirstTime = true;
    }

    event.preventDefault();

    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.matMenuTrigger.openMenu();
  }

  stopPropagation(event){
    event.stopPropagation();
  }

}
