import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatSlideToggleChange } from '@angular/material';
import { CompanyListService } from '../../company-list.service';
import { GsFilterService } from './gs-filter.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

interface Country {
  countryCode: string;
  name: string;
}
@Component({
  selector: 'app-gs-filter-container',
  templateUrl: './gs-filter-container.component.html',
  styleUrls: ['./gs-filter-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GsFilterContainerComponent implements OnInit, OnDestroy {
  seletecedFilter: string = 'GEOGRAPHY';

  filters: 'GEOGRAPHY' | 'SECTOR';

  countries: Country[] = [];
  sectors: string[] = [];

  filteredCountries: Country[] = [];
  filteredSectors: string[] = [];

  geographyNameFc = new FormControl();
  sectorNameFc = new FormControl();

  selectedCountries = new Map<string, Country>();
  selectedSectors = new Map<string, string>();

  selectedCountriesOnly = false;
  selectedOnlySectorsOnly = false;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GsFilterContainerComponent>,
    public clService: CompanyListService,
    private cdr: ChangeDetectorRef,
    private gsFilterService: GsFilterService
  ) {}

  ngOnInit(): void {
    // this.clService.allComapniesInFundClone$.subscribe((companies) => {
    const countriesLookUp = {};
    const sectorLookUp = {};
    let country: Country = { name: '', countryCode: '' };
    let sector = '';
    this.gsFilterService.allCountries.forEach((c) => {
      country = { name: c?.name, countryCode: c?.code };
      if (country.countryCode && !countriesLookUp[country['countryCode']]) {
        this.countries.push({ ...country });
        this.filteredCountries.push({ ...country });
        countriesLookUp[country['countryCode']] = true;
      }
    });
    this.gsFilterService.allSectors.forEach((s) => {
      sector = s;
      if (sector && !sectorLookUp[sector]) {
        this.sectors.push(s);
        this.filteredSectors.push(s);
        sectorLookUp[sector] = true;
      }
    });

    const existingSelectedCountriesState: Map<string, Country> = this.gsFilterService.getSelectedCountries();
    const existingSelectedSectors: Map<string, string> = this.gsFilterService.getSelectedSectors();
    if (existingSelectedCountriesState?.size > 0) {
      existingSelectedCountriesState.forEach((c) => {
        if (!this.selectedCountries.has(c.countryCode)) {
          this.selectedCountries.set(c.countryCode, { ...c });
        }
      });
    }

    if (existingSelectedSectors?.size > 0) {
      existingSelectedSectors.forEach((s) => {
        if (!this.selectedSectors.has(s)) {
          this.selectedSectors.set(s, s);
        }
      });
    }
    // });

    this.geographySearch();
    this.sectorSearch();
  }

  geographySearch() {
    this.geographyNameFc.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.filteredCountries = this.countries.filter((c) => {
        return c.name.toLowerCase().includes(value?.trim()?.toLowerCase());
      });
      this.cdr.detectChanges();
    });
  }

  sectorSearch() {
    this.sectorNameFc.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.filteredSectors = this.sectors.filter((s: string) => {
        return s.toLowerCase().includes(value?.trim());
      });
      this.cdr.detectChanges();
    });
  }

  onSelectedFilterChange(filter: string) {
    this.seletecedFilter = filter;
  }

  onCountryToggle(event, country) {
    if (this.selectedCountries.has(country.countryCode)) {
      this.selectedCountries.delete(country.countryCode);
    } else {
      this.selectedCountries.set(country.countryCode, country);
    }
  }

  onSectorToggle(event, sector) {
    if (this.selectedSectors.has(sector)) {
      this.selectedSectors.delete(sector);
    } else {
      this.selectedSectors.set(sector, sector);
    }
  }

  showOnlySelectedCountries(e: MatSlideToggleChange) {
    this.selectedCountriesOnly = e.checked;
  }

  showOnlySelectedSectors(e: MatSlideToggleChange) {
    this.selectedOnlySectorsOnly = e.checked;
  }

  onApply() {
    this.gsFilterService.setSelectedCountries(this.selectedCountries);
    this.gsFilterService.setSelectedSectors(this.selectedSectors);
    this.clService.applyGeoSectorFilters(this.selectedCountries, this.selectedSectors);

    this.dialogRef.close({
      isGsFilterApplied: this.selectedCountries?.size > 0 || this.selectedSectors?.size > 0,
    });
  }

  onClose() {
    this.dialogRef.close({
      isGsFilterApplied: this.gsFilterService.getSelectedCountries()?.size > 0 || this.gsFilterService.getSelectedSectors()?.size > 0,
    });
  }

  ngOnDestroy(): void {}
}
