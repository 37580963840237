import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { UserManagementService } from 'src/app/services/user-management.service';

import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { QXP_PortFolioService } from '../qxp-portfolio.service';
import { EntityValuationData } from './legal-entities.model';

declare let GC: any;

const ENTITY_NAME_PLACHOLDER = "<Entity Name>";

const DATA_KEY_CORPORATE_PARSED = "DATA_KEY_CORPORATE_PARSED";
const DATA_KEY_CORPORATE_EXCEL_JSON = "DATA_KEY_CORPORATE_EXCEL_JSON";

@Component({
  selector: 'app-legal-entities',
  templateUrl: './legal-entities.component.html',
  styleUrls: ['./legal-entities.component.scss']
})
export class LegalEntitiesComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() corpId;
  @Input() entityValuationData;

  progressMessage = "";
  networkChartData; //ToDo: cleanup no more newtork chart
  userUploadedWorkBook;
  excelIO = new GC.Spread.Excel.IO();
  userUploadedJson;
  file: File;

  excelRawData: any;
  allEntityList: Array<String> = new Array<String>();
  childEntityList: Array<String> = new Array<String>();
  entityShareHoldersMap = new Map<String, Map<String, number>>();
  entityOwnershipsMap = new Map<String, Map<String, number>>();
  chartData = new Array<Array<String>>();

  @ViewChild('excelUI', { read: ElementRef, static: true}) excelUI: ElementRef;

  PAGESTATE_UPLOAD_CH_EXCEL = "UPLOAD_CH_EXCEL";
  PAGESTATE_EDIT_CH_EXCEL = "EDIT_CH_EXCEL";
  PAGESTATE_SHOW_CH_DATA = "SHOW_CH_DATA";
  PAGESTATE_PROCSSING = "PROCSSING";
  pageState: string = this.PAGESTATE_PROCSSING;

  constructor(private utilService: UtilService,
    private dataService: DataService,
    private ums: UserManagementService,
    public portfolioService: QXP_PortFolioService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.pageState = this.PAGESTATE_PROCSSING;

    GC.Spread.Sheets.LicenseKey = environment.grapeCityLicense;
    GC.Spread.Excel.IO.LicenseKey = environment.grapeCityLicense;

    this.progressMessage = "Loading . . . .";

    this.activatedRoute.paramMap.subscribe(params => {
      this.corpId = params.get("corpId");
      this.initSavedData();
    });
  }

  initSavedData() {
    this.dataService.getWidgetDataFromDB(DATA_KEY_CORPORATE_PARSED, this.corpId).subscribe(res =>{        
      if(res.body["response"][0]["widgetData"]) {          
        let loadData = res.body["response"][0]["widgetData"];
        // console.log("Got Saved Parsed Data from DB");

        this.loadSavedData(loadData);

        if(this.entityValuationData) {
          this.pageState = this.PAGESTATE_SHOW_CH_DATA;
          this.crossHoldingValuation();
        } else {
          this.setNewPageState();
        }
      } else {
        this.loadExcelRawJSON();
      }

    }, error =>{
      console.log("Failed to load  Saved Parsed Data.", error);
      this.loadExcelRawJSON();
    });
  }

  ngOnChanges() {
    if(this.corpId) {
      this.initSavedData();
    }

    if(this.entityValuationData) {      
      this.pageState = this.PAGESTATE_SHOW_CH_DATA;
      this.crossHoldingValuation();
    } else {
      this.setNewPageState();
    }
  }

  ngAfterViewInit(){
    this.workbookInit();
  }

  async loadExcelRawJSON() {
    // console.log("No Valuation data found. Loading excel raw json...");
    if(!this.corpId) return;
    
    this.dataService.getWidgetDataFromDB(DATA_KEY_CORPORATE_EXCEL_JSON, this.corpId).subscribe(res =>{
      if(res.body["response"][0]["widgetData"]) {
        // console.log("Got Excel raw json f rom db. Loading...", res.body["response"][0]);
        this.progressMessage = "";
        this.pageState = this.PAGESTATE_EDIT_CH_EXCEL;
        
        this.excelRawData = (res.body["response"][0]["widgetData"]);
        this.userUploadedWorkBook.fromJSON(this.excelRawData)
      } else {
        this.setNewPageState();
      }

    }, error =>{
      console.log("Failed to load excel raw json.", error);
      this.setNewPageState();
    });
  }

  async setNewPageState() {
    // console.log("No saved data found. Initializing new page.");
    this.progressMessage = "";
    this.pageState = this.PAGESTATE_UPLOAD_CH_EXCEL;
  }

  async showExcelUI() {
    this.loadExcelRawJSON();
  }

  workbookInit(){
    this.userUploadedWorkBook = new GC.Spread.Sheets.Workbook(this.excelUI.nativeElement);
  }

  async upload(event){
    this.pageState = this.PAGESTATE_EDIT_CH_EXCEL;

    const target: DataTransfer = <DataTransfer>(event.target);

    if (target.files.length === 0 || target.files.length !== 1) {
      this.utilService.showMessage("Please select only one filled template.", "Ok");
      return;
    }

    if(!target.files[0].name.endsWith("xlsx")) {
      this.utilService.showMessage("Please select filled template in xlsx format.", "Ok");
      return;
    }

    this.progressMessage = "Uploading the file . . . .";

    let list:FileList = event.target.files;
    this.file = list.item(0);

    this.openUploadedExcel();
  }

  openUploadedExcel(){
    this.excelIO.open(this.file, (json) => {
      this.userUploadedJson = json
      this.progressMessage = '';
      if(json){
        this.userUploadedWorkBook.fromJSON(json, { ignoreFormula: false })
      }
    });
  }

  cancelExcelExit() {
    this.pageState = this.PAGESTATE_SHOW_CH_DATA;
  }

  processExcel(){
    this.pageState = this.PAGESTATE_PROCSSING;

    this.progressMessage = "Processing the data..."
    this.excelRawData = this.userUploadedWorkBook.toJSON({ ignoreFormula: false, ignoreStyle: false })

    if(this.excelRawData.sheets["CrossHolding"]) {
      let chSheetData = this.excelRawData.sheets["CrossHolding"].data.dataTable;
      this.allEntityList = new Array<String>();
      this.childEntityList = new Array<String>();
      this.entityShareHoldersMap = new Map<String, Map<String, number>>();
      this.entityOwnershipsMap = new Map<String, Map<String, number>>();
      this.chartData = new Array<Array<String>>();
      
      let rowCount = 0;
      for (const row in chSheetData) {
        if(rowCount === 0) {
          //First row is headers
          this.parseEntityNamesFromHeader(chSheetData[row]);
          this.childEntityList = this.allEntityList.slice(1).filter(cName => cName.length > 0);
          // console.log("All Entities", this.allEntityList);
        } else if(rowCount < (this.allEntityList.length+1)){
          try {
            //All Other rows
            let shareHolders = new Map<String, number>();
            let entityName = this.parseEntry(chSheetData[row], shareHolders);

            if(entityName.length > 0) {
              this.entityShareHoldersMap.set(entityName, shareHolders);
            } else {
              continue;
            }
          } catch(Er) {
            console.log("Reached end of excel file while creating corporate companies", Er);
            break;
          }
        }
        rowCount++;
      }

      this.entityOwnershipsMap = this.parseEntityOwnership();

      // console.log("entityShareHoldersMap:", this.entityShareHoldersMap);
      // console.log("entityOwnershipsMap: ", this.entityOwnershipsMap);
      // console.log("chartData: ", this.chartData);

      this.progressMessage = "";

      let nwPlotData = new Map<String, Object>();
      nwPlotData.set("chartData", this.chartData);
      nwPlotData.set("entityOwnershipsMap", this.entityOwnershipsMap);
      this.networkChartData = nwPlotData;

      this.createCompanies();
      this.crossHoldingValuation();
    } else {
      this.progressMessage = "CrossHolding sheet not found...";
      // console.log( "CrossHolding sheet not found...");
    }

    // this.excelIO.save(json, (blob) => {
    //   this.progressMessage = '';
    //   this.userUploadedJson = json;
    // })
  }

  private parseEntityNamesFromHeader(rowData: any) {
    let colCount = 0;
    
    for (const col in rowData) {
      const value = rowData[col].value;
      if(colCount == 2) {
        this.allEntityList.push(value);        
      } else if((colCount > 2) && value && (value != ENTITY_NAME_PLACHOLDER) ) {
        this.allEntityList.push(value);
      }

      colCount++;
    }

  }

  private parseEntry(rowData: any, shareHolders: Map<String, number>) {
    let entityName = "";
    let entityIndex = 0;
    let colCount = 0;
    
    while(colCount <= (this.allEntityList.length + 1)) {
      const value = rowData[colCount].value;
      if(colCount === 0) {
        //First col is only total
        //ToDo: Check for total 100
      } else if(colCount === 1) {
        //Entity Name
        if(value && (value != ENTITY_NAME_PLACHOLDER)) {
          entityName = value;
        }
      } else {
        // console.log("value %", value);
        //ToDo: Chheck if num max 1
        shareHolders.set(this.allEntityList[entityIndex], value);
        entityIndex++;
      }

      colCount++;
    }

    return entityName;
  }

  private parseEntityOwnership() {
    this.entityOwnershipsMap = new Map<String, Map<String, number>>();

    const parentEntity = this.allEntityList[0];
    
    this.allEntityList.forEach(entity => {
      if(this.entityShareHoldersMap.has(entity)) {
        let shareholding: Map<String, number> = this.entityShareHoldersMap.get(entity);

        this.allEntityList.forEach(entityKey => {
          let value = shareholding.get(entityKey);

          if(entity != parentEntity) {
            if(!this.entityOwnershipsMap.has(entityKey)) {
              this.entityOwnershipsMap.set(entityKey, new Map<String, number>());
            }

            let ownerShip = this.entityOwnershipsMap.get(entityKey);
            ownerShip.set(entity, value);
            this.entityOwnershipsMap.set(entityKey, ownerShip);
            // console.log("e2=>e1=>value ", entityKey, entity, );
          }
          if(value) {
            this.chartData.push([entityKey, entity]);
          }
        });
      }
    });

    return this.entityOwnershipsMap;
  }

  createCompanies(){
    if(this.portfolioService.companies.length > 0) return;
    
    this.progressMessage = "Creating Entities..."

    let loggedInUserDetails = this.ums.getSelectedUserDetails();
    let metaData = {
      valuationDate: new Date(),
      security: "EQUITY"
    }

    this.dataService.createNewCorporateCompanies(metaData, this.childEntityList, loggedInUserDetails, this.ums.getApplicationVersion(), this.portfolioService.fundId).subscribe(res=>{
      // console.log("Company Created", res.body);

      this.initNewForms( res.body["response"] );
      this.utilService.showMessage("Entities Saved", "ok");

      //Save parsed data to widget db (originating from excel related)
      this.saveEntityDatatoDB();

      //Initialize empty cross holding data structure.
      this.initCrossHoldingDataStruture();
      this.pageState = this.PAGESTATE_SHOW_CH_DATA;
      this.progressMessage = "";
    }, err=>{
      console.log("Error while creating company", err)
      this.utilService.showMessage("ERROR: Entities NOT Saved", "Ok");
      this.progressMessage = "";
      this.pageState = this.PAGESTATE_EDIT_CH_EXCEL;
    })
  }

  initNewForms(newForms) { 
    this.portfolioService.prepareCompanyData(newForms);
    this.portfolioService.preparePortfolioData();

    this.portfolioService.searchedForms = this.portfolioService.getInvestmentDateForms();
  }

  private saveEntityDatatoDB() {
    //Save the excel raw json
    this.dataService.saveWidgetDataToDB(DATA_KEY_CORPORATE_EXCEL_JSON, JSON.stringify(this.excelRawData), this.corpId).subscribe(res=>{
      // console.log("Excel JSON saved to db", res.body);
    }, error=>{
      console.log("Failed to save excel json data to db");
    })

    //Save parsed data
    let parsedData = {
      allEntityList: this.allEntityList,
      childEntityList: this.childEntityList,
      entityShareHoldersMap: this.mapToObject(this.entityShareHoldersMap),
      entityOwnershipsMap: this.mapToObject(this.entityOwnershipsMap),
      chartData: this.chartData
    };

    // console.log("saving parsedData", parsedData);
    this.dataService.saveWidgetDataToDB(DATA_KEY_CORPORATE_PARSED, parsedData, this.corpId).subscribe(res=>{
      // console.log("parsedData saved to db", res.body);
    }, error=>{
      console.log("Failed to save parsed data to db");
    })
  }

  private loadSavedData(loadData) {
    if(loadData.allEntityList) {
      this.allEntityList = loadData.allEntityList;
    }
    
    if(loadData.childEntityList) {
      this.childEntityList = loadData.childEntityList;
    }
    
    if(loadData.entityShareHoldersMap) {
      this.entityShareHoldersMap = this.objectToMap(loadData.entityShareHoldersMap);
    }
    
    if(loadData.entityOwnershipsMap) {
      this.entityOwnershipsMap = this.objectToMap(loadData.entityOwnershipsMap);
    }
    
    if(loadData.chartData) {
      this.chartData = loadData.chartData;
    }
  }

  private initCrossHoldingDataStruture() {
    let index = 0;

    this.childEntityList.forEach(entity => {
      let valuationStatus = false;

      this.entityValuationData.set(entity, {
        valuationStatus: valuationStatus
      });
      
      index++;
    });

  }

  crossHoldingValuation() {
    if(!this.entityValuationData) return;

    let readyForCHValuation = true;

    //Check if Valuation status is ready for all entities 
    this.entityValuationData.forEach((value, key) => {
      readyForCHValuation = readyForCHValuation && value.valuationStatus;
    } );

    // console.log("Ready for CH Val: ", readyForCHValuation);

    if(readyForCHValuation) {
      this.progressMessage = "Cross Holding Valuation in progress..."
      this.pageState = this.PAGESTATE_PROCSSING;

      let ownership = new Array<Array<number>>();
      let fairValuePreCrossHolding: Array<number> = new Array<number>();
      
      //Convert to array format for cross holding valuation api
      this.childEntityList.forEach(entity => {
        let thisEntityOwnership = this.entityOwnershipsMap.get(entity);
        let o = Array<number>();

        if(!thisEntityOwnership) {
          return;
        }
        
        this.childEntityList.forEach(ent => {
          o.push(thisEntityOwnership.get(ent));
        });

        ownership.push(o);

        fairValuePreCrossHolding.push(this.entityValuationData.get(entity).fairValuePreCrossHolding);
      });
      
      this.dataService.crossHoldingValuation(this.childEntityList, fairValuePreCrossHolding, ownership).subscribe(res=>{
        this.progressMessage = "";
        // console.log("Cross Holding Valuation ready", res.body);

        const chValuation = res.body["response"];
        // console.log("Cross Holding Valuation", chValuation);

        let index = 0;
        this.childEntityList.forEach(entity => {
          let entityValuation = this.entityValuationData.get(entity);
          entityValuation.crossHoldingValue = chValuation[index];
          entityValuation.fairValuePostCrossHolding = entityValuation.fairValuePreCrossHolding  + chValuation[index];

          this.entityValuationData.set(entity, entityValuation);

          index++;
        });

        this.pageState = this.PAGESTATE_SHOW_CH_DATA;

      }, err=>{
        this.pageState = this.PAGESTATE_SHOW_CH_DATA;
        this.progressMessage = "Error in Cross-holding valuation";
        console.log("Error in Cross-holding valuation", err)
      })
    } else {
      this.pageState = this.PAGESTATE_SHOW_CH_DATA;
      this.progressMessage = "Valuation Data not ready.";
    }
  }

  private mapToObject(m) {
    let lo = {};
    for(let[k,v] of m) {
        if(v instanceof Map) {
            lo[k] = this.mapToObject(v);
        }
        else {
            lo[k] = v;
        }
    }
    return lo;
  }

  private objectToMap(o) {
    let m = new Map();
    for(let k of Object.keys(o)) {
        if(o[k] instanceof Object) {
            m.set(k, this.objectToMap(o[k]));
        }
        else {
            m.set(k, o[k]);
        }    
    }
    return m;
  }

    
  //ToDo:CORP REMOVE Temp function for dummy values
  private createDummyData() {
    let index = 0;
    let evd: Map<String,EntityValuationData> = new Map<String,EntityValuationData>();

    this.childEntityList.forEach(entity => {
      // let valuationStatus = (index/2 == 0) ? true : false;
      let valuationStatus = true;
      let operatingValue = 60*(index+1);
      let nonOperatingValue = 40*(index+1);
      let fairValuePreCrossHolding = operatingValue + nonOperatingValue;

      evd.set(entity, {
        valuationStatus: valuationStatus,
        operatingValue: operatingValue,
        nonOperatingValue: nonOperatingValue,
        fairValuePreCrossHolding: fairValuePreCrossHolding
      });
      
      index++;
    });

    return evd;
  }
}