import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { 
  IStakeholderAllocation, 
  IStakeholderAllocationDetails, 
  IStakeholderAllocationSecurity, 
  IStakeholderSummary
} from './interface/stakeholder.allocation';
import { IStakeholderFund } from './interface/stakeholder.util';
import { IStakeholderUser } from './interface/stakeholder.user';
import { cloneDeep, intersection, isEqual } from 'lodash';
import { IStakeholderSelection } from './interface/stakeholder.selection';
import { ToastService } from 'src/app/utils/toast.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilService } from 'src/app/utils/util.service';
import { TranslateService } from 'src/app/services/translation.service';

enum StakeholderAllocationMode {
  NEW, EDIT
}

@Component({
  selector: 'app-fund-allocation',
  templateUrl: './fund-allocation.component.html',
  styleUrls: ['./fund-allocation.component.scss']
})
export class FundAllocationComponent implements OnInit {
  objectKeys = Object.keys;

  myDetails: {
    id: string;
    organization: {
      id: string;
    };
  } = null;
  dataLoaded: boolean = false;
  isMasterUser: boolean = false;
  editView: boolean = false;

  allFunds: IStakeholderFund[] = [];
  lastFundIdsForStakeholer: Set<string> = new Set<string>();
  selectedFundChipList: {
    fundId: string;
    fundName: string;
  }[] = [];
  stakeholderSummary: IStakeholderSummary[] = [];
  stakeholderAllocationDetails: IStakeholderAllocationDetails[] = [];
  stakeholderUsers: IStakeholderUser[] = [];
  allocationIdsToBeDeleted: Set<string> = new Set<string>();
  stakeholderSelections: IStakeholderSelection = {
    fundIds: new Set<string>(),
    issuerCompanyIds: new Set<string>(),
    securityDebtModelIds: new Set<string>()
  };
  matStakeholderSelections: {
    fundIds: string[],
    issuerCompanyIds: string[],
    securityDebtModelIds: string[]
  } = {
    fundIds: [],
    issuerCompanyIds: [],
    securityDebtModelIds: []
  };
  loadedStatus = {
    stakeholderSummary: false
  };

  placeholderAllocation: IStakeholderAllocation = null;
  placeholderAllocations: IStakeholderAllocation[] = [];
  placeholderAllocationIndex: number = null;
  placeholderAllocationMode: StakeholderAllocationMode = null;

  fundsMap: { [id: string]: string } = null;
  companyMap: { [id: string]: string } = null;
  securityMap: { [id: string]: string } = null;

  stakeholderUserFromGroup = new FormGroup({
    stakeholderUsername: new FormControl(
      '',
      {
        validators: [
          Validators.required, 
          Validators.minLength(3),
          Validators.maxLength(36)
        ],
        updateOn: 'submit'
      }
    )
  });

  addStakeholderFormControl = new FormControl(
    '',
    {
      validators: [
        Validators.required, 
        Validators.minLength(3),
        Validators.maxLength(36)
      ],
    }
  );

  constructor(
    private um: UserManagementService,
    private ds: DataService,
    private toast: ToastService,
    private modalService: NgbModal,
    private utilService: UtilService,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.utilService.showLoadingPopup();
    // Fetch the user information
    this.myDetails = this.um.getSelectedUserDetails();
    this.isMasterUser = this.um.isMasterUser();
    
    await this.getStakholderAllocationSummary();
    // If the stakeholder summary is empty that means no allocations are there
    // If not then show the edit view for adding allocations
    this.loadedStatus.stakeholderSummary = false;
    if (!this.stakeholderSummary.length)
      await this.beforeGoingToEditView();
    this.loadedStatus.stakeholderSummary = true;
    this.utilService.closeAllPopups();
  }

  // Edit View -> ngOnInit
  async beforeGoingToEditView(): Promise<void> {
    this.utilService.closeAllPopups();
    this.utilService.showLoadingPopup();
    this.allFunds = [];
    this.stakeholderSelections = null;
    this.stakeholderUsers = [];
    this.stakeholderAllocationDetails = [];
    this.allocationIdsToBeDeleted = new Set<string>();

    // Get the selections
    await this.getStakeholderSelections();

    // Get the fund list
    await this.getFundList();

    // Get the users
    await this.getStakeholderUsers();

    // Get the stakeholder allocations
    await this.getStakeholderAllocations();

    this.editView = true;
    this.utilService.closeAllPopups();
  }

  // Summary View --> ngOnInit
  async beforeGoingOutOfEditView(): Promise<void> {
    this.utilService.closeAllPopups();
    this.utilService.showLoadingPopup();
    this.stakeholderSummary = [];

    // Save the stakeholder allocations
    await this.updateStakeholderAllocations();

    // Refetch the stakeholder summary
    await this.getStakholderAllocationSummary();

    this.editView = false;
    this.utilService.closeAllPopups();
  }

  private async getStakeholderSelections(): Promise<void> {
    const resp = await this.ds.getStakeholderSelections(this.myDetails).toPromise();
    const stakeholderSelections = resp.body.response;

    stakeholderSelections.fundIds = new Set<string>(stakeholderSelections.fundIds);
    this.matStakeholderSelections.fundIds = [...stakeholderSelections.fundIds];

    stakeholderSelections.issuerCompanyIds = new Set<string>(stakeholderSelections.issuerCompanyIds);
    this.matStakeholderSelections.issuerCompanyIds = [...stakeholderSelections.issuerCompanyIds];

    // Using Set<string> instead of Set<number> because there is some
    // issue with mat-select when using Set<number>
    stakeholderSelections.securityDebtModelIds = new Set<string>(
      [...stakeholderSelections.securityDebtModelIds].map(x => x.toString())
    );
    stakeholderSelections.securityDebtModelIds = new Set<string>(stakeholderSelections.securityDebtModelIds);
    this.matStakeholderSelections.securityDebtModelIds = [...stakeholderSelections.securityDebtModelIds];

    this.stakeholderSelections = stakeholderSelections;
  }

  private async getStakholderAllocationSummary(): Promise<void> {
    this.loadedStatus.stakeholderSummary = false;
    const res = await this.ds.getStakholderAllocationSummary(this.myDetails).toPromise();
    this.stakeholderSummary = res.body.response;
    this.loadedStatus.stakeholderSummary = true;
  }

  private constructSelectionMaps() {
    this.fundsMap = this.allFunds.reduce((acc, fund) => {
      acc[fund.id] = fund.name;
      return acc;
    }, {});
    this.syncStakeholderSelectionsWithMat();

    const companyIds: Set<string> = new Set();
    this.companyMap = this.stakeholderAllocationDetails.reduce((acc, stakeDetails) => {
      acc[stakeDetails.issuerCompanyId] = stakeDetails.issuerCompanyName;
      companyIds.add(stakeDetails.issuerCompanyId);
      return acc;
    }, {});
    // Refresh the selected company list (set intersection)
    this.matStakeholderSelections.issuerCompanyIds = intersection(
      [...this.stakeholderSelections.issuerCompanyIds], 
      [...companyIds]
    );
    this.syncStakeholderSelectionsWithMat();

    this.resetSecuritySelection();
  }

  private resetSecuritySelection(): void {
    const securityMap: { [id: string]: string } = {};
    const securityIds: Set<string> = new Set();
    this.stakeholderAllocationDetails.forEach(stakeDetails => {
      // Skip over the non-selected security
      if (this.stakeholderSelections.issuerCompanyIds.has(stakeDetails.issuerCompanyId))
        stakeDetails.securities.forEach(stakeSecurity => {
          securityMap[stakeSecurity.originationDebtModelId.toString()] = stakeSecurity.securityName;
          securityIds.add(stakeSecurity.originationDebtModelId.toString());
        });
    });
    this.securityMap = securityMap;
    this.matStakeholderSelections.securityDebtModelIds = intersection(
      [...this.stakeholderSelections.securityDebtModelIds],
      [...securityIds]
    );
    this.syncStakeholderSelectionsWithMat();
  }

  private syncStakeholderSelectionsWithMat() {
    this.stakeholderSelections.fundIds = new Set<string>(this.matStakeholderSelections.fundIds);
    this.stakeholderSelections.issuerCompanyIds = new Set<string>(this.matStakeholderSelections.issuerCompanyIds);
    this.stakeholderSelections.securityDebtModelIds = new Set<string>(
      this.matStakeholderSelections.securityDebtModelIds
    );
  }

  private async getFundList(): Promise<void> {
    const res = await this.ds.getAllFundsForStakeholder(this.myDetails).toPromise();
    this.allFunds = res.body.response;
  }

  private async getStakeholderAllocations(fundIds: Set<string> = null): Promise<void> {
    if (fundIds != null && fundIds.size == 0) {
      this.stakeholderAllocationDetails = [];
      this.lastFundIdsForStakeholer = new Set<string>();
      this.constructSelectionMaps();
      return Promise.resolve();
    }
    this.dataLoaded = false;
    const res = await this.ds.getStakeholderAllocation(this.myDetails, fundIds).toPromise();
    this.stakeholderAllocationDetails = res.body.response;
    if (fundIds !== null && fundIds.size)
      this.lastFundIdsForStakeholer = new Set<string>(fundIds);
    else this.lastFundIdsForStakeholer = new Set<string>(this.stakeholderSelections.fundIds);
    this.constructSelectionMaps();
    this.dataLoaded = true;
  }

  private async getStakeholderUsers(): Promise<void> {
    const res = await this.ds.getStakeholderUsers(this.myDetails).toPromise();
    this.stakeholderUsers = res.body.response;
  }

  private async updateStakeholderAllocations(): Promise<void> {
    await Promise.all([
      this.ds.updateStakeholderAllocations(
        this.stakeholderAllocationDetails, this.allocationIdsToBeDeleted, this.myDetails
      )
        .toPromise()
        .then(() => {
          this.toast.openSnackBar('Allocations saved successfully')
        })
        .catch(() => {
          this.toast.openSnackBar('ERROR: Allocations couldn\t be saved');
        }),
      this.ds.saveStakeholderSelection(
        this.myDetails, this.stakeholderSelections
      )
        .toPromise()
        .then(() => {
          this.toast.openSnackBar('Stakeholder selections saved successfully');
        })
        .catch(() => {
          this.toast.openSnackBar('ERROR: Stakeholder selections couldn\'t be saved')
        })
    ]);
  }

  /*
   * Methods used in template file
   */

  async onFundDropdownClose(): Promise<void> {
    this.syncStakeholderSelectionsWithMat();
    if (isEqual(this.lastFundIdsForStakeholer, this.stakeholderSelections.fundIds))
      return;
    this.utilService.showLoadingPopup();
    await this.getStakeholderAllocations(this.stakeholderSelections.fundIds);
    this.lastFundIdsForStakeholer = new Set<string>(this.stakeholderSelections.fundIds);
    this.constructSelectionMaps();
    this.utilService.closeAllPopups();
  }

  onCompanyDropdownClose(): void {
    this.syncStakeholderSelectionsWithMat();
    this.resetSecuritySelection()
  }

  onSecurityDropdownClose(): void {
    this.syncStakeholderSelectionsWithMat();
  }

  async removeChipForFunds(fundIdToDelete: string): Promise<void> {
    this.utilService.showLoadingPopup();
    this.matStakeholderSelections.fundIds = [...this.stakeholderSelections.fundIds]
      .filter(fundId => fundId !== fundIdToDelete);
    this.syncStakeholderSelectionsWithMat();
    await this.getStakeholderAllocations(this.stakeholderSelections.fundIds);
    this.utilService.closeAllPopups();
  }

  removeChipForCompany(companyIdToDelete: string): void {
    this.matStakeholderSelections.issuerCompanyIds = [...this.stakeholderSelections.issuerCompanyIds]
      .filter(issuerCompanyId => issuerCompanyId !== companyIdToDelete)
    this.syncStakeholderSelectionsWithMat();
    this.resetSecuritySelection();
  }

  removeChipForSecurity(securityIdToDelete: string): void {
    this.matStakeholderSelections.securityDebtModelIds = [...this.stakeholderSelections.securityDebtModelIds]
      .filter(securityDebtModelId => securityDebtModelId !== securityIdToDelete);
    this.syncStakeholderSelectionsWithMat();
  }

  removeChipFromStakeholderUser(stakeholderUserId: string) {
    console.log('Removing Stakeholder User:', stakeholderUserId);
    const confirmMatDiaglog = this.utilService.showConfirmMessage(
      `${this.translateService.getLabel("confirm_delete")}?`,
      this.translateService.getLabel('ok'),
      this.translateService.getLabel('cancel')
    );
    confirmMatDiaglog.afterClosed().subscribe(async status => {
      if (status !== 'Yes') return;
      this.utilService.showLoadingPopup();
      try {
        await this.ds.deleteStakeholderUser(stakeholderUserId).toPromise();
        // Filter the allocations to discard the the allocations made by the deleted user
        // Backend already takes care of the allocation deletions when stakeholder user is deleted
        this.stakeholderAllocationDetails.forEach(stakeholderAllocation => {
          stakeholderAllocation.securities.forEach(security => {
            security.allocations = security.allocations
              .filter(allocation => allocation.user.stakeholderUserId !== stakeholderUserId)
          })
        });
        this.stakeholderUsers = this.stakeholderUsers
          .filter(stakeholderUser => stakeholderUser.stakeholderUserId !== stakeholderUserId);
        this.utilService.closeAllPopups();
      } catch {
        this.utilService.closeAllPopups();
        this.toast.openSnackBar('ERROR: Couldn\'t delete stakeholder user');
      }
    });
  }

  computeTotalConcludedValueAndPercentageOfPar(
    allocationSummary: IStakeholderSummary
  ): { concludedValue: number; percentageOfPar: number; } {
    return allocationSummary.allocations.reduce(
      (acc, summary, _, { length }) => {
        acc.concludedValue += summary.concludedValue;
        acc.percentageOfPar += summary.percentageOfPar / length;
        return acc;
      }, 
      {
        concludedValue: 0,
        percentageOfPar: 0
      }
    );
  }

  getIsLessStake(stakeholderAllocationDetails: IStakeholderAllocationDetails): boolean {
    let isLessStake: boolean = false;
    stakeholderAllocationDetails.securities.forEach(security => {
      if (!this.stakeholderSelections.securityDebtModelIds.has(security.originationDebtModelId.toString()))
        return;
      let totalStakeValue = security.allocations.reduce(
        (acc, allocation) => acc + allocation.stake, 
        0
      );
      // If allocations are there, check if the values rounds up to 100%
      if (totalStakeValue > 0 && Math.round(totalStakeValue) != 100)
        isLessStake ||= true;
    });
    return isLessStake;
  }

  openEditAllocationPopUp(
    content: TemplateRef<any>, allocations: IStakeholderAllocation[], index: number
  ): void {
    // update the state variables
    this.placeholderAllocationMode = StakeholderAllocationMode.EDIT;
    this.placeholderAllocations = allocations;
    this.placeholderAllocationIndex = index;

    this.placeholderAllocation = cloneDeep(allocations[index]);
    this.modalService.open(content, { centered: true });
  }

  openAddAllocationPopUp(
    content: TemplateRef<any>, allocations: IStakeholderAllocation[]
  ): void {
    this.placeholderAllocationMode = StakeholderAllocationMode.NEW;
    this.placeholderAllocations = allocations;
    this.placeholderAllocationIndex = -1;

    this.placeholderAllocation = {
      allocationId: null,
      stake: null,
      user: {
        stakeholderUserId: null,
        stakeholderUsername: null
      }
    };
    this.modalService.open(content, { centered: true });
  };

  stakeholderUserCompareFn(option: IStakeholderUser, value: IStakeholderUser): boolean {
    return option.stakeholderUserId === value.stakeholderUserId;
  }

  addAllocation(): void {
    switch (this.placeholderAllocationMode) {
      case StakeholderAllocationMode.EDIT:
        this.placeholderAllocations[this.placeholderAllocationIndex].stake = this.placeholderAllocation.stake;
        this.placeholderAllocations[this.placeholderAllocationIndex].user = this.placeholderAllocation.user;
        break;

      case StakeholderAllocationMode.NEW:
        this.placeholderAllocations.push({
          stake: this.placeholderAllocation.stake,
          user: this.placeholderAllocation.user,
          allocationId: this.placeholderAllocation.allocationId
        });
        break;
    }
    this.modalService.dismissAll();
  }

  deleteAllocation(stakeholderAllocations: IStakeholderAllocation[], index: number): void {
    if (stakeholderAllocations[index].allocationId != null)
      this.allocationIdsToBeDeleted.add(stakeholderAllocations[index].allocationId);
    stakeholderAllocations.splice(index, 1);
  }

  isAllocationModeEditing(): boolean {
    return this.placeholderAllocationMode === StakeholderAllocationMode.EDIT;
  }

  isAllocationModeCreating(): boolean {
    return this.placeholderAllocationMode === StakeholderAllocationMode.NEW;
  }

  showSecurity(securityId: number): boolean {
    return this.stakeholderSelections.securityDebtModelIds.has(securityId.toString());
  }

  showAllocationHeader(securities: IStakeholderAllocationSecurity[]) {
    for (const security of securities)
      if (
        this.stakeholderSelections.securityDebtModelIds.has(
          security.originationDebtModelId.toString()
        )
      )
        return true;
    return false;
  }

  addStakeholder(event: Event) {
    event.preventDefault();
    if (this.stakeholderUserFromGroup.get('stakeholderUsername').invalid) return;
    this.utilService.showLoadingPopup();
    this.ds.saveStakeholderUser(this.myDetails, this.stakeholderUserFromGroup.get('stakeholderUsername').value)
      .subscribe(
        resp => {
          this.utilService.closeAllPopups();
          this.toast.openSnackBar('Stakeholder user create successfully');
          this.stakeholderUsers.push(resp.body.response);
          this.stakeholderUserFromGroup.reset();
          this.stakeholderUserFromGroup.get('stakeholderUsername').setErrors([]);
        },
        error => {
          this.utilService.closeAllPopups();
          this.toast.openSnackBar(`ERROR: ${error.error.message}`);
        }
      );
  }
}
