<div mat-dialog-title class="dialog-header">
  <label class="header">Annualized Volatility </label>
  <em class="fa fa-times" aria-hidden="true" (click)="dismiss()"></em>
</div>

<div mat-dialog-content>
  <div class="volitility-form">
    <div class="row">
      <div class="col-3">
        <ejs-radiobutton label="Auto" value="false" name="type1" [checked]="!annualizedVolatility.manualOverride"
          (change)="toggleManualOverrideVolatility(false)">
        </ejs-radiobutton>
      </div>
      <div class="col-4">
        <ejs-radiobutton label="Manual Override" value="true" name="type1"
          [checked]="annualizedVolatility.manualOverride" (change)="toggleManualOverrideVolatility(true)">
        </ejs-radiobutton>
      </div>
    </div>

    <div class="row" style="padding-top: 10px;" *ngIf="!annualizedVolatility.manualOverride">
      <div class="col input-label">
        Lookback Period
      </div>
      <div class="col">
        <ejs-dropdownlist class="input-field" placeholder="Select" [dataSource]='lookBackPeriod'
          (change)="onChangeLookbackPeriod()" [(value)]="annualizedVolatility.lookbackPeriod"
          [fields]="fieldMapVolitility">
        </ejs-dropdownlist>
      </div>
    </div>

    <div class="row" style="padding-top: 10px;" *ngIf="!annualizedVolatility.manualOverride">
      <div class="col input-label">
        Frequency
      </div>
      <div class="col">
        <ejs-dropdownlist class="input-field" placeholder="Select" [dataSource]='frequency'
          [(value)]="annualizedVolatility.frequency" [fields]="fieldMapVolitility" (change)="onChangeFrequency()">
        </ejs-dropdownlist>
      </div>
    </div>
    <div class="row" *ngIf="!annualizedVolatility?.manualOverride && showWarningMsg">
      <div class="col error-msg">
        The number of data points is not sufficient to compute the volatility
      </div>
    </div>

    <div class="row" *ngIf="!annualizedVolatility?.manualOverride && !showAddCompanyInputs">
      <div class="col align-right">
        <button mat-button class="btn-custom-primary" (click)="openAddCompanyPopUp()"> Add Company </button>
      </div>
    </div>
    <div class="row" *ngIf="!annualizedVolatility?.manualOverride && showAddCompanyInputs">
      <div class="col-4 input-label">
        Search Company
      </div>
      <div class="col-4">
        <mat-form-field>
          <input matInput [(ngModel)]="newCompanyName" [matAutocomplete]="auto" (keyup)="companyNameChange()" required>
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of companyNames ;let index = index" [value]="option.company_name"
              (click)="selectedCompanyName(option)">
              {{option.company_name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="col-2 align-right">
        <button mat-button class="btn-custom-secondry" (click)="showAddCompanyInputs=false">Cancel</button>
      </div>
      <div class="col-2 align-right">
        <button style="width: 100%;" mat-button class="btn-custom-primary" (click)="addCompany()">Add</button>
      </div>
    </div>

    <div class="row" *ngIf="!annualizedVolatility?.manualOverride">
      <div class="col-12 cca-grid">
        <ejs-grid #grid [dataSource]="annualizedVolatility.annualizedVolatilityComparableCompanies" width='100%'
          [allowSelection]='false' (queryCellInfo)='tooltip($event)'>
          <e-columns>
            <e-column field='companyName' headerText='Company' textAlign="Left" width="220px">
              <ng-template #template let-data>
                {{data.companyName}}
              </ng-template>
            </e-column>
            <e-column field='description' headerText='Description' textAlign="Left">
              <ng-template style="text-overflow: ellipsis;" #template let-data>
                {{data.description}}
              </ng-template>
            </e-column>
            <e-column field='volatility' headerText='Volatility' textAlign="Right" width="100px">
              <ng-template #template let-data>
                {{data.volatility | number : '1.1-1'}} %
              </ng-template>
            </e-column>
            <e-column field='' headerText='' textAlign="Center" width="70px">
              <ng-template #template let-data>
                <i class="fas fa-trash pointer" (click)="deleteCompany(data.index)"></i>
              </ng-template>
            </e-column>
          </e-columns>
          <e-aggregates>
            <e-aggregate>
              <e-columns>
                <e-column field="companyName" type="sum">
                  <ng-template #footerTemplate>
                    Average
                  </ng-template>
                </e-column>
                <e-column field="volatility" type="sum" textAlign='Right'>
                  <ng-template #footerTemplate>
                    {{ annualizedVolatility.averageVolatility| number: '1.1-1'}}%
                  </ng-template>
                </e-column>
              </e-columns>
            </e-aggregate>
          </e-aggregates>
        </ejs-grid>
      </div>
    </div>

    <div class="row" *ngIf="annualizedVolatility?.manualOverride">
      <div class="col input-label">
        Annualized Volatility
      </div>
      <div class="col align-right">
        <ejs-numerictextbox class="input-field yellow-input" min="0" format="#,###.0'  %'"
          [(value)]="annualizedVolatility.manualVolatility" [showSpinButton]="false">
        </ejs-numerictextbox>
      </div>
    </div>

    <div class="row" *ngIf="annualizedVolatility?.manualOverride">
      <div class="col input-label">
        Comment
      </div>
      <div class="col">
        <div class="comments-container">
          <textarea id="comment" rows="6" placeholder="Add Comment"
            [(ngModel)]="annualizedVolatility.comment"></textarea>
        </div>
      </div>
    </div>

  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="btn-custom-secondry">Cancel</button>
  <button mat-button [mat-dialog-close]="annualizedVolatility" cdkFocusInitial class="btn-custom-primary"
    [disabled]="!annualizedVolatility?.manualOverride && showWarningMsg"
    (click)="saveAnnualizedVolitility()">Save</button>
</div>

  <div class="center-spinner overlay" *ngIf="loading == 'LOADING'">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
