import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiResponse, DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from 'src/app/utils/toast.service';
import { VersionList } from './version-interfaces';
import { HttpResponse } from '@angular/common/http';
import { CompaniesInFundMap } from '../company-assignment/ca-interfaces';
import { DEAULT_VERSION_LIST } from './version-list/version-data';

@Injectable({
  providedIn: 'root',
})
export class VersionListService {
  private readonly _allVersionLists = new BehaviorSubject<VersionList[]>([]);

  readonly allVersionLists$ = this._allVersionLists.asObservable();

  user;

  set allVersionLists(val) {
    this._allVersionLists.next(val);
  }

  constructor(
    private MappingMgmtConfigService: MappingMgmtConfigService,
    private dataService: DataService,
    private userMgmt: UserManagementService,
    private activatedRouteSnap: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.user = this.userMgmt.getSelectedUserDetails();
  }

  loadVersionListsFromApiResponse(val) {
    this.allVersionLists = val;
  }

  getVersionLists(uploadDate: string, fundId: string): Observable<VersionList[]> | Observable<undefined> {
    return this.dataService.getVersionLists(uploadDate, fundId).pipe(
      map((response) => response.body?.response),
      catchError((err) => {
        this.toastService.openSnackBar('Failed to get version lists.Please try again.');
        console.log(err, 'CatchError...Failed to get version lists.');
        return of(undefined);
      })
    );
  }

  getVersionListsOrDefault(uploadDate: string, fundId: string): Observable<VersionList[]> | Observable<undefined> {

    const allCompaniesInFund$ = this.dataService.getCompanyDetailsForCompanyMaster({
      userId: this.user?.id,
      fundIds: [fundId],
    }) as Observable<HttpResponse<ApiResponse<CompaniesInFundMap>>>;

    const allCompaniesPiped$ = allCompaniesInFund$.pipe(
      map((res) => {
        const companies = res.body?.response[fundId]?.sort((a, b) => (new Date(a.valuationDate) > new Date(b.valuationDate) ? 1 : -1));
        const earliestInvestmentDateAcrossCompanies = companies?.length > 0 ? companies[0]?.valuationDate : undefined;
        if (earliestInvestmentDateAcrossCompanies) {
          const defaultList: VersionList[] = [{ ...DEAULT_VERSION_LIST[0], uploadDate: earliestInvestmentDateAcrossCompanies }];
          return defaultList;
        } else {
          return DEAULT_VERSION_LIST;
        }
      })
    );

    return this.dataService.getVersionLists(uploadDate, fundId).pipe(
      map((response) => response.body?.response),
      switchMap((response) => {
        if (response?.length > 0) {
          return of(response);
        } else {
          return allCompaniesPiped$;
        }
      }),
      catchError((err) => {
        this.toastService.openSnackBar('Failed to get version lists.Please try again.');
        console.log(err, 'CatchError...Failed to get version lists.');
        return of(undefined);
      })
    );
  }
  deleteVersionList(id:string) {
    return this.dataService.deleteVersionList(id);
  }
  downloadVersionList(id:string) {
    return this.dataService.downloadVersionList(id);
  }
}
