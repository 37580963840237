<ng-template [ngIf]="textField" [ngIfElse]="numbersField">
    <span class="cursor" [RawDataDirective]="value" (contextmenu)="openMenuList($event)">{{value}}</span>
</ng-template>
<ng-template #numbersField>
    <ng-template [ngIf]="!displayNegativeNo" [ngIfElse]="elsePart">
        <span [RawDataDirective]="value" class="cursor" (contextmenu)="openMenuList($event)">
            {{ utilService.getDisplayFormattedNumber(value, decimalPoint, suffix, valueInPlaceOfZero, onlyPositiveAllowed)}}
        </span>
    </ng-template>
    <ng-template #elsePart>
        <span [RawDataDirective]="value" class="cursor" (contextmenu)="openMenuList($event)">
            {{ utilService.getNegativeNumberDisplayFormat(value, decimalPoint, suffix, valueInPlaceOfZero)}}
        </span>
    </ng-template>
</ng-template>

<div style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="navmenu">
</div>

<!-- mat-menu code -->
<mat-menu #navmenu="matMenu">
	<ng-template matMenuContent let-item="item">
        <button mat-menu-item class="menu-btn" (click)="openDrawer(); prepareDrillDown()">Drill Down</button>
        <button mat-menu-item class="menu-btn" [RawDataDirective]="value">Copy</button>
		<button mat-menu-item class="menu-btn comment">Comment</button>
	</ng-template>
</mat-menu>

<ng-template [ngIf]="openDrawerContainerForFirstTime">
    <mat-drawer-container [hasBackdrop]="true" (backdropClick)="drawer.close()">
            <mat-drawer #drawer class="example-sidenav" position="end" mode="over">
                <span (click)="stopPropagation($event)">
                    <app-drill-down [drillDown]="drillDown" (closeEvent)="drawer.close();"></app-drill-down>
                </span>
            </mat-drawer>
    </mat-drawer-container>
</ng-template>