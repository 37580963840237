<table class="table">
    <tr>
        <th>Versions</th>
        <th>Rank</th>
    </tr>

    <tr *ngFor="let projectedItem of dataSource; let i = index">
        <td class="tdAlign" class="tdAlign">
            <div *ngIf="!isReadOnly" class="item">
                <app-search-drop-down placeholder="Select a version" [defaultValue]="projectedItem.versionId"
                    [dataSource]="projectedVersionOptions" (openChange)="onVersionOpened($event, i)"
                    (onSelection)="onVersionChange($event, projectedItem)"></app-search-drop-down>
                <mat-icon matRipple class="action-icons" (click)="onDelete(projectedItem, i)">delete</mat-icon>
            </div>
            <div class="mat-error" *ngIf="isProjVerDirty[i] && !projectedItem?.versionId">
                Version is required.
            </div>
            <div *ngIf="isReadOnly">
                {{projectedItem.versionName}}
            </div>
        </td>
        <td class="tdAlign">
            <div *ngIf="!isReadOnly" class="item">
                <mat-form-field>
                    <mat-select placeholder="Select a rank" (selectionChange)="onRankChange($event, projectedItem)"
                        [value]="projectedItem.rank" (openedChange)="onRankOpened($event, i)" required>
                        <mat-option *ngFor="let rank of rankOptions" [value]="rank">
                            {{ rank }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mat-error" *ngIf="isRankDirty[i] && !projectedItem.rank">
                Rank is required.
            </div>
            <div *ngIf="isReadOnly">
                {{projectedItem.rank}}
            </div>
        </td>
    </tr>
</table>