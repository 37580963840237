<div class="row">
    <div class="col-6">
        <div class="example-container">                     
            <div
                cdkDropList
                #evenList="cdkDropList"
                [cdkDropListData]="evenIndexComments" 
                [cdkDropListConnectedTo]="[oddList]" 
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of evenIndexComments" cdkDrag>{{item.label}}</div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="example-container">
            <div  
                cdkDropList
                #oddList="cdkDropList"  
                [cdkDropListData]="oddIndexComments" 
                [cdkDropListConnectedTo]="[evenList]" 
                class="example-list"
                (cdkDropListDropped)="drop($event)">
                <div class="example-box" *ngFor="let item of oddIndexComments" cdkDrag>{{item.label}}</div>
            </div>
        </div>
    </div>
</div>
