import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DataService } from "../services/data.service";
import { UtilService } from "../utils/util.service";
import { UserManagementService } from "../services/user-management.service";
import { ToastService } from "../utils/toast.service";
import { TranslateService } from "../services/translation.service";

/**
 * This configures the power bi report hosted at /power-bi on the aiplatform
 */
@Component({
  selector: "app-power-bi-config",
  templateUrl: "./power-bi-config.component.html",
  styleUrls: ["./power-bi-config.component.scss"],
})
export class PowerBiConfigComponent implements OnInit {
  reportTypes = [
    {
      name: "QxE",
      id: "EQUITY_POWER_BI_TAB",
    },
    {
      name: "QxC",
      id: "CREDIT_POWER_BI_TAB",
    },
  ];

  selectedReportType: string;

  powerbiConfigClone: any[];

  orgId: string;
  powerBiReportId: string;
  powerBiConfigMapping: any[];
  orgName: string;
  payload: any;
  constructor(
    private ds: DataService,
    private ut: UtilService,
    private formBuilder: FormBuilder,
    public ums: UserManagementService,
    public toastService: ToastService,
    public translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.orgId = this.ums.getSelectedUserDetails()?.organization?.id;
    console.log(this.orgId);
    this.getConfigs();
  }

  getConfigs() {
    this.ds.getConfigs(this.orgId).subscribe(
      (resp) => {
        this.powerBiConfigMapping = resp.body["response"];
        console.log(this.powerBiConfigMapping, "powerBiConfigMapping");
        this.powerbiConfigClone = JSON.parse(
          JSON.stringify(this.powerBiConfigMapping)
        );
        const orgDetails = this.ums.getSelectedUserDetails();
        this.orgName = orgDetails?.organization?.orgName;
      },

      (err) => {
        console.log("Failed to get power bi configs");
        this.toastService.openSnackBar("Failed to get power bi configuration.");
      }
    );
  }

  onReportTypeChange(e) {
    console.log(e);
    this.selectedReportType = e.value;
    this.powerBiReportId =
      this.powerBiConfigMapping[this.selectedReportType][0].powerBIReportId;
  }

  saveButton() {
    const orgDetails = this.ums.getSelectedUserDetails();
    let requestBody = [];
    let payLoadForSelectedReportType = {
      orgId: this.orgId?.trim(),
      dashboardType: this.selectedReportType,
      powerBIReportId: this.powerBiReportId?.trim(),
      client: orgDetails?.organization?.orgName,
    };

    console.log(this.powerbiConfigClone, payLoadForSelectedReportType);

    for (let boardType in this.powerBiConfigMapping) {
      console.log(this.powerBiConfigMapping[boardType], boardType);
      requestBody.push(this.powerBiConfigMapping[boardType][0]);
    }
    console.log(JSON.parse(JSON.stringify(requestBody)), "???");

    const updateOrAddConfig = (reportType: string) => {
      const existingConfig = requestBody.find(
        (config) => config.dashboardType === reportType
      );
      if (existingConfig) {
        // Update the existing config
        Object.assign(existingConfig, payLoadForSelectedReportType);
      } else {
        // Add new config only if it doesn't exist
        requestBody.push({ ...payLoadForSelectedReportType });
      }
    };

    updateOrAddConfig(this.selectedReportType);

    this.ds.savePowerBiReportConfigs(this.orgId, requestBody).subscribe(
      (resp) => {
        this.toastService.openSnackBar("Saved successfully.");
        this.ut.showLoadingPopup();
        this.getConfigs();
        this.ut.closeAllPopups();
      },
      (error) => {
        this.toastService.openSnackBar("Failed to save configuration.");
      }
    );
  }
}
