<div style="overflow-y:auto;max-height:400px;" *ngIf="comments && comments.length > 0">
    <p class="comment-list-header">{{translateService.getLabel('qualitative_analysis')}}</p>

    <div class="comments-container" [class.comments-container-scroll]="comments.length > 2">
        <div class="card mt-1" style="border-radius: 8px !important;border: 1px solid #E5E7EB;cursor: pointer;" *ngFor="let qualitativeAnalysis of comments; let i = index" (click)="toggleComment(qualitativeAnalysis)">
            <div class="card-body" *ngIf="!qualitativeAnalysis.isEdit" >
                <div class="row">
                    <div class="col-5">
                        <span class="comment-title">{{qualitativeAnalysis.commentTitle}}</span>
                    </div>
                    <div class="col-3">
                    </div>
                    <div class="col-4" style="text-align: right;">
                        <span class="date">{{qualitativeAnalysis.createdDate | date:'MMMM d, yyyy h:mm'}}</span>&nbsp;
    
                        <mat-icon class="action-icons" (click)="edit(qualitativeAnalysis)">mode_edit</mat-icon>
    
                        <mat-icon class="action-icons" (click)="delete(qualitativeAnalysis, i)">delete</mat-icon>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-12">
                        <p class="qa-comment-body" [ngClass]="{'ellipse': !qualitativeAnalysis.showFullComment, 'full-text': qualitativeAnalysis.showFullComment}">{{qualitativeAnalysis.comment}}</p>
                    </div>
                </div>
            </div>
    
            <div class="card-body" *ngIf="qualitativeAnalysis.isEdit">
                <div class="form-group">
                    <input type="text" class="form-control remove-focus comment-input"  style="font-weight: 600;"
                        [(ngModel)]="qualitativeAnalysis.commentTitle"
                        placeholder="{{translateService.getLabel('title')}}">
                </div>
                <div class="form-group">
                    <textarea class="form-control remove-focus comment-input" [(ngModel)]="qualitativeAnalysis.comment"
                        placeholder="{{translateService.getLabel('write_a_comment')}}..." rows="3"></textarea>
                </div>
                <div class="form-group" style="text-align: right;">
                    <button mat-raised-button class="update-btn" (click)="updateTitleAndComment(qualitativeAnalysis)">
                        <i class="far fa-save" style="vertical-align: baseline;"></i>&nbsp;<span class="btn-text" style="vertical-align: bottom;">{{translateService.getLabel('save')}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>