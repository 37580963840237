import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserManagementService } from '../services/user-management.service';
import { QualitativeAnalysisV2MessagePopupComponent } from '../qualitative-analysis-v2-message-popup/qualitative-analysis-v2-message-popup.component';
import { MatDialog } from '@angular/material';
import { DataService } from '../services/data.service';
import { TranslateService } from '../services/translation.service';

@Component({
  selector: 'app-qualitative-analysis-v2-list',
  templateUrl: './qualitative-analysis-v2-list.component.html',
  styleUrls: ['./qualitative-analysis-v2-list.component.scss']
})
export class QualitativeAnalysisV2ListComponent implements OnInit, OnChanges {

  @Input() comments:any = [];
  
  constructor(private ds : DataService, private ums: UserManagementService, 
    private dialog: MatDialog,
    public translateService: TranslateService) 
    { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("-------------- comments", this.comments)

    if(this.comments) {
      this.comments = this.comments.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    }
  }

  edit(qualitativeAnalysis){
    qualitativeAnalysis.isEdit = true;
  }

  updateTitleAndComment(qualitativeAnalysis){

    const user = this.ums.getUserDetails();
    
    qualitativeAnalysis.lastModifiedBy = user.userName;
    delete qualitativeAnalysis.isEdit;
    delete qualitativeAnalysis.new;
    delete qualitativeAnalysis.showFullComment;
    
    this.ds.saveComment(qualitativeAnalysis).subscribe(res => {
      console.log("Comment updated successfully!")
    })
  }

  delete(qualitativeAnalysis, index){
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(QualitativeAnalysisV2MessagePopupComponent, {
      data: {
        okButtonMsg: this.translateService.getLabel("yes_delete"),
        noButtonMsg: this.translateService.getLabel("no_thanks"),
        title: qualitativeAnalysis.commentTitle,
        comment: qualitativeAnalysis.comment,
        date: qualitativeAnalysis.createdDate,
        icon: 'priority_high',
        error: false
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.ds.deleteComment(qualitativeAnalysis.id).subscribe(res => {
          this.comments.splice(index, 1);
        })
      }
    });
  }

  toggleComment(qualitativeAnalysis) {
    qualitativeAnalysis.showFullComment = !qualitativeAnalysis.showFullComment;
  }
}
