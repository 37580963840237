<div class="assign-map-version-form">
    <div>
        <div class="ud-label">Upload Date</div>
        <mat-form-field class="assign-date">
            <input name="assignDatePicker" matInput [matDatepicker]="assignmentDatePicker" placeholder="Upload Date"
                [max]="today" [value]="uploadDate" (dateChange)="onUploadDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="assignmentDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #assignmentDatePicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div>
        <div class="v-label">Map</div>
        <mat-form-field class="vl-select">
            <mat-select placeholder="List name" (selectionChange)="onMapSelected($event)">
                <div class="search-input" (click)="$event.stopPropagation()" (keydown.space)="$event.stopPropagation()">
                    <mat-icon>search</mat-icon>
                    <input placeholder="Search" matInput type="text" name="mapListSearch"
                        [formControl]="mapListSearchFc" />
                </div>
                <mat-option *ngFor="let map of filteredMapLists" [value]="map?.id">
                    {{ map?.name}}
                </mat-option>
                <mat-option *ngIf="filteredMapLists?.length < 1">
                    No maps available.
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div class="v-label">Version</div>
        <mat-form-field class="vl-select">
            <mat-select placeholder="List name" (selectionChange)="onVlSelected($event)" cdkFocusInitial>
                <div class="search-input" (click)="$event.stopPropagation()" (keydown.space)="$event.stopPropagation()">
                    <mat-icon>search</mat-icon>
                    <input placeholder="Search" matInput type="text" name="versionListSearch"
                        [formControl]="versionListSearchFc" />
                </div>
                <mat-option *ngFor="let vl of filteredVersionLists" [value]="vl?.id">
                    {{ vl?.name}}
                </mat-option>
                <mat-option *ngIf="filteredVersionLists?.length < 1">
                    No versions available.
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>