<div class="top-container">
  <div class="row progress-message">{{progressMessage}}</div>

  <div class="row">
    <div class="col-2">
      <mat-form-field *ngIf="isReady">
        <input matInput [matDatepicker]="picker" placeholder="{{ translateService.getLabel('choose_a_date_for_new_entry') }}" name="valuationDate" 
          [(ngModel)]="valuationDate" (ngModelChange)="addOldValuation()" [max]="yday">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </div>

    <div class="col-2">
      <mat-form-field *ngIf="valuationJSONData && valuationJSONData.length && isReady">
        <mat-label>{{translateService.getLabel("select_old_valuation_entry")}}</mat-label>
        <mat-select [(ngModel)]="valuationBeingEdited" (ngModelChange)="editOldValuation()">
          <mat-option *ngFor="let vJSONData of valuationJSONData; let i = index" [value]="i">
            {{vJSONData?.valuationDate?.value}} - {{vJSONData?.valuationMetadata[0].value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-2">
      <button mat-raised-button class="primary-bg" (click) = "saveExcel()" [hidden]="!selecctionDone">
        {{translateService.getLabel("save")}}
      </button>
    </div>

  </div>

  <!-- <div class="row">
    <div class="col-2">
      <input type="file" accept=".xlsx" (change)="uploadOldValuationExcel($event)"/>
    </div>
  </div> -->

  <div class="row" class="excel-view" #excelUI [hidden]="!(isReady && selecctionDone)"></div>
</div>
