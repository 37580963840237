import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface SearchDropDownDataSource {
  value: string;
  displayValue: string;
  id?: string;
}

@Component({
  selector: 'app-search-drop-down',
  templateUrl: './search-drop-down.component.html',
  styleUrls: ['./search-drop-down.component.scss']
})

export class SearchDropDownComponent implements OnInit {

  private dataSourceCopy = [];
  filteredOptions = [];

  searchString = '';

  @Input() placeholder: string;

  @Input()
  set dataSource(val) {
    this.dataSourceCopy = [...val];
    this.filteredOptions = [...val];
  }

  @Input() defaultValue?: string;

  @Output() onSelection = new EventEmitter();

  @Output() openChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onSelectionChange(e) {
    this.onSelection.emit(e);
  }

  onKeyUp(event) {
    const str = event.target.value;
    this.filteredOptions = this.filterBy(str);
  }

  filterBy(value: string) {
    let filter = value?.toLowerCase();
    return this.dataSourceCopy.filter((item) => {
      return item.displayValue?.toLowerCase().includes(filter);
    });
  }

  onOpenedChange(e: boolean) {
    console.log(e);
    this.openChange.emit(e);
    // this.searchString = '';
    // this.seletedAttributes = this.selectedAttributes.slice();
  }


}
