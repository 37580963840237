import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse } from '@angular/common/http';
import { ImageLoadService } from 'src/app/services/image-load.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { MessagePopupComponent } from 'src/app/utils/popup/message-popup.component';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  orgName;
  isLoading = true;
  userDomain;
  userDomainName;
  userDomainLogo;

  constructor(
    public loaderService: ImageLoadService,
    private activatedRoute: ActivatedRoute,
    public um: UserManagementService,
    private dialog: MatDialog,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.um.logout();
    this.activatedRoute.paramMap.subscribe(async params => {
      this.orgName = params.get("orgName");
      this.um.getOrgSpecificDetails(this.orgName).subscribe(res => {
        let data = res.body["response"];
        if (data && !!data.domainName && !!data.orgName && !!data.photoUrl) {
          this.isLoading = false;
          this.userDomain = data.domainName;
          this.userDomainName = data.orgName;
          this.userDomainLogo =  data.photoUrl;
        }
        else {
          this.isLoading = false;
          this.router.navigateByUrl('/');
        }
      }, err=>{
        this.isLoading = false;
        console.log("Error while getting Organization's login screen", err)
        this.router.navigateByUrl('/');
      });
    });
  }

  loginClick() {
      this.um.getSingleSignOnURL().subscribe((res: HttpResponse<any>) => {
        let redirectUrl = res.body["response"] + "&domain_hint=" + this.userDomain;
        window.location.replace(redirectUrl);
      }, error => {
        console.log("Login error", error);
        this.showMessage("Single Sign On Failed");
      });
  }

  showMessage(msg) {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(MessagePopupComponent, {
      data: {
        okButtonMsg: "Ok",
        dialogMsg: msg
      }
    });
  }

}
