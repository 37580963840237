import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, multiply } from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioService } from '../../portfolio.service';
import { ComparisonFiltersComponent } from './comparison-filters/comparison-filters.component';

@Component({
  selector: 'app-version-comparison',
  templateUrl: './version-comparison.component.html',
  styleUrls: ['./version-comparison.component.scss']
})
export class VersionComparisonComponent implements OnInit, OnChanges {
  @Input() versions : [];

  @Input() companyId : string;

  @Output() close = new EventEmitter();

  public filteredData: ComparisonFiltersComponent;

  comparisonData = [];
  compareKeys = ["enterprise-value", "valuation-approaches", "cash", "debt", "fully-diluted-stake"];

  filtersForSelection = [];
  versionsToBeUsed = [];
  comparisonAPIData = {};

  valuationDate = "";

	constructor(private utilService: UtilService, private modalService: NgbModal,
    private ds: DataService, public portfolioService: PortFolioService, public translateService: TranslateService) 
  { }

  ngOnInit(): void {    
    // this.ds.getWidgetDataFromDB("VERSION_COMPARE_FILTERS", this.companyId).subscribe(res => {

    //   this.compareKeys = res.body["response"][0]["widgetData"];
    //   this.initFilterSelection(this.filters);

    // }, error => {
    //   this.initFilterSelection(this.filters);
    // });

  }

  async ngOnChanges() {
    if(!this.versions) return;

    const vs: any = this.versions;
    this.valuationDate = vs[0].valuationDate;
    
    try {
      const compareKeysAPI = await this.ds.getWidgetDataFromDB("VERSION_COMPARE_FILTERS", this.companyId).toPromise();
      this.compareKeys = compareKeysAPI["body"]["response"][0]["widgetData"];
      this.initFilterSelection(this.filters);
    } catch(e) {
      this.initFilterSelection(this.filters);
    }
    this.versionsToBeUsed = cloneDeep(this.versions).reverse();

    const reqBody = {
      "valuation-date-ids": this.versionsToBeUsed.map((v: any) => v.id),
      "data-points": this.compareKeys
    }

    this.ds.getVersionComparison(reqBody).subscribe(res => {
      this.comparisonAPIData = res.body["response"];
      this.prepareTableData(this.comparisonAPIData);
    })    
  }

  initFilterSelection(filters) {
    filters.forEach(filter => {
      if(!filter.children || filter.children.length == 0) {
        //Leaf node
        //set the selection state
        filter.selected = this.compareKeys.includes(filter.key);

      } else {
        this.initFilterSelection(filter.children)
        const selectedChildren = filter.children.filter(c => c.selected);
        
        filter.indeterminate = selectedChildren.length != 0 && selectedChildren.length < filter.children.length;
        filter.selected = selectedChildren.length == filter.children.length;
      }
    });
  }

  prepareTableData(apiData) {
    this.comparisonData = [];

    this.compareKeys.forEach(key => {
      const row = [ this.getLabel(key) ];

      this.versionsToBeUsed.forEach((v: any) => {
        row.push( this.getFormattedValue(apiData[v.id], key) );
      })

      this.comparisonData.push(row);
    })
  }

  getFormattedValue(obj, key) {
    if(!obj[key]) return "";

    switch(key) {
      case "valuation-approaches" : {
        const approaches: [] = obj[key].map(va => {
          if(va.weight > 0) {
            return va.name + " (" + this.utilService.getDisplayFormattedNumber(va.weight, 1, "%", "0.0%", false) + ")";
          }
        });

        return approaches.join(", ");
      }

      case "fully-diluted-stake":
        return this.getFormattedNumber(obj[key], "stake", "%") + " (" + this.getLabel("stake_" + obj[key].type) + ")";

      //Absolute Numbers
      case "enterprise-value":
      case "cash" :
      case "debt" :
        return this.getFormattedNumber(obj, key, "");

      case "transaction-metric-revenue": 
      case "transaction-metric-ebitda":
      case "transaction-metric-ebit":
        return this.getFormattedNumber(obj[key], "metric", "") + " - " + obj[key].year;

      case "transaction-multiple-revenue": 
      case "transaction-multiple-ebitda":
      case "transaction-multiple-ebit":
        if(obj[key].multiple == 0 && obj[key].weight == 0){
          return "  —  ";
        }
        else{
          return this.getFormattedNumber(obj[key], "multiple", "x") + " (" + this.utilService.getDisplayFormattedNumber(obj[key].weight, 1, "%", "0.0 %", false) + ")";
        }

      case "trading-comps-multiple-revenue":
      case "trading-comps-multiple-ebitda":
      case "trading-comps-multiple-ebit":
      case "trading-comps-multiple-gp":
      case "trading-comps-multiple-ebitda-capex":
      case "trading-comps-multiple-pe":
      case "trading-comps-multiple-pbv":
      case "trading-comps-multiple-psales":
        let formattedMultiples = obj[key].filter(value => value.weight > 0);

        if(!formattedMultiples || formattedMultiples.length == 0) {
          formattedMultiples = obj[key].filter(value => value.year == "LTM");
        }

        formattedMultiples = formattedMultiples.map(value => {
            return this.getFormattedNumber(value, "multiple", "x") + " - " + value.year + 
                " (" + this.utilService.getDisplayFormattedNumber(value.weight, 1, "%", "0.0 %", false) + ")";
          });

        return formattedMultiples.join(", ");

      case "trading-comps-metric-revenue":
      case "trading-comps-metric-ebitda":
      case "trading-comps-metric-ebit":
      case "trading-comps-metric-gp":
      case "trading-comps-metric-ebitda-capex":
      case "trading-comps-metric-pe":
      case "trading-comps-metric-pbv":
      case "trading-comps-metric-psales":
  
        let formattedMetrics = obj[key].map(value => {
            return this.getFormattedNumber(value, "metric", "") + " - " + value.year;
        });

        return formattedMetrics.join(", "); 
        
      case "ia-discountRate":
      case "ia-stableEBITDAMargin": 
      case "ia-longTermGrowthRate": 
      case "ia-terminalYearTaxRate":
        return this.getFormattedNumber(obj, key, "%");

      default: return key;
    }
  }

  getFormattedNumber(obj, key, suffix) {
    try {
      return this.utilService.getDisplayFormattedNumber(obj[key], 1, suffix, ("0.0" + suffix), false);
    } catch(e) {
      return 0;
    }
  }

  getLabel(key) {
    switch(key) {
      case "enterprise-value": 
      return this.translateService.getLabel("valuation_summary") + " - " + this.translateService.getLabel("enterprise_value");
      case "valuation-approaches": 
      return this.translateService.getLabel("valuation_summary") + " - " + this.translateService.getLabel("valuation_approach_weight");
      case "cash": 
      return this.translateService.getLabel("valuation_summary") + " - " + this.translateService.getLabel("cash");
      case "debt": 
      return this.translateService.getLabel("valuation_summary") + " - " + this.translateService.getLabel("debt");
      case "fully-diluted-stake": 
      return this.translateService.getLabel("valuation_summary") + " - " + this.translateService.getLabel("fully_diluted_stake");
      
      case "transaction-multiple-revenue": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_revenue"); 
      case "transaction-multiple-ebitda": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_ebitda");
      case "transaction-multiple-ebit": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_ebit");
      case "transaction-metric-revenue": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("revenue");
      case "transaction-metric-ebitda": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("ebitda");
      case "transaction-metric-ebit": 
      return this.translateService.getLabel("transaction_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("ebit");
     
      case "trading-comps-multiple-revenue": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_revenue");
      case "trading-comps-multiple-ebitda": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_ebitda");
      case "trading-comps-multiple-ebit": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev_ebit");
      case "trading-comps-multiple-gp": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("gross_profit");
      case "trading-comps-multiple-ebitda-capex": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("ev") + "/" + (this.translateService.getLabel("ebitda") + " - " + this.translateService.getLabel("capex"));
      case "trading-comps-multiple-pe": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("earnings");
      case "trading-comps-multiple-pbv": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("book_value");
      case "trading-comps-multiple-psales": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("multiple") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("sales");

      case "trading-comps-metric-revenue": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("revenue");
      case "trading-comps-metric-ebitda": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("ebitda");
      case "trading-comps-metric-ebit": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("ebit");
      case "trading-comps-metric-gp": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("gross_profit");
      case "trading-comps-metric-ebitda-capex": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + (this.translateService.getLabel("ebitda") + " - " + this.translateService.getLabel("capex"));
      case "trading-comps-metric-pe": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("earnings");
      case "trading-comps-metric-pbv": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("book_value");
      case "trading-comps-metric-psales": 
      return this.translateService.getLabel("trading_comps") + " - " + this.translateService.getLabel("metric") + " - " + this.translateService.getLabel("price") + "/" + this.translateService.getLabel("sales");

      case "ia-discountRate": 
      return this.translateService.getLabel("income_approach") + " - " + this.translateService.getLabel("discount_rate");
      case "ia-stableEBITDAMargin": 
      return this.translateService.getLabel("income_approach") + " - " + this.translateService.getLabel("stable_ebitda_margin");
      case "ia-longTermGrowthRate": 
      return this.translateService.getLabel("income_approach") + " - " + this.translateService.getLabel("long_term_growth_rate");
      case "ia-terminalYearTaxRate": 
      return this.translateService.getLabel("income_approach") + " - " + this.translateService.getLabel("terminal_year_tax_rate");

      case "stake_stakeFromModel": 
      return this.translateService.getLabel("custom_excel");
      case "stake_manualEntry": 
      return this.translateService.getLabel("manual");
      case "stake_impliedStakeWaterfall": 
      return this.translateService.getLabel("waterfall");
      case "stake_dilutedStake": 
      return this.translateService.getLabel("captable");
      case "stake_impliedStakeManpack": 
      return this.translateService.getLabel("manpack");
      case "stake_impliedStakeManOption": 
      return this.translateService.getLabel("manpack") + " / " + this.translateService.getLabel("management_options");
      case "stake_teasuryStockStake": 
      return this.translateService.getLabel("tsm");
      default: return key;
    }
  }

  closeWindow() {
    this.close.emit();
  }

  openFilters(filtersPopup) {
    this.filtersForSelection = cloneDeep(this.filters);
    this.modalService.open(filtersPopup, { centered: true })
  }

  saveFilterPreferences() {
    this.compareKeys = [];
    this.comparisonData = [];
    
    this.filters = cloneDeep(this.filtersForSelection);

    this.prepareSelectedComparisonKeys(this.filters);

    this.ds.saveWidgetDataToDB("VERSION_COMPARE_FILTERS", this.compareKeys, this.companyId).subscribe(res => {
      console.log("Filters saved successfully")
    }, err => {
      console.log("Error while saving filters.")
    })

    //Reload the data
    this.comparisonAPIData = [];

    const reqBody = {
      "valuation-date-ids": this.versionsToBeUsed.map((v: any) => v.id),
      "data-points": this.compareKeys
    }

    this.ds.getVersionComparison(reqBody).subscribe(res => {
      this.comparisonAPIData = res.body["response"];
      this.prepareTableData(this.comparisonAPIData);
    })
  }

  prepareSelectedComparisonKeys(filters) {
    filters.forEach(filter => {
      if(!filter.children || filter.children.length == 0) {
        //Leaf node
        //If it is selected, push it to comparison
        if(filter.selected) {
          this.compareKeys.push(filter.key);
        }
      } else {
        this.prepareSelectedComparisonKeys(filter.children)
      }
    });
  }

  filtersSelectionChange(){
    console.log();
  }

  filters = [
    {
      "label": this.translateService.getLabel("valuation_summary"),
      "key": "valuation-summary",
      "selected": false,
      "children": [
        {
          "label": this.translateService.getLabel("enterprise_value"),
          "key": "enterprise-value",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("valuation_approaches"),
          "key": "valuation-approaches",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("cash"),
          "key": "cash",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("debt"),
          "key": "debt",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("fully_diluted_stake"),
          "key": "fully-diluted-stake",
          "selected": false,
          "children": []
        }
      ]
    },
    {
      "label": this.translateService.getLabel("transaction_comps"),
      "key": "transaction-comps",
      "selected": false,
      "children": [
        {
          "label": this.translateService.getLabel("multiple"),
          "key": "transaction-multiple",
          "selected": false,
          "children": [
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("revenue"),
              "key": "transaction-multiple-revenue",
              "selected": false,
              "children": []
            },
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebitda"),
              "key": "transaction-multiple-ebitda",
              "selected": false,
              "children": []
            },
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebit"),
              "key": "transaction-multiple-ebit",
              "selected": false,
              "children": []
            }
          ]
        },
        {
          "label": this.translateService.getLabel("metric"),
          "key": "transaction-metric",
          "selected": false,
          "children": [
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("revenue"),
              "key": "transaction-metric-revenue",
              "selected": false,
              "children": []
            },
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebitda"),
              "key": "transaction-metric-ebitda",
              "selected": false,
              "children": []
            },
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebit"),
              "key": "transaction-metric-ebit",
              "selected": false,
              "children": []
            }
          ]
        }
      ]
    },
    {
      "label": this.translateService.getLabel("trading_comps"),
      "key": "trading-comps",
      "selected": false,
      "children": [
        {
          "label": this.translateService.getLabel("multiple"),
          "key": "trading-comps-multiple",
          "selected": false,
          "children": [
            {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("revenue"),
              "key": "trading-comps-multiple-revenue",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebitda"),
              "key": "trading-comps-multiple-ebitda",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("ebit"),
              "key": "trading-comps-multiple-ebit",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ev") + " / " + this.translateService.getLabel("gross_profit"),
              "key": "trading-comps-multiple-gp",
              "selected": false,
              "children": []
            },  {
              "label": this.translateService.getLabel("ev") + " / " + (this.translateService.getLabel("ebitda") + " - " + this.translateService.getLabel("capex")),
              "key": "trading-comps-multiple-ebitda-capex",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("price") + " / " + this.translateService.getLabel("earnings"),
              "key": "trading-comps-multiple-pe",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("price") + " / " + this.translateService.getLabel("book_value"),
              "key": "trading-comps-multiple-pbv",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("Price") + " / " + this.translateService.getLabel("sales"),
              "key": "trading-comps-multiple-psales",
              "selected": false,
              "children": []
            }
          ]
        },
        {
          "label": this.translateService.getLabel("metric"),
          "key": "trading-comps-metric",
          "selected": false,
          "children": [
            {
              "label": this.translateService.getLabel("revenue"),
              "key": "trading-comps-metric-revenue",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ebitda"),
              "key": "trading-comps-metric-ebitda",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ebit"),
              "key": "trading-comps-metric-ebit",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("gross_profit"),
              "key": "trading-comps-metric-gp",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("ebitda") + " - " + this.translateService.getLabel("capex"),
              "key": "trading-comps-metric-ebitda-capex",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("price") + " / " + this.translateService.getLabel("earnings"),
              "key": "trading-comps-metric-pe",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("price") + " / " + this.translateService.getLabel("book_value"),
              "key": "trading-comps-metric-pbv",
              "selected": false,
              "children": []
            }, {
              "label": this.translateService.getLabel("price") + " / " + this.translateService.getLabel("sales"),
              "key": "trading-comps-metric-psales",
              "selected": false,
              "children": []
            }
          ]
        }
      ]
    },
    {
      "label": this.translateService.getLabel("income_approach"),
      "key": "income-approach",
      "selected": false,
      "children": [
        {
          "label": this.translateService.getLabel("discount_rate"),
          "key": "ia-discountRate",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("stable") + " " +this.translateService.getLabel("ebitda") + " " + this.translateService.getLabel("margin"),
          "key": "ia-stableEBITDAMargin",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("long_term_growth_rate"),
          "key": "ia-longTermGrowthRate",
          "selected": false,
          "children": []
        },
        {
          "label": this.translateService.getLabel("terminal_year_tax_rate"),
          "key": "ia-terminalYearTaxRate",
          "selected": false,
          "children": []
        }
      ]
    }
  ];
}
