import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { numberFormat } from 'highcharts';
import { DatePipe } from '@angular/common';
import { DynamicDecimalPipe } from 'src/app/pipes/dynamic-decimal/dynamic-dm-decimal.pipe';
import Highcharts from 'highcharts';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-spline-chart',
  templateUrl: './spline-chart.component.html',
  styleUrls: ['./spline-chart.component.scss']
})
export class SplineChartComponent implements OnChanges {

  @Input() data:any;
  @Input() legend;
  @Input() xAxisCategories;
  @Input() xAxisTitle;
  @Input() yAxisTitle;
  @Input() yAxisCategories;
  points=[];
  dateSort:any=[];
  lineChart;
  chartColors = ["#1D1563","#269999","#5a8ad9","#eb5f6c","#a36aa9"]

  constructor(private dynamicDecimalPipe: DynamicDecimalPipe,
    private utilService: UtilService) 
  {}

  getFormatedDate(myDate){
    myDate.forEach((values,index)=>{
      if(!!Date.parse(values)){
        this.dateSort.push(new DatePipe('en-US').transform(values, 'MMM dd, yyyy  '));
      }else{
        this.dateSort.push(values)
      }
    }) 
  }

  ngOnChanges() {
    const __this = this;

    this.getFormatedDate(this.data?.xAxisLabels);
    if (!this.data) {
      return;
    }
    const lineChart = new Chart({
      chart: { type: 'spline' },
      title: { text: '' },
      exporting: { enabled: false },
      credits: { enabled: false },
      xAxis: { categories: this.dateSort },
      legend: {
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        y:-50
      },
      yAxis: { 
        min:0, 
        title: { text:  undefined },
        labels:{ enabled:true ,
          formatter: function(){
          return __this.utilService.getDisplayFormattedNumber(this.value, 1, "%", "NA");
          }
        },
      },
      tooltip: {
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y}</b></td></tr>',
        enabled: true,
      },
      series:[],
    });
    this.lineChart = lineChart;
    let i = 0;
    this.data?.dataPoints?.forEach((value,index) => {
      const pointsValues=[];
      value.points?.forEach((pointval,index)=>{
        pointsValues.push(parseFloat(this.dynamicDecimalPipe.transform(100*pointval)));
        // pointsValues.push(parseFloat(numberFormat(100*pointval,1)));
      })
      
      lineChart.addSeries(
        {
          type: 'spline',
          color: this.chartColors[index],
          name: value.name,
          data: pointsValues ,         
        },
        true,
        false
      );
      i++
    });
    lineChart.ref$.subscribe(chart=>{
      chart.reflow();
    });
  }
}
