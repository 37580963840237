import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class ReplicateAttributeListService {
  constructor(private dataService: DataService, private userMgmtService: UserManagementService) {}

  getDataForReplicateMap(attributeListId: string) {
    const user = this.userMgmtService.getSelectedUserDetails();
    const orgId = user.organization.id;
    const gxAttributes = this.dataService.getGxAttributes(orgId).pipe(map(res => res.body['response']));

    const attributeListDetailsToBeReplicated = this.dataService.getAttributeListDetailsById(attributeListId).pipe(map((res) => res.body.response));

    const gxAttrsAndAttributeListDetails = forkJoin([gxAttributes, attributeListDetailsToBeReplicated]);

    return gxAttrsAndAttributeListDetails;
  }
}
