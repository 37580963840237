import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvestmentSummaryService } from '../investment-summary.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { UtilService } from 'src/app/utils/util.service';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-general-attribute-popup',
  templateUrl: './general-attribute-popup.component.html',
  styleUrls: ['./general-attribute-popup.component.scss']
})
export class GeneralAttributePopupComponent implements OnInit {

  @Input() generalAttributes;
  @Input() currency;

  @Input() showHideStatusOfGeneralAttributes = {}

  @Output() updateGeneralAttrsStatus = new EventEmitter();

  constructor( public invSummaryService : InvestmentSummaryService,
    public portfolioService : PortFolioSummaryServiceV2,
    public utilService : UtilService,
    private ds: DataService, 
    private modalService : NgbModal,
    public ums: UserManagementService,
    private route: ActivatedRoute,
    public translateService: TranslateService) { }

  attributeType = "general";

  ngOnInit(): void {
    console.log("Genral Attributes" ,this.generalAttributes);
  }

  updateShowHideStatus(event, key){
    this.showHideStatusOfGeneralAttributes[key] = {
      show: event.checked
    };
    this.updateGeneralAttrsStatus.emit(this.showHideStatusOfGeneralAttributes);
  }
}
