<div class="mat-h2 heading">
    Assign Map and Version to Companies
    <mat-icon class="close-mat-icon" mat-dialog-close matRipple>close</mat-icon>
</div>
<mat-divider></mat-divider>

<app-ca-form [mapLists]="attrsMapListForUploadDate" [versionLists]="versionListsForUploadDate"
    (uploadDateChange)="onUploadDateChange($event)" (mapListChange)="onVMapListChange($event)"
    (versionListChange)="onVersionListChange($event)"></app-ca-form>
<mat-divider></mat-divider>
<div class="list-filter">
    <div class="c-name-filter">
        <div class="company-list-label">Company List</div>
        <mat-form-field class="cl-search">
            <mat-icon matPrefix>search</mat-icon>
            <input type="text" matInput [formControl]="companySearchFc" name="comapnySearch"
                placeholder="Search for companies" />
        </mat-form-field>
    </div>
    <div *ngIf="!isDebtCompanyAssignment" #gsFilter class="gs-filter">
        <button  mat-icon-button (click)="openGeographySectorFilter($event)">
            <mat-icon *ngIf="isGsFilterApplied">filter_alt</mat-icon>
            <mat-icon *ngIf="!isGsFilterApplied">filter_alt_off</mat-icon>
        </button>
    </div>
</div>
<app-company-list [companies]="clService.allCompaniesInFund$ | async" [companyIdsWithConflict]="companyIdsWithConflict"
    (companySelectionChange)="onCompanySelectionChange($event)"></app-company-list>

<mat-divider></mat-divider>
<div class="apply-section">
    <button mat-flat-button class="btn btn-apply"
        [disabled]="versionListsForUploadDate?.length < 1 || selectedCompanies?.length < 1 || !selectedVersionListId || !selectedAttrListId"
        (click)="onAppply()">Apply</button>
</div>