import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-additional-analysis',
  templateUrl: './additional-analysis.component.html',
  styleUrls: ['./additional-analysis.component.scss']
})
export class AdditionalAnalysisComponent implements OnInit {
  @Input() latestCompanyValuationDate;
  @Input() investmentDate;
  @Input() companyId;
  @Input() consolSummary;

  
  constructor(
    public translateService: TranslateService,
    public ds: DataService,
    public navService: NavigationService,
  ) { }

  ngOnInit(): void {
  }
}
