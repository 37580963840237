<div class="container">
  <div class="header">
    <img [src]="loaderService.getImage('73_Strings_logo_blueUE.png')" class="company-logo" alt="">
  </div>

  <div class="main">
    <img [src]="loaderService.getImage('scheduled-upgrade.gif')" class="schelued-upgrade-gif" alt="">
  </div>

  <div class="label-text">
    <label>Our product is currently undergoing scheduled upgrade, will be right back!
    </label>
    <br>
    <label>
      If you have any additional queries in the interim, please reach out to us at support@73strings.com.
    </label>
  </div>
</div>