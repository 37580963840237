import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ToastComponent } from './toast/toast.component';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  //create an instance of MatSnackBar
  
  constructor(private snackBar:MatSnackBar) { }
  
/* It takes three parameters 
    1.the message string 
    2.the action 
    3.the duration, alignment, etc. */
  
  openSnackBar(message: string) {
    this.snackBar.openFromComponent(ToastComponent  , {
       duration: 2500,
       verticalPosition: 'top', // 'top' | 'bottom'
       horizontalPosition: 'right', //'start' | 'center' | 'end' | 'left' | 'right'
       panelClass: ['toaster'],
       data: {message: message}
    });

}
}
