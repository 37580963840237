<nav-menu-header headerLabel="Funds" headerToBeAppended="creditRatingUpload"></nav-menu-header>

<div class="body box-shadow mx-4">
  <div class="label-padding">
    <span class="main-heading">Credit Rating Upload</span>
  </div>
  <div class="d-flex justify-content-between m-3">
    <div class="d-flex align-items-center" style="margin-bottom: 10px;">
      <!-- <span class="mr-3">File</span> -->
      <input type="file" (change)="onFileSelected($event)" value="upload" id="fileUpload" hidden />
      <button class="btn-custom-secondry" (click)="clkFileBtn()">Choose File</button>
      <span class="ml-3">{{currentUploadFile?.name}}</span>
      <span class="ml-3">Valuation Date</span>
      <div class="ml-3 mtop-10">
        <ejs-datepicker [(value)]='dateValue'></ejs-datepicker>
      </div>
    </div>

    <div>
      <!-- <i class="fas fa-file-download font-20 color-primary"></i> -->
      <button class="btn-custom-primary" (click)="processFileUpload()">Upload</button>
    </div>
  </div>
  <div class="table table-condensed" id="container" class="data-management-grid px-0 ml-3 mr-3">
    <ejs-grid #summaryGrid [dataSource]="fileUploadList" [allowPaging]="true" [pageSettings]="gridPageSettings"
      [allowSelection]="true">
      <e-columns>
        <e-column headerText='S/N' width='50px'>
          <ng-template #template let-comp>
            {{+comp.index + 1}}.
          </ng-template>
        </e-column>
        <e-column field='fileName' headerText='File Name' width='100px'>
          <ng-template #template let-comp>
            {{comp.fileName}}
          </ng-template>
        </e-column>
        <e-column field='fileUploadStatus' headerText='File Upload Status' width='100px'>
          <ng-template #template let-comp>
            {{comp.fileUploadStatus}}
          </ng-template>
        </e-column>
        <e-column field='fileDateUploaded' headerText='Valuation Date' width='100px'>
          <ng-template #template let-comp>
            {{comp.fileDateUploaded}}
          </ng-template>
        </e-column>
        <e-column field='view' headerText='Download' width='100px'>
          <ng-template #template let-comp>
            <!-- <i class="far fa-eye font-20 color-primary"></i> -->
             <a [href]="downloadJson" download="{{fileName}}">
              <i class="fa fa-download font-20 color-primary" (click)="generateDownloadJSON(comp)"></i>
             </a>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
  <div class="d-flex justify-content-end m-3">

  </div>
</div>
