import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { FinancialsVersion, VersionListDetails } from '../version-interfaces';
import { UserManagementService } from 'src/app/services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class CreateVlService {
  private readonly _historicalItems = new BehaviorSubject<FinancialsVersion[]>([]);

  private readonly _projectedItems = new BehaviorSubject<FinancialsVersion[]>([]);

  readonly historicalItems$ = this._historicalItems.asObservable();
  readonly projectedItems$ = this._projectedItems.asObservable();

  get historicalItems() {
    return this._historicalItems.getValue();
  }

  set historicalItems(val: FinancialsVersion[]) {
    this._historicalItems.next(val);
  }

  get projectedItems() {
    return this._projectedItems.getValue();
  }

  set projectedItems(val: FinancialsVersion[]) {
    this._projectedItems.next(val);
  }
  historicalDeleted =[];
  projectedDeleted  =[];

  constructor(private dataService: DataService, private userMgmtService: UserManagementService) {}

  areAllHistRowsInvalid() {
    if (this.historicalItems.length < 1) {
      return true;
    }
    if (this.historicalItems.length > 1) {
      return this.historicalItems?.filter((item) => item.versionId && item.rank).length === 0;
    }
    for (let item of this.historicalItems) {
      if (!item.versionId && !item.rank && this.historicalItems.length === 1) {
        return true;
      } else {
        if (!item.versionId && !item.rank) {
          return false;
        } else if (!item.versionId || !item.rank) {
          return true;
        }
      }
    }
    return false;
  }

  areAllProjRowsInvalid() {
    if (this.projectedItems.length < 1) {
      return true;
    }
    if (this.projectedItems.length > 1) {
      return this.projectedItems?.filter((item) => item.versionId && item.rank).length === 0;
    }
    for (let item of this.projectedItems) {
      if (!item.versionId && !item.rank && this.projectedItems.length === 1) {
        return true;
      } else {
        if (!item.versionId && !item.rank) {
          return false;
        } else if (!item.versionId || !item.rank) {
          return true;
        }
      }
    }
    return false;
  }

  addProjectedItem(payLoad: FinancialsVersion) {
    // return [... ...[payLoad]];
    this.projectedItems = [...this.projectedItems, { ...payLoad }];
  }

  editProjectedItem(payLoad) {
    this.projectedItems = this.projectedItems.map((vli) => {
      if ((vli.uuid && payLoad.uuid && vli.uuid === payLoad.uuid) || (vli.id && payLoad.id && vli.id === payLoad.id)) {
        return { ...vli, ...payLoad };
      } else {
        return { ...vli };
      }
    });
  }

  deleteProjectedItem(payLoad: FinancialsVersion) {   
    this.projectedItems = this.projectedItems.filter((row) => row.uuid !== payLoad.uuid);
  }

  deleteExistingProjectedItem(payLoad: FinancialsVersion) {
    payLoad.deleted = true;
    this.projectedDeleted = [...this.projectedDeleted, {...payLoad}]   
    this.projectedItems = this.projectedItems.filter((row) => row.id !== payLoad.id);
  }



  addNewHistoricalItem(payLoad: FinancialsVersion) {
    this.historicalItems = [...this.historicalItems, { ...payLoad }];
  }

  editHistoricalItem(payLoad) {
    this.historicalItems = this.historicalItems.map((vli) => {
      console.log(vli, payLoad);
      if ((vli.uuid && payLoad.uuid && vli.uuid === payLoad.uuid) || (vli.id && payLoad.id && vli.id === payLoad.id)) {
        return { ...vli, ...payLoad };
      } else {
        return { ...vli };
      }
    });
  }

  deleteHistoricalItem(payLoad: FinancialsVersion) {
    this.historicalItems = this.historicalItems.filter((row) => row.uuid !== payLoad.uuid);
  }

  deleteExistingHistoricalItem(payLoad: FinancialsVersion) {
    payLoad.deleted = true;
    this.historicalDeleted = [...this.historicalDeleted, {...payLoad}] 
    this.historicalItems = this.historicalItems.filter((row) => row.id !== payLoad.id);
  }

  isDuplicateHistVersion() {
    const lookUp: { [key: string]: boolean } = {};
    for (let item of this.historicalItems) {
      if (item.versionId && item.versionId in lookUp) {
        return true;
      }
      lookUp[item.versionId] = true;
      lookUp[item.rank] = true;
    }
    return false;
  }

  isDuplicateHistRank() {
    const lookUp: { [key: string]: boolean } = {};
    for (let item of this.historicalItems) {
      if (item.rank && item.rank in lookUp) {
        return true;
      }
      lookUp[item.rank] = true;
    }
    return false;
  }

  isDuplicateProjectedVersion() {
    const lookUp: { [key: string]: boolean } = {};
    for (let item of this.projectedItems) {
      if (item.versionId && item.versionId in lookUp) {
        return true;
      }
      lookUp[item.versionId] = true;
    }
    return false;
  }

  isDuplicateProjectedRank() {
    const lookUp: { [key: string]: boolean } = {};
    for (let item of this.projectedItems) {
      if (item.rank && item.rank in lookUp) {
        return true;
      }
      lookUp[item.rank] = true;
    }
    return false;
  }

  getHistoricalItems() {
    return this.historicalItems;
  }

  setHistoricalItems(items: FinancialsVersion[]) {
    this.historicalItems = items;
  }
  resetDeletedItems(){
    this.projectedDeleted = [];
    this.historicalDeleted=[];
  }

  getProjectedItems() {
    return this.projectedItems;
  }

  setProjectedItems(items: FinancialsVersion[]) {
    this.projectedItems = items;
  }

  getEnabledVersionsFromGx() {
    const userDetails = this.userMgmtService.getSelectedUserDetails();
    const orgId = userDetails?.organization?.id;
    return this.dataService.getEnabledVersions(orgId).pipe(map((resp) => resp.body?.response));
  }

  getVersionListDetails(versionId: string) {
    return this.dataService.getVersionListById(versionId).pipe(map((resp) => resp?.body?.response));
  }

  saveVersionList(name: string, uploadDate: string, fundId: string) {
    const userDetails = this.userMgmtService.getSelectedUserDetails();
    const nonBlankHistoricalItems = this.getHistoricalItems().filter((item) => item.versionId !== '');
    const nonBlankProjectedItems = this.getProjectedItems().filter((item) => item.versionId !== '');
    const newVersionList: VersionListDetails = {
      backwardLookingMappings: nonBlankHistoricalItems,
      forwardLookingMappings: nonBlankProjectedItems,
      name: name,
      orgId: userDetails?.organization?.id,
      fundId: fundId,
      uploadDate: uploadDate,
      userId: userDetails.id,
    };
    return this.dataService.saveVersionList(newVersionList).pipe(map((response) => response.body?.response));
  }

  updateVersionList(name: string, uploadDate: string, fundId: string, versionId: string) {
    const userDetails = this.userMgmtService.getSelectedUserDetails();
    const nonBlankHistoricalItems = [...this.getHistoricalItems().filter((item) => item.versionId !== ''), ...this.historicalDeleted];
    const nonBlankProjectedItems = [...this.getProjectedItems().filter((item) => item.versionId !== ''), ...this.projectedDeleted];
    const newVersionList: VersionListDetails = {
      backwardLookingMappings: nonBlankHistoricalItems,
      forwardLookingMappings: nonBlankProjectedItems,
      name: name,
      orgId: userDetails?.organization?.id,
      fundId: fundId,
      uploadDate: uploadDate,
      userId: userDetails.id,
    };
    console.log(newVersionList);
    return this.dataService.updateVersionList(newVersionList, versionId).pipe(map((response) => response.body?.response));
  }
}
