import { DebtCompanySummaryComponent } from './qubit-x/portfolio/debt/debt-company-summary/debt-company-summary.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { ProfileComponent } from './profile/profile.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { AdminComponent } from './administration/admin.component'
import { UsersListComponent } from './users-list/users-list.component';
import { ResetComponent } from './auth/reset-password/reset-password.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { LoginFailedComponent } from './auth/login-failed/login-failed.component';
import { LoginActivate } from './auth/login-activate.service';
import { UserProfileComponent } from './profile/user-profile/user-profile.component';
import { ApplicationFormsComponent } from './application-forms/application-forms.component';
import { BugsComponent } from './bugs/bugs.component';
import { SearchHomeComponent } from './search-home/search-home.component';
import { PortfolioComponent } from './qubit-x/portfolio/portfolio.component';
import { CompanyValuationComponent } from './qubit-x/portfolio/company-valuation/company-valuation.component';
import { ExitSimulationComponent } from './qubit-x/portfolio/exit-simulation/exit-simulation.component';
import { PortfolioSummaryComponent } from './qubit-x/portfolio/portfolio-summary/portfolio-summary.component';
import { OldInvestmentsComponent } from './qubit-x/portfolio/old-investments/old-investments.component';
import { InvestmentPageSummaryComponent } from './qubit-x/portfolio/investment-page-summary/investment-page-summary.component';
import { FundListUIComponent } from './qubit-x/portfolio/fund-list-ui/fund-list-ui.component';
import { CustomSummaryComponentComponent } from './qubit-x/portfolio/fund-list-ui/custom-summary-component/custom-summary-component.component';
import { ProtfolportfolioCurrencyDashboardComponent } from './qubit-x/portfolio/currency-dashboard/protfolportfolio-currency-dashboard.component';
import { CorporateComponent } from './qubit-x/corporate/corporate.component';
import { LegalEntitiesComponent } from './qubit-x/corporate/legal-entities/legal-entities.component';
import { QXI_PortfolioComponent } from './qubit-x/impairement/qxi-portfolio.component';
import { QXI_CompanyValuationComponent } from './qubit-x/impairement/qxi-company-valuation/qxi-company-valuation.component';
import { QXI_InvestmentPageSummaryComponent } from './qubit-x/impairement/qxi-investment-page-summary/qxi-investment-page-summary.component';
import { QXI_PortfolioSummaryComponent } from './qubit-x/impairement/qxi-portfolio-summary/qxi-portfolio-summary.component';
import { QXI_ExitSimulationComponent } from './qubit-x/impairement/qxi-exit-simulation/qxi-exit-simulation.component';
import { QXI_FundListUIComponent } from './qubit-x/impairement/fund-list-ui/qxi-fund-list-ui.component';
import { QXI_CustomSummaryComponentComponent } from './qubit-x/impairement/fund-list-ui/qxi-custom-summary-component/qxi-custom-summary-component.component';
import { QXI_ProtfolportfolioCurrencyDashboardComponent } from './qubit-x/impairement/qxi-currency-dashboard/qxi-protfolportfolio-currency-dashboard.component';
import { QXP_PortfolioSummaryComponent } from './qubit-x/corporate/qxp-portfolio-summary/qxp-portfolio-summary.component';
import { QXP_CompanyValuationComponent } from './qubit-x/corporate/qxp-company-valuation/qxp-company-valuation.component';
import { QXP_InvestmentPageSummaryComponent } from './qubit-x/corporate/qxp-investment-page-summary/qxp-investment-page-summary.component';
import { QXP_FundListUIComponent } from './qubit-x/corporate/qxp-fund-list-ui/qxp-fund-list-ui.component';
import { QXP_CustomSummaryComponentComponent } from './qubit-x/corporate/qxp-fund-list-ui/qxp-custom-summary-component/qxp-custom-summary-component.component';
import { QXP_PortfolioComponent } from './qubit-x/corporate/qxp-portfolio.component';
import { SharedAccessComponent } from './shared-access/shared-access.component';
import { TransactionsComponent } from './qubit-x/portfolio/transactions/transactions.component';
import { ExitSimulationV2Component } from './exit-simulation-v2/exit-simulation-v2.component';
import { AppScheduledDownComponent } from '../app/app-scheduled-down/app-scheduled-down.component'
import { ValueBridgeFundLevelComponent } from './value-bridge-fund-level/value-bridge-fund-level.component';
import { DebtCompanyValuationComponent } from './qubit-x/portfolio/debt/debt-company-valuation/debt-company-valuation.component';
import { InvestmentComponent } from './qubit-x/portfolio/investment/investment.component';
import { FundAllocationComponent } from './qubit-x/portfolio/fund-allocation/fund-allocation.component';
import { MonitoringNavigationComponent } from './qubit-x/portfolio/monitoring-navigation/monitoring-navigation.component';
import { LeverageRatioComponent } from './qubit-x/portfolio/leverage-ratio/leverage-ratio.component';
import { StatusFundLevelComponent } from './status-fund-level/status-fund-level.component';
import { CompanyValuationV2Component } from './qubit-x/portfolio/company-valuation-v2/company-valuation-v2.component';
import { InvestmentPageSummaryV2Component } from './qubit-x/portfolio/investment-page-summary-v2/investment-page-summary-v2.component';
import { PortfolioSummaryV2Component } from './qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.component';
import { InvestmentV2Component } from './qubit-x/portfolio/investment-v2/investment-v2.component';
import { StatusPageComponent } from './status-page/status-page.component';
import { DataManagementComponent } from './data-management/data-management.component';
import { ValueBridgeFundLevelV2Component } from './value-bridge-fund-level-v2/value-bridge-fund-level-v2.component';
import { ReplicateFundsComponent } from './qubit-x/replicate-funds/replicate-funds.component';
import { OrganizationChooseComponent } from './auth/organization-choose/organization-choose.component';
import { CreditRatingComponent } from './qubit-x/portfolio/debt/credit-rating-upload/credit-rating-upload.component';
import { OrganizationEzComponent } from './auth/organization-ez/organization-ez.component';
import { OrganizationComponent } from './auth/organization/organization.component';
import { IntegrationToolComponent } from './product-integration-tool/integration-tool.component';
import { ProductIntegrationToolComponent } from './product-integration-tool/product-integration-tool.component';
import { MasterCompanyInfoComponent } from './qubit-x/portfolio/master-company-info/master-company-info.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { TermandconditionComponent } from './termandcondition/termandcondition.component';
import { MappingManagementContainerComponent } from './mapping-managment-config/mapping-mgmt-container.component';
import { VersionsComponent } from './mapping-managment-config/versions/versions.component';
import { AttributeListContainerComponent } from './mapping-managment-config/attribute-list-container/attribute-list-container.component';
import { AttributesComponent } from './mapping-managment-config/attributes/attributes.component';
import { CompanyAssignmentComponent } from './mapping-managment-config/company-assignment/company-assignment.component';
import { VersionListComponent } from './mapping-managment-config/versions/version-list/version-list.component';
import { CreateVlComponent } from './mapping-managment-config/versions/create-vl/create-vl.component';
import { CreditBiDashboardComponent } from './credit-bi-dashboard/credit-bi-dashboard.component';
import { CreditCompanyAssignmentComponent } from './mapping-managment-config/company-assignment/credit-company-assignment/credit-company-assignment.component';
import { DataReconDashboardComponent } from './data-recon-dashboard/data-recon-dashboard.component';
import { DataReconPlutoComponent } from './data-recon-pluto/data-recon-pluto.component';
import { PowerBiReportComponent } from './qubit-x/power-bi/power-bi-report/power-bi-report.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full'},
  { path: 'profile', component: ProfileComponent, canActivate:[LoginActivate] },
  { path: 'my-profile', component: UserProfileComponent, canActivate:[LoginActivate] },
  { path: 'user-management', component: UserManagementComponent, canActivate:[LoginActivate] },
  { path: 'admin', component: AdminComponent, canActivate:[LoginActivate] },
  { path: 'application-forms/:userId', component: ApplicationFormsComponent, canActivate:[LoginActivate] },
  { path: 'users', component: UsersListComponent, canActivate:[LoginActivate] },
  { path: 'search-home', component: SearchHomeComponent, canActivate:[LoginActivate] },
  { path: 'support', component: BugsComponent, canActivate:[LoginActivate] },
  // { path: 'portfolio/:fundId', component: PortfolioComponent, canActivate:[LoginActivate] },
  { path: 'portfolio/:fundId', component: PortfolioSummaryV2Component, canActivate:[LoginActivate] },
  // { path: 'company-valuation/:company', component: CompanyValuationComponent, canActivate:[LoginActivate] },
  { path: 'company-valuation/:company', component: CompanyValuationV2Component, canActivate:[LoginActivate] },
  { path: 'debt-company-valuation', component: DebtCompanyValuationComponent, canActivate:[LoginActivate] },
  { path: 'debt-company-summary', component: DebtCompanySummaryComponent, canActivate:[LoginActivate] },
  { path: 'exit-simulation', component: ExitSimulationComponent, canActivate: [LoginActivate] },
  { path: 'portfolio-summary', component: PortfolioSummaryComponent, canActivate: [LoginActivate] },
  { path: 'shared-access', component: SharedAccessComponent, canActivate: [LoginActivate] },
  { path: 'transactions/:fundId', component: TransactionsComponent, canActivate: [LoginActivate] },
  { path: 'company-master-list/:fundId', component: MasterCompanyInfoComponent, canActivate: [LoginActivate] },
  // { path: 'value-bridge-fund-level/:fundId', component: ValueBridgeFundLevelComponent, canActivate: [LoginActivate] },
  { path: 'value-bridge-fund-level/:fundId', component: ValueBridgeFundLevelV2Component, canActivate: [LoginActivate] },
  { path: 'investments', component: InvestmentV2Component, canActivate: [LoginActivate] },
  { path: 'exit-simulation-v2', component: ExitSimulationV2Component, canActivate: [LoginActivate] },
  { path: 'monitoring', component: MonitoringNavigationComponent, canActivate: [LoginActivate] },

  { path: 'auth/:id_token', component: AuthComponent },
  { path: 'error', component: AuthComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'reset-password', component: ResetComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'login-fail', component: LoginFailedComponent },
  { path: 'organization/choose', component: OrganizationChooseComponent },
  { path: 'organization/ez', component:  OrganizationEzComponent},
  { path: 'organization/:orgName', component:  OrganizationComponent},
  { path: 'data-management', component: DataManagementComponent, canActivate: [LoginActivate] },

  { path: 'credit-bi-dashboard', component: CreditBiDashboardComponent, canActivate: [LoginActivate] },

  { path: 'oldInvestments', component:OldInvestmentsComponent, canActivate: [LoginActivate] },
  { path: 'investmentPageSummary', component:InvestmentPageSummaryComponent, canActivate: [LoginActivate] },
  { path: 'investmentPageSummaryV2', component:InvestmentPageSummaryV2Component, canActivate: [LoginActivate] },
  { path: 'fundList', component:FundListUIComponent, canActivate: [LoginActivate] },
  { path: 'customSummary/:reportId', component:CustomSummaryComponentComponent, canActivate: [LoginActivate] },
  { path: 'portfolio-currency-dashboard/:fundId', component:ProtfolportfolioCurrencyDashboardComponent, canActivate: [LoginActivate]},
  { path: 'fund-allocation', component:FundAllocationComponent, canActivate: [LoginActivate] },
  { path: 'credit-rating-upload', component:CreditRatingComponent, canActivate: [LoginActivate] },
  { path: 'leverage-ratio', component:LeverageRatioComponent, canActivate: [LoginActivate] },
  { path: 'status-fund-level/:fundId', component: StatusFundLevelComponent, canActivate: [LoginActivate] },
  { path: 'replicate-funds', component: ReplicateFundsComponent, canActivate: [LoginActivate] },

  //Impairment Valuation
  { path: 'qxi-portfolio/:fundId', component:QXI_PortfolioComponent, canActivate: [LoginActivate] },
  { path: 'qxi-company-valuation/:company', component: QXI_CompanyValuationComponent, canActivate: [LoginActivate] },
  { path: 'qxi-portfolio-summary', component:QXI_PortfolioSummaryComponent, canActivate: [LoginActivate] },
  { path: 'qxi-investmentPageSummary', component:QXI_InvestmentPageSummaryComponent, canActivate: [LoginActivate] },
  { path: 'qxi-exit-simulation', component: QXI_ExitSimulationComponent, canActivate: [LoginActivate] },
  { path: 'qxi-fundList', component:QXI_FundListUIComponent, canActivate: [LoginActivate] },
  { path: 'qxi-customSummary', component:QXI_CustomSummaryComponentComponent, canActivate: [LoginActivate] },
  { path: 'portfolio-currency-dashboard', component:QXI_ProtfolportfolioCurrencyDashboardComponent, canActivate: [LoginActivate] },

  { path: 'qxi-portfolio-currency-dashboard', component:QXI_ProtfolportfolioCurrencyDashboardComponent, canActivate: [LoginActivate] },

  //Corporate Valuation
  { path: 'corporate-valuation', component:CorporateComponent, canActivate: [LoginActivate] },
  { path: 'legal-entities/:corpId', component:LegalEntitiesComponent, canActivate: [LoginActivate] },
  { path: 'qxp-portfolio/:fundId', component:QXP_PortfolioComponent, canActivate: [LoginActivate] },
  { path: 'qxp-company-valuation/:company', component: QXP_CompanyValuationComponent, canActivate: [LoginActivate] },
  { path: 'qxp-portfolio-summary', component:QXP_PortfolioSummaryComponent, canActivate: [LoginActivate] },
  { path: 'qxp-investmentPageSummary', component:QXP_InvestmentPageSummaryComponent, canActivate: [LoginActivate] },
  { path: 'qxp-fundList', component:QXP_FundListUIComponent, canActivate: [LoginActivate] },
  { path: 'qxp-customSummary', component:QXP_CustomSummaryComponentComponent, canActivate: [LoginActivate] },

  //App Scheduled Down
  { path: 'scheduled-upgrade', component: AppScheduledDownComponent},
  { path: 'product-integration', component: ProductIntegrationToolComponent},
  { path: 'status', component: StatusPageComponent},
  { path: 't&c', component: TermandconditionComponent },

  { path: 'power-bi', component: PowerBiReportComponent, canActivate: [LoginActivate] },

  //configuration
  { path: 'configuration-v2/:parentId', component: ConfigurationComponent, canActivate: [LoginActivate] },
  { path: 'mapping-configuration/:fundId', component: MappingManagementContainerComponent, canActivate: [LoginActivate],
    children: [
      { path: '', redirectTo: 'attributes', pathMatch: 'full'},
      {
        path: 'attributes',
        component: AttributeListContainerComponent,
        canActivate: [LoginActivate],
      },{
        path: 'create-attributes-map',
        component: AttributesComponent,
        canActivate: [LoginActivate],
      },
      {
        path: 'replicate-attributes-map/:mapId',
        component: AttributesComponent,
        canActivate: [LoginActivate],
      },
      {
        path: 'attributes-map-details/:mapId',
        component: AttributesComponent,
        canActivate: [LoginActivate],
      },
      { path: 'versions', component: VersionsComponent, canActivate: [LoginActivate], },
      { path: 'version-details/:versionId', component: CreateVlComponent, canActivate: [LoginActivate], },
      { path: 'create-version', component: CreateVlComponent, canActivate: [LoginActivate], },
      { path: 'edit-version/:versionId', component: CreateVlComponent, canActivate: [LoginActivate], },
      { path: 'company-assignment', component: CompanyAssignmentComponent, canActivate: [LoginActivate] },
      { path: 'company-assignment-qxc', component: CreditCompanyAssignmentComponent, canActivate: [LoginActivate] }
    ]
  },
  {
        path: 'data-recon', component: DataReconDashboardComponent, canActivate: [LoginActivate],
  },
  // Data Recon Pluto
  {
    path: 'data-recon-pluto', component: DataReconPlutoComponent, canActivate: [LoginActivate],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, paramsInheritanceStrategy: "always", relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

