<!-- <div class="page-header">
  <nav class="page-header-navigation">
    <ul>
      <li *ngFor="let item of navigationLinks; index as i" [class.active]="item.isActive">
        <a (click)="switchTab(item.key, i);" [class.disabled]="item.isActive">{{item.title}}</a>
      </li>
    </ul>
  </nav>
</div>
<ng-template [ngIf]="activeTab=='goingConcernAnalysis'"> -->

  <div class="exit-investment-container" >
    <!-- <div class="col-2">
      <span class="main-heading">As of {{ valuationDate | date: "mediumDate" }}</span>
    </div> -->

    <!-- <form class="exit-form" [formGroup] = "exitInvestmentFormGroup" >
      <div class="investment-select">
  
          <div class="mat-label">Investment Status</div>
        <mat-form-field class="custom-input-select">
          <mat-select formControlName="exitFormSelect" placeholder="Investment Status"  (selectionChange)="onInvestmentStatusChange($event)">
              <mat-option value="Current">
                  Current
              </mat-option>
              <mat-option value="Exited">
                  Exited
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    <div class="exited-date" *ngIf="exitInvestmentFormGroup.get('exitFormDate')">
        <div class="mat-label">Exit Date</div>
          <mat-form-field class="dateInputBox custom-input-select" >
              <input matInput [matDatepicker]="picker" formControlName="exitFormDate" placeholder="Exit Date"  [max]="yesterday" 
                  (dateChange)="onExitDateChange($event)">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </form> -->
  </div>
 

  <app-going-concern-analysis (valuationDateEvent) ="setValuationDate($event)"></app-going-concern-analysis>
<!-- </ng-template> -->
