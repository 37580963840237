import { Injectable } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { MapChart } from 'angular-highcharts';
import worldMap from './world-data.data';
import { XIRRServiceService } from './xirrservice.service';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import Highcharts from 'highcharts';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { CTMService } from './valuation-algorithms/ctm.service';
import { CCMServiceV3 } from './valuation-algorithms/ccm-new-v3.service';
import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';

import * as moment from 'moment';
import { FundListService } from './fund-list-ui/fund-list.service';
import { MessageService } from 'src/app/services/message.service';
import { colors } from '../../utils/colors'
import { DatePipe } from '@angular/common';
import { TranslateService } from 'src/app/services/translation.service';

@Injectable({
  providedIn: 'root'
})
export class PortFolioService {
  companies = [];
  allCompanyValuationDates = [];

  qualitativeAnalysisV2 = new Subject();

  userInputQualitativeTitle;
  userInputQualitativeComment;
  userInputQualitativeuID;

  allCompanyValuationDatesAvailable = new Subject();

  fundDetails:any
  orgKeyWords = [];
  
  formsClone = []

  partnerList = {
    name: '',
    isSelected: false
  }

  selectedReportId = "";

  exchangeRateUpdated = new Subject();

  investmentTableData = [] //investments

  selectedListOfInvestors = {} //investments

  securityType: string = '';
  fullyDilutedOwnership;
  selectedCompany = {}
  userId;
  portfolioDataClone;

  formDetailsObj: any = {};
  portfolioData = [];
  searchedForms = [];

  topGainers = [];

  topLosers = [];

  selectedForm;

  capTableDataForAllIds = {} as any;

  summaryTableRefreshOnIRRUpdate = new Subject();

  businessUnitsValSummaryForAllIds = {
    summary: {},
    currency: {}
  } as any;

  totalPortfolioData = {
    totalInvestment : 0,
    totalRealisedProceeds : 0,
    totalMOIC : 0,
    totalIRR : 0,
    totalTotalValue: 0,
    totalChangeInValPercentage: 0,
    totalStakeVal: 0,
    totalFairVal: 0,
    totalPrevStakeVal: 0,
    totalShareHoldersReturn: 0,
    loading: true
  };

  totalRealisedProceeds = 0;

  totalInvestmentAmount = 0;

  userSavedInvestedForms = [];  
  userSavedOldFormsExcel = {};

  multiples = {
    investmentAmount: 0,
    realisedProceeds: 0,
    moic: 0,
    irr: 0
  }

  mapData = {
    value: 'currentVal',
    name:'Current Valuation',
    data:[]
  }

  exitSimulationChartData = {
    xAxis: [],
    yAxis: {
      column: {
        nav: [],
        realisedProceeds: [],
        investments: [],
        irr: [],
        moic: []
      },
      line: {
        bearishCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
        baseCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
        bullishCase: {
          nav: [],
          irr: [],
          moic: [],
          realisedProceeds: [],
          investments: []
        },
      }
    }
  }  

  geographicalFormData = [];
  filteredForms = []
  geography = [];

  sectors = [];

  columnChartData = []

  columnChart;
  mapChart;

  companyId;

  portfolioSummary;
  portfolioSummaryWidget = "PORTFOLIO_SUMMARY";

  portfolioUpdated = new Subject();

  selectedCurrency = "USD"
  
  DATA_KEY_OLD_VALUATION = "DATA_KEY_OLD_VALUATION";

  selectedFundId;

  myOrgUsers;

  transactionData = [];

  valDateUpperLimit = {};

  exitSimulationV2Key = "EXIT_SIMULATION_V2";
  exitSimulationData : Object[] = [];
  isCompaniesAvailable = new Subject()

  yellowReportSearchedForms = {
    "response": {
        "portfolioData": [
            {
                "logo": "",
                "companyName": "Altium Packaging, LLC",
                "investmentDate": "2019-06-30",
                "geography": "",
                "sector": "",
                "security": "EQUITY",
                "latestValuationDate": "2021-07-31",
                "valuesInPortfolioCurrency": {
                    "currency": "USD",
                    "stake": 35.0,
                    "stakeValue": 1589.1648664709542,
                    "prevStakeValue": 1343.8389524602085,
                    "changeInValuation": 0.18255603735969983,
                    "changeInValuationPercentage": 18.255603735969984,
                    "investmentAmount": 478.0,
                    "realisedProceeds": 0.0,
                    "moic": 3.324612691361829,
                    "grossIRR": 77.79327769279179,
                    "shareHoldersReturn": 232.4612691361829,
                    "totalValue": 1589.1648664709542,
                    "investmentTransactions": [
                        {
                            "date": "2019-06-30",
                            "value": 478.0,
                            "Amount": 478.0,
                            "Currency": "USD",
                            "Buyer": "OTPP",
                            "companyId": "9822f5d3-efb3-4c2f-84aa-8fff20b81b1f",
                            "companyName": "Altium Packaging, LLC",
                            "rpSource": "",
                            "valueToBeConsidered": 478.0
                        }
                    ],
                    "realisedProceedsTransactions": []
                },
                "valuesInLocalCurrency": {
                    "currency": "USD",
                    "stake": 35.0,
                    "stakeValue": 1589.1648664709542,
                    "prevStakeValue": 1343.8389524602085,
                    "changeInValuation": 0.18255603735969983,
                    "changeInValuationPercentage": 18.255603735969984,
                    "investmentAmount": 478.0,
                    "realisedProceeds": 0.0,
                    "moic": 3.324612691361829,
                    "grossIRR": 77.79327769279179,
                    "shareHoldersReturn": 232.4612691361829,
                    "totalValue": 1589.1648664709542
                }
            },
            {
                "logo": "",
                "companyName": "The Kraft Heinz Foods Company",
                "investmentDate": "2019-06-30",
                "geography": "",
                "sector": "",
                "security": "EQUITY",
                "latestValuationDate": "2021-06-30",
                "valuesInPortfolioCurrency": {
                    "currency": "USD",
                    "stake": 51.8,
                    "stakeValue": 528.2347981418237,
                    "prevStakeValue": 526.4002690842435,
                    "changeInValuation": 0.003485045820306383,
                    "changeInValuationPercentage": 0.34850458203063833,
                    "investmentAmount": 350.0,
                    "realisedProceeds": 0.0,
                    "moic": 1.5092422804052106,
                    "grossIRR": 22.816639900207544,
                    "shareHoldersReturn": 50.92422804052106,
                    "totalValue": 528.2347981418237,
                    "investmentTransactions": [
                        {
                            "date": "2019-06-30",
                            "value": 350.0,
                            "Amount": 350.0,
                            "Currency": "USD",
                            "Buyer": "OTPP",
                            "companyId": "3e218156-8c41-4cfd-bab2-0b8463975f08",
                            "companyName": "Kraft Heinz Foods",
                            "rpSource": "",
                            "valueToBeConsidered": 350.0
                        }
                    ],
                    "realisedProceedsTransactions": []
                },
                "valuesInLocalCurrency": {
                    "currency": "USD",
                    "stake": 51.8,
                    "stakeValue": 528.2347981418237,
                    "prevStakeValue": 526.4002690842435,
                    "changeInValuation": 0.003485045820306383,
                    "changeInValuationPercentage": 0.34850458203063833,
                    "investmentAmount": 350.0,
                    "realisedProceeds": 0.0,
                    "moic": 1.5092422804052106,
                    "grossIRR": 22.816639900207544,
                    "shareHoldersReturn": 50.92422804052106,
                    "totalValue": 528.2347981418237
                }
            },
            {
                "logo": "",
                "companyName": "Arterra Wines Canada, Inc.",
                "investmentDate": "2019-06-30",
                "geography": "",
                "sector": "",
                "security": "EQUITY",
                "latestValuationDate": "2021-08-03",
                "valuesInPortfolioCurrency": {
                    "currency": "USD",
                    "stake": 91.6,
                    "stakeValue": 444.5667956248066,
                    "prevStakeValue": 267.9500200879842,
                    "changeInValuation": 0.6591407437806065,
                    "changeInValuationPercentage": 65.91407437806065,
                    "investmentAmount": 150.89602,
                    "realisedProceeds": 23.602878000000004,
                    "moic": 3.1025978924083395,
                    "grossIRR": 73.02055644988766,
                    "shareHoldersReturn": 210.25978924083398,
                    "totalValue": 468.1696736248066,
                    "investmentTransactions": [
                        {
                            "date": "2019-06-30",
                            "value": 200.0,
                            "Amount": 200.0,
                            "Currency": "CAD",
                            "Buyer": "OTPP",
                            "companyId": "36e3d54e-9300-4716-8b8e-30ad98110db2",
                            "companyName": "Artera Wines",
                            "rpSource": "",
                            "valueToBeConsidered": 150.89602
                        }
                    ],
                    "realisedProceedsTransactions": [
                        {
                            "date": "2021-01-22",
                            "value": 30.0,
                            "Amount": 30.0,
                            "Currency": "CAD",
                            "Seller": "OTPP",
                            "companyId": "36e3d54e-9300-4716-8b8e-30ad98110db2",
                            "companyName": "Artera Wines",
                            "rpSource": "Stake Sale",
                            "valueToBeConsidered": 23.602878000000004
                        }
                    ]
                },
                "valuesInLocalCurrency": {
                    "currency": "CAD",
                    "stake": 91.6,
                    "stakeValue": 558.4854938384333,
                    "prevStakeValue": 336.6112826364799,
                    "changeInValuation": 0.6591407437806066,
                    "changeInValuationPercentage": 65.91407437806066,
                    "investmentAmount": 200.0,
                    "realisedProceeds": 30.0,
                    "moic": 2.9424274691921664,
                    "grossIRR": 68.64214410781662,
                    "shareHoldersReturn": 194.24274691921664,
                    "totalValue": 588.4854938384333
                }
            }
        ],
        "aggregations": {
            "totalInvestment": 978.89602,
            "totalRealisedProceeds": 23.602878000000004,
            "totalTotalValue": 2585.569338237585,
            "totalStakeValue": 2561.9664602375847,
            "totalPrevStakeValue": 2138.1892416324363,
            "totalMOIC": 2.6413115238098372,
            "totalIRR": 60.015392899512186,
            "totalShareHoldersReturn": 1.6413115238098368,
            "totalChangeInValPercentage": 19.819443964726368
        }
    },
    "success": true,
    "errorCode": null,
    "message": "Yellow Report is successfully created"
  }

  constructor(private dataService: DataService, private ums: UserManagementService, 
    public xirrService : XIRRServiceService, private utilService: UtilService,
    private currExchangeService : CurrencyExchangeService,
    private fundService: FundListService, private datePipe: DatePipe,
    private ms: MessageService, public translateService : TranslateService)
  {
    const userId = this.ums.getSelectedUserDetails().id;
    
    this.dataService.getWidgetDataFromDB(this.portfolioSummaryWidget, userId).subscribe(res => {
      // console.log("Widget Logs", res.body["response"]);
      this.portfolioSummary = res.body["response"][0].widgetData;
    }, error => {
      console.log("Error: Failed to load portfolio widget data", error)
    })
    
    this.getUsers();
  }

  getUsers() {
    const myDetails = this.ums.getSelectedUserDetails();
    this.ums.getUsers(myDetails.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(user => user.id !== myDetails.id);;
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  async init(fundId?) {

    if(!this.fundService.allFunds || this.fundService.allFunds.length == 0) {
      const myDetails = this.ums.getSelectedUserDetails();

      this.fundService.getFunds(myDetails);
    }

    try {
      const valDateUpperLimitRes = await this.dataService.getAllWidgetDataFromDB(["ALL_TRANSACTIONS", "VAL_DATES_UPPERLIMIT"], this.selectedFundId).toPromise();
    
      if(valDateUpperLimitRes.body["response"].ALL_TRANSACTIONS) {
        this.transactionData = valDateUpperLimitRes.body["response"].ALL_TRANSACTIONS;
      } else {
        this.transactionData = []
      }

      if(valDateUpperLimitRes.body["response"].VAL_DATES_UPPERLIMIT) {
        this.valDateUpperLimit = valDateUpperLimitRes.body["response"].VAL_DATES_UPPERLIMIT;
      } else {
        this.valDateUpperLimit = {};
      }

    } catch(e) {
      console.log(e)
    }

    try {
      const apiData = await this.dataService.getWidgetDataFromDB(this.exitSimulationV2Key, this.selectedFundId).toPromise();
      const widgetData = apiData.body["response"][0].widgetData;
      this.exitSimulationData = widgetData.simulationData ? widgetData.simulationData : [];
      if(widgetData.chartData){
        this.exitSimulationChartData = widgetData.chartData;
      }

      if(this.exitSimulationData) {
        this.exitSimulationData.forEach((row: any) => {
          
          // variationPercentage is defaulted to 10%.
          // This is backward compatibility code

          if(!row.variationPercentage) {
            row.variationPercentage = 10;
          }
        })
      }
      // console.log("Succesfully fetched Exit Simulation Data", apiData)
    } catch (error) {
      // this.resetExitSimulation();

      console.log("Failed to read Exit Simulation Data", error)
    }

    this.totalPortfolioData['loading'] = true

    if(!this.companies || this.companies.length === 0) {
      this.companies = [];
      this.getAllForms(fundId);

    } else {
      const loggedInUserId = this.ums.getSelectedUserDetails().id;
      
      if(true ||this.companies[0].assessorUserId != loggedInUserId || (fundId && fundId !== this.companies[0].fundCompany) || this.selectedReportId) {
        this.companies = [];
        this.portfolioData = [];
        this.portfolioDataClone = null;
        this.selectedListOfInvestors = {};
        
        this.totalPortfolioData = {
          totalInvestment : 0,
          totalRealisedProceeds : 0,
          totalMOIC : 0,
          totalIRR : 0,
          totalTotalValue : 0,
          totalChangeInValPercentage: 0,
          totalStakeVal: 0,
          totalFairVal: 0,
          totalPrevStakeVal: 0,
          totalShareHoldersReturn: 0,
          loading: true
        };

        this.totalRealisedProceeds = 0;

        this.totalInvestmentAmount = 0;

        this.userSavedInvestedForms = [];  
        this.userSavedOldFormsExcel = {};

        this.searchedForms = [];

        this.getAllForms(fundId);  
              
      } else {
        this.preparePortfolioData();
      }

      this.selectedReportId = "";
    }
  }

  resetExitSimulation() {
    this.exitSimulationData = [];
    this.exitSimulationChartData = {
      xAxis: [],
      yAxis: {
        column: {
          nav: [],
          realisedProceeds: [],
          investments: [],
          irr: [],
          moic: []
        },
        line: {
          bearishCase: {
            nav: [],
            irr: [],
            moic: [],
            realisedProceeds: [],
            investments: []
          },
          baseCase: {
            nav: [],
            irr: [],
            moic: [],
            realisedProceeds: [],
            investments: []
          },
          bullishCase: {
            nav: [],
            irr: [],
            moic: [],
            realisedProceeds: [],
            investments: []
          }
        }
      }
    }
  }

  updatePortfolioData() {
    this.preparePortfolioData();
  }

  addOldValuationDate(newFormInExcel) {    
    try { 
      const selectedComp = this.portfolioData.find(c => c.id === this.companyId);
      
      const formattedNewForm = this.addOldDateToPortfolio(selectedComp, newFormInExcel);
      this.userSavedInvestedForms.push(formattedNewForm);

      this.updatePortfolioData();
    }
    catch(e) { console.log("No user added forms are found.")}
  }

  addOldDateToPortfolio(selectedComp, newForm) {
    const form = {
      id: newForm.id,
      groupFormId: selectedComp.id,
      companyName: selectedComp.companyName,
      security: selectedComp.security,
      website: selectedComp.website,
      geography: selectedComp.geography,
      sector: selectedComp.sector,
      userEntered: true,
      formVersion: selectedComp.formVersion,
      currency: selectedComp.currency,
      
      valuationDate: newForm.valuationDate.value,
      investment: {
        equityValue: {finalAdjustedEquityVal: newForm.fairVal, stake: newForm.stake, stakeValue: newForm.stakeVal}
      }
    }

    this.allCompanyValuationDates.push(form);

    const valDateFilter = (!this.valDateUpperLimit[form.groupFormId]) // No limit is set

              || (new Date(form.valuationDate) <= new Date(this.valDateUpperLimit[form.groupFormId]))              

    if(valDateFilter) {
      this.companies.push(form);
    }

    this.companies = this.companies.sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })

    return form;
  }

  assignUsersToForm(selectedFormForAssignment, newCompanyName?) {

    const userDetails = this.ums.getSelectedUserDetails();

    
    if(newCompanyName) {
      // In case of new form creation, assign to all users with whome the fund is shared.
      const selectedFund = this.fundService.getFundById(selectedFormForAssignment.fundCompany);
      this.resetUserAssignment();


      if(selectedFund.details && this.myOrgUsers && this.myOrgUsers.length > 0) {
        const selectedFundDetails = JSON.parse(selectedFund.details);
        const assignees = selectedFundDetails.assignees;
        if(assignees) {
          this.myOrgUsers.forEach(user => {
            const assignedUser = assignees.find(u => u.id === user.id);
        
            user.assigned = !!assignedUser;
            
            user.editable = assignedUser ? assignedUser.editable || assignedUser.permission : null;

            if(selectedFormForAssignment.assessorUserId === user.id) {
              user.assigned = true;
              user.editable = 'W';
            } else if(this.ums.isMasterUser()) {
              //Form or application will be shared to Org master user by default.
              user.assigned = true;
              user.editable = 'W';
            }
          })
        }
      }
    }

    const usersList = [];
    const assignees = [];
    const editable = [];

    this.myOrgUsers.forEach(u => {
      if(u.editable && u.editable != "FN") {
        usersList.push(u.id);
        editable.push(u.editable);

        const uInfo = {
          id: u.id,
          userName: u.userName,
          userId: u.userId,
          editable: u.editable
        }

        assignees.push(uInfo);
      }
    });
    
    const createdBy = {
      id: userDetails.id,        
      name: userDetails.userName,
      email: userDetails.userId
    }

    const body = {
      "users": usersList,
      "editable": editable,
      "formId": selectedFormForAssignment.id,
      "orgId": userDetails.organization.id,
      "usersInfo": JSON.stringify({createdBy: createdBy, assignees: assignees})
    }

    this.dataService.assignUsersToSAFForm(body).subscribe(result=>{
      // console.log("Assignmet result", result);

      if(!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("suc_user_assign_complete"), this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " " +this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }
      selectedFormForAssignment.assessorDetails.assignees = assignees
      this.ms.publish("loadingMessage", "");
    }, error=>{
      console.log("Assignmet error", error);

      if(!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("err_failed_assign_user_form"), this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " "+ this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }

      this.ms.publish("loadingMessage", "");
    })
  }

  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.editable = '';
    })
  }

  getAllForms(fundId?) {
    const userId = this.ums.getSelectedUserDetails().id;
    if(userId) {
      this.userId = userId;

      this.getOwnForms(fundId);
    }  
  }

  // Copied to Custom Summary
  getOwnForms(fundId?) {
    const user = this.ums.getSelectedUserDetails();

    this.dataService.getAllVCForms(this.userId, fundId, user.organization.id).subscribe( results =>{

      if(results.body["response"] && results.body["response"].length > 0) {
        //this.isCompaniesAvailable.next(true)
        this.prepareCompanyData(results.body["response"]);
        // this.exchangeRateUpdated.subscribe( res => {
        //   this.calculateTotal();
        // })
        this.getInvestmentData();   
        this.getUserSavedForms();
      } else {
        this.isCompaniesAvailable.next(false)
      }
    }, (error)=>{
      console.log("Error in fetching all forms", error);
    })
  }

  getAllFormsForAllFunds(funds) {
    const userId = this.ums.getSelectedUserDetails().id;
    this.dataService.getAllFormsForAllFunds(userId, funds).subscribe( results =>{

      if(results.body["response"] && results.body["response"].length > 0) {
        this.prepareCompanyData(results.body["response"]);
        this.getInvestmentData();        
      } 
    }, (error)=>{
      console.log("Error in fetching all forms", error);
    })
  }

  getAssignedForms(fundId?) {
    this.dataService.getAssignedForms(this.userId).subscribe(results=>{

      if(results.body["response"]) {
        this.prepareCompanyData(results.body["response"]);
      }
    }, error=>{
      console.log("Error in fetching assigned forms", error);
    })
  }

  getUserSavedForms() {
    const allPrimaryInvestmentIds = this.getInvestmentDateForms().map( f=> f.id );
    // console.log("------------------------- Livango exists", allPrimaryInvestmentIds.includes("36c12d46-f66e-4b66-be3c-7f0839351cf0"))

    this.dataService.getWidgetDatForAllIds(this.DATA_KEY_OLD_VALUATION, allPrimaryInvestmentIds).subscribe(res =>{
      const allSavedForms = res.body["response"];
      allPrimaryInvestmentIds.forEach( investmentId => {

        if(allSavedForms[investmentId]) {
          this.userSavedOldFormsExcel[investmentId] = JSON.parse(allSavedForms[investmentId]);
          const selectedComp = this.companies.find(c => c.id === investmentId);

          this.userSavedOldFormsExcel[investmentId].forEach( savedForm => {
            const form = {
              id: savedForm.id,
              groupFormId: selectedComp.id,
              companyName: selectedComp.companyName,
              security: selectedComp.security,
              website: selectedComp.website,
              geography: selectedComp.geography,
              sector: selectedComp.sector,
              userEntered: true,
              formVersion: selectedComp.formVersion,
              
              currency: "",
              
              valuationDate: savedForm.valuationDate.value,
              investment: {
                equityValue: {finalAdjustedEquityVal: savedForm.valuationMetadata[1].value, stake: savedForm.valuationMetadata[2].value, stakeValue: savedForm.valuationMetadata[3].value}
              }
            }
            
            this.allCompanyValuationDates.push(form);

            const valDateFilter = (!this.valDateUpperLimit[form.groupFormId]) // No limit is set

                      || (new Date(form.valuationDate) <= new Date(this.valDateUpperLimit[form.groupFormId]))              

            if(valDateFilter) {
              this.companies.push(form);
            }
          })
        }
      })

      this.allCompanyValuationDatesAvailable.next();
      // this.updatePortfolioData();
      // console.log("User saved forms")
    }, error => {
      console.log("No user saved forms exists")
    })

  }

  async prepareCompanyData(forms) {
    if(!forms) return;

    for(let i=0; i< forms.length; i++) {
      const form = forms[i];

      if(form.assessorDetails) { 
        const details = JSON.parse(form.assessorDetails);
        form.assessorDetails = details
        form.assignees = details.assignees.map(user=>user.userName);
      }
      const details = JSON.parse(form.details);

      // details.details = null;

      if(details) {           
        if(!form.companyName) {
          form.companyName = this.utilService.cleanString(details["companyName"]);
          form.companyNameInForm = this.utilService.cleanString(details["companyNameInForm"]);

        } else {
          form.companyNameInForm = form.companyName;
        }

        form.status = details["status"];
        form.website = details["website"];
        form.geography = details["geography"];
        form.versionName = details["versionName"];
        form.businessUnitsNumber = details["businessUnitsNumber"];

        form.multipleRealisedProceeds = details['multipleRealisedProceeds']? details['multipleRealisedProceeds'] : [];
        form.totalRealisedProceeds = details['totalRealisedProceeds']? details['totalRealisedProceeds'] : 0;

        form.multipleInvestmentAmount = details['multipleInvestmentAmount']? details['multipleInvestmentAmount'] : [];
        
        form.editable = this.isEditable(form);
        
        if(details['multipleInvestmentAmount']) {
          details['totalInvestmentAmount'] = 0;

          details['multipleInvestmentAmount'].forEach(inv => {
            details['totalInvestmentAmount'] += inv.value;
          })
          
          form.totalInvestmentAmount = details['totalInvestmentAmount'];
        }

        form.moic = details['moic']? details['moic'] : 0;
        form.irr = details['irr']? details['irr'] : 0;


        if(details["sector"] && typeof details["sector"] == "string") {
          const sectorParts = details["sector"].split(",")
          form.sector = sectorParts[0];
        }

        if(form.businessUnits) {
          form.currency = details["currency"];
          form.marketDate = details["marketDate"]

          form.businessUnits.forEach(bu => {
            const buDetails = JSON.parse(bu.details);

            if(buDetails) {  
              if(!bu.companyName) {
                bu.companyName = this.utilService.cleanString(buDetails["companyName"]);
                bu.companyNameInForm = this.utilService.cleanString(buDetails["companyNameInForm"]);

              } else {
                // bu.companyNameInForm = bu.companyName;
                bu.companyName = this.utilService.cleanString(buDetails["companyName"]);
                bu.companyNameInForm = this.utilService.cleanString(buDetails["companyNameInForm"]);
              }
              
              bu.versionName = buDetails["versionName"];
              bu.frozen = buDetails["frozen"];
              bu.status = buDetails["status"];
              bu.businessUnitName = buDetails["businessUnitName"];
            }
          });
        }
      }

      form.valuationDate = (moment(form.valuationDate)).format("YYYY-MM-DD");
      
      form.valuation = {CONCLUDED_IA: null, CONCLUDED_CCM: null, CONCLUDED_CTM: null, concluded: null}
    }

    const toBeAddedForms = forms.filter(f=> f.formVersion && +f.formVersion >= 2);

    toBeAddedForms.forEach(f => {
      const formExists = this.companies.find(c => c.id === f.id);

      // Allow only if it is investment date or it's val date is less than or equal to upper limit
      const valDateFilter = (!f.groupFormId) // Investment date

                || (!this.valDateUpperLimit[f.groupFormId]) // No limit is set

                || (new Date(f.valuationDate) <= new Date(this.valDateUpperLimit[f.groupFormId]))              

      if(!formExists && valDateFilter) {
        this.companies.push(f);

      } else if(formExists && !valDateFilter){
        const compIndex = this.companies.findIndex(c => c.id === f.id);
        this.companies.splice(compIndex, 1);
      }
    })

    this.companies = this.companies.sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })

    this.allCompanyValuationDates = toBeAddedForms.sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    })

  }

  isEditable(comp) {
    //Check the approval matrix data & decide whether form is editable or no.
    return !comp.approvalStatus || comp.approvalStatus == 0 || comp.editable_AM;
  }

  //All Valuation Dates created for the given company (after valuation date upper limit)
  getSelectedCompanyDates(companyId) {
    return this.companies.filter(comp=> comp.id === companyId || comp.groupFormId === companyId)
  }


  //All Valuation Dates created for the given company
  getCompanyAllValuationDates(companyId) {
    return this.allCompanyValuationDates.filter(comp=> comp.id === companyId || comp.groupFormId === companyId)
  }

  getInvestmentDateForms() {
    let investmentDateForms = this.companies.filter(comp=> !comp.groupFormId)
    
    return investmentDateForms;
  }

  getInvestmentData() {
    const allFormIds = this.allCompanyValuationDates.map( comp => comp.id );
    this.dataService.getInvestmentData(allFormIds).subscribe(res => {
      const investmentAPIData = res.body["response"];

      if(investmentAPIData) {
        this.allCompanyValuationDates.forEach( comp => {
          
          if(investmentAPIData[comp.id]) {
            comp.investment = JSON.parse(investmentAPIData[comp.id]);           
          }
        })
      }

      this.preparePortfolioData();
    }, error=> {
      console.log("No valuation data found", error)
    })
  }

  initConcludedValuation(company, valDateTime) {
    let concludedValues = [];

    let concludedValDateTime = new Date(valDateTime); 

    if(company.valuation.CONCLUDED_IA) {
      concludedValues.push({date: company.valuation.CONCLUDED_IA.date, valuation: company.valuation.CONCLUDED_IA.concluded})      
    }

    if(company.valuation.CONCLUDED_CCM) {
      concludedValues.push({date: company.valuation.CONCLUDED_CCM.date, valuation: company.valuation.CONCLUDED_CCM.concluded})
    }

    if(company.valuation.CONCLUDED_CTM) {
      concludedValues.push({date: company.valuation.CONCLUDED_CTM.date, valuation: company.valuation.CONCLUDED_CTM.concluded})
    }

    // Get the latest concluded valuation by sorting dates in DESCENDING order.
    concludedValues = concludedValues.sort((v1, v2)=>{
      if (v1.date < v2.date) {return 1;}
      if (v1.date > v2.date) {return -1;}
      return 0;
    })

    company.valuation.concluded = concludedValues[0].valuation;
  }

  initColumnChart(){
    const __this = this;

    const columnChart = new Chart({
      chart: { 
        renderTo: 'column-chart-container',
        type: 'column' 
      },
      title: { text: '' },
      xAxis: {
          categories: this.columnChartData.map( data => data.sectorName),
          crosshair: true
      },
      yAxis:{ title:{ text: "Value (" + this.selectedCurrency + " Mn)" } },
      credits: { enabled: false },
      tooltip: {
        formatter: function() {
          return __this.selectedCurrency + " " + Number(this.y.toFixed(1)).toLocaleString() + " Mn"
        }
      },
      plotOptions: {
          column: {
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      series: [
        { name: 'Previous Valuation', data: this.columnChartData.map( data => data.prevValDate), color: colors.chartPreviousValuationColor},
        { name: 'Current Valuation', data:  this.columnChartData.map( data => data.currValDate), color: colors.chartCurrentValuationColor}
      ] as any
    })

    return columnChart;

  }

  prepareColumnChart(){
    return this.prepareColumnChartInfo(this.filteredForms);
  }

  prepareColumnChartInfo(formsArray) {
    this.columnChartData = [];

    this.sectors = [];

    this.portfolioData.forEach(form =>{
      if(form.sector){
        if(this.sectors.indexOf(form.sector) == -1){
          this.sectors.push(form.sector);
        }
      }
    });

    this.sectors.forEach(sector => {
      const formsWithSameSector = formsArray.filter(form => form.sector == sector);
      let prevValDate: number = 0;
      let currValDate: number = 0;


      formsWithSameSector.forEach((comp) => {
        const currentValDateStakeValue = this.utilService.getValidNumber(comp.stakeVal * comp['exchangeRate']);
        // const changeInStakeValue = comp.stakeVal * comp.changeInValPercentage

        // const prevValDateStakeValue = this.utilService.getValidNumber((currentValDateStakeValue - comp.changeInStakeValue) * comp['exchangeRate']);
        const prevValDateStakeValue = this.utilService.getValidNumber(comp.prevStakeValue * comp['exchangeRate']);

        currValDate += currentValDateStakeValue;

        prevValDate += prevValDateStakeValue;
      })

      this.columnChartData.push({sectorName: sector, prevValDate: prevValDate, currValDate: currValDate});
    });

    this.columnChartData = this.columnChartData.sort((sec1, sec2) => {
      return (sec2.currValDate - sec1.currValDate);
    }).splice(0, 6);

    return this.initColumnChart();
  }

  initWorldMapChart(mapData){
    const mapChart = new MapChart({
      chart: { 
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0,0,0,0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits:{ enabled: false },
      mapNavigation: {
          enabled: false,
          buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
          name: 'Countries',
          color: '#E0E0E0',
          enableMouseTracking: false
        }, {
          name: mapData.name,
          joinBy: ['iso-a3', 'code3'],
          data: mapData.data,
          color: colors.chartCurrentValuationColor,
          minSize: 5,
          maxSize: '12%',
          tooltip: {
              pointFormatter: function() {
                return this.name+ ":" + Number(this.z.toFixed(1)).toLocaleString()
              }
          }
      }] as any
    });    
    return mapChart
  }

  updateWorldMap(){
    this.mapData.data = [];
    this.geographicalFormData.forEach((formData)=>{

      const data = { code3:'', name:'', z: 0 }
      
      if(this.mapData.value == "changeInVal"){
        this.mapData.name = "Change In Valuation";
        let changeInValCountryWise = 0;

        formData.forms.map((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);

          valDateForm = valDateForm.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date > f2Date? -1: 1;
          }).filter(f => f.status !== "Initiated");

          if(valDateForm.length == 1){
            changeInValCountryWise += valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue * valDateForm[0].investment.equityValue.changeInValPercentage).toFixed(2)) : 0;
          }
          else if(valDateForm.length > 1){
            const latestFormStake = valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
            const prevFormStake = valDateForm[1].investment ? +((valDateForm[1].investment.equityValue.stakeValue).toFixed(2)) : 0;
            changeInValCountryWise += latestFormStake - prevFormStake;
          }
        });

        data.z = changeInValCountryWise;
      }
      else if(this.mapData.value == "prevQuarterVal"){
        this.mapData.name = "Previous Quarter Valuation";
        let prevQuarValCountryWise = 0;

        formData.forms.forEach((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);

          valDateForm = valDateForm.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
          }).filter(f => f.status !== "Initiated");


          if(valDateForm.length == 1){
            prevQuarValCountryWise += valDateForm[0].investment ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
          }
          else if(valDateForm.length > 1){
            prevQuarValCountryWise += valDateForm[1].investment ? +((valDateForm[1].investment.equityValue.stakeValue).toFixed(2)) : 0;

          }
        });

        data.z = prevQuarValCountryWise;
      }
      else if(this.mapData.value == "noOfComp"){
        this.mapData.name = "Total Number of Companies";
        let totalNoOfComp = formData.forms.length? formData.forms.length: 0 ;
        data.z = totalNoOfComp;
      }
      else{
        this.mapData.name = "Current Valuation";
        let totalValCountryWise = 0;

        formData.forms.forEach((form) => {
          let valDateForm = this.getSelectedCompanyDates(form.id);
          if(valDateForm) {
            valDateForm = valDateForm.sort((f1, f2) => {
              const f1Date = new Date(f1.valuationDate);
              const f2Date = new Date(f2.valuationDate);
              return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
            }).filter(f => f.status !== "Initiated");
          
            totalValCountryWise += valDateForm[0] && valDateForm[0].investment && valDateForm[0].investment.equityValue.stakeValue ? +((valDateForm[0].investment.equityValue.stakeValue).toFixed(2)) : 0;
          }
        });
        data.z = totalValCountryWise;
      }

      data.code3 = formData.countryCode;
      data.name = formData.countryName;
      this.mapData.data.push(data);
    });

    return this.initWorldMapChart(this.mapData);
  }

  prepareMapData(){
    this.filteredForms = this.portfolioData;
    return this.prepareMapInformation(this.filteredForms);
  }

  prepareMapInformation(portfolioArray) {
    const formsArray = this.companies.filter(comp => {
      const formExists = portfolioArray.find(f => comp.id == f.id);
      return formExists;
    });

    let codeCountryName = [];

    this.geographicalFormData = [];

    formsArray.map(form => {
      if(form.geography != undefined){
        codeCountryName.push({
          alias: form.geography.cbAlias, 
          countryName: form.geography.name
        });
      }
    })

    let alias = '';
    let countryName = '';
    let filteredData = codeCountryName.filter((data)=>{
      if(alias == ''){
        alias=data.alias;
        countryName = data.countryName;
      }
      else{
        if(alias == data.alias && countryName == data.countryName)
          return false
        else
          alias = data.alias
          countryName = data.countryName
          return true
      }

    })

    let geoCode = codeCountryName.map((code) => code.alias)

    let geo = geoCode.filter( (code, index) => {
      return geoCode.indexOf(code) == index;
    })
  
    geo.map( geoCode =>{
      let formData = [];
      formsArray.filter(form =>{
        if(form.geography != undefined){
          if(form.geography.cbAlias == geoCode){
            formData.push(form);
          }
        }
      });
      const geographicalData = {
        countryName:'',
        countryCode:'', 
        forms:[]
      }

      let countryName;
      filteredData.map( data =>{
        if(data.alias == geoCode){
          countryName = data.countryName;
        }
      })
      geographicalData.countryName = countryName;
      geographicalData.countryCode = geoCode;
      geographicalData.forms = formData;
      this.geographicalFormData.push(geographicalData);
    })
    return this.updateWorldMap();
  }

  saveFundPortfolioWidget() {
    //Added to save currency and valuation Map values
    let portfolioDataReqBody = {
      selectedCurrency: this.selectedCurrency,
      valuationMap: this.mapData.value
    };

    this.dataService.saveWidgetDataToDB("PORTFOLIO_SUMMARY_DATA", JSON.stringify(portfolioDataReqBody), this.selectedFundId).subscribe(res=>{
      // console.log("SELECTED PORTFOLIO DATA SAVED TO WIDGET");
    }, error=>{
      console.log("Failed to save Selected portfolio data to db");
    })

    //Added to save currency and valuation Map values
  }

  getValuationDateForms(formId){
    return this.companies.filter(comp=> comp.groupFormId === formId)
  }

  async preparePortfolioData(){
    this.portfolioData = []
    const filteredInvestmentForms = this.companies.filter(comp => comp.groupFormId == null);

    const allFormIds = filteredInvestmentForms.map( comp => comp.id)

    // let logoURLsForAllIds = {};
    // try {
    //   const apiData = await this.dataService.getLogoURLForAllIds(allFormIds).toPromise();
    //   logoURLsForAllIds = apiData.body["response"];
    // } catch (error) {
    //   console.log("Failed to fetch logo URLs for all ids", error);
    // } 
    
    //Get all the forms which has business units.
    const allConsolForms = this.allCompanyValuationDates.filter(comp => comp.businessUnits)
    let buSummaryAPIData = null;

    this.businessUnitsValSummaryForAllIds = { summary: {}, currency: {} } as any;

    try {
      const allConsolFormsIds = allConsolForms.map( comp => comp.id);
      const apiData = await this.dataService.getBUSummaryDataAndExchangeRates(allConsolFormsIds).toPromise();
      buSummaryAPIData = apiData.body['response'];

      this.businessUnitsValSummaryForAllIds.currency = buSummaryAPIData.currencyExchangeRates
    } catch (error) {
      console.log("Failed to fetch Consol Summary Data for all consol forms", error);
    }

    for(let consolForm of allConsolForms) {
      const consolFormId = consolForm.id;

      if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData && buSummaryAPIData.consolFormWiseData[consolFormId]){
        if(buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary){
          this.businessUnitsValSummaryForAllIds.summary[consolFormId] = buSummaryAPIData.consolFormWiseData[consolFormId].consolSummary;
        }

        if(buSummaryAPIData.consolFormWiseData[consolFormId].investment){
          consolForm.investment = buSummaryAPIData.consolFormWiseData[consolFormId].investment
        }
      } else if(buSummaryAPIData && buSummaryAPIData.consolFormWiseData){
        buSummaryAPIData.consolFormWiseData[consolFormId] = {

        }
      }

      // if(buSummaryAPIData && buSummaryAPIData.body["response"][consolFormId]) {
      //   this.businessUnitsValSummaryForAllIds[consolFormId] = JSON.parse(buSummaryAPIData.body["response"][consolFormId]);
      // }

      // this.initBU_ConsolSummary(consolForm);
    }

    filteredInvestmentForms.map((comp) =>{

      const tableObj = {
        id:'',
        logo: '',
        companyName: '',
        investmentDate: '',
        geography:'',
        sector: '',
        security: '',
        type: '',
        version: 0,
        currency: comp.currency,
        exchangeRate: null,
        fairVal: 0,
        stake: 0,
        stakeVal: 0,
        shareHoldersReturn: 0,
        prevStakeValue: 0,
        investmentAmount: 0,
        realisedProceeds: 0,
        moic: 0,
        grossIRR: 0,
        totalValue: 0,
        changeInValPercentage: 0,
        changeInStakeValue: 0,
        userEntered: null,
        latestValuationDate: '',
        valuationDate:'',
        valuation: 0,
        isBusinessUnit: false,
        versionName: comp.versionName
      }
      
      try {
        tableObj.id = comp.id;

        tableObj.isBusinessUnit = comp.businessUnits ? true : false;

        const domain = this.utilService.getDomainName(comp.website);

        // if(logoURLsForAllIds[comp.id]){
        //   tableObj.logo = logoURLsForAllIds[comp.id];
        // }
        // else{
        //   tableObj.logo = comp.website ? "https://logo.allindigital.se/" +  domain: '';
        // }

        tableObj.logo = comp.logo;
  
        if(domain === "m2i-lifesciences.com") {
          tableObj.logo = "https://tse2.mm.bing.net/th?id=OIP.BLfIrnaEoqmVENZo7KF-owHaHa&pid=Api&P=0&w=300&h=300"
        }
  
        tableObj.companyName = comp.companyName;
        tableObj.investmentDate = comp.valuationDate;
        tableObj.geography = comp.geography ? comp.geography : null;
        tableObj.valuationDate = comp.valuationDate;
        tableObj.sector = comp.sector ? comp.sector: null;
        tableObj.type = comp.formType;
        tableObj.version = comp.formVersion;
        tableObj.security = comp.security;
        tableObj.userEntered = comp.userEntered;
        
        tableObj.valuation = (comp.investment? comp.investment.equityValue.finalAdjustedEquityVal : 0);
        
        let valuationDateForms = this.getSelectedCompanyDates(comp.id)
        
        if(valuationDateForms.length > 1){
          valuationDateForms = valuationDateForms.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
          }).filter(f => f.status !== "Initiated" && !f.userEntered);
        }
              
        if(valuationDateForms.length >= 2){
          const latestCompanyForm = valuationDateForms[0]; // .find(comp => !comp.userEntered);
          tableObj.latestValuationDate = latestCompanyForm.valuationDate;
  
          // if(latestCompanyForm.businessUnits) {
          //   this.initBU_ConsolSummary(latestCompanyForm);
          // }
  
          tableObj.currency = latestCompanyForm.investment ? latestCompanyForm.investment.currency : "";
  
          tableObj.fairVal = latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.finalAdjustedEquityVal : 0;
          tableObj.stake = latestCompanyForm.investment ? latestCompanyForm.investment.equityValue.stake : 0;
          
          if(latestCompanyForm.investment) {
            tableObj.stakeVal = latestCompanyForm.investment.equityValue.finalStakeValue 
                ? latestCompanyForm.investment.equityValue.finalStakeValue
                : latestCompanyForm.investment.equityValue.stakeValue ? latestCompanyForm.investment.equityValue.stakeValue : 0;
          }  
          
          // if(valuationDateForms[1].businessUnits) {
            
          //   this.initBU_ConsolSummary(valuationDateForms[1]);
          // }
  
          if(valuationDateForms[1].investment) {
            tableObj.prevStakeValue = valuationDateForms[1].investment.equityValue.finalStakeValue 
                ? valuationDateForms[1].investment.equityValue.finalStakeValue
                : valuationDateForms[1].investment.equityValue.stakeValue ? valuationDateForms[1].investment.equityValue.stakeValue : 0;
          }
  
          const changeInVal = (tableObj.stakeVal) - (tableObj.prevStakeValue)
          let changeInValPercentage = 0;
          if(tableObj.prevStakeValue > 0){
            changeInValPercentage = changeInVal / tableObj.prevStakeValue;
          }
          else{
            changeInValPercentage = 0;
          }
  
          tableObj.changeInStakeValue = +(changeInVal);
          tableObj.changeInValPercentage = isNaN(changeInValPercentage)? 0 : changeInValPercentage;
          if(comp.investment){
            comp.investment.equityValue.changeInEquityVal = tableObj.changeInValPercentage;
          }
        }
        else if(valuationDateForms.length == 1){
          // this.initBU_ConsolSummary(valuationDateForms[0]);
          
          tableObj.currency = valuationDateForms[0].investment ? valuationDateForms[0].investment.currency : "";
          tableObj.latestValuationDate = valuationDateForms[0].valuationDate;
  
          tableObj.fairVal = valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.finalAdjustedEquityVal : 0;
          tableObj.stake = valuationDateForms[0].investment ? valuationDateForms[0].investment.equityValue.stake : 0;
          
          tableObj.prevStakeValue = comp.investment ? comp.investment.equityValue.stakeValue : 0
          
          if(valuationDateForms[0].investment) {
            tableObj.stakeVal = valuationDateForms[0].investment.equityValue.finalStakeValue 
                ? valuationDateForms[0].investment.equityValue.finalStakeValue
                : valuationDateForms[0].investment.equityValue.stakeValue ? valuationDateForms[0].investment.equityValue.stakeValue : 0;
          }
  
          const changeInVal = (tableObj.stakeVal) - (tableObj.prevStakeValue);
          const changeInValPercentage = 1;
          
          tableObj.changeInStakeValue = +(changeInVal);
          tableObj.changeInValPercentage = isNaN(changeInValPercentage)? 0 : changeInValPercentage;
          if(comp.investment){
            comp.investment.equityValue.changeInEquityVal = tableObj.changeInValPercentage;
          }
        }
        
        let inv = 0;
        let rp = 0;
  
        const selectedFund = this.fundService.getFundById(comp.fundCompany);
  
        
        const validTransactions = this.getValidTransactionsForSelectedFund(comp.id, selectedFund.name); //to fetch transactions based on buyer and seller and latest val date
  
        if(validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0){
          validTransactions["multipleRealisedProceeds"].forEach( data => {
            rp += data.convertedValue;
          })
        }
  
        if(validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0){
          validTransactions["multipleInvestmentAmount"].forEach( data => {
            inv += data.convertedValue;
          })
        }
  
        // this.transactionData.forEach( data => {
        //   if(data.companyId == comp.id && data.Buyer && data.Buyer.toLowerCase() === selectedFund.name.toLowerCase()){
        //     if(data.Type.toLowerCase() == "investment" ){
        //       inv += data.convertedValue;
        //     }
        //     else if(data.Type.toLowerCase() == "realised proceeds" ){
        //       rp += data.convertedValue;
        //     }
        //   }
        // })
        tableObj.investmentAmount = inv;
        tableObj.realisedProceeds = rp;
        tableObj.moic = comp.moic? comp.moic : 0;
        tableObj.grossIRR = comp.irr? comp.irr : 0;
        
        tableObj.shareHoldersReturn = 
          tableObj.investmentAmount > 0 
          ? ((tableObj.stakeVal - tableObj.investmentAmount + tableObj.realisedProceeds)/tableObj.investmentAmount) * 100 
          : 0;
  
        tableObj.totalValue = tableObj.stakeVal + tableObj.realisedProceeds;
  
        // this.currExchangeService.initExchangeRates(tableObj.currency, this.selectedCurrency, tableObj.latestValuationDate, tableObj, 'exchangeRate')
        
        this.portfolioData.push(tableObj);
      } catch (error) {
        console.log("Failed to add the company ", comp.companyName)
      }
    })

    this.portfolioData.sort((f1, f2)=>{
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date > f2Date? -1: 1;
    })

    this.portfolioUpdated.next();

    // console.log("############# portfolioData", this.portfolioData);

    this.initExchangeRate();

    this.portfolioDataClone = cloneDeep(this.portfolioData);

    this.searchedForms = this.portfolioData;

    this.prepareCompaniesClone(filteredInvestmentForms, this.portfolioDataClone);

    // this.calculateTotal();
  }

  initBU_ConsolSummary(latestCompanyForm) {    
    const enterpriseValue = this.getBusinessUnitParameterValue2("enterpriseValue", latestCompanyForm);
    const investment = {
      currency : latestCompanyForm.currency,
      enterpriseValue: enterpriseValue,

      equityValue: {
        weightedEquityValue: enterpriseValue,
        finalAdjustedEquityVal: enterpriseValue,
        stake: 100,
        finalStakeValue: enterpriseValue,
        stakeValue: enterpriseValue,
      }
    }

    let buValuationSummary = {
      currency:latestCompanyForm.investment && latestCompanyForm.investment.currency,
      sumOfTheParts: enterpriseValue,
      sumOfPartsAdj: null,
      weightedEquityValue: enterpriseValue,
      adjustmentsOnWeightedEquityValue: null,

      selectedTypeOfStakeValue: 'fullyDilutedShares',

      finalAdjustedEquityVal: enterpriseValue,
      stake: 100,
      stakeValue: enterpriseValue,
      finalAdjustments: null,
      finalStakeValue: enterpriseValue,
      numberOfShares: 0,
      sharePrice: 0,
      sharePriceFormatted: 0,
  
      customStake: {
        stakeFromModel : { value: 0 },
        manualEntry : { value: 100 }, 
        selectedOption: "manualEntry",
        dilutedStake: {value:100},
        impliedStakeWaterfall: {value:100},
        impliedStakeManpack: {value:100, exists: false},
        impliedStakeManOption: {value:100, exists: false},
      }
    }

    buValuationSummary = this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id];

    investment.equityValue.stake = buValuationSummary.stake;

    const equityAdjustments = buValuationSummary.sumOfPartsAdj;
    if(equityAdjustments) {
      const weightedEquityValue = this.getConsolAdjustedValue(investment.enterpriseValue, equityAdjustments);
      investment.equityValue.weightedEquityValue = weightedEquityValue;
      investment.equityValue.finalAdjustedEquityVal = weightedEquityValue;
    }

    const adjustmentsOnWeightedEquityValue = buValuationSummary.adjustmentsOnWeightedEquityValue;
    if(adjustmentsOnWeightedEquityValue) {
      investment.equityValue.finalAdjustedEquityVal = this.getConsolAdjustedValue(investment.equityValue.weightedEquityValue, adjustmentsOnWeightedEquityValue);
    }
    
    if(buValuationSummary.selectedTypeOfStakeValue == "fromWaterFallSummary") {
      // In case of `Waterfall Summary`, the stake value comes from waterfall model
      investment.equityValue.stakeValue = buValuationSummary.stakeValue;

    } else {

      const stakeValue = investment.equityValue.finalAdjustedEquityVal
          * investment.equityValue.stake
          / 100;

      investment.equityValue.stakeValue = stakeValue;
    }

    investment.equityValue.finalStakeValue = investment.equityValue.stakeValue;

    const finalAdjustments = buValuationSummary.finalAdjustments;
    if(finalAdjustments) {
      investment.equityValue.finalStakeValue = this.getConsolAdjustedValue(investment.equityValue.stakeValue, finalAdjustments);
    } 

    if(buValuationSummary.sumOfTheParts == 0) {    
      investment.equityValue.finalAdjustedEquityVal = 0;
      investment.equityValue.stakeValue = 0;
      investment.equityValue.finalStakeValue = 0;
    }

    latestCompanyForm.investment = investment;
    
    buValuationSummary.weightedEquityValue = investment.equityValue.weightedEquityValue;
    buValuationSummary.finalAdjustedEquityVal = investment.equityValue.finalAdjustedEquityVal;
    buValuationSummary.stakeValue = investment.equityValue.stakeValue;
    buValuationSummary.finalStakeValue = investment.equityValue.finalStakeValue;

    if(buValuationSummary.numberOfShares > 0) {
      buValuationSummary.sharePrice = (buValuationSummary.finalStakeValue * 1000000) / buValuationSummary.numberOfShares;
    }

    this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id] = cloneDeep(buValuationSummary);
  }

  
  getConsolAdjustedValue(startValue, adjustments) {
    let adjustedValue = startValue;

    if(adjustments) {
      adjustments.forEach(part => {
        if (part.type == "Add") {
          adjustedValue = adjustedValue + (part.convertedValue || part.value)
        }
        else {
          adjustedValue = adjustedValue - (part.convertedValue || part.value)
        }
      });
    }

    return adjustedValue;
  }

  getBusinessUnitParameterValue(parameter, businessUnits) {
    let total = 0
    
    businessUnits.forEach( (bu) => {
      if(bu.investment) {
        total += bu.investment.equityValue[parameter]
      }
    })

    return total;
  }

  getBusinessUnitParameterValue2(parameter, consolForm) {
    let total = 0
    
    consolForm.businessUnits.forEach( (bu) => {
      if(bu.investment) {
        const currencyCode = bu.investment.currency + consolForm.investment.currency + consolForm.valuationDate;
        // Example currency code USDEUR2021-03-31

        let exchnageRate = this.businessUnitsValSummaryForAllIds.currency[currencyCode];

        if(!exchnageRate) {
          exchnageRate = 1;
        }

        total += this.utilService.getValidNumber(bu.investment[parameter]) * exchnageRate;
      }
    })

    return total;
  }

  async initExchangeRate() {
    this.totalPortfolioData['loading'] = true;

    const reqBody = {
      "rateCapturedForId": this.selectedFundId,
      "rateCapturedFor": "FUND",
      "module": "EQUITY",
    }
    const input = [];

    //Conversion from transaction currency to user selected currency WRT transaction date
    this.transactionData.forEach( data => {
      input.push({
        date: (moment(data.Date)).format("YYYY-MM-DD"),
        source_currency: data.Currency,
        target_currency: this.selectedCurrency,
        type: "TRANSACTIONS"
      })
    })

    this.portfolioData.forEach((comp) => {
      const userDetails:any = localStorage.getItem("73s-selected-vc");
      if(comp.currency){
        input.push({
          date: comp.latestValuationDate,
          source_currency: comp.currency,
          target_currency: this.selectedCurrency,
          type: "VALUATION_DATE",
          orgId:userDetails.organization.orgId,
        })
      }
    });

    reqBody["input"] = input;

    await this.currExchangeService.initExchangeRates(reqBody)

    this.portfolioData.forEach( comp => {

      // else {
        if(this.currExchangeService.exchangeRates[comp.currency + this.selectedCurrency + comp.latestValuationDate]){
          const currencyKey = comp.currency + this.selectedCurrency + comp.latestValuationDate;
          
          comp['exchangeRate'] = this.currExchangeService.exchangeRates[currencyKey] 
            ? this.currExchangeService.exchangeRates[currencyKey] 
            : 1;
        }
      // }
    })

    this.exchangeRateUpdated.next()

    await this.calculateIRRForAllCompanies();

    this.searchedForms = this.portfolioData;

    //Added code to save the selected currency to widget
    let portfolioDataReqBody = {
      selectedCurrency: this.selectedCurrency,
      valuationMap: this.mapData.value
    };
    this.dataService.saveWidgetDataToDB("PORTFOLIO_SUMMARY_DATA", JSON.stringify(portfolioDataReqBody), this.selectedFundId).subscribe(res=>{
      // console.log("Selected portfolio data saved to widget", res.body);
      // console.log("-----------------Selected portfolio data saved to widget", res.body);
    }, error=>{
      console.log("Failed to save Selected portfolio data to db");
    })
    
    
    this.mapChart = this.prepareMapData();
    this.columnChart = this.prepareColumnChart();
    this.isCompaniesAvailable.next(true)
  }

  //selectedForm is of companies array which consists of details key
  //portfolioComp is of portfolio data which consists of exchnageRate
  async initCalculationMultiples(){ 
    this.totalPortfolioData["totalIRRLoading"] = true;
       
    let selectedCompanyDates = this.getSelectedCompanyDates(this.companyId);

    selectedCompanyDates = selectedCompanyDates.sort((f1, f2) => {
      const f1Date = new Date(f1.valuationDate);
      const f2Date = new Date(f2.valuationDate);
      return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
    }).filter(f => f.status !== "Initiated" && !f.userEntered);;
    
    this.selectedForm = this.companies.filter(comp=> comp.id === this.companyId);    
    let portfolioComp = this.portfolioData.find( comp => comp.id === this.companyId);

    // this.calculateMultiples(this.selectedForm[0], portfolioComp, selectedCompanyDates[0], (this.portfolioData.length - 1));

    await this.calculatingIRRMOICWithNewSource(portfolioComp, selectedCompanyDates[0], (this.portfolioData.length - 1));
    this.searchedForms = this.portfolioData;
  }

  calculateIRRForAllCompanies(){
    const consolidatedReqBodyForSelectedCurrency = [];
    const consolidatedReqBodyForValuationCurrency = [];

    for(let index=0; index < this.portfolioData.length; index++) {
      let pComp = this.portfolioData[index];
      try {

        let selectedCompanyDates = this.getSelectedCompanyDates(pComp.id)
        selectedCompanyDates = selectedCompanyDates.sort((f1, f2) => {
          const f1Date = new Date(f1.valuationDate);
          const f2Date = new Date(f2.valuationDate);
          return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
        }).filter(f => f.status !== "Initiated" && !f.userEntered);
        
        // const selectedForm = this.companies.find(comp=> comp.id === pComp.id && !comp.groupFormId);
        
        // this.formDetailsObj.totalInvestmentAmount = selectedForm.totalInvestmentAmount;
  
        // this.formDetailsObj.totalRealisedProceeds = selectedForm.totalRealisedProceeds;
        // this.formDetailsObj.multipleInvestmentAmount = selectedForm.multipleInvestmentAmount;
        // this.formDetailsObj.multipleRealisedProceeds = selectedForm.multipleRealisedProceeds;
  
        pComp['loading'] = true;
  
        // this.calculateMultiples(selectedForm, pComp, selectedCompanyDates[0], true, index);
  
        //new Source for MOIC IRR Calculation
        // await this.calculatingIRRMOICWithNewSource(pComp, selectedCompanyDates[0], index);
        const reqBody = this.createConsolidatedRequestBodyForIRR(pComp, selectedCompanyDates[0], index);
        
        consolidatedReqBodyForSelectedCurrency.push(reqBody.reqBodyForIRRInSelectedCurrency);
        consolidatedReqBodyForValuationCurrency.push(reqBody.reqBodyForIRRInValuationCurrency);

      } catch (error) {
        console.log("Failed to calculate IRR MOIC for ", this.portfolioData[index], error)
      }
    }  

    this.fetchIRRWithConsolidatedReqBody(consolidatedReqBodyForSelectedCurrency, consolidatedReqBodyForValuationCurrency)
  }

  fetchIRRWithConsolidatedReqBody(consolidatedReqBodyForSelectedCurrency, consolidatedReqBodyForValuationCurrency){
    const reqBodyForSelectedCurrency = {
      "request_id": "IRR in Portfolio Currency",
      "data": consolidatedReqBodyForSelectedCurrency
    }
    //Selected Currency GROSS IRR
    this.dataService.getIRRForMultipleRequests(reqBodyForSelectedCurrency).subscribe(res => {
      console.log("Res", res.body["response"]["complete_processed_data"][0]);
      const compWiseData = res.body["response"]["complete_processed_data"][0];

      this.portfolioData.forEach(comp => {
        comp['loading'] = false;
        comp.grossIRR = compWiseData && compWiseData[comp.id] ? compWiseData[comp.id] : 0; 
      })

      this.portfolioDataClone = cloneDeep(this.portfolioData);
      this.calculateTotal();

    }, error => {
      console.log("Error", error);
      this.portfolioDataClone = cloneDeep(this.portfolioData);
      this.calculateTotal();
    })


    const reqBodyForValuationCurrency = {
      "request_id": "IRR in Valuation Currency",
      "data": consolidatedReqBodyForValuationCurrency
    }
    //Local Currency GROSS IRR
    this.dataService.getIRRForMultipleRequests(reqBodyForValuationCurrency).subscribe(res => {
      console.log("Res", res.body["response"]["complete_processed_data"][0]);
      const compWiseData = res.body["response"]["complete_processed_data"][0];

      this.portfolioData.forEach(comp => {
        comp.grossIRRLocalCurrency = compWiseData && compWiseData[comp.id] ? compWiseData[comp.id] : 0; 
      })


    }, error => {
      console.log("Error", error);
    })
  }

  createConsolidatedRequestBodyForIRR(portfolioComp, latestValuationDate, index?){
    const response = this.prepareMultiplesForIRRAndMoic(portfolioComp, latestValuationDate, true);

    portfolioComp.moic = response.moic ? response.moic : 0;
    // portfolioComp.grossIRR = response["IRR"] ? response["IRR"] : 0;
    portfolioComp.investmentAmount = response["totalInvestmentAmount"] ? response["totalInvestmentAmount"] : 0;
    portfolioComp.realisedProceeds = response["totalRealisedProceeds"] ? response["totalRealisedProceeds"] : 0;

    //For new table Where to show the value in Valution Currency
    portfolioComp.totalInvestmentAmountBeforeCurrency = response["totalInvestmentAmountBeforeCurrency"] ? response["totalInvestmentAmountBeforeCurrency"] : 0,
    portfolioComp.totalRealisedProceedsBeforeCurrency = response["totalRealisedProceedsBeforeCurrency"] ? response["totalRealisedProceedsBeforeCurrency"] : 0,
    portfolioComp.totalValue = (portfolioComp.stakeVal * portfolioComp.exchangeRate) + portfolioComp.realisedProceeds; // stake value converted to selcted currency & realised proceeds are in user selected currency;
    portfolioComp.totalValueBeforeConversion = portfolioComp.stakeVal + portfolioComp.totalRealisedProceedsBeforeCurrency

    // portfolioComp.grossIRRLocalCurrency = response["IRRInLocalCurrency"] ? response["IRRInLocalCurrency"] : 0;
    portfolioComp.moicBeforeConversion = response["moicBeforeConversion"] ? response["moicBeforeConversion"] : 0;

    const reqBodyForIRRInSelectedCurrency = {
      id: portfolioComp.id,
      data: response.reqBodyIRR.data
    }

    const reqBodyForIRRInValuationCurrency = {
      id: portfolioComp.id,
      data: response.reqBodyIRRForLocalCurrency.data
    }

    return {
      reqBodyForIRRInSelectedCurrency: reqBodyForIRRInSelectedCurrency,
      reqBodyForIRRInValuationCurrency: reqBodyForIRRInValuationCurrency
    }
  }

  async calculatingIRRMOICWithNewSource(portfolioComp, latestValuationDate, index?){

    if(!latestValuationDate) {
      portfolioComp['loading'] = false;
      if((index + 1) === this.portfolioData.length){
        this.calculateTotal();
      }
      return;
    }

    //true indicates to include porfolioCurrency
    const response = await this.prepareMultiplesForIRRAndMoic(portfolioComp, latestValuationDate, true);

    // console.log("IRR Request", portfolioComp.companyName, response);

    let IRR = 0;
    try {
      const apiResponse = await this.dataService.getIRR(response.reqBodyIRR).toPromise();
      IRR = apiResponse.body["response"].data.complete_processed_data;
      response["IRR"] = IRR;
      
      // console.log("IRR RESPONSE", portfolioComp.companyName, apiResponse.body["response"].data.complete_processed_data);

    } catch(e) {
      console.log("Failed to calculated IRR for -" + portfolioComp.companyName);
    }

    let IRRInLocalCurrency = 0;
    try {
      const apiResponse = await this.dataService.getIRR(response.reqBodyIRRForLocalCurrency).toPromise();
      IRRInLocalCurrency = apiResponse.body["response"].data.complete_processed_data;
      response["IRRInLocalCurrency"] = IRRInLocalCurrency;
      
      // console.log("IRR RESPONSE", portfolioComp.companyName, apiResponse.body["response"].data.complete_processed_data);

    } catch(e) {
      console.log("Failed to calculated IRR for -" + portfolioComp.companyName);
    }

    portfolioComp.moic = response.moic ? response.moic : 0;
    portfolioComp.grossIRR = response["IRR"] ? response["IRR"] : 0;
    portfolioComp.investmentAmount = response["totalInvestmentAmount"] ? response["totalInvestmentAmount"] : 0;
    portfolioComp.realisedProceeds = response["totalRealisedProceeds"] ? response["totalRealisedProceeds"] : 0;

    //For new table Where to show the value in Valution Currency
    portfolioComp.totalInvestmentAmountBeforeCurrency = response["totalInvestmentAmountBeforeCurrency"] ? response["totalInvestmentAmountBeforeCurrency"] : 0,
    portfolioComp.totalRealisedProceedsBeforeCurrency = response["totalRealisedProceedsBeforeCurrency"] ? response["totalRealisedProceedsBeforeCurrency"] : 0,
    portfolioComp.totalValue = (portfolioComp.stakeVal * portfolioComp.exchangeRate) + portfolioComp.realisedProceeds; // stake value converted to selcted currency & realised proceeds are in user selected currency;
    portfolioComp.totalValueBeforeConversion = portfolioComp.stakeVal + portfolioComp.totalRealisedProceedsBeforeCurrency

    portfolioComp.grossIRRLocalCurrency = response["IRRInLocalCurrency"] ? response["IRRInLocalCurrency"] : 0;
    portfolioComp.moicBeforeConversion = response["moicBeforeConversion"] ? response["moicBeforeConversion"] : 0;

    portfolioComp['loading'] = false;

    //Initiating the total Calculation on the last iteration
    if((index + 1) === this.portfolioData.length){
      this.calculateTotal();
    }

  }

  prepareMultiplesForIRRAndMoic(portfolioComp, latestValuationDate, includePortfolioCurreny, stakeValueToBeUsed?, investorName?, valDateFilter?){ // Investments

    let conversionMultiple = 1;
    if(includePortfolioCurreny){
      conversionMultiple = portfolioComp.exchangeRate? portfolioComp.exchangeRate : 1;
    }

    
    const selectedFund = investorName ? investorName : this.fundService.getFundById(latestValuationDate.fundCompany).name;

    const validTransactions = this.getValidTransactionsForSelectedFund(portfolioComp.id, selectedFund, valDateFilter); //to fetch transactions based on buyer and seller and latest val date
    
    let multipleInvestments = []
    
    if(validTransactions["multipleInvestmentAmount"] && validTransactions["multipleInvestmentAmount"].length > 0){
      multipleInvestments = validTransactions["multipleInvestmentAmount"].map( comp => {
        let finalConvertedValue = 0;
        //If user selected currency and Transaction Currency is same . take it from the Amount of Transaction Data
        if(this.selectedCurrency === comp.Currency){
          finalConvertedValue = comp.Amount
        }
        // if user selectedCurrency and transaction currency are different then calc exchnage rate from the transactionDate
        else{
          const exchangeRate = this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] 
                                ? this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] : 1
  
          finalConvertedValue = comp.Amount * exchangeRate; 
        }
        return { 
          date: new Date(comp.Date), 
          value: finalConvertedValue,  //
          Currency: comp.Currency,
          valueBeforeConversion: comp.convertedValue,//For new table Where to show the value in Valution Currency
          exchangeRate : this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] 
        }; //Temp fix for exchange rate
      });
  
    }
    

    let multipleRealisedProceeds = [];
    
    if(validTransactions["multipleRealisedProceeds"] && validTransactions["multipleRealisedProceeds"].length > 0){
      multipleRealisedProceeds = validTransactions["multipleRealisedProceeds"].map( comp => {
        let finalConvertedValue = 0;
        if(this.selectedCurrency === comp.Currency){
          finalConvertedValue = comp.Amount
        }
        // if user selectedCurrency and transaction currency are different then calc exchnage rate from the transactionDate
        else{
          const exchangeRate = this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] 
                                ? this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")] : 1
  
          finalConvertedValue = comp.Amount * exchangeRate; 
        }
        return { 
          date: new Date(comp.Date), 
          value: finalConvertedValue, 
          Currency: comp.Currency,
          valueBeforeConversion: comp.convertedValue, //For new table Where to show the value in Valution Currency
          exchangeRate : this.currExchangeService.exchangeRates[comp.Currency + "" + this.selectedCurrency + "" + (moment(comp.Date)).format("YYYY-MM-DD")]
        }; //Temp fix for exchange rate
      })
    }

    let stakeVal = 0;
    let stakeValArray = [];

    let stakeValArrayBeforeConversion = [];

    if(latestValuationDate.investment && latestValuationDate.investment.equityValue && !stakeValueToBeUsed){
      if(latestValuationDate.investment.equityValue.finalStakeValue) {
        stakeVal = latestValuationDate.investment.equityValue.finalStakeValue;
      } else {
        stakeVal = latestValuationDate.investment.equityValue.stakeValue;
      }
    }
    else if(stakeValueToBeUsed){
      stakeVal = stakeValueToBeUsed
    }

    stakeValArray.push({
      date: new Date(latestValuationDate.valuationDate), 
      value: this.utilService.getValidNumber(stakeVal) * conversionMultiple
    })

    stakeValArrayBeforeConversion.push({
      date: new Date(latestValuationDate.valuationDate), 
      valueBeforeConversion: this.utilService.getValidNumber(stakeVal)
    })

    let totalInvestmentAmount = 0;
    let totalInvestmentAmountBeforeCurrency = 0;

    multipleInvestments.forEach( comp => { 
      totalInvestmentAmount += comp.value ; 

      //For new table Where to show the value in Valution Currency
      totalInvestmentAmountBeforeCurrency += +comp.valueBeforeConversion // Value in Valuation Currency

      //comp.value is Converted to user Selected Currency WRT Trasaction Date
    })

    let totalRealisedProceeds = 0;
    let totalRealisedProceedsBeforeCurrency = 0;

    multipleRealisedProceeds.forEach( comp => {
      totalRealisedProceeds += comp.value; 
      
      //For new table Where to show the value in Valution Currency
      totalRealisedProceedsBeforeCurrency += +comp.valueBeforeConversion // Value in Valuation Currency

      //comp.value is Converted to user Selected Currency WRT Trasaction Date
    })

    const moic = totalInvestmentAmount > 0? ((stakeVal * conversionMultiple) + (totalRealisedProceeds)) / (totalInvestmentAmount) : 0;

    const moicBeforeConversion = totalInvestmentAmountBeforeCurrency > 0? ((stakeVal) + (totalRealisedProceedsBeforeCurrency)) / (totalInvestmentAmountBeforeCurrency) : 0;

    const rpArray = multipleRealisedProceeds.map( comp => {
      return {
          date : comp.date, 
          value: comp.value, //comp.value is Converted to user Selected Currency WRT Trasaction Date 
          Currency: comp.Currency,
          exchangeRate: comp.exchangeRate,
          valueBeforeConversion: comp.valueBeforeConversion
        } 
    })

    const invArray = multipleInvestments.map( comp => {
      return {
        date : comp.date, 
        value: (this.utilService.getValidNumber(comp.value) * -1), //comp.value is Converted to user Selected Currency WRT Trasaction Date 
        Currency: comp.Currency,
        exchangeRate: comp.exchangeRate,
        valueBeforeConversion: this.utilService.getValidNumber(comp.valueBeforeConversion) * -1
      }
    })

    const sortedMultiples =  stakeValArray.concat(invArray).concat(rpArray).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {return 1;}
      if (obj1.date < obj2.date) {return -1;}
      return 0;
    });

    //Multiples for IRR Calculation in local Currency Exchange;
    const sortedMultiplesForLocalCurrency =  stakeValArrayBeforeConversion.concat(invArray).concat(rpArray).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {return 1;}
      if (obj1.date < obj2.date) {return -1;}
      return 0;
    });

    const reqBodyIRR = {
      data: sortedMultiples.map( data => {
              return { date: (moment(data.date)).format("DD/MM/YY"),amount: data.value }
            }),
      request_id: portfolioComp.id
    }

    // Req Body for IRR for local Currency
    const reqBodyIRRForLocalCurrency = {
      data: sortedMultiplesForLocalCurrency.map( data => {
              return { date: (moment(data.date)).format("DD/MM/YY"),amount: data.valueBeforeConversion }
            }),
      request_id: portfolioComp.id
    }

    const response = {
      moic : moic,
      totalRealisedProceeds: totalRealisedProceeds,
      totalInvestmentAmount: totalInvestmentAmount,
      reqBodyIRR: reqBodyIRR,

      //For new table Where to show the value in Valution Currency
      totalInvestmentAmountBeforeCurrency: totalInvestmentAmountBeforeCurrency,
      totalRealisedProceedsBeforeCurrency: totalRealisedProceedsBeforeCurrency,
      reqBodyIRRForLocalCurrency: reqBodyIRRForLocalCurrency,
      stakeArrayBeforeConversion: stakeValArrayBeforeConversion,
      moicBeforeConversion: moicBeforeConversion,
      //For new table Where to show the value in Valution Currency

      multipleInvestmentAmount: invArray,
      multipleRealisedProceeds: rpArray,
      stakeArray: stakeValArray,
      companyName: portfolioComp.companyName,
      exchangeRate: portfolioComp.exchangeRate,
    }

    return response;
  }

  getValidTransactionsForSelectedFund(invFormId, selectedFundName, valDateFilter?){ // valDateFilter - specific date for filtering
    const transactions = {};

    const allValDateForms = this.getCompanyAllValuationDates(invFormId);

    if(allValDateForms.length == 0){
      return transactions;
    }
    
    let dateForTransactionsFilter = allValDateForms[0].valuationDate; // by default transactions are filtered by latest val date.

    if(valDateFilter){
      dateForTransactionsFilter = valDateFilter;
    }

    //Seller Matching for Realised Proceeds
    transactions["multipleRealisedProceeds"] = this.transactionData.filter( comp => {
      return invFormId == comp.companyId && comp.Type.toLowerCase() == "realised proceeds"
              && comp.Seller && comp.Seller.toLowerCase() === selectedFundName.toLowerCase();
    }).sort((f1, f2) => {
      const f1Date = new Date(f1.Date);
      const f2Date = new Date(f2.Date);
      return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    }).filter(f1 => { //filter trans wrt latest val date
      const valDate = new Date(dateForTransactionsFilter);
      const transDate = new Date(f1.Date);
      return transDate <= valDate;
    });

    //Buyer Matching for Investment Amount 
    transactions["multipleInvestmentAmount"] = this.transactionData.filter( comp => {
      return invFormId == comp.companyId && comp.Type.toLowerCase() == "investment"
                && comp.Buyer && comp.Buyer.toLowerCase() === selectedFundName.toLowerCase();
    }).sort((f1, f2) => {
      const f1Date = new Date(f1.Date);
      const f2Date = new Date(f2.Date);
      return f1Date === f2Date? 0: f1Date > f2Date? 1: -1;
    }).filter(f1 => { //filter trans wrt latest val date
      const valDate = new Date(dateForTransactionsFilter);
      const transDate = new Date(f1.Date);
      return transDate <= valDate;
    });

    return transactions;
  }

  //using the same method for Investment Summary moic and IRR calculation and recalculation of moic nd IRR on CurrencyDropDownChange
  //selectedForm is of companies array which consists of details key
  //portfolioComp is of portfolio data which consists of exchnageRate
  // async calculateMultiples(selectedForm, portfolioComp, latestCompanyDate, init?, index?){
  //   //Initiating the total Calculation on the last iteration
  //   if(!latestCompanyDate) {
  //     portfolioComp['loading'] = false;
  //     if((index + 1) === this.portfolioData.length){
  //       this.calculateTotal();
  //     }
  //     return;
  //   }

  // //  console.log("EXCHANGERATES", this.currExchangeService.exchangeRates)

  //   // let valuationForms = this.getSelectedCompanyDates(this.companyId); 

  //   // let stakeVal = 0;
  //   // if(valuationForms[0]['investment']){
  //   //   stakeVal = valuationForms[0]['investment'].equityValue.stakeValue;
  //   // }

  //   let conversionMultiple = portfolioComp.exchangeRate? portfolioComp.exchangeRate : 1;
  //   // exchnageRate

  //   // console.log(selectedForm.currency + '' + this.selectedCurrency + '' + selectedForm[0].valuationDate);
  //   // if(this.currExchangeService.exchangeRates[selectedForm[0].currency + '' + this.selectedCurrency + '' + selectedForm[0].valuationDate]){
  //   //   conversionMultiple = this.currExchangeService.exchangeRates[selectedForm[0].currency + '' + this.selectedCurrency + '' + selectedForm[0].valuationDate]
  //   // }
  //   // else{
  //   //   try {
  //   //     const res = await this.dataService.getValuationDateCurrency(selectedForm[0].currency, this.selectedCurrency, selectedForm[0].valuationDate).toPromise();
  //   //     conversionMultiple = res.body['response'].value;
  //   //   } 
  //   //   catch (error) {
  //   //     console.log("Failed to fetch Currency Exchnage Rates", error);
  //   //   }
  //   // }

    
  //   let stakeVal = 0;

  //   //Stake value is converted to selected currency
  //   if(latestCompanyDate.investment && latestCompanyDate.investment.equityValue){
  //     if(latestCompanyDate.investment.equityValue.finalStakeValue) {
  //       stakeVal = latestCompanyDate.investment.equityValue.finalStakeValue * conversionMultiple;
  //     } else {
  //       stakeVal = latestCompanyDate.investment.equityValue.stakeValue * conversionMultiple
  //     }
  //   }
  //   let stakeValArray = [];

  //   stakeValArray.push({
  //     date: new Date(latestCompanyDate.valuationDate), 
  //     value: stakeVal
  //   })

  //   selectedForm.totalRealisedProceeds = selectedForm.totalRealisedProceeds ? selectedForm.totalRealisedProceeds : 0;

  //   console.log("-----------------------------------")

    
  //   // selectedForm.multipleInvestmentAmount.forEach( arr => arr.value = arr.value * conversionMultiple);
    
  //   let totalInvestmentAmount = 0;
  //   let investmentArray = cloneDeep(selectedForm.multipleInvestmentAmount);
  //   investmentArray = investmentArray.map(arr => {
  //     totalInvestmentAmount += this.utilService.getValidNumber(arr.value) * conversionMultiple;

  //     arr.value = -1 * this.utilService.getValidNumber(arr.value) * conversionMultiple;
  //     return arr;
  //   })
    
  //   let multiplesArray;
  //   let totalRealisedProceeds = 0;
    
  //   if(selectedForm.multipleRealisedProceeds && selectedForm.multipleRealisedProceeds.length > 0){

  //     console.log("IRR realisedProceedsArray", selectedForm.multipleRealisedProceeds);
    
  //     let realisedProceedsArray = cloneDeep(selectedForm.multipleRealisedProceeds)
      
  //     //converting realisedProceeds amount into actual value using exchange rate

  //     realisedProceedsArray.forEach( arr => {
  //       arr.value = arr.value * conversionMultiple

  //       totalRealisedProceeds += arr.value;
  //     });

  //     multiplesArray =  realisedProceedsArray.concat(investmentArray).concat(stakeValArray).sort((obj1, obj2) => {
  //       if (obj1.date > obj2.date) {return 1;}
  //       if (obj1.date < obj2.date) {return -1;}
  //       return 0;
  //     });
  //   }
  //   else{
  //     multiplesArray =  investmentArray.concat(stakeValArray).sort((obj1, obj2) => {
  //       if (obj1.date > obj2.date) {return 1;}
  //       if (obj1.date < obj2.date) {return -1;}
  //       return 0;
  //     });
  //   }
    
  //   const irrApiReqBody = {
  //     data: multiplesArray.map( arr => {
  //             return { 
  //               date: (moment(arr.date)).format("DD/MM/YY"),
  //               amount: arr.value
  //             }
  //           }),
  //     request_id: selectedForm.id
  //   }
  //   let irrVal = 0;

  //   try {
  //     const irrValAPI = await this.dataService.getIRR(irrApiReqBody).toPromise();
  //     irrVal = irrValAPI.body["response"].data.complete_processed_data
  //   } catch(e) {

  //   }
    
  //   console.log("IRR Company Name", selectedForm.companyName)
  //   console.log("INDIVIDUAL IRR", irrVal, irrApiReqBody)

  //   selectedForm.irr = irrVal? irrVal : 0;

  //   selectedForm.details = cloneDeep(selectedForm.details);

  //   if(!init) {
  //     this.dataService.saveFormDetails(selectedForm.details).subscribe(data => {
  //       console.log("Data is Saved Successfully", data);
  //     }, error => {
  //       console.log("Failed to save the data", error);
  //     })
  //   }

  //   portfolioComp.investmentAmount = selectedForm.totalInvestmentAmount;
  //   portfolioComp.realisedProceeds = selectedForm.totalRealisedProceeds;
    
  //   //for moic caluculation
  //   selectedForm.moic = (totalRealisedProceeds + stakeVal)/ totalInvestmentAmount;

  //   console.log("Company MOIC: realisedProceeds", totalInvestmentAmount)
  //   console.log("Company MOIC: stakeVal", stakeVal)
  //   console.log("Company MOIC: investmentAmount", totalRealisedProceeds)

  //   portfolioComp.moic = isFinite(selectedForm.moic) ? selectedForm.moic : 0;


  //   console.log("Company MOIC:", portfolioComp.moic)


  //   portfolioComp.grossIRR = irrVal;
  //   portfolioComp['loading'] = false;

  //   // console.log("------------------- grossIRR portfolioComp", portfolioComp);
  //   // console.log("------------------- grossIRR selectedForm", selectedForm);

  //   //Initiating the total Calculation on the last iteration
  //   if((index + 1) === this.portfolioData.length){
  //     this.calculateTotal();
  //   }
  // }

  prepareMultiplesForIRR(investmentForm, portfolioComp, latestValuationDateForm, stakeValue){
    let conversionMultiple = portfolioComp.exchangeRate? portfolioComp.exchangeRate : 1;

    let stakeVal = stakeValue * conversionMultiple;
    //Stake value is converted to selected currency
    

    //creating stake Value Array
    let stakeValArray = [];

    stakeValArray.push({
      date: new Date(latestValuationDateForm.valuationDate), 
      value: stakeVal
    })


    investmentForm.totalRealisedProceeds = investmentForm.totalRealisedProceeds ? investmentForm.totalRealisedProceeds : 0;
    
    let totalInvestmentAmount = 0;
    let investmentArray = cloneDeep(investmentForm.multipleInvestmentAmount);

    //creating investment amount Array
    investmentArray = investmentArray.map(arr => {
      totalInvestmentAmount += this.utilService.getValidNumber(arr.value) * conversionMultiple;
      arr.value = -1 * this.utilService.getValidNumber(arr.value) * conversionMultiple;
      return arr;
    })


    let multiplesArray;
    let totalRealisedProceeds = 0;

    if(investmentForm.multipleRealisedProceeds && investmentForm.multipleRealisedProceeds.length > 0){
    
      let realisedProceedsArray = cloneDeep(investmentForm.multipleRealisedProceeds)
      
      //converting realisedProceeds amount into actual value using exchange rate

      realisedProceedsArray.forEach( arr => {
        arr.value = arr.value * conversionMultiple;
        totalRealisedProceeds += arr.value;
      });

      multiplesArray =  realisedProceedsArray.concat(investmentArray).concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }
    else{
      multiplesArray =  investmentArray.concat(stakeValArray).sort((obj1, obj2) => {
        if (obj1.date > obj2.date) {return 1;}
        if (obj1.date < obj2.date) {return -1;}
        return 0;
      });
    }

    // preparing reqBody for IRR API
    const reqBodyForIRR = {
      data: multiplesArray.map( arr => {
              return { 
                date: (moment(arr.date)).format("DD/MM/YY"),
                amount: arr.value
              }
            }),
      request_id: investmentForm.id
    }

    const moic = totalInvestmentAmount > 0 ? (totalRealisedProceeds + stakeVal)/ totalInvestmentAmount : 0;

    return { moic: moic, reqBody: reqBodyForIRR};
  }

  async calculateTotal(){

    this.totalPortfolioData = {
      totalInvestment : 0,
      totalRealisedProceeds : 0,
      totalMOIC : 0,
      totalIRR : 0,
      totalTotalValue: 0,
      totalChangeInValPercentage: 0,
      totalStakeVal: 0,
      totalFairVal: 0,
      totalPrevStakeVal: 0,
      totalShareHoldersReturn: 0,
      loading: true
    };

    this.getPortfolioDataTotal("fairVal", "totalFairVal");
    this.getPortfolioDataTotal("investmentAmount", "totalInvestment");
    this.getPortfolioDataTotal("realisedProceeds", "totalRealisedProceeds");
    this.getPortfolioDataTotal("stakeVal", "totalStakeVal");
    this.getPortfolioDataTotal("prevStakeValue", "totalPrevStakeVal");
    this.getPortfolioDataTotal("totalValue", "totalTotalValue");
    
    // this.totalPortfolioData.totalFairVal = this.getPortfolioDataTotal("fairVal").total;
    // this.totalPortfolioData.totalInvestment = this.getPortfolioDataTotal("investmentAmount").total;
    // this.totalPortfolioData.totalRealisedProceeds = this.getPortfolioDataTotal("realisedProceeds").total;
    // this.totalPortfolioData.totalStakeVal = this.getPortfolioDataTotal("stakeVal").total;
    // this.totalPortfolioData.totalPrevStakeVal = this.getPortfolioDataTotal("prevStakeValue").total;

    // console.log("totalRealisedProceeds", this.totalPortfolioData.totalRealisedProceeds);
    // console.log("totalStakeVal", this.totalPortfolioData.totalStakeVal);
    // console.log("totalInvestment", this.totalPortfolioData.totalInvestment);

    // console.log("this.totalPortfolioData[totalIRRLoading]", this.totalPortfolioData["totalIRRLoading"]);

    this.totalPortfolioData.totalChangeInValPercentage = (this.totalPortfolioData.totalStakeVal - this.totalPortfolioData.totalPrevStakeVal) / this.totalPortfolioData.totalPrevStakeVal
    const rp = this.totalPortfolioData.totalRealisedProceeds;
    const inv = this.totalPortfolioData.totalInvestment;
    const stakeVal = this.totalPortfolioData.totalStakeVal;
    this.totalPortfolioData.totalMOIC = inv > 0 ? (rp + stakeVal)/inv : 0;
    
    // console.log("Total MOIC ------------------------------", this.totalPortfolioData.totalMOIC)
    
    await this.getTotalIRR();
    
    setTimeout(() => {
      this.totalPortfolioData["totalIRRLoading"] = false;
      this.summaryTableRefreshOnIRRUpdate.next();
    })

    this.totalPortfolioData.totalShareHoldersReturn = this.totalPortfolioData.totalInvestment > 0 ?
    (this.totalPortfolioData.totalStakeVal - this.totalPortfolioData.totalInvestment + this.totalPortfolioData.totalRealisedProceeds)/this.totalPortfolioData.totalInvestment : 0
  }

  async getTotalIRR(){
    let totalIRR = 0;
    let investmentArray = [];
    let realisedProceedsArray = [];
    let stakeArray = [];

    for(let i = 0; i < this.portfolioData.length; i++){
      const pComp = this.portfolioData[i];
      const comp = this.companies.find( c => c.id === pComp.id);
      if(comp){
        let valuationDateForms = this.getSelectedCompanyDates(comp.id);
        if(valuationDateForms.length > 1){
          valuationDateForms = valuationDateForms.sort((f1, f2) => {
            const f1Date = new Date(f1.valuationDate);
            const f2Date = new Date(f2.valuationDate);
            return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
          }).filter(f => f.status !== "Initiated");
        }
              
        const latestCompanyForm = valuationDateForms.find(comp => !comp.userEntered);
        if(!latestCompanyForm) return;

        const response = this.prepareMultiplesForIRRAndMoic(pComp, latestCompanyForm, true);

        investmentArray = investmentArray.concat(response.multipleInvestmentAmount);
        realisedProceedsArray = realisedProceedsArray.concat(response.multipleRealisedProceeds);
        stakeArray = stakeArray.concat(response.stakeArray);
      }
    }

    const totalMultiplesArray = investmentArray.concat(realisedProceedsArray).concat(stakeArray).sort((obj1, obj2) => {
      if (obj1.date > obj2.date) {return 1;}
      if (obj1.date < obj2.date) {return -1;}
      return 0;
    });
    // const cashFlow = totalMultiplesArray.map( t => t.value);
    // const dates = totalMultiplesArray.map( t => t.date);

    // const irr = this.xirrService.XIRR(cashFlow, dates, 0);
    
    const irrApiReqBody = {
      data: totalMultiplesArray.map( arr => {
              return { 
                date: (moment(arr.date)).format("DD/MM/YY"),
                amount: arr.value
              }
            }),
      request_id: (new Date()).getTime()
    }
    
    let irr = 0;

    // console.log("REQ Body For Total IRR", irrApiReqBody)
    try {
      const irrValAPI = await this.dataService.getIRR(irrApiReqBody).toPromise();
      irr = irrValAPI.body["response"].data.complete_processed_data
      // console.log("Calculated Total IRR", irr);
    } catch(e) {
      console.log("Error While Calculating Total IRR", e)
    }

    totalIRR = isNaN(irr) ? 0 : irr;

    this.totalPortfolioData.totalIRR = totalIRR;

    setTimeout(() => {
        this.totalPortfolioData['loading'] = false;
    });
  }

  getPortfolioDataTotal(parameter, totalParameter) {
    // const returnValue = { total: 0, validRowsLength: 0};

    let conversionMultiple = 1
      this.portfolioData.forEach(compData => {

        if(!isNaN(compData[parameter]) && isFinite(compData[parameter])) {
          // returnValue.total += +compData[parameter];
          // returnValue.validRowsLength ++;

          if(compData.exchangeRate){
            conversionMultiple = compData.exchangeRate
            //total value is already in user selected currency;
            this.totalPortfolioData[totalParameter] += (parameter == "investmentAmount" || parameter == "realisedProceeds" || parameter == "totalValue")  ? +(compData[parameter]) : (+(compData[parameter]) * conversionMultiple) //Temp fix for exchange rate
          }
          else if(this.currExchangeService.exchangeRates[compData.currency + '' + this.selectedCurrency + '' + compData.valuationDate]){
            //total value is already in user selected currency;
            conversionMultiple = this.currExchangeService.exchangeRates[compData.currency + '' + this.selectedCurrency + '' + compData.valuationDate]
            this.totalPortfolioData[totalParameter] += (parameter == "investmentAmount" || parameter == "realisedProceeds" || parameter == "totalValue")  ? +(compData[parameter]) : (+(compData[parameter]) * conversionMultiple)//Temp fix for exchange rate
          }
          // else{
          //   if(compData.currency){
          //     this.dataService.getValuationDateCurrency(compData.currency, this.selectedCurrency, compData.valuationDate).subscribe( res => {
          //       conversionMultiple = res.body['response'].value;
          //       this.totalPortfolioData[totalParameter] += (+(compData[parameter]) * conversionMultiple)
          //     }, error => {
          //       console.log("Failed to fetch the exchange rates", error);
          //     });
          //   }
          // }
        }
      });

      // return returnValue;
  }

  prepareCompaniesClone(filteredForms, portfolioDataClone){
    this.formsClone = cloneDeep(filteredForms);

    let filterInvestmentForms =this.formsClone.filter( comp => comp.investment );

    filterInvestmentForms.sort((comp1, comp2) => {
      return (comp2.investment.equityValue.changeInEquityVal - comp1.investment.equityValue.changeInEquityVal);
    })

    portfolioDataClone.sort((comp1, comp2) => {
      return comp2.stakeVal == comp1.stakeVal? 0 : (comp2.stakeVal > comp1.stakeVal)? 1 : -1;
    })

    this.topGainers = portfolioDataClone.filter( comp => comp.stakeVal >= 0 );
    if(this.topGainers && this.topGainers.length > 3) {
      this.topGainers = this.topGainers.splice(0, 3);
    } 
    
    this.topLosers = portfolioDataClone.filter( comp => comp.stakeVal < 0 );
    if(this.topLosers && this.topLosers.length > 3) {
      this.topLosers = this.topLosers.splice(this.topLosers.length - 3, 3);
    }
    if(this.topLosers.length == 0){
      // this.topLosers = portfolioDataClone.slice(portfolioDataClone.length - 3, 3).reverse();
      const length = this.portfolioDataClone.length;
      this.topLosers = [this.portfolioDataClone[length -1], this.portfolioDataClone[length -2], this.portfolioDataClone[length -3]];
    }
  }

  
  getSelectedCompanyOrgDetails(domain, description, portfolioComp) {
    this.dataService.getSelectedCompanyOrgDetails(domain).subscribe((fundCompanyDetails) => {
      this.fundDetails = fundCompanyDetails.body["response"];
      if(!this.fundDetails.organization) {
        this.fundDetails.organization = {}
      }      

      this.fundDetails.organization.logo_url = null;
      
    }, error=>{
      console.log("Error: While fetching fund company details.", error);
      this.fundDetails = {
        organization: { 
          logo_url: "",
          name: "",
          city: "",
          state: "",
          country: "",
          industry: "",
          estimated_num_employees: 0,
          website_url: ""
        },
        people: []

      }
    })
  }

  getSelectedCompanyOrgDetails2(domain, description) {
    this.orgKeyWords = [];
    let requestBody = {
      uuid: "Test",
      isNewApi: true,
      text: description,
      domain: domain,
      companyId: this.companyId
    };
    this.dataService.getSelectedCompanyOrgDetails(requestBody).subscribe((result) => {
      let orgDetailsAndKeywordsResponse = result.body["response"];    
      if(orgDetailsAndKeywordsResponse.widgetData){
        this.fundDetails = orgDetailsAndKeywordsResponse.widgetData;
      }
      else{
        this.orgKeyWords = orgDetailsAndKeywordsResponse.keywords.map(n => n.tag.replaceAll("_", " "));
        this.fundDetails = orgDetailsAndKeywordsResponse.organizationDetailsNode;
      }
    }, error => {
      console.log("Error: While fetching OrgDetails & Keywords", error);
      this.fundDetails = {
        organization: {
          logo_url: "",
          name: "",
          city: "",
          state: "",
          country: "",
          industry: "",
          estimated_num_employees: 0,
          website_url: ""
        },
        people: []

      }
    })
  }

  openValuation(comp, additionalParams="") {
    //Ignore if it is user added old valuation date
    if(!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if(comp.businessUnits) return;

    const version = comp.formVersion; //this.ums.getApplicationVersion() + "";

    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version);
    localStorage.setItem('qubit-val-date', comp.valuationDate);
    localStorage.setItem('qubit-investment-date-id', this.companyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName=comp.versionName;
    let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName;
    if(comp.consolForm && this.companies && this.companies.length > 0) {
      const consolForm = this.companies.find( f => f.id == comp.consolForm);
      urlParameters += "&consol=" + consolForm.companyNameInForm;
    }
    this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, "Investment Summary");
    let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

    if(comp.approvalRequestId) {
      url += "&aid="+comp.approvalRequestId;
    }

    if(additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self")
  }

  openPortal(portalName){
    if(portalName === 'dashboard') {
      window.open(environment.portalUrl + "/dashboard/#/dashboard-home");
    } else if(portalName === 'approval-matrix') {
      window.open(environment.portalUrl + "/approval-matrix");
    }
  }

  
  sortParameter = "";
  sortOrder = "";

  sortPortfolioCompanies(sortParameter, dataType) {
    if(!this.searchedForms) return;
    
    if(this.sortParameter === sortParameter) {
      if(dataType === "string") {
        const stringEmptyValue = this.sortOrder === "DESC" ? "AAAA" : "ZZZZ"

        this.searchedForms = sortBy(this.searchedForms, (f) => {
          if(sortParameter == 'geography') {
            return f[sortParameter] && f[sortParameter].name ? f[sortParameter].name.toUpperCase(): stringEmptyValue;
          } else {
            return f[sortParameter] ? f[sortParameter].toUpperCase(): stringEmptyValue;
          }
        });

      } else {
        const numberEmptyValue = this.sortOrder === "DESC" ? Number.MAX_VALUE : Number.MIN_VALUE;

        this.searchedForms = sortBy(this.searchedForms, (f) => f[sortParameter] ? +f[sortParameter]: numberEmptyValue).reverse();
      }

      if(this.sortOrder === "DESC") {
        this.searchedForms = this.searchedForms.reverse();
        this.sortOrder = "ASC";
      } else {
        this.sortOrder = "DESC"
      }
      
    } else {
      if(dataType === "string") {
        this.searchedForms = sortBy(this.searchedForms, (f) => {
          if(sortParameter == 'geography') {
            return f[sortParameter] && f[sortParameter].name ? f[sortParameter].name.toUpperCase(): "ZZZZZ";
          } else {
            return f[sortParameter] ? f[sortParameter].toUpperCase(): "ZZZZZ";
          }
        });

        this.sortOrder = "ASC";
      } else {

        this.searchedForms = sortBy(this.searchedForms, (f) => f[sortParameter] ? +f[sortParameter]: Number.MIN_VALUE).reverse();
        this.sortOrder = "DESC";
      }
    }

    this.sortParameter = sortParameter;
  }

  currencyList = [
    { id: "USD", country: "United States Dollar" },
    { id: "GBP", country: "British Pound Sterling" },
    { id: "EUR", country: "Euro" },
    { id: "CHF", country: "Swiss Franc" },
    { id: "AED", country: "United Arab Emirates Dirham" },
    { id: "AFN", country: "Afghan Afghani" },
    { id: "ALL", country: "Albanian Lek" },
    { id: "AMD", country: "Armenian Dram" },
    { id: "ANG", country: "Netherlands Antillean Guilder" },
    { id: "AOA", country: "Angolan Kwanza" },
    { id: "ARS", country: "Argentine Peso" },
    { id: "AUD", country: "Australian Dollar" },
    { id: "AWG", country: "Aruban Florin" },
    { id: "AZN", country: "Azerbaijani Manat" },
    { id: "BAM", country: "Bosnia-Herzegovina Convertible Mark" },
    { id: "BBD", country: "Barbadian Dollar" },
    { id: "BDT", country: "Bangladeshi Taka" },
    { id: "BGN", country: "Bulgarian Lev" },
    { id: "BHD", country: "Bahraini Dinar" },
    { id: "BIF", country: "Burundian Franc" },
    { id: "BMD", country: "Bermudan Dollar" },
    { id: "BND", country: "Brunei Dollar" },
    { id: "BOB", country: "Bolivian Boliviano" },
    { id: "BRL", country: "Brazilian Real" },
    { id: "BSD", country: "Bahamian Dollar" },
    { id: "BTC", country: "Bitcoin" },
    { id: "BTN", country: "Bhutanese Ngultrum" },
    { id: "BWP", country: "Botswanan Pula" },
    { id: "BYN", country: "Belarusian Ruble" },
    { id: "BYR", country: "Belarusian Ruble" },
    { id: "BZD", country: "Belize Dollar" },
    { id: "CAD", country: "Canadian Dollar" },
    { id: "CDF", country: "Congolese Franc" },
    { id: "CLF", country: "Chilean Unit of Account (UF)" },
    { id: "CLP", country: "Chilean Peso" },
    { id: "CNY", country: "Chinese Yuan" },
    { id: "COP", country: "Colombian Peso" },
    { id: "CRC", country: "Costa Rican Colón" },
    { id: "CUC", country: "Cuban Convertible Peso" },
    { id: "CUP", country: "Cuban Peso" },
    { id: "CVE", country: "Cape Verdean Escudo" },
    { id: "CZK", country: "Czech Republic Koruna" },
    { id: "DJF", country: "Djiboutian Franc" },
    { id: "DKK", country: "Danish Krone" },
    { id: "DOP", country: "Dominican Peso" },
    { id: "DZD", country: "Algerian Dinar" },
    { id: "EEK", country: "Estonian Kroon" },
    { id: "EGP", country: "Egyptian Pound" },
    { id: "ERN", country: "Eritrean Nakfa" },
    { id: "ETB", country: "Ethiopian Birr" },
    { id: "FJD", country: "Fijian Dollar" },
    { id: "FKP", country: "Falkland Islands Pound" },
    { id: "GEL", country: "Georgian Lari" },
    { id: "GGP", country: "Guernsey Pound" },
    { id: "GHS", country: "Ghanaian Cedi" },
    { id: "GIP", country: "Gibraltar Pound" },
    { id: "GMD", country: "Gambian Dalasi" },
    { id: "GNF", country: "Guinean Franc" },
    { id: "GTQ", country: "Guatemalan Quetzal" },
    { id: "GYD", country: "Guyanaese Dollar" },
    { id: "HKD", country: "Hong Kong Dollar" },
    { id: "HNL", country: "Honduran Lempira" },
    { id: "HRK", country: "Croatian Kuna" },
    { id: "HTG", country: "Haitian Gourde" },
    { id: "HUF", country: "Hungarian Forint" },
    { id: "IDR", country: "Indonesian Rupiah" },
    { id: "ILS", country: "Israeli New Sheqel" },
    { id: "IMP", country: "Manx pound" },
    { id: "INR", country: "Indian Rupee" },
    { id: "IQD", country: "Iraqi Dinar" },
    { id: "IRR", country: "Iranian Rial" },
    { id: "ISK", country: "Icelandic Króna" },
    { id: "JEP", country: "Jersey Pound" },
    { id: "JMD", country: "Jamaican Dollar" },
    { id: "JOD", country: "Jordanian Dinar" },
    { id: "JPY", country: "Japanese Yen" },
    { id: "KES", country: "Kenyan Shilling" },
    { id: "KGS", country: "Kyrgystani Som" },
    { id: "KHR", country: "Cambodian Riel" },
    { id: "KMF", country: "Comorian Franc" },
    { id: "KPW", country: "North Korean Won" },
    { id: "KRW", country: "South Korean Won" },
    { id: "KWD", country: "Kuwaiti Dinar" },
    { id: "KYD", country: "Cayman Islands Dollar" },
    { id: "KZT", country: "Kazakhstani Tenge" },
    { id: "LAK", country: "Laotian Kip" },
    { id: "LBP", country: "Lebanese Pound" },
    { id: "LKR", country: "Sri Lankan Rupee" },
    { id: "LRD", country: "Liberian Dollar" },
    { id: "LSL", country: "Lesotho Loti" },
    { id: "LTL", country: "Lithuanian Litas" },
    { id: "LVL", country: "Latvian Lats" },
    { id: "LYD", country: "Libyan Dinar" },
    { id: "MAD", country: "Moroccan Dirham" },
    { id: "MDL", country: "Moldovan Leu" },
    { id: "MGA", country: "Malagasy Ariary" },
    { id: "MKD", country: "Macedonian Denar" },
    { id: "MMK", country: "Myanma Kyat" },
    { id: "MNT", country: "Mongolian Tugrik" },
    { id: "MOP", country: "Macanese Pataca" },
    { id: "MRO", country: "Mauritanian Ouguiya" },
    { id: "MUR", country: "Mauritian Rupee" },
    { id: "MVR", country: "Maldivian Rufiyaa" },
    { id: "MWK", country: "Malawian Kwacha" },
    { id: "MXN", country: "Mexican Peso" },
    { id: "MYR", country: "Malaysian Ringgit" },
    { id: "MZN", country: "Mozambican Metical" },
    { id: "NAD", country: "Namibian Dollar" },
    { id: "NGN", country: "Nigerian Naira" },
    { id: "NIO", country: "Nicaraguan Córdoba" },
    { id: "NOK", country: "Norwegian Krone" },
    { id: "NPR", country: "Nepalese Rupee" },
    { id: "NZD", country: "New Zealand Dollar" },
    { id: "OMR", country: "Omani Rial" },
    { id: "PAB", country: "Panamanian Balboa" },
    { id: "PEN", country: "Peruvian Nuevo Sol" },
    { id: "PGK", country: "Papua New Guinean Kina" },
    { id: "PHP", country: "Philippine Peso" },
    { id: "PKR", country: "Pakistani Rupee" },
    { id: "PLN", country: "Polish Zloty" },
    { id: "PYG", country: "Paraguayan Guarani" },
    { id: "QAR", country: "Qatari Rial" },
    { id: "RON", country: "Romanian Leu" },
    { id: "RSD", country: "Serbian Dinar" },
    { id: "RUB", country: "Russian Ruble" },
    { id: "RWF", country: "Rwandan Franc" },
    { id: "SAR", country: "Saudi Riyal" },
    { id: "SBD", country: "Solomon Islands Dollar" },
    { id: "SCR", country: "Seychellois Rupee" },
    { id: "SDG", country: "Sudanese Pound" },
    { id: "SEK", country: "Swedish Krona" },
    { id: "SGD", country: "Singapore Dollar" },
    { id: "SHP", country: "Saint Helena Pound" },
    { id: "SLL", country: "Sierra Leonean Leone" },
    { id: "SOS", country: "Somali Shilling" },
    { id: "SRD", country: "Surinamese Dollar" },
    { id: "STD", country: "São Tomé and Príncipe Dobra" },
    { id: "SVC", country: "Salvadoran Colón" },
    { id: "SYP", country: "Syrian Pound" },
    { id: "SZL", country: "Swazi Lilangeni" },
    { id: "THB", country: "Thai Baht" },
    { id: "TJS", country: "Tajikistani Somoni" },
    { id: "TMT", country: "Turkmenistani Manat" },
    { id: "TND", country: "Tunisian Dinar" },
    { id: "TOP", country: "Tongan Paʻanga" },
    { id: "TRY", country: "Turkish Lira" },
    { id: "TTD", country: "Trinidad and Tobago Dollar" },
    { id: "TWD", country: "New Taiwan Dollar" },
    { id: "TZS", country: "Tanzanian Shilling" },
    { id: "UAH", country: "Ukrainian Hryvnia" },
    { id: "UGX", country: "Ugandan Shilling" },
    { id: "UYU", country: "Uruguayan Peso" },
    { id: "UZS", country: "Uzbekistan Som" },
    { id: "VEF", country: "Venezuelan Bolívar Fuerte" },
    { id: "VND", country: "Vietnamese Dong" },
    { id: "VUV", country: "Vanuatu Vatu" },
    { id: "WST", country: "Samoan Tala" },
    { id: "XAF", country: "CFA Franc BEAC" },
    { id: "XAG", country: "Silver (troy ounce)" },
    { id: "XAU", country: "Gold (troy ounce)" },
    { id: "XCD", country: "East Caribbean Dollar" },
    { id: "XDR", country: "Special Drawing Rights" },
    { id: "XOF", country: "CFA Franc BCEAO" },
    { id: "XPF", country: "CFP Franc" },
    { id: "YER", country: "Yemeni Rial" },
    { id: "ZAR", country: "South African Rand" },
    { id: "ZMK", country: "Zambian Kwacha (pre-2013)" },
    { id: "ZMW", country: "Zambian Kwacha" },
    { id: "ZWL", country: "Zimbabwean Dollar" }
  ]

}