import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FundListService } from './qxi-fund-list.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';

@Component({
  selector: 'app-qxi-fund-list-ui',
  templateUrl: './qxi-fund-list-ui.component.html',
  styleUrls: ['./qxi-fund-list-ui.component.scss']
})
export class QXI_FundListUIComponent implements OnInit {
  myOrgUsers;
  fundName = "";
  cardSelectedForAssignment;

  constructor(private router: Router,     private um: UserManagementService, 
    public fundService: FundListService,  private modalService: NgbModal,
    private utilService: UtilService,     private ds: DataService) 
  { }

  ngOnInit() {
    const myDetails = this.um.getSelectedUserDetails();

    this.fundService.getFunds(myDetails);

    this.um.getUsers(myDetails.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(user => user.id !== myDetails.id);
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  userPopup(content, card) {
    this.resetUserAssignment();

    if(card.details && this.myOrgUsers && this.myOrgUsers.length > 0) {
      const assignees = JSON.parse(card.details).assignees;
      if(assignees) {
        this.myOrgUsers.forEach(user => {
          const assignedUser = assignees.find(u => u.id === user.id);
          
          user.assigned = !!assignedUser;
          user.permission = assignedUser ? assignedUser.permission : null;
        })
      }
    } else if(!this.myOrgUsers || this.myOrgUsers.length == 0){
      return;
    }

    this.cardSelectedForAssignment = card;
    
    this.modalService.open(content, { centered: true });
  }

  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.permission = null;
    })
  }

  addReport() {
    const myDetails = this.um.getSelectedUserDetails();
    this.fundService.createFund({type: "REPORT", name: this.fundName}, myDetails);
    this.modalService.dismissAll();
  }

  addFund() {
    const myDetails = this.um.getSelectedUserDetails();
    this.fundService.createFund({type: "FUND", name: this.fundName}, myDetails);
    this.modalService.dismissAll();
  }

  openPopup(content) {
    this.fundName = "";

    this.modalService.open(content, { centered: true , windowClass: 'create-new-fund'});
  }

  cardClicked(card) {
    if(card.type === "REPORT") {
      this.router.navigateByUrl("/qxi-customSummary");
    } else {
      this.router.navigateByUrl("/qxi-portfolio/" + card.id);
    }
  }

  assignUsersToFund() {
    const assignees = []
    this.myOrgUsers.forEach(user => {
      if(user.assigned) {
        assignees.push({ name: user.userName, id: user.id, userId: user.userId, permission: user.permission ? user.permission : "W"});
      }
    })

    const reqBody = {
      fundId: this.cardSelectedForAssignment.id,
      details: {
        assignees: assignees
      }
    }

    this.modalService.dismissAll()
    this.utilService.showLoadingPopup();

    this.ds.assignUsersToFund(reqBody).subscribe( res => {
      // console.log("Assignment is successful.")
      this.utilService.showMessage("Successfully saved the user assignment.", "Ok");
    }, error => {
      console.log("Assignment is Failed.", error)
      this.utilService.showMessage("Failed to save the user assignment.", "Ok");
    })
  }
}
