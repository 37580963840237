export const generalConstants = {
  seniorityType: {
    SENIOR_SECURED: 'Senior Secured',
    FIRST_LIEN: 'First Lien',
    SECOND_LIEN: 'Second Lien',
    SENIOR_UNSECURED: 'Senior Unsecured',
    SENIOR_SUBORDINATE: 'Senior Subordinate',
    SUBORDINATE: 'Subordinate',
    JUNIOR_SUBORDINATE: 'Junior Subordinate',
    JUNIOR: 'Junior',
    MEZZANINE: 'Mezzanine',
    NA: 'NA',
  },
};
