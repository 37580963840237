<div class="external-valuer">

    <div>
        <span class="main-section-heading">{{translateService.getLabel("valuation_details_for_3rd_party_valuer")}} </span>
    </div>
    
    <div class="row ev-body">
        <div class="col-6">
            <div class="widget">
                <div class="graph-label">
                    <span class="graph-label-text">{{translateService.getLabel("equity_value_range")}} ({{this.formData.GENERAL.GD_General_Details.GD_CR_CURRENCY}} Mn) </span>
                 </div>
                <div class="valuation-range-chart" [chart]="columnChart" style="height: 320px;"></div>
            </div>
        </div>
        <div class="col-6">
            <div class="valuation-widget">
                <div class="valuation-form-header">
                    <span class="valuation-text">{{translateService.getLabel("Valuation_details_(external)")}}</span>
                </div>
                <form #externalValue="ngForm" (ngSubmit)="saveExternalValuer(externalValue.value)" class="external-valuer-form">
                    <div class="row vertical-form-alignment">
                        <div class="col-3">
                            <label class ="valuation-label">{{translateService.getLabel("name_of_valuer")}}</label>
                        </div>
                        <div class="col-9" >
                            <mat-form-field name="value" class="custom-textbox">
                                <input matInput type="text" [(ngModel)]="externalName" [placeholder]="translateService.getLabel('type_name')"   name="externalName" autocomplete="off"  required>
                            </mat-form-field>
                            <!-- <input matInput type="text" [(ngModel)]="externalName" placeholder="Type Name"   name="externalName" autocomplete="off"  required> -->
                        </div>
                    </div>
    
                    <div class = "row valuation-data">
                        <div class="col-3 valuation-label">{{translateService.getLabel("enterprise_value")}}</div>
                        <div class="col">
                            <mat-form-field name="value" class="custom-textbox">
                                <input matInput type="number" [(ngModel)]="enterpriseLow"  [placeholder]="translateService.getLabel('low')"   name="enterpriseLow"
                                 autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field name="value" class="custom-textbox">
                                <input matInput type="number" [(ngModel)]="enterpriseHigh" [placeholder]="translateService.getLabel('high')"   name="enterpriseHigh"
                                 autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class = "row valuation-data">
                        <div class="col-3 valuation-label"> {{translateService.getLabel("equity_value")}} (100%) </div>
                        <div class="col">
                            <mat-form-field name="value" class="custom-textbox">
                                <input matInput type="number" [(ngModel)]="equityLow"  [placeholder]="translateService.getLabel('low')"   name="equityLow"
                                 autocomplete="off" required>
                            </mat-form-field>
    
                        </div>
                        <div class="col">
                            <mat-form-field name="value" class="custom-textbox">
                                <input matInput type="number" [(ngModel)]="equityHigh"  [placeholder]="translateService.getLabel('high')"   name="equityHigh"
                                 autocomplete="off" required>
                            </mat-form-field>
                        </div>
                    </div>
    
                    <div class="row valuation-data">
                        <div class="col-3 valuation-label">{{translateService.getLabel("comment")}}</div>
                        <div class="col">
                            <mat-form-field class="custom-textarea">
                            <textarea matInput rows="6" [placeholder]='translateService.getLabel("type_here")' [(ngModel)]="externalComment"    name="externalComment"
                              ></textarea>
                            </mat-form-field>
    
                        </div>
                    </div>
                    <div class="row val-buttons-pos">
                        <div class="col-2 val-button">
                            <input type="reset" class="btn-custom-secondry"
                                  (click)="clearFormContent()" [value]='translateService.getLabel("clear")'/>
                        </div>
                        <div class="col-2 val-button">
                            <button class="btn-custom-primary"
                                [disabled]="!externalValue.valid">{{translateService.getLabel("save")}}</button>
                        </div>
                    </div>
                </form>
    
            </div>
        </div>
    </div>
    
    <div class = "external-valuer-list-main" *ngIf="this.externalValuerList && this.externalValuerList.length>0">
        <div class="row row-widget table-header">
            <div class="col-2 col-separator"> <label class="header-content">{{translateService.getLabel("name_of_valuer")}}</label></div>
            <div class="col-2 col-separator external-valuer-text" > <label class="header-content">{{translateService.getLabel("enterprise_value")}}</label> </div>
            <div class="col-2 col-separator external-valuer-text" > <label class="header-content">{{translateService.getLabel("equity_value")}}</label></div>
            <!-- <div class="col-2 col-separator external-valuer-text" > <label class="header-content"> Valuation Date</label></div> -->
            <!-- <div class="col-3"> <label class="header-content">Comments</label></div> -->
            <div class="col" style="max-width: 45%;"> {{externalValuer.comment}} </div>
            <div class="col blank-div"></div>
            <div class="col blank-div"></div>
        </div>
    
        <div class="row row-widget table-row" *ngFor="let externalValuer of this.externalValuerList; index as i; first as isFirstRow; last as isLastRow" [ngClass]="{'borderless-row' : isFirstRow, 'border-radius-row' : isLastRow}">
            <div class="col-2"> {{externalValuer.nameOfValuer}} </div>
            <div class="col-2 external-valuer-text" >
                {{externalValuer.enterpriseValue.low | number: "1.1-1"}}
                /
                {{externalValuer.enterpriseValue.high | number: "1.1-1"}}
            </div>
    
            <div class="col-2 external-valuer-text" >
                {{externalValuer.equityValue.low | number: "1.1-1"}}
                /
                {{externalValuer.equityValue.high | number: "1.1-1"}}
            </div>
    
            <!-- <div class="col-2 external-valuer-text" > {{externalValuer.valuationDate | date}} </div> -->
            <!-- <div class="col-3"> {{externalValuer.comment}} </div> -->
            <div class="col" style="max-width: 45%;"> {{externalValuer.comment}} </div>
            
            <div class="col button-external-valuer">
                <em class="fa fa-edit action-icon row-icon" (click)="editExternalValuer(externalValuer, i)" ></em>
            </div>
    
            <div class="col button-external-valuer">
                <em class="fa fa-trash action-icon row-icon" (click)="deleteExternalValuer(i)" ></em>
            </div>
        </div>
    
    </div>
</div>
