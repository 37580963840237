import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { Observable, Subject, interval, of } from 'rxjs';
import { UserManagementService } from '../services/user-management.service';
import { catchError, map } from 'rxjs/operators';
import { InSyncStatus } from './data-recon';

@Injectable({
  providedIn: 'root',
})
export class DataReconService {
  statusInterval = interval(1000);

  inSyncStatusHistory: InSyncStatus[] = [];

  private readonly inSyncStatus = new Subject<InSyncStatus>();

  readonly inSyncStatus$ = this.inSyncStatus.asObservable();

  constructor(private dataService: DataService, private um: UserManagementService) {}

  setInSyncStatus(val: InSyncStatus) {
    this.inSyncStatus.next(val);
  }

  getClients() {
    return this.dataService.getAll73StringsClients().toPromise();
  }

  getFinancialsAndGeneralDetailsDiff(orgIds: string[]): Observable<any> {
    // const orgIds = [this.um.getSelectedUserDetails().organization.id, 'e5d7d34d-8329-437f-be94-859aa27c17d7'];
    return this.dataService.getFinancialsAndGeneralDetailsDiffInGxAndExcelReports(orgIds).pipe(map((res) => res.body['response']));
  }

  getSnowflakeDiffGdAndFins(orgIds: string[]): Observable<any> {
    return this.dataService.getSnowflakeFinancialsAndGeneralDetailsDiffInGxAndExcelReports(orgIds).pipe(map((res) => res.body['response']));
  }

  getAttributesDiff(orgIds: string[]) {
    return this.dataService.getAttributeDetailsDiffInGxAndExcelReports(orgIds).pipe(map((res) => res.body['response']));
  }

  getUserCountsDiff(orgIds: string[]) {
    return this.dataService.getUserCountByOrgId(orgIds).pipe(map((res) => res.body['response']));
  }

  getUserCompanyDetailsDiff(orgIds: string[]) {
    return this.dataService.getUserCompanyDetailsByOrgId(orgIds).pipe(map((res) => res.body['response']));
  }

  getInSyncStatus(orgIds: string[]) {
    return this.dataService.getGxAndReportingSyncStatus(orgIds).pipe(
      map((res) => res.body['response']),
      // tap((res) => {
      //   this.inSyncStatusHistory.push({...res})
      // }),
      // retry(1)
      catchError(err => of(null))
    );
  }
}
