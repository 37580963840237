import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { UserManagementService } from "src/app/services/user-management.service";

@Injectable({
    providedIn: 'root'
})
export class ApprovalMatrixService {
    constructor(private ums: UserManagementService, private ds: DataService) 
    {}

    $approvalRouteUpdated = new Subject();

    APPROVAL_STATUS = {
        NOT_STARTED: "Not Started",
        INITIATED: "Initiated",
        APPROVED: "Approved",
        REJECTED: "Rejected",
        PENDING: "Pending",
        NO_CONDITION: "None",
        APPROVE_REJECT: "Approve / Reject"
    }
    
    APPROVAL_ACTION = {
        INITIATE_APPROVAL: "Initiate Approval",
        RECALL_APPROVAL: "Recall Approval",
        REJECT_APPROVE: "ApproveReject",
        NO_CONDITION: "No Condition",
        NONE: "none"
    }

    allApprovalDetails = [];

    getApprovalStatus(allApprovalDetails, approvalConditionData, initiatorsGroupMembers, formVersion) {

        let obj = {
            approvalStatus : null,
            actionName : null,
            approvalRequestId: null,
            editable: false,
            approvalUserData: [],
        }

        let approvalData = [];

        if(allApprovalDetails.length > 0){
            approvalData = allApprovalDetails.filter(eachApproval => eachApproval.id == formVersion.approvalRequestId );
        }

        if(approvalData.length > 0)
        {
            obj.approvalRequestId = approvalData[0].id;

            if(approvalData[0].status == "approved")
            {
                obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
                obj.actionName = this.APPROVAL_ACTION.NONE;
                approvalData[0].approvals = approvalData[0].approvals.sort(function (a, b) {
                  return new Date(a.user?.timestamp) === new Date(b.user?.timestamp)? 0: new Date(a.user?.timestamp) < new Date(b.user?.timestamp)? 1: -1;
                });
                obj.approvalUserData.push(approvalData[0].approvals)
            }
            else if(approvalData[0].status == "rejected")
            {
                obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
                obj.actionName = this.APPROVAL_ACTION.NONE;
                obj.approvalUserData.push(approvalData[0].approvals)
            }
            else
            {
                if(approvalData[0].status == "pending")
                {
                    let isInitator = this.isInitiator(approvalConditionData, initiatorsGroupMembers);
                    if(isInitator)
                    {
                        obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                        obj.actionName =  this.APPROVAL_ACTION.RECALL_APPROVAL;
                    }
                    else if(this.isCurrentApprover(approvalData))
                    {
                        obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                        obj.actionName =  this.APPROVAL_ACTION.REJECT_APPROVE;
                    }
                    else
                    {
                        // no action
                        obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                        obj.actionName =  this.APPROVAL_ACTION.NONE;
                    }
            
                }
            }
        }
        else if(approvalConditionData && approvalConditionData.hasOwnProperty("conditionName")) 
        {
            let isInitator = this.isInitiator(approvalConditionData, initiatorsGroupMembers);
            if(isInitator)
            {
                obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
                obj.actionName = this.APPROVAL_ACTION.INITIATE_APPROVAL;
                obj.editable = !formVersion.approvalStatus;
            }
            else
            {
                obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
                obj.actionName = this.APPROVAL_ACTION.NONE;
                obj.editable = !formVersion.approvalStatus;
            }
        }
        else
        {
            // Disable initiate button
            obj.actionName = this.APPROVAL_ACTION.NO_CONDITION;
            obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
            obj.editable = !formVersion.approvalStatus;
        }

        return obj;
    }
    

  isInitiator(approvalConditionData, initiatorsGroupMembers) {
    let isInitator = false;

    if(approvalConditionData.approvers[0].type == "user" && approvalConditionData.approvers[0].id == this.getLoggedInUserId())
    {
      isInitator = true;  
    }
    else if(approvalConditionData.approvers[0].type == "group")
    {
      let initatorObj = initiatorsGroupMembers.find(eachMember => eachMember.id == this.getLoggedInUserId());
      if(initatorObj) {
        isInitator = true;
      }
    }
    else {
      isInitator = false;
    }
    return isInitator;
  }

  isCurrentApprover(approvalData) {
    let isCurrentApprover = false;
    let firstPendingApprover = approvalData[0].approvals.find(eachApproval =>  eachApproval.status == "pending" );

    if(firstPendingApprover.hasOwnProperty("group"))
    {
      firstPendingApprover.group.members.forEach((member) => {
        if(member.id == this.getLoggedInUserId())
        {
          isCurrentApprover = true;
        }
      });
    } else if(firstPendingApprover.hasOwnProperty("user"))
    {
      if(firstPendingApprover.user.id == this.getLoggedInUserId())
      {
        isCurrentApprover = true;
      }
    }

    return isCurrentApprover;
  }

    async getApprovalDataForAllIds(allRequestIds, orgId) {
        
        let allRequestIdObject = {
            approvalRequestIds: allRequestIds,
            firmId: orgId
        }

        let allApprovalDetails = [];

        if(allRequestIds.length > 0){
            try {
                let resp = await this.ds.getAllApprovalDetails(allRequestIdObject);
                if(resp?.body && resp?.body["response"]) {
                    allApprovalDetails = resp.body["response"];
                }
            } catch(e) {
                console.log("No approvals exists");
            }
        }

        return allApprovalDetails;
    }

    getLoggedInUserId() {
        return this.ums.getSelectedUserDetails().id;
    }
}