/* tslint:disable:variable-name */
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { OrganizationUsersRow } from '../../data-recon-pluto.model';

@Component({
  selector: 'app-drp-organization-user-details',
  templateUrl: './drp-organization-user-details.component.html',
  styleUrls: ['../table-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrpOrganizationUserDetailsComponent {
  displayColumns = ['organizationName', 'gxUsersCount', 'plutoUsersBaseCount', 'plutoUsersFactCount', 'usersDelta'];

  _data: OrganizationUsersRow[];

  dataSource: MatTableDataSource<OrganizationUsersRow>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input() loading: boolean;

  @Input() error: string | null;

  @Input() set data(data: OrganizationUsersRow[]) {
    this._data = [...data];
    if (this._data) {
      this.dataSource = new MatTableDataSource(this._data);
      this.dataSource.paginator = this.paginator;
    }
  }

  onRowClick($event, row) {
    console.log($event, row);
  }
}
