import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { DataService } from "../services/data.service";
import { UserManagementService } from "../services/user-management.service";
import { colors } from "./colors";

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    PRIMARY_COLOR = "#1D1563";

    primaryColor = "#1D1563"

    orgLogoUrl = null;

    constructor(private ds: DataService, private ums: UserManagementService) {

    }

    refreshTheme(user) {
        if(!user.organization || environment.environment != "us-prod") {
            this.setColors(this.PRIMARY_COLOR);
            return;
        }

        this.ds.getThemeFromUam(user.organization.id).subscribe(res => {
            const uamSettings = res.body['response'];
            const primaryColor = uamSettings.organizationSetting.primaryColor;

            if(uamSettings.photoUrl && uamSettings.photoUrl.length > 0) {
                this.orgLogoUrl = uamSettings.photoUrl;
            }

            this.setColors(primaryColor);

            this.saveThemeToLocalStorage(primaryColor);

        }, err => {
            this.setColors(this.PRIMARY_COLOR);
        })
    }

    setColors(primaryColor) {

        document.documentElement.style.setProperty("--themeColorPrimary-css", primaryColor);
    }

    setDefaultTheme() {
        const theme = this.getThemeFromLocalStorage();

        if(theme.primaryColor) {
            this.setColors(theme.primaryColor);
            this.orgLogoUrl = theme.logo;

        } else {
            this.setColors(this.PRIMARY_COLOR);
        }
    }

    saveThemeToLocalStorage(primaryColor) {
        const user = this.ums.getSelectedUserDetails();
        const theme = {
            primaryColor: primaryColor,
            logo: this.orgLogoUrl
        }

        localStorage.setItem(user.organization.id + "_THEME", JSON.stringify(theme));
    }

    getThemeFromLocalStorage() {
        const user = this.ums.getSelectedUserDetails();
        
        if(!user.organization) return {};

        const themeStr = localStorage.getItem(user.organization.id + "_THEME");

        if(themeStr) {
            return JSON.parse(themeStr);
        } else {
            return {}
        }
    }
}