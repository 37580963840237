import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { PortfolioUtilService } from '../portfolio-util.service';

@Component({
  selector: 'app-equity-portfolio-v2',
  templateUrl: './equity-portfolio-v2.component.html',
  styleUrls: ['./equity-portfolio-v2.component.scss']
})
export class EquityPortfolioV2Component implements OnInit {

  constructor(private portfolioUtil : PortfolioUtilService,
    public translateService : TranslateService,
    public utilService : UtilService) { }

  @Input() allPortfolioData;
  @Input() reportingCurrency = "USD";
  @Input() allowEdit = "false"; //Parmeter which will show add company, Download Excel, & Delete COmpany Functionality

  @Output() emitAction = new EventEmitter();

  IRR_LOADING_PROGRESS = {
    "TOTAL_IRR": false,
    "CURRENT_PORTFOLIO_IRR": false,
    "REALISED_PORTFOLIO_IRR": false,
    "CONSOLIDATED_IRR": false,
  }

  ngOnInit(): void {
    if(this.allPortfolioData){
      const currentPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);
      
      const currentPortfolioAggregations = this.portfolioUtil.getPortfolioSummaryAggregationByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO);
      currentPortfolioAggregations.name = this.translateService.getLabel("current_portfolio");
      currentPortfolioAggregations.key = "current";
      currentPortfolioAggregations.expandPortfolio = true;

      const exitedPortfolioCompanies = this.portfolioUtil.getPortfolioCompaniesByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);
      const exitedPortfolioAggregations = this.portfolioUtil.getPortfolioSummaryAggregationByType(this.allPortfolioData, 
        this.portfolioUtil.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO);
      exitedPortfolioAggregations.name = this.translateService.getLabel("realised_portfolio");
      exitedPortfolioAggregations.key = "exit";
      exitedPortfolioAggregations.expandPortfolio = false;


      const totalAggregations = this.portfolioUtil.getTotalPortfolioSummaryAggregations(this.allPortfolioData);

      this.initAllIRR(totalAggregations, currentPortfolioAggregations, exitedPortfolioAggregations, 
        currentPortfolioCompanies, exitedPortfolioCompanies)
    }
  }

  emittingActionOnPortfolio(event){
    this.emitAction.emit(event);
  }

  initAllIRR(finalTotalAggregations, currentPortfolioAggregations, exitedPortfolioAggregations, 
    currentPortfolioCompanies, exitedPortfolioCompanies){
    const data = cloneDeep(this.allPortfolioData);
    
    this.portfolioUtil.initGroupIRR(finalTotalAggregations, "TOTAL", this.utilService.getUID()).subscribe(res => {
      try {
        data.totalAggregations.totalIRR = this.utilService.zeroInForOneDecimalFormat(res.body["response"].data.complete_processed_data * 100);
      } catch(e) {
        data.totalAggregations.totalIRR = 0
      }

      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("TOTAL_IRR")
    }, error => {
      data.totalAggregations.totalIRR = 0
      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("TOTAL_IRR")
      console.log("Failed to Get Total IRR", error);
    });

    this.portfolioUtil.initGroupIRR(currentPortfolioAggregations, "CURRENT", this.utilService.getUID()).subscribe(res => {
      try {
        data.currentPortfolio.aggregations.totalIRR = this.utilService.zeroInForOneDecimalFormat(res.body["response"].data.complete_processed_data * 100);
      } catch(e) {
        data.currentPortfolio.aggregations.totalIRR = 0
      }

      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("CURRENT_PORTFOLIO_IRR")
    }, error => {
      data.currentPortfolio.aggregations.totalIRR = 0
      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("CURRENT_PORTFOLIO_IRR")
      console.log("Failed to Get Current Portfolio IRR", error);
    });

    this.portfolioUtil.initGroupIRR(exitedPortfolioAggregations, "EXIT", this.utilService.getUID()).subscribe(res => {
      try {
        data.realisedPortfolio.aggregations.totalIRR = this.utilService.zeroInForOneDecimalFormat(res.body["response"].data.complete_processed_data * 100);
      } catch(e) {
        data.realisedPortfolio.aggregations.totalIRR = 0
      }

      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("REALISED_PORTFOLIO_IRR")
    }, error => {
      data.realisedPortfolio.aggregations.totalIRR = 0
      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("REALISED_PORTFOLIO_IRR")
      console.log("Failed to Get Realised Portfolio IRR", error);
    });

    this.portfolioUtil.initConsolidatedIRR(this.utilService.getUID, currentPortfolioCompanies, exitedPortfolioCompanies).subscribe(res => {

      const compWiseData = res.body["response"]["complete_processed_data"][0];

      data.currentPortfolio.portfolioData.forEach(comp => {
        try {
          if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
            comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100)
          }

          if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
            comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
          }
        } catch (e) {
          comp["valuesInLocalCurrency"].grossIRR = 0;
          comp["valuesInPortfolioCurrency"].grossIRR = 0;
        }
      })

      data.realisedPortfolio.portfolioData.forEach(comp => {
        if (!comp.grossIRR) {
          try {
            if (!comp["valuesInLocalCurrency"].grossIRR || comp["valuesInLocalCurrency"].grossIRR == 'loading') {
              comp["valuesInLocalCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_LOCAL"] * 100);
            }
            if (!comp["valuesInPortfolioCurrency"].grossIRR || comp["valuesInPortfolioCurrency"].grossIRR == 'loading') {
              comp["valuesInPortfolioCurrency"].grossIRR = this.utilService.zeroInForOneDecimalFormat(compWiseData[comp.id + "_DEST"] * 100);
            }
          } catch (e) {
            comp["valuesInLocalCurrency"].grossIRR = 0;
            comp["valuesInPortfolioCurrency"].grossIRR = 0;
          }
        }
      })

      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("CONSOLIDATED_IRR")

    }, error => {
      this.allPortfolioData = cloneDeep(data);
      this.updatingLoadingProgressOfIRR("CONSOLIDATED_IRR")
      console.log("Failed to Get Consolidated IRR", error);
    })
  }

  updatingLoadingProgressOfIRR(typeOfIRR){
    const data = cloneDeep(this.IRR_LOADING_PROGRESS);
    data[typeOfIRR] = true
    this.IRR_LOADING_PROGRESS = cloneDeep(data);
  }

}
