import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TranslateService } from 'src/app/services/translation.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FundListService {
  allFunds = [];
  myOrgUsers = [];
  fundsLoaded$ = new BehaviorSubject([]);

  constructor(private ds: DataService,  private utilService: UtilService,
    private um: UserManagementService,  private modalService: NgbModal,
    private translateService: TranslateService)
  {
  }

  createFund(fundBody, user) {
    const myDetails = this.um.getSelectedUserDetails();
    this.ds.createFund(fundBody, user).subscribe(res =>{
      // console.log("Fund Created", res.body["response"]);
      this.allFunds.push({fund: res.body["response"], permission: "FW"});

      this.resetUserAssignment();
      this.assignUsersToFund(res.body["response"], true);
      this.getFunds(myDetails);

    }, error =>{
      console.log("error while creating fund", error)
    })
  }

  assignUsersToFund(cardSelectedForAssignment, newFundCreation) {   
    const myDetails = this.um.getSelectedUserDetails();

    const assignees = [
      { name: myDetails.userName, id: myDetails.id, userId: myDetails.userId, permission: "FW"}
    ] as any;

    // this.myOrgUsers.forEach(user => {
    //   if(user.permission== "FN") {
    //     return;
    //   }
    //   // assignees.push({ name: user.userName, id: user.id, userId: user.userId, permission: user.permission});

    //   // } else 
    //   if(this.um.isMasterUser() || user.id == cardSelectedForAssignment.createdBy) {
    //     //Fund will be shared to Org master user by default.
    //     assignees.push({ name: user.userName, id: user.id, userId: user.userId, permission: "FW"});
    //   }
    //   else if(user.permission!=null){
    //     assignees.push({ name: user.userName, id: user.id, userId: user.userId, permission: user.permission});
    //   }
    // })

    const reqBody = {
      fundId: cardSelectedForAssignment.id,
      details: {
        assignees: assignees
      }
    }

    this.modalService.dismissAll()
    this.utilService.showLoadingPopup();

    this.ds.assignUsersToFund(reqBody).subscribe( res => {
      // console.log("Assignment is successful.")

      const fundFormBody = {
        users: assignees.map(a => a.id),
        fund: cardSelectedForAssignment.id,
        editable: assignees.map(a => a.permission)
      }

      this.ds.assignUsersToFundForms(fundFormBody).subscribe(res => {
        // console.log("All forms assigment updated successfully", res.body)
      }, error => {
        console.log("Form assignment failed", error)
      })

      if(!newFundCreation) {
        this.utilService.showMessage(this.translateService.getLabel("suc_user_assignment"), this.translateService.getLabel("ok"));
      }

      this.utilService.closeAllPopups();
    }, error => {
      console.log("Assignment is Failed.", error)

      if(!newFundCreation) {
        this.utilService.showMessage(this.translateService.getLabel("err_user_assignment"), this.translateService.getLabel("ok"));
      }

      this.utilService.closeAllPopups();
    })

    this.allFunds["user"] = myDetails;
    this.getFunds(myDetails);
  }

  getFunds(user) {
    this.allFunds = [];
    user = this.um.getSelectedUserDetails();

    this.ds.getAllFunds(user).subscribe(res =>{
      this.allFunds = res.body["response"];
      this.allFunds.map(f => { 
        f["showDropDown"] = false 
        f["assignees"] = JSON.parse(f.fund.details)
      });

      // console.log("All Funds", this.allFunds);
      

      // Temporarily hide these funds.
      let idsToBeHidden = ["3cc66d34-48b3-44ee-90c0-7caec741c456", "3cdf4c3b-93c6-4084-b8f3-cc653dbcead8", "4155551d-04f5-4dca-a2b1-5efbecfb8b78", 
      "4324333b-4e63-4776-ae23-bdc189139ef3", "99ef0268-d4d7-492e-b9f6-33fa3a7fc7db", "b3dfe704-dd48-4c2b-a341-9683d251b208", "b4a7edc5-c25f-4178-af04-6127001cbc9c",
      "b9a14e76-d2c6-4deb-ac56-6a2685ae32ec", "c6514393-a7b1-4b33-adf2-999f1eb0658d", "ee78ef2d-70f8-437d-88c8-c5d1f9752377", "279bf12a-44a5-441f-a5ac-d0171b8863cb"];

      if(!environment.production) {
        idsToBeHidden = []
      }

      idsToBeHidden.forEach(id => {
        const fundIndex = this.allFunds.findIndex(f => f.fund.id === id);
        if(fundIndex >= 0) {
          this.allFunds.splice(fundIndex, 1);
        }
      })

      this.allFunds = this.allFunds.sort((f1, f2) => f1.fund.name > f2.fund.name ? 1: -1);

      this.fundsLoaded$.next(this.allFunds);
    }, error =>{
      console.log("Error while fetching all funds", error)
      this.fundsLoaded$.next([]);
    })
    
    this.um.getUsers(user.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(u => u.id !== user.id);
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  getFundName(id) {
    const fundObj = this.allFunds.find( f => f.fund.id === id);
    return fundObj ? fundObj["fund"].name : "";
  }

  getFundById(fundId) {
    return this.allFunds.find(f => f.fund.id === fundId).fund;
  }

  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.permission = null;
    })
  }
}