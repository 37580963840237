<nav-menu-header headerLabel="Custom Fund Summary"></nav-menu-header>

<div class="pageBody">
    <form>
        <div class="first-form-element">
            <div class="row" style="text-align: left; margin: 5px">
              <div class="col-1">Fund</div>

              <div class="col-3">
                <mat-form-field>
                    <mat-select placeholder="Select Fund" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="userSelection.fund" 
                      (ngModelChange)="fundSelectionChange($event)">

                      <mat-option value="all">All</mat-option>

                      <ng-template ngFor [ngForOf]="fundService.allFunds" let-row>
                        <mat-option *ngIf="row.fund.type !== 'REPORT'" value="{{ row.fund.id }}">
                          {{ row.fund.name }}
                        </mat-option>
                      </ng-template>
                    </mat-select>
                </mat-form-field>
              </div>
              
              <div class="col-1">Geography</div>

              <div class="col-3">
                
                  <mat-form-field class="field">
                      <mat-select placeholder="Select geography" (ngModelChange)="addGeography($event)" [ngModel]="userSelection.geography[0]"  [ngModelOptions]="{standalone: true}">
                          <mat-option value="" disabled>Select</mat-option>
                          <mat-option *ngFor="let geographyItem of geography" value="{{ geographyItem }}">
                            {{ geographyItem }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              
              <div class="col-1"> Industry </div>

              <div class="col-3">
                  <mat-form-field class="field">
                      <mat-select placeholder="Select" (ngModelChange)="addSector($event)" [ngModel]="userSelection.sector[0]"  [ngModelOptions]="{standalone: true}">
                          <mat-option value="" disabled>Select</mat-option>
                          <mat-option *ngFor="let sectorItem of sector" value="{{ sectorItem }}">{{ sectorItem }}</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-4"></div>

              <div class="col-4">                
                <mat-chip-list>
                  <mat-chip *ngFor="let value of this.userSelection.geography; let index=index" 
                    [selectable]="false" 
                    [removable]="true" 
                    (removed)="removeGeography(index)">
                    {{value}}
                    <mat-icon matChipRemove class="close-mat-icon">close</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>

              <div class="col-4">                
                <mat-chip-list>
                  <mat-chip *ngFor="let value of this.userSelection.sector; let index=index" 
                    [selectable]="false" 
                    [removable]="true" 
                    (removed)="removeSector(index)">
                    {{value}}
                    <mat-icon matChipRemove class="close-mat-icon">close</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
            <div>
              <button mat-raised-button class="primary-bg" (click)="refreshGraphs()">Filter</button>
            </div>
        </div>
    </form>

    <br>
    <br>

    <div class="row" style=" margin: auto 30px;">

      <div class="col-5">
        <div [chart]="portfolioService.mapChart" style="width: 500px;"></div>
      </div>

      <div class="col">
        <div id="column-chart-container" style="height: 400px; width: 450px;"></div>
      </div>
    </div>

    <br>
    <div style="text-align: left;">
      <button mat-raised-button class="primary-bg" (click)="refreshWithCustomSelection()">Update Graph</button>
    </div>

    <div class="table table-condensed" id="container">
      <table class="a">
        <tr class="bg primary-bg">
          <!-- Custom Selection Column -->
          <td></td>
          <th>Logo</th>
          <th style="width: 160px; text-align: left">Name</th>
          <th style="width: 120px; text-align: left">Investment Date</th>
          <th style="width: 100px; text-align: left">Type of Security</th>
          <th style="text-align: right">Fair Value</th>
          <th style="text-align: right"> Stake </th>
          <th style="text-align: right">Stake Value</th>
          <th style="text-align: right">Investment Amount</th>
          <th style="text-align: right"> Realised Proceeds </th>
          <th style="text-align: right"> MOIC </th>
          <th style="text-align: right"> Gross IRR </th>
          <th style="text-align: right"><i class="fas fa-caret-up"></i>From Last Val Date</th>
        </tr>

        <ng-template ngFor [ngForOf]="filteredCompanies" let-comp let-rowIndex="index">
          <tr>
            <!-- Custom Selection Column -->
            <td>
              <mat-checkbox [checked]="userSelection.customSelection[comp.id]" (change)="userSelection.customSelection[comp.id] = !userSelection.customSelection[comp.id]"></mat-checkbox>
            </td>

            <td>
              <img [src]="comp.logo" width="40" height="40" (error)="comp.logo = null" *ngIf="comp.logo; else defaultLogo">
              <ng-template #defaultLogo>
                <!-- <i class="fa fa-landmark" style="margin-left: 6px; font-size: 15px;"></i> -->
                <i class="far fa-building" style="margin-left: 6px; font-size: 15px;"></i>
              </ng-template>
            </td>
            <td style="text-align: left">
              <span class="p-company-name"><b>{{comp.companyName}}</b></span><br>
              {{comp.geography.name | titlecase}} <br><br>
              {{comp.sector | titlecase}}
            </td>
            <td style="text-align: left">{{comp.investmentDate | date: "MMM, dd yyyy"}}</td>
            
            <td style="text-align: left">{{comp.security}}</td>
            <td style="text-align: right;">
              <ng-template [ngIf]="comp.fairVal != 0">{{comp.fairVal| number: "1.1-1"}}</ng-template>
            </td>
            <td style="text-align: right;">
              <ng-template [ngIf]="comp.stake != 0">{{comp.stake | number: "1.1-1"}}</ng-template>
            </td>
            <td style="text-align: right;">
              <ng-template [ngIf]="comp.stakeVal != 0">{{comp.stakeVal | number: "1.1-1"}}</ng-template>
            </td>
            <td style="text-align: right;">   
              <ng-template [ngIf]="comp.investmentAmount != 0">{{comp.investmentAmount | number: "1.1-1"}}</ng-template>
            </td>
            <td style="text-align: right;"> <ng-template [ngIf]="comp.realisedProceeds != 0">{{ comp.realisedProceeds | number: "1.1-1"}}</ng-template> </td>
            <td style="text-align: right;"> <ng-template [ngIf]="comp.moic != 0 && comp.moic && comp.moic !== ''">{{comp.moic | number: "1.1-1"}} x</ng-template> </td>
            <td style="text-align: right; white-space: nowrap;"> <ng-template [ngIf]="comp.grossIRR != 0 && comp.grossIRR && comp.grossIRR !== ''">{{comp.grossIRR * 100 | number: "1.1-1"}} %</ng-template> </td>
            <td style="text-align: right;"> <ng-template [ngIf]="comp.changeInValPercentage != 0">{{comp.changeInValPercentage * 100 | number: "1.1-1"}}%</ng-template> </td>
          </tr>
          <ng-template [ngIf]= "rowIndex+1 == portfolioService.searchedForms.length">
            <tr style="font-weight: bold;">
              <td> </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">
                {{ portfolioService.totalPortfolioData.totalStakeVal | number: '1.1-1' }}
              </td>
  
              <td style="text-align: right;"> 
                {{ portfolioService.totalPortfolioData.totalInvestment | number: '1.1-1'}} 
              </td>
              <td style="text-align: right;"> 
                {{ portfolioService.totalPortfolioData.totalRealisedProceeds | number: '1.1-1'}} 
              </td>
              <td style="text-align: right;"> 
                {{ portfolioService.totalPortfolioData.totalMOIC | number: '1.1-1'}} x
              </td>
              <td style="text-align: right; white-space: nowrap;"> 
                {{ portfolioService.totalPortfolioData.totalIRR * 100| number: '1.1-1'}} %
              </td>
              <td style="text-align: right;">
                {{ portfolioService.totalPortfolioData.totalChangeInValPercentage * 100 | number: '1.1-1' }} %
              </td>
            </tr>
          </ng-template>
        </ng-template>
      </table>
    </div>
</div>