import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UtilService } from 'src/app/utils/util.service';
import { DebtPortfolioService } from '../debt-portfolio.service';

@Component({
  selector: 'app-covenants',
  templateUrl: './covenants.component.html',
  styleUrls: ['./covenants.component.scss']
})
export class CovenantsComponent implements OnInit {

  covenantData;
  covenantsWidgetDataKey = "SELECTED_COVENANTS_ARRAY";
  isCovenantsDataAvailable = false;
  covenantKeys;
  covenantLabelsArray = [];
  selectedCovenantsArray;
  coverageData;
  issuerCompanyId;
  selectedCovenant = "debtToEquityRatio";
  covenantsPayload : any = {
    maxValueData : {}
  }
  covenantsDropDownList = [{
    label: this.translateService.getLabel("debt_to_equity_ratio"),
    value: "debtToEquityRatio"
  },
  {
    label: this.translateService.getLabel("interest_coverage_ratio"),
    value: "interestCoverageRatio"
  },
  {
    label: this.translateService.getLabel("total_leverage_ratio"),
    value: "totalLeverageRatio"
  },
  {
    label: this.translateService.getLabel("loan_to_value_ratio"),
    value: "loanToValueRatio"
  },
  {
    label: this.translateService.getLabel("return_of_assets"),
    value: "returnOfAssets"
  },
  {
    label: this.translateService.getLabel("cashflow_coverage_ratio"),
    value: "cashflowCoverageRatio"
  },
  {
    label: this.translateService.getLabel("cashflow_to_net_income"),
    value: "cashflowToNetIncome"
  },
  {
    label: this.translateService.getLabel("net_leverage_ratio"),
    value: "netLeverageRatio"
  },
  {
    label: this.translateService.getLabel("current_ratio"),
    value: "currentRatio"
  },
  {
    label: this.translateService.getLabel("debt_to_total_assets"),
    value: "debtToTotalAssets"
  }]

  constructor(public debtPortfolioService: DebtPortfolioService,
              public ds: DataService,
              private activatedRoute: ActivatedRoute,
              public translateService: TranslateService,
              public utilService: UtilService, ) { }

  ngOnInit(): void {
    this. debtPortfolioService.coverageDataAvailable = false;
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get('issuerCompanyId')

    this.getCovenantsListfromWidgetDB();
  }

  async getCovenantsData(){
    let payload = this.debtPortfolioService.issureConsolSummaryPayload;

    payload["covenants"] = this.selectedCovenantsArray;
    this.covenantData = await this.ds.getCovenantsData(payload);
    this.covenantData = this.covenantData.body['response'];
    this.covenantKeys = Object.keys(this.covenantData).filter(e => e != "issuerCompanyId" && e!= "fundId" && e!= "coverageAnalysis");

    this.prepareSecurityLabels();

    this.coverageData = this.covenantData["coverageAnalysis"];
    this.debtPortfolioService.coverageDataAvailable = true;
  }

  prepareSecurityLabels(){
    // this.covenantKeys.forEach((key, index) =>{
      for(let i = 0; i< this.covenantKeys.length; i++){
        if(this.covenantKeys[i] == "debtToEquityRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("debt_to_equity_ratio")
        }
        if(this.covenantKeys[i] == "interestCoverageRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("interest_coverage_ratio")
        }
        if(this.covenantKeys[i] == "totalLeverageRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("total_leverage_ratio")
        }
        if(this.covenantKeys[i] == "loanToValueRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("loan_to_value_ratio")
        }
        if(this.covenantKeys[i] == "returnOfAssets"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("return_of_assets")
        }
        if(this.covenantKeys[i] == "cashflowCoverageRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("cashflow_coverage_ratio")
        }
        if(this.covenantKeys[i] == "cashflowToNetIncome"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("cashflow_to_net_income")
        }
        if(this.covenantKeys[i] == "netLeverageRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("net_leverage_ratio")
        }
        if(this.covenantKeys[i] == "currentRatio"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("current_ratio")
        }
        if(this.covenantKeys[i] == "debtToTotalAssets"){
          this.covenantLabelsArray[i] = this.translateService.getLabel("debt_to_total_assets")
        }
    }
  }

  async deleteCovenant(key, i){
    const index = this.selectedCovenantsArray.indexOf(key);
    if (index > -1) {
      this.selectedCovenantsArray.splice(index, 1);
    }

    this.saveCovenantsListToWidgetDB();
  }

  saveCovenantsListToWidgetDB(){
    this.debtPortfolioService.coverageDataAvailable = false;
    this.ds.saveWidgetDataToDB(this.covenantsWidgetDataKey, this.selectedCovenantsArray, this.issuerCompanyId).subscribe(response => {
      this.getCovenantsListfromWidgetDB();
    })
  }

  getCovenantsListfromWidgetDB(){
    this.ds.getWidgetDataFromDB(this.covenantsWidgetDataKey, this.issuerCompanyId).subscribe(response =>{
      if(response.body['response'][0].widgetData.length != 0) {
        this.selectedCovenantsArray = response.body['response'][0].widgetData;
      } else {
        this.selectedCovenantsArray = ["coverageAnalysis"];
      }
      
      this.getCovenantsData();
    }, err => {
      this.selectedCovenantsArray = ["coverageAnalysis"];
      this.getCovenantsData();
    });
  }

  saveCovenantMaxValue() {
    this.utilService.showLoadingPopup();
    this.covenantsPayload['issuerCompanyId'] = this.issuerCompanyId;
    this.ds.saveCovenantMaxValue(this.covenantsPayload )
      .then(() => {
        this.utilService.closeAllPopups();
      })
      .catch(() => {
        this.utilService.closeAllPopups();
        this.utilService.showMessage('Error occured', 'Ok');
      });
  }

  onMaxValueChange(event, key) {
    this.covenantsPayload.maxValueData[key] = event.value;   
  }

  addCovenant(){
    this.selectedCovenantsArray.push(this.selectedCovenant);
    this.saveCovenantsListToWidgetDB();
  }

  
}
