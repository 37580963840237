import { Component, OnInit, OnChanges, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

declare let GC: any;

@Component({
  selector: 'app-planned-vs-actual',
  templateUrl: './planned-vs-actual.component.html',
  styleUrls: ['./planned-vs-actual.component.scss']
})
export class PlannedVsActualComponent implements OnInit, AfterViewInit {
  @Input() excelData;

  @Output() dataUpdated = new EventEmitter();

  @ViewChild('performanceExcelUI', { read: ElementRef, static: false }) performanceExcelUI: ElementRef;
  spread;

  isLoading = true;
  
  constructor()
  {}

  ngOnInit()
  {}

  ngAfterViewInit() {
    if(this.excelData) {
      this.enablePerformanceExcelUI();
    }
  }

  enablePerformanceExcelUI() {  
    setTimeout(() => {
      this.isLoading = false;
      
      this.spread = new GC.Spread.Sheets.Workbook(this.performanceExcelUI.nativeElement);
      this.readWriteExcel(true);
    });
  }

  readWriteExcel(writeToExcel: boolean) {
    let rowCursor = 0;
    let colCursor = 0;

    if(writeToExcel) {
      this.spread.refresh();
      this.spread.setSheetCount(1);
      this.spread.sheets[0].name("Planed Vs Actual");

      let sheet0 = this.spread.getActiveSheet();
      sheet0.setColumnWidth(colCursor, 120.0);

      sheet0.addSpan(0, 1, 1, 3, GC.Spread.Sheets.SheetArea.viewport);
      sheet0.setValue(0, 1, this.excelData.header1[0]);
      this.styleNonEditableCell(sheet0.getCell(0, 1));

      sheet0.addSpan(0, 4, 1, 3, GC.Spread.Sheets.SheetArea.viewport);
      sheet0.setValue(0, 4, this.excelData.header1[1]);
      this.styleNonEditableCell(sheet0.getCell(0, 4));
      // this.setCellBorder(sheet0.getCell(0, 5));

      sheet0.options.isProtected = true;
    }
    
    let sheet0 = this.spread.getSheet(0);

    //Data grid header
    rowCursor++;
    let colIndexHeader = colCursor + 1;
    this.excelData.header2.forEach(entry => {
      if(writeToExcel) {
        sheet0.setValue(rowCursor, colIndexHeader, entry);
        this.styleNonEditableCell(sheet0.getCell(rowCursor, colIndexHeader))
      }
      colIndexHeader++;
    });

    //Data grid
    rowCursor++;
    this.excelData.rows.forEach(entry => {
      let colIndexData = colCursor;

      //Write row labels
      if(writeToExcel) {
        sheet0.setValue(rowCursor, colIndexData, entry.label);
        this.styleNonEditableCell(sheet0.getCell(rowCursor, colIndexData));
      }
      
      //Write to Excel
      if(writeToExcel) {
        for(let i=0; i<6 ; i++) {
          sheet0.setValue(rowCursor, ++colIndexData, entry.values[i]);
          this.styleEditableCell(sheet0.getCell(rowCursor, colIndexData));
        }
      }

      //Read from excel
      if(!writeToExcel) {
        let updatedValues = [];
        for(let i=0; i<6 ; i++) {
          updatedValues.push(sheet0.getCell(rowCursor, ++colIndexData).value());
        }
        entry.values = updatedValues;
      }

      rowCursor++;
    }); 
  }

  styleEditableCell(cell) {
    cell.locked(false);
    // cell.backColor("#4EE476");
    // this.setCellBorder(cell);
  }

  styleNonEditableCell(cell) {
    cell.backColor("lightgrey");
    // cell.font("bold 14.6px Calibri");
    // this.setCellBorder(cell);
  }

  setCellBorder(cell) {
    const border = new GC.Spread.Sheets.LineBorder("black", GC.Spread.Sheets.LineStyle.thin)
    cell.borderLeft(border);
    cell.borderRight(border);
    cell.borderTop(border);
    cell.borderBottom(border);
  }

  save() {
    this.readWriteExcel(false);
    this.dataUpdated.next(this.excelData);
  }
}