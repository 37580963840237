import { Location } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MappingMgmtConfigService } from '../mapping-mgmt-config.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mapping-info',
  templateUrl: './mapping-info.component.html',
  styleUrls: ['./mapping-info.component.scss'],
})
export class MappingInfoComponent implements OnInit, OnDestroy {
  private commonSub = new Subscription();

  currentRoute: string;
  showCreateVlInfo: boolean;
  showCreateAttributeListInfo: boolean;
  for: 'credit' | undefined;

  constructor(private mappingMgmtService: MappingMgmtConfigService) {}

  ngOnInit(): void {
    this.commonSub.add(
      this.mappingMgmtService.currentRoute$.subscribe((urlSgement:string) => {
        this.showCreateVlInfo = urlSgement?.includes('create-version');
        this.showCreateAttributeListInfo = urlSgement?.includes('create-attributes-map') || urlSgement?.includes('replicate-attributes-map');
        this.for = urlSgement.includes('for=credit') ? 'credit' : undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.commonSub.unsubscribe();
  }
}
