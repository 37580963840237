
//Attention : Any update in this Model should also be done in Portfolio Valuation Summary repository

export class ThresholdInputs {
    fundId : string = "";

    currentNAV : number = 0;
    navasOfPrevValuationDate : number = 0;
    navasOfPrevValuationDateInSync : number = 0;

    totalPortfolioNAVAsOfPrevValuationDate : number = 0;
    totalPortfolioNAVAsOfPrevValuationDateInSync : number = 0;

    prevValuationDateId : string = "";
    prevValuationDate : string = "";
    prevValDateExchangeRate : number = 1;
    prevValuationCurrency : string = "";

    currentFormId : string = "";
    currentValuationDate : string = "";
    currentValDateExchangeRate : number = 1;
    valuationCurrency : string = "";

    reportingCurrency : string = "";
    reportingCurrencyInSync : string = "";
}

export const THRESHOLD_VALUATION_CHANGE = {
    VALUATION_CHANGE: "VALUATION_CHANGE",
    NO_VALUATION_CHANGE: "NO_VALUATION_CHANGE"
}

export class ThresholdAnalysis {
    percentageChangeInNAVBetweenValuationDates : number= 0;
    percentageChangeInTotalPortfolioNAV : number = 0;
    isThereAValuationChange = THRESHOLD_VALUATION_CHANGE.VALUATION_CHANGE;
}

export class ThresholdConfigurations {
    thresholdSelected  : boolean = false; //Copy of data present in Configuration
    thresholdSelectedInSync  : boolean = false; //Copy of data present in Configuration

    thresholdForPercentageChangeInNAVBetweenTheDates : number = 0; //Copy of data present in Configuration
    thresholdForPercentageChangeInNAVBetweenTheDatesInSync : number = 0; //Copy of data present in Configuration

    applyThresholdForPercentageChangeInNAVBetweenTheDates : boolean = false; //Copy of data present in Configuration
    applyThresholdForPercentageChangeInNAVBetweenTheDatesInSync : boolean = false; //Copy of data present in Configuration

    //Total portfolio NAV as of Prev Date
    thresholdForPercentageChangeInTotalPortfolioNAV : number = 0; //Copy of data present in Configuration
    thresholdForPercentageChangeInTotalPortfolioNAVInSync : number = 0; //Copy of data present in Configuration

    applyThresholdForPercentageChangeInTotalPortfolioNAV : boolean = false; //Copy of data present in Configuration
    applyThresholdForPercentageChangeInTotalPortfolioNAVInSync : boolean = false; //Copy of data present in Configuration
}

export class ThresholdData {
    inputs : ThresholdInputs = new ThresholdInputs();
    analysis = new ThresholdAnalysis();
    configurations = new ThresholdConfigurations();
}
