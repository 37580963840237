import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '../services/translation.service';
import { colors } from '../utils/colors';

@Component({
  selector: 'app-qualitative-analysis-v2-message-popup',
  templateUrl: './qualitative-analysis-v2-message-popup.component.html',
  styleUrls: ['./qualitative-analysis-v2-message-popup.component.scss']
})
export class QualitativeAnalysisV2MessagePopupComponent implements OnInit {

  theme = colors;

  constructor(public dialogRef: MatDialogRef<QualitativeAnalysisV2MessagePopupComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfig) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface DialogConfig {
  okButtonMsg: string;
  noButtonMsg: string;
  title: string;
  comment: string;
  date:string,
  icon:string,
  error: boolean;
}

