import { Injectable } from '@angular/core';
import { TranslateService } from 'src/app/services/translation.service';
import { ConfigurationService } from 'src/app/utils/configuration/services/configuration.service';
import { UtilService } from 'src/app/utils/util.service';
import { ThresholdData } from '../model/threshold';
import { DataService } from 'src/app/services/data.service';

@Injectable({
  providedIn: 'root'
})
export class ThresholdService {

  thresholdData : ThresholdData = new ThresholdData();

  thresholdDataLoaded : boolean = false;


  constructor(public configurationService: ConfigurationService,
    public translateService: TranslateService,
    public utilService: UtilService, 
    private ds : DataService){ }

  init(consolFormId){
    this.getThresholdData(consolFormId)
  }

  getThresholdData(consolFormId){
    this.thresholdDataLoaded = false;
    this.ds.getThresholdData(consolFormId).subscribe(res => {
      this.thresholdData = <ThresholdData> res.body["response"];
      this.thresholdDataLoaded = true;
    }, error => {
      this.thresholdDataLoaded = true;
      console.log("failed to get Threshold data", error);
    })
  }

  loadThresholdData(consolFormId){
    this.thresholdDataLoaded = false;
    this.ds.getWidgetDataFromDB("THRESHOLD_DATA", consolFormId).subscribe(res => {
      this.thresholdData = <ThresholdData> res.body["response"][0]["widgetData"];
      this.thresholdDataLoaded = true;
    }, error => {
      console.log("Error while reading Threshold Widget", error);
      this.thresholdDataLoaded = true;
    })
  }
}
