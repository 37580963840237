import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { NewAttributesMap } from './attribute-interfaces';
import { UserManagementService } from 'src/app/services/user-management.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {

  private user;
  showCreateMap = false;

  histIncomeStatementMap = [];
  histBalanceSheetMap = [];
  histCashflowMap = [];
  projecteAttributesMap = [];

  private readonly _incomeStatementAttributes = new BehaviorSubject([]);

  private readonly _histBalanceSheetAttributes = new BehaviorSubject([]);

  private readonly _histCashflowAttributes = new BehaviorSubject([]);

  private readonly _projectedAttributes = new BehaviorSubject([]);

  readonly incomeStatements$ = this._incomeStatementAttributes.asObservable();

  readonly histBalanceSheetAttributes$ = this._histBalanceSheetAttributes.asObservable();

  readonly histCashflowAttributes$ = this._histCashflowAttributes.asObservable();

  readonly projectedAttributes$ = this._projectedAttributes.asObservable();

  get incomeStatements() {
    return this._incomeStatementAttributes.getValue();
  }

  set incomeStatements(val: any[]) {
    this._incomeStatementAttributes.next([...val]);
  }

  set histBalanceSheetAttributes(val: any[]) {
    this._histBalanceSheetAttributes.next([...val]);
  }

  get histCashflowAttributes() {
    return this._histCashflowAttributes.getValue();
  }

  set histCashflowAttributes(val: any[]) {
    this._histCashflowAttributes.next([...val]);
  }

  get projectedAttributes() {
    return this._projectedAttributes.getValue();
  }

  set projectedAttributes(val: any[]) {
    this._projectedAttributes.next([...val]);
  }

  get histBalanceSheetAttributes() {
    return this._histBalanceSheetAttributes.getValue();
  }

  getShowCreateMap(): boolean {
    return this.showCreateMap;
  }

  setShowCreateMap(val: boolean): void {
    this.showCreateMap = val;
  }

  updateIncomeStatementAttrs(payLoad) {
    // this.incomeStatements = this.incomeStatements.map((ics, index) => {
    //   if (ics.qxKey === payLoad.qxKey) {
    //     return { ...ics, ...payLoad };
    //   } else {
    //     return { ...ics };
    //   }
    // });
    this.histIncomeStatementMap = payLoad;
  }

  updateBalanceSheetMap(payLoad) {
    this.histBalanceSheetMap = payLoad;
  }

  updateHistCashflowMap(payLoad) {
    this.histCashflowMap = payLoad;
  }

  updateProjectedAttributesMap(payLoad) {
    this.projecteAttributesMap = payLoad;
  }

  constructor(private dataService: DataService, private userMgmtService: UserManagementService) {}

  setIncomeStatmentMap(listItems) {
    this.incomeStatements = listItems;
  }

  setHistBalanceSheet(listItems) {
    this.histBalanceSheetAttributes = listItems;
  }

  setHistCashflow(listItems) {
    this.histCashflowAttributes = listItems;
  }

  setProjectedAttributes(listItems) {
    this.projectedAttributes = listItems;
  }

  getIncomeStatmentMap() {
    return this.incomeStatements;
  }

  getAttributeListById(attributeListId: string) {
    return this.dataService.getAttributeListDetailsById(attributeListId).pipe(map(resp => resp?.body?.response));
  }

  createAttributeMap(name: string, uploadDate: string, fundId: string) {
    const userDetails = this.userMgmtService.getSelectedUserDetails();
    const reqBody: NewAttributesMap = {
      userId: userDetails.id,
      orgId: userDetails?.organization?.id,
      name,
      uploadDate,
      fundId,
      // data sources are mutated directly by the table, so not using *Map variables defined at the top for now
      backwardLookingMappings: [...this.incomeStatements, ...this.histBalanceSheetAttributes, ...this.histCashflowAttributes],
      forwardLookingMappings: [...this.projectedAttributes],
    };
    console.log(reqBody, 'Req Body');
    return this.dataService.saveAttributeMap(reqBody);
  }
}
