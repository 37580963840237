<ng-template [ngIf]="!editView">
    <div class="row section" *ngIf="loadedStatus.stakeholderSummary">
        <div class="col-7" style="margin-top: 40px;">
            <span class="main-heading"> Summary </span>
            <!-- <p>{{ allocation.stakeHolderName}}</p> -->
            <table class="table table-margins">
                <tr class="tableRow">
                    <th class="tableHeaderCell"> Stakeholder </th>
                    <th class="tableHeaderCell"> Company Name</th>
                    <th class="tableHeaderCell"> Security Name</th>
                    <!-- <th class="tableHeaderCell"> </th> -->
                    <!-- <th class="tableHeaderCell"> </th>
                    <th class="tableHeaderCell"> </th> -->
                    <th class="tableHeaderCell textAlightRight"> Total Value </th>
                    <th class="tableHeaderCell textAlightRight"> % of Par</th>
                </tr>
                <tr class="tableRow" *ngFor="let allocation of stakeholderSummary; let index=index">
                    <td class="tableDataCell">
                        <!-- {{ allocation.stakeHolderName }} -->

                        <div>
                           <span class="boldClass">
                               {{ allocation.stakeholderUsername }}
                           </span> 
                        </div>
                    </td>
                    <td class="tableDataCell">
                        <div class="textHidden">-</div>
                        <div *ngFor="let row of allocation.allocations">
                            <div class="tableDataCell">
                                <!-- <div *ngFor="let security of row.securityList">
                                    {{ row.issuerCompanyName }}
                                </div> -->
                                <div>{{ row.issuerCompanyName }}</div>
                            </div>        
                       </div>
                    </td>
                    <td class="tableDataCell">
                       <div class="textHidden">-</div>
                       <div *ngFor="let row of allocation.allocations">
                            <div class="tableDataCell">
                                <!-- <div *ngFor="let security of row.securityList">
                                    {{ security.securityName }}
                                </div> -->
                                <div>{{ row.securityName }}</div>
                            </div>
                       </div> 
                    </td>
                    <td class="tableDataCell textAlightRight">
                        <div class="textAlightRight">
                            <span class="boldClass">
                                {{
                                    computeTotalConcludedValueAndPercentageOfPar(allocation).concludedValue 
                                    | number: "1.1-1" 
                                }}
                            </span>
                        </div>
                        <div *ngFor="let row of allocation.allocations">
                            <div class="tableDataCell textAlightRight">
                                <!-- <div class="textAlightRight" *ngFor="let security of row.securityList">
                                    {{ security.fairValue | number: "1.1-1"}}
                                </div> -->
                                <div>{{ row.concludedValue | number: '1.1-1' }}</div>
                            </div>
                       </div>
                    </td>
                    <td class="tableDataCell textAlightRight">
                        <div class="textAlightRight">
                            <span class="boldClass">
                                {{
                                    computeTotalConcludedValueAndPercentageOfPar(allocation).percentageOfPar
                                    | number: "1.1-1" 
                                }}%
                            </span>
                        </div>
                        <div *ngFor="let row of allocation.allocations">
                            <div class="tableDataCell textAlightRight">
                                <!-- <div class="textAlightRight" *ngFor="let security of row.securityList">
                                    {{ security.percentagePar | number: "1.1-1" }}%
                                </div> -->
                                <div>{{ row.percentageOfPar | number: '1.1-1' }}%</div>
                            </div>
                       </div>
                    </td>
                    <!-- <td class="tableDataCell textAlightRight">{{ allocation.totalValue | number: "1.1-1"}}</td>
                    <td class="tableDataCell textAlightRight">{{ allocation.totalPercentagePar | number: "1.1-1"}}</td> -->
                </tr>
            </table>
        </div>
        <div class="col-6"></div>
    </div>
    <div class="section col">
        <button 
            mat-raised-button 
            class="btn-custom-primary" 
            *ngIf="loadedStatus.stakeholderSummary"
            (click)="beforeGoingToEditView()"
        > Edit 
        </button>
        <!-- <p *ngIf="disableBtn" class="loadingTextStyle">Loading...</p> -->
    </div>
</ng-template>

<ng-template [ngIf]="editView">
    <div class="row" style=" margin: auto 1.11vw; margin-bottom: 2%; margin-top: 15px">
        <div class="col-5">
            <span class="main-heading">Stakeholder Allocation</span>
        </div>
    </div>
    
    <!-- Funds Selections -->
    <p class="section selection-title">Select Fund</p>
    <div class="row section" *ngIf="fundsMap != null && objectKeys(fundsMap).length">
        <div class="col-4">
            <mat-form-field class="custom-input-select">
                <mat-label>Select Fund</mat-label>
                <!-- <mat-select name="fund">
                    <mat-option value=""> Select Fund </mat-option>
                    <mat-option 
                        value="" 
                        *ngFor="let fund of allFunds"
                        (click)="addFundToStakeholder(fund)" 
                    > {{ fund.name }}
                    </mat-option>
                </mat-select> -->

                <mat-select name="fund" [(value)]="matStakeholderSelections.fundIds" (closed)="onFundDropdownClose()"
                    multiple>
                    <mat-option 
                        [value]="fund.key"
                        *ngFor="let fund of fundsMap | keyvalue"
                    > {{ fund.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="row section" *ngIf="showFetchingDataMessage == true">
        <div class="col-4">
            <p class="dataFecthingMessage">Fetching Fund Details</p>
        </div>
    </div>
    
    <div class="row section" *ngIf="showNoDataAvailableMessage == true">
        <div class="col-4">
            <p class="errorMessage">No Debt Instruments available for this fund</p>
        </div> 
    </div>

    <!-- Fund chips -->
    <div class="row section" *ngIf="stakeholderSelections.fundIds.size > 0">
        <div class="col-4">
            <mat-chip-list class="chipList">
                <mat-chip class="chip" *ngFor="let fundId of stakeholderSelections.fundIds; let index=index">
                    {{fundsMap[fundId]}}
                    <mat-icon 
                        matChipRemove
                        (click)="removeChipForFunds(fundId)"
                        class="chip-close-icon"
                    > close
                    </mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>

    <br /><br />

    <!-- Issuer Company Selections -->
    <p *ngIf="companyMap != null && objectKeys(companyMap).length" class="section selection-title">Select Company</p>
    <div class="row section" *ngIf="companyMap != null && objectKeys(companyMap).length">
        <div class="col-4">
            <mat-form-field class="custom-input-select">
                <mat-label>Select Company</mat-label>
                <mat-select name="fund" [(value)]="matStakeholderSelections.issuerCompanyIds"
                    (closed)="onCompanyDropdownClose()" multiple>
                    <mat-option 
                        [value]="company.key"
                        *ngFor="let company of companyMap | keyvalue"
                    > {{ company.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Isser Company chips -->
    <div class="row section" *ngIf="stakeholderSelections.issuerCompanyIds.size">
        <div class="col-4">
            <mat-chip-list class="chipList">
                <mat-chip class="chip" *ngFor="let issuerCompanyId of stakeholderSelections.issuerCompanyIds">
                    {{companyMap[issuerCompanyId]}}
                    <mat-icon 
                        matChipRemove
                        (click)="removeChipForCompany(issuerCompanyId)"
                        class="chip-close-icon"
                    > close
                    </mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>

    <br /><br />

    <!-- Security Selections -->
    <p class="section selection-title" *ngIf="securityMap != null && objectKeys(securityMap).length">
        Select Security
    </p>
    <div class="row section" *ngIf="securityMap != null && objectKeys(securityMap).length">
        <div class="col-4">
            <mat-form-field class="custom-input-select">
                <mat-label>Select Security</mat-label>
                <mat-select name="fund" [(value)]="matStakeholderSelections.securityDebtModelIds" 
                    (closed)="onSecurityDropdownClose()" multiple>
                    <mat-option 
                        [value]="security.key"
                        *ngFor="let security of securityMap | keyvalue"
                    > {{ security.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Security chips -->
    <div class="row section" *ngIf="stakeholderSelections.securityDebtModelIds.size">
        <div class="col-4">
            <mat-chip-list class="chipList">
                <mat-chip class="chip" *ngFor="let securityId of stakeholderSelections.securityDebtModelIds">
                    {{securityMap[securityId]}}
                    <mat-icon 
                        matChipRemove
                        (click)="removeChipForSecurity(securityId)"
                        class="chip-close-icon"
                    > close
                    </mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>

    <br /><br />

    <!-- Stakeholder User -->
    <p class="section selection-title" *ngIf="stakeholderSelections.securityDebtModelIds.size">
        Stakeholder User
    </p>
    <div class="row section" *ngIf="stakeholderSelections.securityDebtModelIds.size">
        <form [formGroup]="stakeholderUserFromGroup" (ngSubmit)="addStakeholder($event)" class="stakeholder-form">
            <mat-form-field>
                <mat-label>Add Stakeholder</mat-label>
                <input id="stakeholder-form-input" matInput formControlName="stakeholderUsername"
                    placeholder="Enter Stakeholder Username" />
                <mat-error *ngIf="stakeholderUserFromGroup.controls.stakeholderUsername.hasError('required')">
                    Username is <strong>required</strong>
                </mat-error>
                <mat-error 
                    *ngIf="
                        stakeholderUserFromGroup.controls.stakeholderUsername.hasError('minlength') || 
                        stakeholderUserFromGroup.controls.stakeholderUsername.hasError('maxlength')
                    "
                > Username has to be between 3 - 36 characters
                </mat-error>
            </mat-form-field>
            <button class="btn-custom-primary">Add Stakeholder</button>
        </form>
    </div>

     <!-- Stakeholder user chips -->
     <div class="row section" *ngIf="stakeholderSelections.securityDebtModelIds.size && stakeholderUsers.length">
        <div class="col-4">
            <mat-chip-list class="chipList">
                <mat-chip class="chip" *ngFor="let stakeholderUser of stakeholderUsers">
                    {{stakeholderUser.stakeholderUsername}}
                    <mat-icon 
                        matChipRemove
                        (click)="removeChipFromStakeholderUser(stakeholderUser.stakeholderUserId)"
                        class="chip-close-icon"
                    > close
                    </mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </div>
    
    <ng-template [ngIf]="dataLoaded">
        <ng-container *ngFor="let stakeholderAllocation of stakeholderAllocationDetails">
            <div class="row section" *ngIf="showAllocationHeader(stakeholderAllocation.securities)">
                <div class="col-6" style="margin-bottom: 2%; margin-top: 15px">
                    <!-- <span class="main-heading">{{ selectedFundChipList[index].fundName }}</span> -->
                </div>
                <div class="col-6"></div>
                <div class="col-7 tableSection">
                    <!-- <div class="tableSection"> -->
                    <p>{{ stakeholderAllocation.issuerCompanyName }}</p>
                    <table class="table">
                        <tr class="tableRow">
                            <!-- <th class="tableHeaderCell"> Company </th> -->
                            <th class="tableHeaderCell"> Security </th>
                            <th class="tableHeaderCell"> Allocation </th>
                            <th class="tableHeaderCell textAlightRight"> Stake </th>
                            <th class="tableHeaderCell"> </th>
                            <th class="tableHeaderCell"> </th>
                            <th class="tableHeaderCell textAlightRight"> Concluded Value </th>
                            <th class="tableHeaderCell textAlightRight"> % of Par</th>
                            <th class="tableHeaderCell"></th>
                        </tr>
                        <ng-container *ngFor="let security of stakeholderAllocation.securities">
                            <tr class="tableRow" *ngIf="showSecurity(security.originationDebtModelId)">
                                <td class="tableDataCell">{{ security.securityName }}</td>
                                <!-- <td class="tableDataCell"> *** </td> -->
                                <td class="tableDataCell">
                                    <div *ngFor="let allocation of security.allocations">
                                        <div class="allocationCell">
                                            {{ allocation.user.stakeholderUsername }}
                                        </div>
                                    </div>
                                </td>
                                <td class="tableDataCell textAlightRight">
                                    <div *ngFor="let allocation of security.allocations">
                                        <div class="allocationCell">
                                            {{ allocation.stake | number: "1.1-1"}}%
                                        </div>
                                    </div>
                                </td>
                                <td class="tableDataCell textAlightRight">
                                    <div *ngFor="let allocation of security.allocations; let allocationIndex = index">
                                        <button
                                            class="editButton"
                                            (click)="openEditAllocationPopUp(
                                                addAllocationPopUp, security.allocations, allocationIndex
                                            )"
                                        ><i class="far fa-edit action-icon grey-text"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="tableDataCell textAlightRight">
                                    <div *ngFor="let allocation of security.allocations; let allocationIndex = index">
                                        <button 
                                            class="editButton"
                                            (click)="deleteAllocation(security.allocations, allocationIndex)"
                                        ><i class="far fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="tableDataCell textAlightRight">
                                    <div *ngFor="let allocation of security.allocations">
                                        <div class="allocationCell">
                                            {{ (security.concludedValue * allocation.stake) / 100 | number: "1.1-1"}}
                                        </div>
                                    </div>
                                </td>
                                <!-- <td class="tableDataCell"> {{ security.value }}</td> -->
                                <td class="tableDataCell textAlightRight">
                                    {{ security.percentageOfPar | number: "1.1-1" }}%
                                </td>
                                <td class="tableDataCell textAlightRight">
                                    <button 
                                        mat-raised-button 
                                        class="btn-custom-primary" 
                                        (click)="openAddAllocationPopUp(addAllocationPopUp, security.allocations)"
                                    > Add Allocation 
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    <div class="isLessStakeWarning" *ngIf="getIsLessStake(stakeholderAllocation)">
                        <span>The sum does not add up to 100%</span>
                    </div>
                    <!-- </div> -->
                </div>
                <div class="col-6"></div>
            </div>
        </ng-container>
        <div *ngIf="stakeholderSelections.securityDebtModelIds.size" class="section col table-margins">
            <button 
                mat-raised-button class="btn-custom-primary" 
                (click)="beforeGoingOutOfEditView()"
            > Save 
            </button>
            <!-- <p *ngIf="disableBtn" class="loadingTextStyle">Processing...</p> -->
        </div>
    </ng-template>
</ng-template>

<ng-template #addAllocationPopUp let-addAllocationPopUpModal>
    <div class="modal-header">
        <h4 class="modal-title"> Add Alocation </h4>
        <button type="button" class="close" aria-label="Close" (click)="addAllocationPopUpModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">

        <div class="row popupMargin" style="margin: 0px; width: 100%;">
            <div class="col-4"> Stakeholder User </div>

            <div class="col-8">
                <mat-form-field class="yellowBackground">
                    <!-- <input class="yellowBackground" matInput [(ngModel)]="allocationName" /> -->
                    <mat-select *ngIf="placeholderAllocation != null" [compareWith]="stakeholderUserCompareFn"
                        [(ngModel)]="placeholderAllocation.user" [required]="true">
                        <mat-option 
                            *ngFor="let stakeholderUser of stakeholderUsers"
                            [value]="stakeholderUser"
                        >{{ stakeholderUser.stakeholderUsername }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="isAllocationNameError">This name already exist!</mat-error>
            </div>
        </div>

        <div class="row popupMargin" style="margin: 0px; width: 100%;">
            <div class="col-4"> Stake Value </div>

            <div class="col-8">
                <ejs-numerictextbox
                    class="input-field yellow-input" style="width: 92%; border-radius: 6px;"
                    [(ngModel)]="placeholderAllocation.stake"
                    min='0'
                    max='100' 
                    format="#,###.0'%'" 
                    [showSpinButton]="false"
                    required
                >
                </ejs-numerictextbox>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <div class="row" style="margin: 5px; float: right; margin-right: 12px">
            <div class="col">
                <button 
                    matRaisedButton 
                    class="secondary-btn"
                    (click)="addAllocationPopUpModal.dismiss('Cross click')"
                >
                    Cancel
                </button>
            </div>
            <div class="col">
                <button 
                    matRaisedButton 
                    class="primary-btn" 
                    (click)="addAllocation()"
                >
                    <span *ngIf="isAllocationModeCreating()">
                        Add
                    </span>
                    <span *ngIf="isAllocationModeEditing()">
                        Update
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-template>