<nav-menu-header headerLabel='{{ translateService.getLabel("funds") }}' headerToBeAppended="StatusFundLevel" (click)="navigateToPortfolio()"></nav-menu-header>

<div class="top-header">
  <!-- <div class="row" style="float: right; margin-right: 0px;">
    <a (click)="navigateToPortfolio()" class="redirecting-link">
      Go Back To Portfolio Summary
    </a>
  </div> -->
  <span style="font-size: 1.34vw; line-height: 1.61vw;">
    {{translateService.getLabel("status_update")}}
    <ng-template [ngIf]="fundId">
      - {{fundService.getFundName(this.fundId)}}
    </ng-template>
  </span>
</div>

<div class="card" style="padding: 0.55vw;">
  <div class="row" style=" margin: auto 1.11vw; margin-bottom: 2%;">
    <div class="col-5 main-col">
      <span class="main-heading">{{translateService.getLabel("status_update")}}</span>
    </div>
  </div>

  <div class="row comman-row">
    <div class="col-2 market-valuation-date">
      <span>{{translateService.getLabel("valuation_date")}}</span>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill" class="valuation-date-select-type">
        <mat-label>{{translateService.getLabel("select")}}</mat-label>
        <mat-select [(value)]="selectedQuarter">
          <mat-option *ngFor="let valDate of valuationDates" [value]="valDate.value">
            {{valDate.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row comman-row">
    <div class="col-2 market-valuation-date">
      <span>{{translateService.getLabel("market_date")}}</span>
    </div>
    <div class="col-3">
      <mat-form-field appearance="fill" class="market-date-select-type">
        <mat-label>{{translateService.getLabel("select")}}</mat-label>
        <input matInput [matDatepicker]="picker" name="date" (dateInput)="getMarketDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row comman-row">
    <div class="col-4 reset-col">
      <!-- <button class="button comman-button reset-button">Reset</button> -->
    </div>
    <div class="col-1">
      <button
        [ngClass]="{'button fetch-button-disabled' : selectedQuarter === '' || selectedMarketDate === '', 'button fetch-button-enabled': selectedQuarter !== '' || selectedMarketDate !== ''}"
        (click)="getFundDetails()" [disabled]="selectedQuarter === '' || selectedMarketDate === ''">{{translateService.getLabel("fetch")}}</button>
    </div>
  </div>

  <div class="row status-row">
    <span>{{translateService.getLabel("status")}} -&nbsp;&nbsp;</span>
    <span
      [ngClass]="{'status-unknown' : statusHeader == 'Unknown', 'status-irregular': statusHeader == 'Irregularities Detected', 'status-regular': statusHeader == 'No Irregularities'}">{{translateService.getLabel(statusHeader)}}</span>
  </div>

  <div class="row table-row comman-row">
    <div class="col-7 util-lis-grid">
      <ejs-grid #usersListGrid [dataSource]="dataSource" [allowResizing]="true" [allowTextWrap]="true"
        [allowSorting]="true" [allowReordering]="true" [allowPaging]="true" [showColumnChooser]="true"
        [allowFiltering]="true" [filterSettings]="gridFilterSettings" [toolbar]='toolbarOptions' 
        [allowExcelExport]='true' (toolbarClick)='statusExcelExport()'>
        <e-columns>
          <e-column field='companyName' [headerText]="translateService.getLabel('company')" width='100px'></e-column>
          <e-column field='valDateId' [headerText]="translateService.getLabel('valuation_date')" width='100px' textAlign='Right'
            headerTextAlign='Right'>
            <ng-template #template let-data>{{data.valDateId? 'Initiated': 'Not Initiated'}}</ng-template>
          </e-column>
          <e-column field='marketDate' [headerText]="translateService.getLabel('market_date')" width='100px' textAlign='Right' headerTextAlign='Right'>
            <ng-template #template let-data>{{data.marketDate? data.marketDate: 'N/A'}}</ng-template>
          </e-column>
          <e-column field='status' [headerText]="translateService.getLabel('status')" width='100px' textAlign='Center' headerTextAlign='Center'>
            <ng-template #template let-data>
              <ng-template [ngIf]="data.valDateId && data.marketDate && data.marketDate == selectedMarketDate"
                [ngIfElse]="inCorrectData">
                <i class="fa fa-check-circle tick-icon" aria-hidden="true"></i>
              </ng-template>
              <ng-template #inCorrectData>
                <i class="fa fa-times-circle-o cross-icon" aria-hidden="true"></i>
              </ng-template>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>