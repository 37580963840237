import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  AttributesRow,
  Client,
  DataLoadStatus,
  FinancialsGDProfileRow,
  GxAttributeData,
  GxFinancialsGDProfileData, GxFinancialsGDProfileDetails,
  GxOrganizationUserData,
  GxUserCompanyAccessData, GxUserCompanyAccessDetails,
  OrganizationUsersRow,
  PlutoAttributeData,
  PlutoDataLoadStatus,
  PlutoFinancialsGDProfileData, PlutoFinancialsGDProfileDetails,
  PlutoOrganizationUserData,
  PlutoUserCompanyAccessData,
  PlutoUserCompanyAccessDetails,
  UserCompanyAccessRow
} from './data-recon-pluto.model';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataReconPlutoService {
  constructor(private dataService: DataService) {}

  public getClients(): Observable<Client[]> {
    return this.dataService.getPlutoDataReconClients()
      .pipe(
        map((res: HttpResponse<ApiResponse<Client[]>>) => {
          return res.body.response;
        })
      );
  }

  public getDataLoadStatus(orgIds: string[]): Observable<DataLoadStatus> {
    return forkJoin({
      gxData: this.dataService.getGxDataLoadStatus(orgIds) as Observable<HttpResponse<ApiResponse<string>>>,
      plutoData: this.dataService.getPlutoDataLoadStatus() as Observable<HttpResponse<ApiResponse<PlutoDataLoadStatus>>>,
    }).pipe(
      map(res => {
        return {
          gxLastUpdatedTime: res.gxData.body.response,
          plutoLastJobStartTime: res.plutoData.body.response.startTime,
          plutoLastJobSuccessTime: res.plutoData.body.response.lastSuccessTime,
          plutoJobStatus: res.plutoData.body.response.status,
        };
      })
    );
  }

  public getFinancialsGDProfileDetails(orgIds: string[]): Observable<FinancialsGDProfileRow[]> {
    return forkJoin({
      gxData:
        this.dataService.getGxFinancialsGDProfileDetails(orgIds) as Observable<HttpResponse<ApiResponse<GxFinancialsGDProfileData[]>>>,
      plutoData:
        this.dataService.getPlutoFinancialsGDProfileDetails(orgIds) as Observable<HttpResponse<ApiResponse<PlutoFinancialsGDProfileData[]>>>
    }).pipe(
      map(res => {
        return this.getFinancialsGDProfileDetailsDiff(res.gxData.body.response, res.plutoData.body.response);
      })
    );
  }

  public getAttributeDetails(orgIds: string[]): Observable<AttributesRow[]> {
    return forkJoin({
      gxData:
        this.dataService.getGxAttributeDetails(orgIds) as Observable<HttpResponse<ApiResponse<GxAttributeData[]>>>,
      plutoData:
        this.dataService.getPlutoAttributeDetails(orgIds) as Observable<HttpResponse<ApiResponse<PlutoAttributeData[]>>>,
    }).pipe(
      map(res => {
        return this.getAttributesDetailsDiff(res.gxData.body.response, res.plutoData.body.response);
      })
    );
  }

  public getUserCompanyAccessDetails(orgIds: string[]): Observable<UserCompanyAccessRow[]> {
    return forkJoin({
      gxData:
        this.dataService.getGxUserCompanyAccessDetails(orgIds) as Observable<HttpResponse<ApiResponse<GxUserCompanyAccessData[]>>>,
      plutoData:
        this.dataService.getPlutoUserCompanyAccessDetails(orgIds) as Observable<HttpResponse<ApiResponse<PlutoUserCompanyAccessData[]>>>,
    }).pipe(
      map(res => {
        return this.getUserCompanyAccessDetailsDiff(res.gxData.body.response, res.plutoData.body.response);
      })
    );
  }

  public getOrganizationUserDetails(orgIds: string[]): Observable<OrganizationUsersRow[]> {
    return forkJoin({
      gxData:
        this.dataService.getGxOrganizationUserDetails(orgIds) as Observable<HttpResponse<ApiResponse<GxOrganizationUserData[]>>>,
      plutoData:
        this.dataService.getPlutoOrganizationUserDetails(orgIds) as Observable<HttpResponse<ApiResponse<PlutoOrganizationUserData[]>>>,
    }).pipe(
      map(res => {
        return this.getOrganizationUserDetailsDiff(res.gxData.body.response, res.plutoData.body.response);
      })
    );
  }

  private getFinancialsGDProfileDetailsDiff(
    gxData: GxFinancialsGDProfileData[],
    plutoData: PlutoFinancialsGDProfileData[]
  ): FinancialsGDProfileRow[] {
    // Group at org level, and then at company level
    const orgMap: Record<string, Record<string, FinancialsGDProfileRow>> = {};
    plutoData.forEach((financialsGDProfileDetails) => {
      if (!orgMap[financialsGDProfileDetails.orgId]) {
        orgMap[financialsGDProfileDetails.orgId] = {};
      }
      financialsGDProfileDetails.companies.forEach((company) => {
        orgMap[financialsGDProfileDetails.orgId][company.companyId] =
          this.mapPlutoFinancialsGDProfileDetailsToRow(company, financialsGDProfileDetails.orgId, financialsGDProfileDetails.orgName);
      });
    });

    gxData.forEach((financialsGDProfileDetails) => {
      if (!orgMap[financialsGDProfileDetails.orgId]) {
        orgMap[financialsGDProfileDetails.orgId] = {};
      }
      financialsGDProfileDetails.companies.forEach((company) => {
        if (orgMap[financialsGDProfileDetails.orgId][company.companyId]) {
          orgMap[financialsGDProfileDetails.orgId][company.companyId] = {
            ...orgMap[financialsGDProfileDetails.orgId][company.companyId],
            ...this.mapGxFinancialsGDProfileDataToRow(company, financialsGDProfileDetails.orgId, financialsGDProfileDetails.orgName)
          };
        } else {
          orgMap[financialsGDProfileDetails.orgId][company.companyId] =
            this.mapGxFinancialsGDProfileDataToRow(company, financialsGDProfileDetails.orgId, financialsGDProfileDetails.orgName);
        }
      });
    });

    const data: FinancialsGDProfileRow[] = [];
    Object.values(orgMap).forEach((org) => {
      Object.values(org).forEach((row) => {
        // Calculate deltas
        row.gxFinancialsCount = row.gxFinancialsCount || 0;
        row.plutoFinancialsBaseCount = row.plutoFinancialsBaseCount || 0;
        row.plutoFinancialsFactCount = row.plutoFinancialsFactCount || 0;
        row.financialsDelta = row.gxFinancialsCount - row.plutoFinancialsFactCount;

        row.gxGeneralDetailsCount = row.gxGeneralDetailsCount || 0;
        row.plutoGeneralDetailsBaseCount = row.plutoGeneralDetailsBaseCount || 0;
        row.plutoGeneralDetailsFactCount = row.plutoGeneralDetailsFactCount || 0;
        row.generalDetailsDelta = row.gxGeneralDetailsCount - row.plutoGeneralDetailsFactCount;

        row.gxProfileDetailsCount = row.gxProfileDetailsCount || 0;
        row.plutoProfileDetailsBaseCount = row.plutoProfileDetailsBaseCount || 0;
        row.plutoProfileDetailsFactCount = row.plutoProfileDetailsFactCount || 0;
        row.profileDetailsDelta = row.gxProfileDetailsCount - row.plutoProfileDetailsFactCount;

        // Filter out the zero delta rows
        if (row.financialsDelta !== 0 || row.generalDetailsDelta !== 0 || row.profileDetailsDelta !== 0) {
          data.push(row);
        }
      });
    });

    return data;
  }

  private mapPlutoFinancialsGDProfileDetailsToRow(
    plutoData: PlutoFinancialsGDProfileDetails,
    organizationId: string,
    organizationName: string
  ): FinancialsGDProfileRow {
    return {
      organizationId,
      organizationName,
      companyId: plutoData.companyId,
      companyName: plutoData.companyName,
      plutoFinancialsBaseCount: plutoData.financialBaseCount || 0,
      plutoFinancialsFactCount: plutoData.financialFactCount || 0,
      plutoGeneralDetailsBaseCount: plutoData.generalDetailsBaseCount || 0,
      plutoGeneralDetailsFactCount: plutoData.generalDetailsFactCount || 0,
      plutoProfileDetailsBaseCount: plutoData.profileDetailsBaseCount || 0,
      plutoProfileDetailsFactCount: plutoData.profileDetailsFactCount || 0,
    } as FinancialsGDProfileRow;
  }

  private mapGxFinancialsGDProfileDataToRow(
    gxData: GxFinancialsGDProfileDetails,
    organizationId: string,
    organizationName: string
  ): FinancialsGDProfileRow {
    return {
      organizationId,
      organizationName,
      companyId: gxData.companyId,
      gxFinancialsCount: gxData.financialCount || 0,
      gxFinancialsLastUpdated: gxData.financialLastUpdatedOn,
      gxGeneralDetailsCount: gxData.generalDetailsCount || 0,
      gxGeneralDetailsLastUpdated: gxData.generalDetailsLastUpdatedOn,
      gxProfileDetailsCount: gxData.profileDetailsCount || 0,
      gxProfileDetailsLastUpdated: gxData.profileDetailsLastUpdatedOn,
    } as FinancialsGDProfileRow;
  }

  private getAttributesDetailsDiff(gxData: GxAttributeData[], plutoData: PlutoAttributeData[]): AttributesRow[] {
    // Group at org level
    const orgMap: Record<string, AttributesRow> = {};
    plutoData.forEach((attributeDetails) => {
      orgMap[attributeDetails.orgId] = this.mapPlutoAttributeDataToAttributesRow(attributeDetails);
    });

    gxData.forEach((attributeDetails) => {
      if (orgMap[attributeDetails.orgId]) {
        orgMap[attributeDetails.orgId] = { ...orgMap[attributeDetails.orgId], ...this.mapGxAttributeDataToAttributesRow(attributeDetails) };
      } else {
        orgMap[attributeDetails.orgId] = this.mapGxAttributeDataToAttributesRow(attributeDetails);
      }
    });


    return Object.values(orgMap)
      .map(row => {
        // Calculate deltas
        row.plutoGlobalAttributesFactCount = row.plutoGlobalAttributesFactCount || 0;
        row.gxGlobalAttributesCount = row.gxGlobalAttributesCount || 0;
        row.globalAttributesDelta = row.gxGlobalAttributesCount - row.plutoGlobalAttributesFactCount;

        row.plutoCustomAttributesFactCount = row.plutoCustomAttributesFactCount || 0;
        row.gxCustomAttributesCount = row.gxCustomAttributesCount || 0;
        row.customAttributesDelta = row.gxCustomAttributesCount - row.plutoCustomAttributesFactCount;

        row.plutoAttributeFormulaFactCount = row.plutoAttributeFormulaFactCount || 0;
        row.gxAttributeFormulaCount = row.gxAttributeFormulaCount || 0;
        row.attributeFormulaDelta = row.gxAttributeFormulaCount - row.plutoAttributeFormulaFactCount;

        row.plutoFormulaDetailsFactCount = row.plutoFormulaDetailsFactCount || 0;
        row.gxFormulaDetailsCount = row.gxFormulaDetailsCount || 0;
        row.formulaDetailsDelta = row.gxFormulaDetailsCount - row.plutoFormulaDetailsFactCount;

        row.plutoFormulaCompaniesFactCount = row.plutoFormulaCompaniesFactCount || 0;
        row.gxFormulaCompaniesCount = row.gxFormulaCompaniesCount || 0;
        row.formulaCompaniesDelta = row.gxFormulaCompaniesCount - row.plutoFormulaCompaniesFactCount;

        return row;
      })
      .filter((row) => {
        // Filter out the zero delta rows
        return row.globalAttributesDelta !== 0 || row.customAttributesDelta !== 0 || row.attributeFormulaDelta !== 0
          || row.formulaDetailsDelta !== 0 || row.formulaCompaniesDelta !== 0;
      });
  }

  private mapPlutoAttributeDataToAttributesRow(plutoData: PlutoAttributeData): AttributesRow {
    return {
      organizationId: plutoData.orgId,
      organizationName: plutoData.orgName,
      plutoGlobalAttributesBaseCount: plutoData.globalAttributeBaseCount || 0,
      plutoGlobalAttributesFactCount: plutoData.globalAttributeFactCount || 0,
      plutoCustomAttributesBaseCount: plutoData.customAttributeBaseCount || 0,
      plutoCustomAttributesFactCount: plutoData.customAttributeFactCount || 0,
      plutoAttributeFormulaBaseCount: plutoData.attributeFormulaBaseCount || 0,
      plutoAttributeFormulaFactCount: plutoData.attributeFormulaFactCount || 0,
      plutoFormulaDetailsBaseCount: plutoData.formulaDetailsBaseCount || 0,
      plutoFormulaDetailsFactCount: plutoData.formulaDetailsFactCount || 0,
      plutoFormulaCompaniesBaseCount: plutoData.formulaDetailsCompanyBaseCount || 0,
      plutoFormulaCompaniesFactCount: plutoData.formulaDetailsCompanyFactCount || 0,
    } as AttributesRow;
  }

  private mapGxAttributeDataToAttributesRow(gxData: GxAttributeData): AttributesRow {
    return {
      organizationId: gxData.orgId,
      organizationName: gxData.orgName,
      gxGlobalAttributesCount: gxData.globalAttributeCount || 0,
      gxGlobalAttributesLastUpdated: gxData.globalAttributeLastUpdatedOn,
      gxCustomAttributesCount: gxData.customAttributeCount || 0,
      gxCustomAttributesLastUpdated: gxData.customAttributeLastUpdatedOn,
      gxAttributeFormulaCount: gxData.attributeFormulaCount || 0,
      gxAttributeFormulaLastUpdated: gxData.attributeFormulaLastUpdatedOn,
      gxFormulaDetailsCount: gxData.formulaDetailsCount || 0,
      gxFormulaDetailsLastUpdated: gxData.formulaDetailsLastUpdatedOn,
      gxFormulaCompaniesCount: gxData.formulaDetailsCompanyCount || 0,
      gxFormulaCompaniesLastUpdated: gxData.formulaDetailsCompanyLastUpdatedOn,
    } as AttributesRow;
  }

  private getUserCompanyAccessDetailsDiff(
    gxData: GxUserCompanyAccessData[],
    plutoData: PlutoUserCompanyAccessData[]
  ): UserCompanyAccessRow[] {
    // Group at org level, and then at user level
    const orgMap: Record<string, Record<string, UserCompanyAccessRow>> = {};
    plutoData.forEach((userCompanyAccess) => {
      if (!orgMap[userCompanyAccess.orgId]) {
        orgMap[userCompanyAccess.orgId] = {};
      }
      const users = userCompanyAccess.users;
      users.forEach((user) => {
        const userUuid = user.userUuid || user.userId;
        orgMap[userCompanyAccess.orgId][userUuid] =
          this.mapPlutoUserCompanyAccessDataToUserCompanyAccessRow(user, userCompanyAccess.orgId, userCompanyAccess.orgName);
      });
    });

    gxData.forEach((userCompanyAccess) => {
      if (!orgMap[userCompanyAccess.orgId]) {
        orgMap[userCompanyAccess.orgId] = {};
      }
      const users = userCompanyAccess.users;
      users.forEach((user) => {
        const userUuid = user.userUuid;
        if (orgMap[userCompanyAccess.orgId][userUuid]) {
          orgMap[userCompanyAccess.orgId][userUuid] = {
            ...orgMap[userCompanyAccess.orgId][userUuid],
            ...this.mapGxUserCompanyAccessDataToUserCompanyAccessRow(user, userCompanyAccess.orgId, userCompanyAccess.orgName),
          };
        } else {
          orgMap[userCompanyAccess.orgId][userUuid] =
            this.mapGxUserCompanyAccessDataToUserCompanyAccessRow(user, userCompanyAccess.orgId, userCompanyAccess.orgName);
        }
      });
    });

    const data: UserCompanyAccessRow[] = [];

    // Calculate deltas
    Object.values(orgMap).forEach((org) => {
      Object.values(org).forEach((row) => {
        row.plutoUserCompaniesBaseCount = row.plutoUserCompaniesBaseCount || 0;
        row.plutoUserCompaniesFactCount = row.plutoUserCompaniesFactCount || 0;
        row.gxUserCompaniesCount = row.gxUserCompaniesCount || 0;
        row.userCompaniesDelta = row.gxUserCompaniesCount - row.plutoUserCompaniesFactCount;
        // Filter out the zero delta rows
        if (row.userCompaniesDelta !== 0) {
          data.push(row);
        }
      });
    });

    return data;
  }

  private mapPlutoUserCompanyAccessDataToUserCompanyAccessRow(
    plutoData: PlutoUserCompanyAccessDetails,
    organizationId: string,
    organizationName: string
  ): UserCompanyAccessRow {
    return {
      organizationId,
      organizationName,
      userUuid: plutoData.userUuid,
      userId: plutoData.userId,
      userName: plutoData.userName,
      plutoUserCompaniesBaseCount: plutoData.companyBaseCount || 0,
      plutoUserCompaniesFactCount: plutoData.companyFactCount || 0,
    } as UserCompanyAccessRow;
  }

  private mapGxUserCompanyAccessDataToUserCompanyAccessRow(
    gxData: GxUserCompanyAccessDetails,
    organizationId: string,
    organizationName: string
  ): UserCompanyAccessRow {
    return {
      organizationId,
      organizationName,
      userUuid: gxData.userUuid,
      userId: gxData.userId,
      userName: gxData.userName,
      gxUserCompaniesCount: gxData.companyCount || 0,
    } as UserCompanyAccessRow;
  }

  private getOrganizationUserDetailsDiff(
    gxData: GxOrganizationUserData[],
    plutoData: PlutoOrganizationUserData[]
  ): OrganizationUsersRow[] {
    // Group at org level
    const orgMap: Record<string, OrganizationUsersRow> = {};

    plutoData.forEach((organization) => {
      orgMap[organization.orgId] = this.mapPlutoOrganizationUserDataToOrganizationUsersRow(organization);
    });

    gxData.forEach((organization) => {
      if (orgMap[organization.orgId]) {
        orgMap[organization.orgId] = {
          ...orgMap[organization.orgId],
          ...this.mapGxOrganizationUserDataToOrganizationUsersRow(organization),
        };
      } else {
        orgMap[organization.orgId] = this.mapGxOrganizationUserDataToOrganizationUsersRow(organization);
      }
    });

    // Filter out the zero delta rows
    return Object.values(orgMap)
      .map(row => {
        // calculate deltas
        row.plutoUsersBaseCount = row.plutoUsersBaseCount || 0;
        row.plutoUsersFactCount = row.plutoUsersFactCount || 0;
        row.gxUsersCount = row.gxUsersCount || 0;
        row.usersDelta = row.gxUsersCount - row.plutoUsersFactCount;
        return row;
      })
      .filter((row) => {
        return row.usersDelta !== 0;
      });
  }

  private mapPlutoOrganizationUserDataToOrganizationUsersRow(plutoData: PlutoOrganizationUserData): OrganizationUsersRow {
    return {
      organizationId: plutoData.orgId,
      organizationName: plutoData.orgName,
      plutoUsersBaseCount: plutoData.userBaseCount || 0,
      plutoUsersFactCount: plutoData.userFactCount || 0,
    } as OrganizationUsersRow;
  }

  private mapGxOrganizationUserDataToOrganizationUsersRow(gxData: GxOrganizationUserData): OrganizationUsersRow {
    return {
      organizationId: gxData.orgId,
      organizationName: gxData.orgName,
      gxUsersCount: gxData.userCount || 0,
    } as OrganizationUsersRow;
  }
}
