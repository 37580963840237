import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "src/app/services/translation.service";
import { UserManagementService } from "src/app/services/user-management.service";
import { ApprovalMatrixService } from "../../check-list-pop-up/approval-matrix/approval-matrix.service";

@Component({
  selector: "app-old-approval-status",
  templateUrl: "./old-approval-status.component.html",
  styleUrls: ["./old-approval-status.component.scss"]
})
export class OldApprovalStatusComponent implements OnInit {

  @Input() item;

  constructor(
    public approvalMatrixService: ApprovalMatrixService,
    public translateService: TranslateService,
    public ums: UserManagementService,
  ) {}

  ngOnInit() {
  }

}
