<ng-template [ngIf]="restructuredSecurities?.length > 0">
    <ejs-grid #restructuredSecuritiesTable [dataSource]="restructuredSecurities" [allowResizing]="true"
      [allowTextWrap]="true" [allowGrouping]="false" [allowExcelExport]="true" [allowSorting]="true"
      [allowReordering]="true" [allowPaging]="false" [pageSettings]="gridPageSettings" [showColumnChooser]="true"
      [toolbar]="gridToolbar" [allowFiltering]="true" [filterSettings]="gridFilterSettings" (rowSelected)= "navigatewaterfallAnalysis($event)"
      >
      <!-- (rowSelected)="companyRowSelected($event)" (actionComplete)="saveTableState($event, CONSOLIDATED_SUMMARY_TABLE)" -->
      <e-columns>
  
        <e-column field='securityName' [headerText]='translateService.getLabel("security_name")' width='75px' textAlign="left">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp.securityName">
              {{comp.securityName }}
            </ng-template>
          </ng-template>
        </e-column>
  
        <e-column field='valuationDate' [headerText]='translateService.getLabel("valuation_date")' width='70px' textAlign="left">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp.valuationDate">
              {{comp.valuationDate | date:'mediumDate' }}
            </ng-template>
          </ng-template>
        </e-column>
        <e-column field='totalValue' [headerText]='translateService.getLabel("fair_value")' width='60px' textAlign="right">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp.totalValue || comp.totalValue === 0">
              {{comp.totalValue | number: "1.1-1"}}            
            </ng-template>
          </ng-template>
        </e-column>
        <e-column field='totalPercentPar' [headerText]='translateService.getLabel("of_par")' width='70px' textAlign="right">
          <ng-template #template let-comp>
            <ng-template [ngIf]="comp.totalPercentPar || comp.totalPercentPar === 0 ">
              <ng-container *ngIf="(comp.totalPercentPar | typeOf) === 'string'">
                {{comp.totalPercentPar}} 
              </ng-container>
              <ng-container *ngIf="(comp.totalPercentPar | typeOf) === 'number'">
                {{comp.totalPercentPar | number: "1.1-1"}}%  
              </ng-container>
            </ng-template>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </ng-template>