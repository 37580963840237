import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Router, ActivatedRoute } from '@angular/router';

import { DataService } from 'src/app/services/data.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from '../fund-list-ui/fund-list.service';

import Highcharts from 'highcharts';
import { ValuationAlgorithmService } from '../valuation-algorithms/valuation-algorithms.service';
import { Chart } from 'angular-highcharts';
import { DatePipe } from '@angular/common';
import { colors } from 'src/app/utils/colors';
import { MessageService } from 'src/app/services/message.service';
import { PortFolioSummaryServiceV2 } from './portfolio-summary-v2.service';
import { CurrencyExchangeService } from 'src/app/services/currency-exchange.service';
import { TranslateService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-portfolio-summary-v2',
  templateUrl: './portfolio-summary-v2.component.html',
  styleUrls: ['./portfolio-summary-v2.component.scss']
})
export class PortfolioSummaryV2Component implements OnInit {
  fundId;

  transactions = false;

  constructor(private ums: UserManagementService, 
    private dataService: DataService, public utilService: UtilService,
    public portfolioService: PortFolioSummaryServiceV2, private router:Router,
    private activatedRoute: ActivatedRoute, private valuationAlgorithms : ValuationAlgorithmService,
    public fundService: FundListService, private datePipe : DatePipe,
    private ms: MessageService, public currencyService: CurrencyExchangeService, public translateService : TranslateService) 
  {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(async params => {
      this.fundId = params.get("fundId");
      // try {
      //   const currencyResponse = await this.dataService.getWidgetDataFromDB("PORTFOLIO_SUMMARY_DATA", this.fundId).toPromise();
      //   let widgetData = currencyResponse["body"]["response"][0].widgetData;
      //   this.portfolioService.selectedCurrency = widgetData.selectedCurrency;
      //   this.portfolioService.mapData.value = widgetData.valuationMap;
      // }
      // catch(e) {
      //   console.log("Error Retrieving data from Widget", e);
      // }

      this.portfolioService.init(this.fundId);
    });
  }
}
