import { Injectable, Injector } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { InvestmentSummaryService } from '../qubit-x/portfolio/investment-page-summary-v2/investment-summary.service';
import { FundListService } from '../qubit-x/portfolio/fund-list-ui/fund-list.service';
import { PortFolioSummaryServiceV2 } from '../qubit-x/portfolio/portfolio-summary-v2/portfolio-summary-v2.service';
import { UtilService } from '../utils/util.service';
import { UserManagementService } from './user-management.service';
import { DataService } from './data.service';
import { TranslateService } from './translation.service';
import { MENU_LIST } from '../side-menu/side-menu.data';
import { ProductKey } from '../utils/s73-product';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(public router: Router, public userManagementService: UserManagementService,
    public portfolioService: PortFolioSummaryServiceV2,
    private utilService: UtilService,
    private route: ActivatedRoute,  private injector: Injector,
    private ds: DataService, private translateService: TranslateService,
    public fundService: FundListService) {

      this.menuList = MENU_LIST;
    }

  public isExternalUser: boolean = true;
  public isCorpUser: boolean = false;
  public userName = "";
  public shortUserName = "";
  
  currentPage = "users";

  previousPage = null;

  public fundId = localStorage.getItem('FUND_ID');

  userAccessTools=[];

  hasCreditOrGxAccess = false;

  portCoOrgId = 'fc800105-7094-447d-8447-5108e8836ac8';// this ID will be same on UAT and PROD
  campaignsAppFragment = 'campaign-portal/#/dashboard';

  public menuList : any [];

  async enableSideMenuItems() {
    await this.getAccessTools();

    const userId = this.userManagementService.getSelectedUserDetails().id;
    if(userId) {
      await this.setUserProductAccess(userId);

      if(!this.hasCreditOrGxAccess) { // equity only access, then hide ems-home
        this.menuList = this.menuList.filter(m => m.toolKey != "ems-home");
      }
    }

    this.prepareMenuNavItems(this.router.url);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.prepareMenuNavItems(event.url);
      }
    });
  }

  prepareMenuNavItems(url) {
    
    if (url.includes('/users')) {
      this.currentPage = "users";
      localStorage.removeItem("73s-selected-vc");
      this.previousPage = null;

    } else if (url.includes('/fundList')) {
      this.currentPage = "fundList";
      this.previousPage = null;

    } else if (url.includes('/portfolio/')) {      
      this.currentPage = "portfolioSummary";
      this.previousPage = { navigationKey: "fundList", labelKey: "funds" };

    } else if (url.includes('/company-valuation/')) {
      this.currentPage = "companyValuation";
      this.previousPage = { navigationKey: "portfolioSummary", labelKey: "portfolio_summary" };
      
    } else {
      this.currentPage = this.getCurrentPageByURL(url);
      this.previousPage = this.getPreviousPage();
    }

    

    console.log("Current Page", this.currentPage, this.previousPage);
    
    this.userBasedMenuItems();
    
    this.enableMenuItemsBasedOnUserPermissions(this.menuList)
  }

  enableMenuItemsBasedOnUserPermissions(menuItems: any []) {
    const isMasterUser = this.userManagementService.isMasterUser();
    const isSuperMasterUser = this.userManagementService.is73sAdminAndMasterUser();
    const is73sUser = this.userManagementService.is73sAdmin()

    const selectedUser = this.userManagementService.getSelectedUserDetails();

    menuItems.forEach(m => {
      m["show"] = this.currentPage == "users" || m.superMaster ? "DISABLED" : "HIDDEN";

      let showInPage = false;

      if(m[this.currentPage]) {
        showInPage = true;

      } else if(m["allPage"]) {
        showInPage = true;
      }
      
      if(m.masterItem && !isMasterUser) {
        m["show"] = "HIDDEN";
        return;

      }

      if(m.superMaster && !isSuperMasterUser) {
        m["show"] = "HIDDEN";
        return;

      }

      if(m.is73sUser && !is73sUser){
        m["show"] = "HIDDEN";
        return;
      }
     
      if(m.toolKey) {
        this.userAccessTools.forEach(accessibleTool => {

          if(accessibleTool.key == m.toolKey && accessibleTool.key) {
            m["show"] = showInPage ? "SHOW" : "DISABLED";
          }
        });
      } 

      let portfolioSummary = m.portfolioSummary;
      let companyValuation = m.companyValuation;

      if(m.subMenuItems && m.subMenuItems.length > 0) {
        
        this.enableMenuItemsBasedOnUserPermissions(m.subMenuItems);

        for (let s of m.subMenuItems) {
          if (s["show"] == "SHOW") {
            m["show"] = "SHOW";
            break;
          } else if (s["show"] == "DISABLED") {
            m["show"] = "DISABLED";
            if(s.portfolioSummary){
              portfolioSummary = true;
            }

            if(s.companyValuation){
              companyValuation = true;
            }
          }
        }
      } else if(m.masterItem && (isMasterUser || isSuperMasterUser)) {
        if(m["show"] != "HIDDEN"){
          m["show"] = showInPage ? "SHOW" : "DISABLED";
        }
      } else if(m.superMaster && isSuperMasterUser) {
        if(m["show"] != "HIDDEN"){
          m["show"] = showInPage ? "SHOW" : "DISABLED";
        }
      } else if(m.is73sUser && is73sUser){
        m["show"] = showInPage ? "SHOW" : m["show"];
      }

      else if(m.accessForAll) {
        m["show"] = showInPage ? "SHOW" : "DISABLED";
      }

      if(m["show"] == "DISABLED") {
        if(!portfolioSummary && companyValuation){
          m.toolTip = "Please enter company to access this"
        }
        else if(portfolioSummary){
          m.toolTip = "Please enter fund to access this"
        }
      }
      else{
        m.toolTip = "";
      }
    })
  }

  userBasedMenuItems() {
    const details = this.userManagementService.getUserDetails()
    this.userName = details.userName || " ";
    this.shortUserName = this.userName.charAt(0).toUpperCase();

    this.isExternalUser = this.userManagementService.isExternalUser();

    const token = localStorage.getItem("73S-Auth");
    if (token) {
      let orgType = (details.organization.orgType);

      if (orgType == "corp") {
        this.isCorpUser = true;
      }
    }
  }

  openSidenavLinks(menu) {
    if(menu.hide) return;
    let routeLink = menu.link;
    
    console.log("routeLink", routeLink);
    if (routeLink == 'approval-matrix' || routeLink == 'dashboard-equity' || routeLink == 'dashboard-debt' || routeLink == 'scheduler' || routeLink == '/globalcomps' || routeLink == 'embedded-reports-ui' || routeLink == '/mandates' || routeLink.includes('/power-bi')) {
      this.openPortal(routeLink);
    } else if(routeLink == 'value-bridge-fund-level'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)

      let url = this.router.url;
      let id = url.split("/")

      let customSummary = id.includes('customSummary');
      
      if(customSummary){
        this.router.navigateByUrl(routeLink + '/' + id[id.length - 1] + '?reportId=' + id[id.length - 1]);
      }
      else{
        this.router.navigate([routeLink + '/' + ps.selectedFundId]);
      }

        // this.router.navigate([routeLink + '/' + ps.selectedFundId]);
    }
    else if(routeLink == '/monitoring'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      //this.router.navigate([routeLink], { queryParams: { parentId: ps.selectedFundId } });
      window.open(environment.portalUrl + "/monitoring/#/portfolio-summary?parentId=" + ps.selectedFundId, "_self");
    } else if(routeLink == '/v2-monitoring'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      //this.router.navigate([routeLink], { queryParams: { parentId: ps.selectedFundId } });
      window.open(environment.portalUrl + "/v2-monitoring/#/portfolio-summary?parentId=" + ps.selectedFundId, "_self");
    }else if(routeLink == 'status-fund-level'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      this.router.navigate([routeLink + '/' + ps.selectedFundId]);

    } else if(routeLink == 'portfolio-currency-dashboard'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      this.router.navigate([routeLink + '/' + ps.selectedFundId]);

    } else if(routeLink == '/investments') {
      const is = this.injector.get(InvestmentSummaryService);
      const ps = this.injector.get(PortFolioSummaryServiceV2);

      this.router.navigate([routeLink], { queryParams: { companyId: is.companyId, parentId: ps.selectedFundId } });

    } else if(routeLink == '/data-management'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      this.router.navigate([routeLink], { queryParams: { fundId: ps.selectedFundId } });

    } else if(routeLink == '/leverage-ratio'){
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      this.router.navigate([routeLink], { queryParams: { fundId: ps.selectedFundId } });

    }else if(routeLink == '/simulation'){
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      window.open(environment.portalUrl + environment.simulation + "/#/" + ps.selectedFundId);

    } else if(routeLink == '/credit-rating-upload'){
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      this.router.navigate([routeLink], { queryParams: { fundId: ps.selectedFundId } });
    } 
    else if (routeLink == '/admin-uam') { 
      window.open(environment.portalUrl + "/uam/#/admin-uam");
    }
    else if(routeLink == '/configuration-v2') {
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      this.router.navigate([routeLink + '/' + ps.selectedFundId]);
    } 
    else if (routeLink == 'wopi') { 
      window.open(environment.portalUrl + "/excel/");
    }
    else if (routeLink == 'approval-matrix-new-2.0') { 
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      window.open(environment.portalUrl + "/approval-mgmt/#/approval-setup?parentId=" + ps.selectedFundId + "&moduleType=equity");
    }
    else if (routeLink == 'transactions' || routeLink == 'company-master-list') {
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      this.router.navigate([routeLink + '/' + ps.selectedFundId]);
    } else if (routeLink == '/mapping-configuration') {
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      if(menu.toolKey === 'credit_mapping_management') {
        this.router.navigate([routeLink + '/' + ps.selectedFundId], { queryParams: {
          for: 'credit'
        }, queryParamsHandling: 'merge'});
      } else {
        this.router.navigate([routeLink + '/' + ps.selectedFundId]);
      }
    } else if (routeLink == '/data-recon') {
      const ps = this.injector.get(PortFolioSummaryServiceV2);
      this.router.navigate([routeLink]);
    } 
    else if(routeLink == '/ems-home' && this.hasCreditOrGxAccess) {
      window.open(environment.portalUrl + "/v2-home","_self");
    } 
    else {
      this.router.navigate([routeLink], { relativeTo: this.route });
    }
  }

  openPortal(portalName) {
    if (portalName === 'dashboard-equity') {
      window.open(environment.portalUrl + "/dashboard/#/dashboard-equity");
    } else if (portalName === 'dashboard-debt') {
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      if(ps?.selectedFundId) {
        window.open(environment.portalUrl + "/dashboard/#/dashboard-home?parentId=" + ps.selectedFundId + "&appType=DEBT_DOWNLOAD");
      } else {
        window.open(environment.portalUrl + "/dashboard/#/dashboard-home?appType=DEBT_DOWNLOAD");
      }
    } else if (portalName === 'approval-matrix') {
      window.open(environment.portalUrl + "/approval-matrix");
    } else if (portalName === 'scheduler') {
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      const fs = this.injector.get(FundListService)
      const url = environment.portalUrl + "/scheduler/?parentId=" + ps.selectedFundId + "&parentName=" + fs.getFundName(ps.selectedFundId);
      
      window.open(url);
      
    } else if (portalName === '/globalcomps') {
      window.open(environment.portalUrl + "/globalcomps");
    } else if (portalName === '/mandates') {
      const ps = this.injector.get(PortFolioSummaryServiceV2)
      window.open(environment.portalUrl + "/mandates/#/homeV2/" + ps.selectedFundId);
    } else if (portalName === 'embedded-reports-ui') {
      window.open(environment.portalUrl + "/embedded-reports-ui");
    } else if(portalName === 'power-bi/#/?reportType=credit') {
      window.open(`${environment.portalUrl}/power-bi/#/?reportType=credit`)
    }else {
      window.open(environment.portalUrl + portalName);
    }
    
  }

  getPreviousPage() {
    switch(this.currentPage) {
      case "users":        
       if (this.userManagementService.is73sAdmin()) {
        return { navigationKey: "users", labelKey: "users" }
      } else {
        return null;
      };
      case "fundList": return { navigationKey: "fundList", labelKey: "funds" };
      case "portfolioSummary": return { navigationKey: "portfolioSummary", labelKey: "portfolio_summary" };
      case "companyValuation": return { navigationKey: "companyValuation", labelKey: "investment_summary" }
    }

    return { navigationKey: "fundList", labelKey: "funds"};
  }

  openPreviousPage() {
    switch(this.previousPage.navigationKey) {
      case "users":       
      window.open(environment.portalUrl + '/#/users', '_self');
      break;

      case "fundList":       
        window.open(environment.portalUrl + '/#/fundList', '_self');
        break;
    
      case "portfolioSummary": {
        const ps = this.injector.get(PortFolioSummaryServiceV2);
        window.open(environment.portalUrl + '/#/portfolio/' + ps.selectedFundId, '_self');
        break;
      }
    
      case "companyValuation": {
        const ps = this.injector.get(PortFolioSummaryServiceV2);
        const is = this.injector.get(InvestmentSummaryService);
        window.open(environment.portalUrl + '/#/company-valuation/' + is.companyId + "?parentId=" + ps.selectedFundId, '_self');
        break;
      }
    }
  }

  async getAccessTools(){
    try{
      const userId = this.userManagementService.getSelectedUserDetails().id;
      
      this.userAccessTools = [];
      const res = await this.ds.getAccessTools(userId).toPromise();

      let accessTools = res.body["response"]

      accessTools.user.userProducts.forEach((product , i)=>{
        product.tools.forEach((tool) =>{
          if(this.userAccessTools.findIndex( f => f.id == tool.id) == -1){
            this.userAccessTools.push(tool)
          }
        })
      })
    }catch(error){
      console.log("error to fetch data")
    }
  }

  async setUserProductAccess(userId) {

    let redirectToHome = false;

    try {
      const resp = await this.ds.getProductAccessByUserId(userId);
      console.log('USER...ACCESS', resp.body.response);
      const productList = resp.body.response || [];
      let productAccessLookUp = {};
      productList.forEach((p) => {
        productAccessLookUp[p.productKey] = true;
      });

      if(productAccessLookUp[ProductKey.GRAVITON] || productAccessLookUp[ProductKey.CREDIT]) {
       redirectToHome = true;
      } else {
        redirectToHome = false
      }
      this.hasCreditOrGxAccess = redirectToHome;
    } catch (e) {
      console.log('Failed to get user product access...', e);
    }
  }

  async navigateToSpecificApp(user) {
    await this.setUserProductAccess(user.id);
    
    let orgApps = [];
    let details;
    if(user?.organization?.id && user.organization.id === this.portCoOrgId) {
      console.log('Redirecting to...', `${environment.portalUrl}/${this.campaignsAppFragment}`);
      window.open(`${environment.portalUrl}/${this.campaignsAppFragment}`,'_self');
      return;
    }
    if(user?.organization?.details) {
      details = JSON.parse(user.organization.details);
      if(details?.apps) {
        orgApps = details.apps;
      }
    }

    if(this.hasCreditOrGxAccess) {
      window.open(environment.portalUrl + "/v2-home","_self");
     return;
    } else {
      this.router.navigateByUrl('/fundList');
    }

 
    // if(orgApps.includes("monitoring")) {
    //   if(details?.monitoringConfig?.defaultFund) {
    //     const defaultFundId = details?.monitoringConfig?.defaultFund;
    //     window.open(environment.portalUrl + "/monitoring/#/portfolio-summary?parentId=" + defaultFundId, "_self");
    //   } else if(!details?.monitoringConfig?.defaultFund) {
    //     const userSpecificConfig = details?.monitoringConfig?.userSpecificConfig;
    //     const userId = this.userManagementService.getSelectedUserDetails().id;
        
    //     if(userSpecificConfig && userSpecificConfig[userId] && userSpecificConfig[userId]?.defaultFund) {
    //       const defaultFundId = userSpecificConfig[userId]?.defaultFund
    //       window.open(environment.portalUrl + "/monitoring/#/portfolio-summary?parentId=" + defaultFundId, "_self");
    //     } else {
    //       // this.router.navigateByUrl('/fundList');
    //       window.open(environment.portalUrl + "/v2-home","_self");
    //       // new v2 home
    //     }
    //   }else {
    //     this.ds.getAllFunds(user).subscribe(res =>{
    //       let allFunds = res.body["response"];
    //       let firstFundId = "";
    //       if(allFunds.length !=0) {
    //         firstFundId = allFunds[0].fund.id;
    //       }
    //       if(firstFundId) {
    //       window.open(environment.portalUrl + "/monitoring/#/portfolio-summary?parentId=" + firstFundId, "_self");
    //       } else {
    //         this.router.navigateByUrl('/fundList');
    //       }
    //     });
    //   }

    // } else if(hasQxAndGxAccess) {
    //   window.open(environment.portalUrl + "/v2-home","_self");
    // } else {
    //   this.router.navigateByUrl('/fundList');
    // }
  }

  getCurrentPageByURL(url) {
    const urlWithoutSlashes = url.replace(/\//g, " ").trim();
    const urlWithoutQuestionMark = urlWithoutSlashes.replace(/\?/g, " ").trim();
    let currentPageUrl = urlWithoutQuestionMark.split(" ")[0];

    switch(currentPageUrl) {
      // These pages will show menu items same as Users page
      case 'scheduled-upgrade':
      case 'product-integration':
      case 'profile':
      case 'my-profile':
      case 'user-management':
      case 'text-extract':
      case 'market-size':
      case 'exit-simulator':
      case 'personality-analysis':
      case 'reviews-visualization':
      case 'background-check':
      case 'new-company':
      case 'search-home':
      case 'twitter-analysis':
      case 'company-screener':
      case 'contact-details':
      case 'corporates':
      case 'tools-home':
      case "replicate-funds":
      case 'admin':

        return "users";

      // These pages will show menu items same as fundList page

      case 'leverage-ratio':
      case 'fund-allocation':
      case 'ems-home':

        return "fundList"

      // These pages will show menu items same as portfolioSummary page
      case 'exit-simulation-v2':
      case 'status':
      case 'shared-access':
      case 'debt-company-valuation':
      case 'debt-company-summary':
      case 'data-management':
      case 'portfolio-currency-dashboard':
      case 'value-bridge-fund-level':
      case 'status-fund-level':      
      case 'transactions':
      case 'credit-rating-upload':
      case 'configuration-v2':
      case 'company-master-list':

        return "portfolioSummary";

      // These pages will show menu items same as companyValuation page
      case 'investmentPageSummaryV2':
      case 'customSummary':
      case 'oldInvestments':
      case 'investments':

        return "companyValuation";

    }

    return "fundList";
  }

}
