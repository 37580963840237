  
<button mat-icon-button class="primary-color" (click)="openTrackRecordPrefPopup(trackRecordPreferencePopup)" >
    <mat-icon>tune</mat-icon>
</button>


<ng-template #trackRecordPreferencePopup let-trackRecordPreferenceModal>
    <div class="modal-header">
        <div class="modal-title">
            {{ translateService.getLabel("show_hide_track_record_rows") }}
        </div>
        <button type="button" class="close" aria-label="Close"
            (click)="trackRecordPreferenceModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: unset; overflow: hidden;">
        <div style="max-height: 50vh; overflow-y: scroll; overflow-x: hidden;" *ngIf="!fetchingPreferences">
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricOneKey + '_primary']"></mat-checkbox>
                </div>
                <div class="col">{{metricOneLabel}}  ({{ primaryPeriodLabel }}) </div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricTwoKey + '_secondary']"></mat-checkbox>
                </div>
                <div class="col">{{metricTwoLabel}} ({{ secondaryPeriodLabel }}) </div>
            </div>

            <div class="row" style="padding: 4px 0;" *ngIf="this.metricThreeKey">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricThreeKey + '_tertiary']"></mat-checkbox>
                </div>
                <div class="col">{{metricThreeLabel}} ({{ tertiaryPeriodLabel }}) </div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricOneKey + 'Multiple' + '_primary']"></mat-checkbox>
                </div>
                <div class="col">{{metricOneLabel}} {{ translateService.getLabel("multiple") }} ({{ primaryPeriodLabel }}) </div>
            </div>
            <div class="row" style="padding: 4px 0;" >
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricTwoKey + 'Multiple' + '_secondary']"></mat-checkbox>
                </div>
                <div class="col">{{metricTwoLabel}} {{ translateService.getLabel("multiple") }} ({{ secondaryPeriodLabel }}) </div>
            </div> 
            <div class="row" style="padding: 4px 0;" *ngIf="this.metricThreeKey">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[metricThreeKey + 'Multiple' + '_tertiary']"></mat-checkbox>
                </div>
                <div class="col">{{metricThreeLabel}} {{ translateService.getLabel("multiple") }}  ({{ tertiaryPeriodLabel }}) </div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.enterpriseValue"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("enterprise_value") }}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.netDebt"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("netDebt_and_other_balanceSheet_adjustments") }}</div>
            </div>

            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.equityValue"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("equity_value") }} (100%)</div>
            </div>

            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.adjustmentsToEquityValue"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("adjustments_to_equity_value") }}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.adjustedEquityValue"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("adjusted_equity_value") }} (100%)</div>
            </div>

            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.adjustedEquityValueWaterfall"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("adjusted_equity_value_waterfallInputs") }}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.stake"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("stake") }} (%)</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.stakeValue"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("stake_equity_value") }}</div>
            </div>

            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.debtAndOthers"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("adjustment_to_stake_equity_value") }}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.value"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("value") }} {{fundListService.getFundName(fundId)}}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.investedAmount"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("invested_amount") }} {{fundListService.getFundName(fundId)}}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.realisedProceeds"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("proceeds") }} {{fundListService.getFundName(fundId)}}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.investmentMultiple"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("investment_multiple") }} {{fundListService.getFundName(fundId)}}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;" *ngIf="!isEZ">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.dpi"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("dpi") }}</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.irr_YTD"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("ytd_irr")}} (%)</div>
            </div>
            
            <div class="row" style="padding: 4px 0;">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences.irr"></mat-checkbox>
                </div>
                <div class="col">{{ translateService.getLabel("IRR") }}</div>
            </div>

            
            <div class="row" style="padding: 4px 0;" *ngFor="let attr of customAttributes">
                <div class="col-2">
                    <mat-checkbox [(ngModel)]="preferences[attr.attributeKey]"></mat-checkbox>
                </div>
                <div class="col">{{attr.attributeName}}</div>
            </div>
        </div>
    </div>

    <div style="text-align: right; padding: 5px; border-top: 1px solid lightgrey;">
        <button mat-raised-button class="primary-bg" type="submit" 
            [disabled]="fetchingPreferences"
            (click)="save(); trackRecordPreferenceModal.dismiss('Cross click')">
            {{ translateService.getLabel("save") }}
        </button>
    </div>
</ng-template>