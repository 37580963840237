import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VersionList } from '../version-interfaces';
import { MatPaginator, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-version-list',
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss'],
})
export class VersionListComponent implements OnInit {

  // add the commented columsn back in when its confirmed that its in scope
  displayedOrgColumns = [
    'versionName',
    'createdBy',
    'uploadDate',
     'edit',
     'delete',
     'download',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  _versionList: MatTableDataSource<VersionList>;

  @Input() set versionList(data) {
    if (data) {
      this._versionList = new MatTableDataSource(data);
      this._versionList.paginator = this.paginator;
    }
  }

  @Output() onEditVerClick = new EventEmitter();

  @Output() onDeleteVerClick = new EventEmitter();

  @Output() onDownloadVerClick = new EventEmitter();

  @Output() onRowClk = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onEditVer(event, versionList, index: number) {
    event.stopPropagation();
    this.onEditVerClick.emit({
      dataSourceIndex: index,
      versionList,
    });
  }

  onDeleteVer(event, versionList, index: number) {
    event.stopPropagation();
    this.onDeleteVerClick.emit({
      dataSourceIndex: index,
      versionList,
    });
  }

  onDownloadVer(event, versionList, index: number) {
    event.stopPropagation();
    this.onDownloadVerClick.emit({
      dataSourceIndex: index,
      versionList,
    });
  }

  onRowClick(event: PointerEvent, row) {
    console.log(event, row);
    this.onRowClk.emit(row);
  }
}
