import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataService } from 'src/app/services/data.service';
import { ToastService } from 'src/app/utils/toast.service';
import { environment } from 'src/environments/environment';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { DebtPortfolioService } from '../debt-portfolio.service';
import { UtilService } from "./../../../../utils/util.service";
import { InvestmentSummaryService } from '../../investment-page-summary-v2/investment-summary.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { MessageService } from 'src/app/services/message.service';
import { cloneDeep } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, TitleCasePipe } from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from 'src/app/services/translation.service';
import { NotificationService } from 'src/app/utils/notification/notification.service';

@Component({
  selector: 'app-coverage-analysis',
  templateUrl: './coverage-analysis.component.html',
  styleUrls: ['./coverage-analysis.component.scss']
})

export class CoverageAnalysisComponent implements OnInit {
  @Output() waterFallExistChange = new EventEmitter();
  @ViewChild('capitalizationGrid', { static: false }) public capitalizationGrid: GridComponent;
  @ViewChild('covenantsGrid', { static: false }) public covenantsGrid: GridComponent;
  @ViewChild('valuationDatesGrid', { static: false }) public valuationDatesGrid: GridComponent;

  loggedInUser;
  enterpriseValue: number = 0;
  enterpriseValueUnit = "";
  capitalizationApiResponse;
  widgetDataApiResponse;
  enterpriseValueCoverage;
  unSubmittedForms = [];
  valDateUpperLimit;
  investmentDateInfo = {};
  noEquityData = false;
  equityFormNotSubmitted = false;
  equityFormSubmitInProgress: boolean = false;

  issuerCompanyId;
  equityForm: any;
  equityFormdetails: any;
  capitalizationDataReady = false;
  showValDates: boolean = false;
  selectedCompanyDatesWithAllVersions = [];
  primaryCompanyId;
  selectedCompanyDates = [];
  selectedCompanyConsolForm;
  allApprovalDataArray;
  approvalConditionData;
  allApprovalDetails;

  initiatorsGroupMembers;
  newCompany = {name: "", valuationDate: null, groupFormId: null, security: "EQUITY", businessUnitsNumber: 0};
  valuationDateGridDataSource: any = [
    {
      "loading": true
    }
  ];
  allSummaryDataWithAllVersions = {};
  buEditMode = false;
  buShareMode = false;
  selectedBusinessUnits;
  showBuildUpSummary = false;
  showUserValDates = false;
  showConsolSummary = false;
  showInvestment = false;
  debtCompanyId;
  toggleState: boolean;

  APPROVAL_STATUS = {
    NOT_STARTED: "Not Started",
    INITIATED: "Initiated",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    PENDING: "Pending",
    NO_CONDITION: "None",
    APPROVE_REJECT: "Approve / Reject"
  }

  APPROVAL_ACTION = {
    INITIATE_APPROVAL: "Initiate Approval",
    RECALL_APPROVAL: "Recall Approval",
    REJECT_APPROVE: "ApproveReject",
    NO_CONDITION: "No Condition",
    NONE: "none"
  }

  companyValuationSettings = {
    "metric": "bevRevenue",
    "period": "fy",
    "currency": "EUR",
    "valueBridge": "NAV"
  }

  companyValuationSettingsInput = {
    "metric": "bevRevenue",
    "period": "fy",
    "currency": "EUR",
    "valueBridge": "NAV"
  }

  capitalizationData = [
    {
      label: this.translateService.getLabel("revenue"),
      ltm: 0,
      impliedMultiple: 0
    },
    {
      label: this.translateService.getLabel("ebitda"),
      ltm: 0,
      impliedMultiple: 0
    },
    {
      label: this.translateService.getLabel("cash"),
      ltm: 0,
      impliedMultiple: ""
    },
    {
      label: this.translateService.getLabel("net_debt"),
      ltm: 0,
      impliedMultiple: ""
    }
  ];

  waterfallExist:boolean = false;
  debtWaterfallWidgetDataKey = "DEBT_WATERFALL_EXIST";
  rolloverDetails: any;
  constructor(public ds: DataService,
    private activatedRoute: ActivatedRoute,
    public debtPortfolioService: DebtPortfolioService,
    public fundService: FundListService,
    public toastService: ToastService,
    private utilService: UtilService,
    public invSummaryService : InvestmentSummaryService,
    private ums: UserManagementService,
    private protfolioServiceV2: PortFolioSummaryServiceV2,
    private modalService : NgbModal,
    private titlecasePipe: TitleCasePipe,
    private datePipe: DatePipe,
    private ms: MessageService,
    public translateService: TranslateService,
    private notificationService: NotificationService) { }
  async ngOnInit() {
    this.capitalizationDataReady = false;
    this.equityFormNotSubmitted = false;
    this.loggedInUser = this.ums.getSelectedUserDetails();

    const params = this.activatedRoute.snapshot.queryParamMap;
    this.issuerCompanyId = params.get('issuerCompanyId');
    this.debtCompanyId = params.get('companyId');
    this.primaryCompanyId = this.issuerCompanyId;
    this.equityOwnershipInCompany();
    this.getWaterfallSelection()
    this.notificationService.notificationStatusUpdateForEquity.subscribe((details : any) => {      
      this.refreshVersionsList();
    })

    try {
      const response = await this.ds.getEquityFormWithValDate(this.issuerCompanyId,this.debtPortfolioService.recentValuationDateIgnoringExited);
      if(response?.body["response"]?.[0]) {
        this.equityForm = response.body["response"][0];

        if(this.equityForm.details) {
          this.equityFormdetails = JSON.parse(this.equityForm.details);
        }

        if(this.equityFormdetails?.status == "Submitted") {
          const res: any = await (this.ds.getWidgetDataFromDB("Valuation_Summary", this.equityForm.id)).toPromise()
          if(res?.body["response"][0].widgetData) {
            this.widgetDataApiResponse = res?.body["response"][0].widgetData;
            this.enterpriseValue = this.widgetDataApiResponse.equityValue.enterpriseValue;

            if(this.enterpriseValue > 100) {
              this.enterpriseValue = (this.enterpriseValue/1000);
              this.enterpriseValueUnit = "Bn";
            } else {
              this.enterpriseValue = this.enterpriseValue;
              this.enterpriseValueUnit = "Mn";
            }
          }

          this.ds.getFormData(this.equityForm.id, 4).subscribe(res => {
            if(res.body["response"])
            {
              this.capitalizationApiResponse = res.body["response"];
              this.capitalizationApiResponse.formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_DOC.forEach(fin => {
                if(fin.year == "LTM")
                {
                  // this.enterpriseValueCoverage = (fin.earningsBeforeTaxes + fin.interestExpense ) /100;

                  this.capitalizationData.forEach(capRow => {
                    if(capRow.label == "Cash")
                    {
                      capRow.ltm = fin.cash;
                      capRow.impliedMultiple = "";
                    }

                    if(capRow.label == "EBITDA")
                    {
                      capRow.ltm = fin.eBITDA;
                      capRow.impliedMultiple = this.widgetDataApiResponse.bevEbitda;
                    }

                    if(capRow.label == "Revenue")
                    {
                      capRow.ltm = fin.totalNetRevenue;
                      capRow.impliedMultiple = this.widgetDataApiResponse.bevRevenue;
                    }

                    if(capRow.label == "Net Debt")
                    {
                      capRow.ltm = fin.longTermDebt + fin.shortTermDebt - fin.cash
                      capRow.impliedMultiple = "";
                    }
                  })
                }
              })

              this.capitalizationDataReady = true;

              if(this.capitalizationGrid) {
                this.capitalizationGrid.refresh();
              }
            }

          });
        } else if(this.equityFormdetails?.status == "InProgress") {
          this.equityFormSubmitInProgress = true;
        } else {
          this.equityFormNotSubmitted = true;
        }

      } else {
        this.noEquityData = true;
      }
    } catch(e) {
      console.log("Failed to get equity form data", e);
    }
    !this.noEquityData && this.getValuationGridDataSource();
  }

  goToEquity() {
    let additionalParams = "&issuerCompanyId=" + this.issuerCompanyId;
    additionalParams += "&debtCompanyId=" + this.debtCompanyId;

    if(this.equityFormdetails?.status == "Submitted") {
      this.equityForm.versionName = this.equityFormdetails.versionName;
      this.debtPortfolioService.openValuation(this.equityForm, additionalParams);
    } else if(this.equityForm && this.equityFormdetails){
      let urlParameters = "v=" + this.equityForm.formVersion +
                          "&id=" + this.equityForm.id +
                          "&fundName="+ this.fundService.getFundName(this.debtPortfolioService.selectedFundId) +
                          "&versionName="+ this.equityFormdetails.versionName +
                          "&valuationDate=" + this.equityForm.valuationDate +
                          "&parentId="+this.debtPortfolioService.selectedFundId ;

      urlParameters += additionalParams;

      window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&" + urlParameters, "_self");
    } else {
      console.log("ERROR: No equity form data present");
      this.toastService.openSnackBar(this.translateService.getLabel('equity_form_not_found'));
    }
  }

  updateWaterfallSelection(){
    const payload = {
      waterfallExist : this.waterfallExist
    }
    this.ds.saveWidgetDataToDB(this.debtWaterfallWidgetDataKey, payload, this.issuerCompanyId)
    .subscribe((response) => {
      this.waterFallExistChange.emit(this.waterfallExist)
    })
  }
  
  getWaterfallSelection(){
    this.ds.getWidgetDataFromDB(this.debtWaterfallWidgetDataKey, this.issuerCompanyId)
      .subscribe((response:any) => {
        this.waterfallExist = response.body.response[0].widgetData.waterfallExist
        this.waterFallExistChange.emit(this.waterfallExist)
      })
  }

  getValuationGridDataSource() {
    this.ds.getValuationDateFormsWithAllVersionByCompanyId(this.primaryCompanyId).subscribe(async res => {
      const valDates = res.body["response"];
      this.selectedCompanyDatesWithAllVersions = valDates;

      this.selectedCompanyDatesWithAllVersions = this.selectedCompanyDatesWithAllVersions.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date ? 0 : f1Date < f2Date ? 1 : -1;
      })

      this.invSummaryService.allValuationDatesWithVersions = valDates;
      this.unSubmittedForms = this.invSummaryService.allValuationDatesWithVersions.filter(form => {
        const details = JSON.parse(form.details);
        return details.status == "Initiated";
      });

      this.invSummaryService.allValuationDates = this.invSummaryService
        .addInfoToFormobjectFromDetails(this.selectedCompanyDatesWithAllVersions.filter(comp => comp.frozen))
        .sort(function (a, b) { return (b.valuationDate) - (a.valuationDate) });

      try {
        const valDateUpperLimitSavedAPI = await this.ds.getWidgetDataFromDB("VAL_DATES_UPPERLIMIT", this.primaryCompanyId).toPromise();
        this.valDateUpperLimit = valDateUpperLimitSavedAPI.body["response"][0]["widgetData"].limit;
      } catch (e) {
      }

      if (!this.valDateUpperLimit) {
        this.valDateUpperLimit = this.invSummaryService.allValuationDates[0].valuationDate;
      }

      //Get all valuation dates which are on or before upper limit
      const upperLimitIndex = this.invSummaryService.allValuationDates.findIndex(vd => vd.valuationDate == this.valDateUpperLimit);
      this.selectedCompanyDates = this.invSummaryService.allValuationDates.slice(upperLimitIndex, this.invSummaryService.allValuationDates.length);

      let latestSubmittedForm = this.selectedCompanyDates.find(comp => comp.status !== "Initiated" && comp.frozen);
      let latestDateId = latestSubmittedForm ? latestSubmittedForm.id : "";

      const invDateForm = this.selectedCompanyDates?.[0];

      // investment date should always be part of Investment Summary.
      // if (!invDateForm) {
      //   const invDateForm = this.invSummaryService.allValuationDates.find(comp => comp.groupFormId == null);
      //   this.selectedCompanyDates.push(invDateForm);
      //   this.selectedCompanyDates = this.selectedCompanyDates.sort(function (a, b) { return (b.valuationDate) - (a.valuationDate) });
      // }

      this.newCompany.name = invDateForm.companyName;
      this.newCompany.groupFormId = this.primaryCompanyId;
      this.newCompany.businessUnitsNumber = this.invSummaryService.allValuationDates[0].businessUnitsNumber;

      this.ds.getFormData(latestDateId, 4).subscribe(async ver => {
        const formData = ver.body["response"].formData;

        try {
          const settingsData = await this.ds.getWidgetDataFromDB("INVESTMENT_SUMMARY_SETTINGS", this.primaryCompanyId).toPromise();
          this.companyValuationSettings = settingsData.body["response"][0]["widgetData"];
        } catch (ex) {
          this.companyValuationSettings.currency = formData.FINANCIALS.FIN_HIST_FINANCIALS.FIN_FIN_HIST_CURRENCY;
        }

        this.companyValuationSettingsInput = cloneDeep(this.companyValuationSettings);

        //this.investmentDateInfo.formData = formData;
      }, error => {
        console.log("Failed to fetch formData", error)
      });

      this.selectedCompanyConsolForm = cloneDeep(invDateForm); //loading consol summary of latest valuation date by default

      if (this.selectedCompanyDates.length > 0) {
        this.prepareDataSourceForAllValDatesWithVersions();
      }
    }, error => {
      console.log("Failed to fetch All version Forms", error)
    });
  }

  prepareDataSourceForAllValDatesWithVersions(){
    this.allApprovalDataArray = [];
    let allRequestIds = [];
    this.approvalConditionData = [];
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];

    if(this.valuationDatesGrid) {
      this.valuationDatesGrid.refresh();
    }

    let allValDates = [];
    console.log("dates", this.selectedCompanyDatesWithAllVersions);
    this.selectedCompanyDatesWithAllVersions.forEach(eachDate => {
      allValDates.push(eachDate.valuationDate);
    });

    let latestDate = new Date(allValDates[0]);
    let modifiedDate = moment(latestDate).add(1, 'quarter').endOf('quarter');
    this.rolloverDetails = {
      "issuerCompanyId": this.issuerCompanyId,
      "source": "USER_ROLLOVER",
      "orgId": this.loggedInUser.organization.id,
      "userId": this.loggedInUser.id,
      "valuationDate" : (moment(modifiedDate)).format("YYYY-MM-DD")
    };

    const allFormIds = this.selectedCompanyDatesWithAllVersions.map(comp => comp.id);

    this.ds.getSummaryOfFormsByFormIds(allFormIds).subscribe(async(res) => {

      this.allSummaryDataWithAllVersions = res.body["response"];

      this.selectedCompanyDatesWithAllVersions = this.selectedCompanyDatesWithAllVersions.map(comp => {
        if(this.allSummaryDataWithAllVersions && this.allSummaryDataWithAllVersions[comp.id]){
          comp["investment"] = this.allSummaryDataWithAllVersions[comp.id]["investment"];

          // ToDo : Update WRT BU
          if(this.allSummaryDataWithAllVersions[comp.id]["consolSummary"]){
            comp["consolSummary"] = this.allSummaryDataWithAllVersions[comp.id]["consolSummary"];
            comp["businessUnits"] = this.invSummaryService.addInfoToFormobjectFromDetails(this.allSummaryDataWithAllVersions[comp.id]["businessUnits"]);

            comp["businessUnits"] = comp["businessUnits"].sort((bu1, bu2) => {
              if(bu1.businessUnitName < bu2.businessUnitName) { return -1; }
              if(bu1.businessUnitName > bu2.businessUnitName) { return 1; }
              return 0;
            });
          }
        }
        return comp;
      })

      // this.invSummaryService.valuationDateSummaryReady$.next();

      // if(this.showPage == "consol"){
      //   const selectedVersion = this.selectedCompanyDatesWithAllVersions.find(v => v.id == this.showPageId);
      //   this.showByTopMenu('consol', selectedVersion);
      // }
      
      const allValuationDates : Set<string> = new Set(this.selectedCompanyDatesWithAllVersions.map(comp => comp.valuationDate));

      this.valuationDateGridDataSource = []

      let index = 0;
      allValuationDates.forEach((valDate) => {
        this.valuationDateGridDataSource.push({
          name: valDate,
          index: index,
          value: this.selectedCompanyDatesWithAllVersions.filter(comp => {
            return this.utilService.compareDates(new Date(comp.valuationDate), new Date(valDate)) == 0;
          })
        })

        index++
      })

      this.selectedCompanyDatesWithAllVersions.forEach(form => {
        if(form.businessUnits && form.consolSummary){
          this.invSummaryService.businessUnitsValSummaryForAllIds.summary[form.id] = form.consolSummary
          if(this.allSummaryDataWithAllVersions && this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"]){
            const currKeys = Object.keys(this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"]);
            currKeys.forEach(key => {
              this.invSummaryService.businessUnitsValSummaryForAllIds.currency[key] = this.allSummaryDataWithAllVersions[form.id]["currencyExchangeRates"][key];
            })
          }
        }
      })

      this.selectedCompanyDatesWithAllVersions.forEach(form => {
        if(form.businessUnits && form.businessUnits.length > 0){
          this.invSummaryService.initBU_ConsolSummary(form)
        }
      })

      console.log("valuationDateGridDataSource", this.valuationDateGridDataSource);

      try {
        this.approvalConditionData = await this.ds.getApprovalCondition(this.loggedInUser.organization.id, this.primaryCompanyId, this.protfolioServiceV2.selectedFundId);
        this.approvalConditionData = this.approvalConditionData.body.response;
  
        if(this.approvalConditionData && this.approvalConditionData.approvers[0].type == "group") {
          this.initiatorsGroupMembers = (await this.ds.getGroupById(this.approvalConditionData.approvers[0].id)).body["response"].members;
        }
      } catch(e) {
        console.log("Error is getting condition and members");
      }
  
      this.selectedCompanyDatesWithAllVersions.forEach((eachDate) => {
        if(eachDate.approvalRequestId){
          allRequestIds.push(eachDate.approvalRequestId)
        }
      });
      
      let allRequestIdObject = {
        approvalRequestIds: allRequestIds,
        firmId: this.loggedInUser.organization.id
      }
  
      this.allApprovalDetails = [];
      if(allRequestIds.length > 0){
          try {
          let resp = await this.ds.getAllApprovalDetails(allRequestIdObject);
          if(resp?.body && resp?.body["response"]) {
            this.allApprovalDetails = resp.body["response"];
          }
        } catch(e) {
        }
      }
  
      this.valuationDateGridDataSource = [];
      this.selectedCompanyDatesWithAllVersions.forEach((eachDate) => {
        this.getActionNameAndStatus(eachDate);
      });
  
      this.prepareGroupledValVersions();
  
      if(this.valuationDatesGrid) {
        this.valuationDatesGrid.refresh();
      }
      this.utilService.closeAllPopups()

      
    }, error => {
      console.log("Failed to fetch Summary for the FormIds", error)
    });
  }

  async getActionNameAndStatus(formVersion){
    const obj = {
      approvalStatus : null,
      actionName : null,
      approvalRequestId: null,
      editable: false
    }

    let approvalData = [];

      if(this.allApprovalDetails.length > 0){
        approvalData = this.allApprovalDetails.filter(eachApproval => eachApproval.id == formVersion.approvalRequestId );
      }

      if(approvalData.length > 0)
      {
        obj.approvalRequestId = approvalData[0].id;
  
          if(approvalData[0].status == "approved")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.APPROVED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else if(approvalData[0].status == "rejected")
          {
            obj.approvalStatus = this.APPROVAL_STATUS.REJECTED;
            obj.actionName = this.APPROVAL_ACTION.NONE;
          }
          else
          {
            if(approvalData[0].status == "pending")
            {
              let isInitator = this.isInitiator();
              if(isInitator)
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.RECALL_APPROVAL;
              }
              else if(this.isCurrentApprover(approvalData))
              {
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.REJECT_APPROVE;
              }
              else
              {
                // no action
                obj.approvalStatus = this.APPROVAL_STATUS.PENDING;
                obj.actionName =  this.APPROVAL_ACTION.NONE;
              }
      
            }
          }
        // }
      }
      else if(this.approvalConditionData && this.approvalConditionData.hasOwnProperty("conditionName")) 
      {
        let isInitator = this.isInitiator();
        if(isInitator)
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.INITIATE_APPROVAL;
          obj.editable = true;
        }
        else
        {
          obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
          obj.actionName = this.APPROVAL_ACTION.NONE;
          obj.editable = true;
        }
      }
      else
      {
        // Disable initiate button
        obj.actionName = this.APPROVAL_ACTION.NO_CONDITION;
        obj.approvalStatus = this.APPROVAL_STATUS.NOT_STARTED;
        obj.editable = true;
      }

      let formVersionForGrid = cloneDeep(formVersion);

      if(formVersion?.investment?.equityValue?.finalStakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.finalStakeValue
      } else if(formVersion?.investment?.equityValue?.stakeValue) {
        formVersionForGrid["stake"] = formVersion.investment.equityValue.stakeValue;
      } else {
        formVersionForGrid["stake"] = 0.0;
      }

      const details = JSON.parse(formVersion.details);

      formVersionForGrid["frozen"] = details.frozen;
      formVersionForGrid["status"] = details.status;

      formVersionForGrid["versionName"] = JSON.parse(formVersion.details).versionName;
      formVersionForGrid["currency"] = formVersion?.investment?.currency;

      formVersionForGrid["approvalStatus"] = obj["approvalStatus"];
      formVersionForGrid["actionName"] = obj["actionName"];
      formVersionForGrid["editable"] = obj["editable"];
      formVersionForGrid["approvalRequestId"] = obj["approvalRequestId"];

      formVersionForGrid["comments"] = null;
      
      this.valuationDateGridDataSource.push(formVersionForGrid);
  }

  getLoggedInUserId() {
    return this.loggedInUser.id;
  }

  isInitiator() {
    let isInitator = false;

    if(this.approvalConditionData.approvers[0].type == "user" && this.approvalConditionData.approvers[0].id == this.getLoggedInUserId())
    {
      isInitator = true;  
    }
    else if(this.approvalConditionData.approvers[0].type == "group")
    {
      let initatorObj = this.initiatorsGroupMembers.find(eachMember => eachMember.id == this.getLoggedInUserId());
      if(initatorObj) {
        isInitator = true;
      }
    }
    else {
      isInitator = false;
    }
    return isInitator;
  }

  isCurrentApprover(approvalData) {
    let isCurrentApprover = false;
    let firstPendingApprover = approvalData[0].approvals.find(eachApproval =>  eachApproval.status == "pending" );

    if(firstPendingApprover.hasOwnProperty("group"))
    {
      firstPendingApprover.group.members.forEach((member) => {
        if(member.id == this.getLoggedInUserId())
        {
          isCurrentApprover = true;
        }
      });
    } else if(firstPendingApprover.hasOwnProperty("user"))
    {
      if(firstPendingApprover.user.id == this.getLoggedInUserId())
      {
        isCurrentApprover = true;
      }
    }

    return isCurrentApprover;
  }


  prepareGroupledValVersions(){
    let data = this.valuationDateGridDataSource;
    let dataMap = new Map();
    data.forEach(v => {
      if(dataMap.has(v.valuationDate)){
        let arr = dataMap.get(v.valuationDate);
        arr.push(v);
        dataMap.set(v.valuationDate, arr);
      }
      else dataMap.set(v.valuationDate, [v]);
    });

    let obj = {};
    let dataArr = [];
    let count = 0;
    for(let item of dataMap){
      obj = { name : item[0], value : item[1], index : count};
      dataArr.push(obj);
      count++;
    }

    this.valuationDateGridDataSource = dataArr;

    if(this.valuationDateGridDataSource.length) {
      let dateArr = cloneDeep(this.valuationDateGridDataSource);
      dateArr.sort(function(a,b){
        return new Date(b.name).valueOf() - new Date(a.name).valueOf();
      });

      let latestDate = new Date(dateArr[0].name);
      let modifiedDate = moment(latestDate).add(1, 'quarter').endOf('quarter');
      this.newCompany.valuationDate = (moment(modifiedDate)).format("YYYY-MM-DD");
    }
  }

  checkWhetherBusinessUnitIsSubmitted(form){
    let isSubmitted = form.status == 'Submitted' ? true : false;

    if(form.businessUnits && form.businessUnits.length > 0){
      let buSubmittedStatus = false;
      form.businessUnits.forEach(bu => {
        buSubmittedStatus = buSubmittedStatus || bu.status == "Submitted";
      })

      isSubmitted = buSubmittedStatus;
    }

    return isSubmitted;
  }

  addValuationDate() {
    if (!this.newCompany.valuationDate || this.newCompany.valuationDate.length === 0) return;
    this.newCompany.valuationDate = (moment(this.newCompany.valuationDate)).format("YYYY-MM-DD");
    this.newCompany.name = this.selectedCompanyDates[0].companyName;
    this.newCompany.groupFormId = this.primaryCompanyId;

    const submittedForms = this.selectedCompanyDates.filter(valDate => valDate.status == "Submitted")
    let latestFormToCopy;
    if (submittedForms.length > 0) {
      let sortedForms = submittedForms.sort((a, b) => {
        return Number(new Date(b.valuationDate)) - Number(new Date(a.valuationDate))
      });
      latestFormToCopy = sortedForms[0];
    } else {
      latestFormToCopy = this.selectedCompanyDates[0];
    }

    this.createValuationDate(this.newCompany, "FIRST", 'true', latestFormToCopy);
  }

  createValuationDate(newCompanyInfo, versionName, frozen, copyDataFromForm) {
    this.utilService.showLoadingPopup();
    this.ms.publish("loadingMessage", this.translateService.getLabel("preparing_data_for_valuation_date"));

    const previousFormId = copyDataFromForm?.valuationDateId || copyDataFromForm?.id;
    const valuationDate = newCompanyInfo.valuationDate;
    const prevValuationDate = copyDataFromForm.valuationDate;
    const orgId = this.loggedInUser.organization.id;
    const userId = this.loggedInUser.id;
    const groupFormId = newCompanyInfo.groupFormId;

    this.ds.equityRollover(previousFormId, valuationDate, prevValuationDate, versionName, frozen, orgId, userId, groupFormId)
      .subscribe((res: any) => {
        res?.body?.success && this.refreshVersionsList();
      })
  }

  refreshVersionsList() {
    this.valuationDateGridDataSource = [
      {
        "loading": true
      }
    ];
    this.getValuationGridDataSource();
  }

  openSAF(comp, businessUnitPopup, forceEdit?) {
    this.modalService.dismissAll();

    if(!comp.editable) return;

    if(!forceEdit && comp.businessUnits && comp.businessUnits.length > 0){
      this.buShareMode = false;
      this.buEditMode = true;
      this.openBusinessUnits(comp, businessUnitPopup)
      return;
    }

    const version = this.ums.getApplicationVersion() + "";
    
    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version); 
    localStorage.setItem('qubit-val-date', comp.valuationDate); 
    localStorage.setItem('qubit-investment-date-id', this.primaryCompanyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName = comp.versionName;

    let urlParameters = "v=" + version + "&id=" + comp.id + "&fundName="+fundName + "&versionName="+versionName 
      + "&issuerCompanyId=" + this.issuerCompanyId + "&debtCompanyId=" + this.debtCompanyId + "&parentId="+this.debtPortfolioService.selectedFundId;

    if(comp.consolForm) {
      const consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === comp.consolForm);

      if(consolForm) {
        urlParameters += "&srcCurrency=" + consolForm.currency 
          + "&consol=" + consolForm.companyNameInForm
          + "&marketDate=" + consolForm.marketDate;
      } else {
        const consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === this.primaryCompanyId);
        urlParameters += "&srcCurrency=" + consolForm.currency 
          + "&consol=" + consolForm.companyNameInForm
          + "&marketDate=" + consolForm.marketDate;
      }
    }

    window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&" + urlParameters, "_self");
  }

  openBusinessUnits(comp, content) {
    this.selectedBusinessUnits = null;

    this.utilService.showLoadingPopup();

    const consolForm = this.selectedCompanyDatesWithAllVersions.find(f => f.id === comp.id) || comp;

    this.selectedCompanyConsolForm = cloneDeep(consolForm); //loading consol summary selected valuation date, passing as an input to consol summary

    this.utilService.closeAllPopups();
    if(consolForm && consolForm.businessUnits) {
      this.selectedBusinessUnits = [];

      // Add consol form also in edit mode so that consol form can be opened into SAF
      // if(this.buEditMode) {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm);
      // }

      if(consolForm.status !== "Initiated") {
        this.selectedBusinessUnits = this.selectedBusinessUnits.concat(consolForm.businessUnits);

        consolForm.editable = comp.editable;

        consolForm.businessUnits.forEach(form => {

          form.editable = comp.editable;

          const details = JSON.parse(form.details);

          if(details) {  
            if(!form.companyName) {
              form.companyName = this.utilService.cleanString(details["companyName"]);
            }
            form.currency = details["currency"];
            form.versionName = details["versionName"];
            form.businessUnitName = details["businessUnitName"];
            form.status = details["status"];
          }
        })

      }

      this.modalService.open(content, { centered: true })
    } else {
      this.openSAF(comp, null, true)
    }
  }

  openValuation(comp, businessUnitPopup, forceOpen?) {
    this.modalService.dismissAll();

    if(comp.businessUnits && comp.businessUnits.length > 0){
      if(!forceOpen) {
        this.buShareMode = false;
        this.buEditMode = false;
        this.openBusinessUnits(comp, businessUnitPopup)
      }
      else {
        this.showByTopMenu('consol');
      }
      return;
    }

    this.openValuationWithNormalDates(comp);
  }

  showByTopMenu(ui, comp?) {
    this.showConsolSummary = false;
    this.showUserValDates = false;
    this.showValDates = false;
    this.showInvestment = false;

    if(ui === "vd") {
      this.showValDates = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === "userVD") {
      this.showUserValDates = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === "consol") {
      if(comp){
        this.selectedCompanyConsolForm = comp //when top menu is clicked loading latestValuationDate Consol Summary
      }
      this.showConsolSummary = true;
      this.invSummaryService.showSettings = false;
    } else if(ui === 'INVESTMENT') {
      this.showInvestment = true;
    }
  }

  openValuationWithNormalDates(comp, additionalParams="") {
    //Ignore if it is user added old valuation date
    if(!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if(comp.businessUnits) return;

    const version = comp.formVersion; //this.ums.getApplicationVersion() + "";

    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version);
    localStorage.setItem('qubit-val-date', comp.valuationDate);
    localStorage.setItem('qubit-investment-date-id', this.primaryCompanyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName=comp.versionName;
    let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName
    + "&issuerCompanyId=" + this.issuerCompanyId + "&debtCompanyId=" + this.debtCompanyId + "&parentId="+this.debtPortfolioService.selectedFundId;
    if(comp.consolForm && this.selectedCompanyDates && this.selectedCompanyDates.length > 0) {
      const consolForm = this.selectedCompanyDates.find( f => f.id == comp.consolForm);
      urlParameters += "&consol=" + consolForm.companyNameInForm;
    }
    this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, "Investment Summary");
    let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

    if(comp.approvalRequestId) {
      url += "&aid="+comp.approvalRequestId;
    }

    if(additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self")
  }

  deleteCompany(company) {
    if (!company.editable) return;
    let comapnyIdList: string[] = [company.id];
    const companyValDate = this.datePipe.transform(company.valuationDate, 'mediumDate');
    const versionName = " (" + this.titlecasePipe.transform(company.versionName) + ")";
    const confirm = this.utilService.showConfirmMessage(this.translateService.getLabel("info_confirm_delete") + " - " + companyValDate + versionName, this.translateService.getLabel("yes"), this.translateService.getLabel("no"));

    confirm.afterClosed().subscribe(confirmation => {
      if (confirmation === "Yes") {

        if (!company.userEntered) {
          
          const orgId = this.ums.getSelectedUserDetails().organization.id;

          this.ds.deleteCompanies(comapnyIdList, orgId).subscribe(response => {
            // console.log("Successfully Deleted");
            if(response?.body) {
              this.deleteValuationFromWaterfall(comapnyIdList, this.issuerCompanyId, company.valuationDate);
            }
            this.modalService.dismissAll();

            this.deleteCompanyFromList(company);

            this.utilService.showMessage(this.translateService.getLabel("successfully_deleted") + " - " + companyValDate + versionName, this.translateService.getLabel("ok"));
          }, error => {
            console.log("Error While Deleting Companies", error);
            this.modalService.dismissAll();
            this.utilService.showMessage(this.translateService.getLabel("err_failed_delete") + " - " + companyValDate + ". " + this.translateService.getLabel("info_try_after_sometime") + ".", this.translateService.getLabel("ok"), true);
          });
        } else {
          //------------------------------------------------------------------------------
          // Uncomment this code once user saved valuation dates need to be brought back.
          //------------------------------------------------------------------------------

          /******
          // this.dataService.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId]), this.primaryCompanyId).subscribe(res=>{
     
          const savedIndex = this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId].findIndex(comp => comp.id === company.id);
          this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId].splice(savedIndex, 1);

          //Save updated list of user entered companies
          this.ds.saveWidgetDataToDB(this.portfolioService.DATA_KEY_OLD_VALUATION, JSON.stringify(this.portfolioService.userSavedOldFormsExcel[this.primaryCompanyId]), this.primaryCompanyId).subscribe(res=>{
            
            this.deleteCompanyFromList(company);

            this.utilService.showMessage("Successfully deleted - " + companyValDate + versionName, "Ok");

          }, err => {
            this.utilService.showMessage("Error: Failed to delete - " + companyValDate + ". Please try after sometime.", "Ok", true);
          }); 
          */
        }
      }
    });
  }

  deleteValuationFromWaterfall(comapnyIdList, issuerCompanyId, valuationDate) {
    this.ds.deleteValuationFromWaterfall(comapnyIdList, issuerCompanyId, valuationDate)
      .subscribe((res:any) => {
        if(res?.body) {
          this.ds.deleteDebtForm(issuerCompanyId, valuationDate).subscribe();
        }
      });
  }

  deleteCompanyFromList(company) {
    const displayCompIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(displayCompIndex >= 0) {
      this.selectedCompanyDates.splice(displayCompIndex, 1)
    }

    const companyIndex = this.selectedCompanyDates.findIndex(c => c.id === company.id);
    if(companyIndex >= 0) {
      this.selectedCompanyDates.splice(companyIndex, 1)
    }

    const toBeDeletedIndex = this.selectedCompanyDatesWithAllVersions.findIndex(c => c.id === company.id);
    this.selectedCompanyDatesWithAllVersions.splice(toBeDeletedIndex, 1);

    this.utilService.showLoadingPopup();
    this.prepareDataSourceForAllValDatesWithVersions();
  }

  openValuationDatePopup(content){
    this.modalService.open(content, { centered: true, size: "lg",windowClass: 'center-popup-modal'})
  }

  submitRollover(payload) {
    this.ds.saveCommonRollover(payload).subscribe();
  }
  onToggleChange(event: any) {
    const isToggle = event.checked;
    const payload = {
      "equityOwnershipInCompany": isToggle
    };
    this.ds.postequityOwnershipInCompany(this.issuerCompanyId, payload)
      .subscribe();
  }

  equityOwnershipInCompany() {
    this.ds.getequityOwnershipInCompany(this.issuerCompanyId).subscribe((response: any) => {
      this.toggleState = response?.body?.response?.equityOwnershipInCompany;
    });
  }

}
