import { Injectable } from '@angular/core';
import { Chart } from 'highcharts';
import { cloneDeep } from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { colors } from 'src/app/utils/colors';
import { environment } from 'src/environments/environment';
import { FundListService } from '../fund-list-ui/fund-list.service';

import { MapChart } from 'angular-highcharts';
import worldMap from '.././world-data.data';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioSummaryServiceV2 } from '../portfolio-summary-v2/portfolio-summary-v2.service';
import { BehaviorSubject } from 'rxjs';

import { TranslateService } from 'src/app/services/translation.service';
@Injectable({
  providedIn: 'root'
})
export class DebtPortfolioService {

  selectedFundId;
  selectedCompanyId;

  organisedForms;
  portfolioSummary;
  sectorGraphData;
  geographyGraphData;
  debtMapChart;
  debtColumnChart;
  issureConsolSummaryPayload;
  coverageDataAvailable = false

  fundLevel = "fund";
  companyLevel = "company";
  securityCurrency;
  payloadForIssuer: any;
  isIssuerPortfolioResponse: boolean = false;
  issuerPorfolioSummary;
  allCompanyLogos: any;
  public recentValuationDate: any;
  public recentValuationDateIgnoringExited: any;
  isDebtLoaded: boolean = false;

  //From portfolioService
  public myOrgUsers = [];
  public companyId : any;
  public companies = [];
  public selectedListOfInvestors = {} //investments
  public businessUnitsValSummaryForAllIds = {
    summary: {},
    currency: {}
  } as any;
  public mapData = {
    value: 'currentVal',
    name:'Current Valuation',
    data:[]
  }
  public portfolioData = [];
  public searchedForms = [];
  waterfallFieldStatuses = {
    goingConcernInputs: {
      low : {
      'ebitdaMultiple': false,
      'ebitda': false,
      'revenueMultiple': false,
      'revenue': false,
      'grossprofitMultiple': false,
      'grossprofit': false,
      'ebitMultiple': false,
      'ebit': false,
      'cash': false
    },
    midpoint : {
      'ebitdaMultiple': false,
      'ebitda': false,
      'revenueMultiple': false,
      'revenue': false,
      'grossprofitMultiple': false,
      'grossprofit': false,
      'ebitMultiple': false,
      'ebit': false,
      'cash': false
    },
    high : {
      'ebitdaMultiple': false,
      'ebitda': false,
      'revenueMultiple': false,
      'revenue': false,
      'grossprofitMultiple': false,
      'grossprofit': false,
      'ebitMultiple': false,
      'ebit': false,
      'cash': false
    }
  }
  };
  public currencyChanged = new BehaviorSubject<boolean>(false);

  constructor(private dataService: DataService,
    private ums: UserManagementService, public fundService: FundListService, private ms: MessageService,
    private utilService: UtilService, public portfolioServiceV2: PortFolioSummaryServiceV2, private translateService: TranslateService) {
    this.getUsers();
  }

  getSecuritiesByIssuerCompany(fundId, userData) {
    return this.dataService.getSecuritiesByIssuerCompany(userData.id, fundId, userData.organization.id);
  }

  getSecuritiesByIssuerCompanyWithVD(fundId: string, valuationDate: string) {
    return this.dataService.getSecuritiesByIssuerCompanyWithVD(fundId, valuationDate);
  }

  getAllSecuritiesForPortfolio(fundId, userData) {
    return this.dataService.getAllSecuritiesForPortfolio(userData.id, fundId, userData.organization.id);
  }

  getAllSecuritiesForPortfolioWithVD(fundId: string, userData: any, valuationDate: string) {
    return this.dataService.getAllSecuritiesForPortfolioWithVD(
      userData.id, fundId, valuationDate, userData.organization.id
    );
  }

  getDebtInvestMentDetails() {
    // if(!this.organisedForms) {
    const user = this.ums.getSelectedUserDetails();

    if (!this.fundService.allFunds || this.fundService.allFunds.length == 0) {
      this.fundService.getFunds(user);
    }

    return this.dataService.getAllDebtForms(user.id, this.selectedFundId, user.organization.id).then(resp => {
      if (resp.body["response"]) {
        const allForms = resp.body["response"]

        this.organisedForms = {};
        allForms.forEach(form => {
          if (form.groupFormId == null) {
            form.companyId = form.id;
            this.organisedForms[form.id] = form;

            if (!this.organisedForms[form.id]["valuations"]) {
              this.organisedForms[form.id]["valuations"] = [];
            }
            this.organisedForms[form.id]["valuations"].push(form);
          }
        });

        allForms.forEach(form => {
          if (form.groupFormId != null) {
            form.companyId = form.groupFormId;
            if (this.organisedForms[form.groupFormId]) {
              this.organisedForms[form.groupFormId]["valuations"].push(form);
            }
          }
        });

        // console.log(">> all debt organisedForms", this.organisedForms);
      }
    });
    // } else {
    //   return new Promise((resolve, reject) => {
    //       resolve("");
    //   });
    // }

  }

  getSelectedCompanyJSON() {
    return this.organisedForms[this.selectedCompanyId];
  }

  getAllLogos(userDetails, fundId) {
    return this.dataService.getAllLogos(userDetails.id, fundId, userDetails.organization.id);
  }

  getAllFomsForCompany(companyId) {
    let valList = this.organisedForms[companyId].valuations;

    if (valList.length > 0) {
      valList = valList.reverse();
    }
    return valList;
  }

  getPortfolioSummaryLocal(payloadData) {
    return this.dataService.getDebtPortfolioSummaryLocal(payloadData);
  }

  getGeographyData(toCurrency, tempPayload) {
    tempPayload.toCurrency = toCurrency;
    this.dataService.getSectorData(tempPayload).subscribe(res => {
      this.sectorGraphData = res.body['response']
      this.debtColumnChart = this.initColumnChart();
    });

    // return this.dataService.getDebtPortfolioSummaryLocal(portfolioSummaryPayload);
  }

  getPortfolioTotals(toCurrency, payloadData?) {
    if (payloadData) {
      payloadData.toCurrency = toCurrency;
      return this.dataService.getDebtPortfolioSummaryTotals(payloadData);
    } 
    //Need to refactor later in case of Delete Security
    else {
      const portfolioSummaryPayload = this.prepareDebtPayload(this.fundLevel);
      let portfolioSummaryPayloadReporting: any = cloneDeep(portfolioSummaryPayload);
      portfolioSummaryPayloadReporting.toCurrency = toCurrency;
      return this.dataService.getDebtPortfolioSummaryTotals(portfolioSummaryPayloadReporting);
    }
  }

  getPercentOfPar(toCurrency, filteredRows?) {
    const portfolioSummaryPayload = this.prepareDebtPayload(this.fundLevel);
    let portfolioSummaryPayloadReporting: any = cloneDeep(portfolioSummaryPayload);
    portfolioSummaryPayloadReporting.toCurrency = toCurrency;

    if (filteredRows) {
      portfolioSummaryPayloadReporting.companies = this.getFilteredCompanies(portfolioSummaryPayloadReporting, filteredRows);
    }
    return this.dataService.getPercentOfPar(portfolioSummaryPayloadReporting);
  }

  getPortfolioCompanysLogo(userDetails, fundId) {
    this.getAllLogos(userDetails, fundId).subscribe((res:any) => {
      this.allCompanyLogos = res?.body?.response?.logos;
    })
  }

  getFilteredCompanies(portfolioSummaryPayloadReporting, filteredRows) {
    let filteredCompanies = [];
    filteredRows.find((rows: any) => {
      const found = portfolioSummaryPayloadReporting.companies.find((companies: any) => {
        return rows.companyId == companies.companyId;
      });
      if (found) {
        filteredCompanies.push(found);
      }
    });
    return filteredCompanies;
  }

  getSectorData(toCurrency, tempPayload) {
    tempPayload.toCurrency = toCurrency;
    this.dataService.getGeographyData(tempPayload).subscribe(res => {
      this.geographyGraphData = res.body['response'];
      let graphObj = {
        name: 'Current Valuation',
        value: 'currentVal',
        data: this.geographyGraphData.geographyData
      }
      this.debtMapChart = this.initWorldMapChart(graphObj);
    });

    // return this.dataService.getDebtPortfolioSummaryLocal(portfolioSummaryPayload);
  }

  initColumnChart() {
    const __this = this;

    let a = this.sectorGraphData?.map(data => data.currValDate)

    const columnChart = new Chart({
      chart: {
        renderTo: 'column-chart-container',
        type: 'column'
      },
      lang: {
        contextButtonTitle: this.translateService.getLabel("chart_context_menu")
      },
      title: { text: '' },
      xAxis: {
        categories: this.sectorGraphData?.map(data => data.sector),
        crosshair: true
      },
      yAxis: { title: { text: this.translateService.getLabel("value") + " (" + this.portfolioServiceV2.selectedCurrency + " Mn)" } },
      credits: { enabled: false },
      exporting: {
        enabled: true,
        menuItemDefinitions: {
          viewFullscreen: {
            text: this.translateService.getLabel("view_in_full_screen")
          },
          printChart: {
            text: this.translateService.getLabel("print_chart")
          },
          downloadPNG: {
            text: this.translateService.getLabel("download_png_image")
          },
          downloadJPEG: {
            text: this.translateService.getLabel("download_jpeg_image")
          },
          downloadPDF: {
            text: this.translateService.getLabel("download_pdf_document")
          },
          downloadSVG: {
            text: this.translateService.getLabel("download_svg_vector_image")
          },
          downloadCSV: {
            text: this.translateService.getLabel("download_csv")
          },
          downloadXLS: {
            text: this.translateService.getLabel("download_xls")
          },
          viewData: {
            text: this.translateService.getLabel("view_data_table")
          }
        }
      },
      tooltip: {
        formatter: function () {
          return __this.portfolioServiceV2.selectedCurrency + " " + Number(this.y.toFixed(1)).toLocaleString() + " Mn"
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [
        // { name: 'Previous Valuation', data: this.sectorGraphData.map( data => data.prevValDate), color: colors.chartPreviousValuationColor},
        { name: this.translateService.getLabel("current_valuation"), data: this.sectorGraphData?.map(data => data.currValDate), color: colors.chartCurrentValuationColor }
      ] as any
    })

    return columnChart;
  }

  getDebtPortfolioSummaryReporting(toCurrency, payloadData?) {
    payloadData.toCurrency = toCurrency;
    return this.dataService.getDebtPortfolioSummaryReporting(payloadData);
  }

  getDebtValuationSummary(investmentSummaryPayload) {
    return this.dataService.getDebtValuationDetails(investmentSummaryPayload);
  }

  prepareDebtPayload(level) {
    let portfolioSummaryPayload = {
      fundId: this.selectedFundId,
      companies: []
    }

    //Sort forms as per valuation dates
    Object.values(this.organisedForms).forEach((form: any) => {
      form.valuations.sort((a, b) => {
        return <any>new Date(a.valuationDate) - <any>new Date(b.valuationDate);
      });
    });

    Object.keys(this.organisedForms).forEach(key => {
      const company = this.organisedForms[key];
      let companyPayload = {
        companyId: company.id,
        valuationDates: []
      }

      let valDatePayload: any = {};

      if (company.valuations && company.valuations.length != 0) {
        if (level == this.fundLevel) {
          valDatePayload = {
            valuationDateId: company.valuations[company.valuations.length - 1].id
          };
          companyPayload.valuationDates.push(valDatePayload)

        } else if (level == this.companyLevel) {
          company.valuations.forEach(entry => {
            valDatePayload = {
              valuationDateId: entry.id
            };
            companyPayload.valuationDates.push(valDatePayload)
          });
        }
      } else {
        valDatePayload = {
          valuationDateId: company.id
        };
        companyPayload.valuationDates.push(valDatePayload)

      }

      if (level == this.fundLevel) {
        portfolioSummaryPayload.companies.push(companyPayload);
      } if (level == this.companyLevel) {
        if (companyPayload.companyId == this.selectedCompanyId) {
          portfolioSummaryPayload.companies.push(companyPayload);
        }
      }
    });

    return portfolioSummaryPayload;
  }

  openDebtValuation(comp, openInNewTab: boolean = false) {
    let params = `valuationDateId=${comp.valuationDateId}`;
    let url = environment.portalUrl + environment.debtModel + "/#/view-cashflow?" + params;
    if(openInNewTab) {
      window.open(url);
    } else {
      window.open(url, "_self");
    }
  }

  getIssuerPorfolioPayload(fundId) {
    return this.multipleIssueDebtPayload(fundId).then((payload) => {
      let debtPayload: any = {};
      debtPayload.fundId = fundId
      debtPayload.toCurrency = this.portfolioServiceV2.selectedCurrency
      debtPayload.companies = payload
      return debtPayload
    })
  }

  async multipleIssueDebtPayload(fundId) {
    const portfolioSummaryPayload = this.prepareDebtPayload(this.fundLevel);
    let allIssuerForms;
    let finalResult = [];
    const portfolioSummary = portfolioSummaryPayload.companies;

    try {
      const response = await this.dataService.getAllIssuerForms(fundId).toPromise();
      allIssuerForms = response.body['response']

      allIssuerForms.forEach((eachAllIssuer: any) => {
        let result = []
        let companyId = "";
        eachAllIssuer.formIds.forEach(id => {
          portfolioSummary.forEach((eachPortfolioSummary: any) => {
            eachPortfolioSummary.valuationDates.forEach(dates => {
              if (id === dates.valuationDateId) {
                companyId = eachPortfolioSummary.companyId;
                result.push({ valuationDateId: id, companyId: companyId })
              }
            });
          })
        });
        if (result.length > 0) {
          finalResult.push(
            {
              companyId: companyId,
              issuerCompanyId: eachAllIssuer.issuerCompanyId,
              valuationDates: result
            }
          )
        }
      })
      return finalResult
    }

    catch (error) {
      console.log(error);
    }
  }

  /**
   * 
   * @param dates get all valuation dates present for the Debt profile
   * @returns recent valuation date
   */
  public getValuationDate(dates: [], ignoreExited?): void {
    let valuationD = [];
    for (let index = 0; index < dates.length; index++) {
      //For Covergae analysis tab, exited investments should be filtered out
      if(ignoreExited) {
        if(dates[index]['qxFormStatus'] != "EXITED") {
          valuationD.push(dates[index]['valutationDate']);
        }
      } else {
        valuationD.push(dates[index]['valutationDate']);
      }
    }
    valuationD = valuationD.sort();
    return valuationD[valuationD.length - 1];
  }

  /**
   * Get the list of users
   */
  public getUsers() {
    const myDetails = this.ums.getSelectedUserDetails();
    this.ums.getUsers(myDetails.organization.id).subscribe(result => {
      this.myOrgUsers = result.body["response"].filter(user => user.id !== myDetails.id);;
    }, error => {
      console.log("No users are found in my organization", error);
    })
  }

  /**
   * 
   * @param mapData : Graph data for the map
   * @returns 
   */
  initWorldMapChart(mapData) {
    return new MapChart({
      chart: {
        renderTo: 'map-chart',
        map: worldMap as any,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        margin: [0, 0, 0, 0]
      },
      title: { text: '' },
      subtitle: { text: '' },
      legend: { enabled: false },
      exporting: { enabled: false },
      credits: { enabled: false },
      mapNavigation: {
        enabled: false,
        buttonOptions: { verticalAlign: 'bottom' }
      },

      series: [{
        name: this.translateService.getLabel('countries'),
        color: '#E0E0E0',
        enableMouseTracking: false
      }, {
        name: mapData.name,
        joinBy: ['iso-a3', 'code3'],
        data: mapData.data,
        color: colors.chartCurrentValuationColor,
        minSize: 5,
        maxSize: '12%',
        tooltip: {
          pointFormatter: function () {
            return this.name + ":" + Number(this.z.toFixed(1)).toLocaleString()
          }
        }
      }] as any
    });
  }

  /**
   * Resets User assignment
   */
  resetUserAssignment() {
    this.myOrgUsers.forEach(user => {
      user.assigned = false;
      user.editable = '';
    })
  }


  /**
   * 
   * @param selectedFormForAssignment : Selected Form for assignment
   * @param newCompanyName : Company name
   */
  assignUsersToForm(selectedFormForAssignment: any, newCompanyName?: any) {

    const userDetails = this.ums.getSelectedUserDetails();


    if (newCompanyName) {
      // In case of new form creation, assign to all users with whome the fund is shared.
      const selectedFund = this.fundService.getFundById(selectedFormForAssignment.fundCompany);
      this.resetUserAssignment();


      if (selectedFund.details && this.myOrgUsers && this.myOrgUsers.length > 0) {
        const selectedFundDetails = JSON.parse(selectedFund.details);
        const assignees = selectedFundDetails.assignees;
        if (assignees) {
          this.myOrgUsers.forEach(user => {
            const assignedUser = assignees.find(u => u.id === user.id);

            user.assigned = !!assignedUser;

            user.editable = assignedUser ? assignedUser.editable || assignedUser.permission : null;

            if (selectedFormForAssignment.assessorUserId === user.id) {
              user.assigned = true;
              user.editable = 'W';
            } else if (this.ums.isMasterUser()) {
              //Form or application will be shared to Org master user by default.
              user.assigned = true;
              user.editable = 'W';
            }
          })
        }
      }
    }

    const usersList = [];
    const assignees = [];
    const editable = [];

    this.myOrgUsers.forEach(u => {
      if (u.editable && u.editable != "FN") {
        usersList.push(u.id);
        editable.push(u.editable);

        const uInfo = {
          id: u.id,
          userName: u.userName,
          userId: u.userId,
          editable: u.editable
        }

        assignees.push(uInfo);
      }
    });

    const createdBy = {
      id: userDetails.id,
      name: userDetails.userName,
      email: userDetails.userId
    }

    const body = {
      "users": usersList,
      "editable": editable,
      "formId": selectedFormForAssignment.id,
      "orgId": userDetails.organization.id,
      "usersInfo": JSON.stringify({ createdBy: createdBy, assignees: assignees })
    }

    this.dataService.assignUsersToSAFForm(body).subscribe(result => {
      // console.log("Assignmet result", result);

      if (!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("suc_user_assign_complete") + ".", this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }
      selectedFormForAssignment.assessorDetails.assignees = assignees
      this.ms.publish("loadingMessage", "");
    }, error => {
      console.log("Assignmet error", error);

      if (!newCompanyName) {
        this.utilService.showMessage(this.translateService.getLabel("err_failed_assign_user_form") + ".", this.translateService.getLabel("ok"))
      } else {
        this.utilService.showMessage(newCompanyName + " " + this.translateService.getLabel("valuation_is_initiated"), this.translateService.getLabel("ok"));
      }

      this.ms.publish("loadingMessage", "");
    })
  }


  /**
   * 
   * @param comp 
   * @param additionalParams 
   * @returns 
   */
  openValuation(comp, additionalParams="") {
    //Ignore if it is user added old valuation date
    if(!comp.id || comp.id.indexOf("_S") > 0) return;

    //Ignore if it is Consol form (which has business units)
    if(comp.businessUnits) return;

    const version = comp.formVersion; //this.ums.getApplicationVersion() + "";

    localStorage.setItem('formId', comp.id);
    localStorage.setItem('fv', version);
    localStorage.setItem('qubit-val-date', comp.valuationDate);
    localStorage.setItem('qubit-investment-date-id', this.companyId);
    localStorage.setItem("FUND_ID", comp.fundCompany);
    let fundName= this.fundService.getFundName(comp.fundCompany);
    let versionName=comp.versionName;
    let urlParameters = "id=" + comp.id + "&fv=" + version + "&fundName="+fundName + "&versionName="+versionName;
    if(comp.consolForm && this.companies && this.companies.length > 0) {
      const consolForm = this.companies.find( f => f.id == comp.consolForm);
      urlParameters += "&consol=" + consolForm.companyNameInForm;
    }
    this.ums.addUserAction("Valuation Date Selection", comp.id, comp.companyName+ " | " +comp.valuationDate, "Investment Summary");
    let url = environment.portalUrl + environment.pvValuation + "/#/valuation-summary?" + urlParameters + "&parentId=" + comp.fundCompany;

    if(comp.approvalRequestId) {
      url += "&aid="+comp.approvalRequestId;
    }

    if(additionalParams) {
      url += additionalParams;
    }

    window.open(url, "_self")
  }


  /**
   * 
   * @param latestCompanyForm 
   */
  initBU_ConsolSummary(latestCompanyForm) {    
    const enterpriseValue = this.getBusinessUnitParameterValue2("enterpriseValue", latestCompanyForm);
    const investment = {
      currency : latestCompanyForm.currency,
      enterpriseValue: enterpriseValue,

      equityValue: {
        weightedEquityValue: enterpriseValue,
        finalAdjustedEquityVal: enterpriseValue,
        stake: 100,
        finalStakeValue: enterpriseValue,
        stakeValue: enterpriseValue,
      }
    }

    let buValuationSummary = {
      currency:latestCompanyForm.investment && latestCompanyForm.investment.currency,
      sumOfTheParts: enterpriseValue,
      sumOfPartsAdj: null,
      weightedEquityValue: enterpriseValue,
      adjustmentsOnWeightedEquityValue: null,

      selectedTypeOfStakeValue: 'fullyDilutedShares',

      finalAdjustedEquityVal: enterpriseValue,
      stake: 100,
      stakeValue: enterpriseValue,
      finalAdjustments: null,
      finalStakeValue: enterpriseValue,
      numberOfShares: 0,
      sharePrice: 0,
      sharePriceFormatted: 0,
  
      customStake: {
        stakeFromModel : { value: 0 },
        manualEntry : { value: 100 }, 
        selectedOption: "manualEntry",
        dilutedStake: {value:100},
        impliedStakeWaterfall: {value:100},
        impliedStakeManpack: {value:100, exists: false},
        impliedStakeManOption: {value:100, exists: false},
      }
    }

    buValuationSummary = this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id];

    investment.equityValue.stake = buValuationSummary.stake;

    const equityAdjustments = buValuationSummary.sumOfPartsAdj;
    if(equityAdjustments) {
      const weightedEquityValue = this.getConsolAdjustedValue(investment.enterpriseValue, equityAdjustments);
      investment.equityValue.weightedEquityValue = weightedEquityValue;
      investment.equityValue.finalAdjustedEquityVal = weightedEquityValue;
    }

    const adjustmentsOnWeightedEquityValue = buValuationSummary.adjustmentsOnWeightedEquityValue;
    if(adjustmentsOnWeightedEquityValue) {
      investment.equityValue.finalAdjustedEquityVal = this.getConsolAdjustedValue(investment.equityValue.weightedEquityValue, adjustmentsOnWeightedEquityValue);
    }
    
    if(buValuationSummary.selectedTypeOfStakeValue == "fromWaterFallSummary") {
      // In case of `Waterfall Summary`, the stake value comes from waterfall model
      investment.equityValue.stakeValue = buValuationSummary.stakeValue;

    } else {

      const stakeValue = investment.equityValue.finalAdjustedEquityVal
          * investment.equityValue.stake
          / 100;

      investment.equityValue.stakeValue = stakeValue;
    }

    investment.equityValue.finalStakeValue = investment.equityValue.stakeValue;

    const finalAdjustments = buValuationSummary.finalAdjustments;
    if(finalAdjustments) {
      investment.equityValue.finalStakeValue = this.getConsolAdjustedValue(investment.equityValue.stakeValue, finalAdjustments);
    } 

    if(buValuationSummary.sumOfTheParts == 0) {    
      investment.equityValue.finalAdjustedEquityVal = 0;
      investment.equityValue.stakeValue = 0;
      investment.equityValue.finalStakeValue = 0;
    }

    latestCompanyForm.investment = investment;
    
    buValuationSummary.weightedEquityValue = investment.equityValue.weightedEquityValue;
    buValuationSummary.finalAdjustedEquityVal = investment.equityValue.finalAdjustedEquityVal;
    buValuationSummary.stakeValue = investment.equityValue.stakeValue;
    buValuationSummary.finalStakeValue = investment.equityValue.finalStakeValue;

    if(buValuationSummary.numberOfShares > 0) {
      buValuationSummary.sharePrice = (buValuationSummary.finalStakeValue * 1000000) / buValuationSummary.numberOfShares;
    }

    this.businessUnitsValSummaryForAllIds.summary[latestCompanyForm.id] = cloneDeep(buValuationSummary);
  }

  /**
   * 
   * @param parameter 
   * @param consolForm 
   * @returns 
   */
  getBusinessUnitParameterValue2(parameter, consolForm) {
    let total = 0
    
    consolForm.businessUnits.forEach( (bu) => {
      if(bu.investment) {
        const currencyCode = bu.investment.currency + consolForm.investment.currency + consolForm.valuationDate;
        // Example currency code USDEUR2021-03-31

        let exchnageRate = this.businessUnitsValSummaryForAllIds.currency[currencyCode];

        if(!exchnageRate) {
          exchnageRate = 1;
        }

        total += this.utilService.getValidNumber(bu.investment[parameter]) * exchnageRate;
      }
    })

    return total;
  }

  /**
   * 
   * @param startValue 
   * @param adjustments 
   * @returns 
   */
  getConsolAdjustedValue(startValue, adjustments) {
    let adjustedValue = startValue;

    if(adjustments) {
      adjustments.forEach(part => {
        if (part.type == "Add") {
          adjustedValue = adjustedValue + (part.convertedValue || part.value)
        }
        else {
          adjustedValue = adjustedValue - (part.convertedValue || part.value)
        }
      });
    }

    return adjustedValue;
  }

  /**
   * 
   */
  saveFundPortfolioWidget() {
    //Added to save currency and valuation Map values
    let portfolioDataReqBody = {
      selectedCurrency: this.portfolioServiceV2.selectedCurrency,
      valuationMap: this.mapData.value
    };

    this.dataService.saveWidgetDataToDB("PORTFOLIO_SUMMARY_DATA", JSON.stringify(portfolioDataReqBody), this.selectedFundId).subscribe(res=>{
      // console.log("SELECTED PORTFOLIO DATA SAVED TO WIDGET");
    }, error=>{
      console.log("Failed to save Selected portfolio data to db");
    })

    //Added to save currency and valuation Map values
  }

  getWaterfallFieldStatuses(companyId, selectedValuationDate: string) {
    this.dataService.getWidgetDataFromDB(`WATERFALL_ANALYSIS-${selectedValuationDate}`, companyId)
      .subscribe((res:any) => {
        this.waterfallFieldStatuses = res?.body?.response[0]?.widgetData;
      },
      (err) => {
        this.waterfallFieldStatuses = {
          goingConcernInputs: {
            low : {
            'ebitdaMultiple': false,
            'ebitda': false,
            'revenueMultiple': false,
            'revenue': false,
            'grossprofitMultiple': false,
            'grossprofit': false,
            'ebitMultiple': false,
            'ebit': false,
            'cash': false
          },
          midpoint : {
            'ebitdaMultiple': false,
            'ebitda': false,
            'revenueMultiple': false,
            'revenue': false,
            'grossprofitMultiple': false,
            'grossprofit': false,
            'ebitMultiple': false,
            'ebit': false,
            'cash': false
          },
          high : {
            'ebitdaMultiple': false,
            'ebitda': false,
            'revenueMultiple': false,
            'revenue': false,
            'grossprofitMultiple': false,
            'ebit': false,
            'grossprofit': false,
            'ebitMultiple': false,
            'cash': false
          }
        }
        };
      })
  }

  saveWaterfallFieldsStatusesToWidgetDB(valudationDate: string, data, companyId) {
    this.dataService.saveWidgetDataToDB(`WATERFALL_ANALYSIS-${valudationDate}`, data, companyId)
    .subscribe();
  }

  resetMannualWaterfallFieldsStatusesToWidgetDB(valudationDate: string, data, companyId) {
    return this.dataService.saveWidgetDataToDB(`WATERFALL_ANALYSIS-${valudationDate}`, data, companyId);
  }
}
