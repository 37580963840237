import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

/** Current name cannot match existing names */
function duplicateNameValidator(existingValues): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const res = existingValues.filter((item) => control.value?.trim()?.toLowerCase() === item?.name?.toLowerCase());
    return res.length > 0 ? { duplicate: true } : null;
  };
}

function duplicateVlNameAsync(dataService: DataService, cdr: ChangeDetectorRef, fundId: string,versionListId: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return dataService
      .isVlNameUnique({
        name: control.value,
        excludeVersionListId: versionListId ? versionListId : undefined,
        fundId: fundId,
      })
      .pipe(
        map((response) => (response.body?.response?.isExists ? { duplicateName: true } : null)),
        finalize(() => cdr.markForCheck()),
        catchError(() => of(null))
      );
  };
}

function duplicateAttributeListNameAsync(dataService: DataService, cdr: ChangeDetectorRef, fundId: string): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return dataService
      .isAttributeListNameUnique({
        name: control.value,
        fundId: fundId,
      })
      .pipe(
        map((response) => (response.body?.response?.isExists ? { duplicateName: true } : null)),
        finalize(() => cdr.markForCheck()),
        catchError(() => of(null))
      );
  };
}

export { duplicateNameValidator, duplicateVlNameAsync, duplicateAttributeListNameAsync };
