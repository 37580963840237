import { ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { DataService } from 'src/app/services/data.service';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { FundListService } from './fund-list-ui/fund-list.service';

@Injectable({
  providedIn: 'root'
})
export class PortfolioUtilService {

  constructor(public utilService : UtilService, 
    private ums : UserManagementService,
    private router : Router,
    private ds : DataService,
    private translateService : TranslateService,
    private fundService : FundListService) { }

  private mapData: any = {};

  portfolio: any = {
    current : {
      name: this.translateService.getLabel("current_portfolio"),
      expandPortfolio: true
    },
    exit: {
      name: this.translateService.getLabel("realised_portfolio"),
      expandPortfolio: false
    }
  }

  TYPE_OF_PORTFOLIO = {
    CURRENT_PORTFOLIO : "currentPortfolio",
    REALISED_PORTFOLIO : "realisedPortfolio"
  }

  ACTIONS_TYPE = {
    COMPANY_ROW_CLICK : "COMPANY_ROW_CLICK",
    ADD_COMPANY : "ADD_COMPANY",
    EXCEL_DOWNLOAD : "EXCEL_DOWNLOAD",
    DELETE_COMPANY : "DELETE_COMPANY"
  }

  getPortfolioCompaniesByType(allPortfolioData, typeOfPortfolioKey){
    const portfolioCompanies = allPortfolioData[typeOfPortfolioKey].portfolioData;
    return portfolioCompanies.sort((c1, c2) => c1.investmentDate > c2.investmentDate ? -1: 1);
  }

  getPortfolioSummaryAggregationByType(allPortfolioData, typeOfPortfolioKey){
    return allPortfolioData[typeOfPortfolioKey].aggregations;
  }

  getTotalPortfolioSummaryAggregations(allPortfolioData){
    return allPortfolioData.totalAggregations;
  }

  prepareMapInformation(portfolioCompanies) {

    let codeCountryName = [];

    const geographicalFormData = [];

    portfolioCompanies.map(form => {
      if(form.geography){
        codeCountryName.push({
          alias: form["geography-cbAlias"], 
          countryName: form.geography
        });
      }
    })

    let alias = '';
    let countryName = '';
    let filteredData = codeCountryName.filter((data)=>{
      if(alias == ''){
        alias=data.alias;
        countryName = data.countryName;
      }
      else{
        if(alias == data.alias && countryName == data.countryName)
          return false
        else
          alias = data.alias
          countryName = data.countryName
          return true
      }

    })

    let geoCode = codeCountryName.map((code) => code.alias)

    let geo = geoCode.filter( (code, index) => {
      return geoCode.indexOf(code) == index;
    })
  
    geo.map( geoCode =>{
      let formData = [];
      portfolioCompanies.filter(form =>{
        if(form.geography){
          if(form["geography-cbAlias"] == geoCode){
            formData.push(form);
          }
        }
      });
      const geoData = {
        countryName:'',
        countryCode:'', 
        forms:[]
      }

      let countryName;
      filteredData.map( data =>{
        if(data.alias == geoCode){
          countryName = data.countryName;
        }
      })
      geoData.countryName = countryName;
      geoData.countryCode = geoCode;
      geoData.forms = formData;
      geographicalFormData.push(geoData);
    })
    return this.updateWorldMap(geographicalFormData);
  }

  private updateWorldMap(geographicalFormData){
    this.mapData.data = [];

    geographicalFormData.forEach((formData)=>{

      const data = { code3:'', name:'', z: 0 }
      
      if(this.mapData.value == "changeInVal"){
        this.mapData.name = "Change In Valuation";
        let changeInValCountryWise = 0;

        formData.forms.map((form) => {
          changeInValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = changeInValCountryWise;
      }
      else if(this.mapData.value == "prevQuarterVal"){
        this.mapData.name = "Previous Quarter Valuation";
        let prevQuarValCountryWise = 0;

        formData.forms.forEach((form) => {          
          prevQuarValCountryWise += form.valuesInPortfolioCurrency.changeInValuation;
        });

        data.z = prevQuarValCountryWise;
      }
      else if(this.mapData.value == "noOfComp"){
        this.mapData.name = "Total Number of Companies";
        let totalNoOfComp = formData.forms.length? formData.forms.length: 0 ;
        data.z = totalNoOfComp;
      }
      else{
        this.mapData.name = "Current Valuation";
        let totalValCountryWise = 0;

        formData.forms.forEach((form) => {
          
          totalValCountryWise += form.valuesInPortfolioCurrency.stakeValue
        });
        data.z = totalValCountryWise;
      }

      data.code3 = formData.countryCode;
      data.name = formData.countryName;
      this.mapData.data.push(data);
    });

    return this.mapData;
  }

  prepareColumnChartInformation(portfolioCompanies) {
    let columnChartData = [];

    const sectors = [];

    portfolioCompanies.forEach(form =>{
      if(form.sector){
        if(sectors.indexOf(form.sector) == -1){
          sectors.push(form.sector);
        }
      }
    });

    sectors.forEach(sector => {
      const formsWithSameSector = portfolioCompanies.filter(form => form.sector == sector);
      let prevValDate: number = 0;
      let currValDate: number = 0;


      formsWithSameSector.forEach((comp) => {
        const currentValDateStakeValue = this.utilService.getValidNumber(comp.valuesInPortfolioCurrency.stakeValue);
        // const changeInStakeValue = comp.stakeVal * comp.changeInValPercentage

        // const prevValDateStakeValue = this.utilService.getValidNumber((currentValDateStakeValue - comp.changeInStakeValue) * comp['exchangeRate']);
        const prevValDateStakeValue = this.utilService.getValidNumber(comp.valuesInPortfolioCurrency.prevStakeValue);

        currValDate += currentValDateStakeValue;

        prevValDate += prevValDateStakeValue;
      })

      columnChartData.push({sectorName: sector, prevValDate: prevValDate, currValDate: currValDate});
    });

    columnChartData = columnChartData.sort((sec1, sec2) => {
      return (sec2.currValDate - sec1.currValDate);
    });

    return columnChartData;
  }

  emittingActionOnPortfolio(event, id){
    const type : string = event.type;
    const details = event.details
    switch(type){
      case this.ACTIONS_TYPE.COMPANY_ROW_CLICK:
        this.ums.addUserAction("Company Selection", details.data.id, details.data.companyName, "Investment Summary");
        this.router.navigateByUrl('/company-valuation/' + details.data.id + "?parentId=" + details.data.fundId);
        break;
      
      case this.ACTIONS_TYPE.ADD_COMPANY:
        //ToDo: Integrate Add Company Logic
        break;
        
      case this.ACTIONS_TYPE.EXCEL_DOWNLOAD:
        this.downloadExcelReportOfSummary(details, id)
        break; 
        
      case this.ACTIONS_TYPE.DELETE_COMPANY:
        //ToDO: Integrate Delete Company Logic
        break;   
    }
  }

  downloadExcelReportOfSummary(details, id){
    const allPortfolioData = details.portfolioData;
    const reportingCurrency = details.reportingCurrency;

    let fileData = this.getExcelDownLoadRequestBody(allPortfolioData, reportingCurrency);
    this.ds.downloadExcelFile(fileData).subscribe((fileData) => {
      this.manageFileDownload(fileData, "xlsx", details.downloadElement, id);
    }, err => {
      console.log("Failed to download file", err)
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    });
  }

  private getExcelDownLoadRequestBody(allPortfolioData, reportingCurrency) {

    let headers = ["", "Name", "Investment Date", "Exit Date", "Currency", "Stake", "Investment Amount", "Realised Proceeds", "NAV", "Total Value", "Gross IRR (%)", "MOIC (x)", "From Last Val Date (%)"];
    const values = [];

    this.prepareExcelRowsForComps("Current Portfolio", this.getPortfolioCompaniesByType(allPortfolioData, 
      this.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO), values, true);

    this.prepareExcelRowsForAggregations("Total (Current)", this.getPortfolioSummaryAggregationByType(allPortfolioData, 
      this.TYPE_OF_PORTFOLIO.CURRENT_PORTFOLIO), values, reportingCurrency);

    this.prepareExcelRowsForComps("Realised Portfolio", this.getPortfolioCompaniesByType(allPortfolioData, 
      this.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO), values, reportingCurrency, false);

    this.prepareExcelRowsForAggregations("Total (Realised)", this.getPortfolioSummaryAggregationByType(allPortfolioData, 
      this.TYPE_OF_PORTFOLIO.REALISED_PORTFOLIO), values, reportingCurrency);
    
    values.push([ "", "", "", "", "", "", "", "", "", "", ""]);
    
    this.prepareExcelRowsForAggregations("Total (Current + Realised)", this.getTotalPortfolioSummaryAggregations(allPortfolioData), 
    values, reportingCurrency);

    return [{ "tabName": "Portfolio", "data": {"headers": headers, "values": values} }];
  }

  private prepareExcelRowsForComps(header, comps, values, reportingCurrency, current?) {
    values.push([ header, "", "", "", "", "", "", "", "", "", ""]);

    comps.forEach(comp =>{

      values.push(["", comp.companyName, 
        this.utilService.displayFormattedDate(comp.investmentDate, "ll"), 
        current? "" : comp.exitDate,
        reportingCurrency,
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.stake), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.investmentAmount),
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.realisedProceeds), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.stakeValue), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.totalValue), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.grossIRR), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.moic), 
        this.utilService.getValueForExcelWithNegatives(comp.valuesInPortfolioCurrency.changeInValuationPercentage)
      ]);
    })

    values.push([ "", "", "", "", "", "", "", "", "", ""]);
  }

  private prepareExcelRowsForAggregations(header, totals, values, reportingCurrency) {

    values.push([ "", header, "", "", reportingCurrency,
      "",
      this.utilService.getValueForExcelWithNegatives(totals.totalInvestment), 
      this.utilService.getValueForExcelWithNegatives(totals.totalRealisedProceeds),
      this.utilService.getValueForExcelWithNegatives(totals.totalStakeValue), 
      this.utilService.getValueForExcelWithNegatives(totals.totalTotalValue),
      this.utilService.getValueForExcelWithNegatives(totals.totalIRR),
      this.utilService.getValueForExcelWithNegatives(totals.totalMOIC), 
      this.utilService.getValueForExcelWithNegatives(totals.totalChangeInValPercentage)
    ]);

    values.push([ "", "", "", "", "", "", "", "", ""]);
  }

  manageFileDownload(fileData, type, downloadElement : ElementRef, id) {
    let fundName = this.fundService.getFundName(id);
    let date = moment().format("MMM DD YYYY").replace(/ /gi, "-");
    try {
      const url = (window as any).URL.createObjectURL(fileData.body);
      downloadElement.nativeElement.href = url;
      downloadElement.nativeElement.target = "_blank";
      downloadElement.nativeElement.download = fundName + "_Portfolio_" + date + "." + type;
      downloadElement.nativeElement.click();
    } catch (e) {
      console.log("Failed to download file", e)
      this.utilService.showMessage(this.translateService.getLabel("err_download_file"), this.translateService.getLabel("ok"))
    }
  }


  initConsolidatedIRR(fundId, currentPortfolioCompanies, exitedPortfolioCompanies){
    let IRR_ReqBodyForAllCompanies = {
      "request_id": fundId,
      "data": [],
    };

    
    currentPortfolioCompanies.forEach(comp => {
      // IRR in Valuation Currency is already stored
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInLocalCurrency", "IRRReqBody", "LOCAL"));
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInPortfolioCurrency", "IRRInDestCurrReqBody", "DEST"));
    })

    exitedPortfolioCompanies.forEach(comp => {
      // IRR in Valuation Currency is already stored
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInLocalCurrency", "IRRReqBody", "LOCAL"));
      IRR_ReqBodyForAllCompanies.data.push(this.prepareIRR_ReqBodyForCompany(comp, "valuesInPortfolioCurrency", "IRRInDestCurrReqBody", "DEST"));
    })

    IRR_ReqBodyForAllCompanies.data = IRR_ReqBodyForAllCompanies.data.filter(reqBody => reqBody != null);

    return this.ds.getIRRForMultipleRequests(IRR_ReqBodyForAllCompanies);
  }

  initGroupIRR(aggregation, type, fundId) {
    aggregation.totalIRR = "loading";

    const IRRInDestCurrReqBody = {
      "request_id": fundId + "_" + type,
      "data": aggregation.IRRInDestCurrReqBody
    }

    return this.ds.getIRR(IRRInDestCurrReqBody);
  }

  prepareIRR_ReqBodyForCompany(comp, type, reqBodyKey, currencyType) {
    if(comp && comp[type] && comp[type][reqBodyKey]){
      comp[type].grossIRR = "loading";

      const IRRInDestCurrReqBody = {
        "request_id": comp.id + "_" + currencyType,
        "data": comp[type][reqBodyKey]
      }

      return IRRInDestCurrReqBody;
    }

    return null;
  }

}
