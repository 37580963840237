import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "momentDate",
})
export class MomemtDatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, mode?: 'utc'): any {
    if (!value) {
      return '';
    }
    if(mode) {
      return moment.utc(value).local().format('MMM DD, YYYY');
    }
    
    return moment(value).format('MM/DD/YYYY');
  }
}
