import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'dmDecimal'
})
export class DmDecimalPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: number, digitsInfo: string = "1.1-1"): any {
    if (isNaN(value))
      return value;

    const newValue = this.decimalPipe.transform(Math.abs(value), digitsInfo);

    return (value < 0) ? `(${newValue})` : newValue;
  }

}
