<div class="modal-header">
    <div class="modal-title">
      <span style="font-size: 22px;">Adding a Valuation Date</span>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="valuationDateSelectionPopup.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="model-content">
      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("valuation_date")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field class="rollover-text-field" (click)="vDatePicker.open()">
            <input matInput [matDatepicker]="vDatePicker" readonly class="date-field" 
            [(ngModel)]="valuationDate" (dateChange)="marketDateValidator()">
            <mat-datepicker-toggle matSuffix [for]="vDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #vDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("market_date")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field class="rollover-text-field" (click)="mDatePicker.open()">
            <input matInput [matDatepicker]="mDatePicker" readonly class="date-field" 
            [(ngModel)]="marketDataDate" [max]="marketDataDate">
            <mat-datepicker-toggle matSuffix [for]="mDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #mDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("credit_financial_date")}}
        </div>
        <div class="col modal-textbox">
          <mat-form-field class="rollover-text-field" (click)="fDatePicker.open()">
            <input matInput [matDatepicker]="fDatePicker" class="date-field" 
            [(ngModel)]="financialDate">
            <mat-datepicker-toggle matSuffix [for]="fDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fDatePicker class="datepicker"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row p-modal-row">
        <div class="col-4 p-modal-left-col">
          {{translateService.getLabel("version_name")}}
        </div>
        <div class="col modal-textbox">
            <mat-form-field class="rollover-text-field">
                <input type="text" matInput [(ngModel)] ="versionName">
            </mat-form-field>
        </div>
      </div>

  <div class="row p-modal-row">
    <div class="col-5 p-modal-left-col" style="align-self: center;">
        {{ translateService.getLabel("credit_refresh_issuer_financials") }}
    </div>
    <div class="col modal-textbox" style="text-align: left; padding-left: 0px;">
        <button mat-raised-button class="primary-bg" 
            (click)="refreshFinancials = true;"
            [ngClass]="{'btn-custom-primary': refreshFinancials , 'btn-custom-secondry': !refreshFinancials}">
            {{ translateService.getLabel("yes") }}
        </button> &nbsp;
        <button mat-raised-button class="btn-custom-secondry " 
            (click)="refreshFinancials = false;"
            [ngClass]="{'btn-custom-primary': !refreshFinancials, 'btn-custom-secondry ': refreshFinancials}">
            {{ translateService.getLabel("no") }}
        </button>
    </div>
</div>
<div class="row p-modal-row" >
    <div class="col-5 p-modal-left-col" style="align-self: center;">
        {{ translateService.getLabel("credit_refresh_security_details") }}
    </div>
    <div class="col modal-textbox" style="text-align: left; padding-left: 0px;">
        <button mat-raised-button class="primary-bg"  
            (click)="refreshSecurityDetails = true;"
            [ngClass]="{'btn-custom-primary': refreshSecurityDetails , 'btn-custom-secondry': !refreshSecurityDetails}">
            {{ translateService.getLabel("yes") }}
        </button> &nbsp;
        <button mat-raised-button class="btn-custom-secondry"
            (click)="refreshSecurityDetails=false;"
            [ngClass]="{'btn-custom-primary': !refreshSecurityDetails, 'btn-custom-secondry': refreshSecurityDetails}">
            {{ translateService.getLabel("no") }}
        </button>
    </div>
</div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-save" style="width: 12.5%;" (click)="updateValuationDetails();valuationDateSelectionPopup.dismiss('Cross click')">
      {{translateService.getLabel("submit")}}
    </button>
  </div>
 