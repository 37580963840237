<div class="mat-elevation-z1 wrapper">
    <table mat-table [dataSource]="tabeDataSource">
      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef> Org Name </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.orgName}}
  
        </td>
      </ng-container>
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell *matCellDef="let map;index as i"
          [class.error-cell]="map?.isCompanyMissingInMonitoring || map?.isCompanyMissingInReporting">
          <span *ngIf="map?.isCompanyMissingInReporting" matTooltip="Company is missing in reporting.">
            {{map?.companyName}}
          </span>
          <span *ngIf="map?.isCompanyMissingInMonitoring" matTooltip="Company is missing in monitoring.">
            {{map?.companyName}}
          </span>
          <span *ngIf="!map?.isCompanyMissingInReporting && !map?.isCompanyMissingInMonitoring">
            {{map?.companyName}}
          </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="gxFinancialRows">
        <th mat-header-cell *matHeaderCellDef> Platform Financial </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.gxFinancialRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsFinancialRows">
        <th mat-header-cell *matHeaderCellDef> Snowflake DB Financial </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsFinancialRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="financialRowsDiff">
        <th mat-header-cell *matHeaderCellDef> Financial Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.financialRowsDiff !== 0">
          {{map?.financialRowsDiff}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="gxGeneralDetailsRows">
        <th mat-header-cell *matHeaderCellDef> Platform General Details </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.gxGeneralDetailsRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsGeneralDetailsRows">
        <th mat-header-cell *matHeaderCellDef> Snowflake General Details </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsGeneralDetailsRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="generalDetailsRowsDiff">
        <th mat-header-cell *matHeaderCellDef> GD Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.generalDetailsRowsDiff !== 0">
          {{map?.generalDetailsRowsDiff}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="gxProfileDetailsRows">
        <th mat-header-cell *matHeaderCellDef> Platform Profile </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.gxProfileDetailsRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="reportsProfileDetailsRows">
        <th mat-header-cell *matHeaderCellDef> Snowflake Profile </th>
        <td mat-cell *matCellDef="let map;index as i">
          {{map?.reportsProfileDetailsRows}}
  
        </td>
      </ng-container>
  
      <ng-container matColumnDef="profileDetailsRowsDiff">
        <th mat-header-cell *matHeaderCellDef> Profile Delta </th>
        <td mat-cell *matCellDef="let map;index as i" [class.error-cell]="map?.profileDetailsRowsDiff !== 0">
          {{map?.profileDetailsRowsDiff}}
        </td>
      </ng-container>
  
      <ng-container matColumnDef="nodata">
        <td mat-footer-row *matFooterCellDef class="no-companies" [colSpan]="displayedOrgColumns.length">
          No delta found.
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedOrgColumns" (click)="onRowClick($event,row)"></tr>
      <tr mat-footer-row [hidden]="tabeDataSource?.data?.length >0" *matFooterRowDef="['nodata']">
  
  
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>