export class SelectionValue {
    isSelected : boolean = false;
    value: number = 0;
}

export class Threshold {
    isSelected : boolean = false;
    changeInNAVBetweenValDates: SelectionValue = new SelectionValue();
    changeInTotalPortfolioNAV: SelectionValue = new SelectionValue();
}

export class RiskReturnFramework {
    isSelected: boolean = false;
}

export class ImpliedDecline {
    isSelected: boolean = false;
}

export class DiscountRateSizePremium {
  isSelected: boolean = false;
}
