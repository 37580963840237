<div class="wrapper mat-elevation-z1">
<table mat-table [dataSource]="_versionList">
  <ng-container matColumnDef="versionName">
    <th mat-header-cell *matHeaderCellDef> Version List </th>
    <td mat-cell *matCellDef="let ver;index as i">
      {{ver?.name}}

    </td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef> Created By </th>
    <td mat-cell *matCellDef="let ver;index as i">
      {{ver?.createdBy}}

    </td>
  </ng-container>
  <ng-container matColumnDef="uploadDate">
    <th mat-header-cell *matHeaderCellDef> Upload Date </th>
    <td mat-cell *matCellDef="let ver;index as i">
      {{ver?.uploadDate | date: 'mediumDate' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th class="center" mat-header-cell *matHeaderCellDef> Edit </th>
    <td class="center" mat-cell *matCellDef="let ver;index as i">
      <button mat-icon-button [disabled]="!ver?.actions?.canEdit" (click)="onEditVer($event,ver,i)"  class="edit-icon icon-primary" [routerLink]="['../edit-version', ver?.id]"  >
        <i class="fas fa-edit" matTooltip="Cannot be edited as it is already in use" [matTooltipDisabled]="ver?.actions?.canEdit" matTooltipPosition="left"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th class="center" mat-header-cell *matHeaderCellDef> Delete </th>
    <td class="center" mat-cell *matCellDef="let ver;index as i">     
      <button mat-icon-button [disabled]="!ver?.actions?.canDelete" class="delete-icon icon-primary" (click)="onDeleteVer($event,ver,i)" >
        <i class="fas fa-trash" matTooltip="Cannot be deleted as it is already in use" [matTooltipDisabled]="ver?.actions?.canDelete" matTooltipPosition="left"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="download" >
    <th class="center" mat-header-cell *matHeaderCellDef> Download </th>
    <td class="center" mat-cell *matCellDef="let ver;index as i">
      <button *ngIf="ver?.actions" mat-icon-button class="download icon-primary" (click)="onDownloadVer($event,ver, i)" >
        <i class="fas fa-download"></i>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedOrgColumns" (click)="onRowClick($event, row)"></tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>
