import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AssignedCompany } from '../ca-interfaces';

@Component({
  selector: 'app-assignment-table',
  templateUrl: './assignment-table.component.html',
  styleUrls: ['./assignment-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentTableComponent implements OnInit {
  companiesDataSource:  MatTableDataSource<AssignedCompany>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Input()
  set dataSource(allCompanies: AssignedCompany[]) {
    if (allCompanies) {
      // this.companies = [...allCompanies];
      this.companiesDataSource = new MatTableDataSource([...allCompanies]);
      this.companiesDataSource.paginator = this.paginator;
    }
  }

  @Output() onDelete = new EventEmitter<AssignedCompany>();

  displayedColumns = [
    'companyName',
    'attributeList',
    'versionList',
    'assignedBy',
    'uploadDate',
    'delete',
  ];

  constructor() {}

  ngOnInit(): void {
    // this.companiesDataSource.paginator = this.paginator;
  }

  onRowClick(e, row) {
    console.log(e, row);
  }

  onDeleteAssignment(e: UIEvent, row, index) {
    e.stopPropagation();
    this.onDelete.emit(row);
  }
}
