import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/utils/util.service';
import { DebtPortfolioService } from '../debt-portfolio.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { DatePipe } from '@angular/common';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { generalConstants } from '../constants/general-constans';
import { ContextMenuItemModel, GridComponent } from '@syncfusion/ej2-angular-grids';
import { investmentContextMenuItems, MenuItemIds } from './investment-context-menu-items';
import { MenuEventArgs } from '@syncfusion/ej2-angular-navigations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import * as moment from 'moment/moment';
import { DmDecimalPipe } from 'src/app/pipes/dm-decimal/dm-decimal.pipe';
import { TranslateService } from 'src/app/services/translation.service';
import { numberFormat } from 'highcharts';
import { DynamicDecimalPipe } from 'src/app/pipes/dynamic-decimal/dynamic-dm-decimal.pipe';

@Component({
  selector: 'app-debt-investment-page-summary',
  templateUrl: './debt-investment-page-summary.component.html',
  styleUrls: ['./debt-investment-page-summary.component.scss']
})
export class DebtInvestmentPageSummaryComponent implements OnInit {

  yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridFilterSettings = { type: 'Excel' };
  gridToolbar = ['ColumnChooser'];
  contextMenuItems: ContextMenuItemModel[] = investmentContextMenuItems;
  selectedCompanyDates = [];

  // charts
  valueTrendChart;
  amountTrendChart;
  percentOfParTrendChart;
  cleanPricePercentOfParTrendChart;
  companySpecificSpreadTrend;
  leverageTrendChart;
  coverageTrendChart;
  profitablityTrendChart;
  cashBalanceTrendChart;
  cleanCashTrendChart;
  ebitdaTrendChart;


  debtCompanyDetails;
  debtCompanyApiData;
  debtValuationApiData;
  investmentDateInfo;
  selectedCompanyJSON;
  formData;
  debtValuationDetails: any = [
      {
        loading: true
      }
  ];
  seniorityAndType: string;
  originationDate: string;
  exitDate: string = '';
  exitDateAtCurrentInvestmentStatus : string = '';

  exitInvestmentFormGroup : FormGroup;
  exitedInvestmentDefaultValues = {};
  financialCurrency: any;

  @Input() companyIdForExitInvestment: string
  @ViewChild('investmentSummaryGrid') public investmentSummaryGrid: GridComponent;

  isExitInvestmentLoading: boolean;
  userOrgDetails: any;
  investmentSelectedColumns = [];

  //Common object variable to access key and value
  objectKeysValues = Object;
  //Discount Rate
  discountTableTitle: any[] = [
        { key: 'marketImpliedYtm', value: this.translateService.getLabel("market_implied_ytm") },
        { key: 'impliedYield', value: this.translateService.getLabel("credit_implied_yield") },
        { key: 'impliedSpreadOriginationDate',value: this.translateService.getLabel("credit_implied_spread_origination_date")},
        { key: 'riskFreeRateAtOriginationDate', value: this.translateService.getLabel("credit_risk_free_rate_at_origination") },
        { key: 'creditQuality', value: this.translateService.getLabel("credit_credit_quality_adjustment") },
        { key: 'riskFreeRateAtValuationDate', value: this.translateService.getLabel("credit_risk_free_rate_at_valuation") },
        { key: 'concludedDiscountRate', value: this.translateService.getLabel("credit_concluded_discount_rate") },
        { key: 'concludedCreditSpread', value: this.translateService.getLabel("concluded_credit_spread") },
        { key: 'impliedSpreadAtOriginationDate', value: this.translateService.getLabel("credit_implied_spread_at_origination_date") },
        { key: 'impliedSpreadAtValuationDate', value: this.translateService.getLabel("credit_implied_spread_at_valuation_date") },
        
   ];

  //Discount Rate Key
  allDiscountRate:any[]=[];
  //Calibration Keys
  allDiscountRateCalibration:any[]=[];
  allDiscountCalibrationCustomAdjustmentkey:any[]=[];
  //Build Up keys
  allDiscountRateBuildUpKey:any[]=[];
  allDiscountBuildUpCustomAdjustmentKey:any[]=[];

  //Financial 
  allFinancialTitle:any[]=[
    { key: 'revenue', value: this.translateService.getLabel("revenue") },
    { key: 'ebidta', value: this.translateService.getLabel("ebitda") },
    { key: 'cash', value: this.translateService.getLabel("cash") },
    { key: 'longTermDebt', value: this.translateService.getLabel("credit_long_term_debt") },
    { key: 'shortTermDebt', value: this.translateService.getLabel("short_term_debt") }
  ];
  allFinancialKey:any[]=[];
  allFinancial:any[]=[];

  //Shadow rating matric table
  allShadowRatingMatric:any[] = [];
  allShadowRatingMethodLabel:any[] = [];
  allScores:any[]=[];



  constructor( private dataService: DataService,
	              private activatedRoute: ActivatedRoute,
               private utilService: UtilService,
               public debtPortfolioService: DebtPortfolioService,
               private datePipe: DatePipe,
               private dmDecimalPipe: DmDecimalPipe,
	              public ums: UserManagementService,
               private fundService: FundListService,
               private formBuilder: FormBuilder,
               public translateService: TranslateService,
               public dynamicDecimalPipe:DynamicDecimalPipe       
  ) { 
      this.exitInvestmentFormGroup = this.formBuilder.group({
          exitFormSelect : [],
      });
  }

  async ngOnInit() {
	const userDetails = this.ums.getSelectedUserDetails();
    this.userOrgDetails = userDetails;
	const params = this.activatedRoute.snapshot.queryParamMap;
	const fundId = params.get("fundId");
	const companyId = params.get("companyId");
    this.getDiscountRateFinancialMatrix(companyId);
    this.getGlobalConfigStatus(this.userOrgDetails?.organization.id,companyId)

 try {
      this.getExitedInvestmentDetails();
      this.getUserSelectedColumns();
      this.dataService.getDebtCompanyDetails(this.debtPortfolioService.selectedCompanyId)
        .subscribe((resp:any) => {
            if(resp?.body["response"]) {
                this.debtCompanyDetails = resp.body["response"].companyDetails;
                this.debtPortfolioService.securityCurrency = this.debtCompanyDetails?.currency;
                //Get Company Logo
                if (this.debtCompanyDetails) {
                    if (this.debtPortfolioService.allCompanyLogos) {
                        const logoFound = this.debtPortfolioService.allCompanyLogos[companyId];
                        this.debtCompanyDetails.logoURL = logoFound;
                    } else {
                        this.getAllLogos(userDetails, fundId, companyId);
                    }
                }
              }
        })

	     this.dataService.getAllValuationDateForms(userDetails.id, fundId, companyId, userDetails.organization.id).pipe(
	    mergeMap((res: any) => {
		    if (res?.body?.response) {
			    const payload = res.body.response;
				   return this.debtPortfolioService.getDebtValuationSummary(payload);
			}
		    return of(null);
		}))
		.subscribe((resp: any) => {
			if (resp?.body["response"]) {
			    let data = resp.body["response"];
				   if (data?.companies.length > 0) {
					this.debtValuationDetails = data.companies[0].valuationDates;
					this.debtValuationDetails.sort((a, b) => {
					    return <any>new Date(a.date) - <any>new Date(b.date);
					});
                    const debtValuationDetailsLen = (data.companies[0].valuationDates?.length -1);
                    this.seniorityAndType = generalConstants.seniorityType[data.companies[0].valuationDates[debtValuationDetailsLen].seniority];
					this.originationDate = data.companies[0].valuationDates[debtValuationDetailsLen].originationDate;
                    this.exitDateAtCurrentInvestmentStatus = data.companies[0].valuationDates[debtValuationDetailsLen].maturityDate;
                    this.financialCurrency = data.companies[0].valuationDates[debtValuationDetailsLen].latestfinancialCurrency;
                    if(this.exitInvestmentFormGroup?.get('exitFormSelect')?.value === 'Current') {
                    this.exitDate = data.companies[0].valuationDates[debtValuationDetailsLen].maturityDate;
                    this.financialCurrency && this.refreshColumnHeaders();
                    }
                    this.initAllCharts();
				}
			}
		})

      if (!this.fundService.allFunds?.length) {
            this.fundService.getFunds(userDetails);
          }
    } catch(e) {
      console.log("Error in getting debt data", e)
    }
  }

  getExitedInvestmentDetails(userInputExitDate?: string) {
    this.isExitInvestmentLoading = true;
    this.dataService.getExitedInvestmentDetails(this.companyIdForExitInvestment, userInputExitDate).subscribe((resp) => {
        this.isExitInvestmentLoading = false;
        const res = resp['body']['response'];
        // const res = resp['response'];
        this.exitedInvestmentDefaultValues = res;
        // this.exitDate = res?.exitDate;
        this.exitInvestmentFormGroup.get('exitFormSelect')?.setValue(res?.investmentStatus);
        if(res.investmentStatus === 'Exited') {
            this.addExitFormRelatedCtrls(res);
            this.exitDate = res?.exitDate;
        } else if (res.investmentStatus === 'Current') {
            this.exitDate = this.exitDateAtCurrentInvestmentStatus;
        }
        if(userInputExitDate) {
        this.exitInvestmentFormGroup.get('exitFormSelect')?.setValue('Exited');
        this.exitInvestmentFormGroup.get('exitFormNotionalOS').setValue(this.dmDecimalPipe.transform(res?.notionalOutStanding));
        }
    },
    (err) => {
        this.isExitInvestmentLoading = false;
        console.log(err);
    }
    );
  }

  onInvestmentStatusChange(event: MatSelectChange) {
    if(event.value === 'Exited') {
        this.addExitFormRelatedCtrls(this.exitedInvestmentDefaultValues)
    } else {
        this.removeExitFormRelatedCtrls()
    }
  }

  addExitFormRelatedCtrls(res) {
      const exitDate = res.exitDate ? res.exitDate : this.yesterday;
      this.exitInvestmentFormGroup.addControl('exitFormDate', new FormControl(exitDate));
      this.exitInvestmentFormGroup.addControl('exitFormPercentOfPar', new FormControl(this.dmDecimalPipe.transform(res?.percentOfPar)));
      this.exitInvestmentFormGroup.addControl('exitFormNotionalOS', new FormControl(this.dmDecimalPipe.transform(res?.notionalOutStanding)));
  }

  getUserSelectedColumns() {
    this.dataService.getWidgetDataFromDB('INVESTMENT_SUMMARY_COLUMNS', this.userOrgDetails.organization.id)
      .subscribe((res: any) => {
        this.investmentSelectedColumns = res?.body?.response[0]?.widgetData;
      })
    }
  
    columnsSelected(event) {
      if(event.requestType == 'columnstate' && !event.columns?.name) {
        let selectedColumns = [...this.investmentSelectedColumns];
        event.columns.map((column:any) => {
          if(!column.visible) {
            selectedColumns.push(column.headerText);
          } else {
            let index = selectedColumns.indexOf(column.headerText);
            if(index > -1)
            selectedColumns.splice(index, 1)
          }
        })
        this.dataService.saveWidgetDataToDB('INVESTMENT_SUMMARY_COLUMNS', selectedColumns, this.userOrgDetails.organization.id)
          .subscribe();
      }
    }

  refreshColumnHeaders() {
    //Revenue
    let revenueColumn = this.investmentSummaryGrid.getColumnByField('revenue')
    revenueColumn.headerText = this.translateService.getLabel("revenue") + " (" + this.financialCurrency + " Mn)";

    //Ebitda
    let ebitdaColumn = this.investmentSummaryGrid.getColumnByField('ebitda')
    ebitdaColumn.headerText = this.translateService.getLabel("ebitda") + " (" + this.financialCurrency + " Mn)";

    //Cash
    let column = this.investmentSummaryGrid.getColumnByField('cash')
    column.headerText = this.translateService.getLabel("cash") + " (" + this.financialCurrency + " Mn)";

    //this.investmentSummaryGrid.refreshColumns();

    this.investmentSelectedColumns?.length && this.investmentSummaryGrid.hideColumns(this.investmentSelectedColumns);
    this.investmentSummaryGrid.refresh();
}

  removeExitFormRelatedCtrls() {
    this.exitInvestmentFormGroup.removeControl('exitFormDate');
    this.exitInvestmentFormGroup.removeControl('exitFormPercentOfPar');
    this.exitInvestmentFormGroup.removeControl('exitFormNotionalOS');
  }

  onExitDateChange(event) {
      const exitDate = moment(event.value).format('YYYY-MM-DD');
      this.getExitedInvestmentDetails(exitDate);
  }

  onSaveClick() {
      const investmentStatus = this.exitInvestmentFormGroup.value.exitFormSelect?.toLowerCase();
      const formattedExitDate = moment(this.exitInvestmentFormGroup.value.exitFormDate).format('YYYY-MM-DD');
      let reqBody: any = {};
      if(investmentStatus === 'current') {
      reqBody =  { "investmentStatus" : this.exitInvestmentFormGroup.value.exitFormSelect};
        }
      else {
        reqBody = {
            "investmentStatus":this.exitInvestmentFormGroup.value.exitFormSelect,
            "exitDate": formattedExitDate,
            "percentOfPar":this.exitInvestmentFormGroup.value.exitFormPercentOfPar?.toString(),
            "notionalOutStanding": this.exitInvestmentFormGroup.value.exitFormNotionalOS?.toString()
        }
      }
    this.isExitInvestmentLoading = true;
    this.dataService.saveExitedInvestmentDetails(this.companyIdForExitInvestment, reqBody).subscribe((res) => {
    this.isExitInvestmentLoading = false;
    this.utilService.showMessage(this.translateService.getLabel("suc_saved") + ".", this.translateService.getLabel("ok"));
    this.getExitedInvestmentDetails()
    }, 
    (err) => {
        this.isExitInvestmentLoading = false;
        this.utilService.showMessage(this.translateService.getLabel("err_failed") + ".", this.translateService.getLabel("ok"));
        console.log(err);
    }
    );
  }

  contextMenuClick(args: MenuEventArgs): void {
    const data = args['rowInfo']?.rowData;
      if(args.item.id === MenuItemIds.GO_TO_DEBT_MODEL) {
        this.debtPortfolioService.openDebtValuation(data, true);
      }
  }

  companyRowSelected(event) {
      if(event.isInteracted) {
        this.debtPortfolioService.openDebtValuation(event.data);
      }
  }

  getAllLogos(userDetails, fundId, companyId) {
    this.debtPortfolioService.getAllLogos(userDetails, fundId)
      .subscribe((res: any) => {
        if (res?.body?.response) {
          const logoFound = res.body.response.logos[companyId];
          this.debtCompanyDetails.logoURL = logoFound;
        }
      })
  }

  initAllCharts(){

    let valDates = [];
    let financialDates = [];
    let seriesData = [];

    let valueTrendseriesData = [];
    let discountRateTrendseriesData = [];
    let percentageOfParseriesData = [];
    let companySpecificTrendseriesData = [];
    let levrageTrendseriesData = [];
    let coverageTrendseriesData = [];
    let profitabilityTrendseriesData = [];
    let cashBalanceTrendseriesData = [];
    let cleanPriceTrendSeriesData = [];
    let cleanPricePercentageOfParSeriesData = [];
    let ebitdaTrendSeriesData = [];

    this.debtValuationDetails.forEach(element => {
        const vDate = this.datePipe.transform(element.date, 'mediumDate');
        const fDate = this.datePipe.transform(element.financialDate, 'mediumDate');
        valDates.push(vDate);
        financialDates.push(fDate);
    });

    this.debtValuationDetails.forEach(element => {
        try {
            if(element.dirtyPriceFairValue !== null) {
                valueTrendseriesData.push(parseFloat(element.dirtyPriceFairValue.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.discountRate !== null) {
                discountRateTrendseriesData.push(parseFloat(element.discountRate.toFixed(1)));
            }
        } catch(e){}        

        try {
            if(element.dirtyPricePercentOfPar !== null) {
                percentageOfParseriesData.push(parseFloat(element.dirtyPricePercentOfPar.toFixed(1)));
            }
        } catch(e){}

        // try {
        // if(element.) {
        //    companySpecificTrendseriesData.push(parseFloat("0"));
        // }
        // } catch(e){}

        try {
            if(element.leverageTrend !== null) {
                levrageTrendseriesData.push(parseFloat(element.leverageTrend.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.coverageTrend !== null) {
                coverageTrendseriesData.push(parseFloat(element.coverageTrend.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.profitablityTrend !== null) {
                profitabilityTrendseriesData.push(parseFloat(element.profitablityTrend.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.cash !== null) {
                cashBalanceTrendseriesData.push(parseFloat(element.cash.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.cleanPriceFairValue   !== null) {
                cleanPriceTrendSeriesData.push(parseFloat(element.cleanPriceFairValue.toFixed(1)));
            }
        } catch(e){}

        try {
            if(element.cleanPricePercentOfPar !== null) {
                cleanPricePercentageOfParSeriesData.push(parseFloat(element.cleanPricePercentOfPar.toFixed(1)));
            }
        } catch(e){
            console.log(e);
        }

        try {
            if(element.ebitda !== null) {
                ebitdaTrendSeriesData.push(parseFloat(element.ebitda.toFixed(1)));
            }
        } catch(e){
            console.log(e);
        }

    });


    this.initValueTrendChart(valueTrendseriesData, valDates, this.translateService.getLabel("dirty_price_trend"));
    this.initDiscountTrendChart(discountRateTrendseriesData, valDates, this.translateService.getLabel("discount_rate_trend"));
    this.initPercentOfParTrendChart(percentageOfParseriesData, valDates, this.translateService.getLabel("dirty_price_percent_of_par_trend"));
    this.initCleanPricePercentOfParTrendChart(cleanPricePercentageOfParSeriesData, valDates, this.translateService.getLabel("clean_price_percent_of_par_trend"))
    // this.initCompanySpecificSpreadTrendChart(companySpecificTrendseriesData, valDates, "Company Specific Spread Trend");
    this.initLeverageTrendChart(levrageTrendseriesData, financialDates, this.translateService.getLabel("leverage_trend"));
    this.initCoverageTrendChart(coverageTrendseriesData, financialDates, this.translateService.getLabel("coverage_trend"));
    this.initProfitabilityTrendChart(profitabilityTrendseriesData, financialDates, this.translateService.getLabel("profitablity_trend"));
    this.initCashBalanceTrendChart(cashBalanceTrendseriesData, financialDates, this.translateService.getLabel("cash_balance_trend"));
    this.cleanPricingTrend(cleanPriceTrendSeriesData, valDates, this.translateService.getLabel("clean_price_trend"));
    this.initEbitdaTrendChart(ebitdaTrendSeriesData, financialDates, this.translateService.getLabel("ebitda_trend"));
  }

  initValueTrendChart(seriesData, valDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.valueTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: valDates,
        gridLineWidth: 1,
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.debtCompanyDetails?.currency + ' (Mn)',
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}Mn</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
      column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1);
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData,
    }] as any
  });
  }

  initDiscountTrendChart(seriesData, valDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.amountTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: valDates,
        gridLineWidth: 1,
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.translateService.getLabel('discount_rate') + ' (%)'
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1) + '%';
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

  initPercentOfParTrendChart(seriesData, valDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.percentOfParTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: valDates,
        gridLineWidth: 1,
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.translateService.getLabel('percentage') + ' (%)'
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1) + '%';
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

  initCleanPricePercentOfParTrendChart(seriesData, valDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.cleanPricePercentOfParTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: valDates,
        gridLineWidth: 1,
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.translateService.getLabel('percentage') + ' (%)'
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1) + '%';
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

  initCompanySpecificSpreadTrendChart(seriesData, valDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.companySpecificSpreadTrend = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: valDates,
        gridLineWidth: 1,
        crosshair: true,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.translateService.getLabel('comapany_specific_spread') + ' (%)'
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f} Mn</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return this.y.toLocaleString();
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

  initLeverageTrendChart(seriesData, financialDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        financialDates.splice(1, financialDates.length - 6);
    }
    this.leverageTrendChart = new Chart(
    {
      chart: {
          type: 'column'
      },

      title: {
          text: ''
      },

      subtitle: {
          text: ''
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      yAxis: {
          min: 0,
          title: {
              text: this.translateService.getLabel('leverage') + ' (x)'
          },
          gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
      },

      xAxis: {
        categories: financialDates,
        gridLineWidth: 1,
        crosshair: true,
          labels: {
            style: {
                fontSize: '12px'
            }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}x</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },

      legend: {
          enabled: false
      },

      plotOptions: {
          column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1) + 'x';
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
          }
      },

      series: [{
          name: label,
          color: "#1D1563",
          data: seriesData
      },

    ] as any
    });
  }

  initCoverageTrendChart(seriesData, financialDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        financialDates.splice(1, financialDates.length - 6);
    }
    this.coverageTrendChart = new Chart(
      {
        chart: {
            type: 'column'
        },

        title: {
            text: ''
        },

        subtitle: {
            text: ''
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        yAxis: {
            min: 0,
            title: {
                text: this.translateService.getLabel('coverage') + ' (x)'
            },
            gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
        },

        xAxis: {
          categories: financialDates,
          gridLineWidth: 1,
          crosshair: true,
            labels: {
                style: {
                    fontSize: '12px'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y: .1f}x</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },

        legend: {
            enabled: false
        },

        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px'
                    },
                    formatter() {
                        return numberFormat(this.y, 1) + 'x';
                    }
                },
                pointPadding: 0.2,
                borderWidth: 0
            }
        },

        series: [{
            name: label,
            color: "#1D1563",
            data: seriesData
        },

      ] as any
      });
  }

  initProfitabilityTrendChart(seriesData, financialDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        financialDates.splice(1, financialDates.length - 6);
    }
      console.log('Look at this',seriesData)
      this.profitablityTrendChart = new Chart({
      chart: {
          type: 'column'
      },

      title: {
          text: ''
      },

      subtitle: {
          text: ''
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      yAxis: {
          min: 0,
          title: {
              text: this.translateService.getLabel('margin') + ' (%)'
          },
          gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
      },

      xAxis: {
        categories: financialDates,
        gridLineWidth: 1,
        crosshair: true,
          labels: {
            style: {
                fontSize: '12px'
            }
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}%</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },

      legend: {
          enabled: false
      },

      plotOptions: {
          column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1) + '%';
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
          }
      },

      series: [{
          name: label,
          color: "#1D1563",
          data: seriesData
      },

    ] as any
    });
  }

  initCashBalanceTrendChart(seriesData, financialDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        financialDates.splice(1, financialDates.length - 6);
    }
    this.cashBalanceTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: financialDates,
        crosshair: true,
        gridLineWidth: 1,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.debtCompanyDetails?.financialCurrency + ' (Mn)',
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}Mn</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1);
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

  initEbitdaTrendChart(seriesData, financialDates, label) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        financialDates.splice(1, financialDates.length - 6);
    }
    this.ebitdaTrendChart = new Chart({
      chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    xAxis: {
        categories: financialDates,
        crosshair: true,
        gridLineWidth: 1,
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: this.debtCompanyDetails?.financialCurrency + ' (Mn)',
        },
        gridLineColor: 'transparent',
        lineColor: 'transparent',
        labels: {
            style: {
                fontSize: '12px'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y: .1f}Mn</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px'
                },
                formatter() {
                    return numberFormat(this.y, 1);
                }
            },
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    legend: { enabled:false },
    series: [{
        name: label,
        color: "#1D1563",
        data: seriesData
    }] as any
  });
  }

    rows(e: any) {
        var rowDetails = e.row;
        //check the condition for concludedOnWaterfall 
        if (e.data.concludedOnWaterfall === 'Yes') {
            rowDetails.classList.add('waterfall-bg');
        }
    }


  async getImageURL(event){
    try {
      const imageUrl = await this.dataService.getImageUrl(event.target.files[0], event.target.files[0].name);
      this.debtCompanyDetails.logoURL = imageUrl.body["response"];

      const requestBody = {
          url: imageUrl.body["response"],
          companyId: this.debtPortfolioService.selectedCompanyId
      }
      this.dataService.saveDebtLogoUrl(requestBody);
    }
    catch(e) {
      console.log("Error while saving company logo", e);
      this.utilService.showMessage(
        this.translateService.getLabel("err_failed_save_logo") + ".",
        this.translateService.getLabel("ok"), true)
    }
  }

  /**
   * 
   * @param seriesData Data from the /valuation/debt-model/valuationDetails/getAllValuationDateFormsDetails api
   * @param valDates Dates to be plotted on the x axis
   * @param label For the trend
   */
  cleanPricingTrend(seriesData : any, valDates: any, label: string) {
    if(seriesData?.length > 6) {
        seriesData.splice(1, seriesData.length - 6);
        valDates.splice(1, valDates.length - 6);
    }
    this.cleanCashTrendChart = new Chart({
        chart: {
          type: 'column'
      },
      title: {
          text: ''
      },
      subtitle: {
          text: ''
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      xAxis: {
          categories: valDates,
          crosshair: true,
          gridLineWidth: 1,
          labels: {
              style: {
                  fontSize: '12px'
              }
          }
      },
      yAxis: {
          min: 0,
          title: {
              text: this.debtCompanyDetails?.currency + ' (Mn)',
          },
          gridLineColor: 'transparent',
          lineColor: 'transparent',
          labels: {
              style: {
                  fontSize: '12px'
              }
          }
      },
      tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y: .1f}Mn</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
      },
      plotOptions: {
          column: {
              dataLabels: {
                  enabled: true,
                  style: {
                      fontSize: '12px'
                  },
                  formatter() {
                      return numberFormat(this.y, 1);
                  }
              },
              pointPadding: 0.2,
              borderWidth: 0
          }
      },
      legend: { enabled:false },
      series: [{
          name: label,
          color: "#1D1563",
          data: seriesData
      }] as any
    });
  }

  //Discount Rate and Financial Matric data
  getDiscountRateFinancialMatrix(companyId){
    this.dataService.getDiscountRateFinacialMatrix(companyId).subscribe({
        next:(res:any)=>{
            //Discount Data
            
            this.allDiscountCalibrationCustomAdjustmentkey = [];
            if(res?.body?.response?.discountRate){
                let discountRate = res?.body?.response?.discountRate;
                this.allDiscountRate = discountRate;
                //Buid Up
                for(let allKey of discountRate){
                    if(allKey?.discountRateBuildUp){
                        let buidUpKeyArray = this?.objectKeysValues.keys(allKey.discountRateBuildUp);
                        let i=0;
                        for(let buldKey of buidUpKeyArray){
                              if(!this.allDiscountRateBuildUpKey.find(e=>e.toLocaleLowerCase() == buldKey.toLocaleLowerCase()) ){
                                this.allDiscountRateBuildUpKey.splice(i,0,buldKey)
                               } 
                               i++;
                        }
                    }

                    //Calibration Data
                    if(allKey?.discountRateCalibration){
                        const discountCallibrationKey = Object?.keys(allKey?.discountRateCalibration);
                        let i=0;
                        for(let keys of discountCallibrationKey){
                                    if (!this.allDiscountRateCalibration.find(b => b == keys) && (keys!='impliedSpreadAtOriginationDate' && keys!='impliedSpreadAtValuationDate')) {
                                        this.allDiscountRateCalibration.splice(i,0,keys);
                                    }
                                    i++;
                            }
    
                    }
                    // Calibration Customadjustment 
                    if (allKey?.discountRateCalibration?.customAdjustments) {
                        let i=0;
                        for (let conjAdjustmentName of allKey?.discountRateCalibration?.customAdjustments) {
                            if(conjAdjustmentName.value){
                                if (!this.allDiscountCalibrationCustomAdjustmentkey.find(e => e?.toLowerCase() == conjAdjustmentName.adjustmentName.toLowerCase())) {
                                    this.allDiscountCalibrationCustomAdjustmentkey.splice(i,0,conjAdjustmentName.adjustmentName)
                                }
                            }
                            i++;
                        }
                    }
                    //Buildup Customadjustment
                    if (allKey?.discountRateBuildUp?.customAdjustments) {
                        let i=0;
                        for (let conjAdjustmentName of allKey?.discountRateBuildUp?.customAdjustments) {
                            if (conjAdjustmentName.adjustmentName && conjAdjustmentName.value) {
                                if (!this.allDiscountBuildUpCustomAdjustmentKey.find(e => e?.adjustmentName?.toLowerCase() == conjAdjustmentName.adjustmentName.toLowerCase())) {
                                    this.allDiscountBuildUpCustomAdjustmentKey.splice(i,0,conjAdjustmentName)
                                }
                            }
                            i++;
                        }
                    }
                }
            }
            //Financial Data
            if(res?.body?.response?.financials){
                let financial = res?.body?.response?.financials;
                this.allFinancial = financial;
                let financialKey = this.objectKeysValues.keys(financial[0]);
 
                //Financial Array
                for(let objectKeys of financialKey){
                   for(let financialArray of financial){
                    //Check the key exist and value is greater than zero 0
                        if(financialArray[objectKeys] && objectKeys!='valuationDate' && !this.allFinancialKey.find(e=>e?.toLowerCase() == objectKeys.toLowerCase())){
                            this.allFinancialKey.push(objectKeys);
                        }
                    }
                }
                
            }
        }
    }) 
   
  }

  //Getting the value from discountTableTitle 
  getCalibrationValue(keyName:any):string{
    return this.discountTableTitle.find(e=>e.key== keyName)?.value;
  }
  //Getting Build up custom adjustment filter value
  getDiscountCustomAdjustment(values:any,adjustmentName:string){
    let value ='-';
    if(values && values?.customAdjustments?.length>0){
         values?.customAdjustments?.forEach(keyAndValues=>{
            if(keyAndValues.adjustmentName == adjustmentName && keyAndValues.value){
                value = this.dynamicDecimalPipe.transform(keyAndValues.value)+'%';
               }
        })
    }
    return value;
  }

  //Shadow Rating Matric table data
  getShadowRatingMatric(companyId){
    this.dataService.getShadowRatingMatricTable(companyId).subscribe({
      next:(res:any)=>{
        this.allShadowRatingMatric= res?.body?.response?.shadowRatings;
        for (let ratingMethodLabel of this.allShadowRatingMatric) {
          for (let allShadowMethodLabel of ratingMethodLabel.customRatings) {
            //Pushing the value of Shadow Rating/Score
            if (ratingMethodLabel.selectedRatingMethod == allShadowMethodLabel.ratingMethod) {
              this.allScores.push({ 
                roundingScore: allShadowMethodLabel.roundingScore, 
                scoreCorrespondenceRating: allShadowMethodLabel.scoreCorrespondenceRating 
              })
            }
            //Pushing the value of Method/Label
            if (!this.allShadowRatingMethodLabel.find(e => e.label.toLocaleLowerCase() == allShadowMethodLabel.ratingMethodLabel.toLocaleLowerCase() && e.method.toLocaleLowerCase() == allShadowMethodLabel.ratingMethod.toLocaleLowerCase())) {
              this.allShadowRatingMethodLabel.push({
                  method: allShadowMethodLabel.ratingMethod,
                  label: allShadowMethodLabel.ratingMethodLabel
              })
            }
          }
        }
      }
    })
  }

  //Get global status of config
  getGlobalConfigStatus(orgId:string,companyId:string){
    this.dataService.getGlobalConfig(orgId)
    .subscribe((res: any) => {
      let allOrgSettings = res?.body?.response?.orgSettings;
      const isSelectedShadowRatingYes= allOrgSettings?.find(e=>e?.orgSettingsKey=='BX_SHADOW_RATING')?.isActive || false;
      if(isSelectedShadowRatingYes){
        this.getShadowRatingMatric(companyId);
      }
    })
  }

}
