<div class="header" (click)="onNoClick()">
  <mat-icon class="cross-icon">{{translateService.getLabel('close')}}</mat-icon>
</div>

<div class="text-center">
  <span class="circle">
    <mat-icon class="exclamation-icon">{{data.icon}}</mat-icon>
  </span>
</div>

<div class="text-center" mat-dialog-title>
  <p style="font-size: 16px;font-weight: 600;color:#1D1B1B">{{translateService.getLabel('delete_confirmation')}}</p>
  <p style="font-weight: 400;size: 14px;color: #5E718D;line-height: 24px;">{{translateService.getLabel('are_you_sure_you_want_to_delete_this')}} <b>{{translateService.getLabel('comment')}}</b> {{translateService.getLabel('entry')}}?</p>
</div>
<hr>

<div mat-dialog-content style="margin-bottom: 20px;">
  <div class="row">
    <div class="col-6">
      <p class="title">{{data.title}}</p>
    </div>
    <div class="col-2"></div>
    <div class="col-4">
      <p class="date">{{data.date | date:'MMMM d, yyyy'}}</p>
    </div>
  </div>
  <p class="comment">{{data.comment}}</p>
</div>

<div mat-dialog-actions style="float:right">
  <button mat-flat-button class="no-btn" (click)="onNoClick()">{{data.noButtonMsg}}</button>&nbsp;
  <button mat-button class="yes-btn" [mat-dialog-close]="'yes'">{{data.okButtonMsg}}</button>
</div>