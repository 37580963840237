interface EnabledVersion {
  createdBy: string;
  createdDate: string;
  deleted: boolean;
  description: string;
  enabled: boolean;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  new: boolean;
  orgId: string;
  tag: string;
  type: FinancialVersionType;
}

interface VersionList {
  id: string;
  name: string;
  createdBy: string;
  uploadDate: string;
}

interface FinancialsVersion {
  uuid: string | number;
  id: string;
  versionId: string;
  versionName: string;
  rank: number;
  deleted?: boolean;
}

interface VersionListDetails {
  name: string;
  fundId: string;
  uploadDate: string;
  userId: string;
  orgId: string;
  backwardLookingMappings: FinancialsVersion[];
  forwardLookingMappings: FinancialsVersion[];
}

interface VlName {
  fundId: string;
  name: string;
  excludeVersionListId: string;
}

interface NewVersionList extends VersionListDetails {}

enum FinancialVersionType {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

enum VERSION_LIST_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  READ = 'READ',
}

export {
  EnabledVersion,
  FinancialVersionType,
  VersionList,
  VERSION_LIST_MODE,
  FinancialsVersion,
  NewVersionList,
  VersionListDetails,
  VlName
};
