import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { QXI_PortFolioService } from '../qxi-portfolio.service';
import { cloneDeep } from 'lodash';
import { XIRRServiceService } from '../../portfolio/xirrservice.service';

@Component({
  selector: 'app-qxi-exit-simulation',
  templateUrl: './qxi-exit-simulation.component.html',
  styleUrls: ['./qxi-exit-simulation.component.scss']
})
export class QXI_ExitSimulationComponent implements OnInit {

  constructor(public portfolioService: QXI_PortFolioService, private ds: DataService,
    private xirrService: XIRRServiceService ) { }

  ngOnInit() {
    const investmentIds = this.portfolioService.portfolioData.map(comp => {
      return comp.id;
    })

    let latestValDateForms = investmentIds.map( id => {
      let valuationDateForms = this.portfolioService.getSelectedCompanyDates(id);
      valuationDateForms = valuationDateForms.sort((f1, f2) => {
        const f1Date = new Date(f1.valuationDate);
        const f2Date = new Date(f2.valuationDate);
        return f1Date === f2Date? 0: f1Date < f2Date? 1: -1;
      });
      return valuationDateForms[0];
    })

    const currentYear = new Date().getFullYear();
    latestValDateForms = latestValDateForms.filter(form => {
      const valYear = new Date(form.valuationDate).getFullYear();
      return valYear == currentYear && form.id
    })

    this.latestValDateIds = latestValDateForms.map(form => {
      return form.id
    })

    this.ds.getExitSimulationData(this.latestValDateIds).subscribe( res => {
      this.financialData = res.body["response"];
      // console.log("Succesfully Fetched financial data for Exit Simulation", res);
      if(this.financialData){
        this.prepareExitSimulation();
      }
    }, error => {
      console.log("Unable to fetch the financial data for Exit Simulation", error);
    })
  }

  financialData;
  simulationTableData = [];
  latestValDateIds =[];
  tableHeaderYears = []
  grossTotal = {
    totalMoic: 0,
    totalIrr: 0
  }
  exitSimulation = {
    exitMutipleType: 'revenue',
    multipleValue: 0,
    exitDate: '',
    metricYear: ''
  }

  prepareExitSimulation(){
    this.latestValDateIds.forEach( id => {
      if(this.financialData[id]){
        const simulationData = {
          id: id,
          companyName: '',
          financials:{},
          exitValue: 0,
          moic: 0,
          irr: 0
        }
        simulationData.companyName = this.financialData[id].exitSimulation.companyName;
        simulationData.financials = this.financialData[id].exitSimulation.financials;
        
        this.simulationTableData.push(simulationData);
      }
    })

    this.tableHeaderYears = this.simulationTableData[0].financials.map( fin => fin.year);

  }

  calculateExitSimulation(multipleType){
    this.simulationTableData.forEach( data =>{
      const finMultiple = data.financials.find(fin => {
        return fin.year == this.exitSimulation.metricYear; 
      });
      data.exitValue = finMultiple[multipleType] * this.exitSimulation.multipleValue;
    })

    this.calculateMultiples();
  }

  assignMetricYear(date){
    const dateObj = new Date(date);
    const currentYear = dateObj.getFullYear();
    const index = this.tableHeaderYears.findIndex( year => year === currentYear);
    this.exitSimulation.metricYear = this.tableHeaderYears[index-1];
  }

  calculateMultiples(){
    const valDateIds = Object.keys(this.financialData);
    const valDateForms = valDateIds.map( id => {
      const form = this.portfolioService.companies.filter(comp => comp.id == id);
      return form[0];
    })

    const invDateForms = valDateForms.map( valForm => {
      const form = this.portfolioService.companies.filter(comp => comp.id == valForm.groupFormId);
      form[0]["valDateId"] = valForm.id;
      return form[0];
    })

    this.simulationTableData.forEach( data => {
      invDateForms.forEach( form => {
        if( data.id == form.valDateId ){

          if(form.totalInvestmentAmount && form.totalInvestmentAmount > 0){
            const invAmount = form.totalInvestmentAmount;
            const realProceeds = form.totalRealisedProceeds ? form.totalRealisedProceeds : 0;
            const exitValue = data.exitValue;
            data.moic = this.calculateMoic(invAmount, realProceeds, exitValue);
          }

          if(form.multipleInvestmentAmount){
            const multipleInvestmentAmount = form.multipleInvestmentAmount;
            const multipleRealisedProceeds = form.multipleRealisedProceeds ? form.multipleRealisedProceeds : null;
            const multipleExitVal = [{ date : this.exitSimulation.exitDate, value: data.exitValue}];
            data.irr = this.calculateIrr(multipleInvestmentAmount, multipleRealisedProceeds, multipleExitVal);
          }

          this.calculateTotal();
        }
      })
    })
  }

  calculateIrr(multipleInvestmentAmount, multipleRealisedProceeds, multipleExitVal){
    let investmentArray = cloneDeep(multipleInvestmentAmount);
    investmentArray.forEach(arr => {
      if(arr.value > 0){
        arr.value = -arr.value;
      }
    });

    let multiplesArray;
    if(multipleRealisedProceeds){
      multiplesArray=  multipleRealisedProceeds.concat(investmentArray).concat(multipleExitVal);
    }
    else{
      multiplesArray=  investmentArray.concat(multipleExitVal);
    }
    multiplesArray.sort((f1, f2) => {
      f1.date = new Date(f1.date);
      f2.date = new Date(f2.date);
      return f1.date === f2.date? 0: f1.date < f2.date? -1 : 1;
    });

    let cashFlow = multiplesArray.map( arr => {
      return arr.value;
    });
    let dates = multiplesArray.map( arr => {
      return new Date(arr.date);
    });

    const irrVal = this.xirrService.XIRR(cashFlow, dates, 0);
    return isNaN(irrVal)? 0 : irrVal;
  }

  calculateMoic(invAmount, realProceeds, exitValue){
    const moic = (realProceeds + exitValue)/invAmount;
    return moic;
  }

  calculateTotal(){
    let totalMoic = 0;
    let totalIrr = 0;
    this.simulationTableData.forEach( data => {
      totalMoic += data.moic;
      totalIrr += data.irr;
    });

    this.grossTotal.totalMoic = totalMoic/this.simulationTableData.length;
    this.grossTotal.totalIrr = totalIrr/this.simulationTableData.length;

  }
}
