<div class="mat-elevation-z1 wrapper">
<table mat-table [dataSource]="_attribbuteList">
  <ng-container matColumnDef="mapName">
    <th mat-header-cell *matHeaderCellDef> Map </th>
    <td mat-cell *matCellDef="let map;index as i">
      {{map?.name}}

    </td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef> Created By </th>
    <td mat-cell *matCellDef="let map;index as i">
      {{map?.createdBy}}

    </td>
  </ng-container>
  <ng-container matColumnDef="uploadDate">
    <th mat-header-cell *matHeaderCellDef> Upload Date </th>
    <td mat-cell *matCellDef="let map;index as i">
      {{map?.uploadDate | date: 'mediumDate' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef> Edit </th>
    <td mat-cell *matCellDef="let map;index as i">
      <span (click)="onEditMap($event, map,i)" class="edit-icon icon-primary" [routerLink]="'../create-map'">
        <i class="fas fa-pen"></i>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef> Delete </th>
    <td mat-cell *matCellDef="let map;index as i">

      <button mat-icon-button [disabled]="!map?.actions?.canDelete" class="delete-icon icon-primary"
        (click)="onDeleteMap($event,map,i)">
        <i class="fas fa-trash" matTooltip="Cannot be deleted as it is already in use"
          [matTooltipDisabled]="map?.actions?.canDelete" matTooltipPosition="left"></i>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef> Download </th>
    <td mat-cell *matCellDef="let map;index as i">
      <button *ngIf="map.id !== 'default-attribute-map'" mat-icon-button class="download icon-primary" (click)="onDownloadMap($event,map, i)">
        <i class="fas fa-download"></i>
      </button>
    </td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedOrgColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedOrgColumns" (click)="onRowClick($event,row)"></tr>
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>