import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-projected-table',
  templateUrl: './projected-table.component.html',
  styleUrls: ['./projected-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectedTableComponent implements OnInit {
  isRankDirty: { [key: number]: boolean } = {};

  isProjVerDirty: { [key: number]: boolean } = {};

  dataSource = [];

  @Input()
  set projectedVersionListItems(vli) {
    this.dataSource = [...vli];
  }

  @Input() rankOptions;

  @Input() projectedVersionOptions;

  @Input() isReadOnly: boolean;

  @Output() rankChange = new EventEmitter();

  @Output() versionChange = new EventEmitter();

  @Output() deleteProjectedItem = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onRankChange(e, row) {
    this.rankChange.emit({
      event: e,
      row,
    });
  }

  onVersionChange(e, row) {
    this.versionChange.emit({
      event: e,
      row,
    });
  }

  onDelete(row, i: number) {
    console.log(row, 'Delete...');
    this.deleteProjectedItem.emit(row);
    this.isRankDirty[i] = undefined;
    this.isProjVerDirty[i] = undefined;
  }

  onRankOpened(e: boolean, i: number) {
    if (!e) {
      this.isRankDirty[i] = true;
    }
  }

  onVersionOpened(e: boolean, i: number) {
    if (!e) {
      this.isProjVerDirty[i] = true;
    }
  }
}
