import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AttributeList } from '../attr-list-interfaces';
import { MatPaginator, MatTableDataSource } from '@angular/material';

interface CellEvent {
  dataSourceIndex: number;
  map: any
}
@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeListComponent implements OnInit {

  _attribbuteList: MatTableDataSource<AttributeList>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedOrgColumns = [
    'mapName',
    'createdBy',
    'uploadDate',
    //'edit',
    'delete',
    'download',
  ];

  @Input() 
  set attributeList(data: AttributeList[]) {
    if(data) {
      this._attribbuteList = new MatTableDataSource(data);
      this._attribbuteList.paginator = this.paginator;
    }
  }

  @Output() onEditMapClick = new EventEmitter<CellEvent>();

  @Output() onDeleteMapClick = new EventEmitter<CellEvent>();

  @Output() onDownloadMapClick = new EventEmitter<CellEvent>();

  @Output() onRowClk = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onEditMap(event,map, index: number) {
    event.stopPropagation();
    this.onEditMapClick.emit({
      dataSourceIndex: index,
      map
    });
  }

  onDeleteMap(event,map, index: number) {
    event.stopPropagation();
    this.onDeleteMapClick.emit({
      dataSourceIndex: index,
      map
    });
  }

  onDownloadMap(event, map, index: number) {
    event.stopPropagation();
    this.onDownloadMapClick.emit({
      dataSourceIndex: index,
      map
    });
  }

  onRowClick(event: PointerEvent, row) {
    console.log(event, row);
    this.onRowClk.emit(row);
  }
}
