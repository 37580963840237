import { UtilService } from "./../../../../utils/util.service";
import { DataService } from "./../../../../services/data.service";
import { DebtPortfolioService } from "./../debt-portfolio.service";
import { UserManagementService } from 'src/app/services/user-management.service';
import { FundListService } from '../../fund-list-ui/fund-list.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { catchError, mergeMap } from "rxjs/operators";
import { cloneDeep } from "lodash";
import { of } from "rxjs";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { MessageService } from "src/app/services/message.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "src/app/services/translation.service";
import { ToastService } from "src/app/utils/toast.service";
@Component({
  selector: "app-debt-company-summary",
  templateUrl: "./debt-company-summary.component.html",
  styleUrls: ["./debt-company-summary.component.scss"],
})
export class DebtCompanySummaryComponent implements OnInit {
  primaryCompanyId;
  selectedCompanyJSON;
  debtCompanyDetails;
  companyDetails = {};
  showEquityTable: boolean;
  equityValueSummary: any;
  equityTotalvalue:any;
  creditTotalvalue:any;
  equityForm: any;
  equityFormdetails: any;
  gridPageSettings = { pageSizes: true, pageSize: 20 };
  gridFilterSettings = { type: "Excel" };
  gridToolbar = ["ColumnChooser"];

  issuerConsolSummary
  issuerConsolSummaryTotal
  issuerCompanyId
  debtWaterfallWidgetDataKey = "DEBT_WATERFALL_EXIST"

  valuationNavigationLinks = [
    { title: this.translateService.getLabel('consolidated_summary'), isActive: true, key: 'consolidatedSummary' }
  ];

  valuationNavigationLinksFull = [
    { title: this.translateService.getLabel('consolidated_summary'), isActive: true, key: 'consolidatedSummary' },
    { title: this.translateService.getLabel('credit_capital_structure'), isActive: false, key: 'capitalStructure' },
    { title: this.translateService.getLabel('coverage_analysis'), isActive: false, key: 'coverageAnalysis' },
    { title: this.translateService.getLabel('waterfall_analysis'), isActive: false, key: 'waterfallAnalysis' }
  ];

  activeTab = "consolidatedSummary";

  payloadForIssuerConsolSummary: any;
  public consolidatedSelectedColumnList = [];
  public orgId: string;
  readonly CONSOLIDATED_SUMMARY_TABLE="DEBT_CONSOLIDATED_SUMMARY";
  restructuredSecurities = [];
  waterfallId: string;
  concludeOnWaterfall: boolean;
  isLoanCurrency: boolean = false;
  keyMetricsAtEntry: any;
  transactionOverview:any;
  fairValueTrend:any;
  discountRateTrend:any;
  currency:any;
  recentEventsComments:any;
  transactionOverviewComments:any;
  
  @ViewChild('debtConsolidatedSummary', { static: false }) public debtConsolidatedSummaryGrid: GridComponent;

  // @ViewChild('debtConsolidatedSummary', { static: false }) public debtConsolidatedSummaryGrid: GridComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private utilService: UtilService,
    public debtPortfolioService: DebtPortfolioService,
    private router: Router,
    public ums: UserManagementService,
    private fundService: FundListService,
    public translateService: TranslateService,
    public toastService: ToastService,
  ) {}

  async ngOnInit() {
    this.intiliazeContent();
    this.getSpreadStatus();
  }

  getSpreadStatus() {
    this.dataService.getSpreadBuildUp(this.orgId)
      .subscribe((res: any) => {
        let selectedSpreadBuildUp = res?.body?.response?.configValue;
        if (selectedSpreadBuildUp === '1') {
          this.valuationNavigationLinks.push( { title: this.translateService.getLabel('credit_tranching_analysis'), isActive: false, key: 'tranchingAnalysis' })
          this.valuationNavigationLinksFull.push( { title: this.translateService.getLabel('credit_tranching_analysis'), isActive: false, key: 'tranchingAnalysis' });
        }
      });
  }

  intiliazeContent() {
    const userDetails = this.ums.getSelectedUserDetails();
    const params = this.activatedRoute.snapshot.queryParamMap;
    this.primaryCompanyId = params.get("companyId");
    this.issuerCompanyId = params.get('issuerCompanyId')
    this.debtPortfolioService.selectedCompanyId = this.primaryCompanyId;
    const fundId = params.get("fundId");
    this.orgId = userDetails?.organization.id;

    try {
      this.dataService.getDebtCompanyDetails(this.debtPortfolioService.selectedCompanyId)
        .subscribe((resp:any)=> {
          if (resp?.body["response"]) {
            this.debtCompanyDetails = resp.body["response"].companyDetails;
            this.debtPortfolioService.securityCurrency = this.debtCompanyDetails?.financialCurrency;
            //Get Company Logo
            if (this.debtCompanyDetails) {
              if (this.debtPortfolioService.allCompanyLogos) {
                const logoFound = this.debtPortfolioService.allCompanyLogos[this.primaryCompanyId];
                this.debtCompanyDetails.logoURL = logoFound;
              } else {
                this.getAllLogos(userDetails, fundId, this.primaryCompanyId);
              }
            }
          }
        })

      this.debtPortfolioService.selectedFundId = fundId;
      if (!this.fundService.allFunds?.length) {
        this.fundService.getFunds(userDetails);
      }
      
    } catch (e) {
      console.log("Error in getting debt data", e);
    }
    this.getIssuerConsolSummary(userDetails,fundId);
    this.showHideWaterFallTab();
    this.equityOwnershipInCompany();
  }
  
  getConsolidatedCharts(payloadForIssuerConsolSummary) {
    this.dataService.getConsolidatedSummaryCharts(payloadForIssuerConsolSummary, this.issuerCompanyId).pipe(
      catchError((err) => {
        return of(err)
      })
    ).subscribe((res: any) => {
        if (res.body?.response) {
          this.keyMetricsAtEntry = res.body.response.keyMetricsAtEntry;
          this.transactionOverview = res.body.response.transactionOverview;
          this.fairValueTrend = res.body.response.fairValueTrend;
          this.discountRateTrend = res.body.response.discountRateTrend;
          this.currency = this.debtCompanyDetails.currency;
          this.recentEventsComments = res.body.response.recentEvents;
          this.transactionOverviewComments = res.body.response.transactionOverviewComment;
        }
      })
  }

  getIssuerConsolSummary(userDetails,fundId) {
    this.dataService.getAllSecuritiesInIssuerCompany(userDetails.id, fundId, this.issuerCompanyId, userDetails.organization.id).pipe(
      mergeMap((res: any) => {
        if (res?.body?.response) {
          this.payloadForIssuerConsolSummary = res.body.response;
          this.payloadForIssuerConsolSummary.toCurrency = this.debtPortfolioService.securityCurrency;
          this.debtPortfolioService.issureConsolSummaryPayload = cloneDeep(this.payloadForIssuerConsolSummary);
          return this.dataService.getIssuerConsolSummary(this.payloadForIssuerConsolSummary);
        }
        return of(null);
      }), mergeMap((response: any) => {
        if (response?.body?.response) {
          this.issuerConsolSummary = response.body.response.companies[0];
          this.debtPortfolioService.recentValuationDate = this.debtPortfolioService.getValuationDate(this.issuerConsolSummary['valuationDates']);
          this.debtPortfolioService.recentValuationDateIgnoringExited = this.debtPortfolioService.getValuationDate(this.issuerConsolSummary['valuationDates'], true);
          this.valuationNavigationLinks = this.valuationNavigationLinksFull.map(o => { return {...o} });
          this.getUserSelectedColumns(this.CONSOLIDATED_SUMMARY_TABLE);
          this.creditTotalvalue = this.issuerConsolSummary?.valuationDates?.map((valDate) => valDate.convertedTotalValue) 
          .reduce((acc, currentValue) => acc + (currentValue || 0), 0)

          if(this.issuerConsolSummary?.valuationDates?.length) {
            let foundLoanCurrency = this.issuerConsolSummary.valuationDates.find(x=> x.currency != x.financialCurrency);
            this.isLoanCurrency = foundLoanCurrency ? true : false;
          }
          this.getConsolidatedCharts(this.payloadForIssuerConsolSummary);
          return this.dataService.getIssuerConsolSummaryTotal(this.payloadForIssuerConsolSummary);
        }
        return of(null);
      })).subscribe((data: any) => {
        this.issuerConsolSummaryTotal = data?.body?.response?.totalValue;
        this.debtPortfolioService.recentValuationDateIgnoringExited && this.getConcludedWaterfallStatus();
        this.getWaterfallSecurities();
        if (this.debtConsolidatedSummaryGrid) {
          this.consolidatedSelectedColumnList?.length && this.debtConsolidatedSummaryGrid.hideColumns(this.consolidatedSelectedColumnList);
          this.debtConsolidatedSummaryGrid.refresh();
        }
      })
  }

  async getImageURL(event) {
    try {
      const imageUrl = await this.dataService.getImageUrl(
        event.target.files[0],
        event.target.files[0].name
      );
      this.debtCompanyDetails.logoURL = imageUrl.body["response"];

      const requestBody = {
        url: imageUrl.body["response"],
        companyId: this.debtPortfolioService.selectedCompanyId,
      };
      this.dataService.saveDebtLogoUrl(requestBody);
    } catch (e) {
      console.log("Error while saving company logo", e);
      this.utilService.showMessage(
        this.translateService.getLabel("err_failed_save_logo_try_later") + ".",
        this.translateService.getLabel("ok"),
        true
      );
    }
  }

  getAllLogos(userDetails, fundId, companyId) {
    this.debtPortfolioService.getAllLogos(userDetails, fundId)
      .subscribe((res: any) => {
        if (res?.body?.response) {
          const logoFound = res.body.response.logos[companyId];
          this.debtCompanyDetails.logoURL = logoFound;
        }
      })
  }

  async goToEquity() {
    const response = await this.dataService.getEquityFormWithValDate(this.issuerCompanyId,this.debtPortfolioService.recentValuationDateIgnoringExited);
      if(response?.body["response"]?.[0]) {
        this.equityForm = response.body["response"][0];

        if(this.equityForm.details) {
          this.equityFormdetails = JSON.parse(this.equityForm.details);
        }
          let additionalParams = "&issuerCompanyId=" + this.issuerCompanyId;
          additionalParams += "&debtCompanyId=" +this.primaryCompanyId;

          if(this.equityFormdetails?.status == "Submitted") {
            this.equityForm.versionName = this.equityFormdetails.versionName;
            this.debtPortfolioService.openValuation(this.equityForm, additionalParams);
          } else if(this.equityForm && this.equityFormdetails){
            let urlParameters = "v=" + this.equityForm.formVersion +
                                "&id=" + this.equityForm.id +
                                "&fundName="+ this.fundService.getFundName(this.debtPortfolioService.selectedFundId) +
                                "&versionName="+ this.equityFormdetails.versionName +
                                "&valuationDate=" + this.equityForm.valuationDate +
                                "&parentId="+this.debtPortfolioService.selectedFundId ;

            urlParameters += additionalParams;
            window.open(environment.portalUrl + environment.safUrl + "/#/startup-app-form?type=corp&" + urlParameters, "_self");
          } else {
            console.log("ERROR: No equity form data present");
            this.toastService.openSnackBar(this.translateService.getLabel('equity_form_not_found'));
          }
        } else {
          this.router.navigateByUrl(`/debt-company-valuation?valuationId=${this.debtPortfolioService.recentValuationDate}&companyId=${this.debtPortfolioService.selectedCompanyId}&fundId=${this.debtPortfolioService.selectedFundId}`);
        }
  }

  companyRowSelected(event) {
    this.router.navigateByUrl(`/debt-company-valuation?valuationId=${event.data.valuationDateId}&companyId=${event.data.companyId}&fundId=${this.debtPortfolioService.selectedFundId}`);
  }

  switchTab(itemKey, index){
        this.valuationNavigationLinks.forEach(link => { link.isActive = false });
        this.valuationNavigationLinks[index].isActive = true;
        this.activeTab = itemKey;
        this.concludeOnWaterfall = false;
        if(this.activeTab === 'consolidatedSummary') {
          this.intiliazeContent();
        }
        this.getUserSelectedColumns(this.CONSOLIDATED_SUMMARY_TABLE);
      }

  showHideWaterFallTab(){
    this.dataService.getWidgetDataFromDB(this.debtWaterfallWidgetDataKey, this.issuerCompanyId)
      .subscribe((response:any) => {
        const waterFallExist = response.body.response[0].widgetData.waterfallExist
        if(!waterFallExist){
          this.valuationNavigationLinks = this.valuationNavigationLinks.filter(item=>item.key != "waterfallAnalysis");
        }
      })
    }

    waterFallExistChange(event){
      if(!event){
        this.valuationNavigationLinks = this.valuationNavigationLinks.filter(item=>item.key != "waterfallAnalysis");
      }else{
        this.valuationNavigationLinks = [
          { title: this.translateService.getLabel('consolidated_summary'), isActive: false, key: 'consolidatedSummary' },
          { title: this.translateService.getLabel('credit_capital_structure'), isActive: false, key: 'capitalStructure' },
          { title: this.translateService.getLabel('coverage_analysis'), isActive: true, key: 'coverageAnalysis' },
          { title: this.translateService.getLabel('waterfall_analysis'), isActive: false, key: 'waterfallAnalysis' }
        ];
      }
    }

    /**
     * 
     * @param $event Any event on the table eg: column visibility, resize column width
     * @param tableType table the event has occured on 
     */
    public saveTableState($event: any, tableType: string){
      if($event.requestType == 'columnstate' && !$event.columns?.name) {
        let selectedColumns = [];
        if(tableType == this.CONSOLIDATED_SUMMARY_TABLE) {
          selectedColumns = [...new Set(this.consolidatedSelectedColumnList)];
        }
        $event.columns.map((column:any) => {
          if(!column.visible) {
            selectedColumns.push(column.headerText)
            selectedColumns = [...new Set(selectedColumns)];
          } else {
            let index = selectedColumns.indexOf(column.headerText);
            if(index > -1)
            selectedColumns.splice(index, 1)
          }
        })
        this.dataService.saveWidgetDataToDB(tableType, selectedColumns, this.orgId)
          .subscribe();
      }

    }

    /**
     * 
     * @param key key for consolidated Table state change
     */
    getUserSelectedColumns(key: string) {
      this.dataService.getWidgetDataFromDB(key, this.orgId)
      .subscribe((res: any) => {
        try {
          this.consolidatedSelectedColumnList = res?.body?.response[0]?.widgetData;
          if (this.debtConsolidatedSummaryGrid) {
            this.consolidatedSelectedColumnList?.length && this.debtConsolidatedSummaryGrid.hideColumns(this.consolidatedSelectedColumnList);
            this.debtConsolidatedSummaryGrid.refresh();
          }
        } catch (e) {
          console.log("No data in widget DB", e)
        }
      }, 
      error => {
        console.log("No Data in widget DB",error);
      })
    }

  getWaterfallSecurities() {
    this.dataService.getWaterfallSecurities(this.issuerCompanyId, this.debtPortfolioService.recentValuationDate).subscribe((res: any) => {
      this.restructuredSecurities = res.body.response;
    })
  }

  getConcludedWaterfallStatus() {
    this.dataService
      .getWaterfallId(this.issuerCompanyId, this.debtPortfolioService.recentValuationDateIgnoringExited)
      .then((response: any) => {
        this.waterfallId = response.body.response.waterfallId;
        this.concludeOnWaterfall = response.body.response.concludedOnWaterfall;
      });
  }
  navigateWaterFall(event){
    this.valuationNavigationLinks = [
      { title: this.translateService.getLabel('consolidated_summary'), isActive: false, key: 'consolidatedSummary' },
      { title: this.translateService.getLabel('credit_capital_structure'), isActive: false, key: 'capitalStructure' },
      { title: this.translateService.getLabel('coverage_analysis'), isActive: false, key: 'coverageAnalysis' },
      { title: this.translateService.getLabel('waterfall_analysis'), isActive: true, key: 'waterfallAnalysis' }
    ];
    this.activeTab = "waterfallAnalysis";
  }

  equityOwnershipInCompany() {
    this.dataService.getequityOwnershipInCompany(this.issuerCompanyId).pipe(
      mergeMap((res: any) => {
        this.showEquityTable = res?.body?.response?.equityOwnershipInCompany;
        if (this.showEquityTable) {
          return this.dataService.getEquityConsolidatedSummary(this.issuerCompanyId);
        }
        return of(null);
      })).subscribe((res: any) => {
        this.equityValueSummary = res?.body?.response
        this.equityTotalvalue = res?.body?.response[0]?.totalValue
      })
  }
}
