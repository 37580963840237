<div class="drp-wrapper">
  <h4 class="drp-table-header">
    User Companies Access
    <div class="drp-table-subheader">
      Error Count: <mat-chip color="warn" selected="{{dataSource.data.length > 0 }}">{{dataSource.data.length}}</mat-chip>
    </div>
  </h4>
  <div class="drp-table-wrapper mat-elevation-z1">
    <table mat-table [dataSource]="dataSource" class="drp-table">
      <!-- Columns -->
      <ng-container matColumnDef="organizationName">
        <th mat-header-cell *matHeaderCellDef class="left-align" [attr.rowspan]="2">Organization</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.organizationName}}</td>
      </ng-container>
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef class="left-align">User name</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.userName}}</td>
      </ng-container>
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef class="left-align">User ID</th>
        <td mat-cell *matCellDef="let row; index as i" class="left-align">{{row?.userId}}</td>
      </ng-container>
      <ng-container matColumnDef="gxUserCompaniesCount">
        <th mat-header-cell *matHeaderCellDef>Gx Companies</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.gxUserCompaniesCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoUserCompaniesBaseCount">
        <th mat-header-cell *matHeaderCellDef>Pluto Companies (Base)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoUserCompaniesBaseCount}}</td>
      </ng-container>
      <ng-container matColumnDef="plutoUserCompaniesFactCount">
        <th mat-header-cell *matHeaderCellDef>Pluto Companies (Fact)</th>
        <td mat-cell *matCellDef="let row; index as i">{{row?.plutoUserCompaniesFactCount}}</td>
      </ng-container>
      <ng-container matColumnDef="userCompaniesDelta">
        <th mat-header-cell *matHeaderCellDef>Delta</th>
        <td mat-cell *matCellDef="let row; index as i" [ngClass]="{'error-cell': row?.userCompaniesDelta !== 0 }">
          {{row?.userCompaniesDelta}}
        </td>
      </ng-container>

      <!-- Loading, Empty & Error rows -->
      <ng-container matColumnDef="loading">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <div class="progress-loader">
            <div class="indeterminate"></div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="nodata">
        <td *matFooterCellDef [colSpan]="displayColumns.length">No delta found.</td>
      </ng-container>
      <ng-container matColumnDef="error">
        <td *matFooterCellDef [colSpan]="displayColumns.length">
          <mat-icon>error</mat-icon> <span>{{error}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns" (click)="onRowClick($event,row)"></tr>
      <tr mat-footer-row [hidden]="dataSource?.data?.length > 0 || loading || error" *matFooterRowDef="['nodata']" class="empty-row"></tr>
      <tr mat-footer-row [hidden]="!error" *matFooterRowDef="['error']" class="error-row"></tr>
      <tr mat-footer-row [hidden]="!loading" *matFooterRowDef="['loading']" class="loader-row"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
  </div>
</div>
