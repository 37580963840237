import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges,OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/services/translation.service';
import { UserManagementService } from 'src/app/services/user-management.service';
import { UtilService } from 'src/app/utils/util.service';
import { PortFolioSummaryServiceV2 } from '../../portfolio-summary-v2/portfolio-summary-v2.service';
import { PortfolioUtilService } from '../../portfolio-util.service';
import { DrillDownService } from 'src/app/drill-down.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataService } from 'src/app/services/data.service';
import cloneDeep from 'lodash/cloneDeep';
import { PortFolioService } from '../../portfolio.service';

@Component({
  selector: 'app-equity-portfolio-grid',
  templateUrl: './equity-portfolio-grid.component.html',
  styleUrls: ['./equity-portfolio-grid.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class EquityPortfolioGridComponent implements OnInit, OnChanges {
  @Input() portfolioData;

  @Input() currencyKey;

  @Input() totalAggregations;

  @Input() exitGrid: boolean;

  @Input() showAggregations: boolean;

  @Input() currencyToBeDisplayed: string = "";

  @Output() emitAction = new EventEmitter();

  @Output() showHideColumnsUpdated = new EventEmitter();

  @Input() showHideColumns = {};

  @ViewChild('summaryGrid') public summaryGrid: GridComponent;

  gridPageSettings = { pageSizes: true, pageSize: 10 };
  gridToolbar = ['ColumnChooser'];
  gridFilterSettings = { type: 'Excel' };
  customAttributes = {class: 'hilightCols'};
  businessUnit = {}

  gridAllColumnsShowStatus = {
    geography: false,
    sector: false,
    security: false,
    changeInMOIC_valuesInPortfolioCurrency: false
  } as any;

  constructor(public utilService: UtilService,
    public portfolioService: PortFolioSummaryServiceV2,
    public portfolioService1: PortFolioService,
    public dataService : DataService,
    private ums: UserManagementService,
    private router: Router, public translateService : TranslateService,
    private portfolioUtil : PortfolioUtilService,
    public datePipe: DatePipe,
    public drillDownService: DrillDownService,) { }

  transform(value: any, format: string = 'mediumDate'): string {
    if (!value || value === 'Invalid date') {
      return 'Invalid date';
    }
    return this.datePipe.transform(value, format);
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit()
  }

  ngOnInit(): void {
    // making portfolio grid data free of nested JSON objects (eg. comp[currencyKey].stake => comp['stake_' + currencyKey])
    this.portfolioData.forEach(comp => {
      Object.keys(comp).forEach(key => {

        if(key == this.currencyKey) {

          Object.keys(comp[this.currencyKey]).forEach(cKey => {
            comp[cKey + '_' + this.currencyKey] = comp[this.currencyKey][cKey];
            
            this.gridAllColumnsShowStatus[cKey + '_' + this.currencyKey] = !(this.showHideColumns[cKey + '_' + this.currencyKey] == false);
          })

        } else if(key != "valuesInLocalCurrency" && key != "valuesInPortfolioCurrency") {

          this.gridAllColumnsShowStatus[key] = !(this.showHideColumns[key] == false);
        }
      })
    });
    this.gridAllColumnsShowStatus['changeInMOIC_' + this.currencyKey] = !(this.showHideColumns['changeInMOIC_' + this.currencyKey] == false);
    this.gridAllColumnsShowStatus['fullyDilutedStake_' + this.currencyKey] = !(this.showHideColumns['fullyDilutedStake_' + this.currencyKey] == false);
  }

  companyRowSelected(event) {
    const actionDetails = {
      type: this.portfolioUtil.ACTIONS_TYPE.COMPANY_ROW_CLICK,
      details: event
    }

    this.emitAction.emit(actionDetails);
  }

  getBusinessUnit(comp) {
    if (!this.businessUnit[comp.companyName]) {
      this.dataService.multipleBusinessUnit(comp.latestValuationDateId).subscribe(res => {
        let consolFormResponse = res.body["response"]
        if (consolFormResponse.length > 0) {
          if (!this.businessUnit[comp.companyName]) {
            this.businessUnit[comp.companyName] = "MBU"
          }
        }
        else {
          if (!this.businessUnit[comp.companyName]) {
            this.businessUnit[comp.companyName] = "Non MBU"
          }
        }
      }
      )
    }
  }
  
  actionComplete(args:any){ 
    if(args.requestType == "columnstate"){ 

      // When column hide/show status changes, publish the new state.
       const updatedColumns = args.columns.map(c => {
        return {
          columnKey: c.field,
          columnName: c.headerText,
          visible: c.visible
        }
      })

      this.showHideColumnsUpdated.emit(updatedColumns)
    } 
  } 
}
