<div class="card">
  <br>

  <div class="row">
    <div class="col"></div>
    <div class="col-2">
    </div>
  </div>

  <div class="row" style="margin: 0">
    <div class="col-4" style="padding: 0 35px;">{{translateService.getLabel("valuation_date")}}</div>

    <div class="col" style="padding: 0 35px;">{{translateService.getLabel("add_investors")}}</div>

    <div class="col-2" style="text-align: center;">
      <!-- <button mat-raised-button class="primary-light-bg p-button" (click)="saveInvestments(true)">Save</button> -->
      <button mat-raised-button class="primary-light-bg p-button" style="margin-left: 10px;" (click)="closeInvestments()">{{translateService.getLabel("close")}}</button>
    </div>
  </div>

  <div class="row" style="margin: 0">
      <div class="col-4">
        <mat-form-field class="valulation_drop">
          <mat-select [value]="selectedDate?.valuationDate">
            <mat-option *ngFor="let valDate of allValuationDates" [value]="valDate.valuationDate" (click) = "valuationDateChange(valDate)">{{valDate.valuationDate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-spinner style="display: inline-block; margin-bottom: -6px" [diameter] = "20" *ngIf = "loadingCaptable"></mat-spinner>
      </div>
  
  
      <div class="col">
  
        <mat-form-field class="example-full-width">
          <mat-label>{{translateService.getLabel("select")}}</mat-label>
          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="addOption(option)">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-spinner style="display: inline-block; margin-bottom: -6px" [diameter] = "20" *ngIf = "loadingInvestorName"></mat-spinner>
      </div>
    </div>

    <p *ngIf = "!capTableExists" class="warning">{{ warningMsg }}</p>

<mat-chip-list #chipList class="chipsvaldate">
  <mat-chip *ngFor="let value of investors; let index=index" class="chip">
    {{value.name}}
    <mat-icon matChipRemove (click)="removeOption(index)" class="close-mat-icon">close</mat-icon>
  </mat-chip>
</mat-chip-list>

<div class="row">
  <table class="table borderless" *ngIf = "!loadingCaptable && investmentTableData && investmentTableData.length > 0">
    <thead>
      <tr>
        <th class="table-heading leftAlign">
          {{translateService.getLabel("inter-party_summary")}}
        </th>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data>
          <th class="table-heading rightAlign">
            <span (click)="enableSelector(data, data.bsAssignment)" style="cursor: pointer;">
              {{getInvestorNameTranslations(data.investorName)}}
              
            </span>
            <ng-template [ngIf]="bsAssignmentName == data.investorName" [ngIfElse] = "hideDropDown">
              <mat-form-field class="custom-textbox">

                <mat-select [(ngModel)]="selectedBSAssignment" (ngModelChange)="addSelectedBSAssignment(selectedBSAssignment)">                   
                  <mat-option value="aumTotal">AUM {{translateService.getLabel("total")}} </mat-option>
                  <mat-option value="fundCarryco"> {{translateService.getLabel("fund (+carryco)")}} </mat-option>
                  <mat-option value="LPs"> {{translateService.getLabel("lps")}} </mat-option>
                  <mat-option value="Others"> {{translateService.getLabel("others")}} </mat-option>
                </mat-select>

              </mat-form-field>
            </ng-template>

            <ng-template #hideDropDown> 
              <span *ngIf = "data.bsAssignment == 'aumTotal' ">
                &nbsp;(AUM {{translateService.getLabel("total")}})
              </span>
              <span *ngIf = "data.bsAssignment == 'fundCarryco'">
                &nbsp;({{translateService.getLabel("fund (+carryco)")}})
              </span>
              <span *ngIf = "data.bsAssignment == 'LPs'">
                &nbsp;({{translateService.getLabel("lps")}})
              </span>
              <span *ngIf = "data.bsAssignment == 'Others'">
                &nbsp;({{translateService.getLabel("others")}})
              </span>
            </ng-template>

          </th>
        </ng-template>
      </tr>
    </thead>

    <tbody>
      <tr >
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("stake")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            <ng-template [ngIf]="data.investorName != selectedFund.name && !data.isTotal" [ngIfElse]="elseSide">
              <span style="cursor: pointer;" (click)="openCustomStakeSelection(stakePopUp, data)">{{data.stake | number: '1.2-2'}} % </span>
            </ng-template>
            <ng-template #elseSide>
              <span>{{data.stake | number: '1.2-2'}} % </span>
            </ng-template>
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("stake_value")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            <ng-template [ngIf] = "data.investorName == selectedFund.name || data.isTotal" [ngIfElse] = "capTableInvestors">
              {{data.adjustedStakeValue | number: '1.2-2'}}
            </ng-template>
            <ng-template #capTableInvestors>
              <i class="fas fa-edit" (click)="openAdjustmentsPopUp(adjustmentsPopup, data)"></i>
              {{data.adjustedStakeValue | number: '1.2-2'}}
            </ng-template>
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("realised_proceeds")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            {{data.realisedProceeds | number: '1.2-2'}}
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("investment")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            {{data.investmentAmount | number: '1.2-2'}}
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("IRR")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            {{data.grossIRR | number: '1.2-2'}} %
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("moic")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            {{data.moic | number: '1.2-2'}} x
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("no_of_shares (#)")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            {{ data.noOfShares | number: "1.2-2" }}
            <!-- <mat-form-field style="width: 25%" class="text-area"> 
              <input matInput class="label" type="text"  style="margin: 0px; text-align: right;"
                [(ngModel)]="data.noOfShares" (ngModelChange)="saveNoOfShares(data)">             
            </mat-form-field> -->
          </td>
        </ng-template>
      </tr>

      <tr>
        <ng-template ngFor [ngForOf] = "investmentTableData" let-data let-index = "index">
          <td *ngIf = "index == 0" class = "leftAlign">
            {{translateService.getLabel("price_per_share")}}
          </td>
          <td class="table-data" [class.total-column]="data.isTotal">
            <ng-template [ngIf] = "data.noOfShares > 0" [ngIfElse] = "zeroShares">
              {{data.pricePerShare | number: '1.2-2'}}
            </ng-template>
            <ng-template #zeroShares>
              0.00
            </ng-template>
          </td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #adjustmentsPopup let-adjustments>
  <div class="modal-header">
    <div class="modal-title">
      <h4>
        {{stakeValueAdjustments.headerLabel}}
      </h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="adjustments.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-adjustments [totalValueBeforeAdjustment]="stakeValueAdjustments.totalValueBeforeAdjustment"
      headerLabel = '{{translateService.getLabel("stake_value")}}'
      (finalAdjustments)="emittedAdjustedStakeValue($event); adjustments.dismiss('Cross click')"
      [existingAdjustments]="stakeValueAdjustments">
    </app-adjustments>
  </div>
</ng-template>

<ng-template #stakePopUp let-applicationModal>
  <div class="modal-header">
    <div class="modal-title">
        <h4>{{translateService.getLabel("stake")}} ({{getSelectedStakeFundName(selectedStakeFundName)}})</h4>
    </div>
    <button #closeBtn type="button" class="close" aria-label="Close" (click)="applicationModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content">
      <div class="row marginTop">
        <div class="col">
          <div class="row marginTop">
            <div class="col-4">
                <mat-radio-button name="customStakeSelection"
                (change)="updateCustomStakeSelection('manualEntry')"
                [checked]="customStake.selectedOption === 'manualEntry'">
                {{translateService.getLabel("manual_entry")}}
                </mat-radio-button>
            </div>
            <div class="col-2 rightAlignment" style="margin-left: 70px;">
                <mat-form-field class="formFieldWidth rightAlignment" style="width: 90%;">
                    <input type="text" matInput [(ngModel)]="customStake.manualEntry" autocomplete="off">
                    <span matSuffix>%</span>
                </mat-form-field>
            </div>
          </div>

          <div class="row marginTop">
            <div class="col-4">
              <mat-radio-button name="customStakeSelection"
                (change)="updateCustomStakeSelection('capTable')"
                [checked]="customStake.selectedOption === 'capTable'">
                {{translateService.getLabel("fully_diluted_stake_from_cap_table")}}
              </mat-radio-button>
            </div>
            <div class="col-2 rightAlignment" style="margin-left: 100px;">
              {{customStake.capTableModel.stake | number: '1.2-2'}} %
            </div>
          </div>

          <div class="row marginTop" *ngIf="!customStake.capTableModel.selected">
            <div class="col" style="color: red;">
              {{translateService.getLabel("please_choose")}} "{{getSelectedStakeFundName(selectedStakeFundName)}}" {{translateService.getLabel("on_the_cap_table")}}
            </div>
          </div>

          <div class="row marginTop">
            <div class="col-4">
                <mat-radio-button name="customStakeSelection"
                (change)="updateCustomStakeSelection('impliedStakeWaterfall')"
                [checked]="customStake.selectedOption === 'impliedStakeWaterfall'">
                {{translateService.getLabel("implied_stake_from_waterfall")}}
                </mat-radio-button>
            </div>
            <div class="col-2 rightAlignment" style="margin-left: 100px;">
              {{customStake.waterFallModel.stake | number: '1.2-2'}} %
            </div>
          </div>

          <div class="row marginTop" *ngIf="!customStake.waterFallModel.selected">
            <div class="col" style="color: red;">
              {{translateService.getLabel("please_choose")}} "{{getSelectedStakeFundName(selectedStakeFundName)}}" {{translateService.getLabel("on_the_waterfall_summary")}}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button mat-raised-button class="primary-bg" type="button" (click)="applicationModal.dismiss('Cross click')">
      {{translateService.getLabel("cancel")}}
    </button>
    <button mat-raised-button class="primary-bg" type="submit" (click)="saveCustomStake(); applicationModal.dismiss('Cross click') ">
      {{translateService.getLabel("save")}}
    </button>
  </div>
</ng-template>
</div>  