<form #attrForm="ngForm" (ngSubmit)="onSubmit()">
    <table class="table">
        <tr>
            <th>Valuation Specific Data Items</th>
            <th>Mapped to</th>
        </tr>
        <tbody>
            <ng-container *ngFor="let attrs of _attributes; let i = index">
                <tr class="spanned-row"
                    *ngIf="(attrs.qxKey === 'cash' && attrs.financialComponentType === 'ASSETS') || 
                    (attrs.qxKey === 'accountsPayable' && attrs.financialComponentType === 'LIABILITIES') || (attrs.qxKey === 'totalShareholdersEquity' && attrs.financialComponentType === 'EQUITY')">
                    <td [attr.colspan]="_attributes?.length">
                        <span *ngIf="attrs.financialComponentType === 'ASSETS'">
                            Assets
                        </span>
                        <span *ngIf="attrs.financialComponentType === 'LIABILITIES'">
                            Liabilities
                        </span>
                        <span *ngIf="attrs.financialComponentType === 'EQUITY'">
                            Equity
                        </span>
                    </td>
                </tr>
                <tr>

                    <td class="tdAlign" class="tdAlign">
                        <div>
                            {{ attrs?.qxLabel }}
                        </div>
                    </td>
                    <td class="tdAlign">
                        <div *ngIf="isReadOnly">
                            {{ attrs?.gxLabel }}
                        </div>
                        <div *ngIf="!isReadOnly">
                            <mat-form-field class="example-full-width">
                                <mat-icon matPrefix>search</mat-icon>
                                <input type="text" placeholder="Search" name="{{attrs?.qxKey}}" [attr.id]="attrs?.gxKey"
                                    aria-label="gxAttributes" matInput [ngModel]="{name: attrs.gxLabel}"
                                    [matAutocomplete]="auto" (keyup)="onKeyUp($event)" required>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="attrOptionDisplay"
                                    (optionSelected)="onSelect($event, attrs, i)">
                                    <cdk-virtual-scroll-viewport class="autocomplete-test-viewport"
                                        [ngStyle]="{'height': height}" itemSize="20" minBufferPx="200">
                                        <mat-option *cdkVirtualFor="let option of filteredOptions" [value]="option"
                                            [disabled]="_usedAttrs[option.uniqueKey]">
                                            <div class="ac-option">
                                                <div>
                                                    {{option.name}}
                                                </div>
                                                <div>
                                                    {{ option.global === 'true' ? 'Global' : 'Custom' }}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <!-- {{ attrForm?.status }} - {{ attrForm?.valid }} -->
    <!-- <button type="submit">Click me</button> -->
    <!-- {{ attrForm?.value | json}} -->
</form>