<table *ngIf="companiesDataSource" class="mat-elevation-z1 companies-table-wrapper" mat-table
    [dataSource]="companiesDataSource">
    <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Company </th>
        <td mat-cell *matCellDef="let row;index as i">
            <div class="company">
                <img class="company-logo" [src]="row?.logo" alt="logo" />
                <div class="company-info">
                    <div class="company-name">
                        {{row?.companyName}}
                    </div>
                    <div class="company-sector">
                        {{row?.details?.sector}}
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="attributeList">
        <th mat-header-cell *matHeaderCellDef> Attribute Map </th>
        <td mat-cell *matCellDef="let row;index as i">
            {{row?.attributeList?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="versionList">
        <th mat-header-cell *matHeaderCellDef> Version List </th>
        <td mat-cell *matCellDef="let row;index as i">
            {{row?.versionList?.name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="assignedBy">
        <th mat-header-cell *matHeaderCellDef> Assigned By </th>
        <td mat-cell *matCellDef="let row;index as i">
            {{row?.assignedBy}}
        </td>
    </ng-container>
    <ng-container matColumnDef="uploadDate">
        <th mat-header-cell *matHeaderCellDef> Upload Date </th>
        <td mat-cell *matCellDef="let row;index as i">
            {{row?.uploadDate | date: 'mediumDate' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let row;index as i">
            <button *ngIf="!row?.actions?.canDelete || row?.assignedBy === 'System' else deleteTemplate"
                mat-icon-button
                class="icon-primary"
                 disabled>
                <i class="fas fa-trash" matTooltip="Cannot be deleted as it is already in use."
                matTooltipPosition="left"
                ></i>
            </button>
            <ng-template #deleteTemplate>
                <button mat-icon-button class="icon-primary"
                    (click)="onDeleteAssignment($event,row,i)">
                    <i class="fas fa-trash"></i>
                </button>
            </ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="nodata">
        <td mat-footer-row *matFooterCellDef class="no-companies" [colSpan]="displayedColumns.length">
            No company added to this fund. Please add one or more companies to start the assignment.
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClick($event, row)"></tr>
    <tr mat-footer-row [hidden]="companiesDataSource.data.length >0" *matFooterRowDef="['nodata']">
</table>
<mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>